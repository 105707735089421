/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToogleDiv } from '../../../UpsertPane/CollapsableContainer/CollapsableContainer.style';
import { Button, Radio, RadioIcon } from '../../../UpsertPane/UpsertPane.style';
import {
  BtnContainer,
  Input,
  Container,
  FlexWrapper,
  KpvListContainer,
  SearchIcon,
  Inputcontainer
} from './EditCustomiseKpv.style';
import { Creators as UpsertPaneCreators } from '../../../UpsertPane/Store';
import { Creators as KPVChartsCreators } from '../../Store';
import { CustomiseKpvContent } from '../../../UpsertPane/CustomiseKpv/CustomiseKpv.view';
import { searchBlack } from '../../../../assets/icons';
import { CHARTS_WITH_NO_SINGLE_PANE_SUPPORT, CHART_TYPES } from '../../../../constants';
import { ToggleOff, ToggleOn } from '../../../../assets/icons/KPV';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { getMachinesFromKpvs } from '../../utils/getMachinesFromKpvs';
import { getPlotDataPayload } from '../../utils/getPlotDataPayload';

const EditCustomiseKpv = (props) => {
  const { paneData, editPaneNo, setShowCustomiseKpv, setShowMenu } = props;
  const { rawPlotData, ...rest } = paneData;
  const { machinesSelected: prevMachinesSelected, category, chartTypeCategory } = rest;
  const { multiSelectKpv, machinesSelected } = useSelector((state) => state.upsertPaneData);
  const { machinesShortlisted, xMinDomain, xMaxDomain } = useSelector((state) => state.KPVCharts);
  const { chartType, transitionChartType } = useSelector((state) => state.KPVCharts.panesShortHandInfo[editPaneNo]);
  const [filteredMachinesShortlisted, setFilteredMachinesShortlisted] = useState(machinesShortlisted);
  const [kpvSearchQuery, setKpvSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { localeStrings } = useSelector((state) => state.localeStrings);

  // setting machines selected to upsert pane reducer
  useEffect(() => {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'machinesSelected',
        value: prevMachinesSelected
      })
    );
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'multiSelectKpv',
        value: prevMachinesSelected.length > 1
      })
    );
  }, []);

  // handling toogle
  const handleMultiSlectToogle = () => {
    if (multiSelectKpv) {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'machinesSelected',
          value: []
        })
      );
    }
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'multiSelectKpv',
        value: !multiSelectKpv
      })
    );
  };

  const handleUpdate = () => {
    const plotDataPayload = getPlotDataPayload(
      [
        {
          kpv: machinesSelected,
          category,
          chartTypeCategory,
          chartType,
          singlePane: true
        }
      ],
      xMinDomain,
      xMaxDomain
    );
    dispatch(KPVChartsCreators.getPlotData(plotDataPayload, true, editPaneNo, { ...rest, kpv: machinesSelected }));
    dispatch(UpsertPaneCreators.resetUpsertPaneData());
    setShowCustomiseKpv(false);
    setShowMenu(false);
  };

  const handleCancel = () => {
    setShowMenu(false);
    setShowCustomiseKpv(false);
  };

  // handle kpv/machine search
  const handleKPVSearch = (e) => {
    setKpvSearchQuery(e.target.value);
  };
  useEffect(() => {
    const tempUniqueMachines = getMachinesFromKpvs(machinesShortlisted);
    const feilds = chartType === CHART_TYPES.TRANSITION_CHART ? tempUniqueMachines : machinesShortlisted;
    const result = feilds.reduce((tempFilteredMachinesShortlisted, machine) => {
      let isMatchFound = false;
      if (
        chartType === CHART_TYPES.DATA_CHART &&
        (machine.machineName.toLowerCase().includes(kpvSearchQuery.toLowerCase()) ||
          machine.kpvName.toLowerCase().includes(kpvSearchQuery.toLowerCase()))
      ) {
        isMatchFound = true;
      } else if (
        chartType === CHART_TYPES.TRANSITION_CHART &&
        machine.machineName.toLowerCase().includes(kpvSearchQuery.toLowerCase())
      ) {
        isMatchFound = true;
      }
      return isMatchFound ? [...tempFilteredMachinesShortlisted, machine] : tempFilteredMachinesShortlisted;
    }, []);
    setFilteredMachinesShortlisted(result);
  }, [kpvSearchQuery]);

  return (
    <Container>
      <FlexWrapper>
        <Inputcontainer>
          <Input placeholder='Search KPV' onChange={handleKPVSearch} value={kpvSearchQuery} />
          <SearchIcon width='1.5'>
            <DynamicSVGIcon iconUrl={searchBlack} width='1.5rem' />
          </SearchIcon>
        </Inputcontainer>
        {!CHARTS_WITH_NO_SINGLE_PANE_SUPPORT.includes(chartTypeCategory) && (
          <ToogleDiv>
            <span style={{ textTransform: 'capitalize' }}>{localeStrings['Multi Select'] || 'Multi Select'}</span>
            <div style={{ marginLeft: '1rem' }} className='pointer' onClick={handleMultiSlectToogle}>
              {multiSelectKpv ? (
                <DynamicSVGIcon width='2.5rem' iconUrl={ToggleOn} />
              ) : (
                <DynamicSVGIcon width='2.5rem' iconUrl={ToggleOff} />
              )}
            </div>
            {/* <Radio onClick={handleMultiSlectToogle}>{multiSelectKpv && <RadioIcon />}</Radio> */}
          </ToogleDiv>
        )}
      </FlexWrapper>
      <KpvListContainer>
        <CustomiseKpvContent
          kpvs={filteredMachinesShortlisted}
          chartType={chartType}
          chartTypeCategory={chartTypeCategory}
        />
      </KpvListContainer>
      <BtnContainer>
        <Button className='pointer' onClick={handleCancel}>
          {localeStrings.cancel || 'Cancel'}
        </Button>
        <Button className='dark pointer' onClick={handleUpdate}>
          {localeStrings.update || 'update'}
        </Button>
      </BtnContainer>
    </Container>
  );
};

export default EditCustomiseKpv;
