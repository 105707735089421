import styled from 'styled-components';
import { fontFamily } from '../../../../constants';
import { FlexContainer } from '../../../../globalStyles';

export const HeaderContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: ${({ alignEnd }) => (alignEnd ? 'space-between' : 'flex-end')};
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  min-height: 5rem;
  overflow: auto;
  width:100%;
  background-color:#F9F9F9 ;
  padding-left:1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
;     
  &.header::-webkit-scrollbar-thumb {
    background-color: #cac3c3; /* Color for the scrollbar track */
  }
`;

export const WidgetFullViewIcon = styled.div`
cursor: pointer;
position: absolute; 
z-index: 10;
opacity: 0.2;
:hover{
  opacity: 1;
}
`;

export const AddHomeIcon = styled.div`
cursor: pointer;
position: absolute; 
z-index: 10;
left: -5px;
top: 30px;
/* opacity: 0.2; */
:hover{
  /* opacity: 1; */
}
`;

export const RemoveIcon = styled.div`
cursor: pointer;
position: absolute; 
background: white;
width: 20px;
height: 20px;
border-radius: 50%;
z-index: 10;
left: -5px;
top: 30px;
/* opacity: 0.2; */
:hover{
  /* opacity: 1; */
}
`;

export const WidgetHeader = styled.span`
  font-family: ${fontFamily.circularBold};
  font-size: 1.6rem;
  /* color: #545454; */
  color: ${({ theme }) => (theme.contrast.primary)};
  max-width: 80%;    
`;

export const ZoomingDiv = styled(FlexContainer)`
  /* width: 15.8rem; */
  width:90%;
  height: 3.6rem;
  box-shadow: 0px 0px 5px #0000001a;
  border-radius: 4px;
  opacity: 1;
`;

export const ZoomText = styled.span`
  width: 7.4rem;
  text-align: center;
  font: 1.8rem;
  font-family: ${fontFamily.circularMedium};
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  border-right: 1px solid #707070;
  border-left: 1px solid #707070;
  margin: 0rem 0.5rem;
`;

export const IconBox = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  color: #707070;
  pointer: cursor;
  background: none;
  border: none;
  text-align: center;
  opacity: 0.8;

  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  /* :hover {
    transform: scale(1.1);
  } */
`;
