/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import Toast from '../component/Common/Toast';
import UpsertWidget from '../component/UpsertWidget/UpsertWidget';
import WidgetInstances from '../component/UpsertWidget/WidgetInstances/WidgetInstances';
import { useActions } from '../store/hooks/useActions';

import { commonRoutes } from './route';
import { getUserDataFromLocal } from '../shared/utility/helper';

// eslint-disable-next-line import/no-mutable-exports
const Home = () => {
  const finalMenu = useSelector((state) => state.home.finalMenu);
  const error = useSelector((state) => state.configData.error);
  const { setToastError, onLoginFail } = useActions();
  // directing to login page if token is not available
  const userData = getUserDataFromLocal();
  const authenticated = useSelector((state) => state.loginData.authenticated);
  if (authenticated && !userData?.token) {
    onLoginFail({ error: '' });
  }

  const homeMenu =
    finalMenu &&
    finalMenu.length > 0 &&
    [...finalMenu, ...commonRoutes].map((route, index) => {
      const { path, exact, name, Component, menuId } = route;
      if (path === '/home') {
        return null;
      }
      if (Component) {
        return (
          <Route
            key={index}
            path={path}
            exact={exact}
            name={name}
            render={(props) => <Component {...props} menuId={menuId} />}
          />
        );
      }

      return null;
    });

  const homeRouteDetails = finalMenu?.find((e) => e.path === '/home');
  const { Component, path, menuId, name, subMenuOptions } =
    homeRouteDetails || {};
  // console.log('homeRouteDetails', homeRouteDetails);
  // move home at last which uses widgets

  const clearError = () => {
    setToastError('');
  };

  return (
    <>
      <Switch>
        {homeMenu}
        {/* this route should be at last since exact=false all the uncatched routes will use the following  */}
        {homeRouteDetails && (
          <Route
            key={menuId}
            path={path}
            exact={false}
            name={name}
            render={(props) => <Component {...props} menuId={menuId} />}
          />
        )}
      </Switch>
      {error && (
        <Toast
          className='right'
          header='Error'
          message={error}
          fn={clearError}
        />
      )}
    </>
  );
};
export default Home;
