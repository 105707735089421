/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable brace-style */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import kababIcon from '../../../../assets/icons/Filter/kabab.svg';
import { useActions } from '../../../../store/hooks/useActions';
import NestedDropdown from '../../../Common/DropDownModal/NestedDropdown';
import orientation from '../../../../assets/icons/orientation.svg';
import ShowStatus from '../../../../assets/icons/ShowStatus.svg';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { MAX_WIDGETS_IN_HOME, WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../../../constants';
import { getResolvedWidgetCategory, handlePdfExport, handlePngExport } from '../../utils';
import ConfirmationModal from '../../../Common/ConfirmationBox/ConfirmationBox.view';
import { DeleteTab, feedback, settingsBlack, Settingslight, DeleteBlack } from '../../../../assets/icons';
import ZoomLevel from '../../Common/WidgetHeader/ZoomLevel';
import { WidgetCreators } from '../../../../views/Widgets/store';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import FullViewWidget from '../FullViewWidget/FullViewWidget';
import ExportDashboard from '../../../../views/ExportDashboard/ExportDashboard';

const rawOptionsVal = [
  // {
  //   id: 1,
  //   label: 'Export',
  //   value: 'export'
  // },
  // {
  //   id: 2,
  //   label: 'Share',
  //   value: 'share'
  // },
  // {
  //   id: 3,
  //   label: 'configure',
  //   value: 'configure'
  // },
  // {
  //   id: 4,
  //   label: 'Delete',
  //   value: 'delete'
  // },
  {
    id: 6,
    label: 'Show Stats',
    value: 'statsOf',
    type: 'checkbox'
  },
  {
    id: 5,
    label: 'orientation',
    value: 'orientation',
    type: 'radio',
    subMenu: [
      { id: 1, label: 'Horizontal', value: 'horizontal' },
      {
        id: 2,
        label: 'Vertical',
        value: 'vertical'
      }
    ]
  }
];

const itemIcons = {
  orientation,
  statsOf: ShowStatus,
  share: feedback,
  configure: settingsBlack,
  delete: DeleteBlack
  // horizontal: orientation,
  // vertical: orientation
};

const BarChartOptions = ({
  id,
  WID,
  WidgetName,
  setWidgetEditOptions,
  widgetEditOptions,
  barChartDetails,
  rawOptions = rawOptionsVal,
  componentData,
  setZoomLevel,
  zoomLevel,
  hasZoomControl,
  isEdit
}) => {
  const { meta } = barChartDetails || {};
  const { activeTab, layoutComponentData, widgetTypesMenu, isHideHeader, isHideBorder, WTIId } = useSelector((state) => state.widgets);
  const { gridKeys, labelMapping } = meta || {};
  const [showOptions, setShowOptions] = useState(false);
  const userData = useSelector((state) => state.loginData.userData);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfm, setShowDeleteConfm] = useState(false);
  const [error, setError] = useState('');
  const [showMaxError, setshowMaxError] = useState(false);

  // const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  // const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  // const isHideBorder = useSelector((state) => state.widgets.isHideBorder);

  const [showFullView, setShowFullView] = useState(false);
  const [exportXlxs, setExportXlxs] = useState(false);
  const WidgetPanelName = useSelector((state) => state.widgets.WidgetPanelName);
  const dispatch = useDispatch();
  const widgetPayload = useSelector((state) => state.widgets.widgetPayload);
  const { updateWidgetPayload, addWidgetFromPreview, deleteWidgetFromHome, getHomeLayout, setWidgetsInHomeList } = useActions();
  const [showWidgetAdd, setShowWidgetAdd] = useState(false);
  const [showWidgetRemove, setShowWidgetRemove] = useState(false);

  const setModalIsVisibal = (val) => {
    setShowOptions(val);
  };
  const {
    getWidgetsLayout,
    setWidgetType,
    setFormInput,
    valueSetter,
    getWidgetDataSuccess
  } = useActions();

  const handleDelete = () => {
    setModalIsVisibal(false);
    setShowDeleteConfm(true);
  };

  const handleToggleShow = () => {
    setModalIsVisibal(false);

    dispatch(
      WidgetCreators.setHeaderHide({ WID, WPID: activeTab?.id, isHideHeader: !isHideHeader[WID + activeTab?.id] })
    );
    // const payload = { ...widgetPayload, isHideHeader: isHideHeader[WID] };
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        isHideHeader: !isHideHeader[WID + activeTab?.id],
        isHideBorder: isHideBorder[WID + activeTab?.id],
        widgetEditOptions,
        zoomLevel

      },
      WidgetMetaData: widgetMetaData
    };

    updateWidgetPayload({ widgetPayload, WID });
  };
  const handleBorderShow = () => {
    setShowOptions(false);
    dispatch(
      WidgetCreators.setBorderHide({ WID, WPID: activeTab?.id, isHideBorder: !isHideBorder[WID + activeTab?.id] })
    );
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        isHideHeader: isHideHeader[WID + activeTab?.id],
        isHideBorder: !isHideBorder[WID + activeTab?.id],
        widgetEditOptions,
        zoomLevel
      },
      WidgetMetaData: widgetMetaData
    };

    updateWidgetPayload({ widgetPayload, WID });
  };

  const confirmDelete = async () => {
    // console.log('confirmed deletion', id, WID);
    try {
      const WPID = activeTab.id;
      setLoading(true);
      const URL = `${apiEndPoints.deleteWidget}`;
      const resolvedUrl = URL.replace('{{WID}}', WID);

      const res = await WebService.post(resolvedUrl, {
        userId: userData.userId,
        WidgetPanelId: activeTab.id
      });
      if (res.status >= 200 && res.status < 300) {
        // console.log('deletion successfull');
        getWidgetsLayout({
          userId: userData.userId,
          WPID
        });
        setShowDeleteConfm(false);
      } else {
        throw res;
      }
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  const history = useHistory();

  const handleWidgetConfiguration = async () => {
    const { type, WidgetName, widgetCategory, formData } = componentData;
    setWidgetType({
      widgetType: type,
      widgetLabel: widgetTypesMenu?.[type]?.label || type,
      //  TODO_W_R: add widget category also
      [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: widgetCategory
    });
    valueSetter({ widgetName: WidgetName });
    getWidgetDataSuccess({ barChartDetails });
    setFormInput(formData);
    const resolvedCat = getResolvedWidgetCategory(widgetCategory, type);
    if (
      resolvedCat === WIDGET_CATEGORIES.OEE_DONUT ||
      resolvedCat === WIDGET_CATEGORIES.BAR_CHART
    ) {
      history.push({
        pathname: '/home/create-widget/form',
        state: { isEdit: true, WID }
      });
    } else {
      history.push({
        pathname: '/home/create-widget/',
        state: { isEdit: true, WID }
      });
    }
  };

  useEffect(() => {
    if (componentData && !isEmpty(widgetEditOptions)) {
      const { type, WidgetName, widgetCategory, formData } = componentData;
      const { widgetComponentData, widgetMetaData } = {
        widgetComponentData: {
          ...componentData,
          formData,
          type,
          [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
            widgetCategory,
            type
          )
        },
        widgetMetaData: {}
      };
      const widgetPayload = {
        WidgetComponentData: {
          ...widgetComponentData,
          isHideHeader: isHideHeader[WID + activeTab?.id],
          isHideBorder: isHideBorder[WID + activeTab?.id],
          widgetEditOptions,
          zoomLevel
        },
        WidgetMetaData: widgetMetaData
      };

      updateWidgetPayload({ widgetPayload, WID });
    }
  }, [widgetEditOptions]);

  const handleAddToHome = () => {
    if (widgetsInHome?.length >= MAX_WIDGETS_IN_HOME) {
      setshowMaxError(true);
    } else {
      setShowWidgetAdd(true);
    }
  };
  const handleDeleteFromHome = () => {
    setShowWidgetRemove(true);
  };

  const location = useLocation();
  // this instace name are only for popup window
  const instaceName = new URLSearchParams(location.search).get('instace_name');
  const handleAddWidgetToHome = () => {
    const onSuccessNavigation = () => {
      if (!widgetsInHome.includes(WID)) {
        setWidgetsInHomeList([...widgetsInHome, WID]);
      } else {
        const newWidgetList = [...widgetsInHome];
        const index = newWidgetList.indexOf(WID);
        newWidgetList.splice(index, 1);
        setWidgetsInHomeList(newWidgetList);
      }
      getHomeLayout({ UserId: userData.userId });
    };
    const widgetPayload =
    {
      UserId: userData?.userId,
      WID: Number(WID),
      Url: location.pathname,
      WTIId
    };
    addWidgetFromPreview({ widgetPayload, onSuccessNavigation });
    setShowWidgetAdd(false);
  };

  const handleRemoveWidgetFromHome = () => {
    const onSuccessNavigation = () => {
      if (!widgetsInHome.includes(WID)) {
        setWidgetsInHomeList([...widgetsInHome, WID]);
      } else {
        const newWidgetList = [...widgetsInHome];
        const index = newWidgetList.indexOf(WID);
        newWidgetList.splice(index, 1);
        setWidgetsInHomeList(newWidgetList);
      }
      getHomeLayout({ UserId: userData.userId });
    };
    const widgetPayload =
    {
      UserId: userData?.userId,
      WID: Number(WID),
    };
    deleteWidgetFromHome({ widgetPayload, onSuccessNavigation });
    setShowWidgetRemove(false);
  };

  // eslint-disable-next-line consistent-return
  const handleMenuItemClick = (option, parentDetails) => {
    const { subMenu, label, value } = option;
    const { value: parent, type } = parentDetails;
    // if handler defined at option level or else default handler
    if (!subMenu) {
      // console.log('check', parent, option, value);
      switch (value) {
        case 'delete':
          handleDelete();
          break;
        case 'toggleHeader':
          handleToggleShow();
          break;
        case 'toggleBorder':
          handleBorderShow();
          break;

        case 'configure':
          handleWidgetConfiguration();
          break;
        case 'addToHome':
          handleAddToHome();
          break;
        case 'deleteFromHome':
          handleDeleteFromHome();
          break;
        case 'export':
          // handleExport(value);
          break;
        case 'pngExport':
          // if WidgetPanelName are not there so passing their respective instace name
          handlePngExport(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
          break;
        case 'excelExport':
          setExportXlxs(true);
          setShowOptions(false);
          break;
        case 'pdfExport':
          handlePdfExport(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
          break;
        case 'fullView':
          setShowOptions(false);
          setShowFullView(true);
          break;
        default:
          // generic handlers based on type
          if (type === 'radio') {
            setWidgetEditOptions({
              key: parent,
              value: {
                [value]: !widgetEditOptions?.[parent]?.[value]
              }
            });
            setModalIsVisibal(false);
            break;
          }
          if (type === 'checkbox') {
            setWidgetEditOptions({
              key: parent,
              value: {
                ...(widgetEditOptions?.[parent] || {}),
                [value]: !widgetEditOptions?.[parent]?.[value]
              }
            });
            break;
          }
      }
    }
  };

  const options = useMemo(
    () =>
      rawOptions.map((e) => {
        const { value } = e;
        if (value === 'statsOf') {
          return {
            ...e,
            subMenu: gridKeys?.map((e) => ({
              value: e,
              label: labelMapping?.[e]
            }))
          };
        }
        return e;
      }),
    [meta, rawOptions]
  );

  useEffect(() => {
    if (isEmpty(widgetEditOptions) && !WID) {
      // set all the predefined options
      // set the grid details as selected
      const parent = 'statsOf';
      const statsOptions = options?.find((e) => e.value === parent)?.subMenu;
      setWidgetEditOptions(
        {
          key: 'statsOf',
          value: statsOptions?.reduce((acc, e) => {
            const { value } = e;
            return {
              ...acc,
              [value]: true
            };
          }, {})
        },
        false
      );
      setWidgetEditOptions(
        {
          key: 'orientation',
          value: {
            vertical: true
          }
        },
        false
      );
    }
  }, [widgetEditOptions, options]);

  // checks whether the option is selected
  const activeChecker = ({ activeItems, item, parentDetails }) => {
    const { value } = item || {};
    const { value: parent } = parentDetails || {};
    return activeItems?.[parent]?.[value];
  };
  const zoomProps = {
    hasZoomControl,
    setZoomLevel,
    zoomLevel,
    WID,
    componentData: { ...componentData, widgetEditOptions }
  };
  const nestedModalRef = useRef();
  return (
    <>
      <div style={{ position: 'inherit', zIndex: '10', cursor: 'pointer' }} ref={nestedModalRef}>
        <NestedDropdown
          width='auto'
          data={options}
          handleClick={handleMenuItemClick}
        // singleSelection
          noContainerPadding
          setModalIsVisibal={setModalIsVisibal}
          isActive={showOptions}
          ContainerStyle={{
            position: 'absolute',
            width: 'max-content',
            zIndex: '20'
          }}
          isNested={true}
          activeItems={widgetEditOptions}
          isActiveChecker={activeChecker}
          showSelection={false}
          itemIcons={itemIcons}
          extendLeft={nestedModalRef?.current?.getBoundingClientRect()?.left > 500} // if no enough space
        // extendLeft={nestedModalRef.current?.offsetLeft > 254}
          extraMenu={<ZoomLevel {...zoomProps} />}
        >
          <div
            onClick={() => {
              setModalIsVisibal(!showOptions);
            }}
          >
            {!isEdit && <DynamicSVGIcon rotate={90} iconUrl={kababIcon} width='2.2rem' />}
          </div>
        </NestedDropdown>
        {/* delete confirmation modal */}
        <ConfirmationModal
          style={{ position: 'fixed', minWidth: '350px', left: '7%', top: '250px' }}
          showModal={showDeleteConfm}
          handleDelete={confirmDelete}
          closeModalHandler={() => setShowDeleteConfm(false)}
          message={`Do you really want to delete widget ${WidgetName}( Index=${id}, WID=${WID})`}
          buttonLoading={loading}
        />
        <ConfirmationModal
          showModal={showWidgetAdd}
          action="Add"
          Header="Are you Sure?"
          handleDelete={() => { handleAddWidgetToHome(); }}
          closeModalHandler={() => {
            setShowWidgetAdd(false);
          }}
          message="You want to Add this Widget to Home"
        />
        <ConfirmationModal
          showModal={showWidgetRemove}
          action="Remove"
          Header="Are you Sure?"
          handleDelete={() => { handleRemoveWidgetFromHome(); }}
          closeModalHandler={() => {
            setShowWidgetRemove(false);
          }}
          message="You want to Remove this Instance from Home"
        />
        <ConfirmationModal
          showModal={showMaxError}
          action="Free Space"
          error={true}
          Header="Exceeding Limit!"
          handleDelete={() => {
            setshowMaxError(false);
          }}
          closeModalHandler={() => {
            setshowMaxError(false);
          }}
          message="Home reached maximum number of Widgets"
        />
      </div>
      {showFullView && <FullViewWidget WID={WID} fullView={true} setShowFullView={setShowFullView} />}
      {exportXlxs && <ExportDashboard name={`${WidgetPanelName}_${WidgetName}`} setExportXlxs={setExportXlxs} WidgetComponentData={layoutComponentData[WID]} individualExport={true} WID={WID} />}

    </>
  );
};

export default BarChartOptions;
