/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import SearchBar from '../../Common/SearchBar/SearchBar';
import { SidePanelContainer } from '../WidgetCommonCreationForm/WidgetCommonCreationForm.style';
import ButtonComp from '../../Common/Button/Button.view';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import {
  BtnContainer,
  Details,
  IconDiv,
  MainContainer,
  MainDivSbar,
  Text
} from './WidgetTemplateSelectionSideBar.style';
import { UpsertWidgetCreators } from '../Store';
// import { widgetMenu } from '../../Widget/utils/contants';
import { Div, FlexContainer } from '../../../globalStyles';
import { useActions } from '../../../store/hooks/useActions';
import { WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../../constants';
import { EOEED } from '../../../assets/icons';
import { getWidgetCategory } from '../../Widget/utils';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Loader from '../../Loader/Loader';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { Header } from '../../Common/CommonStyles';
import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import KababIcon from '../../../assets/icons/Filter/kabab.svg';

const rowOptions = [
  { id: 2, label: 'Preview', value: 'preview', options: [] }
  // { id: 1, label: 'Edit Instance', value: 'editInstance', options: [] },
  // { id: 3, label: 'Delete', value: 'delete', disable: true, options: [] },
  // { id: 4, label: 'Share', value: 'share', disable: true, options: [] },
  // { id: 5, label: 'Duplicate', value: 'duplicate', disable: true, options: [] }
];

const WidgetTemplateSelectionSideBar = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const widgetType = useSelector((state) => state.upsertWidgetData.widgetType);
  const customTemplateTab = useSelector((state) => state.upsertWidgetData.customTemplateTab);
  const layout = useSelector((state) => state.widgets.layout);
  const InheritedWPID = useSelector((state) => state.widgets.InheritedWPID);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const userData = useSelector((state) => state.loginData.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const modalReff = useRef();
  const { setToastError, valueSetter, setTemplateInstanceDetails, widgetDetailSetter, setTemplateBasedData, clearFilterData, clearTreeData } = useActions();
  const widgetSVGs = {
    default: EOEED
  };
  const [templateList, setTemplateList] = useState([]);
  const fetchTemplateList = async () => {
    try {
      setLoading(true);
      // TODO: intheritedWPID || below condition
      // const WPID = (InheritedWPID) || (!isEmpty(layout?.[breakpoint]) ? activeTab?.id : '');
      // Changed the condition so that on clear the template it will load all templates
      const WPID = ((!isEmpty(layout?.[breakpoint])) ? InheritedWPID : '') || (!isEmpty(layout?.[breakpoint]) ? activeTab?.id : '');
      let endpoint = `${apiEndPoints.getAllWidgetPanelTemplates}?UserId=${userData.userId}`;
      if (WPID) {
        endpoint = `${apiEndPoints.GetWidgetPanelTemplateByWPID}/${WPID}`;
      }
      const resp = await WebService.get(endpoint);
      if (resp.status >= 200 && resp.status < 300) {
        setTemplateList(resp.data?.data);
      } else {
        throw resp;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      setToastError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setTemplateList([]);
    fetchTemplateList();
  }, []);

  // handling row options
  const [activeRowItem, setActiveRowItem] = useState();
  // console.log('activeRowItem', activeRowItem);
  const handleOptionsClick = (item) => {
    setActiveRowItem(item);
  };
  const closeDropdownOptions = () => {
    setActiveRowItem({});
  };

  const handleSelect = (item) => {
    clearFilterData();
    clearTreeData();
    setTemplateBasedData(item?.TemplateBaseData);
    const { WidgetTemplateName, TemplateParameters, WTId } = item || {};
    const { widgetTypesIncluded, formInput, WPID } = TemplateParameters;
    setTemplateInstanceDetails({ templateName: WidgetTemplateName, templateCreatorName: item?.UserId, templateFormInput: formInput, WTId, WPId: WPID });
    widgetDetailSetter({
      key: 'widgetTypesIncluded',
      value: widgetTypesIncluded
    });
    history.push('/home/widget-template-instance-list');
    valueSetter({ customTemplateTab: false });
  };
  const handleRowOptionsItemClick = (opt, rowDetails) => {
    // console.log('dp', opt, rowDetails);
    const { value } = opt;
    const { activeRowItem } = rowDetails;
    valueSetter({ customTemplateTab: false });
    switch (value) {
      case 'preview':
        widgetDetailSetter({
          key: 'isHideHeader',
          value: {}
        });
        widgetDetailSetter({
          key: 'isHideBorder',
          value: {}
        });
        widgetDetailSetter({
          key: 'zoomLevel',
          value: {}
        });
        handleSelect(activeRowItem);
        setTemplateBasedData(activeRowItem?.TemplateBaseData);
        history.push({
          pathname: '/home/view-dashboard',
          state: { isTemplateInstance: false, WTId: activeRowItem?.WTId }
        });
        break;

      default:
        break;
    }
  };
  const filteredTemplateList = templateList.filter((val) => {
    const { WidgetTemplateName } = val;
    if (WidgetTemplateName?.toLocaleLowerCase()?.includes(searchValue.toLocaleLowerCase())) {
      return true;
    }
    return false;
  });

  if (!customTemplateTab) {
    useClickOutside(modalReff, () => {
      valueSetter({ showTemplateListSidePanel: false });
    });
  }
  return (
    <SidePanelContainer className='widgetSidePanel' width='41.8rem' ref={modalReff}>
      <Header>Templates</Header>
      {loading ? <Loader /> : (
        <>
          <div>

            <br />
            <SearchBar
              searchValue={searchValue}
              placeholder='Search templates'
              width='36'
              onChangeHandler={setSearchValue}
            />
          </div>
          <MainContainer>
            {filteredTemplateList.length > 0 ? (
              filteredTemplateList.map((e, i) => (
                <MainDivSbar key={e.id} className={`space-between ${widgetType == e.value ? 'selected' : ''}`}>
                  <FlexContainer className='ai_center'>
                    <IconDiv>
                      <DynamicSVGIcon iconUrl={widgetSVGs.default} width='5rem' />
                    </IconDiv>
                    <Details className='pointer'>
                      <Text onClick={() => handleSelect(e)}>{e.WidgetTemplateName}</Text>
                      <Text className='decription'>{e.detail}</Text>
                    </Details>
                  </FlexContainer>
                  <DropDownModal
                    data={rowOptions}
                    isActive={activeRowItem?.WTId === e.WTId}
                    setModalIsVisibal={closeDropdownOptions}
                    handleClick={handleRowOptionsItemClick}
                    extraParms={{ activeRowItem }}
                    ContainerStyle={{ padding: '0px', width: '20rem' }}
                    TextStyle={{ marginTop: '0' }}
                  >
                    <div className='pointer' onClick={() => handleOptionsClick(e)}>
                      <DynamicSVGIcon iconUrl={KababIcon} rotate={90} width='2.2rem' />
                    </div>
                  </DropDownModal>
                </MainDivSbar>
              ))
            ) : (
              <Div mt='2'>No Templates found</Div>
            )}
          </MainContainer>
        </>
      )}
    </SidePanelContainer>
  );
};

export default WidgetTemplateSelectionSideBar;
