/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import { BtnWrapper, Button, Heading } from '../../../Common/PopUpModal/Modal.style';
import Index from '../../../Common/Input/Input';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';
import { Span } from '../../../../globalStyles';
import InputDropdown from '../../../Common/DropDownModal/InputDropdown';
import { useActions } from '../../../../store/hooks/useActions';
import { HorizontalDivider } from '../../../Common/ConfirmationBox/ConfimationBox.styles';

const options = [
  { label: 'Custom', value: 'custom' },
  { label: 'Select from template', value: 'fromTemplate' },
  { label: 'Select from Instance', value: 'fromInstance' }
];
const AddTabModal = ({ closeModalHandler = () => {}, handleSave }) => {
  const [tabTitle, setTabTitle] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [dpModalVisible, setDpModalVisible] = useState(false);
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);

  useEffect(() => {
    if (widgetDashboardType === 'ALL') {
      setSelectedOption('');
    } else {
      setSelectedOption({ label: 'Select from Instance', value: 'fromInstance' });
    }
  }, [widgetDashboardType]);

  const { resetTemplateInstanceDetails, setSelectedKpvs, setKpvConfigured } = useActions();
  const handleClick = (opt) => {
    setSelectedOption(opt);
    setDpModalVisible(false);
  };

  const isSaveEnabled = [selectedOption, tabTitle].every((e) => {
    if (e) {
      return true;
    }
    return false;
  });
  return (
    <PopUpModal style={{ boxShadow: 'none' }} width='40rem' closeModalHandler={closeModalHandler}>
      <Heading style={{ color: '#474747' }}>Create New Tab</Heading>
      <HorizontalDivider />
      <Span style={{ color: '#474747' }}>Tab Title</Span>
      <Index value={tabTitle} style={{ fontSize: '1.6rem' }} setValue={(val) => setTabTitle(val)} maxLength={200} />
      <br />
      <br />
      {widgetDashboardType === 'ALL' && (
      <div>
        <Span>Template</Span>
        <DropDownModal
          showSelection={true}
          activeItem={selectedOption}
          width='35rem'
          noContainerPadding
          data={options}
          handleClick={handleClick}
          isActive={dpModalVisible}
          setModalIsVisibal={setDpModalVisible}
        >
          <InputDropdown value={selectedOption?.label} handleDropdownVisibility={setDpModalVisible} />
        </DropDownModal>
        <br />
      </div>
      )}
      <BtnWrapper>
        <Button onClick={() => closeModalHandler(false)} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '-3px -3px 11px #FFFFFFCB' }}>Cancel</Button>
        <Button
          disabled={!isSaveEnabled}
          onClick={() => {
            setSelectedKpvs({ selectedKpvs: [] });
            setKpvConfigured({ isKpvConfigured: false }); resetTemplateInstanceDetails(); handleSave({ selectedOption, tabTitle });
          }}
          className='dark'
        >
          {widgetDashboardType === 'ALL' ? 'Save' : 'Add Instance'}
        </Button>
      </BtnWrapper>
    </PopUpModal>
  );
};

export default AddTabModal;
