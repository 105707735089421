/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import ButtonComp from '../../../Common/Button/Button.view';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';

import plus from '../../../../assets/icons/Filter/plus.svg';
import AddTabModal from './AddTabModal';
import { Container } from './AddTab.styles';
import { useActions } from '../../../../store/hooks/useActions';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getWidgetPanelPayload } from '../../utils/getWidgetPanelPayload';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';

const AddTab = (props) => {
  const [showAddTabModal, setShowAddTabModal] = useState(false);
  const { menuIdofWidget, widgetTypesMenu } = useSelector((state) => state.widgets);

  const { valueSetter, widgetDetailSetter, resetTemplateInstanceData } = useActions();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const userData = useSelector((state) => state.loginData.userData);
  const { addWidgetPanelSuccess, addWidget, setToastError } = useActions();

  const createTab = async (tabTitle, selectedOption) => {
    try {
      const URL = apiEndPoints.addWidgetPanel;

      const payload = getWidgetPanelPayload({
        menuId: menuIdofWidget,
        userId: userData.userId,
        isShareable: true, // TODO,
        widgetPanelName: tabTitle,
        widgetPanelLayoutData: {},
        widgetPanelComponentData: [],
        widgetPanelLayoutMetaData: {},
        InheritedWPID: (selectedOption.value === 'fromTemplate' || (selectedOption.value === 'fromInstance')) ? 0 : null,
        WTIId: ''
      });
      const res = await WebService.post(URL, payload);
      if (res.status >= 200 && res.status < 300) {
        const successPayload = res.data?.data[0]?.[0];
        addWidgetPanelSuccess(successPayload);
        setButtonLoading(false);
        valueSetter({ widgetDashboardTitle: tabTitle });
        if (selectedOption.value === 'fromTemplate') {
          valueSetter({ customTemplateTab: true });
          history.push({ pathname: '/home/create-custom-dashboard', state: { isCustomDashboard: false } });
          valueSetter({ showTemplateListSidePanel: true });
        } else if (selectedOption.value === 'fromInstance') {
          valueSetter({ customTemplateTab: true });
          history.push({ pathname: '/home/create-custom-dashboard', state: { isCustomDashboard: false } });
          valueSetter({ showInstanceTablePopUp: true });
        } else {
          history.push({ pathname: '/home/create-custom-dashboard', state: { isCustomDashboard: true } });
          widgetDetailSetter({
            key: 'showSidePanel',
            value: true
          });
        }
      } else {
        throw res;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      // setError(payload);
      console.log('ee', error);
      setToastError(error);
    } finally {
      setButtonLoading(false);
    }
  };
  const handleSave = async ({ selectedOption, tabTitle }) => {
    setButtonLoading(true);
    resetTemplateInstanceData();
    createTab(tabTitle, selectedOption);
    setShowAddTabModal(false);
  };
  return (
    <Container>
      <div onClick={() => setShowAddTabModal(!showAddTabModal)}>
        {props.children || (
          <ButtonComp
            style={{
              position: 'relative',
              width: '4rem',
              paddingTop: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
              // marginRight: '2rem'
            }}
            dark
          >
            <DynamicSVGIcon iconUrl={plus} width='2.5rem' />
          </ButtonComp>
        )}
      </div>
      {/* tab addition modal */}
      {showAddTabModal && <AddTabModal closeModalHandler={setShowAddTabModal} handleSave={handleSave} />}
    </Container>
  );
};

export default AddTab;
