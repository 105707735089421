/* eslint-disable indent */
/* eslint-disable brace-style */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PAGE_TYPES,
  WIDGET_CATEGORIES,
  WIDGET_TERMINOLOGY,
} from '../../constants';
import { Text } from '../../views/Widgets/Widget.style';
import OeeDonutWidget from '../../views/Widgets/WidgetComponents/OEEDonut/OeeDonutWiget';
// import Analytics from '../Widget/WidgetComponents/Analytics/Analytics';
import EquipmentPlan from '../Widget/WidgetComponents/EquipmentPlan/EquipmentPlan';
import FloorPlan from '../Widget/WidgetComponents/FloorPlan/FloorPlan';
import WidgetCommonCreationForm from './WidgetCommonCreationForm/WidgetCommonCreationForm';
import Analytics from './WidgetsComponents/Analytics/Analytics';
import DetailedStatusValueWidget from './WidgetsComponents/DetailedStatusValue/DetailedStatusValueWidget.view';
import UpsertStatusValueWidget from './WidgetsComponents/StatusValue/UpsertStatusValueWidget.view';
import { SelectedWidgetDiv } from './WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import FloorPlanWidget from './WidgetsComponents/FloorPlan/FloorPlan.view';
import UpsertOEEDonutView from './WidgetsComponents/OeeDonut/UpsertOEEDonut.view';
import { getResolvedWidgetCategory } from '../Widget/utils';
import UpsertSaveHeader from './UpsertSaveHeader';
import { getWidgetDetailsPayload } from '../Widget/utils/getWidgetDetailsPayload';
import { useActions } from '../../store/hooks/useActions';
// import Loader from '../Common/Loader/Loader';
import Toast from '../Common/Toast';
import { apiEndPoints } from '../../services/axios/endPoints';
import { WebService } from '../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import BarChart from './WidgetsComponents/BarChart/BarChart';
import GaugeChart from './WidgetsComponents/GaugeChart/GaugeChart';
import SVGWidgetEdit from './WidgetsComponents/SvgWidget/SVGWidgetEdit';
import { isEmpty } from '../../shared/utility/isEmpty';
import TextHtmlEdit from './WidgetsComponents/TextHTML/TextHtmlEdit';
import TableWidgetEdit from './WidgetsComponents/TableWidget/TableWidgetEdit';
import UpsertBarGaugeWidget from './WidgetsComponents/BarGauge/UpsertBarGaugeWidget';

export const widgetComponentMapping = {
  //  TODO_W_R: Change all these from WIDGET_TYPES to WIDGET_CATEGORIES
  [WIDGET_CATEGORIES.ANALYTICS]: Analytics,
  [WIDGET_CATEGORIES.OEE_DONUT]: UpsertOEEDonutView,
  [WIDGET_CATEGORIES.STATUS_VALUE]: UpsertStatusValueWidget,
  [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: DetailedStatusValueWidget,
  [WIDGET_CATEGORIES.FLOOR_PLAN]: FloorPlan,
  [WIDGET_CATEGORIES.EQUIPMENT_PLAN]: EquipmentPlan,
  [WIDGET_CATEGORIES.FLOOR_PLAN]: FloorPlanWidget,
  [WIDGET_CATEGORIES.GAUGE_CHART]: GaugeChart,
  [WIDGET_CATEGORIES.BAR_CHART]: BarChart,
  [WIDGET_CATEGORIES.SVG]: SVGWidgetEdit,
  [WIDGET_CATEGORIES.TEXT_HTML]: TextHtmlEdit,
  [WIDGET_CATEGORIES.TABLE]: TableWidgetEdit,
  [WIDGET_CATEGORIES.PIVOT_TABLE]: TableWidgetEdit,
  [WIDGET_CATEGORIES.TEXT_HTML_WIDGET]: TextHtmlEdit,
  [WIDGET_CATEGORIES.GAUGE_CONCENTRIC_WIDGET]: UpsertBarGaugeWidget
};

const RespectiveWidgetComponent = ({ isEdit }) => {
  const { widgetType, widgetLabel, widgetCategory } = useSelector(
    (state) => state.upsertWidgetData
  );

  //  TODO_W_RR: use widget category to handle
  const WidgetComponent =
    widgetComponentMapping[
    getResolvedWidgetCategory(widgetCategory, widgetType)
    ];
  return (
    <SelectedWidgetDiv>
      {WidgetComponent ? (
        <WidgetComponent isEdit={isEdit} />
      ) : (
        <Text>{widgetLabel}</Text>
      )}
    </SelectedWidgetDiv>
  );
};

const UpsertWidgetLayout = () => {
  const {
    widgetType,
    widgetLabel,
    widgetCategory,
    widgetName,
    widgetDashboardTitle,
    shareWidget,
    formInput,
    error,
    widgetEditOptions
  } = useSelector((state) => state.upsertWidgetData);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const userData = useSelector((state) => state.loginData.userData);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const layoutComponentData = useSelector(
    (state) => state.widgets.layoutComponentData
  );
  const { templateInstanceName, WTIId, WTId } = useSelector(
    (state) => state.upsertWidgetData
  );

  const WTiid = useSelector((state) => state.widgets.WTIId);

  const {
    updateWidgetPayload,
    widgetDetailSetter,
    setLayoutComponent,
    setToastError
  } = useActions();

  const { addWidget, addWidgetFailure } = useActions();

  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { isEdit, WID, fromInstanceList } = state || {};
  const navigateToDashboard = () => {
    if (!widgetDashboardTitle) {
      // handle based on menu
      history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    } else if (WTiid) {
      // condition to push to main dashboard when the tab is instance tab from an original tab
      history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    } else {
      history.push({
        pathname: '/home/create-custom-dashboard',
        state: { isCustomDashboard: true }
      });
    }
    // old implementation
    // history.push('/home');
  };

  useEffect(() => {
    if (isEmpty(formInput)) {
      history.goBack();
    }
  }, [formInput]);

  const zoomLevel = useSelector((state) => state.widgets.zoomLevel);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);

  const handleBack = () => {
    navigateToDashboard();
  };

  const selectedKpvList = useSelector((state) => state.widgets.selectedKpvList);

  // console.log(widgetType, widgetLabel, 'type and label');
  // eslint-disable-next-line arrow-body-style
  const widgetSpecificData = (addWidget = false) => {
    if (addWidget) {
      return {
        // TODO_W_RR: add widget category
        widgetComponentData: {
          formData: formInput,
          type: widgetType,
          widgetEditOptions,
          zoomLevel: zoomLevel[WID + activeTab?.id],
          isHideHeader: isHideHeader[WID + activeTab?.id],
          isHideBorder: isHideBorder[WID + activeTab?.id],
          [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
            widgetCategory,
            widgetType
          )
        },
        widgetMetaData: {}
    };
  }
    return {
      // TODO_W_RR: add widget category
      widgetComponentData: {
        formData: formInput,
        type: widgetType,
        widgetEditOptions,
        zoomLevel: zoomLevel[WID + activeTab?.id],
        isHideHeader: isHideHeader[WID + activeTab?.id],
        isHideBorder: isHideBorder[WID + activeTab?.id],
        selectedKpvItems: selectedKpvList,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          widgetType
        )
      },
      widgetMetaData: {}
    };
  };

  const onSuccessNavigation = () => {
    navigateToDashboard();
  };
  const isPreviewInstance = useSelector(
    (state) => state.widgets.isPreviewInstance
  );
  const templateLayoutData = useSelector(
    (state) => state.upsertWidgetData.templateLayoutData
  );
  const templateLayoutMeta = useSelector(
    (state) => state.upsertWidgetData.templateLayoutMeta
  );
  const layoutWPID = useSelector((state) => state.upsertWidgetData.layoutWPID);

  const handleSave = async () => {
    widgetDetailSetter({
        key: 'showSidePanel',
        value: false
      });
    let widgetPayload = {};
    // fromInstanceList loop will be executed when editing a Widget Instance from Widget Instance List Page
    if (fromInstanceList) {
      const { widgetComponentData, widgetMetaData } = widgetSpecificData();
      widgetPayload = {
        WidgetName: widgetName,
        WidgetComponentData: widgetComponentData,
        WidgetMetaData: widgetMetaData
      };
      try {
        const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
        const resp = await WebService.post(fullURL, widgetPayload);
        if (resp.status >= 200 && resp.status < 300) {
          // setLayoutComponentUpdated(false);
          history.push({
            pathname: '/home/widget-instance',
            state: { isHome: true }
          });
        } else {
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('e', e, error);
        setToastError(error);
      }
    }
    // When Editing a Widget using configure of 3 dot menu and not just for preview purpose
    else if (!isPreviewInstance) {
      if (isEdit) {
        const { widgetComponentData, widgetMetaData } = widgetSpecificData();
        widgetPayload = {
          WidgetName: widgetName,
          WidgetComponentData: widgetComponentData,
          WidgetMetaData: widgetMetaData
        };
        // console.log(widgetComponentData);
        // console.log(layoutComponentData);
        updateWidgetPayload({ widgetPayload, WID });
        const formP = {
          ...layoutComponentData,
          [WID]: {
            ...layoutComponentData[WID],
            formData: widgetComponentData.formData
          }
        };

        setLayoutComponent({ formP });
        onSuccessNavigation();
      }
      // Adding a new Widget there is no pre fill
      else {
        widgetPayload = getWidgetDetailsPayload({
          WPID: activeTab.id,
          userId: userData.userId,
          widgetType,
          widgetTypesMenu,
          widgetName,
          widgetInfo: {},
          isShareable: shareWidget,
          ...widgetSpecificData(true) // different for didderent widgets
        });
        addWidget({ widgetPayload, history, onSuccessNavigation });
      }
    }
    // updating the template
    else {
      const Widget = {
        WID: Number.parseInt(WID, 10),
        WidgetName: widgetName,
        WidgetComponentData: {
          formData: {
            ...formInput
          },
          type: widgetType,
          widgetEditOptions,
          widgetCategory
        }
      };
      const res = await WebService.post(
        `${apiEndPoints.updateWidgetPanelTemplateInstance}/${WTIId}`,
        {
          WTId,
          WidgetTemplateInstanceName: templateInstanceName,
          UserId: userData.userId,
          WidgetComponentData: formInput,
          // WidgetPanelLayoutData: templateLayoutData,
          // WidgetPanelLayoutMetaData: templateLayoutMeta,
          WPID: layoutWPID,
          IsGlobal: false,
          Widgets: [Widget]
        }
      );
      if (res) {
        onSuccessNavigation();
      }
    }
  };

  const clearError = () => {
    addWidgetFailure('');
  };

  return (
    <>
      <UpsertSaveHeader
        header={
          widgetLabel
            ? `${isEdit ? 'Edit' : 'Add'} ${widgetLabel}`
            : activeTab.label
        }
        handleBack={handleBack}
        handleSave={handleSave}
        enableSave={widgetLabel && true}
      />

      <RespectiveWidgetComponent isEdit={isEdit} />
      {/* widget label form side panel */}
      <WidgetCommonCreationForm placeholder='Enter Widget name' />
      {error && (
        <Toast
          className='right'
          message={error}
          header='Error'
          fn={clearError}
        />
      )}
    </>
  );
};
export default UpsertWidgetLayout;
