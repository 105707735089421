/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  Tick,
  Title,
  Label,
  ValueAxis,
  Grid,
  Series,
  Point,
  Tooltip,
  ConstantLine,
  Crosshair,
  HorizontalLine,
  Font,
  Pane,
  AxisTitle
} from 'devextreme-react/chart';
import { colors, fontFamily, maxCharactersAloowed, placeHolderSize } from '../../../../constants';
import useViewLabels from '../hooks/useViewLabels';
import useContainerWidth from '../hooks/useContainerWidth';
import { useWindowSize } from '../../../../shared/hooks/useWindowResize';
import { Creators as kpvChartingCreators } from '../../Store/action';
import { chartConfig } from '../chartConfig';
import { getNearestTSPoint } from '../../utils/getNearestPoints';
import { getNearestTransitonPointByPosition } from '../../utils/getNearestPointByPosition';
import useMeasure from '../../../../shared/hooks/useMeasure';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { getSortedSeriesObjs } from '../utility';

const LabelChart = (props) => {
  const {
    // configData
    themeMode,

    // KPVCharts reducer
    xMinDomain,
    xMaxDomain,
    xMinDomainFullScreen,
    xMaxDomainFullScreen,
    paneFullScreen,
    compressedView,
    colorCodes,
    // hoveredXValue,
    panesShortHandInfo,
    comparisionMode,
    isAligned,

    // general
    setHoveredPosState,
    isLastPane = false,
    setHoveredState,
    hoveredState,
    paddingLeft,
    xPos,
    resize,
    fillLayout,
    ...paneData
  } = props;

  const {
    index,
    data,
    paneDataPoints,
    labelsData: labels,
    showDetails,
    multiAxis,
    multiGrid,
    scaleYaxis,
    scaleYAxisRange,
    color,
    pointInfoObj: pointData,
    xMinDomainProp,
    xMaxDomainProp,
    rawPlotData,
    compStartTime,
    StartTime,
    EndTime,
    id,
    annotations,
    paneIndex,
    showOverlays,

    // new
    seriesTypesSelections,
    xType
  } = paneData;

  const { data: plotData, meta } = rawPlotData || {};
  const [viewXLabels, setViewXLabels] = useState(true);
  // width calc (old method)
  // const showSideNav = useSelector((state) => state.home.showSideNav);
  // const [containerWidth, setContainerWidth] = useState(0);
  // const [width] = useWindowSize();
  // useContainerWidth(index, setContainerWidth, showSideNav, width, showDetails?.legends, paddingLeft, resize?.w);
  const [ref, { width: containerWidth }] = useMeasure();
  const dispatch = useDispatch();
  useViewLabels(setViewXLabels, compressedView);
  const filterFilesSelected = useSelector((state) => state.KPVCharts.filterFilesSelected);

  const [maxPlotChar, setMaxPlotChar] = useState(0);
  const panesData = useSelector((state) => state.KPVCharts.panesData);

  const chartStyles = {
    height: '100%',
    padding: '0px 0px 10px 10px',
    width: '100%',
    minHeight: '20rem',
    position: 'relative'
  };

  const chartRef = useRef();

  // const chart = chartRef.current?.instance;

  const getPointInfo = (pointInfo) => {
    const {
      rangeValue1,
      point: { vx, x, y }
    } = pointInfo;
    const data = getNearestTSPoint(plotData, rangeValue1);
    setHoveredState({
      data: pointInfo.point.data,
      x,
      // left: x + paddingLeft,
      orgX: x
    });
    if (compressedView) {
      // console.log('pointInfo', pointInfo);
      dispatch(
        kpvChartingCreators.setHoveredXValue({
          argument: rangeValue1,
          x: x + paddingLeft
        })
      );
    }
    return { text: '' };
    // return { text: JSON.stringify(l[l.length - 1]) };
  };

  //   useEffect(() => {
  //     const chart = chartRef.current?.instance;
  //     try {
  //       // get the nearest points by postion only in aligned condition
  //       if (xPos && isAligned) {
  //         const e = getNearestTransitonPointByPosition(chart, xPos, plotData);
  //         setHoveredPosState(e);
  //       }
  //     } catch (e) {
  //       console.log('error in finding nearest point based on position', e);
  //     }
  //   }, [xPos, isAligned]);

  const customizeSeries = (seriesName) => ({
    color: labels?.[seriesName]?.color
  });

  const getLabel = (val) => {
    if (val?.valueText?.length >= maxCharactersAloowed) {
      return `${val?.valueText?.substring(0, maxCharactersAloowed)}...`;
    }
    return val?.valueText;
  };

  const getYAxisLabel = () => (
    <Label
      // position={compressedView ? 'inside' : 'outside'}
      visible={showDetails ? showDetails['y-axis'] : true}
      font={{
        family: fontFamily.circularBook,
        size: chartConfig.labelFontSize,
        // TODO: incorrect theme handling
        color: colors[themeMode].contrast.primary
      }}
      // overlappingBehavior='rotate'
      // rotationAngle={270}
      displayMode='rotate'
      customizeHint={(e) => e.valueText}
      customizeText={(val) =>
        getLabel(val)}
      valueMarginsEnabled={false}
      adjustOnZoom={false}
      endOnTick={false}
    />
  );

  const setXaxisVisibility = () => {
    // find the label dom element at making it display none
    // instead of making devextreme handle the visibility
    const currentPaneNo = panesShortHandInfo.findIndex(
      (paneInfo) => paneInfo.id === id
    );
    // console.log('x-axis', viewXLabels, showDetails['x-axis'], id, currentPaneNo);
    // 'dxc-arg-elements' is the className for the x-labels
    const axisElements = document.getElementsByClassName('dxc-val-elements');
    const xAxisEle =
      axisElements.length > 1 ? axisElements[currentPaneNo] : axisElements[0];
    if (xAxisEle) {
      let visibleCondition = false;
      if (compressedView) {
        visibleCondition = viewXLabels || isLastPane;
      } else {
        visibleCondition = showDetails ? showDetails['x-axis'] : true;
      }

      if (!visibleCondition) {
        xAxisEle.style.visibility = 'hidden';
      } else {
        xAxisEle.style.visibility = 'visible';
      }
    }
  };

  const getExtremeValues = (kpv, isMin = true) =>
    meta[kpv].fields.Limit.reduce((prevVal, currVal) => {
      // for maximum
      if (!isMin) {
        if (
          prevVal === null ||
          parseInt(Object.keys(currVal)[0], 10) > parseInt(prevVal, 10)
        ) {
          return Object.keys(currVal)[0];
        }
        return prevVal;
      }
      // for minimum
      if (
        prevVal === null ||
        parseInt(Object.keys(currVal)[0], 10) < parseInt(prevVal, 10)
      ) {
        return Object.keys(currVal)[0];
      }
      return prevVal;
    }, null);

  const getValueAxisRange = (kpv, position = 0) => {
    if (
      scaleYaxis === 'manual' &&
      scaleYAxisRange.length &&
      scaleYAxisRange[0] !== undefined &&
      scaleYAxisRange[position]?.from !== '' &&
      scaleYAxisRange[position]?.to !== ''
    ) {
      // console.log('scaleYAxisRange', scaleYAxisRange, position, scaleYAxisRange[position]);
      return [scaleYAxisRange[position]?.from, scaleYAxisRange[position]?.to];
    }
    if (scaleYaxis === 'processWindow') {
      return [
        Math.min(meta[kpv].minValue, getExtremeValues(kpv, true)),
        Math.max(meta[kpv].maxValue, getExtremeValues(kpv, false))
      ];
    }
    return [null, null];
  };

  const isMounted = useRef();
  //   useEffect(() => {
  //     let timer;
  //     if (!isMounted.current) {
  //       isMounted.current = true;
  //       timer = setTimeout(() => {
  //         setXaxisVisibility();
  //       }, 1000);
  //     } else {
  //       setXaxisVisibility();
  //     }
  //     return () => {
  //       if (timer) {
  //         clearTimeout(timer);
  //       }
  //     };
  //   }, [showDetails['x-axis'], compressedView, viewXLabels, panesShortHandInfo]);

  const getArgumentAxisRange = () => {
    // if (comparisionMode && StartTime) {
    //   return [StartTime, EndTime];
    // }
    if (comparisionMode && StartTime) {
      if (!isEmpty(filterFilesSelected)) {
        return [xMinDomain, xMaxDomain];
      } return [StartTime, EndTime];
    }
    if (paneFullScreen) {
      return [xMinDomainFullScreen, xMaxDomainFullScreen];
    }
    return [xMinDomainProp || xMinDomain, xMaxDomainProp || xMaxDomain];
  };

  function onPointClick({ target: point }) {
    const { data } = point;
    dispatch(
      kpvChartingCreators.editPaneData({
        index,
        key: 'compStartTime',
        value: data.start
      })
    );
  }
  // const constantLineText = comparisionMode ? compStartTime : hoveredXValue;
  const constantLineText = comparisionMode ? compStartTime : '';
  const commonSeriesType = seriesTypesSelections?.common?.key || 'line';
  const xTypeKpv = xType[0];
  const argumentField = 'X' || xTypeKpv?.kpvName || 'lot';
  const orderedSeries = getSortedSeriesObjs(
    meta && Object.keys(meta),
    seriesTypesSelections
  );

  const findMaxLength = (plotData) => {
    let max = 0;
    for (let i = 0; i < plotData?.length; i += 1) {
      const currLength = (plotData[i]?.Y)?.toString()?.length;
      if (currLength + 1 > max) {
        max = currLength + 1;
      }
    }
    return max;
  };

  useEffect(() => {
    const maxArr = [];
    for (let i = 0; i < panesData?.length; i += 1) {
      maxArr.push(findMaxLength(panesData[i]?.rawPlotData?.data));
    }
    const max = Math.max(...maxArr);
    const maxAllocatedLen = max <= maxCharactersAloowed ? max : maxCharactersAloowed;
    setMaxPlotChar(maxAllocatedLen);
  }, [panesData]);

  return (
    <div style={chartStyles} ref={ref}>
      <Chart
        id={`chart${index}`}
        dataSource={plotData}
        // style={{ height: '100%' }}
        // style={chartStyles}
        style={{ height: '100%' }}
        size={{ width: containerWidth }}
        barGroupPadding={0.2}
        rotated={null}
        ref={chartRef}
        onPointClick={onPointClick}
      >
        {/* <Crosshair enabled={true} color='black' width={3} dashStyle='solid'>
          <HorizontalLine visible={false} />
          <Label visible={true} backgroundColor='black'>
            <Font color='#fff' size={12} />
          </Label>
        </Crosshair> */}
        <ArgumentAxis
          // eslint-disable-next-line react/jsx-props-no-multi-spaces
          // visualRange={getArgumentAxisRange()}
          valueMarginsEnabled={false}
          endOnTick={false}
          color={colors[themeMode].contrast.lightSecondary}
        >
          <AxisTitle
            text={xTypeKpv?.kpvName}
            font={{
              family: fontFamily.circularBook,
              size: 'medium',
              color: colors[themeMode].contrast.primary,
              wordWrap: 'breakWord'
            }}
          />
          {/* comparison mode start line */}
          {/* {comparisionMode && isAligned && compStartTime && (
            <ConstantLine
              width={1}
              value={compStartTime}
              color={colors[themeMode].contrast.lightSecondary}
              dashStyle='solid'
            >
              <Label visible={false} />
            </ConstantLine>
          )} */}
          {/* annotations lines */}
          {/* {showDetails?.annotations &&
            !isEmpty(annotations) &&
            annotations.map((annotPoint) => (
              <ConstantLine
                width={2}
                value={annotPoint.argument}
                color={colors[themeMode].contrast.lightSecondary}
                dashStyle='dot'
                // displayBehindSeries={true}
              >
                <Label visible={false} />
              </ConstantLine>
            ))} */}
          <Grid
            visible={true}
            width={1}
            color={colors[themeMode].contrast.lightSecondary}
          />
          <Label
            // visible={showDetails ? (showDetails['x-axis'] && viewXLabels) || isLastPane : true}
            // visible={showDetails ? showDetails['x-axis'] : true}
            font={{
              family: fontFamily.circularBook,
              size: fillLayout ? 10 : 15,
              color: colors[themeMode].contrast.primary,
              wordWrap: 'breakWord'
            }}
            alignment='center'
            // customizeText={(tick) => moment(tick.value).format('DD/MM/YYYY HH:mm:ss:sss')}
          />
        </ArgumentAxis>
        <Legend
          visible={false}
          customizeText={(item) => labels[item.seriesName]}
          font={{ size: 60 }}
        />
        <CommonSeriesSettings>
          <Tick visible={true} />
          <Grid visible={true} width={1} />
          {/* <Point size={0} hoverMode='onlyPoint' hoverStyle={{ size: 15 }} /> */}
        </CommonSeriesSettings>
        <Tooltip enabled={true} customizeTooltip={getPointInfo} />

        {/* value axis */}
        {/* multi axis, multiple grid */}
        {multiAxis &&
          multiGrid &&
          !isEmpty(meta) &&
          Object.keys(meta).map((kpv) => <Pane name={kpv} key={kpv} />)}
        {/* multi axis */}
        {multiAxis &&
          !isEmpty(meta) &&
          Object.keys(meta).map((kpv, index) => (
            <ValueAxis
              key={kpv}
              name={`${kpv}-value`}
              pane={multiGrid ? kpv : undefined}
              color={colorCodes[index]}
              multipleAxesSpacing={chartConfig.multipleAxesSpacing}
              // autoBreaksEnabled={true}
              valueMarginsEnabled={false}
              endOnTick={false}
              visualRange={getValueAxisRange(kpv, index)}
              placeholderSize={placeHolderSize * maxPlotChar}
            >
              <AxisTitle
                text={meta[kpv]?.fields?.displayname}
                font={{
                  family: fontFamily.circularBook,
                  size: 'large',
                  color: colors[themeMode].contrast.primary,
                  wordWrap: 'breakWord'
                }}
              />
              {getYAxisLabel()}
              {/* {meta && multiGrid && showOverlays?.limits !== false && lowermostBand(kpv)}
              {meta && multiGrid && showOverlays?.limits !== false && stripsItem(kpv)} */}
            </ValueAxis>
          ))}
        {/* single axis */}
        {!multiAxis && (
          <ValueAxis
            allowDecimals={true}
            // autoBreaksEnabled={true}
            valueMarginsEnabled={false}
            endOnTick={false}
            visualRange={meta && getValueAxisRange(Object.keys(meta)[0])}
            color={colors[themeMode].contrast.lightSecondary}
            placeholderSize={placeHolderSize * maxPlotChar}

          >
            <Grid
              visible={true}
              width={1}
              color={colors[themeMode].contrast.lightSecondary}
            />
            {getYAxisLabel()}
            {/* {meta && Object.keys(meta).length === 1 && showOverlays?.limits !== false && lowermostStripsArr}
            {meta && Object.keys(meta).length === 1 && showOverlays?.limits !== false && stripsArr} */}
          </ValueAxis>
        )}
        {/* series */}
        {orderedSeries.map((kpv, index) => (
          <Series
            type={seriesTypesSelections?.[kpv]?.key || commonSeriesType}
            key={kpv}
            // axis name is used to bind the value axis, by default it is binded to first axis
            axis={multiAxis ? `${kpv}-value` : undefined}
            pane={multiAxis && multiGrid ? kpv : undefined}
            // valueField={`${kpv}-${argumentField}`}
            valueField={`${kpv}`}
            argumentField={argumentField}
            name={`${kpv}-value`}
            // axis={!multiAxis ? null : key}
            // color={color || colorCodes[index]}
            color={colorCodes[index]}
            ignoreEmptyPoints={false}
            barWidth='10'
          >
            <Point size={8} hoverMode='onlyPoint' />
          </Series>
        ))}
        <Animation enabled={false} />
      </Chart>
    </div>
  );
};

const mapStateToProps = (state) => ({
  xMinDomain: state.KPVCharts.xMinDomain,
  xMaxDomain: state.KPVCharts.xMaxDomain,
  xMinDomainFullScreen: state.KPVCharts.xMinDomainFullScreen,
  xMaxDomainFullScreen: state.KPVCharts.xMaxDomainFullScreen,
  paneFullScreen: state.KPVCharts.paneFullScreen,
  compressedView: state.KPVCharts.compressedView,
  colorCodes: state.KPVCharts.colorCodes,
  // hoveredXValue: state.KPVCharts.hoveredXValue,
  panesShortHandInfo: state.KPVCharts.panesShortHandInfo,
  comparisionMode: state.KPVCharts.comparisionMode,
  isAligned: state.KPVCharts.isAligned,
  themeMode: state.configData.themeMode
});

export default connect(mapStateToProps)(LabelChart);
