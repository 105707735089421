/* eslint-disable indent */
/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';
import { LegendContainer } from '../Legend/Legend.style';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.aux.secondary};
  width: 100%;
  position: relative;
  /* z-index: 1; */
  &:first-child {
    margin-top: 1.5rem;
  }
  &:not(:last-child) {
    /* padding: 2rem; */
  }
  &.normalView {
    /* margin: 0px auto; */
    /* box-shadow: 0px 7px 10px #00000029; */
    box-shadow: 0px 0px 15px #00000029;
    border-radius: 10px;
    margin-top: 1.5rem;
    padding: 0rem;
    &.fullscreen {
      min-height: 85%;
      overflow-y: scroll;
    }
  }
  &.fullscreen {
    flex: 1;
  }

  /* &:hover {
    z-index: 20;
  } */
`;

export const ComparisionOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  height: 5rem;
  padding: 0.5rem;
  width: 100%;
  margin-top: 1rem;
  /* background-color: red; */
  /* z-index: 1; */
  /* height: 90%; */
`;

export const Graph = styled.div`
  width: calc(${({ width }) => width});
  height: ${({ height }) => height || 20}rem;
  min-height: 15rem;
  /* padding-left: 100px; */
  /* flex: 1; */
  /* background-color: green; */
  /* margin-left: 10px; */
  overflow-x: hidden;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* &::-webkit-scrollbar {
    display: none;
  } */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  /* flex: 1; */

  padding-left: ${({ paddingLeft }) => (
    paddingLeft ? `${paddingLeft}px` : '0px')};
  /* padding-left: 100px; */
  &.fullscreen {
    height: 90%;
  }
  &.autoHeight {
    height: auto;
    min-height: auto;
  }
`;

export const WrapperConatiner = styled.div`
  flex-direction: ${({ position }) => (
    position === 'right' ? 'row' : 'column')};
  height: ${({ fillLayout }) => (fillLayout ? '100%' : 'auto')};
  & ${Graph} {
    height: ${({ position, height, isWidget, fillLayout }) => {
    if (fillLayout) {
      return '100%';
    }
    return position === 'right' || isWidget ? height || '25rem' : '60vh';
  }};

    width: ${({ position }) =>
    (position !== 'right' || position !== undefined) && '100%'};
    min-height: ${({ multiGrid }) => (multiGrid ? '40rem' : '25rem')};
  
    &.autoHeight {
      height: auto;
      min-height: auto;
    }
  }
  & ${LegendContainer} * {
    font-size: 1.5rem;
  }
`;

export const Header = styled.div`
  font-size: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.contrast.primary};
  padding: 1.5rem 0px;
  text-transform: capitalize;
  font-family: ${fontFamily.circularBold};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitle = styled.div`
  font-size: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.contrast.primary};
  padding: 1.5rem 0px;
  text-transform: capitalize;
  font-family: ${fontFamily.circularBold};
  flex: 0 1 70%;
`;

export const FilterContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: #EEEFF5 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 15px #FFFFFFCB;
  border: 1px solid #B4B4B4;
  border-radius: 1rem;
  opacity: 1;
  margin-right: 1.7rem;
  width: 4.4rem;
  height: 4.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* &:hover {
    transform: scale(1.1);
  } */
  &.disabled{
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    &:hover {
    transform:none;
  }
  }
`;

export const FileNameInfo = styled.div`
  width: 34rem;
  height:auto ;
  background: #EEEFF5 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #FFFFFFF5;
  border-radius: 10px;
 position: absolute;
  top: 7rem;
  right: 1rem;
  padding: 1rem;
`;

export const InfoHeader = styled.div`
color: #474747;
font-size: 1.4rem;
font-family: ${fontFamily.circularMedium};
margin-bottom: 1rem;
`;

export const FileNameDiv = styled.div`
color: #161616;
font-size: 1.5rem;
font-family: ${fontFamily.circularMedium};
margin-bottom: 1rem;
word-break: break-all;
margin-left: 5px;
`;

export const tickLabels = {
  stroke: '#393939',
  fontSize: '3rem',
  fontFamily: `${fontFamily.circularBook}`
};

export const GraphContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  &.fullscreen {
    position: absolute;
    height: calc(100% - 5rem);
    flex-direction: column;
    flex: 1;
    & > div {
      height: 100%;
    }
  }
  &.singlePane {
    margin-bottom: 2rem;
  }
`;

export const AddAnnotationMenu = styled.div`
  position: absolute;
  right: ${({ width }) => width};
  padding: 1.5rem 0rem;
  top: 0px;
  font-size: 2rem;
  display: flex;
`;

export const DropDownBox = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.specificDetails.primary};
`;

export const DropDownButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.core.secondary};
  display: flex;
  align-items: center;
  border-radius: 8px;
  z-index: 3;
  width: 100px;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  /* left: 0px; */
  top: ${(props) => props.top || '3.5rem'};
  padding: 1.5rem;
  z-index: 11;
  background-color: ${({ theme }) => theme.core.secondary};
  width: 21rem;
  border-radius: 0.5rem;
`;

export const BtnText = styled.div`
font-family: ${fontFamily.circularMedium};
  cursor: pointer;
  font-size: ${fontSize.medium};
  color: ${({ theme }) => theme.contrast.primary};

`;

export const FilterDropContainer = styled.div`
display: flex;
gap: 15rem;
width: 30px;
`;

export const CheckBoxContainer = styled.div`
display: flex;
align-items: center;
`;
