import styled from 'styled-components';
import { fontFamily } from '../../../constants';

export const Checkbox = styled.input``;
export const CancelIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  &.cancel_item {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
`;
export const DropdownContainer = styled.div`
  position: relative;
  right: 65rem;
  top: 2.5rem;
`;
export const Label = styled.text`
  font-size: 1.8rem;
`;
export const ModalHeader = styled.text`
  font: normal normal normal 2rem Circular Std Bold;
  color: #474747;
`;
export const HeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50rem;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 28rem;
`;
export const DetailListContainer = styled.div`
display: flex;
width:100%;
margin-bottom:1rem;
gap:1rem;
background: #FFFFFF 0% 0% no-repeat padding-box;
 box-shadow: -5px -5px 11px #FFFFFFF5;
 border-radius: 10px;
 font-size:1.5rem;
height:4rem;
// justify-content:center;
 align-items:center;

}
`;
export const SelectedList = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #757575;
  border-radius: 17px;
  background: #f9f9f9;
  padding: 8px;
`;
export const ItemText = styled.div`
  font: 1.8rem ${fontFamily.circularMedium};
  padding: 5px;
  padding-right: 0px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 3rem;
  bottom: 2rem;
  right: 3rem;
`;

export const Item = styled.p`
  font: 1.3rem ${fontFamily.circularBook};
  margin-right: 8px;
  white-space: nowrap;
`;

export const Container = styled.div`
  &.allowHorizontal .text-label {
    width: 15rem;
    overflow: visible;
    white-space: normal;
  }
`;
