import React from 'react';
import { SVGList, StatsContainer, StatsText, SvgContainer } from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.style';
import SvgStatusCard from './SvgStatusCard.View';

const Tank = ({ progress, colors, dimension, numSvg, componentData = {} }) => {
  const progressHeight = 328 * (progress / 100);

  const { formData } = componentData || {};
  const svgList = [];
  for (let i = 0; i < numSvg; i += 1) {
    svgList.push(
      <SvgContainer>
        <StatsContainer>
          {formData && (
          <SvgStatusCard
            machineStatus={formData?.machineState?.label}
            speed={formData?.tankLevel?.label}
            label="Level"
          />
          )}
          {/* <StatsText>
            {' '}
            Tank :
            {' '}
            {i + 1}

          </StatsText>
          <StatsText>
            {' '}
            Machine Status :
            {' '}
            {formData?.machineState?.label}

          </StatsText>
          <StatsText>
            {' '}
            Tank Level :
            {' '}
            {formData?.tankLevel?.label}

          </StatsText> */}

        </StatsContainer>
        <svg
          id='a'
          width='100%'
          height='100%'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          viewBox='0 0 351.51 453.98'
        >
          <defs>
            <style>
              {`  .l {
          fill: ${colors.class_0};
        }`}

              {`  .m {
          fill:  ${colors.class_1};
        }
  `}
              {`  .n {
          font-size: 24px;
        }
  `}
              {` .n, .o, .p {
          isolation: isolate;
        }`}

              {`.n, .p {
          fill:  ${colors.class_2};
          font-family: Montserrat-Bold, Montserrat;
          font-weight: 700;
        }`}

              {`.q {
          clip-path: url(#d);
        }`}

              {`  .q, .o, .r {
          fill: none;
        }
  `}
              {`  .s {
          fill:  ${colors.class_3};
        }
  `}
              {` .p {
          font-size: 64px;
        }
  `}
              {` .t {
          fill: ${colors.class_4};;
        }`}
            </style>
            <clipPath id='d'>
              <path
                className='r'
                d='m311.48,375.91H40.02v-175.76h-12.22v-38.1h15.1c6.66-58.74,51.25-106.06,109.49-116.2v-11.5h46.96v11.5c58.14,10.25,102.61,57.54,109.25,116.2h15.1v38.1h-12.22v175.76h0Z'
              />
            </clipPath>
          </defs>
          <g id='b' data-name='Group 24'>
            <path
              id='c'
              data-name='Path 68045'
              className='m'
              d='m323.7,162.06v38.1h-12.22v176.1h-23.72v27.55h-28.75v15.81h-52.71v-15.81h-61.82v15.81h-52.71v-15.81h-28.03v-27.56h-23.71v-176.1h-12.22v-38.1h15.1c6.68-58.73,51.26-106.04,109.49-116.2v-11.5h46.96v11.5c58.13,10.26,102.59,57.55,109.25,116.2h15.1Z'
            />
            <g className='q'>
              <g id='e' data-name='Mask Group 2'>
                <g id='f' data-name='Group 23'>
                  <path
                    id='g'
                    data-name='Path 68204'
                    className='s'
                  // eslint-disable-next-line max-len
                    d={`m36.03,355.47v20.43h279.45v-${progressHeight}c-42.5,11.26-87.2,11.26-129.71,0-34.15-7.59-69.04-11.36-104.03-11.24-15.1-.1-30.19.65-45.21,2.25l-.5-.1Z`}
                  >
                    <animateTransform
                      attributeName='transform'
                      attributeType='XML'
                      type='translate'
                      values='0 0;0 -6;0 0'
                      dur='2s'
                      repeatCount='indefinite'
                    />
                  </path>
                  <path
                    id='h'
                    data-name='Path 68204-2'
                    className='l'
                  // eslint-disable-next-line max-len
                    d={`m315.48,351.47v20.43H36.03v-${progressHeight}c42.5,11.26,87.2,11.26,129.71,0,34.15-7.59,69.04-11.36,104.03-11.24,15.1-.1,30.19.65,45.21,2.25l.5-.1Z`}
                  >
                    <animateTransform
                      attributeName='transform'
                      attributeType='XML'
                      type='translate'
                      values='0 0;0 6;0 0'
                      dur='4s'
                      repeatCount='indefinite'
                    />
                  </path>
                </g>
              </g>
            </g>
            <path
              id='i'
              data-name='Union 27'
              className='t'
              d='m201.51,424.41v-15.81h-52.23v15.81h-62.3v-15.81h-28.03v-27.56h-23.72v-176.1h-12.23v-47.68h15.68c8.38-57.56,51.93-103.71,108.92-115.39v-12.31h56.54v12.34c56.89,11.76,100.33,57.88,108.68,115.36h15.68v47.68h-12.23v176.1h-23.73v27.56h-28.74v15.81h-62.3Zm81.47-52.85v-.09h23.72v-172.39h-.2s-2.44-34.29-2.44-34.29h.03l-.24-2.17c-6.66-56.44-49.4-101.92-105.33-112.05l-3.83-.67h-37.63l-3.84.67c-56.02,10.02-98.89,55.52-105.56,112.04l-.47,4.25h-.2l-2.17,30.43v174.18h23.72v.09h214.45Z'
            />
            <g id='j' data-name=' 10' className='o'>
              <text className='p' transform='translate(130.46 244.41)'>
                <tspan
                  x='0'
                  y='0'
                  style={{
                    fontSize: '46px',
                    fontFamily: 'Montserrat-Bold',
                    fontWeight: 700
                  }}
                >
                  {progress}
                </tspan>
              </text>
            </g>
            <g id='k' data-name=' ' className='o'>
              <text className='n' transform='translate(201.01 244.41)'>
                <tspan
                  x='0'
                  y='0'
                  style={{
                    fontSize: '24px',
                    fontFamily: 'Montserrat-Bold',
                    fontWeight: 700
                  }}
                >
                  %
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      </SvgContainer>
    );
  }
  const width = `${dimension.width}px`;
  const height = `${dimension.height}px`;
  return (<SVGList style={{ width, height }}>{svgList}</SVGList>);
};

export default Tank;
