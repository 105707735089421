/* eslint-disable max-len */
import React from 'react';
import { CHART_TYPE_CATEGORY } from '../../../constants';
import { Item, ItemSubMenu, LabelWrapper } from '../../KPVCharts/EditOptions/EditOptions.style';
import { CheckBox } from '../UpsertPane.style';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { FlexContainer, IconContainer, Span } from '../../../globalStyles';
import { ReactComponent as Diamond } from '../../../assets/icons/KPV/diamond.svg';

const ShowAlarmsView = ({
  chartTypeCategory,
  meta,
  showOverlays = {},
  showAlarms = {},
  handleSelect = () => {},
  isLimitsEnabled,
  isLegendView
}) => {
  if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
    const check =
      !isEmpty(meta) &&
      Object.entries(meta).reduce((acc, kpvData) => {
        const [key1, value] = kpvData;
        return {
          alarmOverlays: {
            ...(acc.alarmOverlays || {}),
            ...Object.entries(value.alarmOverlays)?.reduce((prev, e) => {
              const [key, value] = e;
              return { ...prev, [key]: { data: [value], kpv: key1 } };
            }, {})
          }
          // menuOverlays: {
          //   ...(acc.menuOverlays || {}),
          //   ...Object.entries(value.menuOverlays?.[0])?.reduce((prev, e) => {
          //     const [key, value] = e;
          //     return { ...prev, [key]: { data: value, kpv: key1 } };
          //   }, {})
          // }
        };
      }, {});
    return (
      <>
        {check && !isEmpty(check.alarmOverlays) ? (
          Object.entries(check.alarmOverlays).map((e) => {
            const [key, value] = e;
            const dataNodes = Object.values(meta)[0]?.data;
            const isDataPresentForOverlay = value?.data?.some((nodeItem) => !isEmpty(dataNodes[nodeItem.node]));
            return (
              <Item
                hideItem={isLegendView && (!isDataPresentForOverlay || !showAlarms?.[key])}
                className={`subItem ${!isDataPresentForOverlay ? 'disable' : ''}`}
                onClick={() => handleSelect({ key, value })}
              >
                <FlexContainer style={{ width: '100%' }} className='jc_spacebetween ai_center'>
                  <LabelWrapper>
                    {!isLegendView && (
                      <CheckBox className={showAlarms?.[key] && isDataPresentForOverlay ? 'active' : ''}>
                        <CheckMark className='' />
                      </CheckBox>
                    )}
                    <span>{key}</span>
                  </LabelWrapper>
                  <IconContainer color={value?.data?.[0]?.color}>
                    <Diamond />
                  </IconContainer>
                </FlexContainer>
              </Item>
            );
          })
        ) : (
          <Span className='padding'>No alarms Available</Span>
        )}
      </>
    );
  }
  return (
    <Item className='subItem' onClick={() => isLimitsEnabled && handleSelect({})}>
      <LabelWrapper className={isLimitsEnabled ? '' : 'disable'}>
        <CheckBox className={showOverlays?.limits === false ? '' : 'active'}>
          <CheckMark className='' />
        </CheckBox>
        <span>Limits</span>
      </LabelWrapper>
    </Item>
  );
};

export default ShowAlarmsView;
