/* eslint-disable no-nested-ternary */
import React from 'react';
import { Status, StatusCard, StatusTitle, SvgStatusContainer } from './SvgStatusCard.style';

const SvgStatusCard = ({ machineStatus, speed, label = 'Speed' }) => (
  <SvgStatusContainer>
    <StatusCard>
      <Status backGround={machineStatus === 'Running' ? '#1AFF00' : machineStatus === 'Alarm' ? '#F7FF00' : '#FF0000'}>{machineStatus}</Status>
      <StatusTitle>Run Status</StatusTitle>
    </StatusCard>
    <StatusCard>
      <Status backGround={speed === 'Fast' ? '#1AFF00' : machineStatus === 'Slow' ? '#F7FF00' : '#FF0000'}>{speed}</Status>
      <StatusTitle>{label}</StatusTitle>
    </StatusCard>
  </SvgStatusContainer>
);

export default SvgStatusCard;
