/* eslint-disable no-useless-escape */
/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isEmpty } from '../../shared/utility/isEmpty';
import {
  HeaderText
  // ButtonContainer,
  // Button
} from '../../views/Users/Users.style';
import UsersHeader from '../../views/Users/UsersHeader';
import { INPUT_TYPES, fontSize } from '../../constants';
import { userformStructure as localForm } from './data';
import { BodyContainer } from './UserDetails.style';
import GenericForm from '../Common/GenericForm/GenericForm';
import { getFieldDependents } from '../UpsertWidget/WidgetForm/Utility';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import Loader from '../Common/Loader/Loader';
import { useActions } from '../../store/hooks/useActions';
import { convertFormInputObjToSimple, validateFields } from '../Common/GenericForm/utils';
import { VALIDATION_TYPES, validate } from '../../shared/utility/regex';

const UserDetailsForm = (props) => {
  const locations = useLocation();
  const history = useHistory();
  const { state } = locations;
  const [formInput, setFormInput] = useState({});
  const [userformStructure, setUserformStructure] = useState([]);
  const { setToastError } = useActions();
  const { isFormEditable, formInput: formInputProp, areFormInputsChanged, formApi, saveForm } = state;
  const isEdit = saveForm === apiEndPoints.updateUserDetails;
  const userDetailsFormRef = useRef();
  // setting up the form details from props
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    WebService.get(formApi)
      .then((res) => {
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          // local data
          // setUserformStructure(localForm);
          setUserformStructure(data);
        } else {
          throw res.data;
        }
      })
      .catch((e) => {
        const { error } = sagaCatchBlockHandling(e);
        setToastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // if (!isEmpty(formInputProp)) {
    // console.log('setting details to form', formInputProp);
    setFormInput(formInputProp);
    // }
  }, [formInputProp]);

  // header handlers
  const handleUserEdit = () => {
    history.push({
      pathname: '/home/user/edit',
      state: {
        isFormEditable: true,
        formInput,
        formApi: apiEndPoints.getUserUpdateForm,
        saveForm: apiEndPoints.updateUserDetails
      }
    });
  };
  const handleDisableUser = () => {
    // console.log('handleDisableUser');
  };

  const handleSave = () => {
    const localFormInput = userDetailsFormRef.current.getFormInput();
    try {
      const isPassed = validateFields(userformStructure, localFormInput);
      if (!isPassed) {
        throw 'Validation error has occurred';
      }
      // console.log('localFormInput', localFormInput);
      const formatedFormInput = convertFormInputObjToSimple(localFormInput);
      const requiredInput = userformStructure.reduce((acc, e) => {
        const { key, inputType, defaultValue } = e;
        let val = formatedFormInput[key] || defaultValue;
        if (inputType === INPUT_TYPES.TOGGLE) {
          val = val || false;
        }
        if (!key) return acc;
        return { ...acc, [key]: val };
      }, {});
      WebService.post(saveForm, requiredInput)
        .then((res) => {
          const { data, status } = res;
          if (status >= 200 && status < 300) {
            setFormInput(localFormInput);
            history.push({
              pathname: '/home/user/details',
              state: { isFormEditable: false, formInput: localFormInput, areFormInputsChanged: true, formApi, saveForm }
            });
          } else {
            throw res;
          }
        })
        .catch((e) => {
          const { error } = sagaCatchBlockHandling(e);
          console.log('error===', error);
          setToastError(error);
        });
    } catch (e) {
      console.log('error--', e);
      setToastError(e);
    }
  };
  const handleCancel = () => {
    if (isEdit) {
      history.push({
        pathname: '/home/user/details',
        state: { isFormEditable: false, formInput: formInputProp, formApi, saveForm }
      });
    } else {
      history.push({ pathname: '/home/users', state: { record: areFormInputsChanged ? formInput : {} } });
    }
  };
  const handleBack = () => {
    // if in details page go back to users
    if (!isFormEditable) {
      history.push({ pathname: '/home/users', state: { record: areFormInputsChanged ? formInput : {} } });
    } else {
      history.goBack();
    }
  };
  const [isFormFilled, setIsFormFilled] = useState(false);
  return (
    <>
      <UsersHeader
        title={!isFormEditable ? 'User details' : `${isEdit ? 'Edit' : 'Add'} User Details`}
        isFormEditable={isFormEditable}
        handleUserEdit={handleUserEdit}
        handleDisableUser={handleDisableUser}
        handleSave={handleSave}
        isSaveEnabled={isFormFilled}
        handleCancel={handleCancel}
        handleBack={handleBack}
      />
      <BodyContainer>
        {loading ? (
          <Loader />
        ) : (
          <GenericForm
            isFormEditable={isFormEditable}
            formStructure={userformStructure}
            ref={userDetailsFormRef}
            formInput={formInput}
            setFormInput={setFormInput}
            showHeader
            setIsFormFilled={setIsFormFilled}
            formStructureFieldDepenents={getFieldDependents(userformStructure)}
            allowHorizontal={false}
          />
        )}
      </BodyContainer>
      {/* {isModalVisible && (
        <PopUpModal closeModalHandler={cancelRejection}>
          <FlexContainer className='jc_spacearound' style={{ minHeight: '15rem', flexDirection: 'column' }}>
            <ModalContentText style={{ color: 'black', marginBottom: '2rem', textAlign: 'center' }}>
              You may have changes. Do you want cancel ?
            </ModalContentText>
            <ButtonContainer>
              <FlexContainer className='jc_spacearound' style={{ width: '100%' }}>
                <ButtonComp onClick={cancelRejection}>No</ButtonComp>
                <ButtonComp dark onClick={cancelCormation}>
                  Yes
                </ButtonComp>
              </FlexContainer>
            </ButtonContainer>
          </FlexContainer>
        </PopUpModal>
      )} */}
    </>
  );
};

export default UserDetailsForm;

export const RequiredHeaderContainet = styled.div`
  position: relative;
  bottom: 2rem;
  font-size: ${fontSize.large};
`;
