/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColOptions,
  ColumnText,
  DropdownIcon,
  ColDropdownContainer,
  ViewColoumnsContainer,
  ColoumnListText,
  CheckBoxIcon,
  ColItemContainer
} from '../KPVCustomComponent/KPVCustom.style';
import { Creators as FilterResultCreators } from '../Store/index';
import { DropDownArrow } from '../../../assets/icons';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';

const ColItem = ({ name, label, type }) => {
  const {
    resultDetails: kpvData,
    activeTab,
    metaDetails,
    metaDetailsInShortlist
  } = useSelector((state) => state.filterResultData);
  const meta = type === 'general' ? metaDetails : metaDetailsInShortlist;
  const feildIndex = meta?.fields?.findIndex((e) => e.key === name);

  // const [colVisible, setColVisible] = useState(setCheckbox);
  const [colVisible, setColVisible] = useState(meta?.fields[feildIndex]?.visible);

  const dispatch = useDispatch();

  const handleColToggle = () => {
    // checking if column is present or not.
    const tempObj = [...meta?.fields];
    setColVisible(!colVisible);
    tempObj[feildIndex].visible = !colVisible;
    dispatch(FilterResultCreators.setMetaDetails({ type, metaDetails: { ...meta, fields: tempObj } }));
  };
  return (
    <ColItemContainer onClick={handleColToggle}>
      <CheckBoxIcon>{colVisible && <CheckMark style={{ padding: '15%' }} />}</CheckBoxIcon>
      <ColoumnListText>{label}</ColoumnListText>
    </ColItemContainer>
  );
};

const ColumnFilter = ({ type }) => {
  const [showColDropdown, setShowColDropDown] = useState(false);
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const {
    resultDetails: kpvData,
    activeTab,
    metaDetails,
    metaDetailsInShortlist
  } = useSelector((state) => state.filterResultData);

  const colFilterRef = useRef();
  // console.log('metaDetails', activeTab);
  const handleColDropDownClose = () => {
    if (showColDropdown) setShowColDropDown(false);
  };

  useClickOutside(colFilterRef, handleColDropDownClose);
  const meta = type === 'general' ? metaDetails : metaDetailsInShortlist;

  return (
    <ViewColoumnsContainer ref={colFilterRef}>
      <ColOptions
        onClick={() => {
          setShowColDropDown(!showColDropdown);
        }}
      >
        <ColumnText>{localeStrings.viewColumns || 'View columns'}</ColumnText>
        <DropdownIcon className={showColDropdown ? 'active' : ''}>
          <DynamicSVGIcon iconUrl={DropDownArrow} />
        </DropdownIcon>
      </ColOptions>
      {showColDropdown && (
        <ColDropdownContainer>
          {meta?.fields?.map(
            (col, index) =>
              meta?.collapsible?.includes(col.key) && (
                <ColItem key={index} type={type} label={col.title} name={col.key} />
              )
          )}
        </ColDropdownContainer>
      )}
    </ViewColoumnsContainer>
  );
};

export default ColumnFilter;
