/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { useActions } from '../../../store/hooks/useActions';
import TreeContainer from '../WidgetFormTreeContainer/TreeContainer';
import useFilteredSelectedItem from '../hooks/useFilteredSelectedItem';
import Loader from '../../Loader/Loader';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../utility/populateFilterDashboard';

const BtnWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: flex-end;
  &.bottom {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const TreeInputType = ({
  item,
  // equipments, setEquipments,
  setShowModal,
  showModal,
  saveHandler,
  loading,
  hasPreSelection,
  isPreSelection,
  value,
  disabledFields,
  multiValueSelectionHandler,
  formInput,
  formStructure,
  isFormIntialized,
  tobeRefetched,
  ignoreActiveOption,
  isInstanceCreation
}) => {
  const { label, key, dataSource } = item;
  const objectTreeData = useSelector(
    (state) => state.objectTreeData.objectTreeData
  );
  const { setBulkObjectTreeDetails } = useActions();

  // commented the useEffect to prevent resetting cummulative tags on multi select
  // useEffect(() => {
  //   // setting the selections saved previously
  //   if (!isEmpty(objectTreeData) && value?.[0]?.value) {
  //     // changing to response format
  //     const filterTree = objectTreeData.reduce((acc, cur) => {
  //       const { keyName } = cur;
  //       return {
  //         ...acc,
  //         [keyName]: [cur]
  //       };
  //     }, {});
  //     console.log('cumTags', filterTree);
  //     const payload = { data: value?.[0]?.value }; // payload saved prev
  //     // console.log('value?.[0]?.value', value, payload, filterTree);
  //     const { cumTags, cumSelectedObj } = populateFilterDashboard(
  //       payload,
  //       filterTree
  //     );
  //     // console.log('cumTags', cumTags, cumSelectedObj);
  //     const payload1 = getFilterSelectionInp(cumTags, cumSelectedObj);
  //     setBulkObjectTreeDetails({ ...payload1 }); // populating all the selection
  //   }
  // }, [objectTreeData, value]);

  const { tags, selectedItem, cumulativeTags, keysLatestUpdated } = useSelector(
    (state) => state.objectTreeData
  );
  const isCompletlySelected = !useFilteredSelectedItem(item?.key);
  // checking all the last elements present in the filter tree
  const lastElements = selectedItem?.map((item) => {
    try {
      const entries = Object.entries(tags?.[item]);
      const lastIndex = entries?.length;
      // console.log('lastIndex', lastIndex, entries);
      // return 'aa';
      // console.log('aa', lastIndex, tags, item, tags[item]);
      return entries?.[lastIndex - 1]?.[1]?.id;
    } catch (e) {
      console.log('filter population issue', e);
    }
    return '';
  });
  // const lastElements = [];
  // selectedItem?.forEach((item) => {
  //   try {
  //     const entries = Object.entries(tags?.[item]);
  //     const lastIndex = entries?.length;
  //     // console.log('lastIndex', lastIndex, entries);
  //     // return 'aa';
  //     // console.log('aa', lastIndex, tags, item, tags[item]);
  //     return entries?.[lastIndex - 1]?.[1]?.id;
  //   } catch (e) {
  //     console.log('filter population issue', e);
  //   }
  //   return '';
  // });
  // console.log('lastElements', lastElements);
  useEffect(() => {
    // set the item if the complete path and change is selected
    let checkingText = '';
    if (typeof keysLatestUpdated === 'string') {
      checkingText = keysLatestUpdated;
    } else if (
      typeof keysLatestUpdated === 'object' &&
      keysLatestUpdated?.length > 0
    ) {
      checkingText = keysLatestUpdated[keysLatestUpdated.length - 1];
    }
    if (checkingText === undefined) {
      checkingText = '';
    }
    // added keysLatestUpdated === 'breakpoint' to solve the issue tree not saving in case where tree is locked to end already. BugId=7030
    if (
      (isCompletlySelected &&
      checkingText?.replace('cumulative', '') === item.attribute) || keysLatestUpdated === 'breakpoint'
    ) {
      saveHandler();
    }
  }, [lastElements.length, isCompletlySelected, cumulativeTags, keysLatestUpdated]);

  // useEffect(() => {
  //   // set the item if the complete path and change is selected
  //   if (isCompletlySelected && keysLatestUpdated === item.attribute) {
  //     saveHandler();
  //   }
  // }, [...lastElements, isCompletlySelected, cumulativeTags]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TreeContainer
          disabledFields={disabledFields}
          value={value}
          showModal={showModal}
          setShowModal={setShowModal}
          hasPreSelection={hasPreSelection}
          isPreSelection={isPreSelection}
          item={item}
          multiValueSelectionHandler={multiValueSelectionHandler}
          formInput={formInput}
          formStructure={formStructure}
          tobeRefetched={tobeRefetched}
          isFormIntialized={isFormIntialized}
          ignoreActiveOption={ignoreActiveOption}
          isInstanceCreation={isInstanceCreation}
        />
      )}
    </>
  );
};

export default TreeInputType;
