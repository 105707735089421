/* eslint-disable max-len */
/* eslint-disable arrow-body-style */

import moment from 'moment';
import { graphFormat } from '../../../config/index';

export const updateAllArgDomains = (
  panesShortHandInfo,
  xMinDomain,
  xMaxDomain,
  isForward = true,
  isUpdated = false
) => {
  return panesShortHandInfo.map((shInfo) => {
    const { StartTime, EndTime } = shInfo;
    const startTimeInit = moment(StartTime || xMinDomain, graphFormat);
    const endTimeInit = moment(EndTime || xMaxDomain, graphFormat);
    const diff = moment(endTimeInit).diff(startTimeInit, 'minutes');
    const unitMovement = isForward ? diff : -diff;
    return {
      ...shInfo,
      StartTime: isUpdated ? xMinDomain : startTimeInit.clone().add(unitMovement, 'minutes'),
      EndTime: isUpdated ? xMaxDomain : endTimeInit.clone().add(unitMovement, 'minutes')
    };
  });
};

export const removeAllArgDomains = (panesShortHandInfo) => {
  return panesShortHandInfo.map((shInfo) => {
    const { StartTime, EndTime, ...rest } = shInfo;
    return {
      ...rest
    };
  });
};
