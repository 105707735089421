/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../shared/utility/isEmpty';
import { useActions } from '../../store/hooks/useActions';
import DropDownModal from '../Common/DropDownModal/DropDownModal';
import InputDropdown from '../Common/DropDownModal/InputDropdown';
import CollapsableContainerView from './CollapsableContainer/CollapsableContainer.view';
import { ColorLabelBox, ColorLabelTxt, LegendItemContainer } from './ScaleYAxis/ScaleYAxis.style';

const SeriesTypeSelection = ({ index, item, handleSelection, dataSource, value }) => {
  const [isActive, setIsActive] = useState(false);
  const { dataKey } = dataSource;

  return (
    <React.Fragment key={index}>
      {/* {console.log('item', item, labelsData)} */}
      <LegendItemContainer>
        {/* <ColorLabelBox bgColor={colorCodes[index]} /> */}
        <ColorLabelTxt>{item?.fields?.displayname}</ColorLabelTxt>
      </LegendItemContainer>
      <DropDownModal
        setModalIsVisibal={setIsActive} // active setter
        isActive={isActive}
        data={dataSource[dataKey] || []}
        handleClick={(...rest) => {
          handleSelection(item, ...rest);
        }}
        singleSelection
        ContainerStyle={{ width: '20%' }}
      >
        <InputDropdown handleDropdownVisibility={setIsActive} value={value} />
      </DropDownModal>
    </React.Fragment>
  );
};

const SeriesTypes = ({ opt }) => {
  const {
    multiAxis,
    panesData, // fetch data from meta
    chartTypeCategory,
    colorCodes,
    seriesTypesSelections
  } = useSelector((state) => state.upsertPaneData);
  const labelsData = (panesData.length && Object.keys(panesData[0].meta).map((e) => panesData[0].meta[e])) || [];
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const { setSeriesTypesSelections } = useActions();

  const handleSelection = (kpvDetails, optionSelected) => {
    const kpvKey = kpvDetails?.fields?.key || 'common';
    const graphType = optionSelected?.key;
    setSeriesTypesSelections({
      [kpvKey]: optionSelected
    });
  };
  const { dataSource } = opt.inputDetails;
  const commonProps = {
    dataSource,
    handleSelection
  };

  useEffect(() => {
    const { common, ...kpvSeriesSelections } = seriesTypesSelections;
    const defaultOpt = dataSource?.[dataSource?.dataKey]?.[0];
    if (multiAxis) {
      const payload = labelsData?.reduce((acc, e) => {
        const kpvKey = e?.fields?.key;
        return {
          ...acc,
          [kpvKey]: isEmpty(seriesTypesSelections[kpvKey]) ? defaultOpt : seriesTypesSelections[kpvKey]
        };
      }, {});
      setSeriesTypesSelections(payload);
    } else if (isEmpty(common)) {
      const payload = { common: defaultOpt };
      setSeriesTypesSelections(payload);
    }
  }, [multiAxis, labelsData?.length]);
  return (
    <CollapsableContainerView header={localeStrings[opt.label] || opt.label}>
      {multiAxis ? (
        <>
          {labelsData?.map((item, index, arr) => {
            const kpvValue = item?.fields?.key;

            return (
              <SeriesTypeSelection
                index={index}
                item={item}
                {...commonProps}
                value={seriesTypesSelections?.[kpvValue]?.label}
              />
            );
          })}
        </>
      ) : (
        <SeriesTypeSelection
          handleSelection={handleSelection}
          {...commonProps}
          value={seriesTypesSelections.common?.label}
        />
      )}
    </CollapsableContainerView>
  );
};

export default SeriesTypes;
