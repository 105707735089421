/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import PopUpModal from '../PopUpModal/Modal.view';

import { BtnWrapper, Button, ContentText, Heading } from './ConfimationBox.styles';

const ConfirmationModal = ({
  Header,
  closeModalHandler,
  showModal,
  handleDelete,
  message,
  loading,
  buttonLoading,
  action, style,
  error = false
}) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  if (!showModal) return <></>;
  return (
    <PopUpModal style={style} closeModalHandler={closeModalHandler}>
      <Heading error={error}>{Header || localeStrings.Delete || 'Delete'}</Heading>
      {loading && (
        <div style={{ position: 'relative', width: '100%', height: '100%', background: 'red' }}>
          <Loader />
        </div>
      )}
      <ContentText error={error}>{message}</ContentText>
      <BtnWrapper>
        <Button onClick={() => closeModalHandler(false)}>{localeStrings.cancel || 'Cancel'}</Button>
        <Button
          onClick={() => {
            setTimeout(() => {
              if (!buttonLoading) {
                handleDelete();
              }
            }, 100);
          }}
          className={buttonLoading ? 'loading' : 'dark'}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <div style={{ position: 'absolute', width: '30%', height: '4rem' }}>
              <Loader position='relative' />
            </div>
          ) : (
            action || localeStrings.Delete || 'Delete'
          )}
        </Button>
      </BtnWrapper>
    </PopUpModal>
  );
};

export const ConfirmationActionModal = ({
  closeModalHandler,
  showModal,
  action,
  handleConfirm,
  message,
  loading,
  middleBtnMsg = '',
  showMiddleBtn,
  handleMiddleBtn = () => {}
}) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  if (!showModal) return <></>;
  return (
    <PopUpModal closeModalHandler={closeModalHandler}>
      {/* <Heading>{localeStrings.Delete || 'Delete'}</Heading> */}
      {loading && (
        <div style={{ position: 'relative', width: '100%', height: '100%', background: 'red' }}>
          <Loader />
        </div>
      )}
      <ContentText>{message}</ContentText>
      <BtnWrapper>
        <Button onClick={() => closeModalHandler(false)}>{localeStrings.cancel || 'Cancel'}</Button>
        <Button style={{ margin: '0px 2.2rem 0px' }} onClick={handleMiddleBtn}>
          {middleBtnMsg}
        </Button>
        {showMiddleBtn && (
          <Button onClick={handleConfirm} className='dark'>
            {action}
          </Button>
        )}
      </BtnWrapper>
    </PopUpModal>
  );
};

export default ConfirmationModal;
