import styled from 'styled-components';
import { maxSizeDevice, minDevice } from '../../../constants/device';

export const Continer = styled.div`
  &.allowHorizontal .field-layout {
    display: flex;
  }
  &.allowHorizontal .text-label {
    width: ${({ labelWidth }) => labelWidth || '15rem'};
  }

  &.allowHorizontal {
    .col-sm {
      // 768px - 1439px
      @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
        width: 250px;
      }

      // 1920px - 1440px common
      @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
        width: 300px;
      }

      // 1920px
      @media ${minDevice.laptopXL} {
        width: 350px;
      }
    }

    .col-md {
      // 768px - 1440px
      @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
        width: 300px;
      }

      // 1920px - 1440px common
      @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
        width: 350px;
      }

      // 1920px
      @media ${minDevice.laptopXL} {
        width: 400px;
      }
    }

    .col-lg {
      /* background: red; */
      // 768px - 1440px
      @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
        width: 350px;
      }

      // 1920px - 1440px common
      @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
        width: 400px;
      }

      // 1920px
      @media ${minDevice.laptopXL} {
        width: 450px;
      }
    }

    .col-xl {
      // 768px - 1440px
      @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
        width: 400px;
      }

      // 1920px - 1440px common
      @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
        width: 450px;
      }

      // 1920px - 2560px
      @media ${minDevice.laptopXL} {
        width: 500px;
      }
    }
  }
`;

export const MainContainer = styled.div`
  &.wrap {
    display: flex;
    flex-wrap: wrap;
  }
`;
