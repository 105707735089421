/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';
import {
  generateSelectedFilter,
  getBreadCrumbs
} from '../../../ResultView/Iframe/generateSelectedFilter';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { CardContainer } from './FilterInput.styles';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import { CancelBlack } from '../../../../assets/icons';
import { getFetchDetailsOfWidgetData } from '../../../Widget/utils';
import { Span } from '../../KeyValueLayout/KeyValueLayout.style';
import TreeSearchComponent from '../../../WidgetForm/WidgetFormTreeSearch/TreeSearchComponent';
import TreeInputType from '../../../WidgetForm/WidgetFormInputType/TreeInputType.View';
import { generateSelectedObjectTree } from '../../../ResultView/Iframe/generateSelectedObjectTree';
import { useActions } from '../../../../store/hooks/useActions';

const EquipmentFilterSearchTypeTree = ({
  item,
  fieldClassName,
  value,
  formValueSetter,
  hasPreSelection,
  isPreSelection,
  disabledFields,
  multiValueSelectionHandler,
  resetFieldsTobeRefetched,
  formInput,
  tobeRefetched,
  formStructure,
  isFormIntialized,
  isbreakPointEnabled,
  ignoreActiveOption,
  isInstanceCreation
}) => {
  const { label = '', key, dataSource, isRequired } = item || {};
  const { params, source } = dataSource || {};
  const { fullURL, method } = getFetchDetailsOfWidgetData(source);
  // const { menuId } = params;
  const [showModal, setShowModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const loading = useSelector((state) => state.objectTreeData.loading);

  const { getObjectTreeDataV3 } = useActions();
  useEffect(() => {
    if (!isEmpty(dataSource) && fullURL && method) {
      //
      getObjectTreeDataV3({
        directParams: params,
        directURL: fullURL,
        method,
        placementKey: item?.inputType
      });
    }
  }, []);

  const filterState = useSelector((state) => state.objectTreeData);
  const {
    cumulativeTags,
    objectTreeData,
    tags,
    loading: filterLoading
  } = filterState;

  const check = () => {
    // const b = getBreadCrumbs(
    //   generateSelectedObjectTree(
    //     cumulativeTags,
    //     objectTreeData,
    //     false,
    //     item?.key
    //   )
    // );
    // if (!isEmpty(b) && !isEmpty(b[0]?.key) && !isEmpty(b[0]?.value)) { formValueSetter(b, { key }); }

    const b = generateSelectedObjectTree(
      cumulativeTags,
      objectTreeData,
      true,
      item?.key
    );
    if (!isEmpty(b)) { formValueSetter(b, { key }); }
    setShowModal(false);
  };

  return (
    <div>
      <KeyInputLayoutView
        label={label}
        fieldClassName={fieldClassName}
        tooltip={item?.tooltip}
        className='add_equipment'
        width='50rem'
        isRequired={isRequired}
      >
        {loading ? (
          <Span>Loading...</Span>
        ) : (
          !isEmpty(objectTreeData?.filter((item) => item?.key === 'tree')[0]?.values) && (
            <div>
              <TreeSearchComponent
                item={item}
                isDisabled={
                  isbreakPointEnabled
                    ? false
                    : isPreSelection !== undefined &&
                      !isPreSelection &&
                      item?.key === 'tree'
                }
                showFilter={showModal}
                setShowFilter={setShowModal}
                setActiveTab={() => {}}
                placeholder={item?.placeholder}
              />
              <TreeInputType
                setShowModal={setShowModal}
                showModal={showModal}
                item={item}
                saveHandler={check}
                loading={filterLoading}
                hasPreSelection={hasPreSelection}
                isPreSelection={isbreakPointEnabled ? true : isPreSelection}
                ignoreActiveOption={ignoreActiveOption}
                isInstanceCreation={isInstanceCreation}
                value={value}
                disabledFields={disabledFields}
                multiValueSelectionHandler={multiValueSelectionHandler}
                resetFieldsTobeRefetched={resetFieldsTobeRefetched}
                formInput={formInput}
                formStructure={formStructure}
                isFormIntialized={isFormIntialized}
                tobeRefetched={tobeRefetched}
              />
            </div>
          )
        )}
      </KeyInputLayoutView>
    </div>
  );
};

export default EquipmentFilterSearchTypeTree;
