import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container, ContainerInside } from '../SvgWidget/SVGWidget.style';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import { BodyContainer } from '../../WidgetsCommon.style';
import { DeleteBlack, HideBorder, HideHeader } from '../../../../assets/icons';
import UsersHeader from '../../../../views/Users/UsersHeader';
import TabsTable from '../../../Common/Tabs_Table';

const TableWidgetEdit = (props) => {
  const { componentData, id, isVisible, layoutPos } = props;
  const { data, meta } = useSelector((state) => state.upsertWidgetData);
  const WID = layoutPos?.i?.split('_')?.[0];
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const {
    widgetEditOptions: initWidgetEditOptions,
    WidgetName,
    infoText,
    formData,
  } = componentData || {};
  const {
    widgetLabel
  } = useSelector((state) => state.upsertWidgetData);
  console.log(data);

  const widgetOptions = [
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  const containerRef = useRef();
  return (
    <Container style={{ width: '60%', marginLeft: '2rem' }} headerOn={!isHideHeader[WID + activeTab?.id]}>
      <WidgetHeaderView
        id={id}
        WID={WID} // WID
        WidgetName={WidgetName || widgetLabel}
        widgetOptions={widgetOptions}
        componentData={componentData}

      />
      <BodyContainer headerOn={!isHideHeader[WID + activeTab?.id]} style={{ marginLeft: '0' }}>
        <ContainerInside ref={containerRef}>
          <TabsTable
            metaDetails={meta}
            dataDetails={data}
            showPagiation={false}
            showShortlistSelection={false}
            // handleShortList={handleShortList}
            // onItemSelect={handleShowDetails}
            // assetShortlist={assetShortlist}
            // handlePageChange={handlePageChange}
            styleType='strapedTable'
            noItemsFoundMessage='No Users Found'
            // rowOptions={rowOptions}
            showRowOptionAtStart={false}
            id='UserID'
            // handleRowOptionsItemClick={handleRowOptionsItemClick}
            // shortListSelectionDetails={selectionTypeandPosition}
          />
        </ContainerInside>
      </BodyContainer>
    </Container>
  );
};

export default TableWidgetEdit;
