/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import React, { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import DropArrow from '../../assets/icons/layout/dropdownarrow.svg';
import { activeiconsMap, iconsMap } from '../../assets/icons/menuicons/iconsMap';
import { Icons } from '../../constants/icons';
import { useActions } from '../../store/hooks/useActions';
import { Creators } from '../../views/Layout/store';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import { fetchUserAndLocation } from './FetchStatics';
import RenderMultiLevelNav from './RenderMultiLevelNav';
import { NavItem, NavMenu, Title, Icon } from './Sidenav.styles';

// function to render subnavs
const RenderSubNavs = ({ parent, Name, SubMenu: navs, active, Icon: icon, Permalink, PageType, IconActive }) => {
  const [showDropdown, setshowDropdown] = useState(false);
  const location = useLocation();
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const userData = useSelector((state) => state.loginData.userData);
  const subMenuOptions = useSelector((state) => state.home.subMenuOptions);
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const dispatch = useDispatch();
  const { setShowSideNav } = useActions();
  useEffect(() => {
    const formatedLink = location.pathname.split('/').slice(0, 3).join('/');
    if (formatedLink === parent && !showDropdown) setshowDropdown(true);
  }, [location]);

  const handlePageStats = (nav) => {
    if (!showSideNav) {
      setShowSideNav({ showSideNav: true });
    }
    dispatch(Creators.setActiveMenu(nav));
    const checkSubMenuOptions = subMenuOptions.some((e) => e.path == nav.Permalink);
    if (!checkSubMenuOptions) {
      fetchUserAndLocation(nav.Permalink, userData, { pageType: nav.PageType });
    }
  };

  return (
    <>
      <NavMenu onClick={() => setshowDropdown(!showDropdown)} className={showDropdown ? 'active' : ''}>
        <div className={`arrow ${active ? '' : 'hidden'}`}>{Icons.dropArrow}</div>
        {/* <Icon className={`icon ${active ? '' : 'hidden'}`} icon={showDropdown ? activeIcon : icon} /> */}
        <Icon className={`icon ${active ? '' : 'hidden'}`}>
          <DynamicSVGIcon iconUrl={showDropdown ? activeiconsMap[Name] : iconsMap[Name]} width='20px' />
        </Icon>
        {active && <Title>{localeStrings[Name] || Name}</Title>}
      </NavMenu>

      <div>
        {showDropdown &&
          active &&
          navs.map((nav, i) => {
            if (nav.IsLeaf === '0') {
              return <RenderMultiLevelNav key={i} {...nav} active={active} activeIcon={IconActive} />;
            }
            return (
              <div onClick={() => handlePageStats(nav)}>
                <NavItem key={i} to={{ pathname: nav.Permalink, meta: nav.Meta }} style={{ paddingLeft: '8rem' }}>
                  {showDropdown && <Title>{localeStrings[nav.Name] || nav.Name}</Title>}
                </NavItem>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default memo(RenderSubNavs);
