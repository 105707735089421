/* eslint-disable function-paren-newline */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */

import { groupType } from '../../RangeSelectors/DateRangePicker/constants';

const actualId = (rawId) => (rawId[0] === '_' ? rawId.slice(1, rawId.length) : rawId);

let connectedNodes;
export const linkNodes = (nodes, level) => {
  const root = {};
  let parent = root;
  Object.keys(nodes).forEach((e) => {
    if (e >= level) {
      if (!Array.isArray(nodes[e])) {
        const id = actualId(nodes[e].id);
        parent.values = [{ ...nodes[e], id }];
      } else {
        parent.values = [];
        nodes[e].forEach((ele, index) => {
          parent.values.push({ ...nodes[e][index], id: actualId(nodes[e][index].id) });
        });
      }
      parent = parent.values[0];
    }
  });
  return root;
};

// isMerge for no merging the selection paths
const mergePathOfGroup = (group, tags, isMerge = true) => {
  try {
    const topMostCheck = connectedNodes[group]?.values.find((ele) => ele.id === actualId(tags[0].id));
    if (!topMostCheck || !isMerge) {
      connectedNodes[group].values = [...connectedNodes[group].values, ...linkNodes(tags, 0).values];
    } else {
      let parent = connectedNodes[group].values;
      const tagsKeys = Object.keys(tags);

      tagsKeys.every((tagKey) => {
        const check = parent.find((ele) => ele.id === actualId(tags[tagKey].id));
        if (!check) {
          parent.push(linkNodes(tags, tagKey).values[0]);
          return false;
        }
        parent = check;
        parent = parent.values;
        return true;
      });
    }
  } catch (e) {
    console.log('Issue in merging paths of filter', e);
  }
};

let obj;
export const loopLevels = (results, groups, level, final) => {
  // console.log('results', results, groups, level, final);
  if (level !== groups.length) {
    const { groupTitle, attribute } = results[groups[level]];
    results[groups[level]].values.forEach((selectionPath) => {
      obj[groups[level]] = { groupTitle, attribute, values: [{ ...selectionPath }] };
      loopLevels(results, groups, level + 1, final);
    });
  } else {
    final.push({ ...obj });
  }
};

export const getResolvedBreadCrumb = (item) =>
  // console.log('item', item);
  Object.keys(item).map((groupName) => {
    const group = item[groupName];
    let value = group.groupTitle;
    let pointer = group.values && group.values[0];
    while (pointer?.values?.length) {
      value += ` > ${pointer.name}`;
      // eslint-disable-next-line prefer-destructuring
      pointer = pointer.values[0];
    }
    if (pointer?.name) {
      value += ` > ${pointer.name}`;
    }
    return value;
  });

export const getBreadCrumbs = (results) => {
  const groups = Object.keys(results);
  const level = 0;
  const final = [];
  obj = {};

  loopLevels(results, groups, level, final);
  return final.map((item) => ({ value: item, key: getResolvedBreadCrumb(item) }));
};

export const generateSelectedObjectTree = (cumulativeTags, filterData, isMerge, keyItem) => {
  let objectTreeDataNew;
  if (keyItem) {
    objectTreeDataNew = filterData?.filter((item) => (item?.key === keyItem));
  } else {
    objectTreeDataNew = filterData;
  }

  connectedNodes = {};
  const levelsPresent = objectTreeDataNew?.map((level) => level.groupTitle);
  Object.keys(cumulativeTags).forEach((group) => {
    const indexOfGroup = levelsPresent.indexOf(group);
    if (indexOfGroup > -1) {
      const key = Object.keys(groupType)?.includes(group) ? groupType[group] : group;
      const { values, ...rest } = objectTreeDataNew[indexOfGroup];
      connectedNodes[key] = { ...rest, values: [] };
      const pathNos = Object.keys(cumulativeTags[group]);
      pathNos.forEach((pathNo) => mergePathOfGroup(key, cumulativeTags[group][pathNo], isMerge));
    }
  });

  return { ...connectedNodes };
};
