/* eslint-disable function-paren-newline */
/* eslint-disable prefer-template */
import { generateRandomColor } from '../../../shared/utility/generateRandomColor';

export const getMachinesFromKpvs = (kpvs) => {
  const tempUniqueMachines = [];
  kpvs.forEach((e) => {
    if (!tempUniqueMachines.find((ele) => ele.macId === e.macId)) {
      tempUniqueMachines.push(e);
    }
  });
  return tempUniqueMachines;
};

export const getTransitionChartLabels = (meta) => {
  const labels = {};
  Object.keys(meta).forEach((state) =>
    meta[state]?.fields?.Labels.forEach((labelItem) => {
      const { label, labeltext, colour } = labelItem;
      let finalColour = colour;
      let isGeneratedColour = false;
      // generate colors if not available and add flag for it
      if (!finalColour) {
        // console.log('no color');
        isGeneratedColour = true;
        finalColour = generateRandomColor();
      }
      // console.log(
      //   'lanb',
      //   state,
      //   meta[state]?.fields?.Labels,
      //   'labelItem',
      //   labelItem,
      //   'label, labeltext, colour ',
      //   label,
      //   labeltext,
      //   colour
      // );
      labels[label?.trim()] = { name: labeltext + '|' + label, color: finalColour, isGeneratedColour };
    })
  );
  return labels;
};
