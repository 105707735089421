/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, {
  useCallback,
  memo,
  lazy,
  Suspense,
  useState,
  useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TreeContentContainer from '../WidgetFormTreeContentContainer/TreeContentContainer';
import {
  FilterResultContent,
  FilterResultHeaderText,
  FilterResultHeader
} from '../WidgetFormTreeResultCard/TreeResultCard.style';

import { Creators as ObjectTreeCreators } from '../Store';

import Loader from '../../Loader/Loader';
import Radiobutton from '../../Common/RadioButton/RadioButton';
import MonthRangePicker from '../../RangeSelectors/MonthRangePicker/MonthRangePicker';
import QuarterRangePicker from '../../RangeSelectors/QuarterRangePicker/QuarterRangePicker';
import DateRangePicker from '../../RangeSelectors/DateRangePicker/DateRangePicker';
import {
  calanderInitContants,
  calenderTypes,
  intervalType
} from '../../RangeSelectors/DateRangePicker/constants';
import {
  absoluteWeekNo,
  getWeekno,
  getWeekNoInit,
  thisWeek
} from '../../RangeSelectors/DateRangePicker/utils';
import { Button } from '../../../globalStyles';

const _ = require('lodash');

const CalendarComponent = lazy(() => import('../../Calendar/index'));

function TreeChildrenContent({
  keyVal,
  groupTitle,
  index,
  attribute,
  selectionType,
  handleChildrenClick,
  handleMultiSelectClick,
  isLeaf,
  isPreSelection,
  hasPreSelection,
  showOnlyActive,
  isInstanceCreation
}) {
  const {
    tags,
    [groupTitle]: selectedObj,
    cumulativeTags,
    [`cumulative${groupTitle}`]: cummSelectedObj,
    leafLevels,
    breakpoint,
    objectTreeData
  } = useSelector((state) => state.objectTreeData);
  let meta = {};
  const intervalData = objectTreeData.filter(
    (objData) => objData?.key?.toLowerCase() === intervalType.INTERVAL
  );

  if (intervalData.length && intervalData[0].type === 'object') {
    meta = intervalData[0].meta;
  }
  const {
    weekStartsOn,
    q1StartsOn,
    maxRangeAllowed = {},
    dateOfWeekStart
  } = meta;
  const dispatch = useDispatch();
  const addTabToObj = useCallback(
    (payload) =>
      dispatch(ObjectTreeCreators.universalObjectTreeSetter(payload)),
    [dispatch]
  );
  const handleLeaf = () => {
    // console.log('leafLevels[item.groupTitle]', leafLevels[groupTitle], selectedObj[keyVal]?.attribute);
    if (isPreSelection) {
      const isDeselection = breakpoint?.[groupTitle] === index;
      dispatch(
        ObjectTreeCreators.universalObjectTreeSetter({
          key: 'breakpoint',
          value: {
            ...breakpoint,
            [groupTitle]: isDeselection ? undefined : index
          }
        })
      );
    } else {
      dispatch(
        ObjectTreeCreators.universalObjectTreeSetter({
          key: 'leafLevels',
          value: {
            ...leafLevels,
            [groupTitle]:
              leafLevels[groupTitle] === selectedObj[keyVal]?.attribute
                ? ''
                : selectedObj[keyVal]?.attribute
          }
        })
      );
    }
  };

  // handles only new pickers(daily, weekly, monthly, quarterly)
  const handleSelection = (e) => {
    const foundPathNo = Object.keys(cummSelectedObj).find(
      (pathNo) =>
        cummSelectedObj[pathNo][cumulativeTags[groupTitle][pathNo][index].id]
          .type === selectedObj[keyVal].type
    );
    // console.log('foundPathNo', foundPathNo);
    // console.log(
    //   'foundPathNo',
    //   Object.values(cummSelectedObj[foundPathNo])[0],
    //   Object.values(cumulativeTags[groupTitle][foundPathNo])[0]
    // );

    const att = selectedObj[keyVal].attribute;
    // old
    let name = `${selectedObj[keyVal].attribute} - ${e.value}`;
    if (e.label) {
      name = e.label;
    }
    const selectionObj = Object.values(cummSelectedObj[foundPathNo])[0];
    let detailedTextObj = {};
    const { type } = selectedObj[keyVal];
    try {
      if (type === calenderTypes.DATE_RANGE) {
        detailedTextObj = {
          starttext: moment(e.rawValue?.startDate).format('YYYY-MM-DD'),
          endtext: moment(e.rawValue?.endDate).format('YYYY-MM-DD'),
          startDate: new Date(e.rawValue?.startDate).toISOString(),
          endDate: new Date(e.rawValue?.endDate).toISOString()
        };
      } else if (type === calenderTypes.WEEK) {
        const weekNoInit = getWeekNoInit(dateOfWeekStart, { weekStartsOn });
        // during init the start and end values are not correct ==> fixed this below
        if (e?.rawValue?.isInit) {
          e.rawValue = { ...thisWeek(weekStartsOn), isInit: true };
        }
        const {
          weekNo: startWeekNo,
          isNextYear: isStartInNextYear,
          year: startYear
        } = absoluteWeekNo(
          getWeekno(
            e.rawValue?.startDate,
            weekNoInit,
            dateOfWeekStart,
            '',
            true
          )
        );
        // get the start day of the week and find the week no(which is used for calender also)
        const {
          weekNo: endWeekNo,
          isNextYear: isEndInNextYear,
          year: endYear
        } = absoluteWeekNo(
          getWeekno(
            moment(e.rawValue?.endDate).subtract(6, 'd'),
            weekNoInit,
            dateOfWeekStart,
            '',
            true
          )
        );
        // console.log(dateOfWeekStart);
        // const endWeekNo =
        //   startWeekNo + Math.ceil((moment(e.rawValue?.endDate).diff(e.rawValue?.startDate, 'days') + 1) / 7) - 1;
        // console.log(e.rawValue);
        // const startYear = new Date(e.rawValue.startDate).getFullYear();
        const newStartYear = startYear;
        // const newStartYear = isStartInNextYear ? startYear + 1 : startYear;
        const starttext = `W${startWeekNo} ${newStartYear}`;
        // const endYear = new Date(e.rawValue.endDate).getFullYear();
        const newEndYear = endYear;
        // const newEndYear = isEndInNextYear ? endYear + 1 : endYear;
        // if the selection is made till start only
        const endtext =
          startWeekNo >= endWeekNo && newStartYear === newEndYear
            ? starttext
            : `W${endWeekNo} ${newEndYear}`;
        name = `${att} - ${starttext} -  ${endtext}`;
        detailedTextObj = {
          starttext,
          endtext,
          startDate: new Date(e.rawValue?.startDate).toISOString(),
          endDate: new Date(e.rawValue?.endDate).toISOString()
        };
      } else if (type === calenderTypes.MONTH) {
        // handling undefined start and end
        const currentDate = new Date();
        if (!e.rawValue?.start) {
          e.rawValue = {
            start: moment(new Date(e.rawValue?.start || currentDate))
              .startOf('month')
              .toISOString(),
            end: moment(new Date(e.rawValue?.end || currentDate))
              .endOf('month')
              .toISOString()
          };
        }
        detailedTextObj = {
          starttext: moment(e.rawValue?.start).format('MMM YYYY'),
          endtext: moment(e.rawValue?.end).format('MMM YYYY'),
          startDate: new Date(e.rawValue?.start).toISOString(),
          endDate: new Date(e.rawValue?.end).toISOString()
        };
      } else if (type === calenderTypes.QUARTER) {
        const startAndEndValue = e.value.split('-');
        detailedTextObj = {
          // starttext: e.rawValue?.start?.quarter,
          // endtext: e.rawValue?.end?.quarter,
          starttext: startAndEndValue[0]?.trim(),
          endtext: startAndEndValue[1]?.trim(),
          startDate: moment()
            .year(e.rawValue?.start?.start?.year)
            .month(e.rawValue?.start?.start?.month)
            .startOf('month')
            .toISOString(),
          endDate: moment()
            .year(e.rawValue?.end?.end?.year)
            .month(e.rawValue?.end?.end?.month)
            .endOf('month')
            .toISOString()
        };
      }
    } catch (e) {
      console.log('error in formating', e);
    }
    // console.log('detailedTextObj', detailedTextObj);
    const selObjVal = {
      ...selectionObj,
      name,
      rawValue: e.rawValue,
      isEnd: true
    };
    const tagVal = {
      ...cumulativeTags[groupTitle][foundPathNo][0],
      name,
      rawValue: e.rawValue,
      ...detailedTextObj
    };
    // const tagVal = { ...tags[groupTitle][0], name, rawValue: e.rawValue };

    // console.log('imp check', { ...cumulativeTags[groupTitle][foundPathNo][0] }, { ...tags[groupTitle][0] });
    const selObj = {
      [Object.keys(cummSelectedObj[foundPathNo])[0]]: selObjVal
    };
    const tagObj = { 0: tagVal };
    cummSelectedObj[foundPathNo] = selObj;
    addTabToObj({ value: selObj, key: groupTitle });
    cumulativeTags[groupTitle][foundPathNo] = tagObj;
    tags[groupTitle] = tagObj;
  };
  const [clearSelection, setClearSelection] = useState(false);
  const clearRangeSelected = () => {
    setClearSelection(true);
  };

  let currentYear = moment().format('YYYY');

  if (moment().format('MM') <= moment(dateOfWeekStart, 'DD-MM').format('MM')) {
    if (
      moment().format('MM') === moment(dateOfWeekStart, 'DD-MM').format('MM')
    ) {
      if (
        moment().format('DD') < moment(dateOfWeekStart, 'DD-MM').format('DD')
      ) {
        currentYear -= 1;
      }
    } else {
      currentYear -= 1;
    }
  }
  const dateOfWeekStartWithYear = moment(
    dateOfWeekStart + '-' + currentYear,
    'DD-MM-YYYY'
  ).format('d');

  return (
    <React.Fragment key={index}>
      <div
        style={{
          width: 1,
          borderLeft: '0.5px dashed darkgray',
          marginLeft: '1rem'
        }}
      />
      {/* {console.log('weekStartsOn', weekStartsOn)} */}
      <div
        style={{
          minWidth: Object.values(calenderTypes).includes(
            selectedObj[keyVal].type
          )
            ? '50rem'
            : '0'
        }}
      >
        <FilterResultHeader>
          <FilterResultHeaderText>
            {selectedObj[keyVal]?.attribute}
          </FilterResultHeaderText>
          {(isPreSelection || selectedObj[keyVal].lockable) &&
            !isInstanceCreation && (
              <Radiobutton
                onClick={handleLeaf}
                active={
                  (leafLevels &&
                    leafLevels[groupTitle] ===
                      selectedObj[keyVal]?.attribute) ||
                  (breakpoint && breakpoint[groupTitle] === index)
                }
              />
              // eslint-disable-next-line indent
            )}
          {Object.values(calenderTypes).includes(
            selectedObj[keyVal].type.toLowerCase()
          ) && (
            <Button
              type='button'
              className='dark small'
              onClick={clearRangeSelected}
            >
              clear
            </Button>
          )}
        </FilterResultHeader>
        {selectedObj[keyVal].type === 'multiselect' && (
          <FilterResultContent left={true}>
            {selectedObj[keyVal].values.map((innerItem, i) => (
              <TreeContentContainer
                selectedTabKey={groupTitle}
                innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                selectionType={selectedObj[keyVal].selectionType}
                handleClick={() =>
                  handleMultiSelectClick(
                    { ...innerItem, id: `_${innerItem.id}` },
                    index,
                    groupTitle,
                    attribute
                  )
                }
                key={innerItem.id || i}
                index={index}
                multiSelect={true}
                className='multiSelect'
                isPreSelection={isPreSelection}
                hasPreSelection={hasPreSelection}
                showOnlyActive={showOnlyActive}
              />
            ))}
          </FilterResultContent>
        )}
        {/* {console.log('aadds', selectedObj[keyVal].rawValue, selectedObj[keyVal])} */}

        {selectedObj[keyVal].type === 'date' && (
          <FilterResultContent left={true} className='flexing'>
            <div>
              {selectedObj[keyVal].values.map((innerItem, i) => (
                <TreeContentContainer
                  selectedTabKey={groupTitle}
                  // innerItem={innerItem}
                  innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                  selectionType={selectedObj[keyVal].selectionType}
                  handleClick={() =>
                    handleChildrenClick(
                      { ...innerItem, id: `_${innerItem.id}` },
                      groupTitle,
                      index,
                      attribute,
                      selectionType
                    )
                  }
                  key={innerItem.id || i}
                  index={index}
                  className='typeDate'
                  isLeaf={isLeaf}
                  isPreSelection={isPreSelection}
                  hasPreSelection={hasPreSelection}
                  showOnlyActive={showOnlyActive}
                />
              ))}
            </div>
            <Suspense fallback={<Loader />}>
              <CalendarComponent
                groupTitle={groupTitle}
                values={selectedObj[keyVal].values}
                handleChildrenClick={() =>
                  handleChildrenClick(
                    selectedObj[keyVal].values,
                    groupTitle,
                    index,
                    attribute,
                    selectionType
                  )
                }
                index={index}
              />
            </Suspense>
          </FilterResultContent>
        )}
        {/* refactor date and week as single */}
        {/* {selectedObj[keyVal].type === 'dateRange' && ( */}
        {selectedObj[keyVal].type.toLowerCase() ===
          calenderTypes.DATE_RANGE && (
          //  || selectedObj[keyVal].type === 'week')
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <DateRangePicker
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                weekStartsOn={weekStartsOn ?? calanderInitContants.weekStartsOn}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
                MaxAllowedRange={maxRangeAllowed.daterange}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
              />
            </Suspense>
          </FilterResultContent>
        )}
        {selectedObj[keyVal].type === calenderTypes.WEEK && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <DateRangePicker
                isWeekSelection={selectedObj[keyVal].type === 'week'}
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                // weekStartsOn={weekStartsOn ?? calanderInitContants.weekStartsOn}
                weekStartsOn={dateOfWeekStartWithYear}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
                MaxAllowedRange={maxRangeAllowed.week}
                dateOfWeekStart={dateOfWeekStart}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
                useGivenDateForRevenseCalculation={true}
              />
            </Suspense>
          </FilterResultContent>
        )}

        {selectedObj[keyVal].type === calenderTypes.MONTH && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <MonthRangePicker
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                MaxAllowedRange={maxRangeAllowed.month}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
              />
            </Suspense>
          </FilterResultContent>
        )}

        {selectedObj[keyVal].type === calenderTypes.QUARTER && (
          <FilterResultContent left={true} className='flexing'>
            <Suspense fallback={<Loader />}>
              <QuarterRangePicker
                initValue={selectedObj[keyVal].rawValue}
                handleSelection={handleSelection}
                quarterStartsOn={q1StartsOn ?? calanderInitContants.q1StartsOn}
                MaxAllowedRange={maxRangeAllowed.quarter}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
              />
            </Suspense>
          </FilterResultContent>
        )}

        {(selectedObj[keyVal].type === 'select' ||
          selectedObj[keyVal].type === 'object') && (
          <FilterResultContent left={true}>
            {selectedObj[keyVal].values.map((innerItem, i) => (
              <TreeContentContainer
                selectedTabKey={groupTitle}
                // innerItem={innerItem}
                innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                handleClick={() =>
                  handleChildrenClick(
                    { ...innerItem, id: `_${innerItem.id}` },
                    groupTitle,
                    index,
                    attribute,
                    selectionType
                  )
                }
                selectionType={selectedObj[keyVal].selectionType}
                key={innerItem.id || i}
                index={index}
                isLeaf={isLeaf}
                isPreSelection={isPreSelection}
                hasPreSelection={hasPreSelection}
                showOnlyActive={showOnlyActive}
              />
            ))}
          </FilterResultContent>
        )}
      </div>
    </React.Fragment>
  );
}

export default memo(TreeChildrenContent);
