/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { ToogleDiv } from './CollapsableContainer.style';
import { ToggleOff, ToggleOn } from '../../../assets/icons/KPV';
import { Creators as UpsertPaneCreators } from '../Store';

const MutliSelectToggler = (props) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const multiSelectKpv = useSelector((state) => state.upsertPaneData.multiSelectKpv);
  const dispatch = useDispatch();
  const handleMultiSlectToogle = () => {
    if (multiSelectKpv) {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'machinesSelected',
          value: []
        })
      );
    }
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'multiSelectKpv',
        value: !multiSelectKpv
      })
    );
  };

  return (
    <ToogleDiv>
      <span style={{ textTransform: 'capitalize' }}>{localeStrings['Multi Select'] || 'multi select'}</span>
      <div style={{ marginLeft: '1rem' }} onClick={handleMultiSlectToogle}>
        {multiSelectKpv ? (
          <DynamicSVGIcon width='2.5rem' iconUrl={ToggleOn} />
        ) : (
          <DynamicSVGIcon width='2.5rem' iconUrl={ToggleOff} />
        )}
      </div>
      {/* <Radio onClick={handleMultiSlectToogle}>{multiSelectKpv && <RadioIcon />}</Radio> */}
    </ToogleDiv>
  );
};

export default MutliSelectToggler;
