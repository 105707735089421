/* eslint-disable no-param-reassign */
import { isEmpty } from '../../../shared/utility/isEmpty';
import { nearestDataPoint } from './dataPointBinarySearch';

// data chart nearest point
/* eslint-disable max-len */
const getNearestPoints = (plotData, meta, argument, argProperty) => {
  // console.log('pointInfo-dc-compress');

  // eslint-disable-next-line arrow-body-style
  const e = Object.keys(meta).map((s, index) => {
    // console.log('plplsa', s, plotData.slice(meta[s].startIndex, meta[s].endIndex + 1));
    let plotDataParm = plotData;
    let endIndex = plotData.length - 1;
    if (meta[s].endIndex) {
      plotDataParm = plotData.slice(meta[s].startIndex, meta[s].endIndex + 1);
      endIndex = meta[s].endIndex - meta[s].startIndex;
    }
    return {
      [s]: nearestDataPoint(plotDataParm, argument, 0, endIndex, argProperty)
      // .map((e) => e.data)
    };
  });

  // only one old version...
  // const e = chart?.getAllSeries().reduce(
  //   (prev, s, index) => ({
  //     ...prev,
  //     [s.name]: nearestDataPoint(s._data, argument, 0, s._data.length)
  //     // .map((e) => e.data)
  //   }),
  //   {}
  // );

  // old
  // in compress view get all the series in this graph and getPointByArg
  // const e = chart
  //   ?.getAllSeries()
  //   .reduce((prev, s) => ({ ...prev, [s.name]: s.getPointsByArg(argument).map((e) => e.data) }), {});
  const data =
    e &&
    // eslint-disable-next-line arrow-body-style
    e.reduce((prev, curr) => {
      Object.keys(curr).forEach((key, i) => {
        const obj = curr[key];
        if (isEmpty(prev[i])) {
          prev = {
            ...prev,
            TSValue: obj.TSValue
          };
        }
        prev[`${key}-TSValue`] = obj.TSValue;
        prev[`${key}-SensorData`] = obj.SensorData;
      });
      // console.log('prev', prev);
      return prev;
    }, []);
  // console.log('data=_=+', e, data);
  return data;
};

// spc chart get nearest point by an argument
export const getNearestPointsInSPC = (plotData, meta, showOverlays, argument, selectedOverlayDomain) => {
  // default overlay nodes
  const { default: df, overlayDomain } = meta[Object.keys(meta)[0]];
  const finalOverlayDomain = { ...overlayDomain, ...selectedOverlayDomain };

  // overlay item can have multiple nodes
  // get all the nodes for a given overlay
  const getNodeItemsOfOverlayItem = (overlaysObj, overlayItemKey) =>
    overlaysObj.reduce((innerAcc, nodeItem) => {
      const { node: nodeName } = nodeItem;
      return [...innerAcc, nodeName];
    }, []);

  const defaultOverlaysNode = Object.keys(df).reduce((acc, defaultOverlay) => {
    const nodeItems = getNodeItemsOfOverlayItem(df[defaultOverlay], defaultOverlay);
    return { ...acc, [defaultOverlay]: nodeItems };
  }, {});

  // selectedOverlay nodes
  const selectedOverlays =
    !isEmpty(showOverlays) &&
    Object.keys(showOverlays).reduce((acc, overlay) => {
      const nodeItems = getNodeItemsOfOverlayItem(showOverlays[overlay]?.data, overlay);
      return { ...acc, [overlay]: nodeItems };
    }, {});

  const closestPointsOfOverlays = Object.entries({ ...defaultOverlaysNode, ...(selectedOverlays || {}) }).reduce(
    (acc, s) => {
      const [key, value] = s;
      const closestPointsOfNodes = value.reduce((innerAcc, nodeItem) => {
        const { startIndex = null, endIndex = null } = finalOverlayDomain[nodeItem] || {};
        // console.log('nearest data', [...plotData].length, startIndex, endIndex);
        const c = nearestDataPoint(plotData, argument, startIndex, endIndex, 'X');
        return {
          ...innerAcc,
          [nodeItem]: c
        };
      }, {});
      // commented out below block as we plot all the overlays elements as seperate series
      // check the closest point among all the nodes
      // const closestPoint = Object.entries(closestPointsOfNodes).reduce((acc, e) => {
      //   const [key, value] = e;
      //   const { X } = value;

      //   if (acc.X && Math.abs(new Date(argument) - new Date(acc.X)) < Math.abs(new Date(argument) - new Date(X))) {
      //     return value;
      //   }
      //   return acc;
      // }, Object.values(closestPointsOfNodes)?.[0]);
      // console.log('closestPoint', key, closestPoint);
      // return { ...acc, [key]: closestPoint };
      return { ...acc, ...closestPointsOfNodes };
    },
    {}
  );
  // console.log('check', closestPointsOfOverlays);
  return closestPointsOfOverlays;
};

// transition charts
// get all the points in the graph and check with the pointInfo time
// returns the object with labels as keys and respective values as machines
export const getNearestTSPoint = (plotData, pointInfo) => {
  try {
    const filteredData = plotData?.filter(
      (e) =>
        e.start &&
        e.end &&
        e.start.getTime() !== e.end.getTime() &&
        e.start.getTime() <= pointInfo.getTime() &&
        e.end.getTime() > pointInfo.getTime()
    );
    // console.log('gill', filteredData, pointInfo);
    return filteredData.reduce((final, curr) => {
      if (!isEmpty(final[curr.label])) {
        // final[curr.label].push({ state: curr.state, arg: curr.start });
        final[curr.label].push({ state: curr.state, arg: curr.start });
      } else {
        final[curr.label] = [{ state: curr.state, arg: curr.start }];
      }
      // if (!isEmpty(final[curr.label])) {
      //   final[curr.label].push(curr.state);
      // } else {
      //   final[curr.label] = [curr.state];
      // }
      return final;
    }, {});
  } catch (e) {
    console.log('error', e);
  }
  return {};
};

export default getNearestPoints;
