/* eslint-disable function-paren-newline */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { formatedFilterData } from '../../../../component/Filter/utility/formatFilterData';
// import { sampleFilterData } from '../../../../constants/sampleData';
// import { dummyFilterData } from '../../../../constants/sampleData';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { Creators } from './action';
// import { sampleFilterData } from './sampleFilter';
// import { sampleFilterData2 } from './sampleFilter2';
// import { shortFilterData } from './filterData';
// import { dummyFilterDatastore } from './filterData';
// import { dummyFilterData } from './filterData';

export function* getFilterDataSaga({ payload, dependencyPayload = {} }) {
  // console.log('comming from saga');
  const { tab, menuId, directParams, directURL, method, responseData } = payload;
  const {
    updatedGroup,
    dependentOn,
    dependents,
    metaDependency,
    updateGroupPayload,
    resetDependents,
    keyTitleMapping
  } = dependencyPayload;
  // console.log('payload', payload);
  // console.log(tab);
  // console.log('updatedGroup', updatedGroup, dependencyPayload);
  try {
    // will load entire filter data
    if (!updatedGroup) {
      yield put(Creators.getFilterDataStart());
      let URL = `${apiEndPoints.getFilterCategory}`;

      // if (type === 'page') {
      //   URL = `${apiEndPoints.getDataView}`;
      // }
      if (directURL) {
        URL = directURL;
      }

      const fullURL = yield URL;

      let response;

      if (responseData) {
        response = yield WebService?.[method || 'get'](fullURL, responseData);
      } else {
        response = yield WebService?.[method || 'get'](fullURL, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      }

      if (response.status >= 200 && response.status < 300) {
        const { data, meta: metaRaw, resultType } = response.data;
        // const { data, meta: metaRaw, resultType } = sampleFilterData;
        const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
        // const { data, meta, resultType } = sampleFilterData;
        // console.log('data', data, response.data);
        let dependencies = [];
        let dependents = {};
        if (!isEmpty(meta)) {
          // compute final dependencies combing all the dependencies
          dependencies = Object.keys(meta.dependency).reduce((final, dependent) => {
            const currEle = meta.dependency[dependent];
            const newDependencies = currEle.dependencies.reduce((prev, curr) => {
              if (!prev.includes(curr)) {
                return [...prev, curr];
              }
              return prev;
            }, final);
            return newDependencies;
          }, []);

          // compute  dependents for each group
          dependents = dependencies.reduce((finalDependents, dependency) => {
            const eleDependents = Object.keys(meta.dependency).reduce((prev, curr) => {
              const currEle = meta.dependency[curr];
              if (currEle.dependencies.includes(dependency)) {
                return [...prev, curr];
              }
              return prev;
            }, []);
            return { ...finalDependents, [dependency]: eleDependents };
          }, {});
        }
        // old
        // const formatedData = [];
        // for (const key in data) {
        //   formatedData.push(data[key][0]);
        // }
        // new
        if (responseData) {
          const dataRes = {};
          dataRes.interval = { ...data.interval };
          const { formatedData, keyTitleMapping } = formatedFilterData(dataRes);

          const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
            const { groupTitle, meta: groupMeta } = groupDetails;
            if (!isEmpty(groupMeta)) {
              return { ...meta, [groupTitle]: groupMeta };
            }
            return meta;
          }, {});
          yield put(
            Creators.getFilterDataSuccess({
              data: formatedData,
              meta: { ...meta, ...groupLevelMeta },
              CategoryOptions: data?.CategoryOptions,
              resultType,
              parent: tab,
              dependencies,
              dependents,
              keyTitleMapping,
              menuId
            })
          );
        } else {
          const { formatedData, keyTitleMapping } = formatedFilterData(data);

          // console.log('formatedData', data, formatedData);
          // const { filterData, resultType, dataView } = dummyFilterData;
          // console.log('formatedData', formatedData);

          // meta of groups
          const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
            const { groupTitle, meta: groupMeta } = groupDetails;
            if (!isEmpty(groupMeta)) {
              return { ...meta, [groupTitle]: groupMeta };
            }
            return meta;
          }, {});
          yield put(
            Creators.getFilterDataSuccess({
              data: formatedData,
              meta: { ...meta, ...groupLevelMeta },
              resultType,
              parent: tab,
              dependencies,
              dependents,
              keyTitleMapping,
              menuId
            })
          );
        }
      } else {
        throw response;
      }
    } else {
      yield put(Creators.getFilterDependencyDataStart({ dependentOn }));
      // console.log('loading only this...', updatedGroup, dependentOn, dependents);
      const rawResponses = yield Promise.all(
        dependents.map((dependent) => {
          const filteredObj = Object.keys(updateGroupPayload.data).reduce((prev, group) => {
            if (
              metaDependency[dependent].dependencies
                .map((e) => (e === 'tree' ? 'tree' : keyTitleMapping[e]))
                .includes(group)
            ) {
              return { ...prev, [group]: updateGroupPayload.data[group] };
            }
            return prev;
          }, {});
          // console.log('filteredObj', filteredObj);
          return WebService.post(metaDependency[dependent].api, { ...updateGroupPayload, data: filteredObj });
        })
      );
      const responses = rawResponses.map((e, index) => ({ ...e.data[dependents[index]], keyName: dependents[index] }));
      // console.log('responses', responses);

      // local responses
      // const localResponses = dependents.map((dependent) => sampleFilterData2.data[dependent]);
      // const formatedData = localResponses.reduce(
      //   (final, curr, index) => [...final, { ...curr[0], keyName: dependents[index] }],
      //   // (final, curr, index) => ({ ...final, [dependents[index]]: curr[0] }),
      //   []
      // );
      // const initSelections = dependents.reduce(
      //   (final, dependent) => ({
      //     [dependent]: {},
      //     [`cumulative${dependent}`]: {}
      //   }),
      //   {}
      // );
      // console.log('formatedData', formatedData);
      // meta of groups
      const groupLevelMeta = responses.reduce((meta, groupDetails) => {
        const { groupTitle, meta: groupMeta } = groupDetails;
        if (!isEmpty(groupMeta)) {
          return { ...meta, [groupTitle]: groupMeta };
        }
        return meta;
      }, {});
      yield put(
        Creators.getFilterDependencyDataSuccess({
          filterData: responses,
          meta: groupLevelMeta,
          resetDependents
        })
      );
    }

    // if (directURL === 'http://fl2022.vistrian.com/webservices/demo/api/dummyLotSettings') {
    //   const dummyData = {
    //     data: {
    //       interval: [
    //         {
    //           id: 2,
    //           groupTitle: 'Interval',
    //           type: 'object',
    //           selectionType: 'singleselect',
    //           filtertype: 'range',
    //           required: 'true',
    //           attribute: 'Calendar',
    //           values: [
    //             {
    //               id: 'FI1',
    //               name: 'Daily',
    //               type: 'daterange',
    //               selectionType: 'singleselect',
    //               required: false,
    //               attribute: 'Date'
    //             },
    //             {
    //               id: 'FI2',
    //               name: 'Weekly',
    //               type: 'week',
    //               selectionType: 'singleselect',
    //               required: false,
    //               attribute: 'Week'
    //             },
    //             {
    //               id: 'FI3',
    //               name: 'Monthly',
    //               type: 'month',
    //               selectionType: 'singleselect',
    //               required: false,
    //               attribute: 'Month'
    //             },
    //             {
    //               id: 'FI4',
    //               name: 'Quarterly',
    //               type: 'quarter',
    //               selectionType: 'singleselect',
    //               required: false,
    //               attribute: 'Quarter'
    //             }
    //           ],
    //           meta: {
    //             weekStartsOn: 6,
    //             dateOfWeekStart: '02-07',
    //             q1StartsOn: 6,
    //             maxRangeAllowed: {
    //               daterange: {
    //                 unit: 'days',
    //                 value: '0'
    //               },
    //               week: {
    //                 unit: 'weeks',
    //                 value: '52'
    //               },
    //               month: {
    //                 unit: 'weeks',
    //                 value: '52'
    //               },
    //               quarter: {
    //                 unit: 'quarters',
    //                 value: '4'
    //               }
    //             }
    //           }
    //         }
    //       ],
    //       CategoryOptions: [
    //         {
    //           data: {
    //             lot: {
    //               key: 'lot',
    //               value: 'lot',
    //               label: 'Lot'
    //             },
    //             file: {
    //               key: 'file',
    //               value: 'file',
    //               label: 'file'
    //             }
    //           }
    //         }
    //       ]

    //     },
    //     resultType: 'iframe',
    //     message: 'Success'
    //   };
    //   const { data, meta: metaRaw, resultType } = dummyData.data;

    //   // const { data, meta: metaRaw, resultType } = sampleFilterData;
    //   const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
    //   // const { data, meta, resultType } = sampleFilterData;
    //   // console.log('data', data, response.data);
    //   let dependencies = [];
    //   let dependents = {};
    //   if (!isEmpty(meta)) {
    //     dependencies = Object.keys(meta.dependency).reduce((final, dependent) => {
    //       const currEle = meta.dependency[dependent];
    //       const newDependencies = currEle.dependencies.reduce((prev, curr) => {
    //         if (!prev.includes(curr)) {
    //           return [...prev, curr];
    //         }
    //         return prev;
    //       }, final);
    //       return newDependencies;
    //     }, []);

    //     // compute  dependents for each group
    //     dependents = dependencies.reduce((finalDependents, dependency) => {
    //       const eleDependents = Object.keys(meta.dependency).reduce((prev, curr) => {
    //         const currEle = meta.dependency[curr];
    //         if (currEle.dependencies.includes(dependency)) {
    //           return [...prev, curr];
    //         }
    //         return prev;
    //       }, []);
    //       return { ...finalDependents, [dependency]: eleDependents };
    //     }, {});
    //   }

    //   const { formatedData, keyTitleMapping } = formatedFilterData(data);

    //   const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
    //     const { groupTitle, meta: groupMeta } = groupDetails;
    //     if (!isEmpty(groupMeta)) {
    //       return { ...meta, [groupTitle]: groupMeta };
    //     }
    //     return meta;
    //   }, {});

    //   yield put(
    //     Creators.getFilterDataSuccess({
    //       data: formatedData,
    //       meta: { ...meta, ...groupLevelMeta },
    //       resultType,
    //       parent: tab,
    //       dependencies,
    //       dependents,
    //       keyTitleMapping,
    //       menuId
    //     })
    //   );
    // }
  } catch (error) {
    if (directURL === 'https://vistrian-backend-2.panorbitprojects.com/api/Widget/GenericApi') {
      const dummyData = {
        CategoryOptions:
        {
          data: {
            lot: {
              key: 'lot',
              value: 'lot',
              label: 'Lot'
            },
            file: {
              key: 'file',
              value: 'file',
              label: 'file'
            }
          }
        },
        data: {
          interval: [
            {
              id: 2,
              groupTitle: 'Interval',
              type: 'object',
              selectionType: 'singleselect',
              filtertype: 'range',
              required: 'true',
              attribute: 'Calendar',
              values: [
                {
                  id: 'FI1',
                  name: 'Daily',
                  type: 'daterange',
                  selectionType: 'singleselect',
                  required: false,
                  attribute: 'Date'
                },
                {
                  id: 'FI2',
                  name: 'Weekly',
                  type: 'week',
                  selectionType: 'singleselect',
                  required: false,
                  attribute: 'Week'
                },
                {
                  id: 'FI3',
                  name: 'Monthly',
                  type: 'month',
                  selectionType: 'singleselect',
                  required: false,
                  attribute: 'Month'
                },
                {
                  id: 'FI4',
                  name: 'Quarterly',
                  type: 'quarter',
                  selectionType: 'singleselect',
                  required: false,
                  attribute: 'Quarter'
                }
              ],
              meta: {
                weekStartsOn: 6,
                dateOfWeekStart: '02-07',
                q1StartsOn: 6,
                maxRangeAllowed: {
                  daterange: {
                    unit: 'days',
                    value: '0'
                  },
                  week: {
                    unit: 'weeks',
                    value: '52'
                  },
                  month: {
                    unit: 'weeks',
                    value: '52'
                  },
                  quarter: {
                    unit: 'quarters',
                    value: '4'
                  }
                }
              }
            }
          ]

        },
        resultType: 'iframe',
        message: 'Success'
      };
      const { data, meta: metaRaw, resultType, CategoryOptions } = dummyData;
      console.log('data', data);

      // const { data, meta: metaRaw, resultType } = sampleFilterData;
      const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
      // const { data, meta, resultType } = sampleFilterData;
      // console.log('data', data, response.data);
      let dependencies = [];
      let dependents = {};
      if (!isEmpty(meta)) {
        dependencies = Object.keys(meta.dependency).reduce((final, dependent) => {
          const currEle = meta.dependency[dependent];
          const newDependencies = currEle.dependencies.reduce((prev, curr) => {
            if (!prev.includes(curr)) {
              return [...prev, curr];
            }
            return prev;
          }, final);
          return newDependencies;
        }, []);

        // compute  dependents for each group
        dependents = dependencies.reduce((finalDependents, dependency) => {
          const eleDependents = Object.keys(meta.dependency).reduce((prev, curr) => {
            const currEle = meta.dependency[curr];
            if (currEle.dependencies.includes(dependency)) {
              return [...prev, curr];
            }
            return prev;
          }, []);
          return { ...finalDependents, [dependency]: eleDependents };
        }, {});
      }

      const { formatedData, keyTitleMapping } = formatedFilterData(data);

      const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
        const { groupTitle, meta: groupMeta } = groupDetails;
        if (!isEmpty(groupMeta)) {
          return { ...meta, [groupTitle]: groupMeta };
        }
        return meta;
      }, {});

      yield put(
        Creators.getFilterDataSuccess({
          data: formatedData,
          CategoryOptions,
          meta: { ...meta, ...groupLevelMeta },
          resultType,
          parent: tab,
          dependencies,
          dependents,
          keyTitleMapping,
          menuId
        })
      );
    } else {
      const payload = sagaCatchBlockHandling(error);
      if (!updatedGroup) {
        yield put(Creators.getFilterDataFail(payload));
      } else {
        yield put(Creators.getFilterDependencyDataFail(dependents));
      }
    }
  }
}

export function* getIFrameDataSaga(action) {
  try {
    yield put(Creators.getIFrameDataStart());

    const url = `${apiEndPoints.getGraphURI}`;

    // console.log(action.payload);

    const response = yield WebService.post(url, JSON.stringify(action.payload));
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log('iframe-data', data);
      yield put(Creators.getIFrameDataSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      payload = {
        error: 'No connection try again Later.'
      };
    }

    yield put(Creators.getIFrameDataFail(payload));
  }
}
