/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SearchBar from '../../component/Common/SearchBar/SearchBar';
import AnalyticsTable from '../../component/MyAnalytics/AnalyticsTable';
import { ActiveDivider, Container, HeaderText, HeadingBox, HeadingContainer } from './MyAnalytics.style';

const MyAnalytics = () => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Container>
      <HeadingContainer>
        <HeadingBox>
          <HeaderText>{`My ${localeStrings.Analytics || 'Analytics'}`}</HeaderText>
          <ActiveDivider />
        </HeadingBox>
        <SearchBar
          placeholder='Search Analytics'
          searchValue={searchValue}
          onChangeHandler={setSearchValue}
          width='36'
        />
      </HeadingContainer>
      <AnalyticsTable searchValue={searchValue} setSearchValue={setSearchValue} />
    </Container>
  );
};

export default MyAnalytics;
