/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ItemContainer,
  Radio,
  RadioIcon,
  ScaleEntryDiv,
  ColorLabelBox,
  ColorLabelTxt,
  LegendItemContainer,
  RangeInpDiv,
  ScaleInp
} from './ScaleYAxis.style';
import CollapsableContainer from '../CollapsableContainer/CollapsableContainer.view';
import { Creators as UpsertPaneCreators } from '../Store';
import { validate, VALIDATION_TYPES } from '../../../shared/utility/regex';
import { CHART_TYPE_CATEGORY } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';

export const ManualScale = ({ isModal }) => {
  const { scaleYaxis, multiAxis, scaleYAxisRange, panesData, chartTypeCategory } = useSelector(
    (state) => state.upsertPaneData
  );
  const [isPassed, setIspassed] = useState(true);
  const dispatch = useDispatch();
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const labelsData = panesData.length && Object.keys(panesData[0].meta).map((e) => panesData[0].meta[e]);
  useEffect(() => {
    const defaultObj = { from: '', to: '' };
    // if (multiAxis) {
    let value = {};
    if (chartTypeCategory !== CHART_TYPE_CATEGORY.SPC) {
      const yAxisRanges = new Array(labelsData.length);

      yAxisRanges.fill(defaultObj);
      value = scaleYAxisRange.length > 1 ? scaleYAxisRange : yAxisRanges;
    }
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'scaleYAxisRange',
        value
      })
    );
    // } else {
    //   dispatch(
    //     UpsertPaneCreators.setUpsertPaneData({
    //       key: 'scaleYAxisRange',
    //       value: scaleYAxisRange.length === 1 ? scaleYAxisRange : [defaultObj]
    //     })
    //   );
    // }
  }, [panesData, multiAxis, scaleYaxis]);

  const validateRangeInps = () => {
    const isErrPresent = scaleYAxisRange.some(
      (e) => (e.from && !validate(e.from, VALIDATION_TYPES.FLOAT)) || (e.to && !validate(e.to, VALIDATION_TYPES.FLOAT))
    );
    setIspassed(!isErrPresent);
  };

  // useEffect(() => {
  //   validateRangeInps();
  // }, [scaleYAxisRange]);

  const handleRangeInp = (e, index, key) => {
    const string = e.target.value;
    // if (isPassed || e.target.value[0] === '-') {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'scaleYAxisRange',
        value: [
          ...scaleYAxisRange.slice(0, index),
          { ...scaleYAxisRange[index], [key]: string },
          ...scaleYAxisRange.slice(index + 1)
        ]
      })
    );
    // } else {
    //   dispatch(
    //     UpsertPaneCreators.setUpsertPaneData({
    //       key: 'scaleYAxisRange',
    //       value: [
    //         ...scaleYAxisRange.slice(0, index),
    //         { ...scaleYAxisRange[index], [key]: '' },
    //         ...scaleYAxisRange.slice(index + 1)
    //       ]
    //     })
    //   );
    // }
  };

  // const RangeInputs = ({ index }) => (
  //   <RangeInpDiv>
  //     <ScaleInp
  //       type='text'
  //       value={scaleYAxisRange[index]?.from}
  //       placeholder={isModal ? 'start value' : ''}
  //       onChange={(value) => handleRangeInp(value, index, 'from')}
  //     />
  //     {isModal && <span>to</span>}
  //     <ScaleInp
  //       type='text'
  //       value={scaleYAxisRange[index]?.to}
  //       placeholder={isModal ? 'end value' : ''}
  //       onChange={(value) => handleRangeInp(value, index, 'to')}
  //     />
  //   </RangeInpDiv>
  // );
  // console.log('isPassed', isPassed);

  const handleRangeInpByOverlay = (e, overlay, key) => {
    const string = e.target.value;
    // if (isPassed || e.target.value[0] === '-') {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'scaleYAxisRange',
        value: {
          ...scaleYAxisRange,
          [overlay]: { ...scaleYAxisRange[overlay], [key]: string }
        }
      })
    );
  };

  if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
    let defaultOverlays = [];
    if (!isEmpty(panesData) && !isEmpty(panesData[0].meta)) {
      defaultOverlays = Object.keys(Object.values(panesData[0].meta)?.[0]?.default);
    }
    if (scaleYaxis !== 'manual') return null;
    return defaultOverlays.map((overlay, index) => (
      <React.Fragment key={index}>
        <LegendItemContainer>
          <ColorLabelBox bgColor={Object.values(panesData[0].meta)?.[0]?.default[overlay][0].color} />
          <ColorLabelTxt>{overlay}</ColorLabelTxt>
        </LegendItemContainer>
        <RangeInpDiv>
          <ScaleInp
            type='text'
            // onBlur={validateRangeInps}
            value={scaleYAxisRange[overlay]?.from}
            placeholder={isModal ? 'Start value' : ''}
            onChange={(value) => handleRangeInpByOverlay(value, overlay, 'from')}
          />
          {isModal && <span>{localeStrings.to || 'to'}</span>}
          <ScaleInp
            // onBlur={validateRangeInps}
            type='text'
            value={scaleYAxisRange[overlay]?.to}
            placeholder={isModal ? 'End value' : ''}
            onChange={(value) => handleRangeInpByOverlay(value, overlay, 'to')}
          />
        </RangeInpDiv>
      </React.Fragment>
    ));
  }

  return (
    <>
      {!isPassed && (
        <ColorLabelTxt style={{ color: 'red' }}>Error in input, Please enter only integers/floats</ColorLabelTxt>
      )}
      {scaleYaxis === 'manual' &&
        (multiAxis ? (
          <ScaleEntryDiv>
            <>
              {labelsData.map(
                (item, index, arr) => (
                  <React.Fragment key={index}>
                    {/* {console.log('item', item, labelsData)} */}
                    <LegendItemContainer>
                      <ColorLabelBox bgColor={colorCodes[index]} />
                      <ColorLabelTxt>{item?.fields?.displayname}</ColorLabelTxt>
                    </LegendItemContainer>
                    {/* <RangeInputs index={index} /> */}
                    <RangeInpDiv>
                      <ScaleInp
                        type='text'
                        onBlur={validateRangeInps}
                        value={scaleYAxisRange[index]?.from}
                        placeholder={isModal ? 'Start value' : ''}
                        onChange={(value) => handleRangeInp(value, index, 'from')}
                      />
                      {isModal && <span>{localeStrings.to || 'to'}</span>}
                      <ScaleInp
                        onBlur={validateRangeInps}
                        type='text'
                        value={scaleYAxisRange[index]?.to}
                        placeholder={isModal ? 'End value' : ''}
                        onChange={(value) => handleRangeInp(value, index, 'to')}
                      />
                    </RangeInpDiv>
                  </React.Fragment>
                )
                // eslint-disable-next-line function-paren-newline
              )}
            </>
          </ScaleEntryDiv>
        ) : (
          <RangeInpDiv>
            <ScaleInp
              onBlur={validateRangeInps}
              type='text'
              value={scaleYAxisRange[0]?.from}
              placeholder={isModal ? 'Start value' : ''}
              onChange={(value) => handleRangeInp(value, 0, 'from')}
            />
            {!isModal && <span>{localeStrings.to || 'to'}</span>}
            <ScaleInp
              onBlur={validateRangeInps}
              type='text'
              placeholder={isModal ? 'End value' : ''}
              value={scaleYAxisRange[0]?.to}
              onChange={(value) => handleRangeInp(value, 0, 'to')}
            />
          </RangeInpDiv>
        ))}
    </>
  );
};

const ScaleYAxis = ({ header, scaleYaxisOpt }) => {
  const { scaleYaxis } = useSelector((state) => state.upsertPaneData);
  const dispatch = useDispatch();
  const { localeStrings } = useSelector((state) => state.localeStrings);

  const handleScaleType = (value) => {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'scaleYaxis',
        value
      })
    );
    if (value !== 'manual') {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'scaleYAxisRange',
          value: []
        })
      );
    }
  };

  // hardcoded this used changed it dynamic vlaues from props
  // const scaleYaxisOpt = [
  //   { label: 'Auto', value: 'auto' },
  //   { label: 'Process Window', value: 'processWindow' },
  //   { label: 'Manual', value: 'manual' }
  // ];

  // header={localeStrings['Scale Y-Axis'] || 'Scale Y-Axis'}
  return (
    <CollapsableContainer header={header}>
      <>
        {scaleYaxisOpt.map((ele, index) => {
          const { label, value } = ele;
          return (
            <ItemContainer key={index}>
              <Radio className='pointer' onClick={() => handleScaleType(value)}>
                {scaleYaxis === value && <RadioIcon />}
              </Radio>
              {localeStrings[label] || label}
            </ItemContainer>
          );
        })}
        <ManualScale />
      </>
    </CollapsableContainer>
  );
};

export default ScaleYAxis;
