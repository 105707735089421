/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { CHART_TYPES } from '../../../constants';
import { uid } from '../../../shared/utility';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getPlotData } from '../../KPVCharts/utils/formatPlotData';
import { Types } from './action';

const INITIAL_STATE = {
  paneTitle: '',
  chartType: CHART_TYPES.DATA_CHART,
  // new
  panesData: [],
  // remove
  plotData: [],
  labelsData: [],
  machinesSelected: [],
  multiSelectKpv: false,
  showDetails: {
    'y-axis': true,
    'x-axis': true,
    legends: true,
    annotatedPts: false
  },
  showOverlays: {},
  multiAxis: false,
  singleGrid: true,
  scaleYaxis: 'auto',
  chartTypeSub: 'timeSeries',
  scaleYAxisRange: [],
  multiGrid: false,
  loading: false,
  id: uid(),
  annotations: [],

  // label chart add., details
  xType: [],
  xKPVType: [],
  seriesTypesSelections: {}
};
// utlity method
const setValue = (prop) => (prop.value !== undefined ? prop.value : INITIAL_STATE[prop.key]);

const setUpsertPaneData = (state = INITIAL_STATE, action) => ({
  ...state,
  [action.payload.key]: action.payload.value
});

const setSeriesTypesSelections = (state = INITIAL_STATE, { payload }) => {
  const isCommon = Object.keys(payload)?.[0] === 'common';
  const { common, ...kpvsSeriesTypes } = state.seriesTypesSelections;
  const payloadTobeSet = isCommon ? payload : { ...kpvsSeriesTypes, ...payload };
  return {
    ...state,
    seriesTypesSelections: payloadTobeSet
  };
};

const setChartType = (state = INITIAL_STATE, action) => {
  const { chartType, chartTypeCategory } = action.payload;
  return {
    ...state,
    chartType,
    chartTypeCategory
  };
};

const setCompleteUpsertPaneData = (state = INITIAL_STATE, action) => {
  const {
    // required always
    machinesSelected,
    chartType,
    chartTypeCategory,
    transitionChartType,

    // required if selected
    multiAxis,
    showDetails,
    scaleYaxis,
    scaleYAxisRange,
    multiGrid,
    chartTypeSub,

    // can be infered
    paneTitle,
    plotData,
    labelsData,
    multiSelectKpv,
    rawPlotData,
    id,
    showOverlays,
    showAlarms,
    annotations,
    alarmsData,
    // for widget
    StartTime,
    xMinDomainProp,
    EndTime,
    xMaxDomainProp,
    // newly added
    seriesTypesSelections,
    xType,
    yType,
    xKPVType
  } = action.payload;
  return {
    ...state,
    id,
    paneTitle,
    plotData,
    labelsData,
    machinesSelected: setValue({ key: 'machinesSelected', value: machinesSelected }),
    multiSelectKpv: setValue({ key: 'multiSelectKpv', value: multiSelectKpv }),
    showDetails: setValue({ key: 'showDetails', value: showDetails }),
    showOverlays: setValue({ key: 'showOverlays', value: showOverlays }),
    showAlarms: setValue({ key: 'showAlarms', value: showAlarms }),
    alarmsData: setValue({ key: 'alarmsData', value: alarmsData }),
    multiAxis: setValue({ key: 'multiAxis', value: multiAxis }),
    scaleYaxis: setValue({ key: 'scaleYaxis', value: scaleYaxis }),
    chartTypeSub: setValue({ key: 'chartTypeSub', value: chartTypeSub }),
    scaleYAxisRange: setValue({ key: 'scaleYAxisRange', value: scaleYAxisRange }),
    multiGrid: setValue({ key: 'multiGrid', value: multiGrid }),
    chartType: setValue({ key: 'chartType', value: chartType }),
    chartTypeCategory: setValue({ key: 'chartTypeCategory', value: chartTypeCategory }),
    transitionChartType: setValue({ key: 'transitionChartType', value: transitionChartType }),
    panesData: rawPlotData ? [rawPlotData] : [],
    StartTime: setValue({ key: 'StartTime', value: StartTime || xMinDomainProp }),
    EndTime: setValue({ key: 'EndTime', value: EndTime || xMaxDomainProp }),
    annotations,
    seriesTypesSelections: setValue({ key: 'seriesTypesSelections', value: seriesTypesSelections }),
    xType: setValue({ key: 'xType', value: xType }),
    yType: setValue({ key: 'yType', value: yType }),
    xKPVType: setValue({ key: 'xKPVType', value: xKPVType })
  };
};

const setShowDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  showDetails: { ...state.showDetails, [action.payload.key]: action.payload.value }
});

const getPanePlotDataStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getPanePlotDataSuccess = (state = INITIAL_STATE, action) => {
  // console.log('action.payload', action.payload);
  const { chartType, chartTitle, reqPayload } = action.payload;
  const { singlePane, kpvDetails, category, chartTypeCategory } = reqPayload;
  // console.log('reqpppp', reqPayload, category.toLowerCase());
  const res = {
    ...state,
    panesData: [
      getPlotData(action.payload[category.toLowerCase()][0], chartType, chartTypeCategory, { xKPVType: state.xType })
    ],
    ...reqPayload,
    loading: false
  };
  return res;
};

const getPanePlotDataFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const resetUpsertPaneData = () => INITIAL_STATE;

const HANDLERS = {
  [Types.SET_UPSERT_PANE_DATA]: setUpsertPaneData,
  [Types.SET_CHART_TYPE]: setChartType,
  [Types.SET_SHOW_DETAILS]: setShowDetails,
  [Types.RESET_UPSERT_PANE_DATA]: resetUpsertPaneData,
  [Types.SET_COMPLETE_UPSERT_PANE_DATA]: setCompleteUpsertPaneData,

  [Types.GET_PANE_PLOT_DATA_START]: getPanePlotDataStart,
  [Types.GET_PANE_PLOT_DATA_SUCCESS]: getPanePlotDataSuccess,
  [Types.GET_PANE_PLOT_DATA_FAILURE]: getPanePlotDataFailure,

  [Types.SET_SERIES_TYPES_SELECTIONS]: setSeriesTypesSelections
};
export const UpsertPaneReducer = createReducer(INITIAL_STATE, HANDLERS);
