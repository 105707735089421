/* eslint-disable nonblock-statement-body-position */
/* eslint-disable prefer-destructuring */
/* eslint-disable newline-per-chained-call */
/* eslint-disable max-len */
import lodash, { isEmpty } from 'lodash';
import moment from 'moment';
import { createReducer } from 'reduxsauce';
import { graphFormat } from '../../../config/index';
import { CHART_TYPES } from '../../../constants';
import { uid } from '../../../shared/utility';
// import { sampleTransitionChartDetails } from '../EditOptions/sampleData';
import { setAnnotationsDetails } from '../PaneCard/Annotations/utils';
import { getAnnotations, getPlotData, setPlotData } from '../utils/formatPlotData';
import { Types } from './action';

// const initXMinDomain = moment().subtract(1, 'd').set('hour', 9).set('minute', 0).set('seconds', 0);

// const initXMinDomain = moment('2022-02-22 09:00:00.000');
// const initXMinDomain = new Date();

const initXMinDomain = moment().subtract(24, 'h');
// const initXMinDomain = new Date();

const INITIAL_STATE = {
  singlePane: true,
  xMinDomain: initXMinDomain,
  xMaxDomain: moment(),
  // test
  // xMinDomain: '2022-02-22T04:30:00.000Z',
  // xMaxDomain: '2022-02-22T06:30:00.000Z',
  xDomainDuration: { shorthand: 'h', shorthandValue: 24 },
  latestAvailable: null, // get latest available data for charts
  // xMaxDomain: moment(),

  // domain wihh api
  // xMinDomain: moment('2022-02-01T06:30:00.000Z'),
  // xMaxDomain: moment('2022-02-05T06:30:00.000Z'),

  // local response
  // xMinDomain: moment('2022-03-03T06:56:00.000Z'),
  // xMaxDomain: moment('2022-03-03T07:01:00.000Z'),
  panesShortHandInfo: [],
  panesData: [],
  machinesShortlisted: [],
  paneFullScreen: false,
  xMinDomainFullScreen: initXMinDomain,
  xMaxDomainFullScreen: moment(initXMinDomain).add(180, 'm'),
  selectedPaneNo: '',
  compressedView: false,
  plotData: {},
  loading: false,
  error: '',
  optionsError: '',
  chartType: CHART_TYPES.DATA_CHART,
  colorCodes: ['#1846ed', '#892dcf', '#cfa725', '#d40f1f', '#ba0fb2', '#76a31c', '#18a89c', '#cf7508'],
  optionsLoading: false,
  upsertPaneOptions: {},
  editPaneMenu: {},
  maxAxesWidth: 0,
  comparisionMode: false,
  isAligned: false,

  additionalFilterData: [],
  showAdditionalFilter: [],
  filterFilesSelected: {},
  assetAdditionalInfo: {},
  xMaxDomainForFiltered: [],
  xMinDomainForFiltered: [],

  globalHoveredState: [],
  pointClicked: {},

  maxCharLength: 0,
};

const showDetailsInit = {
  'y-axis': true,
  'x-axis': true,
  legends: true,
  // annotatedPts: false,
  annotations: false,
  isAddAnnotationEnabled: false
};

const genericKPVPropertySetter = (state = INITIAL_STATE, action) => {
  const {
    payload: { key, value }
  } = action;
  return {
    ...state,
    [key]: value
  };
};

const setAnnotations = (state = INITIAL_STATE, action) => {
  // populate, add, update, delete
  const { index, operation, value } = action.payload;
  const { argument } = value;
  let newInfo;
  if (operation === 'add') {
    newInfo = {
      ...state.panesShortHandInfo[index],
      annotations: [...(state.panesShortHandInfo[index].annotations || []), { ...value }]
    };
  } else if (operation === 'edit') {
    newInfo = {
      ...state.panesShortHandInfo[index],
      annotations: [
        ...(state.panesShortHandInfo[index].annotations.map((e) => {
          if (e.argument === argument) {
            return { ...e, ...value };
          }
          return e;
        }) || [])
      ]
    };
  } else if (operation === 'delete') {
    newInfo = {
      ...state.panesShortHandInfo[index],
      annotations: [...(state.panesShortHandInfo[index].annotations.filter((e) => e.argument !== argument) || [])]
    };
  }

  return {
    ...state,
    panesShortHandInfo: [
      ...state.panesShortHandInfo.slice(0, index),
      { ...newInfo },
      ...state.panesShortHandInfo.slice(index + 1)
    ]
  };
};

const setMaxAxesWidth = (state = INITIAL_STATE, action) => ({
  ...state,
  maxAxesWidth: action.payload
});

const setPaneType = (state = INITIAL_STATE, action) => ({
  ...state,
  singlePane: action.payload
});

const setAssetAdditionalInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  assetAdditionalInfo: { ...state.assetAdditionalInfo, [action.payload.chartId]: action.payload.chartObj }
});

const resetAssetAdditionalInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  assetAdditionalInfo: {}
});
const operateDomainRange = (state = INITIAL_STATE, action) => {
  if (action.payload.operation === '-') {
    return {
      ...state,
      [action.payload.key]: new Date(moment(state[action.payload.key]).subtract(action.payload.value, 'm'))
    };
  }
  return {
    ...state,
    [action.payload.key]: new Date(moment(state[action.payload.key]).add(action.payload.value, 'm'))
  };
};

const setDomainRange = (state = INITIAL_STATE, action) => ({
  ...state,
  // xMinDomain: action.payload.xMinDomain,
  // xMaxDomain: action.payload.xMaxDomain
  ...action.payload
});

const setDomainRangeInFullScreen = (state = INITIAL_STATE, action) => ({
  ...state,
  // xMinDomainFullScreen: action.payload.xMinDomainFullScreen,
  // xMaxDomainFullScreen: action.payload.xMaxDomainFullScreen
  ...action.payload
});

const setPaneData = (state = INITIAL_STATE, action) => {
  const { plotData, labelsData, data, paneData, ...shortHandInfoObj } = action.payload;
  return {
    ...state,
    panesData: [...state.panesData, { rawPlotData: paneData }],
    panesShortHandInfo: [...state.panesShortHandInfo, { ...shortHandInfoObj }]
  };
};

const setPanesShortHandInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  panesShortHandInfo: [...action.payload]
});

const setMaxCharLength = (state = INITIAL_STATE, action) => ({
  ...state,
  maxCharLength: action.payload
});

const setChartDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload,
  panesData: INITIAL_STATE.panesData
});

const setPanePlotData = (state = INITIAL_STATE, action) => {
  const { plotData, labelsData, data, index } = action.payload;
  return {
    ...state,
    panesData: [...state.panesData.slice(0, index), { plotData, labelsData, data }, ...state.panesData.slice(index + 1)]
  };
};

const editCompletePaneData = (state = INITIAL_STATE, action) => {
  const { index, paneInfo } = action.payload;
  const { plotData, labelsData, data, paneData, ...shortHandInfoObj } = paneInfo;
  return {
    ...state,
    panesShortHandInfo: [
      ...state.panesShortHandInfo.slice(0, index),
      { ...shortHandInfoObj },
      ...state.panesShortHandInfo.slice(index + 1)
    ],
    panesData: [...state.panesData.slice(0, index), { rawPlotData: paneData }, ...state.panesData.slice(index + 1)]
  };
};

const editSetOfPaneDataProps = (state = INITIAL_STATE, action) => {
  const { index, changes } = action.payload;
  const plotDataProps = ['labelsData', 'data', 'plotData'];

  const newPropsForShortHandInfo = {};
  const newPropsForPanesData = {};
  changes.forEach((change) => {
    const { key, value } = change;
    if (!plotDataProps.includes(key)) newPropsForShortHandInfo[key] = value;
    else newPropsForPanesData[key] = value;
  });

  return {
    ...state,
    panesData: [
      ...state.panesData.slice(0, index),
      { ...state.panesData[index], ...newPropsForPanesData },
      ...state.panesData.slice(index + 1)
    ],
    panesShortHandInfo: [
      ...state.panesShortHandInfo.slice(0, index),
      { ...state.panesShortHandInfo[index], ...newPropsForShortHandInfo },
      ...state.panesShortHandInfo.slice(index + 1)
    ]
  };
};

const editPaneData = (state = INITIAL_STATE, action) => {
  const { index, key, value } = action.payload;
  if (key === 'labelsData' || key === 'data' || key === 'plotData') {
    return {
      ...state,
      panesData: [
        ...state.panesData.slice(0, index),
        { ...state.panesData[index], [key]: value },
        ...state.panesData.slice(index + 1)
      ]
    };
  }

  return {
    ...state,
    panesShortHandInfo: [
      ...state.panesShortHandInfo.slice(0, index),
      { ...state.panesShortHandInfo[index], [key]: value },
      ...state.panesShortHandInfo.slice(index + 1)
    ]
  };
};

const editPropertyofPanes = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    panesShortHandInfo: state.panesShortHandInfo.map((pane) => ({ ...pane, [key]: value }))
  };
};

const setPointInfoforPanes = (state = INITIAL_STATE, action) => {
  const { time } = action.payload;
  const modPanesData = state.panesData?.map((pane, index) => {
    const obj = pane?.data?.map((paneData) => paneData.find((e) => e.time && e.time.getTime() === time.getTime()));
    return { ...state.panesShortHandInfo[index], pointInfoObj: obj };
  });
  return {
    ...state,
    panesShortHandInfo: modPanesData
  };
};

const setHoveredXValue = (state = INITIAL_STATE, action) => ({
  ...state,
  hoveredXValue: action.payload
});

const removePane = (state = INITIAL_STATE, action) => {
  const index = action.payload;
  return {
    ...state,
    panesData: [...state.panesData.slice(0, index), ...state.panesData.slice(index + 1)],
    panesShortHandInfo: [...state.panesShortHandInfo.slice(0, index), ...state.panesShortHandInfo.slice(index + 1)]
  };
};

const setMachinesShortlisted = (state = INITIAL_STATE, action) => ({
  ...state,
  machinesShortlisted: action.payload
});

const setPaneFullScreen = (state = INITIAL_STATE, action) => ({
  ...state,
  paneFullScreen: true
});

const exitPaneFullScreen = (state = INITIAL_STATE, action) => ({
  ...state,
  paneFullScreen: false,
  // xMinDomain: INITIAL_STATE.xMinDomain,
  // xMaxDomain: INITIAL_STATE.xMaxDomain,
  selectedPaneNo: INITIAL_STATE.selectedPaneNo
});

const setSelectedPaneNo = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedPaneNo: action.payload
});

const toggleCompressedView = (state = INITIAL_STATE, action) => ({
  ...state,
  compressedView: !state.compressedView
});

const getPlotDataStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  panesData: !action.payload?.isSingleUpdate ? state.panesData.map((e) => ({ rawPlotData: {} })) : state.panesData
});

const getPlotDataSuccess = (state = INITIAL_STATE, action) => {
  const { plotData } = action.payload;
  // console.log('plotData in reducer', plotData, { ...lodash.merge({ ...state.plotData }, { ...plotData }) });
  return {
    ...state,
    plotData: { ...lodash.merge(lodash.cloneDeep(state.plotData), plotData) },
    loading: false
  };
};

const setGraphFilterDetails = (state = INITIAL_STATE, action) => {
  const { data, singleGraph = false } = action.payload;
  let filter = [];
  let showFilter = [];
  let xMinDomainForFiltered = [];
  let xMaxDomainForFiltered = [];
  // Adding below condition to show fiter if pane is added from Add pane in comparision mode
  if (singleGraph) {
    showFilter = [...state.showAdditionalFilter];
    filter = [...state.additionalFilterData];
    xMinDomainForFiltered = [...state.xMinDomainForFiltered];
    xMaxDomainForFiltered = [...state.xMaxDomainForFiltered];
    filter.push(data?.FilterDetails);
    showFilter.push(data?.ShowFilter);
    xMinDomainForFiltered.push(data?.StartTime);
    xMaxDomainForFiltered.push(data?.StartTime);
  } else {
    data?.forEach((d) => {
      filter.push(d?.FilterDetails);
      showFilter.push(d?.ShowFilter);
      xMinDomainForFiltered.push(d?.StartTime);
      xMaxDomainForFiltered.push(d?.EndTime);
    });
  }
  return {
    ...state,
    additionalFilterData: filter,
    showAdditionalFilter: showFilter,
    xMinDomainForFiltered,
    xMaxDomainForFiltered
  };
};

const setFilterFilesSelected = (state = INITIAL_STATE, action) => ({
  ...state,
  filterFilesSelected: action.payload,
});

const addPaneDetailsSuccess = (state = INITIAL_STATE, action) => {
  // console.log('action.payload', action.payload);
  const { data, isUpdate, index, currentPaneInfoObj } = action.payload;
  const res = {
    ...state,
    loading: false
  };
  // const addPaneDetails = setPlotData(sampleTransitionChartDetails);
  // console.log('addPaneDetails', data);
  const addPaneDetails = setPlotData(data);
  if (!index && index !== 0) {
    res.panesData = isUpdate ? [...addPaneDetails] : [...state.panesData, ...addPaneDetails];
  } else if (isUpdate && index >= 0) {
    res.panesData = [...state.panesData.slice(0, index), ...addPaneDetails, ...state.panesData.slice(index + 1)];
  }

  // if they are not equal implies no need to update shorthadInfo
  // if they are equal then it implies we need to update it with details
  if (state.panesData.length === state.panesShortHandInfo.length) {
    let addShortHandInfoObj = [];
    data.forEach((paneDl, key) => {
      const { chartTitle, reqPayload } = paneDl;
      const { singlePane, chartTypeCategory, category, StartTime, EndTime } = reqPayload;

      // annotations
      const rawPlotData = paneDl[category.toLowerCase()][0];
      const annotations = getAnnotations(rawPlotData, key, chartTypeCategory);
      let plotInfoObject;
      if (singlePane || singlePane === undefined) {
        // console.log('single');
        // plotInfoObject = { chartTitle, singlePane, chartType, category, kpv: kpvDetails };
        if (isEmpty(currentPaneInfoObj)) {
          const prevData = state.panesShortHandInfo ? { ...state.panesShortHandInfo[key] } : {};
          // console.log('empty currentPane', prevData, key, state.panesShortHandInfo);
          plotInfoObject = {
            id: uid(),
            showDetails: showDetailsInit,
            singlePane: true,
            ...prevData,
            chartTitle,
            chartTypeCategory,
            annotations,
            ...reqPayload
          };
        } else if (isUpdate && !isEmpty(currentPaneInfoObj)) {
          // console.log('non-empty currentPane');
          plotInfoObject = {
            id: uid(),
            showDetails: showDetailsInit,
            annotations,
            chartTypeCategory,
            ...currentPaneInfoObj,
            StartTime,
            EndTime
          };
        }
        addShortHandInfoObj = [...addShortHandInfoObj, plotInfoObject];
      }
    });

    if (!index && index !== 0) {
      res.panesShortHandInfo = isUpdate
        ? [...addShortHandInfoObj]
        : [...state.panesShortHandInfo, ...addShortHandInfoObj];
    } else if (isUpdate && index >= 0) {
      res.panesShortHandInfo = [
        ...state.panesShortHandInfo.slice(0, index),
        ...addShortHandInfoObj,
        ...state.panesShortHandInfo.slice(index + 1)
      ];
    }
  }
  return res;
};

// const alignStartingPoint = (state = INITIAL_STATE, action) => {
//   console.log('panesShortHandInfo', state.panesShortHandInfo);
//   return ({
//     ...state,
//     panesShortHandInfo: state.panesShortHandInfo.map((shInfo) => {
//       const startTimeInit = moment(shInfo.compStartTime).subtract(5, 'minutes');
//       return ({
//         ...shInfo,
//         StartTime: startTimeInit.format(graphFormat),
//         EndTime: startTimeInit.add(35, 'minutes').format(graphFormat)
//       });
//     })
//   });
// };

const getPlotDataFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const resetPanesData = (state = INITIAL_STATE, action) => ({
  ...state,
  panesData: [],
  panesShortHandInfo: [],
  xMinDomain: INITIAL_STATE.xMinDomain,
  xMaxDomain: INITIAL_STATE.xMaxDomain
});

const setChartType = (state = INITIAL_STATE, action) => ({
  ...state,
  chartType: action.payload
});

const setColorCodes = (state = INITIAL_STATE, action) => ({
  ...state,
  colorCodes: [...state.colorCodes, ...action.payload]
});

const setPaneOptionsStart = (state = INITIAL_STATE, action) => ({ ...state, optionsLoading: true, optionsError: '' });

const setPaneOptionsSuccess = (state = INITIAL_STATE, action) => {
  const { key, value, chartType } = action.payload;
  return { ...state, optionsLoading: false, [key]: { ...state[key], [chartType]: value } };
};

const setPaneOptionsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  optionsLoading: false,
  optionsError: action.payload
});

const setChartTypes = (state = INITIAL_STATE, action) => ({
  ...state,
  chartOptions: action.payload
});

const HANDLERS = {
  [Types.GENERIC_KPV_PROPERTY_SETTER]: genericKPVPropertySetter,
  [Types.SET_ANNOTATIONS]: setAnnotations,
  [Types.SET_MAX_AXES_WIDTH]: setMaxAxesWidth,
  [Types.SET_PANE_TYPE]: setPaneType,
  [Types.OPERATE_DOMAIN_RANGE]: operateDomainRange,
  [Types.SET_DOMAIN_RANGE]: setDomainRange,
  [Types.SET_DOMAIN_RANGE_IN_FULL_SCREEN]: setDomainRangeInFullScreen,
  [Types.SET_PANE_DATA]: setPaneData,
  [Types.SET_PANES_SHORT_HAND_INFO]: setPanesShortHandInfo,
  [Types.SET_MACHINES_SHORTLISTED]: setMachinesShortlisted,
  [Types.EDIT_COMPLETE_PANE_DATA]: editCompletePaneData,
  [Types.EDIT_PANE_DATA]: editPaneData,
  [Types.EDIT_SET_OF_PANE_DATA_PROPS]: editSetOfPaneDataProps,
  [Types.EDIT_PROPERTYOF_PANES]: editPropertyofPanes,
  [Types.SET_POINT_INFOFOR_PANES]: setPointInfoforPanes,
  [Types.REMOVE_PANE]: removePane,
  [Types.SET_PANE_FULL_SCREEN]: setPaneFullScreen,
  [Types.EXIT_PANE_FULL_SCREEN]: exitPaneFullScreen,
  [Types.SET_SELECTED_PANE_NO]: setSelectedPaneNo,
  [Types.TOGGLE_COMPRESSED_VIEW]: toggleCompressedView,
  [Types.RESET_PANES_DATA]: resetPanesData,

  [Types.GET_PLOT_DATA_START]: getPlotDataStart,
  [Types.GET_PLOT_DATA_SUCCESS]: getPlotDataSuccess,
  [Types.ADD_PANE_DETAILS_SUCCESS]: addPaneDetailsSuccess,
  [Types.SET_GRAPH_FILTER_DETAILS]: setGraphFilterDetails,
  [Types.SET_FILTER_FILES_SELECTED]: setFilterFilesSelected,
  [Types.GET_PLOT_DATA_FAILURE]: getPlotDataFailure,
  [Types.SET_CHART_TYPE]: setChartType,
  [Types.SET_PANE_PLOT_DATA]: setPanePlotData,
  [Types.SET_COLOR_CODES]: setColorCodes,
  [Types.SET_PANE_OPTIONS_START]: setPaneOptionsStart,
  [Types.SET_PANE_OPTIONS_SUCCESS]: setPaneOptionsSuccess,
  [Types.SET_PANE_OPTIONS_FAILURE]: setPaneOptionsFailure,
  [Types.SET_HOVERED_X_VALUE]: setHoveredXValue,
  [Types.SET_CHART_DETAILS]: setChartDetails,
  [Types.SET_CHART_TYPES]: setChartTypes,
  [Types.SET_ASSET_ADDITIONAL_INFO]: setAssetAdditionalInfo,
  [Types.RESET_ASSET_ADDITIONAL_INFO]: resetAssetAdditionalInfo,

  [Types.SET_MAX_CHAR_LENGTH]: setMaxCharLength
  // [Types.ALIGN_STARTING_POINT]: alignStartingPoint
};
export const KPVChartsReducer = createReducer(INITIAL_STATE, HANDLERS);
