/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PointedDropdownModal from '../../Common/DropDownModal/PointedDropdownModal';
import { Creators as LayoutCreators } from '../../../views/Layout/store';
import { tutorials, faq, feedback, about } from '../../../assets/icons';

const HelpDropdown = () => {
  const helpDropdown = useSelector((state) => state.home.helpDropdown);
  const history = useHistory();
  const helpData = [
    { label: 'tutorials', value: 'tutorials', labelIcon: tutorials },
    { label: 'FAQs', value: 'FAQs', labelIcon: faq },
    { label: 'send feedback', value: 'sendFeedback', labelIcon: feedback },
    { label: 'about', value: 'about', labelIcon: about }
  ];

  const dispatch = useDispatch();

  const closeModal = () => {
    if (helpDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption('helpDropdown'));
    }
  };

  const handleClick = (value) => {
    closeModal();
    switch (value) {
      case 'FAQs':
        history.push('/home/FAQs');
        break;
      case 'tutorials':
        history.push('/home/tutorials');
        break;
      default:
        break;
    }
  };

  return <PointedDropdownModal data={helpData} closeModal={() => null} handleClick={handleClick} />;
};

export default HelpDropdown;
