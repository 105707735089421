/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tableItemFlags } from '../../../constants';
import { Div, FlexContainer } from '../../../globalStyles';
import { arrayToJson } from '../../../shared/utility';
import CheckBox from '../../CheckBox/CheckBox';
import ButtonComp from '../Button/Button.view';
import { Span } from '../KeyValueLayout/KeyValueLayout.style';
import { Heading, Modal, ModalContent, Button } from '../PopUpModal/Modal.style';
import TabsTable from '../Tabs_Table';
import { findChangedObjects, mergeConflictRecords } from '../Tabs_Table/utility';
import { columnsDetails, columnsDetailsXl, userData, userDataFromXl } from '../../Users/data';
import { ColorIndicatorDiv } from './ResolveUploadedRecords.style';

const selectionTypeandPosition = {
  isCheckboxTypeSelection: true,
  isSelectionAtFirst: true
};
const ResolveUploadedRecords = (props) => {
  const {
    usersTabelData,
    usersUploadedData,
    showDataDiffModal,
    setShowDataDiffModal,
    saveFinalRecords,
    uniqueId = 'employeeId'
  } = props;

  // records with 2 elements, used to show differences
  const [conflictedRecords, setConflictedRecords] = useState([]);
  // resolve and picked records from conflictedRecords
  const [resolvedRecords, setResolvedRecords] = useState([]);
  const [freshRecords, setFreshRecords] = useState([]);
  // items which are selected to resolve
  const [selectedConflictedItems, setSelectedConflictedItems] = useState([]);
  const [showFinalRecordsModal, setShowFinalRecordsModal] = useState(false);
  const { loading } = useSelector((state) => state.usersDetails);
  useEffect(() => {
    if (loading) {
      const [cr, fr] = findChangedObjects(usersTabelData, usersUploadedData, uniqueId);
      setConflictedRecords(cr);
      setFreshRecords(fr);
    }
  }, [usersTabelData, usersUploadedData]);

  // modal header functions
  const closeModal = () => {
    setShowFinalRecordsModal(false);
    setShowDataDiffModal(false);
  };
  const handleProceed = () => {
    setShowFinalRecordsModal(true);
  };

  const handleSave = () => {
    // setShowFinalRecordsModal(false);
    // setShowDataDiffModal(false);
    closeModal();
    const { replacedRecords, duplicatedRecords } = mergeConflictRecords(resolvedRecords);
    const resolvedRecordsObjs = arrayToJson(replacedRecords, uniqueId);
    saveFinalRecords({
      resolvedRecords: resolvedRecordsObjs, // object
      freshRecords: [...freshRecords, ...duplicatedRecords] // array
    });
    setResolvedRecords([]);
  };

  const handleConflictSelection = (item, isCheckRequired = true) => {
    if (isCheckRequired) {
      let isItemSelected = false;
      const newRecords = selectedConflictedItems.filter((e) => {
        if (e?.[uniqueId] == item?.[uniqueId]) {
          isItemSelected = true;
          return false;
        }
        return true;
      });
      if (!isItemSelected) {
        newRecords.push(item);
      }
      setSelectedConflictedItems(newRecords);
    } else {
      setSelectedConflictedItems([...item]);
    }
  };

  const pickedSelectedConflictedItems = selectedConflictedItems.length;
  // resolving conflicts functions
  const updateConflictedRecords = () => {
    let newConflictedRecords = [...conflictedRecords];
    const conflictSetsTobeResolved = [];
    selectedConflictedItems.forEach((conflictedRecord) => {
      const index = newConflictedRecords.findIndex((e) => e[0]?.[uniqueId] == conflictedRecord?.[uniqueId]);
      // console.log('found index', index, conflictedRecord, conflictedRecord?.[uniqueId]);
      conflictSetsTobeResolved.push(newConflictedRecords[index]);
      newConflictedRecords = [...newConflictedRecords.slice(0, index), ...newConflictedRecords.slice(index + 1)];
      // console.log('newConflictedRecords', newConflictedRecords);
    });
    setConflictedRecords(newConflictedRecords);
    setSelectedConflictedItems([]);
    // returns the item set of conflicts
    return conflictSetsTobeResolved;
  };
  const handleReplace = () => {
    const conflictSetsTobeResolved = updateConflictedRecords();
    const resolvedItems = conflictSetsTobeResolved.reduce((acc, e) => {
      const [oldRecord, newRecord] = e;
      return [...acc, { ...oldRecord, [tableItemFlags.SHOULD_DELETE]: true }, newRecord];
    }, []);
    setResolvedRecords((oldRecords) => [...oldRecords, ...resolvedItems]);
  };
  const handleAddCopy = () => {
    const conflictSetsTobeResolved = updateConflictedRecords();
    const resolvedItems = conflictSetsTobeResolved.map((e) => e[1]);
    setResolvedRecords((oldRecords) => [...oldRecords, ...resolvedItems]);
  };
  const handleDiscard = () => {
    updateConflictedRecords();
  };

  if (!showDataDiffModal) {
    return null;
  }
  // console.log('nnnnn', resolvedRecords, conflictedRecords, freshRecords);
  const differenceContent = (
    <>
      <FlexContainer className='jc_spacebetween ai_center'>
        <Heading>Add Multiple Users</Heading>
        <div>
          <ButtonComp style={{ marginRight: '3rem' }} dark onClick={closeModal}>
            Cancel
          </ButtonComp>
          <ButtonComp dark className={conflictedRecords.length ? 'disable' : ''} onClick={handleProceed}>
            Proceed
          </ButtonComp>
        </div>
      </FlexContainer>
      <TabsTable
        metaDetails={columnsDetailsXl}
        dataDetails={conflictedRecords}
        assetShortlist={selectedConflictedItems}
        isComparision
        showPagiation={false}
        shortListSelectionDetails={selectionTypeandPosition}
        styleType='attached showMinHeight'
        isGroupingUi={true}
        handleShortList={handleConflictSelection}
        noItemsFoundMessage='End of List'
      />
      <FlexContainer className='jc_spacebetween ai_center' style={{ padding: '1rem 2rem' }}>
        {/* TODO */}
        {/* <FlexContainer className='ai_center'>
          <CheckBox />
          <Span style={{ color: '#141414' }}>Select All</Span>
        </FlexContainer> */}
        <div />
        <FlexContainer className='ai_center' style={{ justifyContent: 'flex-end' }}>
          <ColorIndicatorDiv bg='#F70F0F' />
          <Span>Old Field Data</Span>
          <ColorIndicatorDiv bg='#00D5FF' />
          <Span>New Field Data</Span>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        className={`jc_spacearound ${!pickedSelectedConflictedItems ? 'disable' : ''}`}
        style={{ width: '40%', margin: '1rem 0 2rem 30%' }}
      >
        <ButtonComp dark onClick={handleReplace}>
          Replace
        </ButtonComp>
        <ButtonComp dark onClick={handleAddCopy}>
          Add a copy
        </ButtonComp>
        <ButtonComp dark onClick={handleDiscard}>
          Discard
        </ButtonComp>
      </FlexContainer>
    </>
  );

  const finalRecordsContent = (
    <>
      <FlexContainer className='jc_spacebetween ai_center'>
        <Heading>Add Multiple Users</Heading>
        <div>
          <ButtonComp style={{ marginRight: '3rem' }} dark onClick={closeModal}>
            Cancel
          </ButtonComp>
          <ButtonComp dark onClick={handleSave}>
            Save
          </ButtonComp>
        </div>
      </FlexContainer>
      <Span>Confirm the following changes :</Span>
      <TabsTable
        metaDetails={columnsDetailsXl}
        dataDetails={resolvedRecords}
        assetShortlist={[]}
        isComparision={false}
        showPagiation={false}
        shortListSelectionDetails={selectionTypeandPosition}
        styleType='attached'
        showShortlistSelection={false}
        handleShortList={() => {}}
      />
      <Span>List of newly added users :</Span>
      <TabsTable
        metaDetails={columnsDetailsXl}
        dataDetails={freshRecords}
        assetShortlist={[]}
        isComparision={false}
        showPagiation={false}
        shortListSelectionDetails={selectionTypeandPosition}
        styleType='attached'
        showShortlistSelection={false}
        handleShortList={() => {}}
      />
    </>
  );

  return (
    <>
      <Modal>
        <ModalContent width='85%' className='modal_view'>
          {showFinalRecordsModal ? finalRecordsContent : differenceContent}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResolveUploadedRecords;
