import { DEV_EXPRESS_CHART_TYPES } from '../../../../constants';

export const data = {
  data: [
    {
      id: 1,
      tabTitle: 'Mean',
      label: 'Overall',
      sbar: [
        {
          label: '2023 Q2',
          value: 68.59,
          value2: 61,
          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 85,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW27',
          value: 7.8,
          value2: 61,

          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 50,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW28',
          value: 71.78,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 75,
          min: 0,
          unit: '%'
        },
        {
          label: '14-01-2023',
          value: 78.04,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 85,
          min: 0,
          unit: '%'
        },
        {
          label: '15-01-2023',
          value: 78.18,
          value2: 61,

          target: 85,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '16-01-2023',
          value: 53.68,
          value2: 61,

          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 65,
          min: 0,
          unit: '%'
        },
        {
          label: '17-01-2023',
          value: 65.48,
          value2: 61,

          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 75,
          min: 0,
          unit: '%'
        },
        {
          label: '18-01-2023',
          value: 89.96,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 95,
          min: 0,
          unit: '%'
        },
        {
          label: '19-01-2023',
          value: 85.05,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 90,
          min: 0,
          unit: '%'
        },
        {
          label: '20-01-2023',
          value: 79.29,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 85,
          min: 0,
          unit: '%'
        }
      ]
    },
    {
      id: 2,
      tabTitle: 'Median',
      label: 'OEE Trend',
      sbar: [
        {
          label: '2023 Q2',
          value: 68.59,
          value2: 61,
          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 85,
          min: 0,
          unit: '%'
        },
        {
          label: '14-01-2023',
          value: 78.04,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 85,
          min: 0,
          unit: '%'
        },
        {
          label: '15-01-2023',
          value: 78.18,
          value2: 61,

          target: 85,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '16-01-2023',
          value: 53.68,
          value2: 61,

          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 65,
          min: 0,
          unit: '%'
        },
        {
          label: '17-01-2023',
          value: 65.48,
          value2: 61,

          target: 70.22,
          color: '#FF3333',
          color2: '#FFC0CB',

          max: 75,
          min: 0,
          unit: '%'
        },
        {
          label: '18-01-2023',
          value: 89.96,
          value2: 61,

          target: 70.22,
          color: '#33CC66',
          color2: '#FFC0CB',

          max: 95,
          min: 0,
          unit: '%'
        }
      ]
    }
  ],
  meta: {
    nodes: {
      // all the details of each ele are discribed below
      sbar: {
        node: 'sbar',
        labelMapping: {
          value: 'ACT%',
          target: 'TGT%',
          value2: 'Test Values'
        },
        charts: [
          // values tobe considered for the bar
          {
            type: 'Stacked Bar',
            values: ['value', 'value2']
          },
          // values tobe considered for the point
          {
            type: 'Point',
            values: ['target']
          }
        ],
        colorMappings: {
          value: 'color',
          value2: 'color2'
        },
        // {
        //   label: '2023 Q2',
        //   value: 68.59,
        //   value2: 61,
        //   target: 70.22,
        //   color: '#FF3333',
        //   color2: '#FFC0CB',

        //   max: 85,
        //   min: 0,
        //   unit: '%'
        // },
        gridKeys: ['value', 'target', 'value2']
      }
    }
  }
};

export const overall = {
  data: [
    {
      id: 1,
      tabTitle: 'Mean',
      label: 'Overall',
      sbar: [
        {
          label: 'AE',
          value: 95.69,
          loss: 4.31,
          target: 84.0,
          actvalue: 95.69,
          acttarget: 84.0,
          valueColor: '#007A04',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: 'PE',
          value: 53.68,
          loss: 42.01,
          target: 79.99,
          actvalue: 56.1,
          acttarget: 83.59,
          valueColor: '#FF0000',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: 'QE',
          value: 53.68,
          loss: 0,
          target: 53.68,
          actvalue: 100,
          acttarget: 100,
          valueColor: '#007A04',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    },
    {
      id: 2,
      tabTitle: 'Median',
      label: 'OEE Trend',
      sbar: [
        {
          label: 'AE',
          value: 95.69,
          loss: 4.31,
          target: 84.0,
          actvalue: 95.69,
          acttarget: 84.0,
          valueColor: '#007A04',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: 'PE',
          value: 53.68,
          loss: 42.01,
          target: 79.99,
          actvalue: 56.1,
          acttarget: 83.59,
          valueColor: '#FF0000',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: 'QE',
          value: 53.68,
          loss: 0,
          target: 53.68,
          actvalue: 100,
          acttarget: 100,
          valueColor: '#007A04',
          lossColor: '#000000',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    }
  ],
  meta: {
    nodes: {
      // all the details of each ele are discribed below
      sbar: {
        node: 'sbar',
        // key declared at each ele to the label
        labelMapping: {
          value: 'actual',
          loss: 'loss',
          target: 'target',
          actvalue: 'ACT%',
          acttarget: 'TGT%'
        },
        charts: [
          // values tobe considered for the bar
          {
            type: 'Stacked Bar',
            values: ['value', 'loss']
          },
          // values tobe considered for the point
          {
            type: 'Point',
            values: ['target']
          }
        ],
        colorMappings: {
          value: 'valueColor',
          loss: 'lossColor'
        },
        // the below properties will be shown as a grid
        gridKeys: ['actvalue', 'acttarget']
      }
    }
  }
};

export const hitMissResp = {
  data: [
    {
      id: 1,
      tabTitle: 'MEAN',
      label: 'Mean',
      sbar: [
        {
          label: 'Hit Rate',
          hit: 3601,
          miss: 26260,
          hitcolor: '#007A04',
          misscolor: '#FF0000',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    },
    {
      id: 2,
      tabTitle: 'MEDIAN',
      label: 'Median',
      sbar: [
        {
          label: 'Hit Rate',
          hit: 3601,
          miss: 26260,
          hitcolor: '#007A04',
          misscolor: '#FF0000',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    }
  ],
  meta: [
    {
      nodes: {
        sbar: {
          node: 'sbar',
          labelMapping: {
            hit: 'Hit %',
            miss: 'Miss %'
          },
          charts: [
            {
              type: 'Full Stacked Bar',
              values: ['hit', 'miss']
            }
          ],
          colorMappings: {
            hit: 'hitcolor',
            miss: 'misscolor'
          },
          gridKeys: ['hit', 'miss']
        }
      }
    }
  ]
};

export const trendResp = {
  data: [
    {
      id: 1,
      tabTitle: 'Mean',
      label: 'OEE Trend',
      sbar: [
        {
          label: '2023 Q2',
          value: 68.59,
          target: 65,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW27',
          value: 7.8,
          target: 15,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW28',
          value: 71.78,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '14-01-2023',
          value: 78.04,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '15-01-2023',
          value: 78.18,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '16-01-2023',
          value: 53.68,
          target: 45.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '17-01-2023',
          value: 65.48,
          target: 55.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '18-01-2023',
          value: 89.96,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '19-01-2023',
          value: 85.05,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '20-01-2023',
          value: 79.29,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    },
    {
      id: 2,
      tabTitle: 'Median',
      label: 'OEE Trend',
      sbar: [
        {
          label: '2023 Q2',
          value: 68.59,
          target: 70.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW27',
          value: 7.8,
          target: 70.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '2023 WW28',
          value: 71.78,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '14-01-2023',
          value: 78.04,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '15-01-2023',
          value: 78.18,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '16-01-2023',
          value: 53.68,
          target: 70.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '17-01-2023',
          value: 65.48,
          target: 70.22,
          color: '#FF3333',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '18-01-2023',
          value: 89.96,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '19-01-2023',
          value: 85.05,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        },
        {
          label: '20-01-2023',
          value: 79.29,
          target: 70.22,
          color: '#33CC66',
          max: 100,
          min: 0,
          unit: '%'
        }
      ]
    }
  ],
  meta: {
    nodes: {
      // all the details of each ele are discribed below
      sbar: {
        node: 'sbar',
        // key declared at each ele to the label
        labelMapping: {
          value: 'ACT%',
          target: 'TGT%'
        },
        charts: [
          // values tobe considered for the bar
          {
            type: 'Bar',
            values: ['value']
          },
          // values tobe considered for the point
          {
            type: 'Point',
            values: ['target']
          }
        ],
        colorMappings: {
          value: 'color',
          value2: 'color2'
        },
        // the below properties will be shown as a grid
        gridKeys: ['value', 'target']
      }
    }
  }
};

export const getDataSource = (resp, tabNo) => {
  try {
    // const resp = hitMissResp;
    const { data, meta: m } = resp;
    const meta = m[0];
    // console.log('completeData', resp, tabNo, data, meta);
    const rawData = data[tabNo];
    const { id, tabTitle, label, ...otherRawData } = rawData;
    const rawDataKeys = Object.keys(otherRawData);
    const node = rawDataKeys.find((key) => meta.nodes[key] && true);
    const nodeDetails = meta.nodes[node];
    const { charts, colorMappings, gridKeys, labelMapping } = nodeDetails || {};
    // console.log('node', nodeDetails, rawDataKeys, meta.nodes);
    const barChartDetails = charts.find((e) => e.type.toLowerCase().includes('bar'));
    const { type, values } = barChartDetails;
    // return { data: rawData, meta: nodeDetails };
    const uniqueColors = [];
    const gridDetails = {};

    values.forEach((val) => {
      rawData[node].forEach((nodeEle) => {
        const colorKey = colorMappings?.[val];
        const color = nodeEle[colorKey];
        if (!uniqueColors.includes(color)) {
          uniqueColors.push(color);
        }
      });
    });

    const formatedRawData = rawData[node].reduce((acc, ele) => {
      const formatedEle = { ...ele };

      // resolves all the values to val+color-codes
      values.forEach((val) => {
        const colorKey = colorMappings?.[val];
        const color = ele[colorKey];

        formatedEle[`val-${color}`] = ele[val];
        formatedEle.top = ele.max - ele[val];
      });
      gridDetails[ele.label] = (gridKeys || []).reduce((acc, key) => {
        const val = ele[key];
        return { ...acc, [key]: val };
      }, {});
      return [...acc, formatedEle];
    }, []);
    const chartType = DEV_EXPRESS_CHART_TYPES[type.toLowerCase()] || type;
    // console.log('type', type, 'chartType', chartType);
    return {
      data: formatedRawData,
      meta: nodeDetails,
      colors: uniqueColors,
      labelMapping,
      gridDetails,
      chartType,
      completeData: resp
    };
  } catch (e) {
    console.log('error', e);
  }
  return {
    data: [],
    meta: {},
    colors: []
  };
};
