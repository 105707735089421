/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { localString } from '../../../localization/localString';
import { Input } from '../../../component/index';
import { LoginForm, LogoContainer, LogoText } from '../login/Login.style';
import { DescMsg, Btn, Heading } from './ResetPassword.style';
import { passwordShow, passwordHide, vistrianLogo } from '../../../assets/icons';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import Toast from '../../../component/Common/Toast';
import osramLogo from '../../../assets/Images/amsOSRAM_Color.png';
import Logo from '../../../component/Common/Logo';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { Return } from '../ForgotPassword/ForgotPassword.style';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/login/back_arrow.svg';
import { encodeUserDetails } from '../../../shared/utility';

const ForgotPasswordForm = (props) => {
  const [showPwd, setShowPassword] = useState(false);
  const [passwords, setPasswords] = useState({ pwd: '', confirmPwd: '' });
  const [emptyFeilds, setEmptyFeilds] = useState({ pwd: false, confirmPwd: false });
  // api validation error
  const [error, setError] = useState('');
  const { state } = useLocation();

  const { language } = useSelector((state) => state.configData);
  const string = localString[language];
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const history = useHistory();
  const clearToastMessage = () => {
    setError('');
  };

  const handleVerification = async () => {
    if (passwords.pwd) {
      try {
        if (passwords.pwd === passwords.confirmPwd) {
          const payload = { email: state.email, otp: state.otp, newPassword: passwords.pwd };
          const config = {
            headers: {
              ...WebService.defaults.headers,
              Authorization: `Basic ${encodeUserDetails(payload)}`
            }
          };
          const resp = await WebService.post(apiEndPoints.ResetPassword, {}, config);
          if (resp.status >= 200 && resp.status < 300) {
            const { data, message } = resp.data || {};
            history.push('/reset-success');
          } else throw resp;
        } else {
          setError("Passwords didn't match");
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('error', error);
        setError(error);
      }
    } else {
      const temp = { ...emptyFeilds };
      if (!passwords.confirmPwd) {
        temp.confirmPwd = true;
      }
      if (!passwords.pwd) {
        temp.pwd = true;
      }
      setEmptyFeilds(temp);
    }
  };

  const handleClickPwd = () => {
    setShowPassword(!showPwd);
  };

  const handleInputChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    if (emptyFeilds[e.target.name]) {
      setEmptyFeilds({ ...emptyFeilds, [e.target.name]: false });
    }
  };
  const backToLogin = () => {
    props.history.push('/');
  };

  return (
    <LoginForm>
      {/* <LogoText>
        <img src={osramLogo} alt='osram' height='50' />
      </LogoText> */}
      <Logo />
      <Heading marginBottom='1.1rem'>{localeStrings?.forgotPassword || 'Forgot Password'}</Heading>
      <DescMsg>{localeStrings?.setPwdMsg || 'Set a new password'}</DescMsg>
      <Input
        name='pwd'
        type={showPwd ? 'text' : 'password'}
        value={passwords.pwd}
        outline={emptyFeilds.pwd}
        errorMsg='Please provide password'
        placeholder={localeStrings?.newPwd || 'Enter new password'}
        iconUrl={showPwd ? passwordHide : passwordShow}
        onIconPress={handleClickPwd}
        handleInputChange={handleInputChange}
        onSubmit={handleVerification}
      />
      <Input
        name='confirmPwd'
        value={passwords.confirmPwd}
        type='password'
        outline={emptyFeilds.confirmPwd}
        errorMsg='Please provide password to confirm'
        placeholder={localeStrings?.reEnterPwd || 'Re-enter new password'}
        handleInputChange={handleInputChange}
        onSubmit={handleVerification}
      />
      <Btn onClick={handleVerification}>{localeStrings?.resetPwd || 'Reset password'}</Btn>
      <Return onClick={backToLogin}>
        <BackArrowIcon className='fill' style={{ height: '1.5rem', width: '3rem' }} />
        {localeStrings?.backToLogin || 'Back to login'}
      </Return>
      {error && <Toast header='Error' fn={clearToastMessage} message={error} />}
    </LoginForm>
  );
};
export default ForgotPasswordForm;
