import styled from 'styled-components';
import { fontFamily, fontSize } from '../../constants';

export const AlignedDiv = styled.div`
  display: flex;
  align-items: ${({ al }) => al || 'center'};
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const CircularDiv = styled(AlignedDiv)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${({ theme }) => theme.contrast.tertiary};
`;

export const DropdownBar = styled.div`
  width: 100%;
  height: 4rem;
  font-size: ${fontSize.text};
  border: 1px solid;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  outline: none;
  border: 0px;
  border-radius: 1rem;
  color: ${({ theme }) => theme.contrast.darkSecondary};
  font-family: ${fontFamily.circularMedium};
  background-color: ${({ theme }) => theme.core.secondary};
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadowin};

  &.active {
    /* box-shadow: none; */
    box-shadow: ${({ theme }) => theme.shadowin};
  }
`;

export const HeaderContainer = styled.div`
  width: calc(100% - 4rem);
  margin: 0rem 2rem;
  padding: 2.5rem 2rem 1.2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted #707070;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.contrast.primary};
  font: 2.2rem ${fontFamily.circularMedium};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0rem 2rem;
`;

export const MainBodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  align-items: center;
`;

export const NormalContent = styled.div`
  color: ${({ theme }) => theme.contrast.primary};
  font: 2rem ${fontFamily.circularBook};
`;

export const ContentTextTemp = styled.div`
  color: #000000;
  font: 1.6rem ${fontFamily.circularMedium};
  &.ml {
    padding-left: 3rem;
  }
  &.label {
    flex: 1;
  }
  &.heading {
    /* flex: 0.7; */
    min-width: 17rem;
    color: #717171;
    text-transform: capitalize;
  }
`;

// table like details structure
export const DetailsContainerTemp = styled.div`
  display: flex;
  width: ${({ width }) => width && width};
  &.ai {
    align-items: center;
  }
  &.detail {
    margin-bottom: 2.5rem;
    max-width: 50%;
    justify-content: space-between;
  }
`;

export const H2 = styled.h2`
  width: 100%;
`;

export const ContastIconColor = styled.span`
  & .dynamicSVGIcon {
    & * {
      fill: ${({ theme }) => theme.contrast.primary} !important;
    }
  }
`;

export const ContastIconColorContainer = styled.div`
  & .dynamicSVGIcon {
    & * {
      fill: ${({ theme }) => theme.contrast.primary} !important;
    }
  }
`;
