/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import moment from 'moment';
import { createPopper } from '@popperjs/core';
import { useSelector } from 'react-redux';
import { AddToCart, RemoveFromCart } from '../../../assets/icons';
import { FlexContainer } from '../../../globalStyles';
import CollapseIcon from '../../SvgIcons/KPVList/CollapseIcon';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { Td, ToogleGroupICon, Tr } from '../Table/SpacedRows.styles';
import { ActionIcon, TdContentBox } from './TabsTable.style';
import kababIcon from '../../../assets/icons/Filter/kabab.svg';
import DropDownModal from '../DropDownModal/DropDownModal';
import CheckBox from '../../CheckBox/CheckBox';
import { validate, VALIDATION_TYPES } from '../../../shared/utility/regex';
import { format } from '../../../config/index';
import { AvatarComponent } from '../..';
import { tableContentTypes, tableItemFlags } from '../../../constants';
import { uid } from '../../../shared/utility';
import { isRowMatched, isRowMatchedBasedOnKey } from './utility';
import { isEmpty } from '../../../shared/utility/isEmpty';
import DropDownModalV3 from '../DropDownModal/DropdownModal_V3';

const TableContent = ({
  type, // groupby value
  rowsDetails, // object: data is formed from rowsDetails[type]
  metaDetails, // object containing columns
  assetShortlist,
  visibleColumnsCount,
  onItemSelect,
  showShortlistSelection,
  handleShortList,
  rowOptions,
  handleRowOptionsItemClickHandler,
  id = 'id',
  shortListSelectionDetails,
  showRowOptionAtStart,
  isComparision,
  activeRow,
  setActiveRow = () => {},
  hasNestedRowOptions,
  isActionReq = true,
  hasObjectContent = false,
  rowOptionsWithCondition
}) => {
  const { isCheckboxTypeSelection, isSelectionAtFirst: isSelectionAtFirstProp } = shortListSelectionDetails;
  const isSelectionAtFirst = showShortlistSelection
    ? isSelectionAtFirstProp || isSelectionAtFirstProp === undefined
    : false;
  const isSelectionAtLast = showShortlistSelection ? isSelectionAtFirstProp === false : false;
  const [showDetails, setShowDetails] = useState(true);
  const handleToggle = () => {
    setShowDetails((prev) => !prev);
  };
  // show rowoptions
  const [activeRowItem, setActiveRowItem] = useState({});
  const handleOptionsClick = (item) => {
    setActiveRowItem(item);
  };
  // close handler
  const closeDropdownOptions = () => {
    setActiveRowItem({});
  };
  const handleRowOptionsItemClick = (...spred) => {
    handleRowOptionsItemClickHandler(...spred);
    closeDropdownOptions();
  };
  const userData = useSelector((state) => state.loginData.userData);

  const SelectionType = ({ entry }) => {
    // If comparison is required based only on Basis of one key then pass hasObjectContent as true from Parent and comparison key as Thrid parameter to isRowMatchedBasedOnKey
    const check = hasObjectContent ? assetShortlist?.some((ele) => isRowMatchedBasedOnKey(ele, entry, id)) : assetShortlist?.some((ele) => isRowMatched(ele, entry));
    return (
      <>
        {isCheckboxTypeSelection === true ? (
          <Td rowSpan={isComparision ? 2 : 1}>
            <FlexContainer className='jc_center'>
              <CheckBox
                isActive={check}
                // isActive={assetShortlist?.some((ele) => ele.employeeId === entry.employeeId && ele.name === entry.name)}
                handleChange={() => handleShortList(entry)}
              />
            </FlexContainer>
          </Td>
        ) : (isActionReq && (
          <Td className={check ? 'active' : ''} flex={0.5} rowSpan={isComparision ? 2 : 1}>
            <ActionIcon
              // className={!entry[metaDetails.chartable[0]] && 'disable'}
              onClick={() => handleShortList(entry)}
            >
              {check ? (
                <DynamicSVGIcon iconUrl={RemoveFromCart} width='2.2rem' />
              ) : (
                <DynamicSVGIcon iconUrl={AddToCart} width='2.2rem' />
              )}
            </ActionIcon>
          </Td>
        )
        )}
      </>
    );
  };
  const RowOptionsIcon = ({ index, entry }) => {
    let rowOpt;
    if (!isEmpty(rowOptionsWithCondition)) {
      rowOpt = entry.UserId === userData?.userId
      ? (entry?.IsShareable === 'Global Instance' || entry?.IsGlobal) ? rowOptionsWithCondition?.myDashboardMenu : rowOptionsWithCondition?.myDashboardMenuGlobal
      : rowOptionsWithCondition?.globalDashboardMenu;
    }
    return (
      <>
        {(rowOptions || rowOptionsWithCondition) && (
        <Td style={{ width: '5rem' }} id={index}>
          {hasNestedRowOptions ? (
            <DropDownModalV3
              extendLeft
              isNested={true}
              data={rowOptions}
              activeItems={[]}
              isActive={activeRowItem[id] === entry[id]}
              setModalIsVisibal={closeDropdownOptions}
              handleClick={handleRowOptionsItemClick}
              extraParms={{ activeRowItem }}
              noContainerPadding={true}
              ContainerStyle={{ right: '0px' }}
              overrideMainContainerWidth={true}
              // ContainerStyle={{ position: 'absolute', width: 'max-content', zIndex: '20' }}
              width='20rem'
            >
              <div
                // style={{ position: 'relative', background: 'red' }}
                className='pointer'
                onClick={() => handleOptionsClick(entry)}
              >
                <DynamicSVGIcon iconUrl={kababIcon} width='2.2rem' />
              </div>
            </DropDownModalV3>
          ) : (
            <DropDownModal
              data={rowOptionsWithCondition ? rowOpt : rowOptions}
              isActive={activeRowItem[id] === entry[id]}
              setModalIsVisibal={closeDropdownOptions}
              handleClick={handleRowOptionsItemClick}
              extraParms={{ activeRowItem }}
              ContainerStyle={{ padding: '0px' }}
              TextStyle={rowOptions?.length === 1 ? { marginTop: '0' } : {}}
              width="auto"
              isTab={!!isEmpty(rowOptionsWithCondition)}
            >
              <div
                // style={{ position: 'relative', background: 'red' }}
                className='pointer'
                onClick={() => handleOptionsClick(entry)}
              >
                <DynamicSVGIcon iconUrl={kababIcon} width='2.2rem' />
              </div>
            </DropDownModal>
          )}
        </Td>
      )}
      </>
  );
};

  const RowContent = ({ entry, index, isOldItem }) => (
    <>
      {(isComparision ? !isEmpty(entry?.[tableItemFlags.CHANGED_KEYS]) : true) && (
        <Tr
          key={index}
          className={activeRow && `${entry?.[tableItemFlags.SHOULD_DELETE] ? 'shouldDelete' : ''} ${
            entry[tableItemFlags.NEWLY_ADDED] ? 'showOutline' : ''
          } ${activeRow?.[id] === entry[id] ? 'showOutline' : ''}`}
          onClick={() => setActiveRow(entry)}
        >
          {showRowOptionAtStart && <RowOptionsIcon index={index} entry={entry} />}
          {isSelectionAtFirst && (isComparision ? isOldItem : true) && <SelectionType entry={entry} />}
          {metaDetails?.columns?.map((col, key) => {
    // If comparison is required based only on Basis of one key then pass hasObjectContent as true from Parent and comparison key as Thrid parameter to isRowMatchedBasedOnKey
            const isSelected = hasObjectContent ? assetShortlist?.some((ele) => isRowMatchedBasedOnKey(ele, entry, id)) : assetShortlist?.some((ele) => isRowMatched(ele, entry, hasObjectContent));
            const { type, imageKey: imageKeyProp } = col;
            const isValueChanged = isComparision && entry?.[tableItemFlags.CHANGED_KEYS]?.includes(col.key);
            const imageKey = imageKeyProp || 'profilePicture';
            let isImg = false;
            let isHtmlValue = false;
            let value = entry[col.key] || '-';
            if (typeof entry[col.key] === 'string') {
              isImg = entry[col.key]?.startsWith('http');
              isHtmlValue = entry[col.key]?.startsWith('<');
              if (typeof entry[col.key] === 'string' && entry[col.key].trim() === 'to') {
                value = '--:--:-- to --:--:--';
              }
            } else if (typeof entry[col.key] === 'boolean') {
              if (type === 'Instance') {
              value = entry[col.key] ? 'Global Instance' : 'My Instance';
              } else {
              value = entry[col.key] ? 'true' : 'false';
              }
            }
            if (validate(entry[col.key], VALIDATION_TYPES.ISO_DATE_STRING)) {
              value = moment(entry[col.key]).format(format);
            }
            let content = isImg ? <img height='40px' src={entry[col.key]} alt='spare' /> : <>{value}</>;
            if (isHtmlValue) {
              content = (
                <p style={{ fontSize: '1.6rem' }} dangerouslySetInnerHTML={{ __html: entry[col.key] || '-' }} />
              );
            }
            if (type === tableContentTypes.WIDGET_INTERVAL) {
              if (Array.isArray((entry[col.key]?.formData?.interval))) {
                if (Array.isArray(entry[col.key]?.formData?.interval[0]?.values)) {
                  content = entry[col.key]?.formData?.interval && entry[col.key]?.formData?.interval[0] && entry[col.key]?.formData?.interval[0] && entry[col.key]?.formData?.interval[0]?.values[0]?.name;
                } else {
                  content = '';
                }
            } else {
                content = ' ';
              }
            }
            if (type === tableContentTypes.TEMPLATE_INSTANCE_TYPE) {
              content = entry?.IsGlobal ? 'Global Instance' : 'My Instance';
            }
            if (type === tableContentTypes.WIDGET_TYPE) {
              content = entry[col.key]?.type;
            }
            if (type === tableContentTypes.IMAGE_DETAILS) {
              content = (
                <TdContentBox
                  className={`${col.align === 'left' ? 'jc_start' : 'jc_center'} ${
                    col.align === 'right' ? 'jc_end' : 'jc_center'
                  } ai_center`}
                >
                  {imageKey && entry?.[imageKey] ? (
                    <img alt='' src={entry?.[imageKey]} width='35px' height='35px' style={{ borderRadius: '50%' }} />
                  ) : (
                    <span>
                      <AvatarComponent userdata={{ firstName: entry?.[key] }} circle={true} />
                    </span>
                  )}
                  {content}
                </TdContentBox>
              );
            }

            return (
              col.visible && (
                <Td
                  border
                  showOutline={isValueChanged}
                  isOldItem={isOldItem}
                  key={key}
                  style={{
                    color: entry.Status === 'Request Failed' && '#E40505',
                    background: metaDetails?.fieldsToColor?.includes(col.key)
                      ? entry?.[metaDetails?.colorField?.[0]]
                      : ''
                  }}
                  // width={col.width}
                  // order={col.order}
                  // align={col.textAlign}
                  className={`${isSelected ? 'active pointer' : 'pointer'}  ${isValueChanged ? 'showOutline' : ''}`}
                  removeVerticalPadding={isImg}
                  onClick={() => onItemSelect(entry)}
                  align={col.align}
                >
                  {content}
                </Td>
              )
            );
          })}

          {isSelectionAtLast && (isComparision ? isOldItem : true) && <SelectionType entry={entry} />}
          {!showRowOptionAtStart && <RowOptionsIcon index={index} entry={entry} />}
        </Tr>
      )}
    </>
  );
  return (
    <>
      {/* hide row if there is grouping specified */}
      {type !== 'noGrouping' && (
        <Tr>
          <Td style={{ cursor: 'pointer' }} colSpan={visibleColumnsCount}>
            <FlexContainer className='ai_center'>
              <ToogleGroupICon onClick={handleToggle}>
                <CollapseIcon />
              </ToogleGroupICon>
              <p>{type}</p>
            </FlexContainer>
          </Td>
        </Tr>
      )}
      {showDetails &&
        rowsDetails?.[type]?.map((entry, index) => {
          if (Array.isArray(entry) && isComparision) {
            return (
              <>
                {entry.map((innerEntry, index) => (
                  <RowContent entry={innerEntry} index={uid()} isOldItem={index === 0} />
                ))}
              </>
            );
          }
          return <RowContent entry={entry} index={index} />;
        })}
    </>
  );
};

export default TableContent;
