/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';

const Button = styled.button`
  display: inline;
  width: 16rem;
  height: 4rem;
  font-size: ${fontSize.titleBig};
  padding: ${({ padding }) => padding || '0rem'};
  box-shadow: -5px -5px 19px ${({ theme }) => theme.shadowContrast},
    3px 3px 10px ${({ theme }) => theme.shadow};
  border-radius: 10px;
  border: 0px;
  text-transform: capitalize;
  font-family: ${fontFamily.circularMedium};
  background-color: ${({ theme }) => theme.aux.secondary};
  color: ${({ theme }) => theme.contrast.primary};
  cursor: pointer;
  &.dark {
    background-color: ${({ theme }) => theme.specificDetails.primary};
    color: ${({ theme }) => theme.contrast.tertiary};
    /* width: 16rem;
    height: 4rem; */
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const ButtonComp = ({
  children,
  onClick,
  dark,
  style,
  className,
  disabled,
  margin, padding
}) => (
  <Button
    style={{ ...style }}
    disabled={disabled}
    className={`${dark ? 'dark' : ''} ${className}`}
    onClick={onClick}
    padding={padding}
    margin={margin}
  >
    {children}
  </Button>
);

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export default ButtonComp;
