import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../constants';
import DynamicSVGIcon from '../../DynamicSVGIcon';
import ToggleOn from '../../../../assets/icons/KPV/Toggle on.svg';
import ToggleOff from '../../../../assets/icons/KPV/toggle off.svg';

export const Toggle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;

const ToggleType = (props) => {
  const { handleToggle, isActive, itemKey } = props;
  return (
    <div>
      <Toggle onClick={() => handleToggle(itemKey)}>
        <DynamicSVGIcon iconUrl={isActive ? ToggleOn : ToggleOff} width='5rem' />
      </Toggle>
    </div>
  );
};

export default ToggleType;
