/* eslint-disable no-undef */
/* eslint-disable max-len */
import styled from 'styled-components';
import { fontSize, fontFamily } from '../../constants';
import { FlexContainer } from '../../globalStyles';

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const BodyContainer = styled(FlexContainer)`
  margin-bottom: 1rem;
  margin-left: 2rem;
  height: calc(100% - 1rem);
  /* background: red; */
  overflow: auto;
`;

export const CheckBox = styled(FlexCenter)`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  border-radius: 30%;

  border: ${({ theme }) => `1px solid ${theme.contrast.secondary}`};
  justify-content: center;
  margin-right: 1.6rem;
  &.active {
    background-color: ${({ theme }) => 'black'};
    & > svg {
      fill: ${({ theme }) => 'white'};
    }
  }

  &.unactive {
    background-color: ${({ theme }) => theme.checkbox.boxcolor};
    border: ${({ theme }) => `1px solid ${theme.checkbox.border}`};
  }
  & > svg {
    width: 1.5rem;
    fill: ${({ theme }) => theme.aux.darkSecondary};
  }
`;

export const ItemContainer = styled.div`
  //icon and margin
  margin-left: 0;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.contrast.primary};
  display: flex;
  align-items: center;
  font-family: ${fontFamily.circularMedium};
  margin-bottom: 1.6rem;

  &.oeeOptions {
    margin-left: 0;
  }
`;

export const FilterButton = styled.div`
  width: 5rem;
  height: 5.5rem;
  border-radius: 10px;
  margin: 0px 0px 0px 1px;
  background-color: ${({ theme }) => theme.aux.secondary};

  box-shadow: 4px 4px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  & svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;
