/* eslint-disable max-len */

export const isSame = (dateObj1, dateObj2) =>
  dateObj2?.start &&
  dateObj1?.start &&
  dateObj1.start.year === dateObj2.start.year &&
  dateObj1.start.month === dateObj2.start.month;

export const isAfter = (dateObj1, dateObj2) =>
  dateObj1.start.year > dateObj2.start.year ||
  (dateObj1.start.year === dateObj2.start.year && dateObj1.start.month > dateObj2.start.month);

export const isBefore = (dateObj1, dateObj2) =>
  dateObj1.start.year < dateObj2.start.year ||
  (dateObj1.start.year === dateObj2.start.year && dateObj1.start.month < dateObj2.start.month);

export const isSameOrAfter = (dateObj1, dateObj2) => isSame(dateObj1, dateObj2) || isAfter(dateObj1, dateObj2);

export const isSameOrBefore = (dateObj1, dateObj2) => isSame(dateObj1, dateObj2) || isBefore(dateObj1, dateObj2);

export const isBetween = (date, [start, end]) => isSameOrAfter(date, start) && isSameOrBefore(date, end);
