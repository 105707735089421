/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Span } from '../KeyValueLayout/KeyValueLayout.style';

const FileUploadUi = ({ file, handleChange }) => (
  <>
    <Span className='fileUploadBox' style={{ textAlign: 'center' }}>
      {file ? (
        file?.name
      ) : (
        <>
          Drag Your File(s) Here or
          <form style={{ display: 'inline-block' }}>
            <label for='fileUploads'>browse</label>{' '}
            <input
              style={{ display: 'none' }}
              type='file'
              name='fileUploads'
              id='fileUploads'
              accept='.xlsx,.xls'
              onChange={handleChange}
            />
          </form>
          <br />
          Max browse size 15mb
        </>
      )}
    </Span>
  </>
);
export default FileUploadUi;
