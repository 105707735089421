/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint no-else-return: "error" */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FilterTagArea } from '../../../component';
import FilterSearchComponent from '../../../component/Filter/FilterSearchComponent/FilterSearchComponent';
import Toast from '../../../component/Common/Toast';
import DropDownModal from '../../../component/Common/DropDownModal/DropDownModal_V1';
import ShowAllTheFilter from '../../../component/ResultView/ShowAllTheFilter';
import {
  FilterViewContainer,
  FilterHeadingContainer,
  FilterHeadingText,
  FilterTabContainer,
  Divider,
  ActiveDivider,
  FilterHeadingBox,
  MobileFilterButton,
  KababIcon,
  FilterSelectImage,
  FilterSelectText,
  FilterNoDataContainer,
  FilterDataContainer,
  KababIconContainer,
  HeaderText,
  WrapperContainer
} from './Filter.style';
import { Image } from '../../../component/Filter/FilterSearchComponent/FilterSearchComponent.style';
import kababSvg from '../../../assets/icons/Filter/kabab.svg';
import FilterSelectPng from '../../../assets/Images/Filter/Use Filter @3x.png';
import FilterContainer from '../../../component/Filter/FilterContainer/FilterContainer';
import FilterMobileIcon from '../../../assets/icons/Filter/Filters white.svg';
import { filterMenuData } from '../../../constants/sampleData';
import { Creators as FilterCreators } from './store';
// import { localString } from '../../../localization/localString';
import { camelCase } from '../../../shared/utility/camelCase';
import KPVCustomComponent from '../../../component/ResultView/KPVCustomComponent/KPVCustom.view';
// import { applyTypeMapping } from '../../../constants/mapping';
import Loader from '../../../component/Loader/Loader';
import DynamicSVGIcon from '../../../component/Common/DynamicSVGIcon';
import { usePrevious } from '../../../shared/hooks/usePrevious';
import { LeftArrow } from '../../../component/ResultView/KPVShortlist/KPVShortlist.style';
import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';
import BreadCrumbComp from '../../../component/Filter/BreadCrumb/BreadCrumb.view';
import SaveDashboard from '../../MyDashboards/SaveDashboardModal';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { FlexContainer } from '../../../globalStyles';
import SaveAnalytics from '../../../component/MyAnalytics/SaveAnalyticsModal/SaveAnalytics';
import { fetchUserAndLocation } from '../../../component/Sidenav/FetchStatics';
// import { FlexContainer } from '../../../globalStyles';

// export const headingTitle = ['Summary', 'Trend', 'Comparison', 'Details'];

function FilterView(props) {
  const { location, menuId } = props;
  const { goDirectToResult, resType } = location;
  // const [showFilter, setShowFilter] = useState(false);
  const [activeFilterCard, setActiveFilterCard] = useState(0);
  const [modalIsVisibal, setModalIsVisibal] = useState(false);
  const [showSavedashboard, setShowSavedashboard] = useState(false);
  // const [formatTags, setFormatTags] = useState('');
  const history = useHistory();
  const {
    selectedParent,
    filterData,
    resultType,
    showFilterResult,
    error,
    tags,
    showFilter,
    loading,
    menuIdofFilter
  } = useSelector((state) => state.filterData);
  const userData = useSelector((state) => state.loginData.userData);

  // const { resultType } = useSelector((state) => state.filterResultData);
  const subMenuOptions = useSelector((state) => state.home.subMenuOptions);
  const prevResultType = usePrevious(resultType);
  const dispatch = useDispatch();
  // const location = useLocation();
  // const string = localString.English;
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const { isEdit, myAnalyticsItemName } = useSelector(
    (state) => state.myAnalytics.analyticsData
  );
  // const { myDashboardItemId } = useSelector((state) => state.myDashboards);
  const findPathObject = (path) =>
    !!subMenuOptions.find((item) => item.path === path);
  const menuName = useRef();

  const getTabNames = (path = location.pathname) => {
    const data = subMenuOptions.find((item) => item.path === path);
    return data?.subMenuOptions;
  };

  const fetchFilters = (activeTab = '') => {
    dispatch(FilterCreators.handleFilterChange());
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'breadCrumbs',
        value: []
      })
    );
    dispatch(
      FilterCreators.getFilterData({ type: 'tab', menuId, tab: activeTab })
    );
  };

  const setActiveTab = (value) => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'selectedParent', value })
    );
  };
  const setShowFilter = (value) => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'showFilter', value })
    );
  };

  const handleTabChange = (item) => {
    // if (selectedParent !== item) {
    // dispatch(FilterCreators.resetFilterState());
    setShowFilter(false);
    setActiveTab(item);
    fetchFilters(item);
    fetchUserAndLocation(location.pathname, userData, {
      tab: `/${item}`,
      pageType: 'Filter'
    });
    // dispatch(FilterCreators.getFilterData(item));
    // }
  };

  // console.log(filterMenuData, 'fliltermenudata');
  // only for menu change
  const tabsAvailable = getTabNames(location.pathname);
  useEffect(() => {
    // dispatch(FilterCreators.setMenuIdOfFilter(menuId));
    // console.log('menuId changes', menuId, menuIdofFilter);
    if (!goDirectToResult) {
      if (menuIdofFilter !== menuId && !getTabNames()?.length) {
        // Without tabs
        fetchFilters();
        // console.log('1.menu changed, no tabs');
      } else if (menuIdofFilter !== menuId) {
        // With tabs
        // console.log('change tab', selectedParent || tabsAvailable[0]);
        dispatch(FilterCreators.handleFilterChange());
        handleTabChange(tabsAvailable[0]);
        // selectedParent ||
        // console.log('2.menu changed, with tabs so filterChange handle');
      }
    }
    // } else {
    // dispatch(FilterCreators.handleDirectFilterRes({ resType }));
  }, [menuIdofFilter]);

  // console.log('gggg', goDirectToResult, filterData);
  useEffect(() => {
    if (!isEmpty(filterData) && goDirectToResult) {
      dispatch(FilterCreators.handleDirectFilterRes({ resType }));
    }
  }, [filterData]);

  useEffect(() => {
    dispatch(FilterCreators.setMenuIdOfFilter(menuId));
    // get the respective dropdown menu based on the location analytics/daashboards
    const locArr = location.pathname.split('/');
    const homeIndex = locArr.findIndex((e) => e === 'home');
    menuName.current =
      homeIndex + 1 < locArr.length ? locArr[homeIndex + 1] : 'dashboard';
  }, []);

  const clearFilterToastMessage = () => {
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'error', value: null })
    );
  };

  const kababIconRef = useRef();

  const hanldeFilterMenuItemClick = ({ role, disable }) => {
    if (!disable) {
      if (role === 'clear') {
        setShowFilter(false);
        dispatch(FilterCreators.clearSelectedFilter());
      }

      if (role === 'save') {
        setShowSavedashboard(true);
      }
    }
    setModalIsVisibal(false);
  };

  const closeSaveModal = (data) => {
    // console.log(data);
    setShowSavedashboard(false);
  };
  // covered above
  // useEffect(() => {
  //   if (prevResultType !== undefined && resultType !== prevResultType) {
  //     // console.log("don't show result", prevResultType, resultType);
  //     setShowFilter(false);
  //     dispatch(
  //       FilterCreators.universalFilterSetter({
  //         key: 'showFilterResult',
  //         value: false
  //       })
  //     );
  //   }
  // }, [resultType]);

  // old version
  // useEffect(() => {
  //   if (selectedParent && getTabNames()?.length) fetchFilters(selectedParent);
  // }, [selectedParent]);

  // useEffect(() => {
  //   setFormatTags(JSON.stringify(tags));
  // }, [tags]);

  // useEffect(() => {
  //   const singleSelectgrpNames = [];
  //   filterData?.forEach((grp) => {
  //     if (grp.selectionType === 'singleselect') {
  //       singleSelectgrpNames.push(grp.groupTitle);
  //     }
  //   });
  //   dispatch(FilterCreators.setSingleSelectionGroups(singleSelectgrpNames));
  // }, [filterData]);

  const SaveModal = (page) => {
    switch (page) {
      case 'analytics':
        return (
          <SaveAnalytics shouldSaveCharts={false} onCancel={closeSaveModal} />
        );
      default:
        return <SaveDashboard onCancel={closeSaveModal} />;
    }
  };

  return (
    <>
      {showSavedashboard && SaveModal(menuName.current)}
      <FilterViewContainer
        minusHeight={Object.keys(tags).length > 0 ? 119 : 60}
      >
        {subMenuOptions && findPathObject(location.pathname) && (
          <FilterTabContainer showFilter={showFilter}>
            <FilterHeadingContainer>
              {subMenuOptions &&
                tabsAvailable.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FilterHeadingBox
                    className='pointer'
                    key={i}
                    onClick={() => handleTabChange(item)}
                  >
                    <FilterHeadingText active={selectedParent === item}>
                      {item}
                    </FilterHeadingText>
                    {selectedParent === item && <ActiveDivider />}
                  </FilterHeadingBox>
                ))}
            </FilterHeadingContainer>
            {/* old placement of menu moved to down */}
            {/* <div>
              <KababIconContainer ref={kababIconRef} onClick={() => setModalIsVisibal(!modalIsVisibal)}>
                <KababIcon>
                  <DynamicSVGIcon iconUrl={kababSvg} width='3rem' />
                </KababIcon>
              </KababIconContainer>

              {modalIsVisibal && (
                <DropDownModal
                  kababIconRef={kababIconRef}
                  setModalIsVisibal={setModalIsVisibal}
                  data={filterMenuData}
                  ContainerStyle={{ top: 45, right: 0 }}
                  handleClick={hanldeFilterMenuItemClick}
                />
              )}
            </div> */}
            <Divider />
          </FilterTabContainer>
        )}
        {loading && <Loader />}
        {!loading && filterData && (
          <>
            <div
              style={{ display: 'flex', width: '97%', alignItems: 'center' }}
            >
              {isEdit && (
                <>
                  <LeftArrow onClick={() => history.goBack()}>
                    <DynamicSVGIcon iconUrl={DropArrow} width='2.4rem' />
                  </LeftArrow>
                  <HeaderText>{`Edit ${myAnalyticsItemName}`}</HeaderText>
                </>
              )}
              <FilterSearchComponent
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setActiveTab={setActiveFilterCard}
              />
              <WrapperContainer style={{ marginTop: '1.5rem', flex: 1 }}>
                {showFilter && showFilterResult && <BreadCrumbComp />}
                <FlexContainer style={{ justifyContent: 'flex-end', flex: 1 }}>
                  {showFilter && showFilterResult && resultType === 'kpv' && (
                    <KPVCustomComponent />
                  )}
                  {/* <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}> */}
                  <div style={{ position: 'relative', marginLeft: '1rem' }}>
                    <KababIconContainer
                      ref={kababIconRef}
                      onClick={() => setModalIsVisibal(!modalIsVisibal)}
                    >
                      <KababIcon>
                        <DynamicSVGIcon iconUrl={kababSvg} width='3rem' />
                      </KababIcon>
                    </KababIconContainer>

                    <DropDownModal
                      kababIconRef={kababIconRef}
                      isActive={modalIsVisibal}
                      setModalIsVisibal={setModalIsVisibal}
                      data={filterMenuData[menuName.current]}
                      ContainerStyle={{ top: 45, right: 0, width: 'auto' }}
                      handleClick={hanldeFilterMenuItemClick}
                    />
                  </div>
                  {/* </div> */}
                </FlexContainer>
              </WrapperContainer>
            </div>
            {showFilter ? (
              showFilterResult ? (
                <ShowAllTheFilter
                  type={resultType}
                  location={location.pathname}
                />
              ) : (
                <FilterDataContainer>
                  <FilterTagArea
                    setShowFilter={setShowFilter}
                    location={location}
                  />
                  <FilterContainer
                    activeFilterCard={activeFilterCard}
                    setActiveFilterCard={setActiveFilterCard}
                  />
                </FilterDataContainer>
              )
            ) : (
              <FilterNoDataContainer>
                <FilterSelectImage src={FilterSelectPng} />
                <FilterSelectText>
                  {localeStrings.chooseFromFilter ||
                    'Choose From Filters To See Dashboard'}
                </FilterSelectText>
              </FilterNoDataContainer>
            )}

            {!showFilter && (
              <MobileFilterButton onClick={() => setShowFilter(true)}>
                <Image src={FilterMobileIcon} width={2} height={2} />
              </MobileFilterButton>
            )}
          </>
        )}
      </FilterViewContainer>
      {error && (
        <Toast
          style={{ right: 20 }}
          fn={clearFilterToastMessage}
          header='Error'
          message={error}
        />
      )}
    </>
  );
}

export default FilterView;
