/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { LANGUAGES, THEMES } from '../constants';
import config from '../config.json';
import { theme } from './theme';

const themeColor = config.defined_colors.includes(config.theme_color) ? config.theme_color : 'black';
const INITIAL_STATE = {
  error: null,
  loading: false,
  language: LANGUAGES.ENGLISH,
  themeMode: THEMES.LIGHT,
  themeColor,
  specificDetails: theme[themeColor][THEMES.LIGHT], // default black theme
  logoUrl: '',
  favicon: ''
};

const setLanguage = (state = INITIAL_STATE, action) => ({
  ...state,
  language: action.payload
});

const setTheme = (state = INITIAL_STATE, action) => ({
  ...state,
  themeMode: action.payload
});

const setThemeColorsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const setThemeColorsSuccess = (state = INITIAL_STATE, action) => {
  const { primary, secondary, loginprimary, logo, favicon } = action.payload;
  const colorsResp = { primary, secondary, loginprimary };
  return {
    ...state,
    // specificDetails: { ...(theme[themeColor][state.themeMode] || theme[themeColor].common), ...action.payload },
    specificDetails: action.payload,
    loading: false,
    logoUrl: logo,
    favicon,
  };
};

const setThemeColorsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  error: action.payload.error
});

const setToastError = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: payload
});

const HANDLERS = {
  // loading variables

  [Types.SET_LANGUAGE]: setLanguage,
  [Types.SET_THEME]: setTheme,
  [Types.SET_THEME_COLORS_START]: setThemeColorsStart,
  [Types.SET_THEME_COLORS_SUCCESS]: setThemeColorsSuccess,
  [Types.SET_THEME_COLORS_FAILURE]: setThemeColorsFailure,
  [Types.SET_TOAST_ERROR]: setToastError
};

export const configReducer = createReducer(INITIAL_STATE, HANDLERS);
