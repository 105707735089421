/* eslint-disable import/named */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty } from 'lodash';
import { HeadingContainer } from '../../views/MyDashboards/MyDashboards.style';
import { BodyContainer, ButtonContainer, Container, Dot, HeaderRight, HeadingText, NumberOfWidgetsText, Text } from './WidgetInstancesList';
import { Button, FlexContainer } from '../../globalStyles';
import ButtonComp from '../Common/Button/Button.view';
import TabsTable from '../Common/Tabs_Table';
import { useActions } from '../../store/hooks/useActions';
import { apiEndPoints } from '../../services/axios/endPoints';
import { HomeDashboardCreators } from '../../views/Home/store';
import ConfirmationModal from '../Common/ConfirmationBox/ConfirmationBox.view';
import { MAX_WIDGETS_IN_HOME, WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../constants';
import { getResolvedWidgetCategory } from '../Widget/utils';
import FullViewWidget from '../Widget/WidgetComponents/FullViewWidget/FullViewWidget';
import { Footer } from '../KPVCharts/PaneCard/AdditionalFilter/FilterPopUp.styles';
import { BtnWrapper, Heading } from '../Common/PopUpModal/Modal.style';
import { WebService } from '../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import PopUpModal from '../Common/PopUpModal/Modal.view';
import { Input } from '..';
import {
  settingsBlack, Clone, Share,
  View,
} from '../../assets/icons';
import { Edit } from '../../assets/icons/KPV';

const WidgetInstancesList = () => {
  const pageSize = 20;
  const rowOptionsWithCondition = {
    // Row options Available for User who Created the instance and already instance is a Global
    myDashboardMenu: [
      { label: 'Edit', value: 'edit', iconUrl: Edit },
      { label: 'View', value: 'pop', iconUrl: View },
      { label: 'Configure', value: 'configure', iconUrl: settingsBlack },
      { label: 'Clone', value: 'clone', iconUrl: Clone },
    ],

    // Row options Available for User who Created the instance and Instance is My Instance
    myDashboardMenuGlobal: [
      { label: 'Edit', value: 'edit', iconUrl: Edit },
      { label: 'View', value: 'pop', iconUrl: View },
      { label: 'Configure', value: 'configure', iconUrl: settingsBlack },
      { label: 'Clone', value: 'clone', iconUrl: Clone },
      { label: 'Global Share', value: 'global', iconUrl: Share }
    ],

    // Row options Available for User who can only view the instance
    globalDashboardMenu: [{ label: 'View', value: 'pop', iconUrl: View }, { label: 'Clone', value: 'clone', iconUrl: Clone }]
  };
  const [count, setCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [currentRow, setCurrentRow] = useState({});
  const [instanceName, setInstanceName] = useState();
  const [showFullView, setShowFullView] = useState(false);
  const [assetShortlist, setAssetShortlist] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [instanceSelected, setInstanceSelected] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    showWidgetAdd: false,
    showShareGlobalConfirmation: false,
    showCloneConfirmationModal: false,
  });

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setSearchQueries, setWidgetType, valueSetter, setFormInput, setToastError, resetInstanceData } = useActions();

  const userData = useSelector((state) => state.loginData.userData);
  const loading = useSelector((state) => state.HomeDashboardReducer.loading);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const instanceMeta = useSelector((state) => state.HomeDashboardReducer.instanceMeta);
  const instanceData = useSelector((state) => state.HomeDashboardReducer.instanceData);
  const searchQueriesReducer = useSelector((state) => state.HomeDashboardReducer.searchQueries);

  const { isHome = false } = location.state || {};

  /**
   * Reducer action to store the search queries
   * @param {*} payload
   */
  const saveSearchQueries = (payload) => {
    setSearchQueries(payload || {});
  };

  /**
   * Function to be called on forward and backward on pagination
   * @param {*} page
   * @param {*} searchQueries
   */
  const handlePageChange = (page, searchQueries) => {
    setCurrPage(page);
    const endpoint = apiEndPoints.getWidgetInstancesList;
    dispatch(
      HomeDashboardCreators.getWidgetInstancesList({
        page,
        pageSize,
        endpoint,
        searchQueries,
        IsHome: isHome ? 1 : 0,
        UserId: userData?.userId
      })
    );
  };

  useEffect(() => {
    handlePageChange(1);
    return () => {
      saveSearchQueries();
    };
  }, []);

  /**
   * setting the asset short list with default slected values from API
   */
  useEffect(() => {
    const arraySelected = [];
    for (let i = 0; i < instanceData?.length; i += 1) {
      if (instanceData[i]?.IsSelected && !instanceSelected.includes(instanceData[i]?.WID)) {
        arraySelected.push(instanceData[i]);
      }
    }
    setAssetShortlist([...assetShortlist, ...arraySelected]);
  }, [instanceData]);
  // setting state for total number of widget to home
  useEffect(() => {
    setCount(instanceMeta?.HomeWidgetsCount);
  }, [instanceMeta?.HomeWidgetsCount]);

  useEffect(() => {
    const arraySelected = [];
    for (let i = 0; i < assetShortlist?.length; i += 1) {
      arraySelected.push(assetShortlist[i].WID);
    }
    setInstanceSelected(arraySelected);
  }, [assetShortlist]);

  /**
   *Function to update the widget instance name
   * @param {*} value Receives the active row as parameter
   */
  const handleEdit = async (value) => {
    try {
      const fullUrl = `${apiEndPoints.updateOnlyNameWidgetInstance}`;
      const res = await WebService.post(fullUrl, {
        WidgetName: instanceName,
        WID: value.WID,
        UserId: userData.userId
      });
      if (res.status >= 200 && res.status < 300) {
        setShowEditModal(false);
        handlePageChange(currPage);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
  };

  /**
  *Function to Handle View option from the drop down. Opens the pop up view of Widget
  * @param {*} value Receives the active Row
  */
  const handleFullView = (value) => {
    setShowFullView(true);
    setCurrentRow(value);
  };

  /**
   * Function to handle the configuration of Individual Widget Instance
   * @param {*} value Receives the active Row
   * Redirects the page to Form Page
   */
  const handleWidgetConfiguration = async (value) => {
    const { type, widgetCategory, formData } = value?.WidgetComponentData;
    setWidgetType({
      widgetType: type,
      widgetLabel: widgetTypesMenu?.[type]?.label || type,
      //  TODO_W_R: add widget category also
      [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: widgetCategory
    });
    valueSetter({ widgetName: value?.WidgetName });
    setFormInput(formData);
    const resolvedWidgetCategory = getResolvedWidgetCategory(
      widgetCategory,
      type
    );
    if (
      resolvedWidgetCategory === WIDGET_CATEGORIES.OEE_DONUT ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.BAR_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.SVG ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.PIVOT_TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CONCENTRIC_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.STATUS_VALUE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.DETAILED_STATUS_VALUE
    ) {
      history.push({
        pathname: '/home/create-widget/form',
        state: { isEdit: true, fromInstanceList: true, WID: value.WID }
      });
    } else {
      history.push({
        pathname: '/home/create-widget/',
        state: { isEdit: true, fromInstanceList: true, WID: value.WID }
      });
    }
  };

  /**
   * Function to clone the Widget Instance
   * @param {*} activeRow Receives the selected row
   */
  const handleClone = async (activeRow) => {
    setShowConfirmationModal({
      ...showConfirmationModal,
      showCloneConfirmationModal: false
    });
    try {
      const fullUrl = `${apiEndPoints.cloneWidgetInstance}`;
      const res = await WebService.post(fullUrl, {

        WID: activeRow?.WID,
        UserId: userData.userId

      });
      if (res.status >= 200 && res.status < 300) {
        handlePageChange(1);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
  };

  /**
   * Function to global share the Widget Instance
   * @param {*} activeRow Receives the selected row
   */
  const handleGlobalShare = async (activeRow) => {
    setShowConfirmationModal({
      ...showConfirmationModal,
      showShareGlobalConfirmation: false
    });
    try {
      const fullUrl = `${apiEndPoints.updateOnlyNameWidgetInstance}`;
      const res = await WebService.post(fullUrl, {
        IsShareable: 1,
        WID: activeRow.WID,
        UserId: userData.userId
      });
      if (res.status >= 200 && res.status < 300) {
        setShowEditModal(false);
        handlePageChange(1);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
  };

  /**
   *
   * @param {*} e Receives the element selected
   * @param {*} isCheckRequired
   */
  const handleShortList = (e, isCheckRequired = true) => {
    const found = assetShortlist.some((el) => el.WID === e.WID);
    if (isCheckRequired) {
      if (found) {
        setCount((prev) => prev - 1);
        const index = assetShortlist.findIndex((ele) => ele.WID === e.WID);
        assetShortlist.splice(index, 1);
        setAssetShortlist([...assetShortlist]);
      } else if (count < MAX_WIDGETS_IN_HOME) {
        setCount((prev) => prev + 1);
        setAssetShortlist([...assetShortlist, e]);
      }
    } else if (e.length > MAX_WIDGETS_IN_HOME) {
      const arrayWithLimitedNumber = e.splice(0, MAX_WIDGETS_IN_HOME);
      setAssetShortlist([...arrayWithLimitedNumber]);
    } else {
      setAssetShortlist([...e]);
    }
  };

  /**
   *
   * @param {*} opt Receives the option clicked
   * @param {*} rowDetails receives the active row details
   */
  const handleRowOptionsItemClick = (opt, rowDetails) => {
    const { value } = opt;
    const { activeRowItem } = rowDetails;
    // valueSetter({ customTemplateTab: false });
    switch (value) {
      case 'edit':
        setShowEditModal(true);
        setInstanceName(activeRowItem?.WidgetName);
        setCurrentRow(activeRowItem);
        break;
      case 'pop':
        handleFullView(activeRowItem);
        break;
      case 'configure':
        handleWidgetConfiguration(activeRowItem);
        break;
      case 'clone':
        setShowConfirmationModal({
          ...showConfirmationModal,
          showCloneConfirmationModal: true
        });
        setCurrentRow(activeRowItem);
        break;
      case 'global':
        setShowConfirmationModal({
          ...showConfirmationModal,
          showShareGlobalConfirmation: true
        });
        setCurrentRow(activeRowItem);
        break;
      default:
        break;
    }
  };

  /**
   * Called when Add is Clicked. Shows Confirmation if I want to Add it to the home screen
   */
  const handleAddToHome = () => {
    setShowConfirmationModal({
      ...showConfirmationModal,
      showWidgetAdd: true
    });
  };

  /**
   * Function to update the home with selected widgets
   */
  const addToHomeHandler = () => {
    const widgetPayload = {
      UserId: userData.userId,
      WIDs: instanceSelected

    };
    const onSuccessNavigation = () => {
      setShowConfirmationModal({
        ...showConfirmationModal,
        showWidgetAdd: false
      });
      resetInstanceData();
      setAssetShortlist([]);
      // history.goBack();
      history.push('/home');
    };
    dispatch(
      HomeDashboardCreators.addWidgetToHome({
        widgetPayload,
        onSuccessNavigation
      })
    );
  };

  /**
   *
   * @param {*} page is the current Page
   * @param {*} searchQueries  is if search is called
   */

  const selectionTypeandPosition = {
    isCheckboxTypeSelection: true,
    isSelectionAtFirst: true
  };

  return (
    <Container>
      <HeadingContainer>
        <HeadingText>Instance Widget</HeadingText>
        <div style={{ display: 'flex' }} />
        <ButtonContainer>
          <HeaderRight>
            {/* <ButtonComp dark={true} style={{ width: '100%', padding: '0 10px', fontSize: '4rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <DynamicSVGIcon iconUrl={addIcon} />
                Create Widget Instance
              </div>
            </ButtonComp> */}

          </HeaderRight>
          <ButtonComp onClick={() => { history.goBack(); }}>Discard</ButtonComp>
          <ButtonComp dark={true} disabled={count > MAX_WIDGETS_IN_HOME} onClick={handleAddToHome}>Add</ButtonComp>
        </ButtonContainer>

      </HeadingContainer>
      <BodyContainer>
        <NumberOfWidgetsText>
          <Dot color={count >= MAX_WIDGETS_IN_HOME && '#E91D15'}>. </Dot>
          <Text color={count >= MAX_WIDGETS_IN_HOME && '#E91D15'}>
            {`${count || 0}/${MAX_WIDGETS_IN_HOME} Spaces occupied in home`}
          </Text>
        </NumberOfWidgetsText>
        {/* {!isEmpty(instanceData) && !isEmpty(instanceMeta) ? ( */}
        <TabsTable
          metaDetails={instanceMeta}
          dataDetails={instanceData}
          showShortlistSelection={true}
          hasObjectContent={true}
          assetListLoading={loading}
          handleShortList={handleShortList}
          onItemSelect={() => { }}
          showPagiation={true}
          assetShortlist={assetShortlist}
          rowOptionsWithCondition={rowOptionsWithCondition}
          handlePageChange={handlePageChange}
          styleType='strapedTable'
          noItemsFoundMessage='No Data Found'
          showRowOptionAtStart={false}
          saveSearchQueries={saveSearchQueries}
          savedSearchQueries={searchQueriesReducer}
          resetSearchQueries={saveSearchQueries}
          id='WID'
          handleRowOptionsItemClick={handleRowOptionsItemClick}
          shortListSelectionDetails={selectionTypeandPosition}
          selectAllVisible={false}
        />
        {/* ) : (
          <Text style={{ textAlign: 'center', fontSize: '1.8rem', padding: '1rem' }} className='decription center'>No Data</Text>
        )} */}
      </BodyContainer>
      {showEditModal && (
        <PopUpModal
          width='50%'
          closeModalHandler={setShowEditModal}
          disableOutsideClick={true}
        >
          <Heading>{`Edit ${currentRow?.WidgetName}`}</Heading>
          <FlexContainer
            className='ai_center jc_spacebetween'
            style={{ marginBottom: '1rem' }}
          >
            <p style={{ fontSize: '1.6rem' }}>Instance Name</p>
            <Input
              placeholder='Instance Name'
              style={{
                backgroundColor: 'transparent',
                width: '25rem',
                minWidth: '25rem'
              }}
              ContainerStyle={{ marginBottom: '0' }}
              value={instanceName}
              handleInputChange={(e) => {
                setInstanceName(e.target.value);
              }}
              maxLength={200}
            />
          </FlexContainer>
          <Footer style={{ marginTop: '3rem' }}>
            <BtnWrapper style={{ width: '40%', alignItems: 'flex-end' }}>
              <Button
                type='button'
                style={{
                  boxShadow:
                    '-5px -5px 19px rgba(255,255,255,0.1), 3px 3px 10px rgba(0,0,0,0.1)'
                }}
                onClick={() => {
                  setShowEditModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                className='dark'
                disabled={isEmpty(instanceName) || (
                  instanceName === currentRow?.WidgetName)}
                onClick={() => {
                  handleEdit(currentRow);
                }}
              >
                Submit
              </Button>
            </BtnWrapper>
          </Footer>
        </PopUpModal>
      )}
      <ConfirmationModal
        showModal={showConfirmationModal?.showWidgetAdd}
        action="Add"
        Header="Are you Sure?"
        handleDelete={addToHomeHandler}
        closeModalHandler={() => {
          setShowConfirmationModal({
            ...showConfirmationModal,
            showWidgetAdd: false
          });
        }}
        message="You want to Add these Widget to Home"
      />
      <ConfirmationModal
        showModal={showConfirmationModal.showCloneConfirmationModal}
        action="Confirm"
        Header="Clone"
        handleDelete={() => { handleClone(currentRow); }}
        closeModalHandler={() => {
          setCurrentRow({});
          setShowConfirmationModal({
            ...showConfirmationModal,
            showCloneConfirmationModal: false
          });
        }}
        message='Click Confirm to Clone the Instance?'
      />
      <ConfirmationModal
        showModal={showConfirmationModal?.showShareGlobalConfirmation}
        action="Confirm"
        Header="Global Share"
        handleDelete={() => { handleGlobalShare(currentRow); }}
        closeModalHandler={() => {
          setCurrentRow({});
          setShowConfirmationModal({
            ...showConfirmationModal,
            showShareGlobalConfirmation: false
          });
        }}
        message='Click Confirm to Globally Share the Instance ?'
      />
      {showFullView && <FullViewWidget componentData={currentRow.WidgetComponentData} fullView={true} WID={currentRow.WID} setShowFullView={setShowFullView} />}
    </Container>
  );
};

export default WidgetInstancesList;
