/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import RadioTypeComponent from '../../Common/InputTypes/RadioType/RadioType.view';
import CheckboxTypeComponent from '../../Common/InputTypes/CheckboxType/CheckboxType.view';
import TextTypeComponent from '../../Common/InputTypes/TextType/TextType.view';
import DropdownTypeComponent from '../../Common/InputTypes/DropdownType/DropdownType.view';
import { UpsertWidgetCreators } from '../Store';
import {
  ListContainer,
  SelectedList,
  CancelIcon,
  Item,
  Container
} from './AddWidgetForm.style';
import { INPUT_TYPES } from '../../../constants';
import EquipmentSearchView from '../../Common/InputTypes/EquipmentSearch/EquipmentSearch.view';
import EquipmentFilterSearchType from '../../Common/InputTypes/FilterSearchType/EquipmentFilterSearchType.view';
import Toast from '../../Common/Toast';
import { useActions } from '../../../store/hooks/useActions';
import Loader from '../../Common/Loader/Loader';
import { getResetPayloadForDependentFields } from './Utility';
import WidgetFooter from '../WidgetFooter/WidgetFooter.view';
import { genericFormValidation } from '../../Widget/utils/formValidation';
import UpsertSaveHeader from '../UpsertSaveHeader';
import { isEmpty } from '../../../shared/utility/isEmpty';
// import { CancelBlack } from '../../../assets/icons';
// import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
// import SearchBarWithDropdown from '../../Common/SearchBarWithDropdown';
// import ShowFilterItems from '../../Common/ShowFilterItems/ShowFilterItems.view';
// import { ListContainer, SelectedList, CancelIcon } from './AddWidgetForm.style';
// import FilterContainer from '../../Filter/FilterContainer/FilterContainer';
// import FilterInputType from '../../Common/InputTypes/FilterType/FilterInputType';

const AddWidgetForm = (props, ref) => {
  const {
    loading,
    formStructureError, // any error from the form
    error
  } = useSelector((state) => state.upsertWidgetData);
  const initialFormInp = useSelector((state) => state.upsertWidgetData.formInput);

  const { setFormStructureFailure, getWidgetDataFailure } = useActions();
  const {
    data,
    disabledFields,
    showFooter, // boolean
    hideModal,
    handleSave,
    showHeader, // boolean
    header,
    handleBack,
    formInput: globalFormInput,
    formStructureFieldDepenents,
    allowHorizontal = true
    // setFormInput: setGlobalFormInput
  } = props;
  const [formInput, setFormInput] = useState([]);
  const [fieldsTobeRefetched, setFieldsTobeRefetched] = useState([]);
  const { clearFilterData } = useActions();
  // console.log('globalFormInput', globalFormInput);
  // const shouldForceRefetch = useRef();
  const isFormIntialized = useRef();

  useEffect(() => {
    clearFilterData();
  }, []);

  useEffect(() => {
    // populate values in fields
    if (globalFormInput) {
      // console.log('=========1');
      setFormInput(globalFormInput);
    }
  }, [globalFormInput]);

  useEffect(() => {
    // if globalforminput is filled then formInput must be filled
    if (!isEmpty(data)) {
      const isFormFilled = !isEmpty(globalFormInput)
        ? !isEmpty(formInput)
        : true;
      if (
        // !shouldForceRefetch.current &&
        !isFormIntialized.current &&
        isFormFilled
      ) {
        // formIsFilled but formIntialized = false, shouldForceRetch = false
        // console.log('1===========enable force refetch');
        // shouldForceRefetch.current = true;
        isFormIntialized.current = true;
        const dependentFieldsList = Object.values(
          formStructureFieldDepenents
        ).reduce((e, final) => [...final, ...e], []);
        // console.log('=======form initalized==========', formInput);
        // console.log(
        //   '=========dependentFieldsList==============',
        //   data,
        //   formStructureFieldDepenents,
        //   dependentFieldsList
        // );
        setFieldsTobeRefetched(dependentFieldsList);
      }
      // else if (shouldForceRefetch.current) {
      //   // console.log('2=============setting these fields to refetch', dependentFieldsList);
      //   shouldForceRefetch.current = false;
      // }
    }
  }, [formInput, data]);

  useImperativeHandle(
    ref,
    () => ({
      getFormInput() {
        return formInput;
      }
    }),
    [formInput]
  );

  const resetFieldsTobeRefetched = () => {
    setFieldsTobeRefetched([]);
  };
  const resetDependents = (keyOfChangedField, additionalProps) => {
    const { shouldUpdateRefetch = true, localFormInput } =
      additionalProps || {};
    const latestFormInput = localFormInput || formInput;
    const dependents = formStructureFieldDepenents?.[keyOfChangedField];
    // console.log('resting as', keyOfChangedField, dependents);
    if (shouldUpdateRefetch) {
      setFieldsTobeRefetched(dependents);
    }
    const payloadTobeReseted = getResetPayloadForDependentFields(
      dependents,
      latestFormInput
    );
    // console.log('reseting', keyOfChangedField, dependents, payloadTobeReseted);
    return payloadTobeReseted;
  };

  // after first setting of formInput
  // useEffect(() => {
  //   console.log('fresh ...');
  //   // list of all the dependents
  //   const check = Object.values(formStructureFieldDepenents).reduce((e, final) => [...final, ...e], []);
  //   // console.log('check', formStructureFieldDepenents, check);
  //   let localFormInput = { ...globalFormInput };
  //   check.forEach((field) => {
  //     console.log('localFormInput', { ...localFormInput });
  //     localFormInput = { ...localFormInput, ...resetDependents(field, { shouldUpdateRefetch: true, localFormInput }) };
  //   });
  //   // setFormInput((formInput) => ({ ...formInput, ...payloadTobeReseted }));
  //   // set the form with the actual values
  //   setFormInput(localFormInput);
  //   setFieldsTobeRefetched(check);
  //   console.log('check', check, localFormInput, globalFormInput);
  // }, [globalFormInput]);

  const handleType = (ele, item) => {
    const { itemKey: key } = item;
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele
    }));
  };

  const clearError = () => {
    setFormStructureFailure('');
    getWidgetDataFailure('');
  };

  const multiValueSelectionHandler = (
    item,
    { key, itemValueKey = 'value' }, // field related properties
    additionalParams = {} // other props required
  ) => {
    try {
      // console.log('checkbox handler', isFormIntialized.current);
      if (isFormIntialized.current) {
        const { isDirectUpdate = false } = additionalParams;
        const payloadTobeReseted = resetDependents(key);
        // direct list as value for that key is sent
        if (isDirectUpdate) {
          // console.log('1============direcct', key, item, payloadTobeReseted);
          setFormInput((formInput) => ({
            ...formInput,
            ...payloadTobeReseted,
            [key]: item
          }));
        } else {
          // checking if already selected are not
          const selections = formInput?.[key] || [];
          const itemIndex = selections.findIndex(
            (prevItem) => prevItem?.[itemValueKey] === item?.[itemValueKey]
          );
          // console.log('selections', itemIndex, selections, key, formInput?.[key]);
          // console.log('item', item, key, 'itemValueKey', itemValueKey, 'selections', selections, itemIndex);
          if (itemIndex > -1) {
            const newSelections = [
              ...selections.slice(0, itemIndex),
              ...selections.slice(itemIndex + 1)
            ];
            //  selections.splice(itemIndex, 1);
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: newSelections
            }));
          } else {
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: [...selections, item]
            }));
          }
        }
      }
    } catch (e) {
      console.log('checkbox selection', e);
    }
  };

  const formValueSetter = (item, { key }) => {
    if (isEmpty(initialFormInp)) {
      const payloadTobeReseted = resetDependents(key);
      setFormInput((formInput) => ({
        ...formInput,
        ...payloadTobeReseted,
        [key]: item
      }));
    }
  };

  const textInputHandler = (ele, key) => {
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele.target.value
    }));
  };
  const removeListItem = (item, key) => {
    const payloadTobeReseted = resetDependents(key);
    const equipmentArray = formInput?.[key]?.filter(
      (prevItem) => prevItem !== item
    );
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: equipmentArray
    }));
  };
  const isSaveEnabled = genericFormValidation(data, formInput);
  const Header = (
    <>
      {showHeader && (
        <UpsertSaveHeader
          header={header} // {widgetLabel ? `${isEdit ? 'Edit' : 'Add'} ${widgetLabel} Pane` : activeTab.label}
          handleBack={handleBack}
          handleSave={handleSave}
          enableSave={isSaveEnabled && header && true}
          submissionLoader={loading}
        />
      )}
    </>
  );
  // console.log('form==ss=2=', data, formInput);
  return (
    <>
      {Header}
      <div
        className='formContainer'
        style={{ position: 'relative', minHeight: '50rem' }}
      >
        {loading && <Loader width='75%' height='70vh' position='fixed' />}
        <Container
          width='92rem'
          height='48rem'
          className={`status_value_widget ${
            allowHorizontal ? 'allowHorizontal' : ''
          }`}
        >
          {data?.map((item) => {
            // enable this check only if form is intialized
            const tobeRefetched = isFormIntialized.current
              ? fieldsTobeRefetched.includes(item.key)
              : false;
            // console.log('tobeRefetched', item.key, tobeRefetched, fieldsTobeRefetched);
            const commonProps = {
              resetFieldsTobeRefetched,
              formInput,
              tobeRefetched,
              formStructure: data,
              isFormIntialized: isFormIntialized.current
            };
            const disabled = disabledFields?.some((e) => e === item.key);
            if (item.inputType === INPUT_TYPES.EQUIPMENT_SEARCH) {
              const { key } = item;
              return (
                <div className={disabled ? 'disable' : ''}>
                  <EquipmentSearchView
                    item={item}
                    fieldClassName='none'
                    value={formInput?.[key]}
                    itemSelectionHandler={multiValueSelectionHandler}
                    removeListItem={removeListItem}
                    {...commonProps}
                  />
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.FILTER_SEARCH) {
              return (
                <div className={disabled ? 'disable' : ''}>
                  <EquipmentFilterSearchType
                    item={item}
                    fieldClassName='none'
                    value={formInput?.[item.key]}
                    formValueSetter={formValueSetter}
                    {...commonProps}
                  />
                  {/* <ShowFilterItems list={formInput?.equipments} removeListItem={removeListItem} itemKey={item.key} /> */}
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.RADIO) {
              return (
                <div className={disabled ? 'disable' : ''}>
                  <RadioTypeComponent
                    item={item}
                    fieldClassName='none'
                    value={formInput?.[item.key]} // values tobe shown
                    handleChange={formValueSetter}
                    {...commonProps}
                  />
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.CHECKBOX) {
              // console.log(item.key, 'item item');
              return (
                <div className={disabled ? 'disable' : ''}>
                  <CheckboxTypeComponent
                    item={item}
                    fieldClassName='none'
                    value={formInput?.[item.key]} // values tobe shown
                    handleChange={multiValueSelectionHandler}
                    {...commonProps}
                  />
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.TEXT) {
              return (
                <div className={disabled ? 'disable' : ''}>
                  <KeyInputLayoutView
                    fieldClassName='none'
                    label={item.label}
                    className='add_equipment'
                    width='50rem'
                  >
                    <TextTypeComponent
                      item={item}
                      value={item?.[item.key]} // form inp value
                      textInputHandler={textInputHandler}
                      {...commonProps}
                    />
                  </KeyInputLayoutView>
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.DROPDOWN) {
              return (
                <div className={disabled ? 'disable' : ''}>
                  <KeyInputLayoutView
                    fieldClassName='none'
                    label={item.label}
                    className='add_equipment'
                    width='50rem'
                    isRequired={item.isRequired}
                  >
                    <DropdownTypeComponent
                      showSelection={true}
                      isActiveChecker={(activeItem, item) =>
                        activeItem === item.label}
                      item={item}
                      handleClick={handleType}
                      value={formInput?.[item.key]}
                      itemKey={item.key}
                      {...commonProps}
                    />
                  </KeyInputLayoutView>
                </div>
              );
            }

            return null;
          })}
        </Container>
        {(formStructureError || error) && (
          <Toast
            header='Error'
            className='fixed right'
            message={formStructureError || error}
            fn={clearError}
          />
        )}
      </div>
      {showFooter && (
        <WidgetFooter
          hideModal={hideModal}
          handleSave={handleSave}
          enableSave={isSaveEnabled}
        />
      )}
    </>
  );
};
export default forwardRef(AddWidgetForm);
