/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import { Creators as configCreators } from '../../config/action';
import {
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { AvatarComponent, SideNav, IconComponent } from '../../component';
import { Creators as LayoutCreators } from './store';

// import { Icons } from '../../constants/icons';
import Home from '../../routes/HomeRoutes';

import { FlexContainer } from '../../globalStyles';
import { Body, Content, Header, LogoText } from './Layout.styles';
import { useWindowSize } from '../../shared/hooks/useWindowResize';
import useClickOutside from '../../shared/hooks/useClickOutside';

import GlobalSearchContainer from '../../component/GlobalSearch/GlobalSearchContainer';
// import { THEMES } from '../../constants';
import NotificationsModal from '../../component/HeaderOptions/NotificationsModal/NotificationsModal.view';
import SettingsDropdown from '../../component/HeaderOptions/SettingsDropdown/SettingsDropdown.view';
import HelpDropdown from '../../component/HeaderOptions/HelpDropdownModal/HelpDropdownModal';
import SettingsModal from '../../component/HeaderOptions/SettingsModal/SettingsModal.view';
import ChangePwdModal from '../../component/HeaderOptions/SettingsModal/Accounts/ChangePwdModal/ChangePwdModal.view';
import DetailedAlaramModal from '../../component/Alarams/DetailedAlaramModal/DetailedAlaramModal.view';
import AlaramsCommentsModal from '../../component/Alarams/AlaramsCommentsModal/AlaramsCommentsModal.view';
// import osramLogo from '../../assets/Images/amsOSRAM.png';
import Logo from '../../component/Common/Logo';
// import ApprovalsModal from '../../component/Approvals/DetailsModal/DetailsModal.view';
// import ApprovalRejectModal from '../../component/Approvals/RejectModal/ApprovalRejectModal.view';
// import ApprovalApproveModal from '../../component/Approvals/ApproveModal/ApprovalApproveModal.view';
// import SchedulesModal from '../../component/Schedule/DetailsModal/DetailsModal.view';
// import ScheduleRejectModal from '../../component/Schedule/RejectModal/ScheduleRejectModal.view';
// import ScheduleApproveModal from '../../component/Schedule/ApproveModal/ScheduleApproveModal.view';
import config from '../../config.json';
import { getUserDataFromLocal } from '../../shared/utility/helper';
import { useActions } from '../../store/hooks/useActions';
import { PAGE_TYPES, PANE_TYPES } from '../../constants';
import {
  setAccessTokenToHeader,
  setCustomHeader
} from '../../services/axios/helper';
import ButtonComp from '../../component/Common/Button/Button.view';
import ProfileSettingsDropdown from '../../component/HeaderOptions/ProfileSettingDropdown/ProfileSettingsDropdown';

const Dashboard = (props) => {
  const {
    history,
    match,
    // home reducer
    showSideNav,
    notificationsModal,
    helpDropdown,
    settingsDropdown,
    profileSettingsDropdown,
    settingsModalActiveTab,
    changePwd,
    showNotificationTabs,
    // login data reducer
    userData,
    // kpvcharts reducer
    paneFullScreen,
    // alarams
    alaramOfMachineId,
    showAlaramCommentsModal,
    token,
    keyField
  } = props;
  const [width] = useWindowSize();
  const [view, setView] = useState('mobile');
  const [showSearchBarInMobile, setShowSearchBarInMobile] = useState(false);
  // const [modalIsVisibal, setModalIsVisibal] = useState(false);
  const dispatch = useDispatch();
  const sideNavRef = useRef();
  const headerRef = useRef();
  // const Filter = useSelector((state) => state.home.[PAGE_TYPES.FILTER]);
  const Widget = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const menuIdofWidget = useSelector((state) => state.widgets?.menuIdofWidget);
  const menu = useSelector((state) => state.home?.menu);
  const { pathname } = useLocation();
  const { resetLogoutClick } = useActions();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    resetLogoutClick();
  }, []);

  // const {
  //   showDetailsModal: showApprovalDetails,
  //   approvalsRejectModal,
  //   approvalsApproveModal
  // } = useSelector((state) => state.approvals);

  // const {
  //   showDetailsModal: showScheduleDetails,
  //   schedulesRejectModal,
  //   schedulesApproveModal
  // } = useSelector((state) => state.schedules);

  // const { theme } = useSelector((state) => state.configData);
  const fetchMenu = async () => {
    await setAccessTokenToHeader(`Bearer ${token}`);
    await setCustomHeader(userData[keyField]);
    dispatch(LayoutCreators.getMenu({ company: config.company, userData }));
    dispatch(
      LayoutCreators.getNotificationsCount({ userId: userData?.userId })
    ); // userData.userId
  };
  useEffect(() => {
    if (token && keyField) {
      fetchMenu();
    }
  }, [token]);

  // setting default menuid for necessary pagetypes
  // removed as this is handled in Widget component(base of widget page type)
  // useEffect(() => {
  //   // widget menuid init only if menuIdofWidget in not intialized
  //   if (Widget && !menuIdofWidget) {
  //     const firstMenuId = Object.keys(Widget)[0];
  //     setMenuIdOfWidget(firstMenuId);
  //   }
  //   // TODO: make it for filter if needed
  // }, [menu]);

  useEffect(() => {
    if (window.opener) {
      setView('pop');
      dispatch(LayoutCreators.setShowSideNav({ showSideNav: false }));
    } else {
      if (width >= 1024 && !pathname?.includes('/view-dashboard/')) {
        setView('web');
        dispatch(LayoutCreators.setShowSideNav({ showSideNav: true }));
      }

      if (width <= 768 && !pathname?.includes('/view-dashboard/')) {
        setView('mobile');
        dispatch(LayoutCreators.setShowSideNav({ showSideNav: false }));
      }
    }
  }, [width]);

  useEffect(() => {
    if (pathname?.includes('/view-dashboard/')) {
      setView('pop');
    }
  }, []);

  const handleToggle = () => {
    dispatch(LayoutCreators.setShowSideNav({ showSideNav: !showSideNav }));
  };

  useClickOutside(
    sideNavRef,
    () =>
      view === 'mobile' &&
      showSideNav &&
      dispatch(LayoutCreators.setShowSideNav({ showSideNav: false })),
    headerRef
  );

  // const hanldeModalItemClick = (role) => {
  //   if (role === 'filter') {
  //     //
  //   } else {
  //     dispatch(AuthCreators.onLogout());
  //   }
  //   setModalIsVisibal(false);
  // };

  const handleHeaderOptClick = (option) => {
    dispatch(LayoutCreators.toggleHeaderOption(option));
  };

  const closeDropModals = (option) => {
    if (option === 'profileSettingsDropdown' && profileSettingsDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption(option));
    }

    if (option === 'settingsDropdown' && settingsDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption(option));
    }

    if (option === 'helpDropdown' && helpDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption(option));
    }
  };

  const avatarRef = useRef();
  useClickOutside(avatarRef, () => closeDropModals('profileSettingsDropdown'));

  // closes the general settings modal when click outside
  const settingsRef = useRef();
  useClickOutside(settingsRef, () => closeDropModals('settingsDropdown'));

  // closes the help settings modal when click outside
  const helpRef = useRef();
  useClickOutside(helpRef, () => closeDropModals('helpDropdown'));
  const unreadNotifications = useMemo(
    () =>
      showNotificationTabs.reduce(
        (count, tab) => count + (tab.isVisible === false ? 0 : tab.Unread),
        0
      ),
    [showNotificationTabs]
  );

  const CopyToClipboard = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <>
      <Header ref={headerRef}>
        <>
          {view === 'mobile' && (
            <IconComponent
              name='menuWhite'
              onClick={handleToggle}
              cursor='pointer'
            />
          )}
        </>
        {(!showSearchBarInMobile || view === 'web') && (
          // <Logo>
          //   <img src={osramLogo} alt='osram' height='45px' />
          // </Logo>
          <Logo
            height={config.company === 'IQM' ? '25px' : '45px'}
            isInLogin={false}
          />
        )}
        <>
          {(view === 'web' || showSearchBarInMobile) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GlobalSearchContainer
                history={history}
                setShowSearchBarInMobile={setShowSearchBarInMobile}
              />
              {showSearchBarInMobile && (
                <IconComponent
                  name='CancelWhite'
                  ml={2}
                  size={1.5}
                  onClick={() => setShowSearchBarInMobile(false)}
                />
              )}
            </div>
          )}
        </>
        <>
          {view === 'web' ? (
            <FlexContainer className='jc_center ai_center web'>
              {/* settings Modal */}
              {settingsModalActiveTab && <SettingsModal />}

              {/* alarms Modal */}
              {alaramOfMachineId &&
                (!showAlaramCommentsModal ? (
                  <DetailedAlaramModal />
                ) : (
                  <AlaramsCommentsModal />
                ))}

              {/* approvals Details Modal start */}
              {/* {showApprovalDetails && <ApprovalsModal />}
              {approvalsRejectModal && <ApprovalRejectModal />}
              {approvalsApproveModal && <ApprovalApproveModal />} */}
              {/* approvalsDetails Modal Ends */}

              {/* approvals Details Modal start */}
              {/* {showScheduleDetails && <SchedulesModal />}
              {schedulesRejectModal && <ScheduleRejectModal />}
              {schedulesApproveModal && <ScheduleApproveModal />} */}
              {/* approvalsDetails Modal Ends */}

              {/* change password Modal */}
              {changePwd && <ChangePwdModal />}

              {/* Notifications Modal */}
              <>
                <div style={{ position: 'relative' }}>
                  <IconComponent
                    name='notification'
                    mx={1.5}
                    onClick={() => handleHeaderOptClick('notificationsModal')}
                    cursor='pointer'
                  />
                  {!config.hide_notifications && unreadNotifications > 0 && (
                    <span
                      style={{
                        background: 'red',
                        position: 'absolute',
                        top: '-1rem',
                        right: '0px',
                        padding: '0.2rem',
                        fontSize: '1.6rem',
                        borderRadius: '1rem'
                      }}
                    >
                      {unreadNotifications}
                    </span>
                  )}
                </div>
                {notificationsModal && <NotificationsModal />}
              </>

              {/* Help Dropdown Modal */}
              <div style={{ position: 'relative' }} ref={helpRef}>
                <IconComponent
                  name='help'
                  mx={1.5}
                  cursor='pointer'
                  onClick={() => handleHeaderOptClick('helpDropdown')}
                />
                {helpDropdown && <HelpDropdown />}
              </div>

              {/* Settings Dropdown Modal */}

              <div style={{ position: 'relative' }} ref={settingsRef}>
                <IconComponent
                  name='settings'
                  mx={1.5}
                  cursor='pointer'
                  onClick={() => handleHeaderOptClick('settingsDropdown')}
                />
                {settingsDropdown && <SettingsDropdown />}
              </div>

              {/* Profile Dropdown Modal */}
              <div style={{ position: 'relative' }} ref={avatarRef}>
                <AvatarComponent
                  userdata={userData}
                  circle='circle'
                  ml='1.5rem'
                  onClick={() =>
                    handleHeaderOptClick('profileSettingsDropdown')
                  }
                  // onClick={(e) => setModalIsVisibal(!modalIsVisibal)}
                />
                {profileSettingsDropdown && <ProfileSettingsDropdown />}
                {/* {modalIsVisibal && (
                  <DropDownModal
                    kababIconRef={avatarRef}
                    setModalIsVisibal={setModalIsVisibal}
                    data={profileMenuData}
                    style={{ top: '100%', right: 0, zIndex: 250 }}
                    handleClick={hanldeModalItemClick}
                  />
                )} */}
              </div>
            </FlexContainer>
          ) : (
            !showSearchBarInMobile && (
              <FlexContainer className='jc_center ai_center mobile'>
                <IconComponent
                  name='searchW'
                  onClick={() => setShowSearchBarInMobile(true)}
                />
              </FlexContainer>
            )
          )}
        </>
        {pathname?.includes('/view-dashboard/') && (
          <ButtonComp onClick={CopyToClipboard}>
            {copied ? 'Copied!' : 'Copy link'}
          </ButtonComp>
        )}
      </Header>
      <Body>
        {!paneFullScreen && view !== 'pop' && (
          <SideNav
            sideNavRef={sideNavRef}
            isExact={match.isExact}
            view={view}
          />
        )}
        <Content active={showSideNav}>
          <Home />
        </Content>
      </Body>
    </>
  );
};

const mapStateToProps = (state) => ({
  showSideNav: state.home.showSideNav,
  notificationsModal: state.home.notificationsModal,
  helpDropdown: state.home.helpDropdown,
  settingsDropdown: state.home.settingsDropdown,
  profileSettingsDropdown: state.home.profileSettingsDropdown,
  settingsModalActiveTab: state.home.settingsModalActiveTab,
  changePwd: state.home.changePwd,
  showNotificationTabs: state.home.showNotificationTabs,
  userData: state.loginData.userData,
  token: state.loginData.token,
  keyField: state.loginData.keyField,
  paneFullScreen: state.KPVCharts.paneFullScreen,
  alaramOfMachineId: state.alarams.id,
  showCommentsModal: state.alarams.showCommentsModal
});

export default connect(mapStateToProps)(Dashboard);
// export default Dashboard;
