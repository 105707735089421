/* eslint-disable max-len */
import { chartConfig } from '../Chart/chartConfig';

const { multipleAxesSpacing, labelFontSize, tickLength } = chartConfig;
export const getSpaceOccupiedByMultipleVerticalAxes = (meta) => {
  let padding = 0;
  const metaKeys = Object.keys(meta);
  const metaLen = metaKeys?.length;
  metaKeys?.forEach((axis, key) => {
    // non empty, not last axis
    if (meta[axis].isDataPresent && key !== metaLen - 1) {
      padding += 2 * multipleAxesSpacing + tickLength + labelFontSize;
    } else if (meta[axis].isDataPresent && key === metaLen - 1) {
      padding += multipleAxesSpacing + tickLength + labelFontSize;
    } else if (!meta[axis].isDataPresent && key !== metaLen - 1) {
      padding += multipleAxesSpacing + tickLength;
    } else {
      padding += tickLength;
    }
  });
  return padding;
};

export const getPaddingForAlignment = (meta, maxAxesWidth, multiAxis, multiGrid) => {
  const metaKeys = Object.keys(meta);
  if (maxAxesWidth) {
    // multiple axis in single grid.
    if (multiAxis && !multiGrid) {
      return maxAxesWidth - getSpaceOccupiedByMultipleVerticalAxes(meta);
    }
    if (metaKeys.some((axis) => meta[axis].isDataPresent)) {
      return maxAxesWidth - (tickLength + multipleAxesSpacing + labelFontSize);
    }
    return maxAxesWidth - tickLength;
  }
  if (!metaKeys.some((axis) => meta[axis].isDataPresent)) {
    return labelFontSize + multipleAxesSpacing;
  }
  return 0;
};

export const getSpaceOccupiedByVerticalAxes = (meta, multiAxis, multiGrid) => {
  const metaKeys = Object.keys(meta);

  // multiple axis in single grid.
  if (multiAxis && !multiGrid) {
    return getSpaceOccupiedByMultipleVerticalAxes(meta);
  }
  // single axis(multigrid. singlepane)
  if (metaKeys.some((axis) => meta[axis].isDataPresent)) {
    return tickLength + labelFontSize;
  }
  // no data present for all
  return tickLength;
};
