export const ColorsAHU = [{
  class_0: '#3D3F50',
  class_1: '#1BAF0A',
  class_2: '#195C85',
  class_3: '#138F06',
  class_4: '#C1F6A2',
  class_5: '#35CC25',
  class_6: '#F4F4E6',
  class_7: '#14AB03',
  class_8: '#1AFF00',
  class_9: '#143441',
  class_10: '#2E81AA',
  class_11: '#CCF5B5',
  class_12: '#525360',
  class_13: '#7E7F82'
}, {
  class_0: '#3D3F50',
  class_1: '#AC9B00',
  class_2: '#195C85',
  class_3: '#F7FF00',
  class_4: '#E1CB03',
  class_5: '##E1CB03',
  class_6: '#F4F4E6',
  class_7: '#AC9B00',
  class_8: '#AC9B00',
  class_9: '#143441',
  class_10: '#2E81AA',
  class_11: '#F7FF00',
  class_12: '#525360',
  class_13: '#7E7F82'
}, {
  class_0: '#3D3F50',
  class_1: '#BF0000',
  class_2: '#195C85',
  class_3: '#F5B5B5',
  class_4: '#FF0000',
  class_5: '##FF0000',
  class_6: '#F4F4E6',
  class_7: '#BF0000',
  class_8: '#BF0000',
  class_9: '#143441',
  class_10: '#2E81AA',
  class_11: '#F5B5B5',
  class_12: '#525360',
  class_13: '#7E7F82'
}
];

export const ColorsCompressor = [{
  class_0: '#1baf0a',
  class_2: '#1aff00',
  class_3: '#c1f6a2',
  class_4: '#14ab03',
  background: '#3d3f50'
}, {
  class_0: '#AC9B00',
  class_2: '#E1CB03',
  class_3: '#F7FF00',
  class_4: '#AC9B00',
  background: '#3d3f50'
}, {
  class_0: '#bf0000',
  class_2: '#ff3535',
  class_3: '#F5B5B5',
  class_4: '#bf0000',
  background: '#3d3f50'
}];

export const colorsExhaust = [{
  class_0: '#1BAF0A',
  class_1: '#138F06',
  class_2: '#C1F6A2',
  class_3: '#6FBC6F',
  class_4: '#F4F4E6',
  class_5: '#525360',
  class_6: '#3D3F50',
  class_7: '#7E7F82'
}, {
  class_0: '#E1CB03',
  class_1: '#AC9B00',
  class_2: '#F7FF00',
  class_3: '#E1CB03',
  class_4: '#F4F4E6',
  class_5: '#525360',
  class_6: '#3D3F50',
  class_7: '#7E7F82'
}, {
  class_0: '#ff3535',
  class_1: '#bf0000',
  class_2: '#F5B5B5',
  class_3: '#ff3535',
  class_4: '#F4F4E6',
  class_5: '#525360',
  class_6: '#3D3F50',
  class_7: '#7E7F82'
}];

export const colorsFan = [{
  class_0: '#3D3F50',
  class_1: '#1BAF0A',
  class_2: '#1AFF00'
}, {
  class_0: '#3D3F50',
  class_1: '#AC9B00',
  class_2: '#E1CB03'
}, {
  class_0: '#3D3F50',
  class_1: '#bf0000',
  class_2: '#ff3535'
}];

export const colorsChillar = [{
  class_1: '#143441',
  class_2: '#14ab03',
  class_3: '#3d3f50',
  class_4: '#1baf0a',
  class_5: '#2e81aa',
  class_6: '#c1f6a2'
}, {
  class_1: '#143441',
  class_2: '#AC9B00',
  class_3: '#3d3f50',
  class_4: '#E1CB03',
  class_5: '#2e81aa',
  class_6: '#F7FF00'
}, {
  class_1: '#143441',
  class_2: '#bf0000',
  class_3: '#3d3f50',
  class_4: '#ff3535',
  class_5: '#2e81aa',
  class_6: '#F5B5B5'
}];

export const colorsTank = [{
  class_0: '#138F06',
  class_1: '#3d3f50',
  class_2: '#fff',
  class_3: '#C1F6A2',
  class_4: '#1BAF0A'
}, {
  class_0: '#AC9B00',
  class_1: '#3d3f50',
  class_2: '#fff',
  class_3: '#F7FF00',
  class_4: '#E1CB03'
}, {
  class_0: '#bf0000',
  class_1: '#3d3f50',
  class_2: '#fff',
  class_3: '#F5B5B5',
  class_4: '#ff3535'
}];
