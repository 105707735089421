/* eslint-disable function-paren-newline */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BtnWrapper,
  Button,
  Heading
} from '../../Common/PopUpModal/Modal.style';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import { Creators as LayoutCreator } from '../../../views/Layout/store';
import {
  ChartOptContainer,
  ChartTypeText,
  DropDownButton,
  DropDownContainer,
  DropdownImage,
  ItemText,
  PaneButton,
  PaneOptContainer
} from './PlotOptionsModal.style';
import { DropDownArrow } from '../../../assets/icons';
import { Creators as kpvChartingCreators } from '../../KPVCharts/Store/action';
// import { Creators as UpsertPaneCreators } from '../../UpsertPane/Store';
// import { plotData } from '../../../constants/sampleData';
import {
  // chartCategories,
  chartCategoryMapping,
  chartLableMapping,
  CHARTS_WITH_NO_SINGLE_PANE_SUPPORT,
  CHART_CATEGORIES,
  // chartOptions,
  CHART_TYPES,
  CHART_TYPE_CATEGORY,
  PANE_TYPES,
  resolveChartCategory
} from '../../../constants';
import Loader from '../../Loader/Loader';
import { generateRandomColor } from '../../../shared/utility/generateRandomColor';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { getFormatedgraphTS } from '../../../shared/utility/moment';
import { getPlotDataPayload } from '../../KPVCharts/utils/getPlotDataPayload';
import useGetMachinesShortlisted from '../../../shared/hooks/useGetMachinesShortlisted';
import { useActions } from '../../../store/hooks/useActions';
// import DropDownModal from '../../Common/DropDownModal/DropDownModal_V1';

// TODO
const PlotOptionsModal = (props) => {
  const [dropDownPlotOptions, setDropDownPlotOptions] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowPlotOptModal } = props;

  // const { KPVShortlist } = useSelector((state) => state.filterResultData);
  const activeTab = useSelector((state) => state.filterResultData.activeTab);
  const kpvData = useSelector((state) => state.filterResultData.resultDetails);
  const enabledPanes = useSelector(
    (state) => state.filterResultData.enabledPanes
  );
  const activeMenuId = useSelector((state) => state.home.activeMenuId);
  const KPVShortlist =
    useSelector(
      (state) => state.filterResultData.KPVShortlist?.[activeMenuId]
    ) || [];
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const upsertPaneData = useSelector((state) => state.upsertPaneData);
  const { xMinDomain, xMaxDomain } = useSelector((state) => state.KPVCharts);
  const { singlePane, paneData, machinesShortlisted, plotData, colorCodes } =
    useSelector((state) => state.KPVCharts);
  const chartOptions = useSelector((state) => state.KPVCharts.chartOptions);
  const [selectedChart, setSelectedChart] = useState([]);
  const [showChartTypeDropdown, setShowChartTypeDropdown] = useState(false);
  const [updatePanesData, setUpdatePanesData] = useState(false);
  const [plotLoader, setPlotLoader] = useState(false);
  const location = useLocation();
  const { setChartType } = useActions();
  const chartTypeCategory =
    chartOptions?.[selectedChart?.value]?.chartTypeCategory;
  const disableSinglePane =
    CHARTS_WITH_NO_SINGLE_PANE_SUPPORT.includes(chartTypeCategory);
  const paneOptions = [
    { label: PANE_TYPES.SINGLE_PANE },
    { label: PANE_TYPES.DIFFERENT_PANE }
  ];
  const chartsWitchRequireAdditionalDetails = [
    CHART_TYPE_CATEGORY.LABEL,
    CHART_TYPE_CATEGORY.XY_CHART
  ];

  // selecting only the feilds req for kpv plot
  useGetMachinesShortlisted(false);
  // code before hook
  // const setMachineShortlisted = () => {
  //   const machines = KPVShortlist.map((e) => ({
  //     kpvId: e.id,
  //     kpvName: e.KPV,
  //     groupName: e.groupName,
  //     machineId: e.machineId,
  //     machineName: e.machineName,
  //     macId: e.macId
  //   }));
  //   dispatch(kpvChartingCreators.setMachinesShortlisted(machines));
  // };

  // useEffect(() => {
  //   setMachineShortlisted();
  // }, []);

  useEffect(() => {
    // temporary fix to view label chart in chart options
    // const plotMenuFilter = [
    //   ...enabledPanes.filter((e) =>
    //     KPVShortlist.some(
    //       (innerE) => innerE[e.key] === 'true' // machinesShortlisted
    //     )
    //   ),
    // {
    //   key: 'LotChart',
    //   name: 'Label-Lot Chart',
    //   type: CHART_TYPE_CATEGORY.LABEL
    // },
    // {
    //   key: 'XYChart',
    //   name: 'XYChart',
    //   type: CHART_TYPE_CATEGORY.XY_CHART
    // }
    // ];
    const plotMenuFilter = [
      ...enabledPanes.filter((e) =>
        // KPVShortlist
        // check entire list of kpvs in the active tab
        [...KPVShortlist].some(
          (innerE) => innerE[e.key] === 'true' // machinesShortlisted
        )
      )
      // {
      //   key: 'LotChart',
      //   name: 'Label-Lot Chart',
      //   type: CHART_TYPE_CATEGORY.LABEL
      // },
      // {
      //   key: 'XYChart',
      //   name: 'XYChart',
      //   type: CHART_TYPE_CATEGORY.XY_CHART
      // }
    ];
    setDropDownPlotOptions(plotMenuFilter);
    const derivedChartOptions = plotMenuFilter.reduce(
      (prev, e) => ({
        ...prev,
        [chartLableMapping[e.key] || e.key]: {
          ...e,
          key: chartLableMapping[e.key] || e.key,
          value: chartLableMapping[e.key] || e.key, // undefined handling
          chartTypeCategory: chartCategoryMapping[e.type] || e.type, // exteranl internal mapping
          label: e.name
        }
      }),
      {}
    );
    // console.log('plotMenuFilter', enabledPanes, plotMenuFilter, derivedChartOptions);
    dispatch(kpvChartingCreators.setChartTypes(derivedChartOptions));
    // set first chart option as default
    setSelectedChart(Object.values(derivedChartOptions)[0] || {});
  }, []);

  const generateExtraColorCodes = () => {
    // generate extra color codes
    const generatedColorCodes = [];
    let i = 0;
    while (i < machinesShortlisted.length - colorCodes.length) {
      generatedColorCodes.push(generateRandomColor());
      i += 1;
    }
    if (generatedColorCodes.length > 0) {
      dispatch(kpvChartingCreators.setColorCodes(generatedColorCodes));
    }
  };
  const handleCharts = () => {
    setPlotLoader(true);
    dispatch(LayoutCreator.setShowSideNav({ showSideNav: false }));
    dispatch(kpvChartingCreators.resetPanesData());
    generateExtraColorCodes();

    // changed to array of single object as per the new format
    const kpvSelected = machinesShortlisted.filter(
      (e) => e[selectedChart.value] === 'true'
    );
    // make default as latestAvailable option
    dispatch(kpvChartingCreators.setDomainRange({ latestAvailable: 30 }));
    if (chartsWitchRequireAdditionalDetails.includes(chartTypeCategory)) {
      // different flow => move to upserpane
      setChartType({
        chartType: selectedChart.value,
        chartTypeCategory
      });
      history.replace({
        pathname: '/home/kpv-charting/add-pane',
        state: {
          previousPath: location?.state?.previousPath || location.pathname
        }
      });
    } else {
      const machinesWithNoPlotData = getPlotDataPayload(
        [
          {
            kpv: kpvSelected,
            category: resolveChartCategory[chartTypeCategory],
            chartType: selectedChart.value,
            chartTypeCategory,
            singlePane
          }
        ],
        xMinDomain,
        xMaxDomain
      );
      // make default as latestAvailable option
      dispatch(
        kpvChartingCreators.getPlotData(
          machinesWithNoPlotData,
          null,
          null,
          null,
          { latestAvailable: 30 }
        )
      );
      // old
      // dispatch(kpvChartingCreators.getPlotData(machinesWithNoPlotData));

      history.push({
        pathname: '/home/kpv-charting',
        state: {
          previousPath: location?.state?.previousPath || location.pathname
        }
      });
    }
    setUpdatePanesData(true);
  };

  const closeModal = () => {
    setShowPlotOptModal(false);
  };

  useEffect(() => {
    if (disableSinglePane) {
      dispatch(kpvChartingCreators.setPaneType(false));
    }
  }, [selectedChart]);

  const handlePane = (label) => {
    if (label === PANE_TYPES.SINGLE_PANE && !singlePane) {
      dispatch(kpvChartingCreators.setPaneType(true));
    } else if (label === PANE_TYPES.DIFFERENT_PANE && singlePane) {
      dispatch(kpvChartingCreators.setPaneType(false));
    }
  };

  const handleChartSelection = (opt) => {
    const internalChartType = chartLableMapping[opt.key] || opt.key;
    const chartDetails = chartOptions[internalChartType];
    // console.log('clicked', internalChartType, opt, chartOptions, chartDetails);
    setSelectedChart(chartDetails);
  };

  return (
    <PopUpModal
      style={{ position: 'relative' }}
      closeModalHandler={setShowPlotOptModal}
    >
      {plotLoader && (
        <PopUpModal
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%'
          }}
          closeModalHandler={setPlotLoader}
          preventClose={true}
        >
          <Loader />
        </PopUpModal>
      )}
      <Heading>{localeStrings.plotOptions || 'Plot Options'}</Heading>
      {/* pane grid options */}
      {!chartsWitchRequireAdditionalDetails.includes(chartTypeCategory) && (
        <PaneOptContainer>
          {paneOptions.map((opt, index) => (
            <PaneButton
              key={index}
              className={`${
                opt.label ===
                (singlePane
                  ? PANE_TYPES.SINGLE_PANE
                  : PANE_TYPES.DIFFERENT_PANE)
                  ? 'active'
                  : `${disableSinglePane ? 'disable' : ''}`
              }  pointer`}
              onClick={() => handlePane(opt.label)}
            >
              {opt.label}
            </PaneButton>
          ))}
        </PaneOptContainer>
      )}

      <ChartOptContainer
        onClick={() => {
          setShowChartTypeDropdown(!showChartTypeDropdown);
        }}
      >
        <ChartTypeText className={showChartTypeDropdown ? 'active' : ''}>
          {selectedChart.label}
        </ChartTypeText>
        <DropDownButton className='pointer'>
          <DropdownImage className={showChartTypeDropdown ? 'active' : ''}>
            <DynamicSVGIcon iconUrl={DropDownArrow} width='2.4rem' />
          </DropdownImage>
        </DropDownButton>
        {showChartTypeDropdown && (
          // <DropDownContainer>
          //   {chartOptions.map((opt, index) => (
          //     <ItemText key={index} onClick={() => setSelectedChart(opt)}>
          //       {opt.label}
          //     </ItemText>
          //   ))}
          // </DropDownContainer>
          <DropDownContainer>
            {dropDownPlotOptions?.map((opt, index) => (
              <ItemText key={index} onClick={() => handleChartSelection(opt)}>
                {opt.name}
              </ItemText>
            ))}
          </DropDownContainer>
          // TODO: migrate to dropdown component
          // <DropDownModal
          //   setModalIsVisibal={setShowChartTypeDropdown}
          //   handleClick={setSelectedChart}
          //   data={chartOptions}
          // />
        )}
      </ChartOptContainer>
      <BtnWrapper>
        <Button type='button' onClick={closeModal} className='pointer'>
          {localeStrings.cancel || 'Cancel'}
        </Button>
        <Button type='submit' className='dark pointer' onClick={handleCharts}>
          {localeStrings.select || 'Select'}
        </Button>
      </BtnWrapper>
    </PopUpModal>
  );
};
export default PlotOptionsModal;
