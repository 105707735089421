/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { PAGE_TYPES } from '../../constants';
// import Add from '../../assets/icons/Add.svg';
// import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
// import { ContastIconColor } from '../../component/Common/CommonStyles';
// import { Span } from '../../globalStyles';
import { useActions } from '../../store/hooks/useActions';
import { WidgetContainer } from './Widget.style';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { isEmpty } from '../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import WidgetDashBoardPopUp from '../../component/Widget/DashBoards/WidgetDashboardPopUp';
// import { WebService } from '../../services/axios/webServices';
// import { apiEndPoints } from '../../services/axios/endPoints';

const ViewWidgetDashboardPopUp = () => {
  const location = useLocation();
  const { isTemplateInstance } = location.state || {};
  const widgetTypesIncluded = useSelector((state) => state.widgets.widgetTypesIncluded);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const { getWidgetTypes } = useActions();

  const {
    templateInstanceName,
    templateInstanceFormInput,
    WTId,
    templateFormInput,
    templateName,
    templateCreatorName,
    instanceCreatorName
  } = useSelector((state) => state.upsertWidgetData);

  const { id: WTIId } = useParams();

  useEffect(() => {
    if (!WTIId) {
      window.close();
    }
    if (isEmpty(widgetTypesMenu)) {
      getWidgetTypes();
    }
  }, []);

  const templateParamterDetails = {
    WidgetComponentData: templateInstanceFormInput,
    TemplateParameters: {
      widgetTypesIncluded,
      formInput: templateFormInput
    },
    WTIId
  };

  return (
    <WidgetContainer style={{ padding: '0rem 2rem' }}>
      <WidgetDashBoardPopUp instancePreview={true} WTIId={WTIId} templateParamterDetails={templateParamterDetails} isCustom={false} />
    </WidgetContainer>
  );
};

export default ViewWidgetDashboardPopUp;
