/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { localString } from '../../localization/localString';
import IconComponent from '../Icon/Icons';
import { Creators } from '../../views/Layout/store';
// import { navs } from './routes';

import {
  Container,
  Footer,
  NavItem,
  Scroll,
  Title,
  Toggle,
  Icon,
  Image,
  MultiLevelNavHeaderContainer,
  MultiLevelNavHeader,
  BreadCrumb
} from './Sidenav.styles';
import DropArrow from '../../assets/icons/layout/dropdownarrow.svg';
import RenderSubNavs from './RenderSubNavs';
import RenderMultiLevelNav from './RenderMultiLevelNav';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import Hamburger from '../../assets/icons/layout/hamburger.svg';
import {
  activeiconsMap,
  iconsMap
} from '../../assets/icons/menuicons/iconsMap';
import { fetchUserAndLocation } from './FetchStatics';
import { useActions } from '../../store/hooks/useActions';
import { configMenu } from '../../views/Layout/menus';

const SideNav = ({ view, sideNavRef }) => {
  const language = useSelector((state) => state.configData.language);
  // const { menu, showSideNav,  activeMultiLevelNav } = useSelector((state) => state.home);
  const menu = useSelector((state) => state.home.menu);
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const activeMultiLevelNav = useSelector(
    (state) => state.home.activeMultiLevelNav
  );
  const isEditOn = useSelector((state) => state.widgets.isEditOn);

  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const userData = useSelector((state) => state.loginData.userData);
  const { setShowSideNav, setDisableEditWidget } = useActions();
  const string = localString[language];

  const dispatch = useDispatch();

  const handleToggle = () => {
    if (Object.values(isEditOn).includes(true)) {
      dispatch(Creators.setShowSideNav({ showSideNav: false }));
    } else {
      dispatch(Creators.setShowSideNav({ showSideNav: !showSideNav }));
    }
  };

  const deleteLastItem = () => {
    if (activeMultiLevelNav.length < 2) {
      dispatch(Creators.setActiveMultiLevelMenu([]));
    } else {
      const newArray = [...activeMultiLevelNav];
      newArray.pop();
      dispatch(Creators.setActiveMultiLevelMenu(newArray));
    }
  };

  const handleClick = (nav) => {
    // Disabling Edit mode of any widget panel if it was enabled
      setDisableEditWidget();

    if (!showSideNav) {
      setShowSideNav({ showSideNav: true });
    }
    dispatch(Creators.setActiveMenu(nav));
    fetchUserAndLocation(nav.Permalink, userData, { pageType: nav.PageType });
  };

  return (
    <Container
      ref={sideNavRef}
      active={showSideNav}
      className={`icon ${showSideNav ? '' : 'hidden'}`}
    >
      {/* toggle button */}
      <Toggle onClick={handleToggle}>
        {/* <IconComponent name='menu' cursor='pointer' ml={2} /> */}
        <DynamicSVGIcon iconUrl={Hamburger} width='1.5rem' />
      </Toggle>
      {/* menu container */}
      <Scroll className={`${showSideNav ? '' : 'hidden'}`}>
        {activeMultiLevelNav &&
        activeMultiLevelNav.length > 0 &&
        showSideNav ? (
          <>
            <MultiLevelNavHeaderContainer onClick={deleteLastItem}>
              <Image>
                <DynamicSVGIcon iconUrl={DropArrow} width='1.4rem' />
              </Image>
              <div>
                <MultiLevelNavHeader>
                  {localeStrings[
                    activeMultiLevelNav[activeMultiLevelNav.length - 1].parent
                      .name
                  ] ||
                    activeMultiLevelNav[activeMultiLevelNav.length - 1].parent
                      .name}
                </MultiLevelNavHeader>
                <BreadCrumb>
                  {
                    activeMultiLevelNav[activeMultiLevelNav.length - 1].parent
                      .Permalink
                  }
                </BreadCrumb>
              </div>
            </MultiLevelNavHeaderContainer>
            {activeMultiLevelNav[activeMultiLevelNav.length - 1].subMenu.map(
              (nav, i) => (
                <RenderMultiLevelNav key={i} {...nav} active={showSideNav} />
              )
            )}
          </>
        ) : (
          menu.map((nav, index) => {
            if (nav.IsLeaf === '0') {
              return (
                <RenderSubNavs
                  parent={nav.Permalink}
                  key={index}
                  {...nav}
                  active={showSideNav}
                  activeIcon={
                    activeiconsMap[nav.Name] || activeiconsMap?.default
                  }
                />
              );
            }

            return (
              <div onClick={() => handleClick(nav)}>
                <NavItem key={index} exact={true} to={nav.Permalink}>
                  {/* <Icon className='icon' icon={nav.icon} /> */}
                  <Icon className='icon iconNormal'>
                    <DynamicSVGIcon
                      iconUrl={iconsMap[nav.Name] || iconsMap.default}
                      width='2rem'
                    />
                  </Icon>

                  <Icon className='icon iconActive'>
                    <DynamicSVGIcon
                      iconUrl={
                        activeiconsMap[nav.Name] || activeiconsMap?.default
                      }
                      width='2rem'
                    />
                  </Icon>

                  {/* {url(nav.icon)} */}
                  {/* <object type='image/svg+xml' data={nav.icon} /> */}
                  {showSideNav && (localeStrings[nav.Name] || nav.Name)}
                </NavItem>
              </div>
            );
          })
        )}
      </Scroll>

      {/* logo container */}
      {view === 'web' ? (
        <Footer>
          <IconComponent name='vistrian' mr={1.5} />
          {showSideNav && (localeStrings[0]?.brandingTitle)}
        </Footer>
      ) : (
        <Footer>
          <IconComponent name='settingsBlack' ml={2} mr={1.5} />
          <Title>{showSideNav && (localeStrings[0]?.brandingTitle)}</Title>
        </Footer>
      )}
    </Container>
  );
};

export default SideNav;
