/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Div, FlexContainer, Span } from '../../../globalStyles';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { Add } from '../../../assets/icons';
import minus from '../../../assets/icons/Filter/minus.svg';
import { Container } from './TemplateFormComp.styles';
import ToggleType from '../../Common/InputTypes/ToggleType/ToggleType';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { isEmpty } from '../../../shared/utility/isEmpty';

const TemplateFormComp = (props, ref) => {
  const { data, formInput: globalFormInput, templateFormStructureFieldDepenents, setIsFormFilled, setShowFilterSelection } = props;
  const [activeContainer, setActiveContainer] = useState('');
  const handleContainer = (field) => {
    if (activeContainer === field) {
      setActiveContainer('');
    } else {
      setActiveContainer(field);
    }
  };
  const [formInput, setFormInput] = useState([]);
  // console.log('formInput', formInput);
  useEffect(() => {
    // populate values in fields
    if (globalFormInput) {
      setFormInput(globalFormInput);
    }
  }, [globalFormInput]);

  useImperativeHandle(
    ref,
    () => ({
      getFormInput() {
        return formInput;
      }
    }),
    [formInput]
  );

  useEffect(() => {
    if (!isEmpty(formInput)) {
      const res = Object.entries(formInput).some((e) => {
        const [key, value] = e;
        return value;
      });
      setIsFormFilled(res);
    } else {
      setIsFormFilled(false);
    }
  }, [formInput, data]);

  const handleToggle = ({ key }, dependencies) => {
    if (key === 'tree') {
      setShowFilterSelection(!formInput[key]);
    }
    const res = !formInput[key];
    setFormInput({
      ...formInput,
      [key]: res,
      ...(dependencies?.reduce(
        (acc, e) => ({
          ...acc,
          [e]: res
        }),
        {}
      ) || {})
    });
  };

  return (
    <Container ref={ref}>
      {data &&
        Object.entries(data).map((e) => {
          const [key, fields] = e;
          const isActive = key === activeContainer;
          const depenents = templateFormStructureFieldDepenents[key];
          return (
            <div>
              <FlexContainer
                style={{ width: '100%', padding: '1.5rem' }}
                onClick={() => handleContainer(key)}
                className='jc_spacebetween ai_center'
              >
                <Span style={{ textTransform: 'capitalize' }}>{key === 'common' ? 'Common Parameters' : key}</Span>
                <div style={{ padding: '1rem' }}>
                  <DynamicSVGIcon iconUrl={isActive ? minus : Add} width='2.2rem' />
                </div>
              </FlexContainer>
              {isActive && (
                <div style={{ width: '90%', marginLeft: '2rem' }}>
                  {fields.length > 0 ? (
                    fields.map((field) => {
                      const {
                        key: fieldKey,
                        // dataSource: { dependencies }
                      } = field;

                      let dependencies;

                      if (field?.dataSource) {
                        dependencies = field?.dataSource?.dependencies;
                      } else {
                        dependencies = [];
                      }

                      const isDependent = dependencies?.some((e) => formInput[e]);
                      return (
                        <>
                          <KeyInputLayoutView
                            className={`${dependencies?.length ? 'disable' : ''} align-labels`}
                            fieldClassName='none'
                            label={field.label}
                          >
                            <ToggleType
                              handleToggle={() => handleToggle(field, depenents[field.key])}
                              isActive={formInput[fieldKey]}
                              itemKey={fieldKey}
                            />
                          </KeyInputLayoutView>
                        </>
                      );
                    })
                  ) : (
                    <Div mb={2}>
                      <Span>No Fields</Span>
                    </Div>
                  )}
                </div>
              )}
              <hr className='hr' />
            </div>
          );
        })}
    </Container>
  );
};

export default forwardRef(TemplateFormComp);
