/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  ChartType,
  Container,
  Icon,
  Item,
  ItemContainer,
  ItemSubMenu,
  LabelWrapper,
  MenuDropdown,
  SideArrow
} from './EditOptions.style';
import { ReactComponent as Hamburger } from '../../../assets/icons/layout/hamburger_white.svg';
import {
  CHART_TYPES,
  CHART_TYPE_CATEGORY,
  PAGE_TYPES,
  WIDGET_CATEGORIES,
  WIDGET_TERMINOLOGY,
  WIDGET_TYPES
} from '../../../constants';
import { DropDownArrow } from '../../../assets/icons';
import { Creators as UpsertPaneCreators } from '../../UpsertPane/Store';
import { Creators as KPVChartsCreators } from '../Store';
import Loader from '../../Loader/Loader';
import EditCustomiseKpv from './EditCustomiseKpv/EditCustomiseKpv.view';
import { Radio, RadioIcon } from '../../UpsertPane/ScaleYAxis/ScaleYAxis.style';
import EditScaleYAxis from './EditScaleYAxis/EditScaleYAxis.view';
import { CheckBox } from '../../UpsertPane/UpsertPane.style';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DeletePaneModal from './DeletePaneModal/DeletePaneModal.view';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getOverlayDataPoints } from '../utils/getOverlayDataPoints';
import ShowOverlays from '../../UpsertPane/ShowOverlays/ShowOverlays';
import ShowAlarmsView from '../../UpsertPane/ShowAlarms/ShowAlarms.view';
// import { WidgetCreators } from '../../../views/Widgets/store';
import { getWidgetDetailsPayload } from '../../Widget/utils/getWidgetDetailsPayload';
import AddTab from '../../Widget/Header/TabForm/AddTab';
import { useActions } from '../../../store/hooks/useActions';
// import { editPaneMenu } from './sampleData';
import { show_widgets } from '../../../config.json';
import { Span } from '../../../globalStyles';
import { resolveSetWidgetTypePayload } from '../../Widget/utils';

const SubMenu = ({
  subMenu,
  paneData,
  parent,
  showMenu,
  setShowMenu,
  showScaleYAxis,
  setShowScaleYAxis
}) => {
  const dispatch = useDispatch();
  const [showSubMenuOf, setShowSubMenuOf] = useState('');
  // const { scaleYaxis } = useSelector((state) => state.upsertPaneData);
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );

  const handleSelect = (ele) => {
    setShowSubMenuOf('');

    if (ele.subMenu) {
      setShowSubMenuOf(ele.id);
    } else {
      switch (parent) {
        case 'scaleYAxis': {
          switch (ele.value) {
            case 'manual': {
              dispatch(
                UpsertPaneCreators.setCompleteUpsertPaneData({
                  ...paneData,
                  scaleYaxis: ele.value
                })
              );
              setShowScaleYAxis(!showScaleYAxis);
              break;
            }
            case 'auto':
            case 'processWindow': {
              dispatch(
                KPVChartsCreators.editPaneData({
                  index: paneData.paneNo,
                  key: 'scaleYaxis',
                  value: ele.value
                })
              );
              dispatch(
                KPVChartsCreators.editPaneData({
                  index: paneData.paneNo,
                  key: 'scaleYAxisRange',
                  value: []
                })
              );
              break;
            }

            default:
              break;
          }

          break;
        }

        case 'showDetails': {
          dispatch(
            KPVChartsCreators.editPaneData({
              index: paneData.paneNo,
              key: 'showDetails',
              value: {
                ...paneData.showDetails,
                [ele.value]: !paneData.showDetails[ele.value]
              }
            })
          );
          break;
        }
        case 'annotations': {
          // true: has to enable showAnnotations as well(from false)
          if (
            ele.value === 'isAddAnnotationEnabled' &&
            !paneData.showDetails[ele.value]
          ) {
            dispatch(
              KPVChartsCreators.editPaneData({
                index: paneData.paneNo,
                key: 'showDetails',
                value: {
                  ...paneData.showDetails,
                  isAddAnnotationEnabled: true,
                  annotations: true
                }
              })
            );
          } else {
            dispatch(
              KPVChartsCreators.editPaneData({
                index: paneData.paneNo,
                key: 'showDetails',
                value: {
                  ...paneData.showDetails,
                  [ele.value]: !paneData.showDetails[ele.value]
                }
              })
            );
          }
          break;
        }
        case 'showOverlays': {
          const {
            chartType,
            rawPlotData: { data: orgData, meta },
            showOverlays,
            chartTypeCategory
          } = paneData;
          if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
            const { key, value } = ele;
            let data;
            if (paneData.showOverlays?.[key]) {
              data = {
                ...paneData.showOverlays
              };
              delete data[key];
            } else {
              data = {
                ...paneData.showOverlays,
                [key]: value
              };
            }
            dispatch(
              KPVChartsCreators.editPaneData({
                index: paneData.paneNo,
                key: 'showOverlays',
                value: data
              })
            );
            // moved this to spcChart.view
            // const { data: overlayData, overlayDomain } = getOverlayDataPoints(data, meta, false, {
            //   orgDataLength: orgData.length
            // });
            // dispatch(
            //   KPVChartsCreators.editPaneData({
            //     index: paneData.paneNo,
            //     key: 'overlaysData',
            //     value: overlayData
            //   })
            // );
            // dispatch(
            //   KPVChartsCreators.editPaneData({
            //     index: paneData.paneNo,
            //     key: 'overlayDomain',
            //     value: overlayDomain
            //   })
            // );
            break;
          }
          dispatch(
            KPVChartsCreators.editPaneData({
              index: paneData.paneNo,
              key: 'showOverlays',
              value: {
                ...paneData.showOverlays,
                limits: paneData?.showOverlays?.limits === false
              }
            })
          );
          break;
        }
        case 'showAlarms': {
          const {
            chartType,
            chartTypeCategory,
            rawPlotData: { meta, data: orgData },
            showAlarms
          } = paneData;
          if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
            const { key, value } = ele;
            let data = {};
            if (showAlarms?.[key]) {
              data = {
                ...showAlarms
              };
              delete data[key];
            } else {
              data = {
                ...showAlarms,
                [key]: value
              };
            }
            dispatch(
              KPVChartsCreators.editPaneData({
                index: paneData.paneNo,
                key: 'showAlarms',
                value: data
              })
            );
            // moved to spcGraphWrapper
            // const { data: overlayData } = getOverlayDataPoints(data, meta, true, {
            //   orgDataLength: orgData?.length
            // });
            // dispatch(
            //   KPVChartsCreators.editPaneData({
            //     index: paneData.paneNo,
            //     key: 'alarmsData',
            //     value: overlayData
            //   })
            // );
            break;
          }
          break;
        }
        default:
          break;
      }
    }
    if (
      !ele.subMenu &&
      parent !== 'showDetails' &&
      parent !== 'annotations' &&
      parent !== 'showOverlays' &&
      parent !== 'showAlarms'
    ) {
      setShowMenu(!showMenu);
    }
  };
  const isLimitsEnabled = useMemo(() => {
    const { meta } = paneData.rawPlotData || {};
    const KPVs = !isEmpty(meta) && Object.keys(meta);
    if (KPVs.length > 0) {
      let isEnabled = meta[KPVs[0]]?.fields.Limit?.length > 0;
      if (KPVs.length > 1 && !(paneData.multiAxis && paneData.multiGrid)) {
        isEnabled = false;
      }
      return isEnabled;
    }
    return false;
  }, [paneData]);
  if (parent === 'showOverlays') {
    return (
      <ItemSubMenu>
        <ShowOverlays
          meta={paneData.rawPlotData.meta}
          showOverlays={paneData.showOverlays}
          chartTypeCategory={paneData.chartTypeCategory}
          handleSelect={handleSelect}
          isLimitsEnabled={isLimitsEnabled}
        />
      </ItemSubMenu>
    );
  }

  if (parent === 'showAlarms') {
    return (
      <ItemSubMenu>
        <ShowAlarmsView
          meta={paneData.rawPlotData.meta}
          showAlarms={paneData.showAlarms}
          showOverlays={paneData.showOverlays}
          chartTypeCategory={paneData.chartTypeCategory}
          handleSelect={handleSelect}
          isLimitsEnabled={isLimitsEnabled}
        />
      </ItemSubMenu>
    );
  }

  return (
    <ItemSubMenu>
      {subMenu.map((ele) => (
        <ItemContainer key={ele.id}>
          {/* disable item show annotations when add annotations is enabled */}
          <Item
            className={`subItem ${
              ele.value === 'annotations' &&
              paneData.showDetails?.isAddAnnotationEnabled
                ? 'disable'
                : ''
            }`}
            onClick={() => handleSelect(ele)}
          >
            {/* {parent === 'scaleYAxis' && <Radio>{scaleYaxis === ele.value && <RadioIcon />}</Radio>} */}
            <LabelWrapper>
              {parent === 'scaleYAxis' && (
                <Radio>
                  {paneData.scaleYaxis === ele.value && <RadioIcon />}
                </Radio>
              )}
              {parent === 'annotations' && (
                <CheckBox
                  className={paneData.showDetails[ele.value] ? 'active' : ''}
                >
                  <CheckMark />
                </CheckBox>
              )}
              {parent === 'showDetails' && (
                <CheckBox
                  className={paneData.showDetails[ele.value] ? 'active' : ''}
                >
                  <CheckMark />
                </CheckBox>
              )}
              <span>{localeStrings[ele.label] || ele.label}</span>
            </LabelWrapper>
            {(ele.subMenu || ele.value === 'manual') && (
              <SideArrow src={DropDownArrow} alt='' />
            )}
          </Item>
          {showSubMenuOf === ele.id && <SubMenu subMenu={ele.subMenu} />}
        </ItemContainer>
      ))}
    </ItemSubMenu>
  );
};

const EditOptionsContainer = ({ paneData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenuOf, setShowSubMenuOf] = useState('');
  const {
    kpv,
    kpvDetails,
    stateDetails,
    spcDetails,
    machinesSelected: upsertMachineSelected
  } = paneData;
  const machinesSelected =
    kpv || kpvDetails || stateDetails || spcDetails || upsertMachineSelected;
  const [showCustomiseKpv, setShowCustomiseKpv] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const scaleYaxis = useSelector((state) => state.upsertPaneData.scaleYaxis);
  const [showScaleYAxis, setShowScaleYAxis] = useState(false);
  const editOptsRef = useRef();
  const [showRemovePaneModal, setShowRemovePaneModal] = useState(false);
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const editPaneMenu = useSelector((state) => state.KPVCharts.editPaneMenu);
  const chartOptions = useSelector((state) => state.KPVCharts.chartOptions);
  const xMinDomain = useSelector((state) => state.KPVCharts.xMinDomain);
  const xMaxDomain = useSelector((state) => state.KPVCharts.xMaxDomain);
  // const activeTab = useSelector((state) => state.widgets.activeTab);
  // const userData = useSelector((state) => state.loginData.userData);
  const [showAddWidgetTab, setShowAddWidgetTab] = useState(false);
  const { activeTab, widgetTypesMenu } = useSelector((state) => state.widgets);
  const userData = useSelector((state) => state.loginData.userData);
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const optionsError = useSelector((state) => state.KPVCharts.optionsError);
  const optionsLoading = useSelector((state) => state.KPVCharts.optionsLoading);
  const { addWidget, setWidgetType } = useActions();
  // console.log('paneData-11', paneData);
  useClickOutside(editOptsRef, () => setShowMenu(false));
  const { getWidgetTypes } = useActions();

  useEffect(() => {
    if (isEmpty(widgetTypesMenu) && show_widgets) {
      getWidgetTypes();
    }
  }, [widgetTypesMenu]);

  // widget save navigation
  const onSaveWidgetNavigation = () => {
    // history.push('/home');
    // handle based on menu
    // history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    history.push({
      pathname: '/home/find-widget-instance',
      state: { Shared: true }
    });
  };
  const location = useLocation();

  const handleSelect = (ele) => {
    setShowSubMenuOf('');
    if (ele.subMenu) {
      if (showSubMenuOf === ele.id) setShowSubMenuOf('');
      else setShowSubMenuOf(ele.id);
    } else {
      switch (ele.value) {
        case 'fullScreen': {
          dispatch(KPVChartsCreators.setSelectedPaneNo(paneData.paneNo));
          dispatch(KPVChartsCreators.setPaneFullScreen());
          break;
        }
        case 'editPane': {
          // console.log('aaaa', paneData);
          dispatch(
            UpsertPaneCreators.setCompleteUpsertPaneData({
              ...paneData,
              plotData: paneData.data
            })
          );
          dispatch(KPVChartsCreators.setSelectedPaneNo(paneData.paneNo));
          history.replace({
            pathname: '/home/kpv-charting/edit-pane',
            state: { previousPath: location?.state?.previousPath }
          });
          break;
        }
        case 'addAsWidget': {
          const { rawPlotData, ...paneDataDetails } = paneData;
          // setWidgetType(resolveSetWidgetTypePayload(widgetTypesMenu?.[WIDGET_TYPES.ANALYTICS]));
          const widgetPayload = getWidgetDetailsPayload({
            WPID: 0,
            userId: userData.userId,
            widgetType: WIDGET_TYPES.ANALYTICS,
            widgetTypesMenu,
            widgetName: paneData?.chartTitle || paneData?.paneTitle,
            widgetInfo: {},
            isShareable: true,
            widgetComponentData: {
              type: WIDGET_TYPES.ANALYTICS,
              // TODO_W_R: add widget categrory
              [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: WIDGET_CATEGORIES.ANALYTICS,
              // ...paneData
              ...paneDataDetails,
              xMinDomainProp: new Date(xMinDomain),
              xMaxDomainProp: new Date(xMaxDomain)
            },
            widgetMetaData: {}
          });
          // console.log('widgetSpecificData', widgetSpecificData());
          // console.log(widgetPayload, 'widget payload');
          addWidget({
            widgetPayload,
            history,
            onSuccessNavigation: onSaveWidgetNavigation
          });
          break;
        }
        case 'customiseKpvs': {
          setShowCustomiseKpv(!showCustomiseKpv);
          break;
        }

        case 'removePane': {
          // dispatch(KPVChartsCreators.removePane(paneData.paneNo));
          setShowRemovePaneModal(true);
          break;
        }
        default:
          break;
      }
    }

    if (!ele.subMenu) {
      setShowMenu(!showMenu);
    }
  };

  useEffect(() => {
    if (!showMenu) {
      setShowSubMenuOf('');
    }
  }, [showMenu]);

  useEffect(() => {
    const { chartType, chartTypeCategory } = paneData;
    if (!editPaneMenu[chartTypeCategory]) {
      dispatch(
        KPVChartsCreators.setPaneOptions({
          key: 'editPaneMenu',
          chartType: chartTypeCategory || chartType
        })
      );
    }
  }, []);
  // console.log('optionsLoading', optionsLoading, optionsError);
  try {
    return (
      <Container ref={editOptsRef}>
        <ChartType onClick={() => setShowMenu(!showMenu)}>
          <Hamburger />
          {chartOptions
            ? localeStrings[chartOptions[paneData.chartType]?.label] ||
              chartOptions[paneData.chartType]?.label
            : ''}
        </ChartType>
        {showMenu && (
          <MenuDropdown>
            {!optionsLoading && <Span>{optionsError}</Span>}
            {optionsLoading ? (
              <Loader />
            ) : (
              editPaneMenu?.[paneData.chartTypeCategory]?.map((ele) => {
                // eslint-disable-next-line prefer-destructuring
                let label = ele.label;
                if (ele.value === 'addAsWidget') {
                  if (isEmpty(activeTab)) {
                    label = 'Create a Tab and Add This As Widget';
                  } else {
                    label = 'Add This As Widget';
                  }
                }
                return (
                  <ItemContainer key={ele.id}>
                    <Item onClick={() => handleSelect(ele)}>
                      <LabelWrapper>
                        <Icon>
                          <DynamicSVGIcon iconUrl={ele.icon} />
                        </Icon>
                        <span>{localeStrings[ele.label] || label}</span>
                      </LabelWrapper>
                      {ele.subMenu && (
                        <SideArrow>
                          <DynamicSVGIcon iconUrl={DropDownArrow} />
                        </SideArrow>
                      )}
                    </Item>
                    {showSubMenuOf === ele.id && (
                      <SubMenu
                        parent={ele.value}
                        subMenu={ele.subMenu}
                        paneData={paneData}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        showScaleYAxis={showScaleYAxis}
                        setShowScaleYAxis={setShowScaleYAxis}
                      />
                    )}
                  </ItemContainer>
                );
              })
            )}
          </MenuDropdown>
        )}
        {showCustomiseKpv && (
          <EditCustomiseKpv
            editPaneNo={paneData.paneNo}
            setShowCustomiseKpv={setShowCustomiseKpv}
            setShowMenu={setShowMenu}
            paneData={{ ...paneData, machinesSelected }}
          />
        )}
        {scaleYaxis === 'manual' && showScaleYAxis && (
          <EditScaleYAxis
            paneNo={paneData.paneNo}
            setShowScaleYAxis={setShowScaleYAxis}
          />
        )}
        {showRemovePaneModal && (
          <DeletePaneModal
            closeModalHandler={setShowRemovePaneModal}
            paneNo={paneData.paneNo}
          />
        )}
        {/* widgets tabs */}
        {/* {
          <AddTab
            visibal={showAddWidgetTab}
            visibleHandler={setShowAddWidgetTab}
            heading='Add tab'
            shouldCreateWidget
            paneData={paneData}
          />
        } */}
      </Container>
    );
  } catch (error) {
    console.log('Invalid response format', error);
    return error;
  }
};
export default EditOptionsContainer;
