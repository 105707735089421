/* eslint-disable max-len */
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import { configReducer } from '../config/reducer';
import { loginReducer } from '../views/auth/store/reducer';
import { FilterReducer } from '../views/dashboard/FilterView/store';
import { homeReducer } from '../views/Layout/store';
import { localeStringsReducer } from '../localeStrings';
import { globalReducer } from '../component/GlobalSearch/store';
import { FilterResultReducer } from '../component/ResultView/Store';
import { KPVChartsReducer } from '../component/KPVCharts/Store';
import { UpsertPaneReducer } from '../component/UpsertPane/Store';
import { dashboardReducer } from '../views/MyDashboards/store';
import { myAnalyticsReducer } from '../views/MyAnalytics/store';
import { AlaramsReducer } from '../component/Alarams/Store';
import { WidgetReducer } from '../views/Widgets/store';
import { UpsertWidgetReducer } from '../component/UpsertWidget/Store';
import { UsersReducer } from '../views/Users/store';
import { AdditionalFilterReducer } from '../component/KPVCharts/PaneCard/AdditionalFilter/store';
import { ObjectTreeReducer } from '../component/WidgetForm/Store';
import { ExportReducer } from '../views/ExportDashboard/store/reducer';
import { HomeDashboardReducer } from '../views/Home/store';
//

const kpvChartsPersistConfig = {
  key: 'KPVCharts',
  storage,
  blacklist: ['panesData'],
  whitelist: [
    'panesShortHandInfo',
    'xType',
    'yType',
    'colorCodes',
    'selectedPaneNo',
    'chartOptions',
    'machinesShortlisted',
    'latestAvailable'
  ]
};
// 'xMinDomain', 'xMaxDomain',

const filterResultPersistConfig = {
  key: 'filterResultData',
  storage,
  whitelist: ['KPVShortlist', 'metaDetailsInShortlist', 'metaDetails', 'enabledPanes', 'xyMappings']
};

const upsertPaneDataPersistConfig = {
  key: 'upsertPaneData',
  storage,
  blacklist: ['plotData', 'labelsData']
};

const configDataPersistConfig = {
  key: 'configData',
  storage
};

const filtersDataPersistConfig = {
  key: 'filtersData',
  storage,
  blacklist: ['filterData', 'menuIdofFilter', 'selectedParent', 'searchResult', 'iframeRes', 'showFilterResult']
};

const homeDatPersistConfig = {
  key: 'home',
  storage,
  whitelist: ['activeMenuId']
};

const widgetsPersistConfig = {
  key: 'widgets',
  storage,
  whitelist: ['activeTab', 'menuIdofWidget', 'widgetTypesMenu', 'zoomLevel']
};

const upsertWidgetsPersistConfig = {
  key: 'upsertWidgetData',
  storage,
  whitelist: ['widgetType', 'widgetLabel', 'widgetCategory', 'widgetDashboardTitle', 'templateCreatorName', 'instanceCreatorName']
};
const loginDataPersistConfig = {
  key: 'loginData',
  storage,
  blacklist: ['token']
};

const rootReducer = combineReducers({
  configData: persistReducer(configDataPersistConfig, configReducer),
  loginData: persistReducer(loginDataPersistConfig, loginReducer),
  filterData: persistReducer(filtersDataPersistConfig, FilterReducer),
  // filterData: FilterReducer,
  filterResultData: persistReducer(filterResultPersistConfig, FilterResultReducer),
  home: persistReducer(homeDatPersistConfig, homeReducer),
  localeStrings: localeStringsReducer,
  globalSearchData: globalReducer,
  KPVCharts: persistReducer(kpvChartsPersistConfig, KPVChartsReducer),
  upsertPaneData: persistReducer(upsertPaneDataPersistConfig, UpsertPaneReducer),
  myDashboards: dashboardReducer,
  myAnalytics: myAnalyticsReducer,
  alarams: AlaramsReducer,
  widgets: persistReducer(widgetsPersistConfig, WidgetReducer),
  upsertWidgetData: persistReducer(upsertWidgetsPersistConfig, UpsertWidgetReducer),
  usersDetails: UsersReducer,
  additionalFilterDetails: AdditionalFilterReducer,
  objectTreeData: ObjectTreeReducer,
  ExportReducer,
  HomeDashboardReducer
});

export default rootReducer;
