import { THEMES } from '../constants';

export const theme = {
  // common for light and dark theme
  orange: {
    [THEMES.LIGHT]: {
      loginprimary: '#FF6610',
      primary: '#FF6610',
      secondary: '#FF9960',
      filter: {
        activeSelect: '#FF6610',
        prevSelect: '#FF9960'
      },
      loginOverlay:
        'linear-gradient(180deg, rgba(222, 178, 156, 1) 0%, rgba(250, 113, 22, 1) 60%, rgba(241, 105, 16, 1) 100%)'
    },
    [THEMES.DARK]: {
      loginprimary: '#FF6610',
      primary: '#FF6610',
      secondary: '#FF9960',
      filter: {
        activeSelect: '#FF6610',
        prevSelect: '#FF9960'
      },
      loginOverlay:
        'linear-gradient(180deg, rgba(222, 178, 156, 1) 0%, rgba(250, 113, 22, 1) 60%, rgba(241, 105, 16, 1) 100%)'
    }
  },

  black: {
    [THEMES.LIGHT]: {
      loginprimary: '#4F4ED0',
      primary: '#191919',
      secondary: '#191919',
      filter: {
        activeSelect: '#828282',
        prevSelect: '#C8C8C8'
      },
      loginOverlay: 'linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(50,57,232,1) 39%)'
    },
    [THEMES.DARK]: {
      loginprimary: '#4F4ED0',
      primary: '#0377BD',
      secondary: '#325A72',
      filter: {
        activeSelect: '#325A72',
        prevSelect: '#263b47'
      },
      loginOverlay: 'linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(50,57,232,1) 39%)'
    }
  }
};
