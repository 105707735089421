/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import {
  Header,
  HeaderLeft,
  HeaderRight,
  UserInstruction
} from './UserList.style';
import ButtonComp from '../../Common/Button/Button.view';
import { colors } from '../../../constants';
import TabsTable from '../../Common/Tabs_Table';
import { useActions } from '../../../store/hooks/useActions';
import Loader from '../../Loader/Loader';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { CancelBlack } from '../../../assets/icons';
import { usersMetaupdated } from './BulkUserMetaDetails';
import { isEmpty } from '../../../shared/utility/isEmpty';

const UserList = (props) => {
  const { handleCloseUpdate, usersData, usersMeta, modal, setToast } = props;
  const { bulkUpdateList, bulkUpdateListMeta } = useSelector((state) => state.usersDetails);
  const [assetShortlist, setAssetShortlist] = useState([]);
  const [showUpdation, setShowUpdation] = useState(false);
  const { updatedBulkList, updationState, loading, updatedBulkMeta } = useSelector((state) => state.usersDetails);
  const selectionTypeandPosition = {
    isCheckboxTypeSelection: true,
    isSelectionAtFirst: true
  };

  const { updateBulkUsers, resetUpdationStatus } = useActions();
  useEffect(() => {
    if (updationState === 'success') {
      setShowUpdation(true);
      setToast(true);
    }
    resetUpdationStatus();
  }, [updationState]);

  /**
   * Function to select and check items from UserList TabsTable
   * @param {*} e
   * @param {*} isCheckRequired
   */
  const handleShortList = (e, isCheckRequired = true) => {
    const found = assetShortlist.some((el) => el.EmailAddress === e.EmailAddress);
    if (isCheckRequired) {
      if (found) {
        const index = assetShortlist.findIndex((ele) => ele.EmailAddress === e.EmailAddress);
        assetShortlist.splice(index, 1);
        setAssetShortlist([...assetShortlist]);
      } else {
        setAssetShortlist([...assetShortlist, e]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };

  return (
    <div>
      <PopUpModal
        width='90%'
        style={{ padding: '4rem' }}
        closeModalHandler={() => {}}
      >
        <Header>
          <HeaderLeft>{modal === 'bulk add' ? 'Add Multiple Users' : 'Update Multiple Users'}</HeaderLeft>
          <HeaderRight>
            {' '}
            {!showUpdation && (
            <>
              <ButtonComp
                onClick={handleCloseUpdate}
                disabled={false}
              >
                Cancel
              </ButtonComp>
              <ButtonComp
                dark
                onClick={() => {
                  updateBulkUsers({ assetShortlist, modal });
                }}
                disabled={!assetShortlist?.length > 0}
              >
                {modal === 'bulk add' ? 'Update' : 'Submit'}
              </ButtonComp>
            </>
            )}
            {showUpdation && (
            <div style={{ cursor: 'pointer' }} onClick={handleCloseUpdate}>
              <DynamicSVGIcon iconUrl={CancelBlack} />
            </div>
            )}
          </HeaderRight>
        </Header>
        <UserInstruction>{showUpdation ? 'List of users you want to update' : 'Select users you want to update'}</UserInstruction>
        <div>
          {loading ? <Loader /> : showUpdation ? (
            <TabsTable
              metaDetails={updatedBulkMeta}
              dataDetails={updatedBulkList}
              showPagiation={false}
              handleShortList={handleShortList}
              onItemSelect={() => {}}
              assetShortlist={assetShortlist}
              styleType='strapedTable'
              noItemsFoundMessage='No Data Found'
              showRowOptionAtStart={false}
              id='UserID'
              isActionReq={false}
              shortListSelectionDetails={{
                isCheckboxTypeSelection: false,
                isSelectionAtFirst: false,

              }}

            />
          ) : (
            <TabsTable
              metaDetails={!isEmpty(bulkUpdateListMeta) ? bulkUpdateListMeta : usersMeta}
              dataDetails={usersData}
              showPagiation={false}
              showShortlistSelection={true}
              handleShortList={handleShortList}
              onItemSelect={handleShortList}
              assetShortlist={assetShortlist}
              styleType='strapedTable'
              noItemsFoundMessage='No Data Found'
              showRowOptionAtStart={false}
              id='UserID'
              shortListSelectionDetails={selectionTypeandPosition}
              isActionReq={false}

            />
          )}

        </div>
      </PopUpModal>
    </div>
  );
};

export default UserList;
