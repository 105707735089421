/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import OeeDonutWidget from '../../../../views/Widgets/WidgetComponents/OEEDonut/OeeDonutWiget';

const UpsertOEEDonutView = () => {
  const { donutData, donutMeta, loading, error } = useSelector((state) => state.upsertWidgetData);
  return (
    <div style={{ width: '60%' }}>
      <OeeDonutWidget donutData={donutData} donutMeta={donutMeta} loading={loading} error={error} />
    </div>
  );
};

export default UpsertOEEDonutView;
