/* eslint-disable eqeqeq */
/* eslint-disable max-len */
// const a = { searchable: false, sortable: false, hasOptions: false, isDateTime: true };

import { arrayToJson } from '../../../shared/utility';

export const OPTIONS_TYPES = {
  IS_DATE_TIME: 'isDateTime',
  HAS_OPTIONS: 'hasOptions',
  SEARCHABLE: 'searchable',
  SORT_BY: 'sortBy'
};

export const isOptionVisible = (optionType, optionsProvided) => {
  const { searchable, sortable, options, type, sortBy } = optionsProvided;
  const hasOptions = options.length > 0;
  const isDateTime = type === 'DateTime';
  // console.log('optionsProvided', optionType);

  // based on priority the conditions are written in order
  if (isDateTime && searchable) {
    return optionType === 'isDateTime';
  }
  if (hasOptions) {
    return optionType === 'hasOptions';
  }
  if (searchable) {
    return optionType === 'searchable';
  }
  if (sortBy) {
    return optionType === 'sortBy';
  }
  return optionsProvided[optionType];
};

export const curryIsOptionsVisible = (optionsVisible) => (optionType) => isOptionVisible(optionType, optionsVisible);

export const findChangedObjects = (orginalSet, newDataSet, uniqueKey = 'employeeId') => {
  const orginalObjects = arrayToJson(orginalSet, uniqueKey);
  const newObjects = arrayToJson(newDataSet, uniqueKey);
  const conflictedRecords = [];
  const freshRecords = [];
  Object.keys(newObjects).forEach((key) => {
    const originalObj = orginalObjects[key];
    const newObj = newObjects[key];
    if (originalObj) {
      // has a record with the same uniqueId
      originalObj.changedKeys = [];
      newObj.changedKeys = [];
      Object.keys(originalObj).forEach((colKey) => {
        // check only the value
        // eslint-disable-next-line eqeqeq
        if (originalObj[colKey] != newObj[colKey] && colKey !== 'changedKeys') {
          originalObj.changedKeys.push(colKey);
          newObj.changedKeys.push(colKey);
        }
      });
      // push only if there are any changes only
      if (newObj.changedKeys.length) {
        conflictedRecords.push([originalObj, newObj]);
      }
    } else {
      freshRecords.push(newObj);
    }
  });
  return [conflictedRecords, freshRecords];
};

export const isRowMatched = (item1, item2) =>
  Object.entries(item1).every((e) => {
    const [colKey, colValue] = e;
    return item2[colKey] === colValue;
  });

export const isRowMatchedBasedOnKey = (item1, item2, compareKey) => item1[compareKey] === item2[compareKey];

export const mergeConflictRecords = (resolvedRecords, uniqueId = 'employeeId') => {
  const replacedRecords = [];
  const duplicatedRecords = [];
  for (let i = 0; i < resolvedRecords.length; i += 1) {
    if (i !== resolvedRecords.length - 1 && resolvedRecords[i][uniqueId] == resolvedRecords[i + 1]?.[uniqueId]) {
      replacedRecords.push(resolvedRecords[i + 1]);
      i += 1;
    } else {
      duplicatedRecords.push(resolvedRecords[i]);
    }
  }
  return { replacedRecords, duplicatedRecords };
};
