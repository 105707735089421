/* eslint-disable prefer-const */
/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { CHART_CATEGORIES, CHART_TYPE_CATEGORY, resolveChartCategory } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { plotData as labelPlotData } from '../../KPVCharts/Chart/LabelChart/data';
import { xyPlotData } from '../../KPVCharts/Chart/XYChart/data';
import { Creators } from './action';
import { Creators as KPVCreators } from '../../KPVCharts/Store/action';
import { isEmpty } from '../../../shared/utility/isEmpty';

export function* getPanePlotDataSaga({ payload }) {
  try {
    const { latestAvailable, machinesWithNoPlotData } = payload;
    yield put(Creators.getPanePlotDataStart());
    // console.log('payload', payload);
    const fullURL = `${apiEndPoints.getPlotData}`;
    // as it is single
    const { plotDataAvl, singlePane, spcDetails, xKPVType, ...rest } = machinesWithNoPlotData[0];
    const { chartTypeCategory, kpvDetails } = rest;

    let reqPayload = { ...rest, spc: spcDetails };
    if (latestAvailable) {
      // remove start and end time if latestAvailable is available
      const { StartTime, EndTime, ...rest1 } = rest;
      reqPayload = { ...rest1, spc: spcDetails, latestAvailable };
    }

    console.log('pl', payload);
    // console.log('chartTypeCategory', chartTypeCategory);
    let response;
    response = yield WebService.post(fullURL, reqPayload);
    // console.log('responses', response);
    response.status = 200;
    if (latestAvailable) {
      // console.log(
      //   'responses',
      //   response,
      //   !isEmpty(response?.data) ? Object.values(response?.data)?.[0]?.meta.fields : {}
      // );
      const { StartTime: StartTime1, EndTime: EndTime1 } = response.data || {};
      try {
        const { StartTime, EndTime } = !isEmpty(response?.data) ? Object.values(response?.data)?.[0]?.meta.fields : {};
        // set the startTime and endTime from the response
        yield put(KPVCreators.setDomainRange({ xMinDomain: StartTime1 || StartTime, xMaxDomain: EndTime1 || EndTime }));
      } catch (e) {
        console.log('Unable to find the start and end time', e);
      }
    } else {
      // remove latestAvailable
      yield put(KPVCreators.setDomainRange({ latestAvailable: null }));
    }
    if (!(response.status >= 200 && response.status < 300)) {
      throw response;
    }
    let resData = response.data;
    if (chartTypeCategory === CHART_TYPE_CATEGORY.LABEL || chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART) {
      const category = resolveChartCategory[chartTypeCategory];
      resData = { category, [category.toLocaleLowerCase()]: [response.data] };
    }
    // console.log('res', response.data);
    yield put(Creators.getPanePlotDataSuccess({ ...resData, reqPayload: machinesWithNoPlotData[0] }));
    yield put(KPVCreators.setGraphFilterDetails({ data: resData, singleGraph: true }));
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      console.log(error);
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPanePlotDataFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPanePlotDataFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPanePlotDataFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPanePlotDataFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPanePlotDataFailure(payload));
    }
  }
}
