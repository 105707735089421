/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/named */
/* eslint-disable max-len */
/* eslint-disable padded-blocks */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as htmlToImage from 'html-to-image';
import { AddWidgetBg, Export, ExportExcelIcon, ExportImageIcon, ExportPdfIcon } from '../../../assets/icons';
import { WidgetCreators } from '../../../views/Widgets/store';
import { isEmpty } from '../../../shared/utility/isEmpty';
import ShowcaseLayout from './ShowcaseLayout';
import { FlexContainer } from '../../../globalStyles';
import Loader from '../../Loader/Loader';
import EmptyWidgetLanding from '../Common/EmptyWidgetLanding';
import WidgetTypeSelectionSideBar from '../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar';
import { useActions } from '../../../store/hooks/useActions';
import WidgetTemplateSelectionSideBar from '../../UpsertWidget/WidgetTemplateSelectionSideBar/WidgetTemplateSelectionSideBar';
import { PanelHeadingText, TabHeadingText } from '../Header/TabsList/TabsList';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { REPEATED_WIDGETS, SHARED_LAYOUT } from '../../../constants';
import { getWidgetLayoutData } from '../utils/getLayoutData';
import ShowcaseLayoutPop from './ShowcaseLayoutPop';
import { FilterBtn } from '../Header/TabsList/TabsList.style';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import Filter from '../../../assets/icons/Filter/Filters.svg';
import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import ExportDashboard from '../../../views/ExportDashboard/ExportDashboard';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

const WidgetDashBoardPopUp = ({
  isTemplateDashboard,
  instancePreview,
  templateParamterDetails,
  isTemplateInstanceEdit,
  isCustom = false,
  WTIId = ''
}) => {
  const dispatch = useDispatch();
  const handleAddWB = () => {
    dispatch(
      WidgetCreators.widgetDetailSetter({ key: 'showSidePanel', value: true })
    );
  };
  const [loadingLayout, setLoadingLayout] = useState(false);
  const [layout, setLayout] = useState({});

  const [layoutComponentData, setLayoutComponentData] = useState();
  const [repeatedWidgets, setRepeatedWidgets] = useState();
  const [instanceName, setInstanceName] = useState();
  const [instanceDesc, setInstanceDesc] = useState();
  const [instanceNA, setInstanceNA] = useState(false);
  const [widgetComponentData, setWidgetComponentData] = useState();

  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exportXlxs, setExportXlxs] = useState(false);

  const [templateParameters, setTemplateParameters] = useState();

  const WidgetComponentDataFromReducer = useSelector(
    (state) => state.widgets.WidgetComponentData
  );

  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const userData = useSelector((state) => state.loginData.userData);

  const {
    setBulkUpsertWidgetDetails,
    widgetDetailSetter,
    setToastError,
    setConfigureTemplate,
    setWidgetsInHomeList
  } = useActions();
  const history = useHistory();
  const getPreviewDetails = async () => {
    try {
      if (WTIId) {
        const res = await WebService.get(
          `${apiEndPoints.getInstancePreview}/${WTIId}`,
          {
            params: {
              UserId: userData?.userId
            }
          }
        );
        if (res.status >= 200 && res.status < 300) {
          const { data } = res.data;
          if (data[0][0]) {
            const {
              WidgetPanelLayoutData,
              Widgets,
              WidgetComponentData,
              TemplateParameters,
              WTIId,
              TabType,
              InheritedWPID,
              HomeWIDs,
              WidgetTemplateInstanceName = '',
              WidgetTemplateInstanceDescription = '',
              description = ''
            } = data[0][0];

            /**
             * Getting List of Instances available in Home and setting it to Reducer
             */
            const homeIdArr = [];
            HomeWIDs.forEach((homeWid) => {
              homeIdArr.push(String(homeWid.WID));
            });
            setWidgetsInHomeList([...homeIdArr]);

            const templateInstanceParams = {
              WidgetComponentData,
              WTIId,
              TabType,
              InheritedWPID
            };
            setWidgetComponentData(WidgetComponentData);
            const {
              layoutComponentData: layCompData,
              currentLayout,
              widgetTypesIncluded
            } = getWidgetLayoutData(
              Widgets,
              WidgetPanelLayoutData[SHARED_LAYOUT],
              WidgetPanelLayoutData[REPEATED_WIDGETS],
              false,
              {}
            );
            setLayout({ ...layout, [breakpoint]: currentLayout });
            setInstanceName(WidgetTemplateInstanceName);
            setInstanceDesc(description);
            setLayoutComponentData(layCompData);
            setTemplateParameters(TemplateParameters);
            setRepeatedWidgets(WidgetPanelLayoutData[REPEATED_WIDGETS]);
            setLoadingLayout(false);
            widgetDetailSetter({
              key: 'WTIId',
              value: WTIId
            });
            widgetDetailSetter({
              key: 'isInstancePop',
              value: true
            });
          }
        } else {
          throw res;
        }
        setLoadingLayout(false);
      }
    } catch (e) {
      setLoadingLayout(false);
      const { error } = sagaCatchBlockHandling(e);
      if (e.response.status === 431) {
        setInstanceNA(true);
      }
      console.log('e', e, error);
      setToastError(error);
    }
  };

  useEffect(() => {
    // used to preview the template instance
    setLoadingLayout(true);
    if (WTIId) {
      getPreviewDetails();
    }
  }, [instancePreview]);
  // isTempate make true so three dot menu will be visiable in pop up view widget
  useEffect(() => {
    setConfigureTemplate(true);
  }, []);

  if (loadingLayout) {
    return (
      <FlexContainer style={{ marginTop: '30vh' }}>
        <Loader />
      </FlexContainer>
    );
  }

  const handleFilter = () => {
    setBulkUpsertWidgetDetails({
      templateFormInput: templateParameters?.formInput,
      templateInstanceFormInput: !isEmpty(WidgetComponentDataFromReducer)
        ? WidgetComponentDataFromReducer
        : widgetComponentData,
      templateInstanceName: instanceName,
      WTIId
    });
    widgetDetailSetter({
      key: 'widgetTypesIncluded',
      value: templateParameters?.widgetTypesIncluded
    });
    history.push({
      pathname: '/home/template-instance',
      state: { isPreSelection: false, configureInstance: true, isPop: true }
    });
  };

  const ExportMenu = [
    {
      id: 6,
      label: 'Export PDF',
      role: 'exportPdf',
      iconUrl: ExportPdfIcon
      // type: 'radio',
    },
    {
      id: 7,
      label: 'Export Image',
      role: 'exportPng',
      iconUrl: ExportImageIcon
      // type: 'radio',
    },
    {
      id: 8,
      label: 'Export Excel',
      role: 'exportExcel',
      iconUrl: ExportExcelIcon
      // type: 'radio',
    }
  ];

  const handleDropdown = ({ role, val = true }) => {
    if (role === 'exportPng') {
      setShowOptions(false);
      const divToExport =
        document.getElementsByClassName('react-grid-layout')[0];

      setTimeout(() => {
        htmlToImage
          .toPng(divToExport, {
            width: divToExport.scrollWidth,
            height: divToExport.scrollHeight
          })
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = instanceName;
            link.href = dataUrl;
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
          })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }, 100);

      //  captureFullDivScreenshot();
    } else if (role === 'exportPdf') {
      setShowOptions(false);
      document.title = instanceName;
      window.print();
      document.title = 'Vistrian';
    } else if (role === 'exportExcel') {
      setShowOptions(false);
      setExportXlxs(true);
    } else {
      setShowModal({ [role]: val });
    }
  };

  return (
    <>
      {!instanceNA && (
        <PanelHeadingText
          className='flex'
          active={true}
          style={{ textAlign: 'start' }}
        >
          <span
            style={{
              display: 'block',
              flex: '0 1 90%',
              fontSize: '2rem',
              fontFamily: 'Circular Std Bold'
            }}
            className='text-overflow'
          >
            {instanceName && `${instanceName}`}
          </span>
          <span
            style={{ maxWidth: '5%', fontSize: '10px' }}
            className='text-overflow'
          >
            {instanceDesc && instanceDesc}
          </span>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}> */}
          <FilterBtn
            onClick={handleFilter}
            isVisible={true}
            style={{ cursor: 'pointer' }}
          >
            <DynamicSVGIcon width='2.5rem' iconUrl={Filter} />
          </FilterBtn>
          <DropDownModal
            isActive={showOptions}
            data={ExportMenu}
            setModalIsVisibal={() => setShowOptions(false)}
            // setModalIsVisibal={() => {}}
            handleClick={handleDropdown}
            // singleSelection
            ContainerStyle={{ width: 'max-content', zIndex: 200 }}
            noContainerPadding
            className='light'
          >
            <div
              className='widget-tab-kabab'
              style={{ padding: '5px', cursor: 'pointer' }}
              onClick={(e) => {
                setShowOptions((e) => !e);
                e.stopPropagation();
              }}
            >
              <DynamicSVGIcon width='2.5rem' iconUrl={Export} />
            </div>
          </DropDownModal>
        </PanelHeadingText>
      )}
      {isEmpty(layout?.[breakpoint]) ? (
        instanceNA ? (
          <EmptyWidgetLanding
            label='Add Widgets'
            // handleClick={handleAddWB}
            iconUrl={AddWidgetBg}
            description='You Are Not Authorized To Access This Instance!!'
          />
        ) : (
          <>
            {!isTemplateDashboard ? (
              <EmptyWidgetLanding
                label='Add Widgets'
                // handleClick={handleAddWB}
                iconUrl={AddWidgetBg}
                description='Something Went Wrong!!'
              />
            ) : (
              <></>
            )}
          </>
        )
      ) : (
        <>
          <ShowcaseLayoutPop
            isCustom={isCustom}
            layout={layout}
            repeatedWidgets={repeatedWidgets}
            layoutComponentData={layoutComponentData}
            WidgetComponentData={
              !isEmpty(WidgetComponentDataFromReducer)
                ? WidgetComponentDataFromReducer
                : widgetComponentData
            }
            handleFilter={handleFilter}
          />
          {exportXlxs && (
            <ExportDashboard
              name={instanceName}
              setExportXlxs={setExportXlxs}
              WidgetComponentData={WidgetComponentDataFromReducer}
              individualExport={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default WidgetDashBoardPopUp;
