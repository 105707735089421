/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchContainer,
  CommonButtonContainer,
  SearchButton,
  FilterButtom,
  SearchDropDown,
  SearchResultText,
  SearchTextContainer,
  Image,
  SearchMainContainer
} from './TreeSearchComponent.style';

import { Creators as ObjectTreeCreators } from '../Store';

import TreeSearchInput from './TreeSearchInput';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import { ReactComponent as FilterIcon } from '../../../assets/icons/Filter/Filters.svg';
import { ReactComponent as SearchIconSvg } from '../../../assets/icons/Filter/Search Black.svg';
import NoResultSvg from '../../../assets/icons/Filter/No results on filter.svg';
import { localString } from '../../../localization/localString';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { NoResultText } from '../../GlobalSearch/GlobalSearchContainer.style';
import { FILTER_TERMS } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';
import useSelectionTypesDefined from '../hooks/useSelectionTypesDefined';

function TreeSearchComponent({
  item,
  showFilter,
  setShowFilter,
  setActiveTab,
  isLabelChart,
  isDisabled = false,
  placeholder
}) {
  const [searchValue, setSearchValue] = useState('');
  const {
    isDropDownActive,
    searchResult,
    tags,
    selectedItem,
    cumulativeTags,
    showFilterResult,
    objectTreeData: fd
  } = useSelector((state) => state.objectTreeData);
  const objectTreeData = useSelector((state) => state.objectTreeData);
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );

  const dispatch = useDispatch();

  const addTabToObj = useCallback(
    (payload) => dispatch(ObjectTreeCreators.universalObjectTreeSetter(payload)),
    [dispatch]
  );
  const addTags = useCallback(
    (payload) => dispatch(ObjectTreeCreators.setTagsObj(payload)),
    [dispatch]
  );
  const addCummulativeTags = useCallback(
    (payload) => dispatch(ObjectTreeCreators.setCummulativeTagsObj(payload)),
    [dispatch]
  );

  const closeSearchDropDown = () => {
    if (isDropDownActive) {
      dispatch(
        ObjectTreeCreators.universalObjectTreeSetter({
          key: 'isDropDownActive',
          value: false
        })
      );
    }
  };

  const handleClick = (i, parent) => {
    const cummSelectedObj = objectTreeData[`cumulative${parent}`];
    // show filter on click if it is in resultview
    if (showFilterResult) {
      dispatch(
        ObjectTreeCreators.universalObjectTreeSetter({
          key: 'showFilterResult',
          value: false
        })
      );
    }
    let pathInsertIndex = 0;
    let check;
    let pathNoFound;
    // console.log('cummSelectedObj', cummSelectedObj);
    // checking whether the full or partial selection is already present.
    if (cummSelectedObj) {
      check = Object.keys(cummSelectedObj).findIndex((pathNum) =>
        Object.keys(searchResult.searchedObjectPath[i]).every((key) => {
          pathNoFound = pathNum;
          return Object.keys(cummSelectedObj[pathNum]).includes(key);
        })
      );
      // console.log('pathInsertIndex', check, searchResult.searchedObjectPath[i]);
    }

    if (!isEmpty(cummSelectedObj)) {
      const noOfPaths = Object.keys(cummSelectedObj).length;
      pathInsertIndex =
        parseInt(Object.keys(cummSelectedObj)[noOfPaths - 1], 10) + 1;
    }
    // selection is already present(exactly)
    if (check > -1) {
      // console.log('already present', pathNoFound);
      addTabToObj({
        key: parent,
        value: cummSelectedObj[pathNoFound]
      });
      addTags({
        ...tags,
        [parent]: cumulativeTags[parent][pathNoFound]
      });
    } else {
      const selectionTypesDefined = useSelectionTypesDefined(fd, parent);
      // console.log(
      //   'aaaa',
      //   Object.keys(searchResult.searchedObjectPath[i]),
      //   searchResult.searchedObjectPath[i],
      //   selectionTypesDefined
      // );

      // checking whether an item is already selected
      // for a single selection attrubute
      let pathNoTobeReplaced = -1;
      Object.keys(searchResult.searchedObjectPath[i]).some((key) => {
        const item = searchResult.searchedObjectPath[i][key];
        if (
          selectionTypesDefined[item.attribute] === FILTER_TERMS.SINGLE_SELECT
        ) {
          if (!isEmpty(cummSelectedObj)) {
            Object.keys(cummSelectedObj)?.some((pathNo) => {
              if (Object.keys(cummSelectedObj[pathNo]).includes(key)) {
                pathNoTobeReplaced = pathNo;
                return true;
              }
              return false;
            });
          }
          // console.log('pathNoTobeReplaced', pathNoTobeReplaced);
          return true;
        }
        return false;
      });
      if (pathNoTobeReplaced > -1) {
        pathInsertIndex = pathNoTobeReplaced;
      }
      // adding the path
      addTabToObj({
        key: parent,
        value: searchResult.searchedObjectPath[i]
      });
      addTags({
        ...tags,
        [parent]: searchResult.referencePath[i]
      });
      addTabToObj({
        key: `cumulative${parent}`,
        value: {
          ...cummSelectedObj,
          [pathInsertIndex]: searchResult.searchedObjectPath[i]
        }
      });
      addCummulativeTags({
        ...cumulativeTags,
        [parent]: {
          ...cumulativeTags[parent],
          [pathInsertIndex]: searchResult.referencePath[i]
        }
      });
    }
    // }

    closeSearchDropDown();
    if (!showFilter) {
      setShowFilter(true);
    }
    if (window.innerWidth < 767) {
      const index = objectTreeData.findIndex((item) => item.groupTitle === parent);
      setActiveTab(index);
    }

    setSearchValue('');
    addTabToObj({
      key: 'searchResult',
      value: null
    });
  };

  const handleFilterClick = () => {
    setShowFilter(true);
    addTabToObj({
      key: 'showFilterResult',
      value: false
    });
  };

  const ref = useRef();

  useClickOutside(ref, closeSearchDropDown);
  return (
    (showFilter || window.innerWidth > 768) && (
      <SearchMainContainer style={{ width: isLabelChart && '100%' }}>
        <SearchContainer isLabelChart={isLabelChart}>
          <TreeSearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isLabelChart={isLabelChart}
            isDisabled={isDisabled}
            key={item}
            itemKey={item?.key}
            placeholder={placeholder}
          />
          <CommonButtonContainer>
            <SearchButton>
              <SearchIconSvg />
            </SearchButton>
            <FilterButtom isDisabled={isDisabled} onClick={!isDisabled && handleFilterClick}>
              <FilterIcon />
            </FilterButtom>
          </CommonButtonContainer>
          {isDropDownActive && searchValue !== '' && (
            <SearchDropDown ref={ref}>
              <div style={{ marginTop: 5 }}>
                {searchResult &&
                  (searchResult?.pathVal?.length > 0 ? (
                    searchResult.pathVal.map((path, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <SearchTextContainer
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        onClick={() => { handleClick(i, path.parent); }}

                      >
                        <SearchResultText>
                          {`${path.parent} / ${path.path}`}
                        </SearchResultText>
                      </SearchTextContainer>
                    ))
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {/* <Image src={NoResultSvg} width={3} height={3} /> */}
                      <Image src={NoResultSvg}>
                        <DynamicSVGIcon iconUrl={NoResultSvg} width='3rem' />
                      </Image>
                      <NoResultText>
                        {localeStrings.noResult || 'No Result'}
                      </NoResultText>
                    </div>
                  ))}
              </div>
            </SearchDropDown>
          )}
        </SearchContainer>
      </SearchMainContainer>
    )
  );
}

export default TreeSearchComponent;
