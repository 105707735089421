import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCategoryList: ['payload'],
  getCategoryListStart: ['payload'],
  getCategoryListSuccess: ['payload'],
  getCategoryListFailure: ['payload'],

  getFilteredList: ['payload'],
  getFilterListStart: ['payload'],
  getFilterListSuccess: ['payload'],
  getFilterListFailure: ['payload'],
});

export { Types, Creators };
