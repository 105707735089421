/* eslint-disable max-len */
import React, { memo } from 'react';
import { CHART_TYPE_CATEGORY } from '../../../constants';
import DataGraphComponent from '../Chart/DataChart/DataGraphWrapper';
import TransitionGraphComponent from '../Chart/TransitionChart/TransitionChartWrapper';
import SPCGraphWrapper from '../Chart/SPCChart/SPCGraphWrapper';
import LabelChartWrapper from '../Chart/LabelChart/LabelChartWrapper';
import XYChartWrapper from '../Chart/XYChart/XYChartWrapper';

const PaneGraph = ({
  chartTypeCategory,
  isLastPane = true,
  padding = 0,
  index = 0,
  pointInfoObj = {},
  paneData,
  fillLayout, // widgets
  // labelsData,
  // colorCodes,
  position,
  xMinDomainForFiltered,
  xMaxDomainForFiltered
}) => (
  <>
    {chartTypeCategory === CHART_TYPE_CATEGORY.DATA_CHART && (
      <DataGraphComponent
        padding={padding}
        isLastPane={isLastPane}
        {...paneData}
        paneIndex={index}
        index={index}
        paneDataPoints={paneData.data}
        pointInfoObj={pointInfoObj?.length > 0 && pointInfoObj[index]}
        scaleYAxisRange={
          // labelsData?.length > 1 ? [paneData.scaleYAxisRange?.[index]] :
          paneData.scaleYAxisRange
        }
        fillLayout={fillLayout}
        // color={index > 0 ? colorCodes[index] : undefined}
        position={position}
        xMinDomainForFiltered={xMinDomainForFiltered}
        xMaxDomainForFiltered={xMaxDomainForFiltered}
      />
    )}
    {chartTypeCategory === CHART_TYPE_CATEGORY.SPC && (
      <SPCGraphWrapper
        padding={padding}
        isLastPane={isLastPane}
        {...paneData}
        paneIndex={index}
        index={index}
        paneDataPoints={paneData.data}
        pointInfoObj={pointInfoObj?.length > 0 && pointInfoObj[index]}
        scaleYAxisRange={
          // labelsData?.length > 1 ? [paneData.scaleYAxisRange?.[index]] :
          paneData.scaleYAxisRange
        }
        fillLayout={fillLayout}
        // color={index > 0 ? colorCodes[index] : undefined}
        position={position}
      />
    )}
    {chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART && (
      <TransitionGraphComponent
        isLastPane={isLastPane}
        padding={padding}
        {...paneData}
        index={index}
        position={position}
        fillLayout={fillLayout}
      />
    )}
    {chartTypeCategory === CHART_TYPE_CATEGORY.LABEL && (
      <LabelChartWrapper
        isLastPane={isLastPane}
        padding={padding}
        {...paneData}
        index={index}
        position={position}
        fillLayout={fillLayout}
      />
    )}
    {chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART && (
      <XYChartWrapper
        isLastPane={isLastPane}
        padding={padding}
        {...paneData}
        index={index}
        position={position}
        fillLayout={fillLayout}
      />
    )}
  </>
);

export default memo(PaneGraph);
