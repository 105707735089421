/* eslint-disable max-len */
import { getSPCDataPoints } from './getSPCDataPoints';

export const getOverlayDataPoints = (showOverlays, meta, isAlarm, additionalDetails) => {
  // console.log('showOverlays fn', showOverlays, meta);
  const overlayDomain = {};
  const { orgDataLength } = additionalDetails;
  let curLength = orgDataLength;
  const data =
    showOverlays &&
    Object.entries(showOverlays).reduce((prevData, overlay) => {
      const [key, value] = overlay;
      const { data, kpv } = value;
      const overlayDataPoints = data.reduce((prev, nodeObj) => {
        const { grid, Text, name, node, description, color } = nodeObj;
        // console.log('kpv', kpv, meta[kpv]);
        let additionalDetails = {};
        if (isAlarm) {
          const overlays = Object.values(meta[kpv].default);
          additionalDetails = {
            grid,
            name,
            description,
            color,
            pane: overlays?.[grid - 1]?.[0]?.node
          };
        } else {
          // to get rejected by search
          overlayDomain[node] = {
            startIndex: null,
            endIndex: null
          };
          const overlayDataLength = meta[kpv]?.data[node]?.length;
          additionalDetails = {
            grid,
            Text,
            node,
            isOverlay: true
          };
          if (overlayDataLength) {
            overlayDomain[node] = {
              startIndex: curLength,
              endIndex: curLength + overlayDataLength - 1
            };
            curLength += overlayDataLength;
          }
        }
        const currentData = getSPCDataPoints(meta[kpv]?.data[node], additionalDetails, isAlarm);

        return [...prev, ...currentData];
      }, []);
      // console.log('overlayDataPoints', overlayDataPoints);
      return [...prevData, ...overlayDataPoints];
    }, []);
  // console.log('final', data);
  return { data: data || [], overlayDomain };
};
