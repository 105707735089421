import React from 'react';
import { ReactComponent as LoaderSVG } from '../../../assets/icons/loader.svg';
import { Container } from './Loader.style';

const Loader = ({ position, width, height }) => (
  <Container position={position} width={width} height={height}>
    <LoaderSVG />
  </Container>
);

export default Loader;
