/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { generateSelectedFilter } from '../../component/ResultView/Iframe/generateSelectedFilter';
import { WebService } from '../../services/axios/webServices';
import Loader from '../../component/Common/Loader/Loader';
import DashboardForm from './components/DashboardForm';
import { Creators as DashboardCreators } from './store';
import { PAGE_TYPES } from '../../constants';
import { isEmpty } from '../../shared/utility/isEmpty';

const SaveDashboard = ({ onCancel }) => {
  const { menuIdofFilter, cumulativeTags, selectedParent, filterData, leafLevels } = useSelector(
    (state) => state.filterData
  );
  const userData = useSelector((state) => state.loginData.userData);
  const myDashboardPaths = useSelector((state) => state.home?.[PAGE_TYPES.MY_DASHBOARDS]);
  const dispatch = useDispatch();
  const history = useHistory();
  const dashBoardPostInit = {
    loading: false,
    successMsg: '',
    errMsg: ''
  };
  const formInit = {
    DashboardTitle: '',
    // Category: 'CategoryType',
    Searchtags: '',
    Shared: true,
    UserID: userData.email || userData.mail
  };

  useEffect(() => {
    dispatch(DashboardCreators.setDashboardDetails({ form: formInit, isInit: true }));
  }, []);

  const [dashboardPost, setDashboardPost] = useState(dashBoardPostInit);
  const handleSave = async (form) => {
    try {
      setDashboardPost({ ...dashboardPost, loading: true });
      const filterPayload = generateSelectedFilter(cumulativeTags, filterData, true);
      const url = apiEndPoints.callSaveDashboards;
      const response = await WebService.post(url, {
        ...form,
        menuid: menuIdofFilter,
        tab: selectedParent,
        data: { ...filterPayload },
        leafLevels
      });
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        setDashboardPost({ ...dashboardPost, loading: false, successMsg: data });
        onCancel();

        if (!isEmpty(myDashboardPaths)) {
          const firstKey = Object.keys(myDashboardPaths)[0];
          history.push(myDashboardPaths[firstKey]);
        }
      } else {
        throw response;
      }
    } catch (error) {
      const payload = sagaCatchBlockHandling(error);
      setDashboardPost({ ...dashboardPost, loading: false, errMsg: payload.error });
    }
  };

  return (
    <DashboardForm
      onCancel={onCancel}
      handleSave={handleSave}
      error={dashboardPost.errMsg}
      loading={dashboardPost.loading}
    />
  );
};

export default SaveDashboard;
