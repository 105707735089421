/* eslint-disable indent */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { Creators as KPVChartsCreators } from '../../Store';
import { getNearestPointsInSPC } from '../../utils/getNearestPoints';
import { Graph, GraphContainer, WrapperConatiner } from '../../PaneCard/PaneCards.style';
import AnnotationsDetails from '../../PaneCard/Annotations/AnnotationsDetails';
import EditAnnotationsForm from '../../PaneCard/Annotations/EditAnnotationForm';
import AddAnnotationsForm from '../../PaneCard/Annotations/AddAnnotationsForm';
import LegendComp from '../../Legend/Legend.view';
import SPCChartView from './SPCChart.view';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../../constants';
import AlarmDetails from '../../PaneCard/Alarms/AlarmsModal';
import { getOverlayDataPoints } from '../../utils/getOverlayDataPoints';
import { Creators as UpsertPaneCreators } from '../../../UpsertPane/Store';

const SPCGraphComponent = ({ isLastPane, padding, position = 'right', isFullScreen, fillLayout, ...extraPaneData }) => {
  // const paneData = {...rawPaneData }
  const paneData = extraPaneData;
  const {
    showDetails,
    labelsData,
    pointInfoObj,
    xMinDomainProp,
    xMaxDomainProp,
    multiGrid,
    id,
    rawPlotData,
    annotations,
    paneIndex,
    chartType,
    showOverlays,
    showAlarms,
    overlayDomain,
    overlaysData,
    chartTypeCategory
    // isAddAnnotationEnabled
  } = paneData;
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  const pointClicked = useSelector((state) => state.KPVCharts.pointClicked);
  const selectedPaneNo = useSelector((state) => state.KPVCharts.selectedPaneNo);
  const globalHoveredState = useSelector((state) => state.KPVCharts.globalHoveredState);
  const comparisionMode = useSelector((state) => state.KPVCharts.comparisionMode);
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);
  const location = useLocation();
  const index = 0;
  const dispatch = useDispatch();
  // hovered state is manual trigger for compress view
  const [hoveredState, setHoveredState] = useState([]);
  const [hoveredPosState, setHoveredPosState] = useState();
  // global hovered state is only one, the exact point where the  is hovered
  const setGlobalHoveredState = useCallback(
    (value) => {
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'globalHoveredState',
          value: { index: !isEmpty(value) ? paneIndex : -1, ...value }
        })
      );
      setHoveredState(value);
    },
    [dispatch]
  );
  // const [pointClicked, setPointClicked] = useState({});
  const setPointClicked = useCallback(
    (value) =>
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'pointClicked',
          value: { ...value, index: !isEmpty(value) ? paneIndex : -1 }
          // value: { ...value, index: paneIndex }
        })
      ),
    [dispatch]
  );
  const { data: plotData, meta } = rawPlotData || {};

  // moved hover code to outside the graph component
  const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const compressedView = useSelector((state) => state.KPVCharts.compressedView);

  // format it in the desired format and set to hoveredState
  const computeHoveredState = (argument) => {
    // const data = getNearestPointsInSPC(plotData, meta, showOverlays, argument, overlayDomain);
    const data = getNearestPointsInSPC(
      [...plotData, ...(overlaysData || [])],
      meta,
      showOverlays,
      argument,
      overlayDomain
    );
    setHoveredState({ data, x: argument });
  };

  useEffect(() => {
    // console.log('hoveredXValue', hoveredXValue);
    if (compressedView && hoveredXValue) {
      computeHoveredState(hoveredXValue?.argument);
    } else if (hoveredState?.x?.getTime() !== hoveredXValue?.argument?.getTime()) {
      // setHoveredState({});
    }
  }, [hoveredXValue]);

  const checkAnnotations = ({ arg, seriesName }, xPos, yPos) => {
    if (!arg) return false;
    return {
      ...annotations?.find((argPoint) => seriesName === argPoint.seriesKey && moment(arg).isSame(argPoint.argument)),
      left: xPos,
      top: yPos
    };
  };
  const getComparisionTimestamp = () => {
    if (!isEmpty(hoveredPosState)) {
      const arg = Object.values(hoveredPosState)[0]?.X;
      return moment(arg).format('DD/MM/YYYY HH:mm:ss:SSS');
    }
    return null;
  };
  const xCompLabel = useMemo(() => getComparisionTimestamp(), [meta, hoveredPosState]);

  const hoveredAnnotatedPoint = checkAnnotations(
    { arg: globalHoveredState.x, seriesName: globalHoveredState.seriesName },
    globalHoveredState.left,
    globalHoveredState.top
  );
  const clickedAnnotatedPoint = checkAnnotations(
    { arg: pointClicked.argument, seriesName: pointClicked.seriesName },
    pointClicked.x,
    pointClicked.y
  );
  // eslint-disable-next-line no-nested-ternary
  const height = multiGrid ? (meta ? `${Object.keys(meta).length * 16}rem` : '40rem') : '25rem';
  let totalOverlays = { ...(showOverlays || {}) };
  if (!isEmpty(meta)) {
    totalOverlays = { ...totalOverlays, ...Object.values(meta)?.[0]?.default };
  }
  // pane height for comparision mode vertical line
  const [paneHeight, setPaneHeight] = useState(0);
  useEffect(() => {
    let panesAvailable = 0;
    if (!isEmpty(meta)) {
      Object.keys(meta).forEach((kpv) => {
        panesAvailable += Object.keys(meta[kpv].default).length;
      });
    }
    setPaneHeight(panesAvailable * 200);
  }, [meta]);

  // update overlays data onchange of plotdata, showOverlays
  useEffect(() => {
    if (!isEmpty(meta)) {
      // from main charting page
      if (selectedPaneNo === '' && !location.pathname.includes('/add-pane')) {
        const { data: overlayData, overlayDomain } = getOverlayDataPoints(showOverlays, meta, false, {
          orgDataLength: plotData?.length
        });
        dispatch(
          KPVChartsCreators.editPaneData({
            index: paneIndex,
            key: 'overlaysData',
            value: overlayData
          })
        );
        dispatch(
          KPVChartsCreators.editPaneData({
            index: paneIndex,
            key: 'overlayDomain',
            value: overlayDomain
          })
        );
      } else {
        // from add/edit page
        const { data: overlayData } = getOverlayDataPoints(showOverlays, meta, false, {
          orgDataLength: plotData.length
        });
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: 'overlaysData',
            value: overlayData
          })
        );
      }
    }
  }, [showOverlays, plotData]);
  // update alarams data onchange of plotdata, showAlarms
  useEffect(() => {
    if (!isEmpty(meta)) {
      const { data: overlayData } = getOverlayDataPoints(showAlarms, meta, true, {
        orgDataLength: plotData?.length
      });
      if (selectedPaneNo === '' && !location.pathname.includes('/add-pane')) {
        dispatch(
          KPVChartsCreators.editPaneData({
            index: paneIndex,
            key: 'alarmsData',
            value: overlayData
          })
        );
      } else {
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: 'alarmsData',
            value: overlayData
          })
        );
      }
    }
  }, [showAlarms, plotData]);

  return (
    <GraphContainer
      style={{
        flexDirection: 'column',
        height: fillLayout ? '100%' : 'auto'
      }}
    >
      <WrapperConatiner
        className='autoHeight'
        multiGrid={multiGrid}
        position={position}
        key={id}
        style={{ display: 'flex', width: '100%' }}
        fillLayout={fillLayout}
      >
        <Graph
          className='singlePane autoHeight'
          width={(showDetails ? showDetails?.legends : true || isFullScreen) ? '75%' : '100%'}
          paddingLeft={padding}
          // isCursorPointer={isAddAnnotationEnabled}
        >
          {/* comparision mode vertical line */}
          {comparisionMode && isAligned && !isEmpty(globalHoveredState) && (
            <div
              style={{
                position: 'absolute',
                left: globalHoveredState?.left + 10,
                height: `${paneHeight + 90}px`,
                width: '3px',
                zIndex: '10',
                background: 'black'
              }}
            >
              {xCompLabel ? (
                <div
                  style={{
                    fontSize: '1.8rem',
                    position: 'absolute',
                    bottom: '0rem',
                    background: 'black',
                    color: '#fff',
                    padding: '0.5rem'
                  }}
                >
                  {xCompLabel}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* annotations */}
          {showDetails?.annotations && (
            <>
              {/* annotations point details */}
              {globalHoveredState.index === paneIndex &&
                hoveredAnnotatedPoint.argument &&
                !clickedAnnotatedPoint?.argument && (
                  <AnnotationsDetails
                    hoveredAnnotatedPoint={hoveredAnnotatedPoint}
                    showAnnotations={showDetails.annotations}
                  />
                )}
              {/* edit/add annotations modal */}
              {pointClicked.index === paneIndex && (
                <>
                  {clickedAnnotatedPoint?.argument ? (
                    <EditAnnotationsForm
                      index={paneIndex}
                      pointClicked={pointClicked}
                      setPointClicked={setPointClicked}
                      meta={meta}
                      annotatedPoint={{
                        ...clickedAnnotatedPoint,
                        seriesLabel: `${clickedAnnotatedPoint?.seriesKey} => ${clickedAnnotatedPoint?.seriesLabel}`
                      }}
                      chartType={chartType || CHART_TYPES.DATA_CHART}
                      chartTypeCategory={chartTypeCategory || CHART_TYPE_CATEGORY.CHART_TYPE_CATEGORY}
                    />
                  ) : (
                    <AddAnnotationsForm
                      index={paneIndex}
                      pointClicked={pointClicked}
                      setPointClicked={setPointClicked}
                      meta={meta}
                      chartType={chartType || CHART_TYPES.DATA_CHART}
                      chartTypeCategory={chartTypeCategory || CHART_TYPE_CATEGORY.CHART_TYPE_CATEGORY}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* alarmDetails Modal */}
          {globalHoveredState.index === paneIndex && !isEmpty(globalHoveredState.isAlarmPoint) && (
            <AlarmDetails left={globalHoveredState.left} alarmDetails={globalHoveredState.isAlarmPoint} />
          )}

          <SPCChartView
            {...paneData}
            fillLayout={fillLayout}
            paddingLeft={padding}
            isLastPane={isLastPane}
            setHoveredState={setGlobalHoveredState}
            setHoveredPosState={setHoveredPosState}
            setPointClicked={setPointClicked}
            xPos={comparisionMode && isAligned ? globalHoveredState.orgX : 0}
            // hoveredState={hoveredState}
          />
        </Graph>
        {/* {(showDetails ? showDetails?.legends : true) && ( */}
        <LegendComp
          data={labelsData?.[index]}
          labels={meta && Object.values(meta)}
          chartType={chartType}
          showLegends={showDetails ? showDetails?.legends : true}
          chartTypeCategory={CHART_TYPE_CATEGORY.SPC}
          showOverlays={totalOverlays}
          showAlarms={showAlarms}
          // pointData={pointInfoObj?.length > 0 && pointInfoObj[index]}
          noColor
          position={position}
          hoveredState={hoveredState?.data}
          hoveredPosState={hoveredPosState}
        />
        {/* )} */}
      </WrapperConatiner>
    </GraphContainer>
  );
};

export default memo(SPCGraphComponent);
