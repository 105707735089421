import { put } from 'redux-saga/effects';
import { formatedFilterData } from '../../../../Filter/utility/formatFilterData';
import { apiEndPoints } from '../../../../../services/axios/endPoints';
import { WebService } from '../../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../../store/utility/sagaErrorHandling';
import { Creators } from './action';

export function* getCategoryListSaga(payloadObj) {
  try {
    // const {
    //   type, method
    // } = payloadObj.payload;

    // const payload = { categoryType: type };

    yield put(Creators.getCategoryListStart());

    const URL = `${apiEndPoints.getFilterCategory}`;

    // if (type === 'page') {
    //   URL = `${apiEndPoints.getDataView}`;
    // }
    // if (directURL) {
    //   URL = directURL;
    // }
    const fullURL = yield URL;

    // calling api
    let response;
    // const response = yield WebService?.[method || 'get'](fullURL, {
    //   params: { lang: 'eng', menuId, ...directParams }
    // });
    const CategoryOptions = {
      data: {
        dataChart: {
          key: 'dataChart',
          value: 'dataChart',
          label: 'Data Chart'
        },
        LabelChart: {
          key: 'LabelChart',
          value: 'LabelChart',
          label: 'Label Chart'
        }
      }
    };
    const { data } = CategoryOptions;
    yield put(Creators.getCategoryListSuccess({ data }));
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response?.data?.data || {};
      yield put(Creators.getCategoryListSuccess({ data, meta }));
    } else {
      throw response;
    }

    // Remove the lines after api
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('error', error);
    // yield put(Creators.getCategoryListFailure(error?.error));
  }
}

export function* getFilteredListSaga({ payload }) {
  const ListData = {
    Count: 24,
    data: [
      {
        Name: 'Lot 01',
        Time: '11:45pm, 27/06/2023',
        Option1: 'Lorem ipsum',
        Option2: 'Lorem ipsum',
        Option3: 'Lorem ipsum',
        Option4: 'Lorem ipsum',
        Key: '1'
      },
      {
        Name: 'Lot 02',
        Time: '11:45pm, 27/06/2023',
        Option1: 'Lorem ipsum',
        Option2: 'Lorem ipsum',
        Option3: 'Lorem ipsum',
        Option4: 'Lorem ipsum',
        Key: '2'
      }
    ],
    meta: {
      columns: [
        {
          key: 'Name',
          title: 'Name',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: true,
          options: []
        },
        {
          key: 'Time',
          title: 'Time',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: true,
          options: []
        },
        {
          key: 'Option1',
          title: 'Option 1',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: true,
          options: []
        },
        {
          key: 'Option2',
          title: 'Option 2',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: true,
          options: []
        },
        {
          key: 'Option3',
          title: 'Option 3',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: true,
          options: []
        },
        {
          key: 'Key',
          title: 'Key',
          align: 'left',
          sortBy: false,
          searchable: false,
          visible: false,
          options: []
        }
      ]
    }
  };
  try {
    yield put(Creators.getFilterListStart());
    // eslint-disable-next-line max-len
    const response = yield WebService.post(apiEndPoints.getAdditionalFilterFiles, payload);
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      // const { data, meta } = ListData;
      yield put(Creators.getFilterListSuccess({ data, meta }));
    } else {
      throw response;
    }
  } catch (error) {
    // const { error: err } = sagaCatchBlockHandling(error);
    // yield put(Creators.getFilterListFailure(err));
    const { data, meta } = ListData;
    yield put(Creators.getFilterListSuccess({ data, meta }));
  }
}
