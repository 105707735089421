/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React from 'react';
// import useHover from '../../../../shared/hooks/useHover';
import { isEmpty } from '../../../../shared/utility/isEmpty';
// import { Container, DetailsContainer, FormContainer, HeaderText, LowOpacityText } from './Annotations.styles';
import './Annotations.css';
import editIcon from '../../../../assets/icons/KPV/Chart menu_edit pane.svg';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
// import AnnotationsModal from './AnnotationModal';
// import AddAnnotationsForm from './AddAnnotationsForm';
import { AnnotationDetailsContainer } from './Annotations.styles';
import { CHART_TYPE_CATEGORY } from '../../../../constants';

const AnnotationsDetails = ({ hoveredAnnotatedPoint, showAnnotations }) => {
  const { argument, seriesLabel, left, top, text, pointLabel, seriesKey, chartCategory } = hoveredAnnotatedPoint;
  return (
    // console.log('prop', point, 'activeAnnotationsDetails', activeAnnotationsDetails);
    // const { argument, text, seriesLabel } = activeAnnotationsDetails || {};
    <AnnotationDetailsContainer left={left} top={top}>
      <div className='DetailsContainer'>
        <span className='HeaderText' style={{ display: 'block', fontSize: '1.5rem' }}>
          {`${seriesKey && chartCategory === CHART_TYPE_CATEGORY.SPC ? `${seriesKey} => ` : ''} ${
            seriesLabel || 'header'
          }`}
        </span>
        {chartCategory === CHART_TYPE_CATEGORY.SPC && <span className='HeaderText'>{`${pointLabel}`}</span>}
        <span className='LowOpacityText' style={{ display: 'block' }}>
          {new Date(argument).toLocaleString()}
        </span>
        <div style={{ width: '100%', borderBottom: '1px solid #cbcbcf' }} />
        <div className='flex'>
          <span className='text-overflow HeaderText' style={{ display: 'block' }}>
            {text}
          </span>
          {/* <div
            onClick={() => {
              // setPointHovered({ point, activeAnnotationsDetails });
            }}
          >
            <DynamicSVGIcon iconUrl={editIcon} width='2.2rem' />
          </div> */}
        </div>
        {/* {showAnnotations && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DynamicSVGIcon iconUrl={editIcon} width='2.2rem' />
            <span style={{ fontSize: '1.6rem', marginLeft: '1rem' }}>click on the point to edit</span>
          </div>
        )} */}
      </div>
    </AnnotationDetailsContainer>
  );
};

export default AnnotationsDetails;
