/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import {
  Heading,
  SearchContainer,
  Footer,
  FilteredDataContainer,
  FilterHeader,
  HeaderLeft,
  HeaderRight,
  TagContainer,
  Tag,
  TagText,
  FilterContent
} from './FilterPopUp.styles';
import {
  ChartOptContainer,
  ChartTypeText,
  DropDownButton,
  DropDownContainer,
  DropdownImage,
  ItemText
} from '../../../ResultView/PlotOptionsModal/PlotOptionsModal.style';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { DropDownArrow } from '../../../../assets/icons';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/Filter/Filters.svg';
import { ReactComponent as SearchIconSvg } from '../../../../assets/icons/Filter/Search Black.svg';
import FilterSearchComponent from '../../../Filter/FilterSearchComponent/FilterSearchComponent';
import useClickOutside from '../../../../shared/hooks/useClickOutside';
import { Creators as FilterCreators } from '../../../../views/dashboard/FilterView/store';
import { AdditionalFilterCreators } from './store';
import {
  CommonButtonContainer,
  FilterButtom,
  FilterSearch,
  SearchButton
} from '../../../Filter/FilterSearchComponent/FilterSearchComponent.style';
import { BtnWrapper, Button } from '../../../Common/PopUpModal/Modal.style';
import FilterContainer from '../../../Filter/FilterContainer/FilterContainer';
import FilterResultCard from '../../../Filter/FilterResultCard/FilterResultCard';
import FilterView from '../../../../views/dashboard/FilterView/Filter.view';
import TabsTable from '../../../Common/Tabs_Table';
import { useActions } from '../../../../store/hooks/useActions';
import Loader from '../../../Loader/Loader';
import { generateSelectedFilter } from '../../../ResultView/Iframe/generateSelectedFilter';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { updateAllArgDomains } from '../../utils/updateAllArgDomains';
import { Creators as KPVChartsCreators } from '../../Store';
import { getPlotDataPayload } from '../../utils/getPlotDataPayload';

const FilterPopUp = ({
  setShowChartOpts,
  assetShortlist,
  setAssetShortlist,
  setFilteredData,
  filteredData,
  index,
  chartId
}) => {
  const [showCategory, setShowCategory] = useState(false);
  const [selectedCategory, SetSelectedCategory] = useState(null);
  const themeMode = useSelector((state) => state.configData.themeMode);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilterCard, setActiveFilterCard] = useState(0);
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { clearFilterData, setAssetAdditionalInfo } = useActions();
  const handleShortList = (e, isCheckRequired = true) => {
    const found = assetShortlist.some((el) => el.Key === e.Key);
    if (isCheckRequired) {
      if (found) {
        const index = assetShortlist.findIndex((ele) => ele.Key === e.Key);
        assetShortlist.splice(index, 1);
        setAssetShortlist([...assetShortlist]);
      } else {
        setAssetShortlist([...assetShortlist, e]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };

  useEffect(() => {
    const chartObj = { id: chartId, assetShortlist, selectedCategory };
    if (selectedCategory) {
      setAssetAdditionalInfo({ chartId, chartObj });
    }
  }, [assetShortlist]);

  const dispatch = useDispatch();
  const selectionTypeandPosition = {
    isCheckboxTypeSelection: true,
    isSelectionAtFirst: true
  };

  const addTabToObj = useCallback(
    (payload) => dispatch(FilterCreators.universalFilterSetter(payload)),
    [dispatch]
  );

  const listLoading = useSelector(
    (state) => state.additionalFilterDetails.listLoading
  );
  const {
    paneFullScreen,
    panesShortHandInfo,
    xMaxDomain,
    xMinDomain,
    comparisionMode,
    isAligned,
    latestAvailable
  } = useSelector((state) => state.KPVCharts);

  const filterDetails = useSelector(
    (state) => state.KPVCharts.additionalFilterData
  );
  const assetAdditionalInfo = useSelector((state) => state.KPVCharts.assetAdditionalInfo);
  const {
    loading,
    isDropDownActive,
    cumulativeTags,
    filterData,
    filterCategory
  } = useSelector((state) => state.filterData);

  const { listData, listMeta } = useSelector(
    (state) => state.additionalFilterDetails
  );

  const onNextHandler = () => {
    let payload;
    if (filterData) {
      payload = generateSelectedFilter(
        cumulativeTags,
        filterData
      );
    }
    payload.categoryOptions = selectedCategory;
    payload.RequestId = 'anything';
    payload.chartType = panesShortHandInfo[0]?.chartType;
    payload.category = panesShortHandInfo[0]?.category;
    payload.ChartTitle = panesShortHandInfo[0]?.chartTitle;
    payload.SubText = '';
    payload.StartTime = panesShortHandInfo[0]?.StartTime;
    payload.EndTime = panesShortHandInfo[0]?.EndTime;
    payload.FilterDetails = filterDetails[index];
    dispatch(AdditionalFilterCreators.getFilteredList(payload));
    setShowFilteredData(true);
  };

  useEffect(() => {
    dispatch(
      FilterCreators.getFilterData({
        directURL: apiEndPoints.getAdditionalCategories,
        type: 'tab',
        menuId: 2004,
        method: 'post',
        responseData: {
          RequestId: 'anything',
          chartType: panesShortHandInfo[0]?.chartType,
          category: panesShortHandInfo[0]?.category,
          ChartTitle: panesShortHandInfo[0]?.chartTitle,
          SubText: '',
          StartTime: panesShortHandInfo[0]?.StartTime,
          EndTime: panesShortHandInfo[0]?.EndTime,
          FilterDetails: filterDetails[index]
        }
      })
    );
  }, [showFilteredData]);

  const closeSearchDropDown = () => {
    if (isDropDownActive) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'isDropDownActive',
          value: false
        })
      );
    }
  };

  const getPlotDataForUpdatedDomain = async (listData) => {
    if (latestAvailable) {
      const machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo, { latestAvailable: 30 });
      await machinesWithNoPlotData?.forEach((machine, ind) => {
        const assetInfoMapped = Object.values(assetAdditionalInfo)?.filter((addInfo) => addInfo.id === panesShortHandInfo[ind].id);
        if (assetInfoMapped) {
          machine.additionalFilter = assetInfoMapped[0]?.assetShortlist;
          machine.categoryOptions = assetInfoMapped[0]?.selectedCategory;
        }
      });
      dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true, null, null, { latestAvailable: 30 }));
    } else {
      const machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo);
      await machinesWithNoPlotData?.forEach((machine, ind) => {
        const assetInfoMapped = Object.values(assetAdditionalInfo)?.filter((addInfo) => addInfo.id === panesShortHandInfo[ind].id);
        if (assetInfoMapped) {
          machine.additionalFilter = assetInfoMapped[0]?.assetShortlist;
          machine.categoryOptions = assetInfoMapped[0]?.selectedCategory;
        }
      });
      dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
    }
    dispatch(KPVChartsCreators.setFilterFilesSelected(assetShortlist));
  };

  const ref = useRef();
  useClickOutside(ref, closeSearchDropDown);

  return (
    <PopUpModal width='75%' closeModalHandler={setShowChartOpts} disableOutsideClick={true}>
      {!showFilteredData ? (
        <>
          <Heading>Category</Heading>
          <ChartOptContainer
            onClick={() => {
              setShowCategory(!showCategory);
            }}
          >
            <ChartTypeText className={showCategory ? 'active' : ''}>
              {selectedCategory?.label || 'Select Category'}
            </ChartTypeText>
            <DropDownButton>
              <DropdownImage className={showCategory ? 'active' : ''}>
                <DynamicSVGIcon iconUrl={DropDownArrow} width='2rem' />
              </DropdownImage>
            </DropDownButton>
            {showCategory && (
              <DropDownContainer top='4.5rem'>
                {!isEmpty(filterCategory) &&
                  Object.values(filterCategory).map((opt, index) => (
                    <ItemText onClick={() => SetSelectedCategory(opt)}>
                      {opt.label}
                    </ItemText>
                  ))}
              </DropDownContainer>
            )}
          </ChartOptContainer>
          <ChartOptContainer>
            <FilterSearchComponent
              placeHolder="Search For Time Range"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setActiveTab={setActiveFilterCard}
              isLabelChart={true}
            />
          </ChartOptContainer>
          {showFilter &&
            (loading ? (
              <Loader />
            ) : (
              <>
                <FilterContainer
                  activeFilterCard={activeFilterCard}
                  setActiveFilterCard={setActiveFilterCard}
                />
              </>
            ))}
          <Footer>
            <BtnWrapper style={{ width: '40%', alignItems: 'flex-end' }}>
              <Button
                type='button'
                onClick={() => {
                  setShowChartOpts(false);
                  clearFilterData();
                }}
              >
                Back
              </Button>
              <Button
                type='submit'
                className='dark'
                onClick={onNextHandler}
                disabled={!selectedCategory || isEmpty(cumulativeTags)}
              >
                Next
              </Button>
            </BtnWrapper>
          </Footer>
        </>
      ) : (
        <>
          <FilteredDataContainer>
            <FilterHeader>
              <HeaderLeft>
                <TagContainer>
                  <Tag themeMode={themeMode}>
                    <TagText themeMode={themeMode}>
                      {selectedCategory?.label}
                    </TagText>
                  </Tag>
                </TagContainer>
                <TagContainer>
                  {/* <Tag themeMode={themeMode}>
                    <TagText themeMode={themeMode}>25th June 2021</TagText>
                  </Tag> */}
                </TagContainer>
              </HeaderLeft>
              <HeaderRight>
                <BtnWrapper style={{ width: '100%', alignItems: 'flex-end' }}>
                  <Button
                    type='button'
                    onClick={() => {
                      // setShowChartOpts(false);
                      setShowFilteredData(false);
                      // clearFilterData();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type='submit'
                    className='dark'
                    disabled={listData?.length === 0}
                    onClick={() => {
                      setShowFilteredData(false);
                      setShowChartOpts(false);
                      setFilteredData(listData);
                      clearFilterData();
                      getPlotDataForUpdatedDomain(listData);
                    }}
                  >
                    Apply
                  </Button>
                </BtnWrapper>
              </HeaderRight>
            </FilterHeader>
            <FilterContent>
              Based on what you have Selected we found the following data.
              Please select the appropriate action for them accordingly :
            </FilterContent>
            <div>
              {listLoading ? (
                <Loader />
              ) : (
                <TabsTable
                  metaDetails={listMeta}
                  dataDetails={listData}
                  showPagiation={false}
                  showShortlistSelection={true}
                  handleShortList={handleShortList}
                  onItemSelect={() => { }}
                  assetShortlist={assetShortlist}
                  // handlePageChange={handlePageChange}
                  styleType='strapedTable'
                  noItemsFoundMessage='No Data Found'
                  showRowOptionAtStart={false}
                  id='UserID'
                  // handleRowOptionsItemClick={handleRowOptionsItemClick}
                  shortListSelectionDetails={selectionTypeandPosition}
                />
              )}
            </div>
          </FilteredDataContainer>
        </>
      )}
    </PopUpModal>
  );
};

export default FilterPopUp;
