/* eslint-disable react/no-array-index-key */

import React, { useState, useRef } from 'react';
import { SortIcon } from '../../../assets/icons';
import { Div } from '../../../globalStyles';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { Container, Button, DropContainer, ListItem } from '../OptionsModal/OptionsModal.styles';
import Radiobutton from '../RadioButton/RadioButton';

const Groupby = ({ list = ['None'], selected, onClick }) => {
  const [state, setState] = useState(false);
  const ref = useRef();

  useClickOutside(ref, () => setState(false));

  const handleSelection = (el) => {
    onClick(el);
    setState(false);
  };

  return (
    <Container>
      <Button onClick={() => setState(!state)}>
        <DynamicSVGIcon iconUrl={SortIcon} width='18px' />
      </Button>
      {state && (
        <DropContainer ref={ref}>
          <Div className='header'>Group By</Div>
          {list.map((el, key) => (
            <ListItem key={key} onClick={() => handleSelection(el)}>
              <Radiobutton onClick={handleSelection} active={selected === el} margin='0 1rem 0 0' />
              {el}
            </ListItem>
          ))}
        </DropContainer>
      )}
    </Container>
  );
};

export default Groupby;
