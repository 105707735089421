/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import {
  Modal,
  ModalContainer,
  Header,
  TabContainer,
  TabItem,
  ActiveNotificationCount,
  CancelContainer,
  HeaderContainer
} from './NotificationsModal.style';
import CancelIcon from '../../../assets/icons/Filter/cancel white.svg';
import { Creators as LayoutCreators } from '../../../views/Layout/store';
import NotificationItem from './NotificationItem';
import { alaramsData, approvalsData, cbmAlertsData, scheduleData } from '../../../constants/sampleData';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import SettingIcon from '../../../assets/icons/KPV/Chart menu_customize.svg';
// import { Creators as AlaramCreators } from '../../Alarams/Store';
// import { Creators as ApprovalCreators } from '../../Approvals/Store';
// import { Creators as ScheduleCreators } from '../../Schedule/Store';
// import Loader from '../../Common/Loader/Loader';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import config from '../../../config.json';
import TabsTable from '../../Common/Tabs_Table';
import { data, meta } from '../../Common/Tabs_Table/sampleData';
import { notficationsSample } from './sampleData';
// import Toast, { TOAST_TYPES } from '../../Common/Toast';

// const Alarams = () => {
//   const dispatch = useDispatch();
//   const handleClick = (data) => {
//     dispatch(LayoutCreators.toggleHeaderOption('notificationsModal'));
//     const { id, kpvId, machineName } = data;
//     const payload = {
//       machineId: id,
//       kpvId,
//       machineName,
//       chartType: 'dataChart',
//       category: 'lots'
//     };
//     dispatch(AlaramCreators.setDetailedAlaramData({ kpvDetails: [{ ...payload }] }));
//   };
// return (
//   <>
//     {alaramsData.map((item, index) => (
//       <NotificationItem key={index} data={item} handleClick={handleClick} />
//     ))}
//   </>
// );
// };

// const Approvals = () => {
//   const dispatch = useDispatch();
//   const handleClick = (data) => {
//     dispatch(LayoutCreators.toggleHeaderOption('notificationsModal'));
//     dispatch(ApprovalCreators.setApprovalsModal({ data, show: true }));
//   };

//   return (
//     <>
//       {approvalsData.map((item, index) => (
//         <NotificationItem key={index} data={item} handleClick={handleClick} />
//       ))}
//     </>
//   );
// };

// const CBMAlerts = () => (
//   <>
//     {cbmAlertsData.map((item, index) => (
//       <NotificationItem key={index} message={item.message} time={item.time} date={item.date} />
//     ))}
//   </>
// );

// const Schedule = () => {
//   const dispatch = useDispatch();

//   const handleClick = (data) => {
//     dispatch(LayoutCreators.toggleHeaderOption('notificationsModal'));
//     dispatch(ScheduleCreators.setSchedulesModal({ data, show: true }));
//   };

//   return (
//     <>
//       {scheduleData.map((item, index) => (
//         <NotificationItem key={index} data={item} handleClick={handleClick} />
//       ))}
//     </>
//   );
// };

const NotificationsList = ({ activeTab }) => {
  const userData = useSelector((state) => state.loginData.userData);
  const showNotificationTabs = useSelector((state) => state.home.showNotificationTabs);
  const { Name, NotificationID } = activeTab || {};
  const [listDetails, setListDetails] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      WebService.get(apiEndPoints.getNotifications, {
        params: { UserID: userData.userId, tab: Name } // userData.userId
      }).then((res) => {
        const newShowNotificationTabs = showNotificationTabs.reduce(
          (acc, tab) => [...acc, { ...tab, Unread: tab.Name === Name ? 0 : tab.Unread }],
          []
        );
        dispatch(LayoutCreators.getNotificationsCountSuccess({ data: newShowNotificationTabs }));
        const { data } = res?.data?.data?.[NotificationID];
        const { meta } = notficationsSample;
        setListDetails({ data, meta });
      });
    } catch (error) {
      const e = sagaCatchBlockHandling(error);
      console.log('notifications error', e);
    }
  }, [activeTab]);
  const handleClick = () => {};
  return (
    <>
      {/* {list?.map((item, index) => (
        <NotificationItem key={index} data={item} handleClick={handleClick} />
      ))} */}
      <TabsTable
        metaDetails={listDetails.meta}
        dataDetails={listDetails.data}
        assetListLoading={false}
        showPagiation={false}
        showShortlistSelection={false}
        handleTabSelect={() => {}}
        onItemSelect={() => {}}
      />
      {/* <Toast header={TOAST_TYPES.ERROR}  message={}/> */}
    </>
  );
};

const NotificationsModal = (props) => {
  const notificationModalRef = useRef();
  const dispatch = useDispatch();
  const { notificationsModal, showNotificationTabs: showNtfTabs, notificationsCountLoading, userData } = props;
  const showNotificationTabs = config.hide_notifications ? [] : showNtfTabs;
  const closeModal = () => {
    if (notificationsModal) dispatch(LayoutCreators.toggleHeaderOption('notificationsModal'));
  };
  const [activeTab, setActiveTab] =
    useState(showNotificationTabs.filter((tab) => !(tab.isVisible === false))?.[0]) || {};

  useClickOutside(notificationModalRef, closeModal);
  const handleSettings = () => {
    dispatch(LayoutCreators.toggleHeaderOption('notificationsModal'));
    dispatch(LayoutCreators.settingsActiveMenu('Notification'));
  };

  return (
    <Modal>
      <CancelContainer onClick={closeModal}>
        <DynamicSVGIcon iconUrl={CancelIcon} />
      </CancelContainer>
      <ModalContainer
        style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}
        ref={notificationModalRef}
      >
        <HeaderContainer>
          <Header>Notification</Header>
          <div onClick={handleSettings}>
            <DynamicSVGIcon iconUrl={SettingIcon} width='3rem' />
          </div>
        </HeaderContainer>
        <TabContainer>
          {showNotificationTabs
            .filter((tab) => !(tab.isVisible === false))
            .map((tab) => (
              <TabItem
                key={tab.NotificationID}
                className={activeTab.NotificationID === tab.NotificationID && 'active'}
                onClick={() => setActiveTab(tab)}
              >
                {tab.Unread > 0 && <ActiveNotificationCount>{tab.Unread}</ActiveNotificationCount>}
                {tab.Name}
              </TabItem>
            ))}
        </TabContainer>
        {!config.hide_notifications ? <NotificationsList activeTab={activeTab} /> : <></>}
        {/* {(() => {
          switch (activeTab) {
            case 'Alarms':
              return <Alarams />;
            case 'Approvals':
              return <Approvals />;
            case 'Schedule':
              return <Schedule />;
            case 'CBM alerts':
              return <CBMAlerts />;
            default:
              return <></>;
          }
        })()} */}
      </ModalContainer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  notificationsModal: state.home.notificationsModal,
  showNotificationTabs: state.home.showNotificationTabs,
  notificationsCountLoading: state.home.notificationsCountLoading,
  userData: state.loginData.userData
});
export default connect(mapStateToProps)(NotificationsModal);
