/* eslint-disable max-len */
import React from 'react';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
// eslint-disable-next-line import/no-named-as-default
import ToolTip from '../../Common/Tooltip/Tooltip.view';
import { OptBtn, OptIcon } from './KPVCharting.style';

export default function KPVControllerIcon({ onClick, classDefined, rotate, iconUrl, name, style }) {
  return (
    <OptBtn className={classDefined} style={style} onClick={onClick}>
      <OptIcon rotate={rotate} disable={true}>
        <DynamicSVGIcon iconUrl={iconUrl} width='2.5rem' />
      </OptIcon>
      {/* <ToolTip name={name} /> */}
    </OptBtn>
  );
}
