/* eslint-disable max-len */
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import { EditorContainer } from './Editor.style';

const Editor = (props) => {
  const { value: editorHtml, name, onChange: setEditorHtml } = props;

  return (
    <>
      <EditorContainer>
        <ReactQuill onChange={setEditorHtml} value={editorHtml} modules={Editor.modules} formats={Editor.formats} />
      </EditorContainer>
    </>
  );
};

Editor.modules = {
  toolbar: [['bold', 'italic', 'underline', 'strike', 'size'], [{ color: [] }]],
  clipboard: {
    matchVisual: false
  }
};

Editor.formats = [
  'header',
  'fonts',
  'size',
  'color',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'html',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'code-block',
  'emoji'
];

export default Editor;
