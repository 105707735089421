/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import StatusValueWidget from '../../../../views/Widgets/WidgetComponents/StatusValueWidget.jsx/StatusValueWidget.view';
import AddStatusValue from './UpserStatusValueWidgetForm';

const UpsertStatusValueWidget = () => {
  // const { statusEquipmentList, statuscategory } = useSelector((state) => state.upsertWidgetData);
  // const status = useSelector((state) => state.upsertWidgetData);
  // console.log(status, 'status');

  // comes after save click
  const { equipments, widgetType, meta, data = [] } = useSelector((state) => state.upsertWidgetData);

  return (
    <div style={{ width: '51%' }}>
      {/* input form as modal */}
      {/* <AddStatusValue /> */}
      {/* actual widget */}

      <StatusValueWidget isUpsert={true} widgetType={widgetType} equipments={data} meta={meta} />
    </div>
  );
};

export default UpsertStatusValueWidget;
