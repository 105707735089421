/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPopper } from '@popperjs/core';
import backArrow from '../../../assets/icons/layout/BackArrow.svg';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Radiobutton from '../RadioButton/RadioButton';
import NoResultSvg from '../../../assets/icons/layout/No_results_on_Dashboard.svg';
import { FlexContainer } from '../../../globalStyles';
import {
  DropdownLabelContainer,
  Image,
  LevelContainer,
  ModalContainer,
  ModalContentDiv,
  ModalContentText,
  NestedModalContainer
} from './DropDownModal.style';
import Loader from '../Loader/Loader';
import { isEmpty } from '../../../shared/utility/isEmpty';
import CheckBox from '../../CheckBox/CheckBox';
import { DropDownArrow } from '../../../assets/icons';

function DropDownModalV3(props) {
  const {
    ContainerStyle = {},
    TextStyle = {},
    data = [],
    itemContainerStyle = {},
    setModalIsVisibal,
    kababIconRef = false,
    handleClick,
    version = 1,
    isActive,
    field = '',
    extraParms, // additional params required to pass during click
    headerLabel, // dropdown header label
    activeItems, // list of active selected items // arrary or object
    showSelection, // show radio icon indication
    isActiveChecker = ({ activeItems, item }) => activeItems?.some((activeItem) => activeItem.value === item.value),
    label = 'label', // label key which needs to used
    iconUrl = 'iconUrl', // icon for each item ? optional
    width, // required for nesting
    singleSelection, // closes the dropdown on selection if true
    itemKey,
    noContainerPadding = false, // main container padding
    isNested,
    parentDetails,
    extendLeft = true,
    itemIcons,
    extraMenu, // component which needs to be shown other than menu data(like zoom)
    overrideMainContainerWidth = false,
    top
  } = props;
  const modalReff = useRef();
  const secondaryRef = useRef();
  const [modalRef, setModalRef] = useState(null);
  const [boxRef, setBoxRef] = useState(null); // default reference if refernce prop is defined
  createPopper(boxRef, modalRef, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0]
        }
      }
    ]
  });
  const { localeStrings } = useSelector((state) => state.localeStrings);
  useClickOutside(
    modalReff,
    () => {
      if (!field) {
        return setModalIsVisibal(false);
      }
      return setModalIsVisibal(field);
    },
    secondaryRef
  );
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const handleSelection = (item) => {
    const { subMenu, label, value, handler } = item;
    // nested dropdown handlers
    // START
    // if submenu is present then expand the modal
    if (subMenu) {
      return setActiveSubMenu(value);
    }
    // if submenu is not present and parentDetails are present
    if (parentDetails) {
      return handleClick(item, parentDetails, { label, activeRowItem: extraParms?.activeRowItem, itemKey });
    }
    // END

    // general handlers
    if (singleSelection) {
      setModalIsVisibal(false);
    }
    if (!field) {
      return handleClick(item, { label, activeRowItem: extraParms?.activeRowItem, itemKey });
    }
    return handleClick(field, item);
  };

  const header = (
    <>
      {headerLabel && (
        <DropdownLabelContainer ref={kababIconRef} onClick={() => setModalIsVisibal((prev) => !prev)}>
          <span style={{ textTransform: 'capitalize' }}>{headerLabel}</span>
          <DynamicSVGIcon rotate={isActive ? 90 : 270} width='2.3rem' iconUrl={backArrow} />
        </DropdownLabelContainer>
      )}
    </>
  );
  // undefined to handle prev developed code
  // if (!isActive && isActive !== undefined) {
  //   return <>{header}</>;
  // }

  const ItemIcon = ({ item, type }) => {
    const isActive = isActiveChecker({ activeItems, item, parentDetails });
    return (
      <>
        {type === 'checkbox' ? (
          <CheckBox isActive={isActive} handleChange={() => handleSelection(item)} />
        ) : type === 'radio' ? (
          <Radiobutton
            // margin='10px 0px 0px 10px'
            onClick={() => handleSelection(item)}
            active={isActive}
          />
        ) : (<></>)}
      </>
    );
  };
  const left = extendLeft ? '-20rem' : '20rem';
  return (
    <>
      <div
        style={{ width: !overrideMainContainerWidth ? width : '', position: 'absolute' }}
        ref={isNested ? secondaryRef : null}
      >
        <div ref={isNested ? null : setBoxRef}>{props.children}</div>
        {header}
        {(isActive || isActive === undefined) && true && (
          <ModalContainer
            // isActive
            isActive={isActive}
            className={`${version === '1' ? '' : 'outside'} ${isNested ? 'hasNested' : ''}`}
            ref={isNested ? null : setModalRef}
            style={ContainerStyle}
            width={width}
            noContainerPadding={noContainerPadding}
          >
            <NestedModalContainer ref={isNested ? null : modalReff}>
              {/* {loader && <Loader />} */}
              <LevelContainer className='paddingTop' width={width}>
                {extraMenu}
              </LevelContainer>
              {data.length > 0 ? (
                data?.map((item, i) => {
                  const { subMenu, ...coreItemDetails } = item;
                  const { type } = coreItemDetails;
                  const nestedDropdown = activeSubMenu === item.value && (
                    <DropDownModalV3
                      parentDetails={coreItemDetails} // this item acts as parent for the next recurred component
                      ContainerStyle={{ position: 'absolute', right: '2px', top, }}
                      data={item.subMenu}
                      handleClick={handleClick}
                      // singleSelection
                      width={width}
                      isNested={isNested}
                      activeSubMenu={activeSubMenu}
                      noContainerPadding
                      setModalIsVisibal={setModalIsVisibal}
                      activeItems={activeItems}
                      isActiveChecker={isActiveChecker}
                      showSelection={true}
                      extendLeft={extendLeft}
                      itemIcons={itemIcons}
                      extraParms={extraParms}
                    />
                  );
                  const itemIconSvg = itemIcons?.[item.value] || item?.[iconUrl];
                  const isSelectionIconFirst = !itemIconSvg && true;
                  const SelectionIcon = showSelection && !item.subMenu && (
                    <div className={(isSelectionIconFirst ? 'ml' : '') + ' selection_icon testpppp '}>
                      <ItemIcon item={item} type={parentDetails?.type} />
                    </div>
                  );
                  const nextIcon = item.subMenu && (
                    <div className='next_icon'>
                      <DynamicSVGIcon iconUrl={DropDownArrow} alt='' />
                    </div>
                  );
                  const isIconAtLast = (showSelection && !item.subMenu && !item[iconUrl]) || !!nextIcon;
                  return (
                    <FlexContainer style={{ position: 'relative' }}>
                      {nestedDropdown}
                      <LevelContainer width={width}>
                        <ModalContentDiv
                          //   className={`list ${item.class || ''} ${!isEmpty(activeItems) && !item.subMenu ? 'hasRadio' : ''} ${showSelection && !item.subMenu ? 'radio' : ''} ${item[iconUrl] ? 'icon' : ''}`}
                          className={
                            (item.class || '') +
                            ' ' +
                            (activeItems ? 'hasRadio' : '') +
                            ' ' +
                            (showSelection ? 'radio' : '') +
                            ' ' +
                            (item[iconUrl] ? 'icon' : '') +
                            'list'
                          }
                          style={itemContainerStyle}
                          key={i}
                          onClick={() => handleSelection(item)}
                        >
                          <ModalContentText
                            className={
                              (version === '1' ? '' : 'outside') +
                              ' ai_center ' +
                              (isIconAtLast ? 'jc_spacebetween ' : ' ') +
                              (item.disable ? 'disable' : '')
                            }
                            // {`${version === '1' ? '' : 'outside'} ${item.disable ? 'disable' : ''}`}
                            style={TextStyle}
                          >
                            <FlexContainer className='ai_center'>
                              {itemIconSvg ? (
                                <div className='iconSvg'>
                                  <DynamicSVGIcon width='2.3rem' iconUrl={itemIconSvg} />
                                </div>
                              ) : (
                                SelectionIcon
                              )}
                              {localeStrings[item[label]] || item.title || item[label]}
                            </FlexContainer>
                            {itemIconSvg && SelectionIcon}
                            {nextIcon}
                          </ModalContentText>
                        </ModalContentDiv>
                      </LevelContainer>
                    </FlexContainer>
                  );
                })
              ) : (
                <FlexContainer className='ai_center'>
                  <Image src={NoResultSvg}>
                    <DynamicSVGIcon iconUrl={NoResultSvg} width='3rem' />
                  </Image>
                  No items found
                </FlexContainer>
              )}
            </NestedModalContainer>
          </ModalContainer>
        )}
      </div>
    </>
  );
}

export default DropDownModalV3;
