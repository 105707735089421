/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ceil } from 'lodash';
import { ForwardNavArrow } from '../../../assets/icons/KPV';
import Calendar from '../../Calendar/Calendar';
import { Creators as KPVChartsCreators } from '../Store';
import {
  OptIcon,
  //   DurationContainer,
  DurationDropdown,
  ItemText,
  Divider,
  RangeContainer,
  Input,
  Label,
  BtnWrapper,
  Button,
  WrapperDiv,
  CalenderDiv
} from './DurationDropdown.style';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { validateNonEmptyStrings, VALIDATION_TYPES } from '../../../shared/utility/regex';
import { getPlotDataPayload } from '../utils/getPlotDataPayload';
// import { updateTime } from '../../../shared/utility/moment';
import { removeAllArgDomains, updateAllArgDomains } from '../utils/updateAllArgDomains';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { graphFormatLocale } from '../../../config/index';
import { useActions } from '../../../store/hooks/useActions';

const DurationDropdownComp = ({ closeHandler }) => {
  const [rangeDropDown, setRangeDropDown] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startDateDropDown, setStartDateDropDown] = useState(false);
  const [endDate, setEndDate] = useState();
  const [endDateDropDown, setEndDateDropDown] = useState(false);
  const { paneFullScreen, panesShortHandInfo, xMaxDomain, xMinDomain, comparisionMode, isAligned } = useSelector(
    (state) => state.KPVCharts
  );
  // const panesData = useSelector((state) => state.KPVCharts.panesData);
  const dispatch = useDispatch();
  const { resetAssetAdditionalInfo } = useActions();

  const menu = [
    { id: 1, label: '5 minutes', value: { shorthand: 'm', shorthandValue: 5 } },
    { id: 2, label: '15 minutes', value: { shorthand: 'm', shorthandValue: 15 } },
    { id: 3, label: '30 minutes', value: { shorthand: 'm', shorthandValue: 30 } },
    { id: 4, label: '1 hour', value: { shorthand: 'h', shorthandValue: 1 } },
    { id: 5, label: '3 hours', value: { shorthand: 'h', shorthandValue: 3 } },
    { id: 6, label: '6 hours', value: { shorthand: 'h', shorthandValue: 6 } },
    { id: 7, label: '12 hours', value: { shorthand: 'h', shorthandValue: 12 } },
    { id: 8, label: '24 hours', value: { shorthand: 'h', shorthandValue: 24 } }
  ];

  const handleStartDate = (date) => {
    setStartDate(date.format('DD-MM-YYYY -HH:mm'));
    setStartDateDropDown(false);
  };
  const handleEndDate = (date) => {
    setEndDate(date.format('DD-MM-YYYY -HH:mm'));
    setEndDateDropDown(false);
  };

  const errMsgs = {
    rangeErr: 'Please select range less than 30 Days',
    formatErr: 'Invalid input, Please enter To greater than From'
  };

  const [customRangeInp, setCustomRangeInp] = useState({
    from: moment(new Date().setHours(0, 0, 0, 0)).format(graphFormatLocale),
    to: moment(new Date()).format(graphFormatLocale)
  });
  // console.log('customRangeInp', customRangeInp);
  const [isValid, setIsValid] = useState(true);
  const [errMsg, setErrMsg] = useState(errMsgs.formatErr);

  const validateRange = () =>
    customRangeInp.to && customRangeInp.from && new Date(customRangeInp.from) <= new Date(customRangeInp.to);
  // (validateNonEmptyStrings(customRangeInp.from.trim(), VALIDATION_TYPES.DATE_WITH_TIME) ||
  //   validateNonEmptyStrings(customRangeInp.from.trim(), VALIDATION_TYPES.DATE_WITH_TIME_WITH_SECONDS)) &&
  //   (validateNonEmptyStrings(customRangeInp.to.trim(), VALIDATION_TYPES.DATE_WITH_TIME) ||
  //     validateNonEmptyStrings(customRangeInp.to.trim(), VALIDATION_TYPES.DATE_WITH_TIME_WITH_SECONDS));

  const handleCustomInput = (e, key) => {
    setIsValid(true);
    const { name, value } = e.target;
    setCustomRangeInp({ ...customRangeInp, [key]: value });
  };

  const setDuration = (domainObj) => dispatch(KPVChartsCreators.setDomainRange({ ...domainObj }));

  const getPlotDataForUpdatedDomain = (xMin, xMax) => {
    let machinesWithNoPlotData;
    if (comparisionMode) {
      const panesShortHandInfoTemp = updateAllArgDomains(panesShortHandInfo, xMin, xMax, null, true);
      machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfoTemp, null, null, true);
      dispatch(KPVChartsCreators.genericKPVPropertySetter({ key: 'isAligned', value: false }));
    } else {
      machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo, xMin, xMax);
    }
    // isUpdate = true
    dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
  };

  const getPlotDataForAvailableData = () => {
    let machinesWithNoPlotData;
    if (comparisionMode) {
      const panesShortHandInfoTemp = removeAllArgDomains(panesShortHandInfo);
      machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfoTemp, null, null, true, { latestAvailable: 30 });
      dispatch(KPVChartsCreators.genericKPVPropertySetter({ key: 'isAligned', value: false }));
    } else {
      machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo, null, null, null, { latestAvailable: 30 });
    }
    setDuration({ latestAvailable: 30 });
    // isUpdate = true
    dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true, null, null, { latestAvailable: 30 }));
  };

  const handleDurationSelect = (value) => {
    resetAssetAdditionalInfo();
    const { shorthand, shorthandValue } = value;
    // it should be now
    // const xMaxDomain = Date.now();
    // const dataPoints = panesData[0].data[0];
    // const maxValue = dataPoints[dataPoints.length - 1].end || dataPoints[dataPoints.length - 1].time;
    closeHandler();
    if (!paneFullScreen) {
      // duration based on xMaxDomain
      const domainObj = {
        xMaxDomain: new Date(),
        xMinDomain: new Date(moment().subtract(shorthandValue, shorthand)),
        xDomainDuration: value
      };
      // take EndTime in first pane as xMaxDomain
      if (comparisionMode && !isEmpty(panesShortHandInfo)) {
        const { EndTime: orgEndTime } = panesShortHandInfo[0];
        const EndTime = orgEndTime || xMaxDomain;
        domainObj.xMaxDomain = new Date();
        domainObj.xMinDomain = new Date(moment().subtract(shorthandValue, shorthand));
      }
      getPlotDataForUpdatedDomain(domainObj.xMinDomain, domainObj.xMaxDomain);
      setDuration(domainObj);
    } else {
      // based on xMaxDomain
      const domainObj = {
        xMaxDomainFullScreen: new Date(),
        xMinDomainFullScreen: new Date(moment().subtract(shorthandValue, shorthand))
      };

      // const domainObj = {
      //   xMaxDomainFullScreen: new Date(),
      //   xMinDomainFullScreen: new Date(moment().subtract(shorthandValue, shorthand))
      // };

      // console.log('do', domainObj, maxValue, dataPoints, dataPoints[dataPoints.length - 1]);
      dispatch(KPVChartsCreators.setDomainRangeInFullScreen({ ...domainObj }));
    }
  };

  const handleLatestAvailableData = () => {
    closeHandler();
    resetAssetAdditionalInfo();
    if (!paneFullScreen) {
      getPlotDataForAvailableData();
    } else {
      const domainObj = {
        xMaxDomainFullScreen: xMaxDomain,
        xMinDomainFullScreen: xMinDomain
      };
      dispatch(KPVChartsCreators.setDomainRangeInFullScreen({ ...domainObj }));
    }
  };

  const handleSubmit = () => {
    const isValidInput = validateRange();
    // old
    // const hourDiff = moment(customRangeInp.to, 'DD-MM-YYYY HH:mm').diff(
    //   moment(customRangeInp.from, 'DD-MM-YYYY HH:mm'),
    //   'hours',
    //   true
    // );
    const hourDiff =
      Math.abs(new Date(customRangeInp.to).getTime() - new Date(customRangeInp.from).getTime()) / 3600000;
    // console.log('hourDiff', hourDiff);
    const rangeRestrictCheck = ceil(hourDiff) <= 30 * 24;
    if (customRangeInp.to && customRangeInp.from && isValidInput && rangeRestrictCheck) {
      closeHandler();
      const domainObj = {
        xMaxDomain: new Date(customRangeInp.to),
        xMinDomain: new Date(customRangeInp.from),
        xDomainDuration: 'custom'
      };
      // console.log('good to go', customRangeInp, domainObj, customRangeInp.to);
      getPlotDataForUpdatedDomain(domainObj.xMinDomain, domainObj.xMaxDomain);
      setDuration(domainObj);
    } else {
      if (!isValidInput) {
        setErrMsg(errMsgs.formatErr);
      } else if (!rangeRestrictCheck) {
        setErrMsg(errMsgs.rangeErr);
      }
      setIsValid(false);
    }
  };
  // console.log('errMsg', errMsg);
  const timeRange = [
    {
      key: 1,
      label: 'From',
      name: 'from',
      value: customRangeInp.from,
      placeholder: 'DD-MM-YYYY HH:MM(:SS) Start Date',
      dropDown: startDateDropDown,
      dropDownHandler: handleCustomInput,
      handler: handleStartDate
    },
    {
      key: 2,
      label: 'To',
      name: 'to',
      value: customRangeInp.to,
      placeholder: 'DD-MM-YYYY HH:MM(:SS) End Date',
      dropDown: endDateDropDown,
      dropDownHandler: handleCustomInput,
      handler: handleEndDate
    }
  ];

  return (
    <DurationDropdown>
      <div>Select time range</div>
      <ItemText onClick={() => handleLatestAvailableData()}>Latest 30 Minutes</ItemText>
      {menu.map((item) => (
        <ItemText key={item.id} onClick={() => handleDurationSelect(item.value)}>{`Last ${item.label}`}</ItemText>
      ))}
      <Divider />
      <div style={{ position: 'relative' }}>
        <ItemText onClick={() => setRangeDropDown(!rangeDropDown)}>
          Custom Time Range
          <OptIcon width={1.6} src={ForwardNavArrow}>
            <DynamicSVGIcon iconUrl={ForwardNavArrow} width='1.6rem' />
          </OptIcon>
        </ItemText>
        {rangeDropDown && (
          <RangeContainer>
            <div>Enter the range</div>
            {timeRange.map((e) => (
              <WrapperDiv key={e.id}>
                <Label>{e.label}</Label>
                <Input
                  type='datetime-local'
                  step={1}
                  // readOnly
                  // onClick={() => e.dropDownHandler(true)}
                  onChange={(event) => e.dropDownHandler(event, e.name)}
                  value={e.value}
                  name={e.name}
                  placeholder={e.placeholder}
                />
                {/* {e.dropDown && (
                  <CalenderDiv>
                    <Calendar general={true} setDate={e.handler} />
                  </CalenderDiv>
                )} */}
              </WrapperDiv>
            ))}
            {!isValid && (
              <span className='red' style={{ fontSize: '1.8rem' }}>
                {errMsg}
              </span>
            )}
            <BtnWrapper>
              <Button type='button' onClick={closeHandler}>
                cancel
              </Button>
              <Button type='submit' onClick={handleSubmit} className='dark'>
                save
              </Button>
            </BtnWrapper>
          </RangeContainer>
        )}
      </div>
    </DurationDropdown>
  );
};

export default DurationDropdownComp;
