import { createActions } from 'reduxsauce';

export const { Creators, Types } = createActions({
  setMenuIdOfWidget: ['payload'],
  setWidgetPageType: ['payload'],
  // get widget panels/tabs list
  getWidgetTabs: ['payload'],
  getWidgetTabsStart: ['payload'],
  getWidgetTabsSuccess: ['payload'],
  getWidgetTabsFailure: ['payload'],
  addWidgetPanelSuccess: ['payload'],

  // to keep Edit open
  setEditWidget: ['payload'],
  setHeaderHide: ['payload'],
  setBorderHide: ['payload'],
  setEnablePivot: ['payload'],
  updateWidgetsPanelPayloadSave: ['payload'],
  updateWidgetPayload: ['payload'],
  updateTableConfig: ['payload'],
  resetWidgetPanelPayload: ['payload'],
  setLayoutComponent: ['payload'],
  setLayoutComponentUpdated: ['payload'],
  setIsPreviewInstance: ['payload'],
  setConfigureTemplate: ['payload'],
  setEquipmentSelect: ['payload'],
  // to close Edit
  setDisableEditWidget: ['payload'],
  // get widget layout
  getWidgetsLayout: ['payload'],
  getPreviewLayout: ['payload'],
  getPreviewTemplate: ['payload'],
  getWidgetsLayoutStart: ['payload'],
  getWidgetsLayoutSuccess: ['payload'],
  getWidgetsLayoutError: ['payload'],
  getPreviewLayoutSuccess: ['payload'],

  // update widget layout
  updateWidgetsLayout: ['payload'],
  updateWidgetsLayoutStart: ['payload'],
  updateWidgetsLayoutSuccess: ['payload'],
  updateLayoutOfBreakpoint: ['payload'],
  updateWidgetsLayoutError: ['payload'],
  resetWidgetsLayout: ['payload'],

  // delete widget layout
  deleteWidgetsLayout: ['payload'],
  deleteWidgetsLayoutStart: ['payload'],
  deleteWidgetsLayoutSuccess: ['payload'],
  deleteWidgetsLayoutError: ['payload'],

  // widget types list
  getWidgetTypes: ['payload'],
  getWidgetTypesStart: ['payload'],
  getWidgetTypesSuccess: ['payload'],
  getWidgetTypesFailure: ['payload'],

  // setBreakPoint: ['payload'],
  widgetDetailSetter: ['payload'],
  zoomLevelSetter: ['payload'],
  setWidgetActiveTab: ['payload'],
  setNewTab: ['payload'],
  renameTab: ['payload'],
  setWidget: ['payload'],
  deleteTab: ['payload'],
  addNewWidget: ['payload'],
  addNewComponentData: ['payload'],
  setBulkWidgetDetails: ['payload'],
  resetActiveTab: ['payload'],

  // widget types list
  getKpvList: ['payload'],
  getKpvListStart: ['payload'],
  getKpvListSuccess: ['payload'],
  getKpvListError: ['payload'],

  setSelectedKpvs: ['payload'],
  setKpvConfigured: ['payload'],

  setSearchQueriesWidgets: ['payload'],
});
