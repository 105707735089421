/* eslint-disable max-len */
import React from 'react';
import { FlexContainer } from '../../../../globalStyles';
// import useClickOutside from '../../../../shared/hooks/useClickOutside';
import ButtonComp from '../../../Common/Button/Button.view';
import { Container, DescriptionTextBox, FormContainer, HeaderText, LowOpacityText } from './Annotations.styles';

const AnnotationModal = ({
  argument,
  seriesLabel,
  closeHanlder,
  annotDesc,
  setAnnotDesc,
  handleSave,
  handleDelete,
  isEdit
}) => (
  <FormContainer>
    <FlexContainer className='jc_spacebetween'>
      <HeaderText>{`${isEdit ? 'Edit' : 'Add'} Annotation`}</HeaderText>
      <LowOpacityText>{new Date(argument).toLocaleString()}</LowOpacityText>
    </FlexContainer>
    <HeaderText style={{ fontSize: '1.5rem', display: 'block', paddingTop: '1rem' }}>{seriesLabel}</HeaderText>
    <DescriptionTextBox
      rows={4}
      onChange={(e) => setAnnotDesc(e.target.value)}
      value={annotDesc}
      placeholder='Description'
    />
    <FlexContainer style={{ flex: 1, justifyContent: 'right', marginTop: '1.5rem' }}>
      <ButtonComp onClick={closeHanlder}>cancel</ButtonComp>
      {isEdit && (
        <ButtonComp style={{ marginLeft: '10px' }} onClick={handleDelete}>
          delete
        </ButtonComp>
      )}
      <ButtonComp style={{ marginLeft: '10px' }} dark onClick={handleSave}>
        save
      </ButtonComp>
    </FlexContainer>
  </FormContainer>
);

export default AnnotationModal;
