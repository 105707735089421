/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import { Container, ItemsContainer, SidePanel, ContentContainer } from './SettingsModal.style';

import { Creators as LayoutCreators } from '../../../views/Layout/store';
import cancelIcon from '../../../assets/icons/Filter/cancel black.svg';
import General from './General/General.view';
import Accounts from './Accounts/Account.view';
import Notifications from './Notifications/Notifications.view';
import ItemComponent from '../../Common/MenuItem/Item.view';
import { NotificationLight, assigned, profileSettings, settingsBlack } from '../../../assets/icons';

const Item = ({ name, icon }) => {
  const settingsModalActiveTab = useSelector((state) => state.home.settingsModalActiveTab);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(LayoutCreators.settingsActiveMenu(name));
  };

  return <ItemComponent name={name} icon={icon} handleClick={handleClick} activeMenu={settingsModalActiveTab} />;
};

const SettingsModal = () => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(LayoutCreators.settingsActiveMenu(''));
  };
  const settingsModalActiveTab = useSelector((state) => state.home.settingsModalActiveTab);
  const userData = useSelector((state) => state.loginData.userData);

  return (
    <PopUpModal width='110rem' style={{ padding: '0rem' }} closeModalHandler={closeModal}>
      <Container>
        <SidePanel>
          <div className='header'>Settings</div>
          <ItemsContainer>
            <Item name='General' icon={settingsBlack} />
            <Item name='Accounts' icon={profileSettings} />
            <Item name='Notification' icon={NotificationLight} />
            <Item name='Assigned Group /Equipments' icon={assigned} />
          </ItemsContainer>
          <div className='footer capitalize'>{userData.profileName}</div>
        </SidePanel>
        <ContentContainer>
          <div onClick={closeModal} className='pointer'>
            <DynamicSVGIcon iconUrl={cancelIcon} />
          </div>
          {(() => {
            switch (settingsModalActiveTab) {
              case 'General':
                return <General />;
              case 'Accounts':
                return <Accounts />;
              case 'Notification':
                return <Notifications />;
              default:
                return <></>;
            }
          })()}
        </ContentContainer>
      </Container>
    </PopUpModal>
  );
};

export default SettingsModal;
