import React from 'react';

const EquipmentPlan = (props) => {
  const { componentData, id } = props;
  const { widgetName, infoText } = componentData || {};
  return (
    <div>
      <span>{`${id}: ${widgetName}`}</span>
    </div>
  );
};

export default EquipmentPlan;
