/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily } from '../../../constants';
import { FlexContainer, Button } from '../../../globalStyles';
// import { Button, FlexContainer } from '../../globalStyles';

export const Container = styled(FlexContainer)`
  width: 40rem;
  height: 30rem;
  background: #f4f4f8;
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  margin: 2rem;
`;
export const Text = styled.div`
  margin-left: 10px;
  font: 3rem ${fontFamily.circularBold};
`;

export const WDBtn = styled(Button)`
  opacity: 0.2;
  &:hover {
    /* &::after {
      content: 'add a widget';
      width: 100px;
      height: 30px;
      background: black;
      color: white; */

    &::before {
      content: 'Comming soon';
      border-right: ${({ theme, border }) => (border ? `1px dashed ${theme.aux.darkSecondary}` : 0)};
      position: absolute;
      top: 0px;
      background: black;
      color: white;
      padding: 1rem;
    }
    /* } */
  }
`;
