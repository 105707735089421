/* eslint-disable indent */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { Creators as KPVChartsCreators } from '../../Store';
import getNearestPoints from '../../utils/getNearestPoints';
import { Graph, GraphContainer, WrapperConatiner } from '../../PaneCard/PaneCards.style';
import AnnotationsDetails from '../../PaneCard/Annotations/AnnotationsDetails';
import EditAnnotationsForm from '../../PaneCard/Annotations/EditAnnotationForm';
import AddAnnotationsForm from '../../PaneCard/Annotations/AddAnnotationsForm';
import DataChartView from './DataChart.view';
import LegendComp from '../../Legend/Legend.view';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../../constants';

const DataGraphComponent = ({
  isLastPane,
  padding,
  position = 'right',
  isFullScreen,
  fillLayout,
  ...extraPaneData
}) => {
  // const paneData = {...rawPaneData }
  const paneData = extraPaneData;
  const {
    showDetails,
    labelsData,
    pointInfoObj,
    xMinDomainProp,
    xMaxDomainProp,
    multiGrid,
    id,
    rawPlotData,
    annotations,
    paneIndex,
    chartType,
    chartTypeCategory,
    xMinDomainForFiltered,
    xMaxDomainForFiltered,
    // isAddAnnotationEnabled
  } = paneData;
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  const pointClicked = useSelector((state) => state.KPVCharts.pointClicked);
  const globalHoveredState = useSelector((state) => state.KPVCharts.globalHoveredState);
  const comparisionMode = useSelector((state) => state.KPVCharts.comparisionMode);
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);

  const index = 0;
  const dispatch = useDispatch();
  // hovered state is manual trigger for compress view
  const [hoveredState, setHoveredState] = useState([]);
  const [hoveredPosState, setHoveredPosState] = useState();
  // global hovered state is only one, the exact point where the  is hovered
  const setGlobalHoveredState = useCallback(
    (value) => {
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'globalHoveredState',
          value: { index: !isEmpty(value) ? paneIndex : -1, ...value }
        })
      );
      setHoveredState(value);
    },
    [dispatch]
  );
  // const [pointClicked, setPointClicked] = useState({});
  const setPointClicked = useCallback(
    (value) =>
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'pointClicked',
          value: { ...value, index: !isEmpty(value) ? paneIndex : -1 }
          // value: { ...value, index: paneIndex }
        })
      ),
    [dispatch]
  );
  const { data: plotData, meta } = rawPlotData || {};
  // if (!paneData?.rawPlotData) return <Loader />;

  // moved hover code to outside the graph component
  const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const compressedView = useSelector((state) => state.KPVCharts.compressedView);

  // format it in the desired format and set to hoveredState
  const computeHoveredState = (argument) => {
    const data = getNearestPoints(plotData, meta, argument);
    setHoveredState({ data: [data], x: argument });
  };

  useEffect(() => {
    // console.log('hoveredXValue', hoveredXValue);
    if (compressedView && hoveredXValue) {
      computeHoveredState(hoveredXValue.argument);
    } else if (hoveredState?.x?.getTime() !== hoveredXValue?.argument?.getTime()) {
      // setHoveredState({});
    }
  }, [hoveredXValue]);

  const checkAnnotations = (arg, xPos, yPos) => {
    if (!arg) return false;
    return {
      ...annotations?.find((argPoint) => moment(arg).isSame(argPoint.argument)),
      left: xPos,
      top: yPos
    };
  };
  const getComparisionTimestamp = () => {
    const labels = meta && Object.values(meta);
    return (labels && labels[0] && [labels[0]])?.map((item) => {
      const itemKey = item.fields?.key + '-SensorData';
      if (!isEmpty(hoveredPosState) && hoveredPosState[itemKey]) {
        const val = moment(hoveredPosState[itemKey]?.arg).format('DD/MM/YYYY HH:mm:ss:SSS');
        return val;
      }
      return 'NA';
    });
  };
  const xCompLabel = useMemo(() => getComparisionTimestamp(), [meta, hoveredPosState]);

  const hoveredAnnotatedPoint = checkAnnotations(globalHoveredState.x, globalHoveredState.left, globalHoveredState.top);
  const clickedAnnotatedPoint = checkAnnotations(pointClicked.argument, pointClicked.x, pointClicked.y);
  // eslint-disable-next-line no-nested-ternary
  const height = multiGrid ? (meta ? `${Object.keys(meta).length * 16}rem` : '40rem') : '25rem';
  return (
    <GraphContainer
      style={{
        flexDirection: 'column',
        height: fillLayout ? '100%' : 'auto'
      }}
    >
      <WrapperConatiner
        height={height}
        multiGrid={multiGrid}
        position={position}
        key={id}
        style={{ display: 'flex', width: '100%' }}
        fillLayout={fillLayout}
      >
        <Graph
          className='singlePane'
          height={25}
          width={(showDetails ? showDetails?.legends : true || isFullScreen) ? '75%' : '100%'}
          paddingLeft={padding}
          // isCursorPointer={isAddAnnotationEnabled}
        >
          {/* comparision mode vertical line */}
          {comparisionMode && isAligned && !isEmpty(globalHoveredState) && (
            <div
              style={{
                position: 'absolute',
                left: globalHoveredState?.left + 10,
                height,
                width: '3px',
                zIndex: '10',
                background: 'black'
              }}
            >
              {xCompLabel ? (
                <div
                  style={{
                    fontSize: '1.8rem',
                    position: 'absolute',
                    bottom: '0rem',
                    background: 'black',
                    color: '#fff',
                    padding: '0.5rem'
                  }}
                >
                  {xCompLabel}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          {showDetails.annotations && (
            <>
              {/* annotations point details */}
              {globalHoveredState.index === paneIndex &&
                hoveredAnnotatedPoint.argument &&
                !clickedAnnotatedPoint?.argument && (
                  <AnnotationsDetails
                    hoveredAnnotatedPoint={hoveredAnnotatedPoint}
                    showAnnotations={showDetails.annotations}
                  />
                )}
              {/* edit/add annotations modal */}
              {pointClicked.index === paneIndex && (
                <>
                  {clickedAnnotatedPoint?.argument ? (
                    <EditAnnotationsForm
                      chartType={chartType || CHART_TYPES.DATA_CHART}
                      chartTypeCategory={chartTypeCategory || CHART_TYPE_CATEGORY.DATA_CHART}
                      index={paneIndex}
                      pointClicked={pointClicked}
                      setPointClicked={setPointClicked}
                      meta={meta}
                      annotatedPoint={clickedAnnotatedPoint}
                    />
                  ) : (
                    <AddAnnotationsForm
                      index={paneIndex}
                      chartType={chartType || CHART_TYPES.DATA_CHART}
                      chartTypeCategory={chartTypeCategory || CHART_TYPE_CATEGORY.DATA_CHART}
                      pointClicked={pointClicked}
                      setPointClicked={setPointClicked}
                      meta={meta}
                    />
                  )}
                </>
              )}
            </>
          )}

          <DataChartView
            {...paneData}
            fillLayout={fillLayout}
            paddingLeft={padding}
            isLastPane={isLastPane}
            setHoveredState={setGlobalHoveredState}
            setHoveredPosState={setHoveredPosState}
            setPointClicked={setPointClicked}
            xPos={comparisionMode && isAligned ? globalHoveredState.orgX : 0}
            xMinDomainForFiltered={xMinDomainForFiltered}
            xMaxDomainForFiltered={xMaxDomainForFiltered}
            // hoveredState={hoveredState}
          />
        </Graph>

        <LegendComp
          showLegends={showDetails ? showDetails?.legends : true}
          data={labelsData?.[index]}
          labels={meta && Object.values(meta)}
          pointData={pointInfoObj?.length > 0 && pointInfoObj[index]}
          color={index > 0 ? colorCodes[index] : undefined}
          position={position}
          hoveredState={hoveredState?.data}
          hoveredPosState={hoveredPosState}
        />
      </WrapperConatiner>
    </GraphContainer>
  );
};

export default memo(DataGraphComponent);
