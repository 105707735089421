import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  myDashboards: [],
  dashboardDetails: {},

  instanceData: [],
  instanceMeta: {},
  totalInstances: null,
};

const getInstanceDashboardStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getInstanceDashboardSuccess = (state = INITIAL_STATE, action) => {
  const { instanceData, instanceMeta } = action.payload;

  return {
    ...state,
    instanceData,
    instanceMeta,
    totalInstances: instanceMeta?.Count,
    loading: false
  };
};

const getInstanceDashboardFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

const updateInstanceDescriptionStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const updateInstanceDescriptionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false
});

const getDashboardsSuccess = (state = INITIAL_STATE, action) => {
  const { myDashboards } = action.payload;

  return {
    ...state,
    myDashboards,
    loading: false
  };
};

const getDashboardsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const getDashboardsStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const setDashboardDetails = (state = INITIAL_STATE, action) => {
  const { isInit = false, form } = action.payload;
  // console.log('form', form);
  return {
    ...state,
    dashboardDetails: !isInit ? { ...state.dashboardDetails, ...form } : form
  };
};

const resetDashboardsData = (state = INITIAL_STATE, action) => ({
  ...state,
  dashboardDetails: INITIAL_STATE.dashboardDetails
});

const HANDLERS = {
  [Types.GET_DASHBOARDS_START]: getDashboardsStart,
  [Types.GET_DASHBOARDS_SUCCESS]: getDashboardsSuccess,
  [Types.GET_DASHBOARDS_FAILURE]: getDashboardsFailure,
  [Types.SET_DASHBOARD_DETAILS]: setDashboardDetails,
  [Types.RESET_DASHBOARDS_DATA]: resetDashboardsData,

  [Types.GET_INSTANCE_DASHBOARD_START]: getInstanceDashboardStart,
  [Types.GET_INSTANCE_DASHBOARD_SUCCESS]: getInstanceDashboardSuccess,
  [Types.GET_INSTANCE_DASHBOARD_FAILURE]: getInstanceDashboardFailure,

  [Types.UPDATE_INSTANCE_DESCRIPTION_START]: updateInstanceDescriptionStart,
  [Types.UPDATE_INSTANCE_DESCRIPTION_SUCCESS]: updateInstanceDescriptionSuccess,
};

export const dashboardReducer = createReducer(INITIAL_STATE, HANDLERS);
