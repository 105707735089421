/* eslint-disable max-len */
import React, { forwardRef, useRef, useState } from 'react';
import AddWidgetForm from '../../WidgetForm/AddWidgetForm';
import { CancelIcon, ModalHeader, HeaderContainer } from '../../WidgetForm/AddWidgetForm.style';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { CancelBlack } from '../../../../assets/icons';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import WidgetFooter from '../../WidgetFooter/WidgetFooter.view';
import { useActions } from '../../../../store/hooks/useActions';
import { genericFormValidation } from '../../../Widget/utils/formValidation';
import { getFieldDependents } from '../../WidgetForm/Utility';

const formStructure = [
  {
    label: 'Title',
    placholder: 'Add title for pin',
    isRequired: true,
    inputType: 'text',
    key: 'text'
  },
  {
    label: 'Add Equipment',
    inputType: 'equipmentSearch',
    placholder: 'Equipment',
    key: 'equipments',
    dataSource: {
      dataKey: 'data',
      source: 'equipmentsUrl',
      data: [{ label: 'CPA04X1' }, { label: 'CPA04X1' }, { label: 'CPA04X1' }]
    },
    isRequired: true
  },
  {
    inputType: 'checkboxes',
    isRequired: true,
    key: 'checkboxes',
    dataSource: {
      dataKey: 'data',
      data: [
        { label: 'Show Equipment State', value: 'showEquipmentState' },
        {
          label: 'Show KPV Details & Values',
          value: 'showKpvDetails',
          hasLink: true,
          linkDetails: {
            linkLabel: 'Add',
            dataSource: {
              dataKey: 'source',
              source: 'getKpvsUrl',
              params: {
                menuid: 'menuid',
                data: 'equipmentsJson'
              }
            }
          }
        }
      ]
    }
  }
];
const AddFloorPlanWidget = () => {
  const [showModal, setShowModal] = useState(true);
  const [formInput, setFormInput] = useState({});
  const { valueSetter } = useActions();
  const hideModal = () => {
    setShowModal(false);
  };
  const AddWidgetFormRef = useRef();
  const handleSave = () => {
    const localFormInput = AddWidgetFormRef.current.getFormInput();
    setFormInput(localFormInput);
    valueSetter({});
    hideModal();
  };

  return (
    <div>
      {showModal && (
        <PopUpModal width='92rem' height='48rem' closeModalHandler={hideModal} className='status_value_widget'>
          <CancelIcon
            onClick={() => {
              setShowModal(false);
            }}
          >
            <DynamicSVGIcon iconUrl={CancelBlack} width='1.8rem' />
          </CancelIcon>
          <HeaderContainer>
            <ModalHeader>Add Pin</ModalHeader>
          </HeaderContainer>
          <AddWidgetForm
            ref={AddWidgetFormRef}
            formInput={formInput}
            setFormInput={setFormInput}
            data={formStructure}
            showFooter
            hideModal={hideModal}
            handleSave={handleSave}
            formStructureFieldDepenents={getFieldDependents(formStructure)}
          />
        </PopUpModal>
      )}
    </div>
  );
};
export default forwardRef(AddFloorPlanWidget);
