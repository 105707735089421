import { Types as ConfigTypes, Creators as ConfigCreators } from '../config/action';

import { Types as LoginTypes, Creators as LoginCreators } from '../views/auth/store/action';
import { Types as localStringsTypes, Creators as localStringsCreators } from '../localeStrings';
import { Types as FilterTypes, Creators as FilterCreators } from '../views/dashboard/FilterView/store';
import { Types as HomeTypes, Creators as HomeCreators } from '../views/Layout/store';
import { Types as FilterResultTypes, Creators as FilterResultCreators } from '../component/ResultView/Store';
import { Types as KPVChartsTypes, Creators as KPVChartsCreators } from '../component/KPVCharts/Store';
import { Types as UpsertPaneTypes, Creators as UpsertPaneCreators } from '../component/UpsertPane/Store';
import { Types as dashboardTypes, Creators as dashboardCreators } from '../views/MyDashboards/store';
import { Types as myAnalyticsTypes, myAnalyticsCreators } from '../views/MyAnalytics/store';
import { Types as alaramTypes, Creators as alaramCreators } from '../component/Alarams/Store';
import { WidgetCreators, WidgetTypes } from '../views/Widgets/store';
import { UpsertWidgetCreators, UpsertWidgetTypes } from '../component/UpsertWidget/Store';
import { UsersCreators, UsersTypes } from '../views/Users/store';
import { Types as AdditionalFilterType, AdditionalFilterCreators } from '../component/KPVCharts/PaneCard/AdditionalFilter/store';
import { Types as objectTreeDataType, Creators as objectTreeDataCreators } from '../component/WidgetForm/Store';
import { ExportTypes, ExportCreators } from '../views/ExportDashboard/store';
import { HomeDashboardCreators, HomeDashboardTypes } from '../views/Home/store';

const Types = {
  ...ConfigTypes,
  ...LoginTypes,
  ...FilterTypes,
  ...HomeTypes,
  ...localStringsTypes,
  ...FilterResultTypes,
  ...KPVChartsTypes,
  ...UpsertPaneTypes,
  ...dashboardTypes,
  ...myAnalyticsTypes,
  ...alaramTypes,
  ...WidgetTypes,
  ...UpsertWidgetTypes,
  ...UsersTypes,
  ...AdditionalFilterType,
  ...objectTreeDataType,
  ...ExportTypes,
  ...HomeDashboardTypes
};

const Creators = {
  ...ConfigCreators,
  ...LoginCreators,
  ...FilterCreators,
  ...HomeCreators,
  ...localStringsCreators,
  ...FilterResultCreators,
  ...KPVChartsCreators,
  ...UpsertPaneCreators,
  ...dashboardCreators,
  ...myAnalyticsCreators,
  ...alaramCreators,
  ...WidgetCreators,
  ...UpsertWidgetCreators,
  ...UsersCreators,
  ...AdditionalFilterCreators,
  ...objectTreeDataCreators,
  ...ExportCreators,
  ...HomeDashboardCreators
};

export { Types, Creators };
