/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { debounce } from 'lodash';
import { useMemo, useState, useEffect } from 'react';

const defaultState = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

const useMeasure = (props) => {
  const { shouldDebounce = true } = props || {};
  const [element, ref] = useState(null);
  const [rect, setRect] = useState(defaultState);

  const debouncedSetRect = debounce(({ x, y, width, height, top, left, bottom, right }) => {
    setRect({ x, y, width, height, top, left, bottom, right });
  }, 500);
  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
          if (shouldDebounce) {
            // console.log('debounced', width);
            debouncedSetRect(entries[0].contentRect);
          } else {
            setRect({ x, y, width, height, top, left, bottom, right });
          }
        }
      }),
    []
  );

  useEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
};

export default useMeasure;
