/* eslint-disable max-len */
export const kpvData = {
  data: [
    {
      KPV: 'Date and Time',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: '01/23/23 11:49:44',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 1,
      KPVID: 'KPV_P5_1_1',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Lot ID',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: '20230123-W2351_re',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 2,
      KPVID: 'KPV_P5_1_2',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Process',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: 'Box_opened',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 3,
      KPVID: 'KPV_P5_1_3',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'System',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: 'na',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 4,
      KPVID: 'KPV_P5_1_4',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Operator ID',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: 'Aleks',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 5,
      KPVID: 'KPV_P5_1_5',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Sequence Name',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: 'Si_8inch_Siegert',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 6,
      KPVID: 'KPV_P5_1_6',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Recipe Name',
      L1: '',
      LastTSValue: '2023-01-23 11:49:44.000',
      Value: 'Si_8in_Siegert',
      ColorCode: '',
      ShowChart: true,
      ChartType: '',
      SPID: 1,
      SensorIndex: 7,
      KPVID: 'KPV_P5_1_7',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'true',
      LotChart: 'false',
      RecipeChart: 'true',
      TurboStatusChart: 'true',
      E10ModuleStateChart: 'false',
      DataChart: 'false'
    },
    {
      KPV: 'Wafer Total',
      L1: '',
      LastTSValue: '2023-01-23 11:36:53.000',
      Value: '244',
      ColorCode: '',
      DataType: 'Register',
      ShowChart: true,
      ChartType: 'Numeric Plot',
      SPID: 1,
      SensorIndex: 8,
      KPVID: 'KPV_P5_1_8',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    },
    {
      KPV: 'Failure',
      L1: '',
      LastTSValue: '2023-01-23 11:36:53.000',
      Value: 'NONE',
      ColorCode: '',
      ShowChart: false,
      ChartType: '',
      SPID: 1,
      SensorIndex: 9,
      KPVID: 'KPV_P5_1_9',
      MachineId: 100,
      KPVGroupName: 'KPIV',
      MachineName: 'F1M04',
      GroupName: 'KLA Candela',
      Process: 'DE',
      Module: 'FactoryLOOK',
      Plant: 'IQM',
      PlantID: 'P5',
      TransitionChart: 'false',
      LotChart: 'false',
      RecipeChart: 'false',
      TurboStatusChart: 'false',
      E10ModuleStateChart: 'false',
      DataChart: 'true'
    }
  ],
  meta: {
    fields: [
      {
        key: 'KPV',
        title: 'KPV Name (UoM)',
        visible: true,
        searchable: true
      },
      { key: 'L1', title: 'Limits', visible: true, searchable: false },
      {
        key: 'LastTSValue',
        title: 'Time Stamp',
        visible: true,
        searchable: false
      },
      { key: 'Value', title: 'Value', visible: true, searchable: false },
      {
        key: 'ColorCode',
        title: 'ColorCode',
        visible: false,
        searchable: false
      },
      {
        key: 'DataType',
        title: 'DataType',
        visible: true,
        searchable: false
      },
      {
        key: 'ShowChart',
        title: 'ShowChart',
        visible: false,
        searchable: false
      },
      { key: 'SPID', title: 'SPID', visible: false, searchable: false },
      {
        key: 'SensorIndex',
        title: 'SensorIndex',
        visible: false,
        searchable: false
      },
      { key: 'KPVID', title: 'KPVID', visible: false, searchable: false },
      {
        key: 'MachineId',
        title: 'MachineId',
        visible: false,
        searchable: false
      },
      {
        key: 'KPVGroupName',
        title: 'KPV GroupName',
        visible: true,
        searchable: true
      },
      {
        key: 'MachineName',
        title: 'Machine Name',
        visible: true,
        searchable: true
      },
      {
        key: 'GroupName',
        title: 'GroupName',
        visible: false,
        searchable: false
      },
      {
        key: 'Process',
        title: 'Process',
        visible: false,
        searchable: false
      },
      {
        key: 'FunctionalUnit',
        title: 'Module',
        visible: false,
        searchable: false
      },
      { key: 'Plant', title: 'Plant', visible: true, searchable: false },
      {
        key: 'PlantID',
        title: 'PlantID',
        visible: false,
        searchable: false
      },
      { key: 'SPC', title: 'SPC', visible: false, searchable: true },
      {
        key: 'RunChart',
        title: 'Run Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'XBarRChart',
        title: 'XBar R Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'XBarSChart',
        title: 'XBar S Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'IMRChart',
        title: 'IMR Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'TransitionChart',
        title: 'State Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'DataChart',
        title: 'Data Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'TransitionChart',
        title: 'Lot Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'TransitionChart',
        title: 'Recipe Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'TransitionChart',
        title: 'Turbo Status Chart',
        visible: false,
        searchable: false
      },
      {
        key: 'TransitionChart',
        title: 'E10 Module State Chart',
        visible: false,
        searchable: false
      }
    ],
    collapsible: ['L1', 'GroupName', 'Process', 'Module', 'Plant', 'SPC', 'TransitionChart'],
    grouping: ['KPVGroupName', 'DataType'],
    colorField: ['ColorCode'],
    fieldsToColor: ['Value', 'LastTSValue'],
    chartable: 'ShowChart',
    UID: 'KPVID',
    GridOrder: 'grid'
  },
  enabledPanes: [
    { key: 'DataChart', name: 'Data Chart', type: 'DataChart' },
    { key: 'RunChart', name: 'Run Chart', type: 'SPC' },
    { key: 'XBarRChart', name: 'XBarR Chart', type: 'SPC' },
    { key: 'XBarSChart', name: 'XBarS Chart', type: 'SPC' },
    { key: 'IMRChart', name: 'IMR Chart', type: 'SPC' },
    { key: 'LotChart', name: 'Lot Chart', type: 'TransitionChart' },
    { key: 'RecipeChart', name: 'Recipe Chart', type: 'TransitionChart' },
    {
      key: 'TurboStatusChart',
      name: 'Turbo Status Chart',
      type: 'TransitionChart'
    },
    {
      key: 'E10ModuleStateChart',
      name: 'E10 Module State Chart',
      type: 'TransitionChart'
    }
  ]
};
