/* eslint-disable react/no-array-index-key */

import React, { useState, useRef, useEffect } from 'react';
import { SortIcon } from '../../../assets/icons';
import { Div } from '../../../globalStyles';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Radiobutton from '../RadioButton/RadioButton';
import { Button, Container, DropContainer, ListItem } from './OptionsModal.styles';

const OptionsModal = ({
  list = ['A to Z', 'Z to A'],
  heading, // header of the modal
  clearOption,
  onClick, // option handler
  additionalDetails, // additional details like row
  width = '20rem'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const ref = useRef();
  const { columDetails } = additionalDetails;
  useClickOutside(ref, () => setIsVisible(false));

  const handleSelection = (el) => {
    const optionSelected = el === selectedOption ? '' : el;
    setIsVisible(false);
    setSelectedOption(optionSelected);
    onClick(optionSelected, columDetails);
  };

  useEffect(() => {
    if (clearOption) {
      setSelectedOption(null);
    }
  }, [clearOption]);

  return (
    <Container>
      <Button onClick={() => setIsVisible(!isVisible)}>
        <DynamicSVGIcon iconUrl={SortIcon} width='18px' />
      </Button>
      {isVisible && (
        <DropContainer style={{ width }} ref={ref}>
          <Div className='header'>{heading || 'Sort By'}</Div>
          {list.map((el, key) => (
            <ListItem key={key} onClick={() => handleSelection(el)}>
              <Radiobutton
                // onClick={handleSelection}
                onClick={() => {}}
                active={el === selectedOption}
                margin='0 1rem 0 0'
              />
              {el}
            </ListItem>
          ))}
        </DropContainer>
      )}
    </Container>
  );
};

export default OptionsModal;
