import React from 'react';
import { Button } from '../../views/MyDashboards/MyDashboards.style';
import DynamicSVGIcon from './DynamicSVGIcon';
import { SortIcon as iconUrl } from '../../assets/icons';

const SortIcon = ({ isActive = false }) => (
  // <div style={{ transform: 'rotate(90deg)', marginLeft: '0.6rem' }}>
  //   <DynamicSVGIcon iconUrl={DropDownArrow} />
  <Button isActive={isActive}>
    <DynamicSVGIcon iconUrl={iconUrl} width='18px' />
  </Button>
  // </div>
);

export default SortIcon;
