/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useMemo, useState } from 'react';
import { ArgSpan, Argument, Div } from './LabelTicks.style';

const LabelTemplate = ({ label, meta, gridDetails, isRotated, gridKeys, isLabelRotated, chartType }) => {
  const { valueText } = label;
  const { labelMapping } = meta || {};
  const [rotate, setRotate] = useState(false);
  const { width, height } = useMemo(() => {
    const length = Object.values(gridKeys || {})?.filter((ele) => ele === true)?.length;
    return { width: Math.max(length * 60 + 0, 120), height: Math.max(length * 25 + 0, 100) };
  }, [gridKeys]);
  const isFirstLabel = valueText === Object.keys(gridDetails || {})?.[0];
  useEffect(() => {
    if (!isLabelRotated) {
      if (valueText.length > 4) {
        setRotate(true);
      }
    }
  }, [isLabelRotated]);

  const getByDisplayValue = (value) => {
    if (chartType === 'fullstackedbar') {
      let sum = 0;
      // eslint-disable-next-line array-callback-return
      Object.values(gridDetails[label?.valueText])?.map((val) => {
        sum += val;
      });
      const valueText = ((value / sum) * 100).toFixed(2);
      return valueText;
    }
    return value;
  };

  return (
    <>
      <svg overflow='visible'>
        {isRotated ? (
          <foreignObject height='30' width={width} overflow='visible'>
            <span className='fs' style={{ display: 'flex', paddingTop: '8px', justifyContent: 'flex-end' }}>
              {Object.entries(gridKeys || {})
                .reverse()
                ?.map((entry, index) => {
                  const [e, isEnabled] = entry;
                  if (!isEnabled) return <></>;
                  return (
                    <span
                      style={{
                        backgroundColor: 'transparent',
                        // backgroundColor: index === Object.entries(gridKeys || {}).length - 1 ? 'yellow' : 'transparent',
                        borderRadius: '3px',
                        textAlign: 'right',
                        marginLeft: '8px',
                        overflow: 'visible',
                        fontWeight: '600'
                      }}
                    >
                      <Div style={{ width: '40px' }}>
                        {isFirstLabel ? (
                          <>
                            <div
                              style={{
                                position: 'relative',
                                fontSize: '1.4rem',
                                top: '1rem',
                                left: chartType !== 'fullstackedbar' && '50px'
                                // borderTop: '3px solid #EEEFF5'
                              }}
                            >
                              {gridDetails?.[valueText]?.[e] && getByDisplayValue(gridDetails?.[valueText]?.[e])}
                            </div>
                            <div
                              style={{
                                position: 'relative',
                                fontSize: '1.4rem',
                                top: '3rem',
                                left: chartType !== 'fullstackedbar' && '50px'
                                // borderTop: '3px solid #EEEFF5'
                              }}
                            >
                              {labelMapping?.[e]}
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              position: 'relative',
                              fontSize: '1.4rem',
                              top: '1rem',
                              left: '50px'
                              // borderTop: '3px solid #EEEFF5'
                            }}
                          >
                            {
                              gridDetails?.[valueText]?.[e] && getByDisplayValue(gridDetails?.[valueText]?.[e])
                            }
                          </div>
                        )}
                      </Div>
                    </span>
                  );
                })}
              <ArgSpan className='fs arg' rotate={rotate}>{valueText}</ArgSpan>
            </span>
          </foreignObject>
        ) : (
          <foreignObject height={height} width='40' overflow='visible'>
            <Argument className='fs arg' rotate={rotate}>{valueText}</Argument>
            {Object.entries(gridKeys || [])?.map((entry, index) => {
              const [e, isEnabled] = entry;
              if (!isEnabled) return <></>;
              return (
                <>
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      // backgroundColor: index === 0 ? 'yellow' : 'transparent',
                      textAlign: 'center',
                      marginTop: '10px',
                      borderRadius: '3px',
                      overflow: 'visible',
                      fontWeight: '600'
                    }}
                  >
                    <Div className='fs'>
                      {isFirstLabel ? (
                        <span
                          className='fs'
                          style={{
                            position: 'relative',
                            display: 'flex',
                            right: '-2rem'
                          }}
                        >
                          {/* TODO:- handle larger label or more words */}
                          <div
                            className='fs'
                            style={{
                              position: 'absolute',
                              right: '6rem',
                              top: '1px'
                              // background: index === 0 && 'yellow'
                              // background: index === 0 && 'yellow'
                              // textOverflow: 'ellipsis',
                              // width: '32px',
                              // overflow: 'hidden',
                              // whiteSpace: 'nowrap',
                              // wordWrap: 'break-word'
                            }}
                          >
                            {labelMapping?.[e]}
                          </div>
                          {/* {gridDetails?.[valueText]?.[e]} */}
                          {gridDetails?.[valueText]?.[e] && getByDisplayValue(gridDetails?.[valueText]?.[e])}
                        </span>
                      ) : (
                        gridDetails?.[valueText]?.[e] && getByDisplayValue(gridDetails?.[valueText]?.[e])
                      )}
                    </Div>
                  </div>
                </>
              );
            })}
          </foreignObject>
        )}
      </svg>
    </>
  );
};
export default LabelTemplate;
