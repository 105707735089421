import styled from 'styled-components';
import { fontFamily } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';

export const UserDetails = styled(FlexContainer)`
  color: ${({ theme }) => theme.contrast.primary};
  font-size: 1.4rem;
  font-family: ${fontFamily.circularBook};
  margin-bottom: 1.2rem;
  align-items: center;

  & > div {
    margin-right: 1rem;
  }
`;

export const UserName = styled.div`
  color: ${({ theme }) => theme.contrast.primary};
  font-size: 1.8rem;
  font-family: ${fontFamily.circularMedium};
  margin-bottom: 1.2rem;
`;

export const AvatarIconContainer = styled.div`
  margin-right: 1.5rem;
  & span {
    font-size: 2.5rem;
  }
`;
