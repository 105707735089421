/* eslint-disable max-len */
// import { calenderTypes } from '../component/RangeSelectors/DateRangePicker/constants';

export { colors } from './colors';
export { fontFamily, fontWeight, fontSize } from './font';
export { device, minDevice } from './device';

export const PAGE_TYPES = {
  CARD: 'Card',
  FILTER: 'Filter',
  MY_PAGE: 'My Page',
  MY_DASHBOARDS: 'myDashboards',
  MY_ANAYLTICS: 'myAnalytics',
  DIRECT_IFRAME: 'directIframe',
  WIDGET: 'Widget',
  USERS: 'Users',
  INSTANCE_DASHBOARD: 'instanceDashboards',
  WIDGET_INSTANCE: 'Widget_Instance'
};

export const URLS_TO_MARK_DEFAULT = [
  '/home/kpv-charting/:id',
  '/home/analytics/myanalytics',
  '/home/dashboards/mydashboards',
  '/home/widgets/InstanceDashboards',
];

// graph constants
export const CHART_TYPES = {
  DATA_CHART: 'dataChart',
  TRANSITION_CHART: 'transitionChart',

  // SPC CHART TYPES
  RUN_CHART: 'runChart',
  X_BAR_R_CHART: 'xBarRChart',
  X_Bar_S_Chart: 'xBarSChart',
  IMR_Chart: 'IMRChart'
};

// graph label constants
export const maxCharactersAloowed = 12;
export const placeHolderSize = 12;

// major category of charts
export const CHART_TYPE_CATEGORY = {
  DATA_CHART: 'dataChart',
  TRANSITION_CHART: 'transitionChart',
  SPC: 'SPC',
  LABEL: 'labelChart',
  XY_CHART: 'xyChart'
};

export const CHART_TYPE_CATEGORY_SUB = {
  LABEL_SERIES: 'label',
  TIME_SERIES: 'timeSeries',
};

// must define, api depends on this category
export const CHART_CATEGORIES = {
  KPV: 'KPV',
  STATE: 'state', // transition charts
  LOTS: 'lots',
  RECIPE: 'recipe',
  STATUS: 'status',
  SPC: 'SPC',
  LABEL: 'label',
  XY: 'xyChart'
};

export const PANE_TYPES = {
  SINGLE_PANE: 'Single Pane',
  DIFFERENT_PANE: 'Different Pane'
};

export const chartCategories = {
  [CHART_TYPES.DATA_CHART]: [{ label: 'KPV', value: CHART_CATEGORIES.KPV }],
  [CHART_TYPES.TRANSITION_CHART]: [
    { label: 'state', value: CHART_CATEGORIES.STATE }
    // { label: 'lots', value: CHART_CATEGORIES.LOTS },
    // { label: 'recipe', value: CHART_CATEGORIES.RECIPE },
    // { label: 'status', value: CHART_CATEGORIES.STATUS }
  ]
};

// external-internal mapping
export const chartLableMapping = {
  DataChart: CHART_TYPES.DATA_CHART,
  TransitionChart: CHART_TYPES.TRANSITION_CHART,
  RunChart: CHART_TYPES.RUN_CHART,
  XBarRChart: CHART_TYPES.X_BAR_R_CHART,
  XBarSChart: CHART_TYPES.X_Bar_S_Chart,
  IMRChart: CHART_TYPES.IMR_Chart
};
// exteranl internal terminology mapping
// don't need to define this if both are same/new typeCategories, but to support exisiting chart types
export const chartCategoryMapping = {
  DataChart: CHART_TYPE_CATEGORY.DATA_CHART,
  TransitionChart: CHART_TYPE_CATEGORY.TRANSITION_CHART,
  SPC: CHART_TYPE_CATEGORY.SPC
};

// value to read from api response
// if there are differences in internal and external terminolory use chartCategoryMapping
export const resolveChartCategory = {
  [chartCategoryMapping.DataChart]: CHART_CATEGORIES.KPV,
  [chartCategoryMapping.TransitionChart]: CHART_CATEGORIES.STATE,
  [chartCategoryMapping.SPC]: CHART_CATEGORIES.SPC,
  [CHART_TYPE_CATEGORY.LABEL]: CHART_CATEGORIES.LABEL,
  [CHART_TYPE_CATEGORY.XY_CHART]: CHART_CATEGORIES.XY
};

export const CHARTS_WITH_NO_SINGLE_PANE_SUPPORT = [CHART_TYPE_CATEGORY.SPC];

// CONFIG constants
export const LANGUAGES = {
  ENGLISH: 'en',
  TEST: 'Test'
};

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const FILTER_TERMS = {
  SINGLE_SELECT: 'singleselect'
};

export const DASH_STYLE_MAPPING = {
  SOLID: 'solid',
  DOT: 'dot',
  LONG_DASH: 'longDash',
  DASH: 'dash'
};

export const WIDGET_CATEGORIES = {
  FLOOR_PLAN: 'FLOOR_PLAN',
  EQUIPMENT_PLAN: 'EQUIPMENT_PLAN',
  OEE_DONUT: 'OEE_DONUT',
  ANALYTICS: 'ANALYTICS',
  STATUS_VALUE: 'STATUS_VALUE',
  DETAILED_STATUS_VALUE: 'DETAILED_STATUS_VALUE',
  GAUGE_CHART: 'GAUGE',
  BAR_CHART: 'BAR_CHART',
  SVG: 'SVG',
  TEXT_HTML: 'TEXT_HTML',
  TABLE: 'TABLE',
  PIVOT_TABLE: 'PIVOT_TABLE',
  TEXT_HTML_WIDGET: 'TEXT_HTML_WIDGET',
  GAUGE_CONCENTRIC_WIDGET: 'GAUGE_CONCENTRIC_WIDGET'
};

export const WIDGET_CATEGORIES_EXCLUDED_COMMON = [
  'TEXT_HTML', 'TEXT_HTML_WIDGET', 'TABLE', 'STATUS_VALUE', 'EQUIPMENT_KPV_STATUS_VALUE', 'DETAILED_STATUS_VALUE',
];

export const MAX_WIDGETS_IN_HOME = 10;

export const WIDGET_TYPES = {
  FLOOR_PLAN: 'FLOOR_PLAN',
  EQUIPMENT_PLAN: 'EQUIPMENT_PLAN',
  EQUIPMENT_DONUT: 'EQUIPMENT_DONUT',
  EQUIPMENT_OEE_DONUT: 'EQUIPMENT_OEE_DONUT',
  PRODUCT_OEE_DONUT: 'PRODUCT_OEE_DONUT',
  ANALYTICS: 'ANALYTICS',
  STATUS_VALUE: 'STATUS_VALUE',
  DETAILED_STATUS_VALUE: 'DETAILED_STATUS_VALUE',
  GAUGE_CHART: 'GAUGE_CHART',
  BAR_CHART: 'BAR_CHART',
  SVG: 'SVG',
  TEXT_HTML: 'TEXT_HTML',
  GAUGE_CONCENTRIC_WIDGET: 'GAUGE_CONCENTRIC_WIDGET',
  EQUIPMENT_KPV_STATUS_VALUE: 'EQUIPMENT_KPV_STATUS_VALUE'
};

export const WIDGET_TYPE_CATEGORY_MAPPING = {
  [WIDGET_CATEGORIES.OEE_DONUT]: [
    WIDGET_TYPES.PRODUCT_OEE_DONUT,
    WIDGET_TYPES.EQUIPMENT_DONUT,
    WIDGET_TYPES.EQUIPMENT_OEE_DONUT,
    WIDGET_TYPES.GAUGE_CHART,
    WIDGET_TYPES.BAR_CHART
  ],
  [WIDGET_CATEGORIES.ANALYTICS]: [WIDGET_TYPES.ANALYTICS],
  [WIDGET_CATEGORIES.STATUS_VALUE]: [WIDGET_TYPES.STATUS_VALUE, WIDGET_TYPES.EQUIPMENT_KPV_STATUS_VALUE],
  [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: [WIDGET_TYPES.DETAILED_STATUS_VALUE],
  [WIDGET_CATEGORIES.FLOOR_PLAN]: [WIDGET_TYPES.FLOOR_PLAN],
  [WIDGET_CATEGORIES.EQUIPMENT_PLAN]: [WIDGET_TYPES.EQUIPMENT_PLAN],
  [WIDGET_TYPES.GAUGE_CHART]: [WIDGET_TYPES.GAUGE_CHART],
  [WIDGET_TYPES.BAR_CHART]: [WIDGET_TYPES.BAR_CHART],
  [WIDGET_TYPES.SVG]: [WIDGET_TYPES.SVG]
};

export const INPUT_TYPES = {
  TEXT: 'text',
  RADIO: 'radios',
  CHECKBOX: 'checkboxes',
  DROPDOWN: 'dropdown',
  FILTER_SEARCH: 'filterSearch',
  EQUIPMENT_SEARCH: 'equipmentSearch',
  PASSWORD: 'password',
  EMAIL: 'email',
  TOGGLE: 'toggle',
  OBJECT: 'object'
  // EQUIPMENT_SEARCH: 'equipmentSearch'
};

export const COLUMN_WIDTHS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE'
};

export const RESOLVED_FIELD_CLASS_NAME = {
  [COLUMN_WIDTHS.SMALL]: 'col-sm',
  [COLUMN_WIDTHS.MEDIUM]: 'col-md',
  [COLUMN_WIDTHS.LARGE]: 'col-lg',
  [COLUMN_WIDTHS.EXTRA_LARGE]: 'col-xl'
};

export const DATA_TYPES = {
  STRING: 'string',
  JSON: 'JSON'
};

export const FILTER_TERMINOLOGY = {
  LEAF_NODE: 'LEAF_NODE',
  TREE: 'TREE'
};

export const SHARED_LAYOUT = 'sharedLayout';
export const REPEATED_WIDGETS = 'repeatedWidgets';
export const enabledPanesLocal = [
  {
    key: 'DataChart',
    name: 'Data Chart',
    type: 'DataChart'
  },
  {
    key: 'LotChart',
    name: 'Lot Chart',
    type: 'TransitionChart'
  },
  {
    key: 'RecipeChart',
    name: 'Recipe Chart',
    type: 'TransitionChart'
  }
];

// not changeable because of hardcoded(widgetCategory) is used in destructing
export const WIDGET_TERMINOLOGY = {
  WIDGET_CATEGORY: 'widgetCategory'
};

export const DEV_EXPRESS_CHART_TYPES = {
  bar: 'stackedbar',
  'stacked bar': 'stackedbar',
  'full stacked bar': 'fullstackedbar'
};

export const tableContentTypes = {
  IMAGE_DETAILS: 'imageDetails',
  WIDGET_INTERVAL: 'widgetInterval',
  WIDGET_TYPE: 'widgetType',
  TEMPLATE_INSTANCE_TYPE: 'TemplateInstanceType'
};

export const tableItemFlags = {
  NEWLY_ADDED: 'newlyAdded',
  CHANGED_KEYS: 'changedKeys',
  SHOULD_DELETE: 'shouldDelete'
};
