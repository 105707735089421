import React from 'react';
import { SVGList, StatsContainer, StatsText, SvgContainer } from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.style';
import SvgStatusCard from './SvgStatusCard.View';

const Exhaust = ({ colors, speed, dimension, componentData, numSvg }) => {
  console.log(colors);
  const { formData } = componentData || {};
  const svgList = [];
  const width = `${dimension.width}px`;
  const height = `${dimension.height}px`;
  for (let i = 0; i < numSvg; i += 1) {
    svgList.push(
      <SvgContainer>
        <StatsContainer>
          {formData && (
          <SvgStatusCard
            machineStatus={formData?.machineState?.label}
            speed={formData?.speed?.label}
          />
          )}
          {/* <StatsText>
            {' '}
            Exhaust :
            {' '}
            {i + 1}

          </StatsText>
          <StatsText>
            {' '}
            Machine Status :
            {' '}
            {formData?.machineState?.label}

          </StatsText>
          <StatsText>
            {' '}
            Speed :
            {' '}
            {formData?.speed?.label}

          </StatsText> */}

        </StatsContainer>
        <svg
          width="100%"
          height="100%"
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 100 83.9'
        // style="enable-background:new 0 0 100 83.9;"
          style={{ enableBackground: 'new 0 0 100 83.9' }}
          xmlSpace='preserve'
        >
          <style type='text/css'>
            {`.st17 {
      fill: ${colors.class_0};
    }`}

            {`.st18 {
      fill: ${colors.class_1};
    }`}

            {`.st19 {
      fill:${colors.class_2}
    }`}

            {`.st20 {
      fill: ${colors.class_3}
    }`}

            {`.st21 {
      fill: ${colors.class_4}
    }`}

            {`.st22 {
      fill: ${colors.class_5}
    }`}

            {`.st23 {
      fill: ${colors.class_6};
    }`}

            {`.st24 {
      fill: ${colors.class_7}
    }`}
          </style>
          <g id='Group_16855' transform='translate(19397 1489.794)'>
            <g id='Group_16844'>
              <path
                id='Path_67870'
                className='st17'
                d='M-19311.6-1453.3h-10.8c4.2,18.8-7.6,37.4-26.3,41.6c-18.8,4.2-37.4-7.6-41.6-26.3
      c-4.2-18.8,7.6-37.4,26.3-41.6c2.5-0.6,5.1-0.9,7.7-0.9h0.3v0h44.4L-19311.6-1453.3L-19311.6-1453.3z'
              />
              <path
                id='Path_67871'
                className='st18'
                d='M-19311.6-1478.2v3.7h-43.3v0h-0.3c-12.6,0.1-24,7.1-29.8,18.2c-0.1,0.1-0.1,0.2-0.2,0.3
      c-0.1,0.2-0.4,0.3-0.6,0.3c-0.5,0-0.8-0.4-0.8-0.8c0,0,0,0,0,0v-0.1c0.1-0.1,0.1-0.3,0.2-0.4c5.2-12.7,17.5-21.1,31.2-21.2h0.3v0
      L-19311.6-1478.2L-19311.6-1478.2z'
              />
              <path
                id='Path_67897'
                className='st19'
                d='M-19307.4-1484.9L-19307.4-1484.9c2.5,0,4.5,2,4.5,4.5l0,0v27c0,2.5-2,4.5-4.5,4.5l0,0h0
      c-2.5,0-4.5-2-4.5-4.5c0,0,0,0,0,0v-27C-19311.9-1482.9-19309.9-1484.9-19307.4-1484.9
      C-19307.4-1484.9-19307.4-1484.9-19307.4-1484.9z'
              />
              <path
                id='Path_67872'
                className='st20'
                d='M-19306.7-1448.9c-0.2,0-0.4,0-0.6,0c-2.5,0-4.5-2-4.5-4.5c0,0,0,0,0,0v-27
      c0-2.5,2-4.5,4.5-4.5c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c-2.3,0.3-4,2.2-3.9,4.5v27C-19310.7-1451.1-19309-1449.2-19306.7-1448.9z'
              />
              <path
                id='Rectangle_4959'
                className='st21'
                d='M-19303.8-1468.4L-19303.8-1468.4c0.2,0,0.4,0.2,0.4,0.4v12.8c0,0.2-0.2,0.4-0.4,0.4l0,0
      c-0.2,0-0.4-0.2-0.4-0.4v-12.8C-19304.2-1468.3-19304-1468.4-19303.8-1468.4z'
              />
              <path
                id='Rectangle_4960'
                className='st21'
                d='M-19303.8-1471.2L-19303.8-1471.2c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4l0,0
      c-0.2,0-0.4-0.2-0.4-0.4v-1.4C-19304.2-1471-19304-1471.2-19303.8-1471.2z'
              />
              <path
                id='Path_67875'
                className='st18'
                d='M-19331.2-1471.1c4.5,5,7.6,11.1,8.8,17.7c0,0.3-0.1,0.5-0.4,0.6c-0.2,0-0.5-0.1-0.5-0.3
      c-1.8-5.9-5.1-11.2-9.7-15.3c-0.2-0.2-0.2-0.5,0-0.7c0,0,0,0,0,0L-19331.2-1471.1L-19331.2-1471.1L-19331.2-1471.1z'
              />
            </g>
            <g id='Group_16845'>
              <g id='Group_16843'>
                <path
                  id='Path_67873'
                  className='st19'
                  d='M-19332.5-1445.6c0,1.4-0.1,2.9-0.4,4.3c-4.8,2.4-7.8,7.3-7.8,12.6c0,0.4,0,0.7,0,1
        c-2.3,2-4.9,3.5-7.8,4.5c-4.8-3.2-11.1-3.2-15.8,0c-2.9-1-5.5-2.5-7.8-4.5c0-0.3,0-0.7,0-1.1c0-5.4-3-10.2-7.8-12.6l0,0
        c-0.3-1.4-0.4-2.9-0.4-4.3c0-1.5,0.1-3,0.4-4.5c4.9-2.4,7.9-7.3,7.9-12.7c0-0.3,0-0.6,0-0.9c2.2-1.9,4.8-3.4,7.6-4.4
        c4.8,3.2,11.1,3.2,15.8,0c2.8,1,5.3,2.5,7.6,4.4c0,0.3,0,0.6,0,1c0,5.4,3.1,10.3,8,12.7
        C-19332.6-1448.7-19332.5-1447.2-19332.5-1445.6L-19332.5-1445.6z'
                >
                  <animateTransform
                    attributeType='xml'
                    attributeName='transform'
                    type='rotate'
                    from='0 -19356.3 -1445.6'
                    to='360 -19356.3 -1445.6'
                    dur={speed}
                    additive='sum'
                    repeatCount='indefinite'
                  />
                </path>
                <g id='Group_16842'>
                  <animateTransform
                    attributeType='xml'
                    attributeName='transform'
                    type='rotate'
                    from='0 -19356.3 -1445.6'
                    to='360 -19356.3 -1445.6'
                    dur={speed}
                    additive='sum'
                    repeatCount='indefinite'
                  />
                  <ellipse
                    id='Ellipse_847'
                    className='st22'
                    cx='-19356.3'
                    cy='-1445.6'
                    rx='13.3'
                    ry='13.3'
                  />
                  <path
                    id='Path_67874'
                    className='st23'
                    d='M-19345.1-1440.6c-1.3,3.2-4,5.6-7.3,6.5c0,0-0.1,0-0.2,0c-0.2,0-0.4-0.2-0.4-0.4
          c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c6-2.9,8.5-10.1,5.6-16.1c-1.2-2.5-3.2-4.4-5.6-5.6h0
          c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.3h0c0.1,0,0.1,0,0.2,0.1c6.4,2.1,9.8,9,7.7,15.4
          C-19344.8-1441.4-19345-1441-19345.1-1440.6z'
                  />
                  <ellipse
                    id='Ellipse_848'
                    className='st23'
                    cx='-19356.3'
                    cy='-1445.7'
                    rx='3.9'
                    ry='3.9'
                  />
                  <ellipse
                    id='Ellipse_849'
                    className='st24'
                    cx='-19354.2'
                    cy='-1445.7'
                    rx='0.8'
                    ry='1.5'
                  />
                </g>
              </g>
              <path
                id='Path_67876'
                className='st23'
                d='M-19353.8-1457.4c0,0.2-0.1,0.3-0.3,0.3c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1-0.1
      c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.3h0c0.1,0,0.1,0,0.2,0.1C-19353.9-1457.6-19353.8-1457.5-19353.8-1457.4z'
              />
            </g>
          </g>
        </svg>
      </SvgContainer>
    );
  }

  return (<SVGList style={{ width, height }}>{svgList}</SVGList>);
};
export default Exhaust;
