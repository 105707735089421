/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';
import { ItemContainer } from '../../../KPVCharts/EditOptions/EditOptions.style';
import Radiobutton from '../../RadioButton/RadioButton';
import { Label } from '../../../UpsertWidget/WidgetForm/AddWidgetForm.style';
import { getFetchDetailsOfWidgetData } from '../../../Widget/utils';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { useActions } from '../../../../store/hooks/useActions';
import { getReqPayloadForDependentField } from '../../../UpsertWidget/WidgetForm/Utility';
import { Span } from '../../KeyValueLayout/KeyValueLayout.style';
import ToggleableFieldValue from '../../GenericForm/ToggleableFieldValue';

const RadioTypeComponent = (props) => {
  const {
    item,
    value,
    handleChange,
    formStructure,
    formInput, // for handling dependency
    tobeRefetched = false, // check for refetching the data
    resetFieldsTobeRefetched = () => {}, // reset the FieldsTobeRefetched
    isEditable,
    fieldClassName
  } = props;
  const { key, label, dataSource, isRequired } = item;
  // TODO: handle api response
  const { data, dataKey, source, responseKeys, dependencies } = dataSource;
  const { labelKey, valueKey } = responseKeys || {};
  const labelKeyFinal = labelKey || 'label';
  const valueKeyFinal = valueKey || 'value';
  const [options, setOptions] = useState(data);
  const {
    // setToastError,
    setFormStructureFailure
  } = useActions();
  const [loading, setLoading] = useState(false);

  const fetchDetails = async (args) => {
    try {
      setLoading(true);
      const { additionalPayload } = args || {};
      if (dataKey === 'source' && source) {
        // console.log(`fetching dp details of ${key}`);
        const fetchUrl = dataSource.source; // {method, UrlType, Url}
        const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
        let payload = additionalPayload;
        if (method === 'get') {
          payload = { params: additionalPayload };
        }
        const resp = await WebService?.[method](fullURL, payload);
        if (resp.status >= 200 && resp.status < 300) {
          setOptions(resp.data?.data);
        } else {
          throw resp;
        }
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('e', e, error.error);
      // setToastError(error.error);
      setFormStructureFailure(error.error);
    } finally {
      setLoading(false);
      resetFieldsTobeRefetched();
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    if (tobeRefetched) {
      // console.log('refetching....', key);
      const newPayload = getReqPayloadForDependentField(formInput, dependencies, formStructure);
      fetchDetails({ additionalPayload: newPayload });
    }
  }, [tobeRefetched]);

  if (loading) {
    return (
      <KeyInputLayoutView label={label} className='show_stats'>
        {loading && <Span>Loading...</Span>}
      </KeyInputLayoutView>
    );
  }

  return (
    <div>
      <KeyInputLayoutView
        fieldClassName={fieldClassName}
        label={label}
        tooltip={item.tooltip}
        className='show_stats'
        isRequired={isRequired}
      >
        <ToggleableFieldValue isEditable={isEditable} value={formInput?.[key]}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              height: 'auto',
              maxHeight: '20rem',
              // flexWrap: 'wrap',
              padding: '1rem',
              overflowY: 'auto'
            }}
          >
            {options?.map((item) => (
              <ItemContainer className='radio-container'>
                <Radiobutton
                  onClick={() => {
                    handleChange(item, { key });
                  }}
                  active={value?.[valueKeyFinal] === item?.[valueKeyFinal]}
                />
                <Label>{item?.[labelKeyFinal]}</Label>
              </ItemContainer>
            ))}
          </div>
        </ToggleableFieldValue>
      </KeyInputLayoutView>
    </div>
  );
};

export default RadioTypeComponent;
