export const resolveTabsList = (payload) => {
  //   sample payload
  // {
  //     "WPID": 2,
  //     "SiteId": 1,
  //     "SubMenuId": 1,
  //     "UserId": 1,
  //     "CreatedAt": "2022-12-05T06:46:33.8055297Z",
  //     "UpdatedAt": "2022-12-05T06:46:33.8055297Z",
  //     "IsShareable": false,
  //     "WidgetPanelName": "Name",
  //     "WidgetPanelLayoutData": "{\r\n    \"key1\": \"value1\"\r\n  }",
  //     "WidgetPanelLayoutMetaData": "{\r\n    \"key2\": \"value2\"\r\n  }"
  // }
  const { WPID, SiteId, IsShareable, WidgetPanelName } = payload || {};
  return {
    id: WPID,
    siteId: SiteId,
    isShareable: IsShareable,
    label: WidgetPanelName
  };
};
