/* eslint-disable max-len */
import { useEffect } from 'react';
// val - localVal,
// setValue - setter for setting original value
function useDebounce(value, setValue, delay, options = {}) {
  const { additonalValueParams } = options;
  useEffect(() => {
    const timer = setTimeout(() => setValue(value, additonalValueParams), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
}

export default useDebounce;
