/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isRegExp } from 'lodash';
import { isEmpty } from '../../shared/utility/isEmpty';
import {
  HeaderText
  // ButtonContainer,
  // Button
} from '../../views/Users/Users.style';
import UsersHeader from '../../views/Users/UsersHeader';
import { INPUT_TYPES, fontSize } from '../../constants';
import { userformStructure as localForm } from './data';
import { BodyContainer } from './UserDetails.style';
import GenericForm from '../Common/GenericForm/GenericForm';
import { getFieldDependents } from '../UpsertWidget/WidgetForm/Utility';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import Loader from '../Common/Loader/Loader';
import { useActions } from '../../store/hooks/useActions';
import { convertFormInputObjToSimple, validateFields } from '../Common/GenericForm/utils';
import { VALIDATION_TYPES, validate } from '../../shared/utility/regex';
import Toast from '../Common/Toast';
import TabsTable from '../Common/Tabs_Table';

const ADUserDetailsForm = (props) => {
  const history = useHistory();
  const locations = useLocation();
  const userDetailsFormRef = useRef();
  const [error, setError] = useState();
  const { setToastError } = useActions();
  const [loading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [ADsearchType, setADSearchType] = useState();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [assetShortlist, setAssetShortlist] = useState([]);
  const [searchResultData, setSearchResultData] = useState();
  const [showResultTable, setShowResultTable] = useState(false);
  const [userformStructure, setUserformStructure] = useState([]);

  const { state } = locations;
  const { isFormEditable, formInput: formInputProp, areFormInputsChanged, formApi, saveForm } = state;
  const isEdit = saveForm === apiEndPoints.updateUserDetails;

  /**
   * Function which calls the user search api incase of AD/Ldap User Add
   */
  const getADUser = async () => {
    if (!isEmpty(ADsearchType) && ADsearchType?.searchType && ADsearchType?.searchText) {
      try {
        const fullUrl = `${apiEndPoints.getADUser}/${ADsearchType?.searchType}/${ADsearchType?.searchText}`;
        const res = await WebService.get(fullUrl);
        const { status } = res;
        if (res.status >= 200 && res.status < 300) {
          setSearchResultData(res?.data);
          setShowResultTable(true);
        } else {
          setShowResultTable(false);
          throw res;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        setError(e.response?.data?.data);
      }
    } else {
      setError('Please Enter User');
    }
  };

  /**
   * Function Triggered on Search press
   */
  const handleSearchPress = () => {
    getADUser();
  };

  // setting up the form details from props
  useEffect(() => {
    setLoading(true);
    WebService.get(formApi)
      .then((res) => {
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          setUserformStructure(data);
        } else {
          throw res.data;
        }
      })
      .catch((e) => {
        const { error } = sagaCatchBlockHandling(e);
        setToastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
    setFormInput(formInputProp);
  }, [formInputProp]);

  // header handlers
  const handleUserEdit = () => {
    history.push({
      pathname: '/home/user/edit',
      state: {
        isFormEditable: true,
        formInput,
        formApi: apiEndPoints.getUserUpdateForm,
        saveForm: apiEndPoints.updateUserDetails
      }
    });
  };

  const handleDisableUser = () => {
    // console.log('handleDisableUser');
  };

  const handleNext = () => {
    history.push({
      pathname: '/home/user/add',
      state: {
        isFormEditable: true,
        formInput: assetShortlist[0],
        formApi: apiEndPoints.getADUserForm,
        saveForm: apiEndPoints.createADUserDetails
      }
    });
  };

  const handleCancel = () => {
    if (isEdit) {
      history.push({
        pathname: '/home/user/details',
        state: { isFormEditable: false, formInput: formInputProp, formApi, saveForm }
      });
    } else {
      history.push({ pathname: '/home/users', state: { record: areFormInputsChanged ? formInput : {} } });
    }
  };

  const handleBack = () => {
    // if in details page go back to users
    if (!isFormEditable) {
      history.push({ pathname: '/home/users', state: { record: areFormInputsChanged ? formInput : {} } });
    } else {
      history.goBack();
    }
  };

  const handleShortList = (e, isCheckRequired = true) => {
    if (isCheckRequired) {
      if (!assetShortlist.includes(e)) {
        setAssetShortlist([e]);
      } else {
        assetShortlist.splice(assetShortlist.indexOf(e), 1);
        setAssetShortlist([...assetShortlist]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };

  useEffect(() => {
    const localFormInput = userDetailsFormRef.current.getFormInput();
    setFormInput({ ...localFormInput, ...assetShortlist });
  }, [assetShortlist]);

  return (
    <>
      <UsersHeader
        title={!isFormEditable ? 'User details' : `${isEdit ? 'Edit' : 'Search'} AD User Details`}
        isADUser={true}
        isFormEditable={isFormEditable}
        handleUserEdit={handleUserEdit}
        handleDisableUser={handleDisableUser}
        handleSave={handleNext}
        isSaveEnabled={isFormFilled && !isEmpty(assetShortlist)}
        handleCancel={handleCancel}
        handleBack={handleBack}
      />
      <BodyContainer>
        {loading ? (
          <Loader />
        ) : (
          <GenericForm
            isFormEditable={isFormEditable}
            formStructure={userformStructure}
            ref={userDetailsFormRef}
            formInput={formInput}
            setFormInput={setFormInput}
            showHeader
            setIsFormFilled={setIsFormFilled}
            formStructureFieldDepenents={getFieldDependents(userformStructure)}
            allowHorizontal={false}
            setADSearchType={setADSearchType}
            handleSearchPress={handleSearchPress}
          />
        )}
        {showResultTable && (
        <TabsTable
          metaDetails={searchResultData?.meta}
          dataDetails={searchResultData?.data?.adusers}
          showPagiation={false}
          showShortlistSelection={true}
          handleShortList={handleShortList}
          assetShortlist={assetShortlist}
          styleType='strapedTable'
          noItemsFoundMessage='No Users Found'
          showRowOptionAtStart={false}
          id='UserID'
        />
        )}
        {error && <Toast header='Error' className='fixed right' message={error} fn={() => setError('')} />}
      </BodyContainer>

    </>
  );
};

export default ADUserDetailsForm;

export const RequiredHeaderContainet = styled.div`
  position: relative;
  bottom: 2rem;
  font-size: ${fontSize.large};
`;
