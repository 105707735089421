import React from 'react';
import { FileUploadBox } from '../FIleUpload/FileUpload.style';

const DragDropFile = (props) => {
  const { handleFile, children } = props;
  function onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const { files } = evt.dataTransfer;
    if (files && files[0]) handleFile(files[0]);
  }

  function suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  return (
    <FileUploadBox className='jc_center ai_center' onDrop={onDrop} onDragEnter={suppress} onDragOver={suppress}>
      {children}
    </FileUploadBox>
  );
};

export default DragDropFile;
