import styled from 'styled-components';

export const LabelsContainer = styled.div`
display: flex;
justify-content: center;
padding-bottom:30px;
// width: 100%;
`;
export const BarGaugeValueContainer = styled.div`
    display: flex;
    flex-direction: row;

`;
export const BarGaugeTable = styled.table`
text-align: start;
border-spacing: 10px;
`;
