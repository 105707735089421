import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckMark } from '../../assets/icons/KPV/checkmark.svg';
import { FlexContainer } from '../../globalStyles';
import { CheckBox as CheckBoxContainer } from '../UpsertPane/UpsertPane.style';

const Label = styled.div`
  font-size: 1.8rem;
`;

const CheckBox = (props) => {
  const { isActive, style, handleChange, label } = props;
  return (
    <FlexContainer style={style}>
      <CheckBoxContainer className={isActive ? 'active' : 'unactive'} onClick={handleChange}>
        <CheckMark />
      </CheckBoxContainer>
      <Label>{label}</Label>
    </FlexContainer>
  );
};

export default CheckBox;
