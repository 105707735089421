/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Chart, {
  AdaptiveLayout,
  ArgumentAxis,
  CommonSeriesSettings,
  ConstantLine,
  Grid,
  // Strips,
  Strip,
  Label,
  Legend,
  Pane,
  Point,
  Series,
  SeriesTemplate,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
  ZoomAndPan,
  Crosshair,
  HorizontalLine,
  Font,
  Aggregation
  // Annotation
} from 'devextreme-react/chart';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors, fontFamily, maxCharactersAloowed, placeHolderSize } from '../../../../constants';
// import { useLocation } from 'react-router-dom';
// import { useWindowSize } from '../../../../shared/hooks/useWindowResize';
// import useContainerWidth from '../hooks/useContainerWidth';
import useViewLabels from '../hooks/useViewLabels';
import { Creators as kpvChartingCreators } from '../../Store/action';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { chartConfig } from '../chartConfig';
import getNearestPoints from '../../utils/getNearestPoints';
import getNearestPointByPosition from '../../utils/getNearestPointByPosition';
import useMeasure from '../../../../shared/hooks/useMeasure';

// export const colorCodes = ['#6900FF', '#F200BA', '#398BFF', '#A000E4', 'red', 'yellow', 'green', 'orange'];

const ChartContainer = styled.div`
  height: ${({ height }) => height || '100%'};
  padding: 0px 0px 10px 10px;
  width: 100%;
  min-height: 20rem;
`;

const DataChart = ({
  isLastPane = false,
  setHoveredState,
  setHoveredPosState,
  hoveredState,
  paddingLeft = 0,
  setPointClicked,
  xPos,
  resize,
  fillLayout,
  ...paneData
}) => {
  const {
    index,
    data,
    paneDataPoints,
    labelsData: labels,
    showDetails,
    multiAxis,
    multiGrid,
    scaleYaxis,
    scaleYAxisRange,
    color,
    pointInfoObj: pointData,
    xMinDomainProp,
    xMaxDomainProp,
    rawPlotData,
    compStartTime,
    StartTime,
    EndTime,
    id,
    annotations,
    paneIndex,
    showOverlays,
  xMinDomainForFiltered,
  xMaxDomainForFiltered
  } = paneData;
  const { isAddAnnotationEnabled } = showDetails || {};
  const { data: plotData, meta } = rawPlotData || {};
  // const scaleYaxis = 'processWindow';
  // const { 'y-axis': yAxis, 'x-axis': xAxis, legends } = showDetails;
  const xMinDomain = useSelector((state) => state.KPVCharts.xMinDomain);
  const xMaxDomain = useSelector((state) => state.KPVCharts.xMaxDomain);
  const paneFullScreen = useSelector((state) => state.KPVCharts.paneFullScreen);
  const compressedView = useSelector((state) => state.KPVCharts.compressedView);
  const filterFilesSelected = useSelector((state) => state.KPVCharts.filterFilesSelected);
  const xMinDomainFullScreen = useSelector(
    (state) => state.KPVCharts.xMinDomainFullScreen
  );
  const xMaxDomainFullScreen = useSelector(
    (state) => state.KPVCharts.xMaxDomainFullScreen
  );
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  // const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const comparisionMode = useSelector(
    (state) => state.KPVCharts.comparisionMode
  );
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);
  const themeMode = useSelector((state) => state.configData.themeMode);
  // width calc (old method)
  // const { showSideNav } = useSelector((state) => state.home);
  // const [width] = useWindowSize();
  // const [containerWidth, setContainerWidth] = useState(0);
  // useContainerWidth(index, setContainerWidth, showSideNav, width, showDetails?.legends, paddingLeft, resize?.w);
  const [ref, { width: containerWidth, height: containerHeight }] =
    useMeasure();
  const [viewXLabels, setViewXLabels] = useState(true);
  const [maxPlotChar, setMaxPlotChar] = useState(0);
  const panesData = useSelector((state) => state.KPVCharts.panesData);
  useViewLabels(setViewXLabels, compressedView);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'globalHoveredState',
        value: {}
      })
    );
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'pointClicked',
        value: {}
      })
    );
  }, [containerWidth, compressedView, comparisionMode]);

  const chartRef = useRef();

  const getPointInfo = (pointInfo) => {
    const {
      argument,
      point: { x, y },
      seriesName
    } = pointInfo;

    // set annotation related info if annotations enabled
    // if (isAddAnnotationEnabled) {
    //   setPointClicked({ argument, x: x + paddingLeft, y: 0, seriesName });
    // }
    // sets globalHoveredState and normal hoveredState at pane level
    const data = getNearestPoints(plotData, meta, argument);
    setHoveredState({
      data: [data],
      top: y,
      x: argument,
      left: x + paddingLeft,
      orgX: x,
      index: paneIndex
    });
    if (compressedView) {
      dispatch(
        kpvChartingCreators.setHoveredXValue({ argument, x: x + paddingLeft })
      );
    }

    // dispatch(kpvChartingCreators.setHoveredXValue(pointInfo.argument));
    // const l = pointInfo.point.series.getPointsByArg(pointInfo.argument).map((e) => e.data);
    // console.log('data chart point', l);
    // setHoveredState({ data: l, x: hoveredXValue });
    return { text: '' };
  };
  useEffect(() => {
    const chart = chartRef.current?.instance;
    try {
      // get the nearest points by postion only in aligned condition
      if (xPos && isAligned) {
        const e = getNearestPointByPosition(chart, xPos);
        setHoveredPosState(e);
      }
    } catch (e) {
      console.log('error in finding nearest point based on position', e);
    }
  }, [xPos, isAligned]);

  // only one old version...
  // const e = chart?.getAllSeries().reduce(
  //   (prev, s, index) => ({
  //     ...prev,
  //     [s.name]: nearestDataPoint(s._data, argument, 0, s._data.length)
  //     // .map((e) => e.data)
  //   }),
  //   {}
  // );

  const getLabel = (val) => {
    if (val?.valueText?.length >= maxCharactersAloowed) {
      return `${val?.valueText?.substring(0, maxCharactersAloowed)}...`;
    }
      return val?.valueText;
  };
  // old
  // in compress view get all the series in this graph and getPointByArg

  // console.log('hoveredState', hoveredState);
  // used in transition chart also
  const getYAxisLabel = () => (
    <Label
      // position={compressedView ? 'inside' : 'outside'}
      visible={showDetails ? showDetails['y-axis'] : true}
      // rotationAngle={270}
      displayMode='rotate'
      customizeHint={(e) => e.valueText}
      font={{
        family: fontFamily.circularBook,
        size: chartConfig.labelFontSize,
        // TODO: incorrect theme handling in all places
        color: colors[themeMode].contrast.primary
      }}
      customizeText={(val) =>
        getLabel(val)
}
    />
  );

  // bands applicable to single grid and single pane charts
  const stripsItem = (kpv) =>
    meta[kpv]?.fields?.Limit?.map((limit) =>
      Object.keys(limit)?.map((e) => (
        // console.log('limit', e, limit[e]);
        <Strip startValue={e} color={limit[e]} />
      ))
    );

  // can be minimum/maximum
  const getExtremeValues = (kpv, isMin = true) =>
    meta[kpv]?.fields?.Limit?.reduce((prevVal, currVal) => {
      // for maximum
      if (!isMin) {
        if (
          prevVal === null ||
          parseInt(Object.keys(currVal)[0], 10) > parseInt(prevVal, 10)
        ) {
          return Object.keys(currVal)[0];
        }
        return prevVal;
      }
      // for minimum
      if (
        prevVal === null ||
        parseInt(Object.keys(currVal)[0], 10) < parseInt(prevVal, 10)
      ) {
        return Object.keys(currVal)[0];
      }
      return prevVal;
    }, null);

  const lowermostBand = (kpv) => {
    const minLimit = getExtremeValues(kpv, true);
    // return meta[kpv].fields.Limit.map((limit) => {
    // console.log('minLimit', kpv, minLimit, limit[minLimit]);
    const limits = meta[kpv]?.fields?.Limit?.reduce(
      (prev, curr) => ({ ...curr, ...prev }),
      {}
    );
    // console.log('limi', limits, minLimit);
    return <Strip endValue={minLimit} color={limits && limits[minLimit]} />;
    // });
  };

  const stripsArr =
    meta &&
    Object.keys(meta).map((kpv, index) =>
      // console.log('strip meta', meta[kpv].fields.Limit);
      stripsItem(kpv)
    );
  const lowermostStripsArr =
    meta &&
    Object.keys(meta)?.map((kpv, index) =>
      // console.log('strip meta', kpv, meta[kpv].fields.Limit);
      lowermostBand(kpv)
    );

  const getArgumentAxisRange = () => {
    if (comparisionMode && StartTime) {
      if (!isEmpty(filterFilesSelected)) {
        if (!isAligned) {
        return [xMinDomainForFiltered || xMinDomain, xMaxDomainForFiltered || xMaxDomain];
        }
        return [StartTime, xMaxDomain];
      }
      return [StartTime, EndTime];
    }
    if (paneFullScreen) {
      return [xMinDomainFullScreen, xMaxDomainFullScreen];
    }
    return [xMinDomainProp || xMinDomain, xMaxDomainProp || xMaxDomain];
  };

  const getValueAxisRange = (kpv, position = 0) => {
    if (
      scaleYaxis === 'manual' &&
      scaleYAxisRange.length &&
      scaleYAxisRange[0] !== undefined &&
      scaleYAxisRange[position]?.from !== '' &&
      scaleYAxisRange[position]?.to !== ''
    ) {
      // console.log('scaleYAxisRange', scaleYAxisRange, position, scaleYAxisRange[position]);
      return [scaleYAxisRange[position]?.from, scaleYAxisRange[position]?.to];
    }
    if (scaleYaxis === 'processWindow') {
      return [
        Math.min(meta[kpv].minValue, getExtremeValues(kpv, true)),
        Math.max(meta[kpv].maxValue, getExtremeValues(kpv, false))
      ];
    }
    return [null, null];
  };

  const customizePoint = (point) => {
    // special point for annotation
    if (
      showDetails?.annotations &&
      annotations?.some((argPoint) =>
        moment(point.argument).isSame(argPoint.argument)
      )
    ) {
      return {
        ...point,
        size: 15,
        color: 'black'
      };
    }

    // let shouldCustomize = false;
    // console.log('point', hoveredState.data && hoveredState.data[0]);
    const shouldCustomize = Object.keys(meta).some((kpv, index) => {
      if (comparisionMode) {
        const comparisionModeCondition =
          comparisionMode &&
          moment(point.argument).isSame(compStartTime, 'second');
        return comparisionModeCondition;
      }

      const nearestPointCondition =
        !showDetails?.annotations &&
        point.seriesName === `${kpv}-SensorData` &&
        moment(point.argument).isSame(
          hoveredState?.data && hoveredState.data[0][`${kpv}-TSValue`]
        );
      // point is customized on nearest data point and in comparision mode
      return nearestPointCondition;
    });
    if (shouldCustomize) {
      return {
        ...point,
        size: 15
      };
    }
    return point;
  };

  const setXaxisVisibility = () => {
    // find the label dom element at making it display none
    // instead of making devextreme handle the visibility
    // const currentPaneNo = panesShortHandInfo.findIndex((paneInfo) => paneInfo.id === id);
    // console.log('x-axis', viewXLabels, showDetails['x-axis'], id, index);
    // 'dxc-arg-elements' is the className for the x-labels
    const axisElements = document
      .getElementById(id)
      ?.getElementsByClassName('dxc-arg-elements');
    const xAxisEle = !isEmpty(axisElements)
      ? axisElements[axisElements.length - 1]
      : false;

    if (xAxisEle) {
      let visibleCondition = false;
      if (compressedView) {
        visibleCondition = viewXLabels || isLastPane;
      } else {
        visibleCondition = showDetails ? showDetails['x-axis'] : true;
      }

      if (!visibleCondition) {
        xAxisEle.style.visibility = 'hidden';
      } else {
        xAxisEle.style.visibility = 'visible';
      }
    }
  };
  const isMounted = useRef();
  useEffect(() => {
    let timer;
    if (!isMounted.current) {
      isMounted.current = true;
      timer = setTimeout(() => {
        setXaxisVisibility();
      }, 1000);
    } else {
      setXaxisVisibility();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showDetails['x-axis'], compressedView, viewXLabels]);

  function onPointClick({ target: point }) {
    const {
      argument,
      x,
      y,
      series: { name }
      // point: { x, y }
    } = point;
    // // console.log('point', argument);
    // if (point.isSelected()) {
    //   point.clearSelection();
    //   dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: xMinDomain }));
    // } else {
    //   dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: argument }));
    //   point.select();
    // }

    // point clickable on compariosn mode and not aligned
    if (comparisionMode && !isAligned) {
      dispatch(
        kpvChartingCreators.editPaneData({
          index,
          key: 'compStartTime',
          value: argument
        })
      );
    } else if (isAddAnnotationEnabled) {
      // removed method of add/edit annotations by onClick(moved to onHover)
      setPointClicked({ argument, x: x + paddingLeft, y, seriesName: name });
    }
  }

  const findMaxLength = (plotData) => {
    let max = 0;
  for (let i = 0; i < plotData?.length; i += 1) {
    const currLength = (plotData[i]?.SensorData)?.toString()?.length;
    if (currLength + 1 > max) {
      max = currLength + 1;
    }
  }
    return max;
  };

  useEffect(() => {
    const maxArr = [];
    for (let i = 0; i < panesData?.length; i += 1) {
       maxArr.push(findMaxLength(panesData[i]?.rawPlotData?.data));
    }
    const max = Math.max(...maxArr);
    const maxAllocatedLen = max <= maxCharactersAloowed ? max : maxCharactersAloowed;
    setMaxPlotChar(maxAllocatedLen);
  }, [panesData]);

  return (
    <ChartContainer ref={ref}>
      <Chart
        id={`chart${paneIndex}`}
        dataSource={plotData}
        // style={!paneFullScreen ? { maxHeight: '50rem', ...chartStyles } : { ...chartStyles }}
        // style={{ height: '100%' }}
        size={{ width: containerWidth, height: containerHeight || '100%' }}
        rotated={null}
        ref={chartRef}
        customizePoint={customizePoint}
        onPointClick={onPointClick}
      >
        <Crosshair
          enabled={!comparisionMode}
          color='black'
          width={3}
          dashStyle='solid'
        >
          <HorizontalLine visible={false} />
          <Label visible={true} backgroundColor='black'>
            <Font color='#fff' size={12} />
          </Label>
        </Crosshair>
        {/* {console.log(xMaxDomainProp, xMinDomainProp)} */}
        {/* {console.log('location', location.pathname === '/home/kpv-charting/edit-pane')} */}
        {/* <AdaptiveLayout /> */}
        {/* <ZoomAndPan argumentAxis='both' valueAxis='both' /> */}
        <ArgumentAxis
          // eslint-disable-next-line react/jsx-props-no-multi-spaces
          visualRange={getArgumentAxisRange()}
          valueMarginsEnabled={false}
          endOnTick={false}
          color={colors[themeMode].contrast.lightSecondary}
        >
          {/* comparison mode start line */}
          {comparisionMode && isAligned && compStartTime && (
            <ConstantLine
              width={1}
              value={compStartTime}
              color={colors[themeMode].contrast.lightSecondary}
              dashStyle='solid'
            >
              <Label visible={false} />
            </ConstantLine>
          )}
          {/* annotations lines */}
          {showDetails?.annotations &&
            !isEmpty(annotations) &&
            annotations.map((annotPoint) => (
              <ConstantLine
                width={2}
                value={annotPoint.argument}
                color={colors[themeMode].contrast.lightSecondary}
                dashStyle='dot'
                // displayBehindSeries={true}
              >
                <Label visible={false} />
              </ConstantLine>
            ))}
          <Grid
            visible={true}
            width={1}
            color={colors[themeMode].contrast.lightSecondary}
          />
          <Label
            // visible={showDetails ? (showDetails['x-axis'] && viewXLabels) || isLastPane : true}
            // visible={showDetails ? showDetails['x-axis'] : true}
            font={{
              family: fontFamily.circularBook,
              size: fillLayout ? 15 : 22,
              color: colors[themeMode].contrast.primary,
              wordWrap: 'breakWord'
            }}
            alignment='left'
            customizeText={(tick) =>
              moment(tick.value).format('DD/MM/YYYY HH:mm:ss:sss')
            }
          />
        </ArgumentAxis>
        <Legend
          visible={false}
          customizeText={(item) => labels[item.seriesName]}
          font={{ size: 60 }}
        />
        <CommonSeriesSettings
          argumentField='TSValue'
          // valueField='SensorData'
          type='line'
        >
          <Tick visible={true} />
          <Grid visible={true} width={1} />
          <Point size={0} hoverMode='onlyPoint' hoverStyle={{ size: 15 }} />
        </CommonSeriesSettings>
        <Tooltip enabled={true} customizeTooltip={getPointInfo} />

        {/* value axis */}
        {/* multi axis, multiple grid */}
        {multiAxis &&
          multiGrid &&
          !isEmpty(meta) &&
          Object.keys(meta).map((kpv) => <Pane name={kpv} key={kpv} />)}
        {/* multi axis */}
        {multiAxis &&
          !isEmpty(meta) &&
          Object.keys(meta).map((kpv, index) => (
            <ValueAxis
              key={kpv}
              name={`${kpv}-SensorData`}
              pane={multiGrid ? kpv : undefined}
              color={colorCodes[index]}
              multipleAxesSpacing={chartConfig.multipleAxesSpacing}
              // autoBreaksEnabled={true}
              valueMarginsEnabled={false}
              endOnTick={false}
              visualRange={getValueAxisRange(kpv, index)}
              placeholderSize={placeHolderSize * maxPlotChar}
            >
              {getYAxisLabel()}
              {meta &&
                multiGrid &&
                showOverlays?.limits !== false &&
                lowermostBand(kpv)}
              {meta &&
                multiGrid &&
                showOverlays?.limits !== false &&
                stripsItem(kpv)}
            </ValueAxis>
          ))}
        {/* single axis */}
        {!multiAxis && (
          <ValueAxis
            allowDecimals={true}
            // autoBreaksEnabled={true}
            valueMarginsEnabled={false}
            endOnTick={false}
            visualRange={meta && getValueAxisRange(Object.keys(meta)[0])}
            color={colors[themeMode].contrast.lightSecondary}
            placeholderSize={placeHolderSize * maxPlotChar}
          >
            <Grid
              visible={true}
              width={1}
              color={colors[themeMode].contrast.lightSecondary}
            />
            {getYAxisLabel()}
            {meta &&
              Object.keys(meta).length === 1 &&
              showOverlays?.limits !== false &&
              lowermostStripsArr}
            {meta &&
              Object.keys(meta).length === 1 &&
              showOverlays?.limits !== false &&
              stripsArr}
          </ValueAxis>
        )}
        {/* series */}
        {meta &&
          Object.keys(meta).map((kpv, index) => (
            <Series
              key={kpv}
              // axis name is used to bind the value axis, by default it is binded to first axis
              axis={multiAxis ? `${kpv}-SensorData` : undefined}
              pane={multiAxis && multiGrid ? kpv : undefined}
              valueField={`${kpv}-SensorData`}
              name={`${kpv}-SensorData`}
              // axis={!multiAxis ? null : key}
              // color={color || colorCodes[index]}
              color={colorCodes[index]}
              ignoreEmptyPoints={false}
            >
              <Point size={8} hoverMode='onlyPoint' />
            </Series>
          ))}
        {/* for multiple series in a single pane alternative */}
        {/* <SeriesTemplate
        nameField='kpv'
        // customizeSeries={customizeSeries}
      /> */}
      </Chart>
    </ChartContainer>
  );
};

export default memo(DataChart);
