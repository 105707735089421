import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../../constants';
import { Graph, WrapperConatiner } from '../../PaneCard/PaneCards.style';
import LegendComp from '../../Legend/Legend.view';
import XYChartView from './XYChart.view';
import { Creators as KPVChartsCreators } from '../../Store';
import { isEmpty } from '../../../../shared/utility/isEmpty';

const XYChartWrapper = ({ ...paneData }) => {
  // const { rawPlotData } = paneData;
  const {
    isLastPane,
    padding,
    position = 'right',
    isFullScreen,
    fillLayout,

    showDetails,
    labelsData,
    pointInfoObj,
    xMinDomainProp,
    xMaxDomainProp,
    multiGrid,
    id,
    rawPlotData,
    annotations,
    paneIndex,
    chartType,
    chartTypeCategory
    // isAddAnnotationEnabled
  } = paneData;
  const index = 0;
  const { data: plotData, meta } = rawPlotData || {};
  const dispatch = useDispatch();
  const [hoveredState, setHoveredState] = useState([]);
  const setGlobalHoveredState = useCallback(
    (value) => {
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'globalHoveredState',
          value: { index: !isEmpty(value) ? paneIndex : -1, ...value }
        })
      );
      setHoveredState(value);
    },
    [dispatch]
  );

  // console.log('XYChartWrapper', rawPlotData);
  return (
    <WrapperConatiner
      fillLayout={fillLayout}
      position={position}
      //   position={position}
      style={{ display: 'flex', width: '100%', height: '100%' }}
    >
      <Graph
        className='singlePane'
        height={25}
        // paddingLeft={padding}
        width={(showDetails ? showDetails?.legends : true) ? '75%' : '100%'}
        // style={{ minHeight: `${paneHeight}rem` }}
      >
        <XYChartView
          //   isLastPane={isLastPane}
          setHoveredState={setGlobalHoveredState}
          //   setHoveredPosState={setHoveredPosState}
          //   paddingLeft={padding}
          //   labels={labels}
          //   xPos={comparisionMode && isAligned ? globalHoveredState.orgX : 0}
          // hoveredState={hoveredState}
          fillLayout={fillLayout}
          // {...paneData}
          {...paneData}
          rawPlotData={rawPlotData}
        />
      </Graph>

      <LegendComp
        data={labelsData?.[index]}
        labels={meta && Object.values(meta)}
        showLegends={showDetails ? showDetails?.legends : true}
        // transitionChartColorCodes={labels}
        // pointData={pointInfoObj?.length > 0 && pointInfoObj[index]}
        // color={index > 0 ? colorCodes[index] : undefined}
        chartTypeCategory={CHART_TYPE_CATEGORY.LABEL}
        position={position}
        hoveredState={hoveredState.data}
        // hoveredPosState={hoveredPosState}
      />
    </WrapperConatiner>
  );
};

export default memo(XYChartWrapper);
