/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { startOfQuarter } from 'date-fns';
import PickerHeader from '../../Common/Header/Header';
import {
  MonthContainer,
  MonthPickerContainer,
  MonthWrapper
} from '../../MonthRangePicker/MonthPicker/MonthPicker.styles';
import { MONTHS } from '../../MonthRangePicker/utility/constants';
import { QUARTER } from '../utility/constants';
import { isBefore, isBetween, isSame } from '../utility/CompareQuarter';
import { QuarterContainer, QuarterPickerContainer } from './Quarter.style';

export default function QuarterPicker({
  title,
  isAtStart,
  isSingleSelection,
  setIsAtStart,
  setSelectedRange,
  selectedRange,
  hoveredDate,
  setHoveredDate,
  year,
  setYear,
  startInit,
  handleSelection,
  formatSelectionObj,
  maxDate
}) {
  const setSameStartAndEnd = (selectedQuarterObj) => {
    const obj = {
      ...selectedRange,
      start: selectedQuarterObj,
      end: selectedQuarterObj
    };
    setSelectedRange(obj);
    handleSelection(formatSelectionObj(obj));
  };

  const onSelect = (selectedQuarterObj) => {
    if (isAtStart !== 1 && isBefore(selectedQuarterObj, selectedRange.start)) {
      // console.log('1. end turn, date selected is before start');
      setSameStartAndEnd(selectedQuarterObj);
    } else if (!isSame(selectedRange.start, selectedRange.end)) {
      // console.log('2. start and end are not equal');
      setSameStartAndEnd(selectedQuarterObj);
    } else if (isAtStart === 1 && isSame(selectedRange.start, selectedRange.end)) {
      // console.log('3. start and end are not equal');
      setSameStartAndEnd(selectedQuarterObj);
    } else if (isAtStart === 0 && isSame(selectedRange.start, selectedQuarterObj)) {
      // deselection
      const obj = {
        start: undefined,
        end: undefined
      };
      setSelectedRange(obj);
      handleSelection(formatSelectionObj(obj));
    } else {
      // console.log('4. end turn and selected date isAfter start');
      const obj = {
        ...selectedRange,
        [isAtStart === 1 ? 'start' : 'end']: selectedQuarterObj
      };
      setSelectedRange(obj);
      handleSelection(formatSelectionObj(obj));
    }

    // move to another position
    setIsAtStart(isAtStart === 0 ? 1 : 0);
  };
  const handleMouseOver = (currDate) => {
    setHoveredDate(currDate);
  };
  // take corresponding year as the year of end quarter
  const correspondingYear = startInit > 0 ? year[title] + 1 : year[title];
  // console.log(
  //   'selectedRange',
  //   hoveredDate
  //   // currDate, selectedRange, isBetween(currDate, [selectedRange.start, selectedRange.end])
  // );
  return (
    <MonthWrapper>
      <PickerHeader
        title={title}
        hideTitle={isSingleSelection}
        year={year}
        correspondingYear={correspondingYear}
        setYear={setYear}
        isAtStart={isAtStart}
      />
      <QuarterPickerContainer>
        {QUARTER.map((q, index) => {
          const start = startInit + 3 * index;
          const end = start + 2;
          const startObj = {
            month: start % 12,
            year: year[title] + Math.floor(start / 12)
          };
          const endObj = {
            month: end % 12,
            year: year[title] + Math.floor(end / 12)
          };
          const monthRangeText = ` ${MONTHS[startObj.month]} ${startObj.year.toString().substring(2)} - ${
            MONTHS[endObj.month]
          } ${endObj.year.toString().substring(2)}`;
          const currDate = {
            start: startObj,
            end: endObj,
            quarter: `${q} ${year[title]}`,
            correspondingYear: `${q} ${correspondingYear}`
          };

          return (
            <QuarterContainer
              className={`${
                selectedRange?.start &&
                selectedRange?.end &&
                isBetween(currDate, [selectedRange.start, selectedRange.end])
                  ? 'hightlight'
                  : ''
              }
              ${
                !maxDate || !selectedRange?.start || isBetween(currDate, [selectedRange.start, maxDate])
                  ? ''
                  : 'disable'
              }
              ${isSame(currDate, selectedRange.start) ? 'startDate' : ''}
              ${isSame(currDate, selectedRange.end) && !isSame(currDate, selectedRange.start) ? 'endDate' : ''}
            
              ${hoveredDate && isSame(hoveredDate, currDate) ? 'hovered' : ''}

              ${
                hoveredDate &&
                isAtStart === 0 &&
                isSame(selectedRange.start, selectedRange.end) &&
                isBetween(currDate, [selectedRange.start, hoveredDate])
                  ? 'hovered'
                  : ''
              }
              `}
              onMouseOver={() => handleMouseOver(currDate)}
              onClick={() => onSelect(currDate)}
            >
              <span style={{ display: 'block' }}>{`${q} ${correspondingYear}`}</span>
              <div>{monthRangeText}</div>
            </QuarterContainer>
          );
        })}
      </QuarterPickerContainer>
    </MonthWrapper>
  );
}
