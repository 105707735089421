export function compare(a, b, prop, sortOrder = 'inc') {
  if (typeof a[prop] === 'boolean' || typeof b[prop] === 'boolean') {
    return a[prop] ? -1 : 1;
  }

  if (!b[prop]) {
    return -1;
  }
  if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
    return sortOrder === 'inc' ? -1 : 1;
  }
  if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
    return sortOrder === 'inc' ? 1 : -1;
  }
  return 0;
}
