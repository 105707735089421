import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setTagsObj: ['payload'],
  setCummulativeTagsObj: ['payload'],
  populateObjectTreeTagsObj: ['payload'],
  populateObjectTreeSelectedObjs: ['payload'],
  setSingleTagObj: ['payload'],
  setSelectedTabs: ['payload'],
  deletingTags: ['payload'],
  setSelectedItemObj: ['payload'],
  universalObjectTreeSetter: ['payload'],
  UniversalReducerSetterTree: ['payload'],
  PopulateTreeStructure: ['payload'],

  getObjectTreeData: ['payload'],
  getObjectTreeDataV2: ['payload'],
  getObjectTreeDataV3: ['payload'],
  getObjectTreeDataStart: ['payload'],
  getObjectTreeDataSuccess: ['payload'],
  getObjectTreeDataV3Success: ['payload'],
  getObjectTreeDataFail: ['payload'],

  getObjectTreeDependencyDataStart: ['payload'],
  getObjectTreeDependencyDataSuccess: ['payload'],
  getObjectTreeDependencyDataFail: ['payload'],

  resetFilterState: ['payload'],
  clearTreeData: ['payload'],
  clearSelectedFilter: ['payload'],

  populateObjectTreeData: ['payload'],
  setBulkObjectTreeDetails: ['payload']
});

export { Types, Creators };
