/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SubMenuOptionsView from '../../component/Common/SubMenuOptions/SubMenuOptions.view';
import { IframeContainer } from '../../component/ResultView/ShowAllTheFilter';
import { FlexContainer } from '../../globalStyles';
import { apiEndPoints } from '../../services/axios/endPoints';
import { WebService } from '../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';

const DirectIframe = (props) => {
  const [URI, setURI] = useState('');
  const [subMenus, setSubMenus] = useState([]);
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const { activeMenuId, subMenuOptions } = useSelector((state) => state.home);

  const getIframe = (tab) => {
    setActiveSubMenu(tab);
    const url = `${apiEndPoints.getGraphURI}`;
    WebService.post(url, { menuId: activeMenuId, tab })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const { data } = response.data;
          setURI(data.uri);
        }
      })
      .catch((e) => {
        const error = sagaCatchBlockHandling(e);
        console.log('err', error);
      });
  };

  useEffect(() => {
    const foundOptions = subMenuOptions.find((e) => e.path === props.match.path);
    let firstTab;
    if (foundOptions) {
      firstTab = foundOptions?.subMenuOptions[0];
      setSubMenus(foundOptions?.subMenuOptions);
    }
    getIframe(firstTab);
  }, []);

  return (
    <FlexContainer className='col' style={{ paddingLeft: '2.5%', height: '100%' }}>
      <FlexContainer>
        <SubMenuOptionsView subMenus={subMenus} activeSubMenu={activeSubMenu} tabHandler={getIframe} />
      </FlexContainer>
      <IframeContainer src={URI} name='iframe' title='filter-iframe' style={{ border: '0px' }} />;
    </FlexContainer>
  );
};

export default DirectIframe;
