import { isEmpty } from '../../../shared/utility/isEmpty';

export const genericFormValidation = (formStructure, formInput) =>
  formStructure.every((e) => {
    const { isRequired, key } = e;
    if (isRequired) {
      return !isEmpty(formInput?.[key]);
    }
    return true;
  });
