export const xyPlotData = {
  KPV_P5_1_2: {
    data: [
      {
        v: 10.25,
        l: 0
      },
      {
        v: -2.5,
        l: 1
      },
      {
        v: -18.6,
        l: 2
      },
      {
        v: 2.027752458110995,
        l: 3
      },
      {
        v: -17.749411194059427,
        l: 4
      },
      {
        v: -15.839050330063495,
        l: 5
      },
      {
        v: -5.021563113855192,
        l: 6
      },
      {
        v: -25.464143403625695,
        l: 7
      },
      {
        v: -21.858869333812493,
        l: 8
      },
      {
        v: -5.226454796723276,
        l: 9
      }
    ],
    meta: [
      {
        xlabels: 'label',
        ylabels: 'value',
        mapping: {
          value: 'v',
          label: 'l'
        },
        fields: {
          key: 'KPV_P5_1_2',
          displayname: 'THO>>1FAM0103>>KPV>>2D-Code Reader Response (IN)',
          unit: '',
          Message: 'All Points',
          Limit: [
            {
              0.3: '#FF6363'
            },
            {
              0.5: '#ADFFAD'
            },
            {
              1.5: '#FF6363'
            },
            {
              1.7: '#FF6363'
            }
          ],
          Labels: []
        }
      }
    ]
  },
  KPV_P5_1_8: {
    data: [
      {
        v: 210.7436056830822,
        l: 0
      },
      {
        v: -75.15960406070546,
        l: 1
      },
      {
        v: -75.22159291644131,
        l: 2
      },
      {
        v: -15.054870204261874,
        l: 3
      },
      {
        v: -70.48109416478121,
        l: 4
      },
      {
        v: -125.44780330315731,
        l: 5
      },
      {
        v: -55.60193629845965,
        l: 6
      },
      {
        v: -109.5031845413533,
        l: 7
      },
      {
        v: -100.33792301627916,
        l: 8
      },
      {
        v: -121.78625152997404,
        l: 9
      }
    ],
    meta: [
      {
        xlabels: 'label',
        ylabels: 'value',
        mapping: {
          value: 'v',
          label: 'l'
        },
        fields: {
          key: 'KPV_P5_1_8',
          displayname: 'THO>>1FAM0103>>KPV>>VS - POS2 US Power - Side',
          unit: '',
          Message: 'All Points',
          Limit: [
            {
              63: '#FF6363'
            },
            {
              70: '#ADFFAD'
            },
            {
              105: '#FF6363'
            },
            {
              112: '#FF6363'
            }
          ],
          Labels: []
        }
      }
    ]
  }
};
