import { LANGUAGES } from '../constants';

export const localString = {
  [LANGUAGES.ENGLISH]: {
    welcome: 'Welcome',
    brandingTitle: 'Vistrian FactoryLOOK',
    brandingDescription:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. But unfortunately pregnant, but the sauce soft players. Members present ecological sit sauce.',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    forgotPassword: 'Forgot Password',
    login: 'Login',
    search: 'Search',
    forgotPasswordQuery: 'Forgot Password?',
    verifyEmail: 'Verify your email',
    verifyEmailBtnText: 'Verify email',
    backToLogin: 'Back to login',
    emailVerifyError: 'This email is not part of Active directory. Please contact your IT support.',
    verificationCodeMsg: 'Please enter the verification code that you have received in your email',
    resendQuery: "Didn't receive the code?",
    resend: 'Resend',
    codeVal: 'Validate code',
    setPwdMsg: 'Set a new password',
    newPwd: 'Enter new password',
    reEnterPwd: 'Re-enter new password',
    resetPwd: 'Reset password',
    pwdSuccess: 'Password successfully reset',
    to: 'to',
    dummyText: 'dummy',
    // home: 'Home',
    settings: 'settings',
    chooseFromFilter: 'Choose From Filters To See Dashboard',
    apply: 'Apply',
    clear: 'Clear',
    noResult: 'No Result',
    searchFilters: 'Search For Filters',
    plot: 'Plot',
    viewShortlist: 'View Shortlist',
    viewColumns: 'View columns',
    kpvShortlist: 'KPV shortlist',
    plotOptions: 'Plot Options',
    cancel: 'cancel',
    select: 'select',
    save: 'save',
    saveShortlist: 'Save Shortlist',
    sharedShortlist: 'Shared shortlist',
    myPages: 'My Pages',
    searchResults: 'Search Results for',
    kpvDetails: 'KPV details',
    update: 'Update',

    // menu data
    Home: 'Home',
    'Home Desc': 'This is home page of vistrian application',
    Dashboards: 'Dashboards',
    'Dashboards Desc': 'In dashboard you can see performance of all the machines and can get an analysis report',
    OEE: 'OEE',
    'OEE Desc': 'Oee will give you option to filter and see the one you wanted',
    Utilization: 'Utilization',
    'Utilization Desc': 'Total utilization of the machines.',
    Summary: 'Summary',
    'Summary Desc': 'Summary of the machines.',
    Trend: 'Trend',
    'Trend Desc': 'Trend will give show to performance in weekly monthly and quaterly.',
    Comparison: 'Comparison',
    'Comparison Desc': 'Compare all and analysis the outcome.',
    Details: 'Details',
    'Details Desc': 'Details of all the process.',
    Yield: 'Yield',
    'Yield Desc': 'This is yield page.',
    'My Dashboards': 'My Dashboards',
    'My Dashboards Desc': 'Here you can see all the saved filters and redirect to same result quickly.',
    Analytics: 'Analytics',
    'Analytics Desc': 'Analytics will give you option to filter and see the one you wanted',
    'Data View': 'Data View',
    'Data View Desc': 'KPV Data of the machines.',
    'Process Group': 'Process Group',
    'Process Group Desc': 'This is Process Group.',
    Interval: 'Interval',
    'Interval Desc': 'This is the interval.',
    Chart: 'Chart',
    'chart Desc': 'Compare all and analysis the outcome.',
    'Data Verifier': 'Data Verifier',
    'Data Verifier Desc': 'This is data verifier page',
    Review: 'Review',
    'Review Desc': 'This is review page',
    'Production Info': 'Production info',
    'Production Info Desc': 'This is production info',
    'My Pages': 'My pages',
    'My Pages Desc': 'This is My Page',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quaterly: 'Quaterly',
    Yearly: 'Yearly',

    // filter menu dropdown
    'Clear Filter': 'Clear Filter',
    'Save Dashboard': 'Save Dashboard',
    'Print Dashboard': 'Print Dashboard',
    'Share Dashboard': 'Share Dashboard',

    // profile menu
    Filter: 'Filter',
    Logout: 'Logout',

    // kpvlist coloumn names
    KPV: 'KPV',
    L1: 'L1',
    L2: 'L2',
    Timestamp: 'TimeStamp',
    Value: 'Value',
    Action: 'Action',

    // kpvlist shortlist columnn names
    'KPV Name': 'KPV Name',
    'KPV Group': 'KPV Group',
    Machine: 'Machine',
    Plant: 'Plant',

    // edit pane options
    // data chart
    'View Full Screen': 'View Full Screen',
    'Edit Pane': 'Edit Pane',
    'Customise KPVs': 'Customise KPVs',
    'Show Details': 'Show Details',
    'KPV Legends': 'KPV Legends',
    'X-axis': 'X-axis',
    'Y-axis': 'Y-axis',
    Annotations: 'Annotations',
    'Show Overlays': 'Show Overlays',
    'L1 Limits': 'L1 Limits',
    'L2 Limits': 'L2 Limits',
    'Scale Y-Axis': 'Scale Y-Axis',
    Auto: 'Auto',
    'Process Window': 'Process Window',
    Manual: 'Manual',
    'Remove Pane': 'Remove Pane',

    // transition chart
    'Customise Machines': 'Customise Machines',
    Legends: 'Legends',

    // add/edit options
    // data chart
    'Pane Title': 'Pane Title',
    'Annotated points': 'Annotated points',
    'Y-Axis Settings': 'Y-Axis Settings',
    'Single Y-axis': 'Single Y-axis',
    'Multiple Y-axis': 'Multiple Y-axis',
    // transition chart
    'Transition Charts': 'Transition Charts',
    Lots: 'Lots',
    Recipe: 'Recipe',
    Status: 'Status',
    'Pane Details': 'Pane Details',

    // chart options
    'Data Chart': 'Data Chart',
    'Transition Chart': 'Transition Chart',
    'Single Grid': 'Single Grid',
    'Multi Grid': 'Multi Grid',

    Duration: 'Duration',
    Charting: 'Charting',
    Pane: 'Pane',
    Add: 'Add',
    Title: 'Title',
    'Multi Select': 'Multi Select',
    Delete: 'Delete',
    DeleteConfirmation: 'Do you really want to Delete this pane?',
    Charts: 'Charts',
    Edit: 'Edit',
    Discard: 'Discard'
  },
  [LANGUAGES.TEST]: {
    welcome: 'Welcome',
    brandingTitle: 'Vistrian FactoryLOOK',
    brandingDescription:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. But unfortunately pregnant, but the sauce soft players. Members present ecological sit sauce.',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    forgotPassword: 'Forgot Password',
    login: 'Login',
    search: 'Search',
    forgotPasswordQuery: 'Forgot Password?',
    verifyEmail: 'Verify your email',
    verifyEmailBtnText: 'Verify email',
    backToLogin: 'Back to login',
    emailVerifyError: 'This email is not part of Active directory. Please contact your IT support.',
    verificationCodeMsg: 'Please enter the verification code that you have received in your email',
    resendQuery: "Didn't receive the code?",
    resend: 'Resend',
    codeVal: 'Validate code',
    setPwdMsg: 'Set a new password',
    newPwd: 'Enter new password',
    reEnterPwd: 'Re-enter new password',
    resetPwd: 'Reset password',
    pwdSuccess: 'Password successfully reset',
    maintenanceMsg:
      'Dear user, we are updating some of the features and please note the website will be under maintenance from ',
    maintenanceFrom: '20/05/21, 09:00AM IST ',
    to: 'to',
    maintenanceTo: ' 22/05/21, 09:00AM IST',
    dummyText: 'dummy',
    // home: 'Home',
    settings: 'settings',
    chooseFromFilter: 'Choose From Filters To See Dashboard',
    apply: 'Apply',
    clear: 'Clear',
    noResult: 'No Result',
    searchFilters: 'Search For Filters',
    plot: 'Plot',
    viewShortlist: 'View Shortlist',
    viewColumns: 'View columns',
    kpvShortlist: 'KPV shortlist',
    plotOptions: 'Plot Options',
    cancel: 'cancel',
    select: 'select',
    save: 'save',
    saveShortlist: 'Save Shortlist',
    sharedShortlist: 'Shared shortlist',
    myPages: 'My Pages',
    searchResults: 'Search Results for',
    kpvDetails: 'KPV details',

    // menu data
    Home: 'Home test',
    'Home Desc': 'This is home page of vistrian application',
    Dashboards: 'Dashboards',
    'Dashboards Desc': 'In dashboard you can see performance of all the machines and can get an analysis report',
    OEE: 'OEE',
    'OEE Desc': 'Oee will give you option to filter and see the one you wanted',
    Utilization: 'Utilization',
    'Utilization Desc': 'Total utilization of the machines.',
    Summary: 'Summary',
    'Summary Desc': 'Summary of the machines.',
    Trend: 'Trend',
    'Trend Desc': 'Trend will give show to performance in weekly monthly and quaterly.',
    Comparison: 'Comparison',
    'Comparison Desc': 'Compare all and analysis the outcome.',
    Details: 'Details',
    'Details Desc': 'Details of all the process.',
    Yield: 'Yield',
    'Yield Desc': 'This is yield page.',
    'My Dashboards': 'My Dashboards',
    'My Dashboards Desc': 'Here you can see all the saved filters and redirect to same result quickly.',
    Analytics: 'Analytics',
    'Analytics Desc': 'Analytics will give you option to filter and see the one you wanted',
    'Data View': 'Data View',
    'Data View Desc': 'KPV Data of the machines.',
    'Process Group': 'Process Group',
    'Process Group Desc': 'This is Process Group.',
    Interval: 'Interval',
    'Interval Desc': 'This is the interval.',
    Chart: 'Chart',
    'chart Desc': 'Compare all and analysis the outcome.',
    'Data Verifier': 'Data Verifier',
    'Data Verifier Desc': 'This is data verifier page',
    Review: 'Review',
    'Review Desc': 'This is review page',
    'Production Info': 'Production info',
    'Production Info Desc': 'This is production info',
    'My Pages': 'My pages',
    'My Pages Desc': 'This is My Page',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quaterly: 'Quaterly',
    Yearly: 'Yearly',

    // kpv coloumn names
    KPV: 'KPV-test',
    L1: 'L1-test',
    L2: 'L2-test',
    Timestamp: 'TimeStamp-test',
    Value: 'value-test',
    Action: 'action-test'
  }
};
