/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import { createReducer } from 'reduxsauce';
// import { CHART_CATEGORIES, WIDGET_CATEGORIES } from '../../../constants';
import { getFieldDependents, getFieldDependentsOfTemplate, getTemplateFormStructure } from '../WidgetForm/Utility';
import { Types } from './action';

const INITIAL_STATE = {
  widgetDashboardTitle: '',
  customTemplateTab: false,
  // side panel form
  widgetName: '',
  widgetInfo: '',
  shareWidget: false,
  widgetType: '',
  widgetLabel: '',
  // TODO_W_R: add widget category
  widgetCategory: '',
  // donut info
  inputByDonut: {},
  donutData: [],
  donutMeta: [],
  error: '',
  // status value info
  equipments: [],
  meta: {},
  selectedFilteredArray: [],
  // form inputs made
  formStructure: [],
  formStructureMeta: {},
  formStructureFieldDepenents: {},
  formStructureError: '',
  // template form
  showTemplateListSidePanel: false,
  showInstanceTablePopUp: false,
  templateName: '',
  templateCreatorName: '',
  instanceCreatorName: '',
  WTId: '',
  templateFormStructure: [],
  templateFormStructureMeta: {},
  templateFormStructureFieldDepenents: {},
  templateFormStructureError: '',

  templateBasedData: {},

  // form inputs
  formInput: {}, // widgets creation/updation form
  templateFormInput: {}, // tempalate form
  WTIId: '',
  templateInstanceName: '',
  templateInstanceFormInput: {}, // template instance form

  templateLayoutData: {},
  templateLayoutMeta: {},
  layoutWPID: null,

  // bar chart
  barChartDetails: {},
  loading: false,
  donutInfo: '',
  widgetInstancesData: [],
  widgetInstancesMeta: {},

  widgetEditOptions: {}
};

const setWidgetEditOptions = (state = INITIAL_STATE, { payload }) => {
  const { key, value } = payload;
  return {
    ...state,
    widgetEditOptions: {
      ...state.widgetEditOptions,
      [key]: value
    }
  };
};

const setWidgetType = (state = INITIAL_STATE, action) => {
  const { widgetType, widgetLabel, widgetCategory } = action.payload;
  return {
    ...state,
    widgetType,
    widgetLabel,
    widgetCategory
  };
};

const setTemplateBasedData = (state = INITIAL_STATE, action) => ({
  ...state,
  templateBasedData: action.payload
});

/**
 * Reducer to set Data associated with the panel layout for attaching the data while
 * creating a new Template instance
 */

const setTemplateLayoutData = (state = INITIAL_STATE, action) => {
  const { WidgetPanelLayoutData, WidgetPanelLayoutMetaData, WPID } = action.payload;
  return {
    ...state,
    templateLayoutData: WidgetPanelLayoutData,
    templateLayoutMeta: WidgetPanelLayoutMetaData,
    layoutWPID: WPID
  };
};

const setFormInput = (state = INITIAL_STATE, action) => ({
  ...state,
  formInput: action.payload
});

const addWidgetStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const addWidgetSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false
});

const addWidgetFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload
});

const valueSetter = (state = INITIAL_STATE, action) =>
// console.log(action.payload, 'payload');
  ({
    ...state,
    ...action.payload
  });
const resetData = (state = INITIAL_STATE) => ({
  ...INITIAL_STATE,
  widgetType: state.widgetType,
  widgetDashboardTitle: state.widgetDashboardTitle
});

const resetTemplateInstanceData = (state = INITIAL_STATE) => ({
  ...state,
  templateInstanceName: INITIAL_STATE.templateInstanceName,
  templateInstanceFormInput: INITIAL_STATE.templateInstanceFormInput
});

const DetailedStatusValueFormSubmission = (state = INITIAL_STATE, action) => {
  const { filteredEquipments } = action.payload;
  return {
    ...state,
    selectedFilteredArray: [...state.selectedFilteredArray, filteredEquipments[filteredEquipments.length - 1]]
  };
};

const removeEquipment = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedFilteredArray: action.payload
});

const inputSetter = (state = INITIAL_STATE, action) => ({
  ...state,
  inputByDonut: action.payload
});

const getWidgetDataStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getWidgetDataSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, 'succes payload');
  const { data, meta, detailsMapping } = action.payload;
  if (!detailsMapping) {
    return {
      ...state,
      ...action.payload,
      loading: false
    };
  }
  return {
    ...state,
    [detailsMapping.data]: data,
    [detailsMapping.meta]: meta,
    loading: false
  };
};

const getWidgetDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload
});

const setDonutInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  donutInfo: action.payload
});

const getWidgetInstancesStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getWidgetInstancesSuccess = (state = INITIAL_STATE, action) => {
  const { widgetData, widgetMeta } = action.payload;
  return {
    ...state,
    widgetInstancesData: widgetData,
    widgetInstancesMeta: widgetMeta,
    loading: false
  };
};

const getWidgetInstancesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload
});
const setFormStructureStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  formStructure: INITIAL_STATE.formStructure,
  formStructureError: INITIAL_STATE.formStructureError
});

const setFormStructureSuccess = (state = INITIAL_STATE, action) => {
  const { WidgetFormDetails } = action.payload;
  const { data, meta } = WidgetFormDetails || {};
  const fieldDependents = getFieldDependents(data);
  return {
    ...state,
    loading: false,
    formStructure: data,
    formStructureMeta: meta,
    formStructureFieldDepenents: fieldDependents,
    formStructureError: INITIAL_STATE.formStructureError
  };
};
const setFormStructureFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  // formStructure: INITIAL_STATE.formStructure,
  formStructureError: action.payload,
  loading: false
});
const setTemplateFormStructureStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  templateFormStructure: INITIAL_STATE.formStructure,
  templateFormStructureError: INITIAL_STATE.formStructureError
});

const setTemplateFormStructureSuccess = (state = INITIAL_STATE, action) => {
  const WidgetFormDetails = action.payload;
  const data = getTemplateFormStructure(WidgetFormDetails);
  const fieldDependents = getFieldDependentsOfTemplate(data);
  return {
    ...state,
    loading: false,
    templateFormStructure: data,
    templateFormStructureFieldDepenents: fieldDependents,
    templateFormStructureError: INITIAL_STATE.formStructureError
  };
};
const setTemplateFormStructureFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  // formStructure: INITIAL_STATE.formStructure,
  templateFormStructureError: action.payload,
  loading: false
});

const setTemplateInstanceDetails = (state = INITIAL_STATE, action) => {
  const { templateName, templateCreatorName, templateFormInput, widgetTypesIncluded, WTId, WPId } = action.payload;
  return {
    ...state,
    templateName,
    templateCreatorName,
    templateFormInput,
    widgetTypesIncluded,
    WTId,
    WPId
  };
};

const resetTemplateInstanceDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  templateName: '',
  WTId: '',

});
const setTemplateFormInput = (state = INITIAL_STATE, action) => {
  const { templateFormInput } = action.payload;
  return {
    ...state,
    templateFormInput
  };
};
const setBulkUpsertWidgetDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});
const resetTemplateInstanceForm = (state = INITIAL_STATE, action) => ({
  ...state,
  WTIId: INITIAL_STATE.WTIId,
  templateInstanceName: INITIAL_STATE.templateInstanceName,
  templateInstanceFormInput: INITIAL_STATE.templateInstanceFormInput
});

const HANDLERS = {
  [Types.SET_WIDGET_EDIT_OPTIONS]: setWidgetEditOptions,
  [Types.SET_WIDGET_TYPE]: setWidgetType,
  [Types.SET_FORM_INPUT]: setFormInput,
  [Types.SET_TEMPLATE_FORM_STRUCTURE_START]: setTemplateFormStructureStart,
  [Types.SET_TEMPLATE_FORM_STRUCTURE_SUCCESS]: setTemplateFormStructureSuccess,
  [Types.SET_TEMPLATE_FORM_STRUCTURE_FAILURE]: setTemplateFormStructureFailure,
  [Types.SET_FORM_STRUCTURE_START]: setFormStructureStart,
  [Types.SET_FORM_STRUCTURE_SUCCESS]: setFormStructureSuccess,
  [Types.SET_FORM_STRUCTURE_FAILURE]: setFormStructureFailure,
  [Types.VALUE_SETTER]: valueSetter,
  [Types.RESET_DATA]: resetData,
  [Types.RESET_TEMPLATE_INSTANCE_DATA]: resetTemplateInstanceData,
  [Types.SET_TEMPLATE_INSTANCE_DETAILS]: setTemplateInstanceDetails,
  // donut
  [Types.INPUT_SETTER]: inputSetter,

  // add widget
  [Types.ADD_WIDGET_START]: addWidgetStart,
  [Types.ADD_WIDGET_SUCCESS]: addWidgetSuccess,
  [Types.ADD_WIDGET_FAILURE]: addWidgetFailure,
  [Types.DETAILED_STATUS_VALUE_FORM_SUBMISSION]: DetailedStatusValueFormSubmission,
  [Types.REMOVE_EQUIPMENT]: removeEquipment,

  // reducers for widget data
  [Types.GET_WIDGET_DATA_START]: getWidgetDataStart,
  [Types.GET_WIDGET_DATA_SUCCESS]: getWidgetDataSuccess,
  [Types.GET_WIDGET_DATA_FAILURE]: getWidgetDataFailure,
  [Types.SET_DONUT_INFO]: setDonutInfo,
  [Types.GET_WIDGET_INSTANCES_START]: getWidgetInstancesStart,
  [Types.GET_WIDGET_INSTANCES_SUCCESS]: getWidgetInstancesSuccess,
  [Types.GET_WIDGET_INSTANCES_FAILURE]: getWidgetInstancesFailure,
  [Types.SET_BULK_UPSERT_WIDGET_DETAILS]: setBulkUpsertWidgetDetails,
  [Types.RESET_TEMPLATE_INSTANCE_FORM]: resetTemplateInstanceForm,
  [Types.SET_TEMPLATE_LAYOUT_DATA]: setTemplateLayoutData,
  [Types.SET_TEMPLATE_BASED_DATA]: setTemplateBasedData,
  [Types.SET_TEMPLATE_FORM_INPUT]: setTemplateFormInput,
  [Types.RESET_TEMPLATE_INSTANCE_DETAILS]: resetTemplateInstanceDetails,
};

export const UpsertWidgetReducer = createReducer(INITIAL_STATE, HANDLERS);
