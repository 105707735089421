// import { isEmpty } from '../../../../shared/utility/isEmpty';

import { DATA_TYPES, FILTER_TERMINOLOGY, INPUT_TYPES, WIDGET_CATEGORIES, WIDGET_CATEGORIES_EXCLUDED_COMMON } from '../../../../constants';

/* eslint-disable max-len */
export const getReqPayloadForDependentField = (formInput, dependencies, formStructure) => {
  const formStructureFormat = formStructure.reduce((acc, field) => {
    const { key, ...rest } = field;
    return {
      ...acc,
      [key]: { ...rest }
    };
  }, {});
  return dependencies?.reduce((params, field) => {
    // console.log('formInput', formInput, dependencies);
    let value = formInput?.[field];
    const { inputType, dataSource } = formStructureFormat?.[field];
    const { dependentParamDetails } = dataSource || {};
    const { type, paramMapping } = dependentParamDetails || {};
    // console.log('inp', inputType, dependentParamType, field, formInput?.[field], formStructureFormat);
    if (typeof formInput?.[field] === 'object') {
      if (Array.isArray(formInput?.[field])) {
        value = [...formInput?.[field]];
        let attributeName;
        // convert JSON to leaf value
        if (inputType === INPUT_TYPES.FILTER_SEARCH && type === FILTER_TERMINOLOGY.LEAF_NODE) {
          let filterTree = formInput?.[field]?.[0]?.value?.tree;
          while (filterTree?.values) {
            attributeName = filterTree.attribute;
            // eslint-disable-next-line prefer-destructuring
            filterTree = filterTree.values[0];
            value = filterTree;
          }
          // formating the payload required for that field from paramMapping
          const fieldPayload = Object.entries(paramMapping || {}).reduce((acc, detail) => {
            const [requiredDetailKey, existingDetailKey] = detail;
            return {
              ...acc,
              [requiredDetailKey]: existingDetailKey === 'attribute' ? attributeName : value?.[existingDetailKey]
            };
          }, {});
          // exception RETURN
          return { ...params, ...(fieldPayload || {}) };
        }
      } else {
        value = { ...formInput?.[field] };
      }
    }
    return { ...params, [field]: value };
  }, {});
};

export const getResetPayloadForDependentFields = (dependents, formInput) =>
  dependents?.reduce((params, field) => {
    let value = '';
    if (typeof formInput?.[field] === 'object') {
      if (Array.isArray(formInput?.[field])) {
        value = [];
      } else {
        value = {};
      }
    }
    return { ...params, [field]: value };
  }, {});

// dependent fields for each field
export const getFieldDependents = (formStructure) =>
  formStructure.reduce((acc, field) => {
    const { key } = field;
    const dependents = formStructure
      .filter((e) => {
        const dependencies = e.dataSource?.dependencies;
        return dependencies?.includes(key);
      })
      ?.map((e) => e.key);
    return {
      ...acc,
      [key]: dependents
    };
  }, {});

export const getFieldDependentsOfTemplate = (templateForm) =>
  Object.entries(templateForm).reduce((acc, categoryFieldSet) => {
    const [category, fieldSet] = categoryFieldSet;
    return {
      ...acc,
      [category]: getFieldDependents(fieldSet)
    };
  }, {});

export const getTemplateFormStructure = (forms) => {
  const aggregatedForm = {
    common: {}
  };
  forms.forEach((form) => {
    const { type, WidgetFormDetails } = form;
    aggregatedForm[type] = [];
    Object.values(WidgetFormDetails.data).forEach((field) => {
      const { key: fieldKey, dataSource } = field;

      // check, if the field is present in all the widget type forms

      // Old Code
      // if (!aggregatedForm.common?.fieldKey) {
      //   const isPresent = forms.every((form) => {
      //     const {
      //       WidgetFormDetails: { data }
      //     } = form;
      //     return data[fieldKey] && true;
      //   });
      // This code was written not to consider TEXT/HTML and TABLE in common widgets
      const modiWidget = [...forms];
      const commonModiWidget = [];
      forms?.forEach((item, ind) => {
        if (!WIDGET_CATEGORIES_EXCLUDED_COMMON?.includes(item?.type)) {
          commonModiWidget.push(item);
        }
      });
      if (!aggregatedForm.common?.fieldKey) {
        const isPresent = commonModiWidget.every((form) => {
          const {
            WidgetFormDetails: { data }
          } = form;
          return data[fieldKey] && true;
        });
        // if the dependencies list has any common parameter.,
        // which implies this field should also be shown as common fieldset
        const isDependent = dataSource?.dependencies?.some((e) => aggregatedForm.common[e] && true);
        if (isPresent || isDependent) {
          aggregatedForm.common[fieldKey] = field;
        } else {
          aggregatedForm[type].push(field);
        }
      }
    });
  });
  return {
    ...aggregatedForm,
    common: Object.values(aggregatedForm.common)
  };
};

// export const isTheControlledInputChanged = (controlledInputs, formInput, itemKey) => {
//   if (!isEmpty(controlledInputs)) {
//     return Object.entries(controlledInputs).every((inp) => {
//       const [inpKey, inpVal] = inp;
//       console.log(
//         'checking for removing condition',
//         inpVal,
//         '1',
//         inpVal === 'undefined' || inpVal === '',
//         formInput[inpKey],
//         '2',
//         formInput[inpKey] === '' || formInput[inpVal] === 'undefined'
//       );
//       if (
//         (inpVal === 'undefined' || inpVal === '') &&
//         (formInput[inpKey] === '' || formInput[inpVal] === 'undefined')
//       ) {
//         console.log('returning true as undefined', inpKey, inpVal);
//         return true;
//       }
//       console.log('inpVal === formInput[inpKey]', inpKey, 'inpVal', inpVal, 'formInput[inpKey]', formInput[inpKey]);
//       if (typeof inpVal === 'object') {
//         if (Array.isArray(inpVal)) {
//           // comparing the values of array type
//           return (
//             formInput[inpKey]?.length === inpVal.length &&
//             inpVal.every((e, index) => e[itemKey] === formInput[inpKey]?.[index]?.[itemKey])
//           );
//         }
//         // comparing the object item
//         return JSON.stringify(inpVal) === (formInput[inpKey] && JSON.stringify(formInput[inpKey]));
//       }
//       return inpVal === formInput[inpKey];
//     });
//   }
//   return false;
// };
