import styled from 'styled-components';

export const ProgressParent = styled.div`
height: 3px;
width: 100%;
background-color: white;
border-radius: 40px;
/* margin: 50px; */
`;

export const ProgressRuler = styled.div`
 height: 100%;
width: ${({ width }) => width};
background-color: ${({ theme }) => theme.contrast.primary};
border-radius:40px;
`;
