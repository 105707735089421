/* eslint-disable prefer-template */
/* eslint-disable indent */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterResultCardContainer,
  FilterResultContent,
  FilterResultHeader,
  FilterResultHeaderText,
  FilterResultCardMainContainer,
  FilterCardBottomSpace,
  FilterResultContentContainer
} from './FilterResultCard.style';
import FilterContentContainer from '../FilterContentContainer/FilterContentContainer';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
import FilterChildrenContent from '../FilterChildrenContent/FilterChildrenContent';
import { isParentSelected } from '../isItemSelected';
import Radiobutton from '../../Common/RadioButton/RadioButton';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { generateSelectedFilter } from '../../ResultView/Iframe/generateSelectedFilter';
import Loader from '../../Loader/Loader';
import { calenderTypes } from '../../RangeSelectors/DateRangePicker/constants';

function FilterResultCard({
  item,
  handleParentClick,
  handleChildrenClick,
  handleMultiSelectClick,
  isPreSelection,
  hasPreSelection,
  ignoreActiveOption,
  isInstanceCreation

}) {
  // console.log('handleParentClick', handleParentClick);
  const {
    tags,
    cumulativeTags,
    deletingTag,
    selectedItem,
    [item.groupTitle]: selectedObj,
    filterData,
    [`cumulative${item.groupTitle}`]: cummSelectedObj,
    leafLevels,
    breakpoint,
    tempTagsCumulative,
    tempTags,
    dependents,
    dependentOn,
    meta,
    dependencies,
    keyTitleMapping,
    depedentLoading,
    menuIdofFilter,
    selectedParent
  } = useSelector((state) => state.filterData);
  const { templateBasedData } = useSelector((state) => state.upsertWidgetData);
  const dispatch = useDispatch();
  const addTabToObj = useCallback(
    (payload) => dispatch(FilterCreators.universalFilterSetter(payload)),
    [dispatch]
  );
  const addCummulativeTags = useCallback(
    (payload) => dispatch(FilterCreators.setCummulativeTags(payload)),
    [dispatch]
  );
  const addTags = useCallback(
    (payload) => dispatch(FilterCreators.setTags(payload)),
    [dispatch]
  );

  // useEffect(() => {
  //   if (isInstanceCreation) {
  //   if (templateBasedData?.Plant) {
  //     dispatch(FilterCreators.UniversalReducerSetter(templateBasedData?.Plant));
  //   }
  // }
  // }, []);

  // removing incomplete paths, computation of that
  useEffect(() => {
    if (selectedObj) {
      const incompletePaths = [];
      const activeKeys = Object.keys(selectedObj);
      if (cummSelectedObj) {
        Object.keys(cummSelectedObj)?.forEach((pathNo) => {
          const keys = Object.keys(cummSelectedObj[pathNo]);
          const leafIndex = Object.keys(cummSelectedObj[pathNo]).length - 1;
          // condition for checking the nonleaf items other than the active selected obj
          if (
            // condition for checking the attribute before the last group set
            // as the last group has the atrribute in the parent
            item.groupTitle !== leafLevels[item.groupTitle] &&
            // handle selection with only one level
            (cummSelectedObj[pathNo][keys[leafIndex - 1]]
              ? cummSelectedObj[pathNo][keys[leafIndex - 1]].attribute !==
                leafLevels[item.groupTitle]
              : true) &&
            cummSelectedObj[pathNo][keys[leafIndex]]?.values?.length &&
            JSON.stringify(keys) !== JSON.stringify(activeKeys)
          ) {
            incompletePaths.push(pathNo);
          }
        });
      }
      // console.log('incompletePaths', incompletePaths);
      const tempCumObj = { ...cummSelectedObj };
      const tempCumTag = { ...cumulativeTags[item.groupTitle] };
      if (incompletePaths.length > 0) {
        incompletePaths.forEach((pathNo) => {
          delete tempCumObj[pathNo];
          delete tempCumTag[pathNo];
        });
      }

      addTabToObj({
        key: `cumulative${item.groupTitle}`,
        value: tempCumObj
      });
      addCummulativeTags({
        ...cumulativeTags,
        [item.groupTitle]: tempCumTag
      });
    }
  }, [selectedObj]);
  // [leafLevels]

  // computes the new selection objects and respective tags
  // when change in leaf attribute group
  const [computeLeafLevel, setComputeLeafLevel] = useState('');
  useEffect(() => {
    try {
      // computing the leaf level in the group based on the locking
      // which is stored in leafLevels with key as grouptitle and attribute as the value
      let ref = filterData.find((grp) => grp.groupTitle === item.groupTitle);
      let level = 0;
      if (item.groupTitle !== leafLevels[item.groupTitle]) {
        while (ref) {
          if (ref.attribute === leafLevels[item.groupTitle]) {
            break;
          }
          if (ref.values && ref.values[0]?.attribute && ref.values[0].values) {
            ref = ref.values[0];
            level += 1;
          } else break;
        }
      }

      // check is required during tab change with lock applied
      const isLockable = ref.lockable;
      // console.log('isLockable', ref.attribute, isLockable);
      if (isLockable) setComputeLeafLevel(level);
      else {
        // remove the lock as it is not lockable
        delete leafLevels[item.groupTitle];
      }

      if (selectedObj) {
        // computing the tags, objects due to change in leaf
        let tempCumObj = {};
        let tempObj = {};
        let makeNextLevelLeaf = false;
        const tempCumTag = {};
        const tempTags = { ...tags };
        tempCumTag[item.groupTitle] = {};
        tempTags[item.groupTitle] = {};
        // change cumobj and cimtags
        Object.keys(cummSelectedObj).forEach((pathNo) => {
          makeNextLevelLeaf = false;
          Object.keys(cummSelectedObj[pathNo]).every((levelId, level) => {
            if (!tempCumObj[pathNo]) {
              tempCumObj = { ...tempCumObj, [pathNo]: {} };
              tempCumTag[item.groupTitle] = {
                ...tempCumTag[item.groupTitle],
                [pathNo]: {}
              };
            }
            tempCumObj[pathNo] = {
              ...tempCumObj[pathNo],
              [levelId]: cummSelectedObj[pathNo][levelId]
            };
            tempCumTag[item.groupTitle][pathNo] = {
              ...tempCumTag[item.groupTitle][pathNo],
              [level]: cumulativeTags[item.groupTitle][pathNo][level]
            };
            // console.log('tempCumObj', leafLevels[item.groupTitle], item.groupTitle, levelId, tempCumObj, tempCumTag);
            if (leafLevels[item.groupTitle] === item.groupTitle) {
              return false;
            }
            if (makeNextLevelLeaf) return false;
            if (
              cummSelectedObj[pathNo][levelId].attribute ===
              leafLevels[item.groupTitle]
            ) {
              makeNextLevelLeaf = true;
            }
            return true;
          });
        });

        // remove redundent paths
        const nonUniqueKeyList = Object.keys(tempCumObj).filter((p, index) => {
          return Object.keys(tempCumObj)
            .slice(index + 1)
            .some(
              (p2) =>
                Object.keys(tempCumObj[p]).toString() ===
                Object.keys(tempCumObj[p2]).toString()
            );
        });
        // console.log('check', nonUniqueKeyList);

        nonUniqueKeyList.forEach((p) => {
          delete tempCumObj[p];
          // check
          delete tempCumTag[item.groupTitle][p];
        });

        // console.log('final', tempCumObj, tempCumTag);
        addTabToObj({
          key: `cumulative${item.groupTitle}`,
          value: tempCumObj
        });
        addCummulativeTags({
          ...cumulativeTags,
          ...tempCumTag
        });

        // change selectedObj and tags
        makeNextLevelLeaf = false;
        Object.keys(selectedObj).every((levelId, level) => {
          tempObj = { ...tempObj, [levelId]: selectedObj[levelId] };
          tempTags[item.groupTitle] = {
            ...tempTags[item.groupTitle],
            [level]: tags[item.groupTitle] ? tags[item.groupTitle][level] : {}
          };
          // console.log('tempObj', selectedObj[levelId].attribute, leafLevels[item.groupTitle]);
          if (makeNextLevelLeaf) return false;
          if (leafLevels[item.groupTitle] === item.groupTitle) {
            return false;
          }
          if (selectedObj[levelId].attribute === leafLevels[item.groupTitle]) {
            makeNextLevelLeaf = true;
          }

          return true;
        });
        // console.log('tempObj==>final', tempObj, selectedObj, tempTags);
        addTabToObj({
          key: item.groupTitle,
          value: tempObj
        });
        addTags({ ...tempTags });
      }
    } catch (e) {
      console.log('Issue in filter selection', e);
    }
  }, [leafLevels[item.groupTitle]]);

  const isGroupCompletlySelected = () => {
    let check = false;

    // console.log('check-1', !isEmpty(cumulativeTags[item.groupTitle]) && !isEmpty(cummSelectedObj));
    if (
      !isEmpty(cumulativeTags[item.groupTitle]) &&
      !isEmpty(cummSelectedObj)
    ) {
      check = Object.keys(cumulativeTags[item.groupTitle]).every((pathNo) => {
        if (cummSelectedObj[pathNo]) {
          const lastLevel = Object.keys(
            cumulativeTags[item.groupTitle][pathNo]
          ).length;
          const lastItemSelected =
            cummSelectedObj[pathNo][
              cumulativeTags[item.groupTitle][pathNo][lastLevel - 1]?.id
            ];

          // TODO: required not used correctly, the next level is not required
          // enable after change in api response
          // if (!lastItemSelected.required || lastItemSelected.required === 'false') {
          //   // console.log('isRequired not required', item.groupTitle);
          //   return true;
          // }

          if (
            Array.isArray(
              cumulativeTags[item.groupTitle][pathNo][lastLevel - 1]
            )
          ) {
            return (
              cumulativeTags[item.groupTitle][pathNo][lastLevel - 1].length > 0
            );
          }
          // console.log(
          //   'group selected',
          //   item.groupTitle,
          //   cummSelectedObj[pathNo],
          //   cummSelectedObj,
          //   cummSelectedObj[pathNo] &&
          //     !cummSelectedObj[pathNo][cumulativeTags[item.groupTitle][pathNo][lastLevel - 1]?.id]?.values?.length > 0
          // );
          // console.log('cummSelectedObj[pathNo]', cummSelectedObj[pathNo]);
          const keys = Object.keys(cummSelectedObj[pathNo]);
          const leafIndex = Object.keys(cummSelectedObj[pathNo]).length - 1;
          // check if the level is selected as leaf
          if (leafLevels[item.groupTitle]) {
            if (item.groupTitle === leafLevels[item.groupTitle]) {
              return true;
            }
            if (
              cummSelectedObj[pathNo][keys[leafIndex - 1]]?.attribute ===
              leafLevels[item.groupTitle]
            ) {
              return true;
            }
            return false;
          }
          if (
            // condition for checking type of values
            lastItemSelected?.values === null ||
            typeof lastItemSelected?.values !== 'object' ||
            lastItemSelected?.isEnd
          ) {
            // console.log(
            //   'treating as true(enabled) can be due to no further values or because of isEnd flag',
            //   lastItemSelected
            // );
            // values should not be undefined for quarter type
            if (lastItemSelected?.type === calenderTypes.QUARTER) {
              if (!lastItemSelected.rawValue) {
                return false;
              }
            }
            return true;
          }
          return (
            // check for if the values are present or not
            lastItemSelected?.values?.length < 1
          );
        }
        return false;
      });
    }
    // console.log('check', item.groupTitle, check);
    return check;
  };

  // compute selected item list when change in selectionObj
  useEffect(() => {
    const result = isGroupCompletlySelected();
    // console.log('checking...', item.groupTitle, result);
    if (!result && selectedItem.includes(item.groupTitle)) {
      const copiedSelectedArr = [...selectedItem];
      const newSelectedArr = copiedSelectedArr.filter(
        (i) => i !== item.groupTitle
      );
      dispatch(FilterCreators.setSelectedItem(newSelectedArr));
    }
    if (result && selectedItem.findIndex((i) => i === item.groupTitle) === -1) {
      const copiedSelectedArr = [...selectedItem];
      copiedSelectedArr.push(item.groupTitle);
      dispatch(FilterCreators.setSelectedItem(copiedSelectedArr));
    }
  }, [cummSelectedObj]);
  // }, [selectedObj]);
  // console.log('item', selectedObj, selectedObj && Object.keys(selectedObj));

  const handleLeaf = (value) => {
    if (isPreSelection) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'breakpoint',
          value: {
            ...breakpoint,
            [item.groupTitle]:
              breakpoint?.[item.groupTitle] === -1 ? undefined : -1
          }
        })
      );
    } else {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'leafLevels',
          value: {
            ...leafLevels,
            [item.groupTitle]:
              leafLevels[item.groupTitle] === value ? '' : value
          }
        })
      );
    }
  };

  // const [attributes, setAttributes] = useState([]);

  // const getValues = (id, selectedIds) => {
  //   // item
  //   console.log('idss', id, item, selectedIds);
  //   let ref = item.values;
  //   let refEle = null;
  //   let index = 0;
  //   while (ref && index < selectedIds.length) {
  //     console.log('ref', ref);
  //     // eslint-disable-next-line no-loop-func
  //     refEle = ref.find((e) => {
  //       console.log('ref-inside', e, index, selectedIds[index]);
  //       return '_' + e.id === selectedIds[index];
  //     });
  //     console.log('ref-ind', refEle);
  //     ref = refEle?.values;
  //     index += 1;
  //   }
  //   console.log('ref-final===>return', refEle.values);
  //   return refEle.values;
  // };

  // compute selected obj and tags due to change in filter data menu
  useEffect(() => {
    // computing all the attributes for displaying even before selection.
    const tempattributes = [];
    const selectionTypesDefined = {};
    let ref = filterData.find((grp) => grp.groupTitle === item.groupTitle);
    // selectionTypesDefined[ref.attribute] = ref.selectionType;
    while (ref) {
      selectionTypesDefined[ref.attribute] = ref.selectionType;
      tempattributes.push(ref.attribute);
      if (
        !isEmpty(ref.values) &&
        ref.values[0]?.attribute &&
        ref.values[0].values
      ) { ref = ref.values[0]; } else break;
    }
    // setAttributes(tempattributes);

    const tempCumObj = {};
    // const tempCumTags = { ...cumulativeTags };
    const tempCumTags = {};
    tempCumTags[item.groupTitle] = {};
    // refactoring cumulative tags and objects due to change in no. of levels in a group
    if (cummSelectedObj) {
      Object.keys(cummSelectedObj).forEach((pathNo) => {
        let ref = item.values;
        // checking if the item is present or not
        if (
          ref.some(
            (ele) => '_' + ele.id === Object.keys(cummSelectedObj[pathNo])[0]
          )
        ) {
          tempCumObj[pathNo] = {};
          tempCumTags[item.groupTitle][pathNo] = {};
        }
        let isNextValid = true;
        Object.keys(cummSelectedObj[pathNo]).every((levelKey, level) => {
          if (!isNextValid) return false;
          const temp = ref.find((ele) => '_' + ele.id === levelKey);
          // console.log('selectionType', temp, temp.selectionType);
          // check if the attribute is present in the new set of attributes
          if (
            cummSelectedObj[pathNo][levelKey].attribute &&
            item.attribute !== 'Calendar' &&
            !tempattributes.includes(temp?.attribute)
          ) {
            // console.log('next is false');
            isNextValid = false;
          }
          if (!isNextValid && temp) {
            tempCumObj[pathNo][levelKey] = {
              ...cummSelectedObj[pathNo][levelKey],
              ...temp,
              values: null
            };
            tempCumTags[item.groupTitle][pathNo][level] =
              cumulativeTags[item.groupTitle][pathNo][level];
          } else {
            // console.log(
            //   'ceec',
            //   cummSelectedObj[pathNo][levelKey],
            //   cummSelectedObj[pathNo][levelKey].values ||
            //     cummSelectedObj[pathNo][levelKey].bufferValues ||
            //     getValues(levelKey, Object.keys(cummSelectedObj[pathNo]).slice(0, level + 2))
            // );
            // find the values w.r.t item but not w.r.t old object
            try {
              // console.log('lvele', levelKey);
              tempCumObj[pathNo][levelKey] = {
                ...cummSelectedObj[pathNo][levelKey],
                // values: temp.values,
                // attribute: temp.attribute,
                // selectionType: temp.selectionType,
                ...temp

                // cummSelectedObj[pathNo][levelKey].values ||
                // cummSelectedObj[pathNo][levelKey].bufferValues ||
                // getValues(levelKey, Object.keys(cummSelectedObj[pathNo]).slice(0, level + 2))
              };
              tempCumTags[item.groupTitle][pathNo][level] =
                cumulativeTags[item.groupTitle][pathNo][level];
            } catch (e) {
              console.log('catched', e);
              return false;
            }
            ref = tempCumObj[pathNo][levelKey].values;
            if (ref) {
              return true;
            }
            return false;
          }
          // tempCumTags[item.groupTitle][pathNo][level] = cumulativeTags[item.groupTitle][pathNo][level];
          return true;
        });
      });
      // checking for selection types if it is single drop all the paths except the first
      const payload = generateSelectedFilter(tempCumTags, filterData);
      // console.log('p', payload, payload[Object.keys(payload)[0]]);
      const referencePointer = payload[Object.keys(payload)[0]];
      const removePaths = (referencePointer) => {
        if (!isEmpty(referencePointer.values)) {
          // console.log('removing-1', referencePointer.attribute, selectionTypesDefined[referencePointer.attribute]);
          if (
            selectionTypesDefined[referencePointer.attribute] === 'singleselect'
          ) {
            referencePointer.values.slice(1).forEach((e) => {
              // console.log('removing', e.id);
              Object.keys(tempCumObj).every((pathNo) => {
                if (
                  Object.keys(tempCumObj[pathNo]).some((key) => {
                    return key === `_${e.id}`;
                  })
                ) {
                  // console.log('removing-pathno', pathNo);
                  delete tempCumObj[pathNo];
                  // check
                  delete tempCumTags[item.groupTitle][pathNo];
                }
                return true;
              });
            });
            removePaths(referencePointer.values[0]);
          } else {
            referencePointer.values.forEach((val) => {
              removePaths(val);
            });
          }
        }
      };
      removePaths(referencePointer);
      // console.log('revised tags and objects', tempCumObj, tempattributes);
      addTabToObj({
        key: `cumulative${item.groupTitle}`,
        value: tempCumObj
      });
      addTabToObj({
        key: item.groupTitle,
        value: tempCumObj[Object.keys(tempCumObj)[0]]
      });

      const tempCumTagObj = tempCumTags[item.groupTitle];
      cumulativeTags[item.groupTitle] = { ...tempCumTagObj };
      tags[item.groupTitle] = { ...tempCumTagObj[Object.keys(tempCumObj)[0]] };

      // old
      // addTabToObj({
      //   key: 'tempTagsCumulative',
      //   value: { ...tempTagsCumulative, [item.groupTitle]: tempCumTags[item.groupTitle] }
      // });
      // addTabToObj({
      //   key: 'tempTags',
      //   value: { ...tempTags, [item.groupTitle]: tempCumTags[item.groupTitle][0] }
      // });

      // addCummulativeTags({
      //   ...tempCumTags
      // });
      // addTags({
      //   ...tags,
      //   [item.groupTitle]: tempCumTags[item.groupTitle][0]
      // });
      // console.log('tempCumTags[item.groupTitle]', item.groupTitle, tempCumTags[item.groupTitle]);
    }

    // cumulativeTags
  }, [filterData[item.groupTitle]]);

  // cross-verfying whether cumulative tags and cumulative selected obj are having same pathss
  useEffect(() => {
    if (cumulativeTags[item.groupTitle]) {
      Object.keys(cumulativeTags[item.groupTitle]).forEach((pathNo) => {
        if (!cummSelectedObj[pathNo]) {
          delete cumulativeTags[item.groupTitle][pathNo];
        }
      });
    }
  }, [cummSelectedObj]);
  // console.log('dependents', dependents, item.groupTitle === 'Plant' ? 'tree' : item.groupTitle);

  const fetchDependencyData = () => {
    let isCompletelySelected = false || isEmpty(selectedObj);
    if (!isEmpty(selectedObj)) {
      const totalLevels = Object.keys(selectedObj).length - 1;
      const lastSelectedLevelId = Object.keys(selectedObj)[totalLevels];
      const lastBut1SelectedLevelId = Object.keys(selectedObj)[totalLevels - 1];
      const leafCondition =
        leafLevels[item.groupTitle] &&
        leafLevels[item.groupTitle] ===
          selectedObj[lastBut1SelectedLevelId]?.attribute;
      isCompletelySelected =
        isEmpty(selectedObj[lastSelectedLevelId].values) || leafCondition;
    }
    if (isCompletelySelected) {
      // reseting selection and tag objects and passing to fetchDependencyData
      const newSelectionObjs = dependents[item.keyName].reduce((prev, curr) => {
        const current = keyTitleMapping[curr];
        return { ...prev, [current]: {}, [`cumulative${current}`]: {} };
      }, {});
      const newTags = Object.keys(tags).reduce((prev, curr) => {
        if (
          !dependents[item.keyName]
            .map((dep) => keyTitleMapping[dep])
            .includes(curr)
        ) {
          return {
            ...prev,
            tags: { ...prev.tags, [curr]: tags[curr] },
            cumulativeTags: {
              ...prev.cumulativeTags,
              [curr]: cumulativeTags[curr]
            }
          };
        }
        return prev;
      }, {});
      const resetData = {
        ...newSelectionObjs,
        ...newTags
      };

      const payload = {
        menuid: menuIdofFilter,
        tab: selectedParent,
        data: generateSelectedFilter(
          { ...cumulativeTags, ...newSelectionObjs },
          filterData,
          true
        )
      };
      dispatch(
        FilterCreators.getFilterData(
          {},
          {
            updatedGroup: item.groupTitle,
            dependentOn: item.keyName,
            dependents: dependents[item.keyName],
            metaDependency: meta.dependency,
            updateGroupPayload: payload,
            resetDependents: resetData,
            keyTitleMapping
          }
        )
      );
    }
  };

  // on mount fetch the dependent data
  // useEffect(() => {
  //   if (dependencies.includes(item.keyName)) {
  //     // console.log(
  //     //   'item.group mounting',
  //     //   item.groupTitle,
  //     //   item.keyName,
  //     //   dependencies,
  //     //   dependencies.includes(item.keyName),
  //     //   dependents,
  //     //   dependents[item.keyName]
  //     // );
  //     fetchDependencyData();
  //   }
  // }, []);

  // non-empty selectedobj update to stop network calls repetedly during dependency fetch
  const isMounted = useRef(false);

  useEffect(() => {
    if (
      isMounted.current &&
      // && !isEmpty(selectedObj)
      dependencies.includes(item.keyName)
    ) {
      // console.log(
      //   'item.group updating',
      //   item.groupTitle,
      //   item.keyName,
      //   dependencies,
      //   dependencies.includes(item.keyName),
      //   dependents,
      //   dependents[item.keyName]
      // );
      fetchDependencyData();
    } else {
      isMounted.current = true;
    }
  }, [selectedObj]);

  // useEffect(() => {
  //   if (!isEmpty(selectedObj) && dependencies.includes(item.keyName)) {
  //     console.log(
  //       'item.group updating',
  //       item.groupTitle,
  //       item.keyName,
  //       dependencies,
  //       dependencies.includes(item.keyName),
  //       dependents,
  //       dependents[item.keyName]
  //     );
  //     fetchDependencyData();
  //   }
  // }, [selectedObj]);

  // useEffect(() => {
  //   if (tempTagsCumulative && tempTagsCumulative[item.groupTitle]) {
  //     addCummulativeTags({
  //       ...cumulativeTags,
  //       ...tempTagsCumulative
  //     });
  //     addTags({
  //       ...tags,
  //       ...tempTags,
  //       [item.groupTitle]: tempTagsCumulative[item.groupTitle][0]
  //     });
  //   }
  // }, [tempTagsCumulative, tempTags]);
  const calendarTypes = ['date', 'daterange', 'week', 'month', 'quarter'];
  /* check if this group is loading(is dependent on some other or not) */
  const isInLoading =
    depedentLoading && dependents[dependentOn].includes(item.keyName);
  return (
    <FilterResultCardMainContainer>
      <FilterResultCardContainer>
        <FilterResultContentContainer>
          {(hasPreSelection ? isPreSelection : true) && (
            <div>
              <FilterResultHeader>
                <FilterResultHeaderText>
                  {item.groupTitle}
                </FilterResultHeaderText>
                {(isPreSelection || item.lockable) && (
                  <Radiobutton
                    onClick={() => handleLeaf(item.groupTitle)}
                    active={
                      (leafLevels &&
                        leafLevels[item.groupTitle] === item.groupTitle) ||
                      (breakpoint && breakpoint[item.groupTitle] === -1)
                    }
                  />
                )}
              </FilterResultHeader>
              {isInLoading ? (
                <Loader />
              ) : (
                <FilterResultContent
                  left={false}
                  className={item.attribute === 'Calendar' ? 'calendar' : ''}
                >
                  {item.values &&
                    item.values.map((innerItem, i) => (
                      <FilterContentContainer
                        selectedTabKey={item.groupTitle}
                        innerItem={{ ...innerItem, id: `_${innerItem.id}` }}
                        handleClick={() =>
                          handleParentClick(item, {
                            ...innerItem,
                            id: `_${innerItem.id}`
                          })}
                        index={i}
                        selectionType={item.selectionType}
                        key={innerItem.id || i}
                        isLeaf={computeLeafLevel === 0}
                      />
                    ))}
                </FilterResultContent>
              )}
            </div>
          )}

          {/* commented code at last for attributes to be loaded first */}
          {!isInLoading &&
            selectedObj &&
            Object.keys(selectedObj).length > 0 &&
            Object.keys(selectedObj).map((key, index) => {
              const firstKey = Object.keys(selectedObj)[0];
              const group = filterData.find(
                (group) => group.groupTitle === item.groupTitle
              );
              if (!group.values.find((it) => `_${it.id}` === firstKey)) {
                return;
              }
              // console.log('qer', key, selectedObj, selectedObj[key]);

              // allow calanderTypes to load children container even if with no values
              const isVisibleInPreSelection =
                breakpoint?.[item.groupTitle] !== undefined
                  ? breakpoint?.[item.groupTitle] >= index
                  : true;
              const isVisible = isPreSelection
                ? isVisibleInPreSelection
                : !isVisibleInPreSelection ||
                  breakpoint?.[item.groupTitle] === index;
              return (
                ((selectedObj[key].values &&
                  typeof selectedObj[key].values === 'object' &&
                  (leafLevels[item.groupTitle]
                    ? computeLeafLevel > index
                    : true)) ||
                  calendarTypes.includes(
                    selectedObj[key].type?.toLowerCase()
                  )) &&
                (hasPreSelection ? isVisible : true) && (
                  // selectedObj[key].values && (
                  <>
                    <FilterChildrenContent
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      keyVal={key}
                      groupTitle={item.groupTitle}
                      itemId={item.id}
                      index={index}
                      attribute={selectedObj[key].attribute}
                      selectionType={selectedObj[key].selectionType}
                      handleChildrenClick={handleChildrenClick}
                      handleMultiSelectClick={handleMultiSelectClick}
                      isLeaf={computeLeafLevel === index + 1}
                      isPreSelection={isPreSelection}
                      hasPreSelection={hasPreSelection}
                      isInstanceCreation={isInstanceCreation}
                      showOnlyActive={
                        ignoreActiveOption
                          ? false
                          : breakpoint?.[item.groupTitle] === index
                      }
                    />
                    {/* {console.log('item', computeLeafLevel, index)} */}
                  </>
                )
              );
            })}
        </FilterResultContentContainer>
        <FilterCardBottomSpace />
      </FilterResultCardContainer>
    </FilterResultCardMainContainer>
  );
}

export default FilterResultCard;

/*
          // for attributes
          {attributes.map((attribute, index) => {
            const key = selectedObj && Object.keys(selectedObj).length > 0 && Object.keys(selectedObj)[index];
            return (
              <FlexContainer>
                <div
                  style={{
                    width: 1,
                    borderLeft: '0.5px dashed darkgray',
                    marginLeft: '1rem'
                  }}
                />
                <div>
                  <FilterResultHeader>
                    <FilterResultHeaderText style={{ minWidth: '20rem', flex: 1 }}>{attribute}</FilterResultHeaderText>
                    <Radiobutton
                      onClick={() => handleLeaf(attribute)}
                      active={leafLevels && leafLevels[item.groupTitle] === attribute}
                    />
                  </FilterResultHeader>

                  {
                    key && selectedObj[key].values && (leafLevels[item.groupTitle] ? computeLeafLevel > index : true) && (
                      <>
                        <FilterChildrenContent
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          keyVal={key}
                          groupTitle={item.groupTitle}
                          itemId={item.id}
                          index={index}
                          attribute={selectedObj[key].attribute}
                          selectionType={selectedObj[key].selectionType}
                          handleChildrenClick={handleChildrenClick}
                          handleMultiSelectClick={handleMultiSelectClick}
                        />
                      </>
                    )
                    // })
                  }
                </div>
              </FlexContainer>
            );
          })} */
