/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { WIDGET_TYPES } from '../../../../constants';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { Text } from '../../../../views/Widgets/Widget.style';
import OeeDonutWidget from '../../../../views/Widgets/WidgetComponents/OEEDonut/OeeDonutWiget';
import Loader from '../../../Loader/Loader';
import { getFetchDetailsOfWidgetData } from '../../utils';
import { BodyContainer, Container } from '../../Common/WidgetCommons.style';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import { DeleteBlack, DeleteTab, Export, FullScreen, HideBorder, HideHeader, Settingslight, settingsBlack, ExportPdfIcon, ExportImageIcon, ExportExcelIcon, AddToHome, RemoveFromHome, RemoveHomeMenu } from '../../../../assets/icons';
import { useActions } from '../../../../store/hooks/useActions';

const EquipmentDonut = (props) => {
  const { componentData, id, isVisible, layoutPos, fullView, WIDFromProp, HDId } = props;

  const [flag, setFlag] = useState(true);
  const location = useLocation();
  const { state } = location;
  const { WidgetName, infoText, formData, type, ...componentProps } =
    componentData || {};
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const [widgetData, setWidgetData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const isPreviewInstance = useSelector((state) => state.widgets.isPreviewInstance);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);

  const WID = WIDFromProp || layoutPos.i?.split('_')?.[0];

  const { setExportData } = useActions();

  const widgetOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },

    { id: 1, label: 'configure', value: 'configure', iconUrl: settingsBlack },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      // type: 'radio',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },
  ];
  const widgetOptionsInstance = HDId ? [
    {
      id: 1,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },
  ] : [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },
  ];

  const widgetOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },
  ];

  const { id: WTIIdFromUrl } = useParams();

  useEffect(() => {
    if (isEmpty(widgetData)) {
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
      // TODO_W_R
      WebService[method](fullURL, {
        widgetType: type,
        formInput: formData
      })
        .then((response) => {
          const { data, meta } = response?.data?.data;
          setWidgetData({
            donutData: data,
            donutMeta: meta
          });
          setExportData({ TabId: WTIIdFromUrl || activeTab?.id, WID, widgetData: { data, meta, WidgetName, type } });
        })
        .catch((e) => {
          const err = sagaCatchBlockHandling(e);
          setError(err.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // formData is added as dependency to prevent empty formdata going as payload
  }, [formData]);
  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  if (error) return <span>{error}</span>;
  return (
    <>
      { (
        <WidgetHeaderView
          id={id}
          WID={WIDFromProp || layoutPos.i?.split('_')?.[0]} // WID
          WidgetName={WidgetName || 'Equipment OEE Donut'}
          widgetOptions={WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePop : widgetOptionsInstance : widgetOptions}
          componentData={componentData}
          hasZoomControl={true}
          setZoomLevel={setZoomLevel}
          zoomLevel={zoomLevel}
          fullView={fullView}
        />
      )}
      <Container headerOn={!isHideHeader[WID + activeTab?.id]} zoomLevel={fullView ? 1 : zoomLevel} className='donut'>
        {loading ? (
          <Loader />
        ) : (
          <>
            <OeeDonutWidget
              {...(componentProps || {})}
              {...widgetData}
              setFlag={setFlag}
              flag={flag}
              WID={WID}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default memo(EquipmentDonut);
