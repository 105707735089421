/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteBlack, HideBorder, HideHeader } from '../../../../assets/icons';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import { BodyContainer } from '../../WidgetsCommon.style';
import { Container, ContainerInside } from '../SvgWidget/SVGWidget.style';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { getFetchDetailsOfWidgetData } from '../../../Widget/utils';
import { getDataSource } from '../../../../views/Widgets/WidgetComponents/BarChart/data';
import { WebService } from '../../../../services/axios/webServices';

const TextHtmlEdit = (props) => {
  const { componentData, id, isVisible, layoutPos } = props;
  const WID = layoutPos?.i?.split('_')?.[0];
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const {
    widgetLabel
  } = useSelector((state) => state.upsertWidgetData);
  const {
    formInput,
    widgetType,
  } = useSelector((state) => state.upsertWidgetData);
  //   const [machineState, setMachineState] = useState();
  const [dimension, setDimension] = useState({});
  //   const [widgetData, setWidgetData] = useState({});
  //   const [loading, setLoading] = useState(false);

  const containerRef = useRef();

  const {
    widgetEditOptions: initWidgetEditOptions,
    WidgetName,
    infoText,
    formData,
  } = componentData || {};

  const { data, meta } = useSelector((state) => state.upsertWidgetData);

  const widgetOptions = [
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  useEffect(() => {
    if (containerRef) {
      const width = (containerRef.current.offsetWidth - 50);
      const height = (containerRef.current.offsetHeight - 50);
      setDimension({ width, height });
    }
  }, [containerRef?.current?.offsetHeight, containerRef?.current?.offsetWidth]);

  return (
    <Container style={{ width: '40%', marginLeft: '2rem' }} headerOn={!isHideHeader[WID + activeTab?.id]}>
      <WidgetHeaderView
        id={id}
        WID={WID} // WID
        WidgetName={WidgetName || widgetLabel}
        widgetOptions={widgetOptions}
        componentData={componentData}

      />
      <BodyContainer headerOn={!isHideHeader[WID + activeTab?.id]} style={{ marginLeft: '0' }}>
        <ContainerInside ref={containerRef}>
          <div
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </ContainerInside>
      </BodyContainer>
    </Container>

  );
};

export default TextHtmlEdit;
