/* eslint-disable eqeqeq */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import KPVList from './KPVListComponent/KPVListComponent.view';
import { Creators as FilterCreators } from '../../views/dashboard/FilterView/store';
import Loader from '../Common/Loader/Loader';
import { generateSelectedFilter, getBreadCrumbs } from './Iframe/generateSelectedFilter';
import { fetchUserAndLocation } from '../Sidenav/FetchStatics';
// import { generateSelectedFilter, linkNodes } from './Iframe/generateSelectedFilter';

function ShowAllTheFilter({ type, location }) {
  const { menuIdofFilter, cumulativeTags, selectedParent, filterData, inputs } = useSelector(
    (state) => state.filterData
  );
  const userData = useSelector((state) => state.loginData.userData);
  const dispatch = useDispatch();
  const locationPage = useLocation();
  useEffect(() => {
    const formatedTags = generateSelectedFilter(cumulativeTags, filterData, true);
    // console.log('location', location);
    const payload = {
      menuid: menuIdofFilter,
      tab: selectedParent,
      data: formatedTags,
      inputs: { ...inputs }
    };
    fetchUserAndLocation(locationPage.pathname, userData, {
      tab: selectedParent,
      pageType: type
    });
    // console.log('iframe payload', payload);
    // dispatch(FilterCreators.getIFrameData(payload));
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'filterPayload',
        value: payload
      })
    );

    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'activeBreadCrumb',
        value: 0
      })
    );
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'breadCrumbs',
        value: [...getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false))]
      })
    );
  }, []);

  const switchComp = () => {
    switch (type) {
      default:
      case 'iframe':
        return <Iframe location={location} />;
      case 'kpv':
        return <KPVList />;
      // default:
      //   return <div style={{ fontSize: '30px', textAlign: 'center' }}>Invalid Result Type</div>;
    }
  };

  return <>{switchComp()}</>;
}

const Iframe = ({ location }) => {
  const { iframeRes, filterPayload, iframeLoading } = useSelector((state) => state.filterData);

  // const [formatTags, setFormatTags] = useState('');

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setFormatTags(JSON.stringify(tags));
  // }, [tags]);

  useEffect(() => {
    if (filterPayload) dispatch(FilterCreators.getIFrameData(filterPayload));
  }, [filterPayload]);

  if (iframeRes) {
    return (
      <>
        {iframeLoading ? (
          <Loader />
        ) : (
          <IframeContainer src={iframeRes.uri} name='iframe' title='filter-iframe' style={{ border: '0px' }} />
        )}
      </>
    );
    // <div>{JSON.stringify(tags, false, 10)}</div>;
  }
  return null;
};

export const IframeContainer = styled.iframe`
  width: 97%;
  margin: 2rem auto 0px;
  flex: 1;
  /* margin-top: 2rem; */
`;

export default ShowAllTheFilter;
