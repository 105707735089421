import styled from 'styled-components';

export const FilterBtn = styled.div`
  padding: 1rem;
  /* background: red; */
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export const IconContainer = styled.div`
  padding: 1rem;
`;
