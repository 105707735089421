/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { CheckBoxContainer, KPVItemContainer, RadioContainer, RadioIcon, VerticalDiv } from './CustomiseKpv.style';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import CollapsableContainer from '../CollapsableContainer/CollapsableContainer.view';
import { Creators as UpsertPaneCreators } from '../Store';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import { getMachinesFromKpvs } from '../../KPVCharts/utils/getMachinesFromKpvs';
import { FlexContainer, Span } from '../../../globalStyles';
import MutliSelectToggler from '../CollapsableContainer/MutliSelectToggler';
import { ItemPadding } from '../UpsertPane.style';

export const CustomiseKpvContent = ({
  kpvs,
  chartType,
  chartTypeCategory,
  propertyKey = 'machinesSelected',
  isOnlySingleSelect = false
}) => {
  const upsertPaneData = useSelector((state) => state.upsertPaneData);
  const { multiSelectKpv } = upsertPaneData;
  const machinesSelected = upsertPaneData?.[propertyKey] || [];
  // console.log('machinesSelected', machinesSelected, propertyKey);
  const dispatch = useDispatch();
  const machinesShortlisted = useSelector((state) => state.KPVCharts.machinesShortlisted);
  const [UniqueMachines, setUniqueMachines] = useState([]);
  const handleSelectMachine = (ele) => {
    let property = 'kpvId';
    switch (chartTypeCategory || chartType) {
      case CHART_TYPES.DATA_CHART:
        property = 'kpvId';
        break;
      case CHART_TYPES.TRANSITION_CHART:
        // property = 'macId';
        property = 'kpvId';
        break;
      default:
        break;
    }
    if (multiSelectKpv && !isOnlySingleSelect) {
      if (machinesSelected?.find((machines) => machines[property] === ele[property])) {
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: propertyKey,
            value: machinesSelected?.filter(
              (machines) => machines[property] !== ele[property]
              // (machines) => machines.machineId !== ele.machineId && machines.kpvId !== ele.kpvId
            )
          })
        );
      } else {
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: propertyKey,
            value: [...machinesSelected, ele]
          })
        );
      }
    } else {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: propertyKey,
          value: []
        })
      );
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: propertyKey,
          value: [ele]
        })
      );
    }
  };
  // console.log('dada', kpvs, machinesSelected);

  useEffect(() => {
    const tempUniqueMachines = getMachinesFromKpvs(kpvs || machinesShortlisted);
    setUniqueMachines(tempUniqueMachines);
  }, [machinesShortlisted]);

  return (
    <>
      {chartTypeCategory !== CHART_TYPE_CATEGORY.TRANSITION_CHART &&
        (!isEmpty(kpvs) ? kpvs : machinesShortlisted)
          // TODO: temp for label chart
          // .filter((e) => e[chartType] === 'true') // filtering while displaying kpvs
          .map((ele, index) => (
            <KPVItemContainer key={index}>
              {!multiSelectKpv || isOnlySingleSelect ? (
                <RadioContainer className='pointer' onClick={() => handleSelectMachine(ele)}>
                  {machinesSelected?.find((machine) => machine.kpvId === ele.kpvId) && <RadioIcon />}
                </RadioContainer>
              ) : (
                <CheckBoxContainer
                  onClick={() => handleSelectMachine(ele)}
                  className={`${
                    machinesSelected?.find((machine) => machine.kpvId === ele.kpvId) ? 'active' : ''
                  } pointer`}
                >
                  <CheckMark className='' />
                </CheckBoxContainer>
              )}
              <div>
                <div>
                  <span>{ele.machineName}</span>
                  <VerticalDiv>|</VerticalDiv>
                  <span>{ele.kpvName}</span>
                </div>
              </div>
            </KPVItemContainer>
          ))}
      {chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART &&
        (kpvs || machinesShortlisted || UniqueMachines)
          .filter((e) => e[chartType] === 'true')
          .map((ele, index) => (
            <KPVItemContainer key={index}>
              {!multiSelectKpv ? (
                <RadioContainer onClick={() => handleSelectMachine(ele)}>
                  {machinesSelected.find(
                    (machine) => ele.machineId === machine.machineId && machine.kpvId === ele.kpvId
                  ) && <RadioIcon />}
                </RadioContainer>
              ) : (
                <CheckBoxContainer
                  onClick={() => handleSelectMachine(ele)}
                  className={
                    machinesSelected.find(
                      (machine) => machine.kpvId === ele.kpvId
                      // ele.macId === machine.macId
                    )
                      ? 'active'
                      : ''
                  }
                >
                  <CheckMark className='' />
                </CheckBoxContainer>
              )}
              <div>
                <div>
                  <span>{ele.machineName}</span>
                  <VerticalDiv>|</VerticalDiv>
                  <span>{ele.kpvName}</span>
                </div>
              </div>
            </KPVItemContainer>
          ))}
    </>
  );
};

const CustomiseKpv = ({ header, value }) => {
  const { multiSelectKpv, chartType, chartTypeCategory } = useSelector((state) => state.upsertPaneData);

  return (
    <CollapsableContainer
      header={header}
      multiSelectKpv={multiSelectKpv}
      value={value}
      chartTypeCategory={chartTypeCategory}
      showKPVMultiSelectToggle={chartTypeCategory !== CHART_TYPE_CATEGORY.XY_CHART}
    >
      {/* TODO: remove this */}
      {/* for selecting x axis from list of kpvs */}
      {/* {chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART && (
        <>
          <ItemPadding>
            <Span>X-axis</Span>
          </ItemPadding>
          <CustomiseKpvContent
            chartType={chartType}
            chartTypeCategory={chartTypeCategory}
            propertyKey='xKPVType'
            isOnlySingleSelect
          />
          <br />
          <FlexContainer className='ai_center jc_spacebetween'>
            <ItemPadding className='jc_spacebetween' style={{ width: '100%' }}>
              <Span>Y-axis</Span>
              <MutliSelectToggler />
            </ItemPadding>
          </FlexContainer>
        </>
      )} */}
      <CustomiseKpvContent chartType={chartType} chartTypeCategory={chartTypeCategory} />
    </CollapsableContainer>
  );
};

export default CustomiseKpv;
