import styled, { css } from 'styled-components';
import { device, fontFamily, fontSize } from '../constants';
import { shadow } from '../constants/colors';

export const Div = styled.div`
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m}rem;
    `};

  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}rem;
    `};

  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr}rem;
    `};

  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt}rem;
    `};

  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}rem;
    `};

  ${(props) =>
    props.mx &&
    css`
      margin: 0 ${props.mx}rem;
    `};

  ${(props) =>
    props.my &&
    css`
      margin: ${props.my}rem 0;
    `};

  ${(props) =>
    props.p &&
    css`
      pading: ${props.p}rem;
    `};

  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl}rem;
    `};

  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr}rem;
    `};

  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt}rem;
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}rem;
    `};

  ${(props) =>
    props.px &&
    css`
      padding: 0 ${props.px}rem;
    `};

  ${(props) =>
    props.py &&
    css`
      padding: ${props.py}rem 0;
    `};
`;

export const FlexContainer = styled(Div)`
  display: flex;
  &.col {
    flex-direction: column;
  }

  &.jc_center {
    justify-content: center;
  }

  &.jc_start {
    justify-content: flex-start;
  }

  &.jc_end {
    justify-content: flex-end;
  }

  &.jc_spacebetween {
    justify-content: space-between;
  }

  &.jc_spacearound {
    justify-content: space-around;
  }

  &.ai_center {
    align-items: center;
  }

  &.flex_wrap {
    flex-wrap: wrap;
  }
  &.icon {
    padding: 1rem;
    margin-right: 10px;
  }
  &.relative {
    position: relative;
  }
  @media print{
  border-bottom: 0 !important;

  }
`;

export const Image = styled.img`
  width: ${({ width }) => `${width}rem`};
  height: ${({ width }) => `${width}rem`};
`;

export const Button = styled.button`
  display: inline;
  width: 45%;
  height: ${({ height }) => height || '4rem'};
  font-size: ${fontSize.titleBig};
  box-shadow: ${({ theme }) => theme.shadowout};
  border-radius: 5px;
  border: 0px;
  text-transform: capitalize;
  font-family: ${fontFamily.circularMedium};

  background-color: ${({ bgClr }) => bgClr || 'white'};
  color: black;

  &.dark {
    background-color: ${({ theme }) => theme.specificDetails.primary};
    color: white;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &.small {
    width: auto;
    height: auto;
    padding: 0.5rem 1.5rem;
    font-size: 1.5rem;
  }
`;

export const IconContainer = styled.div`
  & svg {
    width: 2rem;
    height: 2rem;
    @media ${device.tablet} {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  & * {
    fill: ${({ theme, color }) => color || theme.contrast.secondary} !important;
  }
`;

export const Span = styled.span`
  font-size: 1.6rem;
  &.padding {
    padding: ${({ padding }) => padding || '2rem'};
  }
`;
