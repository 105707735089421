/* eslint-disable max-len */
import { WIDGET_CATEGORIES, WIDGET_TYPES } from '../../../constants';
import { ClockNWthr, MyDashboard, MyAnalytics, FloorPlan, EOEED, EquipmentPlan, vistrianLogo } from '../../../assets/icons';

export const MODAL_TYPES = {
  ADD_TAB: 'addTab',
  RENAME_TAB: 'renameTab',
  DELETE_TAB: 'deleteTab'
};

export const widgetMenu = [
  {
    id: 3,
    title: 'My Analytics',
    value: WIDGET_TYPES.ANALYTICS,
    category: WIDGET_CATEGORIES.ANALYTICS,
    // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
    svg: MyAnalytics
  },
  // {
  //   id: 4,
  //   title: 'Floor Plan',
  //   value: WIDGET_TYPES.FLOOR_PLAN,
  //   // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
  //   svg: FloorPlan
  // },
  {
    id: 5,
    title: 'Equipment OEE Donut',
    value: WIDGET_TYPES.EQUIPMENT_OEE_DONUT,
    category: WIDGET_CATEGORIES.OEE_DONUT,
    // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
    svg: EOEED
  },
  {
    id: 7,
    title: 'Product OEE Donut',
    value: WIDGET_TYPES.PRODUCT_OEE_DONUT,
    category: WIDGET_CATEGORIES.OEE_DONUT,
    // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
    svg: EOEED
  },
  // {
  //   id: 6,
  //   title: 'Equipment Plan',
  //   value: WIDGET_TYPES.EQUIPMENT_PLAN,
  //   // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
  //   svg: EquipmentPlan
  // },
  {
    id: 1,
    title: 'Status Value',
    value: WIDGET_TYPES.STATUS_VALUE,
    category: WIDGET_CATEGORIES.STATUS_VALUE,
    // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
    svg: ClockNWthr
  },
  {
    id: 2,
    title: 'Detailed Status Value',
    value: WIDGET_TYPES.DETAILED_STATUS_VALUE,
    category: WIDGET_CATEGORIES.DETAILED_STATUS_VALUE,
    // detail: 'Lorema sdnkjsdf sdfnjkds dsfjkn',
    svg: MyDashboard
  }
];

export const widgetInstanceMetaDetails = {
  [WIDGET_CATEGORIES.ANALYTICS]: {
    columns: [
      { key: 'WID', title: 'WID', visible: true, options: [] },
      { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] },
      // { key: 'singlePane', title: 'Single Pane', type: 'boolean', visible: true, options: [] },
      { key: 'chartTypeCategory', title: 'Chart Category', visible: true, options: [] },
      { key: 'chartType', title: 'Chart Type', visible: true, options: [] },
      { key: 'StartTime', title: 'Start Time', visible: true, options: [] },
      { key: 'EndTime', title: 'End Time', visible: true, options: [] }
      // kpvDetails: [],
    ]
  },
  [WIDGET_CATEGORIES.OEE_DONUT]: {
    columns: [
      { key: 'WID', title: 'WID', visible: true, options: [] },
      { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] }
      // { key: 'singlePane', title: 'Single Pane', type: 'boolean', visible: true, options: [] },
      // kpvDetails: [],
    ]
  },
  [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: {
    columns: [
      { key: 'WID', title: 'WID', visible: true, options: [] },
      { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] }
    ]
  },
  [WIDGET_CATEGORIES.STATUS_VALUE]: {
    columns: [
      { key: 'WID', title: 'WID', visible: true, options: [] },
      { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] }
    ]
  }
};

// default meta
export const defaultWidgetInstanceMetaDetails = {
  columns: [
    { key: 'WID', title: 'WID', visible: true, options: [] },
    { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] }
  ]
};
