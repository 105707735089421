/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { WidgetCreators } from '../../../../views/Widgets/store';
// import { getWidgetPanelPayload } from '../../utils/getWidgetPanelPayload';
import TabForm from './TabForm';
import { getWidgetPanelPayload } from '../../utils/getWidgetPanelPayload';
import { SHARED_LAYOUT } from '../../../../constants';
import { useActions } from '../../../../store/hooks/useActions';
import { isEmpty } from '../../../../shared/utility/isEmpty';

const RenameTab = (prop) => {
  const { heading, visibal, visibleHandler, maxLength } = prop;
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);

  const userData = useSelector((state) => state.loginData.userData);
  const menuId = useSelector((state) => state.home.activeMenuId);
  const [input, setInput] = useState(activeTab.label);
  const layout = useSelector((state) => state.widgets.layout);
  const layoutComponentData = useSelector((state) => state.widgets.layoutComponentData);
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleChange = (e) => {
    setInput(e.target.value);
  };
  const dispatch = useDispatch();
  const { widgetDetailSetter, setToastError } = useActions();
  const setErr = (val) => {
    widgetDetailSetter({
      key: 'layoutError',
      value: val
    });
  };
  const handleSaveTab = async () => {
    setButtonLoading(true);
    try {
      const fullUrl = apiEndPoints.updateWidgetPanel.replace('{{WPID}}', activeTab.id);
      const widgetPayload = getWidgetPanelPayload({
        widgetPanelName: input,
        menuId: menuIdofWidget,
        userId: userData.userId,
        isShareable: activeTab?.isShareable,
        // TODO: modularize this layout
        widgetPanelLayoutData: { [SHARED_LAYOUT]: layout?.[breakpoint] },
        // make it array
        widgetPanelComponentData: Object.values(layoutComponentData)
      });
      // console.log('widget', widgetPayload);
      const res = await WebService.post(fullUrl, widgetPayload);
      if (res.status >= 200 && res.status < 300) {
        // rename if success
        dispatch(WidgetCreators.renameTab({ id: activeTab.id, label: input }));
      } else {
        throw res;
      }
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      // setErr(err.error);
      // Commented this line since it resets the layout to be empty when api throws error
      setToastError(err.error);
    } finally {
      setButtonLoading(false);
    }
    // dispatch(WidgetCreators.renameTab({ id: activeTab.id, label: input }));
    visibleHandler(false);
  };

  return (
    <>
      {visibal ? (
        <TabForm
          input={input}
          heading={heading}
          handleChange={handleChange}
          handleSaveTab={handleSaveTab}
          visibleHandler={visibleHandler}
          buttonLoading={buttonLoading}
          maxLength={maxLength}
          disabled={isEmpty(input)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RenameTab;
