import styled from 'styled-components';
import { fontSize } from '../../constants';
import { Button, Div, FlexContainer } from '../../globalStyles';
import { Tag } from './MyDashboards.style';

export const Title = styled.p`
  font-size: ${fontSize.text};
  color: ${({ theme }) => theme.headingText};
`;

export const DropDown = styled(Div)`
  position: relative;
`;

export const DButton = styled(Div)`
  height: 5.5rem;
  width: 5.5rem;
  background: ${({ theme }) => theme.contrast.tertiary};
  border-radius: 1rem;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & > svg {
    height: 15px;
    transform: rotate(90deg);
  }
`;

export const SearchCont = styled(Div)`
  width: 50%;
  position: relative;
`;

export const AddButton = styled(DButton)`
  & > svg {
    height: 15px;
  }
`;

export const P = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0 2.5rem;
`;

export const TagsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  margin: 2rem 0;
`;

export const Tags = styled(Tag)`
  padding-right: 3rem;
  position: relative;

  & > div {
    height: 8px;
    position: absolute;
    top: 9px;
    right: 7px;
  }

  & > div > div > svg > g > path {
    fill: #fff !important;
  }
`;
export const Btn = styled(Button)`
  padding: 1.8rem;
  height: auto;
  cursor: pointer;
  // width: 22rem;
  // height: 4rem;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border-radius: 1rem;
  // font: normal normal normal 1.5rem/2.1rem Circular Std Medium;
  &.cancel_btn {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -3px -3px 11px #ffffffcb;
    color: #010101;
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-family:  Circular Std Medium;
    font-size:1.8rem;
  }
  &.save_btn {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: -5px -5px 15px #ffffffcb;
    color: #ffffff;
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-family: Circular Std Medium;
    font-size:1.8rem;

  }
  &.detailed_cancel_btn {
    width: 20rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -3px -3px 11px #ffffffcb;
    color: #010101;
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font: normal normal normal 1.5rem/2.1rem Circular Std Medium;
  }
  &.detailed_save_btn {
    width: 20rem;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: -5px -5px 15px #ffffffcb;
    color: #ffffff;
    width: 22rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font: normal normal normal 1.5rem/2.1rem Circular Std Medium;
  }
`;
