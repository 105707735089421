/* eslint-disable indent */
/* eslint-disable max-len */
import { Background } from 'devextreme-react/range-selector';
import React from 'react';
import ButtonComp from '../../../Common/Button/Button.view';
import { ModalContentText } from '../../../Common/DropDownModal/DropDownModal.style';
import { InputCotainer } from '../../../Common/Input/Input';
import { Modal, ModalContent } from '../../../Common/PopUpModal/Modal.style';
import Loader from '../../../Loader/Loader';
// import { Button } from '../Header.style';

const TabForm = ({
  input,
  heading,
  handleChange,
  handleSaveTab,
  visibleHandler,
  buttonLoading,
  maxLength,
  disabled = false,
}) => (
  <Modal>
    <ModalContent width='25%' className='tabforms' onClick={(e) => e.stopPropagation()}>
      <ModalContentText style={{ color: 'black', marginBottom: '2rem' }}>
        {heading}
      </ModalContentText>
      <InputCotainer
        type='text'
        placeholder='enter label'
        style={{ fontSize: '1.6rem' }}
        onChange={(e) => {
          handleChange(e);
        }}
        value={input}
        maxLength={maxLength}
      />
      <div className='buttonWrapper'>
        <ButtonComp
          style={{ width: '48%' }}
          className='saveBtn'
          onClick={() => visibleHandler(false)}
        >
          Cancel
        </ButtonComp>
        <ButtonComp
          className={`${input ? 'disable' : ''}}`}
          dark
          onClick={handleSaveTab}
          disabled={disabled}
          style={
            buttonLoading
              ? {
                  width: '48%',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'white'
                }
              : { width: '48%' }
          }
        >
          {buttonLoading ? (
            <div style={{ position: 'absolute', width: '40%' }}>
              <Loader position='relative' />
            </div>
          ) : (
            'Save'
          )}
        </ButtonComp>
      </div>
    </ModalContent>
  </Modal>
);

export default TabForm;
