/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  PAGE_TYPES,
  REPEATED_WIDGETS,
  SHARED_LAYOUT,
  WIDGET_CATEGORIES,
  WIDGET_TYPES
} from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import ButtonComp from '../../Common/Button/Button.view';
import PageHeader from '../../Common/PageHeader/PageHeader';
import TabsList from '../../Common/TabsList/TabsList';
import TabsTable from '../../Common/Tabs_Table';
import Toast from '../../Common/Toast';
import Loader from '../../Loader/Loader';
import {
  defaultWidgetInstanceMetaDetails,
  widgetInstanceMetaDetails
} from '../../Widget/utils/contants';
import { useActions } from '../../../store/hooks/useActions';
import { getWidgetLayoutObj } from '../../Widget/utils/getLayoutData';
import { uid } from '../../../shared/utility';
import { getResolvedWidgetCategory } from '../../Widget/utils';
import { isEmpty } from '../../../shared/utility/isEmpty';
// import UpsertSaveHeader from '../UpsertSaveHeader';
// import AnalyticsInstances from './AnalyticsInstances/AnalyticsInstances';
// import DetaiStatusValueInstances from './DetaiStatusValueInstance/DetaiStatusValueInstances';
// import DonutInstances from './DonutInstances/DonutInstances';
// import StatusValueInstances from './StatusValueInstances/StatusValueInstances';
const defaultMeta = {
  columns: [
    { key: 'WID', title: 'WID', visible: true, options: [] },
    { key: 'WidgetName', title: 'Widget Name', visible: true, options: [] }
    // { key: 'singlePane', title: 'Single Pane', type: 'boolean', visible: true, options: [] },
    // kpvDetails: [],
  ]
};
const WidgetInstances = (props) => {
  const history = useHistory();
  const { location } = props;
  //  history.push('/home/create-widget');
  const {
    widgetType,
    widgetLabel,
    widgetCategory,
    widgetInstancesData,
    widgetInstancesMeta,
    error: listError,
    loading: listLoading,
    widgetDashboardTitle
  } = useSelector((state) => state.upsertWidgetData);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const layoutComponentData = useSelector(
    (state) => state.widgets.layoutComponentData
  );
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const userData = useSelector((state) => state.loginData.userData);
  const onNext = () => {
    // TODO_W_RR: change to widget category
    const resolvedWidgetCategory = getResolvedWidgetCategory(
      widgetCategory,
      widgetType
    );
    if (
      resolvedWidgetCategory === WIDGET_CATEGORIES.OEE_DONUT ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.BAR_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.SVG ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.PIVOT_TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.STATUS_VALUE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CONCENTRIC_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.DETAILED_STATUS_VALUE

    ) {
      history.push('/home/create-widget/form');
    } else {
      history.push('/home/create-widget/');
    }
  };

  const tabs = [
    { label: 'My Instances', value: 'myInstances', id: 1 },
    { label: 'Shared', value: 'Shared', id: 2 }
  ];
  const [instanceType, setInstanceType] = useState(tabs[0]);
  useEffect(() => {
    // go to shared tab
    const { Shared } = location.state || {};
    if (Shared) {
      setInstanceType(tabs[1]);
    }
  }, [location]);
  const instanceTypeHandler = (item) => {
    setInstanceType(item);
    // setInstanceType(item);
  };

  const showMyInstances = instanceType.id === 1;
  const pageSize = 20;

  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const WidgetRoutes = widgetDashboardType === 'ALL' ? useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]) : useSelector((state) => state.home?.[PAGE_TYPES.WIDGET_INSTANCE]);
  // const WidgetRoutes = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const { repeatedWidgets, breakpoint, menuIdofWidget, layout } = useSelector(
    (state) => state.widgets
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    getWidgetInstances,
    updateWidgetsLayout,
    addWidgetFailure,
    clearTreeData,
    widgetDetailSetter
  } = useActions();
  useEffect(() => {
    clearTreeData();
  }, []);
  const onSuccessNavigation = () => {
    widgetDetailSetter({
      key: 'showSidePanel',
      value: false
    });
    if (!widgetDashboardTitle) {
      history.push(WidgetRoutes[menuIdofWidget]);
    } else {
      history.push({
        pathname: '/home/create-custom-dashboard',
        state: { isCustomDashboard: true }
      });
    }
  };
  const handleBack = () => {
    onSuccessNavigation();
  };
  const saveWidgetToThePanel = async (entry) => {
    setLoading(true);
    try {
      const { WID } = entry;
      // console.log('entry', entry, WID);
      // if already exisiting wid
      if (layoutComponentData[WID]) {
        const WidgetComponentData = layoutComponentData[WID];
        // newly created obj of the current entry
        const newLayoutObj = getWidgetLayoutObj(
          WidgetComponentData,
          uid(),
          WID
        );
        // TODO test1
        // console.log('newLayoutObj', newLayoutObj);
        const newRepeatedObjs = [...(repeatedWidgets || []), newLayoutObj];
        const widgetPayload = {
          // Widgets: layout?.[breakpoint],
          SiteId: 1, // TODO: remove
          SubMenuId: menuIdofWidget,
          WidgetPanelName: activeTab?.label,
          UserId: userData.userId,
          WidgetPanelLayoutData: {
            [SHARED_LAYOUT]: layout?.[breakpoint],
            [REPEATED_WIDGETS]: newRepeatedObjs
          }
        };
        // console.log('duplicate..........', layoutComponentData, layoutComponentData[WID]);
        updateWidgetsLayout({
          widgetPayload,
          WPID: activeTab?.id,
          onSuccessNavigation
        });
      } else {
        // general handling if the wid is not existed before
        let URL = apiEndPoints.addShareInstance.replace('{{WID}}', WID);
        URL += `?WPID=${activeTab?.id}`;
        const res = await WebService.post(URL);
        // console.log('added', res.data.data, WidgetRoutes, menuIdofWidget, WidgetRoutes[menuIdofWidget]);
        if (res.status >= 200 && res.status < 300) {
          onSuccessNavigation();
        } else {
          throw res;
        }
      }
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  // V2 for creating custom dashboard
  // const saveWidgetToThePanelLocally = (entry) => {
  //   try {
  //     const { WID, WidgetName, ...WidgetComponentData } = entry;
  //     console.log(layoutComponentData, layoutComponentData[WID]);
  //     // if already exisiting wid
  //     if (layoutComponentData[WID]) {
  //       const WidgetComponentData = layoutComponentData[WID];
  //       // newly created obj of the current entry
  //       const newLayoutObj = getWidgetLayoutObj(WidgetComponentData, uid(), WID);
  //       // console.log('newLayoutObj', newLayoutObj);
  //       const newRepeatedObjs = [...(repeatedWidgets || []), newLayoutObj];
  //       const WidgetPanelLayoutData = {
  //         [SHARED_LAYOUT]: layout?.[breakpoint],
  //         [REPEATED_WIDGETS]: newRepeatedObjs
  //       };
  //       // console.log('duplicate..........', layoutComponentData, layoutComponentData[WID]);
  //       setBulkWidgetDetails({
  //         layout: WidgetPanelLayoutData[SHARED_LAYOUT],
  //         repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS]
  //       });
  //     } else {
  //       console.log('2==', entry, WidgetComponentData);
  //       // newly created obj of the current entry
  //       const newLayoutObj = getWidgetLayoutObj(WidgetComponentData, uid(), WID);
  //       const WidgetPanelLayoutData = {
  //         [SHARED_LAYOUT]: { [breakpoint]: [...(layout?.[breakpoint] || []), newLayoutObj] },
  //         [REPEATED_WIDGETS]: repeatedWidgets || []
  //       };
  //       const newLayoutComponentData = {
  //         ...layoutComponentData,
  //         [WID]: {
  //           id: WID,
  //           WidgetName,
  //           ...WidgetComponentData
  //         }
  //       };
  //       setBulkWidgetDetails({
  //         layoutComponentData: newLayoutComponentData,
  //         layout: WidgetPanelLayoutData[SHARED_LAYOUT],
  //         repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS]
  //       });
  //     }
  //   } catch (e) {
  //     console.log('e', e);
  //     const err = sagaCatchBlockHandling(e);
  //     setError(err.error);
  //   } finally {
  //     setLoading(false);
  //     history.push('/home/create-custom-dashboard');
  //   }
  // };

  const handlePageChange = (page) => {
    getWidgetInstances({
      WidgetTypeId: widgetTypesMenu?.[widgetType]?.id,
      UserId: userData.userId,
      IsSharedWithMe: showMyInstances ? 0 : 1, // myinstances=0,
      Others: showMyInstances ? 0 : 1, // includes others(wpid=0)
      page,
      pageSize
    });
  };

  useEffect(() => {
    // stopping from fetching since it gives undefined WidgetTypeId to fetch details
    if (!isEmpty(widgetTypesMenu) && !isEmpty(widgetType)) {
      // console.log('fetching instances details');
      handlePageChange(1);
    }
  }, [showMyInstances, widgetTypesMenu, widgetType]);

  const handleRowOptionsItemClick = (opt, addParams) => {
    // console.log('dp', opt, addParams);
    const { value } = opt;
    const { activeRowItem } = addParams;
    if (value === 'import') {
      if (activeTab?.id) {
        saveWidgetToThePanel(activeRowItem);
      } else {
        // console.log('running locally');
        // saveWidgetToThePanelLocally(activeRowItem);
      }
    }
  };

  const handleItemSelect = (addParams) => {
    if (activeTab?.id) {
      saveWidgetToThePanel(addParams);
    } else {
      // console.log('running locally');
      // saveWidgetToThePanelLocally(activeRowItem);
    }
  };

  const rowOptions = [
    { id: 1, label: 'import', value: 'import' }
    // { id: 2, label: 'Delete', value: 'delete' }
  ];

  const clearError = () => {
    // console.log('clearing error');
    setError();
    addWidgetFailure('');
  };

  // TODO: support different views previews/table
  return (
    <>
      <PageHeader handleBack={handleBack} title={widgetLabel}>
        <ButtonComp
          onClick={onSuccessNavigation}
          style={{ marginRight: '1rem' }}
        >
          Discard
        </ButtonComp>
        {/* TODO_W_R: Change to widget category */}
        {widgetCategory !== WIDGET_CATEGORIES.ANALYTICS && (
          <ButtonComp dark onClick={onNext}>
            Add New
          </ButtonComp>
        )}
      </PageHeader>
      <TabsList
        tabs={tabs}
        activeTab={instanceType}
        tabHandler={instanceTypeHandler}
        showBorder
        boxWidth='15rem'
      />
      {/* <div>{showInstances()}</div> */}
      {loading || listLoading ? (
        <Loader />
      ) : (
        <>
          <TabsTable
            // TODO_W_R: Change to widget category
            metaDetails={{
              ...(widgetInstanceMetaDetails?.[widgetCategory] || defaultMeta),
              count: widgetInstancesMeta?.Count
            }}
            onItemSelect={handleItemSelect}
            dataDetails={widgetInstancesData}
            showPagiation={true}
            showShortlistSelection={false}
            rowOptions={rowOptions}
            handleRowOptionsItemClick={handleRowOptionsItemClick}
            handlePageChange={handlePageChange}
            id='WID'
            pageSize={pageSize}
          />
        </>
      )}
      {(error || listError) && (
        <Toast
          message={error || listError}
          header='Error'
          className='right'
          fn={clearError}
        />
      )}
    </>
  );
};

export default WidgetInstances;
