import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { WebService } from '../../../services/axios/webServices';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { formatedFilterData } from '../utility/formatFilterData';
/* eslint-disable max-len */
export function* getObjectTreeDataSaga({ payload, dependencyPayload = {} }) {
  const { tab, menuId, directParams, directURL, method, responseData } =
    payload;
  const {
    updatedGroup,
    dependentOn,
    dependents,
    metaDependency,
    updateGroupPayload,
    resetDependents,
    keyTitleMapping
  } = dependencyPayload;
  try {
    // will load entire filter data
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataStart());
      let URL = `${apiEndPoints.getFilterCategory}`;

      if (directURL) {
        URL = directURL;
      }

      const fullURL = yield URL;

      let response;

      if (responseData) {
        response = yield WebService?.[method || 'get'](fullURL, responseData, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      } else {
        response = yield WebService?.[method || 'get'](fullURL, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      }

      if (response.status >= 200 && response.status < 300) {
        const { data, meta: metaRaw, resultType } = response.data;
        const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
        let dependencies = [];
        let dependents = {};
        if (!isEmpty(meta)) {
          // compute final dependencies combing all the dependencies
          dependencies = Object.keys(meta.dependency).reduce(
            (final, dependent) => {
              const currEle = meta.dependency[dependent];
              const newDependencies = currEle.dependencies.reduce(
                (prev, curr) => {
                  if (!prev.includes(curr)) {
                    return [...prev, curr];
                  }
                  return prev;
                },
                final
              );
              return newDependencies;
            },
            []
          );

          // compute  dependents for each group
          dependents = dependencies.reduce((finalDependents, dependency) => {
            const eleDependents = Object.keys(meta.dependency).reduce(
              (prev, curr) => {
                const currEle = meta.dependency[curr];
                if (currEle.dependencies.includes(dependency)) {
                  return [...prev, curr];
                }
                return prev;
              },
              []
            );
            return { ...finalDependents, [dependency]: eleDependents };
          }, {});
        }
        const { formatedData, keyTitleMapping } = formatedFilterData(data.data);
        // meta of groups
        const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
          const { groupTitle, meta: groupMeta } = groupDetails;
          if (!isEmpty(groupMeta)) {
            return { ...meta, [groupTitle]: groupMeta };
          }
          return meta;
        }, {});

        yield put(
          Creators.getObjectTreeDataSuccess({
            data: formatedData,
            meta: { ...meta, ...groupLevelMeta },
            resultType,
            parent: tab,
            dependencies,
            dependents,
            keyTitleMapping,
            menuId
          })
        );
      } else {
        throw response;
      }
    } else {
      yield put(Creators.getObjectTreeDependencyDataStart({ dependentOn }));
      // console.log('loading only this...', updatedGroup, dependentOn, dependents);
      const rawResponses = yield Promise.all(
        dependents.map((dependent) => {
          const filteredObj = Object.keys(updateGroupPayload.data).reduce(
            (prev, group) => {
              if (
                metaDependency[dependent].dependencies
                  .map((e) => (e === 'tree' ? 'tree' : keyTitleMapping[e]))
                  .includes(group)
              ) {
                return { ...prev, [group]: updateGroupPayload.data[group] };
              }
              return prev;
            },
            {}
          );
          // console.log('filteredObj', filteredObj);
          return WebService.post(metaDependency[dependent].api, {
            ...updateGroupPayload,
            data: filteredObj
          });
        })
      );
      const responses = rawResponses.map((e, index) => ({
        ...e.data[dependents[index]],
        keyName: dependents[index]
      }));

      // meta of groups
      const groupLevelMeta = responses.reduce((meta, groupDetails) => {
        const { groupTitle, meta: groupMeta } = groupDetails;
        if (!isEmpty(groupMeta)) {
          return { ...meta, [groupTitle]: groupMeta };
        }
        return meta;
      }, {});
      yield put(
        Creators.getObjectTreeDependencyDataSuccess({
          objectTreeData: responses,
          meta: groupLevelMeta,
          resetDependents
        })
      );
    }
  } catch (error) {
    const payload = sagaCatchBlockHandling(error);
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataFail(payload));
    } else {
      yield put(Creators.getObjectTreeDependencyDataFail(dependents));
    }
  }
}

export function* getObjectTreeDataV2Saga({ payload, dependencyPayload = {} }) {
  const { tab, menuId, directParams, directURL, method, responseData } =
    payload;
  const {
    updatedGroup,
    dependentOn,
    dependents,
    metaDependency,
    updateGroupPayload,
    resetDependents,
    keyTitleMapping
  } = dependencyPayload;
  try {
    // will load entire filter data
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataStart());
      let URL = `${apiEndPoints.getFilterCategory}`;

      if (directURL) {
        URL = directURL;
      }

      const fullURL = yield URL;

      let response;

      if (responseData) {
        response = yield WebService?.[method || 'get'](fullURL, responseData, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      } else {
        response = yield WebService?.[method || 'get'](fullURL, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      }

      if (response.status >= 200 && response.status < 300) {
        const { data, meta: metaRaw, resultType } = response.data;
        const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
        let dependencies = [];
        let dependents = {};
        if (!isEmpty(meta)) {
          // compute final dependencies combing all the dependencies
          dependencies = Object.keys(meta.dependency).reduce(
            (final, dependent) => {
              const currEle = meta.dependency[dependent];
              const newDependencies = currEle.dependencies.reduce(
                (prev, curr) => {
                  if (!prev.includes(curr)) {
                    return [...prev, curr];
                  }
                  return prev;
                },
                final
              );
              return newDependencies;
            },
            []
          );

          // compute  dependents for each group
          dependents = dependencies.reduce((finalDependents, dependency) => {
            const eleDependents = Object.keys(meta.dependency).reduce(
              (prev, curr) => {
                const currEle = meta.dependency[curr];
                if (currEle.dependencies.includes(dependency)) {
                  return [...prev, curr];
                }
                return prev;
              },
              []
            );
            return { ...finalDependents, [dependency]: eleDependents };
          }, {});
        }
        const { formatedData, keyTitleMapping } = formatedFilterData(data);
        // meta of groups
        const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
          const { groupTitle, meta: groupMeta } = groupDetails;
          if (!isEmpty(groupMeta)) {
            return { ...meta, [groupTitle]: groupMeta };
          }
          return meta;
        }, {});

        yield put(
          Creators.getObjectTreeDataSuccess({
            data: formatedData,
            meta: { ...meta, ...groupLevelMeta },
            resultType,
            parent: tab,
            dependencies,
            dependents,
            keyTitleMapping,
            menuId
          })
        );
      } else {
        throw response;
      }
    } else {
      yield put(Creators.getObjectTreeDependencyDataStart({ dependentOn }));
      // console.log('loading only this...', updatedGroup, dependentOn, dependents);
      const rawResponses = yield Promise.all(
        dependents.map((dependent) => {
          const filteredObj = Object.keys(updateGroupPayload.data).reduce(
            (prev, group) => {
              if (
                metaDependency[dependent].dependencies
                  .map((e) => (e === 'tree' ? 'tree' : keyTitleMapping[e]))
                  .includes(group)
              ) {
                return { ...prev, [group]: updateGroupPayload.data[group] };
              }
              return prev;
            },
            {}
          );
          // console.log('filteredObj', filteredObj);
          return WebService.post(metaDependency[dependent].api, {
            ...updateGroupPayload,
            data: filteredObj
          });
        })
      );
      const responses = rawResponses.map((e, index) => ({
        ...e.data[dependents[index]],
        keyName: dependents[index]
      }));

      // meta of groups
      const groupLevelMeta = responses.reduce((meta, groupDetails) => {
        const { groupTitle, meta: groupMeta } = groupDetails;
        if (!isEmpty(groupMeta)) {
          return { ...meta, [groupTitle]: groupMeta };
        }
        return meta;
      }, {});
      yield put(
        Creators.getObjectTreeDependencyDataSuccess({
          objectTreeData: responses,
          meta: groupLevelMeta,
          resetDependents
        })
      );
    }
  } catch (error) {
    const payload = sagaCatchBlockHandling(error);
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataFail(payload));
    } else {
      yield put(Creators.getObjectTreeDependencyDataFail(dependents));
    }
  }
}

export function* getObjectTreeDataV3Saga({ payload, dependencyPayload = {} }) {
  const {
    tab,
    menuId,
    directParams,
    directURL,
    method,
    responseData,
    placementKey
  } = payload;
  const {
    updatedGroup,
    dependentOn,
    dependents,
    metaDependency,
    updateGroupPayload,
    resetDependents,
    keyTitleMapping
  } = dependencyPayload;
  try {
    // will load entire filter data
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataStart());
      let URL = `${apiEndPoints.getFilterCategory}`;

      if (directURL) {
        URL = directURL;
      }

      const fullURL = yield URL;

      let response;

      if (responseData) {
        response = yield WebService?.[method || 'get'](fullURL, responseData, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      } else {
        response = yield WebService?.[method || 'get'](fullURL, {
          params: { lang: 'eng', tab, menuId, ...directParams }
        });
      }

      if (response.status >= 200 && response.status < 300) {
        const { data, meta: metaRaw, resultType } = response.data;
        const meta = !isEmpty(metaRaw) ? metaRaw[0] : {};
        let dependencies = [];
        let dependents = {};
        if (!isEmpty(meta)) {
          // compute final dependencies combing all the dependencies
          dependencies = Object.keys(meta.dependency).reduce(
            (final, dependent) => {
              const currEle = meta.dependency[dependent];
              const newDependencies = currEle.dependencies.reduce(
                (prev, curr) => {
                  if (!prev.includes(curr)) {
                    return [...prev, curr];
                  }
                  return prev;
                },
                final
              );
              return newDependencies;
            },
            []
          );

          // compute  dependents for each group
          dependents = dependencies.reduce((finalDependents, dependency) => {
            const eleDependents = Object.keys(meta.dependency).reduce(
              (prev, curr) => {
                const currEle = meta.dependency[curr];
                if (currEle.dependencies.includes(dependency)) {
                  return [...prev, curr];
                }
                return prev;
              },
              []
            );
            return { ...finalDependents, [dependency]: eleDependents };
          }, {});
        }
        const { formatedData, keyTitleMapping } = formatedFilterData(data);
        // meta of groups
        const groupLevelMeta = formatedData.reduce((meta, groupDetails) => {
          const { groupTitle, meta: groupMeta } = groupDetails;
          if (!isEmpty(groupMeta)) {
            return { ...meta, [groupTitle]: groupMeta };
          }
          return meta;
        }, {});

        yield put(
          Creators.getObjectTreeDataV3Success({
            data: formatedData,
            meta: { ...meta, ...groupLevelMeta },
            resultType,
            parent: tab,
            dependencies,
            dependents,
            keyTitleMapping,
            menuId,
            placementKey
          })
        );
      } else {
        throw response;
      }
    } else {
      yield put(Creators.getObjectTreeDependencyDataStart({ dependentOn }));
      // console.log('loading only this...', updatedGroup, dependentOn, dependents);
      const rawResponses = yield Promise.all(
        dependents.map((dependent) => {
          const filteredObj = Object.keys(updateGroupPayload.data).reduce(
            (prev, group) => {
              if (
                metaDependency[dependent].dependencies
                  .map((e) => (e === 'tree' ? 'tree' : keyTitleMapping[e]))
                  .includes(group)
              ) {
                return { ...prev, [group]: updateGroupPayload.data[group] };
              }
              return prev;
            },
            {}
          );
          // console.log('filteredObj', filteredObj);
          return WebService.post(metaDependency[dependent].api, {
            ...updateGroupPayload,
            data: filteredObj
          });
        })
      );
      const responses = rawResponses.map((e, index) => ({
        ...e.data[dependents[index]],
        keyName: dependents[index]
      }));

      // meta of groups
      const groupLevelMeta = responses.reduce((meta, groupDetails) => {
        const { groupTitle, meta: groupMeta } = groupDetails;
        if (!isEmpty(groupMeta)) {
          return { ...meta, [groupTitle]: groupMeta };
        }
        return meta;
      }, {});
      yield put(
        Creators.getObjectTreeDependencyDataSuccess({
          objectTreeData: responses,
          meta: groupLevelMeta,
          resetDependents
        })
      );
    }
  } catch (error) {
    const payload = sagaCatchBlockHandling(error);
    if (!updatedGroup) {
      yield put(Creators.getObjectTreeDataFail(payload));
    } else {
      yield put(Creators.getObjectTreeDependencyDataFail(dependents));
    }
  }
}
