/* eslint-disable max-len */

import { format as defaultFormat, graphFormat } from '../../config/index';

const moment = require('moment');

export const getFormatedTimestamp = (date = new Date(), format = defaultFormat) => moment(date).format(format);

export const getFormatedgraphTS = (date, format = graphFormat) => moment(date).format(format);

export const isSame = (date1, date2) => moment(date1).isSame(date2, 'minute');

export const isSameOrAfter = (date1, date2) => moment(date1).isSameOrAfter(date2, 'minute');

export const isSameOrBefore = (date1, date2) => moment(date1).isSameOrBefore(date2, 'minute');

export const updateTime = (prevTime, operation, unitVal, unit) => {
  if (operation === 'back') {
    return moment(prevTime).subtract(unitVal, unit);
  }
  return moment(prevTime).add(unitVal, unit);
};
