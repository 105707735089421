/* eslint-disable function-paren-newline */
/* eslint-disable prefer-template */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable indent */
/* eslint-disable max-len */
import moment from 'moment';
import React from 'react';
import ReactExport from 'react-export-excel';
import { useSelector } from 'react-redux';
import { CHART_TYPE_CATEGORY } from '../../../constants';
import { isValidDate } from '../../../shared/utility';
import { isEmpty } from '../../../shared/utility/isEmpty';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const Download = ({ name }) => {
  const panesData = useSelector((state) => state.KPVCharts.panesData);
  const panesShortHandInfo = useSelector(
    (state) => state.KPVCharts.panesShortHandInfo
  );

  const columns = (pane, chartTypeCategory, paneTitle, index) => {
    switch (chartTypeCategory) {
      case CHART_TYPE_CATEGORY.DATA_CHART:
        return (
          <ExcelSheet
            data={
              pane.rawPlotData.data[0]?.TSValue ? pane.rawPlotData.data : []
            }
            name={paneTitle || `Pane${index}`}
          >
            <ExcelColumn
              label='Timestamp'
              value={(col) =>
                isValidDate(col.TSValue)
                  ? new Date(col.TSValue).toLocaleString()
                  : null
              }
            />
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={pane.rawPlotData.meta[col].fields.displayname}
                value={`${col}-SensorData`}
              />
            ))}
          </ExcelSheet>
        );
      case CHART_TYPE_CATEGORY.LABEL:
        return (
          <ExcelSheet
            data={pane?.rawPlotData?.data[0]?.X ? pane.rawPlotData.data : []}
            name={paneTitle || `Pane${index}`}
          >
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={
                  pane.rawPlotData.meta[col]?.exportLabels?.xlabels ||
                  pane.rawPlotData.meta[col].mapping.X
                }
                value={(col) => (col.X ? col.X : null)}
              />
            ))}
            {/* <ExcelColumn
            label="x"
            value={(col) => (col.X ? col.X : null)}
          /> */}
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={
                  pane.rawPlotData.meta[col]?.exportLabels?.xlabels ||
                  pane.rawPlotData.meta[col].mapping.Y
                }
                value={(col) => (col.Y ? col.Y : null)}
              />
            ))}
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={pane.rawPlotData.meta[col].fields.displayname}
                value={`${col}`}
              />
            ))}
          </ExcelSheet>
        );
      case CHART_TYPE_CATEGORY.XY_CHART:
        return (
          <ExcelSheet
            data={pane?.rawPlotData?.data[0]?.X ? pane.rawPlotData.data : []}
            name={paneTitle || `Pane${index}`}
          >
            {/* <ExcelColumn
            label='X'
            value={(col) => (col.X ? col.X : null)}
          />
          <ExcelColumn
            label='Y'
            value={(col) => (col.Y ? col.Y : null)}
          /> */}
            {/* <ExcelColumn
            label='Xtal Frequency'
            value={(col) => (col['Xtal Frequency'] ? col['Xtal Frequency'] : null)}
          /> */}
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={
                  pane.rawPlotData.meta[col]?.exportLabels?.xlabels ||
                  pane.rawPlotData.meta[col].mapping.X
                }
                value={(col) => (col.X ? col.X : null)}
              />
            ))}
            {Object.keys(pane.rawPlotData.meta).map((col) => (
              <ExcelColumn
                label={
                  pane.rawPlotData.meta[col]?.exportLabels?.ylabels ||
                  pane.rawPlotData.meta[col].mapping.Y
                }
                value={(col) => (col.Y ? col.Y : null)}
              />
            ))}
            {Object.keys(pane?.rawPlotData?.meta).map((col) => (
              <ExcelColumn
                label={pane.rawPlotData.meta[col].fields.displayname}
                value={`${col}`}
              />
            ))}
          </ExcelSheet>
        );
      case CHART_TYPE_CATEGORY.TRANSITION_CHART:
        return (
          <ExcelSheet
            data={pane.rawPlotData.data[0]?.label ? pane.rawPlotData.data : []}
            name={paneTitle || `Pane${index}`}
          >
            <ExcelColumn
              label='start'
              value={(col) =>
                isValidDate(col.start || col.Start)
                  ? new Date(col.Start || col.start).toLocaleString()
                  : null
              }
            />
            <ExcelColumn
              label='end'
              value={(col) =>
                isValidDate(col.End || col.end)
                  ? new Date(col.End || col.end).toLocaleString()
                  : null
              }
            />
            <ExcelColumn label='state' value='state' />
            <ExcelColumn
              label='label'
              value={(obj) =>
                `${
                  Object.keys(pane.rawPlotData.meta)
                    .reduce(
                      (final, e) => [
                        ...final,
                        ...pane.rawPlotData.meta[e].fields.Labels
                      ],
                      []
                    )
                    .find((e) => e.label === obj.label)?.labeltext
                } (${obj.label})`
              }
            />
          </ExcelSheet>
        );
      case CHART_TYPE_CATEGORY.SPC:
        // eslint-disable-next-line no-case-declarations
        const meta = Object.values(pane.rawPlotData.meta)[0];
        return (
          <ExcelSheet
            // data={pane.rawPlotData.data[0]?.X ? [...(pane.rawPlotData.data || []), ...(pane.overlaysData || [])] : []}
            data={pane?.paneData || []}
            name={paneTitle || `Pane${index}`}
          >
            <ExcelColumn
              label='TSValue'
              value={(col) =>
                isValidDate(col.X) ? new Date(col.X).toLocaleString() : null
              }
            />
            {/* default overlays */}
            <ExcelColumn
              label='PointLabel'
              value={(col) =>
                col.X ? meta?.xlabel[col.xPointer]?.PointLabel : null
              }
            />
            {meta?.fields?.default.map((colName) =>
              meta?.menuOverlays?.[colName].map((nodeEle) => {
                const { node, Text } = nodeEle;
                return (
                  <ExcelColumn
                    label={Text}
                    value={(col) => col?.[node + '-Y']}
                  />
                );
              })
            )}
            {/* enabled overlays */}
            {!isEmpty(pane.showOverlays) ? (
              Object.values(pane.showOverlays).map((colName) => {
                const { data } = colName;
                return data.map((nodeEle) => {
                  const { node, Text } = nodeEle;
                  return (
                    node && (
                      <ExcelColumn
                        label={Text}
                        value={(col) => col?.[node + '-Overlay'] || null}
                      />
                    )
                  );
                });
              })
            ) : (
              <></>
            )}
          </ExcelSheet>
        );
      default:
        return <></>;
    }
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <ExcelFile element={<span>{name}</span>}>
      {panesData.length &&
        panesData?.map((pane, index) => {
          const paneTitle = panesShortHandInfo[index]?.paneTitle;
          let paneDetails = pane;
          const chartTypeCategory =
            panesShortHandInfo[index]?.chartTypeCategory ||
            panesShortHandInfo[index]?.chartType;
          const meta = Object.values(pane?.rawPlotData?.meta)[0];
          if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
            const { overlaysData, showOverlays, overlayDomain } =
              panesShortHandInfo[index];
            // reset the enabled overlay domain pointers
            const overlayDomainPointer = {
              ...(Object.entries(overlayDomain)?.reduce((prev, e) => {
                const [key, value] = e;
                return { ...prev, [key]: { ...value, currentPos: -1 } };
              }, {}) || {})
            };
            // reset the default overlays domain
            const defaultDomainPointers = {
              ...(Object.entries(meta?.overlayDomain)?.reduce((prev, e) => {
                const [key, value] = e;
                return { ...prev, [key]: { ...value, currentPos: -1 } };
              }, {}) || {})
            };

            // combine all the data
            const paneData = Object.values(meta?.xlabel).map((entry) => {
              const e = { ...entry, X: entry?.TSValue };
              let extraInfo = {};
              const dataLength = pane.rawPlotData.data?.length;
              // default data
              Object.entries(defaultDomainPointers).forEach((entry) => {
                const [key, value] = entry;
                const { startIndex, currentPos } = value;
                const pos = currentPos > -1 ? currentPos : startIndex;
                // console.log('e', currentPos, startIndex, pos, pane.rawPlotData.data[pos]?.X, e?.X);
                if (moment(pane.rawPlotData.data[pos]?.X).isSame(e?.X)) {
                  // console.log('yessssssssss-11111111111', e?.X);
                  value.currentPos = pos + 1;
                  extraInfo = {
                    ...extraInfo,
                    ...pane.rawPlotData.data[pos]
                  };
                } else if (e?.X > value.X) {
                  value.currentPos = pos + 1;
                }
              });

              // overlay data
              Object.entries(overlayDomainPointer).forEach((entry) => {
                const [key, value] = entry;
                const { startIndex, currentPos } = value;
                const pos =
                  currentPos > -1 ? currentPos : startIndex - dataLength;
                // console.log('e', currentPos, startIndex, currentPos || startIndex, pos, overlaysData[pos]?.X, e?.X);
                if (moment(overlaysData[pos]?.X).isSame(e?.X)) {
                  // console.log('yessssssssss-222222222', e?.X);
                  value.currentPos = pos + 1;
                  extraInfo = {
                    ...extraInfo,
                    ...overlaysData[pos]
                  };
                } else if (e?.X > value.X) {
                  value.currentPos = pos + 1;
                }
              });
              // console.log('extra', extraInfo);
              return { ...e, ...extraInfo };
            });
            paneDetails = {
              ...pane,
              overlaysData,
              showOverlays,
              paneData
            };
            // console.log(
            //   'data',
            //   overlayDomain,
            //   'def',
            //   meta?.overlayDomain,
            //   pane.rawPlotData.data,
            //   paneDetails?.overlaysData,
            //   [...(pane.rawPlotData.data || []), ...(paneDetails.overlaysData || [])]
            // );
          }
          return columns(paneDetails, chartTypeCategory, paneTitle, index);
        })}
    </ExcelFile>
  );
};
export default Download;
