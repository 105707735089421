/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import MonthPicker from './MonthPicker/MonthPicker';
import { getUnitDateAdder } from '../Common/Header/utlity';

export default function MonthRangePicker({
  handleSelection,
  initValue,
  MaxAllowedRange,
  clearSelection,
  setClearSelection
}) {
  const [isAtStart, setIsAtStart] = useState(1);
  // const [selectedRange, setSelectedRange] = useState({
  //   start: new Date(moment()),
  //   end: new Date(moment())
  // });
  const initObj = {
    start: undefined,
    end: undefined
  };
  const [selectedRange, setSelectedRange] = useState(initObj);
  const [hoveredDate, setHoveredDate] = useState('');
  const todaysYear = new Date().getFullYear();
  const [year, setYear] = useState({ start: todaysYear - 1, end: todaysYear });

  const formatSelectionObj = (obj) => ({
    value: `${moment(obj.start).format('MMM YYYY')} - ${moment(obj.end).format('MMM YYYY')}`,
    rawValue: obj
  });

  useEffect(() => {
    if (!isEmpty(initValue)) {
      setSelectedRange({ start: new Date(initValue.start), end: new Date(initValue.end) });
    } else {
      handleSelection(formatSelectionObj(selectedRange));
    }
  }, []);

  useEffect(() => {
    if (clearSelection) {
      setSelectedRange(initObj);
      handleSelection(formatSelectionObj(initObj));
      setClearSelection(false);
      setIsAtStart(1);
    }
  }, [clearSelection]);

  const { unit, value: unitValue } = MaxAllowedRange || {};
  const restictedRange = () => {
    if (!selectedRange.start) {
      return {
        minDate: undefined,
        maxDate: undefined
      };
    }
    const date = new Date(selectedRange.start);
    if (!unit || !unitValue) {
      return { minDate: undefined, maxDate: undefined };
    }
    const obj = {};
    // set the function based on unit
    const fn = getUnitDateAdder(unit);
    // not allowing backward date selection
    obj.minDate = date;
    // fn(date, -unitValue);
    obj.maxDate = new Date(moment(fn(date, unitValue)).date(1));
    // console.log('obj', obj, unit, unitValue, new Date(moment(fn(date, unitValue))));
    return obj;
  };
  return (
    <FlexContainer style={{ width: '100%' }}>
      {parseInt(unitValue, 10) > 0 && (
        <MonthPicker
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          isAtStart={isAtStart}
          isSingleSelection={parseInt(unitValue, 10) === 0}
          setIsAtStart={setIsAtStart}
          title='start'
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          year={year}
          setYear={setYear}
          handleSelection={handleSelection}
          formatSelectionObj={formatSelectionObj}
          maxDate={restictedRange().maxDate}
        />
      )}

      <MonthPicker
        isAtStart={isAtStart}
        title='end'
        setIsAtStart={setIsAtStart}
        isSingleSelection={parseInt(unitValue, 10) === 0}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
        maxDate={restictedRange().maxDate}
      />
    </FlexContainer>
  );
}
