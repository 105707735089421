import React from 'react';
import styled from 'styled-components';

export const ToolTip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.specificDetails.primary};
  width: max-content;
  max-width: ${(props) => props.width || '100px'};
  word-break: break-word;
  height: fit-content;
  font-size: 1.2rem;
  /* display: flex; */
  color: white;
  justify-content: center;
  align-items: center;
  top: ${(props) => props.top || '100%'};
  left: ${(props) => props.left || '0'};
  bottom: 0px;
  /* left: 0; */
  border-radius: 4px;
  padding: 5px 10px;
  z-index: 1;
  display: none;
  white-space: normal;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    z-index: -1;
    width: 0;
    height: 0;
    border-bottom: 5px solid ${({ theme }) => theme.specificDetails.primary};
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`;
export const TooltipBox = styled.div`
  position: relative;


  &:hover {
    .tooltip {
      display: flex;
      pointer-events: none;
    }
  }
`;
const Tooltip = ({ name, width, top, left, type }) => (
  <>
    {name && (
      <ToolTip top={top} left={left} width={width} className='tooltip'>
        {name}
      </ToolTip>
    )}
  </>
);

export default Tooltip;
