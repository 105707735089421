/* eslint-disable max-len */
import styled, { createGlobalStyle, css } from 'styled-components';
import { device, minDevice } from '../constants';
import { maxSizeDevice } from '../constants/device';

export const GlobalStyles = createGlobalStyle`

  html {
    height: 100%;
  }
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.contrast.quaternary};
    height: 100%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: 'Circular Std Book', sans-serif;
    /* scrollbar-color: ${({ theme }) => theme.contrast.quaternary} #F5F5F5; */
    scrollbar-color:#9F9F9F;
    scrollbar-width: thin;


    // 1921px - 1441px
    @media ${device.laptopXL} and ${minDevice.laptopL} { 
      font-size: 50%;
    }

    // 2560px - 1920px
    @media ${device.desktop} and ${minDevice.laptopXL} {
      font-size: 62.5%;
    }
 
    // 1440px - 768px
    @media ${device.laptopL} and ${minDevice.tablet} {
      font-size: 47.5%;
    }
    @media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    @page{
      size: landscape;
    }
}
  }

    }
  /* p {
    user-select: none;
    } */
  h2{
    font-size: 2.2rem;
  }

  .field-col{
    min-width: 150px;
    margin-bottom: 1rem;
    margin-right: 8rem;
    /* border: 5px solid red; */
    & .field-colon{
      margin-right: 2rem;
    }
  }
  .col-sm{
    /* background: yellow; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }  
    // 768px - 1439px 
    @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 150px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }
    
    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 200px;
    }

    // 1920px 
    @media  ${minDevice.laptopXL} {
      width: 250px;
    }
  }

  .col-md{
    /* background: orange; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px
    @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 200px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 250px;
    } 

    // 1920px  
    @media ${minDevice.laptopXL} {
      width: 300px;
    }
  }

  .col-lg{
    /* background: red; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px 
     @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 250px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 300px;
    }

    // 1920px
    @media ${minDevice.laptopXL} {
      width: 350px;
    }
  }

  .col-xl{
    /* background: blue; */
    & .field-layout{
        display: block;
    }
    & .field-colon{
        display: none;
    }
    // 768px - 1440px 
     @media ${maxSizeDevice.laptopL} and ${minDevice.tablet} {
      width: 300px;
      & .field-layout{
        display: block;
      }
      & .field-colon{
        display: none;
      }
    }

    // 1920px - 1440px common
    @media ${maxSizeDevice.laptopXL} and ${minDevice.laptopL} {
      width: 350px;
    }

    // 1920px - 2560px 
    @media ${minDevice.laptopXL} {
      width: 400px;
    }
  }
    
  .non-selectable{
    user-select: none;
  }
    
  a {
    text-decoration: none;
    }

  .text-overflow{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .hideScrollBar{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hideScrollBar::-webkit-scrollbar {
      display: none;
    }
  .cursor{
    pointer:cursor;
  }
  
  .dynamicSVGIcon{
    height: auto;
  }

  a[href], input[type="submit"], button, select, label[for], .pointer{
    cursor: pointer;
  }

  .disable{
    pointer-events: none;
    opacity: 0.5
  }
  .required:after {
    content:" *";
  }

  .not-allowed {cursor: not-allowed;}
   
  .hide{
    display: none
  }

  .capitalize{
    text-transform: capitalize;
  }

  .red{
    color: red;
  }

  .rightAlign{
    right: 0px;
  }  
  ::-webkit-scrollbar-track
  {
    /* -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.core.pureSecondary};
    background-color: ${({ theme }) => theme.core.pureSecondary};  */
    border-radius: 10px;
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar
  {
    width: 5px;
    height: 5px;
    background-color: transparent;  
  }
  
  ::-webkit-scrollbar-thumb
  {
    /* background-color: ${({ theme }) => theme.specificDetails.primary}; */
    border-radius: 10px;
    background-color: transparent;
  }
.on-scrollbar{
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.on-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.core.pureSecondary};
  background-color: ${({ theme }) => theme.core.pureSecondary}; 
}

.on-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

.on-scrollbar::-webkit-scrollbar-thumb {
 background-color: ${({ theme }) => theme.specificDetails.primary};
 border-radius: 10px;
}
  
  `;
