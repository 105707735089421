/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
// import styled from 'styled-components';
// import DropDownModal from '../../Common/DropDownModal/DropDownModal_V1';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { DropdownIcon } from '../../ResultView/KPVCustomComponent/KPVCustom.style';
import { BreadCrumbText } from './BreadCrumb.styles';
import { DropDownArrow } from '../../../assets/icons';
import { ModalContainer, ModalContentText } from '../../Common/DropDownModal/DropDownModal.style';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
// import { getResolvedBreadCrumb } from '../../ResultView/Iframe/generateSelectedFilter';
import useClickOutside from '../../../shared/hooks/useClickOutside';

const GetBreadCrumbItem = ({ breadCrumb, index, className }) => {
  const dispatch = useDispatch();

  const handleBreadCrumbSelect = (index) => {
    dispatch(FilterCreators.universalFilterSetter({ key: 'activeBreadCrumb', value: index }));
  };
  return (
    <ModalContentText
      className={className}
      onClick={() => handleBreadCrumbSelect(index)}
      style={{ marginTop: '1.5rem' }}
    >
      {breadCrumb.key.map((level) => (
        <div style={{ fontSize: '1.4rem' }}>{level}</div>
      ))}
    </ModalContentText>
  );
};

const BreadCrumbComp = (props) => {
  // const [breadCrumb, setBreadCrumb] = useState('');
  // const [breadCrumbArr, setBreadCrumbArr] = useState([]);

  const { breadCrumbs, activeBreadCrumb } = props;

  // const getBreadCrumb = (obj) => {
  //   const tempBreadCrumbArr = Object.keys(obj).map((pathNo) => {
  //     const path = obj[pathNo];
  //     let tempBreadCrumb = '';
  //     Object.keys(path).forEach((ele, index) => {
  //       if (path[ele].values) tempBreadCrumb += `${path[ele].name} > `;
  //       else if (index !== Object.keys(path).length - 1) {
  //         tempBreadCrumb += `${path[ele].name}, `;
  //       } else {
  //         tempBreadCrumb += `${path[ele].name}`;
  //       }
  //     });
  //     return { pathNo, label: tempBreadCrumb };
  //   });
  //   setBreadCrumbArr(tempBreadCrumbArr);
  //   setBreadCrumb(tempBreadCrumbArr[0]);
  // };

  // useEffect(() => {
  //   if (cummulativeSelectedObj) getBreadCrumb(cummulativeSelectedObj);
  // }, [cummulativeSelectedObj]);

  // const [showBreadCrumbModal, setShowBreadCrumbModal] = useState(false);
  // const handleBreadCrumbSelection = (item) => {
  //   setShowBreadCrumbModal(false);
  //   setBreadCrumb(item);
  // };

  const [showBreadCrumbModal, setShowBreadCrumbModal] = useState(false);
  const modalRef = useRef();
  useClickOutside(modalRef, () => setShowBreadCrumbModal(false));
  return (
    <div ref={modalRef} onClick={() => setShowBreadCrumbModal(!showBreadCrumbModal)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BreadCrumbText className='pointer' onClick={() => setShowBreadCrumbModal(true)}>
          {breadCrumbs?.length > 0 && (
            <GetBreadCrumbItem
              className='outside'
              breadCrumb={breadCrumbs[activeBreadCrumb] || breadCrumbs[0]}
              index={activeBreadCrumb}
            />
          )}
        </BreadCrumbText>
        {breadCrumbs?.length > 0 && (
        <DropdownIcon className={showBreadCrumbModal ? 'active dp' : 'dp'}>
          <DynamicSVGIcon iconUrl={DropDownArrow} />
        </DropdownIcon>
)}
      </div>
      {showBreadCrumbModal && (
        <ModalContainer width="auto" className='outside'>
          {breadCrumbs.map((breadCrumb, index) => (
            <GetBreadCrumbItem breadCrumb={breadCrumb} index={index} />
          ))}
        </ModalContainer>

        // <DropDownModal
        //   TextStyle={{ color: 'black', fontSize: '1.4rem' }}
        //   ContainerStyle={{
        //     // width: '100px',
        //     overflowy: 'scroll',
        //     height: '150px',
        //     marginTop: '2rem',
        //     padding: '0px 10px',
        //     backgroundColor: 'white'
        //   }}
        //   data={breadCrumbArr}
        //   itemContainerStyle={{
        //     overflowX: 'auto',
        //     flexWrap: 'noWrap'
        //   }}
        //   setModalIsVisibal={setShowBreadCrumbModal}
        //   handleClick={handleBreadCrumbSelection}
        // />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  breadCrumbs: state.filterData.breadCrumbs,
  activeBreadCrumb: state.filterData.activeBreadCrumb
});
export default connect(mapStateToProps)(BreadCrumbComp);
