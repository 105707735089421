/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import Download from '../../KPVCharts/KPVCharting/ExportToExcel';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { ModalContainer, ModalContentDiv, ModalContentText } from './DropDownModal.style';

function DropDownModal(props) {
  const {
    style = {},
    ContainerStyle = {},
    TextStyle = {},
    data = [],
    itemContainerStyle = {},
    setModalIsVisibal,
    kababIconRef = false,
    handleClick,
    version = 1,
    isActive,
    children,
    className // provide "rightAlign" to move the dropdown to left
  } = props;
  const modalRef = useRef();
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  useClickOutside(
    modalRef,
    () => setModalIsVisibal(false)
    // kababIconRef
  );

  // undefined to handle prev developed code
  // if (!isActive && isActive !== undefined) {
  //   return <></>;
  // }
  return (
    <>
      {children}
      {(isActive || isActive === undefined) && (
        <ModalContainer
          className={`${className} ${version === '1' ? '' : 'outside'}`}
          ref={modalRef}
          style={ContainerStyle || style}
        >
          {data.length > 0 &&
            data.map((item, i) => (
              <ModalContentDiv
                className={`${item.class || ''} 'list'`}
                style={itemContainerStyle}
                key={i}
                onClick={(e) => { handleClick(item, e); }}
              >
                {item.role === 'exportAnalytics' ? (
                  <ModalContentText className={item.disable ? 'disable' : ''} style={TextStyle}>
                    <Download name={item.label} />
                  </ModalContentText>
                ) : (
                  <ModalContentText className={`settingMenu ${item.disable ? 'disable' : ''}`} style={TextStyle}>
                    <DynamicSVGIcon iconUrl={item.svg} width='2.2rem' />
                    {item.svg && <span style={{ margin: '1rem' }} />}
                    {localeStrings[item.label] || item.label}
                  </ModalContentText>
                )}
              </ModalContentDiv>
            ))}
        </ModalContainer>
      )}
    </>
  );
}

export default DropDownModal;
