import styled from 'styled-components';
import { fontFamily } from '../../constants';

export const Header = styled.div`
display: flex;
justify-content: space-between;
padding: 1rem;

`;

export const HeadingContent = styled.div`
font-family: ${fontFamily.circularBook};
font-size: 2rem;
margin-left: 1rem;
color: #1A1A1A;
`;

export const HeaderMenu = styled.div``;
