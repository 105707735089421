import styled from 'styled-components';
import { fontFamily } from '../../../../constants';

export const Container = styled.div`
  display: flex;
  margin-top: 4rem;
  width: 100%;
`;

export const AvatarIconContainer = styled.div`
  margin-right: 4rem;
  & span {
    font-size: 5.5rem;
  }
`;

export const HeadingText = styled.div`
  color: ${({ theme }) => theme.contrast.primary};
  font: 3rem ${fontFamily.circularMedium};
  text-transform: capitalize;
`;

export const ContentContainer = styled.div`
  width: 70%;
  display: flex;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0rem 4rem;
  justify-content: space-between;
  align-items: center;
`;

export const UserDetailFeild = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 5rem 0 2rem;
  width: 100%;
  &.jc_evenly {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const Anchor = styled.div`
  color: #4362fc;
  font: 2rem ${fontFamily.circularBold};
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
`;

export const FormContainer = styled.div`
  /* height: 60vh; */
  overflow-y: auto;
  & .field-layout {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  & .field-col {
    margin-right: 0px;
    margin-bottom: 1rem;
  }
  & .minWidth {
    width: 8rem;
    margin-right: 2rem;
  }
`;
