/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import WidgetDashBoard from '../../component/Widget/DashBoards/WidgetDashBoard';
import PageHeader from '../../component/Common/PageHeader/PageHeader';
import ButtonComp from '../../component/Common/Button/Button.view';
// import SettingBtn from '../../component/Widget/Header/Settings/SettingBtn';
import { PAGE_TYPES } from '../../constants';
import Add from '../../assets/icons/Add.svg';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import { ContastIconColor } from '../../component/Common/CommonStyles';
import { Span } from '../../globalStyles';
import { useActions } from '../../store/hooks/useActions';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { WidgetContainer } from './Widget.style';
import ConfirmationModal from '../../component/Common/ConfirmationBox/ConfirmationBox.view';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { isEmpty } from '../../shared/utility/isEmpty';

const CustomWidgetDashboard = () => {
  const location = useLocation();
  const { isCustomDashboard, isPreview, isTemplateInstanceEdit, isConfigurableDashboard } = location.state || {};
  const widgetTypesIncluded = useSelector((state) => state.widgets.widgetTypesIncluded);
  const InheritedWPID = useSelector((state) => state.widgets.InheritedWPID);
  const {
    widgetDashboardTitle,
    templateInstanceName,
    templateInstanceFormInput,
    WTId,
    templateFormInput,
    WTIId,
    templateBasedData
  } = useSelector((state) => state.upsertWidgetData);
  const history = useHistory();
  // const WidgetTypeMenuRoutes = useSelector(
  //   (state) => state.home?.[PAGE_TYPES.WIDGET]
  // );
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const WidgetTypeMenuRoutes = widgetDashboardType === 'ALL' ? useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]) : useSelector((state) => state.home?.[PAGE_TYPES.WIDGET_INSTANCE]);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const {
    widgetDetailSetter,
    resetTemplateInstanceData,
    setToastError,
    updateWidgetsLayout,
    valueSetter
  } = useActions();
  const userData = useSelector((state) => state.loginData.userData);
  const WPId = useSelector((state) => state.upsertWidgetData.WPId);
  const [showSaveConfimation, setShowSaveConfimation] = useState(false);
  const layoutComponentData = useSelector(
    (state) => state.widgets.layoutComponentData
  );
  const showSidePanel = useSelector((state) => state.widgets.showSidePanel);
  const handleBack = () => {
    if ((isPreview && templateInstanceName)) {
      history.goBack();
    } else {
      history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    }
  };

  const templateLayoutMeta = useSelector(
    (state) => state.upsertWidgetData.templateLayoutMeta
  );
  const layoutWPID = useSelector((state) => state.upsertWidgetData.layoutWPID);

  const widgetPanelPayload = useSelector(
    (state) => state.widgets.widgetPanelPayload
  );

  const activeTab = useSelector((state) => state.widgets.activeTab);

  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      if (isCustomDashboard) {
        // custom tab
        history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
        /** When adding a widget in custom template to update the positions and panel layout data */
        if (!isEmpty(widgetPanelPayload)) {
          updateWidgetsLayout({
            widgetPayload: {
              ...widgetPanelPayload,
              WidgetPanelName: activeTab?.label
            },
            WPID: activeTab?.id
          });
        }
      } else if (!isTemplateInstanceEdit) {
        // tab creation from template instance
        const fullURL = `${apiEndPoints.getAllWidgetPanelTemplates}/${WTId}`;
        const response = await WebService.get(fullURL, {
          params: {
            UserId: userData.userId
          }
        });
        let data;
        if (response.status >= 200 && response.status < 300) {
          // eslint-disable-next-line prefer-destructuring
          data = response?.data?.data[0];
        }
        setLoading(true);
        const res = await WebService.post(
          apiEndPoints.addWidgetPanelTemplateInstance,
          {
            WTId,
            WidgetTemplateInstanceName: templateInstanceName,
            UserId: userData.userId,
            WidgetComponentData: templateInstanceFormInput,
            TemplateBaseData: templateBasedData,
            WidgetPanelLayoutData: !isEmpty(widgetPanelPayload)
              ? widgetPanelPayload?.WidgetPanelLayoutData
              : data?.WidgetPanelLayoutData[0]?.WidgetPanelLayoutData,
            WidgetPanelLayoutMetaData: !isEmpty(widgetPanelPayload)
              ? widgetPanelPayload?.WidgetPanelLayoutMetaData
              : templateLayoutMeta,
            WPID: layoutWPID,
            IsGlobal: false,
            Widgets: data?.Widgets
          }
        );
        if (res.status >= 200 && res.status < 300) {
          setLoading(false);
          // preview it in the custom dashboard and the navingate to instance list
          history.push({ pathname: '/home/widget-template-instance-list' });
        } else {
          setLoading(false);
          throw res;
        }
      } else {
        // tab instance updation
        const res = await WebService.post(
          `${apiEndPoints.updateWidgetPanelTemplateInstance}/${WTIId}`,
          {
            WTId,
            WidgetTemplateInstanceName: templateInstanceName,
            UserId: userData.userId,
            WidgetComponentData: templateInstanceFormInput,
            // WidgetPanelLayoutData: templateLayoutData,
            // WidgetPanelLayoutMetaData: templateLayoutMeta,
            WPID: layoutWPID
          }
        );
        if (res.status >= 200 && res.status < 300) {
          // route to main dashboard
          // history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
          if (isConfigurableDashboard) {
            // Into this loop when user goes to the instance configure page from the global instance list
            // Push the user back into Instance dashboard page Instead of main widget page
            history.push({ pathname: '/home/widgets/InstanceDashboards' });
          } else {
            history.push({ pathname: '/home/widget-template-instance-list' });
          }
        } else {
          throw res;
        }
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
    resetTemplateInstanceData();
  };
  const handleMenu = () => {
    // history.push('./home/create-widget');
    widgetDetailSetter({ key: 'showSidePanel', value: true });
  };
  const templateParamterDetails = {
    WidgetComponentData: templateInstanceFormInput,
    TemplateParameters: {
      widgetTypesIncluded,
      formInput: templateFormInput
    },
    WTIId,
    InheritedWPID
  };

  return (
    <WidgetContainer style={{ padding: '0rem 2rem' }}>
      <PageHeader
        handleBack={() => {
          valueSetter({ customTemplateTab: false });
          if (isPreview && templateInstanceName) {
            history.goBack();
          } else if (WidgetTypeMenuRoutes[menuIdofWidget]) {
            history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
          } else {
            history.goBack();
          }
        }}
        title={
          isPreview
            ? `${isTemplateInstanceEdit ? 'Edit' : 'Add new'}  Instance`
            : widgetDashboardTitle
        }
      >
        <div style={{ marginTop: '0px' }} className='buttonWrapper'>
          {/* <SettingBtn /> */}
          {isCustomDashboard &&
            (Object.keys(layoutComponentData)?.length > 0 ? (
              <ButtonComp
                className='saveBtn'
                style={{
                  marginRight: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={handleMenu}
              >
                <ContastIconColor>
                  <DynamicSVGIcon iconUrl={Add} />
                </ContastIconColor>
                <Span style={{ marginLeft: '1rem' }}>Add Widget</Span>
              </ButtonComp>
            ) : (
              !showSidePanel && (
                <ButtonComp
                  className='saveBtn'
                  style={{
                    marginRight: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={handleBack}
                >
                  <Span style={{ marginLeft: '1rem' }}>Discard</Span>
                </ButtonComp>
              )
            ))}
          {isPreview ? (
            <ButtonComp style={{ marginRight: '2rem' }} onClick={handleBack}>
              back
            </ButtonComp>
          ) : (
            <></>
          )}
          {!showSidePanel && (
            <ButtonComp
              className='saveBtn'
              dark
              style={{ marginRight: '2rem' }}
              onClick={() => setShowSaveConfimation(true)}
              disabled={!isCustomDashboard && !templateInstanceName}
            >
              save
            </ButtonComp>
          )}
        </div>
      </PageHeader>
      <WidgetDashBoard
        isTemplateDashboard={!isCustomDashboard}
        instancePreview={isPreview}
        templateParamterDetails={templateParamterDetails}
        isTemplateInstanceEdit={isTemplateInstanceEdit}
        isCustom={true}
      />
      <ConfirmationModal
        Header={
          isPreview
            ? 'Do you really want to save the Instance ?'
            : 'Do you really want to save the Tab?'
        }
        showModal={showSaveConfimation}
        handleDelete={handleSave}
        closeModalHandler={() => setShowSaveConfimation(false)}
        buttonLoading={loading}
        action='Save'
        message={
          isPreview
            ? 'The changes to the Instance will be Saved.'
            : 'No more widgets can be added to this Tab anymore.'
        }
      />
    </WidgetContainer>
  );
};

export default CustomWidgetDashboard;
