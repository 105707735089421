/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  showSideNav: false,
  loading: false,
  error: null,
  menu: [],
  subMenuOptions: null,
  activeMultiLevelNav: [],
  finalMenu: null,
  filterPaths: {},
  notificationsModal: false,
  settingsModalActiveTab: '',
  changePwd: false,
  helpDropdown: false,
  settingsDropdown: false,
  profileSettingsDropdown: false,
  showNotificationTabs: [],
  activeMenu: {},
  defaultUrl: '',
  // 'Alarms', 'Approvals', 'PI', 'Schedule', 'CBM alerts'
};

const setActiveMenu = (state = INITIAL_STATE, action) => {
  const { Id, id } = action.payload;
  // get all the nav except for myAnalytics reducer population
  return {
    ...state,
    activeMenuId: id || Id
  };
};

const setShowSideNav = (state = INITIAL_STATE, action) => {
  const { showSideNav } = action.payload;

  return {
    ...state,
    showSideNav
  };
};

const getMenuStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getMenuSuccess = (state = INITIAL_STATE, action) => {
  const { menu, subMenuOptions, finalMenu, ...componentPaths } = action.payload;

  return {
    ...state,
    menu,
    subMenuOptions,
    finalMenu,
    ...componentPaths,
    loading: false
  };
};

const getMenuFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const setActiveMultiLevelMenu = (state = INITIAL_STATE, action) => ({
  ...state,
  activeMultiLevelNav: action.payload
});

const setError = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    error,
    loading: false
  };
};

const setSubMenuOptions = (state = INITIAL_STATE, action) => {
  const { subMenuOptions } = action.payload;
  return {
    ...state,
    subMenuOptions
  };
};

const toggleHeaderOption = (state = INITIAL_STATE, action) => ({
  ...state,
  [action.payload]: !state[action.payload]
});

const settingsActiveMenu = (state = INITIAL_STATE, action) => ({
  ...state,
  settingsModalActiveTab: action.payload
});

const setNotificationTabs = (state = INITIAL_STATE, action) => ({
  ...state,
  showNotificationTabs: action.payload
});

const fetchDetailsStart = (state = INITIAL_STATE, action) => {
  const {
    key // ex: "notficationsCount"
  } = action.payload;
  return {
    ...state,
    [`${key}Loading`]: true
  };
};

const fetchDetailsFailure = (state = INITIAL_STATE, action) => {
  const {
    key, // ex: "notficationsCount"
    error
  } = action.payload;
  return {
    ...state,
    [`${key}Loading`]: false,
    [`${key}Error`]: error
  };
};

const getNotificationsCountSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    loading: false,
    showNotificationTabs: data,
    notificationsCountLoading: false
  };
};

const setDefaultUrlStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const setDefaultUrlSuccess = (state = INITIAL_STATE, action) => {
  const { defaultUrl } = action.payload;
  return {
    ...state,
    defaultUrl,
    loading: false
  };
};

const setDefaultUrlFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false
  };
};

const getDefaultUrlStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getDefaultUrlSuccess = (state = INITIAL_STATE, action) => {
  const { defaultUrl } = action.payload;
  return {
    ...state,
    defaultUrl,
    loading: false
  };
};

const getDefaultUrlFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false
  };
};

const HANDLERS = {
  [Types.SET_ACTIVE_MENU]: setActiveMenu,
  [Types.SET_SHOW_SIDE_NAV]: setShowSideNav,

  [Types.GET_MENU_START]: getMenuStart,
  [Types.GET_MENU_SUCCESS]: getMenuSuccess,
  [Types.GET_MENU_FAILURE]: getMenuFailure,

  [Types.SET_ERROR]: setError,
  [Types.SET_ACTIVE_MULTI_LEVEL_MENU]: setActiveMultiLevelMenu,
  [Types.SET_SUB_MENU_OPTIONS]: setSubMenuOptions,

  [Types.TOGGLE_HEADER_OPTION]: toggleHeaderOption,
  [Types.SETTINGS_ACTIVE_MENU]: settingsActiveMenu,
  [Types.SET_NOTIFICATION_TABS]: setNotificationTabs,

  [Types.FETCH_DETAILS_START]: fetchDetailsStart,
  [Types.FETCH_DETAILS_FAILURE]: fetchDetailsFailure,

  [Types.GET_NOTIFICATIONS_COUNT_SUCCESS]: getNotificationsCountSuccess,

  [Types.SET_DEFAULT_URL_START]: setDefaultUrlStart,
  [Types.SET_DEFAULT_URL_SUCCESS]: setDefaultUrlSuccess,
  [Types.SET_DEFAULT_URL_FAILURE]: setDefaultUrlFailure,

  [Types.GET_DEFAULT_URL_START]: getDefaultUrlStart,
  [Types.GET_DEFAULT_URL_SUCCESS]: getDefaultUrlSuccess,
  [Types.GET_DEFAULT_URL_FAILURE]: getDefaultUrlFailure,
};

export const homeReducer = createReducer(INITIAL_STATE, HANDLERS);
