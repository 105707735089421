export const notficationsSample = {
  data: [
    {
      id: 'P1_APR_1',
      timestamp: '2022-06-01 09:18:22',
      description: 'Ainol Shyankina Binti Modh Rodhi submitted a Production Information and your approval required'
    },
    {
      id: 'P1_APR_2',
      timestamp: '2022-06-01 09:18:22',
      description: 'Home Castro submitted a Lot Test Infromation and your approval is required'
    },
    {
      id: 'P1_APR_3',
      timestamp: '2022-06-01 09:18:22',
      description: 'Aileen Marsh submitted a Production Information and your approval is required'
    },
    {
      id: 'P1_APR_4',
      timestamp: '2022-06-01 09:18:22',
      description: 'Lim Wei Chuen submitted a 100% DVF Request and your approval is required'
    },
    {
      id: 'P1_APR_5',
      timestamp: '2022-06-01 09:18:22',
      description: 'Lino K.Thomas submitted a Production Test Information and your approval is required'
    },
    {
      id: 'P1_APR_1',
      timestamp: '2022-06-01 09:18:22',
      description: 'Ainol Shyankina Binti Modh Rodhi submitted a Production Information and your approval required'
    },
    {
      id: 'P1_APR_2',
      timestamp: '2022-06-01 09:18:22',
      description: 'Home Castro submitted a Lot Test Infromation and your approval is required'
    },
    {
      id: 'P1_APR_3',
      timestamp: '2022-06-01 09:18:22',
      description: 'Aileen Marsh submitted a Production Information and your approval is required'
    },
    {
      id: 'P1_APR_4',
      timestamp: '2022-06-01 09:18:22',
      description: 'Lim Wei Chuen submitted a 100% DVF Request and your approval is required'
    },
    {
      id: 'P1_APR_5',
      timestamp: '2022-06-01 09:18:22',
      description: 'Lino K.Thomas submitted a Production Test Information and your approval is required'
    }
  ],
  meta: {
    columns: [
      {
        key: 'description',
        title: 'Description',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      },
      {
        key: 'timestamp',
        title: 'Timestamp',
        searchable: true,
        sortable: false,
        visible: true,
        type: 'string',
        options: []
      }
    ]
  }
};
