/* eslint-disable max-len */
import { takeLatest, takeEvery } from 'redux-saga/effects';
import { Types } from './actions';

// imports
import { loginSaga, carouselDataSaga, notificationDataSaga, onLogoutSaga } from '../views/auth/store';
import { getMenuSaga, getNotificationsCountSaga, setDefaultUrlSaga, getDefaultUrlSaga } from '../views/Layout/store';
import { getLocaleStringsSaga } from '../localeStrings';
import { getFilterDataSaga, getIFrameDataSaga } from '../views/dashboard/FilterView/store/saga';
import { getKpvDataSaga, getKpvDetailsSaga } from '../component/ResultView/Store/saga';
import { getPlotDataSaga, getPaneOptionsSaga } from '../component/KPVCharts/Store';
import { setThemeColorsSaga } from '../config/saga';
import { getDashboardsSaga, getInstanceDashboardSaga } from '../views/MyDashboards/store';
import { getAnalyticsSaga } from '../views/MyAnalytics/store';
import { setDetailedAlaramDataSaga } from '../component/Alarams/Store';
import { getPanePlotDataSaga } from '../component/UpsertPane/Store/saga';
import {
  deleteWidgetsLayoutSaga,
  getWidgetsLayoutSaga,
  getWidgetTabsSaga,
  getWidgetTypesSaga,
  updateWidgetsLayoutSaga,
  getPreviewLayoutSaga,
  getPreviewTemplateSaga,
  getKpvListSaga
} from '../views/Widgets/store/saga';
import { addWidgetSaga, getWidgetDataSaga, getWidgetInstancesSaga } from '../component/UpsertWidget/Store';
import { setFormStructureSaga, setTemplateFormStructureSaga } from '../component/UpsertWidget/Store/saga';
import { getBulkUsersUpdateListSaga, getUsersListSaga, updateBulkUsersSaga } from '../views/Users/store';
import { getCategoryListSaga, getFilteredListSaga } from '../component/KPVCharts/PaneCard/AdditionalFilter/store/saga';
import {
  getObjectTreeDataSaga,
  getObjectTreeDataV2Saga,
  getObjectTreeDataV3Saga
} from '../component/WidgetForm/Store/saga';
import { getWidgetInstancesListSaga, addWidgetToHomeSaga, getHomeLayoutSaga, HomeLayoutUpdateSaga, addWidgetFromPreviewSaga, deleteWidgetFromHomeSaga, HomeAllWidgetsUpdateSaga } from '../views/Home/store';

export function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.ON_LOGOUT, onLogoutSaga);
  yield takeLatest(Types.GET_MENU, getMenuSaga);
  yield takeLatest(Types.SET_DEFAULT_URL, setDefaultUrlSaga);
  yield takeLatest(Types.GET_DEFAULT_URL, getDefaultUrlSaga);
  yield takeLatest(Types.CAROUSEL_DATA, carouselDataSaga);
  yield takeLatest(Types.NOTIFICATION_DATA, notificationDataSaga);
  yield takeLatest(Types.GET_LOCALE_STRINGS, getLocaleStringsSaga);
  yield takeLatest(Types.GET_FILTER_DATA, getFilterDataSaga);
  yield takeLatest(Types.GET_I_FRAME_DATA, getIFrameDataSaga);
  yield takeLatest(Types.GET_KPV_DATA, getKpvDataSaga);
  yield takeLatest(Types.GET_KPV_DETAILS, getKpvDetailsSaga);
  yield takeEvery(Types.GET_PLOT_DATA, getPlotDataSaga);
  yield takeLatest(Types.GET_PANE_PLOT_DATA, getPanePlotDataSaga);
  yield takeLatest(Types.SET_THEME_COLORS, setThemeColorsSaga);
  yield takeEvery(Types.SET_PANE_OPTIONS, getPaneOptionsSaga);
  yield takeLatest(Types.GET_DASHBOARDS, getDashboardsSaga);
  yield takeLatest(Types.GET_ANALYTICS, getAnalyticsSaga);
  yield takeLatest(Types.SET_DETAILED_ALARAM_DATA, setDetailedAlaramDataSaga);
  yield takeLatest(Types.GET_NOTIFICATIONS_COUNT, getNotificationsCountSaga);
  yield takeLatest(Types.GET_INSTANCE_DASHBOARD, getInstanceDashboardSaga);

  // widgets
  yield takeLatest(Types.GET_WIDGETS_LAYOUT, getWidgetsLayoutSaga);
  yield takeLatest(Types.GET_PREVIEW_LAYOUT, getPreviewLayoutSaga);
  yield takeLatest(Types.GET_PREVIEW_TEMPLATE, getPreviewTemplateSaga);
  yield takeLatest(Types.UPDATE_WIDGETS_LAYOUT, updateWidgetsLayoutSaga);
  yield takeLatest(Types.DELETE_WIDGETS_LAYOUT, deleteWidgetsLayoutSaga);
  yield takeLatest(Types.GET_WIDGET_TABS, getWidgetTabsSaga);
  yield takeLatest(Types.GET_WIDGET_TYPES, getWidgetTypesSaga);
  yield takeLatest(Types.ADD_WIDGET, addWidgetSaga);
  yield takeLatest(Types.GET_WIDGET_DATA, getWidgetDataSaga);
  yield takeLatest(Types.GET_WIDGET_INSTANCES, getWidgetInstancesSaga);
  yield takeLatest(Types.SET_FORM_STRUCTURE, setFormStructureSaga);
  yield takeLatest(Types.SET_TEMPLATE_FORM_STRUCTURE, setTemplateFormStructureSaga);
  yield takeLatest(Types.GET_USERS_LIST, getUsersListSaga);

  yield takeLatest(Types.GET_CATEGORY_LIST, getCategoryListSaga);
  yield takeLatest(Types.GET_FILTERED_LIST, getFilteredListSaga);
  yield takeLatest(Types.GET_BULK_USERS_UPDATE_LIST, getBulkUsersUpdateListSaga);
  yield takeLatest(Types.UPDATE_BULK_USERS, updateBulkUsersSaga);
  yield takeLatest(Types.GET_OBJECT_TREE_DATA, getObjectTreeDataSaga);
  yield takeLatest(Types.GET_OBJECT_TREE_DATA_V2, getObjectTreeDataV2Saga);
  yield takeLatest(Types.GET_OBJECT_TREE_DATA_V3, getObjectTreeDataV3Saga);

  yield takeLatest(Types.GET_WIDGET_INSTANCES_LIST, getWidgetInstancesListSaga);
  yield takeLatest(Types.ADD_WIDGET_TO_HOME, addWidgetToHomeSaga);
  yield takeLatest(Types.GET_HOME_LAYOUT, getHomeLayoutSaga);
  yield takeLatest(Types.HOME_LAYOUT_UPDATE, HomeLayoutUpdateSaga);
  yield takeLatest(Types.ADD_WIDGET_FROM_PREVIEW, addWidgetFromPreviewSaga);
  yield takeLatest(Types.DELETE_WIDGET_FROM_HOME, deleteWidgetFromHomeSaga);
  yield takeLatest(Types.HOME_ALL_WIDGETS_UPDATE, HomeAllWidgetsUpdateSaga);

  yield takeLatest(Types.GET_KPV_LIST, getKpvListSaga);
}
