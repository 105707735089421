/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable max-len */
import Card from '../component/Card';
import FilterView from '../views/dashboard/FilterView/Filter.view';
import MyPages from '../views/MyPages/MyPages.view';
import MydashboardsView from '../views/MyDashboards/MyDashBoards.view';
import MyAnalyticsView from '../views/MyAnalytics/MyAnalytics.view';
import Widget from '../views/Widgets/index';
import TemporaryWidget from '../views/Widgets/Temporary/index';
import { PAGE_TYPES } from '.';
import DirectIframe from '../views/DirectIframe/DirectIframe';
import { show_widgets } from '../config.json';
import { Users } from '../views/Users/Users.view';
import InstanceDashboardsView from '../views/InstanceDashboard/InstanceDashboardsView';
import InstanceOnlyDashboard from '../views/Widgets/InstanceOnlyDashboard';
import HomeDashboarview from '../views/Home/HomeDashboard.jsx';
// import Test from '../component/Card/Test';

const _ = require('lodash');

const mappingComponent = {
  [PAGE_TYPES.WIDGET]: show_widgets ? Widget : TemporaryWidget,
  [PAGE_TYPES.CARD]: HomeDashboarview,
  [PAGE_TYPES.FILTER]: FilterView,
  [PAGE_TYPES.MY_PAGE]: MyPages,
  [PAGE_TYPES.MY_DASHBOARDS]: MydashboardsView,
  [PAGE_TYPES.MY_ANAYLTICS]: MyAnalyticsView,
  [PAGE_TYPES.DIRECT_IFRAME]: DirectIframe,
  [PAGE_TYPES.USERS]: Users,
  [PAGE_TYPES.INSTANCE_DASHBOARD]: InstanceDashboardsView,
  [PAGE_TYPES.WIDGET_INSTANCE]: InstanceOnlyDashboard,
};

export const filteredMenu = (data) => {
  const routesInfo = {
    finalRoutes: [],
    // filterPaths: {},
    componentPaths: {}
  };
  // let finalRoutes = [];
  data.forEach((item) => {
    if (item.IsLeaf === '0') {
      const returnMenu = filteredMenu(item.SubMenu);
      if (returnMenu) {
        routesInfo.finalRoutes = [...routesInfo.finalRoutes, ...returnMenu.finalRoutes];
        // routesInfo.filterPaths = { ...routesInfo.filterPaths, ...returnMenu.filterPaths };
        routesInfo.componentPaths = _.merge(routesInfo.componentPaths, returnMenu.componentPaths);
      }
    } else {
      // if (item.PageType === 'Filter') {
      //   routesInfo.filterPaths = {
      //     ...routesInfo.filterPaths,
      //     [item.Id]: item.Permalink
      //   };
      // }

      // TODO: remove this, once it is handled in the api
      // const pageType = item.Name === 'Home' ? 'Widget' : item.PageType;
      const pageType = item.PageType;

      routesInfo.componentPaths[pageType] = routesInfo.componentPaths?.[pageType]
        ? {
            ...routesInfo.componentPaths[pageType],
            [item.Id]: item.Permalink
          }
        : { [item.Id]: item.Permalink };

      routesInfo.finalRoutes = [
        ...routesInfo.finalRoutes,
        {
          exact: true,
          path: item.Permalink,
          name: item.Name,
          menuId: item.Id,
          subMenuOptions: item.SubMenuLabelOptions,
          description: item['[Description]'],
          Component: mappingComponent[pageType]
        }
      ];
    }
  });
  // make home path same as oee
  // const oeeDetails = routesInfo.finalRoutes.find((e) => e.name === 'OEE');
  // const homeIndex = routesInfo.finalRoutes.findIndex((e) => e.name === 'Home');
  // routesInfo.finalRoutes[homeIndex] = { ...oeeDetails, path: '/home' };
  return routesInfo;
};
