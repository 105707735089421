import { createActions } from 'reduxsauce';

export const { Creators, Types } = createActions({
  setWidgetEditOptions: ['payload'],
  setWidgetType: ['payload'],
  setFormInput: ['payload'],

  setFormStructure: ['payload'],
  setFormStructureStart: ['payload'],
  setFormStructureSuccess: ['payload'],
  setFormStructureFailure: ['payload'],
  setTemplateBasedData: ['payload'],
  resetTemplateInstanceDetails: ['payload'],

  setTemplateFormInput: ['payload'],
  setTemplateFormStructure: ['payload'],
  setTemplateFormStructureStart: ['payload'],
  setTemplateFormStructureSuccess: ['payload'],
  setTemplateFormStructureFailure: ['payload'],

  valueSetter: ['payload'],
  resetData: ['payload'],
  resetTemplateInstanceData: ['payload'],
  setTemplateInstanceDetails: ['payload'],
  // donut
  inputSetter: ['payload'],

  // add widget
  addWidget: ['payload'],
  addWidgetStart: ['payload'],
  addWidgetSuccess: ['payload'],
  addWidgetFailure: ['payload'],
  DetailedStatusValueFormSubmission: ['payload'],
  removeEquipment: ['payload'],

  // actions for getwidgetdata
  getWidgetData: ['payload'],
  getWidgetDataStart: ['payload'],
  getWidgetDataSuccess: ['payload'],
  getWidgetDataFailure: ['payload'],

  setDonutInfo: ['payload'],
  getWidgetInstances: ['payload'],
  getWidgetInstancesStart: ['payload'],
  getWidgetInstancesSuccess: ['payload'],
  getWidgetInstancesFailure: ['payload'],
  setBulkUpsertWidgetDetails: ['payload'],
  resetTemplateInstanceForm: ['payload'],

  setTemplateLayoutData: ['payload']
});
