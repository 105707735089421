/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
// import moment from 'moment';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import ShowAlarmsView from '../../UpsertPane/ShowAlarms/ShowAlarms.view';
import { getInvertColor } from '../utils/getInvertColor';
// import { colorCodes } from '../Chart/DataChart.view';

import {
  ColorLabel,
  ColorLabelText,
  LegendContainer,
  LegendContainerBtm,
  LegendItemContainer,
  LegendItemContainerBtm,
  OverlayLabelText
} from './Legend.style';

const LegendComp = ({
  data,
  pointData,
  color,
  position = 'right',
  noColor = false,
  chartType,
  chartTypeCategory,
  showOverlays,
  showAlarms,
  labels,
  transitionChartColorCodes,
  hoveredState,
  hoveredPosState,
  showLegends = true
}) => {
  // console.log('hoveredPosState', hoveredState, hoveredPosState);
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  const comparisionMode = useSelector((state) => state.KPVCharts.comparisionMode);
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);
  // console.log('transitionChartColorCodes--2', position, transitionChartColorCodes, !noColor);
  // console.log('hoveredState', hoveredState);
  const transitionLabelValue = (e) => {
    if (comparisionMode && isAligned) {
      if (!isEmpty(hoveredPosState) && hoveredPosState[e]) {
        // return `${hoveredPosState[e]?.arg} =>  ${hoveredPosState[e]?.state}`;
        return ` ${(hoveredPosState[e] || [])?.map((e) => e.state)}`;
      }
      return 'NA';
    }
    return `${!isEmpty(hoveredState) && hoveredState[e] ? hoveredState[e]?.map((e) => e.state) : 'NA'}`;
  };

  const getDataChartLabelValue = (item, chartTypeCategory) => {
    if (comparisionMode && isAligned) {
      const itemKey = `${item.fields?.key}-SensorData`;
      if (!isEmpty(hoveredPosState) && hoveredPosState[itemKey]) {
        // return `${moment(hoveredPosState[itemKey]?.arg).format('DD/MM/YYYY HH:mm:ss:SSS')} =>  ${
        //   hoveredPosState[itemKey]?.value
        // }`;
        return `${hoveredPosState[itemKey]?.value}`;
      }
      return 'NA';
    }
    if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
      return '';
    }
    if (chartTypeCategory === CHART_TYPE_CATEGORY.LABEL || chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART) {
      return !isEmpty(hoveredState) ? hoveredState[`${item?.fields?.key}`] ?? 'NA' : 'NA';
    }
    return `${
      !isEmpty(hoveredState) ? hoveredState[hoveredState.length - 1][`${item?.fields?.key}-SensorData`] ?? 'NA' : 'NA'
    }`;
  };

  const getOverlayValue = (hoveredState, hoveredPosState, node) => {
    let labelValueObj = hoveredState;
    if (!isEmpty(hoveredPosState)) {
      labelValueObj = hoveredPosState;
    }
    return labelValueObj?.[node]?.isOverlay
      ? labelValueObj?.[node]?.[node + '-Overlay'] || 'NA'
      : labelValueObj?.[node]?.Y || 'NA';
  };

  if (chartTypeCategory === CHART_TYPE_CATEGORY.LABEL || chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART) {
    return (
      <LegendContainer position={position}>
        {labels &&
          labels.map((item, index) => {
            const activelabelValue = getDataChartLabelValue(item, chartTypeCategory);
            return (
              <>
                {/* show the label only if it has some value on hover
                  or if legends are enabled */}
                {((activelabelValue !== 'NA' && activelabelValue !== '') || showLegends) && (
                  <LegendItemContainer style={{ flexDirection: 'row' }}>
                    {!noColor && <ColorLabel bgColor={color || colorCodes[index]} />}
                    <div>
                      {/* <ColorLabelText>{pointData ? `${data[item]} (${pointData[item]})` : `${data[item]}`}</ColorLabelText> */}
                      <ColorLabelText>{`${item?.fields?.displayname} ${activelabelValue}`}</ColorLabelText>
                    </div>
                  </LegendItemContainer>
                )}
              </>
            );
          })}
      </LegendContainer>
    );
  }
  return (
    <>
      {/* {console.log({ chartType })} */}

      <LegendContainer position={position}>
        {/* <LegendItem /> */}
        {chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART && (
          <ColorLabelText>
            {/* {`${data[item]} `} {pointData?.machine === data[item] ? `(${pointData?.label})` : '(N/A)'} */}
            <LegendItemContainer>
              {/* states which are active shown in reverse mapping */}
              {transitionChartColorCodes &&
                Object.keys(transitionChartColorCodes).map((e) => {
                  const ele = transitionChartColorCodes[e];
                  const equipmentLabel = transitionLabelValue(e);
                  const hasEquipmentLabel = equipmentLabel !== 'NA';
                  return (
                    <>
                      {hasEquipmentLabel ? (
                        <LegendItemContainer style={{ flexDirection: 'row' }}>
                          {!noColor && <ColorLabel bgColor={ele?.color} />}
                          <ColorLabelText>{`${equipmentLabel}   ${ele?.name}`}</ColorLabelText>
                        </LegendItemContainer>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              {/* all the states */}
              {showLegends &&
                transitionChartColorCodes &&
                Object.keys(transitionChartColorCodes).map((e) => {
                  const ele = transitionChartColorCodes[e];
                  const { isGeneratedColour } = ele;
                  // const equipmentLabel = transitionLabelValue(e);
                  // const hasEquipmentLabel = equipmentLabel !== 'NA';
                  return (
                    <>
                      {
                        // !hasEquipmentLabel &&
                        !isGeneratedColour ? (
                          <LegendItemContainer style={{ flexDirection: 'row' }}>
                            {!noColor && <ColorLabel bgColor={ele?.color} />}
                            <ColorLabelText>{`${ele?.name}`}</ColorLabelText>
                          </LegendItemContainer>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  );
                })}
            </LegendItemContainer>
          </ColorLabelText>
        )}
        {chartTypeCategory !== CHART_TYPE_CATEGORY.TRANSITION_CHART && (
          <LegendItemContainer>
            {labels &&
              labels.map((item, index) => {
                const activelabelValue = getDataChartLabelValue(item, chartTypeCategory);
                return (
                  <>
                    {/* show the label only if it has some value on hover
                    or if legends are enabled */}
                    {((activelabelValue !== 'NA' && activelabelValue !== '') || showLegends) && (
                      <LegendItemContainer style={{ flexDirection: 'row' }}>
                        {!noColor && <ColorLabel bgColor={color || colorCodes[index]} />}
                        <div>
                          {/* <ColorLabelText>{pointData ? `${data[item]} (${pointData[item]})` : `${data[item]}`}</ColorLabelText> */}

                          <ColorLabelText>{`${item?.fields?.displayname} ${activelabelValue}`}</ColorLabelText>
                        </div>
                      </LegendItemContainer>
                    )}
                    <div style={{ width: '100%', flexWrap: 'wrap', justifyContent: 'space-evenly', display: 'flex' }}>
                      {/* maintain the order of menu overlays */}
                      {!isEmpty(showOverlays) &&
                        !isEmpty(item.menuOverlays) &&
                        Object.entries(item.menuOverlays).map((entry) => {
                          const [entryLabel, entryvalue] = entry;
                          return (
                            showOverlays[entryLabel] && (
                              <FlexContainer className='col ai_center'>
                                <ColorLabelText
                                  style={{
                                    marginTop: '0.5rem',
                                    marginBottom: '-0.5rem'
                                  }}
                                >
                                  {entryLabel}
                                </ColorLabelText>
                                {item.menuOverlays?.[entryLabel]?.map((overlayInnerItem) => {
                                  const { Text, color, node } = overlayInnerItem || {};
                                  const overlayHoverValue = getOverlayValue(hoveredState, hoveredPosState, node);
                                  // console.log('hoveredState', hoveredState, overlay);
                                  return (
                                    <>
                                      {/* show labels only when overlayHoverValue or legends enabled */}
                                      {overlayHoverValue || showLegends ? (
                                        <OverlayLabelText
                                          style={{ minWidth: '30rem' }}
                                          bgColor={color}
                                          color={getInvertColor(color)}
                                          className='jc_center'
                                        >
                                          {Text} {overlayHoverValue}
                                          {/* verification */}
                                          {/* {hoveredState?.[overlay]?.X?.toLocaleString()} */}
                                        </OverlayLabelText>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </FlexContainer>
                            )
                          );
                        })}
                      {chartTypeCategory === CHART_TYPE_CATEGORY.SPC && showLegends && (
                        <div style={{ width: '100%', marginTop: '2rem' }}>
                          <ShowAlarmsView
                            chartTypeCategory={chartTypeCategory}
                            meta={labels.reduce((acc, e, index) => ({ ...acc, [`legend${index}`]: e }), {})}
                            showAlarms={showAlarms}
                            isLegendView
                          />
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
          </LegendItemContainer>
        )}
      </LegendContainer>
    </>
  );
};

export default memo(LegendComp);

// old
// data &&
// <LegendItemContainer key={item}>
//   {!noColor && <ColorLabel bgColor={color || colorCodes[index]} />}
//   {chartType === CHART_TYPES.TRANSITION_CHART ? (
//     <div>
//       {/* <ColorLabelText>{pointData ? `${data[item]} (${pointData[item]})` : `${data[item]}`}</ColorLabelText> */}
//       <ColorLabelText>
//         {`${data[item]} `} {pointData?.machine === data[item] ? `(${pointData?.label})` : '(N/A)'}
//       </ColorLabelText>
//       {/* <ColorLabelText>
//         {pointData?.machine === data[item] ? `(${pointData?.label})` : '(N/A)'}
//       </ColorLabelText> */}
//     </div>
//   ) : (
//     <div>
//       {/* <ColorLabelText>{pointData ? `${data[item]} (${pointData[item]})` : `${data[item]}`}</ColorLabelText> */}
//       <ColorLabelText>{`${data[item]}`}</ColorLabelText>
//       <ColorLabelText>
//         {pointData &&
//           (pointData[item] !== undefined
//             ? `(${pointData[item]})`
//             : pointData?.value !== undefined
//             ? `(${pointData.value})`
//             : '(N/A)')}
//       </ColorLabelText>
//     </div>
//   )}
// </LegendItemContainer>
