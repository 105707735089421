import React from 'react';
import {
  SVGList,
  StatsContainer,
  StatsText,
  SvgContainer
} from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.style';
import SvgStatusCard from './SvgStatusCard.View';

const Fan = ({ colors, speed, dimension, componentData, numSvg }) => {
  const { formData } = componentData || {};
  const svgList = [];
  const width = `${dimension.width}px`;
  const height = `${dimension.height}px`;
  for (let i = 0; i < numSvg; i += 1) {
    svgList.push(
      <SvgContainer>
        <StatsContainer>
          {formData && (
          <SvgStatusCard
            machineStatus={formData?.machineState?.label}
            speed={formData?.speed?.label}
          />
          )}
          {/* <StatsText>
            {' '}
            Fan :
            {' '}
            {i + 1}

          </StatsText>
          <StatsText>
            {' '}
            Machine Status :
            {' '}
            {formData?.machineState?.label}

          </StatsText>
          <StatsText>
            {' '}
            Speed :
            {' '}
            {formData?.speed?.label}

          </StatsText> */}
        </StatsContainer>

        <svg
          width='100%'
          height='100%'
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 100 100'
          // style="enable-background:new 0 0 100 100;"
          style={{ enableBackground: 'new 0 0 100 100' }}
          xmlSpace='preserve'
        >
          <style type='text/css'>
            {`.st22 {
  fill: ${colors.class_0}
}`}

            {`.st23 {
      fill:${colors.class_1}
    }`}

            {`.st24 {
      fill: ${colors.class_2};
    }`}
          </style>
          <g id='Group_16857' transform='translate(19206.24 1482.712)'>
            <g id='Group_16850' transform='translate(6 -37)'>
              <ellipse
                id='Ellipse_875'
                className='st22'
                cx='-19162.2'
                cy='-1395.7'
                rx='45.3'
                ry='45.3'
              />
              <path
                id='Path_67896'
                className='st23'
                d='M-19162.2-1350.4c-25,0-45.3-20.3-45.3-45.3c0-25,20.3-45.3,45.3-45.3
      c25,0,45.3,20.3,45.3,45.3c0,0,0,0,0,0C-19117-1370.7-19137.2-1350.5-19162.2-1350.4z M-19162.2-1438.4
      c-23.6,0-42.7,19.1-42.7,42.7s19.1,42.7,42.7,42.7c23.6,0,42.7-19.1,42.7-42.7C-19119.6-1419.3-19138.7-1438.4-19162.2-1438.4z'
              />
            </g>
            <g id='Group_16851' transform='translate(6 -37)'>
              <path
                id='Path_67895'
                className='st23'
                d='M-19127.4-1379c-5.8,9.2-17.9,12-27.1,6.2c-4.7-2.9-7.9-7.7-8.9-13.2
      c-0.1-0.4-0.4-0.7-0.8-0.8c-1.3-0.3-2.6-0.9-3.6-1.7c-0.4-0.3-1-0.3-1.3,0.1c-5.6,6.2-7.1,15.2-3.8,22.9c0.2,0.5,0,1.1-0.5,1.3
      c-0.1,0-0.1,0-0.2,0.1c-10.5,2.2-20.7-4.5-23-15c-1.7-8,1.8-16.2,8.8-20.5c5.3-3.4,11.9-4,17.7-1.7c0.4,0.2,0.8,0.1,1.1-0.2
      c0.7-0.8,1.6-1.5,2.5-2c0.4-0.2,0.6-0.7,0.5-1.1c-2.1-8.3-9-14.4-17.4-15.8c-0.5-0.1-0.9-0.6-0.8-1.1c0-0.1,0-0.1,0.1-0.2
      c3.7-10,14.9-15.1,24.9-11.4c7.7,2.9,12.7,10.2,12.6,18.4c0,5.7-2.5,11.2-6.9,15c-0.3,0.3-0.4,0.7-0.3,1.1c0.4,1,0.6,2.1,0.6,3.2
      c0,0.1,0,0.2,0,0.4c0,0.4,0.3,0.8,0.7,1c8.1,2.3,16.7-0.4,22.2-6.8c0.4-0.4,1-0.5,1.4-0.1c0.1,0,0.1,0.1,0.1,0.1
      C-19123.8-1394.7-19123.3-1385.9-19127.4-1379L-19127.4-1379z'
              >
                <animateTransform
                  attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  from='360 -19162.2 -1395.7'
                  to='0 -19162.2 -1395.7'
                  dur={speed}
                  additive='sum'
                  repeatCount='indefinite'
                />
              </path>
              <ellipse
                id='Ellipse_876'
                className='st24'
                cx='-19162.2'
                cy='-1395.7'
                rx='6.8'
                ry='6.8'
              />
            </g>
          </g>
        </svg>
      </SvgContainer>
    );
  }
  return <SVGList style={{ width, height }}>{svgList}</SVGList>;
};
export default Fan;
