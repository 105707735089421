import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';

export const MainDivSbar = styled.div`
  background-color: ${({ theme }) => theme.cardBg};
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 2rem;
  width: 35.8rem;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.hover};
  }

  &.selected {
    box-shadow: inset 0px 0px 2px 2px #000000;
  }
`;

export const IconDiv = styled.div`
  border-radius: 5px;
  padding: 1rem;
  background: #d3d5e2;
`;

export const Details = styled.div`
  font: 1.8 ${fontFamily.circularBook};
  padding-left: 1rem;
  padding-bottom: 1rem;
  /* height: 4rem; */
`;

export const Text = styled.p`
  font-size: 1.6rem;
  padding-top: 0.8rem;
  font-family: ${fontFamily.circularMedium};

  &.decription {
    color: #a7a7a7;
  }
  &.center {
    text-align: center;
  }
`;

export const SelectedWidgetDiv = styled.div`
  /* display: flex; */
  /* width: 98%; */
  /* background-color: #f4f4f8; */
  /* margin: 1rem; */
  /* border-radius: 10px; */
  /* flex: 1; */
  width: 100%;
  /* height: 95%; */
height: ${({ HDId }) => (HDId ? '95%' : '100%')}
`;

export const UrlComponentDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
color: #337AB7;
text-decoration: underline;
padding-top: 1rem;
font-size: 1.8rem;
cursor: pointer;

`;

export const UrlCompText = styled.div`
max-width: 90%;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
display: block;
margin-right: 1rem;
`;

export const BtnContainer = styled(FlexContainer)`
  position: fixed;
  /* bottom: 10px; */
  bottom: 0;

  justify-content: center;
  /* border-top: 3px solid #d8d9e2; */
  padding: 1rem 2rem;
  background: white;
`;

export const MainContainer = styled.div`
  margin-bottom: 6rem;
`;

export const AddWidgetHeader = styled.div`
padding-bottom: 1rem;
font: 1.8 ${fontFamily.circularBook};
font-size: 1.8rem;
`;
