/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  // resultType: 'kpv',
  resultDetails: {},
  KPVShortlist: [],
  singlePane: true,
  loading: false,
  detailsLoading: false,
  kpvDetails: null,
  metaDetails: {},
  metaDetailsInShortlist: {},
  activeTab: null,
  errror: null,
  infoMsg: ''
};

const setActiveTab = (state = INITIAL_STATE, action) => ({
  ...state,
  activeTab: action.payload
});

const setMetaDetails = (state = INITIAL_STATE, action) => {
  const { type, metaDetails } = action.payload;
  if (type === 'shortlist') {
    return {
      ...state,
      metaDetailsInShortlist: metaDetails
    };
  }
  return {
    ...state,
    metaDetails
  };
};

const setKPVShortlistDetails = (state = INITIAL_STATE, action) => {
  const { metaDetailsInShortlist, KPVShortlist, menuId } = action.payload;
  return {
    ...state,
    metaDetailsInShortlist,
    KPVShortlist: { ...state.KPVShortlist, [menuId]: KPVShortlist }
  };
};

const setResultDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  resultDetails: action.payload
});

const setKpvShortlist = (state = INITIAL_STATE, action) => {
  const { menuId, payload } = action.payload;
  return {
    ...state,
    // KPVShortlist: payload,
    KPVShortlist: { ...state.KPVShortlist, [menuId]: payload },
    menuId
  };
};

const getKpvDataStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  errror: null
});

const getKpvDataSuccess = (state = INITIAL_STATE, action) => {
  const { data, metaDetails, enabledPanes, xyMappings } = action.payload;
  const output = {
    ...state,
    loading: false,
    resultDetails: data,
    metaDetails,
    enabledPanes,
    xyMappings
  };
  if (metaDetails) {
    output.metaDetailsInShortlist = metaDetails;
  }
  return { ...output };
};

const getKpvDataFail = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload.error,
  resultDetails: INITIAL_STATE.resultDetails,
  metaDetails: INITIAL_STATE.metaDetails
});

const getKpvDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  detailsLoading: true
});

const getKpvDetailsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  detailsLoading: false,
  kpvDetails: action.payload
});

const getKpvDetailsFail = (state = INITIAL_STATE, action) => ({
  ...state,
  detailsLoading: false
});

const resetKpvDetails = (state = INITIAL_STATE) => ({ ...INITIAL_STATE, enabledPanes: state.enabledPanes });

const genericSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value
  };
};

const HANDLERS = {
  [Types.SET_RESULT_DETAILS]: setResultDetails,
  [Types.SET_KPV_SHORTLIST]: setKpvShortlist,
  [Types.SET_KPV_SHORTLIST_DETAILS]: setKPVShortlistDetails,
  [Types.GET_KPV_DATA_START]: getKpvDataStart,
  [Types.GET_KPV_DATA_SUCCESS]: getKpvDataSuccess,
  [Types.GET_KPV_DATA_FAIL]: getKpvDataFail,
  [Types.GET_KPV_DETAILS_START]: getKpvDetailsStart,
  [Types.GET_KPV_DETAILS_SUCCESS]: getKpvDetailsSuccess,
  [Types.GET_KPV_DETAILS_FAIL]: getKpvDetailsFail,
  [Types.RESET_KPV_DETAILS]: resetKpvDetails,
  [Types.SET_ACTIVE_TAB]: setActiveTab,
  [Types.SET_META_DETAILS]: setMetaDetails,
  [Types.GENERIC_SETTER]: genericSetter
};

export const FilterResultReducer = createReducer(INITIAL_STATE, HANDLERS);
