/* eslint-disable indent */
/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import ReactExport from 'react-export-excel';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../shared/utility/isEmpty';
import { DownloadSpan } from '../../component/Users/BulkUserUpdate/BulkUpdateDownload.style';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;
/**
 *
 * @param {string} name // name of excel file
 * @param {Function} setExportXlxs // function after downloading excel file make it false
 * @param {Object} WidgetComponentData //
 * @param {boolean} individualExport // define exporting individual widget or multiple widget
 * @param {Number} WID // individual selected widget id
 *
 */
const ExportDashboard = ({ name, setExportXlxs, WidgetComponentData, individualExport, WID }) => {
  const [data, setData] = useState([]);
  const [dataTree, setDataTree] = useState([]);

  // const { WidgetComponentData } = useSelector((state) => state.widgets);
  const exportData = useSelector((state) => state.ExportReducer.exportData);

  const columns = (data = [], type, title) => {
    setExportXlxs(false);
    switch (type) {
      case 'PRODUCTION_OEE_GAUGE':
      case 'PRODUCTION_OEE_GAUGE_DAILY':
      case 'PRODUCTION_OEE_GAUGE_LINE':
      case 'PRODUCTION_OEE_GAUGE_LINE_DAILY':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="type" value="tabTitle" />
            <ExcelColumn label="act" value={(col) => (col.linearGauge?.act ? col.linearGauge?.act : null)} />
            <ExcelColumn label="pln" value={(col) => (col.linearGauge?.pln ? col.linearGauge?.pln : null)} />
            <ExcelColumn label="cpt" value={(col) => (col.linearGauge?.cpt ? col.linearGauge?.cpt : null)} />
            <ExcelColumn label="actColor" value={(col) => (col.linearGauge?.actColor ? col.linearGauge?.actColor : null)} />
            <ExcelColumn label="cptColor" value={(col) => (col.linearGauge?.cptColor ? col.linearGauge?.cptColor : null)} />
            <ExcelColumn label="plnColor" value={(col) => (col.linearGauge?.plnColor ? col.linearGauge?.plnColor : null)} />
          </ExcelSheet>
        );
      case 'OVERALL_OEE_DONUT':
      case 'OVERALL_OEE_DONUT_DAILY':
      case 'OVERALL_OEE_DONUT_LINE':
      case 'OVERALL_OEE_DONUT_LINE_DAILY':
        const formattedData = [];
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < data[i].data.length; j += 1) {
            let check;
            check = { ...data[i].data[j] };
            check.id = data[i].id;
            check.tabTitle = data[i].tabTitle;
            formattedData.push(check);
          }
        }
        // data.map((item) => {
        //   item.data.map((innerItem) => {
        //     let check;
        //     check = { ...innerItem };
        //     check.id = item.id;
        //     check.tabTitle = item.tabTitle;
        //     formattedData.push(check);
        //   });
        // });
        return (
          <ExcelSheet
            data={formattedData || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="type" value="tabTitle" />
            <ExcelColumn label="label" value="label" />
            <ExcelColumn label="percentage" value="percentage" />
            <ExcelColumn label="target" value="target" />
            <ExcelColumn label="color" value="color" />
            <ExcelColumn label="max" value="max" />
            <ExcelColumn label="min" value="min" />
            <ExcelColumn label="unit" value="unit" />
          </ExcelSheet>
        );
      case 'EQUIPMENT_OEE_DONUT':
      case 'EQUIPMENT_OEE_DONUT_DAILY':
      case 'PRODUCT_OEE_DONUT':
      case 'PRODUCT_OEE_DONUT_DAILY':
      case 'LINE_OEE_DONUT':
      case 'LINE_OEE_DONUT_DAILY':
        const formattedDataDonut = [];
        for (let i = 0; i < data?.length; i += 1) {
          for (let j = 0; j < data[i]?.data?.length; j += 1) {
            let check;
            check = { ...data[i].data[j] };
            check.id = data[i].id;
            check.tabTitle = data[i].tabTitle;
            check.mainLabel = data[i].excelExportLabel || data[i].label;
            formattedDataDonut.push(check);
          }
        }
        return (
          formattedDataDonut.length > 0 && (
            <ExcelSheet
              data={formattedDataDonut || []}
              name={type || 'Widget'}
            >
              <ExcelColumn label="id" value="id" />
              <ExcelColumn label="type" value="tabTitle" />
              <ExcelColumn label="excelExportLabel" value="mainLabel" />
              <ExcelColumn label="label" value="label" />
              <ExcelColumn label="percentage" value="percentage" />
              <ExcelColumn label="target" value="target" />
              <ExcelColumn label="color" value="color" />
              <ExcelColumn label="max" value="max" />
              <ExcelColumn label="min" value="min" />
              <ExcelColumn label="unit" value="unit" />
            </ExcelSheet>
          )
        );
      case 'TEXT_HTML':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="data" value="html" />
          </ExcelSheet>
        );
      case 'HITRATE_OEE_BARCHART':
      case 'HITRATE_OEE_BARCHART_LINE':
      case 'HITRATE_OEE_BARCHART_DAILY':
      case 'HITRATE_OEE_BARCHART_LINE_DAILY':
        const formattedDataBar = [];
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < data[i].sbar.length; j += 1) {
            let check;
            check = { ...data[i].sbar[j] };
            check.id = data[i].id;
            check.tabTitle = data[i].tabTitle;
            formattedDataBar.push(check);
          }
        }
        // data?.map((item) => {
        //   item?.sbar.map((innerItem) => {
        //     let check;
        //     check = { ...innerItem };
        //     check.id = item.id;
        //     check.tabTitle = item.tabTitle;
        //     formattedDataBar.push(check);
        //   });
        // });
        return (
          <ExcelSheet
            data={formattedDataBar || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="type" value="tabTitle" />
            <ExcelColumn label="label" value="label" />
            <ExcelColumn label="hit" value="hit" />
            <ExcelColumn label="miss" value="miss" />
            <ExcelColumn label="hitColor" value="hitcolor" />
            <ExcelColumn label="missColor" value="misscolor" />
            <ExcelColumn label="max" value="max" />
            <ExcelColumn label="min" value="min" />
            <ExcelColumn label="unit" value="unit" />
          </ExcelSheet>
        );
      case 'OVERALL_OEE_BARCHART':
      case 'OVERALL_OEE_BARCHART_DAILY':
      case 'OVERALL_OEE_BARCHART_LINE':
      case 'OVERALL_OEE_BARCHART_LINE_DAILY':
        const formattedDataOverallBar = [];
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < data[i].sbar.length; j += 1) {
            let check;
            check = { ...data[i].sbar[j] };
            check.id = data[i].id;
            check.tabTitle = data[i].tabTitle;
            formattedDataOverallBar.push(check);
          }
        }
        // data?.map((item) => {
        //   item?.sbar.map((innerItem) => {
        //     let check;
        //     check = { ...innerItem };
        //     check.id = item.id;
        //     check.tabTitle = item.tabTitle;
        //     formattedDataOverallBar.push(check);
        //   });
        // });
        return (
          <ExcelSheet
            data={formattedDataOverallBar || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="type" value="tabTitle" />
            <ExcelColumn label="label" value="label" />
            <ExcelColumn label="value" value="value" />
            <ExcelColumn label="loss" value="loss" />
            <ExcelColumn label="target" value="target" />
            <ExcelColumn label="actvalue" value="actvalue" />
            <ExcelColumn label="missvalue" value="missvalue" />
            <ExcelColumn label="acttarget" value="acttarget" />
            <ExcelColumn label="valueColor" value="valueColor" />
            <ExcelColumn label="lossColor" value="lossColor" />
            <ExcelColumn label="max" value="max" />
            <ExcelColumn label="min" value="min" />
            <ExcelColumn label="unit" value="unit" />
          </ExcelSheet>
        );
      case 'TREND_OEE_BARCHART_DAILY':
      case 'TREND_OEE_BARCHART':
      case 'TREND_OEE_BARCHART_LINE':
      case 'TREND_OEE_BARCHART_LINE_DAILY':
        // const formattedDataTrendBar = [];
        // data?.map((item) => {
        //   item?.sbar.map((innerItem) => {
        //     let check;
        //     check = { ...innerItem };
        //     check.id = item.id;
        //     check.tabTitle = item.tabTitle;
        //     check.mainLabel = item.label;
        //     formattedDataTrendBar.push(check);
        //   });
        // });
        const formattedDataTrendBar = [];
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < data[i].sbar.length; j += 1) {
            let check;
            check = { ...data[i].sbar[j] };
            check.id = data[i].id;
            check.tabTitle = data[i].tabTitle;
            check.mainLabel = data[i].label;
            formattedDataTrendBar.push(check);
          }
        }
        return (
          <ExcelSheet
            data={formattedDataTrendBar || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="type" value="tabTitle" />
            <ExcelColumn label="label" value="label" />
            <ExcelColumn label="value" value="value" />
            <ExcelColumn label="target" value="target" />
            <ExcelColumn label="color" value="color" />
            <ExcelColumn label="max" value="max" />
            <ExcelColumn label="min" value="min" />
            <ExcelColumn label="unit" value="unit" />
          </ExcelSheet>
        );
      case 'TABLE':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="Full Name" value="UserFullName" />
            <ExcelColumn label="UserID" value="UserID" />
            <ExcelColumn label="EmployeeID" value="EmployeeID" />
            <ExcelColumn label="EmailAddress" value="EmailAddress" />
            <ExcelColumn label="Manager" value="Manager" />
            <ExcelColumn label="Role Id" value="RoleId" />
            <ExcelColumn label="Mobile Number" value="MobileNumber" />
          </ExcelSheet>
        );
      case 'STATUS_VALUE':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="Equipment Name" value="title" />
            <ExcelColumn label="state Name" value="sub_title" />
            <ExcelColumn label="color" value="background_color" />
            <ExcelColumn label="Last Updated" value="additional_data" />
          </ExcelSheet>
        );
      case 'EQUIPMENT_KPV_STATUS_VALUE':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="KPV Name" value="title" />
            <ExcelColumn label="Status" value="sub_title" />
            <ExcelColumn label="color" value="background_color" />
            <ExcelColumn label="Ts Value" value="additional_data" />
          </ExcelSheet>
        );
      case 'GAUGE_CONCENTRIC_WIDGET':
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="Labels" value="labels" />
            <ExcelColumn label="Names" value="names" />
            <ExcelColumn label="TGT" value="TGT" />
            <ExcelColumn label="Value" value="value" />
            <ExcelColumn label="ACT" value="ACT" />
          </ExcelSheet>
        );
        case 'DETAILED_STATUS_VALUE':
          const formattedDataAndon = [];
          for (let i = 0; i < data?.length; i += 1) {
            for (let j = 0; j < data[i]?.data?.length; j += 1) {
              let check;
              check = { ...data[i].data[j] };
              check.id = data[i].id;
              check.tabTitle = data[i].tabTitle;
              check.mainLabel = data[i].excelExportLabel || data[i].label;
              formattedDataAndon.push(check);
            }
          }
          return (
            formattedDataAndon.length > 0 && (
              <ExcelSheet
                data={formattedDataAndon || []}
                name={type || 'Widget'}
              >
                <ExcelColumn label="id" value="id" />
                <ExcelColumn label="type" value="tabTitle" />
                <ExcelColumn label="label" value="label" />
                <ExcelColumn label="percentage" value="percentage" />
                <ExcelColumn label="target" value="target" />
                <ExcelColumn label="color" value="color" />
              </ExcelSheet>
            )
          );
      default:
        return (
          <ExcelSheet
            data={data || []}
            name={type || 'Widget'}
          >
            <ExcelColumn label="id" value="id" />
          </ExcelSheet>
        );
    }
  };

  const downloadRef = useRef(null);
  useEffect(() => {
    downloadRef?.current?.click();
  },
    [downloadRef.current, data]);

  const getLastLevelOfTree = (values) => {
    if (values[0]?.values) {
      getLastLevelOfTree(values[0]?.values);
    } else {
      let valuesFinal = [];
      let selectionDate = '';
      if (WidgetComponentData?.formInput?.common?.interval) {
        selectionDate = WidgetComponentData?.formInput?.common?.interval[0]?.values[0]?.name || '';
      }
      const productArray = [];
      const productList = isEmpty(WidgetComponentData?.formInput?.common?.product) ? [WidgetComponentData?.formInput?.common?.product] : [];
      productList?.map((item) => item?.ProductName && productArray.push(item?.ProductName));
      const products = productArray.join();
      for (let i = 0; i < values.length; i += 1) {
        const value = { ...values[i], selectionDate, products };
        valuesFinal.push(value);
      }
      setDataTree(valuesFinal);
      return values;
    }
  };

  useEffect(() => {
    if (!isEmpty(WidgetComponentData) && WidgetComponentData?.formInput?.common?.tree) {
      const dataArr = getLastLevelOfTree(WidgetComponentData?.formInput?.common?.tree[0]?.values);
    }
  }, [WidgetComponentData]);
  // useEffect to update the exportData when there is a change in exportData.
  // if individualExport is true exporting individual widget and use WID else export all widgwt
  useEffect(() => {
    const list = [];
    if (individualExport) {
      Object.entries(exportData).map((item) => {
        Object.entries(item[1]).map((entry) => {
          if (entry[0] === WID && entry[1]?.data) {
            list.push(entry);
          }
        });
      });
    } else {
      Object.entries(exportData).map((item) => {
        Object.entries(item[1]).map((entry) => {
          if (entry[1]?.data) {
            list.push(entry);
          }
        });
      });
    }
    setData(list);
  }, [exportData]);
  if (isEmpty(dataTree)) {
    return (

      !isEmpty(data) && (
        <ExcelFile
          // eslint-disable-next-line react/button-has-type
          name="check"
          element={<DownloadSpan ref={downloadRef}>Download Data</DownloadSpan>}
          filename={name}
        >
          {
            data.map((entry) =>
              columns(entry[1]?.data, entry[1]?.type, entry[1]?.WidgetName))
          }

        </ExcelFile>
      )

    );
  }
  return (

    !isEmpty(data) && (
      <ExcelFile
        // eslint-disable-next-line react/button-has-type
        name="check"
        element={<DownloadSpan ref={downloadRef}>Download Data</DownloadSpan>}
        filename={name}
      >
        {
          data.map((entry) =>
            columns(entry[1]?.data, entry[1]?.type, entry[1]?.WidgetName))
        }
        <ExcelSheet
          data={dataTree || []}
          name="FILTERS"
        >
          <ExcelColumn label="Equipment" value="name" />
          <ExcelColumn label="selectionDate" value="selectionDate" />
          <ExcelColumn label="Products" value="products" />
        </ExcelSheet>
      </ExcelFile>
    )

  );
};

export default ExportDashboard;
