import styled from 'styled-components';

export const ActionIcon = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disable {
    cursor: not-allowed;
    opacity: 0.2;
  }
`;
