/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { WIDGET_TERMINOLOGY, WIDGET_TYPES } from '../../../../constants';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { useActions } from '../../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import StatusValueWidget from '../../../../views/Widgets/WidgetComponents/StatusValueWidget.jsx/StatusValueWidget.view';
import { Container } from '../../Common/WidgetCommons.style';
import Loader from '../../../Loader/Loader';
import {
  getFetchDetailsOfWidgetData,
  getResolvedWidgetCategory
} from '../../utils';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import {
  AddToHome,
  DeleteBlack,
  Export,
  ExportExcelIcon,
  ExportImageIcon,
  ExportPdfIcon,
  HideBorder,
  HideHeader,
  RemoveHomeMenu,
  settingsBlack
} from '../../../../assets/icons';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import {
  BtnWrapper,
  Button,
  Heading
} from '../../../Common/PopUpModal/Modal.style';
import {
  FilterHeader,
  HeaderLeft,
  HeaderRight
} from '../../../KPVCharts/PaneCard/AdditionalFilter/FilterPopUp.styles';
import { Text } from '../../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import KpvSelectionList from '../KpvSelectionComponent/KpvSelectionList.View';

const StatusValue = (props) => {
  const {
    componentData,
    id,
    isVisible,
    WIDFromProp,
    layoutPos,
    fullView,
    HDId
  } = props;
  const {
    WidgetName,
    type,
    data = [],
    category = '',
    infoText,
    formData,
    ...componentProps
  } = componentData || {};

  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const selectedKpvs = useSelector((state) => state.widgets.selectedKpvs);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const widgetsInHome = useSelector(
    (state) => state.HomeDashboardReducer.widgetsInHome
  );

  const [error, setError] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({});
  const [selectedWidgetData, setSelectedWidgetData] = useState({});
  const [showKpvConfigure, setShowKpvConfigure] = useState(false);
  const [saveOnApply, setSaveOnApply] = useState(false);
  const [kpvShortList, setKpvShortList] = useState([]);

  const WID = layoutPos?.i?.split('_')?.[0];
  const { id: WTIIdFromUrl } = useParams();
  const {
    setExportData,
    getKpvList,
    setSelectedKpvs,
    setKpvConfigured,
    updateWidgetPayload,
    setToastError,
    widgetDetailSetter
  } = useActions();

  const handleConfigureKpv = (save = false) => {
    setShowKpvConfigure(true);
    setSaveOnApply(save);
    const payload = {
      formData: {
        tree: formData?.tree[0]
      }
    };
    getKpvList(payload);
  };

  const widgetOptions =
    type === 'EQUIPMENT_KPV_STATUS_VALUE'
      ? [
          {
            id: 1,
            label: 'configure',
            value: 'configure',
            iconUrl: settingsBlack
          },
          {
            id: 6,
            label: 'Select KPV',
            value: 'configureKpv',
            handler: handleConfigureKpv,
            iconUrl: settingsBlack
          },
          { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ]
      : [
          {
            id: 1,
            label: 'configure',
            value: 'configure',
            iconUrl: settingsBlack
          },
          { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ];

  const widgetOptionsInstance = HDId
    ? type === 'EQUIPMENT_KPV_STATUS_VALUE'
      ? [
          {
            id: 1,
            label: 'Delete',
            value: 'deleteFromHome',
            iconUrl: DeleteBlack
          },
          {
            id: 6,
            label: 'Select KPV',
            value: 'configureKpv',
            handler: handleConfigureKpv,
            iconUrl: settingsBlack
          },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ]
      : [
          {
            id: 1,
            label: 'Delete',
            value: 'deleteFromHome',
            iconUrl: DeleteBlack
          },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ]
    : type === 'EQUIPMENT_KPV_STATUS_VALUE'
    ? [
        {
          id: 6,
          label: 'Select KPV',
          value: 'configureKpv',
          handler: handleConfigureKpv,
          iconUrl: settingsBlack
        },
        {
          id: 3,
          label: isHideHeader[WID + activeTab?.id]
            ? 'Show Header'
            : 'Hide Header',
          value: 'toggleHeader',
          iconUrl: HideHeader
        },
        {
          id: 4,
          label: isHideBorder[WID + activeTab?.id]
            ? 'Show Border'
            : 'Hide Border',
          value: 'toggleBorder',
          iconUrl: HideBorder
        },
        {
          id: 5,
          label: 'Export',
          value: 'export',
          iconUrl: Export,
          subMenu: [
            {
              id: 1,
              label: 'Image',
              value: 'PngTableExport',
              iconUrl: ExportImageIcon
            },
            {
              id: 2,
              label: 'PDF',
              value: 'pdfExportWithoutTab',
              iconUrl: ExportPdfIcon
            },
            {
              id: 2,
              label: 'Excel',
              value: 'excelExport',
              iconUrl: ExportExcelIcon
            }
          ]
        }
      ]
    : [
        {
          id: 3,
          label: isHideHeader[WID + activeTab?.id]
            ? 'Show Header'
            : 'Hide Header',
          value: 'toggleHeader',
          iconUrl: HideHeader
        },
        {
          id: 4,
          label: isHideBorder[WID + activeTab?.id]
            ? 'Show Border'
            : 'Hide Border',
          value: 'toggleBorder',
          iconUrl: HideBorder
        },
        {
          id: 5,
          label: 'Export',
          value: 'export',
          iconUrl: Export,
          subMenu: [
            {
              id: 1,
              label: 'Image',
              value: 'PngTableExport',
              iconUrl: ExportImageIcon
            },
            {
              id: 2,
              label: 'PDF',
              value: 'pdfExportWithoutTab',
              iconUrl: ExportPdfIcon
            },
            {
              id: 2,
              label: 'Excel',
              value: 'excelExport',
              iconUrl: ExportExcelIcon
            }
          ]
        }
      ];

  const widgetOptionsInstancePop =
    type === 'EQUIPMENT_KPV_STATUS_VALUE'
      ? [
          {
            id: 6,
            label: 'Select KPV',
            value: 'configureKpv',
            handler: handleConfigureKpv,
            iconUrl: settingsBlack
          },
          {
            id: 1,
            label: widgetsInHome.includes(WID)
              ? 'Remove From Home'
              : 'Add To Home',
            value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
            iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
          },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ]
      : [
          {
            id: 1,
            label: widgetsInHome.includes(WID)
              ? 'Remove From Home'
              : 'Add To Home',
            value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
            iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
          },
          {
            id: 3,
            label: isHideHeader[WID + activeTab?.id]
              ? 'Show Header'
              : 'Hide Header',
            value: 'toggleHeader',
            iconUrl: HideHeader
          },
          {
            id: 4,
            label: isHideBorder[WID + activeTab?.id]
              ? 'Show Border'
              : 'Hide Border',
            value: 'toggleBorder',
            iconUrl: HideBorder
          },
          {
            id: 5,
            label: 'Export',
            value: 'export',
            iconUrl: Export,
            subMenu: [
              {
                id: 1,
                label: 'Image',
                value: 'PngTableExport',
                iconUrl: ExportImageIcon
              },
              {
                id: 2,
                label: 'PDF',
                value: 'pdfExportWithoutTab',
                iconUrl: ExportPdfIcon
              },
              {
                id: 2,
                label: 'Excel',
                value: 'excelExport',
                iconUrl: ExportExcelIcon
              }
            ]
          }
        ];

  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  useEffect(() => {
    if (isEmpty(widgetData)) {
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
      // console.log('detailed widget fetch', fullURL, method);
      // TODO_W_R

      if (type === 'EQUIPMENT_KPV_STATUS_VALUE') {
        const selectedData = [];
        kpvShortList?.forEach((kpv) => {
          selectedData.push(kpv?.KPVId || kpv?.KSId);
        });
        const allKPV = !componentData?.selectedKpvItems;
        const KPVs = componentData?.selectedKpvItems || [];
        if (componentData?.selectedKpvItems) {
          setKpvConfigured({ isKpvConfigured: true });
        }
        WebService[method](fullURL, {
          widgetType: type, // WIDGET_TYPES.STATUS_VALUE,
          formInput: formData,
          allKPV,
          KPVs
        })
          .then((response) => {
            const { data, mata } = response?.data?.data;
            setWidgetData({
              equipments: data,
              meta: mata
            });
            setKpvShortList(data);
            setSelectedKpvs({ selectedKpvs: [...data] });

            setLoading(false);
            setExportData({
              TabId: WTIIdFromUrl || activeTab?.id,
              WID,
              widgetData: { data, mata, WidgetName, type }
            });
          })
          .catch((e) => {
            const err = sagaCatchBlockHandling(e);
            setError(err.error);
            setLoading(false);
            console.log('status value err', e);
          });
      } else {
        WebService[method](fullURL, {
          widgetType: type, // WIDGET_TYPES.STATUS_VALUE,
          formInput: formData
        })
          .then((response) => {
            const { data, mata } = response?.data?.data;
            setWidgetData({
              equipments: data,
              meta: mata
            });
            setLoading(false);
            setExportData({
              TabId: WTIIdFromUrl || activeTab?.id,
              WID,
              widgetData: { data, mata, WidgetName, type }
            });
          })
          .catch((e) => {
            const err = sagaCatchBlockHandling(e);
            setError(err.error);
            setLoading(false);
            console.log('status value err', e);
          });
      }
    }
  }, []);

  useEffect(() => {
    const selectedData = [];
    selectedKpvs?.forEach((kpv) => {
      selectedData.push(kpv?.KPVId || kpv?.KSId);
    });
    const selectedWidget = widgetData?.equipments?.filter((equip) =>
      selectedData?.includes(equip?.KPVId)
    );
    setSelectedWidgetData({
      ...widgetData,
      equipments: selectedWidget
    });
  }, [selectedKpvs]);

  const updateWidget = async (WID, widgetPayload) => {
    try {
      const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
      const resp = await WebService.post(fullURL, widgetPayload);
      if (resp.status >= 200 && resp.status < 300) {
        // setLayoutComponentUpdated(false);
      } else {
        throw resp;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      setToastError(error);
    }
  };

  const handleKpvSelectionSave = () => {
    setSelectedKpvs({ selectedKpvs: [...kpvShortList] });
    setKpvConfigured({ isKpvConfigured: true });
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const selectedData = [];
    kpvShortList?.forEach((kpv) => {
      selectedData.push(kpv?.KPVId || kpv?.KSId);
    });
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        selectedKpvItems: selectedData
      },
      WidgetMetaData: widgetMetaData
    };
    // if (saveOnApply) {
    updateWidgetPayload({ widgetPayload, WID });
    if (!WTIId && !WTid && !HDId) {
      updateWidget(WID, widgetPayload);
      widgetDetailSetter({ key: 'shouldRefreshPage', value: true });
    } else {
      const selectedData = [];
      kpvShortList?.forEach((kpv) => {
        selectedData.push(kpv?.KPVId || kpv?.KSId);
      });
      const allKPV = false;
      const KPVs = selectedData;
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
      WebService[method](fullURL, {
        widgetType: type, // WIDGET_TYPES.STATUS_VALUE,
        formInput: formData,
        allKPV,
        KPVs
      })
        .then((response) => {
          const { data, mata } = response?.data?.data;
          setWidgetData({
            equipments: data,
            meta: mata
          });
          setKpvShortList(data);
          setSelectedKpvs({ selectedKpvs: [...data] });

          setLoading(false);
          setExportData({
            TabId: WTIIdFromUrl || activeTab?.id,
            WID,
            widgetData: { data, mata, WidgetName, type }
          });
        })
        .catch((e) => {
          const err = sagaCatchBlockHandling(e);
          setError(err.error);
          setLoading(false);
          console.log('status value err', e);
        });
    }
  };

  if (error) {
    return <span>{`${error} in ${WidgetName || 'Status Value'}`}</span>;
  }
  return (
    <>
      <Container>
        {
          <WidgetHeaderView
            id={id}
            WID={WIDFromProp || layoutPos.i?.split('_')?.[0]} // WID
            WidgetName={WidgetName || 'Status Value'}
            widgetOptions={
              WTIId || WTid || HDId
                ? isInstancePop
                  ? widgetOptionsInstancePop
                  : widgetOptionsInstance
                : widgetOptions
            }
            componentData={componentData}
            hasZoomControl={true}
            setZoomLevel={setZoomLevel}
            zoomLevel={zoomLevel}
            fullView={fullView}
          />
        }
        {loading ? (
          <Loader />
        ) : (
          <div style={{ backgroundColor: '#FFF', height: '85%' }}>
            <StatusValueWidget
              handleConfigureKpv={handleConfigureKpv}
              selectedWidgetData={selectedWidgetData}
              widgetType={type}
              fullView={fullView}
              zoomLevelVal={zoomLevel}
              {...componentProps}
              {...widgetData}
              meta
              data={data}
              category={category}
            />
          </div>
        )}
      </Container>
      {showKpvConfigure && (
        <PopUpModal
          width='60%'
          height='90%'
          closeModalHandler={setShowKpvConfigure}
        >
          <Heading>KPV Configuration</Heading>
          <FilterHeader>
            <HeaderLeft>
              <Text>Select The Columns To be Shown</Text>
            </HeaderLeft>
            <HeaderRight>
              <BtnWrapper style={{ width: '100%', alignItems: 'flex-end' }}>
                <Button
                  type='button'
                  onClick={() => {
                    setShowKpvConfigure(false);
                    setKpvShortList([...selectedKpvs]);
                  }}
                >
                  Back
                </Button>
                <Button
                  type='submit'
                  className='dark'
                  onClick={() => {
                    setShowKpvConfigure(false);
                    handleKpvSelectionSave();
                  }}
                >
                  {WTIId || WTid || HDId ? 'Apply KPV' : 'Save KPV'}
                </Button>
              </BtnWrapper>
            </HeaderRight>
          </FilterHeader>
          <div style={{ height: '80%', overflow: 'auto', marginTop: '2rem' }}>
            <KpvSelectionList
              kpvShortList={kpvShortList}
              setKpvShortList={setKpvShortList}
            />
          </div>
        </PopUpModal>
      )}
    </>
  );
};

export default StatusValue;
