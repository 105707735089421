export const getWidgetPanelPayload = (payload) => {
  const {
    siteId = 1, // TODO
    menuId,
    userId,
    isShareable,
    widgetPanelName,
    widgetPanelLayoutData,
    widgetPanelComponentData,
    widgetPanelLayoutMetaData = {},
    ...rest
  } = payload;
  return {
    SiteId: siteId,
    SubMenuId: menuId,
    UserId: userId,
    IsShareable: isShareable ? 1 : 0,
    WidgetPanelName: widgetPanelName,
    WidgetPanelLayoutData: widgetPanelLayoutData,
    Widgets: widgetPanelComponentData,
    WidgetPanelLayoutMetaData: widgetPanelLayoutMetaData,
    ...rest
  };
};
