/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../../constants';

const Container = styled.div`
  position: absolute;
  left: ${({ left }) => `${left || 0}px`};
  top: ${({ top }) => (top ? `calc(${top}px + 3rem)` : 'calc(100% - 30px)')};
  z-index: 2;
  /* top: calc(100% - 30px); */
`;

const FormContainer = styled.div`
  width: 40rem;
  padding: 1rem;
  box-shadow: -5px -5px 15px #ffffffcb;
  border: 1px solid #b4b4b4;
  border-radius: 10px;
  background: ${({ theme }) => theme.core.secondary};
`;
const DetailsContainer = styled(FormContainer)`
  flex-direction: column;
  & > * + * {
    margin-top: 1rem;
  }
`;

const HeaderText = styled.span`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularMedium};
  color: ${({ theme }) => theme.contrast.primary};
`;

const LowOpacityText = styled(HeaderText)`
  color: ${({ theme }) => theme.contrast.lightSecondary};
`;

const DescriptionTextBox = styled.textarea`
  font-size: ${fontSize.title};
  font-family: ${fontFamily.circularMedium};
  color: ${({ theme }) => theme.contrast.primary};
  background: ${({ theme }) => theme.core.secondary};
  padding: 1rem;
  box-shadow: inset -3px -3px 5px ${({ theme }) => theme.shadowContrast},
    inset 3px 3px 10px ${({ theme }) => theme.shadow};
  border: 0px solid #b4b4b4;
  width: 100%;
  outline: none;
  margin-top: 5px;
  ::placeholder {
    color: ${({ theme }) => theme.contrast.lightSecondary};
    font-size: ${fontSize.loginInputSize};
  }
`;

const AnnotationDetailsContainer = styled.div`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => (top ? `calc(${top}px + 5rem)` : 'calc(100% - 30px)')};
  z-index: 5;
`;

export {
  Container,
  DescriptionTextBox,
  DetailsContainer,
  FormContainer,
  HeaderText,
  LowOpacityText,
  AnnotationDetailsContainer
};
