/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from '../../../../globalStyles';
import ChartComp from '../../../../views/Widgets/WidgetComponents/BarChart/BarChart';
import { Container, Text } from './BarChart.style';
import { getDataSource } from '../../../../views/Widgets/WidgetComponents/BarChart/data';
import { useActions } from '../../../../store/hooks/useActions';
import BarChartOptions from '../../../Widget/WidgetComponents/BarChart/BarChartOptions';
import Loader from '../../../Common/Loader/Loader';
import {
  TabBtnContainer,
  TabContainer
} from '../../../../views/Widgets/WidgetComponents/OEEDonut/EquipmentDonut.style';
import { TabUi } from '../../../Common/TabUi/TabUi';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { BodyContainer } from '../../../Common/TabUi/TabUi.style';

const BarChart = ({ isEdit }) => {
  const { widgetEditOptions, barChartDetails, loading } = useSelector((state) => state.upsertWidgetData);
  // const { valueSetter } = useActions();
  //   useEffect(() => {
  //     const { data, meta, colors, labelMapping, gridDetails, chartType } = getDataSource();
  //     // console.log('data', data, colors, labelMapping);
  //     // console.log('meta', meta);
  //     const pointDetails = meta.charts?.find((e) => e.type === 'Point');
  //     valueSetter({
  //       barChartDetails: {
  //         data,
  //         meta,
  //         colors,
  //         labelMapping,
  //         gridDetails,
  //         pointDetails,
  //         chartType
  //       }
  //     });
  //   }, []);
  const { getWidgetDataSuccess } = useActions();
  const { statsOf } = widgetEditOptions || {};
  const { setWidgetEditOptions } = useActions();
  // const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const { completeData } = barChartDetails || {};
  useEffect(() => {
    if (!isEmpty(completeData)) {
      const barChartDetails = getDataSource(completeData, currentTab - 1);
      getWidgetDataSuccess({ barChartDetails });
    }
  }, [currentTab]);
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };
  return (
    <Container style={{ position: 'inherit', zIndex: '9' }}>
      <FlexContainer className='ai_center' style={{ justifyContent: 'space-between', width: '94%' }}>
        <Text>Bar Chart</Text>
        <BarChartOptions
          barChartDetails={barChartDetails}
          widgetEditOptions={widgetEditOptions}
          setWidgetEditOptions={setWidgetEditOptions}
          isEdit={isEdit}
        />
      </FlexContainer>
      <>
        {loading ? (
          <Loader />
        ) : (
          <TabContainer style={{ position: 'relative', width: 'auto', height: '100%' }}>
            <TabBtnContainer>
              <TabUi currentTab={currentTab} tabs={completeData?.data} handleTabClick={handleTabClick} />
            </TabBtnContainer>
            <BodyContainer>
              <ChartComp
                statsOf={statsOf}
                barChartDetails={barChartDetails}
                isRotated={!widgetEditOptions?.orientation?.vertical}
              />
            </BodyContainer>
          </TabContainer>
        )}
      </>
    </Container>
  );
};
export default BarChart;
