/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-self-compare */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SettingBtn from './Settings/SettingBtn';
import { Button, FlexContainer } from '../../../globalStyles';
// import { WidgetCreators } from '../../../views/Widgets/store';
// import { isEmpty } from '../../../shared/utility/isEmpty';
// import DropDownModal from '../../Common/DropDownModal/DropDownModal';
import { useActions } from '../../../store/hooks/useActions';
// import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
// import { Kabab } from '../../../assets/icons/KPV';
// import CreateTemplateModal from './CreateTemplate/CreateTemplateModal';
import AddTab from './AddTab/AddTab';
import TabsList from './TabsList/TabsList';
import ButtonComp from '../../Common/Button/Button.view';
import { Creators } from '../../../views/Layout/store';
import { WidgetCreators } from '../../../views/Widgets/store';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { RightContainer } from './Header.style';
import ConfirmationModal from '../../Common/ConfirmationBox/ConfirmationBox.view';

const Header = () => {
  const tabs = useSelector((state) => state.widgets.tabList);
  const widgetPanelPayload = useSelector(
    (state) => state.widgets.widgetPanelPayload
  );
  const widgetPayload = useSelector((state) => state.widgets.widgetPayload);

  const showTemplateListSidePanel = useSelector(
    (state) => state.upsertWidgetData.showTemplateListSidePanel
  );
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const TabType = useSelector((state) => state.widgets.TabType);
  const showInstanceTablePopUp = useSelector((state) => state.upsertWidgetData.showInstanceTablePopUp);
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const history = useHistory();
  const [showChangeConfirm, setShowChangeConfirm] = useState(false);
  const { updateWidgetsLayout, setToastError, widgetDetailSetter, resetWidgetPanelPayload, resetExportData, setSelectedKpvs, setKpvConfigured } =
    useActions();
  const { setWidgetActiveTab, valueSetter, setLayoutComponentUpdated, resetTemplateInstanceDetails } =
    useActions();
  const dispatch = useDispatch();
  const [changeItem, setChangeItem] = useState();
  const refreshTab = () => {
    resetTemplateInstanceDetails();
    widgetDetailSetter({
      key: 'isTemplateComponentUpdateReq',
      value: true
    });
    widgetDetailSetter({ key: 'shouldRefreshPage', value: true });
  };

  const confirmChange = (item) => {
    refreshTab();
    setShowChangeConfirm(false);
    dispatch(
      WidgetCreators.setEditWidget({
        isEditOn: !isEditOn[activeTab?.id],
        WPID: activeTab?.id
      })
    );
    if (item.id !== activeTab.id) {
      setWidgetActiveTab(item);
    }
  };
  const tabHandler = (item) => {
    resetExportData();
    refreshTab();
    setSelectedKpvs({ selectedKpvs: [] });
    setKpvConfigured({ isKpvConfigured: false });
    if (!WTIId && isEditOn[activeTab?.id]) {
      setChangeItem(item);
      setShowChangeConfirm(true);
    } else if (item.id !== activeTab.id) {
      setWidgetActiveTab(item);
      if (!WTIId) {
        refreshTab();
      }
    }
  };

  useEffect(() => {
    widgetDetailSetter({
      key: 'isTemplateComponentUpdateReq',
      value: true
    });
    widgetDetailSetter({ key: 'shouldRefreshPage', value: true });
  }, []);

  useEffect(() => {
    valueSetter({ widgetDashboardTitle: activeTab?.label });
  }, [activeTab]);

  const showTemplates = () => {
    dispatch(WidgetCreators.widgetDetailSetter({ key: 'showSidePanel', value: false }));
    valueSetter({ showTemplateListSidePanel: !showTemplateListSidePanel });
  };

  const updateWidget = async (WID, widgetPayload) => {
    try {
      const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
      const resp = await WebService.post(fullURL, widgetPayload);
      if (resp.status >= 200 && resp.status < 300) {
        setLayoutComponentUpdated(false);
      } else {
        throw resp;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      setToastError(error);
    }
  };

  const handleToggle = async () => {
    if (!isEmpty(widgetPanelPayload)) {
      updateWidgetsLayout({
        widgetPayload: widgetPanelPayload,
        WPID: activeTab?.id
      });
    }

    if (!isEmpty(widgetPayload)) {
      Object.entries(widgetPayload).map(
        (wid) =>
          // const reqPayload = { ...wid[1], isHideHeader: hide };
          // console.log(reqPayload, 'fh');
          updateWidget(wid[0], wid[1])
        // eslint-disable-next-line function-paren-newline
      );
    }

    dispatch(Creators.setShowSideNav({ showSideNav: true }));
    dispatch(
      WidgetCreators.setEditWidget({
        isEditOn: !isEditOn[activeTab?.id],
        WPID: activeTab?.id
      })

    );
    resetWidgetPanelPayload();
  };

  useEffect(() => {
    if (!showInstanceTablePopUp && !WTIId && TabType === 'Instance_Tab') {
      setTimeout(() => {
        refreshTab();
      }, 500);
    }
  }, [showInstanceTablePopUp]);

  useEffect(() => {
    if (!isEditOn[activeTab?.id]) {
      refreshTab();
    }
  }, [isEditOn]);

  return (
    <FlexContainer
      className='jc_spacebetween ai_center'
      style={{
        borderBottom: '1px solid #DFDFDF',
        paddingTop: '5px',
        marginBottom: '1rem'
      }}
    >
      <TabsList
        tabs={tabs}
        activeTab={activeTab}
        tabHandler={tabHandler}
        isWidgetTab
      />
      <RightContainer>
        <AddTab />
        {!isEditOn[activeTab?.id] && widgetDashboardType === 'ALL' && (
        <ButtonComp dark onClick={showTemplates}>
          Templates
        </ButtonComp>
        )}
        {isEditOn[activeTab?.id] && (
        <ButtonComp dark onClick={handleToggle}>
          Save
        </ButtonComp>
        )}
      </RightContainer>

      {/* <button
        onClick={() => {
          valueSetter({ widgetDashboardTitle: activeTab?.label });
          history.push({
            pathname: '/home/create-custom-dashboard',
            state: { isCustomDashboard: true }
          });
        }}
      >
        aa
      </button> */}
      {/* <SettingBtn /> */}
      <ConfirmationModal
        showModal={showChangeConfirm}
        Header="Save Changes"
        // style={{ position: 'fixed', minWidth: '350px', left: '25%', top: '10px' }}
        handleDelete={() => { confirmChange(changeItem); }}
        closeModalHandler={() => setShowChangeConfirm(false)}
        message="Changes are not saved yet. Do you really want to Leave?"
        action="Change"
      />

    </FlexContainer>
  );
};

export default Header;
