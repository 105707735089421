/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { useActions } from '../../../../store/hooks/useActions';
import FilterContainer from '../../../Filter/FilterContainer/FilterContainer';
import {
  generateSelectedFilter,
  getBreadCrumbs
} from '../../../ResultView/Iframe/generateSelectedFilter';
import ButtonComp from '../../Button/Button.view';
import Loader from '../../Loader/Loader';
import PopUpModal from '../../PopUpModal/Modal.view';
import useFilteredSelectedItem from '../../../Filter/hooks/useFilteredSelectedItem';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../../Filter/utility/populateFilterDashboard';

const BtnWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: flex-end;
  &.bottom {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const FilterInputType = ({
  item,
  // equipments, setEquipments,
  setShowModal,
  saveHandler,
  loading,
  hasPreSelection,
  isPreSelection,
  value,
  ignoreActiveOption,
  isInstanceCreation

}) => {
  const { label, key, dataSource } = item;
  const filterData = useSelector((state) => state.filterData.filterData);
  const { setBulkFilterDetails } = useActions();

  // useEffect(() => {
  //   // setting the selections saved previously
  //   if (!isEmpty(filterData) && value?.[0]?.value) {
  //     // changing to response format
  //     const filterTree = filterData.reduce((acc, cur) => {
  //       const { keyName } = cur;
  //       return {
  //         ...acc,
  //         [keyName]: [cur]
  //       };
  //     }, {});
  //     const payload = { data: value?.[0]?.value }; // payload saved prev
  //     // console.log('value?.[0]?.value', value, payload, filterTree);
  //     const { cumTags, cumSelectedObj } = populateFilterDashboard(
  //       payload,
  //       filterTree
  //     );
  //     const payload1 = getFilterSelectionInp(cumTags, cumSelectedObj);
  //     setBulkFilterDetails({ ...payload1 }); // populating all the selection
  //   }
  // }, [filterData, value]);
  // const { params } = dataSource || {};
  // const { menuId } = params;
  // const { setTags, universalFilterSetter } = useActions();
  // const filterState = useSelector((state) => state.filterData);
  // const { cumulativeTags, filterData, tags } = filterState;

  // const check = () => {
  //   const b = getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false));
  //   console.log('getBreadCrumbs', b);
  //   setEquipments(b);
  // };
  // const removeMachine = (index) => {
  //   const treeKey = filterData[0].groupTitle;
  //   // get the pathNo
  //   const keyList = Object.keys(cumulativeTags[treeKey]);
  //   const pathNo = keyList[index];
  //   const lastIndex = keyList.length - 1;
  //   const isLastMachine = pathNo === keyList[lastIndex];

  //   // console.log(
  //   //   'pathNo',
  //   //   lastIndex,
  //   //   keyList[lastIndex],
  //   //   isLastMachine,
  //   //   index,
  //   //   pathNo,
  //   //   Object.keys(cumulativeTags[Object.keys(cumulativeTags)[0]])
  //   // );
  //   // dispatch(FilterCreators.setTags(tempTags));

  //   delete cumulativeTags[treeKey][pathNo];
  //   const cumulativeSelectedObj = filterState[`cumulative${treeKey}`];
  //   delete cumulativeSelectedObj[pathNo];
  //   check();

  //   // no paths
  //   if (isEmpty(cumulativeTags[treeKey])) {
  //     setTags({ ...tags, [treeKey]: {} });
  //     universalFilterSetter({
  //       key: treeKey,
  //       value: {}
  //     });
  //   }
  // };
  const { tags, selectedItem } = useSelector((state) => state.filterData);
  const isCompletlySelected = !useFilteredSelectedItem();
  // checking all the last elements present in the filter tree
  const lastElements = selectedItem?.map((item) => {
    try {
      const entries = Object.entries(tags?.[item]);
      const lastIndex = entries?.length;
      // console.log('lastIndex', lastIndex, entries);
      // return 'aa';
      // console.log('aa', lastIndex, tags, item, tags[item]);
      return entries?.[lastIndex - 1]?.[1]?.id;
    } catch (e) {
      console.log('filter population issue', e);
    }
    return '';
  });
  // console.log('lastElements', lastElements);
  useEffect(() => {
    // set the item if the complete path and change is selected
    if (isCompletlySelected) {
      // console.log('updating', isCompletlySelected);
      saveHandler();
    }
  }, [...lastElements, isCompletlySelected]);

  return (
    // <PopUpModal width='60%'>
    <>
      {/* <span style={{ fontSize: '2.2rem' }}>{label || 'Select Equipments'}</span> */}
      {loading ? (
        <Loader />
      ) : (
        <FilterContainer
          hasPreSelection={hasPreSelection}
          isPreSelection={isPreSelection}
          ignoreActiveOption={ignoreActiveOption}
          isInstanceCreation={isInstanceCreation}

        />
      )}
      {/* <BtnWrapper className='bottom'>
        <ButtonComp onClick={() => setShowModal(false)}>cancel</ButtonComp>
        <ButtonComp style={{ marginLeft: '2rem' }} dark onClick={saveHandler}>
          Save
        </ButtonComp>
      </BtnWrapper> */}
    </>
    // </PopUpModal>
  );
};

export default FilterInputType;
