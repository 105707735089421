import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles';

export const Container = styled.div`
  height: calc(100% - 7rem);
  background: #f4f4f8;
  width: 50%;
  ${'' /* box-shadow: -5px -5px 11px gray; */}
  border-radius: 10px;
  opacity: 1;
  padding: 2rem;
`;

export const Text = styled.p`
  font: normal normal normal 20px/28px Circular Std Bold;
  letter-spacing: 0px;
  color: #202020;
`;
