/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
import { CHART_TYPE_CATEGORY } from '../../../constants';
import { checkRangeByPos, nearestDataPointByPosition, nearestTransitionPointByPosition } from './dataPointBinarySearch';
import { getNearestTSPoint } from './getNearestPoints';

/* eslint-disable max-len */
const getNearestPointByPosition = (chart, xPos, chartTypeCategory) => {
  try {
    const e = chart?.getAllSeries().reduce((prev, s, index) => {
      const finalPoint = nearestDataPointByPosition(s.getVisiblePoints(), xPos, 0, s.getVisiblePoints().length - 1);
      if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
        const { X, isOverlay } = finalPoint.data;
        const yLabel = isOverlay ? s.name + '-Overlay' : 'Y';
        const value = finalPoint?.data?.[yLabel];
        return {
          ...prev,
          [s.name]: {
            [yLabel]: value,
            X,
            isOverlay
          }
        };
      }
      return {
        ...prev,
        [s.name]: {
          value: finalPoint?.data?.[s.name],
          arg: finalPoint?.data.TSValue,
          x: finalPoint?.x
        }
      };
    }, {});
    return e;
  } catch (e) {
    console.log('error in finding nearest point based on position', e);
  }
  return {};
};

export const getNearestTransitonPointByPosition = (chart, xPos, plotData) => {
  try {
    const rawFinalizedPoint = chart?.getAllSeries()?.reduce((prev, s, index) => {
      const finalPoint = nearestTransitionPointByPosition(
        s.getVisiblePoints(),
        xPos,
        0,
        s.getVisiblePoints().length - 1
      );
      // console.log('final result for each series', finalPoint);
      // return {
      //   ...prev,
      //   [s.name]: {
      //     value: finalPoint?.data?.[s.name],
      //     arg: finalPoint?.data.TSValue,
      //     x: finalPoint?.x
      //   }
      // };
      return {
        ...prev,
        [s.name]: finalPoint
      };
    }, {});
    let minPoint = null;
    let finalizedPoint;
    let isInRange = false;
    // get the final point from rawFinalizedPoint
    Object.keys(rawFinalizedPoint).forEach((stateName) => {
      const { isInRange: isInRangeProp, diff: res } = checkRangeByPos(rawFinalizedPoint[stateName], xPos, true);
      if (
        rawFinalizedPoint[stateName].x === xPos ||
        minPoint === null ||
        (!isInRange ? isInRangeProp || minPoint > res : isInRangeProp && minPoint > res)
      ) {
        // console.log('updating min point', stateName);
        minPoint = res;
        isInRange = isInRangeProp;
        finalizedPoint = rawFinalizedPoint[stateName];
      }
    });
    // get the points for all the equipments for the argument
    const data = getNearestTSPoint(plotData, finalizedPoint?.data?.start);
    // console.log('total final', rawFinalizedPoint, finalizedPoint, data);
    return data;
  } catch (e) {
    console.log('error in finding nearest point based on position', e);
  }
  return {};
};

export default getNearestPointByPosition;
