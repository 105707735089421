import styled from 'styled-components';
import { fontFamily } from '../../../../constants';

export const Div = styled.div`
  font-family: ${fontFamily.circularBold};
  font-size: 12px;
`;

export const Span = styled.span`
  font-family: ${fontFamily.circularBold};
  font-size: 2.2rem;
  position: absolute;
  left: 0;
`;

export const Argument = styled.div`
  font-size: 1.2rem;
  /* transform: rotate(90deg); */
  /* min-height: 80px; */
  word-break: keep-all;
  width: fit-content;
  max-width: 120px;
  /* padding-top: 55px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  rotate: ${({ rotate }) => rotate && '45deg'};
  padding-top: ${({ rotate }) => rotate && '2.5rem'};

`;

export const ArgSpan = styled.span`
  font-size: 12px;
  text-align: right;
  min-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;
  rotate: ${({ rotate }) => rotate && '45deg'};
  padding-top: ${({ rotate }) => rotate && '-1.5rem'};
  margin-left: ${({ rotate }) => (rotate ? '-42px' : '5px')};

`;
