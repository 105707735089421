/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { FlexContainer } from '../../globalStyles';
import UpsertWidgetLayout from './UpsertWidgetLayout';
import WidgetInstances from './WidgetInstances/WidgetInstances';
import { BodyContainer } from './WidgetsCommon.style';
import FormPage from './WidgetForm/FormPage';

const UpsertWidgetRoutes = (props) => (
  // const { handlePanel } = props;

  <BodyContainer className='col'>
    <Switch>
      {/*
        if any pages are required before going to actual page(selectedWidget)
        place at top
      */}
      {/* Donut widget form */}
      <Route
        path='/home/create-widget/form'
        exact={true}
        name='donut-widget'
        render={(props) => <FormPage {...props} />}
      />

      {/* actual page of widget creation */}
      <Route
        path='/home/create-widget'
        exact={true}
        name='add-donut'
        render={(props) => <UpsertWidgetLayout {...props} />}
      />
    </Switch>
  </BodyContainer>
);
export default UpsertWidgetRoutes;
