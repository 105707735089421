import styled from 'styled-components';
import { Heading as HeadingRef } from '../../../Common/PopUpModal/Modal.style';
import { device, fontFamily, fontSize, THEMES } from '../../../../constants';

export const Heading = styled(HeadingRef)`
  text-align: left;
  margin-bottom: 4rem;
`;

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  @media ${device.tablet} {
    width: 95%;
    height: 70px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FilteredDataContainer = styled.div``;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  flex: 0 1 40%;
  display: flex;
  gap: 10px;
`;

export const HeaderRight = styled.div`
  flex: 0 1 40%;
`;

export const TagContainer = styled.div``;

export const Tag = styled.div`
  position: relative;
  // TODO: incorrect theme handling
  max-width: fit-content;
  border: 1px solid
  ${({ theme, themeMode }) => (
    themeMode === THEMES.LIGHT
      ? theme.contrast.quaternary
      : theme.contrast.secondary)};
  height: 3.2rem;
  min-width: 6.5rem;
  border-radius: 17px;
  padding: 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    fill: ${({ theme, themeMode }) => (
    themeMode === THEMES.LIGHT
      ? theme.contrast.quaternary
      : theme.contrast.secondary)} !important;
  }

  @media ${device.tablet} {
    height: 25px;
    min-width: max-content;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
  }
`;

export const TagText = styled.div`
  font-size: 10px;
  color: ${({ theme, themeMode }) => (
    themeMode === THEMES.LIGHT
      ? theme.contrast.quaternary
      : theme.contrast.secondary)};
  /* ${({ theme }) => theme.filter.tagText}; */

  text-transform: capitalize;
  @media ${device.tablet} {
    font-size: 9px;
  }
`;

export const FilterContent = styled.div`
  font-size: ${fontSize.text};
  color: ${({ theme }) => theme.contrast.darkSecondary};
  font-family: ${fontFamily.circularBook};
  margin-top: 10px;
`;
