import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setUpsertPaneData: ['payload'],
  setChartType: ['payload'],
  setShowDetails: ['payload'],
  resetUpsertPaneData: ['payload'],
  setCompleteUpsertPaneData: ['payload'],
  getPanePlotData: ['payload'],
  getPanePlotDataStart: ['payload'],
  getPanePlotDataSuccess: ['payload'],
  getPanePlotDataFailure: ['payload'],
  setSeriesTypesSelections: ['payload']
});

export { Types, Creators };
