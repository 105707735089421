/* eslint-disable indent */
/* eslint-disable max-len */
import { put, select } from 'redux-saga/effects';
// import { enabledPanesLocal } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { Creators } from './action';
import { kpvData } from './kpvData';
import { arrayToJson } from '../../../shared/utility';
import { isEmpty } from '../../../shared/utility/isEmpty';

const resolveSets = (sets, obj, { UID, machineId }) =>
  Object.entries(sets).reduce((acc, entry) => {
    const [key, value] = entry;
    return {
      ...acc,
      [key]: value.map((e) => {
        const entry = obj[e] || {};
        const { MachineId, MachineName } = entry;
        return {
          ...entry,
          machineId: MachineId,
          machineName: MachineName,
          // groupName,
          id: entry[UID],
          kpvName: entry.KPV,
          macId: machineId,
          kpvId: entry[UID]
        };
      })
    };
  }, {});

const resolveSetsNew = (xyMappings, kpvObjs, { UID, machineId }) =>
  Object.entries(xyMappings).reduce((acc, entry) => {
    const [chartType, mapping] = entry;
    return {
      ...acc,
      [chartType]: !isEmpty(mapping?.xyMappings)
        ? {
            ...mapping,
            xSets: resolveSets(mapping.xSets, kpvObjs, { UID, machineId }),
            ySets: resolveSets(mapping.ySets, kpvObjs, { UID, machineId })
          }
        : { xSets: [], ySets: [], xyMappings: {} }
    };
  }, {});
export function* getKpvDataSaga(action) {
  const { resultDetails } = yield select((state) => state.filterResultData);
  const { machineId, payload, menuId, tab } = action.payload;
  try {
    yield put(Creators.getKpvDataStart());

    const url = `${apiEndPoints.getKpvData}`;

    const response = yield WebService.post(url, { menuid: menuId, tab: tab || null, data: { ...payload } });
    if (response.status >= 200 && response.status < 300) {
      const { data, meta, enabledPanes: enabledPanesOld } = response.data;
      const { enabledPanes, xyMapping } = meta || {};
      // const { data, meta, enabledPanes } = kpvData;
      // const xyMapping = {
      //   labelChart: {
      //     xSets: {
      //       x1: ['KPV_P5_1_2'],
      //       x2: ['KPV_P5_1_1']
      //     },
      //     ySets: {
      //       y1: ['KPV_P5_1_8']
      //     },
      //     xyMappings: {
      //       x1: ['y1'],
      //       x2: ['y1']
      //     }
      //   },
      //   xyChart: {
      //     xSets: {
      //       x1: ['KPV_P5_1_1']
      //     },
      //     ySets: {
      //       y1: ['KPV_P5_1_8']
      //     },
      //     xyMappings: {
      //       x1: ['y1']
      //     }
      //   }
      // };
      // const xyMappings = {
      //   labelChart: null,
      //   xyChart: null
      // };
      const kpvObjs = arrayToJson(data, meta.UID);
      const newXYmapping = resolveSetsNew(xyMapping || {}, kpvObjs, { UID: meta.UID, machineId });
      yield put(
        Creators.getKpvDataSuccess({
          data: {
            ...resultDetails,
            [machineId]: data
          },
          metaDetails: meta,
          enabledPanes: enabledPanes || enabledPanesOld,
          xyMappings: newXYmapping
          // : enabledPanesLocal
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    let payload;
    console.log('error', error);
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      payload = {
        error: 'No connection try again Later.'
      };
    }

    yield put(Creators.getKpvDataFail(payload));
  }
}

export function* getKpvDetailsSaga(action) {
  const { data, machineId } = action.payload;
  try {
    yield put(Creators.getKpvDetailsStart());
    const url = `${apiEndPoints.getKpvDetails}?machineId=${machineId}&kpv=${JSON.stringify(data)}`;

    const response = yield WebService.get(url);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getKpvDetailsSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      payload = {
        error: 'No connection try again Later.'
      };
    }

    yield put(Creators.getKpvDetailsFail(payload));
  }
}
