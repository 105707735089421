import { createActions } from 'reduxsauce';

export const { Creators, Types } = createActions({
  userReducerValueSetter: ['payload'],
  updateUsersData: ['payload'],
  resetUserDataFlags: ['payload'],
  getUserTableData: ['payload'],
  setUserTableData: ['payload'],
  updateUserTableData: ['payload'],
  setSearchQueries: ['payload'],
  latestSavedValue: ['payload'],
  getUsersList: ['payload'],
  getUsersListStart: ['payload'],
  getUsersListSuccess: ['payload'],
  getUsersListFailure: ['payload'],

  getBulkUsersUpdateList: ['payload'],
  getBulkUsersUpdateListStart: ['payload'],
  getBulkUsersUpdateListSuccess: ['payload'],
  getBulkUsersUpdateListFailure: ['payload'],
  updateBulkUsers: ['payload'],
  updateBulkUsersStart: ['payload'],
  updateBulkUsersSuccess: ['payload'],
  updateBulkUsersFailure: ['payload'],
  resetUpdationStatus: ['payload'],
  resetUpdationData: ['payload']
});
