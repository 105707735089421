/* eslint-disable max-len */
import styled from 'styled-components';
// import { fontFamily, THEMES, device, minDevice } from '../../../../constants';
// import { FlexContainer } from '../../../../globalStyles';

export const TabContainer = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 40rem;
  &.chart {
    height: 90%;
    overflow-y: scroll;
  }
  &.padding {
    padding: 1rem;
  }
  &.gauge {
    height: 100%;
  }
`;

export const TabButton = styled.button`
  border: none;
  padding: 1rem;
  color: black;
  cursor: pointer;
  width: 100%;
  transition: all 0.5s ease-out;
  font-size: 1.5rem;
  background-color: ${({ theme }) => theme.core.darkSecondary};
  box-shadow: inset 0px 4px 6px -1px rgba(0, 0, 0, 0.16);

  border-left: 1px solid #cbcbcf;
  :disabled {
    border-radius: 10px 10px 0px 0px;
    background: white;
    color: black;
    box-shadow: none;
    border: none;
  }
`;

export const TabBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  /* z-index: 100; */
`;

export const BodyContainer = styled.div`
  max-width: calc(100%);
  height: calc(100% - 4rem);
  overflow: auto;
  padding: 1rem;
`;
