/* eslint-disable max-len */
import styled from 'styled-components';
// import { fontSize, device, fontFamily } from '../../../constants';

export const SettingButton = styled.button`
  display: flex;
  width: 14rem;
  height: 5rem;
  border-radius: 1rem;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled, theme }) => (disabled ? theme.core.secondary : theme.specificDetails.primary)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  position: absolute;
  right: 3rem;
`;

export const Button = styled.button`
  width: 20rem;
  height: 5rem;
  border-radius: 1rem;
  border: none;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.contrast.tertiary};
  font-size: large;
  background-color: ${({ disabled, theme }) => (disabled ? theme.core.secondary : theme.specificDetails.primary)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &.saveBtn {
    background-color: white;
    color: black;
  }
`;

export const RightContainer = styled.div`
display: flex;
align-items: center;
gap: 2rem;
  @media print {
      display: none;
    }
`;

// export const Input = styled.input`
//   background-color: ${({ theme }) => theme.core.secondary};
//   box-shadow: ${({ theme }) => theme.shadowin};
//   border-radius: 1rem;
//   border: 0rem;
//   width: ${({ width }) => width || '100%'};
//   height: min(45px, 100%);
//   min-height: 45px;
//   padding-left: 2.1rem;
//   padding-right: 3.7rem;
//   color: ${({ theme }) => theme.contrast.secondary};
//   font-size: ${fontSize.text};

//   &:focus {
//     outline: none;
//   }

//   &:disabled {
//     opacity: 0.4;
//   }

//   ::placeholder {
//     color: ${({ theme }) => theme.placeHolderText};
//     font-size: ${fontSize.loginInputSize};
//   }
// `;
