/* eslint-disable max-len */
import React from 'react';
import { FlexContainer } from '../../../globalStyles';
import { ReactComponent as Arrow } from '../../../assets/icons/layout/dropdownarrow.svg';
import { InputCotainer } from '../Input/Input';
import { DropBtn } from './DropDownModal.style';

const InputDropdown = (props) => {
  const {
    keyName, // used to manage dropdown visibilty
    value,
    handleDropdownVisibility,
    width,
    placeholder,
    isReadOnly
  } = props;
  return (
    <div>
      <FlexContainer
        className='can-disable-input'
        style={{ position: 'relative' }}
        onClick={() => handleDropdownVisibility(keyName)}
      >
        <InputCotainer
          readOnly={isReadOnly}
          width={width}
          placeholder={placeholder}
          value={value}
        />
        <DropBtn
          className='ai_center jc_center'
          onClick={(e) => {
            e.stopPropagation();
            handleDropdownVisibility((prev) => !prev);
          }}
        >
          <Arrow />
        </DropBtn>
      </FlexContainer>
    </div>
  );
};

export default InputDropdown;
