/* eslint-disable max-len */
import * as htmlToImage from 'html-to-image';
import { WIDGET_TYPE_CATEGORY_MAPPING } from '../../../constants';
import { apiEndPoints, primaryRootConfig } from '../../../services/axios/endPoints';
import { isEmpty } from '../../../shared/utility/isEmpty';

export const getResolvedWidgetCategory = (category, type) => {
  // if defined just retunirng this
  if (category) return category;
  // else get the widget category based on type known
  // console.log('category not defined');
  return Object.entries(WIDGET_TYPE_CATEGORY_MAPPING).find((e) => {
    const [categoryDefined, typesMapped] = e;
    // console.log('check====', typesMapped, type, typesMapped.includes(type));
    return typesMapped.includes(type);
  })?.[0]; // key, i.e, categoryDefined
};

export const getFetchDetailsOfWidgetData = (fetchUrl, isActual = false) => {
  if (fetchUrl) {
    // method 1 approch
    // const { method: definedMethod, baseUrl, absoluteUrl } = fetchUrl;
    // // console.log('fetch widget detail from', definedMethod, baseUrl, absoluteUrl);
    // return {
    //   fullURL: baseUrl ? `${baseUrl}${absoluteUrl}` : `${primaryRootConfig}${absoluteUrl}`,
    //   method: definedMethod
    // };

    // method 2 approch
    const { method: definedMethod, UrlType, Url } = fetchUrl;
    // console.log('fetch widget detail from', definedMethod, baseUrl, absoluteUrl);
    return {
      fullURL: UrlType === 'absolute' ? Url : `${primaryRootConfig}${Url}`,
      method: definedMethod
    };
  }
  // console.log('Using default details to fetch widget Data');
  if (isActual) {
    return {
      fullURL: apiEndPoints.getWidgetDataActual,
      method: 'post'
    };
  }
  return {
    fullURL: apiEndPoints.getWidgetData,
    method: 'post'
  };
};

export const getColorFromDonutMeta = (value, donutMetaColors) => {
  const colorObj = value >= 0 ? donutMetaColors.higher : donutMetaColors.lesser;
  const colorObjArr = !isEmpty(colorObj) ? Object.keys(colorObj) : [];
  let findValueIndex = 0;
  const absoluteVal = Math.abs(value);
  for (let i = 0; i < colorObjArr.length; i += 1) {
    // if at last return last index
    if (i + 1 === colorObjArr.length) {
      // console.log('last index', i);
      findValueIndex = i;
      break;
    }
    // absoluteVal belongs to [colorObjArr[i], colorObjArr[i + 1])
    const res = colorObjArr[i] <= absoluteVal && absoluteVal < colorObjArr[i + 1];
    if (res) {
      // console.log('in middele', i);
      findValueIndex = i;
      break;
    }
  }
  // console.log('fetching color', value, absoluteVal, colorObj, findValueIndex, colorObj[colorObjArr[findValueIndex]]);
  return colorObj?.[colorObjArr?.[findValueIndex]];
};

export const resolveSetWidgetTypePayload = (widgetPayload) => {
  const { label, value, category } = widgetPayload;
  return {
    widgetType: value,
    widgetLabel: label,
    widgetCategory: category
  };
};

export const getWidgetCategory = (value) =>
  Object.entries(WIDGET_TYPE_CATEGORY_MAPPING).find((e) => {
    const [category, types] = e;
    if (types.includes(value)) {
      return true;
    }
    return false;
  })?.[0];
// Export widget as in png formate
export const handlePngExport = (WidgetName, WidgetPanelName, WID, setShowOptions) => {
  setShowOptions(false);
  const divToExport = document.getElementById(`printableDiv${WID}`);
  divToExport.style.height = 'auto';
  setTimeout(() => {
    htmlToImage.toPng(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = WidgetPanelName.concat(`_${WidgetName}`);
      link.href = dataUrl;
      link.click();
      divToExport.style.height = '100%';
      // Remove the link from the document
    })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }, 100);
};
//
export const PngTableExport = (WidgetName, WidgetPanelName, WID, setShowOptions) => {
  setShowOptions(false);
  const divToExport = document.getElementById(`printableDiv${WID}`);
  divToExport.style.height = 'auto';
  divToExport.style.width = 'fit-content';
  setTimeout(() => {
    htmlToImage.toPng(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = WidgetPanelName.concat(`_${WidgetName}`);
      link.href = dataUrl;
      link.click();
      divToExport.style.height = '100%';
      // Remove the link from the document
      divToExport.style.width = '100%';
      document.body.removeChild(link);
    })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }, 100);
};
// export widget in pdf formate
export const handlePdfExport = (WidgetName = '', WidgetPanelName = '', WID, setShowOptions) => {
  const originalTitle = document.title;
  document.title = WidgetPanelName?.concat(`_${WidgetName}`);
  setShowOptions(false);
  const divToExport = document.getElementById(`printableDiv${WID}`);
  divToExport.style.height = 'auto';
  const widgetTabs = document.getElementById(`tab${WID}`);
  const urls = [];
  const headerName = [];
  const printTabContent = (tabIndex) => {
    if (tabIndex < widgetTabs.children.length) {
      const button = widgetTabs.children[tabIndex];
      button.click();
      headerName.push(widgetTabs.children[tabIndex]?.innerHTML);

      setTimeout(() => {
        htmlToImage.toSvg(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
          const iframe = document.createElement('iframe');
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.src = 'about:blank';
          iframe.onload = function () {
            const domdoc = iframe.contentDocument || iframe.contentWindow.document;
            let windowContent = '<!DOCTYPE html>';
            windowContent += '<html>';
            windowContent += `<head><style>@media print {
              html, body {
                height: 100vh;
                margin: 0 !important;
                padding: 0 !important;
              }
            } @page { size: ${divToExport.scrollWidth}px ${divToExport.scrollHeight + 600}px; }</style> <title>${WidgetPanelName}</title></head>`;
            windowContent += `<body style='width: ${divToExport.scrollWidth}px; height: ${divToExport.scrollHeight}px;'>`;
            // windowContent += `<img src="${dataUrl}"style="page-break-after: auto;">`;
            windowContent += '</body>';
            windowContent += '</html>';
            urls.push(dataUrl);
            // domdoc.body.innerHTML = windowContent;
            if (urls.length === widgetTabs.children.length) {
              for (let index = 0; index < urls.length; index += 1) {
                const dataUrl = urls[index];
                windowContent += `<p>${headerName[index]}</p>`;
                windowContent += `<img src="${dataUrl}"style="page-break-after: auto;">`;
              }
            }
            domdoc.body.innerHTML = windowContent;
            setTimeout(() => {
              // on last tab print accumalated data
              if (tabIndex === widgetTabs.children.length - 1) {
                iframe.focus();
                iframe.contentWindow.print();
                iframe.remove();
              }
              printTabContent(tabIndex + 1);
            }, 1);
          };
        })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }, 100);
    } else {
      // All tabs processed, reset the original title
      document.title = originalTitle;
      divToExport.style.height = '100%';
    }
  };

  // Start printing from the first tab
  printTabContent(0);
};

// export widget in pef formate if tab dosn't exist
export const handlePdfExportWithoutpdf = (WidgetName = ' ', WidgetPanelName = '', WID, setShowOptions) => {
  const originalTitle = document.title;
  document.title = WidgetPanelName?.concat(`_${WidgetName}`);
  setShowOptions(false);
  const divToExport = document.getElementById(`printableDiv${WID}`);
  divToExport.style.height = 'auto';
  divToExport.style.width = 'fit-content';
  setTimeout(() => {
    htmlToImage.toSvg(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
      const iframe = document.createElement('iframe'); // load content in an iframe to print later
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      iframe.src = 'about:blank';

      iframe.onload = function () {
        const domdoc = iframe.contentDocument || iframe.contentWindow.document;
        let windowContent = '<!DOCTYPE html>';
        windowContent += '<html>';
        windowContent += `<head><style>@media print {
          html, body {
            height: 100vh;
            margin: 0 !important;
            padding: 0 !important;
          }
        } @page { size: ${divToExport.scrollWidth}px ${divToExport.scrollHeight + 600}px; }</style> <title>${WidgetPanelName}</title></head>`;
        windowContent += `<body style='width: ${divToExport.scrollWidth}px; height: ${divToExport.scrollHeight}px;'>`;
        windowContent += `<img src="${dataUrl}" style="page-break-after: auto; backgound-color :red">`;
        windowContent += '</body>';
        windowContent += '</html>';

        domdoc.body.innerHTML = windowContent;
        setTimeout(() => {
          iframe.focus();
          iframe.contentWindow.print();
          iframe.remove();
          document.title = originalTitle;
          divToExport.style.width = '100%';
        }, 1);
      };
      // divToExport.style.width = '100%';
    }).then(() => {
      divToExport.style.height = '100%';
    })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }, 100);
};
