/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { CHART_TYPE_CATEGORY } from '../../../constants';
import { Item, ItemSubMenu, LabelWrapper } from '../../KPVCharts/EditOptions/EditOptions.style';
import { CheckBox } from '../UpsertPane.style';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { Span } from '../../../globalStyles';

const ShowOverlays = ({ chartTypeCategory, meta, showOverlays, handleSelect, isLimitsEnabled }) => {
  if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
    // console.log('paneData meta', paneData.rawPlotData);
    const check =
      !isEmpty(meta) &&
      Object.entries(meta).reduce((acc, kpvData) => {
        const [key1, value] = kpvData;
        // console.log('spc', key1, value, value.menuOverlays?.[0]);
        return {
          menuOverlays: {
            ...(acc.menuOverlays || {}),
            ...Object.entries(value.menuOverlays)?.reduce((prev, e) => {
              const [key, value] = e;
              return { ...prev, [key]: { data: value, kpv: key1 } };
            }, {})
          }
        };
      }, {});
    return (
      <>
        {check && !isEmpty(check.menuOverlays) ? (
          Object.entries(check.menuOverlays).map((e) => {
            const [key, value] = e;
            const isDefaultOverlay = Object.values(meta)[0]?.default?.[key];
            const dataNodes = Object.values(meta)[0]?.data;
            const isDataPresentForOverlay = value?.data?.some((nodeItem) => !isEmpty(dataNodes[nodeItem.node]));
            return (
              <Item
                className={`subItem ${isDefaultOverlay || !isDataPresentForOverlay ? 'disable' : ''}`}
                onClick={() => handleSelect({ key, value })}
              >
                <LabelWrapper>
                  <CheckBox className={isDefaultOverlay || showOverlays?.[key] ? 'active' : ''}>
                    <CheckMark className='' />
                  </CheckBox>
                  <span>{key}</span>
                </LabelWrapper>
              </Item>
            );
          })
        ) : (
          <Span className='padding'>No overlays Available</Span>
        )}
      </>
    );
  }

  return (
    <Item className='subItem' onClick={() => isLimitsEnabled && handleSelect({})}>
      <LabelWrapper className={isLimitsEnabled ? '' : 'disable'}>
        <CheckBox className={showOverlays?.limits === false ? '' : 'active'}>
          <CheckMark className='' />
        </CheckBox>
        <span>Limits</span>
      </LabelWrapper>
    </Item>
  );
};

export default ShowOverlays;
