/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable function-paren-newline */
import moment from 'moment';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { setAnnotationsDetails } from '../PaneCard/Annotations/utils';
// import { kpvDataWithShortLabels, transitionChartDataShortLabel } from './kpvChartsWithShortlabels';

// handles if end value is not difined at each state
export const getExtremeStateValue = (orgVal, substituteValueEle) => {
  if (orgVal) {
    return new Date(
      moment(orgVal)
      // .second(0).millisecond(0)
    );
  }
  if (substituteValueEle) {
    // just 1 sec before
    const d = new Date(substituteValueEle?.Start);
    d.setSeconds(d.getSeconds() - 1);
    return d;
  }
  return null;
};

export const getMultiSeriesSourceData = (rawPlotData) => {
  let plotData = [];
  const metaData = {};
  let currLength = 0;
  if (rawPlotData) {
    plotData = Object.keys(rawPlotData)
      .map((kpv) => {
        const max = {};
        const min = {};
        const { mapping, xlabels: xlabelRaw, ylabels: ylabelRaw } = rawPlotData[kpv].meta;

        const xlabels = [xlabelRaw];
        const ylabels = [ylabelRaw];
        const xlabelShorthands = xlabels.map((e) => mapping[e]);
        const ylabelShorthands = ylabels.map((e) => mapping[e]);
        let data;
        try {
          data = rawPlotData[kpv].data.map((e) => {
            const eDup = { ...e };
            const yvalues = ylabelShorthands.reduce((acc, ylabelShorthand, index) => {
              const ylabel = ylabels[index];
              const yvalue = e[ylabelShorthand] || e[ylabelShorthand] === null ? e[ylabelShorthand] : e?.[ylabel];
              const key = ylabel;
              min[key] = min[key] === null || min[key] > yvalue ? yvalue : min[key];
              max[key] = min[key] === null || max[key] < yvalue ? yvalue : max[key];

              return {
                ...acc,
                [ylabel]: yvalue,
                [`${kpv}`]: yvalue
                // [`${kpv}-${key}`]: yvalue
              };
            }, {});

            const xvalues = xlabelShorthands.reduce((acc, xlabelShorthand, index) => {
              const xlabel = xlabels[index];
              return {
                ...acc,
                [xlabelShorthand]: e[xlabelShorthand] || e?.[xlabel]
              };
            }, {});
            // remove shorthand key-values
            ylabelShorthands.forEach((ylabelShorthand) => {
              delete eDup[ylabelShorthand];
            });
            xlabelShorthands.forEach((xlabelShorthand) => {
              delete eDup[xlabelShorthand];
            });

            return {
              ...eDup,
              ...yvalues,
              ...xvalues
            };
          });
          // console.log('min', min, max);
          metaData[kpv] = {
            ...rawPlotData[kpv].meta,
            startIndex: currLength,
            endIndex: currLength + rawPlotData[kpv].data?.length - 1,
            minValue: min,
            maxValue: max,
            isDataPresent: rawPlotData[kpv].data?.length > 1 && true
          };
          currLength += rawPlotData[kpv].data?.length;
        } catch (e) {
          console.log('eeee', e);
        }
        return data;
      })
      // eslint-disable-next-line arrow-body-style
      .reduce((prev, cur) => {
        // console.log('ccc', cur);
        return [...prev, ...cur];
      }, []);
  }
  return { plotData, metaData };
};

// for xy chart: adding x to the items of data
const getFormatedRawPlotData = (rawPlotData, xKPVType, argField) => {
  try {
    const KPVID = xKPVType?.[0]?.kpvId;
    // resolve shorthand key
    const { xlabels, mapping } = rawPlotData[KPVID]?.meta?.[0] || {};
    const labelFullKey = xlabels?.[0];
    const label = mapping?.[labelFullKey] || labelFullKey;
    const xValues = rawPlotData[KPVID].data.reduce(
      (acc, item) => ({
        ...acc,
        [item?.[label]]: item
      }),
      {}
    );
    return Object.entries(rawPlotData).reduce((acc, details) => {
      const [kpv, kpvPlotData] = details;
      const formatedKpvPlotData = { ...kpvPlotData };
      // console.log('formatedKpvPlotData', formatedKpvPlotData, formatedKpvPlotData.data);
      formatedKpvPlotData.data = formatedKpvPlotData.data.map((e) => {
        // resolve shorthand key
        const { xlabels: xlabelsRaw, ylabels: ylabelsRaw, mapping } = kpvPlotData?.meta || {};
        const xlabels = [xlabelsRaw];
        const ylabels = [ylabelsRaw];
        const labelFullKey = xlabels?.[0];
        const label = mapping?.[labelFullKey] || labelFullKey;
        const valueFullKey = ylabels?.[0];
        const value = mapping?.[valueFullKey] || valueFullKey;
        return {
          ...e,
          [argField]: xValues[e?.[label]]?.[value]
        };
      });

      return {
        ...acc,
        [kpv]: formatedKpvPlotData
      };
    }, {});
  } catch (e) {
    console.log('Isssue at getFormatedRawPlotData', e);
  }
  return rawPlotData;
};

export const getPlotData = (rawPlotData, chartType, category, others) => {
  const { xKPVType } = others || {};
  let plotData = [];
  let metaData = {};
  // console.log('rawPlotData111', rawPlotData);
  let currLength = 0;
  // console.log('chartType', chartType, category, category || chartType);
  switch (category || chartType) {
    case CHART_TYPE_CATEGORY.DATA_CHART:
      if (rawPlotData) {
        plotData = Object.keys(rawPlotData)
          .map((kpv) => {
            let max = null;
            let min = null;
            const { mapping } = rawPlotData[kpv].meta;
            const { SensorData: sensorDataShorthand, TSValue: tsValueShorthand } = mapping || {};
            const data = rawPlotData[kpv].data.map((e) => {
              const eDup = { ...e };
              let sensorData = e.SensorData;
              if (e[sensorDataShorthand] || e[sensorDataShorthand] === null) {
                sensorData = e[sensorDataShorthand];
              }
              const tsValue = e[tsValueShorthand] || e.TSValue;
              min = min === null || min > sensorData ? sensorData : min;
              max = min === null || max < sensorData ? sensorData : max;

              // remove shorthand key-values
              delete eDup[sensorDataShorthand];
              delete eDup[tsValueShorthand];

              return {
                ...eDup,
                SensorData: sensorData,
                [`${kpv}-SensorData`]: sensorData,
                TSValue: new Date(
                  moment(tsValue)
                  // .second(0).millisecond(0)
                )
              };
            });
            // console.log('min', min, max);
            metaData[kpv] = {
              ...rawPlotData[kpv].meta,
              startIndex: currLength,
              endIndex: currLength + rawPlotData[kpv].data?.length - 1,
              minValue: min,
              maxValue: max,
              isDataPresent: rawPlotData[kpv].data?.length > 1 && true
            };
            currLength += rawPlotData[kpv].data?.length;
            return data;
          })
          // eslint-disable-next-line arrow-body-style
          .reduce((prev, cur) => {
            // console.log('ccc', cur);
            return [...prev, ...cur];
          }, []);
      }
      break;
    case CHART_TYPE_CATEGORY.LABEL:
    case CHART_TYPE_CATEGORY.XY_CHART:
      // eslint-disable-next-line no-case-declarations
      const details = getMultiSeriesSourceData(rawPlotData);
      plotData = details.plotData;
      metaData = details.metaData;
      break;
    // case CHART_TYPE_CATEGORY.XY_CHART:
    //   // eslint-disable-next-line no-case-declarations
    //   const details1 = getMultiSeriesSourceData(getFormatedRawPlotData(rawPlotData, xKPVType, 'x'));
    //   plotData = details1.plotData;
    //   metaData = details1.metaData;
    //   break;
    case CHART_TYPE_CATEGORY.TRANSITION_CHART:
      if (rawPlotData) {
        plotData = Object.keys(rawPlotData)
          // eslint-disable-next-line arrow-body-style
          .map((state) => {
            const { mapping } = rawPlotData[state].meta;
            const { Start: StartShort, end: endShort, label: labelShort } = mapping || {};
            // console.log('state', state, rawPlotData[state].data);
            metaData[state] = {
              ...rawPlotData[state].meta,
              isDataPresent: rawPlotData[state].data?.length > 0 && true
            };

            return rawPlotData[state].data.map((e, index) => {
              const eDup = { ...e };
              const label = e[labelShort] || e.label;
              const Start = e[StartShort] || e.Start;
              const end = e[endShort] || e.end;

              // delete shorthand key-values
              delete eDup[labelShort];
              delete eDup[StartShort];
              delete eDup[endShort];

              return {
                ...eDup,
                label,
                state: rawPlotData[state].meta.fields.displayname,
                start: Start
                  ? new Date(
                      moment(Start)
                      // .second(0).millisecond(0)
                    )
                  : null,
                end: getExtremeStateValue(end, rawPlotData[state].data?.[index + 1])
              };
            });
          })
          .reduce((prev, cur) => [...prev, ...cur], []);
      }
      break;
    case CHART_TYPE_CATEGORY.SPC:
      if (rawPlotData) {
        plotData = Object.keys(rawPlotData)
          .map((kpv) => {
            let max = null;
            let min = null;
            const overlayDomain = {};
            const { menuOverlays, fields } = rawPlotData[kpv].meta;
            const { xlabels } = rawPlotData[kpv];
            // resolve xlabels
            const {
              SensorData: sensorDataShorthand,
              TSValue: tsValueShorthand,
              PointLabel: pointLabelMapping
            } = fields?.mapping || {};

            // converting short labels to actual labels
            const xlabel =
              (!isEmpty(xlabels) &&
                Object.entries(xlabels).reduce((acc, e) => {
                  const [key, value] = e;
                  return { ...acc, [key]: { PointLabel: value[pointLabelMapping], TSValue: value[tsValueShorthand] } };
                }, {})) ||
              {};
            // mapping || {};
            let sortedNodesData = {};
            let data;
            try {
              // sort the node data and resolve xlabel mapping
              sortedNodesData = Object.entries(rawPlotData[kpv].data).reduce((prev, node) => {
                const [nodeLabel, nodeData] = node;
                if (nodeData?.length > 0 && !nodeData[0]) {
                  return { ...prev, [nodeLabel]: [] };
                }
                const resolvedNodeData = nodeData
                  .map((e) => {
                    // const tsValue = xlabels[e.X]?.[tsValueShorthand] || xlabels[e.X]?.TSValue;
                    const tsValue = xlabel[e.X]?.TSValue;
                    // return { Y: e.Y, X: new Date(tsValue), xPointer: e.X };
                    return { Y: e.Y, X: new Date(tsValue), XLabel: tsValue, xPointer: e.X };
                  }).sort((a, b) => (new Date(a.X) > new Date(b.X) ? 1 : -1));
                return {
                  ...prev,
                  [nodeLabel]: resolvedNodeData,
                };
              }, {});
              data = rawPlotData[kpv].meta.fields.default.reduce((prev, defaultOverlay) => {
                const currentData = menuOverlays[0][defaultOverlay].reduce((innerPrev, overlayEle) => {
                  const { node, color, linetype, Text } = overlayEle;
                  overlayDomain[node] = {
                    startIndex: currLength,
                    endIndex: currLength + sortedNodesData[node].length - 1
                  };
                  currLength += sortedNodesData[node].length;
                  const partData = sortedNodesData[node].map((e) => {
                    const eDup = { ...e };
                    let sensorData = e.Y;
                    if (e[sensorDataShorthand] || e[sensorDataShorthand] === null) {
                      sensorData = e[sensorDataShorthand];
                    }
                    // const tsValue = xlabels[e.X]?.[tsValueShorthand] || xlabels[e.X].TSValue;
                    min = min === null || min > sensorData ? sensorData : min;
                    max = min === null || max < sensorData ? sensorData : max;

                    // remove shorthand key-values
                    delete eDup[sensorDataShorthand];
                    delete eDup[tsValueShorthand];

                    return {
                      ...eDup,
                      Y: parseFloat(sensorData),
                      [`${node}-Y`]: parseFloat(sensorData),
                      X: e.X,
                      xPointer: e.xPointer
                      // X: new Date(
                      //   moment(tsValue)
                      //   // .second(0).millisecond(0)
                      // )
                    };
                  });
                  return [...innerPrev, ...partData];
                }, []);
                return [...prev, ...currentData];
              }, []);
            } catch (e) {
              console.log('here1', e);
            }

            // console.log('min', min, max);
            try {
              metaData[kpv] = {
                ...rawPlotData[kpv].meta,
                xlabel,
                menuOverlays: rawPlotData[kpv].meta.menuOverlays?.[0] || [],
                alarmOverlays: rawPlotData[kpv].meta.alarmOverlays?.[0] || [],
                annotations: rawPlotData[kpv].meta.annotations,
                data: sortedNodesData,
                // startIndex: currLength,
                // endIndex: currLength + rawPlotData[kpv].data?.length - 1,
                overlayDomain,
                minValue: min,
                maxValue: max,
                isDataPresent: sortedNodesData?.length > 1 && true,
                default: rawPlotData[kpv].meta.fields.default.reduce(
                  (prev, e) => ({ ...prev, [e]: menuOverlays[0][e] }),
                  {}
                )
              };
            } catch (e) {
              console.log('here2', e);
            }
            return data;
          })
          // eslint-disable-next-line arrow-body-style
          .reduce((prev, cur) => {
            // console.log('ccc', cur);
            return [...prev, ...cur];
          }, []);
      }
      break;
    default:
      break;
  }

  return { data: plotData, meta: metaData };
};

export const setPlotData = (data) => {
  let addPaneDetails = [];
  // console.log('data---', data);
  data.forEach((paneDl) => {
    const { chartType, chartTitle, reqPayload } = paneDl;
    const { singlePane, category, kpvDetails, chartTypeCategory } = reqPayload;
    let rawPlotData;
    if (singlePane || singlePane === undefined) {
      rawPlotData = paneDl[category.toLowerCase()][0];

      // test shorthand keys
      // transition charts
      // rawPlotData = transitionChartDataShortLabel;
      // datachart charts
      // rawPlotData = kpvDataWithShortLabels;

      // console.log('saas', getPlotData(rawPlotData, chartType));
      addPaneDetails = [...addPaneDetails, { rawPlotData: getPlotData(rawPlotData, chartType, chartTypeCategory) }];
      // console.log('single pane', rawPlotData);
    }
    // else {
    // will not get multi pane,
    // this is handled in payload format creation
    //   // eslint-disable-next-line arrow-body-style
    //   rawPlotData = Object.keys(paneDl[category.toLowerCase()][0]).map((e) => {
    //     // console.log('saas-1', getPlotData({ [e]: paneDl[category.toLowerCase()][0][e] }, chartType));
    //     return {
    //       // rawPlotData: { [e]: paneDl[category.toLowerCase()][0][e] }
    //       rawPlotData: getPlotData({ [e]: paneDl[category.toLowerCase()][0][e] }, chartType)
    //     };
    //   });
    //   // console.log('multi pane', rawPlotData);
    //   addPaneDetails = [...addPaneDetails, ...rawPlotData];
    // }
  });
  // console.log('last pane', addPaneDetails);
  return addPaneDetails;
};

export const getAnnotations = (rawPlotData, key, chartTypeCategory) =>
  Object.keys(rawPlotData)
    .reduce((final, kpvKey) => {
      let rawAnnotations = rawPlotData[kpvKey]?.annotations;
      if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
        rawAnnotations = rawPlotData[kpvKey]?.annotations;
      }
      return [
        ...final,
        ...(rawAnnotations?.map((e) => ({
          ...e,
          index: key,
          seriesLabel: rawPlotData[kpvKey]?.meta.fields?.displayname,
          seriesKey: kpvKey
        })) || [])
      ];
    }, [])
    ?.map((e) => setAnnotationsDetails({ ...e, chartTypeCategory }));
