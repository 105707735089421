import React from 'react';
import DynamicSVGIcon from '../../../component/Common/DynamicSVGIcon';
import AddIcon from '../../../assets/icons/Filter/add black.svg';
import { Container, Text, WDBtn } from './Widget.style';

export default function Widget() {
  return (
    <Container className='flex ai_center jc_center'>
      <WDBtn
        style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        height='12rem'
        bgClr='#E8E8F1'
      >
        <DynamicSVGIcon iconUrl={AddIcon} width='3rem' />
        <Text>Widget</Text>
      </WDBtn>
    </Container>
  );
}
