import {
  menuCustomize,
  menuEditPane,
  menuFullScreen,
  menuRemove,
  menuScaleYAxis,
  menuShowDetails,
  menuShowOverlays
} from '../assets/icons';

/* eslint-disable max-len */

export const filterMenuData = {
  analytics: [
    { label: 'Clear Filter', id: 1, role: 'clear', class: 'pointer' },
    { label: 'Save Analytics', id: 2, role: 'save', class: 'pointer' }
    // disable: true
    // { label: 'Print Dashboard', id: 3, role: 'print', class: 'pointer' },
    // { label: 'Share Dashboard', id: 4, role: 'share', class: 'pointer' }
  ],
  dashboards: [
    { label: 'Clear Filter', id: 1, role: 'clear', class: 'pointer' },
    { label: 'Save Dashboard', id: 2, role: 'save', class: 'pointer' }
    // { label: 'Print Dashboard', id: 3, role: 'print', class: 'pointer' },
    // { label: 'Share Dashboard', id: 4, role: 'share', class: 'pointer' }
  ]
};

export const settingMenu = [
  { label: 'Add new tab', id: 1, role: 'add', class: 'pointer', svg: 'add white' },
  { label: 'Add New Widget', id: 2, role: 'add', class: 'pointer', svg: 'add white' },
  { label: 'Rename Tab', id: 3, role: 'rename ', class: 'pointer', svg: 'add white' },
  { label: 'Clear The Tab', id: 4, role: 'clear', class: 'pointer', svg: 'add white' },
  { label: 'Delete tab', id: 5, role: 'delete', class: 'pointer', svg: 'add white' }
];

export const profileMenuData = [
  { label: 'Filter', id: 1, role: 'filter' },
  { label: 'Logout', id: 2, role: 'logout' }
];

export const dataView = [
  {
    groupTitle: 'Process Group',
    id: 'id-1',
    required: 'true',
    type: 'object',
    values: [
      {
        name: 'Cleaning',
        id: 'id-1-2',
        required: 'false',
        type: 'object',
        attribute: 'Category',
        values: [
          {
            name: 'SA Prime Debond',
            id: 'id-1-2-1',
            required: 'false',
            type: 'multiselect',
            attribute: 'Machines',
            values: [
              {
                name: 'CPA001',
                id: 'id-1-2-1-1'
              },
              { name: 'CPA002', id: 'id-1-2-1-2' },
              { name: 'CPA003', id: 'id-1-2-1-3' },
              { name: 'CPA004', id: 'id-1-2-1-4' },
              { name: 'CPA005', id: 'id-1-2-1-5' },
              { name: 'CPA006', id: 'id-1-2-1-6' },
              { name: 'CPA007', id: 'id-1-2-1-7' }
            ]
          },
          {
            name: 'SA Prime Cleaning',
            id: 'id-1-2-2',
            required: 'false',
            type: 'object',
            attribute: 'Machines',
            values: [
              {
                name: 'CPA001',
                id: 'id-1-2-2-1',
                required: 'false',
                type: 'object'
              },
              { name: 'CPA010', id: 'id-1-2-2-2' },
              { name: 'CPA011', id: 'id-1-2-2-3' },
              { name: 'CPA012', id: 'id-1-2-2-4' },
              { name: 'CPA013', id: 'id-1-2-2-5' },
              { name: 'CPA014', id: 'id-1-2-2-6' },
              { name: 'CPA015', id: 'id-1-2-2-7' },
              { name: 'CPA016', id: 'id-1-2-2-8' },
              { name: 'CPA017', id: 'id-1-2-2-9' },
              { name: 'CPA018', id: 'id-1-2-2-10' },
              { name: 'CPA019', id: 'id-1-2-2-11' },
              { name: 'CPA020', id: 'id-1-2-2-12' },
              { name: 'CPA021', id: 'id-1-2-2-13' },
              { name: 'CPA022', id: 'id-1-2-2-14' },
              { name: 'CPA023', id: 'id-1-2-2-15' },
              { name: 'CPA024', id: 'id-1-2-2-16' },
              { name: 'CPA025', id: 'id-1-2-2-17' }
            ]
          }
        ]
      }
    ]
  }
];

export const kpvDetails = {
  'KPV Configuration': [
    {
      'KPV Name': 'CPA041/Defectrate/ Defect Rate 1',
      'Unit of Measure': 'MS',
      Notes: 'Lorem Ipsum Dolor Sit Amet,'
    }
  ],
  'Plot Chart': [
    {
      'Data Chart': 'Plot',
      'Run Chart': 'Plot',
      SPC: 'NA'
    }
  ],
  'DAQ Settings': {
    'Gain / Offset': {
      Gain: '1.23',
      Offset: '32.1',
      'KPV Read value': '135.5'
    },
    'KPV Address': [
      {
        'Tag Name': 'CPAO41',
        'Column No.': '121',
        'Column Name': 'Defect Rate'
      }
    ]
  },
  'Process Settings': {
    'Limit Setting': [
      {
        Limits: 'L1',
        From: 5,
        To: 95,
        'Band Colour': 'yellow',
        Alarm: true
      },
      {
        Limits: 'L2',
        From: 34,
        To: 76,
        'Band Colour': 'orange',
        Alarm: false
      }
    ]
  },
  'SPC Settings': {},
  'Display Settings': {}
};

export const editPaneMenu = {
  dataChart: [
    {
      id: '1-1',
      label: 'View Full Screen',
      value: 'fullScreen',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_full_screen_pkv6iv.svg'
    },
    {
      id: '1-2',
      label: 'Edit Pane',
      value: 'editPane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-3',
      label: 'Customise KPVs',
      value: 'customiseKpvs',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_customize_cjewlx.svg'
    },
    {
      id: '1-4',
      label: 'Show Details',
      value: 'showDetails',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_details_vpnhyu.svg',
      subMenu: [
        {
          id: '1-4-1',
          label: 'KPV Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        },
        {
          id: '1-4-4',
          label: 'Annotations',
          value: 'annotations'
        }
      ]
    },
    {
      id: '1-5',
      label: 'Show Overlays',
      value: 'showOverlays',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-5-1',
          label: 'L1 Limits',
          value: 'l1Limits'
        },
        {
          id: '1-5-2',
          label: 'L2 Limits',
          value: 'l2Limits'
        }
      ]
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scaleYAxis',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017253/Chart_menu_scale_Y_axis_se2izd.svg',
      subMenu: [
        {
          id: '1-6-1',
          label: 'Auto',
          value: 'auto'
        },
        {
          id: '1-6-2',
          label: 'Process Window',
          value: 'processWindow'
        },
        {
          id: '1-6-3',
          label: 'Manual',
          value: 'manual'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Remove Pane',
      value: 'removePane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_remove_pane_t88nsg.svg'
    }
  ],
  transitionChart: [
    {
      id: '1-1',
      label: 'View Full Screen',
      value: 'fullScreen',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_full_screen_pkv6iv.svg'
    },
    {
      id: '1-2',
      label: 'Edit Pane',
      value: 'editPane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-3',
      label: 'Customise Machines',
      value: 'customiseKpvs',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_customize_cjewlx.svg'
    },
    {
      id: '1-4',
      label: 'Show Details',
      value: 'showDetails',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_details_vpnhyu.svg',
      subMenu: [
        {
          id: '1-4-1',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Remove Pane',
      value: 'removePane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_remove_pane_t88nsg.svg'
    }
  ]
};

export const upsertOptions = {
  dataChart: [
    {
      id: '1-1',
      label: 'Pane Title',
      value: 'paneTitle',
      type: 'paneTitle'
    },
    {
      id: '1-2',
      label: 'Customise KPVs',
      value: 'customiseKpvs',
      type: 'customiseKpvs'
    },
    {
      id: '1-3',
      label: 'Show Details',
      value: 'showDetails',
      type: 'showDetails',
      subMenu: [
        {
          id: '1-3-1',
          label: 'Y-axis',
          value: 'y-axis'
        },
        {
          id: '1-3-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-3-3',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-3-4',
          label: 'Annotated points',
          value: 'annotatedPts'
        }
      ]
    },
    {
      id: '1-4',
      label: 'Show Overlays',
      value: 'showOverlays',
      type: 'showOverlays',
      subMenu: [
        {
          id: '1-4-1',
          label: 'L1 limits',
          value: 'l1-limits'
        },
        {
          id: '1-4-2',
          label: 'L2 limits',
          value: 'l2-limits'
        }
      ]
    },
    {
      id: '1-5',
      label: 'Y-Axis Settings',
      value: 'yAxis-settings',
      type: 'yAxis-settings',
      subMenu: [
        {
          id: '1-5-1',
          label: 'Single Y-axis',
          value: false
        },
        {
          id: '1-5-2',
          label: 'Multiple Y-axis',
          value: true
        }
      ]
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scale-yAxis',
      type: 'scale-yAxis',
      subMenu: [
        { id: '1-6-1', label: 'Auto', value: 'auto' },
        { id: '1-6-2', label: 'Process Window', value: 'processWindow' },
        { id: '1-6-3', label: 'Manual', value: 'manual' }
      ]
    }
  ],
  transitionChart: [
    {
      id: '1-1',
      label: 'Pane Title',
      value: 'paneTitle',
      type: 'paneTitle'
    },
    {
      id: '1-2',
      label: 'Transition Charts',
      value: 'transitionCharts',
      type: 'transitionCharts',
      subMenu: [
        {
          id: '1-2-1',
          label: 'Lots',
          value: 'lots'
        },
        {
          id: '1-2-2',
          label: 'Recipe',
          value: 'recipe'
        },
        {
          id: '1-2-3',
          label: 'Status',
          value: 'status'
        }
      ]
    },
    {
      id: '1-3',
      label: 'Customise Machines',
      value: 'customiseKpvs',
      type: 'customiseKpvs'
    },
    {
      id: '1-4',
      label: 'Pane Details',
      value: 'showDetails',
      type: 'showDetails',
      subMenu: [
        {
          id: '1-4-1',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    }
  ]
};

export const myAnalytics = [
  {
    id: 1,
    title: 'Flowrate KPV SPC Data',
    category: 'Alarms',
    shared: true,
    machineIds: ['P2_1575', 'P2_1574', 'P2_2821'],
    menuId: 2004,
    kpvs: [
      {
        KPV: 'GEM SVID CurrentBondPadCol',
        id: 'id-1-1',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Vietnam',
        Value: '702.00 ms',
        machineId: 'P2_1575',
        machineName: '1FAM0101',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadCol',
        id: 'id-1-1',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Vietnam',
        Value: '702.00 ms',
        machineId: 'P2_1574',
        machineName: '1FAM0102',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadRow',
        id: 'id-1-2',
        L1: '-0.12 to 0.56',
        L2: '-0.12 to 0.56',
        Timestamp: '24-06-2021, 10:59:00.213 AM',
        Plant: 'Vietnam',
        Value: '0 ms',
        machineId: 'P2_1574',
        machineName: '1FAM0101',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadTargetDieNum',
        id: 'id-1-3',
        L1: '-0.21 to 0.25',
        L2: '-0.21 to 0.25',
        Timestamp: '24-06-2021, 11:03:00.253 AM',
        Plant: 'Malaysia',
        Value: '0.4272 %',
        machineId: 'P2_2821',
        machineName: '1FAM0102',
        groupName: 'KPV'
      }
    ],
    panesShortHandInfo: [
      {
        paneTitle: '',
        chartType: 'dataChart',
        machinesSelected: [
          {
            machineId: 'P2_2821',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P2_1574',
            machineName: '1FAM0102',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P2_1575',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-2',
            kpvName: 'GEM SVID CurrentBondPadRow'
          }
        ],
        multiSelectKpv: false,
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        singleGrid: true,
        scaleYaxis: 'auto',
        scaleYAxisRange: [],
        multiGrid: false,
        transitionChartType: false,
        pointInfoObj: []
      },
      {
        paneTitle: '',
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        scaleYaxis: 'auto',
        scaleYAxisRange: [
          {
            from: '',
            to: ''
          }
        ],
        machinesSelected: [
          {
            kpvId: 'id-1-1',
            machineId: 'P2_1574',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvName: 'GEM SVID CurrentBondPadCol'
          }
        ],
        chartType: 'dataChart',
        transitionChartType: 'lots',
        multiGrid: false,
        pointInfoObj: []
      }
    ]
  },
  {
    id: 2,
    title: 'Tank temperature Run Data',
    category: 'Daily Check',
    shared: false,
    menuId: 2004,
    machineIds: ['P1_7622', 'P1_7623', 'P1_3102'],
    kpvs: [
      {
        KPV: 'GEM SVID CurrentBondPadCol',
        id: 'id-1-1',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Vietnam',
        Value: '702.00 ms',
        machineId: 'P1_7622',
        machineName: '1PLM0203',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID PostBondX',
        id: 'id-1-1',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Malaysia',
        Value: '12 ms',
        machineId: 'P1_7623',
        machineName: '1PLM0203',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadCol',
        id: 'id-1-2',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Vietnam',
        Value: '702.00 ms',
        machineId: 'P1_7623',
        machineName: '1PLM0405',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadRow',
        id: 'id-1-3',
        L1: '-0.12 to 0.56',
        L2: '-0.12 to 0.56',
        Timestamp: '24-06-2021, 10:59:00.213 AM',
        Plant: 'Vietnam',
        Value: '0 ms',
        machineId: 'P1_3102',
        machineName: '1PLM0405',
        groupName: 'KPV'
      }
    ],
    panesShortHandInfo: [
      {
        paneTitle: '',
        chartType: 'dataChart',
        machinesSelected: [
          {
            machineId: 'P1_7622',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P1_7623',
            machineName: '1FAM0102',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P1_7623',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-2',
            kpvName: 'GEM SVID CurrentBondPadRow'
          },
          {
            machineId: 'P1_3102',
            machineName: '1FAM0102',
            groupName: 'KPV',
            kpvId: 'id-1-3',
            kpvName: 'GEM SVID CurrentBondPadTargetDieNum'
          }
        ],
        multiSelectKpv: false,
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        singleGrid: true,
        scaleYaxis: 'auto',
        scaleYAxisRange: [],
        multiGrid: false,
        transitionChartType: false,
        pointInfoObj: []
      },
      {
        paneTitle: '',
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        scaleYaxis: 'auto',
        scaleYAxisRange: [
          {
            from: '',
            to: ''
          }
        ],
        machinesSelected: [
          {
            kpvId: 'id-1-1',
            machineId: 'P1_7622',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvName: 'GEM SVID CurrentBondPadCol'
          }
        ],
        chartType: 'dataChart',
        transitionChartType: 'lots',
        multiGrid: false,
        pointInfoObj: []
      }
    ]
  },
  {
    id: 3,
    title: 'Defect Rate - Data Chart',
    category: 'Log File',
    shared: false,
    machineIds: ['P1_2035', 'P1_2033', 'P1_6147'],
    menuId: 2004,
    kpvs: [
      {
        KPV: 'GEM SVID CurrentBondPadCol',
        id: 'id-1-1',
        L1: '-0.156 to 0.45',
        L2: '-0.156 to 0.45',
        Timestamp: '24-06-2021, 11:03:00.045 AM',
        Plant: 'Vietnam',
        Value: '702.00 ms',
        machineId: 'P1_2035',
        machineName: '2PLM0601',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID PreBondEPIX',
        id: 'id-1-8',
        L1: '-0.21 to 0.25',
        L2: '-0.21 to 0.25',
        Timestamp: '24-06-2021, 11:03:00.253 AM',
        Plant: 'Malaysia',
        Value: '132.00 ms',
        machineId: 'P1_2033',
        machineName: '2PLM0601',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID CurrentBondPadTargetDieNum',
        id: 'id-1-3',
        L1: '-0.21 to 0.25',
        L2: '-0.21 to 0.25',
        Timestamp: '24-06-2021, 11:03:00.253 AM',
        Plant: 'Malaysia',
        Value: '0.4272 %',
        machineId: 'P1_6147',
        machineName: '2PLM0403',
        groupName: 'KPV'
      },
      {
        KPV: 'GEM SVID PostBondAngle',
        id: 'id-1-4',
        L1: '-0.132 to 0.5',
        L2: '-0.132 to 0.5',
        Timestamp: '24-06-2021, 12:39:00.012 PM',
        Plant: 'Vietnam',
        Value: '123.00 ms',
        machineId: 'P1_2035',
        machineName: '2PLM0403',
        groupName: 'KPV'
      }
    ],
    panesShortHandInfo: [
      {
        paneTitle: '',
        chartType: 'dataChart',
        machinesSelected: [
          {
            machineId: 'P1_2035',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P1_2035',
            machineName: '1FAM0102',
            groupName: 'KPV',
            kpvId: 'id-1-1',
            kpvName: 'GEM SVID CurrentBondPadCol'
          },
          {
            machineId: 'P1_2033',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvId: 'id-1-8',
            kpvName: 'GEM SVID CurrentBondPadRow'
          },
          {
            machineId: 'P1_6147',
            machineName: '1FAM0102',
            groupName: 'KPV',
            kpvId: 'id-1-3',
            kpvName: 'GEM SVID CurrentBondPadTargetDieNum'
          }
        ],
        multiSelectKpv: false,
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        singleGrid: true,
        scaleYaxis: 'auto',
        scaleYAxisRange: [],
        multiGrid: false,
        transitionChartType: false,
        pointInfoObj: []
      },
      {
        paneTitle: '',
        showDetails: {
          'y-axis': true,
          'x-axis': true,
          legends: true,
          annotatedPts: false
        },
        multiAxis: false,
        scaleYaxis: 'auto',
        scaleYAxisRange: [
          {
            from: '',
            to: ''
          }
        ],
        machinesSelected: [
          {
            kpvId: 'id-1-1',
            machineId: 'P1_2035',
            machineName: '1FAM0101',
            groupName: 'KPV',
            kpvName: 'GEM SVID CurrentBondPadCol'
          }
        ],
        chartType: 'dataChart',
        transitionChartType: 'lots',
        multiGrid: false,
        pointInfoObj: []
      }
    ]
  }
];

export const myDashboardData = [
  {
    id: 1,
    title: 'Dashboard/OEE/Summary/HGA/HGA Compact/Daily/9th june',
    category: 'Summary',
    searchTags: ['OEE', 'Summary'],
    ids: ['P2_1575', 'P2_1574', 'P2_2821', '3'],
    shared: true,
    menuId: 2000
  },
  {
    id: 2,
    title: 'Dashboard/OEE/Trend/ASM/Monthly/May',
    category: 'Trend',
    searchTags: ['OEE', 'Trend', 'Summary'],
    shared: false,
    menuId: 2000,
    ids: ['P1_7622', 'P1_7623', 'P1_3102', '4']
  },
  {
    id: 3,
    title: 'Dashboard/OEE/Comparison/HGA/Combo/20th May',
    category: 'Comparison',
    searchTags: ['OEE', 'Comparison'],
    shared: false,
    menuId: 2000,
    ids: ['P1_2035', 'P1_2033', 'P1_6147', '5']
  }
];

export const myDashboard = [
  {
    DashboardId: 2,
    DashboardName: 'Utilization Trend(1FBT0104)',
    Category: 'CategoryType',
    SearchTags: '1FBT0104, PEN_TREND',
    Owner: 'lino.thomas@vistrian.com',
    Shared: true,
    IsDeletable: 'False'
  },
  {
    DashboardId: 3,
    DashboardName: 'Utilization Trend(1FBT0104)',
    Category: 'CategoryType',
    SearchTags: '1FBT0104, PEN_TREND',
    Owner: 'lino.thomas@vistrian.com',
    Shared: true,
    IsDeletable: 'False'
  },
  {
    DashboardId: 5,
    DashboardName: 'Test Dashboard',
    Category: 'CategoryType',
    SearchTags: '[]',
    Owner: 'md.akhil@panorbit.in',
    Shared: true,
    IsDeletable: 'True'
  },
  {
    DashboardId: 4,
    DashboardName: 'Test dashboard',
    Category: 'CategoryType',
    SearchTags: "['OEE Trend']",
    Shared: true,
    IsDeletable: 'False'
  }
];

export const alaramsData = [
  {
    id: '101',
    kpvId: 'id-1-1',
    machineName: 'ACAM55',
    kpvName: 'Logfile: UV1 Box',
    message: 'ACAM55, Logfile: UV1 Box Width raised Below Min. Limit Alarm',
    time: '08:14 AM'
  },
  {
    id: '102',
    kpvId: 'id-1-1',
    machineName: 'ACAM55',
    kpvName: 'Logfile: UV1 Box',
    message: 'ACAM55, Logfile: UV1 Box Height raised Below Min. Limit Alarm ',
    time: '08:00 AM'
  },
  {
    id: '103',
    kpvId: 'id-1-2',
    machineName: 'ACAM55',
    kpvName: 'Logfile: UV2 Box',
    message: 'ACAM55, Logfile: UV2 Box Width raised Below Min. Limit Alarm',
    time: '06:23 AM'
  },
  {
    id: '104',
    kpvId: 'id-1-3',
    machineName: 'Diakite01',
    kpvName: 'VS : VS - POS2 US',
    message: 'Diakite01, VS : VS - POS2 US Power - Side raised Below Min. Limit Alarm',
    time: '12:30 AM'
  }
];

export const detailedAlaramData = {
  101: {
    machines: 'ACAM-55',
    parameters: 'LogFile : UV1 Box Width',
    startTime: '08/17/2021 04:19:55 PM',
    endTime: '08/17/2021 04:19:55 PM',
    status: 'Below Min Limit',
    setMinLimit: '246',
    setMaxLimit: '254',
    setDuration: '1sec'
  },
  102: {
    machines: 'ACAM-55',
    parameters: 'LogFile : UV2 Box Width',
    startTime: '08/17/2021 04:19:55 PM',
    endTime: '08/17/2021 04:19:55 PM',
    status: 'Below Min Limit',
    setMinLimit: '246',
    setMaxLimit: '254',
    setDuration: '1sec'
  },
  103: {
    machines: 'Diakite-01',
    parameters: 'VS : VS - POS2 US Power - Side',
    startTime: '08/17/2021 04:19:55 PM',
    endTime: '08/17/2021 04:19:55 PM',
    status: 'Below Min Limit',
    setMinLimit: '246',
    setMaxLimit: '254',
    setDuration: '200ms'
  },
  104: {
    machines: 'Diakite-01',
    parameters: 'VS : VS - POS2 US Power - Bottom',
    startTime: '08/17/2021 04:19:55 PM',
    endTime: '08/17/2021 04:19:55 PM',
    status: 'Below Min Limit',
    setMinLimit: '246',
    setMaxLimit: '254',
    setDuration: '200ms'
  }

  //   : {
  //     machines:
  // parameters:
  // startTime:
  // endTime:
  // status:
  // setMinLimit:
  // setMaxLimit:
  // setDuration:
  //   }
};

export const alaramComments = {
  101: {
    description: 'DI water pH in wiping station above Normal',
    comments: [
      {
        profilePic: '',
        name: 'Lim Wei Chuen',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        profilePic: '',
        name: 'Chan, Liang Teng',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      {
        profilePic: '',
        name: 'Bin Zaidin, Mohd Shafik',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      }
    ]
  }
};

export const approvalsData = [
  {
    message: 'Ainol Syakina Binti Mohd Rodhi submitted a Production Information and your approval is required.',
    time: '08:14 AM'
  },
  {
    message: 'Homer Castro submitted a Lot Test Information and your approval is required.',
    time: '08:00 AM'
  },
  {
    message: 'Aileen Marsh submitted a Production Information and your approval is required.',
    time: '06:23 AM'
  }
];

export const cbmAlertsData = [
  {
    message: 'Equipment Violation alert on 1SAW0420',
    time: '08:14:45 AM',
    date: '7/15/2021'
  },
  {
    message: 'Equipment Violation alert on 1SAW0420',
    time: '10:14:38 AM',
    date: '7/15/2021'
  },
  {
    message: 'Equipment Violation alert on 1SAW0460',
    time: '06:14:45 PM',
    date: '7/15/2021'
  },
  {
    message: 'Equipment Violation alert on 1SAW0320',
    time: '10:54:38 PM',
    date: '7/15/2021'
  }
];

export const scheduleData = [
  {
    message: 'Lim Wei Chuen submitted ADHOC schedule request and your approval is required',
    time: '08:14 AM'
  },
  {
    message: 'Chan, Liang Teng submitted New schedule request and your approval is required',
    time: '08:00 AM'
  },
  {
    message: 'Aileen Marsh submitted a ADHOC Schedule request and your approval is required.',
    time: '06:23 AM'
  },
  {
    message: 'Lim Wei Chuen submitted a 100% DVF Request and your approval is required.',
    time: '04:23 AM'
  }
];

export const userProfileDetails = {
  profileName: 'Mohammad Akhil',
  empId: 39403,
  userId: 'MdAkhil',
  role: 'Engineer',
  mail: 'md.akhil@panorbit.in',
  reportingManager: 'Louie Corbyn',
  phone: '0788- 536 7542'
};

export const obl = {
  0: {
    name: 'WUX',
    id: '_P2',
    attribute: 'Module'
  },
  1: {
    name: 'MF1 - APT',
    id: '_P2_1009',
    attribute: 'Process'
  },
  2: {
    name: 'TAPE',
    id: '_P2_1311',
    attribute: 'Group'
  },
  3: {
    name: 'TOKYO_WELD',
    id: '_P2_1380',
    attribute: 'Equipment'
  },
  4: [
    {
      name: '7BTWTS02',
      id: '_P2_1382',
      attribute: null
    },
    {
      name: '7BTWTS03',
      id: '_P2_1383',
      attribute: 'Equipment'
    }
  ]
};

export const cummObj = {
  Plant: {
    0: {
      0: {
        name: 'PEN',
        id: '_P1',
        attribute: 'Module'
      },
      1: {
        name: 'MF2 - SMARTLED',
        id: '_P1_1026',
        attribute: 'Process'
      },
      2: {
        name: 'AVI',
        id: '_P1_2886',
        attribute: 'Group'
      },
      3: {
        name: '2AVI04_E01',
        id: '_P1_2923',
        attribute: 'Equipment'
      },
      4: [
        {
          name: '2AVI0401',
          id: '_P1_3108',
          attribute: null
        }
      ]
    },
    1: {
      0: {
        name: 'PEN',
        id: '_P1',
        attribute: 'Module'
      },
      1: {
        name: 'MF1 - OSTAR',
        id: '_P1_1045',
        attribute: 'Process'
      },
      2: {
        name: 'PLASMA',
        id: '_P1_1046',
        attribute: 'Group'
      },
      3: {
        name: '1PLM01_F02',
        id: '_P1_2032',
        attribute: 'Equipment'
      },
      4: [
        {
          name: '1PLM0112',
          id: '_P1_2033',
          attribute: null
        },
        {
          name: '1PLM0111',
          id: '_P1_2034',
          attribute: null
        },
        {
          name: '1PLM0110',
          id: '_P1_2035',
          attribute: null
        }
      ]
    },
    2: {
      0: {
        name: 'PEN',
        id: '_P1',
        attribute: 'Module'
      },
      1: {
        name: 'MF1 - OSTAR',
        id: '_P1_1045',
        attribute: 'Process'
      },
      2: {
        name: 'SORTTAPER',
        id: '_P1_1320',
        attribute: 'Group'
      },
      3: {
        name: '1FBT01_T01',
        id: '_P1_1525',
        attribute: 'Equipment'
      },
      4: [
        {
          name: '1FBT0104',
          id: '_P1_1377',
          attribute: null
        },
        {
          name: '1FBT0105',
          id: '_P1_1378',
          attribute: null
        },
        {
          name: '1FBT0107',
          id: '_P1_1567',
          attribute: null
        }
      ]
    },
    3: {
      0: {
        name: 'WUX',
        id: '_P2',
        attribute: 'Module'
      },
      1: {
        name: 'MF1 - APT',
        id: '_P2_1009',
        attribute: 'Process'
      },
      2: {
        name: 'DETAPE',
        id: '_P2_2561',
        attribute: 'Group'
      },
      3: {
        name: 'INTO_TX20',
        id: '_P2_1709',
        attribute: 'Equipment'
      },
      4: [
        {
          name: '7BITDS01',
          id: '_P2_2700',
          attribute: null
        }
      ]
    },
    4: {
      0: {
        name: 'WUX',
        id: '_P2',
        attribute: 'Module'
      },
      1: {
        name: 'MF1 - APT',
        id: '_P2_1009',
        attribute: 'Process'
      },
      2: {
        name: 'LAYER_TRANSFER',
        id: '_P2_1327',
        attribute: 'Group'
      },
      3: {
        name: 'DATACON',
        id: '_P2_1328',
        attribute: 'Equipment'
      },
      4: [
        {
          name: '7BLAYS09',
          id: '_P2_1337',
          attribute: null
        },
        {
          name: '7BLAYS02',
          id: '_P2_1330',
          attribute: null
        }
      ]
    }
  },
  Interval: {
    0: {
      0: {
        name: 'Weekly',
        id: '_5',
        attribute: 'Week'
      },
      1: {
        name: 'Current Week',
        id: '_6',
        attribute: null
      }
    }
  }
};

// export const dummyFilterData = {
//   Summary: {
//     data: {
//       tree: [
//         {
//           id: 1,
//           groupTitle: 'Plant',
//           type: 'object',
//           selectionType: 'multiselect',
//           required: 'true',
//           attribute: 'Plant',
//           values: [
//             {
//               id: 'P1',
//               name: 'PEN',
//               type: 'object',
//               selectionType: 'multiselect',
//               required: 'false',
//               attribute: 'Module',
//               lockable: false,
//               values: [
//                 {
//                   id: 'P1_1026',
//                   name: 'MF2 - SMARTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_2886',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2923',
//                           name: '2AVI04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3108',
//                               name: '2AVI0401'
//                             },
//                             {
//                               id: 'P1_3109',
//                               name: '2AVI0402'
//                             },
//                             {
//                               id: 'P1_3110',
//                               name: '2AVI0403'
//                             },
//                             {
//                               id: 'P1_3112',
//                               name: '2AVI0405'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2887',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5321',
//                           name: '2DAT18_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3136',
//                               name: '2DAT1804'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2891',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2933',
//                           name: '2OVN23_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3119',
//                               name: '2OVN2301'
//                             },
//                             {
//                               id: 'P1_3120',
//                               name: '2OVN2302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5323',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3113',
//                               name: '2OVN0125'
//                             },
//                             {
//                               id: 'P1_3114',
//                               name: '2OVN0135'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5427',
//                           name: '2OVN22_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3116',
//                               name: '2OVN2202'
//                             },
//                             {
//                               id: 'P1_3117',
//                               name: '2OVN2203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2892',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5324',
//                           name: '2PLM09_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3122',
//                               name: '2PLM0904'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5319',
//                       name: 'DEFLASHING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5426',
//                           name: '2DEF02_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3131',
//                               name: '2DEF0203'
//                             },
//                             {
//                               id: 'P1_5689',
//                               name: '2DEF0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5326',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5327',
//                           name: '2SBB02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2658',
//                               name: '2SBB0201'
//                             },
//                             {
//                               id: 'P1_2659',
//                               name: '2SBB0202'
//                             },
//                             {
//                               id: 'P1_2660',
//                               name: '2SBB0203'
//                             },
//                             {
//                               id: 'P1_2661',
//                               name: '2SBB0205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5328',
//                           name: '2SBB03_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2663',
//                               name: '2SBB0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5954',
//                           name: '2SBB07_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5795',
//                               name: '2SBB0715'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5329',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5330',
//                           name: '2TBT02_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2693',
//                               name: '2TBT0213'
//                             },
//                             {
//                               id: 'P1_2694',
//                               name: '2TBT0214'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5331',
//                           name: '2TBT03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2697',
//                               name: '2TBT0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5332',
//                           name: '2TBT05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2714',
//                               name: '2TBT0501'
//                             },
//                             {
//                               id: 'P1_2715',
//                               name: '2TBT0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5955',
//                           name: '2TBT08_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5797',
//                               name: '2TBT0811'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5510',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5511',
//                           name: '2FRB07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3279',
//                               name: '2FRB0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5591',
//                           name: '2FRB09_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5594',
//                               name: '2FRB0901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5514',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5515',
//                           name: '2OVN32_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3574',
//                               name: '2OVN3201'
//                             },
//                             {
//                               id: 'P1_3575',
//                               name: '2OVN3202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5846',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5847',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2443',
//                               name: '2VRR0226'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7720',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7721',
//                           name: '2MVI20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7622',
//                               name: '2MVI2003'
//                             },
//                             {
//                               id: 'P1_7623',
//                               name: '2MVI2004'
//                             },
//                             {
//                               id: 'P1_7624',
//                               name: '2MVI2005'
//                             },
//                             {
//                               id: 'P1_7625',
//                               name: '2MVI2006'
//                             },
//                             {
//                               id: 'P1_7626',
//                               name: '2MVI2007'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7722',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7723',
//                           name: '2AVI02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3141',
//                               name: '2AVI0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8673',
//                       name: 'DB_WB_MOLD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8674',
//                           name: 'DA_WB_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3103',
//                               name: '2TMD0301'
//                             },
//                             {
//                               id: 'P1_3125',
//                               name: '2WBD1911'
//                             },
//                             {
//                               id: 'P1_3126',
//                               name: '2WBD1912'
//                             },
//                             {
//                               id: 'P1_3138',
//                               name: '2DAT1901'
//                             },
//                             {
//                               id: 'P1_3139',
//                               name: '2DAT1902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8783',
//                           name: 'DA_WB_S05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3100',
//                               name: '2WBD1102'
//                             },
//                             {
//                               id: 'P1_3107',
//                               name: '2TMD0305'
//                             },
//                             {
//                               id: 'P1_3137',
//                               name: '2DAT1805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8799',
//                           name: 'DA_WB_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3101',
//                               name: '2WBD1301'
//                             },
//                             {
//                               id: 'P1_3104',
//                               name: '2TMD0302'
//                             },
//                             {
//                               id: 'P1_3127',
//                               name: '2WBD1913'
//                             },
//                             {
//                               id: 'P1_3134',
//                               name: '2DAT1701'
//                             },
//                             {
//                               id: 'P1_3135',
//                               name: '2DAT1702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8800',
//                           name: 'DA_WB_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3102',
//                               name: '2WBD1302'
//                             },
//                             {
//                               id: 'P1_3105',
//                               name: '2TMD0303'
//                             },
//                             {
//                               id: 'P1_3423',
//                               name: '2DAT2027'
//                             },
//                             {
//                               id: 'P1_3553',
//                               name: '2WBD1903'
//                             },
//                             {
//                               id: 'P1_6631',
//                               name: '2DAT1806'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1045',
//                   name: 'MF1 - OSTAR',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1046',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2032',
//                           name: '1PLM01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2033',
//                               name: '1PLM0112'
//                             },
//                             {
//                               id: 'P1_2034',
//                               name: '1PLM0111'
//                             },
//                             {
//                               id: 'P1_2035',
//                               name: '1PLM0110'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3838',
//                           name: '1PLM04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2083',
//                               name: '1PLM0403'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6655',
//                           name: '1PLM01_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1049',
//                               name: '1PLM0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1320',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1525',
//                           name: '1FBT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1377',
//                               name: '1FBT0104'
//                             },
//                             {
//                               id: 'P1_1378',
//                               name: '1FBT0105'
//                             },
//                             {
//                               id: 'P1_1379',
//                               name: '1FBT0106'
//                             },
//                             {
//                               id: 'P1_1567',
//                               name: '1FBT0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1715',
//                       name: 'AUTO_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1325',
//                           name: '1ATE01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1359',
//                               name: '1ATE0101'
//                             },
//                             {
//                               id: 'P1_1360',
//                               name: '1ATE0102'
//                             },
//                             {
//                               id: 'P1_1361',
//                               name: '1ATE0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6542',
//                           name: '1ATE02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6543',
//                               name: '1ATE0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7491',
//                           name: '1ATE05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7492',
//                               name: '1ATE0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7493',
//                           name: '1AST01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7494',
//                               name: '1AST0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1716',
//                       name: 'FOIL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1326',
//                           name: '1SFF01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1362',
//                               name: '1SFF0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1717',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1328',
//                           name: '1PFF01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1373',
//                               name: '1PFF0102'
//                             },
//                             {
//                               id: 'P1_1374',
//                               name: '1PFF0103'
//                             },
//                             {
//                               id: 'P1_1375',
//                               name: '1PFF0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2039',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5562',
//                           name: '1STE01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5564',
//                               name: '1STE0101'
//                             },
//                             {
//                               id: 'P1_5565',
//                               name: '1STE0102'
//                             },
//                             {
//                               id: 'P1_6146',
//                               name: '1STE0103'
//                             },
//                             {
//                               id: 'P1_6147',
//                               name: '1STE0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2048',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2064',
//                           name: '1DAT10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2093',
//                               name: '1DAT1001'
//                             },
//                             {
//                               id: 'P1_2097',
//                               name: '1DAT1011'
//                             },
//                             {
//                               id: 'P1_2098',
//                               name: '1DAT1015'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2065',
//                           name: '1DAT05_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2103',
//                               name: '1DAT0533'
//                             },
//                             {
//                               id: 'P1_2227',
//                               name: '1DAT0502'
//                             },
//                             {
//                               id: 'P1_4675',
//                               name: '1DAT0576'
//                             },
//                             {
//                               id: 'P1_4676',
//                               name: '1DAT0577'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4989',
//                           name: '1DAT10_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2089',
//                               name: '1DAT1014'
//                             },
//                             {
//                               id: 'P1_2090',
//                               name: '1DAT1016'
//                             },
//                             {
//                               id: 'P1_2096',
//                               name: '1DAT1008'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2049',
//                       name: 'WINDOW_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2066',
//                           name: '1WAT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2114',
//                               name: '1WAT0105'
//                             },
//                             {
//                               id: 'P1_2115',
//                               name: '1WAT0106'
//                             },
//                             {
//                               id: 'P1_2116',
//                               name: '1WAT0111'
//                             },
//                             {
//                               id: 'P1_2117',
//                               name: '1WAT0112'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2349',
//                           name: '1WAT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2111',
//                               name: '1WAT0110'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2050',
//                       name: 'FRAME_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2067',
//                           name: '1FAT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2112',
//                               name: '1WAT0104'
//                             },
//                             {
//                               id: 'P1_2113',
//                               name: '1WAT0103'
//                             },
//                             {
//                               id: 'P1_2120',
//                               name: '1FAT0102'
//                             },
//                             {
//                               id: 'P1_2121',
//                               name: '1FAT0103'
//                             },
//                             {
//                               id: 'P1_2122',
//                               name: '1FAT0104'
//                             },
//                             {
//                               id: 'P1_7117',
//                               name: '1WAT0113'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2051',
//                       name: 'SUPP_CERAMIC_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2068',
//                           name: '1CRA01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2125',
//                               name: '1CRA0105'
//                             },
//                             {
//                               id: 'P1_2126',
//                               name: '1CRA0103'
//                             },
//                             {
//                               id: 'P1_3666',
//                               name: '1CRA0106'
//                             },
//                             {
//                               id: 'P1_3848',
//                               name: '1CRA0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2052',
//                       name: 'US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2350',
//                           name: '1USM01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2130',
//                               name: '1USM0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2351',
//                           name: '1USM03_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2132',
//                               name: '1USM0301'
//                             },
//                             {
//                               id: 'P1_3864',
//                               name: '1USM0304'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2053',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2070',
//                           name: '1WBD04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2142',
//                               name: '1WBD0426'
//                             },
//                             {
//                               id: 'P1_2143',
//                               name: '1WBD0425'
//                             },
//                             {
//                               id: 'P1_2144',
//                               name: '1WBD0424'
//                             },
//                             {
//                               id: 'P1_2145',
//                               name: '1WBD0410'
//                             },
//                             {
//                               id: 'P1_2146',
//                               name: '1WBD0407'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2353',
//                           name: '1WBD11_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2135',
//                               name: '1WBD1103'
//                             },
//                             {
//                               id: 'P1_2136',
//                               name: '1WBD1102'
//                             },
//                             {
//                               id: 'P1_2137',
//                               name: '1WBD1101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3835',
//                           name: '1WBD07_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3620',
//                               name: '1WBD0702'
//                             },
//                             {
//                               id: 'P1_4659',
//                               name: '1WBD0730'
//                             },
//                             {
//                               id: 'P1_5117',
//                               name: '1WBD0740'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2054',
//                       name: 'THERMOGRAPHY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3872',
//                           name: '1TMG02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3873',
//                               name: '1TMG0202'
//                             },
//                             {
//                               id: 'P1_3874',
//                               name: '1TMG0203'
//                             },
//                             {
//                               id: 'P1_5483',
//                               name: '1TMG0212'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2058',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3810',
//                           name: '1MVI02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3811',
//                               name: '1MVI0215'
//                             },
//                             {
//                               id: 'P1_3812',
//                               name: '1MVI0204'
//                             },
//                             {
//                               id: 'P1_3813',
//                               name: '1MVI0212'
//                             },
//                             {
//                               id: 'P1_3815',
//                               name: '1MVI0214'
//                             },
//                             {
//                               id: 'P1_3816',
//                               name: '1MVI0203'
//                             },
//                             {
//                               id: 'P1_3817',
//                               name: '1MVI0206'
//                             },
//                             {
//                               id: 'P1_3818',
//                               name: '1MVI0207'
//                             },
//                             {
//                               id: 'P1_3819',
//                               name: '1MVI0208'
//                             },
//                             {
//                               id: 'P1_3820',
//                               name: '1MVI0209'
//                             },
//                             {
//                               id: 'P1_3823',
//                               name: '1MVI0213'
//                             },
//                             {
//                               id: 'P1_4837',
//                               name: '1MVI0202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6287',
//                           name: '1AVT02_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6288',
//                               name: '1AVT0212'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6656',
//                           name: '1AVI02_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1770',
//                               name: '1AVI0206'
//                             },
//                             {
//                               id: 'P1_1898',
//                               name: '1AVI0208'
//                             },
//                             {
//                               id: 'P1_6214',
//                               name: '1AVI0241'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2060',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2078',
//                           name: '1SAW03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2240',
//                               name: '1SAW0331'
//                             },
//                             {
//                               id: 'P1_2242',
//                               name: '1SAW0334'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7459',
//                           name: '1SAW07_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7460',
//                               name: '1SAW0701'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2062',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2080',
//                           name: '1CAS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2169',
//                               name: '1CAS0101'
//                             },
//                             {
//                               id: 'P1_2174',
//                               name: '1CAS0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2081',
//                           name: '1CAS03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2175',
//                               name: '1CAS0303'
//                             },
//                             {
//                               id: 'P1_2176',
//                               name: '1CAS0302'
//                             },
//                             {
//                               id: 'P1_2280',
//                               name: '1CAS0307'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2337',
//                           name: '1CAS04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2284',
//                               name: '1CAS0419'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2330',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2331',
//                           name: '1DAT08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2099',
//                               name: '1DAT0802'
//                             },
//                             {
//                               id: 'P1_2100',
//                               name: '1DAT0804'
//                             },
//                             {
//                               id: 'P1_3688',
//                               name: '1DAT0807'
//                             },
//                             {
//                               id: 'P1_3837',
//                               name: '1DAT0825'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8784',
//                           name: '1DAT08_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4209',
//                               name: '1DAT0830'
//                             },
//                             {
//                               id: 'P1_4797',
//                               name: '1DAT0834'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2333',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5735',
//                           name: '1SCF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5748',
//                               name: '1SCF0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2398',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4221',
//                           name: '1OVN03_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4222',
//                               name: '1OVN0302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4840',
//                           name: '1OVN01_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4843',
//                               name: '1OVN0164'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5059',
//                           name: '1OVN01_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4852',
//                               name: '1OVN0172'
//                             },
//                             {
//                               id: 'P1_4853',
//                               name: '1OVN0174'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5060',
//                           name: '1OVN01_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4848',
//                               name: '1OVN0168'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5061',
//                           name: '1OVN01_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4846',
//                               name: '1OVN0177'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5521',
//                           name: '1OVN15_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5522',
//                               name: '1OVN1527'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5750',
//                           name: '1OVN01_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5092',
//                               name: '1OVN0160'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5751',
//                           name: '1OVN01_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4905',
//                               name: '1OVN0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5775',
//                           name: '1OVN01_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4559',
//                               name: '1OVN0162'
//                             },
//                             {
//                               id: 'P1_4561',
//                               name: '1OVN0151'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6003',
//                           name: '1OVN45_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6004',
//                               name: '1OVN4501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6510',
//                           name: '1OVN45_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6005',
//                               name: '1OVN4502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7432',
//                           name: '1OVN30_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7433',
//                               name: '1OVN3001'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7439',
//                           name: '1OVN30_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7434',
//                               name: '1OVN3002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7440',
//                           name: '1OVN30_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7435',
//                               name: '1OVN3003'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7441',
//                           name: '1OVN30_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7436',
//                               name: '1OVN3004'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7442',
//                           name: '1OVN30_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7437',
//                               name: '1OVN3005'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7443',
//                           name: '1OVN30_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7444',
//                               name: '1OVN3007'
//                             },
//                             {
//                               id: 'P1_7445',
//                               name: '1OVN3008'
//                             },
//                             {
//                               id: 'P1_7448',
//                               name: '1OVN3011'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7495',
//                           name: '1OVN30_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7446',
//                               name: '1OVN3009'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7496',
//                           name: '1OVN30_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7438',
//                               name: '1OVN3006'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3809',
//                       name: 'TRAY_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5561',
//                           name: '1ATF01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5413',
//                               name: '1ATF0101'
//                             },
//                             {
//                               id: 'P1_5414',
//                               name: '1ATF0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5831',
//                           name: '1ATF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5833',
//                               name: '1ATF0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4361',
//                       name: 'MANUAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4684',
//                           name: '1MTE01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1370',
//                               name: '1MTE0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4362',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2040',
//                           name: '1LYT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1645',
//                               name: '1LYT0105'
//                             },
//                             {
//                               id: 'P1_2104',
//                               name: '1LYT0112'
//                             },
//                             {
//                               id: 'P1_2106',
//                               name: '1LYT0118'
//                             },
//                             {
//                               id: 'P1_2107',
//                               name: '1LYT0117'
//                             },
//                             {
//                               id: 'P1_2109',
//                               name: '1LYT0116'
//                             },
//                             {
//                               id: 'P1_3665',
//                               name: '1LYT0125'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5279',
//                       name: 'LAMINATION_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4363',
//                           name: '1MTR02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4364',
//                               name: '1MTR0211'
//                             },
//                             {
//                               id: 'P1_4365',
//                               name: '1MTR0209'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6123',
//                       name: 'SUPP_DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6124',
//                           name: '1DTR03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6125',
//                               name: '1DTR0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6148',
//                       name: 'SUPP_MANUAL_PACKING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6149',
//                           name: '1MPA01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6150',
//                               name: '1MPA0101'
//                             },
//                             {
//                               id: 'P1_6151',
//                               name: '1MPA0102'
//                             },
//                             {
//                               id: 'P1_6695',
//                               name: '1MPA0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6157',
//                       name: 'MANUAL_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6158',
//                           name: '1MVI13_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6179',
//                               name: '1MVI1302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6650',
//                       name: 'SUPP_USM_AUTO_BLOWER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6868',
//                           name: '1ABW01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6652',
//                               name: '1ABW0101'
//                             },
//                             {
//                               id: 'P1_6653',
//                               name: '1ABW0102'
//                             },
//                             {
//                               id: 'P1_6654',
//                               name: '1ABW0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6705',
//                       name: 'SOLDER_PASTE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2072',
//                           name: '1SPT03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2151',
//                               name: '1SPT0304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6866',
//                           name: '1SPT04_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2150',
//                               name: '1SPT0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7139',
//                       name: 'SUPP_AIR_CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7140',
//                           name: '1PAC01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7141',
//                               name: '1PAC0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7247',
//                       name: 'FOIL_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7248',
//                           name: '1SFA01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7249',
//                               name: '1SFA0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7497',
//                           name: '1SFA02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7498',
//                               name: '1SFA0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8081',
//                       name: 'SUPP_MIELE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2074',
//                           name: '1FXC03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2155',
//                               name: '1FXC0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2077',
//                           name: '1FXC01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2161',
//                               name: '1FXC0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3853',
//                           name: '1CLN01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3854',
//                               name: '1CLN0101'
//                             },
//                             {
//                               id: 'P1_3856',
//                               name: '1CLN0103'
//                             },
//                             {
//                               id: 'P1_5401',
//                               name: '1CLN0106'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5014',
//                           name: '1CLN01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4360',
//                               name: '1CLN0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8503',
//                           name: '1CLN02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8504',
//                               name: '1CLN0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8254',
//                       name: 'SUPP_FOIL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1385',
//                           name: '1FST01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3682',
//                               name: '1FST0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8814',
//                       name: 'TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8815',
//                           name: '1AST02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8816',
//                               name: '1AST0201'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1050',
//                   name: 'MF2 - POWERTOPLED LENS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_2909',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5943',
//                           name: '2CAS13_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5876',
//                               name: '2CAS1321'
//                             },
//                             {
//                               id: 'P1_5877',
//                               name: '2CAS1322'
//                             },
//                             {
//                               id: 'P1_7159',
//                               name: '2CAS1325'
//                             },
//                             {
//                               id: 'P1_7160',
//                               name: '2CAS1326'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2910',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7663',
//                           name: '2DAT13_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3230',
//                               name: '2DAT1315'
//                             },
//                             {
//                               id: 'P1_3231',
//                               name: '2DAT1316'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2913',
//                       name: 'LENS_BONDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4506',
//                           name: 'LENS_BONDING_M01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3505',
//                               name: '2LBD0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4507',
//                           name: 'LENS_BONDING_M02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3506',
//                               name: '2LBD0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4508',
//                           name: 'LENS_BONDING_M03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3507',
//                               name: '2LBD0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4509',
//                           name: 'LENS_BONDING_M04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3508',
//                               name: '2LBD0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4510',
//                           name: 'LENS_BONDING_M05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3509',
//                               name: '2LBD0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4511',
//                           name: 'LENS_BONDING_M06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3510',
//                               name: '2LBD0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6143',
//                           name: 'LENS_BONDING_M07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5457',
//                               name: '2LBD0202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7414',
//                           name: 'LENS_BONDING_M08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7278',
//                               name: '2LBD0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2917',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4072',
//                           name: '2FRB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2595',
//                               name: '2FRB0130'
//                             },
//                             {
//                               id: 'P1_2596',
//                               name: '2FRB0134'
//                             },
//                             {
//                               id: 'P1_2597',
//                               name: '2FRB0135'
//                             },
//                             {
//                               id: 'P1_2600',
//                               name: '2FRB0143'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5963',
//                           name: '2FRB03_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5603',
//                               name: '2FRB0322'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2921',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7664',
//                           name: '2WBD17_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3363',
//                               name: '2WBD1722'
//                             },
//                             {
//                               id: 'P1_3364',
//                               name: '2WBD1723'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4060',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4065',
//                           name: '2BST04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2562',
//                               name: '2BST0412'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4066',
//                           name: '2BST03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2538',
//                               name: '2BST0307'
//                             },
//                             {
//                               id: 'P1_2544',
//                               name: '2BST0315'
//                             },
//                             {
//                               id: 'P1_2548',
//                               name: '2BST0319'
//                             },
//                             {
//                               id: 'P1_2550',
//                               name: '2BST0321'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4061',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4068',
//                           name: '2SBB05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2669',
//                               name: '2SBB0506'
//                             },
//                             {
//                               id: 'P1_2672',
//                               name: '2SBB0513'
//                             },
//                             {
//                               id: 'P1_2674',
//                               name: '2SBB0516'
//                             },
//                             {
//                               id: 'P1_2675',
//                               name: '2SBB0517'
//                             },
//                             {
//                               id: 'P1_2676',
//                               name: '2SBB0518'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4069',
//                           name: '2SBB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2648',
//                               name: '2SBB0122'
//                             },
//                             {
//                               id: 'P1_2649',
//                               name: '2SBB0123'
//                             },
//                             {
//                               id: 'P1_7377',
//                               name: '2SBB0138'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4070',
//                           name: '2SBB04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2664',
//                               name: '2SBB0406'
//                             },
//                             {
//                               id: 'P1_2665',
//                               name: '2SBB0408'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6200',
//                           name: '2SBB11_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6176',
//                               name: '2SBB1101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4062',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4074',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2761',
//                               name: '2VRR0217'
//                             },
//                             {
//                               id: 'P1_2762',
//                               name: '2VRR0220'
//                             },
//                             {
//                               id: 'P1_2768',
//                               name: '2VRR0230'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5585',
//                           name: '2VRR02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5586',
//                               name: '2VRR0209'
//                             },
//                             {
//                               id: 'P1_5589',
//                               name: '2VRR0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4102',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4103',
//                           name: '2TBT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1533',
//                               name: '2TBT0430'
//                             },
//                             {
//                               id: 'P1_2470',
//                               name: '1TBT0406'
//                             },
//                             {
//                               id: 'P1_2698',
//                               name: '2TBT0408'
//                             },
//                             {
//                               id: 'P1_2699',
//                               name: '2TBT0410'
//                             },
//                             {
//                               id: 'P1_2700',
//                               name: '2TBT0411'
//                             },
//                             {
//                               id: 'P1_2701',
//                               name: '2TBT0412'
//                             },
//                             {
//                               id: 'P1_2711',
//                               name: '2TBT0433'
//                             },
//                             {
//                               id: 'P1_2712',
//                               name: '2TBT0434'
//                             },
//                             {
//                               id: 'P1_2713',
//                               name: '2TBT0435'
//                             },
//                             {
//                               id: 'P1_7303',
//                               name: '2TBT0444'
//                             },
//                             {
//                               id: 'P1_7532',
//                               name: '2TBT0445'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4104',
//                           name: '2TBT06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2719',
//                               name: '2TBT0606'
//                             },
//                             {
//                               id: 'P1_4075',
//                               name: '2TBT0613'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4161',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4162',
//                           name: '2OVN21_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3033',
//                               name: '2OVN2108'
//                             },
//                             {
//                               id: 'P1_3039',
//                               name: '2OVN2110'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7312',
//                           name: '2OVN01_E10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5632',
//                               name: '2OVN0126'
//                             },
//                             {
//                               id: 'P1_7313',
//                               name: '2OVN0132'
//                             },
//                             {
//                               id: 'P1_7598',
//                               name: '2OVN0134'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4163',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4353',
//                           name: '2OVN01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4196',
//                               name: '2OVN0150'
//                             },
//                             {
//                               id: 'P1_4242',
//                               name: '2OVN0173'
//                             },
//                             {
//                               id: 'P1_5638',
//                               name: '2OVN0106'
//                             },
//                             {
//                               id: 'P1_7503',
//                               name: '2OVN0149'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4382',
//                           name: '2OVN37_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4238',
//                               name: '2OVN3701'
//                             },
//                             {
//                               id: 'P1_4247',
//                               name: '2OVN3702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6069',
//                           name: '2OVN37_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5666',
//                               name: '2OVN3710'
//                             },
//                             {
//                               id: 'P1_5667',
//                               name: '2OVN3711'
//                             },
//                             {
//                               id: 'P1_5668',
//                               name: '2OVN3712'
//                             },
//                             {
//                               id: 'P1_5669',
//                               name: '2OVN3713'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4197',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4226',
//                           name: '2OVN01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4181',
//                               name: '2OVN0111'
//                             },
//                             {
//                               id: 'P1_4182',
//                               name: '2OVN0110'
//                             },
//                             {
//                               id: 'P1_4188',
//                               name: '2OVN0109'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7571',
//                           name: '2OVN19_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7572',
//                               name: '2OVN1901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4448',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4449',
//                           name: '2GBB03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4450',
//                               name: '2GBB0301'
//                             },
//                             {
//                               id: 'P1_4786',
//                               name: '2GBB0302'
//                             },
//                             {
//                               id: 'P1_4787',
//                               name: '2GBB0303'
//                             },
//                             {
//                               id: 'P1_4788',
//                               name: '2GBB0304'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5907',
//                       name: 'SUPP_AUTO_LENS_CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8493',
//                           name: '2ALC01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5909',
//                               name: '2ALC0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6100',
//                       name: 'DB_WB',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6101',
//                           name: 'DA_WB_S10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3269',
//                               name: '2AVI0303'
//                             },
//                             {
//                               id: 'P1_3424',
//                               name: '2DAT2028'
//                             },
//                             {
//                               id: 'P1_3425',
//                               name: '2DAT2029'
//                             },
//                             {
//                               id: 'P1_3428',
//                               name: '2DAT2047'
//                             },
//                             {
//                               id: 'P1_3429',
//                               name: '2DAT2048'
//                             },
//                             {
//                               id: 'P1_5250',
//                               name: '2WBD0725'
//                             },
//                             {
//                               id: 'P1_5251',
//                               name: '2WBD0726'
//                             },
//                             {
//                               id: 'P1_5252',
//                               name: '2WBD0727'
//                             },
//                             {
//                               id: 'P1_5253',
//                               name: '2WBD0728'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6132',
//                           name: 'DA_WB_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3268',
//                               name: '2AVI0302'
//                             },
//                             {
//                               id: 'P1_3453',
//                               name: '2DAT2072'
//                             },
//                             {
//                               id: 'P1_3454',
//                               name: '2DAT2073'
//                             },
//                             {
//                               id: 'P1_3455',
//                               name: '2DAT2074'
//                             },
//                             {
//                               id: 'P1_3456',
//                               name: '2DAT2075'
//                             },
//                             {
//                               id: 'P1_3539',
//                               name: '2WBD1418'
//                             },
//                             {
//                               id: 'P1_3540',
//                               name: '2WBD1419'
//                             },
//                             {
//                               id: 'P1_3541',
//                               name: '2WBD1420'
//                             },
//                             {
//                               id: 'P1_3542',
//                               name: '2WBD1421'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6133',
//                           name: 'DA_WB_S12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3270',
//                               name: '2AVI0304'
//                             },
//                             {
//                               id: 'P1_3433',
//                               name: '2DAT2052'
//                             },
//                             {
//                               id: 'P1_3434',
//                               name: '2DAT2053'
//                             },
//                             {
//                               id: 'P1_3435',
//                               name: '2DAT2054'
//                             },
//                             {
//                               id: 'P1_3436',
//                               name: '2DAT2055'
//                             },
//                             {
//                               id: 'P1_5254',
//                               name: '2WBD0729'
//                             },
//                             {
//                               id: 'P1_5255',
//                               name: '2WBD0730'
//                             },
//                             {
//                               id: 'P1_5256',
//                               name: '2WBD0731'
//                             },
//                             {
//                               id: 'P1_5257',
//                               name: '2WBD0732'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6134',
//                           name: 'DA_WB_S13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3437',
//                               name: '2DAT2056'
//                             },
//                             {
//                               id: 'P1_3438',
//                               name: '2DAT2057'
//                             },
//                             {
//                               id: 'P1_3439',
//                               name: '2DAT2058'
//                             },
//                             {
//                               id: 'P1_3440',
//                               name: '2DAT2059'
//                             },
//                             {
//                               id: 'P1_3537',
//                               name: '2WBD0308'
//                             },
//                             {
//                               id: 'P1_3538',
//                               name: '2WBD0309'
//                             },
//                             {
//                               id: 'P1_5940',
//                               name: '2AVI0305'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7381',
//                           name: 'DA_WB_S11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3187',
//                               name: '2DAT2012'
//                             },
//                             {
//                               id: 'P1_3188',
//                               name: '2DAT2013'
//                             },
//                             {
//                               id: 'P1_3203',
//                               name: '2DAT2101'
//                             },
//                             {
//                               id: 'P1_3204',
//                               name: '2DAT2102'
//                             },
//                             {
//                               id: 'P1_7239',
//                               name: '2WBD0751'
//                             },
//                             {
//                               id: 'P1_7240',
//                               name: '2WBD0752'
//                             },
//                             {
//                               id: 'P1_7241',
//                               name: '2WBD0753'
//                             },
//                             {
//                               id: 'P1_7242',
//                               name: '2WBD0754'
//                             },
//                             {
//                               id: 'P1_7263',
//                               name: '2AVI0313'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6783',
//                       name: 'SUPP_INLINE_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6784',
//                           name: '2OVN22_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3306',
//                               name: '2OVN2206'
//                             },
//                             {
//                               id: 'P1_3308',
//                               name: '2OVN2208'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6785',
//                           name: '2OVN25_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3310',
//                               name: '2OVN2502'
//                             },
//                             {
//                               id: 'P1_3311',
//                               name: '2OVN2503'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7387',
//                           name: '2OVN28_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7265',
//                               name: '2OVN2806'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8454',
//                           name: '2OVN21_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4165',
//                               name: '2OVN2118'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8317',
//                       name: 'LENS_DRYING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8318',
//                           name: '2OVN56_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8319',
//                               name: '2OVN5603'
//                             },
//                             {
//                               id: 'P1_8320',
//                               name: '2OVN5604'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8322',
//                           name: '2OVN58_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8323',
//                               name: '2OVN5801'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1099',
//                   name: 'MF2 - APM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1100',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2411',
//                           name: '2DES01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1136',
//                               name: '2DRYAPM'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1110',
//                   name: 'MF2 - STS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1398',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1412',
//                           name: '2DAT03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1434',
//                               name: '2DAT0307'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2402',
//                           name: '2DAT06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1429',
//                               name: '2DAT0602'
//                             },
//                             {
//                               id: 'P1_1430',
//                               name: '2DAT0603'
//                             },
//                             {
//                               id: 'P1_1432',
//                               name: '2DAT0605'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2403',
//                           name: '2DAT11_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1435',
//                               name: '2DAT1108'
//                             },
//                             {
//                               id: 'P1_1436',
//                               name: '2DAT1109'
//                             },
//                             {
//                               id: 'P1_2479',
//                               name: '2DAT1114'
//                             },
//                             {
//                               id: 'P1_4021',
//                               name: '2DAT1121'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1399',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1650',
//                           name: '2WBD05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2502',
//                               name: '2WBD0501'
//                             },
//                             {
//                               id: 'P1_2503',
//                               name: '2WBD0502'
//                             },
//                             {
//                               id: 'P1_2504',
//                               name: '2WBD0505'
//                             },
//                             {
//                               id: 'P1_2505',
//                               name: '2WBD0506'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_1851',
//                           name: '2WBD06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2499',
//                               name: '2WBD0607'
//                             },
//                             {
//                               id: 'P1_3273',
//                               name: '2WBD0611'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1400',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1408',
//                           name: '2CAS05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1477',
//                               name: '2CAS0502'
//                             },
//                             {
//                               id: 'P1_1480',
//                               name: '2CAS0505'
//                             },
//                             {
//                               id: 'P1_1481',
//                               name: '2CAS0506'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2406',
//                           name: '2CAS08_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1491',
//                               name: '2CAS0816'
//                             },
//                             {
//                               id: 'P1_3703',
//                               name: '2CAS0829'
//                             },
//                             {
//                               id: 'P1_3953',
//                               name: '2CAS0832'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4469',
//                           name: '2CAS03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4470',
//                               name: '2CAS0301'
//                             },
//                             {
//                               id: 'P1_4471',
//                               name: '2CAS0302'
//                             },
//                             {
//                               id: 'P1_4472',
//                               name: '2CAS0303'
//                             },
//                             {
//                               id: 'P1_4544',
//                               name: '2CAS0307'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1402',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1590',
//                           name: '2SBB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1419',
//                               name: '2SBB0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6682',
//                           name: '2SBB08_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5804',
//                               name: '2SBB0805'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1403',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1819',
//                           name: '2TBT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1420',
//                               name: '2TBT0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4641',
//                           name: '2TTT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2735',
//                               name: '2TTT0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1404',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5595',
//                           name: '2FRB02_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5596',
//                               name: '2FRB0207'
//                             },
//                             {
//                               id: 'P1_5597',
//                               name: '2FRB0208'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5598',
//                           name: '2FRB03_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5599',
//                               name: '2FRB0323'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1450',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3941',
//                           name: '2PLM07_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3518',
//                               name: '2PLM0701'
//                             },
//                             {
//                               id: 'P1_3942',
//                               name: '2PLM0706'
//                             },
//                             {
//                               id: 'P1_3944',
//                               name: '2PLM0708'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2905',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4808',
//                           name: '2OVN01_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3249',
//                               name: '2OVN0170'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5369',
//                           name: '2OVN14_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4439',
//                               name: '2OVN1415'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3035',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1773',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1518',
//                               name: '2VRR0207'
//                             },
//                             {
//                               id: 'P1_1519',
//                               name: '2VRR0205'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4606',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4608',
//                           name: '2MVI01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1468',
//                               name: '2MVI0109'
//                             },
//                             {
//                               id: 'P1_1469',
//                               name: '2MVI0110'
//                             },
//                             {
//                               id: 'P1_1470',
//                               name: '2MVI0111'
//                             },
//                             {
//                               id: 'P1_1473',
//                               name: '2MVI0114'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4655',
//                           name: '2AVI01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4451',
//                               name: '2AVI0120'
//                             },
//                             {
//                               id: 'P1_4452',
//                               name: '2AVI0121'
//                             },
//                             {
//                               id: 'P1_4453',
//                               name: '2AVI0122'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4607',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6557',
//                           name: '2AVI08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6496',
//                               name: '2AVI0802'
//                             },
//                             {
//                               id: 'P1_6596',
//                               name: '2AVI0803'
//                             },
//                             {
//                               id: 'P1_6597',
//                               name: '2AVI0804'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4653',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4654',
//                           name: '2BST01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1422',
//                               name: '2BST0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4764',
//                           name: '2TBT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1507',
//                               name: '2TBT0427'
//                             },
//                             {
//                               id: 'P1_1508',
//                               name: '2TBT0428'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5558',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5567',
//                           name: '2OVN37_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4383',
//                               name: '2OVN3703'
//                             },
//                             {
//                               id: 'P1_4385',
//                               name: '2OVN3705'
//                             },
//                             {
//                               id: 'P1_5287',
//                               name: '2OVN3706'
//                             },
//                             {
//                               id: 'P1_5288',
//                               name: '2OVN3707'
//                             },
//                             {
//                               id: 'P1_5289',
//                               name: '2OVN3708'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5568',
//                           name: '2OVN01_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4812',
//                               name: '2OVN0179'
//                             },
//                             {
//                               id: 'P1_4814',
//                               name: '2OVN0181'
//                             },
//                             {
//                               id: 'P1_4815',
//                               name: '2OVN0182'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5569',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5570',
//                           name: '2OVN01_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4809',
//                               name: '2OVN0176'
//                             },
//                             {
//                               id: 'P1_4810',
//                               name: '2OVN0177'
//                             },
//                             {
//                               id: 'P1_4811',
//                               name: '2OVN0178'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5571',
//                           name: '2OVN14_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5370',
//                               name: '2OVN1409'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5572',
//                           name: '2OVN37_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4384',
//                               name: '2OVN3704'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5620',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5861',
//                           name: '2OVN37_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5862',
//                               name: '2OVN3720'
//                             },
//                             {
//                               id: 'P1_5863',
//                               name: '2OVN3721'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5684',
//                       name: 'SUPP_GLUE_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5685',
//                           name: '2OVN14_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3250',
//                               name: '2OVN0171'
//                             },
//                             {
//                               id: 'P1_4438',
//                               name: '2OVN1414'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7853',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7854',
//                           name: '2OVN14_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3245',
//                               name: '2OVN1402'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1114',
//                   name: 'MF2 - SMT_POINTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_2897',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2946',
//                           name: '2OVN01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7575',
//                               name: '2OVN0137'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7576',
//                           name: '2OVN18_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7577',
//                               name: '2OVN1801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2899',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4969',
//                           name: '2FRB02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2608',
//                               name: '2FRB0206'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4970',
//                           name: '2FRB03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4726',
//                               name: '2FRB0318'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2902',
//                       name: 'SUPP_VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2957',
//                           name: '2MVI03_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3161',
//                               name: '2MVI0311'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2903',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2961',
//                           name: '2WBD17_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3366',
//                               name: '2WBD1725'
//                             },
//                             {
//                               id: 'P1_3367',
//                               name: '2WBD1726'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2962',
//                           name: '2WBD17_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3360',
//                               name: '2WBD1719'
//                             },
//                             {
//                               id: 'P1_3361',
//                               name: '2WBD1720'
//                             },
//                             {
//                               id: 'P1_3362',
//                               name: '2WBD1721'
//                             },
//                             {
//                               id: 'P1_3374',
//                               name: '2WBD1733'
//                             },
//                             {
//                               id: 'P1_3375',
//                               name: '2WBD1734'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2964',
//                           name: '2WBD19_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3390',
//                               name: '2WBD1909'
//                             },
//                             {
//                               id: 'P1_3391',
//                               name: '2WBD1910'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3206',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3209',
//                           name: '2DAT03_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3238',
//                               name: '2DAT0302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4112',
//                           name: '2DAT22_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4118',
//                               name: '2DAT2206'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3639',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3640',
//                           name: '2CAS13_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4686',
//                               name: '2CAS1316'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4090',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4121',
//                           name: '2SBB01_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4158',
//                               name: '2SBB0136'
//                             },
//                             {
//                               id: 'P1_4159',
//                               name: '2SBB0137'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5992',
//                           name: '2SBB08_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5670',
//                               name: '2SBB0804'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4573',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4601',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3031',
//                               name: '2OVN0104'
//                             },
//                             {
//                               id: 'P1_4186',
//                               name: '2OVN0121'
//                             },
//                             {
//                               id: 'P1_5311',
//                               name: '2OVN0197'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4574',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4610',
//                           name: '2OVN21_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4173',
//                               name: '2OVN2119'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6094',
//                           name: '2OVN21_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4011',
//                               name: '2OVN2140'
//                             },
//                             {
//                               id: 'P1_5645',
//                               name: '2OVN2144'
//                             },
//                             {
//                               id: 'P1_5646',
//                               name: '2OVN2145'
//                             },
//                             {
//                               id: 'P1_8512',
//                               name: '2OVN2128'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4689',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4987',
//                           name: '2VRR02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2767',
//                               name: '2VRR0225'
//                             },
//                             {
//                               id: 'P1_5587',
//                               name: '2VRR0212'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4757',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4980',
//                           name: '2TBT01_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4157',
//                               name: '2TBT0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4984',
//                           name: '2TBT07_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2729',
//                               name: '2TBT0709'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5066',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5067',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5072',
//                               name: '2OVN2005'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5077',
//                           name: '2OVN43_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5078',
//                               name: '2OVN4301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5700',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5704',
//                           name: '2CAS13_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3641',
//                               name: '2CAS1311'
//                             },
//                             {
//                               id: 'P1_4348',
//                               name: '2CAS1315'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7652',
//                       name: 'SUPP_VACUUM_SEALER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7653',
//                           name: '2VUS05_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7855',
//                               name: '2VUS0510'
//                             },
//                             {
//                               id: 'P1_7856',
//                               name: '2VUS0511'
//                             },
//                             {
//                               id: 'P1_7857',
//                               name: '2VUS0512'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1141',
//                   name: 'MF1 - OSLON NON-SSL_GBL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1143',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1145',
//                           name: '1DES01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1152',
//                               name: '1DES0106'
//                             },
//                             {
//                               id: 'P1_1380',
//                               name: '1DES0108'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2408',
//                           name: '1DES03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1147',
//                               name: '1DES0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5964',
//                           name: '1DES06_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5965',
//                               name: '1DES0605'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8138',
//                           name: '1DES02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1148',
//                               name: '1DES0202'
//                             },
//                             {
//                               id: 'P1_1149',
//                               name: '1DES0204'
//                             },
//                             {
//                               id: 'P1_1150',
//                               name: '1DES0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8251',
//                           name: '1DES04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1381',
//                               name: '1DES0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1178',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1179',
//                           name: '1SFB02_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1183',
//                               name: '1SFB0204'
//                             },
//                             {
//                               id: 'P1_1393',
//                               name: '1SFB0201'
//                             },
//                             {
//                               id: 'P1_3676',
//                               name: '1SFB0224'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1197',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1866',
//                           name: '1TBT04_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1208',
//                               name: '1TBT0402'
//                             },
//                             {
//                               id: 'P1_4199',
//                               name: '1TBT0409'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1684',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2339',
//                           name: '1DAT05_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1695',
//                               name: '1DAT0520'
//                             },
//                             {
//                               id: 'P1_1698',
//                               name: '1DAT0541'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7958',
//                           name: '1DAT05_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2234',
//                               name: '1DAT0540'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1707',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1708',
//                           name: '1WBD04_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1882',
//                               name: '1WBD0421'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1892',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2229',
//                           name: '1AVT01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3793',
//                               name: '1AVT0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2002',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1596',
//                           name: '1CMD01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2021',
//                               name: '1CMD0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2277',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5484',
//                           name: '1OVN15_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6024',
//                               name: '1OVN1532'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6546',
//                           name: '1OVN01_F12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2431',
//                               name: '1OVN0180'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7959',
//                           name: '1OVN01_E11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5210',
//                               name: '1OVN0125'
//                             },
//                             {
//                               id: 'P1_5212',
//                               name: '1OVN0146'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2461',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2467',
//                           name: '1SAW03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2268',
//                               name: '1SAW0328'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6255',
//                       name: 'MANUAL_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6254',
//                           name: '1MVI03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6259',
//                               name: '1MVI0315'
//                             },
//                             {
//                               id: 'P1_6260',
//                               name: '1MVI0316'
//                             },
//                             {
//                               id: 'P1_6261',
//                               name: '1MVI0317'
//                             },
//                             {
//                               id: 'P1_6262',
//                               name: '1MVI0318'
//                             },
//                             {
//                               id: 'P1_6265',
//                               name: '1MVI0321'
//                             },
//                             {
//                               id: 'P1_6266',
//                               name: '1MVI0322'
//                             },
//                             {
//                               id: 'P1_6642',
//                               name: '1MVI0333'
//                             },
//                             {
//                               id: 'P1_6692',
//                               name: '1MVI0334'
//                             },
//                             {
//                               id: 'P1_6693',
//                               name: '1MVI0335'
//                             },
//                             {
//                               id: 'P1_6694',
//                               name: '1MVI0336'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6771',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6924',
//                           name: '1MVI01_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2197',
//                               name: '1MVI0126'
//                             },
//                             {
//                               id: 'P1_2206',
//                               name: '1MVI0115'
//                             },
//                             {
//                               id: 'P1_2457',
//                               name: '1MVI0130'
//                             },
//                             {
//                               id: 'P1_6010',
//                               name: '1MVI0135'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1574',
//                   name: 'MF2 - QCAST 4014',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1414',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2876',
//                           name: '2AVI02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3096',
//                               name: '2AVI0207'
//                             },
//                             {
//                               id: 'P1_3097',
//                               name: '2AVI0208'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1575',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1577',
//                           name: '2DES04_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1579',
//                               name: '2DES0408'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7388',
//                           name: '2DES01_T08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1079',
//                               name: '2DES0116'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1712',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1273',
//                           name: '2SBB07_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4232',
//                               name: '2SBB0708'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2291',
//                           name: '2SBB08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1528',
//                               name: '2SBB0801'
//                             },
//                             {
//                               id: 'P1_1529',
//                               name: '2SBB0802'
//                             },
//                             {
//                               id: 'P1_1530',
//                               name: '2SBB0803'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2292',
//                           name: '2SBB04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1527',
//                               name: '2SBB0414'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2295',
//                           name: '2SBB01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7464',
//                               name: '2SBB0139'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1714',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2293',
//                           name: '2TBT09_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1532',
//                               name: '2TBT0901'
//                             },
//                             {
//                               id: 'P1_1534',
//                               name: '2TBT0902'
//                             },
//                             {
//                               id: 'P1_1535',
//                               name: '2TBT0903'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2294',
//                           name: '2TBT04_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6550',
//                               name: '2TBT0443'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2296',
//                           name: '2TBT02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1536',
//                               name: '2TBT0220'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5796',
//                           name: '2TBT08_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4769',
//                               name: '2TBT0806'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2869',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2877',
//                           name: '2DAT11_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3066',
//                               name: '2DAT1101'
//                             },
//                             {
//                               id: 'P1_3067',
//                               name: '2DAT1102'
//                             },
//                             {
//                               id: 'P1_3070',
//                               name: '2DAT1105'
//                             },
//                             {
//                               id: 'P1_3071',
//                               name: '2DAT1106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2870',
//                       name: 'SUPP_FREEZER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2878',
//                           name: '2FRZ04_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3079',
//                               name: '2FRZ0402'
//                             },
//                             {
//                               id: 'P1_3080',
//                               name: '2FRZ0403'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2871',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2880',
//                           name: '2OVN14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3074',
//                               name: '2OVN1404'
//                             },
//                             {
//                               id: 'P1_3075',
//                               name: '2OVN1405'
//                             },
//                             {
//                               id: 'P1_3076',
//                               name: '2OVN1406'
//                             },
//                             {
//                               id: 'P1_3077',
//                               name: '2OVN1407'
//                             },
//                             {
//                               id: 'P1_3078',
//                               name: '2OVN1408'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4745',
//                           name: '2OVN01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4746',
//                               name: '2OVN0175'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2872',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1035',
//                           name: '2PLM06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2496',
//                               name: '2PLM0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5956',
//                           name: '2PLM04_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6685',
//                               name: '2PLM0403'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2873',
//                       name: 'SUPP_SPC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2883',
//                           name: '2SPC01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3081',
//                               name: '2SPC0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2875',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2885',
//                           name: '2WBD14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3090',
//                               name: '2WBD1406'
//                             },
//                             {
//                               id: 'P1_3092',
//                               name: '2WBD1408'
//                             },
//                             {
//                               id: 'P1_3093',
//                               name: '2WBD1409'
//                             },
//                             {
//                               id: 'P1_3094',
//                               name: '2WBD1410'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3693',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2383',
//                           name: '2DTR01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3696',
//                               name: '2DTR0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4139',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4140',
//                           name: '2CAS08_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1500',
//                               name: '2CAS0824'
//                             },
//                             {
//                               id: 'P1_1502',
//                               name: '2CAS0826'
//                             },
//                             {
//                               id: 'P1_1503',
//                               name: '2CAS0827'
//                             },
//                             {
//                               id: 'P1_1504',
//                               name: '2CAS0828'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5018',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5019',
//                           name: '2GBB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2442',
//                               name: '2GBB0102'
//                             },
//                             {
//                               id: 'P1_3562',
//                               name: '2GBB0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5761',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5762',
//                           name: '2OVN14_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4214',
//                               name: '2OVN1413'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6601',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7376',
//                           name: '1SAW03_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3869',
//                               name: '1SAW0339'
//                             },
//                             {
//                               id: 'P1_3910',
//                               name: '1SAW0309'
//                             },
//                             {
//                               id: 'P1_3913',
//                               name: '1SAW0337'
//                             },
//                             {
//                               id: 'P1_3914',
//                               name: '1SAW0338'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7681',
//                           name: '1SAW04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3850',
//                               name: '1SAW0411'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7382',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7383',
//                           name: '2VRR02_T09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2759',
//                               name: '2VRR0210'
//                             },
//                             {
//                               id: 'P1_2770',
//                               name: '2VRR0232'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7567',
//                       name: 'LENS_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7568',
//                           name: '1LMT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1871',
//                               name: '1LMT0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7592',
//                       name: 'LAMINATION_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7680',
//                           name: '1MTR02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7198',
//                               name: '1MTR0212'
//                             },
//                             {
//                               id: 'P1_7594',
//                               name: '1MTR0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7595',
//                       name: 'SEDIMENTATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7596',
//                           name: '1PCS01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7597',
//                               name: '2PCS0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8420',
//                       name: 'SUPP_THAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8421',
//                           name: '2THW01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3083',
//                               name: '2THW0104'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1581',
//                   name: 'MF1 - OBF AUTO_OBF',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1319',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1771',
//                           name: '1SBB04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1669',
//                               name: '1SBB0401'
//                             },
//                             {
//                               id: 'P1_1670',
//                               name: '1SBB0402'
//                             },
//                             {
//                               id: 'P1_3668',
//                               name: '1SBB0403'
//                             },
//                             {
//                               id: 'P1_3669',
//                               name: '1SBB0404'
//                             },
//                             {
//                               id: 'P1_3670',
//                               name: '1SBB0405'
//                             },
//                             {
//                               id: 'P1_3671',
//                               name: '1SBB0406'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6076',
//                           name: '1SBB07_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6077',
//                               name: '1SBB0709'
//                             },
//                             {
//                               id: 'P1_6443',
//                               name: '1SBB0710'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1321',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2003',
//                           name: '1TBT06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1671',
//                               name: '1TBT0603'
//                             },
//                             {
//                               id: 'P1_1673',
//                               name: '1TBT0604'
//                             },
//                             {
//                               id: 'P1_3673',
//                               name: '1TBT0601'
//                             },
//                             {
//                               id: 'P1_3675',
//                               name: '1TBT0606'
//                             },
//                             {
//                               id: 'P1_3755',
//                               name: '1TBT0607'
//                             },
//                             {
//                               id: 'P1_3756',
//                               name: '1TBT0608'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6074',
//                           name: '1TBT08_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6075',
//                               name: '1TBT0814'
//                             },
//                             {
//                               id: 'P1_6444',
//                               name: '1TBT0815'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1543',
//                       name: 'SUPP_OVEN_DRYBAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6578',
//                           name: '1OVN15_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5226',
//                               name: '1OVN0178'
//                             },
//                             {
//                               id: 'P1_6579',
//                               name: '1OVN1540'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6923',
//                           name: '1OVN01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6644',
//                               name: '1OVN0132'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1583',
//                       name: 'WIRE_BOND_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1854',
//                           name: '1WBD06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1612',
//                               name: '1WBD0632'
//                             },
//                             {
//                               id: 'P1_1617',
//                               name: '1WBD0602'
//                             },
//                             {
//                               id: 'P1_1618',
//                               name: '1WBD0604'
//                             },
//                             {
//                               id: 'P1_1658',
//                               name: '1WBD0607'
//                             },
//                             {
//                               id: 'P1_1659',
//                               name: '1WBD0635'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4325',
//                           name: '1WBD07_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3967',
//                               name: '1WBD0728'
//                             },
//                             {
//                               id: 'P1_6196',
//                               name: '1WBD0750'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1584',
//                       name: 'PLASMA_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2314',
//                           name: '1PLM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1621',
//                               name: '1PLM0101'
//                             },
//                             {
//                               id: 'P1_3834',
//                               name: '1PLM0117'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1585',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2362',
//                           name: '1MVI03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2188',
//                               name: '1MVI0306'
//                             },
//                             {
//                               id: 'P1_2363',
//                               name: '1MVI0311'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6091',
//                           name: '1AVT02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6093',
//                               name: '1AVT0211'
//                             },
//                             {
//                               id: 'P1_6522',
//                               name: '1AVT0214'
//                             },
//                             {
//                               id: 'P1_7209',
//                               name: '1AVT0215'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1586',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1595',
//                           name: '1CAS03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1631',
//                               name: '1CAS0309'
//                             },
//                             {
//                               id: 'P1_2045',
//                               name: '1CAS0313'
//                             },
//                             {
//                               id: 'P1_2046',
//                               name: '1CAS0312'
//                             },
//                             {
//                               id: 'P1_2047',
//                               name: '1CAS0314'
//                             },
//                             {
//                               id: 'P1_2359',
//                               name: '1CAS0315'
//                             },
//                             {
//                               id: 'P1_2360',
//                               name: '1CAS0316'
//                             },
//                             {
//                               id: 'P1_3632',
//                               name: '1CAS0317'
//                             },
//                             {
//                               id: 'P1_3633',
//                               name: '1CAS0318'
//                             },
//                             {
//                               id: 'P1_3764',
//                               name: '1CAS0319'
//                             },
//                             {
//                               id: 'P1_3765',
//                               name: '1CAS0320'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3634',
//                           name: '1CAS04_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3635',
//                               name: '1CAS0422'
//                             },
//                             {
//                               id: 'P1_3636',
//                               name: '1CAS0423'
//                             },
//                             {
//                               id: 'P1_5881',
//                               name: '1CAS0445'
//                             },
//                             {
//                               id: 'P1_5884',
//                               name: '1CAS0447'
//                             },
//                             {
//                               id: 'P1_6739',
//                               name: '1CAS0450'
//                             },
//                             {
//                               id: 'P1_7175',
//                               name: '1CAS0451'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1587',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2043',
//                           name: '1LYT02_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2285',
//                               name: '1LYT0221'
//                             },
//                             {
//                               id: 'P1_2286',
//                               name: '1LYT0222'
//                             },
//                             {
//                               id: 'P1_3630',
//                               name: '1LYT0223'
//                             },
//                             {
//                               id: 'P1_3631',
//                               name: '1LYT0224'
//                             },
//                             {
//                               id: 'P1_3662',
//                               name: '1LYT0226'
//                             },
//                             {
//                               id: 'P1_6742',
//                               name: '1LYT0242'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2313',
//                           name: '1LYT01_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1639',
//                               name: '1LYT0104'
//                             },
//                             {
//                               id: 'P1_1640',
//                               name: '1LYT0111'
//                             },
//                             {
//                               id: 'P1_1641',
//                               name: '1LYT0115'
//                             },
//                             {
//                               id: 'P1_1642',
//                               name: '1LYT0108'
//                             },
//                             {
//                               id: 'P1_1646',
//                               name: '1LYT0120'
//                             },
//                             {
//                               id: 'P1_1988',
//                               name: '1LYT0109'
//                             },
//                             {
//                               id: 'P1_2287',
//                               name: '1LYT0121'
//                             },
//                             {
//                               id: 'P1_2468',
//                               name: '1LYT0124'
//                             },
//                             {
//                               id: 'P1_6011',
//                               name: '1LYT0128'
//                             },
//                             {
//                               id: 'P1_6012',
//                               name: '1LYT0129'
//                             },
//                             {
//                               id: 'P1_6102',
//                               name: '1LYT0103'
//                             },
//                             {
//                               id: 'P1_6580',
//                               name: '1LYT0130'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6189',
//                           name: '1LYT03_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6743',
//                               name: '1LYT0315'
//                             },
//                             {
//                               id: 'P1_6744',
//                               name: '1LYT0316'
//                             },
//                             {
//                               id: 'P1_7456',
//                               name: '1LYT0317'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2022',
//                       name: 'US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2023',
//                           name: '1USM03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2027',
//                               name: '1USM0306'
//                             },
//                             {
//                               id: 'P1_2028',
//                               name: '1USM0307'
//                             },
//                             {
//                               id: 'P1_2133',
//                               name: '1USM0303'
//                             },
//                             {
//                               id: 'P1_2369',
//                               name: '1USM0308'
//                             },
//                             {
//                               id: 'P1_2370',
//                               name: '1USM0309'
//                             },
//                             {
//                               id: 'P1_3637',
//                               name: '1USM0310'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2321',
//                           name: '1USM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2024',
//                               name: '1USM0102'
//                             },
//                             {
//                               id: 'P1_2025',
//                               name: '1USM0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5548',
//                           name: '1USM05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5549',
//                               name: '1USM0501'
//                             },
//                             {
//                               id: 'P1_5560',
//                               name: '1USM0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6235',
//                           name: '1USM06_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5557',
//                               name: '1USM0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2329',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2393',
//                           name: '1DAT08_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2101',
//                               name: '1DAT0805'
//                             },
//                             {
//                               id: 'P1_2394',
//                               name: '1DAT0809'
//                             },
//                             {
//                               id: 'P1_2395',
//                               name: '1DAT0810'
//                             },
//                             {
//                               id: 'P1_2396',
//                               name: '1DAT0811'
//                             },
//                             {
//                               id: 'P1_2397',
//                               name: '1DAT0812'
//                             },
//                             {
//                               id: 'P1_3653',
//                               name: '1DAT0817'
//                             },
//                             {
//                               id: 'P1_3832',
//                               name: '1DAT0823'
//                             },
//                             {
//                               id: 'P1_4796',
//                               name: '1DAT0833'
//                             },
//                             {
//                               id: 'P1_5924',
//                               name: '1DAT0842'
//                             },
//                             {
//                               id: 'P1_5925',
//                               name: '1DAT0843'
//                             },
//                             {
//                               id: 'P1_6000',
//                               name: '1DAT0845'
//                             },
//                             {
//                               id: 'P1_6763',
//                               name: '1DAT0851'
//                             },
//                             {
//                               id: 'P1_8620',
//                               name: '1DAT0860'
//                             },
//                             {
//                               id: 'P1_8675',
//                               name: '1DAT0861'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2460',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3807',
//                           name: '1SAW04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3826',
//                               name: '1SAW0406'
//                             },
//                             {
//                               id: 'P1_3849',
//                               name: '1SAW0412'
//                             },
//                             {
//                               id: 'P1_3866',
//                               name: '1SAW0413'
//                             },
//                             {
//                               id: 'P1_3867',
//                               name: '1SAW0414'
//                             },
//                             {
//                               id: 'P1_3870',
//                               name: '1SAW0415'
//                             },
//                             {
//                               id: 'P1_6440',
//                               name: '1SAW0422'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3759',
//                       name: 'LASER_MARK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3760',
//                           name: '1SLM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3761',
//                               name: '1SLM0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4374',
//                           name: '1SLM02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3922',
//                               name: '1SLM0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4468',
//                           name: '1SLM03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3923',
//                               name: '1SLM0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6732',
//                           name: '1SLM04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6733',
//                               name: '1SLM0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3845',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3660',
//                           name: '1TMD03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3722',
//                               name: '1TMD0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6072',
//                           name: '1TMD04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6073',
//                               name: '1TMD0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3970',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1592',
//                           name: '1PLM01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1623',
//                               name: '1PLM0107'
//                             },
//                             {
//                               id: 'P1_2358',
//                               name: '1PLM0115'
//                             },
//                             {
//                               id: 'P1_3839',
//                               name: '1PLM0116'
//                             },
//                             {
//                               id: 'P1_4250',
//                               name: '1PLM0119'
//                             },
//                             {
//                               id: 'P1_6006',
//                               name: '1PLM0120'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2315',
//                           name: '1PLM04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1622',
//                               name: '1PLM0402'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6270',
//                           name: '1PLM13_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6271',
//                               name: '1PLM1301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6441',
//                           name: '1PLM12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6442',
//                               name: '1PLM1201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4366',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4367',
//                           name: '1GBB01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4333',
//                               name: '1GBB0105'
//                             },
//                             {
//                               id: 'P1_4369',
//                               name: '1GBB0102'
//                             },
//                             {
//                               id: 'P1_4370',
//                               name: '1GBB0104'
//                             },
//                             {
//                               id: 'P1_6792',
//                               name: '1GBB0110'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4487',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4488',
//                           name: '1DTR06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4783',
//                               name: '1DTR0601'
//                             },
//                             {
//                               id: 'P1_4784',
//                               name: '1DTR0602'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5428',
//                       name: 'BACKSIDE_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5429',
//                           name: '1BTG01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6786',
//                               name: '1BTG0103'
//                             },
//                             {
//                               id: 'P1_7180',
//                               name: '1BTG0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5505',
//                       name: 'SUPP_SEDIMENTATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5506',
//                           name: '1PCS02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5640',
//                               name: '1PCS0201'
//                             },
//                             {
//                               id: 'P1_5641',
//                               name: '1PCS0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6215',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6216',
//                           name: '1AVI02_F10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1625',
//                               name: '1AVI0213'
//                             },
//                             {
//                               id: 'P1_1667',
//                               name: '1AVI0210'
//                             },
//                             {
//                               id: 'P1_2361',
//                               name: '1AVI0214'
//                             },
//                             {
//                               id: 'P1_3643',
//                               name: '1AVI0215'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6218',
//                           name: '1AVI02_F11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5981',
//                               name: '1AVI0236'
//                             },
//                             {
//                               id: 'P1_5982',
//                               name: '1AVI0237'
//                             },
//                             {
//                               id: 'P1_6800',
//                               name: '1AVI0242'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6417',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6416',
//                           name: '1AVI02_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5433',
//                               name: '1AVI0222'
//                             },
//                             {
//                               id: 'P1_5434',
//                               name: '1AVI0223'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6419',
//                       name: 'PRETIO2',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6420',
//                           name: '1AVI02_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3644',
//                               name: '1AVI0216'
//                             },
//                             {
//                               id: 'P1_3645',
//                               name: '1AVI0217'
//                             },
//                             {
//                               id: 'P1_4445',
//                               name: '1AVI0220'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6422',
//                           name: '1AVI02_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5983',
//                               name: '1AVI0238'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6563',
//                       name: 'SUPP_USM_AUTO_BLOWER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6564',
//                           name: '1AWB02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6565',
//                               name: '1AWB0201'
//                             },
//                             {
//                               id: 'P1_6566',
//                               name: '1AWB0202'
//                             },
//                             {
//                               id: 'P1_6884',
//                               name: '1AWB0203'
//                             },
//                             {
//                               id: 'P1_6885',
//                               name: '1AWB0204'
//                             },
//                             {
//                               id: 'P1_7238',
//                               name: '1AWB0205'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6715',
//                       name: 'DIE_ATTACH_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6717',
//                           name: '1DAT05_F13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1599',
//                               name: '1DAT0537'
//                             },
//                             {
//                               id: 'P1_1600',
//                               name: '1DAT0524'
//                             },
//                             {
//                               id: 'P1_3648',
//                               name: '1DAT0562'
//                             },
//                             {
//                               id: 'P1_5886',
//                               name: '1DAT0588'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6716',
//                       name: 'DIE_ATTACH_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2311',
//                           name: '1DAT05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1603',
//                               name: '1DAT0542'
//                             },
//                             {
//                               id: 'P1_3649',
//                               name: '1DAT0563'
//                             },
//                             {
//                               id: 'P1_3763',
//                               name: '1DAT0567'
//                             },
//                             {
//                               id: 'P1_3781',
//                               name: '1DAT0568'
//                             },
//                             {
//                               id: 'P1_4204',
//                               name: '1DAT0572'
//                             },
//                             {
//                               id: 'P1_4205',
//                               name: '1DAT0573'
//                             },
//                             {
//                               id: 'P1_4211',
//                               name: '1DAT0575'
//                             },
//                             {
//                               id: 'P1_5882',
//                               name: '1DAT0586'
//                             },
//                             {
//                               id: 'P1_5885',
//                               name: '1DAT0587'
//                             },
//                             {
//                               id: 'P1_5889',
//                               name: '1DAT0591'
//                             },
//                             {
//                               id: 'P1_5890',
//                               name: '1DAT0592'
//                             },
//                             {
//                               id: 'P1_6136',
//                               name: '1DAT0594'
//                             },
//                             {
//                               id: 'P1_6761',
//                               name: '1DAT2701'
//                             },
//                             {
//                               id: 'P1_6764',
//                               name: '1DAT2703'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6775',
//                       name: 'SUPP_OVEN_MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2322',
//                           name: '1OVN15_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4356',
//                               name: '1OVN1515'
//                             },
//                             {
//                               id: 'P1_4357',
//                               name: '1OVN1516'
//                             },
//                             {
//                               id: 'P1_4358',
//                               name: '1OVN1517'
//                             },
//                             {
//                               id: 'P1_4359',
//                               name: '1OVN1518'
//                             },
//                             {
//                               id: 'P1_4553',
//                               name: '1OVN1523'
//                             },
//                             {
//                               id: 'P1_4554',
//                               name: '1OVN1524'
//                             },
//                             {
//                               id: 'P1_6272',
//                               name: '1OVN1537'
//                             },
//                             {
//                               id: 'P1_6273',
//                               name: '1OVN1538'
//                             },
//                             {
//                               id: 'P1_6274',
//                               name: '1OVN1539'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6776',
//                       name: 'SUPP_OVEN_US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6177',
//                           name: '1OVN15_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2323',
//                               name: '1OVN1509'
//                             },
//                             {
//                               id: 'P1_6015',
//                               name: '1OVN1529'
//                             },
//                             {
//                               id: 'P1_6016',
//                               name: '1OVN1530'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6777',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6267',
//                           name: '1OVN46_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6268',
//                               name: '1OVN4602'
//                             },
//                             {
//                               id: 'P1_6269',
//                               name: '1OVN4603'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6632',
//                           name: '2OVN17_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2810',
//                               name: '2OVN1701'
//                             },
//                             {
//                               id: 'P1_2811',
//                               name: '2OVN1702'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6778',
//                       name: 'SUPP_OVEN_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2432',
//                           name: '1OVN06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2435',
//                               name: '1OVN0698'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_2434',
//                           name: '1OVN15_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2384',
//                               name: '1OVN1508'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6191',
//                           name: '1OVN15_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6192',
//                               name: '1OVN1535'
//                             },
//                             {
//                               id: 'P1_6193',
//                               name: '1OVN1536'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6276',
//                           name: '1OVN46_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6022',
//                               name: '1OVN4601'
//                             },
//                             {
//                               id: 'P1_6277',
//                               name: '1OVN4604'
//                             },
//                             {
//                               id: 'P1_6278',
//                               name: '1OVN4605'
//                             },
//                             {
//                               id: 'P1_7179',
//                               name: '1OVN4606'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7266',
//                       name: 'WIRE_BOND_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2364',
//                           name: '1WBD07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2365',
//                               name: '1WBD0703'
//                             },
//                             {
//                               id: 'P1_2368',
//                               name: '1WBD0706'
//                             },
//                             {
//                               id: 'P1_3651',
//                               name: '1WBD0713'
//                             },
//                             {
//                               id: 'P1_5711',
//                               name: '1WBD0745'
//                             },
//                             {
//                               id: 'P1_6463',
//                               name: '1WBD0751'
//                             },
//                             {
//                               id: 'P1_8797',
//                               name: '1WBD0767'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7314',
//                       name: 'MVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6536',
//                           name: '1MVI03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6524',
//                               name: '1MVI0323'
//                             },
//                             {
//                               id: 'P1_6526',
//                               name: '1MVI0324'
//                             },
//                             {
//                               id: 'P1_6527',
//                               name: '1MVI0325'
//                             },
//                             {
//                               id: 'P1_6528',
//                               name: '1MVI0326'
//                             },
//                             {
//                               id: 'P1_6529',
//                               name: '1MVI0327'
//                             },
//                             {
//                               id: 'P1_6531',
//                               name: '1MVI0329'
//                             },
//                             {
//                               id: 'P1_6532',
//                               name: '1MVI0330'
//                             },
//                             {
//                               id: 'P1_6533',
//                               name: '1MVI0331'
//                             },
//                             {
//                               id: 'P1_7306',
//                               name: '1MVI0337'
//                             },
//                             {
//                               id: 'P1_7307',
//                               name: '1MVI0338'
//                             },
//                             {
//                               id: 'P1_7430',
//                               name: '1MVI0328'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8808',
//                       name: 'SUPP_MAGAZINE CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8809',
//                           name: '1MGC01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8810',
//                               name: '1MGC0102'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_1678',
//                   name: 'MF2 - QPER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_1006',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2477',
//                           name: '2CAS08_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1488',
//                               name: '2CAS0813'
//                             },
//                             {
//                               id: 'P1_1497',
//                               name: '2CAS0821'
//                             },
//                             {
//                               id: 'P1_1499',
//                               name: '2CAS0823'
//                             },
//                             {
//                               id: 'P1_2478',
//                               name: '2CAS0801'
//                             },
//                             {
//                               id: 'P1_3949',
//                               name: '2CAS0806'
//                             },
//                             {
//                               id: 'P1_3950',
//                               name: '2CAS0807'
//                             },
//                             {
//                               id: 'P1_3954',
//                               name: '2CAS0833'
//                             },
//                             {
//                               id: 'P1_3955',
//                               name: '2CAS0834'
//                             },
//                             {
//                               id: 'P1_3956',
//                               name: '2CAS0835'
//                             },
//                             {
//                               id: 'P1_3957',
//                               name: '2CAS0836'
//                             },
//                             {
//                               id: 'P1_3958',
//                               name: '2CAS0837'
//                             },
//                             {
//                               id: 'P1_3959',
//                               name: '2CAS0838'
//                             },
//                             {
//                               id: 'P1_3960',
//                               name: '2CAS0839'
//                             },
//                             {
//                               id: 'P1_3962',
//                               name: '2CAS0840'
//                             },
//                             {
//                               id: 'P1_3963',
//                               name: '2CAS0841'
//                             },
//                             {
//                               id: 'P1_3964',
//                               name: '2CAS0842'
//                             },
//                             {
//                               id: 'P1_4571',
//                               name: '2CAS0846'
//                             },
//                             {
//                               id: 'P1_4736',
//                               name: '2CAS0848'
//                             },
//                             {
//                               id: 'P1_5756',
//                               name: '2CAS0849'
//                             },
//                             {
//                               id: 'P1_5757',
//                               name: '2CAS0850'
//                             },
//                             {
//                               id: 'P1_5758',
//                               name: '2CAS0851'
//                             },
//                             {
//                               id: 'P1_5759',
//                               name: '2CAS0852'
//                             },
//                             {
//                               id: 'P1_6515',
//                               name: '2CAS0853'
//                             },
//                             {
//                               id: 'P1_6516',
//                               name: '2CAS0854'
//                             },
//                             {
//                               id: 'P1_6517',
//                               name: '2CAS0855'
//                             },
//                             {
//                               id: 'P1_6518',
//                               name: '2CAS0856'
//                             },
//                             {
//                               id: 'P1_6519',
//                               name: '2CAS0857'
//                             },
//                             {
//                               id: 'P1_6670',
//                               name: '2CAS0858'
//                             },
//                             {
//                               id: 'P1_6671',
//                               name: '2CAS0859'
//                             },
//                             {
//                               id: 'P1_6672',
//                               name: '2CAS0860'
//                             },
//                             {
//                               id: 'P1_6673',
//                               name: '2CAS0861'
//                             },
//                             {
//                               id: 'P1_6674',
//                               name: '2CAS0862'
//                             },
//                             {
//                               id: 'P1_6675',
//                               name: '2CAS0863'
//                             },
//                             {
//                               id: 'P1_6676',
//                               name: '2CAS0864'
//                             },
//                             {
//                               id: 'P1_6677',
//                               name: '2CAS0865'
//                             },
//                             {
//                               id: 'P1_8628',
//                               name: '2CAS0868'
//                             },
//                             {
//                               id: 'P1_8629',
//                               name: '2CAS0869'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3752',
//                           name: '1CAS04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1629',
//                               name: '1CAS0418'
//                             },
//                             {
//                               id: 'P1_8700',
//                               name: '1CAS0455'
//                             },
//                             {
//                               id: 'P1_8701',
//                               name: '1CAS0456'
//                             },
//                             {
//                               id: 'P1_8702',
//                               name: '1CAS0457'
//                             },
//                             {
//                               id: 'P1_8703',
//                               name: '1CAS0458'
//                             },
//                             {
//                               id: 'P1_8704',
//                               name: '1CAS0459'
//                             },
//                             {
//                               id: 'P1_8705',
//                               name: '1CAS0460'
//                             },
//                             {
//                               id: 'P1_8706',
//                               name: '1CAS0461'
//                             },
//                             {
//                               id: 'P1_8707',
//                               name: '1CAS0462'
//                             },
//                             {
//                               id: 'P1_8708',
//                               name: '1CAS0463'
//                             },
//                             {
//                               id: 'P1_8709',
//                               name: '1CAS0464'
//                             },
//                             {
//                               id: 'P1_8710',
//                               name: '1CAS0465'
//                             },
//                             {
//                               id: 'P1_8807',
//                               name: '1CAS0424'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1010',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2447',
//                           name: '2SBB07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2452',
//                               name: '2SBB0707'
//                             },
//                             {
//                               id: 'P1_4233',
//                               name: '2SBB0709'
//                             },
//                             {
//                               id: 'P1_4807',
//                               name: '2SBB0710'
//                             },
//                             {
//                               id: 'P1_5781',
//                               name: '2SBB0713'
//                             },
//                             {
//                               id: 'P1_5782',
//                               name: '2SBB0714'
//                             },
//                             {
//                               id: 'P1_6083',
//                               name: '2SBB0716'
//                             },
//                             {
//                               id: 'P1_6084',
//                               name: '2SBB0717'
//                             },
//                             {
//                               id: 'P1_6085',
//                               name: '2SBB0718'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8757',
//                           name: '1SBB12_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8758',
//                               name: '1SBB1201'
//                             },
//                             {
//                               id: 'P1_8759',
//                               name: '1SBB1202'
//                             },
//                             {
//                               id: 'P1_8804',
//                               name: '1SBB1203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1012',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7648',
//                           name: '2DAT23_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6922',
//                               name: '2DAT2315'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8617',
//                           name: '1DAT23_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8618',
//                               name: '1DAT2318'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1014',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4087',
//                           name: '2WBD07_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4079',
//                               name: '2WBD0701'
//                             },
//                             {
//                               id: 'P1_4080',
//                               name: '2WBD0702'
//                             },
//                             {
//                               id: 'P1_4081',
//                               name: '2WBD0703'
//                             },
//                             {
//                               id: 'P1_4082',
//                               name: '2WBD0704'
//                             },
//                             {
//                               id: 'P1_4083',
//                               name: '2WBD0705'
//                             },
//                             {
//                               id: 'P1_4084',
//                               name: '2WBD0706'
//                             },
//                             {
//                               id: 'P1_5656',
//                               name: '2WBD0733'
//                             },
//                             {
//                               id: 'P1_5657',
//                               name: '2WBD0734'
//                             },
//                             {
//                               id: 'P1_5658',
//                               name: '2WBD0735'
//                             },
//                             {
//                               id: 'P1_5659',
//                               name: '2WBD0736'
//                             },
//                             {
//                               id: 'P1_5660',
//                               name: '2WBD0737'
//                             },
//                             {
//                               id: 'P1_5661',
//                               name: '2WBD0738'
//                             },
//                             {
//                               id: 'P1_5736',
//                               name: '2WBD0739'
//                             },
//                             {
//                               id: 'P1_8655',
//                               name: '2WBD0760'
//                             },
//                             {
//                               id: 'P1_8656',
//                               name: '2WBD0761'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8614',
//                           name: '1WBD07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8791',
//                               name: '1WBD0763'
//                             },
//                             {
//                               id: 'P1_8792',
//                               name: '1WBD0764'
//                             },
//                             {
//                               id: 'P1_8795',
//                               name: '1WBD0765'
//                             },
//                             {
//                               id: 'P1_8796',
//                               name: '1WBD0766'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1029',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2441',
//                           name: '2VRR02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2760',
//                               name: '2VRR0211'
//                             },
//                             {
//                               id: 'P1_5730',
//                               name: '2VRR0238'
//                             },
//                             {
//                               id: 'P1_6812',
//                               name: '2VRR0239'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1030',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2338',
//                           name: '2GBB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4236',
//                               name: '2GBB0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7072',
//                           name: '2GBB03_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5720',
//                               name: '2GBB0307'
//                             },
//                             {
//                               id: 'P1_5721',
//                               name: '2GBB0308'
//                             },
//                             {
//                               id: 'P1_8651',
//                               name: '1GBB0301'
//                             },
//                             {
//                               id: 'P1_8652',
//                               name: '1GBB0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1214',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2373',
//                           name: '2TBT08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3731',
//                               name: '2TBT0804'
//                             },
//                             {
//                               id: 'P1_4826',
//                               name: '2TBT0808'
//                             },
//                             {
//                               id: 'P1_5783',
//                               name: '2TBT0810'
//                             },
//                             {
//                               id: 'P1_6086',
//                               name: '2TBT0813'
//                             },
//                             {
//                               id: 'P1_6087',
//                               name: '2TBT0814'
//                             },
//                             {
//                               id: 'P1_6549',
//                               name: '2TBT1501'
//                             },
//                             {
//                               id: 'P1_8634',
//                               name: '1TBT0819'
//                             },
//                             {
//                               id: 'P1_8794',
//                               name: '1TBT0825'
//                             },
//                             {
//                               id: 'P1_8805',
//                               name: '1TBT0823'
//                             },
//                             {
//                               id: 'P1_8806',
//                               name: '1TBT0824'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_1317',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2446',
//                           name: '1TMD02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2451',
//                               name: '1TMD0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5842',
//                           name: '2TMD02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5615',
//                               name: '2TMD0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7047',
//                           name: '2TMD04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6723',
//                               name: '2TMD0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2059',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5945',
//                           name: '2LYT03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3608',
//                               name: '2LYT0301'
//                             },
//                             {
//                               id: 'P1_4492',
//                               name: '2LYT0302'
//                             },
//                             {
//                               id: 'P1_4493',
//                               name: '2LYT0303'
//                             },
//                             {
//                               id: 'P1_5787',
//                               name: '2LYT0304'
//                             },
//                             {
//                               id: 'P1_5788',
//                               name: '2LYT0305'
//                             },
//                             {
//                               id: 'P1_6678',
//                               name: '2LYT0306'
//                             },
//                             {
//                               id: 'P1_6679',
//                               name: '2LYT0307'
//                             },
//                             {
//                               id: 'P1_6680',
//                               name: '2LYT0309'
//                             },
//                             {
//                               id: 'P1_6681',
//                               name: '2LYT0308'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8812',
//                           name: '1LYT03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8813',
//                               name: '1LYT0319'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2922',
//                       name: 'SUPP_DESSICATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3611',
//                           name: '2DES01_T07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8485',
//                               name: '2DES0178'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3938',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4389',
//                           name: '2PLM07_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4380',
//                               name: '2PLM0709'
//                             },
//                             {
//                               id: 'P1_4650',
//                               name: '2PLM0710'
//                             },
//                             {
//                               id: 'P1_5760',
//                               name: '2PLM0711'
//                             },
//                             {
//                               id: 'P1_6669',
//                               name: '2PLM0712'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8755',
//                           name: '1PLM14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8756',
//                               name: '1PLM1401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3974',
//                       name: 'BACKSIDE_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3975',
//                           name: '2BTG01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3976',
//                               name: '2BTG0101'
//                             },
//                             {
//                               id: 'P1_6292',
//                               name: '2BTG0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8668',
//                           name: '1BTG01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8669',
//                               name: '1BTG0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5545',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6135',
//                           name: '1SAW04_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3808',
//                               name: '1SAW0416'
//                             },
//                             {
//                               id: 'P1_5547',
//                               name: '1SAW0418'
//                             },
//                             {
//                               id: 'P1_5834',
//                               name: '1SAW0419'
//                             },
//                             {
//                               id: 'P1_5853',
//                               name: '1SAW0420'
//                             },
//                             {
//                               id: 'P1_5854',
//                               name: '1SAW0421'
//                             },
//                             {
//                               id: 'P1_7282',
//                               name: '1SAW0417'
//                             },
//                             {
//                               id: 'P1_8790',
//                               name: '1SAW0425'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8663',
//                           name: '1SAW04_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8654',
//                               name: '1SAW0424'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5681',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5686',
//                           name: '2OVN14_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4213',
//                               name: '2OVN1412'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6249',
//                           name: '2OVN37_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6250',
//                               name: '2OVN3722'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5778',
//                       name: 'SUPP_XRAY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5779',
//                           name: '2XRY04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5780',
//                               name: '2XRY0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5844',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5845',
//                           name: '2AVI02_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4031',
//                               name: '2AVI0212'
//                             },
//                             {
//                               id: 'P1_4032',
//                               name: '2AVI0213'
//                             },
//                             {
//                               id: 'P1_4444',
//                               name: '2AVI0216'
//                             },
//                             {
//                               id: 'P1_5843',
//                               name: '2AVI0217'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6095',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6096',
//                           name: '2AVI08_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6097',
//                               name: '2AVI0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6410',
//                       name: 'SUPP_MANUAL_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6411',
//                           name: '2MVI17_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6412',
//                               name: '2MVI1701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8141',
//                           name: '2MVI25_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8142',
//                               name: '2MVI2502'
//                             },
//                             {
//                               id: 'P1_8143',
//                               name: '2MVI2501'
//                             },
//                             {
//                               id: 'P1_8144',
//                               name: '2MVI2503'
//                             },
//                             {
//                               id: 'P1_8145',
//                               name: '2MVI2504'
//                             },
//                             {
//                               id: 'P1_8146',
//                               name: '2MVI2505'
//                             },
//                             {
//                               id: 'P1_8147',
//                               name: '2MVI2506'
//                             },
//                             {
//                               id: 'P1_8148',
//                               name: '2MVI2507'
//                             },
//                             {
//                               id: 'P1_8149',
//                               name: '2MVI2508'
//                             },
//                             {
//                               id: 'P1_8150',
//                               name: '2MVI2509'
//                             },
//                             {
//                               id: 'P1_8151',
//                               name: '2MVI2510'
//                             },
//                             {
//                               id: 'P1_8152',
//                               name: '2MVI2511'
//                             },
//                             {
//                               id: 'P1_8153',
//                               name: '2MVI2512'
//                             },
//                             {
//                               id: 'P1_8154',
//                               name: '2MVI2513'
//                             },
//                             {
//                               id: 'P1_8155',
//                               name: '2MVI2514'
//                             },
//                             {
//                               id: 'P1_8156',
//                               name: '2MVI2515'
//                             },
//                             {
//                               id: 'P1_8157',
//                               name: '2MVI2516'
//                             },
//                             {
//                               id: 'P1_8158',
//                               name: '2MVI2517'
//                             },
//                             {
//                               id: 'P1_8159',
//                               name: '2MVI2518'
//                             },
//                             {
//                               id: 'P1_8160',
//                               name: '2MVI2519'
//                             },
//                             {
//                               id: 'P1_8161',
//                               name: '2MVI2520'
//                             },
//                             {
//                               id: 'P1_8162',
//                               name: '2MVI2521'
//                             },
//                             {
//                               id: 'P1_8163',
//                               name: '2MVI2522'
//                             },
//                             {
//                               id: 'P1_8164',
//                               name: '2MVI2523'
//                             },
//                             {
//                               id: 'P1_8165',
//                               name: '2MVI2524'
//                             },
//                             {
//                               id: 'P1_8166',
//                               name: '2MVI2525'
//                             },
//                             {
//                               id: 'P1_8167',
//                               name: '2MVI2526'
//                             },
//                             {
//                               id: 'P1_8168',
//                               name: '2MVI2527'
//                             },
//                             {
//                               id: 'P1_8169',
//                               name: '2MVI2528'
//                             },
//                             {
//                               id: 'P1_8170',
//                               name: '2MVI2529'
//                             },
//                             {
//                               id: 'P1_8171',
//                               name: '2MVI2530'
//                             },
//                             {
//                               id: 'P1_8172',
//                               name: '2MVI2531'
//                             },
//                             {
//                               id: 'P1_8173',
//                               name: '2MVI2532'
//                             },
//                             {
//                               id: 'P1_8174',
//                               name: '2MVI2533'
//                             },
//                             {
//                               id: 'P1_8175',
//                               name: '2MVI2534'
//                             },
//                             {
//                               id: 'P1_8176',
//                               name: '2MVI2535'
//                             },
//                             {
//                               id: 'P1_8177',
//                               name: '2MVI2536'
//                             },
//                             {
//                               id: 'P1_8178',
//                               name: '2MVI2537'
//                             },
//                             {
//                               id: 'P1_8179',
//                               name: '2MVI2538'
//                             },
//                             {
//                               id: 'P1_8180',
//                               name: '2MVI2539'
//                             },
//                             {
//                               id: 'P1_8181',
//                               name: '2MVI2540'
//                             },
//                             {
//                               id: 'P1_8182',
//                               name: '2MVI2541'
//                             },
//                             {
//                               id: 'P1_8183',
//                               name: '2MVI2542'
//                             },
//                             {
//                               id: 'P1_8184',
//                               name: '2MVI2543'
//                             },
//                             {
//                               id: 'P1_8185',
//                               name: '2MVI2544'
//                             },
//                             {
//                               id: 'P1_8186',
//                               name: '2MVI2545'
//                             },
//                             {
//                               id: 'P1_8187',
//                               name: '2MVI2546'
//                             },
//                             {
//                               id: 'P1_8188',
//                               name: '2MVI2547'
//                             },
//                             {
//                               id: 'P1_8189',
//                               name: '2MVI2548'
//                             },
//                             {
//                               id: 'P1_8190',
//                               name: '2MVI2549'
//                             },
//                             {
//                               id: 'P1_8191',
//                               name: '2MVI2550'
//                             },
//                             {
//                               id: 'P1_8192',
//                               name: '2MVI2551'
//                             },
//                             {
//                               id: 'P1_8193',
//                               name: '2MVI2552'
//                             },
//                             {
//                               id: 'P1_8194',
//                               name: '2MVI2553'
//                             },
//                             {
//                               id: 'P1_8195',
//                               name: '2MVI2554'
//                             },
//                             {
//                               id: 'P1_8196',
//                               name: '2MVI2555'
//                             },
//                             {
//                               id: 'P1_8197',
//                               name: '2MVI2556'
//                             },
//                             {
//                               id: 'P1_8198',
//                               name: '2MVI2557'
//                             },
//                             {
//                               id: 'P1_8199',
//                               name: '2MVI2558'
//                             },
//                             {
//                               id: 'P1_8200',
//                               name: '2MVI2559'
//                             },
//                             {
//                               id: 'P1_8201',
//                               name: '2MVI2560'
//                             },
//                             {
//                               id: 'P1_8202',
//                               name: '2MVI2561'
//                             },
//                             {
//                               id: 'P1_8203',
//                               name: '2MVI2562'
//                             },
//                             {
//                               id: 'P1_8204',
//                               name: '2MVI2563'
//                             },
//                             {
//                               id: 'P1_8205',
//                               name: '2MVI2564'
//                             },
//                             {
//                               id: 'P1_8206',
//                               name: '2MVI2565'
//                             },
//                             {
//                               id: 'P1_8207',
//                               name: '2MVI2566'
//                             },
//                             {
//                               id: 'P1_8208',
//                               name: '2MVI2567'
//                             },
//                             {
//                               id: 'P1_8209',
//                               name: '2MVI2568'
//                             },
//                             {
//                               id: 'P1_8210',
//                               name: '2MVI2569'
//                             },
//                             {
//                               id: 'P1_8211',
//                               name: '2MVI2570'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8607',
//                           name: '1MVI03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6178',
//                               name: '1MVI1301'
//                             },
//                             {
//                               id: 'P1_6258',
//                               name: '1MVI0314'
//                             },
//                             {
//                               id: 'P1_6263',
//                               name: '1MVI0319'
//                             },
//                             {
//                               id: 'P1_6264',
//                               name: '1MVI0320'
//                             },
//                             {
//                               id: 'P1_6641',
//                               name: '1MVI0332'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8682',
//                           name: '1MVI36_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8683',
//                               name: '1MVI3601'
//                             },
//                             {
//                               id: 'P1_8684',
//                               name: '1MVI3602'
//                             },
//                             {
//                               id: 'P1_8685',
//                               name: '1MVI3603'
//                             },
//                             {
//                               id: 'P1_8686',
//                               name: '1MVI3604'
//                             },
//                             {
//                               id: 'P1_8687',
//                               name: '1MVI3605'
//                             },
//                             {
//                               id: 'P1_8692',
//                               name: '1MVI3606'
//                             },
//                             {
//                               id: 'P1_8693',
//                               name: '1MVI3607'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6445',
//                       name: 'SUPP_GLUE_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6446',
//                           name: '2OVN37_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6447',
//                               name: '2OVN3714'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7207',
//                           name: '2OVN14_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4567',
//                               name: '2OVN1416'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7719',
//                           name: '2OVN37_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6448',
//                               name: '2OVN3715'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6657',
//                       name: 'DIE_ATTACH_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6658',
//                           name: '2DAT23_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4495',
//                               name: '2DAT2302'
//                             },
//                             {
//                               id: 'P1_5654',
//                               name: '2DAT2305'
//                             },
//                             {
//                               id: 'P1_5655',
//                               name: '2DAT2306'
//                             },
//                             {
//                               id: 'P1_5717',
//                               name: '2DAT2309'
//                             },
//                             {
//                               id: 'P1_5718',
//                               name: '2DAT2310'
//                             },
//                             {
//                               id: 'P1_6703',
//                               name: '2DAT2313'
//                             },
//                             {
//                               id: 'P1_6704',
//                               name: '2DAT2314'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6659',
//                           name: '2DAT05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2422',
//                               name: '2DAT0501'
//                             },
//                             {
//                               id: 'P1_3733',
//                               name: '2DAT0502'
//                             },
//                             {
//                               id: 'P1_4095',
//                               name: '2DAT0505'
//                             },
//                             {
//                               id: 'P1_4096',
//                               name: '2DAT0506'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8647',
//                           name: '2DAT30_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8648',
//                               name: '2DAT3001'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8711',
//                           name: '1DAT23_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8712',
//                               name: '1DAT2302'
//                             },
//                             {
//                               id: 'P1_8713',
//                               name: '1DAT2303'
//                             },
//                             {
//                               id: 'P1_8714',
//                               name: '1DAT2304'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6660',
//                       name: 'DIE_ATTACH_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6661',
//                           name: '2DAT23_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4494',
//                               name: '2DAT2301'
//                             },
//                             {
//                               id: 'P1_5652',
//                               name: '2DAT2303'
//                             },
//                             {
//                               id: 'P1_5653',
//                               name: '2DAT2304'
//                             },
//                             {
//                               id: 'P1_5715',
//                               name: '2DAT2307'
//                             },
//                             {
//                               id: 'P1_5716',
//                               name: '2DAT2308'
//                             },
//                             {
//                               id: 'P1_6701',
//                               name: '2DAT2311'
//                             },
//                             {
//                               id: 'P1_6702',
//                               name: '2DAT2312'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6663',
//                           name: '2DAT05_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4093',
//                               name: '2DAT0503'
//                             },
//                             {
//                               id: 'P1_4094',
//                               name: '2DAT0504'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8715',
//                           name: '1DAT23_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8716',
//                               name: '1DAT2305'
//                             },
//                             {
//                               id: 'P1_8717',
//                               name: '1DAT2306'
//                             },
//                             {
//                               id: 'P1_8718',
//                               name: '1DAT2307'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7205',
//                       name: 'SUPP_OVEN_PMC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1654',
//                           name: '2OVN15_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3712',
//                               name: '2OVN1501'
//                             },
//                             {
//                               id: 'P1_3713',
//                               name: '2OVN1512'
//                             },
//                             {
//                               id: 'P1_4476',
//                               name: '2OVN1515'
//                             },
//                             {
//                               id: 'P1_4477',
//                               name: '2OVN1516'
//                             },
//                             {
//                               id: 'P1_6921',
//                               name: '2OVN1517'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7206',
//                       name: 'SUPP_OVEN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4731',
//                           name: '1OVN14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4732',
//                               name: '1OVN1411'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5573',
//                           name: '2OVN14_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4793',
//                               name: '2OVN1417'
//                             },
//                             {
//                               id: 'P1_4794',
//                               name: '2OVN1418'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6082',
//                           name: '2OVN15_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5435',
//                               name: '2OVN3709'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7389',
//                       name: 'BACKSIDE_DETAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7390',
//                           name: '2DTR05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4124',
//                               name: '2DTR0501'
//                             },
//                             {
//                               id: 'P1_7391',
//                               name: '2DTR0502'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7533',
//                       name: 'SUPP_OVEN_LAYER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4729',
//                           name: '1OVN16_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4730',
//                               name: '1OVN1601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7534',
//                       name: 'SUPP_OVEN_LPS',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7538',
//                           name: '2OVN15_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3926',
//                               name: '2OVN1514'
//                             },
//                             {
//                               id: 'P1_4733',
//                               name: '2OVN1513'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7535',
//                       name: 'SUPP_XRAY_AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7537',
//                           name: '2VRR02_T10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5729',
//                               name: '2VRR0237'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8214',
//                       name: 'SUPP_PHOSPHOR_SEDIMENTATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6507',
//                           name: '2PPS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6252',
//                               name: '2PPS0101'
//                             },
//                             {
//                               id: 'P1_6253',
//                               name: '2PPS0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6508',
//                           name: '2PPS02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6251',
//                               name: '2PPS0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8218',
//                       name: 'SUPP_VACUUM_SEALER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7650',
//                           name: '2VUS05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7651',
//                               name: '2VUS0505'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_2179',
//                   name: 'MFX - COMMON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4229',
//                       name: 'SUPP_THAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5037',
//                           name: '2THW01_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3318',
//                               name: '2THW0101'
//                             },
//                             {
//                               id: 'P1_3319',
//                               name: '2THW0102'
//                             },
//                             {
//                               id: 'P1_3320',
//                               name: '2THW0103'
//                             },
//                             {
//                               id: 'P1_3979',
//                               name: '2THW0111'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5267',
//                           name: '1THW01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5271',
//                               name: '1THW0101'
//                             },
//                             {
//                               id: 'P1_5272',
//                               name: '1THW0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6035',
//                           name: '1THW01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6036',
//                               name: '1THW0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7167',
//                           name: '1THW03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7168',
//                               name: '1THW0301'
//                             },
//                             {
//                               id: 'P1_7169',
//                               name: '1THW0302'
//                             },
//                             {
//                               id: 'P1_7170',
//                               name: '1THW0303'
//                             },
//                             {
//                               id: 'P1_7171',
//                               name: '1THW0304'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4436',
//                       name: 'SUPP_XRAY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4454',
//                           name: '2XRY01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4433',
//                               name: '2XRY0101'
//                             },
//                             {
//                               id: 'P1_4455',
//                               name: '2XRY0102'
//                             },
//                             {
//                               id: 'P1_4456',
//                               name: '2XRY0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4457',
//                           name: '2XRY02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4458',
//                               name: '2XRY0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4459',
//                           name: '2XRY03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4460',
//                               name: '2XRY0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4856',
//                       name: 'SUPP_SCREEN_PRINTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4871',
//                           name: '1LPT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4873',
//                               name: '1LPT0102'
//                             },
//                             {
//                               id: 'P1_4874',
//                               name: '1LPT0103'
//                             },
//                             {
//                               id: 'P1_4875',
//                               name: '1LPT0104'
//                             },
//                             {
//                               id: 'P1_4878',
//                               name: '1LPT0107'
//                             },
//                             {
//                               id: 'P1_4879',
//                               name: '1LPT0108'
//                             },
//                             {
//                               id: 'P1_4880',
//                               name: '1LPT0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4858',
//                       name: 'SUPP_ROLLER_MILL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4889',
//                           name: '1PRP06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4891',
//                               name: '1PRP0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4890',
//                           name: '1PRP07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4892',
//                               name: '1PRP0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7471',
//                           name: '1PRP01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7481',
//                               name: '1PRP0101'
//                             },
//                             {
//                               id: 'P1_7482',
//                               name: '1PRP0102'
//                             },
//                             {
//                               id: 'P1_8664',
//                               name: '1PRP0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4862',
//                       name: 'SUPP_REFRIGERATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4917',
//                           name: '1FRZ03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4918',
//                               name: '1FRZ0301'
//                             },
//                             {
//                               id: 'P1_4919',
//                               name: '1FRZ0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4866',
//                       name: 'SUPP_MANUAL_RETAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4934',
//                           name: '1MRT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4940',
//                               name: '1MRT0101'
//                             },
//                             {
//                               id: 'P1_4941',
//                               name: '1MRT0102'
//                             },
//                             {
//                               id: 'P1_4942',
//                               name: '1MRT0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4935',
//                           name: '1MRT02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4943',
//                               name: '1MRT0201'
//                             },
//                             {
//                               id: 'P1_4944',
//                               name: '1MRT0202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6949',
//                           name: '1MRT03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6950',
//                               name: '1MRT0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4993',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1101',
//                           name: '2DES01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1053',
//                               name: '2DES0142'
//                             },
//                             {
//                               id: 'P1_1054',
//                               name: '2DES0146'
//                             },
//                             {
//                               id: 'P1_1055',
//                               name: '2DES0135'
//                             },
//                             {
//                               id: 'P1_1056',
//                               name: '2DES0147'
//                             },
//                             {
//                               id: 'P1_1061',
//                               name: '2DES0136'
//                             },
//                             {
//                               id: 'P1_1063',
//                               name: '2DES0144'
//                             },
//                             {
//                               id: 'P1_1066',
//                               name: '2DES0143'
//                             },
//                             {
//                               id: 'P1_1071',
//                               name: '2DES0102'
//                             },
//                             {
//                               id: 'P1_1072',
//                               name: '2DES0125'
//                             },
//                             {
//                               id: 'P1_1075',
//                               name: '2DES0122'
//                             },
//                             {
//                               id: 'P1_1076',
//                               name: '2DES0120'
//                             },
//                             {
//                               id: 'P1_1077',
//                               name: '2DES0118'
//                             },
//                             {
//                               id: 'P1_1078',
//                               name: '2DES0103'
//                             },
//                             {
//                               id: 'P1_1080',
//                               name: '2DES0115'
//                             },
//                             {
//                               id: 'P1_1081',
//                               name: '2DES0117'
//                             },
//                             {
//                               id: 'P1_1082',
//                               name: '2DES0119'
//                             },
//                             {
//                               id: 'P1_1091',
//                               name: '2DES0105'
//                             },
//                             {
//                               id: 'P1_1093',
//                               name: '2DES0127'
//                             },
//                             {
//                               id: 'P1_1095',
//                               name: '2DES0153'
//                             },
//                             {
//                               id: 'P1_1097',
//                               name: '2DES0151'
//                             },
//                             {
//                               id: 'P1_1105',
//                               name: '2DES0132'
//                             },
//                             {
//                               id: 'P1_1107',
//                               name: '2DES0131'
//                             },
//                             {
//                               id: 'P1_1118',
//                               name: '2DES0104'
//                             },
//                             {
//                               id: 'P1_1120',
//                               name: '2DES0133'
//                             },
//                             {
//                               id: 'P1_1121',
//                               name: '2DES0134'
//                             },
//                             {
//                               id: 'P1_1123',
//                               name: '2DES0137'
//                             },
//                             {
//                               id: 'P1_1124',
//                               name: '2DES0138'
//                             },
//                             {
//                               id: 'P1_1131',
//                               name: '2DES0109'
//                             },
//                             {
//                               id: 'P1_1132',
//                               name: '2DES0126'
//                             },
//                             {
//                               id: 'P1_1135',
//                               name: '2DRYSMT'
//                             },
//                             {
//                               id: 'P1_1674',
//                               name: '2DES0165'
//                             },
//                             {
//                               id: 'P1_2425',
//                               name: '2DES0170'
//                             },
//                             {
//                               id: 'P1_4636',
//                               name: '2DES0129'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4920',
//                           name: '1DES01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4922',
//                               name: '1DES0112'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4921',
//                           name: '1DES08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4924',
//                               name: '1DES0804'
//                             },
//                             {
//                               id: 'P1_4925',
//                               name: '1DES0805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5502',
//                           name: '2DES03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1122',
//                               name: '2DES0304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7479',
//                           name: '1DES13_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7489',
//                               name: '1DES1301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8240',
//                           name: '2DES01_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1057',
//                               name: '2DES0141'
//                             },
//                             {
//                               id: 'P1_1092',
//                               name: '2DES0113'
//                             },
//                             {
//                               id: 'P1_1096',
//                               name: '2DES0152'
//                             },
//                             {
//                               id: 'P1_1098',
//                               name: '2DES0139'
//                             },
//                             {
//                               id: 'P1_1102',
//                               name: '2DES0150'
//                             },
//                             {
//                               id: 'P1_1103',
//                               name: '2DES0148'
//                             },
//                             {
//                               id: 'P1_1104',
//                               name: '2DES0149'
//                             },
//                             {
//                               id: 'P1_1106',
//                               name: '2DES0140'
//                             },
//                             {
//                               id: 'P1_1108',
//                               name: '2DES0155'
//                             },
//                             {
//                               id: 'P1_1137',
//                               name: '2DRYSTS'
//                             },
//                             {
//                               id: 'P1_1138',
//                               name: '2DRYSMLED'
//                             },
//                             {
//                               id: 'P1_1139',
//                               name: '2DES0154'
//                             },
//                             {
//                               id: 'P1_1140',
//                               name: '2DRYKESP'
//                             },
//                             {
//                               id: 'P1_1164',
//                               name: '2DESDRYHVM'
//                             },
//                             {
//                               id: 'P1_1211',
//                               name: '2DES0159'
//                             },
//                             {
//                               id: 'P1_1212',
//                               name: '2DES0160'
//                             },
//                             {
//                               id: 'P1_1675',
//                               name: '2DES0166'
//                             },
//                             {
//                               id: 'P1_1676',
//                               name: '2DES0167'
//                             },
//                             {
//                               id: 'P1_2474',
//                               name: '2DES0168'
//                             },
//                             {
//                               id: 'P1_3710',
//                               name: '2DES0177'
//                             },
//                             {
//                               id: 'P1_4995',
//                               name: '2DES0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8241',
//                           name: '2DES02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1058',
//                               name: '2DES0204'
//                             },
//                             {
//                               id: 'P1_1109',
//                               name: '2DES0303'
//                             },
//                             {
//                               id: 'P1_1113',
//                               name: '2DES0208'
//                             },
//                             {
//                               id: 'P1_1128',
//                               name: '2DES0301'
//                             },
//                             {
//                               id: 'P1_1129',
//                               name: '2DES0202'
//                             },
//                             {
//                               id: 'P1_1168',
//                               name: '2DES0213'
//                             },
//                             {
//                               id: 'P1_1173',
//                               name: '2DES0212'
//                             },
//                             {
//                               id: 'P1_1176',
//                               name: '2DES0215'
//                             },
//                             {
//                               id: 'P1_1177',
//                               name: '2DES0214'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8242',
//                           name: '2DES04_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1169',
//                               name: '2DES0405'
//                             },
//                             {
//                               id: 'P1_1170',
//                               name: '2DES0401'
//                             },
//                             {
//                               id: 'P1_1171',
//                               name: '2DES0402'
//                             },
//                             {
//                               id: 'P1_1172',
//                               name: '2DES0403'
//                             },
//                             {
//                               id: 'P1_1174',
//                               name: '2DES0406'
//                             },
//                             {
//                               id: 'P1_1175',
//                               name: '2DES0404'
//                             },
//                             {
//                               id: 'P1_1578',
//                               name: '2DES0407'
//                             },
//                             {
//                               id: 'P1_1580',
//                               name: '2DES0409'
//                             },
//                             {
//                               id: 'P1_3831',
//                               name: '2DES0410'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8243',
//                           name: '2DES07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3064',
//                               name: '2DES0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8249',
//                           name: '2DES08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6471',
//                               name: '2DES0803'
//                             },
//                             {
//                               id: 'P1_6472',
//                               name: '2DES0804'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8366',
//                           name: '3DES14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8367',
//                               name: '3DES1401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4997',
//                       name: 'SUPP_FREEZER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4998',
//                           name: '2FRZ04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2423',
//                               name: '2FRZ0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4999',
//                           name: '2FRZ10_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4391',
//                               name: '2FRZ1002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5000',
//                           name: '2FRZ06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3205',
//                               name: '2FRZ0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5001',
//                           name: '2FRZ09_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3343',
//                               name: '2FRZ0902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5002',
//                           name: '2FRZ04_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3253',
//                               name: '2FR0404'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5268',
//                           name: '1FRZ01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5273',
//                               name: '1FRZ0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6647',
//                           name: '1FRZ12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6648',
//                               name: '1FRZ1201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8446',
//                           name: '1FRZ13_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8447',
//                               name: '1FRZ1301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5052',
//                       name: 'SUPP_SPC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5100',
//                           name: '1SPC01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5101',
//                               name: '1SPC0106'
//                             },
//                             {
//                               id: 'P1_6477',
//                               name: '1SPC0103'
//                             },
//                             {
//                               id: 'P1_6484',
//                               name: '1SPC0108'
//                             },
//                             {
//                               id: 'P1_7185',
//                               name: '1SPC0109'
//                             },
//                             {
//                               id: 'P1_7308',
//                               name: '1SPC0110'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6224',
//                           name: '2SPC13_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6226',
//                               name: '2SPC1301'
//                             },
//                             {
//                               id: 'P1_6227',
//                               name: '2SPC1302'
//                             },
//                             {
//                               id: 'P1_6228',
//                               name: '2SPC1303'
//                             },
//                             {
//                               id: 'P1_6229',
//                               name: '2SPC1304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6225',
//                           name: '2SPC14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6230',
//                               name: '2SPC1401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6429',
//                           name: '1SPC04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2167',
//                               name: '1SPC0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6598',
//                           name: '1SPC06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6599',
//                               name: '1SPC0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6879',
//                           name: '1SPC16_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6880',
//                               name: '1SPC1601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7341',
//                           name: '1SPC17_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7342',
//                               name: '1SPC1701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7506',
//                           name: '2SPC18_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7507',
//                               name: '2SPC1801'
//                             },
//                             {
//                               id: 'P1_7508',
//                               name: '2SPC1802'
//                             },
//                             {
//                               id: 'P1_7509',
//                               name: '2SPC1803'
//                             },
//                             {
//                               id: 'P1_7510',
//                               name: '2SPC1804'
//                             },
//                             {
//                               id: 'P1_7511',
//                               name: '2SPC1805'
//                             },
//                             {
//                               id: 'P1_7513',
//                               name: '2SPC1806'
//                             },
//                             {
//                               id: 'P1_7514',
//                               name: '2SPC1807'
//                             },
//                             {
//                               id: 'P1_7515',
//                               name: '2SPC1808'
//                             },
//                             {
//                               id: 'P1_7516',
//                               name: '2SPC1809'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7512',
//                           name: '2SPC19_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7517',
//                               name: '2SPC1901'
//                             },
//                             {
//                               id: 'P1_7518',
//                               name: '2SPC1902'
//                             },
//                             {
//                               id: 'P1_7519',
//                               name: '2SPC1903'
//                             },
//                             {
//                               id: 'P1_7520',
//                               name: '2SPC1904'
//                             },
//                             {
//                               id: 'P1_7521',
//                               name: '2SPC1905'
//                             },
//                             {
//                               id: 'P1_7522',
//                               name: '2SPC1906'
//                             },
//                             {
//                               id: 'P1_7523',
//                               name: '2SPC1907'
//                             },
//                             {
//                               id: 'P1_7524',
//                               name: '2SPC1908'
//                             },
//                             {
//                               id: 'P1_7525',
//                               name: '2SPC1909'
//                             },
//                             {
//                               id: 'P1_7526',
//                               name: '2SPC1910'
//                             },
//                             {
//                               id: 'P1_7527',
//                               name: '2SPC1911'
//                             },
//                             {
//                               id: 'P1_7528',
//                               name: '2SPC1912'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5266',
//                       name: 'SUPP_CALIBRATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5270',
//                           name: '1ECB01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5277',
//                               name: '1ECB0101'
//                             },
//                             {
//                               id: 'P1_5278',
//                               name: '1ECB0102'
//                             },
//                             {
//                               id: 'P1_6144',
//                               name: '1ECB0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5283',
//                       name: 'SUPP_SPEED_MIXER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4939',
//                           name: '1SMX05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4950',
//                               name: '1SMX0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5284',
//                           name: '1SMX01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5285',
//                               name: '1SMX0101'
//                             },
//                             {
//                               id: 'P1_5334',
//                               name: '1SMX0105'
//                             },
//                             {
//                               id: 'P1_6664',
//                               name: '1SMX0106'
//                             },
//                             {
//                               id: 'P1_6665',
//                               name: '1SMX0107'
//                             },
//                             {
//                               id: 'P1_7166',
//                               name: '1SMX0108'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6645',
//                           name: '1SMX02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6646',
//                               name: '1SMX0201'
//                             },
//                             {
//                               id: 'P1_6666',
//                               name: '1SMX0202'
//                             },
//                             {
//                               id: 'P1_7156',
//                               name: '1SMX0203'
//                             },
//                             {
//                               id: 'P1_7172',
//                               name: '1SMX0204'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7477',
//                           name: '1SMX04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7487',
//                               name: '1SMX0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5818',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5819',
//                           name: '2OVN34_G01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4056',
//                               name: '2OVN3405'
//                             },
//                             {
//                               id: 'P1_4057',
//                               name: '2OVN3406'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6233',
//                       name: 'SUPP_US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6931',
//                           name: '1USM03_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6592',
//                               name: '1USM0311'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6241',
//                       name: 'SUPP_PEEL_FORCE_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6242',
//                           name: '2PFT01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6243',
//                               name: '2PFT0102'
//                             },
//                             {
//                               id: 'P1_6244',
//                               name: '2PFT0103'
//                             },
//                             {
//                               id: 'P1_6245',
//                               name: '2PFT0104'
//                             },
//                             {
//                               id: 'P1_6246',
//                               name: '2PFT0105'
//                             },
//                             {
//                               id: 'P1_6247',
//                               name: '2PFT0106'
//                             },
//                             {
//                               id: 'P1_6248',
//                               name: '2PFT0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6881',
//                       name: 'SUPP_IN_TRANSIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6882',
//                           name: '1ITR01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6798',
//                               name: '1ITR0101'
//                             },
//                             {
//                               id: 'P1_6799',
//                               name: '1ITR0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7470',
//                       name: 'SUPP_AUTO_MIXER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7478',
//                           name: '1SMX06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7488',
//                               name: '1SMX0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8227',
//                       name: 'SUPP_FRAME_MOUNTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4936',
//                           name: '1FMT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4945',
//                               name: '1FMT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8230',
//                       name: 'SUPP_LAURIER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4926',
//                           name: '1LST01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4927',
//                               name: '1LST0101'
//                             },
//                             {
//                               id: 'P1_4928',
//                               name: '1LST0102'
//                             },
//                             {
//                               id: 'P1_4929',
//                               name: '1LST0103'
//                             },
//                             {
//                               id: 'P1_4930',
//                               name: '1LST0104'
//                             },
//                             {
//                               id: 'P1_4931',
//                               name: '1LST0105'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8232',
//                       name: 'SUPP_TAPE_CUTTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4937',
//                           name: '1MTC01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4946',
//                               name: '1MTC0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4947',
//                           name: '1MTC02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4948',
//                               name: '1MTC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8236',
//                       name: 'SUPP_SMART_SCOPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4932',
//                           name: '1SPC12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4933',
//                               name: '1SPC1201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8237',
//                       name: 'SUPP_ULTRASONIC_BATH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6583',
//                           name: '1ULT02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6585',
//                               name: '1ULT0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6584',
//                           name: '1ULT03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6586',
//                               name: '1ULT0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8238',
//                       name: 'SUPP_WEIGHING_MACHINE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4893',
//                           name: '1WGH08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4895',
//                               name: '1WGH0801'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4894',
//                           name: '1WGH09_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4896',
//                               name: '1WGH0901'
//                             },
//                             {
//                               id: 'P1_4897',
//                               name: '1WGH0902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5269',
//                           name: '1WGH01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5274',
//                               name: '1WGH0101'
//                             },
//                             {
//                               id: 'P1_5275',
//                               name: '1WGH0102'
//                             },
//                             {
//                               id: 'P1_5276',
//                               name: '1WGH0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5673',
//                           name: '2WGH10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5674',
//                               name: '2WGH1001'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6757',
//                           name: '1WGH10_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6758',
//                               name: '1WGH1001'
//                             },
//                             {
//                               id: 'P1_6759',
//                               name: '1WGH1002'
//                             },
//                             {
//                               id: 'P1_6760',
//                               name: '1WGH1003'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7472',
//                           name: '1WGH11_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7483',
//                               name: '1WGH1101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7473',
//                           name: '1WGH12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7484',
//                               name: '1WGH1201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7474',
//                           name: '1WGH13_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7485',
//                               name: '1WGH1301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7476',
//                           name: '1WGH14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7486',
//                               name: '1WGH1401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8239',
//                       name: 'SUPP_CLEANING_COILER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5013',
//                           name: '2CCS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4373',
//                               name: '2CCS0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8245',
//                       name: 'SUPP_SS_VISUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4045',
//                           name: '2SVI01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4059',
//                               name: '2SVI0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8258',
//                       name: 'SUPP_LED_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8371',
//                           name: '3MAT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8372',
//                               name: '3MAT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8348',
//                       name: 'SUPP_PROBER_LAYERPRINTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4881',
//                           name: '1LPP01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4882',
//                               name: '1LPP0101'
//                             },
//                             {
//                               id: 'P1_4883',
//                               name: '1LPP0102'
//                             },
//                             {
//                               id: 'P1_4884',
//                               name: '1LPP0103'
//                             },
//                             {
//                               id: 'P1_4885',
//                               name: '1LPP0104'
//                             },
//                             {
//                               id: 'P1_4886',
//                               name: '1LPP0105'
//                             },
//                             {
//                               id: 'P1_4887',
//                               name: '1LPP0106'
//                             },
//                             {
//                               id: 'P1_4888',
//                               name: '1LPP0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8482',
//                       name: 'SUPP_MANUAL_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8451',
//                           name: '2MVI03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3164',
//                               name: '2MVI0314'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8494',
//                       name: 'SUPP_VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7563',
//                           name: '2VPP01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7007',
//                               name: '2VPP0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7564',
//                           name: '2VSS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7458',
//                               name: '2VSS0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8443',
//                           name: '2AVI05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3297',
//                               name: '2AVI0502'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8509',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7696',
//                           name: '2OVN01_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5633',
//                               name: '2OVN0127'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8665',
//                       name: 'AUTO PACKING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8666',
//                           name: '1APM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8667',
//                               name: '1APM0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8750',
//                       name: 'SUPP_BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8751',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5071',
//                               name: '2OVN2004'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_2231',
//                   name: 'MFX - GALVANIC',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4034',
//                       name: 'DEFLASHING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5320',
//                           name: '2DEF02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3129',
//                               name: '2DEF0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5335',
//                           name: '2DEF01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4052',
//                               name: '2DEF0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4035',
//                       name: 'ACID_RINSE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4042',
//                           name: '2ARN01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4053',
//                               name: '2ARN0101'
//                             },
//                             {
//                               id: 'P1_4054',
//                               name: '2ARN0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7599',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7076',
//                           name: '2AVI03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6535',
//                               name: '2AVI0310'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7600',
//                           name: '2MVI10_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7601',
//                               name: '2MVI1010'
//                             },
//                             {
//                               id: 'P1_7602',
//                               name: '2MVI1011'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7605',
//                           name: '2MVI20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7607',
//                               name: '2MVI2002'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7871',
//                       name: 'PLATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5755',
//                           name: '2PLA01_G01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4048',
//                               name: '2PLA0103'
//                             },
//                             {
//                               id: 'P1_4049',
//                               name: '2PLA0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7872',
//                           name: '2PLA02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4051',
//                               name: '2PLA0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7873',
//                       name: 'SS_VISUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6626',
//                           name: '2MVI14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6405',
//                               name: '2MVI1401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6627',
//                           name: '2MVI15_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6407',
//                               name: '2MVI1501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6628',
//                           name: '2MVI16_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6409',
//                               name: '2MVI1601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6629',
//                           name: '2AVI10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6182',
//                               name: '2AVI1001'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_2789',
//                   name: 'MF3 - MDP LASER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_2790',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2799',
//                           name: '2DAT10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2812',
//                               name: '2DAT1001'
//                             },
//                             {
//                               id: 'P1_4800',
//                               name: '2DAT1003'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2791',
//                       name: 'US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2800',
//                           name: '2USM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2817',
//                               name: '2USM0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2793',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4740',
//                           name: '2OVN40_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4743',
//                               name: '2OVN4003'
//                             },
//                             {
//                               id: 'P1_4744',
//                               name: '2OVN4004'
//                             },
//                             {
//                               id: 'P1_6152',
//                               name: '2OVN4005'
//                             },
//                             {
//                               id: 'P1_6153',
//                               name: '2OVN4006'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4838',
//                           name: '2OVN42_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4839',
//                               name: '2OVN4201'
//                             },
//                             {
//                               id: 'P1_6475',
//                               name: '2OVN4202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2795',
//                       name: 'HERMETIC_SEALING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2804',
//                           name: '2HSS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2814',
//                               name: '2HSS0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2796',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2806',
//                           name: '2AVI03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2851',
//                               name: '2AVI0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3621',
//                       name: 'BAR_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3624',
//                           name: '2SCC01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3627',
//                               name: '2SCC0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3622',
//                       name: 'FINAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3625',
//                           name: '2MTE01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3628',
//                               name: '2MTE0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3623',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3862',
//                           name: '2DES08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3863',
//                               name: '2DES0802'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7221',
//                           name: '2DES12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7222',
//                               name: '2DES1202'
//                             },
//                             {
//                               id: 'P1_7223',
//                               name: '2DES1203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3858',
//                       name: 'SUPP_SPC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6750',
//                           name: '2SPC01_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3860',
//                               name: '2SPC0109'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7865',
//                           name: '2SPC02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3861',
//                               name: '2SPC0201'
//                             },
//                             {
//                               id: 'P1_6925',
//                               name: '2SPC0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4144',
//                       name: 'LENS_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4148',
//                           name: '2LMT02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4152',
//                               name: '2LMT0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4869',
//                       name: 'SUPP_UV_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4938',
//                           name: '1UVC02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4949',
//                               name: '1UVC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6748',
//                       name: 'SUPP_FREEZER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6749',
//                           name: '2FRZ08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3167',
//                               name: '2FRZ0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7392',
//                       name: 'AUTO_BAR_FLIPPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7393',
//                           name: '2BFP02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7394',
//                               name: '2BFP0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8216',
//                       name: 'SUPP_THAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2884',
//                           name: '2THW01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4231',
//                               name: '2THW0112'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8219',
//                       name: 'SUPP_WAFER_EXPANDER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7225',
//                           name: '2WFE01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7226',
//                               name: '2WFE0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8252',
//                       name: 'SUPP_HERMETIC_SEALING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8253',
//                           name: '2HSS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6919',
//                               name: '2OVN5401'
//                             },
//                             {
//                               id: 'P1_6920',
//                               name: '2OVN5402'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8397',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8399',
//                           name: '3MVI28_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8401',
//                               name: '3MVI2801'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8400',
//                           name: '3MVI29_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8402',
//                               name: '3MVI2901'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_3877',
//                   name: 'MF1 - OBF AUTO_FWL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_3878',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3916',
//                           name: '1DAT08_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3646',
//                               name: '1DAT0819'
//                             },
//                             {
//                               id: 'P1_3647',
//                               name: '1DAT0820'
//                             },
//                             {
//                               id: 'P1_3663',
//                               name: '1DAT0818'
//                             },
//                             {
//                               id: 'P1_3664',
//                               name: '1DAT0816'
//                             },
//                             {
//                               id: 'P1_3833',
//                               name: '1DAT0824'
//                             },
//                             {
//                               id: 'P1_5921',
//                               name: '1DAT0839'
//                             },
//                             {
//                               id: 'P1_5922',
//                               name: '1DAT0840'
//                             },
//                             {
//                               id: 'P1_5923',
//                               name: '1DAT0841'
//                             },
//                             {
//                               id: 'P1_6001',
//                               name: '1DAT0846'
//                             },
//                             {
//                               id: 'P1_6765',
//                               name: '1DAT0852'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3879',
//                       name: 'DIE_ATTACH_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3885',
//                           name: '1DAT05_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2389',
//                               name: '1DAT0551'
//                             },
//                             {
//                               id: 'P1_2390',
//                               name: '1DAT0552'
//                             },
//                             {
//                               id: 'P1_2391',
//                               name: '1DAT0553'
//                             },
//                             {
//                               id: 'P1_2392',
//                               name: '1DAT0554'
//                             },
//                             {
//                               id: 'P1_4206',
//                               name: '1DAT0574'
//                             },
//                             {
//                               id: 'P1_5887',
//                               name: '1DAT0589'
//                             },
//                             {
//                               id: 'P1_5888',
//                               name: '1DAT0590'
//                             },
//                             {
//                               id: 'P1_6762',
//                               name: '1DAT2702'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3880',
//                       name: 'WIRE_BOND_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3886',
//                           name: '1WBD07_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3965',
//                               name: '1WBD0726'
//                             },
//                             {
//                               id: 'P1_3966',
//                               name: '1WBD0727'
//                             },
//                             {
//                               id: 'P1_3968',
//                               name: '1WBD0729'
//                             },
//                             {
//                               id: 'P1_5708',
//                               name: '1WBD0742'
//                             },
//                             {
//                               id: 'P1_5709',
//                               name: '1WBD0743'
//                             },
//                             {
//                               id: 'P1_6754',
//                               name: '1WBD0755'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3881',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3889',
//                           name: '1CAS04_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3716',
//                               name: '1CAS0426'
//                             },
//                             {
//                               id: 'P1_3762',
//                               name: '1CAS0425'
//                             },
//                             {
//                               id: 'P1_3841',
//                               name: '1CAS0432'
//                             },
//                             {
//                               id: 'P1_3842',
//                               name: '1CAS0433'
//                             },
//                             {
//                               id: 'P1_3843',
//                               name: '1CAS0434'
//                             },
//                             {
//                               id: 'P1_3919',
//                               name: '1CAS0435'
//                             },
//                             {
//                               id: 'P1_3920',
//                               name: '1CAS0436'
//                             },
//                             {
//                               id: 'P1_5878',
//                               name: '1CAS0442'
//                             },
//                             {
//                               id: 'P1_5879',
//                               name: '1CAS0443'
//                             },
//                             {
//                               id: 'P1_5880',
//                               name: '1CAS0444'
//                             },
//                             {
//                               id: 'P1_5883',
//                               name: '1CAS0446'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3882',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3891',
//                           name: '1LYT02_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1643',
//                               name: '1LYT0208'
//                             },
//                             {
//                               id: 'P1_1644',
//                               name: '1LYT0209'
//                             },
//                             {
//                               id: 'P1_1660',
//                               name: '1LYT0218'
//                             },
//                             {
//                               id: 'P1_1661',
//                               name: '1LYT0219'
//                             },
//                             {
//                               id: 'P1_1662',
//                               name: '1LYT0220'
//                             },
//                             {
//                               id: 'P1_3618',
//                               name: '1LYT0210'
//                             },
//                             {
//                               id: 'P1_3715',
//                               name: '1LYT0225'
//                             },
//                             {
//                               id: 'P1_3917',
//                               name: '1LYT0229'
//                             },
//                             {
//                               id: 'P1_3918',
//                               name: '1LYT0230'
//                             },
//                             {
//                               id: 'P1_5536',
//                               name: '1LYT0236'
//                             },
//                             {
//                               id: 'P1_5537',
//                               name: '1LYT0237'
//                             },
//                             {
//                               id: 'P1_5538',
//                               name: '1LYT0238'
//                             },
//                             {
//                               id: 'P1_5539',
//                               name: '1LYT0239'
//                             },
//                             {
//                               id: 'P1_6740',
//                               name: '1LYT0240'
//                             },
//                             {
//                               id: 'P1_6741',
//                               name: '1LYT0241'
//                             },
//                             {
//                               id: 'P1_6813',
//                               name: '1LYT0243'
//                             },
//                             {
//                               id: 'P1_6814',
//                               name: '1LYT0244'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3883',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3892',
//                           name: '1TMD02_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2464',
//                               name: '1TMD0202'
//                             },
//                             {
//                               id: 'P1_2465',
//                               name: '1TMD0203'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7406',
//                           name: '1TMD04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6731',
//                               name: '1TMD0402'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3894',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3896',
//                           name: '1TST01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1565',
//                               name: '1TST0102'
//                             },
//                             {
//                               id: 'P1_1566',
//                               name: '1TST0103'
//                             },
//                             {
//                               id: 'P1_3893',
//                               name: '1TST0104'
//                             },
//                             {
//                               id: 'P1_4216',
//                               name: '1TST0106'
//                             },
//                             {
//                               id: 'P1_4217',
//                               name: '1TST0105'
//                             },
//                             {
//                               id: 'P1_4220',
//                               name: '1TST0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7396',
//                           name: '1TST02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7218',
//                               name: '1TST0201'
//                             },
//                             {
//                               id: 'P1_7399',
//                               name: '1TST0202'
//                             },
//                             {
//                               id: 'P1_7401',
//                               name: '1TST0203'
//                             },
//                             {
//                               id: 'P1_8625',
//                               name: '1TST0204'
//                             },
//                             {
//                               id: 'P1_8626',
//                               name: '1TST0205'
//                             },
//                             {
//                               id: 'P1_8642',
//                               name: '1TST0207'
//                             },
//                             {
//                               id: 'P1_8646',
//                               name: '1TST0206'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7961',
//                           name: '1TST03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7962',
//                               name: '1TST0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3895',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3897',
//                           name: '1TBT03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1563',
//                               name: '1TBT0301'
//                             },
//                             {
//                               id: 'P1_3672',
//                               name: '1TBT0302'
//                             },
//                             {
//                               id: 'P1_4218',
//                               name: '1TBT0304'
//                             },
//                             {
//                               id: 'P1_4219',
//                               name: '1TBT0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6078',
//                           name: '1TBT12_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6079',
//                               name: '1TBT1201'
//                             },
//                             {
//                               id: 'P1_6355',
//                               name: '1TBT1203'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7859',
//                           name: '1TBT08_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7237',
//                               name: '1TBT0817'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_3969',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2215',
//                           name: '1SAW05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2216',
//                               name: '1SAW0501'
//                             },
//                             {
//                               id: 'P1_3642',
//                               name: '1SAW0502'
//                             },
//                             {
//                               id: 'P1_5772',
//                               name: '1SAW0503'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8334',
//                           name: '1SAW08_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7960',
//                               name: '1SAW0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5431',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6088',
//                           name: '1AVT02_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6089',
//                               name: '1AVT0208'
//                             },
//                             {
//                               id: 'P1_6090',
//                               name: '1AVT0209'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6070',
//                       name: 'BACKSIDE_TAPER_AUTO',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6071',
//                           name: '1BTG01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5430',
//                               name: '1BTG0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6770',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6745',
//                           name: '1PLM13_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6746',
//                               name: '1PLM1302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6774',
//                       name: 'SUPP_OVEN_MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4277',
//                           name: '1OVN15_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4278',
//                               name: '1OVN1522'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4349',
//                           name: '1OVN01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3717',
//                               name: '1OVN0187'
//                             },
//                             {
//                               id: 'P1_4427',
//                               name: '1OVN0152'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7293',
//                       name: 'WIRE_BOND_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2320',
//                           name: '1WBD07_F13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2367',
//                               name: '1WBD0705'
//                             },
//                             {
//                               id: 'P1_3652',
//                               name: '1WBD0714'
//                             },
//                             {
//                               id: 'P1_3836',
//                               name: '1WBD0717'
//                             },
//                             {
//                               id: 'P1_5710',
//                               name: '1WBD0744'
//                             },
//                             {
//                               id: 'P1_6721',
//                               name: '1WBD0753'
//                             },
//                             {
//                               id: 'P1_6753',
//                               name: '1WBD0754'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8643',
//                       name: 'DETAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1546',
//                           name: '1CFB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3780',
//                               name: '1CFB0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8644',
//                           name: '1CFB02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8645',
//                               name: '1CFB0201'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_4141',
//                   name: 'MF3 - COSA O',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4142',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4146',
//                           name: '2DAT10_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6499',
//                               name: '2DAT1005'
//                             },
//                             {
//                               id: 'P1_6500',
//                               name: '2DAT1006'
//                             },
//                             {
//                               id: 'P1_6501',
//                               name: '2DAT1007'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4143',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4255',
//                           name: '2MVI06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2816',
//                               name: '2MVI0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4677',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4680',
//                           name: '2SFF01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4682',
//                               name: '2SFF0102'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_4265',
//                   name: 'MF1 - OSLON NON-SSL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4266',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4271',
//                           name: '1CAS01_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1969',
//                               name: '1CAS0107'
//                             },
//                             {
//                               id: 'P1_2018',
//                               name: '1CAS0108'
//                             },
//                             {
//                               id: 'P1_2173',
//                               name: '1CAS0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4306',
//                           name: '1CAS04_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3617',
//                               name: '1CAS0421'
//                             },
//                             {
//                               id: 'P1_3932',
//                               name: '1CAS0427'
//                             },
//                             {
//                               id: 'P1_4734',
//                               name: '1CAS0437'
//                             },
//                             {
//                               id: 'P1_5805',
//                               name: '1CAS0441'
//                             },
//                             {
//                               id: 'P1_6027',
//                               name: '1CAS0448'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6525',
//                           name: '1CAS04_E12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3915',
//                               name: '1CAS0420'
//                             },
//                             {
//                               id: 'P1_6028',
//                               name: '1CAS0449'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8408',
//                           name: '1CAS03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1630',
//                               name: '1CAS0310'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4267',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4301',
//                           name: '1DAT05_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1694',
//                               name: '1DAT0547'
//                             },
//                             {
//                               id: 'P1_1697',
//                               name: '1DAT0536'
//                             },
//                             {
//                               id: 'P1_3739',
//                               name: '1DAT0555'
//                             },
//                             {
//                               id: 'P1_3740',
//                               name: '1DAT0556'
//                             },
//                             {
//                               id: 'P1_4302',
//                               name: '1DAT0570'
//                             },
//                             {
//                               id: 'P1_4303',
//                               name: '1DAT0571'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4268',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4304',
//                           name: '1DAT08_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3744',
//                               name: '1DAT0815'
//                             },
//                             {
//                               id: 'P1_4308',
//                               name: '1DAT0828'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5929',
//                           name: '1DAT08_F09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3742',
//                               name: '1DAT0806'
//                             },
//                             {
//                               id: 'P1_3745',
//                               name: '1DAT0822'
//                             },
//                             {
//                               id: 'P1_3928',
//                               name: '1DAT0821'
//                             },
//                             {
//                               id: 'P1_5930',
//                               name: '1DAT0844'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6127',
//                           name: '1DAT08_F10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3743',
//                               name: '1DAT0813'
//                             },
//                             {
//                               id: 'P1_6128',
//                               name: '1DAT0848'
//                             },
//                             {
//                               id: 'P1_6130',
//                               name: '1DAT0850'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7956',
//                           name: '1DAT08_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3927',
//                               name: '1DAT0814'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8502',
//                           name: '1DAT08_F11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6129',
//                               name: '1DAT0849'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4269',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4315',
//                           name: '1LYT01_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3925',
//                               name: '1LYT0127'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4317',
//                           name: '1LYT03_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3930',
//                               name: '1LYT0305'
//                             },
//                             {
//                               id: 'P1_3931',
//                               name: '1LYT0304'
//                             },
//                             {
//                               id: 'P1_4781',
//                               name: '1LYT0306'
//                             },
//                             {
//                               id: 'P1_4782',
//                               name: '1LYT0307'
//                             },
//                             {
//                               id: 'P1_5422',
//                               name: '1LYT0308'
//                             },
//                             {
//                               id: 'P1_5423',
//                               name: '1LYT0309'
//                             },
//                             {
//                               id: 'P1_6017',
//                               name: '1LYT0310'
//                             },
//                             {
//                               id: 'P1_6018',
//                               name: '1LYT0311'
//                             },
//                             {
//                               id: 'P1_6019',
//                               name: '1LYT0312'
//                             },
//                             {
//                               id: 'P1_6190',
//                               name: '1LYT0313'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7903',
//                           name: '1LYT02_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2007',
//                               name: '1LYT0205'
//                             },
//                             {
//                               id: 'P1_2009',
//                               name: '1LYT0207'
//                             },
//                             {
//                               id: 'P1_2014',
//                               name: '1LYT0217'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4270',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4289',
//                           name: '1OVN01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6649',
//                               name: '1OVN0142'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4291',
//                           name: '1OVN15_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4292',
//                               name: '1OVN1510'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4294',
//                           name: '1OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4295',
//                               name: '1OVN0138'
//                             },
//                             {
//                               id: 'P1_4296',
//                               name: '1OVN0139'
//                             },
//                             {
//                               id: 'P1_4297',
//                               name: '1OVN0140'
//                             },
//                             {
//                               id: 'P1_4299',
//                               name: '1OVN0181'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4339',
//                           name: '1OVN15_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4340',
//                               name: '1OVN1513'
//                             },
//                             {
//                               id: 'P1_4341',
//                               name: '1OVN1514'
//                             },
//                             {
//                               id: 'P1_5485',
//                               name: '1OVN1525'
//                             },
//                             {
//                               id: 'P1_5486',
//                               name: '1OVN1526'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4355',
//                           name: '1OVN01_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4300',
//                               name: '1OVN0182'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6106',
//                           name: '1OVN01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6034',
//                               name: '1OVN0189'
//                             },
//                             {
//                               id: 'P1_6581',
//                               name: '1OVN0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6545',
//                           name: '1OVN01_E15',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4298',
//                               name: '1OVN0157'
//                             },
//                             {
//                               id: 'P1_4904',
//                               name: '1OVN0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6554',
//                           name: '1OVN15_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6025',
//                               name: '1OVN1533'
//                             },
//                             {
//                               id: 'P1_6026',
//                               name: '1OVN1534'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7201',
//                           name: '1OVN15_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6023',
//                               name: '1OVN1531'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4272',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4312',
//                           name: '1SAW03_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2166',
//                               name: '1SAW0316'
//                             },
//                             {
//                               id: 'P1_2254',
//                               name: '1SAW0310'
//                             },
//                             {
//                               id: 'P1_2260',
//                               name: '1SAW0317'
//                             },
//                             {
//                               id: 'P1_2261',
//                               name: '1SAW0318'
//                             },
//                             {
//                               id: 'P1_2262',
//                               name: '1SAW0319'
//                             },
//                             {
//                               id: 'P1_5540',
//                               name: '1SAW0346'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6114',
//                           name: '1SAW03_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6467',
//                               name: '1SAW0349'
//                             },
//                             {
//                               id: 'P1_6468',
//                               name: '1SAW0350'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4273',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4318',
//                           name: '1SFB02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1185',
//                               name: '1SFB0206'
//                             },
//                             {
//                               id: 'P1_2471',
//                               name: '1SFB0226'
//                             },
//                             {
//                               id: 'P1_2472',
//                               name: '1SFB0227'
//                             },
//                             {
//                               id: 'P1_3678',
//                               name: '1SFB0228'
//                             },
//                             {
//                               id: 'P1_3679',
//                               name: '1SFB0231'
//                             },
//                             {
//                               id: 'P1_3687',
//                               name: '1SFB0232'
//                             },
//                             {
//                               id: 'P1_4138',
//                               name: '1SFB0233'
//                             },
//                             {
//                               id: 'P1_5424',
//                               name: '1SFB0239'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6212',
//                           name: '1SFB04_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6213',
//                               name: '1SFB0406'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4274',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4319',
//                           name: '1TBT02_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1199',
//                               name: '1TBT0204'
//                             },
//                             {
//                               id: 'P1_2473',
//                               name: '1TBT0226'
//                             },
//                             {
//                               id: 'P1_3680',
//                               name: '1TBT0227'
//                             },
//                             {
//                               id: 'P1_3824',
//                               name: '1TBT0229'
//                             },
//                             {
//                               id: 'P1_4202',
//                               name: '1TBT0233'
//                             },
//                             {
//                               id: 'P1_5425',
//                               name: '1TBT0236'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6466',
//                           name: '1FBT02_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6116',
//                               name: '1FBT0202'
//                             },
//                             {
//                               id: 'P1_6117',
//                               name: '1FBT0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4275',
//                       name: 'THERMOGRAPHY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4320',
//                           name: '1TMG02_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1834',
//                               name: '1TMG0201'
//                             },
//                             {
//                               id: 'P1_3945',
//                               name: '1TMG0204'
//                             },
//                             {
//                               id: 'P1_3946',
//                               name: '1TMG0205'
//                             },
//                             {
//                               id: 'P1_4321',
//                               name: '1TMG0207'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4281',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4324',
//                           name: '1WBD07_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3723',
//                               name: '1WBD0709'
//                             },
//                             {
//                               id: 'P1_3724',
//                               name: '1WBD0710'
//                             },
//                             {
//                               id: 'P1_3725',
//                               name: '1WBD0711'
//                             },
//                             {
//                               id: 'P1_3726',
//                               name: '1WBD0712'
//                             },
//                             {
//                               id: 'P1_4779',
//                               name: '1WBD0731'
//                             },
//                             {
//                               id: 'P1_4780',
//                               name: '1WBD0732'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6503',
//                           name: '1WBD07_F12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3728',
//                               name: '1WBD0707'
//                             },
//                             {
//                               id: 'P1_4327',
//                               name: '1WBD0720'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4283',
//                       name: 'LAMINATION_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4314',
//                           name: '1MTR02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5215',
//                               name: '1MTR0204'
//                             },
//                             {
//                               id: 'P1_5905',
//                               name: '1MTR0202'
//                             },
//                             {
//                               id: 'P1_6780',
//                               name: '1MTR0221'
//                             },
//                             {
//                               id: 'P1_7550',
//                               name: '1MTR0214'
//                             },
//                             {
//                               id: 'P1_7587',
//                               name: '1MTR0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4284',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5986',
//                           name: '1AVI02_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5541',
//                               name: '1AVI0229'
//                             },
//                             {
//                               id: 'P1_5542',
//                               name: '1AVI0230'
//                             },
//                             {
//                               id: 'P1_5891',
//                               name: '1AVI0232'
//                             },
//                             {
//                               id: 'P1_5892',
//                               name: '1AVI0233'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6050',
//                           name: '1AVI02_F09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6039',
//                               name: '1AVI0239'
//                             },
//                             {
//                               id: 'P1_6755',
//                               name: '1AVI0224'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7579',
//                           name: '1AVI01_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1905',
//                               name: '1AVI0102'
//                             },
//                             {
//                               id: 'P1_1996',
//                               name: '1AVI0105'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4285',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4342',
//                           name: '1MVI01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2199',
//                               name: '1MVI0105'
//                             },
//                             {
//                               id: 'P1_2201',
//                               name: '1MVI0108'
//                             },
//                             {
//                               id: 'P1_2454',
//                               name: '1MVI0128'
//                             },
//                             {
//                               id: 'P1_5308',
//                               name: '1MVI0138'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4286',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4331',
//                           name: '1GBB01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4332',
//                               name: '1GBB0103'
//                             },
//                             {
//                               id: 'P1_5498',
//                               name: '1GBB0107'
//                             },
//                             {
//                               id: 'P1_5650',
//                               name: '1GBB0108'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4287',
//                       name: 'SUPP_CYBER_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6205',
//                           name: '1CYS03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5420',
//                               name: '1CYS0301'
//                             },
//                             {
//                               id: 'P1_6206',
//                               name: '1CYS0303'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4288',
//                       name: 'US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4328',
//                           name: '1USM03_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1912',
//                               name: '1USM0302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6236',
//                           name: '1USM03_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2026',
//                               name: '1USM0305'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6485',
//                           name: '1USM02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2128',
//                               name: '1USM0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6041',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6043',
//                           name: '1CFB01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6220',
//                               name: '1CFB0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6513',
//                       name: 'LASER_DICING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4311',
//                           name: '1LSR01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2306',
//                               name: '1LSR0101'
//                             },
//                             {
//                               id: 'P1_3851',
//                               name: '1LSR0102'
//                             },
//                             {
//                               id: 'P1_6046',
//                               name: '1LSR0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7199',
//                       name: 'CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7200',
//                           name: '1SAW03_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2162',
//                               name: '1SAW0335'
//                             },
//                             {
//                               id: 'P1_2264',
//                               name: '1SAW0321'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7243',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7203',
//                           name: '1AVT01_T07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3792',
//                               name: '1AVT0105'
//                             },
//                             {
//                               id: 'P1_6621',
//                               name: '1AVT0117'
//                             },
//                             {
//                               id: 'P1_7454',
//                               name: '1AVT0118'
//                             },
//                             {
//                               id: 'P1_7455',
//                               name: '1AVT0119'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7244',
//                           name: '1AVT01_T08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2218',
//                               name: '1AVT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7904',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4956',
//                           name: '1PFF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4957',
//                               name: '1PFF0201'
//                             },
//                             {
//                               id: 'P1_4958',
//                               name: '1PFF0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7906',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6488',
//                           name: '1PLM01_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1998',
//                               name: '1PLM0104'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_4478',
//                   name: 'MFX - LASERMARK',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4479',
//                       name: 'LASER_MARK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1521',
//                           name: '2LSS01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4824',
//                               name: '2LSS0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_1522',
//                           name: '2LSS02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1524',
//                               name: '2LSS0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4480',
//                           name: '2LPP01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2778',
//                               name: '2LPP0101'
//                             },
//                             {
//                               id: 'P1_2779',
//                               name: '2LPP0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4484',
//                           name: '2LPP03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2785',
//                               name: '2LPP0301'
//                             },
//                             {
//                               id: 'P1_6559',
//                               name: '2LPP0304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4485',
//                           name: '2LSS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2483',
//                               name: '2LSS0102'
//                             },
//                             {
//                               id: 'P1_2786',
//                               name: '2LSS0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5065',
//                           name: '2LSS01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5803',
//                               name: '2LSS0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5998',
//                           name: '2LPP03_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4953',
//                               name: '2LPP0302'
//                             },
//                             {
//                               id: 'P1_5999',
//                               name: '2LPP0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7449',
//                           name: '2LRR01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2782',
//                               name: '2LRR0101'
//                             },
//                             {
//                               id: 'P1_2784',
//                               name: '2LRR0103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7450',
//                           name: '2LRR02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2780',
//                               name: '2LRR0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7468',
//                           name: '2LSS03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8608',
//                               name: '2LSS0302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8396',
//                           name: '2LRR02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2781',
//                               name: '2LRR0202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8688',
//                           name: '1LSS04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8690',
//                               name: '1LSS0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8689',
//                           name: '1LSS05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8691',
//                               name: '1LSS0501'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_4737',
//                   name: 'MF2 - OWLP',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_3698',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2037',
//                           name: '2PFF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3699',
//                               name: '2PFF0201'
//                             },
//                             {
//                               id: 'P1_6980',
//                               name: '2PFF0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4738',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4747',
//                           name: '2PLM02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4748',
//                               name: '1PLM0202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6434',
//                           name: '2PLM04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4569',
//                               name: '2PLM0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4749',
//                       name: 'FOIL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4751',
//                           name: '2SFF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4825',
//                               name: '2SFF0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7395',
//                           name: '2SFF02_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6982',
//                               name: '2SFF0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4760',
//                       name: 'LAMINATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4761',
//                           name: '2LMN04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4762',
//                               name: '2LMN0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_4835',
//                           name: '2LMN03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3701',
//                               name: '2LMN0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4831',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_3981',
//                           name: '2LSC02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3982',
//                               name: '2LSC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4832',
//                       name: 'SUPP_DEBONDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4833',
//                           name: '2DTM01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3702',
//                               name: '2DTM0101'
//                             },
//                             {
//                               id: 'P1_7011',
//                               name: '2DTM0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4836',
//                       name: 'SUPP_UV_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4630',
//                           name: '2UVC02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4631',
//                               name: '2UVC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4954',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4959',
//                           name: '2OVN14_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4960',
//                               name: '2OVN1419'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5056',
//                       name: 'SUPP_SCC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5057',
//                           name: '2SCC02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5058',
//                               name: '2SCC0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5081',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5082',
//                           name: '2AVI02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4264',
//                               name: '2AVI0214'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5625',
//                           name: '2AVI02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3706',
//                               name: '2AVI0209'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5407',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5408',
//                           name: '1SAW03_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6613',
//                               name: '1SAW0351'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8306',
//                       name: 'SUPP_LAMINATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6104',
//                           name: '2LMN04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6105',
//                               name: '2LMN0402'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8506',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8507',
//                           name: '1TBT14_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8505',
//                               name: '1TBT1401'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5040',
//                   name: 'MF1 - FAM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_4683',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5233',
//                           name: '1TBT08_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5849',
//                               name: '1TBT0811'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5495',
//                           name: '1TBT08_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4395',
//                               name: '1TBT0801'
//                             },
//                             {
//                               id: 'P1_4952',
//                               name: '1TBT0804'
//                             },
//                             {
//                               id: 'P1_5232',
//                               name: '1TBT0807'
//                             },
//                             {
//                               id: 'P1_5800',
//                               name: '1TBT0810'
//                             },
//                             {
//                               id: 'P1_5975',
//                               name: '1TBT0812'
//                             },
//                             {
//                               id: 'P1_6556',
//                               name: '1TBT0816'
//                             },
//                             {
//                               id: 'P1_8470',
//                               name: '1TBT0818'
//                             },
//                             {
//                               id: 'P1_8638',
//                               name: '1TBT0820'
//                             },
//                             {
//                               id: 'P1_8639',
//                               name: '1TBT0821'
//                             },
//                             {
//                               id: 'P1_8640',
//                               name: '1TBT0822'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5041',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5043',
//                           name: '1LYT02_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1937',
//                               name: '1LYT0206'
//                             },
//                             {
//                               id: 'P1_1941',
//                               name: '1LYT0214'
//                             },
//                             {
//                               id: 'P1_2006',
//                               name: '1LYT0215'
//                             },
//                             {
//                               id: 'P1_2010',
//                               name: '1LYT0216'
//                             },
//                             {
//                               id: 'P1_2013',
//                               name: '1LYT0202'
//                             },
//                             {
//                               id: 'P1_3683',
//                               name: '1LYT0227'
//                             },
//                             {
//                               id: 'P1_3684',
//                               name: '1LYT0228'
//                             },
//                             {
//                               id: 'P1_3929',
//                               name: '1LYT0204'
//                             },
//                             {
//                               id: 'P1_5047',
//                               name: '1LYT0231'
//                             },
//                             {
//                               id: 'P1_5048',
//                               name: '1LYT0232'
//                             },
//                             {
//                               id: 'P1_5055',
//                               name: '1LYT0233'
//                             },
//                             {
//                               id: 'P1_5109',
//                               name: '1LYT0234'
//                             },
//                             {
//                               id: 'P1_5110',
//                               name: '1LYT0235'
//                             },
//                             {
//                               id: 'P1_8679',
//                               name: '1LYT0247'
//                             },
//                             {
//                               id: 'P1_8680',
//                               name: '1LYT0248'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6487',
//                           name: '1LYT01_E10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3921',
//                               name: '1LYT0126'
//                             },
//                             {
//                               id: 'P1_8592',
//                               name: '1LYT0131'
//                             },
//                             {
//                               id: 'P1_8593',
//                               name: '1LYT0132'
//                             },
//                             {
//                               id: 'P1_8594',
//                               name: '1LYT0133'
//                             },
//                             {
//                               id: 'P1_8600',
//                               name: '1LYT0134'
//                             },
//                             {
//                               id: 'P1_8601',
//                               name: '1LYT0135'
//                             },
//                             {
//                               id: 'P1_8602',
//                               name: '1LYT0136'
//                             },
//                             {
//                               id: 'P1_8603',
//                               name: '1LYT0137'
//                             },
//                             {
//                               id: 'P1_8604',
//                               name: '1LYT0138'
//                             },
//                             {
//                               id: 'P1_8605',
//                               name: '1LYT0139'
//                             },
//                             {
//                               id: 'P1_8606',
//                               name: '1LYT0140'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5042',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5044',
//                           name: '1WBD07_F10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3727',
//                               name: '1WBD0716'
//                             },
//                             {
//                               id: 'P1_3751',
//                               name: '1WBD0725'
//                             },
//                             {
//                               id: 'P1_4326',
//                               name: '1WBD0719'
//                             },
//                             {
//                               id: 'P1_5049',
//                               name: '1WBD0737'
//                             },
//                             {
//                               id: 'P1_5050',
//                               name: '1WBD0738'
//                             },
//                             {
//                               id: 'P1_5051',
//                               name: '1WBD0739'
//                             },
//                             {
//                               id: 'P1_5535',
//                               name: '1WBD0741'
//                             },
//                             {
//                               id: 'P1_5628',
//                               name: '1WBD0721'
//                             },
//                             {
//                               id: 'P1_5713',
//                               name: '1WBD0746'
//                             },
//                             {
//                               id: 'P1_8635',
//                               name: '1WBD0757'
//                             },
//                             {
//                               id: 'P1_8636',
//                               name: '1WBD0758'
//                             },
//                             {
//                               id: 'P1_8637',
//                               name: '1WBD0759'
//                             },
//                             {
//                               id: 'P1_8681',
//                               name: '1WBD0760'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5045',
//                       name: 'SUPP_CYBER_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5419',
//                           name: '1CYS03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5421',
//                               name: '1CYS0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5046',
//                       name: 'THERMOGRAPHY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1830',
//                           name: '1TMG02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5015',
//                               name: '1TMG0209'
//                             },
//                             {
//                               id: 'P1_5016',
//                               name: '1TMG0210'
//                             },
//                             {
//                               id: 'P1_5017',
//                               name: '1TMG0211'
//                             },
//                             {
//                               id: 'P1_5712',
//                               name: '1TMG0213'
//                             },
//                             {
//                               id: 'P1_5769',
//                               name: '1TMG0206'
//                             },
//                             {
//                               id: 'P1_5770',
//                               name: '1TMG0208'
//                             },
//                             {
//                               id: 'P1_8632',
//                               name: '1TMG0214'
//                             },
//                             {
//                               id: 'P1_8633',
//                               name: '1TMG0215'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5111',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5112',
//                           name: '1DAT08_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1693',
//                               name: '1DAT0803'
//                             },
//                             {
//                               id: 'P1_2237',
//                               name: '1DAT0801'
//                             },
//                             {
//                               id: 'P1_3689',
//                               name: '1DAT0808'
//                             },
//                             {
//                               id: 'P1_4305',
//                               name: '1DAT0826'
//                             },
//                             {
//                               id: 'P1_4777',
//                               name: '1DAT0831'
//                             },
//                             {
//                               id: 'P1_5113',
//                               name: '1DAT0835'
//                             },
//                             {
//                               id: 'P1_5454',
//                               name: '1DAT0838'
//                             },
//                             {
//                               id: 'P1_8042',
//                               name: '1DAT0855'
//                             },
//                             {
//                               id: 'P1_8445',
//                               name: '1DAT0856'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8501',
//                           name: '1DAT08_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4307',
//                               name: '1DAT0827'
//                             },
//                             {
//                               id: 'P1_4309',
//                               name: '1DAT0829'
//                             },
//                             {
//                               id: 'P1_4778',
//                               name: '1DAT0832'
//                             },
//                             {
//                               id: 'P1_5114',
//                               name: '1DAT0836'
//                             },
//                             {
//                               id: 'P1_5453',
//                               name: '1DAT0837'
//                             },
//                             {
//                               id: 'P1_7177',
//                               name: '1DAT0853'
//                             },
//                             {
//                               id: 'P1_8002',
//                               name: '1DAT0854'
//                             },
//                             {
//                               id: 'P1_8619',
//                               name: '1DAT0857'
//                             },
//                             {
//                               id: 'P1_8621',
//                               name: '1DAT0858'
//                             },
//                             {
//                               id: 'P1_8622',
//                               name: '1DAT0859'
//                             },
//                             {
//                               id: 'P1_8676',
//                               name: '1DAT0862'
//                             },
//                             {
//                               id: 'P1_8677',
//                               name: '1DAT0863'
//                             },
//                             {
//                               id: 'P1_8678',
//                               name: '1DAT0864'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5263',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5494',
//                           name: '1SBB07_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4394',
//                               name: '1SBB0701'
//                             },
//                             {
//                               id: 'P1_4951',
//                               name: '1SBB0702'
//                             },
//                             {
//                               id: 'P1_5231',
//                               name: '1SBB0703'
//                             },
//                             {
//                               id: 'P1_5799',
//                               name: '1SBB0706'
//                             },
//                             {
//                               id: 'P1_5974',
//                               name: '1SBB0708'
//                             },
//                             {
//                               id: 'P1_8469',
//                               name: '1SBB0711'
//                             },
//                             {
//                               id: 'P1_8641',
//                               name: '1SBB0712'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5402',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5403',
//                           name: '1AVI02_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5404',
//                               name: '1AVI0226'
//                             },
//                             {
//                               id: 'P1_5405',
//                               name: '1AVI0227'
//                             },
//                             {
//                               id: 'P1_5406',
//                               name: '1AVI0228'
//                             },
//                             {
//                               id: 'P1_5835',
//                               name: '1AVI0218'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5490',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5491',
//                           name: '1GBB01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4368',
//                               name: '1GBB0101'
//                             },
//                             {
//                               id: 'P1_5496',
//                               name: '1GBB0106'
//                             },
//                             {
//                               id: 'P1_5784',
//                               name: '1GBB0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5497',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5492',
//                           name: '1SAW03_E10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2239',
//                               name: '1SAW0330'
//                             },
//                             {
//                               id: 'P1_2255',
//                               name: '1SAW0311'
//                             },
//                             {
//                               id: 'P1_2257',
//                               name: '1SAW0313'
//                             },
//                             {
//                               id: 'P1_2259',
//                               name: '1SAW0315'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5509',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6481',
//                           name: '1OVN14_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6482',
//                               name: '1OVN1419'
//                             },
//                             {
//                               id: 'P1_6483',
//                               name: '1OVN1420'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5984',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5985',
//                           name: '1FAM01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5699',
//                               name: '1FAM0101'
//                             },
//                             {
//                               id: 'P1_7231',
//                               name: '1FAM0102'
//                             },
//                             {
//                               id: 'P1_8776',
//                               name: '1FAM0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6207',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6032',
//                           name: '1PLM01_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1048',
//                               name: '1PLM0108'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6208',
//                           name: '1PLM04_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6209',
//                               name: '1PLM0405'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6453',
//                           name: '1PLM02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4338',
//                               name: '1PLM0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6766',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6767',
//                           name: '1PFF02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6768',
//                               name: '1PFF0203'
//                             },
//                             {
//                               id: 'P1_8609',
//                               name: '1PFF0204'
//                             },
//                             {
//                               id: 'P1_8631',
//                               name: '1PFF0205'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6801',
//                       name: '2D_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6803',
//                           name: '1AVI02_F13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6051',
//                               name: '1AVI0225'
//                             },
//                             {
//                               id: 'P1_8422',
//                               name: '1AVI0244'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6932',
//                       name: 'WET_BLASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6933',
//                           name: '1WBL01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5979',
//                               name: '1WBL0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7245',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7246',
//                           name: '1AVT01_T09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6275',
//                               name: '1AVT0116'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7642',
//                       name: 'LAMINATION_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7643',
//                           name: '1MTR01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7549',
//                               name: '1MTR0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8785',
//                       name: 'SUPP_FREEZER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8786',
//                           name: '1FRZ14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8787',
//                               name: '1FRZ1401'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5120',
//                   name: 'MF1 - OBF NON-AUTO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5194',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1855',
//                           name: '1WBD06_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1614',
//                               name: '1WBD0631'
//                             },
//                             {
//                               id: 'P1_1889',
//                               name: '1WBD0636'
//                             },
//                             {
//                               id: 'P1_1890',
//                               name: '1WBD0634'
//                             },
//                             {
//                               id: 'P1_1891',
//                               name: '1WBD0633'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3657',
//                           name: '1WBD07_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3735',
//                               name: '1WBD0718'
//                             },
//                             {
//                               id: 'P1_8798',
//                               name: '1WBD0768'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5195',
//                       name: 'DIE_ATTACH_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7413',
//                           name: '1DAT05_F17',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1701',
//                               name: '1DAT0512'
//                             },
//                             {
//                               id: 'P1_3738',
//                               name: '1DAT0561'
//                             },
//                             {
//                               id: 'P1_5523',
//                               name: '1DAT0581'
//                             },
//                             {
//                               id: 'P1_6489',
//                               name: '1DAT0566'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5197',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5216',
//                           name: '1SAW03_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2256',
//                               name: '1SAW0312'
//                             },
//                             {
//                               id: 'P1_2258',
//                               name: '1SAW0314'
//                             },
//                             {
//                               id: 'P1_2269',
//                               name: '1SAW0340'
//                             },
//                             {
//                               id: 'P1_3868',
//                               name: '1SAW0341'
//                             },
//                             {
//                               id: 'P1_3911',
//                               name: '1SAW0308'
//                             },
//                             {
//                               id: 'P1_3912',
//                               name: '1SAW0336'
//                             },
//                             {
//                               id: 'P1_4313',
//                               name: '1SAW0342'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5198',
//                       name: 'COMPRESSION_MOLD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5208',
//                           name: '1CMD01_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1647',
//                               name: '1CMD0111'
//                             },
//                             {
//                               id: 'P1_1648',
//                               name: '1CMD0103'
//                             },
//                             {
//                               id: 'P1_1649',
//                               name: '1CMD0101'
//                             },
//                             {
//                               id: 'P1_2274',
//                               name: '1CMD0112'
//                             },
//                             {
//                               id: 'P1_2275',
//                               name: '1CMD0114'
//                             },
//                             {
//                               id: 'P1_3654',
//                               name: '1CMD0116'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5199',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5336',
//                           name: '1OVN15_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5437',
//                               name: '1OVN1501'
//                             },
//                             {
//                               id: 'P1_5438',
//                               name: '1OVN1502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6285',
//                           name: '1OVN01_E13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4249',
//                               name: '1OVN0155'
//                             },
//                             {
//                               id: 'P1_5211',
//                               name: '1OVN0154'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6711',
//                           name: '1OVN15_F09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4293',
//                               name: '1OVN1521'
//                             },
//                             {
//                               id: 'P1_5338',
//                               name: '1OVN1520'
//                             },
//                             {
//                               id: 'P1_5727',
//                               name: '1OVN1528'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5202',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5204',
//                           name: '1AVI02_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5961',
//                               name: '1AVI0235'
//                             },
//                             {
//                               id: 'P1_6548',
//                               name: '1AVI0209'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5206',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5207',
//                           name: '1PLM01_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1624',
//                               name: '1PLM0113'
//                             },
//                             {
//                               id: 'P1_4160',
//                               name: '1PLM0118'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5224',
//                           name: '1PLM01_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1911',
//                               name: '1PLM0114'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5217',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5218',
//                           name: '1MVI01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2189',
//                               name: '1MVI0112'
//                             },
//                             {
//                               id: 'P1_2192',
//                               name: '1MVI0119'
//                             },
//                             {
//                               id: 'P1_2455',
//                               name: '1MVI0133'
//                             },
//                             {
//                               id: 'P1_2458',
//                               name: '1MVI0129'
//                             },
//                             {
//                               id: 'P1_5219',
//                               name: '1MVI0137'
//                             },
//                             {
//                               id: 'P1_5220',
//                               name: '1MVI0136'
//                             },
//                             {
//                               id: 'P1_5312',
//                               name: '1MVI0139'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6914',
//                           name: '1AVI13_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6915',
//                               name: '1AVI1301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5316',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5317',
//                           name: '1LYT01_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1638',
//                               name: '1LYT0101'
//                             },
//                             {
//                               id: 'P1_2038',
//                               name: '1LYT0123'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5439',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7897',
//                           name: '1SFB02_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1193',
//                               name: '1SFB0214'
//                             },
//                             {
//                               id: 'P1_3685',
//                               name: '1SFB0229'
//                             },
//                             {
//                               id: 'P1_3686',
//                               name: '1SFB0230'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5440',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5443',
//                           name: '1TBT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1343',
//                               name: '1TBT0401'
//                             },
//                             {
//                               id: 'P1_2469',
//                               name: '1TBT0405'
//                             },
//                             {
//                               id: 'P1_3681',
//                               name: '1TBT0407'
//                             },
//                             {
//                               id: 'P1_3789',
//                               name: '1TBT0408'
//                             },
//                             {
//                               id: 'P1_6683',
//                               name: '1TBT0412'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5444',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5445',
//                           name: '1AVT01_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4208',
//                               name: '1AVT0115'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7896',
//                           name: '1AVT01_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3784',
//                               name: '1AVT0112'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5512',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5513',
//                           name: '1CFB01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5763',
//                               name: '1CFB0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5662',
//                       name: 'SUPP_DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7183',
//                           name: '1DTR06_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7184',
//                               name: '1DTR0604'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7190',
//                       name: 'BACKSIDE_TAPER_MANUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7191',
//                           name: '1BTG01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5559',
//                               name: '1BTG0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7227',
//                       name: 'DIE_ATTACH_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5200',
//                           name: '1DAT05_F10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1602',
//                               name: '1DAT0544'
//                             },
//                             {
//                               id: 'P1_1730',
//                               name: '1DAT0549'
//                             },
//                             {
//                               id: 'P1_1732',
//                               name: '1DAT0548'
//                             },
//                             {
//                               id: 'P1_1733',
//                               name: '1DAT0546'
//                             },
//                             {
//                               id: 'P1_3736',
//                               name: '1DAT0559'
//                             },
//                             {
//                               id: 'P1_3737',
//                               name: '1DAT0560'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7232',
//                       name: 'TRANSFER_MOLD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5436',
//                           name: '1TMD03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4665',
//                               name: '1TMD0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7384',
//                       name: 'BACKSIDE_TAPER_AUTO',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7385',
//                           name: '1BTG01_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7386',
//                               name: '1BTG0105'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8255',
//                       name: 'SUPP_LAMINATION_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8256',
//                           name: '1MTR02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4336',
//                               name: '1MTR0207'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8733',
//                       name: 'SUPP_OVEN_DRYBAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8734',
//                           name: '1OVN01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7946',
//                               name: '1OVN0129'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5339',
//                   name: 'MF2 - SMT_DRAGON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5340',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5341',
//                           name: '2VRR02_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2756',
//                               name: '2VRR0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5342',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5343',
//                           name: '2STT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2687',
//                               name: '2STT0101'
//                             },
//                             {
//                               id: 'P1_2690',
//                               name: '2STT0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5344',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5345',
//                           name: '2TTT03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1313',
//                               name: '2TTT0303'
//                             },
//                             {
//                               id: 'P1_2736',
//                               name: '2TTT0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5347',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7672',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5068',
//                               name: '2OVN2001'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5352',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5353',
//                           name: '2DTR04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2572',
//                               name: '2DTR0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5354',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5606',
//                           name: '2FRB03_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5576',
//                               name: '2FRB0321'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5359',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5398',
//                           name: '2LYT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3017',
//                               name: '2LYT0107'
//                             },
//                             {
//                               id: 'P1_3018',
//                               name: '2LYT0108'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5384',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5386',
//                           name: '2DAT18_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3183',
//                               name: '2DAT1802'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5387',
//                           name: '2DAT20_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3189',
//                               name: '2DAT2031'
//                             },
//                             {
//                               id: 'P1_3190',
//                               name: '2DAT2032'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5388',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5389',
//                           name: '2MVI03_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3158',
//                               name: '2MVI0308'
//                             },
//                             {
//                               id: 'P1_3159',
//                               name: '2MVI0309'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5391',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5392',
//                           name: '2WBD05_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3168',
//                               name: '2WBD0509'
//                             },
//                             {
//                               id: 'P1_3169',
//                               name: '2WBD0510'
//                             },
//                             {
//                               id: 'P1_3170',
//                               name: '2WBD0511'
//                             },
//                             {
//                               id: 'P1_3171',
//                               name: '2WBD0512'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5395',
//                           name: '2WBD03_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3142',
//                               name: '2WBD0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5516',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5517',
//                           name: '2OVN21_E06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3044',
//                               name: '2OVN2129'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5702',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5706',
//                           name: '2CAS13_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5363',
//                               name: '2CAS1318'
//                             },
//                             {
//                               id: 'P1_5364',
//                               name: '2CAS1317'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5789',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5790',
//                           name: '2OVN01_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3029',
//                               name: '2OVN0102'
//                             },
//                             {
//                               id: 'P1_5349',
//                               name: '2OVN0107'
//                             },
//                             {
//                               id: 'P1_5350',
//                               name: '2OVN0108'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7669',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7670',
//                           name: '2MVI19_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7604',
//                               name: '2MVI1901'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7671',
//                           name: '2MVI20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7606',
//                               name: '2MVI2001'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5371',
//                   name: 'MF1 - RADIAL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5372',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5373',
//                           name: '2FSB05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5132',
//                               name: '2FSB0501'
//                             },
//                             {
//                               id: 'P1_5133',
//                               name: '2FSB0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5648',
//                           name: '2FSB07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5649',
//                               name: '2FSB0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5910',
//                           name: '2FSB02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5137',
//                               name: '2FSB0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5375',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5376',
//                           name: '2TBT10_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5154',
//                               name: '2TBT1003'
//                             },
//                             {
//                               id: 'P1_5155',
//                               name: '2TBT1004'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5377',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5379',
//                           name: '2SBB10_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5140',
//                               name: '2SBB1001'
//                             },
//                             {
//                               id: 'P1_5141',
//                               name: '2SBB1005'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5380',
//                       name: 'REEL TEST TO BULK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5381',
//                           name: '2RTB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5143',
//                               name: '2RTB0103'
//                             },
//                             {
//                               id: 'P1_5144',
//                               name: '2RTB0106'
//                             },
//                             {
//                               id: 'P1_5145',
//                               name: '2RTB0107'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5382',
//                           name: '2RTB02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5147',
//                               name: '2RTB0210'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5448',
//                       name: 'MANUAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5449',
//                           name: '2MTE03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5149',
//                               name: '2MTE0301'
//                             },
//                             {
//                               id: 'P1_5150',
//                               name: '2MTE0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5450',
//                       name: 'MANUAL_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5451',
//                           name: '2MVT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5157',
//                               name: '2MVT0402'
//                             },
//                             {
//                               id: 'P1_5159',
//                               name: '2MVT0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6053',
//                       name: 'SUPP_MVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6054',
//                           name: '2MVI01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3515',
//                               name: '2MVI0130'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6567',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6568',
//                           name: '2CAS18_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6295',
//                               name: '2CAS1801'
//                             },
//                             {
//                               id: 'P1_6296',
//                               name: '2CAS1802'
//                             },
//                             {
//                               id: 'P1_6297',
//                               name: '2CAS1803'
//                             },
//                             {
//                               id: 'P1_6298',
//                               name: '2CAS1804'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6698',
//                           name: '2CAS22_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6308',
//                               name: '2CAS2201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6571',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6572',
//                           name: '2OVN49_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6313',
//                               name: '2OVN4901'
//                             },
//                             {
//                               id: 'P1_6314',
//                               name: '2OVN4902'
//                             },
//                             {
//                               id: 'P1_6315',
//                               name: '2OVN4903'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6573',
//                           name: '2OVN50_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6317',
//                               name: '2OVN5001'
//                             },
//                             {
//                               id: 'P1_6318',
//                               name: '2OVN5002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6605',
//                           name: '2OVN48_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6310',
//                               name: '2OVN4801'
//                             },
//                             {
//                               id: 'P1_6311',
//                               name: '2OVN4802'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8739',
//                           name: '1OVN59_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8738',
//                               name: '1OVN5901'
//                             },
//                             {
//                               id: 'P1_8745',
//                               name: '1OVN5904'
//                             },
//                             {
//                               id: 'P1_8746',
//                               name: '1OVN5905'
//                             },
//                             {
//                               id: 'P1_8747',
//                               name: '1OVN5906'
//                             },
//                             {
//                               id: 'P1_8748',
//                               name: '1OVN5907'
//                             },
//                             {
//                               id: 'P1_8749',
//                               name: '1OVN5908'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6603',
//                       name: 'CUT_TIE_BAR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6604',
//                           name: '2TBR01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6303',
//                               name: '2TBR0101'
//                             },
//                             {
//                               id: 'P1_6304',
//                               name: '2TBR0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6699',
//                       name: 'SUPP_SILICONE_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6700',
//                           name: '2OVN51_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6320',
//                               name: '2OVN5101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8630',
//                           name: '2OVN01_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4849',
//                               name: '1OVN0169'
//                             },
//                             {
//                               id: 'P1_5169',
//                               name: '2OVN0192'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6734',
//                       name: 'SUPP_GLUE_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6821',
//                           name: '2OVN01_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6350',
//                               name: '2OVN0130'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6828',
//                           name: '2OVN51_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6346',
//                               name: '2OVN5102'
//                             },
//                             {
//                               id: 'P1_6347',
//                               name: '2OVN5103'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6843',
//                           name: '2OVN01_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6351',
//                               name: '2OVN0131'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8752',
//                           name: '1OVN59_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8753',
//                               name: '1OVN5902'
//                             },
//                             {
//                               id: 'P1_8754',
//                               name: '1OVN5903'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6824',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6826',
//                           name: '2DAT03_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6345',
//                               name: '2DAT0317'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6827',
//                           name: '2DAT26_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6338',
//                               name: '2DAT2601'
//                             },
//                             {
//                               id: 'P1_6339',
//                               name: '2DAT2602'
//                             },
//                             {
//                               id: 'P1_6341',
//                               name: '2DAT2603'
//                             },
//                             {
//                               id: 'P1_6342',
//                               name: '2DAT2604'
//                             },
//                             {
//                               id: 'P1_6343',
//                               name: '2DAT2605'
//                             },
//                             {
//                               id: 'P1_6344',
//                               name: '2DAT2606'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6829',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6831',
//                           name: '2WBD24_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6331',
//                               name: '2WBD2402'
//                             },
//                             {
//                               id: 'P1_6332',
//                               name: '2WBD2403'
//                             },
//                             {
//                               id: 'P1_6333',
//                               name: '2WBD2404'
//                             },
//                             {
//                               id: 'P1_6334',
//                               name: '2WBD2405'
//                             },
//                             {
//                               id: 'P1_6335',
//                               name: '2WBD2406'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6832',
//                           name: '2WBD25_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6337',
//                               name: '2WBD2501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7899',
//                       name: 'RADIAL_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7629',
//                           name: '1MVI22_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7633',
//                               name: '1MVI2201'
//                             },
//                             {
//                               id: 'P1_7634',
//                               name: '1MVI2202'
//                             },
//                             {
//                               id: 'P1_7635',
//                               name: '1MVI2203'
//                             },
//                             {
//                               id: 'P1_7636',
//                               name: '1MVI2204'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7630',
//                           name: '1MVI23_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7637',
//                               name: '1MVI2301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7631',
//                           name: '1MVI24_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7638',
//                               name: '1MVI2401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7900',
//                           name: '1MVI25_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7639',
//                               name: '1MVI2501'
//                             },
//                             {
//                               id: 'P1_7640',
//                               name: '1MVI2502'
//                             },
//                             {
//                               id: 'P1_7641',
//                               name: '1MVI2503'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8139',
//                       name: 'SUPP_MANUAL_PACKING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5828',
//                           name: '2MPA01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5822',
//                               name: '2MPA0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8486',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5006',
//                           name: '1MVI33_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8491',
//                               name: '1MVI3301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5007',
//                           name: '1MVI22_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8487',
//                               name: '1MVI2205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5010',
//                           name: '1MVI34_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8492',
//                               name: '1MVI3401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5011',
//                           name: '1MVI31_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8488',
//                               name: '1MVI3101'
//                             },
//                             {
//                               id: 'P1_8489',
//                               name: '1MVI3102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7620',
//                           name: '1MVI32_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8490',
//                               name: '1MVI3201'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5458',
//                   name: 'MF1 - LASER_HPL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5459',
//                       name: 'MANUAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5460',
//                           name: '2MTE04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5461',
//                               name: '2MTE0401'
//                             },
//                             {
//                               id: 'P1_5463',
//                               name: '2MTE0403'
//                             },
//                             {
//                               id: 'P1_5464',
//                               name: '2MTE0404'
//                             },
//                             {
//                               id: 'P1_5470',
//                               name: '2MTE0405'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5468',
//                           name: '2MTE05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5469',
//                               name: '2MTE0502'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5474',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6606',
//                           name: '2SIN07_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6324',
//                               name: '2SIN0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8407',
//                           name: '2SIN06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5476',
//                               name: '2SIN0602'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6569',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6570',
//                           name: '2CAS20_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6322',
//                               name: '2CAS2001'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6607',
//                           name: '2CAS19_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6326',
//                               name: '2CAS1901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6574',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6575',
//                           name: '2OVN02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6301',
//                               name: '2OVN0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6840',
//                           name: '2OVN47_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6328',
//                               name: '2OVN4701'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6815',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6816',
//                           name: '2DAT06_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6349',
//                               name: '2DAT0606'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6817',
//                           name: '2DAT07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6352',
//                               name: '2DAT0701'
//                             },
//                             {
//                               id: 'P1_6353',
//                               name: '2DAT0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6825',
//                           name: '2DAT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6340',
//                               name: '2DAT0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6818',
//                       name: '100% 3RD OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6819',
//                           name: '2MVI18_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6427',
//                               name: '2MVI1801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6822',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6823',
//                           name: '2WBD06_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6348',
//                               name: '2WBD0618'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6830',
//                           name: '2WBD23_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6336',
//                               name: '2WBD2301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8250',
//                       name: 'SUPP_MANUAL_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5466',
//                           name: '2MVT03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5473',
//                               name: '2MVT0305'
//                             },
//                             {
//                               id: 'P1_8358',
//                               name: '2MVT0205'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5806',
//                   name: 'MF3 - MINNEAPOLIS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7461',
//                       name: 'MANUAL_INSPECTION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7462',
//                           name: '3MVI04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7463',
//                               name: '3MVI0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8222',
//                       name: 'SUPP_CONTACT_ANGLE_MEASUREMENT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6808',
//                           name: '3CAM01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6809',
//                               name: '3CAM0101'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_5894',
//                   name: 'MF2 - SMT_TOPLED C241',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5897',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5898',
//                           name: '2FRB06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2616',
//                               name: '2FRB0629'
//                             },
//                             {
//                               id: 'P1_2617',
//                               name: '2FRB0631'
//                             },
//                             {
//                               id: 'P1_2618',
//                               name: '2FRB0632'
//                             },
//                             {
//                               id: 'P1_2619',
//                               name: '2FRB0633'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5899',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5900',
//                           name: '2SBB06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2679',
//                               name: '2SBB0605'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8384',
//                           name: '2SBB04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2420',
//                               name: '2SBB0405'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5901',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5902',
//                           name: '2BST03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2540',
//                               name: '2BST0311'
//                             },
//                             {
//                               id: 'P1_2542',
//                               name: '2BST0313'
//                             },
//                             {
//                               id: 'P1_2545',
//                               name: '2BST0316'
//                             },
//                             {
//                               id: 'P1_2546',
//                               name: '2BST0317'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5903',
//                           name: '2BST04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2560',
//                               name: '2BST0409'
//                             },
//                             {
//                               id: 'P1_2564',
//                               name: '2BST0414'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7551',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7707',
//                           name: '2DAT03_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3239',
//                               name: '2DAT0303'
//                             },
//                             {
//                               id: 'P1_3240',
//                               name: '2DAT0304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7708',
//                           name: '2DAT20_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4425',
//                               name: '2DAT2078'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7700',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7701',
//                           name: '2OVN01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4227',
//                               name: '2OVN0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7702',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5070',
//                               name: '2OVN2003'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7703',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7704',
//                           name: '2CAS10_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3061',
//                               name: '2CAS1013'
//                             },
//                             {
//                               id: 'P1_3063',
//                               name: '2CAS1015'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7709',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7710',
//                           name: '2GBB03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5725',
//                               name: '2GBB0312'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7711',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7712',
//                           name: '2OVN01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3284',
//                               name: '2OVN0157'
//                             },
//                             {
//                               id: 'P1_3287',
//                               name: '2OVN0160'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7713',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7714',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4180',
//                               name: '2OVN0114'
//                             },
//                             {
//                               id: 'P1_4183',
//                               name: '2OVN0124'
//                             },
//                             {
//                               id: 'P1_4193',
//                               name: '2OVN0115'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7715',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7716',
//                           name: '2WBD07_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5246',
//                               name: '2WBD0722'
//                             },
//                             {
//                               id: 'P1_5247',
//                               name: '2WBD0723'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7717',
//                           name: '2WBD17_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3359',
//                               name: '2WBD1718'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_6047',
//                   name: 'MF1 - VALCO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_6551',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6552',
//                           name: '1OVN01_F14',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4290',
//                               name: '1OVN0175'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6782',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6781',
//                           name: '1AVI02_F12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5543',
//                               name: '1AVI0231'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_6844',
//                   name: 'MF2 - SMT_SIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_6845',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6846',
//                           name: '2VRR01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2745',
//                               name: '2VRR0110'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6906',
//                           name: '2VRR02_T07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5583',
//                               name: '2VRR0229'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6847',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6849',
//                           name: '2CAS13_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5612',
//                               name: '2CAS1320'
//                             },
//                             {
//                               id: 'P1_7165',
//                               name: '2CAS1324'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6850',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6907',
//                           name: '2OVN21_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4166',
//                               name: '2OVN2114'
//                             },
//                             {
//                               id: 'P1_4168',
//                               name: '2OVN2124'
//                             },
//                             {
//                               id: 'P1_4174',
//                               name: '2OVN2116'
//                             },
//                             {
//                               id: 'P1_4175',
//                               name: '2OVN2113'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7559',
//                           name: '2OVN01_E11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4625',
//                               name: '2OVN0148'
//                             },
//                             {
//                               id: 'P1_7589',
//                               name: '2OVN0136'
//                             },
//                             {
//                               id: 'P1_7590',
//                               name: '2OVN0145'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6851',
//                       name: 'DB_WB_M01',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6852',
//                           name: '2OVN28_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3322',
//                               name: '2OVN2805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6853',
//                           name: '2WBD15_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3336',
//                               name: '2WBD1502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6908',
//                           name: '2DAT20_F07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3405',
//                               name: '2DAT2005'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6854',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6587',
//                           name: '2DAT22_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5949',
//                               name: '2DAT2207'
//                             },
//                             {
//                               id: 'P1_5950',
//                               name: '2DAT2208'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7689',
//                           name: '2DAT13_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3222',
//                               name: '2DAT1307'
//                             },
//                             {
//                               id: 'P1_3223',
//                               name: '2DAT1308'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6856',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6910',
//                           name: '2CAS13_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3494',
//                               name: '2CAS1309'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6857',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6858',
//                           name: '2FRB01_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2583',
//                               name: '2FRB0113'
//                             },
//                             {
//                               id: 'P1_2602',
//                               name: '2FRB0145'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6859',
//                           name: '2FRB03_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5575',
//                               name: '2FRB0320'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6860',
//                           name: '2FRB06_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2623',
//                               name: '2FRB0639'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6861',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6862',
//                           name: '2SBB05_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5086',
//                               name: '2SBB0519'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6863',
//                           name: '2SBB11_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6173',
//                               name: '2SBB1102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6864',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6865',
//                           name: '2BST04_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2569',
//                               name: '2BST0421'
//                             },
//                             {
//                               id: 'P1_2571',
//                               name: '2BST0424'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6870',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6871',
//                           name: '2TBT04_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5087',
//                               name: '2TBT0438'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6873',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6874',
//                           name: '2WBD07_F06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5248',
//                               name: '2WBD0724'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6875',
//                           name: '2WBD14_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3332',
//                               name: '2WBD1415'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6912',
//                           name: '2WBD17_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3370',
//                               name: '2WBD1729'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6974',
//                       name: 'DB_WB_M02',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6975',
//                           name: '2DAT20_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3404',
//                               name: '2DAT2004'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6976',
//                           name: '2OVN28_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3321',
//                               name: '2OVN2804'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6977',
//                           name: '2WBD15_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3335',
//                               name: '2WBD1501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7683',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7684',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7619',
//                               name: '2MVI0616'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7685',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7686',
//                           name: '2OVN01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5075',
//                               name: '2OVN0190'
//                             },
//                             {
//                               id: 'P1_5076',
//                               name: '2OVN0191'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7687',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5073',
//                               name: '2OVN2006'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7690',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7691',
//                           name: '2GBB03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5119',
//                               name: '2GBB0306'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7692',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7693',
//                           name: '2OVN16_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3281',
//                               name: '2OVN1601'
//                             },
//                             {
//                               id: 'P1_3282',
//                               name: '2OVN1602'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7694',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7695',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4184',
//                               name: '2OVN0119'
//                             },
//                             {
//                               id: 'P1_4187',
//                               name: '2OVN0120'
//                             },
//                             {
//                               id: 'P1_4190',
//                               name: '2OVN0122'
//                             },
//                             {
//                               id: 'P1_4191',
//                               name: '2OVN0117'
//                             },
//                             {
//                               id: 'P1_5309',
//                               name: '2OVN0195'
//                             },
//                             {
//                               id: 'P1_5310',
//                               name: '2OVN0196'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_6877',
//                   name: 'MF2 - TOPLED 6 LEAD',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_6706',
//                       name: '100% 3RD OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6707',
//                           name: '2AVI03_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6539',
//                               name: '2AVI0311'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6878',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6886',
//                           name: '2DAT16_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3191',
//                               name: '2DAT2033'
//                             },
//                             {
//                               id: 'P1_3192',
//                               name: '2DAT2034'
//                             },
//                             {
//                               id: 'P1_3398',
//                               name: '2DAT1601'
//                             },
//                             {
//                               id: 'P1_3399',
//                               name: '2DAT1602'
//                             },
//                             {
//                               id: 'P1_3400',
//                               name: '2DAT1603'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6887',
//                           name: '2DAT20_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3410',
//                               name: '2DAT2014'
//                             },
//                             {
//                               id: 'P1_3411',
//                               name: '2DAT2015'
//                             },
//                             {
//                               id: 'P1_3412',
//                               name: '2DAT2016'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6888',
//                           name: '2DAT22_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4113',
//                               name: '2DAT2201'
//                             },
//                             {
//                               id: 'P1_4114',
//                               name: '2DAT2202'
//                             },
//                             {
//                               id: 'P1_4115',
//                               name: '2DAT2203'
//                             },
//                             {
//                               id: 'P1_4116',
//                               name: '2DAT2204'
//                             },
//                             {
//                               id: 'P1_4117',
//                               name: '2DAT2205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8777',
//                           name: '2DAT20_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3418',
//                               name: '2DAT2022'
//                             },
//                             {
//                               id: 'P1_3419',
//                               name: '2DAT2023'
//                             },
//                             {
//                               id: 'P1_3420',
//                               name: '2DAT2024'
//                             },
//                             {
//                               id: 'P1_3421',
//                               name: '2DAT2025'
//                             },
//                             {
//                               id: 'P1_3422',
//                               name: '2DAT2026'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6889',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6890',
//                           name: '2CAS13_E08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3493',
//                               name: '2CAS1308'
//                             },
//                             {
//                               id: 'P1_3840',
//                               name: '2CAS1312'
//                             },
//                             {
//                               id: 'P1_4347',
//                               name: '2CAS1314'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6891',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6892',
//                           name: '2FRB01_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2604',
//                               name: '2FRB0147'
//                             },
//                             {
//                               id: 'P1_2605',
//                               name: '2FRB0148'
//                             },
//                             {
//                               id: 'P1_7285',
//                               name: '2FRB0155'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6893',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6894',
//                           name: '2SBB04_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1314',
//                               name: '2SBB0409'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6895',
//                           name: '2SBB05_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1231',
//                               name: '2SBB0505'
//                             },
//                             {
//                               id: 'P1_1232',
//                               name: '2SBB0511'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6896',
//                           name: '2SBB07_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4756',
//                               name: '2SBB0712'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6897',
//                           name: '2SBB11_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6174',
//                               name: '2SBB1103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6898',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6899',
//                           name: '2TBT06_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1240',
//                               name: '2TBT0605'
//                             },
//                             {
//                               id: 'P1_1241',
//                               name: '2TBT0611'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6900',
//                           name: '2TBT08_T06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4759',
//                               name: '2TBT0809'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6901',
//                           name: '2TBT13_T07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6713',
//                               name: '2TBT1303'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6902',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6903',
//                           name: '2WBD05_S04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3326',
//                               name: '2WBD0507'
//                             },
//                             {
//                               id: 'P1_3327',
//                               name: '2WBD0508'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6904',
//                           name: '2WBD07_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5742',
//                               name: '2WBD0745'
//                             },
//                             {
//                               id: 'P1_5743',
//                               name: '2WBD0746'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6905',
//                           name: '2WBD14_S05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3328',
//                               name: '2WBD1411'
//                             },
//                             {
//                               id: 'P1_3329',
//                               name: '2WBD1412'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8778',
//                           name: '2WBD07_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5744',
//                               name: '2WBD0747'
//                             },
//                             {
//                               id: 'P1_5745',
//                               name: '2WBD0748'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8779',
//                           name: '2WBD07_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4085',
//                               name: '2WBD0707'
//                             },
//                             {
//                               id: 'P1_4086',
//                               name: '2WBD0708'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8780',
//                           name: '2WBD05_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3324',
//                               name: '2WBD0503'
//                             },
//                             {
//                               id: 'P1_3325',
//                               name: '2WBD0504'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8781',
//                           name: '2WBD14_S06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3330',
//                               name: '2WBD1413'
//                             },
//                             {
//                               id: 'P1_3331',
//                               name: '2WBD1414'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7407',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7408',
//                           name: '2GBB03_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5118',
//                               name: '2GBB0305'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7565',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7566',
//                           name: '2OVN01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7501',
//                               name: '2OVN0199'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7665',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7666',
//                           name: '2VRR02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2763',
//                               name: '2VRR0221'
//                             },
//                             {
//                               id: 'P1_5584',
//                               name: '2VRR0234'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7667',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8513',
//                           name: '2OVN21_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5644',
//                               name: '2OVN2143'
//                             },
//                             {
//                               id: 'P1_5647',
//                               name: '2OVN2146'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8379',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8380',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7617',
//                               name: '2MVI0614'
//                             },
//                             {
//                               id: 'P1_7618',
//                               name: '2MVI0615'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7294',
//                   name: 'MF3 - LIDAR',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7272',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7273',
//                           name: '3SAW03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7274',
//                               name: '3SAW0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7275',
//                       name: 'LAMINATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7276',
//                           name: '3LMN05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7277',
//                               name: '3LMN0501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7289',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7291',
//                           name: '3PLM06_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7292',
//                               name: '3PLM0602'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7858',
//                           name: '3PLM12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6284',
//                               name: '3PLM1201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7295',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7296',
//                           name: '3DAT05_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7301',
//                               name: '3DAT0509'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7298',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7299',
//                           name: '3WBD07_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7300',
//                               name: '3WBD0750'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7310',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7013',
//                           name: '3OVN45_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6696',
//                               name: '3OVN4503'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7311',
//                           name: '3OVN46_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6397',
//                               name: '3OVN4601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7315',
//                           name: '3OVN15_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7316',
//                               name: '3OVN1501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7339',
//                           name: '3OVN45_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7340',
//                               name: '3OVN4504'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7318',
//                       name: 'COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7321',
//                           name: '3COT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7323',
//                               name: '3COT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7320',
//                       name: 'TAPE_MASKING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7322',
//                           name: '3TMK01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7324',
//                               name: '3TMK0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7325',
//                       name: 'LASER_MARK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7329',
//                           name: '3LSS03_G01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7330',
//                               name: '3LSS0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7326',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7331',
//                           name: '3AVI12_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7332',
//                               name: '3AVI1203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7328',
//                       name: 'BACKSIDE_DETAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7336',
//                           name: '3BTG03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7338',
//                               name: '3BTG0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7333',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7334',
//                           name: '3CMD01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7335',
//                               name: '3CMD0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7343',
//                       name: 'SUPP_VACUUM_SEALER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7344',
//                           name: '3VUS05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7345',
//                               name: '3VUS0501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7346',
//                       name: 'SUPP_UV_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7287',
//                           name: '3UVC04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7288',
//                               name: '3UVC0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7350',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7348',
//                           name: '3AVI13_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7349',
//                               name: '3AVI1301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7359',
//                       name: 'SUPP_DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7360',
//                           name: '3DTR09_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7361',
//                               name: '3DTR0902'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7365',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7366',
//                           name: '3VRR02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7367',
//                               name: '3VRR0201'
//                             },
//                             {
//                               id: 'P1_8394',
//                               name: '3VRR0201-M'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7397',
//                       name: 'TEST_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7400',
//                           name: '3FFT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7402',
//                               name: '3FFT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8223',
//                       name: 'SUPP_100%DMVI_OQA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7874',
//                           name: '3DVI02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7875',
//                               name: '3DVI0201'
//                             },
//                             {
//                               id: 'P1_7876',
//                               name: '3DVI0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8398',
//                       name: 'SUPP_VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8474',
//                           name: '3MVI30_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8475',
//                               name: '3MVI3001'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7417',
//                   name: 'MF2 - TULIP/DAISY',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7418',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6841',
//                           name: '2BST08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6842',
//                               name: '2BST0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7422',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6737',
//                           name: '2AVI02_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6722',
//                               name: '2AVI0218'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7724',
//                   name: 'MF2 - SMT_APT PLUS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7725',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7726',
//                           name: '2VRR02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2757',
//                               name: '2VRR0206'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7727',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7728',
//                           name: '2OVN21_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3144',
//                               name: '2OVN2106'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7729',
//                           name: '2OVN26_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3151',
//                               name: '2OVN2604'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7754',
//                   name: 'MF2 - SMT_MICROSIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7755',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7756',
//                           name: '2VRR02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2771',
//                               name: '2VRR0233'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7757',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7758',
//                           name: '2OVN01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5865',
//                               name: '2OVN0198'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7760',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7761',
//                           name: '2CAS10_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3475',
//                               name: '2CAS1007'
//                             },
//                             {
//                               id: 'P1_3476',
//                               name: '2CAS1008'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7762',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7763',
//                           name: '2OVN01_E10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4194',
//                               name: '2OVN0116'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7764',
//                           name: '2OVN21_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4167',
//                               name: '2OVN2126'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7765',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7766',
//                           name: '2DAT13_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3219',
//                               name: '2DAT1304'
//                             },
//                             {
//                               id: 'P1_3220',
//                               name: '2DAT1305'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7767',
//                           name: '2DAT13_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3221',
//                               name: '2DAT1306'
//                             },
//                             {
//                               id: 'P1_3232',
//                               name: '2DAT1317'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7768',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7769',
//                           name: '2OVN01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3286',
//                               name: '2OVN0159'
//                             },
//                             {
//                               id: 'P1_3290',
//                               name: '2OVN0163'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7770',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7771',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4189',
//                               name: '2OVN0113'
//                             },
//                             {
//                               id: 'P1_4624',
//                               name: '2OVN0144'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7772',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7773',
//                           name: '2CAS15_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4346',
//                               name: '2CAS1501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7774',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7775',
//                           name: '2FRB05_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2614',
//                               name: '2FRB0518'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7776',
//                           name: '2FRB08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5593',
//                               name: '2FRB0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7777',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7778',
//                           name: '2SBB06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2678',
//                               name: '2SBB0603'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7779',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7780',
//                           name: '2BST02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2529',
//                               name: '2BST0203'
//                             },
//                             {
//                               id: 'P1_2533',
//                               name: '2BST0207'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7781',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7782',
//                           name: '2TBT08_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2730',
//                               name: '2TBT0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7783',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7784',
//                           name: '2WBD07_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5240',
//                               name: '2WBD0716'
//                             },
//                             {
//                               id: 'P1_5241',
//                               name: '2WBD0717'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7785',
//                           name: '2WBD17_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3350',
//                               name: '2WBD1709'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8373',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8374',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7613',
//                               name: '2MVI0610'
//                             },
//                             {
//                               id: 'P1_7614',
//                               name: '2MVI0611'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7787',
//                   name: 'MF2 - SMT_MULTILED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7788',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7789',
//                           name: '2VRR01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2751',
//                               name: '2VRR0128'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7791',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7792',
//                           name: '2OVN20_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5069',
//                               name: '2OVN2002'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7793',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7794',
//                           name: '2CAS10_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3059',
//                               name: '2CAS1011'
//                             },
//                             {
//                               id: 'P1_3062',
//                               name: '2CAS1014'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7795',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7796',
//                           name: '2OVN01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7558',
//                               name: '2OVN0140'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7797',
//                           name: '2OVN21_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4170',
//                               name: '2OVN2123'
//                             },
//                             {
//                               id: 'P1_4171',
//                               name: '2OVN2122'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7798',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7799',
//                           name: '2DAT20_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3401',
//                               name: '2DAT2001'
//                             },
//                             {
//                               id: 'P1_3409',
//                               name: '2DAT2009'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8782',
//                           name: '2DAT20_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3402',
//                               name: '2DAT2002'
//                             },
//                             {
//                               id: 'P1_3403',
//                               name: '2DAT2003'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7800',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7801',
//                           name: '2OVN01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3289',
//                               name: '2OVN0162'
//                             },
//                             {
//                               id: 'P1_3291',
//                               name: '2OVN0164'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7802',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7803',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4185',
//                               name: '2OVN0118'
//                             },
//                             {
//                               id: 'P1_4195',
//                               name: '2OVN0151'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7804',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7806',
//                           name: '2FRB06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2615',
//                               name: '2FRB0628'
//                             },
//                             {
//                               id: 'P1_2621',
//                               name: '2FRB0637'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7807',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7808',
//                           name: '2SBB03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2662',
//                               name: '2SBB0302'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7809',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7811',
//                           name: '2BST03_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2547',
//                               name: '2BST0318'
//                             },
//                             {
//                               id: 'P1_2551',
//                               name: '2BST0322'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7813',
//                           name: '2BST04_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2563',
//                               name: '2BST0413'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7814',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7815',
//                           name: '2WBD07_S01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5242',
//                               name: '2WBD0718'
//                             },
//                             {
//                               id: 'P1_5243',
//                               name: '2WBD0719'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7816',
//                           name: '2WBD17_S02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3368',
//                               name: '2WBD1727'
//                             },
//                             {
//                               id: 'P1_3369',
//                               name: '2WBD1728'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7817',
//                           name: '2WBD18_S03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3384',
//                               name: '2WBD1809'
//                             },
//                             {
//                               id: 'P1_3385',
//                               name: '2WBD1810'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8375',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8376',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7608',
//                               name: '2MVI0606'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7818',
//                   name: 'MF2 - SMT_SMTRLS LINE',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7821',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7822',
//                           name: '2OVN01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7557',
//                               name: '2OVN0133'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7823',
//                           name: '2OVN01_E11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7560',
//                               name: '2OVN0146'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7824',
//                           name: '2OVN21_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4169',
//                               name: '2OVN2125'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7825',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7826',
//                           name: '2DAT13_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3237',
//                               name: '2DAT1322'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7827',
//                       name: 'SUPP_OVEN_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7828',
//                           name: '2OVN01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3285',
//                               name: '2OVN0158'
//                             },
//                             {
//                               id: 'P1_3288',
//                               name: '2OVN0161'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7829',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7830',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4192',
//                               name: '2OVN0112'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7831',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7832',
//                           name: '2FRB01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5577',
//                               name: '2FRB0149'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7833',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7834',
//                           name: '2TST01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2716',
//                               name: '2TST0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7835',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7836',
//                           name: '2WBD17_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3337',
//                               name: '2WBD1701'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8377',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8378',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7615',
//                               name: '2MVI0612'
//                             },
//                             {
//                               id: 'P1_7616',
//                               name: '2MVI0613'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7837',
//                   name: 'MF2 - SMT_TOPLED RG',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_7838',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7839',
//                           name: '2CAS10_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3060',
//                               name: '2CAS1012'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7840',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7841',
//                           name: '2DAT20_S04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3407',
//                               name: '2DAT2007'
//                             },
//                             {
//                               id: 'P1_3408',
//                               name: '2DAT2008'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7842',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7843',
//                           name: '2FRB06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2620',
//                               name: '2FRB0636'
//                             },
//                             {
//                               id: 'P1_2622',
//                               name: '2FRB0638'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7844',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7845',
//                           name: '2BST02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2527',
//                               name: '2BST0201'
//                             },
//                             {
//                               id: 'P1_2532',
//                               name: '2BST0206'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7846',
//                           name: '2BST03_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2552',
//                               name: '2BST0323'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7847',
//                           name: '2BST04_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2565',
//                               name: '2BST0415'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7848',
//                           name: '2BST05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4155',
//                               name: '2BST0502'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8381',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7609',
//                           name: '2MVI06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7610',
//                               name: '2MVI0607'
//                             },
//                             {
//                               id: 'P1_7611',
//                               name: '2MVI0608'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8590',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8591',
//                           name: '2VRR02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2766',
//                               name: '2VRR0224'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7877',
//                   name: 'MF3 - CERALID OH',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_3754',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5746',
//                           name: '2WBD07_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5747',
//                               name: '2WBD0749'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_4678',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4679',
//                           name: '2PFF01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4681',
//                               name: '2PFF0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7879',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8023',
//                           name: '3DAT29_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8027',
//                               name: '3DAT2901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7932',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7943',
//                           name: '3AVI12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6999',
//                               name: '3AVI1201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8212',
//                       name: 'SUPP_HELIUM_BOMBING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7886',
//                           name: '3HEB01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7889',
//                               name: '3HEB0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8225',
//                       name: 'SUPP_SINTER_BONDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7893',
//                           name: '3SNT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7894',
//                               name: '3SNT0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8811',
//                           name: '2DAT10_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6590',
//                               name: '2DAT1004'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8343',
//                       name: 'TEST_N_SORTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7950',
//                           name: '3TNS01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7951',
//                               name: '3TNS0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8658',
//                       name: 'FINE_LEAK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2807',
//                           name: '2FLT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2809',
//                               name: '2FLT0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8659',
//                           name: '3FLT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8660',
//                               name: '3FLT0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8694',
//                       name: 'SUPP_MANUAL_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8695',
//                           name: '3MVI35_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8696',
//                               name: '3MVI3501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8760',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7884',
//                           name: '3OVN40_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7888',
//                               name: '3OVN4002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8448',
//                           name: '3OVN40_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7887',
//                               name: '3OVN4001'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8817',
//                           name: '2OVN40_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4742',
//                               name: '2OVN4002'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8761',
//                       name: 'SUPP_BURN_IN_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8762',
//                           name: '3BIT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8763',
//                               name: '3BIT0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8764',
//                       name: 'SUPP_100%DMVI_OQA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8765',
//                           name: '3DVI02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8766',
//                               name: '3DVI0203'
//                             },
//                             {
//                               id: 'P1_8767',
//                               name: '3DVI0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8801',
//                       name: 'GROSS_LEAK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2808',
//                           name: '2GLT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2819',
//                               name: '2GLT0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8802',
//                           name: '3GLT02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8803',
//                               name: '3GLT0201'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_7907',
//                   name: 'UNUSED_EQUIPMENT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5926',
//                       name: 'HEATER_BOND_HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1589',
//                           name: '1DAT09_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1608',
//                               name: '1DAT0905'
//                             },
//                             {
//                               id: 'P1_1609',
//                               name: '1DAT0904'
//                             },
//                             {
//                               id: 'P1_1664',
//                               name: '1DAT0906'
//                             },
//                             {
//                               id: 'P1_1665',
//                               name: '1DAT0907'
//                             },
//                             {
//                               id: 'P1_1666',
//                               name: '1DAT0908'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6790',
//                       name: 'THERMOGRAPHY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2071',
//                           name: '1TMG01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2148',
//                               name: '1TMG0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7910',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6872',
//                           name: '2TBT06_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2723',
//                               name: '2TBT0615'
//                             },
//                             {
//                               id: 'P1_2725',
//                               name: '2TBT0705'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7280',
//                           name: '1TBT04_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1564',
//                               name: '1TBT0404'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8045',
//                           name: 'IT',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8043',
//                               name: 'IT55'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8363',
//                           name: '1TBT02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1726',
//                               name: '1TBT0224'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8744',
//                           name: '2TBT02_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1235',
//                               name: '2TBT0215'
//                             },
//                             {
//                               id: 'P1_1236',
//                               name: '2TBT0216'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8775',
//                           name: '2TFT01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3972',
//                               name: '2TFT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7911',
//                       name: 'AUTO DETAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7189',
//                           name: '1SFB02_T07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1561',
//                               name: '1SFB0218'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7923',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2312',
//                           name: '1DAT06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1601',
//                               name: '1DAT0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6055',
//                           name: '1DAT03_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1689',
//                               name: '1DAT0313'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7862',
//                           name: '2DAT10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4150',
//                               name: '2DAT1002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7930',
//                           name: '2DAT03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1433',
//                               name: '2DAT0306'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7941',
//                           name: '2DAT12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2495',
//                               name: '2DAT1201'
//                             },
//                             {
//                               id: 'P1_3704',
//                               name: '2DAT1202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7942',
//                           name: '3DAT25_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5970',
//                               name: '3DAT2501'
//                             },
//                             {
//                               id: 'P1_5971',
//                               name: '3DAT2502'
//                             },
//                             {
//                               id: 'P1_5972',
//                               name: '3DAT2503'
//                             },
//                             {
//                               id: 'P1_5973',
//                               name: '3DAT2504'
//                             },
//                             {
//                               id: 'P1_6934',
//                               name: '3DAT2505'
//                             },
//                             {
//                               id: 'P1_6935',
//                               name: '3DAT2506'
//                             },
//                             {
//                               id: 'P1_6936',
//                               name: '3DAT2507'
//                             },
//                             {
//                               id: 'P1_6941',
//                               name: '3DAT2512'
//                             },
//                             {
//                               id: 'P1_6942',
//                               name: '3DAT2513'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7948',
//                           name: '1DAT12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3612',
//                               name: '1DAT1204'
//                             },
//                             {
//                               id: 'P1_3709',
//                               name: '1DAT1205'
//                             },
//                             {
//                               id: 'P1_3746',
//                               name: '1DAT1206'
//                             },
//                             {
//                               id: 'P1_3747',
//                               name: '1DAT1207'
//                             },
//                             {
//                               id: 'P1_4819',
//                               name: '1DAT1208'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8386',
//                           name: '2DAT06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1431',
//                               name: '2DAT0604'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8441',
//                           name: '2DAT05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6968',
//                               name: '2DAT0508'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8449',
//                           name: '1DAT05_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1696',
//                               name: '1DAT0519'
//                             },
//                             {
//                               id: 'P1_1699',
//                               name: '1DAT0523'
//                             },
//                             {
//                               id: 'P1_5934',
//                               name: '1DAT2301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8772',
//                           name: '2DAT20_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3195',
//                               name: '2DAT2037'
//                             },
//                             {
//                               id: 'P1_3198',
//                               name: '2DAT2040'
//                             },
//                             {
//                               id: 'P1_3199',
//                               name: '2DAT2041'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8773',
//                           name: '1DAT05_F13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1598',
//                               name: '1DAT0506'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8793',
//                           name: '1DAT10_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2087',
//                               name: '1DAT1012'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7924',
//                       name: 'SCC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7925',
//                           name: '2SCC02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4261',
//                               name: '2SCC0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7926',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2405',
//                           name: '2CAS07_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1479',
//                               name: '2CAS0704'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6697',
//                           name: '2CAS21_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6306',
//                               name: '2CAS2101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7820',
//                           name: '2CAS10_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3470',
//                               name: '2CAS1002'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8297',
//                           name: '1CAS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2170',
//                               name: '1CAS0102'
//                             },
//                             {
//                               id: 'P1_2172',
//                               name: '1CAS0104-removed'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8495',
//                           name: '1CAS02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1634',
//                               name: '1CAS0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7927',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8389',
//                           name: '2TBT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1427',
//                               name: '2TBT0406'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8774',
//                           name: '2TBT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1505',
//                               name: '2TBT0219'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7929',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8736',
//                           name: '2AVI04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3111',
//                               name: '2AVI0404'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7931',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8414',
//                           name: '1PLM05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1820',
//                               name: '1PLM0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8442',
//                           name: '2PLM09_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3317',
//                               name: '2PLM0903'
//                             },
//                             {
//                               id: 'P1_3523',
//                               name: '2PLM0902'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7934',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7917',
//                           name: '1WBD04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2141',
//                               name: '1WBD0427'
//                             },
//                             {
//                               id: 'P1_2228',
//                               name: '1WBD0417'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7935',
//                           name: '2WBD06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5672',
//                               name: '2WBD0617'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8287',
//                           name: '1WBD04_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1880',
//                               name: '1WBD0422'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8291',
//                           name: '2WBD18_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3380',
//                               name: '2WBD1805'
//                             },
//                             {
//                               id: 'P1_3381',
//                               name: '2WBD1806'
//                             },
//                             {
//                               id: 'P1_3382',
//                               name: '2WBD1807'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8771',
//                           name: '2WBD05_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3172',
//                               name: '2WBD0513'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7937',
//                       name: 'SUPP_POST_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6156',
//                           name: '2OVN01_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3251',
//                               name: '2OVN0172'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8728',
//                           name: '2OVN01_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3030',
//                               name: '2OVN0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7938',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_1454',
//                           name: '2TMD01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1455',
//                               name: '2TMD0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8735',
//                           name: '2TMD03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3106',
//                               name: '2TMD0304'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7939',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4979',
//                           name: '2SBB06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2680',
//                               name: '2SBB0607'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7404',
//                           name: '3SFF02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7405',
//                               name: '3SFF0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8743',
//                           name: '2SBB01_T05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1227',
//                               name: '2SBB0118'
//                             },
//                             {
//                               id: 'P1_1228',
//                               name: '2SBB0119'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7944',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2466',
//                           name: '1SAW03_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4771',
//                               name: '1SAW0343'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_3902',
//                           name: '1SAW02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3904',
//                               name: '1SAW0203'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7947',
//                           name: '3SAW07_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6369',
//                               name: '3SAW0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8403',
//                           name: '1SAW02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3903',
//                               name: '1SAW0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8025',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8026',
//                           name: '1LSC02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8028',
//                               name: '1LSC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8260',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7428',
//                           name: '1MVI12_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6014',
//                               name: '1MVI1201'
//                             },
//                             {
//                               id: 'P1_7429',
//                               name: '1MVI1204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8293',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8295',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5590',
//                               name: '2VRR0213'
//                             },
//                             {
//                               id: 'P1_7412',
//                               name: '2VRR0241'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8302',
//                       name: 'GLASS_TURRET',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8303',
//                           name: '2GBB01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3730',
//                               name: '2GBB0104'
//                             },
//                             {
//                               id: 'P1_4235',
//                               name: '2GBB0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8330',
//                       name: 'PLATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_4039',
//                           name: '2PLA02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4050',
//                               name: '2PLA0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8364',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2278',
//                           name: '1OVN03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4263',
//                               name: '1OVN0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5691',
//                           name: '2OVN39_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4628',
//                               name: '2OVN3902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5776',
//                           name: '1OVN01_F09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4560',
//                               name: '1OVN0163'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6171',
//                           name: '2OVN39_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4627',
//                               name: '2OVN3901'
//                             },
//                             {
//                               id: 'P1_5566',
//                               name: '2OVN3903'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6622',
//                           name: '1OVN14_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6623',
//                               name: '1OVN1410'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8365',
//                           name: '1OVN01_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4558',
//                               name: '1OVN0148'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8388',
//                           name: '2OVN01_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4813',
//                               name: '2OVN0180'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8413',
//                           name: '1OVN14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5994',
//                               name: '1OVN1412-removed'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8444',
//                           name: '2OVN42_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6476',
//                               name: '2OVN4203'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8473',
//                           name: '3OVN46_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6839',
//                               name: '3OVN4603'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8595',
//                           name: '1OVN02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4902',
//                               name: '1OVN0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8387',
//                       name: 'VISION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2181',
//                           name: '1MVI01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2210',
//                               name: '1MVI0122'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5687',
//                           name: '2AVT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5688',
//                               name: '2AVT0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7644',
//                           name: '1CYS02_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4330',
//                               name: '1CYS0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8580',
//                           name: '8AVI12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3296',
//                               name: '2AVI0501'
//                             },
//                             {
//                               id: 'P1_3298',
//                               name: '2AVI0503'
//                             },
//                             {
//                               id: 'P1_8581',
//                               name: '8AVI1201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8596',
//                           name: '2MVI01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3511',
//                               name: '2MVI0124'
//                             },
//                             {
//                               id: 'P1_3513',
//                               name: '2MVI0126'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8726',
//                           name: '2MVI01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3053',
//                               name: '2MVI0123'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8770',
//                           name: '2MVI01_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3152',
//                               name: '2MVI0128'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8390',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8391',
//                           name: '2DTR01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3694',
//                               name: '2DTR0101'
//                             },
//                             {
//                               id: 'P1_3695',
//                               name: '2DTR0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8392',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8742',
//                           name: '2FRB02_T04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5602',
//                               name: '2FRB0209'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8404',
//                       name: 'MANUAL_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5867',
//                           name: '1BTG02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5868',
//                               name: '1BTG0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8406',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8415',
//                           name: '1SAS01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1556',
//                               name: '1SAS0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8416',
//                           name: '1SIN08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2581',
//                               name: '2FRB0111'
//                             },
//                             {
//                               id: 'P1_3278',
//                               name: '2FRB0701'
//                             },
//                             {
//                               id: 'P1_6491',
//                               name: '1SIN0801'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8768',
//                           name: '2SIN04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3143',
//                               name: '2SIN0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8437',
//                       name: 'LENS_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8438',
//                           name: '2LMT02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6593',
//                               name: '2LMT0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8439',
//                       name: 'FINAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8440',
//                           name: '2MTE01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6562',
//                               name: '2MTE0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8479',
//                       name: 'SPC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8480',
//                           name: '3SPC09_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8481',
//                               name: '3SPC0901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8649',
//                       name: 'SUPP_SCREEN_PRINTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8650',
//                           name: '1LPT01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4872',
//                               name: '1LPT0101'
//                             },
//                             {
//                               id: 'P1_4876',
//                               name: '1LPT0105'
//                             },
//                             {
//                               id: 'P1_4877',
//                               name: '1LPT0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8661',
//                       name: 'SUPP_CERAMIC_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8662',
//                           name: '1CRA01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7105',
//                               name: '1CRA0102-removed'
//                             },
//                             {
//                               id: 'P1_7106',
//                               name: '1CRA0104-removed'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8720',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8721',
//                           name: '2CAS13_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4345',
//                               name: '2CAS1313'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8722',
//                       name: 'LENS_BONDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8723',
//                           name: '2LBD01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5368',
//                               name: '2LBD0108'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8724',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8725',
//                           name: '2LYT01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3014',
//                               name: '2LYT0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8729',
//                       name: 'CDA_HEAT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8730',
//                           name: '2OVN21_E07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3697',
//                               name: '2OVN2139'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8731',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8732',
//                           name: '2OVN21_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3034',
//                               name: '2OVN2109'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8769',
//                           name: '2OVN26_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3148',
//                               name: '2OVN2601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8740',
//                       name: 'SUPP_MIELE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8741',
//                           name: '1CLN01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3857',
//                               name: '1CLN0104'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_8349',
//                   name: 'R&D',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_5807',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5809',
//                           name: '3DAT24_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5810',
//                               name: '3DAT2401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8455',
//                           name: '3DAT25_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6937',
//                               name: '3DAT2508'
//                             },
//                             {
//                               id: 'P1_6938',
//                               name: '3DAT2509'
//                             },
//                             {
//                               id: 'P1_6939',
//                               name: '3DAT2510'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8514',
//                           name: '8DAT24_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8515',
//                               name: '8DAT2402'
//                             },
//                             {
//                               id: 'P1_8516',
//                               name: '8DAT2403'
//                             },
//                             {
//                               id: 'P1_8517',
//                               name: '8DAT2404'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8518',
//                           name: '8DAT03_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8519',
//                               name: '8DAT0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8520',
//                           name: '8DAT23_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8521',
//                               name: '8DAT2301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5814',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5815',
//                           name: '3WBD07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5816',
//                               name: '3WBD0701'
//                             },
//                             {
//                               id: 'P1_5817',
//                               name: '3WBD0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8527',
//                           name: '8WBD11_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8528',
//                               name: '8WBD1101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8533',
//                           name: '8WBD07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8534',
//                               name: '8WBD0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8550',
//                           name: '8WBD26_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8551',
//                               name: '8WBD2601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6280',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6283',
//                           name: '3PLM06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6437',
//                               name: '3PLM0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6360',
//                           name: '3PLM12_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6372',
//                               name: '3PLM1202'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8529',
//                           name: '8PLM05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8530',
//                               name: '8PLM0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8582',
//                           name: '1PLM06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1804',
//                               name: '1PLM0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6356',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6365',
//                           name: '3SAW07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6366',
//                               name: '3SAW0701'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8578',
//                           name: '8SAW09_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8579',
//                               name: '8SAW0901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6357',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6358',
//                           name: '3AVI09_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6359',
//                               name: '3AVI0901'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8458',
//                           name: '3AVI12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7143',
//                               name: '3AVI1202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6390',
//                       name: 'SUPP_LED_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6391',
//                           name: '3ATE02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6392',
//                               name: '3ATE0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_6438',
//                       name: 'SUPP_BURN_IN_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8221',
//                           name: '3BIT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6439',
//                               name: '3BIT0101'
//                             },
//                             {
//                               id: 'P1_7059',
//                               name: '3BIT0102'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7356',
//                       name: 'SUPP_6_SIDES_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7357',
//                           name: '3AVI14_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7358',
//                               name: '3AVI1401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7369',
//                       name: 'PICK_AND_PLACE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7371',
//                           name: '3PNP01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7372',
//                               name: '3PNP0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7868',
//                       name: 'SUPP_100%DMVI_OQA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7869',
//                           name: '3DMVI_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7870',
//                               name: '3DMVI0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8224',
//                       name: 'SUPP_MOUNTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7452',
//                           name: '3MTR03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7453',
//                               name: '3MTR0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8259',
//                       name: 'SUPP_CMOS_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6394',
//                           name: '3CMT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6395',
//                               name: '3CMT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8328',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7148',
//                           name: '2FAM02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7149',
//                               name: '2FAM0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8426',
//                           name: '3FAM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6375',
//                               name: '3FAM0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8548',
//                           name: '8FAM01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8549',
//                               name: '8FAM0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8350',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5811',
//                           name: '3OVN44_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_5812',
//                               name: '3OVN4401'
//                             },
//                             {
//                               id: 'P1_5813',
//                               name: '3OVN4402'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6059',
//                           name: '3OVN44_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6060',
//                               name: '3OVN4403'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6064',
//                           name: '3OVN44_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6065',
//                               name: '3OVN4404'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6361',
//                           name: '3OVN45_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6362',
//                               name: '3OVN4501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8427',
//                           name: '3OVN52_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7154',
//                               name: '3OVN5201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8459',
//                           name: '3OVN46_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8460',
//                               name: '3OVN4602'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8535',
//                           name: '8OVN01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8536',
//                               name: '8OVN0101'
//                             },
//                             {
//                               id: 'P1_8537',
//                               name: '8OVN0102'
//                             },
//                             {
//                               id: 'P1_8538',
//                               name: '8OVN0103'
//                             },
//                             {
//                               id: 'P1_8587',
//                               name: '8OVN0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8552',
//                           name: '8OVN40_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8553',
//                               name: '8OVN4003'
//                             },
//                             {
//                               id: 'P1_8554',
//                               name: '8OVN4004'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8555',
//                           name: '8OVN41_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8556',
//                               name: '8OVN4101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8557',
//                           name: '8OVN02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8558',
//                               name: '8OVN0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8570',
//                           name: '8OVN39_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8571',
//                               name: '8OVN3901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8351',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6401',
//                           name: '3AST02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6403',
//                               name: '3AST0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8352',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6378',
//                           name: '3AVI08_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6379',
//                               name: '3AVI0801'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_6380',
//                           name: '3AVI11_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6381',
//                               name: '3AVI1101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8353',
//                       name: 'GLOB_TOP',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6062',
//                           name: '3CAS17_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6058',
//                               name: '3CAS1701'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8354',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7019',
//                           name: '3DTR09_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7020',
//                               name: '3DTR0901'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_7426',
//                           name: '2DTR06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7457',
//                               name: '2DTR0602'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8559',
//                           name: '8DTR10_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8560',
//                               name: '8DTR1001'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8355',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6067',
//                           name: '3LSC04_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6068',
//                               name: '3LSC0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8428',
//                           name: '3LSC04_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6389',
//                               name: '3LSC0402'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8565',
//                           name: '8LSC05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8566',
//                               name: '8LSC0501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8356',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6384',
//                           name: '3VRR03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6385',
//                               name: '3VRR0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8382',
//                       name: 'AUTO_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8424',
//                           name: '3SAT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8425',
//                               name: '3SAT0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8572',
//                           name: '8ATE06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8573',
//                               name: '8ATE0601'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8574',
//                           name: '8ATE07_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8575',
//                               name: '8ATE0701'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8395',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6057',
//                           name: '3CAS17_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6063',
//                               name: '3CAS1702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8525',
//                           name: '8CAS01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8526',
//                               name: '8CAS0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8531',
//                           name: '8CAS05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8532',
//                               name: '8CAS0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8576',
//                           name: '8CAS23_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8577',
//                               name: '8CAS2301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8456',
//                       name: 'SUPP_SPC',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8457',
//                           name: '3SPC15_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6387',
//                               name: '3SPC1501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8467',
//                           name: '3SPC02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8468',
//                               name: '3SPC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8461',
//                       name: 'SUPP_CYBER_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8462',
//                           name: '3CYS01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8463',
//                               name: '3CYS0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8464',
//                       name: 'HIGH_PROCESS_CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8465',
//                           name: '3HPC01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8466',
//                               name: '3HPC0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8476',
//                       name: 'SUPP_FREEZER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8477',
//                           name: '3FRZ04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8478',
//                               name: '3FRZ0401'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8522',
//                       name: 'HEATER_BOND _HEAD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8523',
//                           name: '8DAT08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8524',
//                               name: '8DAT0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8539',
//                       name: 'US_SCAN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8540',
//                           name: '8USM02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8541',
//                               name: '8USM0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8545',
//                       name: 'SEDIMENTATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8546',
//                           name: '8PCS02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8547',
//                               name: '8PCS0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8561',
//                       name: 'MANUAL_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7363',
//                           name: '3MRT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7364',
//                               name: '3MRT0401'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8562',
//                           name: '8MVT05_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8563',
//                               name: '8MVT0501'
//                             },
//                             {
//                               id: 'P1_8564',
//                               name: '8MVT0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8585',
//                           name: '8MVT06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8586',
//                               name: '8MVT0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8567',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8568',
//                           name: '8FSB08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8569',
//                               name: '8FSB0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8583',
//                       name: 'MANUAL_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7354',
//                           name: '3MTE01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7355',
//                               name: '3MTE0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8584',
//                           name: '1MTE01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1371',
//                               name: '1MTE0107'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8670',
//                       name: 'FOIL_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8671',
//                           name: '3SFA03_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8672',
//                               name: '3SFA0301'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_8483',
//                   name: 'MF3 - DAIDALOS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_8484',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7658',
//                           name: '3TBT08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_7659',
//                               name: '3TBT0801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8498',
//                       name: 'SUPP_VACUUM_SEALER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8499',
//                           name: '3VUS05_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8500',
//                               name: '3VUS0502'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8627',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_6363',
//                           name: '3OVN45_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_6364',
//                               name: '3OVN4502'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_8788',
//                   name: 'MF2 - VELVET',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_8789',
//                       name: 'SORTTAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8624',
//                           name: '2TBT04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_2709',
//                               name: '2TBT0431'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P1_8818',
//                   name: 'MF3 - OSLUX',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P1_8819',
//                       name: 'DIE_ATTACH_ESD',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8820',
//                           name: '1DAT05_F08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3741',
//                               name: '1DAT0557'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8821',
//                       name: 'PLASMA_PRE_WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8822',
//                           name: '1PLM02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1863',
//                               name: '1PLM0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8823',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8824',
//                           name: '1WBD05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1858',
//                               name: '1WBD0504'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8825',
//                       name: 'TAPE_SAW',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8826',
//                           name: '1SAW02_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_3905',
//                               name: '1SAW0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8827',
//                       name: 'REFLOW_PROFILE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8828',
//                           name: '2OVN38_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4335',
//                               name: '2OVN3801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8829',
//                       name: 'DIE_BOND_LED',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8830',
//                           name: '1DAT05_F18',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_1700',
//                               name: '1DAT0522'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8831',
//                       name: 'DIE_BOND_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8832',
//                           name: '2OVN40_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4741',
//                               name: '2OVN4001'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8833',
//                       name: 'TAPE_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8834',
//                           name: '1OVN02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_4901',
//                               name: '1OVN0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8836',
//                       name: 'REFLECTOR_DRY_BAKE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8843',
//                           name: '3OVN58_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8851',
//                               name: '3OVN5801'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8837',
//                       name: 'PLASMA_REFLECTOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8841',
//                           name: '3PLM02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8849',
//                               name: '3PLM0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8838',
//                       name: 'REFLECTOR_MOUNTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8844',
//                           name: '3RRM01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8852',
//                               name: '3RRM0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8839',
//                       name: 'REFLECTOR_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8842',
//                           name: '3OVN60_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8850',
//                               name: '3OVN6001'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8840',
//                       name: 'TEST_N_TAPING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8845',
//                           name: '3TNT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8853',
//                               name: '3TNT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8846',
//                       name: 'LAMINATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8847',
//                           name: '3LMN06_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8848',
//                               name: '3LMN0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8854',
//                       name: 'DETAPER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8855',
//                           name: '3DTR10_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8856',
//                               name: '3DTR1001'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8857',
//                       name: 'MANUAL_VISUAL_IN_TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8858',
//                           name: '3MVT06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8859',
//                               name: '3MVT0601'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8860',
//                       name: 'AUTO_VISUAL_IN_TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8861',
//                           name: '3AVT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P1_8862',
//                               name: '3AVT0203'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 }
//               ]
//             },
//             {
//               id: 'P2',
//               name: 'WUX',
//               type: 'object',
//               selectionType: 'multiselect',
//               required: 'false',
//               attribute: 'Module',
//               lockable: false,
//               values: [
//                 {
//                   id: 'P2_1002',
//                   name: 'MF1 - TOP99',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1003',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1004',
//                           name: 'DB_M19',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1005',
//                               name: '7EMDLC19D1'
//                             },
//                             {
//                               id: 'P2_1006',
//                               name: '7EMDLC19D2'
//                             },
//                             {
//                               id: 'P2_1007',
//                               name: '7EMDLC19D3'
//                             },
//                             {
//                               id: 'P2_1008',
//                               name: '7EMDLC19D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1711',
//                           name: 'DB_M01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1712',
//                               name: '7EMDLC01D1'
//                             },
//                             {
//                               id: 'P2_1713',
//                               name: '7EMDLC01D2'
//                             },
//                             {
//                               id: 'P2_1714',
//                               name: '7EMDLC01D3'
//                             },
//                             {
//                               id: 'P2_1715',
//                               name: '7EMDLC01D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1720',
//                           name: 'DB_M02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1721',
//                               name: '7EMDLC02D1'
//                             },
//                             {
//                               id: 'P2_1722',
//                               name: '7EMDLC02D2'
//                             },
//                             {
//                               id: 'P2_1723',
//                               name: '7EMDLC02D3'
//                             },
//                             {
//                               id: 'P2_1724',
//                               name: '7EMDLC02D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1728',
//                           name: 'DB_M03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1729',
//                               name: '7EMDLC03D1'
//                             },
//                             {
//                               id: 'P2_1730',
//                               name: '7EMDLC03D2'
//                             },
//                             {
//                               id: 'P2_1731',
//                               name: '7EMDLC03D3'
//                             },
//                             {
//                               id: 'P2_1732',
//                               name: '7EMDLC03D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1736',
//                           name: 'DB_M04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1737',
//                               name: '7EMDLC04D1'
//                             },
//                             {
//                               id: 'P2_1738',
//                               name: '7EMDLC04D2'
//                             },
//                             {
//                               id: 'P2_1739',
//                               name: '7EMDLC04D3'
//                             },
//                             {
//                               id: 'P2_1740',
//                               name: '7EMDLC04D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1744',
//                           name: 'DB_M05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1745',
//                               name: '7EMDLC05D1'
//                             },
//                             {
//                               id: 'P2_1746',
//                               name: '7EMDLC05D2'
//                             },
//                             {
//                               id: 'P2_1747',
//                               name: '7EMDLC05D3'
//                             },
//                             {
//                               id: 'P2_1748',
//                               name: '7EMDLC05D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1752',
//                           name: 'DB_M06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1753',
//                               name: '7EMDLC06D1'
//                             },
//                             {
//                               id: 'P2_1754',
//                               name: '7EMDLC06D2'
//                             },
//                             {
//                               id: 'P2_1755',
//                               name: '7EMDLC06D3'
//                             },
//                             {
//                               id: 'P2_1756',
//                               name: '7EMDLC06D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1760',
//                           name: 'DB_M07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1761',
//                               name: '7EMDLC07D1'
//                             },
//                             {
//                               id: 'P2_1762',
//                               name: '7EMDLC07D2'
//                             },
//                             {
//                               id: 'P2_1763',
//                               name: '7EMDLC07D3'
//                             },
//                             {
//                               id: 'P2_1764',
//                               name: '7EMDLC07D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1768',
//                           name: 'DB_M08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1769',
//                               name: '7EMDLC08D1'
//                             },
//                             {
//                               id: 'P2_1770',
//                               name: '7EMDLC08D2'
//                             },
//                             {
//                               id: 'P2_1771',
//                               name: '7EMDLC08D3'
//                             },
//                             {
//                               id: 'P2_1772',
//                               name: '7EMDLC08D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1776',
//                           name: 'DB_M09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1777',
//                               name: '7EMDLC09D1'
//                             },
//                             {
//                               id: 'P2_1778',
//                               name: '7EMDLC09D2'
//                             },
//                             {
//                               id: 'P2_1779',
//                               name: '7EMDLC09D3'
//                             },
//                             {
//                               id: 'P2_1780',
//                               name: '7EMDLC09D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1784',
//                           name: 'DB_M10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1785',
//                               name: '7EMDLC10D1'
//                             },
//                             {
//                               id: 'P2_1786',
//                               name: '7EMDLC10D2'
//                             },
//                             {
//                               id: 'P2_1787',
//                               name: '7EMDLC10D3'
//                             },
//                             {
//                               id: 'P2_1788',
//                               name: '7EMDLC10D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1794',
//                           name: 'DB_M11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1795',
//                               name: '7EMDLC11D1'
//                             },
//                             {
//                               id: 'P2_1796',
//                               name: '7EMDLC11D2'
//                             },
//                             {
//                               id: 'P2_1797',
//                               name: '7EMDLC11D3'
//                             },
//                             {
//                               id: 'P2_1798',
//                               name: '7EMDLC11D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1814',
//                           name: 'DB_M13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1815',
//                               name: '7EMDLC13D1'
//                             },
//                             {
//                               id: 'P2_1816',
//                               name: '7EMDLC13D2'
//                             },
//                             {
//                               id: 'P2_1817',
//                               name: '7EMDLC13D3'
//                             },
//                             {
//                               id: 'P2_1818',
//                               name: '7EMDLC13D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1832',
//                           name: 'DB_M15',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1833',
//                               name: '7EMDLC15D1'
//                             },
//                             {
//                               id: 'P2_1834',
//                               name: '7EMDLC15D2'
//                             },
//                             {
//                               id: 'P2_1835',
//                               name: '7EMDLC15D3'
//                             },
//                             {
//                               id: 'P2_1836',
//                               name: '7EMDLC15D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1840',
//                           name: 'DB_M16',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1841',
//                               name: '7EMDLC16D1'
//                             },
//                             {
//                               id: 'P2_1842',
//                               name: '7EMDLC16D2'
//                             },
//                             {
//                               id: 'P2_1843',
//                               name: '7EMDLC16D3'
//                             },
//                             {
//                               id: 'P2_1844',
//                               name: '7EMDLC16D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1848',
//                           name: 'DB_M17',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1849',
//                               name: '7EMDLC17D1'
//                             },
//                             {
//                               id: 'P2_1850',
//                               name: '7EMDLC17D2'
//                             },
//                             {
//                               id: 'P2_1851',
//                               name: '7EMDLC17D3'
//                             },
//                             {
//                               id: 'P2_1852',
//                               name: '7EMDLC17D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1871',
//                           name: 'DB_M20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1872',
//                               name: '7EMDLC20D1'
//                             },
//                             {
//                               id: 'P2_1873',
//                               name: '7EMDLC20D2'
//                             },
//                             {
//                               id: 'P2_1874',
//                               name: '7EMDLC20D3'
//                             },
//                             {
//                               id: 'P2_1875',
//                               name: '7EMDLC20D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1901',
//                           name: 'DB_M23',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1902',
//                               name: '7EMDLC23D1'
//                             },
//                             {
//                               id: 'P2_1903',
//                               name: '7EMDLC23D2'
//                             },
//                             {
//                               id: 'P2_1904',
//                               name: '7EMDLC23D3'
//                             },
//                             {
//                               id: 'P2_1905',
//                               name: '7EMDLC23D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1909',
//                           name: 'DB_M24',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1910',
//                               name: '7EMDLC24D1'
//                             },
//                             {
//                               id: 'P2_1911',
//                               name: '7EMDLC24D2'
//                             },
//                             {
//                               id: 'P2_1912',
//                               name: '7EMDLC24D3'
//                             },
//                             {
//                               id: 'P2_1913',
//                               name: '7EMDLC24D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1917',
//                           name: 'DB_M25',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1918',
//                               name: '7EMDLC25D1'
//                             },
//                             {
//                               id: 'P2_1919',
//                               name: '7EMDLC25D2'
//                             },
//                             {
//                               id: 'P2_1920',
//                               name: '7EMDLC25D3'
//                             },
//                             {
//                               id: 'P2_1921',
//                               name: '7EMDLC25D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1927',
//                           name: 'DB_M26',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1928',
//                               name: '7EMDLC26D1'
//                             },
//                             {
//                               id: 'P2_1929',
//                               name: '7EMDLC26D2'
//                             },
//                             {
//                               id: 'P2_1930',
//                               name: '7EMDLC26D3'
//                             },
//                             {
//                               id: 'P2_1931',
//                               name: '7EMDLC26D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1935',
//                           name: 'DB_M27',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1936',
//                               name: '7EMDLC27D1'
//                             },
//                             {
//                               id: 'P2_1937',
//                               name: '7EMDLC27D2'
//                             },
//                             {
//                               id: 'P2_1938',
//                               name: '7EMDLC27D3'
//                             },
//                             {
//                               id: 'P2_1939',
//                               name: '7EMDLC27D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1945',
//                           name: 'DB_M28',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1946',
//                               name: '7EMDLC28D1'
//                             },
//                             {
//                               id: 'P2_1947',
//                               name: '7EMDLC28D2'
//                             },
//                             {
//                               id: 'P2_1948',
//                               name: '7EMDLC28D3'
//                             },
//                             {
//                               id: 'P2_1949',
//                               name: '7EMDLC28D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2628',
//                           name: 'DB_M29',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2630',
//                               name: '7EMDLC29D1'
//                             },
//                             {
//                               id: 'P2_2649',
//                               name: '7EMDLC29D2'
//                             },
//                             {
//                               id: 'P2_2650',
//                               name: '7EMDLC29D3'
//                             },
//                             {
//                               id: 'P2_2651',
//                               name: '7EMDLC29D4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1716',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1717',
//                           name: 'WB_M01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1718',
//                               name: '7EMDLC01W1'
//                             },
//                             {
//                               id: 'P2_1719',
//                               name: '7EMDLC01W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1725',
//                           name: 'WB_M02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1726',
//                               name: '7EMDLC02W1'
//                             },
//                             {
//                               id: 'P2_1727',
//                               name: '7EMDLC02W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1733',
//                           name: 'WB_M03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1734',
//                               name: '7EMDLC03W1'
//                             },
//                             {
//                               id: 'P2_1735',
//                               name: '7EMDLC03W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1741',
//                           name: 'WB_M04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1742',
//                               name: '7EMDLC04W1'
//                             },
//                             {
//                               id: 'P2_1743',
//                               name: '7EMDLC04W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1749',
//                           name: 'WB_M05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1750',
//                               name: '7EMDLC05W1'
//                             },
//                             {
//                               id: 'P2_1751',
//                               name: '7EMDLC05W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1757',
//                           name: 'WB_M06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1758',
//                               name: '7EMDLC06W1'
//                             },
//                             {
//                               id: 'P2_1759',
//                               name: '7EMDLC06W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1765',
//                           name: 'WB_M07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1766',
//                               name: '7EMDLC07W1'
//                             },
//                             {
//                               id: 'P2_1767',
//                               name: '7EMDLC07W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1773',
//                           name: 'WB_M08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1774',
//                               name: '7EMDLC08W1'
//                             },
//                             {
//                               id: 'P2_1775',
//                               name: '7EMDLC08W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1781',
//                           name: 'WB_M09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1782',
//                               name: '7EMDLC09W1'
//                             },
//                             {
//                               id: 'P2_1783',
//                               name: '7EMDLC09W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1789',
//                           name: 'WB_M10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1790',
//                               name: '7EMDLC10W1'
//                             },
//                             {
//                               id: 'P2_1791',
//                               name: '7EMDLC10W2'
//                             },
//                             {
//                               id: 'P2_1792',
//                               name: '7EMDLC10W3'
//                             },
//                             {
//                               id: 'P2_1793',
//                               name: '7EMDLC10W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1799',
//                           name: 'WB_M11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1800',
//                               name: '7EMDLC11W1'
//                             },
//                             {
//                               id: 'P2_1801',
//                               name: '7EMDLC11W2'
//                             },
//                             {
//                               id: 'P2_1802',
//                               name: '7EMDLC11W3'
//                             },
//                             {
//                               id: 'P2_1803',
//                               name: '7EMDLC11W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1819',
//                           name: 'WB_M13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1820',
//                               name: '7EMDLC13W1'
//                             },
//                             {
//                               id: 'P2_1821',
//                               name: '7EMDLC13W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1837',
//                           name: 'WB_M15',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1838',
//                               name: '7EMDLC15W1'
//                             },
//                             {
//                               id: 'P2_1839',
//                               name: '7EMDLC15W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1845',
//                           name: 'WB_M16',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1846',
//                               name: '7EMDLC16W1'
//                             },
//                             {
//                               id: 'P2_1847',
//                               name: '7EMDLC16W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1853',
//                           name: 'WB_M17',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1854',
//                               name: '7EMDLC17W1'
//                             },
//                             {
//                               id: 'P2_1855',
//                               name: '7EMDLC17W2'
//                             },
//                             {
//                               id: 'P2_1856',
//                               name: '7EMDLC17W3'
//                             },
//                             {
//                               id: 'P2_1857',
//                               name: '7EMDLC17W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1868',
//                           name: 'WB_M19',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1869',
//                               name: '7EMDLC19W1'
//                             },
//                             {
//                               id: 'P2_1870',
//                               name: '7EMDLC19W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1876',
//                           name: 'WB_M20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1877',
//                               name: '7EMDLC20W1'
//                             },
//                             {
//                               id: 'P2_1878',
//                               name: '7EMDLC20W2'
//                             },
//                             {
//                               id: 'P2_1879',
//                               name: '7EMDLC20W3'
//                             },
//                             {
//                               id: 'P2_1880',
//                               name: '7EMDLC20W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1906',
//                           name: 'WB_M23',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1907',
//                               name: '7EMDLC23W1'
//                             },
//                             {
//                               id: 'P2_1908',
//                               name: '7EMDLC23W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1914',
//                           name: 'WB_M24',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1915',
//                               name: '7EMDLC24W1'
//                             },
//                             {
//                               id: 'P2_1916',
//                               name: '7EMDLC24W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1922',
//                           name: 'WB_M25',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1923',
//                               name: '7EMDLC25W1'
//                             },
//                             {
//                               id: 'P2_1924',
//                               name: '7EMDLC25W2'
//                             },
//                             {
//                               id: 'P2_1925',
//                               name: '7EMDLC25W3'
//                             },
//                             {
//                               id: 'P2_1926',
//                               name: '7EMDLC25W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1932',
//                           name: 'WB_M26',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1933',
//                               name: '7EMDLC26W1'
//                             },
//                             {
//                               id: 'P2_1934',
//                               name: '7EMDLC26W2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1940',
//                           name: 'WB_M27',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1941',
//                               name: '7EMDLC27W1'
//                             },
//                             {
//                               id: 'P2_1942',
//                               name: '7EMDLC27W2'
//                             },
//                             {
//                               id: 'P2_1943',
//                               name: '7EMDLC27W3'
//                             },
//                             {
//                               id: 'P2_1944',
//                               name: '7EMDLC27W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1950',
//                           name: 'WB_M28',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1951',
//                               name: '7EMDLC28W1'
//                             },
//                             {
//                               id: 'P2_1952',
//                               name: '7EMDLC28W2'
//                             },
//                             {
//                               id: 'P2_1953',
//                               name: '7EMDLC28W3'
//                             },
//                             {
//                               id: 'P2_1954',
//                               name: '7EMDLC28W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2629',
//                           name: 'WB_M29',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2652',
//                               name: '7EMDLC29W1'
//                             },
//                             {
//                               id: 'P2_2653',
//                               name: '7EMDLC29W2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1955',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1956',
//                           name: 'IND',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1957',
//                               name: '7E3RDS05'
//                             },
//                             {
//                               id: 'P2_1958',
//                               name: '7E3RDS06'
//                             },
//                             {
//                               id: 'P2_1959',
//                               name: '7E3RDS07'
//                             },
//                             {
//                               id: 'P2_1960',
//                               name: '7E3RDS08'
//                             },
//                             {
//                               id: 'P2_1961',
//                               name: '7E3RDS09'
//                             },
//                             {
//                               id: 'P2_1962',
//                               name: '7E3RDS10'
//                             },
//                             {
//                               id: 'P2_1964',
//                               name: '7E3RDS12'
//                             },
//                             {
//                               id: 'P2_1965',
//                               name: '7E3RDS13'
//                             },
//                             {
//                               id: 'P2_1966',
//                               name: '7E3RDS14'
//                             },
//                             {
//                               id: 'P2_1968',
//                               name: '7E3RDS16'
//                             },
//                             {
//                               id: 'P2_1969',
//                               name: '7E3RDS17'
//                             },
//                             {
//                               id: 'P2_1970',
//                               name: '7E3RDS18'
//                             },
//                             {
//                               id: 'P2_1971',
//                               name: '7E3RDS19'
//                             },
//                             {
//                               id: 'P2_1972',
//                               name: '7E3RDS20'
//                             },
//                             {
//                               id: 'P2_1973',
//                               name: '7E3RDS21'
//                             },
//                             {
//                               id: 'P2_1975',
//                               name: '7E3RDS23'
//                             },
//                             {
//                               id: 'P2_1977',
//                               name: '7E3RDS25'
//                             },
//                             {
//                               id: 'P2_1978',
//                               name: '7E3RDS26'
//                             },
//                             {
//                               id: 'P2_1979',
//                               name: '7E3RDS27'
//                             },
//                             {
//                               id: 'P2_1980',
//                               name: '7E3RDS28'
//                             },
//                             {
//                               id: 'P2_1981',
//                               name: '7E3RDS29'
//                             },
//                             {
//                               id: 'P2_2542',
//                               name: '7E3RDS31'
//                             },
//                             {
//                               id: 'P2_2543',
//                               name: '7E3RDS32'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1983',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1984',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1985',
//                               name: '7EIMSS01'
//                             },
//                             {
//                               id: 'P2_1986',
//                               name: '7EIMSS02'
//                             },
//                             {
//                               id: 'P2_1987',
//                               name: '7EIMSS03'
//                             },
//                             {
//                               id: 'P2_1988',
//                               name: '7EIMSS04'
//                             },
//                             {
//                               id: 'P2_1991',
//                               name: '7EIMSS08'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2025',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2633',
//                               name: '7EITSS14'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2030',
//                           name: 'INTO_TS40',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2031',
//                               name: '7EITSS09'
//                             },
//                             {
//                               id: 'P2_2032',
//                               name: '7EITSS10'
//                             },
//                             {
//                               id: 'P2_2034',
//                               name: '7EITSS12'
//                             },
//                             {
//                               id: 'P2_2472',
//                               name: '7EITSS13'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2035',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2036',
//                               name: '7ETWSS01'
//                             },
//                             {
//                               id: 'P2_2037',
//                               name: '7ETWSS02'
//                             },
//                             {
//                               id: 'P2_2038',
//                               name: '7ETWSS03'
//                             },
//                             {
//                               id: 'P2_2039',
//                               name: '7ETWSS04'
//                             },
//                             {
//                               id: 'P2_2226',
//                               name: '7ETWSS05'
//                             },
//                             {
//                               id: 'P2_2227',
//                               name: '7ETWSS06'
//                             },
//                             {
//                               id: 'P2_2228',
//                               name: '7ETWSS07'
//                             },
//                             {
//                               id: 'P2_2229',
//                               name: '7ETWSS08'
//                             },
//                             {
//                               id: 'P2_2230',
//                               name: '7ETWSS09'
//                             },
//                             {
//                               id: 'P2_2231',
//                               name: '7ETWSS10'
//                             },
//                             {
//                               id: 'P2_2232',
//                               name: '7ETWSS11'
//                             },
//                             {
//                               id: 'P2_2233',
//                               name: '7ETWSS12'
//                             },
//                             {
//                               id: 'P2_2234',
//                               name: '7ETWSS13'
//                             },
//                             {
//                               id: 'P2_2235',
//                               name: '7ETWSS14'
//                             },
//                             {
//                               id: 'P2_2236',
//                               name: '7ETWSS15'
//                             },
//                             {
//                               id: 'P2_2237',
//                               name: '7ETWSS16'
//                             },
//                             {
//                               id: 'P2_2238',
//                               name: '7ETWSS17'
//                             },
//                             {
//                               id: 'P2_2239',
//                               name: '7ETWSS18'
//                             },
//                             {
//                               id: 'P2_2240',
//                               name: '7ETWSS19'
//                             },
//                             {
//                               id: 'P2_2241',
//                               name: '7ETWSS20'
//                             },
//                             {
//                               id: 'P2_2242',
//                               name: '7ETWSS21'
//                             },
//                             {
//                               id: 'P2_2243',
//                               name: '7ETWSS22'
//                             },
//                             {
//                               id: 'P2_2244',
//                               name: '7ETWSS23'
//                             },
//                             {
//                               id: 'P2_2245',
//                               name: '7ETWSS24'
//                             },
//                             {
//                               id: 'P2_2246',
//                               name: '7ETWSS25'
//                             },
//                             {
//                               id: 'P2_2247',
//                               name: '7ETWSS26'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1998',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1999',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2002',
//                               name: '7EIMTS03'
//                             },
//                             {
//                               id: 'P2_2003',
//                               name: '7EIMTS04'
//                             },
//                             {
//                               id: 'P2_2004',
//                               name: '7EIMTS05'
//                             },
//                             {
//                               id: 'P2_2005',
//                               name: '7EIMTS06'
//                             },
//                             {
//                               id: 'P2_2007',
//                               name: '7EIMTS08'
//                             },
//                             {
//                               id: 'P2_2010',
//                               name: '7EIMTS11'
//                             },
//                             {
//                               id: 'P2_2011',
//                               name: '7EIMTS12'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2012',
//                           name: 'INLINER',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2013',
//                               name: '7EINLS01'
//                             },
//                             {
//                               id: 'P2_2015',
//                               name: '7EINLS03'
//                             },
//                             {
//                               id: 'P2_2017',
//                               name: '7EINLS05'
//                             },
//                             {
//                               id: 'P2_2019',
//                               name: '7EINLS07'
//                             },
//                             {
//                               id: 'P2_2020',
//                               name: '7EINLS08'
//                             },
//                             {
//                               id: 'P2_2021',
//                               name: '7EINLS09'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2123',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2124',
//                               name: '7EITTS01'
//                             },
//                             {
//                               id: 'P2_2125',
//                               name: '7EITTS02'
//                             },
//                             {
//                               id: 'P2_2145',
//                               name: '7EITTS21'
//                             },
//                             {
//                               id: 'P2_2146',
//                               name: '7EITTS22'
//                             },
//                             {
//                               id: 'P2_2147',
//                               name: '7EITTS23'
//                             },
//                             {
//                               id: 'P2_2148',
//                               name: '7EITTS24'
//                             },
//                             {
//                               id: 'P2_2149',
//                               name: '7EITTS25'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2126',
//                           name: 'INTO_TX27',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2127',
//                               name: '7EITTS03'
//                             },
//                             {
//                               id: 'P2_2128',
//                               name: '7EITTS04'
//                             },
//                             {
//                               id: 'P2_2129',
//                               name: '7EITTS05'
//                             },
//                             {
//                               id: 'P2_2130',
//                               name: '7EITTS07'
//                             },
//                             {
//                               id: 'P2_2131',
//                               name: '7EITTS08'
//                             },
//                             {
//                               id: 'P2_2132',
//                               name: '7EITTS09'
//                             },
//                             {
//                               id: 'P2_2138',
//                               name: '7EITTS15'
//                             },
//                             {
//                               id: 'P2_2151',
//                               name: '7EITTS27'
//                             },
//                             {
//                               id: 'P2_2152',
//                               name: '7EITTS28'
//                             },
//                             {
//                               id: 'P2_2153',
//                               name: '7EITTS29'
//                             },
//                             {
//                               id: 'P2_2154',
//                               name: '7EITTS30'
//                             },
//                             {
//                               id: 'P2_2155',
//                               name: '7EITTS31'
//                             },
//                             {
//                               id: 'P2_2156',
//                               name: '7EITTS32'
//                             },
//                             {
//                               id: 'P2_2157',
//                               name: '7EITTS33'
//                             },
//                             {
//                               id: 'P2_2158',
//                               name: '7EITTS34'
//                             },
//                             {
//                               id: 'P2_2159',
//                               name: '7EITTS36'
//                             },
//                             {
//                               id: 'P2_2160',
//                               name: '7EITTS37'
//                             },
//                             {
//                               id: 'P2_2161',
//                               name: '7EITTS38'
//                             },
//                             {
//                               id: 'P2_2162',
//                               name: '7EITTS39'
//                             },
//                             {
//                               id: 'P2_2163',
//                               name: '7EITTS40'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2139',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2634',
//                               name: '7EITTS41'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2248',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2249',
//                               name: '7ETWTS01'
//                             },
//                             {
//                               id: 'P2_2250',
//                               name: '7ETWTS02'
//                             },
//                             {
//                               id: 'P2_2251',
//                               name: '7ETWTS03'
//                             },
//                             {
//                               id: 'P2_2252',
//                               name: '7ETWTS04'
//                             },
//                             {
//                               id: 'P2_2253',
//                               name: '7ETWTS05'
//                             },
//                             {
//                               id: 'P2_2254',
//                               name: '7ETWTS06'
//                             },
//                             {
//                               id: 'P2_2256',
//                               name: '7ETWTS08'
//                             },
//                             {
//                               id: 'P2_2257',
//                               name: '7ETWTS09'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2258',
//                           name: 'INTO_TX30',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2260',
//                               name: '7EVSTS05'
//                             },
//                             {
//                               id: 'P2_2261',
//                               name: '7EVSTS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2022',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2023',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2024',
//                               name: '7EITDS01'
//                             },
//                             {
//                               id: 'P2_2090',
//                               name: '7EITDS02'
//                             },
//                             {
//                               id: 'P2_2091',
//                               name: '7EITDS03'
//                             },
//                             {
//                               id: 'P2_2547',
//                               name: '7EITDS04'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2040',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2041',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2042',
//                               name: '7EAVIS01'
//                             },
//                             {
//                               id: 'P2_2043',
//                               name: '7EAVIS02'
//                             },
//                             {
//                               id: 'P2_2044',
//                               name: '7EAVIS03'
//                             },
//                             {
//                               id: 'P2_2056',
//                               name: '7EAVIS14'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2047',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2048',
//                               name: '7EAVIS06'
//                             },
//                             {
//                               id: 'P2_2049',
//                               name: '7EAVIS07'
//                             },
//                             {
//                               id: 'P2_2050',
//                               name: '7EAVIS08'
//                             },
//                             {
//                               id: 'P2_2051',
//                               name: '7EAVIS09'
//                             },
//                             {
//                               id: 'P2_2052',
//                               name: '7EAVIS10'
//                             },
//                             {
//                               id: 'P2_2053',
//                               name: '7EAVIS11'
//                             },
//                             {
//                               id: 'P2_2054',
//                               name: '7EAVIS12'
//                             },
//                             {
//                               id: 'P2_2055',
//                               name: '7EAVIS13'
//                             },
//                             {
//                               id: 'P2_2057',
//                               name: '7EAVIS15'
//                             },
//                             {
//                               id: 'P2_2058',
//                               name: '7EAVIS16'
//                             },
//                             {
//                               id: 'P2_2059',
//                               name: '7EAVIS17'
//                             },
//                             {
//                               id: 'P2_2060',
//                               name: '7EAVIS18'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2065',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2066',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2067',
//                               name: '7EEVIS31'
//                             },
//                             {
//                               id: 'P2_2068',
//                               name: '7EEVIS32'
//                             },
//                             {
//                               id: 'P2_2069',
//                               name: '7EEVIS33'
//                             },
//                             {
//                               id: 'P2_2070',
//                               name: '7EEVIS34'
//                             },
//                             {
//                               id: 'P2_2071',
//                               name: '7EEVIS35'
//                             },
//                             {
//                               id: 'P2_2072',
//                               name: '7EEVIS36'
//                             },
//                             {
//                               id: 'P2_2073',
//                               name: '7EEVIS37'
//                             },
//                             {
//                               id: 'P2_2074',
//                               name: '7EEVIS38'
//                             },
//                             {
//                               id: 'P2_2075',
//                               name: '7EEVIS39'
//                             },
//                             {
//                               id: 'P2_2079',
//                               name: '7EEVIS42'
//                             },
//                             {
//                               id: 'P2_2080',
//                               name: '7EEVIS43'
//                             },
//                             {
//                               id: 'P2_2081',
//                               name: '7EEVIS44'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2076',
//                           name: 'IEF',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2078',
//                               name: '7EEVIS41'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2082',
//                       name: '100%_VISUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2083',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2085',
//                               name: '7EEVIS46'
//                             },
//                             {
//                               id: 'P2_2087',
//                               name: '7EEVIS48'
//                             },
//                             {
//                               id: 'P2_2089',
//                               name: '7EEVIS50'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2094',
//                       name: 'LASER_MARK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2095',
//                           name: 'LEDVANCE',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1503',
//                               name: '70MRKS03'
//                             },
//                             {
//                               id: 'P2_1504',
//                               name: '70MRKS04'
//                             },
//                             {
//                               id: 'P2_1505',
//                               name: '70MRKS05'
//                             },
//                             {
//                               id: 'P2_2096',
//                               name: '7EMRKS01'
//                             },
//                             {
//                               id: 'P2_2097',
//                               name: '7EMRKS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2098',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1507',
//                               name: '70MRKS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2100',
//                       name: 'RESIN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2172',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2173',
//                               name: '7ERCAS01'
//                             },
//                             {
//                               id: 'P2_2174',
//                               name: '7ERCAS02'
//                             },
//                             {
//                               id: 'P2_2177',
//                               name: '7ERCAS05'
//                             },
//                             {
//                               id: 'P2_2178',
//                               name: '7ERCAS06'
//                             },
//                             {
//                               id: 'P2_2179',
//                               name: '7ERCAS07'
//                             },
//                             {
//                               id: 'P2_2180',
//                               name: '7ERCAS08'
//                             },
//                             {
//                               id: 'P2_2182',
//                               name: '7ERCAS10'
//                             },
//                             {
//                               id: 'P2_2183',
//                               name: '7ERCAS11'
//                             },
//                             {
//                               id: 'P2_2185',
//                               name: '7ERCAS13'
//                             },
//                             {
//                               id: 'P2_2186',
//                               name: '7ERCAS14'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2116',
//                       name: 'SUPP_SILICONE_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2117',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2118',
//                               name: '7ESCDS01'
//                             },
//                             {
//                               id: 'P2_2119',
//                               name: '7ESCDS02'
//                             },
//                             {
//                               id: 'P2_2120',
//                               name: '7ESCDS03'
//                             },
//                             {
//                               id: 'P2_2121',
//                               name: '7ESCDS04'
//                             },
//                             {
//                               id: 'P2_2122',
//                               name: '7ESCDS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2187',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2188',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2189',
//                               name: '7ESCAS01'
//                             },
//                             {
//                               id: 'P2_2190',
//                               name: '7ESCAS02'
//                             },
//                             {
//                               id: 'P2_2191',
//                               name: '7ESCAS03'
//                             },
//                             {
//                               id: 'P2_2195',
//                               name: '7ESCAS07'
//                             },
//                             {
//                               id: 'P2_2196',
//                               name: '7ESCAS08'
//                             },
//                             {
//                               id: 'P2_2197',
//                               name: '7ESCAS09'
//                             },
//                             {
//                               id: 'P2_2198',
//                               name: '7ESCAS10'
//                             },
//                             {
//                               id: 'P2_2199',
//                               name: '7ESCAS11'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2200',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2201',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2202',
//                               name: '7ETRFS01'
//                             },
//                             {
//                               id: 'P2_2203',
//                               name: '7ETRFS02'
//                             },
//                             {
//                               id: 'P2_2204',
//                               name: '7ETRFS03'
//                             },
//                             {
//                               id: 'P2_2205',
//                               name: '7ETRFS04'
//                             },
//                             {
//                               id: 'P2_2207',
//                               name: '7ETRFS06'
//                             },
//                             {
//                               id: 'P2_2208',
//                               name: '7ETRFS07'
//                             },
//                             {
//                               id: 'P2_2209',
//                               name: '7ETRFS08'
//                             },
//                             {
//                               id: 'P2_2210',
//                               name: '7ETRFS09'
//                             },
//                             {
//                               id: 'P2_2211',
//                               name: '7ETRFS10'
//                             },
//                             {
//                               id: 'P2_2214',
//                               name: '7ETRFS12'
//                             },
//                             {
//                               id: 'P2_2217',
//                               name: '7ETRFS15'
//                             },
//                             {
//                               id: 'P2_2218',
//                               name: '7ETRFS16'
//                             },
//                             {
//                               id: 'P2_2219',
//                               name: '7ETRFS17'
//                             },
//                             {
//                               id: 'P2_2220',
//                               name: '7ETRFS18'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2212',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2213',
//                               name: '7ETRFS11'
//                             },
//                             {
//                               id: 'P2_2225',
//                               name: '7ETRFS23'
//                             },
//                             {
//                               id: 'P2_2632',
//                               name: '7ETRFS24'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2546',
//                       name: 'SUPP_RESIN_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2101',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2102',
//                               name: '7ERCDS01'
//                             },
//                             {
//                               id: 'P2_2103',
//                               name: '7ERCDS02'
//                             },
//                             {
//                               id: 'P2_2104',
//                               name: '7ERCDS03'
//                             },
//                             {
//                               id: 'P2_2105',
//                               name: '7ERCDS04'
//                             },
//                             {
//                               id: 'P2_2106',
//                               name: '7ERCDS05'
//                             },
//                             {
//                               id: 'P2_2107',
//                               name: '7ERCDS06'
//                             },
//                             {
//                               id: 'P2_2108',
//                               name: '7ERCDS07'
//                             },
//                             {
//                               id: 'P2_2109',
//                               name: '7ERCDS08'
//                             },
//                             {
//                               id: 'P2_2110',
//                               name: '7ERCDS09'
//                             },
//                             {
//                               id: 'P2_2111',
//                               name: '7ERCDS10'
//                             },
//                             {
//                               id: 'P2_2112',
//                               name: '7ERCDS11'
//                             },
//                             {
//                               id: 'P2_2114',
//                               name: '7ERCDS13'
//                             },
//                             {
//                               id: 'P2_2115',
//                               name: '7ERCDS14'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_1009',
//                   name: 'MF1 - APT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1010',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1273',
//                           name: 'DB_M03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1274',
//                               name: '7BDAPC03D1'
//                             },
//                             {
//                               id: 'P2_1275',
//                               name: '7BDAPC03D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1276',
//                           name: 'DB_M04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1277',
//                               name: '7BDAPC04D1'
//                             },
//                             {
//                               id: 'P2_1278',
//                               name: '7BDAPC04D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1279',
//                           name: 'DB_M05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1280',
//                               name: '7BDAPC05D1'
//                             },
//                             {
//                               id: 'P2_1281',
//                               name: '7BDAPC05D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1284',
//                           name: 'DB_M07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1285',
//                               name: '7BDAPC07D1'
//                             },
//                             {
//                               id: 'P2_1286',
//                               name: '7BDAPC07D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1287',
//                           name: 'DB_M08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1288',
//                               name: '7BDAPC08D1'
//                             },
//                             {
//                               id: 'P2_1289',
//                               name: '7BDAPC08D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1290',
//                           name: 'DB_M09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1291',
//                               name: '7BDAPC09D1'
//                             },
//                             {
//                               id: 'P2_1292',
//                               name: '7BDAPC09D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1293',
//                           name: 'DB_M10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1294',
//                               name: '7BDAPC10D1'
//                             },
//                             {
//                               id: 'P2_1295',
//                               name: '7BDAPC10D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1296',
//                           name: 'DB_M11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1297',
//                               name: '7BDAPC11D1'
//                             },
//                             {
//                               id: 'P2_1298',
//                               name: '7BDAPC11D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1299',
//                           name: 'DB_M12',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1300',
//                               name: '7BDAPC12D1'
//                             },
//                             {
//                               id: 'P2_1301',
//                               name: '7BDAPC12D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1302',
//                           name: 'DB_M13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1303',
//                               name: '7BDAPC13D1'
//                             },
//                             {
//                               id: 'P2_1304',
//                               name: '7BDAPC13D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1305',
//                           name: 'DB_M14',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1306',
//                               name: '7BDAPC14D1'
//                             },
//                             {
//                               id: 'P2_1307',
//                               name: '7BDAPC14D2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1237',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1238',
//                           name: 'IND',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1239',
//                               name: '7B3RDS06'
//                             },
//                             {
//                               id: 'P2_1240',
//                               name: '7B3RDS07'
//                             },
//                             {
//                               id: 'P2_1241',
//                               name: '7B3RDS08'
//                             },
//                             {
//                               id: 'P2_1242',
//                               name: '7B3RDS09'
//                             },
//                             {
//                               id: 'P2_1243',
//                               name: '7B3RDS10'
//                             },
//                             {
//                               id: 'P2_1244',
//                               name: '7B3RDS11'
//                             },
//                             {
//                               id: 'P2_1245',
//                               name: '7B3RDS12'
//                             },
//                             {
//                               id: 'P2_1246',
//                               name: '7B3RDS13'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1247',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1248',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1249',
//                               name: '7BAVIS01'
//                             },
//                             {
//                               id: 'P2_1254',
//                               name: '7BAVIS05'
//                             },
//                             {
//                               id: 'P2_1255',
//                               name: '7BAVIS06'
//                             },
//                             {
//                               id: 'P2_2839',
//                               name: '7BAVIS08'
//                             },
//                             {
//                               id: 'P2_2841',
//                               name: '7BAVIS09'
//                             },
//                             {
//                               id: 'P2_2842',
//                               name: '7BAVIS10'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1257',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1258',
//                           name: 'IEF',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1259',
//                               name: '7BEVIS11'
//                             },
//                             {
//                               id: 'P2_1260',
//                               name: '7BEVIS12'
//                             },
//                             {
//                               id: 'P2_1261',
//                               name: '7BEVIS13'
//                             },
//                             {
//                               id: 'P2_1262',
//                               name: '7BEVIS14'
//                             },
//                             {
//                               id: 'P2_1263',
//                               name: '7BEVIS15'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1265',
//                       name: 'SUPP_SILICONE_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1266',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1268',
//                               name: '7BSCDS01'
//                             },
//                             {
//                               id: 'P2_1269',
//                               name: '7BSCDS02'
//                             },
//                             {
//                               id: 'P2_1270',
//                               name: '7BSCDS03'
//                             },
//                             {
//                               id: 'P2_1271',
//                               name: '7BSCDS04'
//                             },
//                             {
//                               id: 'P2_1272',
//                               name: '7BSCDS05'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2760',
//                           name: 'LAYER_OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1267',
//                               name: '7BLTCS01'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1308',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1367',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1368',
//                               name: '7BTWSS01'
//                             },
//                             {
//                               id: 'P2_1369',
//                               name: '7BTWSS02'
//                             },
//                             {
//                               id: 'P2_1370',
//                               name: '7BTWSS03'
//                             },
//                             {
//                               id: 'P2_1371',
//                               name: '7BTWSS04'
//                             },
//                             {
//                               id: 'P2_1372',
//                               name: '7BTWSS05'
//                             },
//                             {
//                               id: 'P2_1373',
//                               name: '7BTWSS06'
//                             },
//                             {
//                               id: 'P2_1374',
//                               name: '7BTWSS07'
//                             },
//                             {
//                               id: 'P2_1375',
//                               name: '7BTWSS08'
//                             },
//                             {
//                               id: 'P2_1376',
//                               name: '7BTWSS09'
//                             },
//                             {
//                               id: 'P2_1377',
//                               name: '7BTWSS10'
//                             },
//                             {
//                               id: 'P2_1378',
//                               name: '7BTWSS11'
//                             },
//                             {
//                               id: 'P2_1379',
//                               name: '7BTWSS12'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1311',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1319',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1320',
//                               name: '7BITTS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1324',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1325',
//                               name: '7BITTS05'
//                             },
//                             {
//                               id: 'P2_1326',
//                               name: '7BITTS06'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1380',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1381',
//                               name: '7BTWTS01'
//                             },
//                             {
//                               id: 'P2_1382',
//                               name: '7BTWTS02'
//                             },
//                             {
//                               id: 'P2_1383',
//                               name: '7BTWTS03'
//                             },
//                             {
//                               id: 'P2_1384',
//                               name: '7BTWTS04'
//                             },
//                             {
//                               id: 'P2_1385',
//                               name: '7BTWTS05'
//                             },
//                             {
//                               id: 'P2_1386',
//                               name: '7BTWTS06'
//                             },
//                             {
//                               id: 'P2_1387',
//                               name: '7BTWTS07'
//                             },
//                             {
//                               id: 'P2_1388',
//                               name: '7BTWTS08'
//                             },
//                             {
//                               id: 'P2_1389',
//                               name: '7BTWTS09'
//                             },
//                             {
//                               id: 'P2_1390',
//                               name: '7BTWTS10'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1327',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1328',
//                           name: 'DATACON',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1329',
//                               name: '7BLAYS01'
//                             },
//                             {
//                               id: 'P2_1330',
//                               name: '7BLAYS02'
//                             },
//                             {
//                               id: 'P2_1331',
//                               name: '7BLAYS03'
//                             },
//                             {
//                               id: 'P2_1333',
//                               name: '7BLAYS05'
//                             },
//                             {
//                               id: 'P2_1334',
//                               name: '7BLAYS06'
//                             },
//                             {
//                               id: 'P2_1337',
//                               name: '7BLAYS09'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1340',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1341',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1342',
//                               name: '7BSCAS01'
//                             },
//                             {
//                               id: 'P2_1343',
//                               name: '7BSCAS02'
//                             },
//                             {
//                               id: 'P2_1344',
//                               name: '7BSCAS03'
//                             },
//                             {
//                               id: 'P2_1345',
//                               name: '7BSCAS04'
//                             },
//                             {
//                               id: 'P2_1346',
//                               name: '7BSCAS05'
//                             },
//                             {
//                               id: 'P2_1348',
//                               name: '7BSCAS07'
//                             },
//                             {
//                               id: 'P2_1349',
//                               name: '7BSCAS08'
//                             },
//                             {
//                               id: 'P2_1350',
//                               name: '7BSCAS09'
//                             },
//                             {
//                               id: 'P2_1351',
//                               name: '7BSCAS10'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1352',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1353',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1354',
//                               name: '7BTRFS01'
//                             },
//                             {
//                               id: 'P2_1355',
//                               name: '7BTRFS02'
//                             },
//                             {
//                               id: 'P2_1356',
//                               name: '7BTRFS03'
//                             },
//                             {
//                               id: 'P2_1357',
//                               name: '7BTRFS04'
//                             },
//                             {
//                               id: 'P2_1360',
//                               name: '7BTRFS07'
//                             },
//                             {
//                               id: 'P2_1362',
//                               name: '7BTRFS09'
//                             },
//                             {
//                               id: 'P2_1363',
//                               name: '7BTRFS10'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1364',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1365',
//                               name: '7BTRFS11'
//                             },
//                             {
//                               id: 'P2_1366',
//                               name: '7BTRFS12'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1391',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1392',
//                           name: 'SKW_ACB3000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1393',
//                               name: '7BWBPS02'
//                             },
//                             {
//                               id: 'P2_1394',
//                               name: '7BWBPS03'
//                             },
//                             {
//                               id: 'P2_1395',
//                               name: '7BWBPS04'
//                             },
//                             {
//                               id: 'P2_1396',
//                               name: '7BWBPS05'
//                             },
//                             {
//                               id: 'P2_1400',
//                               name: '7BWBPS08'
//                             },
//                             {
//                               id: 'P2_1401',
//                               name: '7BWBPS09'
//                             },
//                             {
//                               id: 'P2_1402',
//                               name: '7BWBPS10'
//                             },
//                             {
//                               id: 'P2_1403',
//                               name: '7BWBPS11'
//                             },
//                             {
//                               id: 'P2_1407',
//                               name: '7BWBPS14'
//                             },
//                             {
//                               id: 'P2_1408',
//                               name: '7BWBPS15'
//                             },
//                             {
//                               id: 'P2_1411',
//                               name: '7BWBPS18'
//                             },
//                             {
//                               id: 'P2_1412',
//                               name: '7BWBPS19'
//                             },
//                             {
//                               id: 'P2_1413',
//                               name: '7BWBPS20'
//                             },
//                             {
//                               id: 'P2_2616',
//                               name: '7BWBPS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1404',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1405',
//                               name: '7BWBPS12'
//                             },
//                             {
//                               id: 'P2_1406',
//                               name: '7BWBPS13'
//                             },
//                             {
//                               id: 'P2_1409',
//                               name: '7BWBPS16'
//                             },
//                             {
//                               id: 'P2_1410',
//                               name: '7BWBPS17'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2475',
//                       name: 'LASER_MARK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2477',
//                           name: 'LEDVANCE',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1501',
//                               name: '70MRKS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2478',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2099',
//                               name: '7EMRKS03'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2561',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1709',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2700',
//                               name: '7BITDS01'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_1508',
//                   name: 'MF1 - MINITOP',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1509',
//                       name: 'SUPP_RESIN_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1510',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1511',
//                               name: '7CRCDS01'
//                             },
//                             {
//                               id: 'P2_1512',
//                               name: '7CRCDS02'
//                             },
//                             {
//                               id: 'P2_1513',
//                               name: '7CRCDS03'
//                             },
//                             {
//                               id: 'P2_1514',
//                               name: '7CRCDS04'
//                             },
//                             {
//                               id: 'P2_1515',
//                               name: '7CRCDS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1516',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1517',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1518',
//                               name: '7CEVIS10'
//                             },
//                             {
//                               id: 'P2_1519',
//                               name: '7CEVIS11'
//                             },
//                             {
//                               id: 'P2_1520',
//                               name: '7CEVIS12'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1521',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1522',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1574',
//                               name: '7CIMSS01'
//                             },
//                             {
//                               id: 'P2_1575',
//                               name: '7CIMSS02'
//                             },
//                             {
//                               id: 'P2_1576',
//                               name: '7CIMSS03'
//                             },
//                             {
//                               id: 'P2_1577',
//                               name: '7CIMSS04'
//                             },
//                             {
//                               id: 'P2_1578',
//                               name: '7CIMSS05'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1620',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1621',
//                               name: '7CTWSS01'
//                             },
//                             {
//                               id: 'P2_1622',
//                               name: '7CTWSS02'
//                             },
//                             {
//                               id: 'P2_1623',
//                               name: '7CTWSS03'
//                             },
//                             {
//                               id: 'P2_1624',
//                               name: '7CTWSS04'
//                             },
//                             {
//                               id: 'P2_1625',
//                               name: '7CTWSS05'
//                             },
//                             {
//                               id: 'P2_1626',
//                               name: '7CTWSS06'
//                             },
//                             {
//                               id: 'P2_1627',
//                               name: '7CTWSS07'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1524',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1587',
//                           name: 'INTO_TX30',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1588',
//                               name: '7CITTS02'
//                             },
//                             {
//                               id: 'P2_1600',
//                               name: '7CITTS13'
//                             },
//                             {
//                               id: 'P2_1601',
//                               name: '7CITTS14'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1589',
//                           name: 'INTO_TX27',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1590',
//                               name: '7CITTS03'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1594',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1595',
//                               name: '7CITTS08'
//                             },
//                             {
//                               id: 'P2_1596',
//                               name: '7CITTS09'
//                             },
//                             {
//                               id: 'P2_1597',
//                               name: '7CITTS10'
//                             },
//                             {
//                               id: 'P2_1598',
//                               name: '7CITTS11'
//                             },
//                             {
//                               id: 'P2_1599',
//                               name: '7CITTS12'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1628',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1629',
//                               name: '7CTWTS01'
//                             },
//                             {
//                               id: 'P2_1630',
//                               name: '7CTWTS02'
//                             },
//                             {
//                               id: 'P2_1631',
//                               name: '7CTWTS03'
//                             },
//                             {
//                               id: 'P2_1632',
//                               name: '7CTWTS04'
//                             },
//                             {
//                               id: 'P2_1633',
//                               name: '7CTWTS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1528',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1529',
//                           name: 'DB_M01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1530',
//                               name: '7CDAPC01D1'
//                             },
//                             {
//                               id: 'P2_1531',
//                               name: '7CDAPC01D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1532',
//                           name: 'DB_M21',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2820',
//                               name: '7CDAPC21D1'
//                             },
//                             {
//                               id: 'P2_2821',
//                               name: '7CDAPC21D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1535',
//                           name: 'DB_M03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1536',
//                               name: '7CDAPC03D1'
//                             },
//                             {
//                               id: 'P2_1537',
//                               name: '7CDAPC03D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1538',
//                           name: 'DB_M04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1539',
//                               name: '7CDAPC04D1'
//                             },
//                             {
//                               id: 'P2_1540',
//                               name: '7CDAPC04D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1541',
//                           name: 'DB_M05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1542',
//                               name: '7CDAPC05D1'
//                             },
//                             {
//                               id: 'P2_1543',
//                               name: '7CDAPC05D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1544',
//                           name: 'DB_M06',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1545',
//                               name: '7CDAPC06D1'
//                             },
//                             {
//                               id: 'P2_1546',
//                               name: '7CDAPC06D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1547',
//                           name: 'DB_M07',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1548',
//                               name: '7CDAPC07D1'
//                             },
//                             {
//                               id: 'P2_1549',
//                               name: '7CDAPC07D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1550',
//                           name: 'DB_M08',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1551',
//                               name: '7CDAPC08D1'
//                             },
//                             {
//                               id: 'P2_1552',
//                               name: '7CDAPC08D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1553',
//                           name: 'DB_M09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1554',
//                               name: '7CDAPC09D1'
//                             },
//                             {
//                               id: 'P2_1555',
//                               name: '7CDAPC09D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1556',
//                           name: 'DB_M10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1557',
//                               name: '7CDAPC10D1'
//                             },
//                             {
//                               id: 'P2_1558',
//                               name: '7CDAPC10D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1559',
//                           name: 'DB_M11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1560',
//                               name: '7CDAPC11D1'
//                             },
//                             {
//                               id: 'P2_1561',
//                               name: '7CDAPC11D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1565',
//                           name: 'DB_M13',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1566',
//                               name: '7CDAPC13D1'
//                             },
//                             {
//                               id: 'P2_1567',
//                               name: '7CDAPC13D2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1603',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1604',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1605',
//                               name: '7CTRFS01'
//                             },
//                             {
//                               id: 'P2_1606',
//                               name: '7CTRFS02'
//                             },
//                             {
//                               id: 'P2_1607',
//                               name: '7CTRFS03'
//                             },
//                             {
//                               id: 'P2_1608',
//                               name: '7CTRFS04'
//                             },
//                             {
//                               id: 'P2_1609',
//                               name: '7CTRFS05'
//                             },
//                             {
//                               id: 'P2_1612',
//                               name: '7CTRFS08'
//                             },
//                             {
//                               id: 'P2_1613',
//                               name: '7CTRFS09'
//                             },
//                             {
//                               id: 'P2_1616',
//                               name: '7CTRFS12'
//                             },
//                             {
//                               id: 'P2_1617',
//                               name: '7CTRFS13'
//                             },
//                             {
//                               id: 'P2_1619',
//                               name: '7CTRFS15'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1635',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1636',
//                           name: 'SKW_ACB3000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1637',
//                               name: '7CWBPS01'
//                             },
//                             {
//                               id: 'P2_1638',
//                               name: '7CWBPS02'
//                             },
//                             {
//                               id: 'P2_1639',
//                               name: '7CWBPS03'
//                             },
//                             {
//                               id: 'P2_1640',
//                               name: '7CWBPS04'
//                             },
//                             {
//                               id: 'P2_1641',
//                               name: '7CWBPS05'
//                             },
//                             {
//                               id: 'P2_1642',
//                               name: '7CWBPS06'
//                             },
//                             {
//                               id: 'P2_1647',
//                               name: '7CWBPS12'
//                             },
//                             {
//                               id: 'P2_1648',
//                               name: '7CWBPS13'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1643',
//                           name: 'ASM_HARRIER',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1649',
//                               name: '7CWBPS18'
//                             },
//                             {
//                               id: 'P2_1650',
//                               name: '7CWBPS19'
//                             },
//                             {
//                               id: 'P2_1651',
//                               name: '7CWBPS20'
//                             },
//                             {
//                               id: 'P2_1652',
//                               name: '7CWBPS21'
//                             },
//                             {
//                               id: 'P2_1653',
//                               name: '7CWBPS22'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1654',
//                           name: 'SKW_ACB1000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2771',
//                               name: '7CWBPC23W1'
//                             },
//                             {
//                               id: 'P2_2772',
//                               name: '7CWBPC23W2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1658',
//                       name: 'RESIN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1659',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1660',
//                               name: '7CRCAS01'
//                             },
//                             {
//                               id: 'P2_1662',
//                               name: '7CRCAS03'
//                             },
//                             {
//                               id: 'P2_1663',
//                               name: '7CRCAS04'
//                             },
//                             {
//                               id: 'P2_1664',
//                               name: '7CRCAS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1667',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1668',
//                           name: 'IND',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1669',
//                               name: '7C3RDS04'
//                             },
//                             {
//                               id: 'P2_1670',
//                               name: '7C3RDS05'
//                             },
//                             {
//                               id: 'P2_1671',
//                               name: '7C3RDS06'
//                             },
//                             {
//                               id: 'P2_1672',
//                               name: '7C3RDS07'
//                             },
//                             {
//                               id: 'P2_1673',
//                               name: '7C3RDS08'
//                             },
//                             {
//                               id: 'P2_1676',
//                               name: '7C3RDS11'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1679',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1680',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1681',
//                               name: '7CAVIS01'
//                             },
//                             {
//                               id: 'P2_1682',
//                               name: '7CAVIS02'
//                             },
//                             {
//                               id: 'P2_1683',
//                               name: '7CAVIS03'
//                             },
//                             {
//                               id: 'P2_1684',
//                               name: '7CAVIS04'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2474',
//                       name: 'LASER_MARK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2476',
//                           name: 'LEDVANCE',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1502',
//                               name: '70MRKS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2532',
//                       name: 'DETAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2765',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2766',
//                               name: '7CITDS01'
//                             },
//                             {
//                               id: 'P2_2767',
//                               name: '7CITDS02'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_1691',
//                   name: 'R&D - QFN',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_2829',
//                       name: 'SUPP_AUTO_BACKSIDE_TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2830',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2831',
//                               name: '7KABTS01'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2270',
//                   name: 'MF1 - MLA&NMI',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1687',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1688',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1689',
//                               name: '7CSCASM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1701',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1702',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2639',
//                               name: '7CAVISM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2263',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2264',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2265',
//                               name: '7CTRFSF1'
//                             },
//                             {
//                               id: 'P2_2269',
//                               name: '7CTRFSU1'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2769',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2770',
//                               name: '7CTRFSM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2271',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2272',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2273',
//                               name: '7CWBPSM1'
//                             },
//                             {
//                               id: 'P2_2274',
//                               name: '7CWBPSM2'
//                             },
//                             {
//                               id: 'P2_2838',
//                               name: '7CWBPSM3'
//                             },
//                             {
//                               id: 'P2_2840',
//                               name: '7CWBPSF1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2275',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2276',
//                           name: 'MLA6-01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2277',
//                               name: '7CDAPCM1D1'
//                             },
//                             {
//                               id: 'P2_2278',
//                               name: '7CDAPCM1D2'
//                             },
//                             {
//                               id: 'P2_2279',
//                               name: '7CDAPCM1D3'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2832',
//                           name: 'MLA6-02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2834',
//                               name: '7CDAPCM2D1'
//                             },
//                             {
//                               id: 'P2_2835',
//                               name: '7CDAPCM2D2'
//                             },
//                             {
//                               id: 'P2_2836',
//                               name: '7CDAPCM2D3'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2833',
//                           name: 'ML4-01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2782',
//                               name: '7CDAPCF1D1'
//                             },
//                             {
//                               id: 'P2_2837',
//                               name: '7CDAPCF1D2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2283',
//                       name: 'RESIN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2284',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2285',
//                               name: '7CRCASF1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2467',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2468',
//                           name: 'INTO_TX30',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1602',
//                               name: '7CITTSM1'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2523',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2828',
//                               name: '7CITTSM2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2470',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1314',
//                           name: 'INTO_T20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2827',
//                               name: '7CITSSM2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2471',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1584',
//                               name: '7CITSSM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2536',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2762',
//                           name: 'IEF',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2763',
//                               name: '7CEVISM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2550',
//                       name: 'SUPP_SILICONE_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2551',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2552',
//                               name: '7CSCDSM1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2553',
//                       name: 'SUPP_RESIN_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2554',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2555',
//                               name: '7CRCDSF1'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2286',
//                   name: 'MF1 - POINTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_2287',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2288',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2289',
//                               name: '7CWBPSP1'
//                             },
//                             {
//                               id: 'P2_2290',
//                               name: '7CWBPSP2'
//                             },
//                             {
//                               id: 'P2_2688',
//                               name: '7CWBPSP3'
//                             },
//                             {
//                               id: 'P2_2807',
//                               name: '7CWBPSP4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2291',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1421',
//                           name: 'ASM_AD830+',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2640',
//                               name: '7CDAPSP2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2292',
//                           name: 'TSK_4200',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1569',
//                               name: '7CDAPCP5D1'
//                             },
//                             {
//                               id: 'P2_1570',
//                               name: '7CDAPCP5D2'
//                             },
//                             {
//                               id: 'P2_2293',
//                               name: '7CDAPSP1'
//                             },
//                             {
//                               id: 'P2_2697',
//                               name: '7CDAPSP3'
//                             },
//                             {
//                               id: 'P2_2698',
//                               name: '7CDAPSP4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2294',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2295',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2296',
//                               name: '7CTRFSP1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2462',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2464',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1690',
//                               name: '7CSCASP1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2463',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2469',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1523',
//                               name: '7CIMSSP1'
//                             },
//                             {
//                               id: 'P2_2702',
//                               name: '7CIMSSP2'
//                             },
//                             {
//                               id: 'P2_2703',
//                               name: '7CIMSSP3'
//                             },
//                             {
//                               id: 'P2_2796',
//                               name: '7CIMSSP4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2465',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2466',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1527',
//                               name: '7CIMTSP1'
//                             },
//                             {
//                               id: 'P2_2706',
//                               name: '7CIMTSP2'
//                             },
//                             {
//                               id: 'P2_2707',
//                               name: '7CIMTSP3'
//                             },
//                             {
//                               id: 'P2_2797',
//                               name: '7CIMTSP4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2534',
//                       name: '100%_VISUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2533',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2710',
//                               name: '7CEVISP1'
//                             },
//                             {
//                               id: 'P2_2814',
//                               name: '7CEVISP2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2605',
//                       name: 'RESIN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2606',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1665',
//                               name: '7CRCASP1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2692',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2693',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1685',
//                               name: '7CAVISP1'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2297',
//                   name: 'MF1 - SIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1695',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2493',
//                           name: 'IND',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1677',
//                               name: '7C3RDSS1'
//                             },
//                             {
//                               id: 'P2_1678',
//                               name: '7C3RDSS2'
//                             },
//                             {
//                               id: 'P2_2816',
//                               name: '7C3RDSS3'
//                             },
//                             {
//                               id: 'P2_2817',
//                               name: '7C3RDSS4'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2267',
//                       name: 'SUPP_RESIN_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2268',
//                           name: 'OVEN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2641',
//                               name: '7CRCDSS1'
//                             },
//                             {
//                               id: 'P2_2642',
//                               name: '7CRCDSS2'
//                             },
//                             {
//                               id: 'P2_2643',
//                               name: '7CRCDSS3'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2298',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2299',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2300',
//                               name: '7CTRFSS1'
//                             },
//                             {
//                               id: 'P2_2656',
//                               name: '7CTRFSS2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2535',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2709',
//                               name: '7CTRFSS3'
//                             },
//                             {
//                               id: 'P2_2800',
//                               name: '7CTRFSS4'
//                             },
//                             {
//                               id: 'P2_2801',
//                               name: '7CTRFSS5'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2301',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1693',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2704',
//                               name: '7CIMSSS1'
//                             },
//                             {
//                               id: 'P2_2705',
//                               name: '7CIMSSS2'
//                             },
//                             {
//                               id: 'P2_2794',
//                               name: '7CIMSSS3'
//                             },
//                             {
//                               id: 'P2_2795',
//                               name: '7CIMSSS4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2302',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2303',
//                               name: '7CITSSS1'
//                             },
//                             {
//                               id: 'P2_2599',
//                               name: '7CITSSS2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2304',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2305',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2306',
//                               name: '7CITTSS1'
//                             },
//                             {
//                               id: 'P2_2598',
//                               name: '7CITTSS2'
//                             },
//                             {
//                               id: 'P2_2701',
//                               name: '7CITTSS3'
//                             },
//                             {
//                               id: 'P2_2708',
//                               name: '7CITTSS4'
//                             },
//                             {
//                               id: 'P2_2792',
//                               name: '7CITTSS5'
//                             },
//                             {
//                               id: 'P2_2793',
//                               name: '7CITTSS6'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2307',
//                       name: 'RESIN_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2308',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2309',
//                               name: '7CRCASS1'
//                             },
//                             {
//                               id: 'P2_2644',
//                               name: '7CRCASS2'
//                             },
//                             {
//                               id: 'P2_2798',
//                               name: '7CRCASS3'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2310',
//                       name: 'SILICONE_CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2311',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2312',
//                               name: '7CSCASS1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2313',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2314',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2315',
//                               name: '7CWBPSS1'
//                             },
//                             {
//                               id: 'P2_2626',
//                               name: '7CWBPSS3'
//                             },
//                             {
//                               id: 'P2_2627',
//                               name: '7CWBPSS2'
//                             },
//                             {
//                               id: 'P2_2699',
//                               name: '7CWBPSS4'
//                             },
//                             {
//                               id: 'P2_2803',
//                               name: '7CWBPSS5'
//                             },
//                             {
//                               id: 'P2_2804',
//                               name: '7CWBPSS6'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2316',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2414',
//                           name: 'ASM_AD830+',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2635',
//                               name: '7CDAPSS1'
//                             },
//                             {
//                               id: 'P2_2636',
//                               name: '7CDAPSS2'
//                             },
//                             {
//                               id: 'P2_2637',
//                               name: '7CDAPSS3'
//                             },
//                             {
//                               id: 'P2_2695',
//                               name: '7CDAPSS4'
//                             },
//                             {
//                               id: 'P2_2696',
//                               name: '7CDAPSS5'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2815',
//                           name: 'ASM_AD830R',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2805',
//                               name: '7CDAPSS6'
//                             },
//                             {
//                               id: 'P2_2806',
//                               name: '7CDAPSS7'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2320',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2321',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2064',
//                               name: '7CAVISS3'
//                             },
//                             {
//                               id: 'P2_2638',
//                               name: '7CAVISS2'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2323',
//                       name: 'LASER_MARK_TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2324',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2325',
//                               name: '7CMRKSS1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2645',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2646',
//                           name: 'IEF',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2647',
//                               name: '7CEVISS1'
//                             },
//                             {
//                               id: 'P2_2648',
//                               name: '7CEVISS2'
//                             },
//                             {
//                               id: 'P2_2711',
//                               name: '7CEVISS3'
//                             },
//                             {
//                               id: 'P2_2812',
//                               name: '7CEVISS4'
//                             },
//                             {
//                               id: 'P2_2813',
//                               name: '7CEVISS5'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2392',
//                   name: 'MF2 - PRE-MOLD',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_2343',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2362',
//                           name: 'TEPLA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2363',
//                               name: '7JPLMS02'
//                             },
//                             {
//                               id: 'P2_2580',
//                               name: '7JPLMS06'
//                             },
//                             {
//                               id: 'P2_2581',
//                               name: '7JPLMS07'
//                             },
//                             {
//                               id: 'P2_2663',
//                               name: '7JPLMS08'
//                             },
//                             {
//                               id: 'P2_2679',
//                               name: '7JPLMS09'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2364',
//                           name: 'E&R',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2365',
//                               name: '7JPLMS05'
//                             },
//                             {
//                               id: 'P2_2578',
//                               name: '7JPLMS03'
//                             },
//                             {
//                               id: 'P2_2579',
//                               name: '7JPLMS04'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2351',
//                       name: 'WIN_CUT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2352',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2353',
//                               name: '7JFCTS01'
//                             },
//                             {
//                               id: 'P2_2671',
//                               name: '7JFCTS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2357',
//                       name: 'LASER_MARK_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2358',
//                           name: 'PRISM_LM-118',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2359',
//                               name: '7JMRKS01'
//                             },
//                             {
//                               id: 'P2_2360',
//                               name: '7JMRKS02'
//                             },
//                             {
//                               id: 'P2_2568',
//                               name: '7KMRKS01'
//                             },
//                             {
//                               id: 'P2_2609',
//                               name: '7JMRKS03'
//                             },
//                             {
//                               id: 'P2_2617',
//                               name: '7JMRKS04'
//                             },
//                             {
//                               id: 'P2_2674',
//                               name: '7JMRKS05'
//                             },
//                             {
//                               id: 'P2_2845',
//                               name: '7JMRKS06'
//                             },
//                             {
//                               id: 'P2_2857',
//                               name: '7JMRKS07'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2385',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2386',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2387',
//                               name: '7JAVIS01'
//                             },
//                             {
//                               id: 'P2_2388',
//                               name: '7JAVIS02'
//                             },
//                             {
//                               id: 'P2_2389',
//                               name: '7JAVIS04'
//                             },
//                             {
//                               id: 'P2_2390',
//                               name: '7JAVIS05'
//                             },
//                             {
//                               id: 'P2_2607',
//                               name: '7JAVIS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2418',
//                       name: 'TIO2&CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2419',
//                           name: 'PROTEC_VERMES',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2337',
//                               name: '7JSCAS06'
//                             },
//                             {
//                               id: 'P2_2339',
//                               name: '7JSCAS15'
//                             },
//                             {
//                               id: 'P2_2340',
//                               name: '7JSCAS16'
//                             },
//                             {
//                               id: 'P2_2420',
//                               name: '7JSCAS07'
//                             },
//                             {
//                               id: 'P2_2421',
//                               name: '7JSCAS08'
//                             },
//                             {
//                               id: 'P2_2436',
//                               name: '7JSCAS03'
//                             },
//                             {
//                               id: 'P2_2456',
//                               name: '7JSCAS09'
//                             },
//                             {
//                               id: 'P2_2457',
//                               name: '7JSCAS01'
//                             },
//                             {
//                               id: 'P2_2582',
//                               name: '7JSCAS18'
//                             },
//                             {
//                               id: 'P2_2584',
//                               name: '7JSCAS02'
//                             },
//                             {
//                               id: 'P2_2676',
//                               name: '7JSCAS22'
//                             },
//                             {
//                               id: 'P2_2851',
//                               name: '7JSCAS28'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2444',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2344',
//                           name: 'SKW_ACB3000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2587',
//                               name: '7JWBPS13'
//                             },
//                             {
//                               id: 'P2_2588',
//                               name: '7JWBPS14'
//                             },
//                             {
//                               id: 'P2_2670',
//                               name: '7JWBPS16'
//                             },
//                             {
//                               id: 'P2_2684',
//                               name: '7JWBPS17'
//                             },
//                             {
//                               id: 'P2_2685',
//                               name: '7JWBPS18'
//                             },
//                             {
//                               id: 'P2_2786',
//                               name: '7JWBPS20'
//                             },
//                             {
//                               id: 'P2_2787',
//                               name: '7JWBPS21'
//                             },
//                             {
//                               id: 'P2_2850',
//                               name: '7JWBPS22'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2445',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2398',
//                               name: '7JWBPS05'
//                             },
//                             {
//                               id: 'P2_2399',
//                               name: '7JWBPS06'
//                             },
//                             {
//                               id: 'P2_2401',
//                               name: '7JWBPS12'
//                             },
//                             {
//                               id: 'P2_2402',
//                               name: '7JWBPS02'
//                             },
//                             {
//                               id: 'P2_2424',
//                               name: '7JWBPS03'
//                             },
//                             {
//                               id: 'P2_2425',
//                               name: '7JWBPS04'
//                             },
//                             {
//                               id: 'P2_2446',
//                               name: '7JWBPS09'
//                             },
//                             {
//                               id: 'P2_2453',
//                               name: '7JWBPS01'
//                             },
//                             {
//                               id: 'P2_2589',
//                               name: '7JWBPS15'
//                             },
//                             {
//                               id: 'P2_2854',
//                               name: '7JWBPS25'
//                             },
//                             {
//                               id: 'P2_2855',
//                               name: '7JWBPS24'
//                             },
//                             {
//                               id: 'P2_2856',
//                               name: '7JWBPS23'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2454',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2408',
//                           name: 'PROTEC_LINEAR',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1124',
//                               name: '7JSCAS19'
//                             },
//                             {
//                               id: 'P2_1209',
//                               name: '6VSCAS13'
//                             },
//                             {
//                               id: 'P2_2334',
//                               name: '7JSCAS05'
//                             },
//                             {
//                               id: 'P2_2341',
//                               name: '7JSCAS17'
//                             },
//                             {
//                               id: 'P2_2342',
//                               name: '7JSCAS13'
//                             },
//                             {
//                               id: 'P2_2431',
//                               name: '7JSCAS14'
//                             },
//                             {
//                               id: 'P2_2435',
//                               name: '7JSCAS12'
//                             },
//                             {
//                               id: 'P2_2437',
//                               name: '7JSCAS04'
//                             },
//                             {
//                               id: 'P2_2438',
//                               name: '7JSCAS10'
//                             },
//                             {
//                               id: 'P2_2583',
//                               name: '7JSCAS20'
//                             },
//                             {
//                               id: 'P2_2686',
//                               name: '7JSCAS26'
//                             },
//                             {
//                               id: 'P2_2776',
//                               name: '7JSCAS27'
//                             },
//                             {
//                               id: 'P2_2785',
//                               name: '6VSCAS40'
//                             },
//                             {
//                               id: 'P2_2852',
//                               name: '7JSCAS29'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2458',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2459',
//                           name: 'ASM_TWIN833',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1125',
//                               name: '7JDAPS16'
//                             },
//                             {
//                               id: 'P2_2330',
//                               name: '7JDAPS06'
//                             },
//                             {
//                               id: 'P2_2331',
//                               name: '7JDAPS08'
//                             },
//                             {
//                               id: 'P2_2391',
//                               name: '7JDAPS11'
//                             },
//                             {
//                               id: 'P2_2395',
//                               name: '7JDAPS12'
//                             },
//                             {
//                               id: 'P2_2411',
//                               name: '7JDAPS03'
//                             },
//                             {
//                               id: 'P2_2415',
//                               name: '7JDAPS04'
//                             },
//                             {
//                               id: 'P2_2416',
//                               name: '7JDAPS10'
//                             },
//                             {
//                               id: 'P2_2417',
//                               name: '7JDAPS07'
//                             },
//                             {
//                               id: 'P2_2441',
//                               name: '7JDAPS13'
//                             },
//                             {
//                               id: 'P2_2460',
//                               name: '7JDAPS01'
//                             },
//                             {
//                               id: 'P2_2461',
//                               name: '7JDAPS02'
//                             },
//                             {
//                               id: 'P2_2586',
//                               name: '7JDAPS15'
//                             },
//                             {
//                               id: 'P2_2672',
//                               name: '7JDAPS18'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2483',
//                           name: 'ASM_AD838L',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2329',
//                               name: '7JDAPS05'
//                             },
//                             {
//                               id: 'P2_2777',
//                               name: '7JDAPS20'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2540',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2541',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2348',
//                               name: '7JEVIS01'
//                             },
//                             {
//                               id: 'P2_2349',
//                               name: '7JEVIS03'
//                             },
//                             {
//                               id: 'P2_2350',
//                               name: '7JEVIS04'
//                             },
//                             {
//                               id: 'P2_2593',
//                               name: '7JEVIS05'
//                             },
//                             {
//                               id: 'P2_2618',
//                               name: '7JEVIS07'
//                             },
//                             {
//                               id: 'P2_2780',
//                               name: '7JEVIS09'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2594',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2620',
//                               name: '7JEVIS06'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2613',
//                           name: 'IEF',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2846',
//                               name: '7JEVIS10'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2558',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2559',
//                           name: 'FOSYS',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2368',
//                               name: '7JTRFS01'
//                             },
//                             {
//                               id: 'P2_2560',
//                               name: '7JTRFS03'
//                             },
//                             {
//                               id: 'P2_2664',
//                               name: '7JTRFS04'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2788',
//                           name: 'FOSYS_TEMP',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2657',
//                               name: '7JTRFS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2600',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2601',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2378',
//                               name: '7J3RDS01'
//                             },
//                             {
//                               id: 'P2_2379',
//                               name: '7J3RDS02'
//                             },
//                             {
//                               id: 'P2_2380',
//                               name: '7J3RDS04'
//                             },
//                             {
//                               id: 'P2_2381',
//                               name: '7J3RDS05'
//                             },
//                             {
//                               id: 'P2_2382',
//                               name: '7J3RDS06'
//                             },
//                             {
//                               id: 'P2_2383',
//                               name: '7J3RDS07'
//                             },
//                             {
//                               id: 'P2_2384',
//                               name: '7J3RDS08'
//                             },
//                             {
//                               id: 'P2_2658',
//                               name: '7J3RDS09'
//                             },
//                             {
//                               id: 'P2_2680',
//                               name: '7J3RDS10'
//                             },
//                             {
//                               id: 'P2_2681',
//                               name: '7J3RDS11'
//                             },
//                             {
//                               id: 'P2_2682',
//                               name: '7J3RDS12'
//                             },
//                             {
//                               id: 'P2_2779',
//                               name: '7J3RDS13'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2789',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2448',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1136',
//                               name: '6VTWSS09'
//                             },
//                             {
//                               id: 'P2_2372',
//                               name: '7JTWSS04'
//                             },
//                             {
//                               id: 'P2_2434',
//                               name: '7JTWSS02'
//                             },
//                             {
//                               id: 'P2_2449',
//                               name: '7JTWSS01'
//                             },
//                             {
//                               id: 'P2_2556',
//                               name: '7JTWSS07'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2480',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2668',
//                               name: '7JIMSS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2502',
//                           name: 'INTOTEST',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2596',
//                               name: '7JITSS01'
//                             },
//                             {
//                               id: 'P2_2669',
//                               name: '7JITSS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2790',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2451',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2375',
//                               name: '7JTWTS07'
//                             },
//                             {
//                               id: 'P2_2428',
//                               name: '7JTWTS05'
//                             },
//                             {
//                               id: 'P2_2452',
//                               name: '7JTWTS01'
//                             },
//                             {
//                               id: 'P2_2557',
//                               name: '7JTWTS09'
//                             },
//                             {
//                               id: 'P2_2608',
//                               name: '7JTWTS06'
//                             },
//                             {
//                               id: 'P2_2781',
//                               name: '7JTWTS11'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2495',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2667',
//                               name: '7JIMTS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2595',
//                           name: 'INTOTEST',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2405',
//                               name: '7JITTS02'
//                             },
//                             {
//                               id: 'P2_2597',
//                               name: '7JITTS06'
//                             },
//                             {
//                               id: 'P2_2665',
//                               name: '7JITTS05'
//                             },
//                             {
//                               id: 'P2_2666',
//                               name: '7JITTS07'
//                             },
//                             {
//                               id: 'P2_2818',
//                               name: '7JITTS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2802',
//                       name: 'PLASMA_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2562',
//                           name: 'PANASONIC',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2563',
//                               name: '7JPLMS01'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2412',
//                   name: 'UNUSED_EQUIPMENT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1046',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1397',
//                           name: 'SKW_ACB1000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1398',
//                               name: '7BWBPS06'
//                             },
//                             {
//                               id: 'P2_1399',
//                               name: '7BWBPS07'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1492',
//                           name: 'ASM_EAGLE60',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1493',
//                               name: '7VWBPS04'
//                             },
//                             {
//                               id: 'P2_1494',
//                               name: '7VWBPS05'
//                             },
//                             {
//                               id: 'P2_1495',
//                               name: '7VWBPS06'
//                             },
//                             {
//                               id: 'P2_1496',
//                               name: '7VWBPS07'
//                             },
//                             {
//                               id: 'P2_1497',
//                               name: '7VWBPS08'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1896',
//                           name: 'WB_M22(UTC5100 T99)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1897',
//                               name: '7EMDLC22W1'
//                             },
//                             {
//                               id: 'P2_1898',
//                               name: '7EMDLC22W2'
//                             },
//                             {
//                               id: 'P2_1899',
//                               name: '7EMDLC22W3'
//                             },
//                             {
//                               id: 'P2_1900',
//                               name: '7EMDLC22W4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2367',
//                           name: 'ASM_HARRIER',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1645',
//                               name: '7CWBPS08'
//                             },
//                             {
//                               id: 'P2_1646',
//                               name: '7CWBPS09'
//                             },
//                             {
//                               id: 'P2_1830',
//                               name: '7EMDLC14W1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1166',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1282',
//                           name: 'DB_M06(TSK4200)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1283',
//                               name: '7BDAPC06D1'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1562',
//                           name: 'DB_M12(MINI TSK4200)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1563',
//                               name: '7CDAPC12D1'
//                             },
//                             {
//                               id: 'P2_1564',
//                               name: '7CDAPC12D2'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1804',
//                           name: 'DB_M12(TSK3310)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1805',
//                               name: '7EMDLC12D1'
//                             },
//                             {
//                               id: 'P2_1806',
//                               name: '7EMDLC12D2'
//                             },
//                             {
//                               id: 'P2_1807',
//                               name: '7EMDLC12D3'
//                             },
//                             {
//                               id: 'P2_1808',
//                               name: '7EMDLC12D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1824',
//                           name: 'DB_M14(TSK4200)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1825',
//                               name: '7EMDLC14D1'
//                             },
//                             {
//                               id: 'P2_1826',
//                               name: '7EMDLC14D2'
//                             },
//                             {
//                               id: 'P2_1827',
//                               name: '7EMDLC14D3'
//                             },
//                             {
//                               id: 'P2_1828',
//                               name: '7EMDLC14D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1858',
//                           name: 'DB_M18(TSK3310)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1859',
//                               name: '7EMDLC18D1'
//                             },
//                             {
//                               id: 'P2_1860',
//                               name: '7EMDLC18D2'
//                             },
//                             {
//                               id: 'P2_1861',
//                               name: '7EMDLC18D3'
//                             },
//                             {
//                               id: 'P2_1862',
//                               name: '7EMDLC18D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1891',
//                           name: 'DB_M22(TSK4200 T99)',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1892',
//                               name: '7EMDLC22D1'
//                             },
//                             {
//                               id: 'P2_1893',
//                               name: '7EMDLC22D2'
//                             },
//                             {
//                               id: 'P2_1894',
//                               name: '7EMDLC22D3'
//                             },
//                             {
//                               id: 'P2_1895',
//                               name: '7EMDLC22D4'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2819',
//                           name: 'ASM_AD830R',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1012',
//                               name: '7BDAPC01D1'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1420',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2488',
//                           name: 'ANDA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1251',
//                               name: '7BAVIS02'
//                             },
//                             {
//                               id: 'P2_1252',
//                               name: '7BAVIS03'
//                             },
//                             {
//                               id: 'P2_1256',
//                               name: '7BAVIS07'
//                             },
//                             {
//                               id: 'P2_2490',
//                               name: '7VAVIS01'
//                             },
//                             {
//                               id: 'P2_2752',
//                               name: '6VAVIS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2843',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2061',
//                               name: '7EAVIS19'
//                             },
//                             {
//                               id: 'P2_2062',
//                               name: '7EAVIS21'
//                             },
//                             {
//                               id: 'P2_2063',
//                               name: '7EAVIS22'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1692',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1316',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1318',
//                               name: '7BITSS03'
//                             },
//                             {
//                               id: 'P2_1580',
//                               name: '7CITSS01'
//                             },
//                             {
//                               id: 'P2_1581',
//                               name: '7CITSS02'
//                             },
//                             {
//                               id: 'P2_1582',
//                               name: '7CITSS03'
//                             },
//                             {
//                               id: 'P2_1583',
//                               name: '7CITSS04'
//                             },
//                             {
//                               id: 'P2_1634',
//                               name: '7CVSSS03'
//                             },
//                             {
//                               id: 'P2_2026',
//                               name: '7EITSS05'
//                             },
//                             {
//                               id: 'P2_2027',
//                               name: '7EITSS06'
//                             },
//                             {
//                               id: 'P2_2028',
//                               name: '7EITSS07'
//                             },
//                             {
//                               id: 'P2_2029',
//                               name: '7EITSS08'
//                             },
//                             {
//                               id: 'P2_2033',
//                               name: '7EITSS11'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2427',
//                           name: 'INTO_T20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1317',
//                               name: '7BITSS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2689',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1464',
//                               name: '7VIMSS03'
//                             },
//                             {
//                               id: 'P2_1990',
//                               name: '7EIMSS06'
//                             },
//                             {
//                               id: 'P2_1996',
//                               name: '7EIMSS13'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1705',
//                       name: 'TRIM_FORM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1015',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1359',
//                               name: '7BTRFS06'
//                             },
//                             {
//                               id: 'P2_1361',
//                               name: '7BTRFS08'
//                             },
//                             {
//                               id: 'P2_1610',
//                               name: '7CTRFS06'
//                             },
//                             {
//                               id: 'P2_1611',
//                               name: '7CTRFS07'
//                             },
//                             {
//                               id: 'P2_1614',
//                               name: '7CTRFS10'
//                             },
//                             {
//                               id: 'P2_1615',
//                               name: '7CTRFS11'
//                             },
//                             {
//                               id: 'P2_1618',
//                               name: '7CTRFS14'
//                             },
//                             {
//                               id: 'P2_2206',
//                               name: '7ETRFS05'
//                             },
//                             {
//                               id: 'P2_2215',
//                               name: '7ETRFS13'
//                             },
//                             {
//                               id: 'P2_2216',
//                               name: '7ETRFS14'
//                             },
//                             {
//                               id: 'P2_2224',
//                               name: '7ETRFS22'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1708',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2092',
//                           name: 'INTO_T20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2093',
//                               name: '7EITTS06'
//                             },
//                             {
//                               id: 'P2_2133',
//                               name: '7EITTS10'
//                             },
//                             {
//                               id: 'P2_2134',
//                               name: '7EITTS11'
//                             },
//                             {
//                               id: 'P2_2135',
//                               name: '7EITTS12'
//                             },
//                             {
//                               id: 'P2_2136',
//                               name: '7EITTS13'
//                             },
//                             {
//                               id: 'P2_2137',
//                               name: '7EITTS14'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2168',
//                           name: 'INTO_TX30',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2259',
//                               name: '7EVSTS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2370',
//                           name: 'INTO_TWIN',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1586',
//                               name: '7CITTS01'
//                             },
//                             {
//                               id: 'P2_1591',
//                               name: '7CITTS04'
//                             },
//                             {
//                               id: 'P2_1592',
//                               name: '7CITTS05'
//                             },
//                             {
//                               id: 'P2_1593',
//                               name: '7CITTS07'
//                             },
//                             {
//                               id: 'P2_2140',
//                               name: '7EITTS16'
//                             },
//                             {
//                               id: 'P2_2141',
//                               name: '7EITTS17'
//                             },
//                             {
//                               id: 'P2_2142',
//                               name: '7EITTS18'
//                             },
//                             {
//                               id: 'P2_2143',
//                               name: '7EITTS19'
//                             },
//                             {
//                               id: 'P2_2144',
//                               name: '7EITTS20'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2744',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1321',
//                               name: '7BITTS02'
//                             },
//                             {
//                               id: 'P2_1323',
//                               name: '7BITTS04'
//                             },
//                             {
//                               id: 'P2_2356',
//                               name: '7JITTS03'
//                             },
//                             {
//                               id: 'P2_2406',
//                               name: '7JITTS04'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2756',
//                           name: 'INLINER',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2014',
//                               name: '7EINLS02'
//                             },
//                             {
//                               id: 'P2_2016',
//                               name: '7EINLS04'
//                             },
//                             {
//                               id: 'P2_2018',
//                               name: '7EINLS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2332',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1035',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1347',
//                               name: '7BSCAS06'
//                             },
//                             {
//                               id: 'P2_1661',
//                               name: '7CRCAS02'
//                             },
//                             {
//                               id: 'P2_1666',
//                               name: '7CRCAS07'
//                             },
//                             {
//                               id: 'P2_2175',
//                               name: '7ERCAS03'
//                             },
//                             {
//                               id: 'P2_2176',
//                               name: '7ERCAS04'
//                             },
//                             {
//                               id: 'P2_2181',
//                               name: '7ERCAS09'
//                             },
//                             {
//                               id: 'P2_2184',
//                               name: '7ERCAS12'
//                             },
//                             {
//                               id: 'P2_2192',
//                               name: '7ESCAS04'
//                             },
//                             {
//                               id: 'P2_2193',
//                               name: '7ESCAS05'
//                             },
//                             {
//                               id: 'P2_2194',
//                               name: '7ESCAS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2346',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2522',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2565',
//                               name: '7JEVIS02'
//                             },
//                             {
//                               id: 'P2_2824',
//                               name: '7VEVIS10'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2825',
//                           name: 'SAVI STATION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2826',
//                               name: '7VEVIS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2376',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2377',
//                           name: 'IND',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1963',
//                               name: '7E3RDS11'
//                             },
//                             {
//                               id: 'P2_1967',
//                               name: '7E3RDS15'
//                             },
//                             {
//                               id: 'P2_1974',
//                               name: '7E3RDS22'
//                             },
//                             {
//                               id: 'P2_1976',
//                               name: '7E3RDS24'
//                             },
//                             {
//                               id: 'P2_1982',
//                               name: '7E3RDS30'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2430',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2712',
//                               name: '7V3RDS01'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2479',
//                       name: 'SUPP_100%_VISUAL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2499',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2084',
//                               name: '7EEVIS45'
//                             },
//                             {
//                               id: 'P2_2086',
//                               name: '7EEVIS47'
//                             },
//                             {
//                               id: 'P2_2088',
//                               name: '7EEVIS49'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2494',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1019',
//                           name: 'ASM_AD838L',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1477',
//                               name: '7VLAYS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1123',
//                           name: 'DATACON',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1336',
//                               name: '7BLAYS08'
//                             },
//                             {
//                               id: 'P2_1339',
//                               name: '7BLAYS11'
//                             },
//                             {
//                               id: 'P2_1437',
//                               name: '7VLAYS06'
//                             },
//                             {
//                               id: 'P2_2576',
//                               name: '7BLAYS12'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2443',
//                   name: 'MF2 - CERAMOLD',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1029',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1030',
//                           name: 'TOWA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1031',
//                               name: '6VMODS05'
//                             },
//                             {
//                               id: 'P2_1032',
//                               name: '6VMODS06'
//                             },
//                             {
//                               id: 'P2_1033',
//                               name: '6VMODS07'
//                             },
//                             {
//                               id: 'P2_1440',
//                               name: '7VMODS04'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1439',
//                           name: 'FFT1030W',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1480',
//                               name: '7VMODS01'
//                             },
//                             {
//                               id: 'P2_1481',
//                               name: '7VMODS02'
//                             },
//                             {
//                               id: 'P2_1482',
//                               name: '7VMODS03'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1416',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1417',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1418',
//                               name: '7V3RDS04'
//                             },
//                             {
//                               id: 'P2_1419',
//                               name: '7V3RDS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1423',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1424',
//                           name: 'ASM_AD838L',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1020',
//                               name: '6VDAPS30'
//                             },
//                             {
//                               id: 'P2_1021',
//                               name: '6VDAPS31'
//                             },
//                             {
//                               id: 'P2_1022',
//                               name: '6VDAPS32'
//                             },
//                             {
//                               id: 'P2_1023',
//                               name: '6VDAPS36'
//                             },
//                             {
//                               id: 'P2_1024',
//                               name: '6VDAPS37'
//                             },
//                             {
//                               id: 'P2_1025',
//                               name: '6VDAPS38'
//                             },
//                             {
//                               id: 'P2_1026',
//                               name: '6VDAPS39'
//                             },
//                             {
//                               id: 'P2_1027',
//                               name: '6VDAPS40'
//                             },
//                             {
//                               id: 'P2_1028',
//                               name: '6VDAPS41'
//                             },
//                             {
//                               id: 'P2_1425',
//                               name: '7VDAPS12'
//                             },
//                             {
//                               id: 'P2_1426',
//                               name: '7VDAPS13'
//                             },
//                             {
//                               id: 'P2_1427',
//                               name: '7VDAPS14'
//                             },
//                             {
//                               id: 'P2_1452',
//                               name: '7VDAPS01'
//                             },
//                             {
//                               id: 'P2_1453',
//                               name: '7VDAPS02'
//                             },
//                             {
//                               id: 'P2_1454',
//                               name: '7VDAPS03'
//                             },
//                             {
//                               id: 'P2_1455',
//                               name: '7VDAPS04'
//                             },
//                             {
//                               id: 'P2_1456',
//                               name: '7VDAPS05'
//                             },
//                             {
//                               id: 'P2_1457',
//                               name: '7VDAPS06'
//                             },
//                             {
//                               id: 'P2_1458',
//                               name: '7VDAPS07'
//                             },
//                             {
//                               id: 'P2_1459',
//                               name: '7VDAPS08'
//                             },
//                             {
//                               id: 'P2_1460',
//                               name: '7VDAPS09'
//                             },
//                             {
//                               id: 'P2_1461',
//                               name: '7VDAPS10'
//                             },
//                             {
//                               id: 'P2_1462',
//                               name: '7VDAPS11'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1428',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1429',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1036',
//                               name: '6VIMSS01'
//                             },
//                             {
//                               id: 'P2_1037',
//                               name: '6VIMSS02'
//                             },
//                             {
//                               id: 'P2_1038',
//                               name: '6VIMSS03'
//                             },
//                             {
//                               id: 'P2_1039',
//                               name: '6VIMSS04'
//                             },
//                             {
//                               id: 'P2_1430',
//                               name: '7VIMSS01'
//                             },
//                             {
//                               id: 'P2_1463',
//                               name: '7VIMSS02'
//                             },
//                             {
//                               id: 'P2_1465',
//                               name: '7VIMSS04'
//                             },
//                             {
//                               id: 'P2_1466',
//                               name: '7VIMSS05'
//                             },
//                             {
//                               id: 'P2_1467',
//                               name: '7VIMSS06'
//                             },
//                             {
//                               id: 'P2_1468',
//                               name: '7VIMSS07'
//                             },
//                             {
//                               id: 'P2_2504',
//                               name: '7VIMSS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1431',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1432',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1043',
//                               name: '6VIMTS05'
//                             },
//                             {
//                               id: 'P2_1044',
//                               name: '6VIMTS06'
//                             },
//                             {
//                               id: 'P2_1045',
//                               name: '6VIMTS07'
//                             },
//                             {
//                               id: 'P2_1112',
//                               name: '6VIMTS02'
//                             },
//                             {
//                               id: 'P2_1433',
//                               name: '7VIMTS01'
//                             },
//                             {
//                               id: 'P2_1469',
//                               name: '7VIMTS02'
//                             },
//                             {
//                               id: 'P2_1470',
//                               name: '7VIMTS03'
//                             },
//                             {
//                               id: 'P2_1473',
//                               name: '7VIMTS06'
//                             },
//                             {
//                               id: 'P2_1474',
//                               name: '7VIMTS07'
//                             },
//                             {
//                               id: 'P2_2505',
//                               name: '7VIMTS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1434',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1435',
//                           name: 'DATACON',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1436',
//                               name: '7VLAYS04'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1475',
//                           name: 'ASM_AD838L',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1476',
//                               name: '7VLAYS01'
//                             },
//                             {
//                               id: 'P2_1478',
//                               name: '7VLAYS03'
//                             },
//                             {
//                               id: 'P2_1479',
//                               name: '7VLAYS05'
//                             },
//                             {
//                               id: 'P2_2496',
//                               name: '7VLAYS07'
//                             },
//                             {
//                               id: 'P2_2497',
//                               name: '7VLAYS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1441',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1442',
//                           name: 'DISCO',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1443',
//                               name: '7VSAWS07'
//                             },
//                             {
//                               id: 'P2_1483',
//                               name: '7VSAWS01'
//                             },
//                             {
//                               id: 'P2_1484',
//                               name: '7VSAWS02'
//                             },
//                             {
//                               id: 'P2_1485',
//                               name: '7VSAWS03'
//                             },
//                             {
//                               id: 'P2_1486',
//                               name: '7VSAWS04'
//                             },
//                             {
//                               id: 'P2_1487',
//                               name: '7VSAWS05'
//                             },
//                             {
//                               id: 'P2_1488',
//                               name: '7VSAWS06'
//                             },
//                             {
//                               id: 'P2_2610',
//                               name: '7VSAWS08'
//                             },
//                             {
//                               id: 'P2_2715',
//                               name: '6VSAWS27'
//                             },
//                             {
//                               id: 'P2_2716',
//                               name: '6VSAWS28'
//                             },
//                             {
//                               id: 'P2_2717',
//                               name: '6VSAWS29'
//                             },
//                             {
//                               id: 'P2_2718',
//                               name: '6VSAWS30'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1706',
//                           name: 'TSK_AD2000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2720',
//                               name: '6VSAWS23'
//                             },
//                             {
//                               id: 'P2_2721',
//                               name: '6VSAWS24'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1444',
//                       name: 'TIO2&CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2774',
//                           name: 'PROTEC_VERMES',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1016',
//                               name: '6VSCAS28'
//                             },
//                             {
//                               id: 'P2_1017',
//                               name: '6VSCAS32'
//                             },
//                             {
//                               id: 'P2_1219',
//                               name: '6VSCAS23'
//                             },
//                             {
//                               id: 'P2_1223',
//                               name: '6VSCAS27'
//                             },
//                             {
//                               id: 'P2_1446',
//                               name: '7VSCAS01'
//                             },
//                             {
//                               id: 'P2_1447',
//                               name: '7VSCAS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1448',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1449',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1048',
//                               name: '6VWBPS36'
//                             },
//                             {
//                               id: 'P2_1049',
//                               name: '6VWBPS37'
//                             },
//                             {
//                               id: 'P2_1050',
//                               name: '6VWBPS44'
//                             },
//                             {
//                               id: 'P2_1051',
//                               name: '6VWBPS45'
//                             },
//                             {
//                               id: 'P2_1052',
//                               name: '6VWBPS46'
//                             },
//                             {
//                               id: 'P2_1053',
//                               name: '6VWBPS49'
//                             },
//                             {
//                               id: 'P2_1450',
//                               name: '7VWBPS09'
//                             },
//                             {
//                               id: 'P2_1489',
//                               name: '7VWBPS01'
//                             },
//                             {
//                               id: 'P2_1490',
//                               name: '7VWBPS02'
//                             },
//                             {
//                               id: 'P2_1491',
//                               name: '7VWBPS03'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2393',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2603',
//                           name: 'PROTEC',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2604',
//                               name: '7VSCTS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2722',
//                           name: 'JJC_OMNI SPRAY A340+',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2723',
//                               name: '6VSCTS01'
//                             },
//                             {
//                               id: 'P2_2724',
//                               name: '6VSCTS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2482',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1506',
//                           name: 'PRISM',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2662',
//                               name: '7VEVIS11'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2694',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2614',
//                               name: '6VEVIS09'
//                             },
//                             {
//                               id: 'P2_2615',
//                               name: '6VEVIS10'
//                             },
//                             {
//                               id: 'P2_2691',
//                               name: '7VEVIS17'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2485',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2489',
//                           name: 'ANDA-TECH',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1422',
//                               name: '7VAVIS02'
//                             },
//                             {
//                               id: 'P2_2757',
//                               name: '6VAVIS03'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2742',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2751',
//                               name: '6VAVIS01'
//                             },
//                             {
//                               id: 'P2_2753',
//                               name: '6VAVIS04'
//                             },
//                             {
//                               id: 'P2_2754',
//                               name: '6VAVIS05'
//                             },
//                             {
//                               id: 'P2_2755',
//                               name: '6VAVIS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2544',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2564',
//                           name: 'E&R',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2611',
//                               name: '7VPLMS03'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2690',
//                           name: 'TEPLA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2623',
//                               name: '7VPLMS01'
//                             },
//                             {
//                               id: 'P2_2624',
//                               name: '7VPLMS02'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P2_2531',
//                   name: 'MF2 - QFN',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P2_1055',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1056',
//                           name: 'SKW_ACB3000',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1057',
//                               name: '6VWBPS01'
//                             },
//                             {
//                               id: 'P2_1058',
//                               name: '6VWBPS02'
//                             },
//                             {
//                               id: 'P2_1059',
//                               name: '6VWBPS03'
//                             },
//                             {
//                               id: 'P2_1060',
//                               name: '6VWBPS04'
//                             },
//                             {
//                               id: 'P2_1061',
//                               name: '6VWBPS05'
//                             },
//                             {
//                               id: 'P2_1062',
//                               name: '6VWBPS06'
//                             },
//                             {
//                               id: 'P2_1063',
//                               name: '6VWBPS07'
//                             },
//                             {
//                               id: 'P2_1064',
//                               name: '6VWBPS08'
//                             },
//                             {
//                               id: 'P2_1065',
//                               name: '6VWBPS09'
//                             },
//                             {
//                               id: 'P2_1066',
//                               name: '6VWBPS10'
//                             },
//                             {
//                               id: 'P2_1081',
//                               name: '6VWBPS26'
//                             },
//                             {
//                               id: 'P2_1082',
//                               name: '6VWBPS27'
//                             },
//                             {
//                               id: 'P2_1094',
//                               name: '6VWBPS39'
//                             },
//                             {
//                               id: 'P2_1095',
//                               name: '6VWBPS40'
//                             },
//                             {
//                               id: 'P2_1096',
//                               name: '6VWBPS41'
//                             },
//                             {
//                               id: 'P2_1097',
//                               name: '6VWBPS42'
//                             },
//                             {
//                               id: 'P2_1098',
//                               name: '6VWBPS43'
//                             },
//                             {
//                               id: 'P2_1099',
//                               name: '6VWBPS47'
//                             },
//                             {
//                               id: 'P2_1100',
//                               name: '6VWBPS48'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1067',
//                           name: 'SKW_UTC5100',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1068',
//                               name: '6VWBPS13'
//                             },
//                             {
//                               id: 'P2_1069',
//                               name: '6VWBPS14'
//                             },
//                             {
//                               id: 'P2_1070',
//                               name: '6VWBPS15'
//                             },
//                             {
//                               id: 'P2_1071',
//                               name: '6VWBPS16'
//                             },
//                             {
//                               id: 'P2_1072',
//                               name: '6VWBPS17'
//                             },
//                             {
//                               id: 'P2_1073',
//                               name: '6VWBPS18'
//                             },
//                             {
//                               id: 'P2_1074',
//                               name: '6VWBPS19'
//                             },
//                             {
//                               id: 'P2_1075',
//                               name: '6VWBPS20'
//                             },
//                             {
//                               id: 'P2_1076',
//                               name: '6VWBPS21'
//                             },
//                             {
//                               id: 'P2_1077',
//                               name: '6VWBPS22'
//                             },
//                             {
//                               id: 'P2_1078',
//                               name: '6VWBPS23'
//                             },
//                             {
//                               id: 'P2_1079',
//                               name: '6VWBPS24'
//                             },
//                             {
//                               id: 'P2_1080',
//                               name: '6VWBPS25'
//                             },
//                             {
//                               id: 'P2_1451',
//                               name: '7VWBPS10'
//                             },
//                             {
//                               id: 'P2_2345',
//                               name: '7JWBPS08'
//                             },
//                             {
//                               id: 'P2_2400',
//                               name: '7JWBPS07'
//                             },
//                             {
//                               id: 'P2_2439',
//                               name: '7JWBPS10'
//                             },
//                             {
//                               id: 'P2_2440',
//                               name: '7JWBPS11'
//                             },
//                             {
//                               id: 'P2_2571',
//                               name: '7KWBPS01'
//                             },
//                             {
//                               id: 'P2_2778',
//                               name: '7JWBPS19'
//                             },
//                             {
//                               id: 'P2_2853',
//                               name: '7KWBPS02'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1085',
//                           name: 'ASM_AEROLED',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1086',
//                               name: '6VWBPS29'
//                             },
//                             {
//                               id: 'P2_1087',
//                               name: '6VWBPS30'
//                             },
//                             {
//                               id: 'P2_1088',
//                               name: '6VWBPS31'
//                             },
//                             {
//                               id: 'P2_1089',
//                               name: '6VWBPS32'
//                             },
//                             {
//                               id: 'P2_1090',
//                               name: '6VWBPS33'
//                             },
//                             {
//                               id: 'P2_1091',
//                               name: '6VWBPS34'
//                             },
//                             {
//                               id: 'P2_1092',
//                               name: '6VWBPS35'
//                             },
//                             {
//                               id: 'P2_2746',
//                               name: '6VWBPS50'
//                             },
//                             {
//                               id: 'P2_2747',
//                               name: '6VWBPS51'
//                             },
//                             {
//                               id: 'P2_2748',
//                               name: '6VWBPS52'
//                             },
//                             {
//                               id: 'P2_2749',
//                               name: '6VWBPS53'
//                             },
//                             {
//                               id: 'P2_2750',
//                               name: '6VWBPS54'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1104',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1105',
//                           name: 'DISCO',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1148',
//                               name: '6VSAWS01'
//                             },
//                             {
//                               id: 'P2_1149',
//                               name: '6VSAWS02'
//                             },
//                             {
//                               id: 'P2_1150',
//                               name: '6VSAWS03'
//                             },
//                             {
//                               id: 'P2_1151',
//                               name: '6VSAWS04'
//                             },
//                             {
//                               id: 'P2_1152',
//                               name: '6VSAWS05'
//                             },
//                             {
//                               id: 'P2_1153',
//                               name: '6VSAWS06'
//                             },
//                             {
//                               id: 'P2_1154',
//                               name: '6VSAWS07'
//                             },
//                             {
//                               id: 'P2_1155',
//                               name: '6VSAWS11'
//                             },
//                             {
//                               id: 'P2_1156',
//                               name: '6VSAWS12'
//                             },
//                             {
//                               id: 'P2_1157',
//                               name: '6VSAWS13'
//                             },
//                             {
//                               id: 'P2_1158',
//                               name: '6VSAWS14'
//                             },
//                             {
//                               id: 'P2_1159',
//                               name: '6VSAWS15'
//                             },
//                             {
//                               id: 'P2_1160',
//                               name: '6VSAWS16'
//                             },
//                             {
//                               id: 'P2_1161',
//                               name: '6VSAWS17'
//                             },
//                             {
//                               id: 'P2_1162',
//                               name: '6VSAWS19'
//                             },
//                             {
//                               id: 'P2_1163',
//                               name: '6VSAWS20'
//                             },
//                             {
//                               id: 'P2_1164',
//                               name: '6VSAWS21'
//                             },
//                             {
//                               id: 'P2_1165',
//                               name: '6VSAWS22'
//                             },
//                             {
//                               id: 'P2_2527',
//                               name: '7KSAWS01'
//                             },
//                             {
//                               id: 'P2_2713',
//                               name: '6VSAWS25'
//                             },
//                             {
//                               id: 'P2_2714',
//                               name: '6VSAWS26'
//                             },
//                             {
//                               id: 'P2_2719',
//                               name: '6VSAWS31'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1107',
//                       name: 'TIO2&CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1108',
//                           name: 'PROTEC_VERMES',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1206',
//                               name: '6VSCAS09'
//                             },
//                             {
//                               id: 'P2_2675',
//                               name: '7JSCAS21'
//                             },
//                             {
//                               id: 'P2_2737',
//                               name: '6VSCAS34'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1210',
//                           name: 'NSW',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1211',
//                               name: '6VSCAS15'
//                             },
//                             {
//                               id: 'P2_1212',
//                               name: '6VSCAS16'
//                             },
//                             {
//                               id: 'P2_1213',
//                               name: '6VSCAS17'
//                             },
//                             {
//                               id: 'P2_1214',
//                               name: '6VSCAS18'
//                             },
//                             {
//                               id: 'P2_1215',
//                               name: '6VSCAS19'
//                             },
//                             {
//                               id: 'P2_1216',
//                               name: '6VSCAS20'
//                             },
//                             {
//                               id: 'P2_1217',
//                               name: '6VSCAS21'
//                             },
//                             {
//                               id: 'P2_1218',
//                               name: '6VSCAS22'
//                             },
//                             {
//                               id: 'P2_1220',
//                               name: '6VSCAS24'
//                             },
//                             {
//                               id: 'P2_1221',
//                               name: '6VSCAS25'
//                             },
//                             {
//                               id: 'P2_1222',
//                               name: '6VSCAS26'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1126',
//                       name: 'PLASMA_FOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2725',
//                           name: 'E&R_PLMASMAX 800III',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2727',
//                               name: '6VPLMS01'
//                             },
//                             {
//                               id: 'P2_2729',
//                               name: '6VPLMS03'
//                             },
//                             {
//                               id: 'P2_2731',
//                               name: '6VPLMS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1229',
//                       name: 'COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1230',
//                           name: 'OXFORD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1231',
//                               name: '6VCOAS01'
//                             },
//                             {
//                               id: 'P2_1232',
//                               name: '6VCOAS02'
//                             },
//                             {
//                               id: 'P2_1233',
//                               name: '6VCOAS03'
//                             },
//                             {
//                               id: 'P2_1234',
//                               name: '6VCOAS04'
//                             },
//                             {
//                               id: 'P2_1235',
//                               name: '6VCOAS05'
//                             },
//                             {
//                               id: 'P2_1236',
//                               name: '6VCOAS06'
//                             },
//                             {
//                               id: 'P2_2592',
//                               name: '6JCOAS01'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_1499',
//                       name: 'DAM',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2783',
//                           name: 'PROTEC',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1198',
//                               name: '6VSCAS01'
//                             },
//                             {
//                               id: 'P2_1204',
//                               name: '6VSCAS07'
//                             },
//                             {
//                               id: 'P2_1205',
//                               name: '6VSCAS08'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2327',
//                       name: 'PLASMA_EOL',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2726',
//                           name: 'E&R_MW300',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2728',
//                               name: '6VPLMS02'
//                             },
//                             {
//                               id: 'P2_2730',
//                               name: '6VPLMS04'
//                             },
//                             {
//                               id: 'P2_2732',
//                               name: '6VPLMS06'
//                             },
//                             {
//                               id: 'P2_2733',
//                               name: '6VPLMS07'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2422',
//                       name: 'TIO2',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2773',
//                           name: 'PROTEC_MARCO',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1203',
//                               name: '6VSCAS06'
//                             },
//                             {
//                               id: 'P2_1224',
//                               name: '6VSCAS29'
//                             },
//                             {
//                               id: 'P2_1227',
//                               name: '6VSCAS33'
//                             },
//                             {
//                               id: 'P2_1228',
//                               name: '6VSCAS35'
//                             },
//                             {
//                               id: 'P2_2738',
//                               name: '6VSCAS36'
//                             },
//                             {
//                               id: 'P2_2740',
//                               name: '6VSCAS38'
//                             },
//                             {
//                               id: 'P2_2741',
//                               name: '6VSCAS39'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2507',
//                       name: 'DIE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1190',
//                           name: 'ASM_AD862H',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1191',
//                               name: '6VDAPS28'
//                             },
//                             {
//                               id: 'P2_1192',
//                               name: '6VDAPS29'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2516',
//                           name: 'ASM_TWIN833',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1168',
//                               name: '6VDAPS01'
//                             },
//                             {
//                               id: 'P2_1169',
//                               name: '6VDAPS02'
//                             },
//                             {
//                               id: 'P2_1170',
//                               name: '6VDAPS03'
//                             },
//                             {
//                               id: 'P2_1171',
//                               name: '6VDAPS06'
//                             },
//                             {
//                               id: 'P2_1172',
//                               name: '6VDAPS07'
//                             },
//                             {
//                               id: 'P2_1173',
//                               name: '6VDAPS08'
//                             },
//                             {
//                               id: 'P2_1174',
//                               name: '6VDAPS09'
//                             },
//                             {
//                               id: 'P2_1175',
//                               name: '6VDAPS10'
//                             },
//                             {
//                               id: 'P2_1176',
//                               name: '6VDAPS11'
//                             },
//                             {
//                               id: 'P2_1177',
//                               name: '6VDAPS12'
//                             },
//                             {
//                               id: 'P2_1178',
//                               name: '6VDAPS13'
//                             },
//                             {
//                               id: 'P2_1179',
//                               name: '6VDAPS14'
//                             },
//                             {
//                               id: 'P2_1180',
//                               name: '6VDAPS15'
//                             },
//                             {
//                               id: 'P2_1181',
//                               name: '6VDAPS16'
//                             },
//                             {
//                               id: 'P2_1182',
//                               name: '6VDAPS19'
//                             },
//                             {
//                               id: 'P2_1183',
//                               name: '6VDAPS20'
//                             },
//                             {
//                               id: 'P2_1184',
//                               name: '6VDAPS21'
//                             },
//                             {
//                               id: 'P2_1185',
//                               name: '6VDAPS22'
//                             },
//                             {
//                               id: 'P2_1186',
//                               name: '6VDAPS23'
//                             },
//                             {
//                               id: 'P2_1187',
//                               name: '6VDAPS24'
//                             },
//                             {
//                               id: 'P2_1188',
//                               name: '6VDAPS25'
//                             },
//                             {
//                               id: 'P2_1189',
//                               name: '6VDAPS26'
//                             },
//                             {
//                               id: 'P2_1193',
//                               name: '6VDAPS33'
//                             },
//                             {
//                               id: 'P2_1194',
//                               name: '6VDAPS34'
//                             },
//                             {
//                               id: 'P2_1195',
//                               name: '6VDAPS35'
//                             },
//                             {
//                               id: 'P2_1196',
//                               name: '6VDAPS42'
//                             },
//                             {
//                               id: 'P2_1197',
//                               name: '6VDAPS43'
//                             },
//                             {
//                               id: 'P2_2410',
//                               name: '7JDAPS09'
//                             },
//                             {
//                               id: 'P2_2442',
//                               name: '7JDAPS14'
//                             },
//                             {
//                               id: 'P2_2524',
//                               name: '7KDAPS01'
//                             },
//                             {
//                               id: 'P2_2525',
//                               name: '7KDAPS02'
//                             },
//                             {
//                               id: 'P2_2526',
//                               name: '7KDAPS03'
//                             },
//                             {
//                               id: 'P2_2585',
//                               name: '7JDAPS17'
//                             },
//                             {
//                               id: 'P2_2673',
//                               name: '7JDAPS19'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2734',
//                           name: 'ASM_AD800',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2735',
//                               name: '6VDAPS44'
//                             },
//                             {
//                               id: 'P2_2736',
//                               name: '6VDAPS45'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2509',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2518',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2528',
//                               name: '7K3RDS01'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2511',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2520',
//                           name: 'PROTEC_LINEAR',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1199',
//                               name: '6VSCAS02'
//                             },
//                             {
//                               id: 'P2_1200',
//                               name: '6VSCAS03'
//                             },
//                             {
//                               id: 'P2_1201',
//                               name: '6VSCAS04'
//                             },
//                             {
//                               id: 'P2_1202',
//                               name: '6VSCAS05'
//                             },
//                             {
//                               id: 'P2_1207',
//                               name: '6VSCAS10'
//                             },
//                             {
//                               id: 'P2_1208',
//                               name: '6VSCAS11'
//                             },
//                             {
//                               id: 'P2_1225',
//                               name: '6VSCAS30'
//                             },
//                             {
//                               id: 'P2_1226',
//                               name: '6VSCAS31'
//                             },
//                             {
//                               id: 'P2_2338',
//                               name: '7JSCAS11'
//                             },
//                             {
//                               id: 'P2_2529',
//                               name: '7KSCAS01'
//                             },
//                             {
//                               id: 'P2_2530',
//                               name: '7KSCAS02'
//                             },
//                             {
//                               id: 'P2_2677',
//                               name: '7JSCAS24'
//                             },
//                             {
//                               id: 'P2_2678',
//                               name: '7JSCAS25'
//                             },
//                             {
//                               id: 'P2_2683',
//                               name: '7JSCAS23'
//                             },
//                             {
//                               id: 'P2_2687',
//                               name: '7KSCAS03'
//                             },
//                             {
//                               id: 'P2_2739',
//                               name: '6VSCAS37'
//                             },
//                             {
//                               id: 'P2_2859',
//                               name: '7KSCAS04'
//                             },
//                             {
//                               id: 'P2_2860',
//                               name: '7KSCAS05'
//                             },
//                             {
//                               id: 'P2_2862',
//                               name: '7KSCAS06'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2513',
//                       name: '100%_AVI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2521',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2577',
//                               name: '7KEVIS01'
//                             },
//                             {
//                               id: 'P2_2759',
//                               name: '6VEVIS01'
//                             },
//                             {
//                               id: 'P2_2844',
//                               name: '7KEVIS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2659',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_2660',
//                           name: 'TTVISION',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2661',
//                               name: '7KAVIS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2791',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1102',
//                           name: 'LISU',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1103',
//                               name: '6VLSTS01'
//                             },
//                             {
//                               id: 'P2_2572',
//                               name: '6VLSTS02'
//                             },
//                             {
//                               id: 'P2_2745',
//                               name: '6VLSTS03'
//                             },
//                             {
//                               id: 'P2_2849',
//                               name: '6VLSTS04'
//                             },
//                             {
//                               id: 'P2_2861',
//                               name: '6VLSTS05'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1110',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1042',
//                               name: '6VIMTS03'
//                             },
//                             {
//                               id: 'P2_1111',
//                               name: '6VIMTS01'
//                             },
//                             {
//                               id: 'P2_1113',
//                               name: '6VIMTS04'
//                             },
//                             {
//                               id: 'P2_1114',
//                               name: '6VIMTS08'
//                             },
//                             {
//                               id: 'P2_2574',
//                               name: '6VIMTS09'
//                             },
//                             {
//                               id: 'P2_2575',
//                               name: '6VIMTS10'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1116',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1117',
//                               name: '6VTWTS01'
//                             },
//                             {
//                               id: 'P2_1118',
//                               name: '6VTWTS02'
//                             },
//                             {
//                               id: 'P2_1119',
//                               name: '6VTWTS03'
//                             },
//                             {
//                               id: 'P2_1121',
//                               name: '6VTWTS05'
//                             },
//                             {
//                               id: 'P2_1122',
//                               name: '7JTWTS12'
//                             },
//                             {
//                               id: 'P2_2374',
//                               name: '7JTWTS04'
//                             },
//                             {
//                               id: 'P2_2625',
//                               name: '7JTWTS08'
//                             },
//                             {
//                               id: 'P2_2764',
//                               name: '7JTWTS10'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_1579',
//                           name: 'ISMECA2',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2848',
//                               name: '7KIMTS01'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2768',
//                           name: 'INTO_TX20',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1115',
//                               name: '6VITTS02'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2799',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1127',
//                           name: 'TOKYO_WELD',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1128',
//                               name: '6VTWSS01'
//                             },
//                             {
//                               id: 'P2_1129',
//                               name: '6VTWSS02'
//                             },
//                             {
//                               id: 'P2_1130',
//                               name: '6VTWSS03'
//                             },
//                             {
//                               id: 'P2_1131',
//                               name: '6VTWSS04'
//                             },
//                             {
//                               id: 'P2_1133',
//                               name: '6VTWSS06'
//                             },
//                             {
//                               id: 'P2_1134',
//                               name: '6VTWSS07'
//                             },
//                             {
//                               id: 'P2_1135',
//                               name: '6VTWSS08'
//                             },
//                             {
//                               id: 'P2_1137',
//                               name: '6VTWSS10'
//                             },
//                             {
//                               id: 'P2_1139',
//                               name: '6VTWSS12'
//                             },
//                             {
//                               id: 'P2_1140',
//                               name: '6VTWSS13'
//                             },
//                             {
//                               id: 'P2_1141',
//                               name: '6VTWSS14'
//                             },
//                             {
//                               id: 'P2_2371',
//                               name: '7JTWSS03'
//                             },
//                             {
//                               id: 'P2_2622',
//                               name: '7JTWSS06'
//                             },
//                             {
//                               id: 'P2_2761',
//                               name: '7JTWSS08'
//                             },
//                             {
//                               id: 'P2_2784',
//                               name: '7JTWSS09'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P2_2775',
//                           name: 'ISMECA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_2573',
//                               name: '6VIMSS05'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P2_2808',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P2_1143',
//                           name: 'TOWA',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P2_1144',
//                               name: '6VMODS01'
//                             },
//                             {
//                               id: 'P2_1145',
//                               name: '6VMODS02'
//                             },
//                             {
//                               id: 'P2_1146',
//                               name: '6VMODS03'
//                             },
//                             {
//                               id: 'P2_1147',
//                               name: '6VMODS04'
//                             },
//                             {
//                               id: 'P2_2858',
//                               name: '7KMODS01'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 }
//               ]
//             },
//             {
//               id: 'P3',
//               name: 'KLM',
//               type: 'object',
//               selectionType: 'multiselect',
//               required: 'false',
//               attribute: 'Module',
//               lockable: false,
//               values: [
//                 {
//                   id: 'P3_1002',
//                   name: 'MF4 - CERAMOLD_OSLON SSL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1003',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1016',
//                           name: '1AVI03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1017',
//                               name: '1AVI0302'
//                             },
//                             {
//                               id: 'P3_1407',
//                               name: '1AVI0305'
//                             },
//                             {
//                               id: 'P3_1408',
//                               name: '1AVI0303'
//                             },
//                             {
//                               id: 'P3_1409',
//                               name: '1AVI0304'
//                             },
//                             {
//                               id: 'P3_1410',
//                               name: '1AVI0306'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1021',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1025',
//                           name: '1DES06_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1026',
//                               name: '1DES0603'
//                             },
//                             {
//                               id: 'P3_1027',
//                               name: '1DES0604'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1034',
//                           name: '1DES05_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1035',
//                               name: '1DES0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1305',
//                           name: '1DES05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1306',
//                               name: '1DES0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1317',
//                           name: '1DES09_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1318',
//                               name: '1DES0901'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1599',
//                           name: '1DES01_T03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1023',
//                               name: '1DES0111'
//                             },
//                             {
//                               id: 'P3_1024',
//                               name: '1DES0109'
//                             },
//                             {
//                               id: 'P3_1036',
//                               name: '1DES0110'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1600',
//                           name: '1DES10_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1029',
//                               name: '1DES1002'
//                             },
//                             {
//                               id: 'P3_1030',
//                               name: '1DES1004'
//                             },
//                             {
//                               id: 'P3_1031',
//                               name: '1DES1005'
//                             },
//                             {
//                               id: 'P3_1032',
//                               name: '1DES1006'
//                             },
//                             {
//                               id: 'P3_1033',
//                               name: '1DES1007'
//                             },
//                             {
//                               id: 'P3_1037',
//                               name: '1DES1003'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1038',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1039',
//                           name: '1DAT05_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1040',
//                               name: '1DAT0579'
//                             },
//                             {
//                               id: 'P3_1041',
//                               name: '1DAT0580'
//                             },
//                             {
//                               id: 'P3_1042',
//                               name: '1DAT0582'
//                             },
//                             {
//                               id: 'P3_1043',
//                               name: '1DAT0534'
//                             },
//                             {
//                               id: 'P3_1044',
//                               name: '1DAT0535'
//                             },
//                             {
//                               id: 'P3_1045',
//                               name: '1DAT0550'
//                             },
//                             {
//                               id: 'P3_1046',
//                               name: '1DAT0584'
//                             },
//                             {
//                               id: 'P3_1047',
//                               name: '1DAT0585'
//                             },
//                             {
//                               id: 'P3_1048',
//                               name: '1DAT0578'
//                             },
//                             {
//                               id: 'P3_1049',
//                               name: '1DAT0532'
//                             },
//                             {
//                               id: 'P3_1050',
//                               name: '1DAT0538'
//                             },
//                             {
//                               id: 'P3_1051',
//                               name: '1DAT0539'
//                             },
//                             {
//                               id: 'P3_1052',
//                               name: '1DAT0583'
//                             },
//                             {
//                               id: 'P3_1053',
//                               name: '1DAT0515'
//                             },
//                             {
//                               id: 'P3_1054',
//                               name: '1DAT0545'
//                             },
//                             {
//                               id: 'P3_1055',
//                               name: '1DAT0526'
//                             },
//                             {
//                               id: 'P3_1056',
//                               name: '1DAT0527'
//                             },
//                             {
//                               id: 'P3_1057',
//                               name: '1DAT0528'
//                             },
//                             {
//                               id: 'P3_1058',
//                               name: '1DAT0569'
//                             },
//                             {
//                               id: 'P3_1059',
//                               name: '1DAT0530'
//                             },
//                             {
//                               id: 'P3_1060',
//                               name: '1DAT0531'
//                             },
//                             {
//                               id: 'P3_1061',
//                               name: '1DAT0507'
//                             },
//                             {
//                               id: 'P3_1062',
//                               name: '1DAT0508'
//                             },
//                             {
//                               id: 'P3_1063',
//                               name: '1DAT0529'
//                             },
//                             {
//                               id: 'P3_1064',
//                               name: '1DAT0516'
//                             },
//                             {
//                               id: 'P3_1065',
//                               name: '1DAT0509'
//                             },
//                             {
//                               id: 'P3_1066',
//                               name: '1DAT0525'
//                             },
//                             {
//                               id: 'P3_1067',
//                               name: '1DAT0505'
//                             },
//                             {
//                               id: 'P3_1070',
//                               name: '1DAT0503'
//                             },
//                             {
//                               id: 'P3_1728',
//                               name: '1DAT0523'
//                             },
//                             {
//                               id: 'P3_1730',
//                               name: '1DAT0543'
//                             },
//                             {
//                               id: 'P3_1731',
//                               name: '1DAT0565'
//                             },
//                             {
//                               id: 'P3_1732',
//                               name: '1DAT0593'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1081',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1082',
//                           name: '1CMD01_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1083',
//                               name: '1CMD0120'
//                             },
//                             {
//                               id: 'P3_1085',
//                               name: '1CMD0115'
//                             },
//                             {
//                               id: 'P3_1086',
//                               name: '1CMD0117'
//                             },
//                             {
//                               id: 'P3_1087',
//                               name: '1CMD0118'
//                             },
//                             {
//                               id: 'P3_1088',
//                               name: '1CMD0119'
//                             },
//                             {
//                               id: 'P3_1089',
//                               name: '1CMD0105'
//                             },
//                             {
//                               id: 'P3_1090',
//                               name: '1CMD0106'
//                             },
//                             {
//                               id: 'P3_1091',
//                               name: '1CMD0107'
//                             },
//                             {
//                               id: 'P3_1092',
//                               name: '1CMD0108'
//                             },
//                             {
//                               id: 'P3_1093',
//                               name: '1CMD0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1095',
//                       name: 'MOUNTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1096',
//                           name: '1MTR02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1097',
//                               name: '1MTR0220'
//                             },
//                             {
//                               id: 'P3_1516',
//                               name: '1MTR0206'
//                             },
//                             {
//                               id: 'P3_1517',
//                               name: '1MTR0205'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1098',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1099',
//                           name: '1OVN14_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1100',
//                               name: '1OVN1402'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1101',
//                           name: '1OVN14_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1102',
//                               name: '1OVN1401'
//                             },
//                             {
//                               id: 'P3_1658',
//                               name: '1OVN1422'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1525',
//                           name: '1OVN01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1526',
//                               name: '1OVN0133'
//                             },
//                             {
//                               id: 'P3_1528',
//                               name: '1OVN0185'
//                             },
//                             {
//                               id: 'P3_1574',
//                               name: '1OVN0104'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1635',
//                           name: '1OVN14_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1518',
//                               name: '1OVN1406'
//                             },
//                             {
//                               id: 'P3_1519',
//                               name: '1OVN1405'
//                             },
//                             {
//                               id: 'P3_1520',
//                               name: '1OVN1416'
//                             },
//                             {
//                               id: 'P3_1521',
//                               name: '1OVN1417'
//                             },
//                             {
//                               id: 'P3_1522',
//                               name: '1OVN1407'
//                             },
//                             {
//                               id: 'P3_1535',
//                               name: '1OVN1408'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1711',
//                           name: '1OVN14_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1534',
//                               name: '1OVN1413'
//                             },
//                             {
//                               id: 'P3_1541',
//                               name: '1OVN1415'
//                             },
//                             {
//                               id: 'P3_1577',
//                               name: '1OVN1412'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1103',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1104',
//                           name: '1PLM01_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1106',
//                               name: '1PLM0106'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1416',
//                           name: '1PLM07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1417',
//                               name: '1PLM0701'
//                             },
//                             {
//                               id: 'P3_1418',
//                               name: '1PLM0702'
//                             },
//                             {
//                               id: 'P3_1420',
//                               name: '1PLM0704'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1109',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1110',
//                           name: '1SAW03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1111',
//                               name: '1SAW0345'
//                             },
//                             {
//                               id: 'P3_1112',
//                               name: '1SAW0323'
//                             },
//                             {
//                               id: 'P3_1113',
//                               name: '1SAW0301'
//                             },
//                             {
//                               id: 'P3_1115',
//                               name: '1SAW0305'
//                             },
//                             {
//                               id: 'P3_1120',
//                               name: '1SAW0326'
//                             },
//                             {
//                               id: 'P3_1121',
//                               name: '1SAW0344'
//                             },
//                             {
//                               id: 'P3_1122',
//                               name: '1SAW0347'
//                             },
//                             {
//                               id: 'P3_1123',
//                               name: '1SAW0348'
//                             },
//                             {
//                               id: 'P3_1124',
//                               name: '1SAW0306'
//                             },
//                             {
//                               id: 'P3_1125',
//                               name: '1SAW0322'
//                             },
//                             {
//                               id: 'P3_1126',
//                               name: '1SAW0303'
//                             },
//                             {
//                               id: 'P3_1127',
//                               name: '1SAW0304'
//                             },
//                             {
//                               id: 'P3_1128',
//                               name: '1SAW0325'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1116',
//                           name: '1SAW06_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1117',
//                               name: '1SAW0602'
//                             },
//                             {
//                               id: 'P3_1118',
//                               name: '1SAW0601'
//                             },
//                             {
//                               id: 'P3_1119',
//                               name: '1SAW0603'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1130',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1131',
//                           name: '1SFB02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1132',
//                               name: '1SFB0222'
//                             },
//                             {
//                               id: 'P3_1133',
//                               name: '1SFB0221'
//                             },
//                             {
//                               id: 'P3_1141',
//                               name: '1SFB0213'
//                             },
//                             {
//                               id: 'P3_1142',
//                               name: '1SFB0216'
//                             },
//                             {
//                               id: 'P3_1146',
//                               name: '1SFB0203'
//                             },
//                             {
//                               id: 'P3_1147',
//                               name: '1SFB0205'
//                             },
//                             {
//                               id: 'P3_1148',
//                               name: '1SFB0208'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1134',
//                           name: '1SFB04_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1135',
//                               name: '1SFB0401'
//                             },
//                             {
//                               id: 'P3_1136',
//                               name: '1SFB0402'
//                             },
//                             {
//                               id: 'P3_1137',
//                               name: '1SFB0403'
//                             },
//                             {
//                               id: 'P3_1138',
//                               name: '1SFB0404'
//                             },
//                             {
//                               id: 'P3_1139',
//                               name: '1SFB0405'
//                             },
//                             {
//                               id: 'P3_1140',
//                               name: '1SFB0407'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1143',
//                           name: '1SFB01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1144',
//                               name: '1SFB0107'
//                             },
//                             {
//                               id: 'P3_1145',
//                               name: '1SFB0108'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1150',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1151',
//                           name: '1LSC01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1152',
//                               name: '1LSC0110'
//                             },
//                             {
//                               id: 'P3_1159',
//                               name: '1LSC0107'
//                             },
//                             {
//                               id: 'P3_1160',
//                               name: '1LSC0108'
//                             },
//                             {
//                               id: 'P3_1161',
//                               name: '1LSC0109'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1153',
//                           name: '1LSC03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1154',
//                               name: '1LSC0301'
//                             },
//                             {
//                               id: 'P3_1155',
//                               name: '1LSC0302'
//                             },
//                             {
//                               id: 'P3_1157',
//                               name: '1LSC0303'
//                             },
//                             {
//                               id: 'P3_1158',
//                               name: '1LSC0305'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1497',
//                           name: '6LSC03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1498',
//                               name: '6LSC0306'
//                             },
//                             {
//                               id: 'P3_1499',
//                               name: '6LSC0307'
//                             },
//                             {
//                               id: 'P3_1500',
//                               name: '6LSC0308'
//                             },
//                             {
//                               id: 'P3_1501',
//                               name: '6LSC0309'
//                             },
//                             {
//                               id: 'P3_1502',
//                               name: '6LSC0310'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1167',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1168',
//                           name: '1TBT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1169',
//                               name: '1TBT0211'
//                             },
//                             {
//                               id: 'P3_1170',
//                               name: '1TBT0212'
//                             },
//                             {
//                               id: 'P3_1172',
//                               name: '1TBT0216'
//                             },
//                             {
//                               id: 'P3_1173',
//                               name: '1TBT0225'
//                             },
//                             {
//                               id: 'P3_1174',
//                               name: '1TBT0222'
//                             },
//                             {
//                               id: 'P3_1182',
//                               name: '1TBT0221'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1175',
//                           name: '1TBT08_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1176',
//                               name: '1TBT0806'
//                             },
//                             {
//                               id: 'P3_1183',
//                               name: '1TBT0802'
//                             },
//                             {
//                               id: 'P3_1184',
//                               name: '1TBT0803'
//                             },
//                             {
//                               id: 'P3_1185',
//                               name: '1TBT0805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1177',
//                           name: '1TBT11_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1178',
//                               name: '1TBT1101'
//                             },
//                             {
//                               id: 'P3_1179',
//                               name: '1TBT1102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1180',
//                           name: '1TBT12_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1181',
//                               name: '1TBT1202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1186',
//                       name: 'TEST_AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1187',
//                           name: '1AVT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1729',
//                               name: '1AVT0210'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1661',
//                           name: '6AVT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1580',
//                               name: '6AVT0102'
//                             },
//                             {
//                               id: 'P3_1582',
//                               name: '6AVT0104'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1193',
//                       name: 'TIO2',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1194',
//                           name: '1CAS04_E09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1195',
//                               name: '1CAS0440'
//                             },
//                             {
//                               id: 'P3_1203',
//                               name: '1CAS0439'
//                             },
//                             {
//                               id: 'P3_1247',
//                               name: '1CAS0417'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1196',
//                           name: '1CAS03_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1197',
//                               name: '1CAS0304'
//                             },
//                             {
//                               id: 'P3_1198',
//                               name: '1CAS0305'
//                             },
//                             {
//                               id: 'P3_1199',
//                               name: '1CAS0311'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1204',
//                       name: 'SUPP_UV_CURE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1205',
//                           name: '1UVC03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1206',
//                               name: '1UVC0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1207',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1208',
//                           name: '1WBD04_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1209',
//                               name: '1WBD0409'
//                             },
//                             {
//                               id: 'P3_1210',
//                               name: '1WBD0411'
//                             },
//                             {
//                               id: 'P3_1211',
//                               name: '1WBD0412'
//                             },
//                             {
//                               id: 'P3_1212',
//                               name: '1WBD0413'
//                             },
//                             {
//                               id: 'P3_1213',
//                               name: '1WBD0414'
//                             },
//                             {
//                               id: 'P3_1214',
//                               name: '1WBD0415'
//                             },
//                             {
//                               id: 'P3_1223',
//                               name: '1WBD0404'
//                             },
//                             {
//                               id: 'P3_1224',
//                               name: '1WBD0405'
//                             },
//                             {
//                               id: 'P3_1229',
//                               name: '1WBD0401'
//                             },
//                             {
//                               id: 'P3_1230',
//                               name: '1WBD0406'
//                             },
//                             {
//                               id: 'P3_1231',
//                               name: '1WBD0403'
//                             },
//                             {
//                               id: 'P3_1735',
//                               name: '1WBD0416'
//                             },
//                             {
//                               id: 'P3_1736',
//                               name: '1WBD0423'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1217',
//                           name: '1WBD07_F09',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1218',
//                               name: '1WBD0733'
//                             },
//                             {
//                               id: 'P3_1219',
//                               name: '1WBD0734'
//                             },
//                             {
//                               id: 'P3_1220',
//                               name: '1WBD0735'
//                             },
//                             {
//                               id: 'P3_1221',
//                               name: '1WBD0736'
//                             },
//                             {
//                               id: 'P3_1226',
//                               name: '1WBD0747'
//                             },
//                             {
//                               id: 'P3_1227',
//                               name: '1WBD0748'
//                             },
//                             {
//                               id: 'P3_1228',
//                               name: '1WBD0752'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1573',
//                           name: '1WBD07_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1288',
//                               name: '1WBD0724'
//                             },
//                             {
//                               id: 'P3_1290',
//                               name: '1WBD0756'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1739',
//                           name: '6WBD07_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1740',
//                               name: '6WBD0702'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P3_1232',
//                   name: 'MF4 - COB_LIGHT KERNEL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1248',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1249',
//                           name: '1DES10_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1250',
//                               name: '1DES1001'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P3_1295',
//                   name: 'MF4 - QCAST',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1296',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1424',
//                           name: '6CAS23_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1427',
//                               name: '6CAS2303'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1304',
//                       name: 'SUPP_DESICCATOR',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1307',
//                           name: '1DES11_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1308',
//                               name: '1DES1101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1310',
//                           name: '1DES06_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1311',
//                               name: '1DES0601'
//                             },
//                             {
//                               id: 'P3_1312',
//                               name: '1DES0602'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1313',
//                           name: '1DES08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1314',
//                               name: '1DES0801'
//                             },
//                             {
//                               id: 'P3_1315',
//                               name: '1DES0802'
//                             },
//                             {
//                               id: 'P3_1316',
//                               name: '1DES0803'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1319',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1320',
//                           name: '1DAT11_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1326',
//                               name: '1DAT1105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1399',
//                           name: '1DAT05_F14',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1068',
//                               name: '1DAT0504'
//                             },
//                             {
//                               id: 'P3_1069',
//                               name: '1DAT0501'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1335',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1336',
//                           name: '1PLM06_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1338',
//                               name: '1PLM0603'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1570',
//                           name: '1PLM07_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1419',
//                               name: '1PLM0703'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1339',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1340',
//                           name: '1SAW04_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1342',
//                               name: '1SAW0405'
//                             },
//                             {
//                               id: 'P3_1349',
//                               name: '1SAW0403'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1350',
//                       name: 'SEDIMENTATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1351',
//                           name: '1PCS01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1509',
//                               name: '1PCS0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1354',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1355',
//                           name: '1SBB03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1356',
//                               name: '1SBB0301'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1402',
//                           name: '1SFB01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1403',
//                               name: '1SFB0109'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1362',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1363',
//                           name: '1TBT05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1367',
//                               name: '1TBT0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1401',
//                           name: '1TBT02_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1171',
//                               name: '1TBT0214'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1370',
//                       name: 'TEST_AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1668',
//                           name: '6AVT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1579',
//                               name: '6AVT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1378',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1215',
//                           name: '1WBD06_F04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1216',
//                               name: '1WBD0605'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1379',
//                           name: '1WBD06_F05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1382',
//                               name: '1WBD0621'
//                             },
//                             {
//                               id: 'P3_1383',
//                               name: '1WBD0622'
//                             },
//                             {
//                               id: 'P3_1388',
//                               name: '1WBD0628'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1737',
//                           name: '2WBD06_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1738',
//                               name: '2WBD0610'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1487',
//                       name: 'TIO2',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1488',
//                           name: '1CAS04_E10',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1202',
//                               name: '1CAS0438'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1727',
//                           name: '6CAS23_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1425',
//                               name: '6CAS2301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1490',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1491',
//                           name: '1CMD01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1094',
//                               name: '1CMD0110'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1539',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1540',
//                           name: '1OVN14_F03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1542',
//                               name: '1OVN1418'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1636',
//                           name: '1OVN15_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1544',
//                               name: '1OVN1505'
//                             },
//                             {
//                               id: 'P3_1545',
//                               name: '1OVN1506'
//                             },
//                             {
//                               id: 'P3_1546',
//                               name: '1OVN1504'
//                             },
//                             {
//                               id: 'P3_1547',
//                               name: '1OVN1507'
//                             },
//                             {
//                               id: 'P3_1637',
//                               name: '1OVN1541'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1638',
//                           name: '1OVN15_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1639',
//                               name: '1OVN1542'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1733',
//                       name: 'CASTING_TIO2',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1734',
//                           name: '6CAS23_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1426',
//                               name: '6CAS2302'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P3_1428',
//                   name: 'MF4 - COMMON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1429',
//                       name: 'SUPP_SCOPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1430',
//                           name: '6SCP01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1432',
//                               name: '6SCP0101'
//                             },
//                             {
//                               id: 'P3_1433',
//                               name: '6SCP0102'
//                             },
//                             {
//                               id: 'P3_1434',
//                               name: '6SCP0103'
//                             },
//                             {
//                               id: 'P3_1435',
//                               name: '6SCP0104'
//                             },
//                             {
//                               id: 'P3_1436',
//                               name: '6SCP0105'
//                             },
//                             {
//                               id: 'P3_1437',
//                               name: '6SCP0106'
//                             },
//                             {
//                               id: 'P3_1438',
//                               name: '6SCP0107'
//                             },
//                             {
//                               id: 'P3_1439',
//                               name: '6SCP0108'
//                             },
//                             {
//                               id: 'P3_1440',
//                               name: '6SCP0109'
//                             },
//                             {
//                               id: 'P3_1441',
//                               name: '6SCP0110'
//                             },
//                             {
//                               id: 'P3_1442',
//                               name: '6SCP0111'
//                             },
//                             {
//                               id: 'P3_1443',
//                               name: '6SCP0112'
//                             },
//                             {
//                               id: 'P3_1444',
//                               name: '6SCP0113'
//                             },
//                             {
//                               id: 'P3_1445',
//                               name: '6SCP0114'
//                             },
//                             {
//                               id: 'P3_1446',
//                               name: '6SCP0115'
//                             },
//                             {
//                               id: 'P3_1447',
//                               name: '6SCP0116'
//                             },
//                             {
//                               id: 'P3_1448',
//                               name: '6SCP0117'
//                             },
//                             {
//                               id: 'P3_1449',
//                               name: '6SCP0118'
//                             },
//                             {
//                               id: 'P3_1450',
//                               name: '6SCP0119'
//                             },
//                             {
//                               id: 'P3_1451',
//                               name: '6SCP0120'
//                             },
//                             {
//                               id: 'P3_1452',
//                               name: '6SCP0121'
//                             },
//                             {
//                               id: 'P3_1453',
//                               name: '6SCP0122'
//                             },
//                             {
//                               id: 'P3_1454',
//                               name: '6SCP0123'
//                             },
//                             {
//                               id: 'P3_1455',
//                               name: '6SCP0124'
//                             },
//                             {
//                               id: 'P3_1456',
//                               name: '6SCP0125'
//                             },
//                             {
//                               id: 'P3_1457',
//                               name: '6SCP0126'
//                             },
//                             {
//                               id: 'P3_1458',
//                               name: '6SCP0127'
//                             },
//                             {
//                               id: 'P3_1459',
//                               name: '6SCP0128'
//                             },
//                             {
//                               id: 'P3_1460',
//                               name: '6SCP0129'
//                             },
//                             {
//                               id: 'P3_1461',
//                               name: '6SCP0130'
//                             },
//                             {
//                               id: 'P3_1462',
//                               name: '6SCP0131'
//                             },
//                             {
//                               id: 'P3_1463',
//                               name: '6SCP0132'
//                             },
//                             {
//                               id: 'P3_1464',
//                               name: '6SCP0133'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1603',
//                           name: '6SCP01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1465',
//                               name: '6SCP0134'
//                             },
//                             {
//                               id: 'P3_1466',
//                               name: '6SCP0135'
//                             },
//                             {
//                               id: 'P3_1467',
//                               name: '6SCP0136'
//                             },
//                             {
//                               id: 'P3_1468',
//                               name: '6SCP0137'
//                             },
//                             {
//                               id: 'P3_1469',
//                               name: '6SCP0138'
//                             },
//                             {
//                               id: 'P3_1470',
//                               name: '6SCP0139'
//                             },
//                             {
//                               id: 'P3_1471',
//                               name: '6SCP0140'
//                             },
//                             {
//                               id: 'P3_1472',
//                               name: '6SCP0141'
//                             },
//                             {
//                               id: 'P3_1473',
//                               name: '6SCP0142'
//                             },
//                             {
//                               id: 'P3_1474',
//                               name: '6SCP0143'
//                             },
//                             {
//                               id: 'P3_1475',
//                               name: '6SCP0144'
//                             },
//                             {
//                               id: 'P3_1476',
//                               name: '6SCP0145'
//                             },
//                             {
//                               id: 'P3_1477',
//                               name: '6SCP0146'
//                             },
//                             {
//                               id: 'P3_1478',
//                               name: '6SCP0147'
//                             },
//                             {
//                               id: 'P3_1479',
//                               name: '6SCP0148'
//                             },
//                             {
//                               id: 'P3_1480',
//                               name: '6SCP0149'
//                             },
//                             {
//                               id: 'P3_1481',
//                               name: '6SCP0150'
//                             },
//                             {
//                               id: 'P3_1482',
//                               name: '6SCP0151'
//                             },
//                             {
//                               id: 'P3_1483',
//                               name: '6SCP0152'
//                             },
//                             {
//                               id: 'P3_1484',
//                               name: '6SCP0153'
//                             },
//                             {
//                               id: 'P3_1485',
//                               name: '6SCP0154'
//                             },
//                             {
//                               id: 'P3_1486',
//                               name: '6SCP0155'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1560',
//                       name: 'SUPP_TORQUE_TESTER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1561',
//                           name: '6TQT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1562',
//                               name: '6TQT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1584',
//                       name: 'SUPP_DRYPACK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1585',
//                           name: '6DPK01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1586',
//                               name: '6DPK0101'
//                             },
//                             {
//                               id: 'P3_1587',
//                               name: '6DPK0102'
//                             },
//                             {
//                               id: 'P3_1588',
//                               name: '6DPK0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1681',
//                       name: 'SUPP_INLINE_COLOUR_CORRECTION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1682',
//                           name: '1ICC01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1683',
//                               name: '1ICC0102'
//                             },
//                             {
//                               id: 'P3_1684',
//                               name: '1ICC0106'
//                             },
//                             {
//                               id: 'P3_1685',
//                               name: '1ICC0104'
//                             },
//                             {
//                               id: 'P3_1686',
//                               name: '1ICC0107'
//                             },
//                             {
//                               id: 'P3_1687',
//                               name: '1ICC0108'
//                             },
//                             {
//                               id: 'P3_1688',
//                               name: '1ICC0103'
//                             },
//                             {
//                               id: 'P3_1689',
//                               name: '1ICC0105'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1690',
//                           name: '6ICC03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1691',
//                               name: '6ICC0301'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1703',
//                       name: 'SUPP_3RD_OPT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1704',
//                           name: '6AVI02_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1655',
//                               name: '6AVI0201'
//                             },
//                             {
//                               id: 'P3_1656',
//                               name: '6AVI0202'
//                             },
//                             {
//                               id: 'P3_1657',
//                               name: '6AVI0203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1712',
//                       name: 'SUPP_MAGAZINE_CLEANING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1713',
//                           name: '1MGC01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1080',
//                               name: '1MGC0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1722',
//                           name: '1MGC01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1565',
//                               name: '1MGC0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1714',
//                       name: 'SUPP_XRAY',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1715',
//                           name: '6XRY01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1716',
//                               name: '6XRY0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1724',
//                       name: 'SUPP_SORT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1725',
//                           name: '1SCC01_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1553',
//                               name: '1SCC0102'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P3_1589',
//                   name: 'UNUSED_EQUIPMENT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1566',
//                       name: 'PROBER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1567',
//                           name: '1PFF01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1568',
//                               name: '1PFF0106'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1590',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1252',
//                           name: '1DAT12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1253',
//                               name: '1DAT1210'
//                             },
//                             {
//                               id: 'P3_1254',
//                               name: '1DAT1211'
//                             },
//                             {
//                               id: 'P3_1255',
//                               name: '1DAT1212'
//                             },
//                             {
//                               id: 'P3_1257',
//                               name: '1DAT1202'
//                             },
//                             {
//                               id: 'P3_1259',
//                               name: '1DAT1203'
//                             },
//                             {
//                               id: 'P3_1260',
//                               name: '1DAT1206'
//                             },
//                             {
//                               id: 'P3_1261',
//                               name: '1DAT1207'
//                             },
//                             {
//                               id: 'P3_1264',
//                               name: '1DAT1208'
//                             },
//                             {
//                               id: 'P3_1265',
//                               name: '1DAT1209'
//                             },
//                             {
//                               id: 'P3_1266',
//                               name: '1DAT1201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1592',
//                       name: 'WIRE_BOND',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1291',
//                           name: '1WBD12_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1292',
//                               name: '1WBD1203'
//                             },
//                             {
//                               id: 'P3_1293',
//                               name: '1WBD1204'
//                             },
//                             {
//                               id: 'P3_1294',
//                               name: '1WBD1205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1633',
//                           name: '1WBD07_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1286',
//                               name: '1WBD0722'
//                             },
//                             {
//                               id: 'P3_1287',
//                               name: '1WBD0723'
//                             },
//                             {
//                               id: 'P3_1723',
//                               name: '1WBD0702'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1593',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1239',
//                           name: '1CAS03_E05',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1241',
//                               name: '1CAS0308'
//                             },
//                             {
//                               id: 'P3_1421',
//                               name: '1CAS0306'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1595',
//                       name: 'HOT_TESTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1596',
//                           name: '1SRT01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1235',
//                               name: '1SRT0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1616',
//                       name: 'LASER_MARK',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1234',
//                           name: '1SRT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1406',
//                               name: '1SRT0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1617',
//                           name: '2LPP02_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1405',
//                               name: '2LPP0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1645',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1548',
//                           name: '1OVN01_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1549',
//                               name: '1OVN0126'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1646',
//                           name: '1OVN15_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1692',
//                               name: '1OVN1511'
//                             },
//                             {
//                               id: 'P3_1693',
//                               name: '1OVN1503'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1652',
//                           name: '1OVN01_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1527',
//                               name: '1OVN0145'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1647',
//                       name: 'TEST',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1555',
//                           name: '1LUT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1557',
//                               name: '1LUT0102'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1648',
//                           name: '1LUT02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1558',
//                               name: '1LUT0201'
//                             },
//                             {
//                               id: 'P3_1559',
//                               name: '1LUT0202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1649',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1272',
//                           name: '1PLM05_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1273',
//                               name: '1PLM0501'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1665',
//                           name: '1PLM08_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1726',
//                               name: '1PLM0802'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1650',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1277',
//                           name: '1SAS01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1278',
//                               name: '1SAS0101'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1279',
//                           name: '1SIN08_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1280',
//                               name: '1SIN0801'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1641',
//                           name: '6STE01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1642',
//                               name: '6STE0101'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1651',
//                       name: 'SORTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1530',
//                           name: '1SCC02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1531',
//                               name: '1SCC0201'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1677',
//                           name: '1SFB02_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1149',
//                               name: '1SFB0211'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1678',
//                           name: '1SBB03_T02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1360',
//                               name: '1SBB0306'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1662',
//                       name: 'TEST_AVIT',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1663',
//                           name: '6AVT01_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1581',
//                               name: '6AVT0103'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1679',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1680',
//                           name: '1TBT05_T01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1369',
//                               name: '1TBT0503'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1705',
//                       name: 'SPRAY_COATING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1706',
//                           name: '1LSC01_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1156',
//                               name: '1LSC0106'
//                             },
//                             {
//                               id: 'P3_1165',
//                               name: '1LSC0104'
//                             },
//                             {
//                               id: 'P3_1166',
//                               name: '1LSC0105'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1707',
//                       name: 'LAYER_TRANSFER',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1708',
//                           name: '1LYT02_E02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1076',
//                               name: '1LYT0211'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 {
//                   id: 'P3_1643',
//                   name: 'R&D',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process',
//                   lockable: false,
//                   values: [
//                     {
//                       id: 'P3_1644',
//                       name: 'CASTING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1611',
//                           name: '1CAS04_E11',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1298',
//                               name: '1CAS0415'
//                             },
//                             {
//                               id: 'P3_1415',
//                               name: '1CAS0424'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1669',
//                           name: '6CAS23_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1670',
//                               name: '6CAS2304'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1694',
//                           name: '1CAS04_E04',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1302',
//                               name: '1CAS0413'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1695',
//                       name: 'DAM_DISPENSING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1696',
//                           name: '1CAS04_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1246',
//                               name: '1CAS0416'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1697',
//                       name: 'MOLDING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1698',
//                           name: '1CMD01_E03',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1084',
//                               name: '1CMD0113'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1699',
//                       name: 'SAWING',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1700',
//                           name: '1SAW03_E01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1676',
//                               name: '1SAW0324'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P3_1717',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'multiselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P3_1718',
//                           name: '1OVN14_F02',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1536',
//                               name: '1OVN1414'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P3_1719',
//                           name: '1OVN15_F01',
//                           type: 'object',
//                           selectionType: 'multiselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: false,
//                           values: [
//                             {
//                               id: 'P3_1524',
//                               name: '1OVN1519'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 }
//               ]
//             }
//           ]
//         }
//       ],
//       interval: [
//         {
//           id: 2,
//           groupTitle: 'Interval',
//           type: 'object',
//           selectionType: 'multiselect',
//           required: 'true',
//           attribute: 'Calendar',
//           values: [
//             {
//               id: 'FI1',
//               name: 'Daily',
//               type: 'date',
//               selectionType: 'singleselect',
//               required: false,
//               attribute: 'Date',
//               values: [
//                 {
//                   id: 'FI5',
//                   name: 'Today'
//                 },
//                 {
//                   id: 'FI6',
//                   name: 'Yesterday'
//                 },
//                 {
//                   id: 'FI7',
//                   name: 'Custom'
//                 }
//               ]
//             },
//             {
//               id: 'FI2',
//               name: 'Weekly',
//               type: 'object',
//               selectionType: 'singleselect',
//               required: false,
//               attribute: 'Week',
//               values: [
//                 {
//                   id: 'FI8',
//                   name: 'Current Week'
//                 },
//                 {
//                   id: 'FI9',
//                   name: 'Last Week'
//                 }
//               ]
//             },
//             {
//               id: 'FI3',
//               name: 'Monthly',
//               type: 'object',
//               selectionType: 'singleselect',
//               required: false,
//               attribute: 'Month',
//               values: [
//                 {
//                   id: 'FI10',
//                   name: 'Current Month'
//                 },
//                 {
//                   id: 'FI11',
//                   name: 'Last Month'
//                 }
//               ]
//             },
//             {
//               id: 'FI4',
//               name: 'Yearly',
//               type: 'object',
//               selectionType: 'singleselect',
//               required: false,
//               attribute: 'Year',
//               values: [
//                 {
//                   id: 'FI12',
//                   name: 'Current Year'
//                 },
//                 {
//                   id: 'FI13',
//                   name: 'Last Year'
//                 }
//               ]
//             }
//           ]
//         }
//       ],
//       options: [
//         {
//           id: 30,
//           groupTitle: 'Options',
//           type: 'object',
//           selectionType: 'singleselect',
//           required: 'true',
//           attribute: 'Trend',
//           values: [
//             {
//               id: 'FO5',
//               name: 'OEE Trend (Overall)'
//             },
//             {
//               id: 'FO6',
//               name: 'OEE Trend (Without NS)'
//             },
//             {
//               id: 'FO7',
//               name: 'OEE Trend QE100% (Overall)'
//             },
//             {
//               id: 'FO8',
//               name: 'OEE Trend QE100% (Without NS)'
//             }
//           ]
//         }
//       ]
//     },
//     resultType: 'iframe',
//     message: 'Success'
//   },
//   Trend: {
//     data: {
//       tree: [
//         {
//           id: 1,
//           groupTitle: 'Plant',
//           type: 'object',
//           selectionType: 'multiselect',
//           required: 'true',
//           attribute: 'Plant',
//           lockable: false,
//           values: [
//             {
//               id: 'P1',
//               name: 'PENmm',
//               type: 'object',
//               selectionType: 'multiselect',
//               required: 'false',
//               attribute: 'Module',
//               lockable: false,
//               values: [
//                 {
//                   id: 'P1_1026',
//                   name: 'MF2 - SMARTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Processss',
//                   values: [
//                     {
//                       id: 'P1_2886',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2923',
//                           name: '2AVI04_E01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3108',
//                               name: '2AVI0401'
//                             },
//                             {
//                               id: 'P1_3109',
//                               name: '2AVI0402'
//                             },
//                             {
//                               id: 'P1_3110',
//                               name: '2AVI0403'
//                             },
//                             {
//                               id: 'P1_3112',
//                               name: '2AVI0405'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2887',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5321',
//                           name: '2DAT18_F01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3136',
//                               name: '2DAT1804'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2891',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2933',
//                           name: '2OVN23_F01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3119',
//                               name: '2OVN2301'
//                             },
//                             {
//                               id: 'P1_3120',
//                               name: '2OVN2302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5323',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3113',
//                               name: '2OVN0125'
//                             },
//                             {
//                               id: 'P1_3114',
//                               name: '2OVN0135'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5427',
//                           name: '2OVN22_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3116',
//                               name: '2OVN2202'
//                             },
//                             {
//                               id: 'P1_3117',
//                               name: '2OVN2203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2892',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5324',
//                           name: '2PLM09_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3122',
//                               name: '2PLM0904'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5319',
//                       name: 'DEFLASHING',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5426',
//                           name: '2DEF02_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3131',
//                               name: '2DEF0203'
//                             },
//                             {
//                               id: 'P1_5689',
//                               name: '2DEF0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5326',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5327',
//                           name: '2SBB02_T02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2658',
//                               name: '2SBB0201'
//                             },
//                             {
//                               id: 'P1_2659',
//                               name: '2SBB0202'
//                             },
//                             {
//                               id: 'P1_2660',
//                               name: '2SBB0203'
//                             },
//                             {
//                               id: 'P1_2661',
//                               name: '2SBB0205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5328',
//                           name: '2SBB03_T03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2663',
//                               name: '2SBB0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5954',
//                           name: '2SBB07_T05',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5795',
//                               name: '2SBB0715'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5329',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5330',
//                           name: '2TBT02_T06',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2693',
//                               name: '2TBT0213'
//                             },
//                             {
//                               id: 'P1_2694',
//                               name: '2TBT0214'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5331',
//                           name: '2TBT03_T02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2697',
//                               name: '2TBT0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5332',
//                           name: '2TBT05_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2714',
//                               name: '2TBT0501'
//                             },
//                             {
//                               id: 'P1_2715',
//                               name: '2TBT0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5955',
//                           name: '2TBT08_T04',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5797',
//                               name: '2TBT0811'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5510',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5511',
//                           name: '2FRB07_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3279',
//                               name: '2FRB0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5591',
//                           name: '2FRB09_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5594',
//                               name: '2FRB0901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5514',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5515',
//                           name: '2OVN32_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3574',
//                               name: '2OVN3201'
//                             },
//                             {
//                               id: 'P1_3575',
//                               name: '2OVN3202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5846',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5847',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2443',
//                               name: '2VRR0226'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7720',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7721',
//                           name: '2MVI20_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_7622',
//                               name: '2MVI2003'
//                             },
//                             {
//                               id: 'P1_7623',
//                               name: '2MVI2004'
//                             },
//                             {
//                               id: 'P1_7624',
//                               name: '2MVI2005'
//                             },
//                             {
//                               id: 'P1_7625',
//                               name: '2MVI2006'
//                             },
//                             {
//                               id: 'P1_7626',
//                               name: '2MVI2007'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7722',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7723',
//                           name: '2AVI02_F02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3141',
//                               name: '2AVI0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8673',
//                       name: 'DB_WB_MOLD',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8674',
//                           name: 'DA_WB_S01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3103',
//                               name: '2TMD0301'
//                             },
//                             {
//                               id: 'P1_3125',
//                               name: '2WBD1911'
//                             },
//                             {
//                               id: 'P1_3126',
//                               name: '2WBD1912'
//                             },
//                             {
//                               id: 'P1_3138',
//                               name: '2DAT1901'
//                             },
//                             {
//                               id: 'P1_3139',
//                               name: '2DAT1902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8783',
//                           name: 'DA_WB_S05',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3100',
//                               name: '2WBD1102'
//                             },
//                             {
//                               id: 'P1_3107',
//                               name: '2TMD0305'
//                             },
//                             {
//                               id: 'P1_3137',
//                               name: '2DAT1805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8799',
//                           name: 'DA_WB_S02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3101',
//                               name: '2WBD1301'
//                             },
//                             {
//                               id: 'P1_3104',
//                               name: '2TMD0302'
//                             },
//                             {
//                               id: 'P1_3127',
//                               name: '2WBD1913'
//                             },
//                             {
//                               id: 'P1_3134',
//                               name: '2DAT1701'
//                             },
//                             {
//                               id: 'P1_3135',
//                               name: '2DAT1702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8800',
//                           name: 'DA_WB_S03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3102',
//                               name: '2WBD1302'
//                             },
//                             {
//                               id: 'P1_3105',
//                               name: '2TMD0303'
//                             },
//                             {
//                               id: 'P1_3423',
//                               name: '2DAT2027'
//                             },
//                             {
//                               id: 'P1_3553',
//                               name: '2WBD1903'
//                             },
//                             {
//                               id: 'P1_6631',
//                               name: '2DAT1806'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 // {
//                 //   id: 'P1_1045',
//                 //   name: 'MF1 - OSTAR',
//                 //   type: 'object',
//                 //   selectionType: 'multiselect',
//                 //   required: 'false',
//                 //   attribute: 'Process'
//                 // },
//                 {
//                   id: 'P1_1050',
//                   name: 'MF2 - POWERTOPLED LENS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1099',
//                   name: 'MF2 - APM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1110',
//                   name: 'MF2 - STS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1114',
//                   name: 'MF2 - SMT_POINTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1141',
//                   name: 'MF1 - OSLON NON-SSL_GBL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1574',
//                   name: 'MF2 - QCAST 4014',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1581',
//                   name: 'MF1 - OBF AUTO_OBF',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1678',
//                   name: 'MF2 - QPER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2179',
//                   name: 'MFX - COMMON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2231',
//                   name: 'MFX - GALVANIC',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2789',
//                   name: 'MF3 - MDP LASER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_3877',
//                   name: 'MF1 - OBF AUTO_FWL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4141',
//                   name: 'MF3 - COSA O',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4265',
//                   name: 'MF1 - OSLON NON-SSL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4478',
//                   name: 'MFX - LASERMARK',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4737',
//                   name: 'MF2 - OWLP',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5040',
//                   name: 'MF1 - FAM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5120',
//                   name: 'MF1 - OBF NON-AUTO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5339',
//                   name: 'MF2 - SMT_DRAGON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5371',
//                   name: 'MF1 - RADIAL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5458',
//                   name: 'MF1 - LASER_HPL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5806',
//                   name: 'MF3 - MINNEAPOLIS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5894',
//                   name: 'MF2 - SMT_TOPLED C241',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6047',
//                   name: 'MF1 - VALCO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6844',
//                   name: 'MF2 - SMT_SIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6877',
//                   name: 'MF2 - TOPLED 6 LEAD',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7294',
//                   name: 'MF3 - LIDAR',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7417',
//                   name: 'MF2 - TULIP/DAISY',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7724',
//                   name: 'MF2 - SMT_APT PLUS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7754',
//                   name: 'MF2 - SMT_MICROSIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7787',
//                   name: 'MF2 - SMT_MULTILED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7818',
//                   name: 'MF2 - SMT_SMTRLS LINE',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7837',
//                   name: 'MF2 - SMT_TOPLED RG',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7877',
//                   name: 'MF3 - CERALID OH',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7907',
//                   name: 'UNUSED_EQUIPMENT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8349',
//                   name: 'R&D',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8483',
//                   name: 'MF3 - DAIDALOS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8788',
//                   name: 'MF2 - VELVET',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8818',
//                   name: 'MF3 - OSLUX',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 }
//               ]
//             }
//           ]
//         }
//       ],
//       tree2: [
//         {
//           id: 1,
//           groupTitle: 'Plant',
//           type: 'object',
//           selectionType: 'multiselect',
//           required: 'true',
//           attribute: 'Plant',
//           lockable: false,
//           values: [
//             {
//               id: 'P1',
//               name: 'PENmm',
//               type: 'object',
//               selectionType: 'multiselect',
//               required: 'false',
//               attribute: 'Module',
//               lockable: false,
//               values: [
//                 {
//                   id: 'P1_1026',
//                   name: 'MF2 - SMARTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Processss',
//                   values: [
//                     {
//                       id: 'P1_2886',
//                       name: 'AVI',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2923',
//                           name: '2AVI04_E01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3108',
//                               name: '2AVI0401'
//                             },
//                             {
//                               id: 'P1_3109',
//                               name: '2AVI0402'
//                             },
//                             {
//                               id: 'P1_3110',
//                               name: '2AVI0403'
//                             },
//                             {
//                               id: 'P1_3112',
//                               name: '2AVI0405'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2887',
//                       name: 'DIE_ATTACH',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5321',
//                           name: '2DAT18_F01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3136',
//                               name: '2DAT1804'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2891',
//                       name: 'SUPP_OVEN',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_2933',
//                           name: '2OVN23_F01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3119',
//                               name: '2OVN2301'
//                             },
//                             {
//                               id: 'P1_3120',
//                               name: '2OVN2302'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5323',
//                           name: '2OVN01_E02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3113',
//                               name: '2OVN0125'
//                             },
//                             {
//                               id: 'P1_3114',
//                               name: '2OVN0135'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5427',
//                           name: '2OVN22_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3116',
//                               name: '2OVN2202'
//                             },
//                             {
//                               id: 'P1_3117',
//                               name: '2OVN2203'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_2892',
//                       name: 'PLASMA',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5324',
//                           name: '2PLM09_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3122',
//                               name: '2PLM0904'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5319',
//                       name: 'DEFLASHING',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5426',
//                           name: '2DEF02_F03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3131',
//                               name: '2DEF0203'
//                             },
//                             {
//                               id: 'P1_5689',
//                               name: '2DEF0204'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5326',
//                       name: 'SORT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5327',
//                           name: '2SBB02_T02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2658',
//                               name: '2SBB0201'
//                             },
//                             {
//                               id: 'P1_2659',
//                               name: '2SBB0202'
//                             },
//                             {
//                               id: 'P1_2660',
//                               name: '2SBB0203'
//                             },
//                             {
//                               id: 'P1_2661',
//                               name: '2SBB0205'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5328',
//                           name: '2SBB03_T03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2663',
//                               name: '2SBB0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5954',
//                           name: '2SBB07_T05',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5795',
//                               name: '2SBB0715'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5329',
//                       name: 'TAPE',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5330',
//                           name: '2TBT02_T06',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2693',
//                               name: '2TBT0213'
//                             },
//                             {
//                               id: 'P1_2694',
//                               name: '2TBT0214'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5331',
//                           name: '2TBT03_T02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2697',
//                               name: '2TBT0303'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5332',
//                           name: '2TBT05_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2714',
//                               name: '2TBT0501'
//                             },
//                             {
//                               id: 'P1_2715',
//                               name: '2TBT0502'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5955',
//                           name: '2TBT08_T04',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5797',
//                               name: '2TBT0811'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5510',
//                       name: 'SINGULATION',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5511',
//                           name: '2FRB07_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3279',
//                               name: '2FRB0702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_5591',
//                           name: '2FRB09_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_5594',
//                               name: '2FRB0901'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5514',
//                       name: 'BAKE',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5515',
//                           name: '2OVN32_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3574',
//                               name: '2OVN3201'
//                             },
//                             {
//                               id: 'P1_3575',
//                               name: '2OVN3202'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_5846',
//                       name: 'AVIT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_5847',
//                           name: '2VRR02_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_2443',
//                               name: '2VRR0226'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7720',
//                       name: '100%_VI',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7721',
//                           name: '2MVI20_T01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_7622',
//                               name: '2MVI2003'
//                             },
//                             {
//                               id: 'P1_7623',
//                               name: '2MVI2004'
//                             },
//                             {
//                               id: 'P1_7624',
//                               name: '2MVI2005'
//                             },
//                             {
//                               id: 'P1_7625',
//                               name: '2MVI2006'
//                             },
//                             {
//                               id: 'P1_7626',
//                               name: '2MVI2007'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_7722',
//                       name: '3RD_OPT',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_7723',
//                           name: '2AVI02_F02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3141',
//                               name: '2AVI0201'
//                             }
//                           ]
//                         }
//                       ]
//                     },
//                     {
//                       id: 'P1_8673',
//                       name: 'DB_WB_MOLD',
//                       type: 'object',
//                       selectionType: 'singleselect',
//                       required: 'false',
//                       attribute: 'Group',
//                       lockable: false,
//                       values: [
//                         {
//                           id: 'P1_8674',
//                           name: 'DA_WB_S01',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3103',
//                               name: '2TMD0301'
//                             },
//                             {
//                               id: 'P1_3125',
//                               name: '2WBD1911'
//                             },
//                             {
//                               id: 'P1_3126',
//                               name: '2WBD1912'
//                             },
//                             {
//                               id: 'P1_3138',
//                               name: '2DAT1901'
//                             },
//                             {
//                               id: 'P1_3139',
//                               name: '2DAT1902'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8783',
//                           name: 'DA_WB_S05',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3100',
//                               name: '2WBD1102'
//                             },
//                             {
//                               id: 'P1_3107',
//                               name: '2TMD0305'
//                             },
//                             {
//                               id: 'P1_3137',
//                               name: '2DAT1805'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8799',
//                           name: 'DA_WB_S02',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3101',
//                               name: '2WBD1301'
//                             },
//                             {
//                               id: 'P1_3104',
//                               name: '2TMD0302'
//                             },
//                             {
//                               id: 'P1_3127',
//                               name: '2WBD1913'
//                             },
//                             {
//                               id: 'P1_3134',
//                               name: '2DAT1701'
//                             },
//                             {
//                               id: 'P1_3135',
//                               name: '2DAT1702'
//                             }
//                           ]
//                         },
//                         {
//                           id: 'P1_8800',
//                           name: 'DA_WB_S03',
//                           type: 'object',
//                           selectionType: 'singleselect',
//                           required: 'false',
//                           attribute: 'Equipment',
//                           lockable: true,
//                           values: [
//                             {
//                               id: 'P1_3102',
//                               name: '2WBD1302'
//                             },
//                             {
//                               id: 'P1_3105',
//                               name: '2TMD0303'
//                             },
//                             {
//                               id: 'P1_3423',
//                               name: '2DAT2027'
//                             },
//                             {
//                               id: 'P1_3553',
//                               name: '2WBD1903'
//                             },
//                             {
//                               id: 'P1_6631',
//                               name: '2DAT1806'
//                             }
//                           ]
//                         }
//                       ]
//                     }
//                   ]
//                 },
//                 // {
//                 //   id: 'P1_1045',
//                 //   name: 'MF1 - OSTAR',
//                 //   type: 'object',
//                 //   selectionType: 'multiselect',
//                 //   required: 'false',
//                 //   attribute: 'Process'
//                 // },
//                 {
//                   id: 'P1_1050',
//                   name: 'MF2 - POWERTOPLED LENS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1099',
//                   name: 'MF2 - APM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1110',
//                   name: 'MF2 - STS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1114',
//                   name: 'MF2 - SMT_POINTLED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1141',
//                   name: 'MF1 - OSLON NON-SSL_GBL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1574',
//                   name: 'MF2 - QCAST 4014',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1581',
//                   name: 'MF1 - OBF AUTO_OBF',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_1678',
//                   name: 'MF2 - QPER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2179',
//                   name: 'MFX - COMMON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2231',
//                   name: 'MFX - GALVANIC',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_2789',
//                   name: 'MF3 - MDP LASER',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_3877',
//                   name: 'MF1 - OBF AUTO_FWL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4141',
//                   name: 'MF3 - COSA O',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4265',
//                   name: 'MF1 - OSLON NON-SSL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4478',
//                   name: 'MFX - LASERMARK',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_4737',
//                   name: 'MF2 - OWLP',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5040',
//                   name: 'MF1 - FAM',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5120',
//                   name: 'MF1 - OBF NON-AUTO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5339',
//                   name: 'MF2 - SMT_DRAGON',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5371',
//                   name: 'MF1 - RADIAL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5458',
//                   name: 'MF1 - LASER_HPL',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5806',
//                   name: 'MF3 - MINNEAPOLIS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_5894',
//                   name: 'MF2 - SMT_TOPLED C241',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6047',
//                   name: 'MF1 - VALCO',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6844',
//                   name: 'MF2 - SMT_SIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_6877',
//                   name: 'MF2 - TOPLED 6 LEAD',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7294',
//                   name: 'MF3 - LIDAR',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7417',
//                   name: 'MF2 - TULIP/DAISY',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7724',
//                   name: 'MF2 - SMT_APT PLUS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7754',
//                   name: 'MF2 - SMT_MICROSIDELED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7787',
//                   name: 'MF2 - SMT_MULTILED',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7818',
//                   name: 'MF2 - SMT_SMTRLS LINE',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7837',
//                   name: 'MF2 - SMT_TOPLED RG',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7877',
//                   name: 'MF3 - CERALID OH',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_7907',
//                   name: 'UNUSED_EQUIPMENT',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8349',
//                   name: 'R&D',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8483',
//                   name: 'MF3 - DAIDALOS',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8788',
//                   name: 'MF2 - VELVET',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 },
//                 {
//                   id: 'P1_8818',
//                   name: 'MF3 - OSLUX',
//                   type: 'object',
//                   selectionType: 'multiselect',
//                   required: 'false',
//                   attribute: 'Process'
//                 }
//               ]
//             }
//           ]
//         }
//       ]
//     },
//     resultType: 'iframe',
//     message: 'Success'
//   }
// };
