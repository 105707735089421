/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAGE_TYPES } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { myAnalyticsCreators } from '../../../views/MyAnalytics/store';
import { generateSelectedFilter } from '../../ResultView/Iframe/generateSelectedFilter';
import AnalyticsForm from './AnalyticsForm';

const SaveAnalytics = ({ shouldSaveCharts = true, onCancel }) => {
  const { menuIdofFilter, cumulativeTags, selectedParent, filterData, leafLevels } = useSelector(
    (state) => state.filterData
  );
  const KPVShortlist = useSelector((state) => state.filterResultData.KPVShortlist?.[menuIdofFilter]) || [];
  const { metaDetailsInShortlist, enabledPanes } = useSelector((state) => state.filterResultData);
  const { userData } = useSelector((state) => state.loginData);
  const { panesShortHandInfo, xMinDomain, xMaxDomain, xDomainDuration, latestAvailable } = useSelector(
    (state) => state.KPVCharts
  );
  const myAnalyticsPaths = useSelector((state) => state.home?.[PAGE_TYPES.MY_ANAYLTICS]);
  const dispatch = useDispatch();
  const history = useHistory();
  const dashBoardPostInit = {
    loading: false,
    successMsg: '',
    errMsg: ''
  };
  const formInit = {
    Title: '',
    Category: '',
    Shared: true,
    Searchtags: '',
    UserID: userData.email || userData.mail
    // UserID: 'lino.thomas@vistrian.com'
  };

  // useEffect(() => {
  //   dispatch(myAnalyticsCreators.setAnalyticsDetails({ form: formInit, isInit: true }));
  // }, []);

  const [dashboardPost, setDashboardPost] = useState(dashBoardPostInit);
  const handleSave = async (form) => {
    try {
      setDashboardPost({ ...dashboardPost, loading: true });

      const url = apiEndPoints.CallSaveAnalytics;
      let requestPayload = {
        ...form,
        menuid: menuIdofFilter || form.menuid,
        tab: selectedParent || form.tab,
        leafLevels: leafLevels || form.leafLevels,
        kpvDetails: {
          metaDetailsInShortlist: { enabledPanes, ...metaDetailsInShortlist },
          KPVShortlist
        },
        IsChartable: false
      };
      if (menuIdofFilter) {
        const filterPayload = generateSelectedFilter(cumulativeTags, filterData, true);
        requestPayload.filterData = { ...filterPayload };
      }
      if (shouldSaveCharts && !isEmpty(panesShortHandInfo)) {
        const chartProps = {
          chartDetails: panesShortHandInfo,
          IsChartable: true,
          xMinDomain,
          xMaxDomain,
          xDomainDuration,
          latestAvailable
        };
        requestPayload = { ...requestPayload, ...chartProps };
      }
      const response = await WebService.post(url, requestPayload);
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        setDashboardPost({ ...dashboardPost, loading: false, successMsg: data });
        onCancel();

        if (!isEmpty(myAnalyticsPaths)) {
          const firstKey = Object.keys(myAnalyticsPaths)[0];
          history.push(myAnalyticsPaths[firstKey]);
        }
      } else {
        throw response;
      }
    } catch (error) {
      console.log('save analytics error', error);
      const payload = sagaCatchBlockHandling(error);
      setDashboardPost({ ...dashboardPost, loading: false, errMsg: payload.error });
    }
  };

  return (
    <AnalyticsForm
      onCancel={onCancel}
      handleSave={handleSave}
      error={dashboardPost.errMsg}
      loading={dashboardPost.loading}
      form={formInit}
    />
  );
};

export default SaveAnalytics;
