/* eslint-disable vars-on-top */
/* eslint-disable max-len */
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getFormatedgraphTS } from '../../../shared/utility/moment';
import { MAX_CHART_SHOW } from '../../ResultView/config';
// import { P } from '../../../views/MyDashboards/SaveDashboardModal.style';

// pass
// xMinDomain, xMaxDomain as null when respective start, end times needs to be applied
// isUpdatedDomain is for applying common domain
export const getPlotDataPayload = (...spread) => {
  const [panesShortHandInfo, xMinDomain, xMaxDomain, isUdatedDomain] = spread;
  let startTimeInit;
  let endTimeInit;

  // converting multipane to single pane payload.
  return panesShortHandInfo.reduce((acc, pane) => {
    const {
      kpv,
      kpvDetails,
      stateDetails,
      machinesSelected,
      category,
      chartType,
      chartTypeCategory,
      singlePane,
      StartTime: StartTimeProp,
      EndTime: EndTimeProp,
      spcDetails,
      // added for widgets
      spc,
      xMinDomainProp,
      xMaxDomainProp,
      // xKPVs,
      // yKPVs,
      xType,
      yType
    } = pane;

    let payload = [];
    const StartTime = StartTimeProp || xMinDomainProp;
    const EndTime = EndTimeProp || xMaxDomainProp;
    // transition and all other chart types with only key change
    if (chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART || chartType === CHART_TYPES.TRANSITION_CHART) {
      let machinePayload = [];
      // stateDetails is used after state persist
      (kpv || stateDetails || machinesSelected).forEach((curr) => {
        // if (!machinePayload.some((e) => e.id === curr.macId)) {
        machinePayload = [
          ...machinePayload,
          {
            // id is used if on refresh (persisted shorthand info state)
            ...curr,
            id: curr.macId || curr.id,
            // data not defined
            start: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || curr.StartTime || StartTime : StartTime),
            end: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || curr.EndTime || EndTime : EndTime)
            // start: getFormatedgraphTS('2022-02-17 9:00:00.000'),
            // end: getFormatedgraphTS('2022-02-17 11:10:00.000')
          }
        ];
        // }
      });

      const commonObj = {
        RequestId: 'anything',
        chartType,
        category,
        chartTypeCategory,
        StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || startTimeInit : StartTime),
        EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || endTimeInit : EndTime)
      };

      if (!singlePane && singlePane !== undefined) {
        const addPayload = machinePayload.slice(0, MAX_CHART_SHOW).map((machine) => ({
          ...commonObj,
          stateDetails: [machine]
          // start: getFormatedgraphTS(xMinDomain),
          // end: getFormatedgraphTS(xMaxDomain)
        }));
        payload = [...payload, ...addPayload];
      } else {
        payload = [
          {
            ...commonObj,
            stateDetails: machinePayload.slice(0, MAX_CHART_SHOW),
            singlePane
            // start: getFormatedgraphTS(xMinDomain),
            // end: getFormatedgraphTS(xMaxDomain)
          }
        ];
      }
    } else if (chartTypeCategory === CHART_TYPE_CATEGORY.LABEL || chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART) {
      const commonObj = {
        RequestId: 'anything',
        chartType,
        chartTypeCategory,
        category,
        StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || startTimeInit : StartTime),
        EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || endTimeInit : EndTime)
      };

      const xKPVsPayload = xType.map((e) => {
        if (e.StartTime) {
          startTimeInit = e.StartTime;
          endTimeInit = e.EndTime;
        }
        return {
          ...e,
          KPVID: e.kpvId,
          StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || e.StartTime || startTimeInit : StartTime),
          EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || e.EndTime || endTimeInit : EndTime)
        };
      });
      const yKPVsPayload = (yType || []).map((e) => {
        if (e.StartTime) {
          startTimeInit = e.StartTime;
          endTimeInit = e.EndTime;
        }
        return {
          ...e,
          KPVID: e.kpvId,
          StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || e.StartTime || startTimeInit : StartTime),
          EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || e.EndTime || endTimeInit : EndTime)
        };
      });

      // TODO
      // converting multi pane to invidual single panes
      // if (!singlePane && singlePane !== undefined) {
      //   const addPayload = kpvDetailsPayload
      //     .slice(0, MAX_CHART_SHOW)
      //     .map((kpv) => ({ ...commonObj, [detailsOf]: [kpv] }));
      //   payload = [...payload, ...addPayload];
      // } else {
      //   payload = [
      //     ...payload,
      //     {
      //       ...commonObj,
      //       // kpvDetails is used after state persist
      //       [detailsOf]: kpvDetailsPayload.slice(0, MAX_CHART_SHOW),
      //       singlePane
      //     }
      //   ];
      // }
      payload = [
        ...payload,
        {
          ...commonObj,
          // kpvDetails is used after state persist
          xKPVs: xKPVsPayload,
          yKPVs: yKPVsPayload,
          singlePane
        }
      ];
    } else {
      const commonObj = {
        RequestId: 'anything',
        chartType,
        chartTypeCategory,
        category,
        StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || startTimeInit : StartTime),
        EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || endTimeInit : EndTime)
      };

      let kpvs = kpv || kpvDetails || machinesSelected;
      // TODO: SPC
      if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC && !isEmpty(spcDetails || spc)) {
        kpvs = spcDetails || spc;
      }
      const kpvDetailsPayload = kpvs.map((e) => {
        if (e.StartTime) {
          startTimeInit = e.StartTime;
          endTimeInit = e.EndTime;
        }
        return {
          ...e,
          KPVID: e.kpvId,
          StartTime: getFormatedgraphTS(!isUdatedDomain ? xMinDomain || e.StartTime || startTimeInit : StartTime),
          EndTime: getFormatedgraphTS(!isUdatedDomain ? xMaxDomain || e.EndTime || endTimeInit : EndTime)
        };
      });

      let detailsOf = 'kpvDetails';

      if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
        detailsOf = 'spcDetails';
      }

      // converting multi pane to invidual single panes
      if (!singlePane && singlePane !== undefined) {
        const addPayload = kpvDetailsPayload
          .slice(0, MAX_CHART_SHOW)
          .map((kpv) => ({ ...commonObj, [detailsOf]: [kpv] }));
        payload = [...payload, ...addPayload];
      } else {
        payload = [
          ...payload,
          {
            ...commonObj,
            // kpvDetails is used after state persist
            [detailsOf]: kpvDetailsPayload.slice(0, MAX_CHART_SHOW),
            singlePane
          }
        ];
      }
    }
    // console.log('aaa', payload);

    return [...acc, ...payload];
    // return [...acc, ...getPayload(pane, rest)];
  }, []);
};
