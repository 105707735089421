/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchUserAndLocation } from '../Sidenav/FetchStatics';
import { commonRoutes } from '../../routes/route';

const Alarams = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData } = useSelector((state) => state.loginData);

  useEffect(() => {
    const name = commonRoutes.find((ele) => ele.path == location.pathname);
    fetchUserAndLocation(location.pathname, userData, { pageType: name.name });
  }, []);

  return <div>Alarams</div>;
};

export default Alarams;
