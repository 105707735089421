/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import {
  Chart,
  CommonSeriesSettings,
  Legend,
  SeriesTemplate,
  Animation,
  ArgumentAxis,
  Tick,
  Title,
  Label,
  ValueAxis,
  Grid,
  Series,
  Point,
  Tooltip,
  ConstantLine,
  Crosshair,
  HorizontalLine,
  Font
} from 'devextreme-react/chart';
import { colors, fontFamily, maxCharactersAloowed, placeHolderSize } from '../../../../constants';
import useViewLabels from '../hooks/useViewLabels';
import useContainerWidth from '../hooks/useContainerWidth';
import { useWindowSize } from '../../../../shared/hooks/useWindowResize';
import { Creators as kpvChartingCreators } from '../../Store/action';
import { chartConfig } from '../chartConfig';
import { getNearestTSPoint } from '../../utils/getNearestPoints';
import { getNearestTransitonPointByPosition } from '../../utils/getNearestPointByPosition';
import useMeasure from '../../../../shared/hooks/useMeasure';
import { isEmpty } from '../../../../shared/utility/isEmpty';

const TransitionChart = (props) => {
  const {
    // configData
    themeMode,

    // KPVCharts reducer
    xMinDomain,
    xMaxDomain,
    xMinDomainFullScreen,
    xMaxDomainFullScreen,
    paneFullScreen,
    compressedView,
    colorCodes,
    // hoveredXValue,
    panesShortHandInfo,
    comparisionMode,
    isAligned,

    // general
    setHoveredPosState,
    isLastPane = false,
    setHoveredState,
    hoveredState,
    paddingLeft,
    labels,
    xPos,
    resize,
    // fillLayout,
    ...paneData
  } = props;

  const {
    index,
    showDetails,
    xMinDomainProp,
    xMaxDomainProp,
    rawPlotData,
    // labels,
    id,
    compStartTime,
    StartTime,
    EndTime
  } = paneData;
  const { data: plotData, meta } = rawPlotData || {};
  const [viewXLabels, setViewXLabels] = useState(true);
  // width calc (old method)
  // const showSideNav = useSelector((state) => state.home.showSideNav);
  // const [containerWidth, setContainerWidth] = useState(0);
  // const [width] = useWindowSize();
  // useContainerWidth(index, setContainerWidth, showSideNav, width, showDetails?.legends, paddingLeft, resize?.w);
  const [ref, { width: containerWidth }] = useMeasure();
  const dispatch = useDispatch();
  useViewLabels(setViewXLabels, compressedView);
  const filterFilesSelected = useSelector((state) => state.KPVCharts.filterFilesSelected);

  const [maxPlotChar, setMaxPlotChar] = useState(0);
  const panesData = useSelector((state) => state.KPVCharts.panesData);

  const chartStyles = {
    height: '100%',
    padding: '0px 0px 10px 10px',
    width: '100%',
    minHeight: '20rem',
    position: 'relative'
  };

  const chartRef = useRef();

  // const chart = chartRef.current?.instance;

  const getPointInfo = (pointInfo) => {
    const {
      rangeValue1,
      point: { vx, x, y }
    } = pointInfo;
    const data = getNearestTSPoint(plotData, rangeValue1);
    setHoveredState({ data, x: pointInfo.rangeValue1, left: x + paddingLeft, orgX: x });
    if (compressedView) {
      // console.log('pointInfo', pointInfo);
      dispatch(kpvChartingCreators.setHoveredXValue({ argument: rangeValue1, x: x + paddingLeft }));
    }
    return { text: '' };
    // return { text: JSON.stringify(l[l.length - 1]) };
  };
  useEffect(() => {
    const chart = chartRef.current?.instance;
    try {
      // get the nearest points by postion only in aligned condition
      if (xPos && isAligned) {
        const e = getNearestTransitonPointByPosition(chart, xPos, plotData);
        setHoveredPosState(e);
      }
    } catch (e) {
      console.log('error in finding nearest point based on position', e);
    }
  }, [xPos, isAligned]);

  const customizeSeries = (seriesName) => ({ color: labels?.[seriesName]?.color });

  const getLabel = (val) => {
    if (val?.valueText?.length >= maxCharactersAloowed) {
      return `${val?.valueText?.substring(0, maxCharactersAloowed)}...`;
    }
    return val?.valueText;
  };

  const getYAxisLabel = () => (
    <Label
      // position={compressedView ? 'inside' : 'outside'}
      visible={showDetails ? showDetails['y-axis'] : true}
      font={{
        family: fontFamily.circularBook,
        size: chartConfig.labelFontSize,
        // TODO: incorrect theme handling
        color: colors[themeMode].contrast.primary
      }}
      // overlappingBehavior='rotate'
      // rotationAngle={30}
      // rotationAngle={270}
      displayMode='rotate'
      // customizeText={(e) => `...${e.valueText.substring(e.valueText.length - 5)}`}
      customizeHint={(e) => e.valueText}
      valueMarginsEnabled={false}
      adjustOnZoom={false}
      endOnTick={false}
      customizeText={(val) =>
        getLabel(val)}
    />
  );

  const setXaxisVisibility = () => {
    // find the label dom element at making it display none
    // instead of making devextreme handle the visibility
    const currentPaneNo = panesShortHandInfo.findIndex((paneInfo) => paneInfo.id === id);
    // console.log('x-axis', viewXLabels, showDetails['x-axis'], id, currentPaneNo);
    // 'dxc-arg-elements' is the className for the x-labels
    const axisElements = document.getElementsByClassName('dxc-val-elements');
    const xAxisEle = axisElements.length > 1 ? axisElements[currentPaneNo] : axisElements[0];
    if (xAxisEle) {
      let visibleCondition = false;
      if (compressedView) {
        visibleCondition = viewXLabels || isLastPane;
      } else {
        visibleCondition = showDetails ? showDetails['x-axis'] : true;
      }

      if (!visibleCondition) {
        xAxisEle.style.visibility = 'hidden';
      } else {
        xAxisEle.style.visibility = 'visible';
      }
    }
  };

  const isMounted = useRef();
  useEffect(() => {
    let timer;
    if (!isMounted.current) {
      isMounted.current = true;
      timer = setTimeout(() => {
        setXaxisVisibility();
      }, 1000);
    } else {
      setXaxisVisibility();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showDetails['x-axis'], compressedView, viewXLabels, panesShortHandInfo]);

  const getArgumentAxisRange = () => {
    // if (comparisionMode && StartTime) {
    //   return [StartTime, EndTime];
    // }
    if (comparisionMode && StartTime) {
      if (!isEmpty(filterFilesSelected)) {
        return [xMinDomain, xMaxDomain];
      } return [StartTime, EndTime];
    }
    if (paneFullScreen) {
      return [xMinDomainFullScreen, xMaxDomainFullScreen];
    }
    return [new Date(xMinDomainProp || xMinDomain), new Date(xMaxDomainProp || xMaxDomain)];
  };

  function onPointClick({ target: point }) {
    const { data } = point;
    dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: data.start }));
  }
  // const constantLineText = comparisionMode ? compStartTime : hoveredXValue;
  const constantLineText = comparisionMode ? compStartTime : '';
  // console.log('containerWidth', containerWidth);

  const findMaxLength = (plotData) => {
    let max = 0;
    for (let i = 0; i < plotData?.length; i += 1) {
      const currLength = (plotData[i]?.SensorData)?.toString()?.length;
      if (currLength + 1 > max) {
        max = currLength + 1;
      }
    }
    return max;
  };

  useEffect(() => {
    const maxArr = [];
    for (let i = 0; i < panesData?.length; i += 1) {
      maxArr.push(findMaxLength(panesData[i]?.rawPlotData?.data));
    }
    const max = Math.max(...maxArr);
    const maxAllocatedLen = max <= maxCharactersAloowed ? max : maxCharactersAloowed;
    setMaxPlotChar(maxAllocatedLen);
  }, [panesData]);

  return (
    <div style={chartStyles} ref={ref}>
      <Chart
        id={`chart${index}`}
        dataSource={plotData}
        // style={{ height: '100%' }}
        // style={chartStyles}
        style={{ height: '100%' }}
        size={{ width: containerWidth }}
        barGroupPadding={0.2}
        rotated={true}
        ref={chartRef}
        onPointClick={onPointClick}
      >
        <Crosshair enabled={true} color='black' width={3} dashStyle='solid'>
          <HorizontalLine visible={false} />
          <Label visible={true} backgroundColor='black'>
            <Font color='#fff' size={12} />
          </Label>
        </Crosshair>
        {/* y axis */}
        <ArgumentAxis
          // categories={['CPA004', 'CPA003', 'CPA001']}
          color={colors[themeMode].contrast.lightSecondary}
        >
          <Grid visible={true} width={1} color={colors[themeMode].contrast.lightSecondary} />
          {getYAxisLabel()}
        </ArgumentAxis>
        {/* x axis */}

        <ValueAxis
          // autoBreaksEnabled={true}
          color={colors[themeMode].contrast.lightSecondary}
          visualRange={getArgumentAxisRange()}
          valueMarginsEnabled={false}
          endOnTick={false}
          placeholderSize={placeHolderSize * maxPlotChar}
        >
          <Grid visible={true} width={1} color={colors[themeMode].contrast.lightSecondary} />
          <Label
            valueMarginsEnabled={false}
            adjustOnZoom={false}
            endOnTick={false}
            font={{ family: fontFamily.circularBook, size: 22, color: colors[themeMode].contrast.primary }}
            alignment='left'
            customizeText={(tick) => moment(tick.value).format('DD/MM/YYYY HH:mm:ss:sss')}
          />
          {comparisionMode && isAligned && (
            <ConstantLine
              width={1}
              value={compStartTime}
              color={colors[themeMode].contrast.lightSecondary}
              dashStyle='solid'
            >
              <Label visible={false} />
            </ConstantLine>
          )}
          {comparisionMode && compStartTime && (
            <ConstantLine
              width={3}
              value={constantLineText}
              color={colors[themeMode].contrast.lightSecondary}
              dashStyle='solid'
            >
              <Label
                horizontalAlignment='right'
                verticalAlignment='bottom'
                position='inside'
                font={{ family: fontFamily.circularBook, size: 22, color: colors[themeMode].contrast.primary }}
                visible={true}
                text={`${moment(constantLineText).format('DD/MM/YYYY')}\n${moment(constantLineText).format(
                  'HH:mm:ss:sss'
                )}`}
              />
            </ConstantLine>
          )}
        </ValueAxis>
        <CommonSeriesSettings
          type='rangeBar'
          argumentField='state'
          rangeValue1Field='start'
          rangeValue2Field='end'
          barOverlapGroup='state'
        >
          <Grid visible={true} width={1} />
        </CommonSeriesSettings>
        <Tooltip enabled={true} customizeTooltip={getPointInfo} />
        <Legend visible={false} verticalAlignment='bottom' horizontalAlignment='center' />
        {/* {console.log('seriesRef', seriesRef)} */}
        <SeriesTemplate nameField='label' customizeSeries={customizeSeries} />
        <Animation enabled={false} />
      </Chart>
    </div>
  );
};

const mapStateToProps = (state) => ({
  xMinDomain: state.KPVCharts.xMinDomain,
  xMaxDomain: state.KPVCharts.xMaxDomain,
  xMinDomainFullScreen: state.KPVCharts.xMinDomainFullScreen,
  xMaxDomainFullScreen: state.KPVCharts.xMaxDomainFullScreen,
  paneFullScreen: state.KPVCharts.paneFullScreen,
  compressedView: state.KPVCharts.compressedView,
  colorCodes: state.KPVCharts.colorCodes,
  // hoveredXValue: state.KPVCharts.hoveredXValue,
  panesShortHandInfo: state.KPVCharts.panesShortHandInfo,
  comparisionMode: state.KPVCharts.comparisionMode,
  isAligned: state.KPVCharts.isAligned,
  themeMode: state.configData.themeMode
});

export default connect(mapStateToProps)(TransitionChart);
