/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Nav } from './Otp.style';
import { Span } from '../../../globalStyles';

const Countdown = ({ handleResend }) => {
  const initTimer = 10;
  const [timer, setTimer] = useState(initTimer);
  useEffect(() => {
    const x = setInterval(() => {
      setTimer((t) => {
        if (t > 0) {
          return t - 1;
        }
        return t;
      });
    }, [1000]);
    return () => x;
  }, []);
  const handleResendLocal = () => {
    setTimer(initTimer);
    handleResend();
  };
  return (
    <div>
      <Span>{`Resend Authorization code in  ${Math.floor(timer / 60)} min ${timer % 60} sec`} </Span>
      <Nav onClick={handleResendLocal} className={timer < 1 ? '' : 'disable'}>
        Resend
      </Nav>
    </div>
  );
};

export default Countdown;
