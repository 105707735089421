/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DateRange from '../DateRange';
import DefinedRange from '../DefinedRange';
import { findNextRangeIndex, generateStyles } from '../../utils';
import coreStyles from '../../styles';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedRange: [findNextRangeIndex(props.ranges), 0]
    };
    this.styles = generateStyles([coreStyles, props.classNames]);
  }

  componentDidUpdate() {
    if (this.props.clearSelection) {
      // focus to start on clear
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        focusedRange: [0, 0]
      });
    }
  }

  render() {
    const { focusedRange } = this.state;
    const { dateOfWeekStart } = this.props;
    // console.log('focusedRange', focusedRange);
    return (
      <div className={classnames(this.styles.dateRangePickerWrapper, this.props.className)}>
        {!this.props.isWeekSelection && (
          <DefinedRange
            focusedRange={focusedRange}
            onPreviewChange={(value) =>
              this.dateRange.updatePreview(
                value ? this.dateRange.calcNewSelection(value, typeof value === 'string') : null
              )
            }
            {...this.props}
            range={this.props.ranges[focusedRange[0]]}
            className={undefined}
          />
        )}
        <DateRange
          onRangeFocusChange={(focusedRange) => this.setState({ focusedRange })}
          focusedRange={focusedRange}
          {...this.props}
          ref={(t) => (this.dateRange = t)}
          className={undefined}
          dateOfWeekStart={dateOfWeekStart}
        />
      </div>
    );
  }
}

DateRangePicker.defaultProps = {};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  className: PropTypes.string,
  dateOfWeekStart: PropTypes.string
};

export default DateRangePicker;
