export const concatStringWithUnderscore = (str) => {
  // Create a regular expression to match all non-alphabetic characters.
  const regex = /[^a-zA-Z]/g;

  // Replace all non-alphabetic characters with underscores.
  const newStr = str.replace(regex, '_');

  // Return the new string.
  return newStr;
};
