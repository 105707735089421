/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
// import { kpvData } from '../../../constants/sampleData';
import styled from 'styled-components';
import moment from 'moment';
import { AddToCart, CancelBlack, RemoveFromCart, searchBlack, rightArrow } from '../../../assets/icons';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  MachineHeaderText,
  Row,
  TableContainer,
  Cell,
  CancelIcon,
  SearchIcon,
  SearchInput,
  ActionIcon
} from './TabsTable.style';
import Loader from '../../Loader/Loader';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Pagination from '../Pagination/Pagination.view';
import { Table, Tr, Td, Th } from '../Table/SpacedRows.styles';
import { FlexContainer } from '../../../globalStyles';
import Toast from '../Toast';
import DropDownModal from '../DropDownModal/DropDownModal_V1';
import { fontSize } from '../../../constants';
import PopUpModal from '../PopUpModal/Modal.view';
import DateRangePicker from '../../RangeSelectors/DateRangePicker/DateRangePicker';
import ButtonComp from '../Button/Button.view';
import { isEmpty } from '../../../shared/utility/isEmpty';
import Groupby from '../GroupBy/GroupBy';
import TableContent from './TableContent';
import { curryIsOptionsVisible, OPTIONS_TYPES } from './utility';
import { ReactComponent as CheckMark } from '../../../assets/icons/KPV/checkmark.svg';
import CheckBox from '../../CheckBox/CheckBox';
import SortBy from '../OptionsModal/OptionsModal';
import { compare } from '../../../shared/utility/objComparision';
import PaginationWithPage from '../Pagination/PaginationWithPageNumber';
// import { assetMetaCol } from '../../../views/WorkOrders/data';

const TabTable = styled(Table)`
  background-color: ${({ theme }) => theme.core.secondary};
  border-radius: 10px;
  padding: 1rem;
`;

const TabsTable = ({
  // activeTab = 'a',
  // setActiveTab,
  // tabs = [
  //   { id: 1, name: 'a' },
  //   { id: 2, name: 'b' },
  //   { id: 3, name: 'c' }
  // ],
  // removeTab,
  // handleTabSelect,
  metaDetails,
  dataDetails,
  pageSize,
  handlePageChange,
  assetShortlist = [],
  handleShortList,
  assetListLoading,
  onItemSelect = () => {},
  showPagiation = true,
  saveSearchQueries,
  savedSearchQueries,
  showShortlistSelection = true,
  hasObjectContent = false,
  resetSearchQueries = () => {},
  error,
  rowOptions,
  handleRowOptionsItemClick: handleRowOptionsItemClickHandler,
  id,
  styleType, // type of style needed to be applied to the table
  noItemsFoundMessage,
  showRowOptionAtStart, // show row options at start
  shortListSelectionDetails = {}, // details of where to show the shortlist option // isCheckboxTypeSelection, isSelectionAtFirst
  isComparision = false,
  handleSortBy = () => {}, // sortby handler
  setActiveRow,
  activeRow,
  hasNestedRowOptions,
  isActionReq = true,
  rowOptionsWithCondition,
  selectAllVisible = true,
  fullData = [],
  handlePageChangeLocal = () => {},
  localFilteredData,
  forcedPushtoFirstPage = false,
  setForcedPushToFirstPage
}) => {
  const { isCheckboxTypeSelection, isSelectionAtFirst: isSelectionAtFirstProp, } = shortListSelectionDetails;
  const isSelectionAtFirst = showShortlistSelection
    ? isSelectionAtFirstProp || isSelectionAtFirstProp === undefined
    : false;
  const isSelectionAtLast = showShortlistSelection ? isSelectionAtFirstProp === false : false;
  // const metaDetails = assetMetaCol;
  // console.log('metaDetails', metaDetails);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const [showSearchInp, setShowSearchInp] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [searchQuery, setSearchQuery] = useState(savedSearchQueries || {});
  const [filteredResultData, setFilteredResultData] = useState(null);
  const [groupByList, setGroupByList] = useState([]);
  const [groupBy, setGroupBy] = useState();
  const [activeSortByCol, setActiveSortByCol] = useState();
  const searchRef = useRef();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [tempFilteredKpvGroupDetStored, setTempFilteredKpvGroupDetStored] = useState();
  // const metaColumns = metaDetails?.columns || metaDetails?.olumns;

  // console.log('savedSearchQueries', savedSearchQueries);
  const isLocalSearch = (!savedSearchQueries && true) || metaDetails?.isLocalSearch === 'true';
  const getDataDetailsByGroup = (dataDetails, groupBy) => {
    try {
      if (!groupBy) {
        return {
          noGrouping: dataDetails
        };
      }
      if (!dataDetails) {
        return {
          [groupBy]: []
        };
      }
      return dataDetails.reduce((result, dataItem) => {
        const groupValue = dataItem[groupBy];
        if (!result[groupValue]) {
          return { ...result, [groupValue]: [dataItem] };
        }
        result[groupValue].push(dataItem);
        return result;
      }, {});
    } catch (e) {
      console.log('error in getDataDetailsByGroup ', e);
    }
    return {};
  };
  useEffect(() => {
    // set groupBylist & groupBy
    if (!isEmpty(metaDetails?.grouping)) {
      const grouping = metaDetails.grouping?.map((groupName) => ({
        key: groupName,
        title: metaDetails.columns?.find((col) => col.key === groupName)?.title
      }));
      setGroupByList(grouping);
      const defaultGroup = grouping[0].key;
      setGroupBy(defaultGroup);
    }
    // compute inputs
    if (!isLocalSearch) {
      const showFeildInputs = Object.keys(savedSearchQueries).reduce(
        (acc, key) => ({ ...acc, [key]: savedSearchQueries[key] && true }),
        {}
      );
      setShowSearchInp(showFeildInputs);
    }
  }, []);

  // format the normal/fitered data based on groups
  const [rowsDetails, setRowsDetails] = useState({});

  useEffect(() => {
    const rowsDetailsArrRes =
      Object.keys(showSearchInp).some((e) => showSearchInp[e]) && filteredResultData ? filteredResultData : dataDetails;
    const rowsDetailsRes = rowsDetailsArrRes?.length ? getDataDetailsByGroup(rowsDetailsArrRes, groupBy) : {};
    setRowsDetails(rowsDetailsRes);
  }, [filteredResultData, dataDetails, groupBy]);

  // useEffect(() => {
  //   // searchable feilds computation
  //   const metaColumns = metaDetails?.columns || metaDetails?.fields;
  //   const searchableFields = metaColumns?.filter((field) => field.searchable);
  //   const tempShowSearchInp = {};
  //   searchableFields?.forEach((e) => {
  //     console.log('saa', e.key, savedSearchQueries[e.key]?.length);
  //     tempShowSearchInp[e.key] = savedSearchQueries[e.key]?.length > 0;
  //   });
  //   setShowSearchInp({ ...tempShowSearchInp });
  // }, []);

  useEffect(() => {
    if (metaDetails?.isLocalSearch === 'true' || (metaDetails?.LocalPagination === 'true')) {
    setFilteredResultData(localFilteredData);
    }
    }, [localFilteredData]);

  const fetchData = (searchQueryParam) => {
    saveSearchQueries(searchQueryParam || searchQuery);
    handlePageChange(1, { searchParams: searchQueryParam || searchQuery });
  };

  const [showToast, setShowToast] = useState(false);
  const handleSearchQuery = (field, e, isDirectValue = false) => {
    let locSearchQuery = e?.target?.value || '';
    if (isDirectValue) {
      locSearchQuery = e;
    }
    if (!isLocalSearch && !(metaDetails?.isLocalSearch === 'true') && !isDirectValue) {
      // toast handling
      if (!showToast && locSearchQuery.length > 0) {
        setShowToast(true);
      } else if (locSearchQuery.length < 1) {
        setShowToast(false);
      }
    }

    const updatedSearchQueries = { ...searchQuery, [field]: locSearchQuery };
    setSearchQuery(updatedSearchQueries);
    if (isLocalSearch) {
      if (!(metaDetails?.isLocalSearch === 'true')) {
      let tempFilteredKpvGroupDet = { ...dataDetails };

      // eslint-disable-next-line arrow-body-style
      tempFilteredKpvGroupDet = dataDetails.filter((item) => {
        return Object.keys(searchQuery).every((query) => {
          if (query === field) {
            return String(item[query]).toLowerCase().includes(locSearchQuery.toLowerCase());
          }
          // all other cumulative search queries are checked
          return String(item[query]).toLowerCase().includes(searchQuery[query].toLowerCase());
        });
      });

      setFilteredResultData(tempFilteredKpvGroupDet);
    } else {
      let tempFilteredKpvGroupDet = { ...fullData };

      // eslint-disable-next-line arrow-body-style
      tempFilteredKpvGroupDet = fullData.filter((item) => {
        return Object.keys(searchQuery).every((query) => {
          if (query === field) {
            return String(item[query]).toLowerCase().includes(locSearchQuery.toLowerCase());
          }
          // all other cumulative search queries are checked
          return String(item[query]).toLowerCase().includes(searchQuery[query].toLowerCase());
        });
      });
      handlePageChangeLocal(tempFilteredKpvGroupDet);
      setTempFilteredKpvGroupDetStored(tempFilteredKpvGroupDet?.length);
      setFilteredResultData(localFilteredData);
    }
  } else {
      const savedSearchQuery = savedSearchQueries[field] || '';
      // fetch data if complete data is present(isDirectValue===true)
      if (isDirectValue && savedSearchQuery !== locSearchQuery) {
        fetchData({ ...searchQuery, [field]: locSearchQuery });
      }
    }
  };

  useEffect(() => {
    if (metaDetails?.isLocalSearch === 'true' || (metaDetails?.LocalPagination === 'true')) {
      Object.keys(showSearchInp)?.forEach((field) => {
        handleSearchQuery(field, '');
      });
      setShowSearchInp({});
    }
    }, [fullData]);
  const _handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === 'Enter' && !isLocalSearch) {
      setShowToast(false);
      fetchData();
    }
  };

  const handleUICompVisiblity = ({ field, index }, uiComp = 'input') => {
    if (uiComp === 'dropdown') {
      setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
    } else {
      // closing the searchinput(click cancel icon)
      if (showSearchInp[field]) {
        handleSearchQuery(field, '', true);
      }
      setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
    }
  };

  const handleDropdownItem = (field, item) => {
    if (searchQuery[field] === (item.value || item.label)) {
      handleSearchQuery(field, '', true);
    } else {
      handleSearchQuery(field, item.value || item.label, true);
    }
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const closeDropdown = (field) => {
    const updatedSearchQueries = { ...searchQuery, [field]: savedSearchQueries[field] };
    setSearchQuery(updatedSearchQueries);
    setShowDropdown({ ...showDropdown, [field]: false });
  };

  // console.log('kpvGroupDetails', kpvGroupDetails);
  // tabs
  // <HeaderContainer>
  //   {tabs.map((tab) => (
  //     <MachineHeaderText
  //       onClick={() => handleTabSelect(tab)}
  //       className={activeTab === tab.name ? 'active' : ''}
  //       key={tab.id}
  //     >
  //       {tab.name}
  //       {/* <CancelIcon onClick={() => handleRemoveMachine(selectedMachines[machine])}>
  //             <DynamicSVGIcon iconUrl={CancelBlack} width='1.5rem' />

  //           </CancelIcon> */}
  //     </MachineHeaderText>
  //   ))}
  // </HeaderContainer>;

  const handleDateSelection = (value, rest) => {
    const { label } = rest;
    handleSearchQuery(label, { target: { value: value.rawValue } });
    // old datetime handling
    // setShowToast(true);
    // toastMsg.current = 'Please close the dropdown to search';
  };

  const closeDateModal = (field) => {
    setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
  };

  const DateRangeModal = (col) => (
    <PopUpModal
      style={{ width: 'fit-content' }}
      closeModalHandler={() => {
        closeDateModal(col.key);
      }}
    >
      <DateRangePicker
        initValue={searchQuery[col.key]}
        handleSelection={(e) => {
          handleDateSelection(e, { label: col.key });
        }}
      />
      <FlexContainer style={{ marginTop: '2rem', justifyContent: 'flex-end' }}>
        <ButtonComp
          style={{ marginRight: '2rem' }}
          onClick={() => {
            closeDateModal(col.key);
          }}
        >
          Discard
        </ButtonComp>
        <ButtonComp
          dark
          onClick={() => {
            closeDateModal(col.key);
            fetchData();
          }}
        >
          Apply
        </ButtonComp>
      </FlexContainer>
    </PopUpModal>
  );
  const displayDate = (col) =>
    `= ${moment(searchQuery[col.key]?.startDate)?.format('DD-MM-YYYY')} => ${moment(
      searchQuery[col.key]?.endDate
    )?.format('DD-MM-YYYY')}`;

  const handleGroupBy = (item) => {
    setGroupBy(item);
  };

  const visibleColumnsCount = metaDetails?.columns?.reduce((count, obj) => {
    if (obj.visible) {
      return count + 1;
    }
    return count;
  }, 0);

  // TODO: handle grouping case also rowsDetails
  const handleCheckAll = () => {
    if (dataDetails) {
      let newShortlist = [...dataDetails];
      if (isComparision) {
        // resolving 2 to 1 item
        newShortlist = newShortlist.map((e) => e[0]);
      }
      if (!isCheckAll) {
        handleShortList([...newShortlist], false);
      } else {
        handleShortList([], false);
      }
      setIsCheckAll(!isCheckAll);
    }
  };

  const handlesortBy = (item, colDetails) => {
    const { key: colKey } = colDetails;
    // console.log('==', item, colKey);
    // const rowsDetailsCopy = [...rowsDetails?.noGrouping];
    // TODO: handle other types also
    if (item) {
      // enable sort
      if (isLocalSearch) {
        setActiveSortByCol(colKey);
        rowsDetails?.noGrouping?.sort((a, b) => compare(a, b, colKey, item === 'A to Z' ? 'inc' : 'dec'));
      } else {
        handleSortBy(colKey, item === 'A to Z' ? 'inc' : 'dec');
      }
    } else {
      // remove sort
      setActiveSortByCol();
    }
    setRowsDetails({ ...rowsDetails });
  };

  // flag for checkAll
  useEffect(() => {
    if (assetShortlist.length === dataDetails?.length && assetShortlist.length > 0) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [assetShortlist]);

  // console.log(checkedListLength, 'userTable', '==.');
  const SelectionType = () => {
    if (isCheckboxTypeSelection === true) {
      return (
        <Th>
          {selectAllVisible && (
          <FlexContainer className='jc_center'>
            <CheckBox handleChange={handleCheckAll} isActive={isCheckAll} />
          </FlexContainer>
)}
        </Th>
      );
    }
    return isActionReq && <Th style={{ paddingBottom: 0 }} flex={0.5}>Action</Th>;
  };
  const RowOptionsIcon = () => <>{rowOptions && <Th />}</>;
  return (
    <Container className={styleType}>
      <ContentContainer isScroll={dataDetails?.length > 1} className={styleType && `${styleType}`}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', padding: '1rem' }}>
          {showPagiation && (
            <PaginationWithPage
              ItemsPerPage={pageSize}
              total={tempFilteredKpvGroupDetStored > 0 ? tempFilteredKpvGroupDetStored : metaDetails?.count || metaDetails?.Count}
              handlePageChange={handlePageChange}
              forcedPushtoFirstPage={forcedPushtoFirstPage}
              setForcedPushToFirstPage={setForcedPushToFirstPage}
              searchParams={savedSearchQueries}
              buttonStyle={{ backgroundColor: '#EEEFF5' }}
            />
          )}
        </div>
        <TabTable className={styleType}>
          <Tr className='header'>
            {showRowOptionAtStart && <RowOptionsIcon />}
            {isSelectionAtFirst && <SelectionType />}
            {metaDetails?.columns?.map((col, index) => {
              const { searchable, sortable, options, type, sortBy } = col;
              const columnOptionsConfig = {
                searchable,
                sortable,
                options,
                type,
                sortBy
              };
              const isOptionVisible = curryIsOptionsVisible(columnOptionsConfig);
              return (
                col?.visible && (
                  <Th
                    border
                    key={col.key}
                    width={col.width}
                    // border={col.name !== 'Action'}
                    // eslint-disable-next-line prefer-template
                    className={('colHeading ' + col.key, styleType)}
                    style={{ padding: '0 1rem' }}
                  >
                    <FlexContainer
                      className={`${col.align === 'left' ? 'jc_start' : 'jc_center'} ${
                        col.align === 'right' ? 'jc_end' : 'jc_center'
                      }`}
                    >
                      <FlexContainer className=' ai_center jc_center'>
                        {showSearchInp[col.key] && isOptionVisible(OPTIONS_TYPES.SEARCHABLE) ? (
                          <SearchInput
                            className='show'
                            ref={searchRef}
                            onKeyUp={(e) => _handleKeyPress(e)}
                            type='text'
                            placeholder={'Search ' + col.title}
                            onChange={(e) => handleSearchQuery(col.key, e)}
                            value={searchQuery[col.key]}
                            // onKeyUp={(e) => _handleKeyPress(e)}
                          />
                        ) : (
                          <FlexContainer className='col ai_center' style={{ position: 'relative' }}>
                            {/* show title for all and dropdown value if present */}
                            <p style={{ fontSize: '1.8rem' }} className='title'>
                              {(localeStrings[col.title] || col.title) +
                                (col.options?.length && searchQuery[col.key] ? '=' + searchQuery[col.key] : '')}
                            </p>
                            {/* show daterange value */}
                            <p style={{ fontSize: '1.8rem' }}>
                              {col.type === 'DateTime' && searchQuery[col.key] ? displayDate(col) : ''}
                            </p>
                          </FlexContainer>
                        )}
                      </FlexContainer>
                      <FlexContainer className='jc_center'>
                        {/* search/cancel icon only for input type */}
                        {isOptionVisible(OPTIONS_TYPES.SEARCHABLE) && (
                          <SearchIcon onClick={() => handleUICompVisiblity({ field: col.key, index })}>
                            {!showSearchInp[col.key] ? (
                              <DynamicSVGIcon width='1.6rem' iconUrl={searchBlack} />
                            ) : (
                              <DynamicSVGIcon width='1.6rem' iconUrl={CancelBlack} />
                            )}
                          </SearchIcon>
                        )}
                        {/* sortby icon  */}
                        {isOptionVisible(OPTIONS_TYPES.SORT_BY) && (
                          <FlexContainer className='sortBy'>
                            <SortBy
                              clearOption={activeSortByCol !== col.key}
                              onClick={handlesortBy}
                              additionalDetails={{ columDetails: col }}
                            />
                          </FlexContainer>
                        )}
                        {/* dropdown icon for open/close state */}
                        {(isOptionVisible(OPTIONS_TYPES.HAS_OPTIONS) ||
                          isOptionVisible(OPTIONS_TYPES.IS_DATE_TIME)) && (
                          // dropdown/cancel icon based on value
                          <SearchIcon>
                            {!searchQuery[col.key] ? (
                              <div
                                style={{
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  transform: 'rotate(90deg)'
                                }}
                                onClick={() => handleUICompVisiblity({ field: col.key, index }, 'dropdown')}
                              >
                                <DynamicSVGIcon iconUrl={rightArrow} width='3.5rem' />
                              </div>
                            ) : (
                              <FlexContainer style={{ width: '100%' }} className='jc_center'>
                                <div
                                  style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    transform: 'rotate(270deg)'
                                  }}
                                  onClick={() => handleSearchQuery(col.key, '', true)}
                                >
                                  <DynamicSVGIcon width='1.6rem' iconUrl={CancelBlack} />
                                </div>
                              </FlexContainer>
                            )}
                          </SearchIcon>
                        )}
                        {/* show group by for first column & if groupList exists */}
                        {index === 0 && !isEmpty(groupByList) && (
                          <div style={{ marginLeft: 'auto' }}>
                            <Groupby selected={groupBy} onClick={handleGroupBy} list={groupByList} />
                          </div>
                        )}
                        {/* {col.key === 'KPV' && (
                      <div style={{ marginLeft: 'auto' }}>
                        <Groupby selected={groupBy} onClick={handleGroupBy} list={groupByList} />
                      </div>
                    )} */}
                      </FlexContainer>
                      {/* dropdown ui */}
                      {col.type !== 'DateTime' && showDropdown[col.key] && (
                        <div style={{ width: '100%', position: 'relative' }}>
                          <DropDownModal
                            ContainerStyle={{ top: '0px' }}
                            version={2}
                            data={col.options}
                            handleClick={handleDropdownItem}
                            setModalIsVisibal={closeDropdown}
                            field={col.key}
                          />
                        </div>
                      )}
                      {/* date dropdown ui */}
                      {col.type === 'DateTime' && showDropdown[col.key] && (
                        <div style={{ width: '200%', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                          <>{DateRangeModal(col)}</>
                        </div>
                        // old date time picker
                        // <DateDropDown style={{ position: 'absolute', zIndex: '10' }}>
                        // <DateTimePicker
                        //     // more width for input when sideNav is acitve
                        //     value={searchQuery[col.key]}
                        //     setValue={handleDateSelection}
                        //     width='100%'
                        //     index={index}
                        //     placeholder={`Enter ${title}`}
                        //     label={col.key}
                        //   />
                        // </DateDropDown>
                      )}
                    </FlexContainer>
                  </Th>
                )
              );
            })}
            {isSelectionAtLast && <SelectionType />}
            {!showRowOptionAtStart && <RowOptionsIcon />}
          </Tr>
          {/* {Object.keys(showSearchInp).some((e) => showSearchInp[e]) && filteredResultData ? (
            Object.keys(filteredResultData)?.map((groupName) => (
              <KPVGroup
                // key={groupName.id}
                groupItems={filteredResultData[groupName]}
                groupTitle={groupName}
                machineId={activeTab}
                machineName={selectedMachines[activeTab]?.name}
                activeTab={activeTab}
              />
            ))
          ) :  */}
          {assetListLoading ? (
            <Loader />
          ) : (
            Object.keys(rowsDetails).map((type) => (
              <TableContent
                type={type}
                hasNestedRowOptions={hasNestedRowOptions}
                rowsDetails={rowsDetails}
                metaDetails={metaDetails}
                assetShortlist={assetShortlist}
                visibleColumnsCount={visibleColumnsCount}
                onItemSelect={onItemSelect}
                showShortlistSelection={showShortlistSelection}
                handleShortList={handleShortList}
                rowOptions={rowOptions}
                id={id}
                className={styleType}
                handleRowOptionsItemClickHandler={handleRowOptionsItemClickHandler}
                shortListSelectionDetails={shortListSelectionDetails}
                showRowOptionAtStart={showRowOptionAtStart}
                isComparision={isComparision}
                setActiveRow={setActiveRow}
                activeRow={activeRow}
                isActionReq={isActionReq}
                hasObjectContent={hasObjectContent}
                rowOptionsWithCondition={rowOptionsWithCondition}
              />
            ))
          )}
        </TabTable>

        {!assetListLoading &&
          !error &&
          metaDetails?.columns &&
          !Object.values(rowsDetails).some((values) => values.length > 0) && (
            <FlexContainer className='ai_center jc_center' style={{ height: '100%' }}>
              <span style={{ fontSize: fontSize.text, fontWeight: 'bold' }}>{noItemsFoundMessage || 'No Items'}</span>
            </FlexContainer>
          )}
        {showToast && (
          <Toast
            message='Please press enter to search'
            style={{ right: '20px', bottom: '30px' }}
            header='note'
            shouldFade={false}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default TabsTable;
