/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { useActions } from '../../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { WidgetCreators } from '../../../../views/Widgets/store';
import ConfirmationModal from '../../../Common/ConfirmationBox/ConfirmationBox.view';

const DeletTab = (props) => {
  const { showDeleteConfModal, setShowDeleteConfModal } = props;
  const activeTab = useSelector((state) => state.widgets.activeTab);
  // const tabList = useSelector((state) => state.widgets.tabList);
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);

  // useEffect(() => {
  //   setShowDeleteConfModal(true);
  // }, []);

  const { widgetDetailSetter } = useActions();
  const setErr = (val) => {
    widgetDetailSetter({
      key: 'layoutError',
      value: val
    });
  };

  const closeDeleteConfModal = () => {
    setShowDeleteConfModal(false);
  };

  const handleDelete = async () => {
    // dispatch(WidgetCreators.setWidgetActiveTab({ ...activeTab }));
    // dispatch(WidgetCreators.deleteTab({ activeTab }));
    setButtonLoading(true);
    try {
      const fullUrl = apiEndPoints.deleteWidgetPanel.replace('{{WPID}}', activeTab.id);
      const res = await WebService.post(fullUrl);
      if (res.status >= 200 && res.status < 300) {
        // resetWidgetsLayout();
        dispatch(WidgetCreators.deleteTab({ activeTab }));
        closeDeleteConfModal();
        setButtonLoading(false);
      } else {
        throw res;
      }
    } catch (e) {
      const payload = sagaCatchBlockHandling(e);
      console.log('deletion error', payload);
      setErr(payload.error);
    }
    closeDeleteConfModal();
  };

  return (
    <ConfirmationModal
      showModal={showDeleteConfModal}
      handleDelete={handleDelete}
      closeModalHandler={closeDeleteConfModal}
      message='Do you really want to delete the tab?'
      buttonLoading={buttonLoading}
    />
  );
};

export default DeletTab;
