/* eslint-disable max-len */
// d=data, t=target, s=start, e=end, m=middle
export const nearestDataPoint = (d, t, s, e, argProperty = 'TSValue') => {
  if (s === null || e === null) {
    return {};
  }
  try {
    const m = Math.floor((s + e) / 2);
    // console.log(
    //   't === d[m][argProperty]',
    //   t,
    //   d,
    //   s,
    //   e,
    //   m
    //   //  new Date(t) === new Date(d[m][argProperty])
    // );
    if (e - 1 <= s) {
      return Math.abs(new Date(d[s][argProperty]) - new Date(t)) > Math.abs(new Date(d[e][argProperty]) - new Date(t))
        ? d[e]
        : d[s];
    }

    if (new Date(t) > new Date(d[m][argProperty])) {
      return nearestDataPoint(d, t, m, e, argProperty);
    }
    if (new Date(t) < new Date(d[m][argProperty])) {
      return nearestDataPoint(d, t, s, m, argProperty);
    }
    // if (new Date(t) === new Date(d[m][argProperty]))
    return d[m];
  } catch (e) {
    // console.log('e', e);
  }
  return null;
};

export const nearestDataPointByPosition = (d, xPos, s, e) => {
  const m = Math.floor((s + e) / 2);
  if (e - 1 <= s) {
    // console.log('codition matched at binary search ==> e, s', e, s);
    return Math.abs(d[s]?.x - xPos) > Math.abs(d[e]?.x - xPos) ? d[e] : d[s];
  }
  // console.log('m', d[m]?.x, xPos, 'xPos > d[m]?.x', xPos > d[m]?.x, 's, m, e', s, m, e);

  if (xPos > d[m]?.x) return nearestDataPointByPosition(d, xPos, m, e);
  if (xPos < d[m]?.x) return nearestDataPointByPosition(d, xPos, s, m);
  return d[m];
};

// helper function for nearestTransitionPointByPosition
const getMinDiff = (minPoint, maxPoint, xPos) => {
  let isInRange = false;
  if (minPoint <= xPos && xPos <= maxPoint) {
    isInRange = true;
  }
  return { isInRange, diff: Math.min(Math.abs(minPoint - xPos), Math.abs(maxPoint - xPos)) };
};

// helper function for nearestTransitionPointByPosition
export const checkRangeByPos = (point, xPos, getDiff = false) => {
  // const minDiff = point.x - xPos;
  const minDiff = point.x;

  const maxDiff = minDiff + point.width;

  if (getDiff) {
    const r = getMinDiff(minDiff, maxDiff, xPos);
    return r;
  }
  if (minDiff > xPos && maxDiff > xPos) {
    return -1;
  }
  if (minDiff < xPos && maxDiff < xPos) {
    return 1;
  }
  return 0;
};

export const nearestTransitionPointByPosition = (d, xPos, s, e) => {
  if (s === e) {
    return d[s];
  }
  const m = Math.floor((s + e) / 2);
  if (e - 1 === s) {
    // console.log('codition matched at binary search ==> e, s', e, s);
    return Math.min(Math.abs(d[s]?.x - xPos), Math.abs(d[s]?.x - xPos + d[s]?.width)) >
      Math.min(Math.abs(d[e]?.x - xPos), Math.abs(d[e]?.x - xPos + d[e]?.width))
      ? d[e]
      : d[s];
  }
  const next = checkRangeByPos(d[m], xPos);
  if (next === 1) return nearestTransitionPointByPosition(d, xPos, m, e);
  if (next === -1) return nearestTransitionPointByPosition(d, xPos, s, m);

  return d[m];
};
