/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { put } from 'redux-saga/effects';
import { myDashboard } from '../../../constants/dashboardsData';

import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { Creators } from './action';

export function* getDashboardsSaga({ payload }) {
  try {
    yield put(Creators.getDashboardsStart());

    const fullURL = yield `${apiEndPoints.getSavedDashboardSummary}`;

    const response = yield WebService.post(fullURL, { ...payload });

    if (response.status >= 200 && response.status < 300) {
      // const { data } = JSON.parse(response.data.replace('["', '').replace('"]', ''));
      // const data = myDashboard;
      const { data } = response.data;
      yield put(Creators.getDashboardsSuccess({ myDashboards: data || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = sagaCatchBlockHandling(error);
    yield put(Creators.setError(payload));
  }
}

export function* getInstanceDashboardSaga({ payload }) {
  const { page, pageSize, searchQueries, UserId } = payload;
  try {
    yield put(Creators.getInstanceDashboardStart());

    const fullURL = yield `${apiEndPoints.getAllWidgets}`;
    const payloadForApi = {
      Others: 1,
      PageSize: pageSize,
      Page: page,
      UserId,
      searchQueries: searchQueries?.searchParams
    };
    const response = yield WebService.post(fullURL, { ...payloadForApi });

    // const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      // const { data } = JSON.parse(response.data.replace('["', '').replace('"]', ''));
      // const data = myDashboard;
      const { data, meta } = response.data;
      yield put(Creators.getInstanceDashboardSuccess({ instanceData: data || [], instanceMeta: meta || {} }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = sagaCatchBlockHandling(error);
    yield put(Creators.getInstanceDashboardFailure());
    yield put(Creators.setError(payload));
  }
}

export function* updateInstanceDescription({ payload }) {
  try {
    yield put(Creators.updateInstanceDescriptionStart());

    const { instanceName, instanceDesc, WTIId } = payload;
    // const fullURL = yield `${apiEndPoints.getAllWidgets}?UserId=${userId}&Others=1&Page=${pageNumber}&PageSize=${pageSize}`;

    // const response = yield WebService.get(fullURL);
    const response = {};

    if (response.status >= 200 && response.status < 300) {
      // const { data } = JSON.parse(response.data.replace('["', '').replace('"]', ''));
      // const data = myDashboard;
      const { data, meta } = response.data;
      yield put(Creators.updateInstanceDescriptionSuccess({ instanceName, instanceDesc }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = sagaCatchBlockHandling(error);
    yield put(Creators.setError(payload));
  }
}
