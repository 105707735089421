import styled from 'styled-components';

export const Container = styled.div`
  width: max(50%, 75rem);
  margin: 2rem;
  /* background: red; */
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
  & hr {
    border-top: 1px solid #979797;
  }
`;
