/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { Creators as KPVChartsCreators } from '../../Store';
import { getNearestTSPoint } from '../../utils/getNearestPoints';
import { Graph, WrapperConatiner } from '../../PaneCard/PaneCards.style';
import LegendComp from '../../Legend/Legend.view';
import TransitionChart from './TransitionChart.view';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../../constants';
import { getTransitionChartLabels } from '../../utils/getMachinesFromKpvs';

const TransitionGraphComponent = (props) => {
  const { isLastPane, padding, position = 'right', fillLayout, ...paneData } = props;
  const { showDetails, labelsData, pointInfoObj, xMinDomainProp, xMaxDomainProp, rawPlotData, paneIndex } = paneData;
  const { data: plotData, meta } = rawPlotData || {};
  // const { colorCodes } = useSelector((state) => state.KPVCharts);
  const dispatch = useDispatch();
  const index = 0;
  const labels = useMemo(() => meta && getTransitionChartLabels(meta), [meta]);
  const [hoveredState, setHoveredState] = useState({});
  const [hoveredPosState, setHoveredPosState] = useState();

  const setGlobalHoveredState = useCallback(
    (value) => {
      dispatch(
        KPVChartsCreators.genericKPVPropertySetter({
          key: 'globalHoveredState',
          value: { index: !isEmpty(value) ? paneIndex : -1, ...value }
        })
      );
      setHoveredState(value);
    },
    [dispatch]
  );

  // moved hover code to outside the graph component
  const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const compressedView = useSelector((state) => state.KPVCharts.compressedView);
  const globalHoveredState = useSelector((state) => state.KPVCharts.globalHoveredState);
  const comparisionMode = useSelector((state) => state.KPVCharts.comparisionMode);
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);

  useEffect(() => {
    if (compressedView && hoveredXValue) {
      const data = getNearestTSPoint(plotData, hoveredXValue.argument);
      setHoveredState({ data, x: hoveredXValue });
    } else if (hoveredState?.x?.getTime() !== hoveredXValue?.argument?.getTime()) {
      // setHoveredState({});
    }
  }, [hoveredXValue]);

  // get timestamp only for the first label
  const getComparisionTimestamp = () =>
    hoveredPosState &&
    [Object.keys(hoveredPosState)[0]].reduce(
      (_, e) => (hoveredPosState?.[e] || [])?.reduce((prev, e) => moment(e.arg).format('DD/MM/YYYY HH:mm:ss:SSS'), ''),
      ''
    );
  const xCompLabel = getComparisionTimestamp();
  const paneHeight = Math.max(!isEmpty(meta) && Object.keys(meta).length * 10, 25);
  return (
    <WrapperConatiner
      fillLayout={fillLayout}
      position={position}
      style={{ display: 'flex', width: '100%', height: '100%' }}
    >
      <Graph
        className='singlePane'
        height={25}
        paddingLeft={padding}
        width={(showDetails ? showDetails?.legends : true) ? '75%' : '100%'}
        style={{ minHeight: `${paneHeight}rem` }}
      >
        {/* comparsion mode lines when aligned */}
        {comparisionMode && isAligned && !isEmpty(globalHoveredState) && (
          <div
            style={{
              position: 'absolute',
              left: globalHoveredState?.left + 10,
              height: '25rem',
              minHeight: `${paneHeight}rem`,
              width: '3px',
              zIndex: '10',
              background: 'black'
            }}
          >
            {xCompLabel ? (
              <div
                style={{
                  fontSize: '1.8rem',
                  position: 'absolute',
                  bottom: '0rem',
                  background: 'black',
                  color: '#fff',
                  padding: '0.5rem'
                }}
              >
                {xCompLabel}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        <TransitionChart
          isLastPane={isLastPane}
          setHoveredState={setGlobalHoveredState}
          setHoveredPosState={setHoveredPosState}
          paddingLeft={padding}
          labels={labels}
          xPos={comparisionMode && isAligned ? globalHoveredState.orgX : 0}
          // hoveredState={hoveredState}
          fillLayout={fillLayout}
          {...paneData}
        />
      </Graph>

      <LegendComp
        data={labelsData?.[index]}
        labels={meta}
        showLegends={showDetails ? showDetails?.legends : true}
        transitionChartColorCodes={labels}
        pointData={pointInfoObj?.length > 0 && pointInfoObj[index]}
        // color={index > 0 ? colorCodes[index] : undefined}
        chartType={CHART_TYPES.TRANSITION_CHART}
        chartTypeCategory={CHART_TYPE_CATEGORY.TRANSITION_CHART}
        position={position}
        hoveredState={hoveredState.data}
        hoveredPosState={hoveredPosState}
      />
    </WrapperConatiner>
  );
};

export default memo(TransitionGraphComponent);
