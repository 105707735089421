/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WIDGET_TERMINOLOGY } from '../../../../constants';
import { getResolvedWidgetCategory } from '../../utils';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { IconBox, ZoomText, ZoomingDiv } from './WidgetHeader.styles';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { ZoomOut, ZoomIn } from '../../../../assets/icons';
import { useActions } from '../../../../store/hooks/useActions';

const ZoomLevel = (props) => {
  const { hasZoomControl, setZoomLevel, zoomLevel, WID, componentData } = props;
  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const { zoomLevelSetter, updateWidgetPayload } = useActions();
  const { setToastError } = useActions();
  const maxZoomLevel = 2;
  const minZoomLevel = 0.2;
  const stepSize = 0.1;

  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  const saveZoom = async (zoomLevel) => {
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: { ...widgetComponentData, zoomLevel, isHideHeader: isHideHeader[WID + activeTab?.id], },
      WidgetMetaData: widgetMetaData
    };
    zoomLevelSetter({ key: WID + activeTab?.id, value: zoomLevel });
    updateWidgetPayload({ widgetPayload, WID });
    // try {
    //   const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
    //   const resp = await WebService.post(fullURL, widgetPayload);
    //   if (resp.status >= 200 && resp.status < 300) {
    //     zoomLevelSetter({ key: WID, value: zoomLevel });
    //   } else {
    //     throw resp;
    //   }
    //   // console.log('resp', resp);
    // } catch (e) {
    //   const { error } = sagaCatchBlockHandling(e);
    //   console.log('e', e, error);
    //   setToastError(error);
    // }
  };
  const handleZoomIn = () => {
    if (zoomLevel < maxZoomLevel) {
      const zl = zoomLevel + stepSize;
      setZoomLevel(zl);
      saveZoom(zl);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > minZoomLevel) {
      const zl = zoomLevel - stepSize;
      setZoomLevel(zl);
      saveZoom(zl);
    }
  };
  const zoom = Number(zoomLevel * 100).toFixed(0);
  return (
    <>
      {hasZoomControl && (
        <ZoomingDiv className='jc_center ai_center'>
          <IconBox
            onClick={handleZoomOut}
            className={zoomLevel <= minZoomLevel ? 'disabled' : ''}
          >
            <DynamicSVGIcon iconUrl={ZoomOut} width='2.5rem' />
          </IconBox>
          <ZoomText>{`${zoom}%`}</ZoomText>
          <IconBox
            onClick={handleZoomIn}
            className={zoomLevel >= maxZoomLevel ? 'disabled' : ''}
          >
            <DynamicSVGIcon iconUrl={ZoomIn} width='2.5rem' />
          </IconBox>
        </ZoomingDiv>
      )}
    </>
  );
};

export default ZoomLevel;
