/* eslint-disable indent */
import React from 'react';
import {
  BarGauge, Label, Size
} from 'devextreme-react/bar-gauge';
import { BarGaugeDiv, DonutDetails, DonutDiv, P } from '../OEEDonut/EquipmentDonut.style';
import useMeasure from '../../../../shared/hooks/useMeasure';
import { BarGaugeTable, LabelsContainer } from './BarGaugeView.Styles';

const values = [47.27, 65.32, 84.59, 71.86];
const format = {
  type: 'fixedPoint',
  precision: 1,
};

const BarGaugeView = (props) => {
  const { barGaugeData, initializing, fullView, zoomLevelVal, } = props;
  const chartValue = barGaugeData?.map((data) => data?.value);
  const chartColors = barGaugeData?.map((data) => data?.colors);
  const customizeText = ({ valueText }) => `${valueText} %`;
  //
  const [ref, { width, height }] = useMeasure();
  return (
    <BarGaugeDiv
      ref={ref}
      zoomLevel={fullView ? 1 : zoomLevelVal}
      width="400px"
      height="400px"
    >
      <BarGauge
        id="gauge"
        startValue={0}
        endValue={100}
        defaultValues={chartValue || values}
        palette={chartColors}
      >
        <Size height={height} width={width} />
        <Label
          indent={10}
          format={format}
          customizeText={customizeText}
          visible={true}
        />

      </BarGauge>
      <LabelsContainer>
        {barGaugeData?.length && (
          <BarGaugeTable>
            <thead>
              <tr>
                <th scope="col">Labels</th>
                <th scope="col">Names</th>
                <th scope="col">TGT(%)</th>
                <th scope="col">ACT(%)</th>
              </tr>
            </thead>
            <tbody>

              {barGaugeData?.map((data) => (

                <tr key={data?.key}>
                  <th
                    scope="row"
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {data?.labels}
                  </th>
                  <td>{data?.names}</td>
                  <td>{data?.TGT}</td>
                  <td>{data?.ACT}</td>
                </tr>
              ))}
            </tbody>

          </BarGaugeTable>
        )}
      </LabelsContainer>
      <DonutDetails height={height + 20}>
        <P className='fn'>OEE</P>
        <P className='fl mb' style={{ color: 'green' }}>
          20%
        </P>
      </DonutDetails>
    </BarGaugeDiv>

  );
};
export default BarGaugeView;
