/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PointedDropdownModal from '../../Common/DropDownModal/PointedDropdownModal';
import { Creators as LayoutCreators } from '../../../views/Layout/store';
import ToggleOn from '../../../assets/icons/KPV/Toggle on.svg';
import ToggleOff from '../../../assets/icons/KPV/toggle off.svg';
import { THEMES, URLS_TO_MARK_DEFAULT } from '../../../constants';
import { Creators as configCreators } from '../../../config/action';
import { mode, settingsBlack, defaultUrlIcon } from '../../../assets/icons';
import ConfirmationModal from '../../Common/ConfirmationBox/ConfirmationBox.view';

const SettingsDropdown = () => {
  // Extracting the current location using React Router's useLocation hook
  const location = useLocation();

  // Extracting 'id' parameter from the query string of the current location
  const idParam = new URLSearchParams(location.search).get('id');

  // Managing the state for the toggle icon and confirmation modal visibility
  const [toggleIcon, setToggleIcon] = useState(ToggleOff);
  const [showUrlConfirm, setShowConfirm] = useState(false);

  // Managing the state for the default URL setting
  const [defaultUrl, setDefaultUrl] = useState(false);

  // Constructing the location URL based on the presence of 'id' parameter
  const locationUrl =
    idParam !== null
      ? `${location.pathname}${location.search}`
      : location.pathname;

  // Creating a common URL pattern by replacing the last path segment with '/:id'
  const commonUrl = location.pathname.replace(
    location.pathname?.substring(location.pathname?.lastIndexOf('/')),
    '/:id'
  );

  const themeMode = useSelector((state) => state.configData.themeMode);
  const settingsDropdown = useSelector((state) => state.home.settingsDropdown);
  //
  const userData = useSelector((state) => state.loginData.userData);

  const settingData = [
    {
      label: 'Dark Mode',
      value: 'darkMode',
      disable: false,
      icon: toggleIcon,
      labelIcon: mode
    },
    {
      label: 'Mark as Home Page',
      value: 'defaultUrl',
      labelIcon: defaultUrlIcon
    },
    { label: 'User Settings', value: 'userSettings', labelIcon: settingsBlack }
  ];
  const settingDataWithUrl = [
    {
      label: 'Dark Mode',
      value: 'darkMode',
      disable: false,
      icon: toggleIcon,
      labelIcon: mode
    },

    { label: 'User Settings', value: 'userSettings', labelIcon: settingsBlack }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (themeMode === 'dark') {
      setToggleIcon(ToggleOn);
    } else {
      setToggleIcon(ToggleOff);
    }
  }, [themeMode]);

  const closeModal = () => {
    if (settingsDropdown) {
      dispatch(LayoutCreators.toggleHeaderOption('settingsDropdown'));
    }
  };

  const handleClick = (value) => {
    let newTheme;
    switch (value) {
      case 'darkMode':
        newTheme = themeMode === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
        if (newTheme === THEMES.LIGHT) {
          setToggleIcon(ToggleOff);
        } else {
          setToggleIcon(ToggleOn);
        }
        dispatch(configCreators.setThemeColors({ themeMode: newTheme }));
        dispatch(configCreators.setTheme(newTheme));
        break;

      case 'userSettings':
        dispatch(LayoutCreators.toggleHeaderOption('settingsDropdown'));
        dispatch(LayoutCreators.settingsActiveMenu('General'));
        break;
      case 'defaultUrl':
        setShowConfirm(true);
        break;

      default:
        break;
    }
  };
  //
  // Checking if page has ID as query param or in url to show the mark as default feature
  useEffect(() => {
    if (URLS_TO_MARK_DEFAULT.includes(commonUrl) || idParam) {
      setDefaultUrl(true);
    }
  }, [commonUrl, idParam]);

  // Loading the default url
  useEffect(() => {
    dispatch(LayoutCreators.getDefaultUrl({ userId: userData?.userId }));
  }, []);

  /**
   * handleAddUrl - Function to handle the addition of a URL to the user's settings.
   * This function dispatches actions to set the default URL for the user from the settings dropdown in the header.
   *
   * It also hides the confirmation dialog after the URL is added.
   */

  const handleAddUrl = () => {
    dispatch(
      LayoutCreators.setDefaultUrl({
        UserId: userData?.userId,
        Url: locationUrl
      })
    );

    dispatch(LayoutCreators.toggleHeaderOption('settingsDropdown'));
    setShowConfirm(false);
  };
  // Remove functionality for the Mark as default
  const handleRemove = (e) => {
    e.stopPropagation();
    dispatch(
      LayoutCreators.setDefaultUrl({ UserId: userData?.userId, Url: '' })
    );
    dispatch(LayoutCreators.getDefaultUrl({ userId: userData?.userId }));
  };
  return (
    <PointedDropdownModal
      data={defaultUrl ? settingData : settingDataWithUrl}
      closeModal={() => null}
      handleClick={handleClick}
      locationUrl={locationUrl}
      handleRemove={handleRemove}
    >
      {/* <-----------------Confirmation Modal for default url  -------------------------> */}

      <ConfirmationModal
        showModal={showUrlConfirm}
        action='Confirm'
        Header='Mark as Home Page'
        handleDelete={handleAddUrl}
        closeModalHandler={() => {
          setShowConfirm(false);
        }}
        message='Click Confirm To Mark as Home Page?'
      />
    </PointedDropdownModal>
  );
};

export default SettingsDropdown;
