/* eslint-disable max-len */
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';

export const editPaneMenu = {
  transitionChart: [
    {
      id: '1-1',
      label: 'View Full Screen',
      value: 'fullScreen',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_full_screen_pkv6iv.svg'
    },
    {
      id: '1-2',
      label: 'Edit Pane',
      value: 'editPane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-10',
      label: 'Add This As Widget',
      value: 'addAsWidget',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-3',
      label: 'Customise Machines',
      value: 'customiseKpvs',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_customize_cjewlx.svg'
    },
    {
      id: '1-4',
      label: 'Show Details',
      value: 'showDetails',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_details_vpnhyu.svg',
      subMenu: [
        {
          id: '1-4-1',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Remove Pane',
      value: 'removePane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_remove_pane_t88nsg.svg'
    }
  ],
  dataChart: [
    {
      id: '1-1',
      label: 'View Full Screen',
      value: 'fullScreen',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_full_screen_pkv6iv.svg'
    },
    {
      id: '1-2',
      label: 'Edit Pane',
      value: 'editPane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-10',
      label: 'Add This As Widget',
      value: 'addAsWidget',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-3',
      label: 'Customise KPVs',
      value: 'customiseKpvs',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_customize_cjewlx.svg'
    },
    {
      id: '1-4',
      label: 'Show Details',
      value: 'showDetails',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_details_vpnhyu.svg',
      subMenu: [
        {
          id: '1-4-1',
          label: 'KPV Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    },
    {
      id: '1-8',
      label: 'Annotations',
      value: 'annotations',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-8-1',
          label: 'Show Annotations',
          value: 'annotations'
        },
        {
          id: '1-8-2',
          label: 'Add Annotations',
          value: 'isAddAnnotationEnabled'
        }
      ]
    },
    {
      id: '1-5',
      label: 'Show Overlays',
      value: 'showOverlays',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-5-1',
          label: 'Limits',
          value: 'limits'
        }
      ]
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scaleYAxis',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017253/Chart_menu_scale_Y_axis_se2izd.svg',
      subMenu: [
        {
          id: '1-6-1',
          label: 'Auto',
          value: 'auto'
        },
        {
          id: '1-6-2',
          label: 'Process Window',
          value: 'processWindow'
        },
        {
          id: '1-6-3',
          label: 'Manual',
          value: 'manual'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Remove Pane',
      value: 'removePane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_remove_pane_t88nsg.svg'
    }
  ],
  [CHART_TYPE_CATEGORY.SPC]: [
    {
      id: '1-1',
      label: 'View Full Screen',
      value: 'fullScreen',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_full_screen_pkv6iv.svg'
    },
    {
      id: '1-2',
      label: 'Edit Pane',
      value: 'editPane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-10',
      label: 'Add This As Widget',
      value: 'addAsWidget',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_edit_pane_izml94.svg'
    },
    {
      id: '1-3',
      label: 'Customise KPVs',
      value: 'customiseKpvs',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_customize_cjewlx.svg'
    },
    {
      id: '1-4',
      label: 'Show Details',
      value: 'showDetails',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_details_vpnhyu.svg',
      subMenu: [
        {
          id: '1-4-1',
          label: 'KPV Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    },
    {
      id: '1-8',
      label: 'Annotations',
      value: 'annotations',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-8-1',
          label: 'Show Annotations',
          value: 'annotations'
        },
        {
          id: '1-8-2',
          label: 'Add Annotations',
          value: 'isAddAnnotationEnabled'
        }
      ]
    },
    {
      id: '1-9',
      label: 'Show Alarms',
      value: 'showAlarms',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-5-1',
          label: 'L1 Limits',
          value: 'l1Limits'
        },
        {
          id: '1-5-2',
          label: 'L2 Limits',
          value: 'l2Limits'
        }
      ]
    },
    {
      id: '1-5',
      label: 'Show Overlays',
      value: 'showOverlays',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_show_overlays_squ39f.svg',
      subMenu: [
        {
          id: '1-5-1',
          label: 'L1 Limits',
          value: 'l1Limits'
        },
        {
          id: '1-5-2',
          label: 'L2 Limits',
          value: 'l2Limits'
        }
      ]
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scaleYAxis',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017253/Chart_menu_scale_Y_axis_se2izd.svg',
      subMenu: [
        {
          id: '1-6-1',
          label: 'Auto',
          value: 'auto'
        },
        // {
        //   id: '1-6-2',
        //   label: 'Process Window',
        //   value: 'processWindow'
        // },
        {
          id: '1-6-3',
          label: 'Manual',
          value: 'manual'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Remove Pane',
      value: 'removePane',
      icon: 'https://res.cloudinary.com/vistrian/image/upload/v1634017251/Chart_menu_remove_pane_t88nsg.svg'
    }
  ]
};

export const createPaneMenu = {
  dataChart: [
    {
      id: '1-1',
      label: 'Pane Title',
      value: 'paneTitle',
      type: 'paneTitle'
    },
    {
      id: '1-2',
      label: 'Customise KPVs',
      value: 'customiseKpvs',
      type: 'customiseKpvs'
    },
    {
      id: '1-3',
      label: 'Show Details',
      value: 'showDetails',
      type: 'showDetails',
      subMenu: [
        {
          id: '1-3-1',
          label: 'Y-axis',
          value: 'y-axis'
        },
        {
          id: '1-3-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-3-3',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-3-4',
          label: 'Annotated points',
          value: 'annotatedPts'
        }
      ]
    },
    {
      id: '1-4',
      label: 'Show Overlays',
      value: 'showOverlays',
      type: 'showOverlays',
      subMenu: [
        {
          id: '1-4-1',
          label: 'L1 limits',
          value: 'l1-limits'
        },
        {
          id: '1-4-2',
          label: 'L2 limits',
          value: 'l2-limits'
        }
      ]
    },
    {
      id: '1-5',
      label: 'Y-Axis Settings',
      value: 'yAxis-settings',
      type: 'yAxis-settings',
      subMenu: [
        {
          id: '1-5-1',
          label: 'Single Y-axis',
          value: false
        },
        {
          id: '1-5-2',
          label: 'Multiple Y-axis',
          value: true
        }
      ]
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scale-yAxis',
      type: 'scale-yAxis',
      subMenu: [
        {
          id: '1-6-1',
          label: 'Auto',
          value: 'auto'
        },
        {
          id: '1-6-2',
          label: 'Process Window',
          value: 'processWindow'
        },
        {
          id: '1-6-3',
          label: 'Manual',
          value: 'manual'
        }
      ]
    }
  ],
  transitionChart: [
    {
      id: '1-1',
      label: 'Pane Title',
      value: 'paneTitle',
      type: 'paneTitle'
    },
    {
      id: '1-2',
      label: 'Transition Charts',
      value: 'transitionCharts',
      type: 'transitionCharts',
      subMenu: [
        {
          id: '1-2-1',
          label: 'Lots',
          value: 'lots'
        },
        {
          id: '1-2-2',
          label: 'Recipe',
          value: 'recipe'
        },
        {
          id: '1-2-3',
          label: 'Status',
          value: 'status'
        }
      ]
    },
    {
      id: '1-3',
      label: 'Customise Machines',
      value: 'customiseKpvs',
      type: 'customiseKpvs'
    },
    {
      id: '1-4',
      label: 'Pane Details',
      value: 'showDetails',
      type: 'showDetails',
      subMenu: [
        {
          id: '1-4-1',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    }
  ],
  [CHART_TYPE_CATEGORY.SPC]: [
    {
      id: '1-1',
      label: 'Pane Title',
      value: 'paneTitle',
      type: 'paneTitle'
    },
    {
      id: '1-3',
      label: 'Customise Machines',
      value: 'customiseKpvs',
      type: 'customiseKpvs'
    },
    {
      id: '1-4',
      label: 'Pane Details',
      value: 'showDetails',
      type: 'showDetails',
      subMenu: [
        {
          id: '1-4-1',
          label: 'Legends',
          value: 'legends'
        },
        {
          id: '1-4-2',
          label: 'X-axis',
          value: 'x-axis'
        },
        {
          id: '1-4-3',
          label: 'Y-axis',
          value: 'y-axis'
        }
      ]
    },
    {
      id: '1-4',
      label: 'Show Overlays',
      value: 'showOverlays',
      type: 'showOverlays',
      subMenu: [
        {
          id: '1-4-1',
          label: 'L1 limits',
          value: 'l1-limits'
        },
        {
          id: '1-4-2',
          label: 'L2 limits',
          value: 'l2-limits'
        }
      ]
    },
    {
      id: '1-7',
      label: 'Show Alarms',
      value: 'showAlarms',
      type: 'showAlarms',
      subMenu: []
    },
    {
      id: '1-6',
      label: 'Scale Y-Axis',
      value: 'scale-yAxis',
      type: 'scale-yAxis',
      subMenu: [
        {
          id: '1-6-1',
          label: 'Auto',
          value: 'auto'
        },
        // {
        //   id: '1-6-2',
        //   label: 'Process Window',
        //   value: 'processWindow'
        // },
        {
          id: '1-6-3',
          label: 'Manual',
          value: 'manual'
        }
      ]
    }
  ]
};

// export const sampleTransitionChartDetails = [
//   {
//     RequestId: 'anything',
//     chartType: 'transitionChart',
//     category: 'state',
//     ChartTitle: 'State Chart',
//     SubText: '',
//     state: [
//       {
//         P1_8776: {
//           data: [
//             {
//               Start: '2022-02-22T10:00:00',
//               end: '2022-02-22T10:17:40',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:17:40',
//               end: '2022-02-22T10:17:52',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:17:52',
//               end: '2022-02-22T10:18:50',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:18:50',
//               end: '2022-02-22T10:22:17',
//               label: 'SD'
//             },
//             {
//               Start: '2022-02-22T10:22:17',
//               end: '2022-02-22T10:22:48',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:22:48',
//               end: '2022-02-22T10:22:59',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:22:59',
//               end: '2022-02-22T10:24:38',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:24:38',
//               end: '2022-02-22T10:35:35',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:35:35',
//               end: '2022-02-22T10:35:42',
//               label: 'UD'
//             },
//             {
//               Start: '2022-02-22T10:35:42',
//               end: '2022-02-22T10:35:53',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:35:53',
//               end: '2022-02-22T10:36:13',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:36:13',
//               end: '2022-02-22T10:36:33',
//               label: 'UD'
//             },
//             {
//               Start: '2022-02-22T10:36:33',
//               end: '2022-02-22T10:42:24',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:42:24',
//               end: '2022-02-22T10:42:31',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:42:31',
//               end: '2022-02-22T10:44:14',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:44:14',
//               end: '2022-02-22T10:53:00',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:53:00',
//               end: '2022-02-22T10:56:34',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:56:34',
//               end: '2022-02-22T10:56:41',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T10:56:41',
//               end: '2022-02-22T10:56:42',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T10:56:42',
//               end: '2022-02-22T11:05:41',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:05:41',
//               end: '2022-02-22T11:07:05',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T11:07:05',
//               end: '2022-02-22T11:22:43',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:22:43',
//               end: '2022-02-22T11:22:45',
//               label: 'UD'
//             },
//             {
//               Start: '2022-02-22T11:22:45',
//               end: '2022-02-22T11:23:07',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:23:07',
//               end: '2022-02-22T11:23:30',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T11:23:30',
//               end: '2022-02-22T11:32:31',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:32:31',
//               end: '2022-02-22T11:32:38',
//               label: 'UD'
//             },
//             {
//               Start: '2022-02-22T11:32:38',
//               end: '2022-02-22T11:32:39',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T11:32:39',
//               end: '2022-02-22T11:34:05',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:34:05',
//               end: '2022-02-22T11:34:21',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T11:34:21',
//               end: '2022-02-22T11:56:45',
//               label: 'PR'
//             },
//             {
//               Start: '2022-02-22T11:56:45',
//               end: '2022-02-22T11:57:15',
//               label: 'SB'
//             },
//             {
//               Start: '2022-02-22T11:57:15',
//               end: '2022-02-22T12:00:00',
//               label: 'PR'
//             }
//           ],
//           meta: {
//             fields: {
//               key: 'P1_8776',
//               displayname: 'PEN>>1FAM0103',
//               Labels: [
//                 {
//                   label: 'EN',
//                   labeltext: 'Engineering Time'
//                   // colour: '#33A8FF'
//                 },
//                 {
//                   label: 'NC',
//                   labeltext: 'Data Not Available'
//                   // colour: '#CCCCCC'
//                 },
//                 {
//                   label: 'ND',
//                   labeltext: 'Nonscheduled Time'
//                   // colour: '#A0A0A0'
//                 },
//                 {
//                   label: 'PR',
//                   labeltext: 'Productive Time'
//                   // colour: '#74B230'
//                 },
//                 {
//                   label: 'SB',
//                   labeltext: 'Standby Time'
//                   // colour: '#FFFF00'
//                 },
//                 {
//                   label: 'SD',
//                   labeltext: 'Scheduled Downtime'
//                   // colour: '#FFCCCC'
//                 },
//                 {
//                   label: 'UD',
//                   labeltext: 'Unscheduled Downtime'
//                   // colour: '#FF0000'
//                 }
//               ]
//             }
//           }
//         }
//       }
//     ],
//     reqPayload: {
//       RequestId: 'anything',
//       chartType: 'transitionChart',
//       category: 'state',
//       chartTypeCategory: 'transitionChart',
//       StartTime: '2022-02-22 10:00:00',
//       EndTime: '2022-02-22 12:00:00',
//       stateDetails: [
//         {
//           id: 'P1_8776',
//           start: '2022-02-22 10:00:00',
//           end: '2022-02-22 12:00:00',
//           macId: 'P1_8776'
//         }
//       ],
//       singlePane: true
//     }
//   }
// ];
