/* eslint-disable max-len */
import { isEmpty } from '../../../shared/utility/isEmpty';
import { generateSelectedFilter } from '../../ResultView/Iframe/generateSelectedFilter';

export const saveAnalyticsPayload = ({
  cumulativeTags,
  filterData,
  form,
  menuIdofFilter,
  selectedParent,
  leafLevels,
  metaDetailsInShortlist,
  KPVShortlist,
  userData,
  panesShortHandInfo,
  xMinDomain,
  xMaxDomain,
  xDomainDuration,
  shouldSaveCharts = true
}) => {
  const { myAnalyticsItemId, myAnalyticsItemName, menuId, chartDetails, filterSelections, ...restForm } = form;
  let requestPayload = {
    ...restForm,
    Id: myAnalyticsItemId,
    menuid: menuIdofFilter || form.menuId,
    tab: selectedParent || form.selectedParent,
    // tab: selectedParent,
    // leafLevels,
    Title: myAnalyticsItemName,
    kpvDetails: {
      metaDetailsInShortlist,
      KPVShortlist
    },
    UserID: userData.email || userData.mail
    // UserID: 'lino.thomas@vistrian.com'
  };
  if (!shouldSaveCharts) {
    requestPayload.IsChartable = false;
  }
  if (shouldSaveCharts && !isEmpty(panesShortHandInfo)) {
    const chartProps = { chartDetails: panesShortHandInfo, IsChartable: true, xMinDomain, xMaxDomain, xDomainDuration };
    requestPayload = { ...requestPayload, ...chartProps };
  }
  if (!isEmpty(cumulativeTags) && !isEmpty(filterData)) {
    const filterPayload = generateSelectedFilter(cumulativeTags, filterData, true);
    requestPayload.filterData = { ...filterPayload };
    requestPayload.leafLevels = leafLevels;
  }
  return requestPayload;
};
