import styled from 'styled-components';

export const Text = styled.p`
  font: normal normal normal 20px/28px Circular Std Bold;
  letter-spacing: 0px;
  color: #202020;
`;

export const ChartContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

export const ChartDiv = styled.div`
  position: relative;
  top: 2rem;
  width: 100%;
`;
