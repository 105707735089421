export const formatDetailedStatusData = (donutData) => {
  const detailedStatus = {};
  donutData?.forEach((data) => {
    const tab = data.tabTitle;
    data?.data?.forEach((item) => {
      detailedStatus[item?.label] = {
        title: {
          label: item.label,
          bgcolor: '#000',
          textColor: '#FFF'
        },
        data: {
          ...detailedStatus[item?.label]?.data,
          [tab]: {
            label: `${data.tabTitle} %`,
            value: item.percentage,
            target: item.target,
            textColor: '#000',
            bgcolor: item.color
          }
        }
      };
    });
  });
  return Object.values(detailedStatus);
};
