/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Kabab } from '../../../../assets/icons/KPV';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import ConfirmationModal from '../../../Common/ConfirmationBox/ConfirmationBox.view';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import {
  AddHomeIcon,
  HeaderContainer,
  RemoveIcon,
  WidgetFullViewIcon,
  WidgetHeader,
} from './WidgetHeader.styles';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { useActions } from '../../../../store/hooks/useActions';
import Toast from '../../../Common/Toast';
import { MAX_WIDGETS_IN_HOME, WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../../../constants';
import { getResolvedWidgetCategory, handlePdfExport, handlePdfExportWithoutpdf, handlePngExport, PngTableExport } from '../../utils';
import { AddToHome, DeleteBlack, FullScreen, RemoveFromHome } from '../../../../assets/icons';
import { FlexContainer } from '../../../../globalStyles';
import ZoomLevel from './ZoomLevel';
import { WidgetCreators } from '../../../../views/Widgets/store';
import NestedDropdown from '../../../Common/DropDownModal/NestedDropdown';
import FullViewWidget from '../../WidgetComponents/FullViewWidget/FullViewWidget';
import ExportDashboard from '../../../../views/ExportDashboard/ExportDashboard';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import { Heading } from '../../../Common/PopUpModal/Modal.style';
import { Text } from '../../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import { CheckBox } from '../../../UpsertPane/UpsertPane.style';

const WidgetHeaderView = (props) => {
  const {
    id,
    WID,
    WidgetName,
    widgetOptions,
    menu,
    componentData,
    hasZoomControl,
    setZoomLevel,
    setshowConfig = () => { },
    zoomLevel,
    fullView = false,
    bottomFullView = 15,
    leftFullView = 12,
    setShowPivotTable,
    setIsLocalEnablePivot,
  } = props;
  const { zoomLevel: definedZoomLevel } = componentData || {};
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteConfm, setShowDeleteConfm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const userData = useSelector((state) => state.loginData.userData);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const widgetPayload = useSelector((state) => state.widgets.widgetPayload);
  const isPreviewInstance = useSelector((state) => state.widgets.isPreviewInstance);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const [showWidgetAdd, setShowWidgetAdd] = useState(false);
  const [showWidgetRemove, setShowWidgetRemove] = useState(false);
  const [showMaxError, setshowMaxError] = useState(false);

  const {
    getWidgetsLayout,
    setWidgetType,
    setFormInput,
    valueSetter,
    updateWidgetPayload,
    setWidgetsInHomeList,
    addWidgetFromPreview,
    deleteWidgetFromHome, getHomeLayout
  } = useActions();
  const { WTIId, layoutComponentData } = useSelector((state) => state.widgets);
  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const HDId = useSelector((state) => state.HomeDashboardReducer.HDId);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isEnablePivot = useSelector((state) => state.widgets.isEnablePivot);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const isTemplateConfigure = useSelector((state) => state.widgets.isTemplateConfigure);
  const WidgetPanelName = useSelector((state) => state.widgets.WidgetPanelName);
  const [showFullView, setShowFullView] = useState(false);
  const [exportXlxs, setExportXlxs] = useState(false);
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  // this instace name are only for popup window
  const instaceName = new URLSearchParams(location.search).get('instace_name');

  const handleDelete = (value) => {
    setShowDeleteConfm(true);
  };

  /**
   * Function to Delete an Widget
   */
  const confirmDelete = async () => {
    try {
      const WPID = activeTab.id;
      setLoading(true);
      const URL = `${apiEndPoints.deleteWidget}`;
      const resolvedUrl = URL.replace('{{WID}}', WID);

      const res = await WebService.post(resolvedUrl, {
        userId: userData.userId,
        WidgetPanelId: activeTab.id
      });
      if (res.status >= 200 && res.status < 300) {
        // console.log('deletion successfull');
        getWidgetsLayout({
          userId: userData.userId,
          WPID
        });
        setShowDeleteConfm(false);
      } else {
        throw res;
      }
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function to Handle Individual Configurations of Widgets in Designer Tab
   * @param {*} value
   */
  const handleWidgetConfiguration = async (value) => {
    const { type, WidgetName, widgetCategory, formData } = componentData;
    setWidgetType({
      widgetType: type,
      widgetLabel: widgetTypesMenu?.[type]?.label || type,
      //  TODO_W_R: add widget category also
      [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: widgetCategory
    });
    valueSetter({ widgetName: WidgetName });
    setFormInput(formData);
    const resolvedWidgetCategory = getResolvedWidgetCategory(
      widgetCategory,
      type
    );
    if (
      resolvedWidgetCategory === WIDGET_CATEGORIES.OEE_DONUT ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.BAR_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CHART ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.SVG ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.PIVOT_TABLE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.TEXT_HTML_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.STATUS_VALUE ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.GAUGE_CONCENTRIC_WIDGET ||
      resolvedWidgetCategory === WIDGET_CATEGORIES.DETAILED_STATUS_VALUE
    ) {
      history.push({
        pathname: '/home/create-widget/form',
        state: { isEdit: true, WID }
      });
    } else {
      history.push({
        pathname: '/home/create-widget/',
        state: { isEdit: true, WID }
      });
    }
  };

  const handleTableColumnConfiguration = (value) => {
    setshowConfig(true);
  };

  /**
   * Function to Toggle the Header (Show/Hide)
   * @param {*} value
   */
  const handleToggleShow = (value) => {
    setShowOptions(false);
    dispatch(
      WidgetCreators.setHeaderHide({ WID, WPID: activeTab?.id, isHideHeader: !isHideHeader[WID + activeTab?.id] })
    );
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        isHideHeader: !isHideHeader[WID + activeTab?.id],
        isHideBorder: isHideBorder[WID + activeTab?.id],
        isEnablePivot: isEnablePivot[WID + activeTab?.id],
        zoomLevel
      },
      WidgetMetaData: widgetMetaData
    };

    updateWidgetPayload({ widgetPayload, WID });
  };

  /**
   * Function to Toggle Border of Widgets
   * @param {*} value
   */
  const handleBorderShow = (value) => {
    setShowOptions(false);
    dispatch(
      WidgetCreators.setBorderHide({ WID, WPID: activeTab?.id, isHideBorder: !isHideBorder[WID + activeTab?.id] })
    );
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        isHideHeader: isHideHeader[WID + activeTab?.id],
        isHideBorder: !isHideBorder[WID + activeTab?.id],
        isEnablePivot: isEnablePivot[WID + activeTab?.id],
        zoomLevel
      },
      WidgetMetaData: widgetMetaData
    };

    updateWidgetPayload({ widgetPayload, WID });
  };

  const setModalIsVisibal = () => {
    setShowOptions(false);
  };

  /**
   * Function to Set Fullview of the clicked Widget
   */
  const handleFullView = () => {
    setShowOptions(false);
    setShowFullView(true);
  };

  /**
   * Handling Image Export
   */
  const handleImgExport = () => {
    // if WidgetPanelName are not there so passing their respective instace name
    handlePngExport(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
  };

  /**
   * Handling Pdf Export
   */
  const handlePDFExport = () => {
    handlePdfExport(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
  };
  //
  const handlePdfExportWithoutTab = () => {
    handlePdfExportWithoutpdf(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
  };
  //
  const handlePngTableExport = () => {
    PngTableExport(WidgetName, WidgetPanelName || instaceName, WID, setShowOptions);
  };
  //
  const handleExcelExport = () => {
    setShowOptions(false);
    setExportXlxs(true);
  };

  /**
   * Function to set confirmation modal For Add to Home
   */
  const handleAddToHome = () => {
    if (widgetsInHome?.length >= MAX_WIDGETS_IN_HOME) {
      setshowMaxError(true);
    } else {
      setShowWidgetAdd(true);
    }
  };

  /**
   * Function to set Confirmation Modal For Delete From Home
   */
  const handleDeleteFromHome = () => {
    setShowWidgetRemove(true);
  };
  //
  const piviotTable = () => {
    setShowPivotTable((prev) => !prev);
  };
  const handleTogglePivot = () => {
    setShowOptions(false);
    dispatch(
      WidgetCreators.setEnablePivot({ WID, WPID: activeTab?.id, isEnablePivot: !isEnablePivot[WID + activeTab?.id] })
    );
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        isHideHeader: isHideHeader[WID + activeTab?.id],
        isHideBorder: isHideBorder[WID + activeTab?.id],
        isEnablePivot: !isEnablePivot[WID + activeTab?.id],
        zoomLevel
      },
      WidgetMetaData: widgetMetaData
    };
    setIsLocalEnablePivot(!isEnablePivot[WID + activeTab?.id]);
    // setShowPivotTable((prev) => !prev);
    updateWidgetPayload({ widgetPayload, WID });
  };
  const defaultHandlers = {
    delete: handleDelete,
    configure: handleWidgetConfiguration,
    configureColum: handleTableColumnConfiguration,
    addToHome: handleAddToHome,
    deleteFromHome: handleDeleteFromHome,
    toggleHeader: handleToggleShow,
    toggleBorder: handleBorderShow,
    fullView: handleFullView,
    pngExport: handleImgExport,
    pdfExport: handlePDFExport,
    excelExport: handleExcelExport,
    piviot: piviotTable,
    togglePivot: handleTogglePivot,
    pdfExportWithoutTab: handlePdfExportWithoutTab,
    PngTableExport: handlePngTableExport,
  };

  const handleMenuItemClick = (option) => {
    const { label, value, handler } = option;
    // if handler defined at option level or else default handler
    if (handler) {
      handler();
    } else {
      defaultHandlers[value]?.(value);
    }
  };

  if (error) {
    // console.log('error occured', error);
  }

  useEffect(() => {
    if (setZoomLevel) {
      setZoomLevel(definedZoomLevel || 1);
    }
  }, [definedZoomLevel]);
  // const zoom = Number(zoomLevel * 100).toFixed(0);
  const zoomProps = {
    hasZoomControl,
    setZoomLevel,
    zoomLevel,
    WID,
    componentData
  };

  useEffect(() => {
    // it has to be undefined because reset the reducer only when data is not previously in reducer
    if (componentData && isHideHeader[WID + activeTab?.id] === undefined) {
      dispatch(
        WidgetCreators.setHeaderHide({
          WID,
          WPID: activeTab?.id,
          isHideHeader: componentData?.isHideHeader
        })
      );
    }
    if (componentData && isHideBorder[WID + activeTab?.id] === undefined) {
      dispatch(
        WidgetCreators.setBorderHide({
          WID,
          WPID: activeTab?.id,
          isHideBorder: componentData?.isHideBorder
        })
      );
    }
    if (componentData && isEnablePivot[WID + activeTab?.id] === undefined) {
      dispatch(
        WidgetCreators.setEnablePivot({
          WID,
          WPID: activeTab?.id,
          isEnablePivot: componentData?.isEnablePivot
        })
      );
    }
  }, []);
  const nestedModalRef = useRef();

  /**
   * Function to call Api to Add Widget to HOme
   */
  const handleAddWidgetToHome = () => {
    const onSuccessNavigation = () => {
      if (!widgetsInHome.includes(WID)) {
        setWidgetsInHomeList([...widgetsInHome, WID]);
      } else {
        const newWidgetList = [...widgetsInHome];
        const index = newWidgetList.indexOf(WID);
        newWidgetList.splice(index, 1);
        setWidgetsInHomeList(newWidgetList);
      }
      getHomeLayout({ UserId: userData.userId });
    };
    const widgetPayload =
    {
      UserId: userData?.userId,
      WID: Number(WID),
      Url: location.pathname,
      WTIId
    };
    addWidgetFromPreview({ widgetPayload, onSuccessNavigation });
    setShowWidgetAdd(false);
  };

  /**
   * Function to Api Remove widget to Home
   */
  const handleRemoveWidgetFromHome = () => {
    // This is setting the widget Instance as soon as success
    const onSuccessNavigation = () => {
      if (!widgetsInHome.includes(WID)) {
        setWidgetsInHomeList([...widgetsInHome, WID]);
      } else {
        const newWidgetList = [...widgetsInHome];
        const index = newWidgetList.indexOf(WID);
        newWidgetList.splice(index, 1);
        setWidgetsInHomeList(newWidgetList);
      }
      getHomeLayout({ UserId: userData.userId });
    };
    const widgetPayload =
    {
      UserId: userData?.userId,
      WID: Number(WID),
    };
    deleteWidgetFromHome({ widgetPayload, onSuccessNavigation });
    setShowWidgetRemove(false);
  };

  if (fullView) return <></>;
  return (
    <HeaderContainer
      className='header'
      // style={!isHideHeader[WID] && { justifyContent: 'flex-end' }}
      alignEnd={!isHideHeader[WID + activeTab?.id]}
      style={isHideHeader[WID + activeTab?.id] ? { minHeight: '0', padding: '0' } : { minHeight: '5rem' }}
    >
      {!showOptions && <WidgetFullViewIcon style={{ top: !isHideHeader[WID + activeTab?.id] ? `${bottomFullView + 45}px` : `${bottomFullView}px`, right: isHideHeader[WID + activeTab?.id] ? `${leftFullView + 10}px` : `${leftFullView}px`, zIndex: 1 }} onClick={handleFullView}>
        <DynamicSVGIcon width='2rem' iconUrl={FullScreen} />
      </WidgetFullViewIcon>}
      {isInstancePop && widgetsInHome.includes(WID) &&
        <AddHomeIcon onClick={() => { handleDeleteFromHome(WID); }} style={{ top: !isHideHeader[WID + activeTab?.id] ? '42px' : '-5px', left: !isHideHeader[WID + activeTab?.id] ? '4px' : '-3px' }}>
          <DynamicSVGIcon width='2.5rem' iconUrl={RemoveFromHome} />
        </AddHomeIcon>}
      {/* ${id}::${WID}:: */}
      {!isHideHeader[WID + activeTab?.id] && (
        <WidgetHeader className='text-overflow'>{widgetPayload[WID]?.WidgetName || `${WidgetName}`}</WidgetHeader>
      )}
      {(WTIId && !isPreviewInstance && !WTid) || isTemplateConfigure ? (
        <FlexContainer style={isHideHeader[WID + activeTab?.id] ? showDeleteConfm ? { position: 'absolute', right: '20px', top: '10px' } : { position: 'absolute', right: '20px', top: '10px', zIndex: '201' } : { gap: '1.5rem' }}>
          {menu || (
            <>
              {/* <DropDownModal
                isActive={showOptions}
                data={widgetOptions}
                handleClick={handleMenuItemClick}
                singleSelection
                setModalIsVisibal={setModalIsVisibal}
                ContainerStyle={{ width: 'max-content', zIndex: 200 }}
                noContainerPadding
                extraMenu={<ZoomLevel {...zoomProps} />}
              >
                <div onClick={() => setShowOptions((e) => !e)} style={{ cursor: 'pointer' }}>
                  <DynamicSVGIcon width='2rem' rotate={90} iconUrl={Kabab} style={{ cursor: 'pointer' }} />
                </div>
              </DropDownModal> */}
              <div style={{ position: 'inherit', zIndex: '9', cursor: 'pointer' }} ref={nestedModalRef}>
                <NestedDropdown
                  width='auto'
                  data={widgetOptions}
                  handleClick={handleMenuItemClick}
                  noContainerPadding
                  setModalIsVisibal={setModalIsVisibal}
                  isActive={showOptions}
                  ContainerStyle={{
                    position: 'absolute',
                    width: 'max-content',
                    zIndex: '20'
                  }}
                  isNested={true}
                  showSelection={false}
                  extendLeft={nestedModalRef?.current?.getBoundingClientRect()?.left > 500} // if no enough space
                  extraMenu={<ZoomLevel {...zoomProps} />}
                >
                  <div onClick={() => setShowOptions((e) => !e)} style={{ cursor: 'pointer' }}>
                    <DynamicSVGIcon width='2rem' rotate={90} iconUrl={Kabab} style={{ cursor: 'pointer' }} />
                  </div>
                </NestedDropdown>
              </div>
              {/* delete confirmation modal */}
              <ConfirmationModal
                style={{ position: 'fixed', minWidth: '350px', left: '32%', top: '40%', }}
                showModal={showDeleteConfm}
                handleDelete={confirmDelete}
                closeModalHandler={() => setShowDeleteConfm(false)}
                message={`Do you really want to delete widget ${WidgetName}( Index=${id}, WID=${WID})`}
                buttonLoading={loading}
              />
            </>
          )}
        </FlexContainer>
      ) : (
        (isEditOn[activeTab?.id] || isEditOn[HDId]) && (
          <FlexContainer style={isHideHeader[WID + activeTab?.id] ? showDeleteConfm ? { position: 'absolute', right: '20px', top: '10px' } : { position: 'absolute', right: '20px', top: '10px', zIndex: '201' } : { gap: '1.5rem' }}>
            {menu || (
              <>

                {/* <NestedDropdown
                  isActive={showOptions}
                  data={widgetOptions}
                  handleClick={handleMenuItemClick}
                  singleSelection
                  setModalIsVisibal={setModalIsVisibal}
                  ContainerStyle={{ width: 'max-content', zIndex: 200 }}
                  noContainerPadding
                  extraMenu={<ZoomLevel {...zoomProps} />}
                > */}
                <div style={{ position: 'inherit', zIndex: '9', cursor: 'pointer' }} ref={nestedModalRef}>
                  <NestedDropdown
                    width='auto'
                    data={widgetOptions}
                    handleClick={handleMenuItemClick}
                    noContainerPadding
                    setModalIsVisibal={setModalIsVisibal}
                    isActive={showOptions}
                    ContainerStyle={{
                      position: 'absolute',
                      width: 'max-content',
                      zIndex: '20'
                    }}
                    isNested={true}
                    extendLeft={nestedModalRef?.current?.getBoundingClientRect()?.left > 500} // if no enough space
                    // activeItems={widgetEditOptions}
                    // isActiveChecker={activeChecker}
                    showSelection={false}
                    // itemIcons={itemIcons}
                    // extendLeft={nestedModalRef?.current?.getBoundingClientRect()?.left > 407} // if no enough space
                    // extendLeft={nestedModalRef.current?.offsetLeft > 254}
                    extraMenu={<ZoomLevel {...zoomProps} />}
                  >
                    <div onClick={() => setShowOptions((e) => !e)} style={{ cursor: 'pointer' }}>
                      <DynamicSVGIcon width='2rem' rotate={90} iconUrl={Kabab} style={{ cursor: 'pointer' }} />
                    </div>
                  </NestedDropdown>
                </div>

                {/* delete confirmation modal */}
                <ConfirmationModal
                  showModal={showDeleteConfm}
                  style={{ position: 'fixed', minWidth: '350px', left: '32%', top: '40%' }}
                  handleDelete={confirmDelete}
                  closeModalHandler={() => setShowDeleteConfm(false)}
                  message={`Do you really want to delete widget ${WidgetName}( Index=${id}, WID=${WID})`}
                  buttonLoading={loading}
                />
              </>
            )}
          </FlexContainer>
        )
      )}

      {error && (
        <Toast
          className='fixed'
          message={error}
          header='Error'
          fn={() => setError('')}
        />
      )}
      {showFullView && <FullViewWidget componentData={componentData} fullView={true} WID={WID} setShowFullView={setShowFullView} />}
      <ConfirmationModal
        showModal={showWidgetAdd}
        action={widgetsInHome.includes(WID) ? 'Remove' : 'Add'}
        Header="Are you Sure?"
        handleDelete={() => { handleAddWidgetToHome(); }}
        closeModalHandler={() => {
          setShowWidgetAdd(false);
        }}
        message={widgetsInHome.includes(WID) ? 'You want to Remove this Instance from Home' : 'You want to Add this Widget to Home'}
      />

      <ConfirmationModal
        showModal={showMaxError}
        action="Free Space"
        error={true}
        Header="Exceeding Limit!"
        handleDelete={() => {
          setshowMaxError(false);
        }}
        closeModalHandler={() => {
          setshowMaxError(false);
        }}
        message="Home reached maximum number of Widgets"
      />
      {exportXlxs && <ExportDashboard name={`${WidgetPanelName}_${WidgetName}`} setExportXlxs={setExportXlxs} WidgetComponentData={layoutComponentData[WID]} individualExport={true} WID={WID} />}
      <ConfirmationModal
        showModal={showWidgetRemove}
        action="Remove"
        Header="Are you Sure?"
        handleDelete={() => { handleRemoveWidgetFromHome(); }}
        closeModalHandler={() => {
          setShowWidgetRemove(false);
        }}
        message="You want to Remove this Instance from Home"
      />

    </HeaderContainer>
  );
};

export default WidgetHeaderView;
