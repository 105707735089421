import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UpsertWidget from '../../component/UpsertWidget/UpsertWidget';
import WidgetInstances from '../../component/UpsertWidget/WidgetInstances/WidgetInstances';
import TemplateFormView from '../../component/WidgetTemplate/TemplateForm/TemplateForm.view';
import TemplateInstanceForm from '../../component/WidgetTemplate/TemplateInstance/TemplateInstanceForm.view';
import WidgetTemplateInstances from '../../component/UpsertWidget/WidgetTemplateInstances/WidgetTemplateInstances';
import CustomWidgetDashboard from './CustomWidgetDashboard';
import ViewWidgetDashboard from './ViewWidgetDashboard';
import ViewWidgetDashboardPopUp from './ViewWidgetDashboardPopUp';
import WidgetInstancesList from '../../component/HomeDashboard/WidgetInstancesList.jsx';

const WidgetRoutes = [
  {
    path: '/home/create-widget',
    exact: false,
    name: 'create-widget',
    Component: UpsertWidget
  },
  {
    path: '/home/create-custom-dashboard',
    exact: true,
    name: 'create-custom-dashboard',
    Component: CustomWidgetDashboard
  },
  {
    path: '/home/view-dashboard',
    exact: true,
    name: 'view-dashboard',
    Component: ViewWidgetDashboard
  },
  {
    path: '/home/view-dashboard/:id',
    exact: true,
    name: 'view-dashboard-pop',
    Component: ViewWidgetDashboardPopUp
  },
  {
    path: '/home/find-widget-instance',
    exact: true,
    name: 'find-widget-instance',
    Component: WidgetInstances
  },
  {
    path: '/home/widget-template-instance-list',
    exact: true,
    name: 'widget-template-instance-list',
    Component: WidgetTemplateInstances
  },
  {
    path: '/home/create-template',
    exact: true,
    name: 'template-creation',
    Component: TemplateFormView
  },
  {
    path: '/home/edit-template',
    exact: true,
    name: 'template-edit',
    Component: TemplateFormView
  },
  {
    path: '/home/template-instance',
    exact: true,
    name: 'template-instance-create',
    Component: TemplateInstanceForm
  },
  {
    path: '/home/widget-instance',
    exact: true,
    name: 'widgetInstance-List',
    Component: WidgetInstancesList
  }
];

export default WidgetRoutes;
