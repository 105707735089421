/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import { chartCategoryMapping, chartLableMapping } from '../../../constants';

export const getPlotOptions = (enabledPanes, KPVShortlist) => {
  const plotMenuFilter = enabledPanes;
  const derivedChartOptions = plotMenuFilter.reduce(
    (prev, e) => ({
      ...prev,
      [chartLableMapping[e.key] || e.key]: {
        ...e,
        key: chartLableMapping[e.key] || e.key,
        value: chartLableMapping[e.key] || e.key, // undefined handling
        chartTypeCategory: chartCategoryMapping[e.type] || e.type, // exteranl internal mapping
        label: e.name
      }
    }),
    {}
  );
  return derivedChartOptions;
};
