/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAGE_TYPES } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { ConfirmationActionModal } from '../../Common/ConfirmationBox/ConfirmationBox.view';
import Toast, { TOAST_TYPES } from '../../Common/Toast';
import { saveAnalyticsPayload } from '../utility/saveAnalyticsPayload';

const SaveAnalyticsChanges = ({
  closeSaveConfirmationModal,
  showSaveConfirmation,
  setShowSavedashboard,
  shouldSaveCharts = true,
  ...reducerProps
}) => {
  const history = useHistory();
  const {
    cumulativeTags,
    filterData,
    form,
    menuIdofFilter,
    selectedParent,
    leafLevels,
    metaDetailsInShortlist,
    enabledPanes,
    KPVShortlist,
    userData,
    panesShortHandInfo,
    xMinDomain,
    xMaxDomain,
    xDomainDuration
  } = reducerProps;
  const myAnalyticsPaths = useSelector((state) => state.home?.[PAGE_TYPES.MY_ANAYLTICS]);
  const [err, setErr] = useState('');
  const handleSaveChanges = async () => {
    try {
      const requestPayload = saveAnalyticsPayload({
        cumulativeTags,
        filterData,
        form,
        menuIdofFilter,
        selectedParent,
        leafLevels,
        metaDetailsInShortlist: { enabledPanes, ...metaDetailsInShortlist },
        KPVShortlist,
        userData,
        panesShortHandInfo,
        xMinDomain,
        xMaxDomain,
        xDomainDuration,
        shouldSaveCharts
      });
      const url = apiEndPoints.callUpdateAnalytics;
      const response = await WebService.post(url, requestPayload);
      if (response.status >= 200 && response.status < 300) {
        if (!isEmpty(myAnalyticsPaths)) {
          const firstKey = Object.keys(myAnalyticsPaths)[0];
          history.push(myAnalyticsPaths[firstKey]);
        }
      } else {
        throw response;
      }
    } catch (error) {
      const payload = sagaCatchBlockHandling(error);
      setErr(payload.error);
      console.log('error', payload);
    }
  };

  return (
    <>
      <ConfirmationActionModal
        closeModalHandler={closeSaveConfirmationModal}
        showModal={showSaveConfirmation}
        action='Save'
        handleConfirm={handleSaveChanges}
        message='Do you want to apply the changes to the existing Chart?'
        middleBtnMsg='Save As'
        showMiddleBtn={form.isEditable === true || form.isEditable === 'True'}
        handleMiddleBtn={() => {
          closeSaveConfirmationModal();
          setShowSavedashboard(true);
        }}
      />
      {err && (
        <Toast message={err} header={TOAST_TYPES.ERROR} fn={() => setErr('')} style={{ bottom: 30, right: 10 }} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cumulativeTags: state.filterData.cumulativeTags,
  filterData: state.filterData.filterData,
  menuIdofFilter: state.filterData.menuIdofFilter,
  leafLevels: state.filterData.leafLevels,
  selectedParent: state.filterData.selectedParent,
  metaDetailsInShortlist: state.filterResultData.metaDetailsInShortlist,
  enabledPanes: state.filterResultData.enabledPanes,
  KPVShortlist: state.filterResultData.KPVShortlist?.[state.filterData.menuIdofFilter],
  panesShortHandInfo: state.KPVCharts.panesShortHandInfo,
  xMinDomain: state.KPVCharts.xMinDomain,
  xMaxDomain: state.KPVCharts.xMaxDomain,
  xDomainDuration: state.KPVCharts.xDomainDuration,
  form: state.myAnalytics.analyticsData,
  userData: state.loginData.userData
});
export default connect(mapStateToProps)(SaveAnalyticsChanges);
