/* eslint-disable max-len */
import React from 'react';
import { InputCotainer } from '../../Input/Input';

const TextTypeComponent = ({ item, value, textInputHandler, wd, placeholder, initialFormInput, formInput }) => {
  const { key, isReadOnly, inputType, allowEmpty, placeholder: itemPlaceholder } = item;
  return (
    <>
      <InputCotainer
        type={inputType}
        placeholder={placeholder || itemPlaceholder || ''}
        name='name'
        onChange={(e) => textInputHandler(e, key)}
        value={value}
        width={wd}
        readOnly={!allowEmpty && !initialFormInput?.[key] ? false : isReadOnly}
      />
    </>
  );
};
export default TextTypeComponent;
