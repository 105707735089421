/* eslint-disable prefer-template */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useFilteredSelectedItem = (keyItem) => {
  const { objectTreeData, tags, selectedItem } = useSelector((state) => state.objectTreeData);
  const objectTreeDataNew = objectTreeData.filter((item) => (item?.key === keyItem));

  // count of no of required groups to enable the gateway
  const totalLength = objectTreeDataNew?.reduce((count, grp) => {
    if (grp.type !== 'input' && (grp.isRequired === 'true' || grp.isRequired === true)) {
      return count + 1;
    }
    return count;
  }, 0);
  // completely selected groups
  const [filteredSelectedItem, setFilteredSelectedItem] = useState([]);
  useEffect(() => {
    const tempSelectedItem = [];
    // filteredSelectedItem is filter of selectedItem and will have only the groups which are present in filterData
    if (objectTreeDataNew) {
      objectTreeDataNew.forEach((ele) => {
        if (selectedItem.includes(ele.groupTitle) && ele.type !== 'input') tempSelectedItem.push(ele.groupTitle);
      });
    }
    setFilteredSelectedItem(tempSelectedItem);
  }, [selectedItem]);

  // extra layer of validation
  // cross validating the selections with tags
  const test =
    filteredSelectedItem.filter((item) => {
      const resultGroup = objectTreeDataNew.find(
        (q) => q.groupTitle === item && (q.isRequired === 'true' || q.isRequired === true)
      );
      return resultGroup?.values.find((qa) => '_' + qa.id === (tags[item] && tags[item]['0']?.id));
    }) || [];

  // either input should be filled or all the levels need to be selected
  const enableCondition = totalLength === test.length;

  // );
  return !enableCondition;
};

export default useFilteredSelectedItem;
