/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import GenericForm from '../../Common/GenericForm/GenericForm';

import { useActions } from '../../../store/hooks/useActions';
import ButtonComp, { ButtonContainer } from '../../Common/Button/Button.view';
import PageHeader from '../../Common/PageHeader/PageHeader';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
import { Creators as ObjectTreeCreators } from '../../WidgetForm/Store';
import { PAGE_TYPES } from '../../../constants';
import { Container } from './TemplateInstance.styles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Index from '../../Common/Input/Input';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import { genericFormValidation } from '../../Widget/utils/formValidation';
import { formatedFilterData } from '../../Filter/utility/formatFilterData';
import GenericFormTree from '../../Common/GenericForm/GenericFormTree';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../WidgetForm/utility/populateFilterDashboard';
import { Text } from '../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';

const TemplateInstanceForm = () => {
  const {
    templateFormStructure,
    templateFormStructureFieldDepenents,
    templateFormInput = {},
    templateInstanceFormInput = {},
    WTId,
    templateInstanceName,
    WTIId,
    templateBasedData
  } = useSelector((state) => state.upsertWidgetData);
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const WidgetRoutes = widgetDashboardType === 'ALL' ? useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]) : useSelector((state) => state.home?.[PAGE_TYPES.WIDGET_INSTANCE]);
  // const WidgetRoutes = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const history = useHistory();
  const {
    setTemplateFormStructure,
    getObjectTreeDataSuccess,
    updateWidgetPayload,
    valueSetter,
    widgetDetailSetter,
    setToastError,
    clearTreeData
  } = useActions();
  const location = useLocation();
  const [isBreakPointEnabled, setIsBreakPointEnabled] = useState();
  const [templateName, setTemplateName] = useState(templateInstanceName);

  // configureInstance is added only to check if filter option of template instance is clicked
  const { isPreSelection = false, isEdit, configureInstance, isPop = false, isConfigurableDashboard = false } = location.state;

  const breakpoint = useSelector((state) => state.filterData.breakpoint);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const widgetTypesIncluded = useSelector(
    (state) => state.widgets.widgetTypesIncluded
  );
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const objectTreeData = useSelector(
    (state) => state.objectTreeData.objectTreeData
  );
  const objectTreeDataLoading = useSelector(
    (state) => state.objectTreeData.loading
  );
  useEffect(() => {
    if (widgetTypesIncluded) {
      setTemplateFormStructure({ widgetTypesMenu, widgetTypesIncluded });
    }
  }, [widgetTypesIncluded, widgetTypesMenu]);

  const arr = {};
  const [showSave, setShowSave] = useState(false);

  // useEffect(() => {
  //   if (isPop) {
  //     if (!templateFormInput || isEmpty(templateFormInput) || isEmpty(widgetTypesIncluded)) {
  //       history.goBack();
  //     }
  //   }
  // }, [templateFormInput]);

  useEffect(() => {
    const dataObj = {};
    // eslint-disable-next-line array-callback-return
    templateFormStructure?.common?.map((item) => {
      dataObj[item?.key] = [{ ...item }];
    });
    const { formatedData, keyTitleMapping } = formatedFilterData(dataObj);
    const resultType = 'iframe';
    getObjectTreeDataSuccess({
      data: formatedData,
      resultType,
      keyTitleMapping
    });
  }, [templateFormStructure]);

  const categories = !isEmpty(templateFormStructure)
    ? Object.entries(templateFormStructure)
    : [];
  const refs = useRef([]);
  const assignRef = (index) => (element) => {
    refs.current[index] = element;
  };
  const [proceedToDashboard, setProceedToDashboard] = useState(false);
  useEffect(() => {
    if (proceedToDashboard) {
      history.push(WidgetRoutes[menuIdofWidget]);
    }
  }, [proceedToDashboard]);
  const handleSave = async () => {
    try {
      if (isPreSelection) {
        history.push({
          pathname: '/home/template-instance',
          state: { isPreSelection: false, isEdit }
        });
        valueSetter({
          templateInstanceFormInput: {
            ...templateInstanceFormInput,
            breakpoint
          }
        });
      } else {
        const formInput = refs.current.reduce((acc, e, index) => {
          const res = e?.getFormInput();
          return {
            ...acc,
            [categories[index]?.[0]]: res
          };
        }, {});
        const payload = { ...templateInstanceFormInput, formInput };
        valueSetter({ templateInstanceFormInput: payload });

        // added to show data on edit
        widgetDetailSetter({
          key: 'WidgetComponentData',
          value: payload
        });
        widgetDetailSetter({
          key: 'configureInstance',
          value: configureInstance
        });
        widgetDetailSetter({
          key: 'isTemplateComponentUpdateReq',
          value: false
        });

        if (WTIId && !isEdit) {
          // if it is just filter adjustment
          widgetDetailSetter({
            key: 'WidgetComponentData',
            value: payload
          });
          widgetDetailSetter({
            key: 'isTemplateComponentUpdateReq',
            value: false
          });
          if (isPop) {
            history.goBack();
          } else {
            setProceedToDashboard(true);
          }
        } else if (WTIId) {
          // template instance updation
          widgetDetailSetter({
            key: 'WidgetComponentData',
            value: payload
          });
          widgetDetailSetter({
            key: 'isTemplateComponentUpdateReq',
            value: false
          });
          history.push({
            pathname: '/home/create-custom-dashboard',
            state: { isPreview: true, isTemplateInstanceEdit: isEdit, isConfigurableDashboard }
          });
        } else {
          // template instance creation
          history.push({
            pathname: '/home/create-custom-dashboard',
            state: { isPreview: true }
          });
        }
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
      console.log('error', e);
    }
  };

  const dispatch = useDispatch();
  // init template breakpoint to filter breakpoint
  useEffect(() => {
    dispatch(
      ObjectTreeCreators.universalObjectTreeSetter({
        key: 'breakpoint',
        value: typeof templateInstanceFormInput === 'string'
          ? JSON.parse(templateInstanceFormInput)?.breakpoint

          : templateInstanceFormInput?.breakpoint
      })
    );
  }, [templateInstanceFormInput]);

  useEffect(() => {
    if (isEmpty(templateBasedData)) {
      clearTreeData();
    }
  }, []);

  // useEffect(() => {
  //   setIsBreakPointEnabled(isEmpty(templateBasedData));
  //   if (!isEmpty(templateBasedData)) {
  //     if (templateBasedData?.Plant) {
  //       dispatch(ObjectTreeCreators.UniversalReducerSetterTree(templateBasedData?.Plant));
  //     }
  //     dispatch(
  //       ObjectTreeCreators.universalObjectTreeSetter({
  //         key: 'breakpoint',
  //         value: templateBasedData?.breakpoint
  //       })
  //     );
  //     valueSetter({ templateInstanceFormInput: { ...templateInstanceFormInput, breakpoint: templateBasedData?.breakpoint } });
  //   }
  // }, [templateBasedData]);

  useEffect(() => {
    if (!isEdit || !configureInstance) {
      setIsBreakPointEnabled(isEmpty(templateBasedData));
      if (!isEmpty(templateBasedData) && !isEmpty(objectTreeData)) {
        const filterTree = objectTreeData.reduce((acc, cur) => {
          const { keyName } = cur;
          return {
            ...acc,
            [keyName]: [cur]
          };
        }, {});
        // console.log(filterTree, 'fil');
        const check = {
          data: {
            tree: [
              {
                ...templateBasedData?.formInput?.common?.attribute[0]?.value?.tree
              }
            ]
          }
        };
        delete check?.data?.tree[0]?.id;
        const { cumTags, cumSelectedObj } = populateFilterDashboard(
          check,
          filterTree
        );
        const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
        dispatch(ObjectTreeCreators.PopulateTreeStructure(payload));
        dispatch(
          ObjectTreeCreators.universalObjectTreeSetter({
            key: 'breakpoint',
            value: templateBasedData?.breakpoint
          })
        );
        valueSetter({
          templateInstanceFormInput: {
            ...templateInstanceFormInput,
            breakpoint: templateBasedData?.breakpoint
          }
        });
      }
    }
  }, [templateBasedData, objectTreeData, isEdit, configureInstance]);

  const handleTemplateInstanceName = (name) => {
    valueSetter({ templateInstanceName: name });
  };
  const handleBack = () => {
    valueSetter({ templateInstanceName: templateName });
    history.goBack();
  };
  const labelWidth = '25rem';
  const isSaveEnabled = useMemo(
    () =>
      categories?.every((e, index) => {
        const [category, fieldSet] = e;
        const filteredFieldSet = fieldSet.filter(
          (field) => templateFormInput[field?.key]
        );
        return genericFormValidation(filteredFieldSet, templateFormInput);
      }),
    [categories]
  );

  return (
    <>
      <PageHeader
        hasBack={true}
        handleBack={handleBack}
        title={`${isEdit ? 'Edit Instance' : WTIId ? 'Tab Filter' : 'Add Instance'
        } `}
      >
        <ButtonContainer>
          <ButtonComp onClick={handleBack}>
            {isPreSelection ? 'Discard' : 'Back'}
          </ButtonComp>
          <ButtonComp
            disabled={!(templateInstanceName && isSaveEnabled && showSave && !objectTreeDataLoading)}
            dark
            onClick={handleSave}
          >
            {WTIId ? 'Apply' : 'Save'}
          </ButtonComp>
        </ButtonContainer>
      </PageHeader>
      <Container>
        {/* <InputField value='' setValue={() => {}} /> */}
        {!isPreSelection && (
          <KeyInputLayoutView
            labelWidth={labelWidth}
            label='Name'
            className='add_equipment minWidth'
          >
            <Index
              width='30rem'
              value={templateInstanceName}
              setValue={handleTemplateInstanceName}
              disabled={WTIId && !isEdit}
              maxLength={200}
            />
          </KeyInputLayoutView>
        )}
        {isEmpty(templateFormInput) || isEmpty(templateFormStructure?.common) ? <Text className='decription center'>No Instance Data To Apply Filter</Text> : categories.map((e, index) => {
          const [category, fieldSet] = e;
          const filteredFieldSet = fieldSet.filter(
            (field) => templateFormInput[field?.key]
          );
          // console.log('templateFormInput', JSON.parse(templateInstanceFormInput)?.formInput?.[category]);
          // typeof (templateInstanceFormInput) === 'string'

          return (
            <>
              <GenericFormTree
                allowHorizontal
                allowFieldSetWrap={false}
                hasPreSelection={true}
                isPreSelection={isPreSelection}
                isInstanceCreation={true}
                isbreakPointEnabled={isBreakPointEnabled}
                ref={assignRef(index)}
                formInput={
              typeof templateInstanceFormInput === 'string'
                ? JSON.parse(templateInstanceFormInput)?.formInput?.[
                  category
                ]
                : templateInstanceFormInput?.formInput?.[category]
            }
                formStructureFieldDepenents={
              templateFormStructureFieldDepenents[category] || {}
            }
                formStructure={filteredFieldSet}
                templateFormInput={templateFormInput}
                setIsFormFilled={(res) => {
                  arr[category] = res;
                  if (Object.values(arr).includes(false)) {
                    setShowSave(false);
                  } else {
                    setShowSave(true);
                  }
                }}
                labelWidth={labelWidth}
                configureInstance={configureInstance || isEdit}
                ignoreActiveOption={!WTIId}
              />
            </>
          );
        })}
      </Container>
    </>
  );
};

export default TemplateInstanceForm;
