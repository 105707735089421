/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { CHART_TYPES, CHART_TYPE_CATEGORY, resolveChartCategory } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { kpvData } from './kpvGraphData';
import { createPaneMenu, editPaneMenu } from '../EditOptions/sampleData';
import { show_widgets, company } from '../../../config.json';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { plotData } from '../Chart/LabelChart/data';
// import { sampleData } from '.';
// import { sampleKpvData } from './kpvGraphData';
// import { isEmpty } from '../../../shared/utility/isEmpty';
// import { kpvData } from './kpvGraphData';

export function* getPlotDataSaga({ payload, isUpdate = false, index, currentPaneInfoObj, additionalInfo = {} }) {
  try {
    // check for latestAvailable param
    const { latestAvailable } = additionalInfo;
    // console.log('latestAvailable', latestAvailable);
    yield put(Creators.getPlotDataStart({ isSingleUpdate: index > -1 }));
    // console.log('payload--SAGA', payload, isUpdate);
    const fullURL = `${apiEndPoints.getPlotData}`;
    // for testing error handling
    // const fullURL = 'http://35.161.23.251/flapi/api';

    let additionalFilterAvailable = false;
    const responses = yield Promise.all(
      payload.map((pl) => {
        if (pl.additionalFilter || pl.categoryOptions) {
          additionalFilterAvailable = true;
        }
        const { plotDataAvl, singlePane, spcDetails, ...rest } = pl;
        const { chartTypeCategory, kpvDetails } = rest;
        let reqPayload = { ...rest, spc: spcDetails, latestAvailable };
        if (latestAvailable) {
          // remove start and end time if latestAvailable is available
          const { StartTime, EndTime, ...rest1 } = rest;
          reqPayload = { ...rest1, spc: spcDetails, latestAvailable };
        }
        return WebService.post(fullURL, reqPayload);
      })
    );

    // [kpvData];
    // console.log('responses', responses);

    // else if (additionalFilter) {
    //   const { StartTime, EndTime } = responses?.[0]?.data;
    //   yield put(Creators.setDomainRange({ latestAvailable: 30 }));
    //   // set the startTime and endTime from the response
    //   yield put(Creators.setDomainRange({ xMinDomain: StartTime, xMaxDomain: EndTime }));
    // }

    const response = {};
    response.status = 200;
    if (latestAvailable) {
      const { StartTime, EndTime } = responses?.[0]?.data;
      // set the startTime and endTime from the response
      yield put(Creators.setDomainRange({ xMinDomain: StartTime, xMaxDomain: EndTime }));
    } else {
      // remove latestAvailable
      yield put(Creators.setDomainRange({ latestAvailable: null }));
      const { StartTime, EndTime } = responses?.[0]?.data;
      // set the startTime and endTime from the response

      if (!additionalFilterAvailable) {
        yield put(Creators.setDomainRange({ xMinDomain: StartTime, xMaxDomain: EndTime }));
      }
    }
    response.data = responses?.map((res, index) => {
      if (!(res.status >= 200 && res.status < 300)) {
        response.status = res.status;
        throw res;
      }
      // console.log('payload[index]', payload[index]);
      const { StartTime, EndTime } = res?.data;
      // set the startTime and endTime from the response
      let reqPayload;
      const {
        chartType,
        chartTypeCategory,
        // datachart
        kpvDetails,
        // transitionchartq
        stateDetails,
        // spc charts,
        spcDetails
      } = payload[index];
      if (chartTypeCategory === CHART_TYPE_CATEGORY.DATA_CHART || chartType === CHART_TYPES.DATA_CHART) {
        if (StartTime && EndTime) {
          reqPayload = {
            ...payload[index],
            kpvDetails: kpvDetails.map((e) => ({ ...e, kpvId: e.kpvId || e.KPVID })),
            xMinDomain: StartTime,
            xMaxDomain: EndTime
          };
        } else {
          reqPayload = {
            ...payload[index],
            kpvDetails: kpvDetails.map((e) => ({ ...e, kpvId: e.kpvId || e.KPVID })),
          };
        }
      } else if (
        chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART ||
        chartType === CHART_TYPES.TRANSITION_CHART
      ) {
        reqPayload = {
          ...payload[index],
          stateDetails: stateDetails.map((e) => ({ ...e, macId: e.macId || e.id }))
        };
      } else if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
        reqPayload = {
          ...payload[index],
          spcDetails: spcDetails.map((e) => ({ ...e, kpvId: e.kpvId || e.KPVID }))
        };
      } else if (
        chartTypeCategory === CHART_TYPE_CATEGORY.LABEL ||
        chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART
      ) {
        const category = resolveChartCategory[chartTypeCategory];
        // local
        // const label = [payload[0]?.yKPVs.reduce((acc, e) => ({ ...acc, [e.KPVID]: plotData[e.KPVID] }), {})];
        // response.data = { category, [category.toLocaleLowerCase()]: label };
        const resData = { category, [category.toLocaleLowerCase()]: [res.data] };
        return { ...resData, reqPayload: payload[index] };
      }
      return { ...res.data, reqPayload };
    });
    // console.log('res', responses, response.data);

    yield put(Creators.addPaneDetailsSuccess({ data: response.data, isUpdate, index, currentPaneInfoObj }));
    yield put(Creators.setGraphFilterDetails({ data: response.data, singleGraph: false }));
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      console.log(error);
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlotDataFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlotDataFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPlotDataFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlotDataFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: 'Inavlid Response'
      };

      yield put(Creators.getPlotDataFailure(payload));
    }
  }
}

export function* getPaneOptionsSaga({ payload }) {
  try {
    const { key, chartType } = payload;
    yield put(Creators.setPaneOptionsStart());
    let finalUrl;
    if (key === 'upsertPaneOptions') {
      finalUrl = apiEndPoints.getInsertPaneOptions;
    } else {
      finalUrl = apiEndPoints.getEditPaneMenu;
    }

    // yield put(
    //   Creators.setPaneOptionsSuccess({
    //     key,
    //     value: sampleData,

    //     // chartType used here is chartTypeCategory actually
    //     // value: key === 'upsertPaneOptions' ? createPaneMenu[chartType] : editPaneOptions,
    //     // value: response.data.data[CHART_TYPES.DATA_CHART],
    //     chartType
    //   })
    // );

    const response = yield WebService.get(finalUrl, {
      params: {
        company,
        chartType
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const finalResponse = show_widgets
        ? response.data.data[chartType]
        : response.data.data[chartType]?.filter((e) => e.id !== '1-10'); // "filtering Add as widget"
      yield put(
        Creators.setPaneOptionsSuccess({
          key,
          value: finalResponse,

          // chartType used here is chartTypeCategory actually
          // value: key === 'upsertPaneOptions' ? createPaneMenu[chartType] : editPaneOptions,
          // value: response.data.data[CHART_TYPES.DATA_CHART],
          chartType
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = sagaCatchBlockHandling(error);
    yield put(Creators.setPaneOptionsFailure(payload.error));
  }
}
