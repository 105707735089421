/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ForwardNavArrowBlack } from '../../../assets/icons/KPV';
import { Divider, DividerContainer, Icon, InnerTitle, Title } from './CollapsableContainer.style';
import { Creators as UpsertPaneCreators } from '../Store';
import { CHARTS_WITH_NO_SINGLE_PANE_SUPPORT, CHART_TYPES } from '../../../constants';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import MutliSelectToggler from './MutliSelectToggler';

const CollapsableContainer = (props) => {
  const { header, value, children, chartTypeCategory, showKPVMultiSelectToggle, showKPVMultiSelectToggleIMP } = props;
  const chartType = useSelector((state) => state.upsertPaneData.chartType);
  const [showMenu, setShowMenu] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chartType === CHART_TYPES.TRANSITION_CHART) {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'multiSelectKpv',
          value: true
        })
      );
    }
  }, [chartType]);

  return (
    <>
      <DividerContainer>
        <Divider />
      </DividerContainer>

      <Title>
        <InnerTitle onClick={() => setShowMenu(!showMenu)}>
          <Icon rotate={90} width={1.5} src={ForwardNavArrowBlack}>
            <DynamicSVGIcon iconUrl={ForwardNavArrowBlack} width='1.5rem' />
          </Icon>
          {header}
        </InnerTitle>
        {value === 'customiseKpvs' && (
          // && chartType !== CHART_TYPES.TRANSITION_CHART
          <>
            {!CHARTS_WITH_NO_SINGLE_PANE_SUPPORT.includes(chartTypeCategory) && showKPVMultiSelectToggle && (
              <MutliSelectToggler />
            )}
          </>
        )}
        {showKPVMultiSelectToggleIMP && <MutliSelectToggler />}
      </Title>
      {showMenu && <div>{children}</div>}
    </>
  );
};

export default memo(CollapsableContainer);
