/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from '../../../../globalStyles';
import kababIcon from '../../../../assets/icons/Filter/kabab.svg';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';
import { TabUi } from '../../../Common/TabUi/TabUi';
import { apiData } from '../../../../views/Widgets/WidgetComponents/GaugeChart/data';
import { ContainerBody } from '../../../Widget/Common/WidgetCommons.style';
import { ChartDiv, Text, ChartContainer } from '../../../Widget/WidgetComponents/GaugeChart/GaugeChart.style';
import GaugeChartWidget from '../../../../views/Widgets/WidgetComponents/GaugeChart/GaugeChart';

const GaugeChart = () => {
  const { data, meta } = useSelector((state) => state.upsertWidgetData);
  // const { data, meta } = apiData;
  return (
    <ContainerBody>
      <FlexContainer className='jc_spacebetween ai_center'>
        <Text>Gauge</Text>
      </FlexContainer>
      <GaugeChartWidget data={data} meta={meta} />
    </ContainerBody>
  );
};

export default GaugeChart;
