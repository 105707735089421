import styled from 'styled-components';
import { fontFamily } from '../../../constants';

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 2rem;

  &.active {
    & div {
      color: ${({ theme }) => theme.specificDetails.primary};
    }
    & * {
      fill: ${({ theme }) => theme.specificDetails.primary} !important;
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 0px;
  & > * {
    fill: ${({ theme }) => theme.contrast.primary};
  }
`;

export const ItemText = styled.div`
  padding-left: 2.5rem;
  color: ${({ theme }) => theme.contrast.primary};
  font: 1.8rem ${fontFamily.circularBook};
  text-transform: capitalize;
`;
