export const kpvData = {
  data: {
    RequestId: 'anything',
    chartType: 'xBarRchart',
    category: 'SPC',
    ChartTitle: 'XBar-R Chart',
    spc: [
      {
        KPV_P1_615_77: {
          data: {
            xbar: [
              {
                X: '1',
                Y: '213.12'
              },
              {
                X: '2',
                Y: '213.43'
              },
              {
                X: '3',
                Y: '213.13'
              },
              {
                X: '4',
                Y: '213.52'
              },
              {
                X: '5',
                Y: '213.62'
              },
              {
                X: '6',
                Y: '204.45'
              }
            ],
            xmean: [
              {
                X: '1',
                Y: '203.32'
              },
              {
                X: '2',
                Y: '203.32'
              },
              {
                X: '3',
                Y: '203.32'
              },
              {
                X: '4',
                Y: '203.32'
              },
              {
                X: '5',
                Y: '203.32'
              },
              {
                X: '6',
                Y: '203.32'
              }
            ],
            lcl: [
              {
                X: '1',
                Y: '157.33'
              },
              {
                X: '2',
                Y: '157.33'
              },
              {
                X: '3',
                Y: '157.33'
              },
              {
                X: '4',
                Y: '157.33'
              },
              {
                X: '5',
                Y: '157.33'
              },
              {
                X: '6',
                Y: '157.33'
              }
            ],
            ucl: [
              {
                X: '1',
                Y: '262.99'
              },
              {
                X: '2',
                Y: '262.99'
              },
              {
                X: '3',
                Y: '262.99'
              },
              {
                X: '4',
                Y: '262.99'
              },
              {
                X: '5',
                Y: '262.99'
              },
              {
                X: '6',
                Y: '262.99'
              }
            ],
            lsl: [
              {
                X: '1',
                Y: '80'
              },
              {
                X: '4',
                Y: '80'
              },
              {
                X: '8',
                Y: '80'
              },
              {
                X: '10',
                Y: '80'
              }
            ],
            usl: [
              {
                X: '1',
                Y: '100'
              },
              {
                X: '4',
                Y: '100'
              },
              {
                X: '8',
                Y: '100'
              },
              {
                X: '10',
                Y: '100'
              }
            ],
            range: [
              {
                X: '1',
                Y: '125.44'
              },
              {
                X: '2',
                Y: '125.44'
              },
              {
                X: '3',
                Y: '121.34'
              },
              {
                X: '4',
                Y: '113.34'
              },
              {
                X: '5',
                Y: '123.34'
              },
              {
                X: '6',
                Y: '120.34'
              }
            ],
            rmean: [
              {
                X: '1',
                Y: '121.42'
              },
              {
                X: '2',
                Y: '127.42'
              },
              {
                X: '3',
                Y: '127.42'
              },
              {
                X: '4',
                Y: '127.42'
              },
              {
                X: '5',
                Y: '127.42'
              },
              {
                X: '6',
                Y: '127.42'
              }
            ],
            rlcl: [
              {
                X: '1',
                Y: '0'
              },
              {
                X: '2',
                Y: '0'
              },
              {
                X: '3',
                Y: '0'
              },
              {
                X: '4',
                Y: '0'
              },
              {
                X: '5',
                Y: '0'
              },
              {
                X: '6',
                Y: '0'
              }
            ],
            rucl: [
              {
                X: '1',
                Y: '150.47'
              },
              {
                X: '2',
                Y: '150.47'
              },
              {
                X: '3',
                Y: '150.47'
              },
              {
                X: '4',
                Y: '150.47'
              },
              {
                X: '5',
                Y: '150.47'
              },
              {
                X: '6',
                Y: '150.47'
              }
            ],
            aOSL: [
              {
                X: '5',
                Y: '213.62'
              }
            ],
            aOCL: [
              {
                X: '3',
                Y: '213.13'
              },
              {
                X: '6',
                Y: '204.45'
              }
            ],
            aFreakI: [],
            aFreakII: [],
            aTrend: [],
            aShift: [],
            aOsc: [],
            aStrat: [],
            aMix: []
          },
          xlabels: {
            1: {
              X: '1',
              PointLabel: '1 - 2022-03-03 12:26:00.00',
              TSValue: '2022-03-03 12:26:00.00'
            },
            2: {
              X: '2',
              PointLabel: '2 - 2022-03-03 12:27:00.00',
              TSValue: '2022-03-03 12:27:00.00'
            },
            3: {
              X: '3',
              PointLabel: '3 - 2022-03-03 12:28:00.00',
              TSValue: '2022-03-03 12:28:00.00'
            },
            4: {
              X: '4',
              PointLabel: '4 - 2022-03-03 12:29:00.00',
              TSValue: '2022-03-03 12:29:00.00'
            },
            5: {
              X: '5',
              PointLabel: '5 - 2022-03-03 12:30:00.00',
              TSValue: '2022-03-03 12:30:00.00'
            },
            6: {
              X: '6',
              PointLabel: '6 - 2022-03-03 12:31:00.00',
              TSValue: '2022-03-03 12:31:00.00'
            }
          },
          xOptions: ['TSValue', 'PointLabel'],
          meta: {
            fields: {
              key: 'KPV_P1_615_77',
              displayname: 'KPV_P1_615_77 THO>>1FAM0103>>KPV>>2D-Code Reader Response (IN)',
              unit: '',
              Message: 'All Points',
              Limits: [],
              Labels: [],
              default: ['X-Bar', 'Range']
            },
            // mapping: {
            //   pl: 'PointLabel',
            //   ts: 'TSValue'
            // },
            alarmOverlays: [
              {
                'Outside Specification Limit': {
                  grid: '1',
                  node: 'aOSL',
                  name: 'Outside Specification Limit',
                  description: '1 Point outside Specification limit',
                  color: '#FF0000'
                },
                'Outside Control Limit': {
                  grid: '1',
                  node: 'aOCL',
                  name: 'Outside Control Limit',
                  description: '1 Point outside 3 Sigma limit',
                  color: '#00FF00'
                },
                'Freak I': {
                  grid: '1',
                  node: 'aFreakI',
                  name: 'Freak I',
                  description: 'm out of n Points beyond the 2 Sigma limit, same side',
                  color: '#0000FF'
                },
                'Freak II': {
                  grid: '1',
                  node: 'aFreakII',
                  name: 'Freak II',
                  description: 'm out of n Points beyond the 1 Sigma limit, same side',
                  color: '#660000'
                },
                Trend: {
                  grid: '1',
                  node: 'aTrend',
                  name: 'Trend',
                  description: 'K Points Consecutive all Increasing or all Decreasing',
                  color: '#FF9999'
                },
                Shift: {
                  grid: '1',
                  node: 'aShift',
                  name: 'Shift',
                  description: 'K Consecutive Points on one side of centerline',
                  color: '#FF66B2'
                },
                Oscillation: {
                  grid: '1',
                  node: 'aOsc',
                  name: 'Oscillation',
                  description: 'N Consecutive Points alternating up and down',
                  color: '#003366'
                },
                Stratification: {
                  grid: '1',
                  node: 'aStrat',
                  name: 'Stratification',
                  description: 'N Consecutive Points within zone C either side',
                  color: '#FFB266'
                },
                Mixture: {
                  grid: '1',
                  node: 'aMix',
                  name: 'Mixture',
                  description: 'N Consecutive Points beyond zone C either side',
                  color: '#CC6600'
                }
              }
            ],
            menuOverlays: [
              {
                'X-Bar': [
                  {
                    grid: '1',
                    node: 'xbar',
                    Text: 'X-Bar',
                    linetype: 'SOLID',
                    color: '#FF0000'
                  }
                ],
                'X-BAR Mean': [
                  {
                    grid: '1',
                    node: 'xmean',
                    Text: 'X-Bar Mean',
                    linetype: 'SOLID',
                    color: '#00FF00'
                  }
                ],
                'XBar Control Limits': [
                  {
                    grid: '1',
                    node: 'lcl',
                    Text: 'LCL',
                    linetype: 'SOLID',
                    color: '#990000'
                  },
                  {
                    grid: '1',
                    node: 'ucl',
                    Text: 'UCL',
                    linetype: 'SOLID',
                    color: '#990000'
                  }
                ],
                'XBar Spec Limits': [
                  {
                    grid: '1',
                    node: 'lsl',
                    Text: 'LSL',
                    linetype: 'SOLID',
                    color: '#3333FF'
                  },
                  {
                    grid: '1',
                    node: 'usl',
                    Text: 'USL',
                    linetype: 'SOLID',
                    color: '#3333FF'
                  }
                ],
                Range: [
                  {
                    grid: '2',
                    node: 'range',
                    Text: 'Range',
                    linetype: 'SOLID',
                    color: '#FF0000'
                  }
                ],
                'Range Mean': [
                  {
                    grid: '2',
                    node: 'rmean',
                    Text: 'R-Mean',
                    linetype: 'SOLID',
                    color: '#00FF00'
                  }
                ]
              }
            ],
            annotations: [
              {
                Annotation: 'Test By Lino 14:39',
                TSValue: '2022-03-03T07:01:00.000Z',
                UpdatedBy: 'lino.thomas@vistrian.com'
              }
            ]
          }
        }
      }
    ]
  },
  status: 200,
  statusText: 'OK',
  headers: {
    'content-length': '3688',
    'content-type': 'application/json'
  },
  config: {
    url: 'http://35.161.23.251/flapi/api/getGraphData/',
    method: 'post',
    data: '{"RequestId":"anything","chartType":"dataChart","category":"KPV","kpvDetails":[{"kpvId":"KPV_P1_5224_1","machineId":8776,"machineName":"1FAM0103","groupName":"KPV","kpvName":"2D-Code Reader Response (IN) ","macId":"P1_8776","KPVID":"KPV_P1_5224_1","StartTime":"2022-02-22 09:00:00","EndTime":"2022-02-22 09:30:00"},{"kpvId":"KPV_P1_5224_18","machineId":8776,"machineName":"1FAM0103","groupName":"KPV","kpvName":"Clamp Pressure HF-1 ","macId":"P1_8776","KPVID":"KPV_P1_5224_18","StartTime":"2022-02-22 09:00:00","EndTime":"2022-02-22 09:30:00"},{"kpvId":"KPV_P1_5224_19","machineId":8776,"machineName":"1FAM0103","groupName":"KPV","kpvName":"Clamp Pressure HF-2 ","macId":"P1_8776","KPVID":"KPV_P1_5224_19","StartTime":"2022-02-22 09:00:00","EndTime":"2022-02-22 09:30:00"}],"StartTime":"2022-02-22 09:00:00","EndTime":"2022-02-22 09:30:00"}',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    baseURL: 'http://35.161.23.251/flapi/api',
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1,
    maxBodyLength: -1
  },
  request: {}
};

export const kpvChartingMenu = [
  {
    label: 'Switch to {{view}} mode',
    role: 'comparisionMode',
    id: 1
  },
  {
    label: 'Save Analytics',
    role: 'saveAnalytics',
    id: 2
  },
  // {
  //   label: 'print analytics',
  //   role: 'printAnalytics',
  //   id: 3
  // },
  // {
  //   label: 'share analytics',
  //   role: 'shareAnalytics',
  //   id: 4
  // },
  {
    label: 'export analytics',
    role: 'exportAnalytics',
    id: 5
  }
  // {
  //   label: 'clear chart',
  //   role: 'clearChart',
  //   id: 6
  // },
];

export const kpvChartingMenuWithoutComparison = [
  {
    label: 'Save Analytics',
    role: 'saveAnalytics',
    id: 2
  },
  // {
  //   label: 'print analytics',
  //   role: 'printAnalytics',
  //   id: 3
  // },
  // {
  //   label: 'share analytics',
  //   role: 'shareAnalytics',
  //   id: 4
  // },
  {
    label: 'export analytics',
    role: 'exportAnalytics',
    id: 5
  }
  // {
  //   label: 'clear chart',
  //   role: 'clearChart',
  //   id: 6
  // },
];

export const menuInit = [
  { label: '5 minutes', value: { shorthand: 'm', shorthandValue: 5 } },
  { label: '15 minutes', value: { shorthand: 'm', shorthandValue: 15 } },
  { label: '30 minutes', value: { shorthand: 'm', shorthandValue: 30 } },
  { label: '1 hour', value: { shorthand: 'h', shorthandValue: 1 } },
  { label: '3 hours', value: { shorthand: 'h', shorthandValue: 3 } },
  { label: '6 hours', value: { shorthand: 'h', shorthandValue: 6 } },
  { label: '12 hours', value: { shorthand: 'h', shorthandValue: 12 } },
  { label: '24 hours', value: { shorthand: 'h', shorthandValue: 24 } }
];
