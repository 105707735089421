import React, { useEffect } from 'react';
import { useActions } from '../../store/hooks/useActions';
import WidgetLayout from './WidgetLandingPage';
import { FlexContainer } from '../../globalStyles';

const InstanceOnlyDashboard = (props) => {
  const { menuId } = props;
  const { setMenuIdOfWidget, setWidgetPageType } = useActions();
  useEffect(() => {
    setMenuIdOfWidget(menuId);
    setWidgetPageType('INSTANCE_ONLY');
  }, [menuId]);

  return (
    <FlexContainer className='col' style={{ height: '100%', padding: '0rem 1.5rem' }}>
      <WidgetLayout menuId={menuId} />
    </FlexContainer>
  );
};

export default InstanceOnlyDashboard;
