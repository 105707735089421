import styled from 'styled-components';
import { fontFamily } from '../../../../constants';
import { FlexContainer } from '../../../../globalStyles';

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* justify-content: flex-start; */}
  font-size: 2.6rem;
  font-family: ${fontFamily.circularBold};
  text-transform: capitalize;
`;

export const Span = styled.span`
  color: #000000;
  font-weight: bold;
  font-size: 1.6rem;
  font-family: ${fontFamily.circularBold};
  &.p_qunatity {
    font-size: 1.7rem;
  }
  &.label {
    font-size: 1.7rem;
    font-family: ${fontFamily.circularBold};
  }
`;

export const RatingSpan = styled.span`
  text-align: center;
  font-size: 1.6rem;
  font-family: ${fontFamily.circularMedium};
  &.hit_rate {
    font-size: 1.9rem;
  }
`;

export const TextBox = styled(FlexContainer)`
  justify-content: flex-start;
  margin: 0 8% 0 8%;
  position: relative;
  bottom: 3rem;
  gap: 29%;
`;
