/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { REPEATED_WIDGETS, SHARED_LAYOUT } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';

export function* getWidgetTabsSaga({ payload }) {
  const { menuId, userId, makeFistTabActive, idParam } = payload;
  try {
    yield put(Creators.getWidgetTabsStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = yield `${apiEndPoints.getWidgetPanels}`;
    const response = yield WebService.get(fullURL, {
      params: {
        SubMenuId: menuId,
        UserId: userId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log('data', data[0]);
      yield put(
        Creators.getWidgetTabsSuccess({
          makeFistTabActive,
          tabList: data[0],
          idParam
        })
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.getWidgetTabsFailure(error));
  }
}

export function* getWidgetsLayoutSaga({ payload }) {
  try {
    const { WPID, previewTemplateInstanceParams, widgetComponentData } =
      payload;
    yield put(Creators.getWidgetsLayoutStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    let response = {};
    if (WPID) {
      const fullURL = yield `${apiEndPoints.getWidgetsByPanelId}/${WPID}`;
      response = yield WebService.get(fullURL);
    }

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log('layout', data[0]);
      if (data[0]) {
        const {
          WidgetPanelLayoutData,
          Widgets,
          WidgetComponentData,
          TemplateParameters,
          WTIId,
          TabType,
          InheritedWPID,
          TemplateBaseData,
          WidgetPanelName = '',
          WidgetPanelDescription = ''
        } = data[0];
        let templateInstanceParams;
        if (widgetComponentData) {
          templateInstanceParams = previewTemplateInstanceParams || {
            WidgetComponentData: widgetComponentData,
            TemplateParameters: JSON.parse(TemplateParameters),
            WTIId,
            TabType,
            InheritedWPID
          };
        } else {
          templateInstanceParams = previewTemplateInstanceParams || {
            WidgetComponentData:
              WidgetComponentData && JSON.parse(WidgetComponentData),
            TemplateParameters:
              TemplateParameters && JSON.parse(TemplateParameters),
            WTIId,
            TabType,
            InheritedWPID
          };
        }
        yield put(
          Creators.getWidgetsLayoutSuccess({
            // shared layout
            layout: WidgetPanelLayoutData[SHARED_LAYOUT],
            WidgetPanelName,
            WidgetPanelDesc: WidgetPanelDescription,
            layoutComponentData: Widgets,
            repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS],
            TemplateBaseData: TemplateBaseData && JSON.parse(TemplateBaseData),
            ...templateInstanceParams
          })
        );
      } else {
        // deleted before
        yield put(
          Creators.deleteTab({
            activeTab: {
              id: WPID
            },
            error: "Tab doesn't exist this was deleted earlier"
          })
        );
      }
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error, e);
    yield put(Creators.getWidgetsLayoutError(error));
  }
}

export function* getPreviewLayoutSaga({ payload }) {
  try {
    yield put(
      Creators.widgetDetailSetter({
        key: 'isHideHeader',
        value: {}
      })
    );
    yield put(
      Creators.widgetDetailSetter({
        key: 'isHideBorder',
        value: {}
      })
    );
    yield put(
      Creators.widgetDetailSetter({
        key: 'zoomLevel',
        value: {}
      })
    );
    const {
      WTIId,
      userId,
      previewTemplateInstanceParams,
      widgetComponentData
    } = payload;
    // console.log('ppp', payload, WPID);
    // yield put(Creators.getWidgetsLayoutStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = yield `${apiEndPoints.getInstancePreview}/${WTIId}`;
    const response = yield WebService.get(fullURL, {
      params: {
        UserId: userId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      if (data[0][0]) {
        const {
          WidgetPanelLayoutData,
          Widgets,
          WidgetComponentData,
          TemplateParameters,
          WTIId,
          TabType,
          InheritedWPID,
          WidgetPanelName = '',
          WidgetPanelDesc = ''
        } = data[0][0];
        let templateInstanceParams;
        if (widgetComponentData) {
          templateInstanceParams = previewTemplateInstanceParams || {
            WidgetComponentData: widgetComponentData,
            TemplateParameters: JSON.parse(TemplateParameters),
            WTIId,
            TabType,
            InheritedWPID
          };
        } else {
          templateInstanceParams = previewTemplateInstanceParams || {
            WidgetComponentData,
            // TemplateParameters: JSON.parse(TemplateParameters),
            WTIId,
            TabType,
            InheritedWPID
          };
        }
        yield put(
          Creators.getWidgetsLayoutSuccess({
            // shared layout
            layout: WidgetPanelLayoutData[SHARED_LAYOUT],
            layoutComponentData: Widgets,
            WidgetPanelName,
            WidgetPanelDesc,
            repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS],
            // TemplateBaseData: JSON.parse(TemplateBaseData),
            ...templateInstanceParams
          })
        );
        // yield put(
        //   Creators.getPreviewLayoutSuccess({
        //     // shared layout
        //     layout: WidgetPanelLayoutData[SHARED_LAYOUT],
        //     layoutComponentData: Widgets,
        //     repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS],
        //     ...templateInstanceParams
        //   })
        // );
        yield put(
          Creators.widgetDetailSetter({
            key: 'WidgetComponentData',
            value: widgetComponentData || WidgetComponentData
          })
        );
        yield put(
          Creators.widgetDetailSetter({
            key: 'isTemplateComponentUpdateReq',
            value: false
          })
        );
      } else {
        // deleted before
        yield put(
          Creators.deleteTab({
            activeTab: {
              id: WTIId
            },
            error: "Tab doesn't exist this was deleted earlier"
          })
        );
      }
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error, e);
    yield put(Creators.getWidgetsLayoutError(error));
  }
}

export function* getPreviewTemplateSaga({ payload }) {
  try {
    const { WTId } = payload;
    // console.log('ppp', payload, WPID);
    yield put(Creators.getWidgetsLayoutStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    if (WTId) {
      const fullURL =
        yield `${apiEndPoints.getAllWidgetPanelTemplates}/${WTId}`;
      const response = yield WebService.get(fullURL);
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        if (data[0]) {
          const {
            WidgetPanelLayoutData,
            Widgets,
            WidgetComponentData,
            TemplateParameters,
            WTIId,
            InheritedWPID,
            WidgetPanelName = '',
            WidgetPanelDesc = ''
          } = data[0];
          const templateInstanceParams = {
            // WidgetComponentData: JSON.parse(WidgetComponentData),
            TemplateParameters,
            WTIId,
            InheritedWPID
          };
          yield put(
            Creators.getWidgetsLayoutSuccess({
              // shared layout
              layout:
                WidgetPanelLayoutData[0]?.WidgetPanelLayoutData[SHARED_LAYOUT],
              layoutComponentData: Widgets,
              WidgetPanelName,
              WidgetPanelDesc,
              repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS],
              ...templateInstanceParams
            })
          );
        } else {
          // deleted before
          yield put(
            Creators.deleteTab({
              activeTab: {
                id: WTId
              },
              error: "Tab doesn't exist this was deleted earlier"
            })
          );
        }
      } else {
        throw response;
      }
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error, e);
    yield put(Creators.getWidgetsLayoutError(error));
  }
}

export function* updateWidgetsLayoutSaga({ payload }) {
  const { WPID, widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.updateWidgetsLayoutStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.updateWidgetPanel2}/${WPID}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const { WidgetPanelLayoutData } = data[0];
      // console.log('update widget layout', data[0]);
      yield put(
        Creators.updateWidgetsLayoutSuccess({
          layout: WidgetPanelLayoutData[SHARED_LAYOUT],
          repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS]
        })
      );
      // if defined execute it
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.updateWidgetsLayoutError(error));
  }
}

// TODO: remove not in use
export function* deleteWidgetsLayoutSaga({ payload }) {
  const { WPID } = payload;
  try {
    yield put(Creators.deleteWidgetsLayoutStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = apiEndPoints.deleteWidgetPanel.replace('{{WPID}}', WPID);
    const response = yield WebService.post(fullURL);

    if (response.status >= 200 && response.status < 300) {
      // yield put(Creators.deleteWidgetsLayoutSuccess());
      yield put(Creators.getWidgetTabs());
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.deleteWidgetsLayoutError(error));
  }
}

export function* getWidgetTypesSaga({ payload }) {
  try {
    yield put(Creators.getWidgetTypesStart());
    const fullURL = yield `${apiEndPoints.getWidgetTypes}`;
    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log('widget types', data[0]);
      // yield put(Creators.getWidgetTypesSuccess(tempData[0])); // TODO :- change tempData to data
      yield put(Creators.getWidgetTypesSuccess(data[0])); // TODO :- change tempData to data
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.getWidgetTypesFailure(error));
  }
}

export function* getKpvListSaga({ payload }) {
  const { formData } = payload;
  try {
    yield put(Creators.getKpvListStart());
    const fullURL = `${apiEndPoints.getKpvList}`;
    const response = yield WebService.post(fullURL, { formInput: formData });

    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;

      yield put(
        Creators.getKpvListSuccess({
          kpvData: data,
          kpvMeta: meta
        })
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.getKpvListError(error));
  }
}
