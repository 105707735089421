/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { Creators } from './action';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

/**
 * Saga function to get users list
 */
export function* getUsersListSaga() {
  try {
    yield put(Creators.getUsersListStart());
    const response = yield WebService.get(apiEndPoints.getUsersList);
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      yield put(Creators.getUsersListSuccess({ data, meta }));
    } else {
      throw response;
    }
  } catch (error) {
    const { error: err } = sagaCatchBlockHandling(error);
    yield put(Creators.getUsersListFailure(err));
  }
}

/**
 * saga to download exmpty excel or excel with user data for update nd bulk add
 *
 */
export function* getBulkUsersUpdateListSaga({ payload }) {
  try {
    yield put(Creators.getBulkUsersUpdateListStart());
    const url = payload === 'bulk add' ? apiEndPoints.getBulkAddExcel : apiEndPoints.getBulkUpdateExcel;
    const response = yield WebService.get(url);
    if (response.status >= 200 && response.status < 300) {
      // const { data, meta } = response.data;
      yield put(Creators.getBulkUsersUpdateListSuccess(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    const { error: err } = sagaCatchBlockHandling(error);
    yield put(Creators.getBulkUsersUpdateListFailure(err));
  }
}

/**
 *Saga to update the bulk user addition and updation
 */

export function* updateBulkUsersSaga({ payload }) {
  try {
    yield put(Creators.updateBulkUsersStart());
    const url = payload?.modal === 'bulk add' ? apiEndPoints.addBulkUsers : apiEndPoints.updateBulkUsers;
    const response = yield WebService.post(url, payload?.assetShortlist);
    if (response.status >= 200 && response.status < 300) {
      // const { data, meta } = response.data;
      yield put(Creators.updateBulkUsersSuccess(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    const { error: err } = sagaCatchBlockHandling(error);
    yield put(Creators.updateBulkUsersFailure(err));
  }
}
