/* eslint-disable max-len */
import React, { useState } from 'react';
import { AddWidgetBg } from '../../../assets/icons';
import { FlexContainer } from '../../../globalStyles';
import ButtonComp from '../../Common/Button/Button.view';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { Text } from '../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import AddTab from '../Header/AddTab/AddTab';

const EmptyWidgetLanding = (props) => {
  const {
    iconUrl = AddWidgetBg,
    label = 'Add Widgets',
    description = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt asperiores, totam quos incidunt essenecessitatibus?',
    handleClick
  } = props;

  return (
    <FlexContainer className=' col ai_center jc_center' style={{ flex: 1 }}>
      <FlexContainer className=' col ai_center jc_center' style={{ width: '30%' }}>
        <DynamicSVGIcon iconUrl={iconUrl} width='22rem' />
        {handleClick && (
          <>
            {label !== 'Add Tab' ? (
              <ButtonComp
                dark
                style={{ borderRadius: '3rem', margin: '1rem', width: '21rem', height: '5rem' }}
                onClick={() => handleClick(true)}
                className='AddWidgetBtn'
              >
                {label}
              </ButtonComp>
            ) : (
              <AddTab>
                <ButtonComp
                  dark
                  style={{ borderRadius: '3rem', margin: '1rem', width: '21rem', height: '5rem' }}
                  onClick={() => handleClick(true)}
                  className='AddWidgetBtn'
                >
                  {label}
                </ButtonComp>
              </AddTab>
            )}
          </>
        )}
        <Text className='decription center'>{description}</Text>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EmptyWidgetLanding;
