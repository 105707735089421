/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ForwardNavArrow } from '../../assets/icons/KPV';
import { Creators as LayoutCreator } from '../../views/Layout/store';
import { Creators as KPVChartsCreators } from '../KPVCharts/Store';
import { Creators as UpsertPaneCreators } from './Store';
import {
  Button,
  ChartContainer,
  Container,
  ContentContainer,
  Graph,
  Header,
  NavigationDiv,
  OptIcon
} from './UpsertPane.style';
import SidePanel from './SidePanel.view';
import {
  chartCategories,
  // chartOptions,
  CHART_TYPES,
  CHART_TYPE_CATEGORY,
  PAGE_TYPES,
  resolveChartCategory,
  WIDGET_CATEGORIES,
  WIDGET_TERMINOLOGY,
  WIDGET_TYPES
} from '../../constants';
import useGetMachinesShortlisted from '../../shared/hooks/useGetMachinesShortlisted';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import { getPlotDataPayload } from '../KPVCharts/utils/getPlotDataPayload';
import { isEmpty } from '../../shared/utility/isEmpty';
import DataGraphComponent from '../KPVCharts/Chart/DataChart/DataGraphWrapper';
import TransitionGraphComponent from '../KPVCharts/Chart/TransitionChart/TransitionChartWrapper';
import SPCGraphComponent from '../KPVCharts/Chart/SPCChart/SPCGraphWrapper';
import Loader from '../Common/Loader/Loader';
import { fetchUserAndLocation } from '../Sidenav/FetchStatics';
import { commonRoutes } from '../../routes/route';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import LabelChartWrapper from '../KPVCharts/Chart/LabelChart/LabelChartWrapper';
import XYChartWrapper from '../KPVCharts/Chart/XYChart/XYChartWrapper';
import Toast, { TOAST_TYPES } from '../Common/Toast';

// TODO
const UpsertPane = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { panesData, error, ...upsertData } = useSelector(
    (state) => state.upsertPaneData
  );
  const {
    plotData,
    labelsData,
    machinesSelected,
    paneTitle,
    showDetails,
    showOverlays,
    showAlarms,
    overlaysData,
    multiAxis,
    scaleYaxis,
    chartTypeSub,
    scaleYAxisRange,
    multiGrid,
    chartType,
    chartTypeCategory,
    transitionChartType: transChartType,
    multiSelectKpv,
    loading,
    id,
    StartTime,
    EndTime,
    annotations,
    alarmsData,

    // TEST: new from label chart
    seriesTypesSelections,
    xType,
    yType,
    xKPVType
  } = upsertData;
  const chartOptions = useSelector((state) => state.KPVCharts.chartOptions);
  const panesShortHandInfo = useSelector(
    (state) => state.KPVCharts.panesShortHandInfo
  );
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const userData = useSelector((state) => state.loginData.userData);
  const {
    plotData: graphData,
    colorCodes,
    xMinDomain,
    xMaxDomain,
    selectedPaneNo,
    latestAvailable
  } = useSelector((state) => state.KPVCharts);
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const [transitionChartType, setTransitionChartType] =
    useState(transChartType);
  useEffect(() => {
    setTransitionChartType(transChartType || 'lots');
  }, [transChartType]);
  const { isFromWidgets, WID, widgetName } = location.state || {};
  useGetMachinesShortlisted(true, isFromWidgets);
  useEffect(() => {
    // default range
    // fetchUserAndLocation()
    const name = commonRoutes.find((ele) => ele.path === location.pathname);
    fetchUserAndLocation(location.state?.previousPath, userData, {
      pageType: name.name,
      tab: name.name
    });

    dispatch(LayoutCreator.setShowSideNav({ showSideNav: false }));
    return () => {
      dispatch(LayoutCreator.setShowSideNav({ showSideNav: true }));
      dispatch(KPVChartsCreators.setSelectedPaneNo(''));
      dispatch(UpsertPaneCreators.resetUpsertPaneData());
    };
  }, []);

  useEffect(() => {
    // for edit and add, on mount
    // console.log('isEmpty(machinesSelected', isEmpty(machinesSelected));
    // TODO: max depth
    // if (isEmpty(machinesSelected)) {
    //   dispatch(
    //     UpsertPaneCreators.setUpsertPaneData({
    //       key: 'machinesSelected',
    //       value: (!isEmpty(panesData) && Object.keys(panesData[0]?.meta)?.map((e) => ({ kpvId: e, macId: e }))) || []
    //     })
    //   );
    // }
  }, [panesData]);

  useEffect(() => {
    if (!multiSelectKpv) {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'multiSelectKpv',
          value: machinesSelected?.length > 1
        })
      );
    }

    if (!isEmpty(machinesSelected) || (!isEmpty(xType) && !isEmpty(yType))) {
      let payload = {
        kpv: [...machinesSelected, ...xKPVType],
        category: resolveChartCategory[chartTypeCategory],
        chartTypeCategory,
        chartType,
        singlePane: true
      };
      if (
        chartTypeCategory === CHART_TYPE_CATEGORY.LABEL ||
        chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART
      ) {
        payload = {
          xType,
          yType,
          category: resolveChartCategory[chartTypeCategory],
          chartTypeCategory,
          chartType,
          singlePane: true
        };
      }
      const machinesWithNoPlotData = getPlotDataPayload(
        [payload],
        StartTime || xMinDomain,
        EndTime || xMaxDomain
      );
      // dispatch(UpsertPaneCreators.getPanePlotData(machinesWithNoPlotData));
      if (!latestAvailable) {
        dispatch(
          UpsertPaneCreators.getPanePlotData({ machinesWithNoPlotData })
        );
      } else {
        dispatch(KPVChartsCreators.setDomainRange({ latestAvailable: 30 }));
        // isUpdate = true
        dispatch(
          UpsertPaneCreators.getPanePlotData({
            machinesWithNoPlotData,
            latestAvailable: 30
          })
        );
      }
    }
  }, [machinesSelected, transitionChartType, yType]);

  const handleWidgetCustomization = async (payload) => {
    const {
      StartTime,
      EndTime,
      xMinDomainProp,
      xMaxDomainProp,
      paneData,
      ...restPayload
    } = payload;
    const reqPayload = {
      WidgetComponentData: {
        type: WIDGET_TYPES.ANALYTICS,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: WIDGET_CATEGORIES.ANALYTICS,
        ...restPayload,
        xMinDomainProp:
          xMinDomainProp || StartTime
            ? new Date(StartTime)
            : new Date(xMinDomain),
        xMaxDomainProp:
          xMaxDomainProp || EndTime ? new Date(EndTime) : new Date(xMaxDomain)
      }
    };

    // console.log('payload', payload, reqPayload);
    try {
      const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
      const resp = await WebService.post(fullURL, reqPayload);
      if (resp.status >= 200 && resp.status < 300) {
        history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
      } else {
        throw resp;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('e', e, error.error);
    }
  };

  const handleAddPane = () => {
    const paneInfoObj = {
      id,
      data: plotData,
      labelsData,
      paneTitle,
      showDetails,
      showOverlays,
      multiAxis,
      scaleYaxis,
      chartTypeSub,
      scaleYAxisRange,
      machinesSelected,
      chartType,
      chartTypeCategory,
      transitionChartType,
      multiGrid,
      paneData: panesData[0],
      category: resolveChartCategory[chartTypeCategory],
      spcDetails: panesShortHandInfo[selectedPaneNo]?.spcDetails,
      StartTime,
      EndTime,
      annotations,
      // new from label chart
      xType,
      yType,
      seriesTypesSelections,
      xKPVType
    };
    if (isFromWidgets) {
      // widget customization
      handleWidgetCustomization(paneInfoObj);
    } else {
      if (!selectedPaneNo && selectedPaneNo !== 0) {
        dispatch(KPVChartsCreators.setPaneData(paneInfoObj));
      } else {
        dispatch(
          KPVChartsCreators.editCompletePaneData({
            index: selectedPaneNo,
            paneInfo: paneInfoObj
          })
        );
      }
      dispatch(UpsertPaneCreators.resetUpsertPaneData());
      history.replace({
        pathname: '/home/kpv-charting',
        state: { previousPath: location.state?.previousPath }
      });
    }
  };

  const handleDiscard = () => {
    dispatch(UpsertPaneCreators.resetUpsertPaneData());
    history.replace({
      pathname: '/home/kpv-charting',
      state: { previousPath: location.state?.previousPath }
    });
  };

  const handleBack = () => {
    dispatch(UpsertPaneCreators.resetUpsertPaneData());
    history.goBack();
  };
  // const labels = panesData[0] && Object.keys(panesData[0]).map((e) => panesData[0][e].meta);
  let saveLabel =
    !selectedPaneNo && selectedPaneNo !== 0
      ? localeStrings.Add || 'Add'
      : localeStrings.apply || 'Apply';
  if (isFromWidgets) {
    saveLabel = 'Save';
  }
  let header = localeStrings.Charts || 'Charts';
  if (isFromWidgets) {
    header = `Edit ${widgetName}`;
  }
  const clearErrorHandler = () => {
    dispatch(UpsertPaneCreators.getPanePlotDataFailure(''));
  };
  return (
    <Container>
      <Header>
        <NavigationDiv>
          <OptIcon rotate={180} onClick={handleBack}>
            <DynamicSVGIcon iconUrl={ForwardNavArrow} width='2.5rem' />
          </OptIcon>

          {!selectedPaneNo && selectedPaneNo !== 0 ? (
            <span>{header}</span>
          ) : (
            <span>
              {`${chartOptions[chartType]?.label} -${
                localeStrings.Edit || 'Edit'
              } ${localeStrings.Pane || 'Pane'}`}
            </span>
          )}
        </NavigationDiv>
        <div>
          <Button onClick={handleDiscard}>
            {localeStrings.Discard || 'Discard'}
          </Button>
          <Button
            className={`dark ${loading ? 'disable' : ''}`}
            disabled={loading}
            onClick={handleAddPane}
          >
            {saveLabel}
          </Button>
        </div>
      </Header>
      <ContentContainer>
        <ChartContainer>
          <Graph id={id} length={plotData?.length}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {/* show respective graph  */}
                {(chartTypeCategory === CHART_TYPE_CATEGORY.DATA_CHART ||
                  chartType === CHART_TYPES.DATA_CHART) &&
                  !isEmpty(panesData[0]) && (
                    <DataGraphComponent
                      xMinDomainProp={StartTime}
                      xMaxDomainProp={EndTime}
                      paneTitle={paneTitle}
                      showDetails={showDetails}
                      multiAxis={multiAxis}
                      scaleYaxis={scaleYaxis}
                      scaleYAxisRange={scaleYAxisRange}
                      rawPlotData={panesData[0]}
                      multiGrid={multiGrid}
                      showOverlays={showOverlays}
                      position='bottom'
                      annotations={annotations}
                      id={id}
                    />
                  )}
                {(chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART ||
                  chartType === CHART_TYPES.TRANSITION_CHART) &&
                  !isEmpty(panesData[0]) && (
                    <TransitionGraphComponent
                      xMinDomainProp={StartTime}
                      xMaxDomainProp={EndTime}
                      paneTitle={paneTitle}
                      showDetails={showDetails}
                      transitionChartType={transitionChartType}
                      rawPlotData={panesData[0]}
                      position='bottom'
                    />
                  )}
                {chartTypeCategory === CHART_TYPE_CATEGORY.SPC &&
                  !isEmpty(panesData[0]) && (
                    <SPCGraphComponent
                      xMinDomainProp={StartTime}
                      xMaxDomainProp={EndTime}
                      paneTitle={paneTitle}
                      showDetails={showDetails}
                      scaleYaxis={scaleYaxis}
                      chartTypeSub={chartTypeSub}
                      scaleYAxisRange={scaleYAxisRange}
                      // transitionChartType={transitionChartType}
                      rawPlotData={panesData[0]}
                      position='bottom'
                      showOverlays={showOverlays}
                      showAlarms={showAlarms}
                      alarmsData={alarmsData}
                      overlaysData={overlaysData}
                    />
                  )}
                {chartTypeCategory === CHART_TYPE_CATEGORY.LABEL &&
                  !isEmpty(panesData[0]) && (
                    <LabelChartWrapper
                      xMinDomainProp={StartTime}
                      xMaxDomainProp={EndTime}
                      paneTitle={paneTitle}
                      showDetails={showDetails}
                      scaleYaxis={scaleYaxis}
                      scaleYAxisRange={scaleYAxisRange}
                      // transitionChartType={transitionChartType}
                      rawPlotData={panesData[0]}
                      position='bottom'
                      showOverlays={showOverlays}
                      showAlarms={showAlarms}
                      alarmsData={alarmsData}
                      overlaysData={overlaysData}
                      seriesTypesSelections={seriesTypesSelections}
                      xType={xType}
                      multiAxis={multiAxis}
                      multiGrid={multiGrid}
                      id={id}
                    />
                  )}
                {chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART &&
                  !isEmpty(panesData[0]) && (
                    <XYChartWrapper
                      xMinDomainProp={StartTime}
                      xMaxDomainProp={EndTime}
                      paneTitle={paneTitle}
                      showDetails={showDetails}
                      scaleYaxis={scaleYaxis}
                      scaleYAxisRange={scaleYAxisRange}
                      // transitionChartType={transitionChartType}
                      rawPlotData={panesData[0]}
                      position='bottom'
                      showOverlays={showOverlays}
                      showAlarms={showAlarms}
                      alarmsData={alarmsData}
                      overlaysData={overlaysData}
                      seriesTypesSelections={seriesTypesSelections}
                      xType={xType}
                      multiAxis={multiAxis}
                      multiGrid={multiGrid}
                      id={id}
                    />
                  )}
              </>
            )}
          </Graph>
        </ChartContainer>
        <SidePanel />
        {error && (
          <Toast
            message={error}
            header={TOAST_TYPES.ERROR}
            fn={clearErrorHandler}
            style={{ position: 'fixed', bottom: 30, right: 0 }}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default UpsertPane;
