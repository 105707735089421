/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../Common/Loader/Loader';
import { ContentContainer, SearchIcon, SearchInput } from '../../../ResultView/KPVListComponent/KPVListComponent.style';
import { Table, Th, Tr } from '../../../Common/Table/SpacedRows.styles';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { FlexContainer } from '../../../../globalStyles';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { CancelBlack, searchBlack } from '../../../../assets/icons';
import Groupby from '../../../Common/GroupBy/GroupBy';
import KpvSelectionGroup from './KpvSelectionGroup.View';

const KpvSelectionList = ({ kpvShortList, setKpvShortList }) => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);

  const { loadingKpv, kpvData, kpvMeta: metaDetails } = useSelector((state) => state.widgets);

  const searchRef = useRef();
  const [groupBy, setGroupBy] = useState();
  const [kpvGroups, setKpvGroups] = useState({});
  const [groupByList, setGroupByList] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [showSearchInp, setShowSearchInp] = useState({});
  const [kpvGroupDetails, setKpvGroupDetails] = useState([]);
  const [filteredResultData, setFilteredResultData] = useState(null);

  useEffect(() => {
    if (kpvData) {
      const data = kpvData;
      if (metaDetails && data.length) {
        const { grouping } = metaDetails;
        setGroupByList(grouping);

        const tempGrps = {};
        grouping?.forEach((group) => {
          data?.forEach((e) => {
            if (!tempGrps[group]?.length) {
              tempGrps[group] = [];
            }
            tempGrps[group].push(e);
          });
        });
        setKpvGroups(tempGrps);
      }
    }
  }, [kpvData]);

  // computing groupBy, searchableFields, searchInpFileds from dynamic meta content
  useEffect(() => {
    setGroupBy(metaDetails?.grouping && metaDetails?.grouping[0]);
    // searchable feilds computation
    const searchableFields = metaDetails?.fields?.filter((field) => field.searchable);
    const tempShowSearchInp = {};
    searchableFields?.forEach((e) => {
      tempShowSearchInp[e.key] = false;
    });
    setShowSearchInp({ ...tempShowSearchInp });
  }, [kpvData]);

  useEffect(() => {
    if (kpvGroups[groupBy]?.length) {
      const tempSubGroups = {};
      kpvGroups[groupBy].forEach((innerGroup) => {
        if (!innerGroup) return;
        if (!tempSubGroups[innerGroup[groupBy]]?.length) {
          tempSubGroups[innerGroup[groupBy]] = [];
        }
        tempSubGroups[innerGroup[groupBy]].push(innerGroup);
      });
      setKpvGroupDetails(tempSubGroups);
    }
  }, [kpvGroups, groupBy]);

  const handleGroupBy = (data) => {
    setGroupBy(data);
  };

  const handleSearchQuery = (field, e) => {
    const locSearchQuery = e?.target?.value || '';
    setSearchQuery({ ...searchQuery, [field]: locSearchQuery });
    const tempFilteredKpvGroupDet = { ...kpvGroupDetails };

    Object.keys(kpvGroupDetails).forEach((group) => {
      tempFilteredKpvGroupDet[group] = [
        ...kpvGroupDetails[group].filter((item) =>
          Object.keys(searchQuery).every((query) => {
            if (query === field) {
              return item[query]?.toString().toLowerCase().includes(locSearchQuery.toLowerCase());
            }
            // all other cumulative search queries are checked
            return item[query]?.toString().toLowerCase().includes(searchQuery[query].toLowerCase());
          }))
      ];
    });

    setFilteredResultData(tempFilteredKpvGroupDet);
  };

  const handleSearchVisiblity = (field) => {
    // before changing showSearchInp
    if (showSearchInp[field]) {
      setSearchQuery({ ...searchQuery, [field]: '' });
      handleSearchQuery(field, '');
    }

    setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
  };

  let Content = null;
  if (loadingKpv) Content = <Loader />;
  else {
    Content = (
      <ContentContainer>
        {!isEmpty(metaDetails?.fields) ? (
          <Table className='attached'>
            {/* table columns */}
            <Tr className='header'>
              {metaDetails?.fields?.map(
                (col, key) =>
                  // !metaDetails?.grouping?.includes(col.key) &&
                  col?.visible && (
                    <Th
                      key={key}
                      width={col.width}
                      order={col.order}
                      border={col.name !== 'Action'}
                      // eslint-disable-next-line prefer-template
                      className={'colHeading ' + col.key}
                    >
                      <FlexContainer style={{ width: '100%' }} className='ai_center jc_center'>
                        <FlexContainer style={{ width: '100%' }} className='ai_center jc_center'>
                          {showSearchInp[col.key] && col.searchable ? (
                            <SearchInput
                              className='show'
                              ref={searchRef}
                              type='text'
                              placeholder={`Search ${col.key}s`}
                              onChange={(e) => handleSearchQuery(col.key, e)}
                              value={searchQuery[col.key]}
                              // onKeyUp={(e) => _handleKeyPress(e)}
                            />
                          ) : (
                            <p>{localeStrings[col.title] || col.title}</p>
                          )}
                          {col.searchable && (
                            <SearchIcon onClick={() => handleSearchVisiblity(col.key)}>
                              {!showSearchInp[col.key] ? (
                                <DynamicSVGIcon iconUrl={searchBlack} />
                              ) : (
                                <DynamicSVGIcon iconUrl={CancelBlack} />
                              )}
                            </SearchIcon>
                          )}
                        </FlexContainer>

                        {col.key === 'KPV' && (
                          <div style={{ marginLeft: 'auto' }}>
                            <Groupby selected={groupBy} onClick={handleGroupBy} list={groupByList} />
                          </div>
                        )}
                      </FlexContainer>
                    </Th>
                  )
              )}
              <Th>action</Th>
            </Tr>
            {/* table rows */}
            {Object.keys(showSearchInp).some((e) => showSearchInp[e]) && filteredResultData
              ? Object.keys(filteredResultData)?.map((groupName) => (
                <KpvSelectionGroup
                  groupItems={filteredResultData[groupName]}
                  groupTitle={groupName || 'KPV'}
                  kpvShortList={kpvShortList}
                  setKpvShortList={setKpvShortList}
                />
              ))
              : kpvGroupDetails &&
                Object.keys(kpvGroupDetails)?.map((groupName) => (
                  <KpvSelectionGroup
                    groupItems={kpvGroupDetails[groupName]}
                    groupTitle={groupName || 'KPV'}
                    kpvShortList={kpvShortList}
                    setKpvShortList={setKpvShortList}
                  />
                ))}
          </Table>
        ) : (
          <FlexContainer className='jc_center ai_center' style={{ fontSize: '2rem', height: '100%' }}>
            No data available
          </FlexContainer>
        )}
      </ContentContainer>
    );
  }
  return (
    <div>
      {Content}
    </div>
  );
};

export default KpvSelectionList;
