/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  AddToHome,
  DeleteBlack,
  Export,
  HideBorder,
  HideHeader,
  RemoveFromHome,
  RemoveHomeMenu,
  settingsBlack
} from '../../../../assets/icons';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import {
  Container,
  ContainerInside,
  ContainerInsideText,
  TextBodyContainer
} from '../SvgWidget/SVGWidget.style';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { getFetchDetailsOfWidgetData } from '../../../Widget/utils';
import { getDataSource } from '../../../../views/Widgets/WidgetComponents/BarChart/data';
import { WebService } from '../../../../services/axios/webServices';
import { useActions } from '../../../../store/hooks/useActions';
import { apiEndPoints } from '../../../../services/axios/endPoints';

const TextHtml = (props) => {
  const { componentData, id, isVisible, layoutPos, fullView, HDId } = props;
  const WID = layoutPos?.i?.split('_')?.[0];
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const widgetsInHome = useSelector(
    (state) => state.HomeDashboardReducer.widgetsInHome
  );
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const [zoomLevel, setZoomLevel] = useState(1);

  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  const { widgetLabel } = useSelector((state) => state.upsertWidgetData);
  const { formInput, widgetType } = useSelector(
    (state) => state.upsertWidgetData
  );
  const [dimension, setDimension] = useState({});
  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({});

  const { getWidgetDataSuccess, setToastError, setExportData } = useActions();
  const { id: WTIIdFromUrl } = useParams();
  const containerRef = useRef();

  const {
    widgetEditOptions: initWidgetEditOptions,
    WidgetName,
    infoText,
    type,
    formData
  } = componentData || {};

  const { data, meta } = useSelector((state) => state.upsertWidgetData);

  const widgetOptions = [
    // {
    //   id: 1,
    //   label: 'Export',
    //   value: 'export',
    //   // type: 'radio',
    //   iconUrl: Export,
    //   subMenu: [
    //     { id: 1, label: 'Image', value: 'pngExport' },
    //     {
    //       id: 2,
    //       label: 'PDF',
    //       value: 'pdfExport'
    //     }
    //   ]
    // },
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: Export,
    //   // type: 'radio',
    // },
    {
      id: 1,
      label: 'configure',
      value: 'configure',
      iconUrl: settingsBlack,
    },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  const widgetOptionsInstance = HDId
    ? [
        {
          id: 1,
          label: 'Delete',
          value: 'deleteFromHome',
          iconUrl: DeleteBlack
        },
        {
          id: 3,
          label: isHideHeader[WID + activeTab?.id]
            ? 'Show Header'
            : 'Hide Header',
          value: 'toggleHeader',
          iconUrl: HideHeader
        },
        {
          id: 4,
          label: isHideBorder[WID + activeTab?.id]
            ? 'Show Border'
            : 'Hide Border',
          value: 'toggleBorder',
          iconUrl: HideBorder
        }
      ]
    : [
        // {
        //   id: 9,
        //   label: 'Full View',
        //   value: 'fullView',
        //   iconUrl: Export,
        //   // type: 'radio',
        // },
        {
          id: 3,
          label: isHideHeader[WID + activeTab?.id]
            ? 'Show Header'
            : 'Hide Header',
          value: 'toggleHeader',
          iconUrl: HideHeader
        },
        {
          id: 4,
          label: isHideBorder[WID + activeTab?.id]
            ? 'Show Border'
            : 'Hide Border',
          value: 'toggleBorder',
          iconUrl: HideBorder
        }
      ];

  const widgetOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  useEffect(() => {
    if (containerRef) {
      const width = containerRef.current.offsetWidth - 50;
      const height = containerRef.current.offsetHeight - 50;
      setDimension({ width, height });
    }
  }, [containerRef?.current?.offsetHeight, containerRef?.current?.offsetWidth]);

  const getDataDummy = async () => {
    try {
      const fullURL = apiEndPoints.dummyEnd;
      const resp = await WebService.post(fullURL, {
        type: widgetType,
        widgetPayload: formData,
        htmlContent:
          '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <b>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</b></p>',
        // onSuccessNavigation,
        widgetType
      });
      // `<img
      //   class="fit-picture"
      //   src="https://picsum.photos/200/300"
      //   alt="Grapefruit slice atop a pile of other slices" />`
      if (resp.status >= 200 && resp.status < 300) {
        getWidgetDataSuccess({
          data: resp?.data?.data?.htmlContent,
          meta: resp?.data?.data?.widgetPayload?.dataSource
        });
        setExportData({
          TabId: WTIIdFromUrl || activeTab?.id,
          WID,
          widgetData: {
            data: [{ html: resp?.data?.data?.htmlContent }],
            meta: resp?.data?.data?.widgetPayload?.dataSource,
            WidgetName,
            type: 'TEXT_HTML'
          }
        });

        // onSuccessNavigation();
      } else {
        // history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
        setToastError('Failed to Update Template');
        throw resp;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      // setToastError(error);
    }
  };

  useEffect(() => {
    if (isEmpty(widgetData)) {
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
      // TODO_W_R

      WebService[method](fullURL, {
        widgetType: type,
        formInput: formData
      })
        .then((response) => {
          const { htmlContent } = response?.data?.data;
          getWidgetDataSuccess({
            data: htmlContent,
            meta: formData
          });
          setExportData({
            TabId: WTIIdFromUrl || activeTab?.id,
            WID,
            widgetData: {
              data: [{ html: htmlContent }],
              meta: formData,
              WidgetName,
              type: 'TEXT_HTML'
            }
          });
          // const barChartDet = getDataSource(response?.data?.data, 0);
          // setExportData({ TabId: WTIIdFromUrl || activeTab?.id, WID, widgetData: { data, meta, WidgetName, type } });
          setLoading(false);
        })
        .catch((e) => {
          const err = sagaCatchBlockHandling(e);
          // setError(err.error);
          setLoading(false);
          console.log('status value err', e);
        });
    }
    // getDataDummy();
  }, [isVisible]);

  return (
    <Container headerOn={!isHideHeader[WID + activeTab?.id]}>
      {
        <WidgetHeaderView
          id={id}
          WID={WID} // WID
          WidgetName={WidgetName || widgetLabel}
          hasZoomControl={true}
          setZoomLevel={setZoomLevel}
          zoomLevel={zoomLevel}
          widgetOptions={
            WTIId || WTid || HDId
              ? isInstancePop
                ? widgetOptionsInstancePop
                : widgetOptionsInstance
              : widgetOptions
          }
          componentData={componentData}
          fullView={fullView}
        />
      }
      <TextBodyContainer
        className='zoom'
        zoomLevel={fullView ? 1 : zoomLevel}
        headerOn={!isHideHeader[WID + activeTab?.id]}
      >
        <ContainerInsideText
          ref={containerRef}
          dangerouslySetInnerHTML={{ __html: data }}
        />
      </TextBodyContainer>
    </Container>
  );
};

export default TextHtml;
