import styled from 'styled-components';

export const SvgStatusContainer = styled.div`
width: 160px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
padding: 1rem;
`;

export const StatusCard = styled.div`
display: flex;
gap: 5px;
border-radius: 8px;
padding-bottom: 1rem;


`;

export const Status = styled.div`
background-color: ${(props) => props.backGround || '#1AFF00'};
font-size: 1.1rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;
padding: 5px;
flex: 0 1 50%;

`;

export const StatusTitle = styled.div`
font-size: 1.1rem;
/* background-color: ${({ theme }) => theme.contrast.secondary}; */
background-color:${({ theme }) => theme.filter.hover}; 
display: flex;
align-items: center;
justify-content: center;
padding: 5px;
border-radius: 8px;
flex: 0 1 50%;
`;
