/* eslint-disable prefer-template */
/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyInputLayoutView from '../KeyValueLayout/KeyInputLayout.view';
import RadioTypeComponent from '../InputTypes/RadioType/RadioType.view';
import CheckboxTypeComponent from '../InputTypes/CheckboxType/CheckboxType.view';
import TextTypeComponent from '../InputTypes/TextType/TextType.view';
import DropdownTypeComponent from '../InputTypes/DropdownType/DropdownType.view';
import { INPUT_TYPES, RESOLVED_FIELD_CLASS_NAME } from '../../../constants';
import EquipmentSearchView from '../InputTypes/EquipmentSearch/EquipmentSearch.view';
import EquipmentFilterSearchType from '../InputTypes/FilterSearchType/EquipmentFilterSearchType.view';
import Toast from '../Toast';
import { useActions } from '../../../store/hooks/useActions';
import Loader from '../Loader/Loader';
import { genericFormValidation } from '../../Widget/utils/formValidation';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getResetPayloadForDependentFields } from '../../UpsertWidget/WidgetForm/Utility';
import ToggleableFieldValue from './ToggleableFieldValue';
import { H2 } from '../CommonStyles';
import ToggleType from '../InputTypes/ToggleType/ToggleType';
import { Continer, MainContainer } from './GenericForm.style';
import EquipmentFilterSearchTypeTree from '../InputTypes/FilterSearchType/EquimentFilterSearchTypeTree';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../WidgetForm/utility/populateFilterDashboard';
import { Creators as ObjectTreeCreators } from '../../WidgetForm/Store';

const GenericFormTree = (props, ref) => {
  const {
    loading,
    formStructureError, // any error from the form
    error,
    templateInstanceFormInput
  } = useSelector((state) => state.upsertWidgetData);
  const { objectTreeData } = useSelector((state) => state.objectTreeData);
  const { setFormStructureFailure, getWidgetDataFailure } = useActions();
  const dispatch = useDispatch();
  const {
    formStructure,
    formInput: globalFormInput,
    formStructureFieldDepenents,
    isFormEditable,
    setIsFormFilled,
    allowHorizontal, // key-value side-by-side
    labelWidth,
    allowFieldSetWrap = true,
    hideHeader,
    hasPreSelection,
    isPreSelection,
    isbreakPointEnabled = false,
    configureInstance = false,
    // setFormInput: setGlobalFormInput
    ignoreActiveOption = false,
    isInstanceCreation = false
  } = props;
  const [formInput, setFormInput] = useState({});
  const [fieldsTobeRefetched, setFieldsTobeRefetched] = useState([]);
  // const shouldForceRefetch = useRef();
  const isFormIntialized = useRef();
  useEffect(() => {
    // populate values in fields
    if (globalFormInput) {
      // console.log('=========1');
      setFormInput(globalFormInput);
    }
  }, [globalFormInput]);

  useEffect(() => {
    if (configureInstance) {
      if (!isEmpty(objectTreeData) && !isEmpty(objectTreeData?.values)) {
        const filterTree = objectTreeData.reduce((acc, cur) => {
          const { keyName } = cur;
          return {
            ...acc,
            [keyName]: [cur]
          };
        }, {});
        const payloadTree = {
          data:
            typeof templateInstanceFormInput === 'string'
              ? JSON.parse(templateInstanceFormInput)?.formInput?.common
              : templateInstanceFormInput?.formInput?.common
        };
        if (payloadTree?.data) {
          const { cumTags, cumSelectedObj } = populateFilterDashboard(
            payloadTree,
            filterTree
          );
          const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
          dispatch(ObjectTreeCreators.PopulateTreeStructure(payload));
        }
      }
    }
  }, [templateInstanceFormInput, objectTreeData, configureInstance]);

  useEffect(() => {
    // if globalforminput is filled then formInput must be filled
    if (!isEmpty(formStructure)) {
      const isFormFilled = !isEmpty(globalFormInput)
        ? !isEmpty(formInput)
        : true;
      if (
        // !shouldForceRefetch.current &&
        !isFormIntialized.current &&
        isFormFilled
      ) {
        // formIsFilled but formIntialized = false, shouldForceRetch = false
        // console.log('1===========enable force refetch');
        // shouldForceRefetch.current = true;
        isFormIntialized.current = true;
        const dependentFieldsList = Object.values(
          formStructureFieldDepenents
        ).reduce((e, final) => [...final, ...e], []);
        setFieldsTobeRefetched(dependentFieldsList);
      }
    }
  }, [formInput, formStructure]);

  useImperativeHandle(
    ref,
    () => ({
      getFormInput() {
        // limit the forminput to formstructure fields
        return formStructure.reduce(
          (acc, e) => ({
            ...acc,
            [e.key]: formInput[e.key]
          }),
          {}
        );
      }
    }),
    [formInput]
  );

  const resetFieldsTobeRefetched = () => {
    setFieldsTobeRefetched([]);
  };
  const resetDependents = (keyOfChangedField, additionalProps) => {
    const { shouldUpdateRefetch = true, localFormInput } =
      additionalProps || {};
    const latestFormInput = localFormInput || formInput;
    const dependents = formStructureFieldDepenents?.[keyOfChangedField];
    // console.log('resting as', keyOfChangedField, dependents);
    if (shouldUpdateRefetch) {
      setFieldsTobeRefetched(dependents);
    }
    const payloadTobeReseted = getResetPayloadForDependentFields(
      dependents,
      latestFormInput
    );
    // console.log('reseting', keyOfChangedField, dependents, payloadTobeReseted);
    return payloadTobeReseted;
  };

  const handleType = (ele, item) => {
    const { itemKey: key } = item;
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele
    }));
  };

  const clearError = () => {
    setFormStructureFailure('');
    getWidgetDataFailure('');
  };

  const multiValueSelectionHandler = (
    item,
    { key, itemValueKey = 'value' }, // field related properties
    additionalParams = {} // other props required
  ) => {
    try {
      // console.log('checkbox handler', isFormIntialized.current);
      if (isFormIntialized.current) {
        const { isDirectUpdate = false } = additionalParams;
        const payloadTobeReseted = resetDependents(key);
        // direct list as value for that key is sent
        if (isDirectUpdate) {
          // console.log('1============direcct', key, item, payloadTobeReseted);
          setFormInput((formInput) => ({
            ...formInput,
            ...payloadTobeReseted,
            [key]: item
          }));
        } else {
          // checking if already selected are not
          const selections = formInput?.[key] || [];
          const itemIndex = selections.findIndex(
            (prevItem) => prevItem?.[itemValueKey] === item?.[itemValueKey]
          );
          // console.log('selections', itemIndex, selections, key, formInput?.[key]);
          // console.log('item', item, key, 'itemValueKey', itemValueKey, 'selections', selections, itemIndex);
          if (itemIndex > -1) {
            const newSelections = [
              ...selections.slice(0, itemIndex),
              ...selections.slice(itemIndex + 1)
            ];
            //  selections.splice(itemIndex, 1);
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: newSelections
            }));
          } else {
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: [...selections, item]
            }));
          }
        }
      }
    } catch (e) {
      console.log('checkbox selection', e);
    }
  };

  const formValueSetter = (item, { key }) => {
    const payloadTobeReseted = resetDependents(key);
    // setFormInput((formInput) => ({ ...formInput, ...payloadTobeReseted, [key]: item }));
    if (!isEmpty(item)) {
      setFormInput((formInput) => ({
        ...formInput,
        ...payloadTobeReseted,
        [key]: [item[key]]
      }));
    }
  };

  const textInputHandler = (ele, key) => {
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele.target.value
    }));
  };

  const toggleHandler = (key) => {
    setFormInput((fi) => ({ ...fi, [key]: !fi[key] }));
  };
  const removeListItem = (item, key) => {
    const payloadTobeReseted = resetDependents(key);
    const equipmentArray = formInput?.[key]?.filter(
      (prevItem) => prevItem !== item
    );
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: equipmentArray
    }));
  };
  useEffect(() => {
    const res = genericFormValidation(formStructure, formInput);
    setIsFormFilled(res);
  }, [formStructure, formInput]);

  // console.log('form==ss=2=', formStructure, formInput, globalFormInput);
  return (
    <>
      <Continer
        labelWidth={labelWidth}
        className={`formContainer ${allowHorizontal ? 'allowHorizontal' : ''}`}
        style={{
          position: 'relative'
          // , minHeight: '50rem'
        }}
      >
        {loading && <Loader width='75%' height='70vh' position='fixed' />}
        <MainContainer
          className={`${allowFieldSetWrap ? 'wrap' : ''} status_value_widget`}
        >
          {formStructure?.map((item) => {
            // enable this check only if form is intialized
            const tobeRefetched = isFormIntialized.current
              ? fieldsTobeRefetched.includes(item.key)
              : false;
            // console.log('tobeRefetched', item.key, tobeRefetched, fieldsTobeRefetched);
            const commonProps = {
              resetFieldsTobeRefetched,
              formInput,
              tobeRefetched,
              formStructure,
              isFormIntialized: isFormIntialized.current,
              isEditable: isFormEditable,
              initialFormInput: globalFormInput
            };
            const { fieldWidth, key, header } = item;
            // items to be skiped in preselection form(template)
            if (
              isPreSelection &&
              item.inputType !== INPUT_TYPES.FILTER_SEARCH
            ) {
              return <></>;
            }
            const fieldClassName = RESOLVED_FIELD_CLASS_NAME[fieldWidth];
            const value = formInput?.[key] || item.defaultValue;
            // console.log('fieldClassName', fieldClassName, item);
            if (header && !hideHeader) {
              return <H2>{header}</H2>;
            }
            if (item.inputType === INPUT_TYPES.EQUIPMENT_SEARCH) {
              const { key } = item;
              return (
                <div>
                  <EquipmentSearchView
                    item={item}
                    value={value}
                    itemSelectionHandler={multiValueSelectionHandler}
                    removeListItem={removeListItem}
                    {...commonProps}
                  />
                </div>
              );
            }
            if (item.type === INPUT_TYPES.OBJECT) {
              if (!isEmpty(objectTreeData)) {
                const itemRequired = objectTreeData?.filter(
                  (obj) => obj?.key === item?.key
                );

                return (
                  <div>
                    <EquipmentFilterSearchTypeTree
                      item={itemRequired[0]}
                      fieldClassName='none'
                      value={formInput?.[item.key]}
                      formValueSetter={formValueSetter}
                      isPreSelection={
                        itemRequired[0]?.key === 'tree' ? isPreSelection : false
                      }
                      isbreakPointEnabled={isbreakPointEnabled}
                      hasPreSelection={
                        itemRequired[0]?.key === 'tree'
                          ? hasPreSelection
                          : false
                      }
                      ignoreActiveOption={ignoreActiveOption}
                      isInstanceCreation={isInstanceCreation}
                      // multiValueSelectionHandler={multiValueSelectionHandler}
                      {...commonProps}
                    />
                    {/* <ShowFilterItems list={formInput?.equipments} removeListItem={removeListItem} itemKey={item.key} /> */}
                  </div>
                );
              }
            }
            if (item.inputType === INPUT_TYPES.RADIO) {
              return (
                <RadioTypeComponent
                  item={item}
                  value={value} // values tobe shown
                  handleChange={formValueSetter}
                  {...commonProps}
                />
              );
            }
            if (item.inputType === INPUT_TYPES.CHECKBOX) {
              // console.log(item.key, 'item item');
              return (
                <div>
                  <CheckboxTypeComponent
                    item={item}
                    value={value} // values tobe shown
                    handleChange={multiValueSelectionHandler}
                    {...commonProps}
                    isInstanceCreation={isInstanceCreation}
                  />
                </div>
              );
            }
            if (
              item.inputType === INPUT_TYPES.PASSWORD ||
              item.inputType === INPUT_TYPES.TEXT ||
              item.inputType === INPUT_TYPES.EMAIL
            ) {
              return (
                <div>
                  <KeyInputLayoutView
                    isRequired={item.isRequired}
                    label={item.label}
                    tooltip={item.tooltip}
                    className='add_equipment'
                    fieldClassName={fieldClassName}
                  >
                    <ToggleableFieldValue
                      type={item?.inputType}
                      isEditable={isFormEditable}
                      value={value}
                    >
                      <TextTypeComponent
                        item={item}
                        value={value} // form inp value
                        textInputHandler={textInputHandler}
                        {...commonProps}
                      />
                    </ToggleableFieldValue>
                  </KeyInputLayoutView>
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.TOGGLE) {
              return (
                <div>
                  <KeyInputLayoutView
                    isRequired={item.isRequired}
                    label={item.label}
                    tooltip={item.tooltip}
                    className='add_equipment'
                    fieldClassName={fieldClassName}
                  >
                    <ToggleableFieldValue
                      type={item?.inputType}
                      isEditable={isFormEditable}
                      value={value}
                    >
                      <ToggleType
                        itemKey={item.key}
                        handleToggle={toggleHandler}
                        isActive={value}
                      />
                    </ToggleableFieldValue>
                  </KeyInputLayoutView>
                </div>
              );
            }
            if (item.inputType === INPUT_TYPES.DROPDOWN) {
              return (
                <div>
                  <KeyInputLayoutView
                    isRequired={item.isRequired}
                    label={item.label}
                    tooltip={item.tooltip}
                    className='add_equipment'
                    fieldClassName={fieldClassName}
                  >
                    <ToggleableFieldValue
                      type={item?.inputType}
                      isEditable={isFormEditable}
                      value={value}
                    >
                      <DropdownTypeComponent
                        item={item}
                        handleClick={handleType}
                        value={value}
                        itemKey={item.key}
                        {...commonProps}
                      />
                    </ToggleableFieldValue>
                  </KeyInputLayoutView>
                </div>
              );
            }

            return null;
          })}
        </MainContainer>
        {(formStructureError || error) && (
          <Toast
            header='Error'
            className='fixed right'
            message={formStructureError || error}
            fn={clearError}
          />
        )}
      </Continer>
    </>
  );
};
export default forwardRef(GenericFormTree);
