/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { rightArrow } from '../../../../assets/icons';
import { FlexContainer } from '../../../../globalStyles';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import { Icons, TitleContainer } from './header.styles';

export default function PickerHeader({ title, hideTitle, year, correspondingYear, setYear, isAtStart }) {
  const handleEqual = (changedYear) => {
    setYear({
      start: changedYear,
      end: changedYear + 1
    });
  };

  const handleYearChange = (changedYear) => {
    // console.log('cha', changedYear, year[title], year[title === 'start' ? 'end' : 'start']);
    if (changedYear === year[title === 'start' ? 'end' : 'start']) {
      handleEqual(changedYear);
    } else {
      setYear({ ...year, [title]: changedYear });
    }
  };

  return (
    <>
      {!hideTitle && (
        <TitleContainer isActive={isAtStart === 1 ? title === 'start' : title === 'end'} style={{ display: 'block' }}>
          {title}
        </TitleContainer>
      )}

      <FlexContainer style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{correspondingYear || year[title]}</span>
        <Icons>
          <div
            style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
            onClick={() => handleYearChange(year[title] - 1)}
          >
            <DynamicSVGIcon width='2.5rem' iconUrl={rightArrow} />
          </div>
          <div
            style={{ transform: 'rotate(270deg)', cursor: 'pointer' }}
            onClick={() => handleYearChange(year[title] + 1)}
          >
            <DynamicSVGIcon width='2.5rem' iconUrl={rightArrow} />
          </div>
        </Icons>
      </FlexContainer>
    </>
  );
}
