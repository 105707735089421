/* eslint-disable max-len */
// {
//     "kpvid": "KPV_P1_150_1",
//     "tsvalue": "2022-06-25 13:00:00",
//     "annotation": "This is a test annotation",
//     "userid": "lino.thomas@vistrian.com",
//     "mode": "insert"
//    }

import moment from 'moment';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../../constants';

export const graphsWithAnnotations = [CHART_TYPES.DATA_CHART];
// {
//     seriesLabel: 'THO>>1FAM0103>>KPV>>VS - POS2 US Power - Bottom',
//     argument: '2022-02-22T05:28:18.000Z',
//     text: 'sdsad'
//   }
export const getAnnotationPayload = (annotationDetails) => {
  const { kpvid, seriesKey, seriesLabel, argument, text, userid, chartType, chartCategory, pointLabel } =
    annotationDetails;
  return {
    kpvid: kpvid || seriesKey,
    tsvalue: moment(new Date(argument)).format('YYYY-MM-DD HH:mm:ss.SSS'),
    annotation: text,
    userid,
    chartType,
    chartCategory,
    seriesKey,
    seriesLabel: seriesLabel || seriesKey,
    pointLabel
  };
};

// {
//     Annotation: 'This is a test annotation',
//     TSValue: '2022-02-22T10:03:02',
//     UpdatedBy: 'md.akhil@panorbit.in'
//   }
export const setAnnotationsDetails = (annotationDetails) => {
  const { chartTypeCategory, PointLabel, Series, ...restAnnotationDetails } = annotationDetails;
  const { seriesKey, seriesLabel, Annotation, TSValue, UpdatedBy } = restAnnotationDetails;
  // console.log('ann', annotationDetails);
  if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
    return {
      ...restAnnotationDetails,
      seriesLabel,
      argument: new Date(TSValue),
      text: Annotation,
      chartCategory: chartTypeCategory,
      pointLabel: PointLabel,
      seriesKey: Series
    };
  }
  return {
    seriesLabel,
    seriesKey,
    argument: new Date(TSValue),
    text: Annotation
  };
};
