/* eslint-disable indent */
import { apiEndPoints } from '../../services/axios/endPoints';
import { WebService } from '../../services/axios/webServices';
import config from '../../config.json';
/* eslint-disable max-len */
export const fetchUserAndLocation = async (
  pathname,
  userData,
  additionalDetails
) => {
  // extra validation for checking subMenuOptions
  // if ('tab' in additionalDetails) {
  if (config.update_page_statistics) {
    try {
      const details = additionalDetails?.tab
        ? {
            pagename: pathname,
            userid: userData.userId,
            tab: additionalDetails?.tab
          }
        : { pagename: pathname, userid: userData.userId };
      const url = apiEndPoints.updatePageUsageStatistics;
      const response = await WebService.post(url, details);
    } catch (error) {
      console.log(error, 'in fetchUserAndLocation ');
    }
  }
  // }
};

// export const fetchUserAndLocation = async (pathname, userData, additionalDetails) => {
// try {
//   let details = { page: pathname, user: userData, additionalDetails: additionalDetails };
//   const url = apiEndPoints.updatePageUsageStatistics;
//   const response = await WebService.post(url, details);
// } catch (error) {
//   console.log(erron, 'in fetchUserAndLocation ');
// }
// };
