import styled from 'styled-components';

export const ContainerDiv = styled.div`
padding: 2rem;
`;

export const SaveAnalyticsContainer = styled.div`
margin-bottom: 1rem;
display: flex;
justify-content: flex-end;
gap:20px;
`;
