import styled from 'styled-components';

export const CommonBtnContainer = styled.div`
  position: relative;
  right: 7rem;
  top: 0;
  display: flex;
  /* padding: 3rem; */
  /* justify-content: center; */
`;

export const SearchBtn = styled.div`
  width: 2.5rem;
  height: 3rem;
  /* padding: 1rem; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  cursor: pointer;
  z-index: 10;
  opacity: 0.5;
  position: relative;
  bottom: 1rem;
  right: 1rem;
`;

export const FilterButtom = styled.div`
  width: 5rem;
  height: 4.5rem;
  border-radius: 10px;
  margin: 0px 0px 0px 1px;
  background-color: ${({ theme }) => theme.aux.secondary};

  box-shadow: 4px 4px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  & svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;
