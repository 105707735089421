/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MainBodyContainer } from '../../component/Common/CommonStyles';
import Pagination from '../../component/Common/Pagination/Pagination.view';
import TabsTable from '../../component/Common/Tabs_Table';
import { mergeConflictRecords } from '../../component/Common/Tabs_Table/utility';
// import {
//   columnsDetails,
//   userData,
//   userformStructure
// } from '../../component/Users/data';
import ResolveUploadedRecords from '../../component/Common/ResolveUploadedRecords/ResolveUploadedRecords';
import { tableItemFlags } from '../../constants';
import { arrayToJson } from '../../shared/utility';
import { useActions } from '../../store/hooks/useActions';
import { Container, HeaderText, HeadingBox, HeadingContainer, Button, ButtonContainer } from './Users.style';
import UsersHeader from './UsersHeader';
import Loader from '../../component/Loader/Loader';
import Toast, { TOAST_TYPES } from '../../component/Common/Toast';
import { apiEndPoints } from '../../services/axios/endPoints';
import BulkUpdateDownload from '../../component/Users/BulkUserUpdate/BulkUpdateDownload.View';
import UserList from '../../component/Users/BulkUserUpdate/UserList.view';

const selectionTypeandPosition = {
  isCheckboxTypeSelection: true,
  isSelectionAtFirst: true
};

export const Users = (props) => {
  const pageSize = 5;
  const [assetShortlist, setAssetShortlist] = useState([]);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [modal, setModal] = useState();
  const [updateUsersList, setUpdateUsersList] = useState();
  const [toast, setToast] = useState(false);
  const history = useHistory();
  const {
    userReducerValueSetter,
    updateUsersData,
    getUserTableData,
    resetUserDataFlags,
    getUsersList,
    getUsersListFailure, resetUpdationData
  } = useActions();
  const { usersData, usersMeta, usersUploadedData, loading, error } = useSelector((state) => state.usersDetails);
  const { updatedBulkList, updationState } = useSelector((state) => state.usersDetails);

  const locations = useLocation();
  const { state } = locations;
  const { record } = state || {};

  useEffect(() => {
    getUsersList();
  }, []);
  const handleShortList = (e, isCheckRequired = true) => {
    if (isCheckRequired) {
      if (!assetShortlist.includes(e)) {
        setAssetShortlist([...assetShortlist, e]);
      } else {
        assetShortlist.splice(assetShortlist.indexOf(e), 1);
        setAssetShortlist([...assetShortlist]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };
  const getNumberOfSuccess = () => {
    const result = updatedBulkList?.filter((data) => data.Status === 'Success');
    return result?.length;
  };

  const clearErrorHandler = () => {
    setToast(false);
  };

  const handlePageChange = () => {
    // console.log('users page change');
  };

  const [showDataDiffModal, setShowDataDiffModal] = useState(false);

  const handleNextAfterUpload = () => {
    setShowDataDiffModal(true);
  };
  const saveFinalRecords = (records) => {
    const { resolvedRecords, freshRecords } = records;
    console.log('saving records....', resolvedRecords, freshRecords);
    updateUsersData({
      resolvedRecords, // object
      freshRecords // array
    });
    setTimeout(() => {
      updateUsersData({
        resolvedRecords: {}, // object
        freshRecords: [] // array
      });
      resetUserDataFlags();
    }, [3000]);
  };

  const saveEditedRecord = (record) => {
    updateUsersData({
      resolvedRecords: {
        [record.employeeId]: record
      }, // object
      freshRecords: [] // array
    });
    setTimeout(() => {
      updateUsersData({
        resolvedRecords: {}, // object
        freshRecords: [] // array
      });
      resetUserDataFlags();
    }, [3000]);
  };

  useEffect(() => {
    if (record) {
      saveEditedRecord(record);
    }
  }, []);
  const handleUserCreate = () => {
    history.push({
      pathname: '/home/user/add',
      state: {
        isFormEditable: true,
        formInput: {},
        formApi: apiEndPoints.getUserForm,
        saveForm: apiEndPoints.createUserDetails
      }
    });
  };

  const handleADUserCreate = () => {
    history.push({
      pathname: '/home/user/add-AD-User',
      state: {
        isFormEditable: true,
        formInput: {},
        formApi: apiEndPoints.getADUserSearchForm,
        saveForm: apiEndPoints.createADUserDetails
      }
    });
  };

  /**
   * sets Show modal for Excel file download on click of bulk user update
   * @returns {any}
   */
  const handleUpdateBulkUser = () => {
    resetUpdationData();
    setBulkUpdateModal(true);
    setModal('bulk update');
  };

  const handleAddBulkUser = () => {
    resetUpdationData();
    setBulkUpdateModal(true);
    setModal('bulk add');
  };

  const handleCloseUpdate = () => {
    setBulkUpdateModal(false);
  };

  const handleCloseUsers = () => {
    setUpdateUsersList();
  };

  const handleShowDetails = (ele) => {
    history.push('/home/user/details', {
      formInput: ele,
      isFormEditable: false,
      formApi: apiEndPoints.getUserUpdateForm,
      saveForm: apiEndPoints.updateUserDetails
    });
  };

  const clearError = () => {
    getUsersListFailure('');
  };
  // console.log(error, 'eroo');
  const rowOptions = [
    { id: 1, label: 'Edit details', value: 'editDetails' },
    { id: 2, label: 'Change Password', value: 'changePassword' }
  ];
  const handleRowOptionsItemClick = ({ value }, { activeRowItem }) => {
    if (value === 'editDetails') {
      history.push({
        pathname: '/home/user/edit',
        state: {
          isFormEditable: true,
          formInput: activeRowItem,
          formApi: apiEndPoints.getUserUpdateForm,
          saveForm: apiEndPoints.updateUserDetails
        }
      });
    } else if (value === 'changePassword') {
      history.push({
        pathname: '/home/user/edit',
        state: {
          isFormEditable: true,
          formInput: activeRowItem,
          formApi: apiEndPoints.getChangePasswordForm,
          saveForm: apiEndPoints.passwordUser
        }
      });
    }
  };
  return (
    <>
      <MainBodyContainer>
        <UsersHeader
          hasBack={false}
          title='Users'
          isCreate
          handleUserCreate={handleUserCreate}
          handleADUserCreate={handleADUserCreate}
          handleNext={handleNextAfterUpload}
          handleUpdateBulkUser={handleUpdateBulkUser}
          handleAddBulkUser={handleAddBulkUser}
        />
        {loading ? (
          <Loader />
        ) : (
          <TabsTable
            metaDetails={usersMeta}
            dataDetails={usersData}
            showPagiation={false}
            showShortlistSelection={false}
            // handleShortList={handleShortList}
            onItemSelect={handleShowDetails}
            assetShortlist={assetShortlist}
            handlePageChange={handlePageChange}
            styleType='strapedTable'
            noItemsFoundMessage='No Users Found'
            rowOptions={rowOptions}
            showRowOptionAtStart={false}
            id='UserID'
            handleRowOptionsItemClick={handleRowOptionsItemClick}
            shortListSelectionDetails={selectionTypeandPosition}
          />
        )}
        {bulkUpdateModal && <BulkUpdateDownload label={modal === 'bulk add' ? 'Add Multiple Users' : 'Update Multiple Users'} updateUsersLis={updateUsersList} setUpdateUsersList={setUpdateUsersList} handleCloseUpdate={handleCloseUpdate} modal={modal} />}
        {!bulkUpdateModal && updateUsersList && <UserList handleCloseUpdate={handleCloseUsers} modal={modal} usersData={updateUsersList} usersMeta={usersMeta} setToast={setToast} />}
      </MainBodyContainer>
      <ResolveUploadedRecords
        usersTabelData={usersData}
        usersUploadedData={usersUploadedData}
        showDataDiffModal={showDataDiffModal}
        setShowDataDiffModal={setShowDataDiffModal}
        saveFinalRecords={saveFinalRecords}
        uniqueId='employeeId'
      />
      {error && <Toast header='Error' className='fixed right' message={error} fn={clearError} />}
      {toast && getNumberOfSuccess() !== 0 && (
        <Toast
          message={modal === 'bulk add' ? `${getNumberOfSuccess()} Users Added Successfully` : `${getNumberOfSuccess()} Users Updated Successfully`}
          header={TOAST_TYPES.SUCCESS}
          showHeader={false}
          fn={clearErrorHandler}
          style={{ position: 'fixed', bottom: 30, right: '35%' }}
        />
      )}
    </>
  );
};
