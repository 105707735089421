/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import BarChartWidget from '../../../../views/Widgets/WidgetComponents/BarChart/BarChart';
import BarChartOptions from './BarChartOptions';
import { Container, Text } from './BarChart.style';
import {
  getDataSource
  // data as finalData
} from '../../../../views/Widgets/WidgetComponents/BarChart/data';
import { useActions } from '../../../../store/hooks/useActions';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import {
  getFetchDetailsOfWidgetData,
  getResolvedWidgetCategory
} from '../../utils';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import Loader from '../../../Common/Loader/Loader';
import {
  TabBtnContainer,
  TabContainer
} from '../../../../views/Widgets/WidgetComponents/OEEDonut/EquipmentDonut.style';
import { TabUi } from '../../../Common/TabUi/TabUi';
import { BodyContainer } from '../../../Common/TabUi/TabUi.style';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import { WIDGET_TERMINOLOGY } from '../../../../constants';
import {
  AddToHome,
  DeleteBlack,
  Export,
  HideBorder,
  HideHeader,
  RemoveFromHome,
  RemoveHomeMenu,
  settingsBlack
} from '../../../../assets/icons';
import ExportImg from '../../../../assets/icons/Export Image.svg';
import ExportPdf from '../../../../assets/icons/Export PDF.svg';
import Exportexcel from '../../../../assets/icons/Export excel.svg';

const BarChart = (props) => {
  const { componentData, id, isVisible, layoutPos, fullView, WIDFromProp, HDId } = props;
  const WID = WIDFromProp || layoutPos?.i?.split('_')?.[0];
  const [currentTab, setCurrentTab] = useState('1');
  const {
    WidgetName,
    infoText,
    formData,
    type,
    widgetEditOptions: initWidgetEditOptions,
    ...componentProps
  } = componentData || {};
  const { zoomLevel: definedZoomLevel } = componentData || {};
  const [zoomLevel, setZoomLevel] = useState(1);
  const [widgetData, setWidgetData] = useState({});
  const [widgetEditOptions, setWidgetEditOptions] = useState({});
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const [loading, setLoading] = useState(false);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const widgetPayload = useSelector((state) => state.widgets.widgetPayload);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const [barChartDetails, setBarChartDetails] = useState({});
  const [error, setError] = useState(false);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const isPreviewInstance = useSelector(
    (state) => state.widgets.isPreviewInstance
  );
  // const [hiddenHeader, setHiddenHeader] = useState(isHideHeader[WID]);
  const { updateWidgetPayload, setExportData } = useActions();

  // Options of Barchart Widget Header when Designer Tab
  const rawOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    // {
    //   id: 1,
    //   label: 'Export',
    //   value: 'export',
    //   iconUrl: Export,
    //   subMenu: [
    //     { id: 1, label: 'Image', value: 'pngExport' },
    //     {
    //       id: 2,
    //       label: 'PDF',
    //       value: 'pdfExport'
    //     }
    //   ]
    // },
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 3,
      label: 'configure',
      value: 'configure',
      iconUrl: settingsBlack
    },
    {
      id: 4,
      label: 'Delete',
      value: 'delete',
      iconUrl: DeleteBlack
    },
    {
      id: 6,
      label: 'Show Stats',
      value: 'statsOf',
      type: 'checkbox'
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ] }
  ];

  // Options of Barchart Widget Header when Instance Tab and HDId Condition is for showing Delete when Home Widget
  const rawOptionsInstance = HDId ?
    [
      {
        id: 9,
        label: 'Delete',
        value: 'deleteFromHome',
        iconUrl: DeleteBlack,
      },
      {
        id: 2,
        label: 'Share',
        value: 'share'
      },
      {
        id: 6,
        label: 'Show Stats',
        value: 'statsOf',
        type: 'checkbox'
      },
      {
        id: 7,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 8,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 5,
        label: 'orientation',
        value: 'orientation',
        type: 'radio',
        subMenu: [
          { id: 1, label: 'Horizontal', value: 'horizontal' },
          {
            id: 2,
            label: 'Vertical',
            value: 'vertical'
          }
        ]
      },
      {
        id: 9,
        label: 'Export',
        value: 'export',
        iconUrl: Export,
        subMenu: [
          { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExport',
            iconUrl: ExportPdf,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: Exportexcel,
          }
        ]
      },
    ]
    : [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
      {
        id: 2,
        label: 'Share',
        value: 'share'
      },
      {
        id: 6,
        label: 'Show Stats',
        value: 'statsOf',
        type: 'checkbox'
      },
      {
        id: 7,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 8,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 5,
        label: 'orientation',
        value: 'orientation',
        type: 'radio',
        subMenu: [
          { id: 1, label: 'Horizontal', value: 'horizontal' },
          {
            id: 2,
            label: 'Vertical',
            value: 'vertical'
          }
        ]
      },
      {
        id: 9,
        label: 'Export',
        value: 'export',
        iconUrl: Export,
        subMenu: [
          { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExport',
            iconUrl: ExportPdf,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: Exportexcel,
          }
        ]
      },
    ];

  // Options of Barchart Widget Header when Instance Pop view
  const rawOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 6,
      label: 'Show Stats',
      value: 'statsOf',
      type: 'checkbox'
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ]
    },
  ];

  // Options of Barchart Widget Header when there is show stats option available
  const rawOptionsWithoutStats = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    // {
    //   id: 1,
    //   label: 'Export',
    //   value: 'export',
    //   iconUrl: Export,
    //   subMenu: [
    //     { id: 1, label: 'Image', value: 'pngExport' },
    //     {
    //       id: 2,
    //       label: 'PDF',
    //       value: 'pdfExport'
    //     }
    //   ]
    // },
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 3,
      label: 'configure',
      value: 'configure',
      iconUrl: settingsBlack
    },
    {
      id: 4,
      label: 'Delete',
      value: 'delete',
      iconUrl: DeleteBlack
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ] }
  ];

  // Options of Barchart Widget Header when there is show stats option not available
  const rawOptionsInstanceWithoutStats = HDId ? [
    {
      id: 9,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack,
    },
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ],

    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ] }
  ] : [
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ] }
  ];

  // Options of Barchart Widget Header when there is show stats option available and Pop view
  const rawOptionsInstanceWithoutStatsPop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 2,
      label: 'Share',
      value: 'share'
    },
    {
      id: 7,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 8,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'orientation',
      value: 'orientation',
      type: 'radio',
      subMenu: [
        { id: 1, label: 'Horizontal', value: 'horizontal' },
        {
          id: 2,
          label: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    {
      id: 9,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ] }
  ];

  useEffect(() => {
    if (widgetPayload[WID]?.WidgetComponentData?.widgetEditOptions) {
      setWidgetEditOptions(
        widgetPayload[WID]?.WidgetComponentData?.widgetEditOptions
      );
    } else {
      setWidgetEditOptions(initWidgetEditOptions);
    }
  }, [initWidgetEditOptions]);

  const { id: WTIIdFromUrl } = useParams();

  useEffect(() => {
    if (isEmpty(widgetData)) {
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
      // TODO_W_R

      WebService[method](fullURL, {
        widgetType: type,
        formInput: formData
      })
        .then((response) => {
          const { data, meta } = response?.data?.data;
          const barChartDet = !isEmpty(response?.data?.data) && getDataSource(response?.data?.data, 0);
          setBarChartDetails(barChartDet);
          setExportData({ TabId: WTIIdFromUrl || activeTab?.id, WID, widgetData: { data, meta, WidgetName, type } });
          setLoading(false);
        })
        .catch((e) => {
          const err = sagaCatchBlockHandling(e);
          setError(err.error);
          setLoading(false);
          console.log('status value err', e);
        });
    }
  }, []);
  const { completeData } = barChartDetails || {};
  useEffect(() => {
    if (currentTab !== 1 && !isEmpty(completeData)) {
      const barChartDetails = getDataSource(completeData, currentTab - 1);
      setTimeout(() => {
        setBarChartDetails(barChartDetails);
      }, 100);
    }
  }, [currentTab]);

  const saveDetails = async (editOptions) => {
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        ),
        zoomLevel
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        widgetEditOptions: editOptions
      },
      WidgetMetaData: widgetMetaData
    };
    updateWidgetPayload({ widgetPayload, WID });
    // try {
    //   const fullURL = apiEndPoints.updateWidget.replace('{{WID}}', WID);
    //   const resp = await WebService.post(fullURL, widgetPayload);
    //   if (resp.status >= 200 && resp.status < 300) {
    //     // nothing
    //   } else {
    //     throw resp;
    //   }
    //   // console.log('resp', resp);
    // } catch (e) {
    //   const error = sagaCatchBlockHandling(e);
    //   console.log('e', e, error.error);
    // }
  };
  const handleWidgetEditOptions = (payload, isSaveReq = true) => {
    const { key, value } = payload;
    const newPayload = {
      ...widgetEditOptions,
      [key]: value
    };
    if (isSaveReq) {
      saveDetails(newPayload);
    }

    setWidgetEditOptions((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };
  const { statsOf } = widgetEditOptions || {};
  // console.log('widgetEditOptions', widgetEditOptions, initWidgetEditOptions);
  useEffect(() => {
    setZoomLevel(definedZoomLevel || 1);
  }, [definedZoomLevel]);

  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const menu = isEditOn ? (
    <BarChartOptions
      rawOptions={WTIId || WTid || HDId ? barChartDetails?.meta?.gridKeys ? isInstancePop ? rawOptionsInstancePop : rawOptionsInstance : isInstancePop ? rawOptionsInstanceWithoutStatsPop : rawOptionsInstanceWithoutStats : barChartDetails?.meta?.gridKeys ? rawOptions : rawOptionsWithoutStats}
      barChartDetails={barChartDetails}
      widgetEditOptions={widgetEditOptions}
      setWidgetEditOptions={handleWidgetEditOptions}
      componentData={componentData}
      id={id}
      WidgetName={WidgetName}
      WID={WID}
      setZoomLevel={setZoomLevel}
      zoomLevel={zoomLevel}
      hasZoomControl={true}
    />
  ) : (
    <></>
  );

  if (error) return <span>{error}</span>;
  return (
    <>
      { <WidgetHeaderView
        id={id}
        WID={WIDFromProp || layoutPos?.i?.split('_')?.[0]} // WID
        WidgetName={WidgetName || 'Equipment OEE Donut'}
        componentData={componentData}
        menu={menu}
        fullView={fullView}
        bottomFullView={10}
        leftFullView={12}
      />}
      <Container
        headerOn={!isHideHeader[WID + activeTab?.id]}
        className='zoom barChart'
        zoomLevel={fullView ? 1 : zoomLevel}

      >
        <>
          {loading ? !fullView ? (
            <Loader />
          ) : <Loader width="70%" height="40%" /> : (
            // <div className='container-wrapper'>
            <TabContainer
              isBarChart={widgetEditOptions?.orientation?.vertical}
              className='zoom barChart'
            >
              <TabBtnContainer id={`tab${WID}`}>
                <TabUi
                  currentTab={currentTab}
                  tabs={completeData?.data}
                  handleTabClick={handleTabClick}
                />
              </TabBtnContainer>
              <BodyContainer>
                <BarChartWidget
                  statsOf={statsOf}
                  barChartDetails={barChartDetails}
                  isRotated={!widgetEditOptions?.orientation?.vertical}
                  isLabelRotated={
                    widgetEditOptions?.labelOrientation?.horizontal &&
                    widgetEditOptions?.labelOrientation?.horizontal
                  }
                  activeTab={activeTab}
                  WID={WID}
                  zoomLevel={zoomLevel}
                />
              </BodyContainer>
            </TabContainer>
            // </div>
          )}
        </>
      </Container>
    </>
  );
};
export default memo(BarChart);
