/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../BarChart/BarChart.style';
import Ahu from '../../../SvgAnimationComponents/Ahu.View';

import { BodyContainer, ContainerBody } from '../../../Widget/Common/WidgetCommons.style';
import { Container, ContainerInside } from './SVGWidget.style';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import { DeleteBlack, HideBorder, HideHeader } from '../../../../assets/icons';
import { } from '../../WidgetsCommon.style';
import Compressor from '../../../SvgAnimationComponents/Compressor.View';
import Chillar from '../../../SvgAnimationComponents/Chillar.View';
import Exhaust from '../../../SvgAnimationComponents/Exhaust.View';
import Fan from '../../../SvgAnimationComponents/Fan.View';
import Tank from '../../../SvgAnimationComponents/Tank.View';
import { ColorsAHU, ColorsCompressor, colorsChillar, colorsExhaust, colorsFan, colorsTank } from './SvgWidgetColors';

const SVGWidgetEdit = (props) => {
  // eslint-disable-next-line max-len

  const { componentData, id, isVisible, layoutPos, fullView } = props;
  const WID = layoutPos?.i?.split('_')?.[0];
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const [machineState, setMachineState] = useState();
  const [dimension, setDimension] = useState({});
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef) {
      const width = (containerRef.current.offsetWidth - 50);
      const height = (containerRef.current.offsetHeight - 50);
      setDimension({ width, height });
    }
  }, [containerRef?.current?.offsetHeight, containerRef?.current?.offsetWidth]);
  const {
    widgetLabel

  } = useSelector((state) => state.upsertWidgetData);
  const {
    WidgetName,
    infoText,
    formData,
    type,
    widgetEditOptions: initWidgetEditOptions,
  } = componentData || {};

  const {
    formInput,
    widgetType,
  } = useSelector((state) => state.upsertWidgetData);

  const widgetOptions = [
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  let index;
  /**
   * Function to return random element from an array
   * @param {any} array
   * @returns random element from array
   */
  function getRandomItem(arr) {
    // get random index value
    index = Math.floor(Math.random() * arr.length);
    // get random item
    const item = arr[index];
    return item;
  }

  useEffect(() => {
    const state = getRandomItem(['success', 'warning', 'Failure']);
    setMachineState(state);
  }, []);

  /**
   * Function to return Respective widget component based on WidgetName
   * @param {any} WidgetName
   * @returns component
   */
  // eslint-disable-next-line consistent-return
  const getRespectiveComponent = (WidgetName) => {
    switch (type || widgetType) {
      case 'AHU': return <Ahu dimension={dimension} colors={formInput?.machineState?.value === 'running' ? ColorsAHU[0] : formInput?.machineState?.value === 'alarm' ? ColorsAHU[1] : ColorsAHU[2]} speed={formInput?.speed?.value} numSvg={formInput?.number?.value} />;
      case 'COMPRESSOR': return <Compressor dimension={dimension} Compressorcolors={formInput?.machineState?.value === 'running' ? ColorsCompressor[0] : formInput?.machineState?.value === 'alarm' ? ColorsCompressor[1] : ColorsCompressor[2]} speed={formInput?.speed?.value} numSvg={formInput?.number?.value} />;
      case 'CHILLER': return <Chillar dimension={dimension} colors={formInput?.machineState?.value === 'running' ? colorsChillar[0] : formInput?.machineState?.value === 'alarm' ? colorsChillar[1] : colorsChillar[2]} speed={formInput?.speed?.value} numSvg={formInput?.number?.value} />;
      case 'EXHAUST': return <Exhaust dimension={dimension} colors={formInput?.machineState?.value === 'running' ? colorsExhaust[0] : formInput?.machineState?.value === 'alarm' ? colorsExhaust[1] : colorsExhaust[2]} speed={formInput?.speed?.value} numSvg={formInput?.number?.value} />;
      case 'FAN': return <Fan dimension={dimension} colors={formInput?.machineState?.value === 'running' ? colorsFan[0] : formInput?.machineState?.value === 'alarm' ? colorsFan[1] : colorsFan[2]} speed={formInput?.speed?.value} numSvg={formInput?.number?.value} />;
      case 'TANK': return <Tank dimension={dimension} colors={formInput?.machineState?.value === 'running' ? colorsTank[0] : formInput?.machineState?.value === 'alarm' ? colorsTank[1] : colorsTank[2]} progress={formInput?.tankLevel?.value} numSvg={formInput?.number?.value} />;
      default: break;
    }
  };

  return (
    <Container style={{ width: '40%' }} headerOn={!isHideHeader[WID + activeTab?.id]}>
      {(
        <WidgetHeaderView
          id={id}
          WID={WID} // WID
          WidgetName={WidgetName || widgetLabel}
          widgetOptions={widgetOptions}
          componentData={componentData}
          fullView={fullView}
        />
      )}
      <BodyContainer headerOn={!isHideHeader[WID + activeTab?.id]}>
        <ContainerInside ref={containerRef}>
          {getRespectiveComponent(WidgetName || widgetLabel)}
        </ContainerInside>
      </BodyContainer>
    </Container>
  );
};

export default SVGWidgetEdit;
