/* eslint-disable indent */
/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily, THEMES, device, minDevice } from '../../../../constants';
import { FlexContainer } from '../../../../globalStyles';

export const TabContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.contrast.tertiary};
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  /* min-width: 195px; */
  min-width: ${({ isBarChart }) => !isBarChart && '195px'};
  /* overflow: auto;
  overflow-y: auto; */
  overflow: auto;
  /* min-height:40rem; */
  // and other controls from parent
`;

export const TabButton = styled.button`
  border: none;
  padding: 1rem;
  color: black;
  cursor: pointer;
  width: 100%;
  transition: all 0.5s ease-out;
  font-size: 18px;
  background-color: ${({ theme }) => theme.core.darkSecondary};
  box-shadow: inset 0px 4px 6px -1px rgba(0, 0, 0, 0.16);

  border-left: 1px solid #cbcbcf;
  :disabled {
    border-radius: 10px 10px 0px 0px;
    background: white;
    color: black;
    box-shadow: none;
    border: none;
  }
`;

export const TabBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
`;

export const AddEquipBtn = styled.div`
  border: ${({ theme }) => `5px dashed ${theme.contrast.lightSecondary}`};
  border-radius: 10px;
  width: 22rem;
  height: 16rem;
  margin: 2rem;
  margin-left: 3rem;
  text-align: center;
  padding: 3rem;
  color: ${({ theme }) => theme.input.gray};
  cursor: pointer;
  /* position: relative; */
`;

export const LimitPoint = styled.div`
  position: absolute;
  top: 0px;
  transform: scale(1.5);
`;

export const DonutDetails = styled(FlexContainer)`
  position: absolute;
  width: 100%;
  /* margin-top: 20px; */
  top: 0px;
  height: ${({ height }) => height}px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: red; */
`;

export const DonutDiv = styled.div`
  position: relative;
  margin: 0rem 1rem 2rem;
  overflow: hidden;
  width:${({ width }) => width || '200px'};
  height:${({ width }) => width || '200px'};
`;
export const BarGaugeDiv = styled.div`
position: relative;
margin: 0rem 1rem 2rem;
/* overflow: scroll; */
width:${({ width }) => width || '200px'};
height:${({ width }) => width || '200px'};
background: #fff 0% 0% no-repeat padding-box;
min-width: 40rem;
gap: 1rem;
// height: calc(100% - 7rem);

/* &.zoom {
    transform: scale( ${({ zoomLevel }) => zoomLevel || 1});
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (0.9 / zoomLevel) * 100 || 100}%;
  } */
`;

export const ContainerDiv = styled.div`
height: calc(100% - 6rem);
display: flex;
justify-content: center;
overflow: auto;
background-color: #ffff;
&.zoom {
    transform: scale( ${({ zoomLevel }) => zoomLevel || 1});
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (0.9 / zoomLevel) * 100 || 100}%;
  }

`;

export const P = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.donut.gray};
  /* background: red; */
  &.fl {
    font-size: 2rem;
  }
  &.fs {
    font-size: 1.6rem;
  }
  &.fm {
    font-size: 1.8rem;
  }
`;

export const EquipmentTitle = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
`;

export const DonutListContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 1rem;
  z-index: 0;

  & .fl {
    font-size: 2rem;
  }
  & .mb {
    margin-bottom: 5px;
  }
  & .fm {
    font-size: 1.8rem;
  }
  & .fs {
    font-size: 1.6rem;
  }
  & .fn {
    font-size: 1rem;
  }
`;
