/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Chart, {
  AdaptiveLayout,
  ArgumentAxis,
  CommonSeriesSettings,
  ConstantLine,
  Grid,
  // Strips,
  Strip,
  Label,
  Legend,
  Pane,
  Point,
  Series,
  SeriesTemplate,
  Tick,
  Title,
  Tooltip,
  ValueAxis,
  ZoomAndPan,
  Crosshair,
  HorizontalLine,
  Font,
  Aggregation
  // Annotation
} from 'devextreme-react/chart';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CHART_TYPE_CATEGORY, CHART_TYPE_CATEGORY_SUB, colors, DASH_STYLE_MAPPING, fontFamily, maxCharactersAloowed, placeHolderSize } from '../../../../constants';
import { useWindowSize } from '../../../../shared/hooks/useWindowResize';
import useViewLabels from '../hooks/useViewLabels';
import useContainerWidth from '../hooks/useContainerWidth';
import { Creators as kpvChartingCreators } from '../../Store/action';
import { Creators as UpsertPaneCreators } from '../../../UpsertPane/Store';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { chartConfig } from '../chartConfig';
import { getNearestPointsInSPC } from '../../utils/getNearestPoints';
// import { nearestDataPointByPosition } from '../utils/dataPointBinarySearch';
import getNearestPointByPosition from '../../utils/getNearestPointByPosition';
import { getOverlayDataPoints } from '../../utils/getOverlayDataPoints';
import useMeasure from '../../../../shared/hooks/useMeasure';
// import Loader from '../../../Loader/Loader';

// export const colorCodes = ['#6900FF', '#F200BA', '#398BFF', '#A000E4', 'red', 'yellow', 'green', 'orange'];
const chartPaddingTop = 20;
const paneHeight = 200;
// const ChartStyles = {
//   padding: `${chartPaddingTop}px 0px 10px 10px`,
//   width: '100%'
//   // height: '100%'
//   // height: ${({ height }) => height || '100%'};
//   // auto height
//   // height: '100%',
//   // minHeight: '20rem'
// };

const ChartContainer = styled.div`
  height: ${({ height, fillLayout }) => (fillLayout ? height || '100%' : 'auto')};
  padding: ${chartPaddingTop}px 0px 10px 10px;
  width: '100%';
`;

const SPCChart = ({
  isLastPane = false,
  setHoveredState,
  setHoveredPosState,
  hoveredState,
  paddingLeft = 0,
  setPointClicked,
  xPos,
  resize,
  fillLayout,
  ...paneData
}) => {
  const {
    index,
    data,
    paneDataPoints,
    labelsData: labels,
    showDetails,
    multiAxis,
    multiGrid,
    scaleYaxis,
    chartTypeSub = 'timeSeries',
    scaleYAxisRange,
    color,
    pointInfoObj: pointData,
    xMinDomainProp,
    xMaxDomainProp,
    rawPlotData,
    compStartTime,
    StartTime,
    EndTime,
    id,
    annotations,
    paneIndex,
    showOverlays,
    overlaysData,
    overlayDomain,
    alarmsData
  } = paneData;
  const { isAddAnnotationEnabled } = showDetails || {};
  const { data: plotData, meta } = rawPlotData || {};

  // const plotData = chartTypeSub === CHART_TYPE_CATEGORY_SUB?.TIME_SERIES ? plotDataInitial?.sort((a, b) => (new Date(a.X) > new Date(b.X) ? 1 : -1)) : plotDataInitial;
  // const scaleYaxis = 'processWindow';
  // const { 'y-axis': yAxis, 'x-axis': xAxis, legends } = showDetails;
  const xMinDomain = useSelector((state) => state.KPVCharts.xMinDomain);
  const xMaxDomain = useSelector((state) => state.KPVCharts.xMaxDomain);
  const paneFullScreen = useSelector((state) => state.KPVCharts.paneFullScreen);
  const compressedView = useSelector((state) => state.KPVCharts.compressedView);
  const xMinDomainFullScreen = useSelector((state) => state.KPVCharts.xMinDomainFullScreen);
  const xMaxDomainFullScreen = useSelector((state) => state.KPVCharts.xMaxDomainFullScreen);
  const colorCodes = useSelector((state) => state.KPVCharts.colorCodes);
  // const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const comparisionMode = useSelector((state) => state.KPVCharts.comparisionMode);
  const isAligned = useSelector((state) => state.KPVCharts.isAligned);
  const themeMode = useSelector((state) => state.configData.themeMode);
  const [viewXLabels, setViewXLabels] = useState(true);
  useViewLabels(setViewXLabels, compressedView);
  const filterFilesSelected = useSelector((state) => state.KPVCharts.filterFilesSelected);
  // width calc (old method)
  // const { showSideNav } = useSelector((state) => state.home);
  // const [containerWidth, setContainerWidth] = useState(0);
  // const [width] = useWindowSize();
  // useContainerWidth(index, setContainerWidth, showSideNav, width, showDetails?.legends, paddingLeft, resize?.w);
  const [ref, { width: containerWidth, height: containerHeight }] = useMeasure();
  const dispatch = useDispatch();
  const [maxPlotChar, setMaxPlotChar] = useState(0);
  const panesData = useSelector((state) => state.KPVCharts.panesData);
  useEffect(() => {
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'globalHoveredState',
        value: {}
      })
    );
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'pointClicked',
        value: {}
      })
    );
  }, [containerWidth, compressedView, comparisionMode]);

  const chartRef = useRef();

  const checkIsAlarmPoint = (point) =>
    alarmsData?.find((alarmPoint) => {
      const {
        argument,
        data: { isOverlay }
      } = point;
      const { X, pane, Y } = alarmPoint;
      const res = !isOverlay && moment(argument).isSame(X) && point.data[pane + '-Y'] && true;
      return res;
    });

  const getPointInfo = (pointInfo) => {
    const {
      argument,
      point: { x, y },
      seriesName
    } = pointInfo;
    // const kpvKey = !isEmpty(meta) && Object.keys(meta)[0];
    // if (isAddAnnotationEnabled) {
    //   const xlabels = meta?.[kpvKey].xlabel;
    //   const datPointInfo = pointInfo.point.data;
    //   setPointClicked({
    //     argument,
    //     x: x + paddingLeft,
    //     y: 0,
    //     seriesName,
    //     kpvKey,
    //     kpvLabel: rawPlotData?.meta?.[kpvKey]?.fields?.displayname,
    //     pointLabel: xlabels[datPointInfo.xPointer]?.PointLabel
    //   });
    // }
    const isAlarmPoint = checkIsAlarmPoint({ argument: pointInfo.argument, data: pointInfo.point.data });

    const data = getNearestPointsInSPC(
      [...plotData, ...(overlaysData || [])],
      meta,
      showOverlays,
      argument,
      overlayDomain
    );
    setHoveredState({
      data,
      x: argument,
      top: y,
      left: x + paddingLeft,
      orgX: x,
      index: paneIndex,
      isAlarmPoint,
      seriesName
    });
    if (compressedView) {
      dispatch(kpvChartingCreators.setHoveredXValue({ argument, x: x + paddingLeft }));
    }
    // dispatch(kpvChartingCreators.setHoveredXValue(pointInfo.argument));
    // const l = pointInfo.point.series.getPointsByArg(pointInfo.argument).map((e) => e.data);
    // console.log('data chart point', l);
    // setHoveredState({ data: l, x: hoveredXValue });
    return { text: '' };
  };
  useEffect(() => {
    const chart = chartRef.current?.instance;
    try {
      // get the nearest points by postion only in aligned condition
      if (xPos && isAligned) {
        const e = getNearestPointByPosition(chart, xPos, CHART_TYPE_CATEGORY.SPC);
        setHoveredPosState(e);
      }
    } catch (e) {
      console.log('error in finding nearest point based on position', e);
    }
  }, [xPos, isAligned]);

  // only one old version...
  // const e = chart?.getAllSeries().reduce(
  //   (prev, s, index) => ({
  //     ...prev,
  //     [s.name]: nearestDataPoint(s._data, argument, 0, s._data.length)
  //     // .map((e) => e.data)
  //   }),
  //   {}
  // );

  // old
  // in compress view get all the series in this graph and getPointByArg

  const getLabel = (val) => {
    if (val?.valueText?.length >= maxCharactersAloowed) {
      return `${val?.valueText?.substring(0, maxCharactersAloowed)}...`;
    }
      return val?.valueText;
  };

  // console.log('hoveredState', hoveredState);
  // used in transition chart also
  const getYAxisLabel = () => (
    <Label
      // position={compressedView ? 'inside' : 'outside'}
      visible={showDetails ? showDetails['y-axis'] : true}
      // rotationAngle={320}
      displayMode='rotate'
      font={{
        family: fontFamily.circularBook,
        size: chartConfig.labelFontSize,
        // TODO: incorrect theme handling
        color: colors[themeMode].contrast.primary
      }}
      customizeText={(val) =>
        getLabel(val)
}
    />
  );

  // bands applicable to single grid and single pane charts
  // const stripsItem = (kpv) =>
  //   meta[kpv].fields.Limit.map((limit) =>
  //     Object.keys(limit).map((e) => (
  //       // console.log('limit', e, limit[e]);
  //       <Strip startValue={e} color={limit[e]} />
  //     ))
  //   );

  // can be minimum/maximum
  // const getExtremeValues = (kpv, isMin = true) =>
  //   meta[kpv].fields.Limit.reduce((prevVal, currVal) => {
  //     // for maximum
  //     if (!isMin) {
  //       if (prevVal === null || parseInt(Object.keys(currVal)[0], 10) > parseInt(prevVal, 10)) {
  //         return Object.keys(currVal)[0];
  //       }
  //       return prevVal;
  //     }
  //     // for minimum
  //     if (prevVal === null || parseInt(Object.keys(currVal)[0], 10) < parseInt(prevVal, 10)) {
  //       return Object.keys(currVal)[0];
  //     }
  //     return prevVal;
  //   }, null);

  // const lowermostBand = (kpv) => {
  //   const minLimit = getExtremeValues(kpv, true);
  //   // return meta[kpv].fields.Limit.map((limit) => {
  //   // console.log('minLimit', kpv, minLimit, limit[minLimit]);
  //   const limits = meta[kpv].fields.Limit.reduce((prev, curr) => ({ ...curr, ...prev }), {});
  //   // console.log('limi', limits, minLimit);
  //   return <Strip endValue={minLimit} color={limits[minLimit]} />;
  //   // });
  // };

  // const stripsArr =
  //   meta &&
  //   Object.keys(meta).map((kpv, index) =>
  //     // console.log('strip meta', meta[kpv].fields.Limit);
  //     stripsItem(kpv)
  //   );
  // const lowermostStripsArr =
  //   meta &&
  //   Object.keys(meta).map((kpv, index) =>
  //     // console.log('strip meta', kpv, meta[kpv].fields.Limit);
  //     lowermostBand(kpv)
  //   );

  const getArgumentAxisRange = () => {
    // if (comparisionMode && StartTime) {
    //   return [new Date(StartTime), new Date(EndTime)];
    // }
    if (comparisionMode && StartTime) {
      if (!isEmpty(filterFilesSelected)) {
        return [new Date(xMinDomain), new Date(xMaxDomain)];
      } return [new Date(StartTime), new Date(EndTime)];
    }
    if (paneFullScreen) {
      return [new Date(xMinDomainFullScreen), new Date(xMaxDomainFullScreen)];
    }
    return [
      xMinDomainProp ? new Date(xMinDomainProp) : new Date(xMinDomain),
      xMaxDomainProp ? new Date(xMaxDomainProp) : new Date(xMaxDomain)
    ];
  };
  // console.log('getArgumentAxisRange', getArgumentAxisRange());

  const getValueAxisRange = (defaultOverlay) => {
    if (scaleYaxis === 'manual') {
      // console.log('scaleYAxisRange', scaleYAxisRange, position, scaleYAxisRange[position]);
      return [scaleYAxisRange?.[defaultOverlay]?.from, scaleYAxisRange?.[defaultOverlay]?.to];
    }
    // if (scaleYaxis === 'processWindow') {
    //   return [
    //     Math.min(meta[kpv].minValue, getExtremeValues(kpv, true)),
    //     Math.max(meta[kpv].maxValue, getExtremeValues(kpv, false))
    //   ];
    // }
    return [null, null];
  };

  const customizePoint = (point) => {
    // special point for annotation
    if (showDetails?.annotations) {
      const isAnnotationPoint = annotations?.some(
        (argPoint) => point.seriesName === argPoint.seriesKey && moment(point.argument).isSame(argPoint.argument)
      );
      if (isAnnotationPoint) {
        return {
          size: 15,
          color: 'black',
          symbol: 'circle'
        };
      }
    }

    // alarm points
    const isAlarmPoint = checkIsAlarmPoint(point);
    if (isAlarmPoint && !isEmpty(isAlarmPoint)) {
      return {
        size: 15,
        symbol: 'polygon',
        color: isAlarmPoint.color
      };
    }

    // let shouldCustomize = false;
    // console.log('point', hoveredState.data && hoveredState.data[0]);
    const shouldCustomize =
      !isEmpty(meta) &&
      Object.keys(meta).some((kpv, index) => {
        if (comparisionMode) {
          const comparisionModeCondition = comparisionMode && moment(point.argument).isSame(compStartTime, 'second');
          return comparisionModeCondition;
        }

        // const nearestPointCondition =
        //   !showDetails?.annotations &&
        //   point.seriesName === `${kpv}-Y` &&
        //   moment(point.argument).isSame(hoveredState?.data && hoveredState.data[0][`${kpv}-TSValue`]);
        // // point is customized on nearest data point and in comparision mode
        // return nearestPointCondition;

        return false;
      });
    if (shouldCustomize) {
      return {
        size: 15
      };
    }
    return {};
  };

  const setXaxisVisibility = () => {
    // find the label dom element at making it display none
    // instead of making devextreme handle the visibility
    // const currentPaneNo = panesShortHandInfo.findIndex((paneInfo) => paneInfo.id === id);
    // console.log('x-axis', viewXLabels, showDetails['x-axis'], id, index);
    // 'dxc-arg-elements' is the className for the x-labels
    const axisElements = document.getElementById(id)?.getElementsByClassName('dxc-arg-elements');
    const xAxisEle = !isEmpty(axisElements) ? axisElements[axisElements.length - 1] : false;

    if (xAxisEle) {
      let visibleCondition = false;
      if (compressedView) {
        visibleCondition = viewXLabels || isLastPane;
      } else {
        visibleCondition = showDetails ? showDetails['x-axis'] : true;
      }

      if (!visibleCondition) {
        xAxisEle.style.visibility = 'hidden';
      } else {
        xAxisEle.style.visibility = 'visible';
      }
    }
  };
  const isMounted = useRef();
  useEffect(() => {
    let timer;
    if (!isMounted.current) {
      isMounted.current = true;
      timer = setTimeout(() => {
        setXaxisVisibility();
      }, 1000);
    } else {
      setXaxisVisibility();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showDetails['x-axis'], compressedView, viewXLabels]);

  function onPointClick({ target: point }) {
    const {
      argument,
      x,
      y,
      series: { name: seriesName }
    } = point;
    // // console.log('point', argument);
    // if (point.isSelected()) {
    //   point.clearSelection();
    //   dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: xMinDomain }));
    // } else {
    //   dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: argument }));
    //   point.select();
    // }

    // point clickable on compariosn mode and not aligned
    if (comparisionMode && !isAligned) {
      dispatch(kpvChartingCreators.editPaneData({ index, key: 'compStartTime', value: argument }));
    } else if (isAddAnnotationEnabled) {
      // removed method of add/edit annotations by onClick(moved to onHover)
      const kpvKey = !isEmpty(meta) && Object.keys(meta)[0];
      const xlabels = meta?.[kpvKey].xlabel;
      const datPointInfo = point.data;
      setPointClicked({
        argument,
        x: x + paddingLeft,
        y,
        seriesName,
        kpvKey,
        kpvLabel: rawPlotData?.meta?.[kpvKey]?.fields?.displayname,
        pointLabel: xlabels[datPointInfo.xPointer]?.PointLabel
      });
    }
  }
  // if (!meta || !plotData) return <Loader />;

  const findMaxLength = (plotData) => {
    let max = 0;
  for (let i = 0; i < plotData?.length; i += 1) {
    const currLength = (plotData[i]?.Y)?.toString()?.length;
    if (currLength + 1 > max) {
      max = currLength + 1;
    }
  }
    return max;
  };

  useEffect(() => {
    const maxArr = [];
    for (let i = 0; i < panesData?.length; i += 1) {
       maxArr.push(findMaxLength(panesData[i]?.rawPlotData?.data));
    }
    const max = Math.max(...maxArr);
    const maxAllocatedLen = max <= maxCharactersAloowed ? max : maxCharactersAloowed;
    setMaxPlotChar(maxAllocatedLen);
  }, [panesData]);

  return (
    <ChartContainer fillLayout={fillLayout} ref={ref}>
      {/* pane labels */}
      {!isEmpty(meta) &&
        Object.keys(meta).map((kpv) =>
          Object.keys(meta[kpv].default).map((defaultOverlay, i) => {
            // top position of label
            const heightPos = i * (paneHeight + chartPaddingTop + 10);
            const leftPos = paddingLeft + 60;
            return (
              <p
                style={{
                  position: 'absolute',
                  fontSize: '1.6rem',
                  left: `${leftPos}px`,
                  top: `${heightPos}px`
                }}
              >
                {defaultOverlay}
              </p>
            );
          })
        )}
      <Chart
        id={`chart${paneIndex}`}
        dataSource={[...(plotData || []), ...(overlaysData || [])]}
        // style={!paneFullScreen ? { maxHeight: '50rem', ...chartStyles } : { ...chartStyles }}
        // style={chartStyles}
        // style={{ height: '100%' }}
        size={{
          width: containerWidth
          // , height: containerHeight || '100%'
        }}
        rotated={null}
        ref={chartRef}
        customizePoint={customizePoint}
        onPointClick={onPointClick}
      >
        <Crosshair enabled={!comparisionMode} color='black' width={3} dashStyle='solid'>
          <HorizontalLine visible={false} />
          <Label visible={true} backgroundColor='black'>
            <Font color='#fff' size={12} />
          </Label>
        </Crosshair>
        {/* {console.log(xMaxDomainProp, xMinDomainProp)} */}
        {/* {console.log('location', location.pathname === '/home/kpv-charting/edit-pane')} */}
        {/* <AdaptiveLayout /> */}
        {/* <ZoomAndPan argumentAxis='both' valueAxis='both' /> */}
        <ArgumentAxis
          // eslint-disable-next-line react/jsx-props-no-multi-spaces
          visualRange={getArgumentAxisRange()}
          // visualRange={['2022-02-01T06:30:00.000Z', '2022-02-05T06:30:00.000Z']}
          valueMarginsEnabled={false}
          endOnTick={false}
          color={colors[themeMode].contrast.lightSecondary}
        >
          {/* comparison mode start line */}
          {comparisionMode && isAligned && compStartTime && (
            <ConstantLine
              width={1}
              value={compStartTime}
              color={colors[themeMode].contrast.lightSecondary}
              dashStyle='solid'
            >
              <Label visible={false} />
            </ConstantLine>
          )}
          {/* annotations lines */}
          {showDetails?.annotations &&
            !isEmpty(annotations) &&
            annotations
              .filter(
                (e) =>
                  (rawPlotData?.meta &&
                    Object.keys(Object.values(rawPlotData.meta)[0].default).includes(e.seriesKey)) ||
                  (showOverlays && Object.keys(showOverlays).includes(e.seriesKey))
              )
              .map((annotPoint) => (
                <ConstantLine
                  width={2}
                  value={annotPoint.argument}
                  color={colors[themeMode].contrast.lightSecondary}
                  dashStyle='dot'
                  // displayBehindSeries={true}
                >
                  <Label visible={false} />
                </ConstantLine>
              ))}
          <Grid visible={true} width={1} color={colors[themeMode].contrast.lightSecondary} />
          <Label
            // visible={showDetails ? (showDetails['x-axis'] && viewXLabels) || isLastPane : true}
            // visible={showDetails ? showDetails['x-axis'] : true}
            font={{
              family: fontFamily.circularBook,
              size: 22,
              color: colors[themeMode].contrast.primary,
              wordWrap: 'breakWord'
            }}
            alignment='left'
            customizeText={(tick) => moment(tick.value).format('DD/MM/YYYY HH:mm:ss:sss')}
          />
        </ArgumentAxis>
        <Legend visible={false} customizeText={(item) => labels[item.seriesName]} font={{ size: 60 }} />
        <CommonSeriesSettings
          argumentField={chartTypeSub === CHART_TYPE_CATEGORY_SUB?.TIME_SERIES ? 'X' : 'XLabel'}
          // valueField='Y'
          type='line'
        >
          <Tick visible={true} />
          <Grid visible={true} width={1} />
          <Point size={0} hoverMode='onlyPoint' hoverStyle={{ size: 15 }} />
        </CommonSeriesSettings>
        <Tooltip enabled={true} customizeTooltip={getPointInfo} />
        {/* value axis */}
        {/* multi axis, multiple grid */}
        {!isEmpty(meta) &&
          Object.keys(meta).map((kpv) =>
            Object.keys(meta[kpv].default).map((defaultOverlay) => (
              <Pane height={paneHeight} name={meta[kpv].default[defaultOverlay][0].node} key={kpv} />
            ))
          )}
        {/* !multiAxis */}
        {!isEmpty(meta) &&
          Object.keys(meta).map((kpv) =>
            Object.keys(meta[kpv].default).map((defaultOverlay) => (
              <ValueAxis
                allowDecimals={true}
                // autoBreaksEnabled={true}
                valueMarginsEnabled={false}
                endOnTick={false}
                pane={meta[kpv].default[defaultOverlay][0].node}
                name={meta[kpv].default[defaultOverlay][0].node}
                visualRange={meta && getValueAxisRange(defaultOverlay)}
                color={meta[kpv].default[defaultOverlay][0].color}
                placeholderSize={placeHolderSize * maxPlotChar}

              >
                {getYAxisLabel()}
              </ValueAxis>
            ))
          )}
        {/* {
          // multiAxis &&
          !isEmpty(meta) &&
            Object.keys(meta).map((kpv, index) => (
              <ValueAxis
                key={kpv}
                name={`${kpv}-Y`}
                // pane={multiGrid ? kpv : undefined}
                pane={kpv}
                color={colorCodes[index]}
                multipleAxesSpacing={chartConfig.multipleAxesSpacing}
                // autoBreaksEnabled={true}
                valueMarginsEnabled={false}
                endOnTick={false}
                visualRange={getValueAxisRange(kpv, index)}
              >
                {getYAxisLabel()}
                {meta && multiGrid && showOverlays?.limits !== false && stripsItem(kpv)}
              </ValueAxis>
            ))
        } */}
        {/* single axis */}
        {/* {
          // !multiAxis && (
          <ValueAxis
            allowDecimals={true}
            // autoBreaksEnabled={true}
            valueMarginsEnabled={false}
            endOnTick={false}
            visualRange={meta && getValueAxisRange(Object.keys(meta)[0])}
            color={colors[themeMode].contrast.lightSecondary}
          >
            <Grid visible={true} width={1} color={colors[themeMode].contrast.lightSecondary} />
            {getYAxisLabel()}
            {meta && Object.keys(meta).length === 1 && showOverlays?.limits !== false && lowermostStripsArr}
            {meta && Object.keys(meta).length === 1 && showOverlays?.limits !== false && stripsArr}
          </ValueAxis>
          // )
        } */}
        {/* series */}
        {/* default overlays */}
        {!isEmpty(meta) &&
          Object.keys(meta).map((kpv, index) => {
            const { default: df } = meta[kpv];
            return Object.keys(df).map((defaultOverlay) => {
              const { node: nodeName, linetype, color } = df[defaultOverlay][0];
              return (
                <Series
                  key={kpv}
                  // axis name is used to bind the value axis, by default it is binded to first axis
                  axis={nodeName}
                  // pane={multiAxis && multiGrid ? kpv : undefined}
                  pane={nodeName}
                  valueField={`${nodeName}-Y`}
                  name={defaultOverlay}
                  // axis={!multiAxis ? null : key}
                  // color={color || colorCodes[index]}
                  color={color || colorCodes[index]}
                  ignoreEmptyPoints={false}
                />
              );
            });
          })}
        {/* enabled menuOverlays */}
        {!isEmpty(showOverlays) &&
          Object.entries(showOverlays)?.map((overlay) => {
            const [key, value] = overlay;
            const { kpv, data } = value;
            const nodeName =
              !isEmpty(meta) && Object.values(Object.values(meta)[0].default)[data[0].grid - 1]?.[0]?.node;
              return data?.map((e) => (
                <Series
                  key={key}
                // axis name is used to bind the value axis, by default it is binded to first axis
                  axis={nodeName}
                  pane={nodeName}
                  valueField={e.node + '-Overlay'}
                // name={kpv + '-Y'}
                  name={key}
                // axis={!multiAxis ? null : key}
                  color={e?.color}
                  dashStyle={DASH_STYLE_MAPPING[e?.linetype] || e?.linetype}
                  ignoreEmptyPoints={false}
                />
            ));
          })}
        {/* for multiple series in a single pane alternative */}
        {/* <SeriesTemplate
        nameField='kpv'
        // customizeSeries={customizeSeries}
      /> */}
      </Chart>
    </ChartContainer>
  );
};

export default memo(SPCChart);
