/* eslint-disable quotes */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import DataChart from '../Chart/DataChart.view';
// import TransitionChart from '../Chart/TransitionChart.view';
import { Container, GraphContainer, Header } from './PaneCards.style';
// import LegendComp from '../Legend/Legend.view';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import { Creators as kpvChartingCreators } from '../Store/action';
import { getPlotDataPayload } from '../utils/getPlotDataPayload';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { setPlotData } from '../utils/formatPlotData';
import DataGraphComponent from '../Chart/DataChart/DataGraphWrapper';
import TransitionGraphComponent from '../Chart/TransitionChart/TransitionChartWrapper';
import SPCGraphComponent from '../Chart/SPCChart/SPCGraphWrapper';
import XYChartWrapper from '../Chart/XYChart/XYChartWrapper';

const FullScreenPane = () => {
  const {
    panesData: panesPlotData,
    panesShortHandInfo,
    selectedPaneNo,
    xMinDomain,
    xMaxDomain,
    xMinDomainFullScreen,
    xMaxDomainFullScreen
  } = useSelector((state) => state.KPVCharts);

  const dispatch = useDispatch();
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);

  const [paneData, setPaneData] = useState({ ...panesShortHandInfo[selectedPaneNo], ...panesPlotData[selectedPaneNo] });

  useEffect(() => {
    dispatch(
      kpvChartingCreators.setDomainRangeInFullScreen({
        xMinDomainFullScreen: xMinDomain,
        xMaxDomainFullScreen: xMaxDomain
      })
    );
    return () => {
      kpvChartingCreators.setDomainRangeInFullScreen({
        xMinDomainFullScreen: xMinDomain,
        xMaxDomainFullScreen: xMaxDomain
      });
    };
  }, []);

  const [fullScreenInteractions, setFullScreenInteractions] = useState({
    loading: false,
    error: ''
  });

  // get plotdata when change in fullscreen domain
  useEffect(() => {
    setFullScreenInteractions({ ...fullScreenInteractions, loading: true });
    const machinesWithNoPlotData = getPlotDataPayload(
      [panesShortHandInfo[selectedPaneNo]],
      xMinDomainFullScreen,
      xMaxDomainFullScreen
    );
    // isUpdate = true
    const fullURL = `${apiEndPoints.getPlotData}`;
    const { plotDataAvl, singlePane, ...rest } = machinesWithNoPlotData[0];

    WebService.post(fullURL, rest)
      .then((res) => {
        const plotData = setPlotData([{ ...res.data, reqPayload: rest }]);
        // console.log('resas', res.data, setPlotData([{ ...res.data, reqPayload: rest }]));
        setPaneData({ ...paneData, rawPlotData: plotData[0].rawPlotData });
        setFullScreenInteractions({ ...fullScreenInteractions, loading: false });
      })
      .catch((e) => {
        const err = sagaCatchBlockHandling(e);
        // console.log('eeeadad', err);
        setFullScreenInteractions({ ...fullScreenInteractions, loading: false, error: err.payload });
      });
  }, [xMinDomainFullScreen || xMaxDomainFullScreen]);

  const { rawPlotData, paneTitle, chartType, chartTypeCategory } = paneData;

  return (
    <Container className='fullscreen'>
      {paneTitle ? (
        <Header>{paneTitle || 'Pane Title'}</Header>
      ) : (
        <Header>{`${localeStrings.Pane || 'Pane'} ${localeStrings.Title || 'Title'}`}</Header>
      )}
      <GraphContainer className='fullscreen'>
        {/* using wrapper components to minimize the code dupication */}
        {(chartTypeCategory === CHART_TYPE_CATEGORY.DATA_CHART || chartType === CHART_TYPES.DATA_CHART) && (
          <DataGraphComponent isFullScreen {...paneData} position='bottom' />
        )}
        {(chartTypeCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART || chartType === CHART_TYPES.TRANSITION_CHART) && (
          <TransitionGraphComponent {...paneData} isFullScreen position='bottom' />
        )}
        {chartTypeCategory === CHART_TYPE_CATEGORY.SPC && (
          <SPCGraphComponent {...paneData} isFullScreen position='bottom' />
        )}
        {chartTypeCategory === CHART_TYPE_CATEGORY.XY_CHART && (
        <XYChartWrapper
          {...paneData}
          isFullScreen
          position='bottom'
        />
        )}
      </GraphContainer>
    </Container>
  );
};
export default FullScreenPane;
