/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { utils, read, writeFile } from 'xlsx';
import { ModalContentText } from '../DropDownModal/DropDownModal.style';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { Modal, ModalContent, Heading } from '../PopUpModal/Modal.style';
import { ReactComponent as CancelBlackSvg } from '../../../assets/icons/Filter/cancel black.svg';
import { Div, FlexContainer } from '../../../globalStyles';
import FileUploadUi from './FileUploadUi';
// import { Span } from '../KeyValueLayout/KeyValueLayout.style';
import { Span, FileUploadBox, UploadingBox, HeadingDiv, Dot } from './FileUpload.style';
// /* import because disabled is not ButtonCOmp
import { FilterApplyButton, FilterApplyText } from '../../Filter/FilterTagArea/FilterTagArea.style';
import ButtonComp from '../Button/Button.view';
import SheetJSApp from '../Excel/ImportExcel';
import { simpleArrayToArrayJson } from '../Excel/utility';

const FileUpload = ({ setFileUploadBox, setData, handleNext }) => {
  const [file, setFile] = useState(null);
  const [isFile, setIsFile] = useState();
  const [prog, setProg] = useState(0);
  const [uploadingStatus, setUpLoadingStatus] = useState('Uploading...');
  //   const [uploadedBytes, setUploadedBytes] = useState();
  //   const [totalBytes, setTotalBytes] = useState();
  const [remainingTime, setRemainingTime] = useState(0);
  const [showPorgress, setShowProgress] = useState(false);
  const statusRef = useRef();
  const remainingTimeRef = useRef();
  const FileUploadRef = useRef();
  const fileTypes = ['xlsx', 'xlsm', 'xlsb', 'xls', 'docx', 'jpg', 'jpeg'];
  const startingTime = Date.now();

  // const handleChange = (file) => {
  //   setIsFile(file);
  // };

  // const UploadFile = () => {
  //   setShowProgress(true);
  //   const file = isFile[0];
  //   const fileSize = file.size;
  //   // setTotalBytes(file?.size);
  //   setFile(URL.createObjectURL(file));
  //   const formData = new FormData();
  //   formData.append('file', file);

  //   const xhr = new XMLHttpRequest();
  //   xhr.upload.addEventListener('progress', ProgressHandler, false);
  //   xhr.addEventListener('load', SuccessHandler, false);
  //   xhr.addEventListener('error', ErrorHandler, false);
  //   xhr.addEventListener('abort', AbortHandler, false);
  //   xhr.open('POST', 'https://v2.convertapi.com/upload');
  //   xhr.send(formData);
  //   // console.log(xhr, 'xhrxhrhxhrhxhr');

  //   // const getFileObject = (progress) => {
  //   //   //   progress;
  //   //   console.log(progress, '))))))');
  //   // };

  //   // axios.post('https://v2.convertapi.com/upload', file, {
  //   //   onUploadProgress: (e) => {
  //   //     console.log(e, '==+++++++');
  //   //     ProgressHandler(e);
  //   //   },
  //   //   onLoad: (e) => {
  //   //     SuccessHandler(e);
  //   //   }
  //   // });
  // };

  const ProgressHandler = (e) => {
    const totalBytes = e.total;
    const uploadedBytes = e.loaded;
    const percent = (e.loaded / e.total) * 100;
    setProg(Math.round(percent));
    // console.log('pp', percent);
    statusRef.current.innerHTML = `${Math.round(percent)}%`;
    const currentTime = Date.now();
    const uploadSpeed = (uploadedBytes / (currentTime - startingTime)) * 1000;
    const timeRemaining = Math.round((totalBytes - uploadedBytes) / uploadSpeed);
    setRemainingTime(timeRemaining);
  };

  const SuccessHandler = (e) => {
    // console.log(e, '--88-');
    if (e.target.status === 200) {
      setUpLoadingStatus('Upload completed');
    }
    // statusRef.current.innerHTML = e.target.status;
    // progressRef.current.value = 0;
  };
  const ErrorHandler = (e) => {
    statusRef.current.innerHTML = 'upload failed!!';
  };
  const AbortHandler = (e) => {
    statusRef.current.innerHTML = 'upload aborted!!';
  };

  const handleCancel = () => {
    setShowProgress(false);
  };

  function handleFile(file /*: File */) {
    setShowProgress(true);
    setFile(file);
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const [columns, ...data] = utils.sheet_to_json(ws, { header: 1 });

      // old
      // const xhr = new XMLHttpRequest();
      // xhr.upload.onprogress = (evt) => {
      //   const percent = (evt.loaded / evt.total) * 100;
      //   console.log('percent', Math.round(percent));
      // };
      // xhr.open('POST', 'https://v2.convertapi.com/upload');
      // xhr.send(data);

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', ProgressHandler, false);
      xhr.addEventListener('load', SuccessHandler, false);
      xhr.addEventListener('error', ErrorHandler, false);
      xhr.addEventListener('abort', AbortHandler, false);
      xhr.open('POST', 'https://v2.convertapi.com/upload');
      xhr.send(data);
      /* Update state */
      setData(simpleArrayToArrayJson(columns, data));
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  function handleChange(e) {
    const { files } = e.target;
    if (files && files[0]) handleFile(files[0]);
  }

  useEffect(() => {
    if (prog === 100) {
      // proceed to next step and close the upload box
      setFileUploadBox(false);
      handleNext();
    }
  }, [prog]);

  return (
    <>
      <Modal>
        <ModalContent width='32%' className='fileUploadForm'>
          <HeadingDiv className='ai_center jc_spacebetween'>
            <Heading className='modalHeading'>Add multiple item</Heading>
            <CancelBlackSvg
              className='pointer'
              width='1.8rem'
              onClick={() => {
                setFileUploadBox(false);
              }}
            />
          </HeadingDiv>
          {/* library */}
          {/* <FileUploader
            ref={FileUploadRef}
            multiple={true}
            children={<FileUploadUi file={isFile} />}
            name='file'
            types={fileTypes}
            handleChange={handleChange}
            maxSize={150}
          /> */}
          <SheetJSApp
            handleFile={handleFile}
            content={<FileUploadUi file={file} handleChange={handleChange} />}
            handleChange={handleChange}
          />
          {!showPorgress ? (
            <div style={{ margin: '2rem 0 2rem 34%' }}>
              {/* <ButtonComp dark className={!isFile ? 'disable' : ''} onClick={UploadFile}>
                <FilterApplyText>Upload</FilterApplyText>
              </ButtonComp> */}
            </div>
          ) : (
            <UploadingBox>
              <ModalContentText className='uploader'>{uploadingStatus}</ModalContentText>
              <FlexContainer className='jc_spacebetween ai_center'>
                <FlexContainer>
                  <Span>{isFile?.[0]?.name}</Span> <Dot> .</Dot>
                  <Span ref={statusRef} />
                  {uploadingStatus !== 'Upload completed' && (
                    <>
                      <Dot>.</Dot>
                      <Span>{remainingTime} Secs remaining</Span>
                    </>
                  )}
                  <Span ref={remainingTimeRef} />
                </FlexContainer>
                <FlexContainer>
                  <div>
                    <CancelBlackSvg
                      className='pointer'
                      style={{
                        background: '#fff',
                        width: '2.4rem',
                        height: '2.4rem',
                        padding: '2px',
                        borderRadius: '50%'
                      }}
                      onClick={handleCancel}
                    />
                  </div>
                </FlexContainer>
              </FlexContainer>

              <label>
                <progress
                  //   ref={progressRef}
                  value={prog}
                  max='100'
                  style={{
                    width: '100%',
                    height: '10px'
                  }}
                />
              </label>
              {/* <p ref={statusRef} /> */}
            </UploadingBox>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default FileUpload;
