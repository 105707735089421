/* eslint-disable max-len */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';

export const InputCotainer = styled.input`
  background-color: ${({ theme }) => theme.core.secondary};
  box-shadow: ${({ theme }) => theme.shadowin};
  border-radius: 1rem;
  border: 0rem;
  width: ${({ width }) => width || '100%'};
  height: min(45px, 100%);
  min-height: 45px;
  padding-left: 2.1rem;
  padding-right: 3.7rem;
  color: ${({ theme }) => theme.contrast.quaternary};
  font-size: ${fontSize.text};
  font-family: ${fontFamily.circularMedium};
  &.gray {
    background-color: ${({ theme }) => theme.input.gray};
  }

  &.input {
    padding-left: 2rem;
    padding-right: 0.1rem;
    padding-top: 5px;
    text-transform: uppercase;
    color: #717171;
  }

  &:read-only {
    background-color: ${({ theme }) => theme.aux.darkSecondary};
  }

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeHolderText};
    font-size: ${fontSize.loginInputSize};
    font-family: ${fontFamily.circularBook};
    text-transform: capitalize;
  }

  &:disabled {
    opacity: 0.4;
  }

  &.widgetSideBar {
    margin-bottom: 3rem;
  }

  & + .searchIcon {
    position: absolute;
    right: 8px;
  }

  &.nonActive {
    box-shadow: none;
    background-color: white;
    pointer-events: none;
    font-size: ${fontSize.titleBig};
    color: black;
  }
`;

const Index = (props) => {
  const { disabled, placeholder, value, setValue, maxLength, type, margin, style, width, handleKeyDown, ...rest } = props;
  const ref = useRef();
  return (
    <InputCotainer
      disabled={disabled}
      placeholder={placeholder || ''}
      value={value}
      onChange={(event) => setValue(event.target.value, rest)}
      type={type}
      ref={ref}
      style={{ margin, ...style }}
      width={width}
      onKeyDown={(event) => handleKeyDown(event.target.value, event.key, rest)}
      maxLength={maxLength}
    />
  );
};

Index.defaultProps = {
  disabled: false,
  placeholder: '',
  value: '',
  setValue: () => null,
  handleKeyDown: () => {},
  type: 'text'
};

export default Index;
