/* eslint-disable prefer-destructuring */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React, { useEffect, useState } from 'react';
import { BarGauge, Label, Size } from 'devextreme-react/bar-gauge';
import { LimitPoint, DonutDetails, DonutDiv, P, EquipmentTitle } from './EquipmentDonut.style';
import { getColorFromDonutMeta } from '../../../../component/Widget/utils';
import useMeasure from '../../../../shared/hooks/useMeasure';

const format = {
  type: 'fixedPoint',
  precision: 1
};

const SemiDonut = ({ data, donutMeta }) => {
  const { percentage, target, max, min, label, color: inlineColor } = data || {};
  const donutMetaColors = donutMeta.colors || {};
  let color = inlineColor;
  if (!color) {
    const res = getColorFromDonutMeta(parseInt(percentage, 10) - parseInt(target, 10), donutMetaColors);
    // console.log(
    //   'res',
    //   res,
    //   donutMetaColors,
    //   percentage,
    //   target,
    //   percentage >= target,
    //   parseInt(percentage, 10) >= parseInt(target, 10)
    // );
    color = res;
  }
  const [ref, { width, height }] = useMeasure();
  return (
    <DonutDiv ref={ref}>
      <BarGauge
        id='gauge'
        startValue={min}
        endValue={max}
        values={[percentage]}
        relativeInnerRadius={0.65}
        palette={[color]}
      >
        <Label visible={false} customizeText={() => ' '} />
        <Size height={height} width={width} />
      </BarGauge>
      <LimitPoint>
        <BarGauge
          relativeInnerRadius={0.65}
          backgroundColor=''
          palette={['']}
          startValue={min}
          endValue={max}
          values={[target]}
        >
          <Label connectorWidth={3} connectorColor='yellow' format={format} visible={true} customizeText={() => ' '} />
          <Size height={height} width={width} />
        </BarGauge>
      </LimitPoint>
      <DonutDetails className='donut-details' height={height}>
        <P className='fn'>OEE</P>
        <P className='fl mb' style={{ color: `${color}` }}>
          {percentage}%
        </P>
        {/* <br /> */}
        <P className='fn'>TGT</P>
        <P className='fs mb' style={{ color: `${color}` }}>
          {target}%
        </P>
        {/* <br /> */}
      </DonutDetails>
      <EquipmentTitle>
        <P className='fm'>{label}</P>
      </EquipmentTitle>
    </DonutDiv>
  );
};

export default SemiDonut;
