/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { AddEquipBtn, DonutListContainer, TabBtnContainer, TabButton, TabContainer } from './EquipmentDonut.style';
import SemiDonut from './SemiDonut';
import { TabUi } from '../../../../component/Common/TabUi/TabUi';

const DonutWidget = (props) => {
  const [currentTab, setCurrentTab] = useState('1');
  const history = useHistory();
  const state = useLocation();
  const { pathname } = state;
  const { tabs, inputByDonut, donutData, donutMeta, zoomLevel, className, WID } = props;

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  const addEquipment = () => {
    history.push('/home/create-widget/form', { donutData, inputByDonut });
  };

  const location = useLocation();

  return (
    <>
      <TabContainer className={className}>
        <TabBtnContainer id={`tab${WID}`}>
          <TabUi currentTab={currentTab} tabs={tabs} handleTabClick={handleTabClick} />
        </TabBtnContainer>
        {/* donuts */}
        <DonutListContainer zoomLevel={zoomLevel}>
          {!isEmpty(tabs) &&
            tabs
              .find((tab) => tab.id === parseInt(currentTab, 10))
              ?.data?.map((item, i) => (
                <React.Fragment key={i}>
                  <SemiDonut data={item} donutMeta={donutMeta} />
                </React.Fragment>
              ))}
          {/* TODO: not inuse display only while creation */}
          {location.pathname?.includes('create-widget') && (
            <AddEquipBtn onClick={addEquipment}>
              <h1 style={{ fontSize: '5rem' }}>+</h1>
              <h1 style={{ fontSize: '2rem' }}>Add Equipment</h1>
            </AddEquipBtn>
          )}
        </DonutListContainer>
      </TabContainer>
    </>
  );
};

export default DonutWidget;
