/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */

export default function useSelectionTypesDefined(filterData, parent) {
  const selectionTypesDefined = {};
  let ref = filterData.find((grp) => grp.groupTitle === parent);
  while (ref) {
    selectionTypesDefined[ref.attribute] = ref.selectionType;
    if (ref.values && ref.values[0].attribute && ref.values[0].values) ref = ref.values[0];
    else break;
  }
  return selectionTypesDefined;
}
