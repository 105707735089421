/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/named */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AddWidgetBg } from '../../../assets/icons';
import { WidgetCreators } from '../../../views/Widgets/store';
import { isEmpty } from '../../../shared/utility/isEmpty';
import ShowcaseLayout from './ShowcaseLayout';
import { FlexContainer } from '../../../globalStyles';
import Loader from '../../Loader/Loader';
import EmptyWidgetLanding from '../Common/EmptyWidgetLanding';
import WidgetTypeSelectionSideBar from '../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar';
import { useActions } from '../../../store/hooks/useActions';
import WidgetTemplateSelectionSideBar from '../../UpsertWidget/WidgetTemplateSelectionSideBar/WidgetTemplateSelectionSideBar';
import { PanelHeadingText, TabHeadingText } from '../Header/TabsList/TabsList';
import InstanceSelectionPop from '../../UpsertWidget/InstanceSelectionView/InstanceSelectionPop';

const WidgetDashBoard = ({ isTemplateDashboard, instancePreview, templateParamterDetails, isTemplateInstanceEdit, isCustom = false, WTId = '' }) => {
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WPID = useSelector((state) => state.upsertWidgetData.WPId);
  const WTIId = useSelector((state) => state.upsertWidgetData.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const shouldRefreshPage = useSelector((state) => state.widgets.shouldRefreshPage);
  const TabType = useSelector((state) => state.widgets.TabType);
  const { widgetDetailSetter, getWidgetsLayout, getPreviewTemplate, setIsPreviewInstance, getPreviewLayout, setTemplateBasedData, setConfigureTemplate, valueSetter } = useActions();
  // const widget = useSelector((state) => state.widgets.addedWidget);
  // const [searchValue, setSearchValue] = useState(activeTab);
  const dispatch = useDispatch();
  const handleAddWB = () => {
    if (TabType === 'Instance_Tab') {
      valueSetter({ showInstanceTablePopUp: true });
    } else {
      dispatch(WidgetCreators.widgetDetailSetter({ key: 'showSidePanel', value: true }));
    }
  };
  const showTemplateListSidePanel = useSelector((state) => state.upsertWidgetData.showTemplateListSidePanel);
  const showInstanceTablePopUp = useSelector((state) => state.upsertWidgetData.showInstanceTablePopUp);
  const loadingLayout = useSelector((state) => state.widgets.loadingLayout);
  const showSidePanel = useSelector((state) => state.widgets.showSidePanel);
  const layout = useSelector((state) => state.widgets.layout);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const widgetComponentData = useSelector((state) => state.widgets.WidgetComponentData);
  const WidgetPanelName = useSelector((state) => state.widgets.WidgetPanelName);
  const WidgetPanelDesc = useSelector((state) => state.widgets.WidgetPanelDesc);
  const TemplateBaseData = useSelector((state) => state.widgets.TemplateBaseData);
  const configureInstance = useSelector((state) => state.widgets.configureInstance);
  const userData = useSelector((state) => state.loginData.userData);
  const isEditOn = useSelector((state) => state.widgets.isEditOn);

  // get layout
  useEffect(() => {
    if (!isEmpty(activeTab) && !instancePreview) {
      // console.log('getting layout for........', activeTab);
      widgetDetailSetter({
        key: 'showSidePanel',
        value: false
      });

      if (configureInstance) {
        getWidgetsLayout({
          userId: userData.userId,
          WPID: activeTab.id,
          widgetComponentData
        });
        widgetDetailSetter({ key: 'shouldRefreshPage', value: false });
      } else if (WTid) {
        widgetDetailSetter({
          key: 'isHideHeader',
          value: {}
        });
        widgetDetailSetter({
          key: 'isHideBorder',
          value: {}
        });
        widgetDetailSetter({
          key: 'zoomLevel',
          value: {}
        });
        if (WTId) {
          getPreviewTemplate({ WTId });
        }
      } else {
        if (!isEditOn[activeTab?.id]) {
          widgetDetailSetter({
            key: 'isHideHeader',
            value: {}
          });
          widgetDetailSetter({
            key: 'isHideBorder',
            value: {}
          });
          widgetDetailSetter({
            key: 'zoomLevel',
            value: {}
          });
        }
        getWidgetsLayout({
          userId: userData.userId,
          WPID: activeTab.id,
        });
      }
    }
  }, [activeTab, WTid]);

  useEffect(() => {
    // WTid was used before. Removed it to stop calling preview api on coming back from edit instance
    if (WTId && !instancePreview) {
      getPreviewTemplate({ WTId });
    }
    if (isTemplateInstanceEdit) {
      // getWidgetsLayout({
      //   userId: userData.userId,
      //   WPID: activeTab.id,
      //   widgetComponentData
      // });
      // Fixed this when instance is edited from empty tab
      getPreviewLayout({
        userId: userData.userId,
        WTIId,
        previewTemplateInstanceParams: templateParamterDetails,
        widgetComponentData
      });
    }
  }, []);

  useEffect(() => {
    setIsPreviewInstance(false);
    setConfigureTemplate(false);
    if (!instancePreview) {
      widgetDetailSetter({
        key: 'isInstancePop',
        value: false
      });
    }
  }, []);

  useEffect(() => {
    if (TemplateBaseData && !isEmpty(TemplateBaseData)) {
      setTemplateBasedData(TemplateBaseData);
    }
  }, [TemplateBaseData]);

  useEffect(() => {
    if (shouldRefreshPage) {
      getWidgetsLayout({
        userId: userData.userId,
        WPID: activeTab.id
      });
      widgetDetailSetter({ key: 'shouldRefreshPage', value: false });
    }
  }, [shouldRefreshPage]);

  useEffect(() => {
    // used to preview the template instance
    if (instancePreview && WTIId && !isTemplateInstanceEdit) {
      setIsPreviewInstance(true);
      getPreviewLayout({
        userId: userData.userId,
        WTIId,
        previewTemplateInstanceParams: templateParamterDetails
      });
    }
  }, [instancePreview]);

  if (loadingLayout) {
    return (
      <FlexContainer style={{ marginTop: '30vh' }}>
        <Loader />
      </FlexContainer>
    );
  }

  const handlePanel = (value) => {
    dispatch(WidgetCreators.widgetDetailSetter({ key: 'showSidePanel', value: value || !showSidePanel }));
  };

  return (
    <>
      <PanelHeadingText className='flex' active={true}>
        <span style={{ maxWidth: '45%', fontSize: '1.6rem', marginTop: isEmpty(layout?.[breakpoint]) ? '1rem' : '0' }} className='text-overflow'>
          {WidgetPanelName && `${WidgetPanelName}`}
        </span>
        <span style={{ maxWidth: '45%', fontSize: '1.6rem', marginTop: isEmpty(layout?.[breakpoint]) ? '1rem' : '0' }} className='text-overflow'>
          {WidgetPanelDesc && `${WidgetPanelDesc}` }
        </span>
      </PanelHeadingText>
      {isEmpty(layout?.[breakpoint]) ? (
        <>
          {!isTemplateDashboard ? (
            <EmptyWidgetLanding
              label={TabType === 'Instance_Tab' ? 'Add Instance' : 'Add Widgets'}
              handleClick={handleAddWB}
              iconUrl={AddWidgetBg}
              description={TabType === 'Instance_Tab' ? 'No Widgets! Please add instance to display' : 'No Widgets! Please add widgets to display'}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <ShowcaseLayout isCustom={isCustom} />
        </>
      )}
      {/* <WidgetTypeSelectionSideBar handlePanel={handlePanel} /> */}
      {/* widget types */}
      {showSidePanel && <WidgetTypeSelectionSideBar handlePanel={handlePanel} />}
      {showTemplateListSidePanel && <WidgetTemplateSelectionSideBar />}
      {showInstanceTablePopUp && <InstanceSelectionPop />}
    </>
  );
};

export default WidgetDashBoard;
