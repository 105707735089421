/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { GridContainer, ItemContainer, ItemCategory, Label } from './StatusValueWidget.style';
import { format } from '../../../../config/index';
import { formatDateAndTime } from '../../../../constants/helper';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { AddEquipBtn } from '../OEEDonut/EquipmentDonut.style';
import { Text } from '../../Widget.style';

const StatusValueWidget = (props) => {
  const { equipments: initialEquipments, isUpsert = false, meta, fullView, zoomLevelVal, widgetType, selectedWidgetData, handleConfigureKpv } = props; // statusValueDetails
  // const equipments = selectedWidgetData ? selectedWidgetData?.equipments : initialEquipments;
  const equipments = widgetType === 'EQUIPMENT_KPV_STATUS_VALUE' ? selectedWidgetData?.equipments ? selectedWidgetData?.equipments : initialEquipments : initialEquipments;

  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const activeTab = useSelector((state) => state.widgets.activeTab);

  if (widgetType === 'EQUIPMENT_KPV_STATUS_VALUE' && (isEmpty(equipments))) {
    return (
      isUpsert ? (
        <GridContainer className='zoom_status_values' zoomLevel={fullView ? 1 : zoomLevelVal}>
          {/* <AddEquipBtn onClick={() => { handleConfigureKpv(true); }}>
            <h1 style={{ fontSize: '5rem' }}>+</h1>
            <h1 style={{ fontSize: '2rem' }}>Add KPV</h1>
          </AddEquipBtn> */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Text style={{ font: '2rem' }}>
              No Kpv Selected
            </Text>
          </div>
        </GridContainer>
      )
        : (
          <GridContainer className='zoom_status_values' zoomLevel={fullView ? 1 : zoomLevelVal}>
            <AddEquipBtn onClick={() => {
              if (isEditOn[activeTab.id]) {
                handleConfigureKpv(false);
              } else {
                handleConfigureKpv(true);
              }
            }}
            >
              <h1 style={{ fontSize: '5rem' }}>+</h1>
              <h1 style={{ fontSize: '2rem' }}>Add KPV</h1>
            </AddEquipBtn>
          </GridContainer>
        )
    );
  }
  return (
    <GridContainer className='zoom_status_values' zoomLevel={fullView ? 1 : zoomLevelVal}>
      {equipments?.map((item, i) => {
        // eslint-disable-next-line camelcase
        const { state_name } = item;
        // console.log(state, item, meta?.colors?.[state], 'statusItem');
        return (
          <ItemContainer className={widgetType === 'EQUIPMENT_KPV_STATUS_VALUE' ? 'status_card_size equipment_status_value' : 'status_card_size'} key={i.toString()} color={item?.background_color || meta?.colors?.[state_name] || '#f9f9f9'}>
            <Label style={{ maxWidth: '18rem', wordBreak: 'break-word' }}>{item?.title || ''}</Label>
            {/* {item?.kpv_name ? <ItemCategory>{item?.equipment}</ItemCategory> : <Label>{item?.equipment}</Label>} */}
            <ItemCategory>{item?.sub_title}</ItemCategory>
            <ItemCategory>{!isEmpty(item?.additional_data) ? formatDateAndTime(item?.additional_data, format) : ''}</ItemCategory>
          </ItemContainer>
        );
      })}
    </GridContainer>
  );
};
export default StatusValueWidget;
