/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import { v4 as uuid } from 'uuid';

export const uid = () => uuid();

export const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const arrayToJson = (arr, key) => arr.reduce((final, ele) => ({ ...final, [ele?.[key]]: ele }), {});

function encodeToHex(input) {
  let hex = '';
  for (let i = 0; i < input.length; i += 1) {
    hex += input.charCodeAt(i).toString(16);
  }
  return hex;
}

function reverseString(input) {
  return input.split('').reverse().join('');
}

function encodeToBase64(input) {
  return btoa(input);
}

export const encodeUserDetails = (inputString = { userid: 'Lino', password: 'Code&50' }) => {
  const encodedHex = encodeToHex(JSON.stringify(inputString));
  // console.log('Encoded HEX:', encodedHex);

  const reversedHex = reverseString(encodedHex);
  // console.log('Reversed HEX:', reversedHex);

  const encodedBase64 = encodeToBase64(reversedHex);
  // console.log('Encoded BASE64:', encodedBase64);

  const reversedBase64 = reverseString(encodedBase64);
  // console.log('Reversed BASE64:', reversedBase64);

  const encodedBase64Again = encodeToBase64(reversedBase64);
  // console.log('Encoded BASE64 Again:', encodedBase64Again);

  return encodedBase64Again;
};
