import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';

export const MainDivSbar = styled.div`
  background-color: ${({ theme }) => theme.cardBg};
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 2rem;
  width: 35.8rem;
  cursor: pointer;

  /* :hover {
    background-color: ${({ theme }) => theme.hover};
  } */

  &.selected {
    box-shadow: inset 0px 0px 2px 2px #000000;
  }
  &.space-between {
    justify-content: space-between;
    cursor: default;
  }
`;

export const IconDiv = styled.div`
  border-radius: 5px;
  padding: 1rem;
  background: #d3d5e2;
`;

export const Details = styled.div`
  font: 1.8 ${fontFamily.circularBook};
  padding-left: 1rem;
  padding-bottom: 1rem;
  /* height: 4rem; */
  &.pointer {
    cursor: pointer;
    /* background-color: ${({ theme }) => theme.hover}; */
  }
`;

export const Text = styled.p`
  font-size: 1.6rem;
  padding-top: 0.8rem;

  &.decription {
    color: #a7a7a7;
  }
  &.center {
    text-align: center;
  }
`;

export const SelectedWidgetDiv = styled.div`
  /* display: flex; */
  /* width: 98%; */
  /* background-color: #f4f4f8; */
  /* margin: 1rem; */
  /* border-radius: 10px; */
  /* flex: 1; */
  width: 100%;
  height: 100%;
`;

export const BtnContainer = styled(FlexContainer)`
  position: fixed;
  bottom: 10px;
  padding-top: 1rem;
  justify-content: center;
  border-top: 3px solid #d8d9e2;
`;

export const MainContainer = styled.div`
  margin-bottom: 6rem;
  & .dynamicSVGIcon {
    & * {
      fill: ${({ theme }) => theme.contrast.primary} !important;
    }
  }
`;
