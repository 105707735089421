/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import WidgetDashBoard from '../../component/Widget/DashBoards/WidgetDashBoard';
import PageHeader from '../../component/Common/PageHeader/PageHeader';
import ButtonComp from '../../component/Common/Button/Button.view';
import SettingBtn from '../../component/Widget/Header/Settings/SettingBtn';
import { PAGE_TYPES } from '../../constants';
// import Add from '../../assets/icons/Add.svg';
// import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
// import { ContastIconColor } from '../../component/Common/CommonStyles';
// import { Span } from '../../globalStyles';
import { useActions } from '../../store/hooks/useActions';
import { WidgetContainer } from './Widget.style';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { isEmpty } from '../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
// import { WebService } from '../../services/axios/webServices';
// import { apiEndPoints } from '../../services/axios/endPoints';

const ViewWidgetDashboard = () => {
  const location = useLocation();
  const { isTemplateInstance = false, isConfigurableDashboard = false } = location.state || {};
  const widgetTypesIncluded = useSelector((state) => state.widgets.widgetTypesIncluded);

  const {
    templateInstanceName,
    templateInstanceFormInput,
    WTId,
    templateFormInput,
    WTIId,
    templateName,
    templateCreatorName,
    instanceCreatorName
  } = useSelector((state) => state.upsertWidgetData);
  const history = useHistory();
  const WidgetTypeMenuRoutes = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const layout = useSelector((state) => state.widgets.layout);
  const fullWidgetData = useSelector((state) => state.widgets.fullWidgetData);
  const { resetWidgetPanelPayload, setConfigureTemplate, setTemplateLayoutData, setToastError, getWidgetTypes, widgetDetailSetter } = useActions();
  const userData = useSelector((state) => state.loginData.userData);
  const WPId = useSelector((state) => state.upsertWidgetData.WPId);
  const [showConfigure, setShowConfigure] = useState(false);
  const widgetPanelPayload = useSelector(
    (state) => state.widgets.widgetPanelPayload
  );

  const [completeWidgets, setCompleteWidgets] = useState();
  useEffect(() => {
    if (!isEmpty(fullWidgetData)) {
      const modefiedWidgets = [...fullWidgetData];
      // eslint-disable-next-line array-callback-return
      modefiedWidgets.map((modified, index) => {
        if (typeof (modified?.WidgetComponentData) === 'string') {
          modefiedWidgets[index] = { ...modified, WidgetComponentData: JSON.parse(modified?.WidgetComponentData) };
        }
      });

      setCompleteWidgets(modefiedWidgets);
    }
  }, [fullWidgetData]);

  // Redirect when no data in instance preview
  useEffect(() => {
    if (!WTId) {
      if (!instanceCreatorName) {
        history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
      }
    }
    if (isTemplateInstance && isEmpty(templateInstanceName)) {
      history.goBack();
    }
    if (!isTemplateInstance && !templateName) {
      history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    }
  }, []);

  const widgetPayload = useSelector(
    (state) => state.widgets.widgetPayload
  );

  useEffect(() => {
    if (isEmpty(widgetTypesMenu)) {
      getWidgetTypes();
    }
  }, []);

  const templateParamterDetails = {
    WidgetComponentData: templateInstanceFormInput,
    TemplateParameters: {
      widgetTypesIncluded,
      formInput: templateFormInput
    },
    WTIId
  };
  const handleEditParameters = () => {
    history.push({ pathname: '/home/create-template', state: { WTId } });
  };
  const handleEditTemplate = () => {
    history.push({ pathname: '/home/template-instance', state: { isEdit: true, isPreSelection: false, isConfigurableDashboard } });
  };

  const updateTemplateWidget = async (WID, widgetPayloadData, index) => {
    for (let i = 0; i < completeWidgets?.length; i += 1) {
      if (completeWidgets[i]?.WID === Number(WID)) {
        const payload = widgetPayloadData;
        completeWidgets[i] = { ...completeWidgets[i], WidgetComponentData: payload?.WidgetComponentData };
      }
    }
    if (index === Object.keys(widgetPayload).length - 1) {
      try {
        const fullURL = apiEndPoints.templateWidgetUpdateNew;
        const Widgets = completeWidgets;
        const resp = await WebService.post(fullURL, Widgets, {
          params: {
            WTId
          },
        });

        if (resp.status >= 200 && resp.status < 300) {
        // setLayoutComponentUpdated(false);
        } else {
          history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
          setToastError('Failed to Update Template');
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('e', e, error);
      // setToastError(error);
      }
    }
  };

  const updateInstanceWidget = async (WID, widgetPayloadData, index) => {
    for (let i = 0; i < completeWidgets?.length; i += 1) {
      if (completeWidgets[i]?.WID === Number(WID)) {
        const payload = widgetPayloadData;
        completeWidgets[i] = { ...completeWidgets[i], WidgetComponentData: payload?.WidgetComponentData };
      }
    }
    if (index === Object.keys(widgetPayload).length - 1) {
      try {
        const fullURL = apiEndPoints.instanceWidgetUpdateNew;
        const resp = await WebService.post(fullURL, completeWidgets, {
          params: {
            WTIId
          },
        });

        if (resp.status >= 200 && resp.status < 300) {
          // setHeaderHide({
          //   WID,
          //   WPID: WPId,
          //   isHideHeader: widgetPayload?.WidgetComponentData?.isHideHeader
          // });
          // setBorderHide({
          //   WID,
          //   WPID: WPId,
          //   isHideBorder: widgetPayload?.WidgetComponentData?.isHideBorder
          // });
        } else {
          history.goBack();
          setToastError('Failed to Update Instance');
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('e', e, error);
        // setToastError(error);
      }
    }
  };

  const updateLayout = async () => {
    if (!isEmpty(widgetPanelPayload)) {
      const res = await WebService.post(
        `${apiEndPoints.updateWidgetPanelTemplate}/${WTId}`,
        {
        // TODO: need to add WPId also here
          WidgetTemplateName: templateName,
          UserId: userData.userId,
          WidgetPanelLayoutData: [{ WidgetPanelLayoutData: widgetPanelPayload?.WidgetPanelLayoutData }]

        }

      );
      if (res.status >= 200 && res.status < 300) {
        const templateData = res?.data?.data[0];

        setTemplateLayoutData({
          WidgetPanelLayoutData: templateData[0]?.WidgetPanelLayoutData[0]?.WidgetPanelLayoutData,
          WidgetPanelLayoutMetaData: templateData[0].WidgetPanelLayoutMetaData,
          WPID: templateData[0].WPID
        });
      // setTemplateLayoutData({})
      }
    }
    if (!isEmpty(widgetPayload)) {
      Object.entries(widgetPayload).map(
        // eslint-disable-next-line array-callback-return
        (wid, index) => {
          // const reqPayload = { ...wid[1], isHideHeader: hide };
          // console.log(reqPayload, 'fh');
          updateTemplateWidget(wid[0], wid[1], index);
        }
      );
    }
  };

  const updateInstanceLayout = async () => {
    if (!isEmpty(widgetPanelPayload)) {
      const res = await WebService.post(
        `${apiEndPoints.updateWidgetPanelTemplateInstance}/${WTIId}`,
        {
        // TODO: need to add WPId also here
          // WidgetTemplateName: templateName,
          UserId: userData.userId,
          WidgetPanelLayoutData: widgetPanelPayload?.WidgetPanelLayoutData,

        }

      );
      if (res.status >= 200 && res.status < 300) {

        // setTemplateLayoutData({
        //   WidgetPanelLayoutData: templateData[0]?.WidgetPanelLayoutData[0]?.WidgetPanelLayoutData,
        //   WidgetPanelLayoutMetaData: templateData[0].WidgetPanelLayoutMetaData,
        //   WPID: templateData[0].WPID
        // });
      // setTemplateLayoutData({})
      }
    }
    if (!isEmpty(widgetPayload)) {
      Object.entries(widgetPayload).map(
        // eslint-disable-next-line array-callback-return
        (wid, index) => {
          // const reqPayload = { ...wid[1], isHideHeader: hide };
          // console.log(reqPayload, 'fh');
          updateInstanceWidget(wid[0], wid[1], index);
          // eslint-disable-next-line function-paren-newline
        }
      );
    }
  };

  return (
    <WidgetContainer style={{ padding: '0rem 2rem' }}>
      <PageHeader
        title={isTemplateInstance ? templateInstanceName : templateName}
        handleBack={() => {
          setShowConfigure(false);
          setConfigureTemplate(false);
          history.goBack();
        }}
      >
        {!isTemplateInstance ? templateCreatorName === userData?.userId && (
          <>
            <div style={{ marginTop: '0px' }} className='buttonWrapper'>
              <ButtonComp
                className='saveBtn'
                style={{ marginRight: '2rem' }}
                onClick={() => {
                  if (showConfigure) {
                    updateLayout();
                    setShowConfigure(false);
                    setConfigureTemplate(false);
                    resetWidgetPanelPayload();
                  } else {
                    resetWidgetPanelPayload();
                    setShowConfigure(true);
                    setConfigureTemplate(true);
                  }
                }}
              >
                {showConfigure ? 'Save' : 'Configure'}
              </ButtonComp>
              {showConfigure && (
              <ButtonComp
                className='saveBtn'
                dark
                style={{ marginRight: '2rem' }}
                onClick={() => {
                  setShowConfigure(false);
                  setConfigureTemplate(false);
                  history.goBack();
                }}
              >
                Cancel
              </ButtonComp>
              )}
              {!showConfigure && (
              <ButtonComp className='saveBtn' disabled={showConfigure} dark style={{ marginRight: '2rem' }} onClick={handleEditParameters}>
                Edit Parameters
              </ButtonComp>
              )}
            </div>
          </>
        ) : instanceCreatorName === userData?.userId && (
          <>
            <ButtonComp
              className='saveBtn'
              style={{ marginRight: '2rem' }}
              onClick={() => {
                if (showConfigure) {
                  updateInstanceLayout();
                  setShowConfigure(false);
                  setConfigureTemplate(false);
                  resetWidgetPanelPayload();
                } else {
                  resetWidgetPanelPayload();
                  setShowConfigure(true);
                  setConfigureTemplate(true);
                }
              }}
            >
              {showConfigure ? 'Save' : 'Configure'}
            </ButtonComp>
            {showConfigure && (
            <ButtonComp
              className='saveBtn'
              dark
              style={{ marginRight: '2rem' }}
              onClick={() => {
                setShowConfigure(false);
                setConfigureTemplate(false);
                history.goBack();
              }}
            >
              Cancel
            </ButtonComp>
            )}
            {!showConfigure && (
            <ButtonComp className='saveBtn' disabled={showConfigure} dark style={{ marginRight: '2rem' }} onClick={handleEditTemplate}>
              Edit
            </ButtonComp>
            )}
          </>
        )}
      </PageHeader>
      <WidgetDashBoard instancePreview={isTemplateInstance} WTId={WTId} templateParamterDetails={templateParamterDetails} isCustom={showConfigure} />
    </WidgetContainer>
  );
};

export default ViewWidgetDashboard;
