/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty } from 'lodash';
import { WidgetContainer } from './Widget.style';
import Header from '../../component/Widget/Header/Header';
import WidgetDashBoard from '../../component/Widget/DashBoards/WidgetDashBoard';
import { AddWidgetBg } from '../../assets/icons';
import { MODAL_TYPES } from '../../component/Widget/utils/contants';
import AddTab from '../../component/Widget/Header/AddTab/AddTab';
import EmptyWidgetLanding from '../../component/Widget/Common/EmptyWidgetLanding';
// import SearchBarWithDropdown from '../../composnent/Common/SearchBarWithDropdown';
import { useActions } from '../../store/hooks/useActions';
import Toast from '../../component/Common/Toast';
import Loader from '../../component/Common/Loader/Loader';

const WidgetLayout = ({ menuId }) => {
  // const layoutComponentData = useSelector((state) => state.widgets.layoutComponentData);
  const history = useHistory();
  const tabs = useSelector((state) => state.widgets.tabList);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const loading = useSelector((state) => state.widgets.loading);
  const WidgetMenus = useSelector((state) => state.home.Widget);
  const WidgetMenusInStance = useSelector((state) => state.home.Widget_Instance);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const { defaultUrl } = useSelector((state) => state.home);
  // const activeWidgetTab = useSelector((state) => state.widgets.activeTab);
  const [showModal, setShowModal] = useState('');
  const { getWidgetTabs, getWidgetTypes, valueSetter, resetTemplateInstanceData, getWidgetsLayout, setWidgetActiveTab } =
    useActions();
  const userData = useSelector((state) => state.loginData.userData); // // init menu
  const location = useLocation();
  const { fromLogin } = location.state || false;
  const idParam = new URLSearchParams(location.search).get('id');
  const modifiedDefaultUrl = activeTab?.id || defaultUrl?.split('id=')[1];
  // get list of tabs
  useEffect(() => {
    if (WidgetMenus?.[menuId] || WidgetMenusInStance?.[menuId]) {
      // console.log('fetching tabs', WidgetMenus, menuId, menuIdofWidget);
      getWidgetTabs({
        menuId,
        userId: userData.userId,
        makeFistTabActive: menuId !== menuIdofWidget,
        idParam: !fromLogin ? idParam : modifiedDefaultUrl,
      }); // TODO test:activeMenuId
      getWidgetTypes();
    }
  }, [menuId, WidgetMenus]);

  useEffect(() => {
    if (activeTab?.id) {
      history.push({
        pathname: `${location.pathname}`,
        search: `?id=${activeTab?.id}`
      });
    }
  }, [activeTab, idParam]);

  const handleAddTab = () => {
    setShowModal(MODAL_TYPES.ADD_TAB);
  };

  const layoutWarning = useSelector((state) => state.widgets.layoutWarning);
  const layoutError = useSelector((state) => state.widgets.layoutError);
  const { getWidgetsLayoutError, getWidgetsLayoutWarning } = useActions();
  // const handleCustomNavigation = () => {
  //   valueSetter({ widgetDashboardTitle: activeTab?.label });
  //   valueSetter({ showTemplateListSidePanel: true });
  //   history.push({ pathname: '/home/create-custom-dashboard', state: { isCustomDashboard: false } });
  // };
  return (
    <>
      {loading ? (
        <Loader position='relative' />
      ) : (
        <WidgetContainer>
          {/* test search bar dp */}
          {/* <SearchBarWithDropdown /> */}
          {tabs.length < 1 ? (
            <EmptyWidgetLanding
              label='Add Tab'
              handleClick={handleAddTab}
              iconUrl={AddWidgetBg}
              description='No tabs! please Add one or more tabs'
            />
          ) : (
            <>
              {/* <button onClick={handleCustomNavigation}>asa</button> */}
              <Header />
              <WidgetDashBoard />
            </>
          )}
          {(layoutError || layoutWarning) && (
            <Toast
              className='right'
              message={layoutError || layoutWarning}
              header={layoutWarning ? 'Warning' : 'Error'}
              fn={() => getWidgetsLayoutError()}
            />
          )}
        </WidgetContainer>
      )}
    </>
  );
};

export default WidgetLayout;
