import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles';
import { fontFamily } from '../../../../constants';

export const Container = styled.div`
  /* background: #f4f4f8; */
  border-radius: 10px;
  opacity: 1;
  /* overflow-y: auto; */
  &.barChart {
    width: 100%;
    height: ${({ headerOn }) => (headerOn ? 'calc(100% - 5rem)' : 'calc(100% - 2rem)')};
    /* height: calc(100% - 5rem); */
    /* background: pink; */
    /* width: ${({ zoomLevel }) => (zoomLevel || 1) * 100}%;
    height: ${({ zoomLevel }) => (zoomLevel || 1) * 100}%; */
  }
  & .zoom {
    padding: 1rem;
    scale: ${({ zoomLevel }) => zoomLevel || 1};
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  font-family: ${fontFamily.circularBold};
  letter-spacing: 0px;
  color: #202020;
`;
