import React, { useState } from 'react';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import { Heading, HorizontalDivider } from '../../../Common/ConfirmationBox/ConfimationBox.styles';
import { BtnWrapper, Button } from '../../../Common/PopUpModal/Modal.style';
import Index from '../../../Common/Input/Input';
import { useActions } from '../../../../store/hooks/useActions';

const CreateTemplateModal = (props) => {
  const { Header, closeModalHandler, handleSave } = props;
  const [val, setVal] = useState();
  const { setTemplateFormInput } = useActions();
  return (
    <PopUpModal style={{ boxShadow: ' 0px 0px 10px #00000026', width: '25%' }} closeModalHandler={closeModalHandler}>
      <Heading style={{ textAlign: 'start', color: '#474747' }}>{Header}</Heading>
      <HorizontalDivider />
      <Index placeholder='Enter title for the template' style={{ fontSize: '1.6rem' }} value={val} setValue={(val) => setVal(val)} />
      <br />
      <br />
      <BtnWrapper>
        <Button style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '-3px -3px 11px #FFFFFFCB' }} onClick={() => closeModalHandler(false)}>Cancel</Button>
        <Button
          disabled={!val}
          onClick={() => {
            // Setting template creation parameter to default
            setTemplateFormInput({ templateFormInput: {} });
            handleSave(val);
          }}
          className='dark'
        >
          Save
        </Button>
      </BtnWrapper>
    </PopUpModal>
  );
};

export default CreateTemplateModal;
