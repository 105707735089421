/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../component/Common/PopUpModal/Modal.view';
import { Creators as DashboardCreators } from '../store';
import {
  AddButton,
  DButton,
  DropDown,
  SearchCont,
  Title,
  P,
  TagsContainer,
  Tags,
  Btn
} from '../SaveDashboardModal.style';
import Input from '../../../component/Common/Input/Input';
import { FlexContainer } from '../../../globalStyles';
import Radiobutton from '../../../component/Common/RadioButton/RadioButton';
import DynamicSVGIcon from '../../../component/Common/DynamicSVGIcon';
import { ReactComponent as Arrow } from '../../../assets/icons/KPV/dropdownArrowBlack.svg';
import { ReactComponent as Add } from '../../../assets/icons/Filter/add black.svg';
import Close from '../../../assets/icons/Filter/cancel black.svg';
import Loader from '../../../component/Common/Loader/Loader';
import { isEmpty } from '../../../shared/utility/isEmpty';

export default function DashboardForm({ onCancel, handleSave, error, loading = false }) {
  const form = useSelector((state) => state.myDashboards.dashboardDetails);
  const { DashboardTitle: dTitle, Shared, Searchtags, Category } = form;
  const property = dTitle !== undefined ? 'DashboardTitle' : 'DashboardName';
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    // const isFormFilled = Object.keys(form).every((key) => !isEmpty(form[key]));
    if (form[property]) setIsSaveDisabled(false);
    else setIsSaveDisabled(true);
  }, [form[property]]);

  const dispatch = useDispatch();
  const setForm = (form) => {
    dispatch(DashboardCreators.setDashboardDetails({ form }));
  };

  const [searchTag, setSearchTag] = useState('');
  const handleAddTag = () => {
    if (searchTag) {
      setForm({ Searchtags: `${form.Searchtags ? `${form.Searchtags},` : ''} ${searchTag}` });
      setSearchTag('');
    }
  };
  const handleShared = () => {
    setForm({ Shared: !form.Shared });
  };

  const deleteTag = (index) => {
    const filters = form.Searchtags.split(',').filter((el, i) => i !== index);
    setForm({ Searchtags: !isEmpty(filters) ? filters.join(',') : '' });
  };

  const handleTitle = (value) => {
    setForm({ [property]: value });
  };
  // console.log('form', form, 'asa', form.Searchtags && form.Searchtags?.split(','));
  return (
    <Modal closeModalHandler={onCancel}>
      <div style={{ position: 'relative' }}>
        {loading && <Loader />}
        <Title>Save Dashboard</Title>
        <Input margin='1rem 0' placeholder='Title' value={form[property]} setValue={handleTitle} />
        {/* <DropDown my='1'>
          <Input placeholder='Category' value={Category} disabled={true} />
          <DButton>
            <Arrow />
          </DButton>
        </DropDown> */}
        <FlexContainer className='jc_spacebetween ai_center'>
          <SearchCont my='1'>
            <Input placeholder='Search Tags' value={searchTag} setValue={(val) => setSearchTag(val)} />
            <AddButton onClick={handleAddTag}>
              <Add />
            </AddButton>
          </SearchCont>
          <FlexContainer className='jc_center ai_center'>
            <P> Shared Dashboard</P>
            <Radiobutton active={Shared} onClick={handleShared} />
          </FlexContainer>
        </FlexContainer>
        <TagsContainer>
          {Searchtags &&
            Searchtags?.split(',')?.map((tag, i) => (
              <Tags key={i} onClick={() => deleteTag(i)}>
                {tag}
                <DynamicSVGIcon iconUrl={Close} width='10px' />
              </Tags>
            ))}
        </TagsContainer>
        <Title style={{ color: 'red' }}>{error}</Title>
        <FlexContainer className='jc_spacebetween ai_center' my='1'>
          <Btn onClick={onCancel}>Cancel</Btn>
          <Btn className='dark' disabled={isSaveDisabled} onClick={() => handleSave(form)}>
            Save
          </Btn>
        </FlexContainer>
      </div>
    </Modal>
  );
}
