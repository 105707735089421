/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useMemo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { device, fontFamily, fontSize } from '../../../../constants';
import { FlexContainer } from '../../../../globalStyles';
import { ActiveDivider } from '../../../../views/dashboard/FilterView/Filter.style';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';
import { useWindowSize } from '../../../../shared/hooks/useWindowResize';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { Kabab } from '../../../../assets/icons/KPV';
import filter from '../../../../assets/icons/Filter/Filters.svg';
import { FilterBtn, IconContainer } from './TabsList.style';
import { useActions } from '../../../../store/hooks/useActions';
import CreateTemplateModal from '../CreateTemplate/CreateTemplateModal';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import {
  Add,
  Rename,
  DeleteTab,
  TabSettings,
  ClearTab,
  Export,
  ExportPdfIcon,
  ExportImageIcon,
  ExportExcelIcon
} from '../../../../assets/icons';
import refresh from '../../../../assets/icons/refresh.svg';
import { ContastIconColorContainer } from '../../../Common/CommonStyles';
import RenameTab from '../TabForm/Rename';
import DeletTab from '../TabForm/DeletTab';
import ClearTabConfModal from '../TabForm/ClearTab';
import { Creators } from '../../../../views/Layout/store';
import { WidgetCreators } from '../../../../views/Widgets/store';
import Tooltip, { TooltipBox } from '../../../Common/Tooltip/TooltipV2.view';
import ExportDashboard from '../../../../views/ExportDashboard/ExportDashboard';

const tabHorizontalPadding = 10;

const tabMenu = [
  {
    label: 'Create Template',
    id: 1,
    role: 'createTemplate',
    class: 'pointer',
    iconUrl: Add
  },
  {
    label: 'Edit',
    id: 1,
    role: 'editTemplate',
    class: 'pointer',
    iconUrl: Rename
  },
  {
    label: 'Rename Tab',
    id: 3,
    role: 'rename',
    class: 'pointer',
    iconUrl: Rename
  },
  {
    label: 'Clear The Tab',
    id: 4,
    role: 'clear',
    class: 'pointer',
    iconUrl: ClearTab
  },
  {
    label: 'Delete tab',
    id: 5,
    role: 'delete',
    class: 'pointer',
    iconUrl: DeleteTab
  },
  {
    id: 6,
    label: 'Export PDF',
    role: 'exportPdf',
    iconUrl: ExportPdfIcon
    // type: 'radio',
  },
  {
    id: 7,
    label: 'Export Image',
    role: 'exportPng',
    iconUrl: ExportImageIcon
    // type: 'radio',
  },
  {
    id: 8,
    label: 'Export Excel',
    role: 'exportExcel',
    iconUrl: ExportExcelIcon
    // type: 'radio',
  }
];
const tabMenuEdit = [
  {
    label: 'Create Template',
    id: 1,
    role: 'createTemplate',
    class: 'pointer',
    iconUrl: Add
  },

  {
    label: 'Rename Tab',
    id: 3,
    role: 'rename',
    class: 'pointer',
    iconUrl: Rename
  },
  {
    label: 'Clear The Tab',
    id: 4,
    role: 'clear',
    class: 'pointer',
    iconUrl: ClearTab
  },
  {
    label: 'Delete tab',
    id: 5,
    role: 'delete',
    class: 'pointer',
    iconUrl: DeleteTab
  },
  {
    id: 6,
    label: 'Export PDF',
    role: 'exportPdf',
    iconUrl: ExportPdfIcon
    // type: 'radio',
  },
  {
    id: 7,
    label: 'Export Image',
    role: 'exportPng',
    iconUrl: ExportImageIcon
    // type: 'radio',
  },
  {
    id: 8,
    label: 'Export Excel',
    role: 'exportExcel',
    iconUrl: ExportExcelIcon
    // type: 'radio',
  }
];
const tabMenuInstance = [
  {
    label: 'Rename Tab',
    id: 3,
    role: 'rename',
    class: 'pointer',
    iconUrl: Rename
  },
  {
    label: 'Clear The Tab',
    id: 4,
    role: 'clear',
    class: 'pointer',
    iconUrl: ClearTab
  },
  {
    label: 'Delete tab',
    id: 5,
    role: 'delete',
    class: 'pointer',
    iconUrl: DeleteTab
  },
  {
    id: 6,
    label: 'Export PDF',
    role: 'exportPdf',
    iconUrl: ExportPdfIcon
    // type: 'radio',
  },
  {
    id: 7,
    label: 'Export Image',
    role: 'exportPng',
    iconUrl: ExportImageIcon
    // type: 'radio',
  },
  {
    id: 8,
    label: 'Export Excel',
    role: 'exportExcel',
    iconUrl: ExportExcelIcon
    // type: 'radio',
  }
];

const tabEmpty = [
  {
    label: 'Rename Tab',
    id: 3,
    role: 'rename',
    class: 'pointer',
    iconUrl: Rename
  },
  {
    label: 'Delete tab',
    id: 5,
    role: 'delete',
    class: 'pointer',
    iconUrl: DeleteTab
  },
];
const TabDropdown = ({ activeTab, isTab = false, item }) => {
  const [showOptions, setShowOptions] = useState(false);
  const history = useHistory();
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const [showModal, setShowModal] = useState(false);
  const { valueSetter, } = useActions();
  const dispatch = useDispatch();
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const WidgetPanelName = useSelector((state) => state.widgets.WidgetPanelName);
  const { WidgetComponentData } = useSelector((state) => state.widgets);
  const handleCreateTemplateRoute = (val) => {
    valueSetter({
      templateName: val
    });
    history.push('/home/create-template');
  };

  const [exportXlxs, setExportXlxs] = useState(false);

  const handleDropdown = ({ role, val = true }) => {
    if (role === 'editTemplate') {
      dispatch(Creators.setShowSideNav({ showSideNav: false }));
      dispatch(
        WidgetCreators.setEditWidget({
          isEditOn: true,
          WPID: activeTab?.id
        })
      );
    } else if (role === 'exportPng') {
      setShowOptions(false);
      const divToExport = document.getElementsByClassName('react-grid-layout')[0];

      setTimeout(() => {
        htmlToImage.toPng(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
          const link = document.createElement('a');
          link.download = WidgetPanelName;
          link.href = dataUrl;
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }, 100);

      //  captureFullDivScreenshot();
    } else if (role === 'exportPdf') {
      const originalTitle = document.title;
      document.title = WidgetPanelName;
      setShowOptions(false);
      const divToExport = document.getElementsByClassName('react-grid-layout')[0];

      setTimeout(() => {
        htmlToImage.toSvg(divToExport, { width: divToExport.scrollWidth, height: divToExport.scrollHeight }).then((dataUrl) => {
          const iframe = document.createElement('iframe'); // load content in an iframe to print later
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.src = 'about:blank';

          iframe.onload = function () {
            const domdoc = iframe.contentDocument || iframe.contentWindow.document;
            let windowContent = '<!DOCTYPE html>';
            windowContent += '<html>';
            windowContent += `<head><style>@media print {
              html, body {
                height: 100vh;
                margin: 0 !important;
                padding: 0 !important;
              }
            } @page { size: ${divToExport.scrollWidth}px ${divToExport.scrollHeight + 200}px; }</style> <title>${WidgetPanelName}</title></head>`;
            windowContent += `<body style='width: ${divToExport.scrollWidth}px; height: ${divToExport.scrollHeight}px;'>`;
            windowContent += `<img src="${dataUrl}" style="page-break-after: auto;">`;
            windowContent += '</body>';
            windowContent += '</html>';

            domdoc.body.innerHTML = windowContent;
            setTimeout(() => {
              iframe.focus();
              iframe.contentWindow.print();
              iframe.remove();
              document.title = originalTitle;
            }, 1);
          };
        })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }, 100);
    } else if (role === 'exportExcel') {
      setShowOptions(false);
      setExportXlxs(true);
    } else {
      setShowModal({ [role]: val });
    }
  };
  const TabType = useSelector((state) => state.widgets.TabType);
  const layout = useSelector((state) => state.widgets.layout);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  return (
    <>
      <ContastIconColorContainer>
        <DropDownModal
          isActive={showOptions}
          data={
          (WTIId || TabType === 'Instance_Tab')
              ? isEmpty(layout?.[breakpoint]) ? tabEmpty : tabMenuInstance
              : isEditOn[activeTab?.id]
                ? tabMenuEdit
                : isEmpty(layout?.[breakpoint]) ? tabEmpty : tabMenu
          }
          setModalIsVisibal={() => setShowOptions(false)}
          // setModalIsVisibal={() => {}}
          handleClick={handleDropdown}
          // singleSelection
          ContainerStyle={{ width: 'max-content', zIndex: 200 }}
          noContainerPadding
          className='light'
          isTab={isTab}
        >
          <div
            className='widget-tab-kabab'
            style={{ padding: '5px' }}
            onClick={(e) => {
              // setWidgetActiveTab(item);
              setShowOptions((e) => !e);
              e.stopPropagation();
            }}
          >
            {item?.id === activeTab?.id && <DynamicSVGIcon width='1.5rem' iconUrl={Kabab} rotate={90} />}
          </div>
        </DropDownModal>
      </ContastIconColorContainer>
      {/* create template modal */}
      {showModal?.createTemplate && (
        <CreateTemplateModal
          Header='Create Template'
          closeModalHandler={
            (val) => handleDropdown({ role: 'createTemplate', val })
            // eslint-disable-next-line react/jsx-curly-newline
          }
          handleSave={handleCreateTemplateRoute}
        />
      )}
      <RenameTab
        heading='Rename Tab'
        visibal={showModal?.rename}
        visibleHandler={(val) => handleDropdown({ role: 'rename', val })}
        maxLength={200}
      />
      <DeletTab
        showDeleteConfModal={showModal?.delete}
        setShowDeleteConfModal={
          (val) => handleDropdown({ role: 'delete', val })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
      <ClearTabConfModal
        showDeleteConfModal={showModal?.clear}
        setShowDeleteConfModal={(val) => handleDropdown({ role: 'clear', val })}
      />
      {exportXlxs && <ExportDashboard name={WidgetPanelName} setExportXlxs={setExportXlxs} WidgetComponentData={WidgetComponentData} individualExport={false} />}
    </>
  );
};

const TabsList = ({
  tabs,
  activeTab,
  tabHandler,
  showBorder = false,
  boxWidth,
  isWidgetTab
}) => {
  const tabListRef = useRef();
  const tabListRef2 = useRef();
  const [tabRange, setTabRange] = useState([]);
  const [width] = useWindowSize();
  const {
    widgetDetailSetter,
    setBulkUpsertWidgetDetails,
    resetWidgetPanelPayload,
    setDisableEditWidget
  } = useActions();
  const startIndex = useSelector((state) => state.widgets.startIndex);
  const showSideNav = useSelector((state) => state.home.showSideNav);

  /** To start tabs from current active tab */
  useEffect(() => {
    let activeTabIndex;
    tabs.forEach((tab, ind) => {
      if (tab?.id === activeTab?.id) {
        activeTabIndex = ind;
      }
      widgetDetailSetter({ key: 'startIndex', value: activeTabIndex });
    });
    // return () => {
    //   setDisableEditWidget();
    // };
  }, []);

  const {
    TemplateParameters,
    WidgetComponentData,
    templateInstanceName,
    WTIId,
    TabType
  } = useSelector((state) => state.widgets);

  // function getVisibleMenu(arr, startIdx, n) {
  //   if (arr.length === 0) {
  //     return { selected: [], leftOut: [] };
  //   }
  //   const selected = [];
  //   const leftOut = [];
  //   let currentIndex = startIdx % arr.length;
  //   for (let i = 0; i < arr.length; i += 1) {
  //     if (i < n) {
  //       selected.push(arr[currentIndex]);
  //     } else {
  //       leftOut.push(arr[currentIndex]);
  //     }
  //     currentIndex = (currentIndex + 1) % arr.length;
  //   }
  //   return { selected, leftOut };
  // }

  // const [numberOfItems, setNumberOfItems] = useState();

  // useEffect(() => {
  //   if (tabListRef.current) {
  //     let endIndex = startIndex;
  //     let availableWidth;
  //     if (showSideNav) {
  //       availableWidth = tabListRef2.current.offsetWidth - 246;
  //     } else {
  //       availableWidth = tabListRef2.current.offsetWidth - 200;
  //     }
  //     let occupiedWidth = 0;
  //     const currentWidth = 126;
  //     const defaultTabs = 6;
  //     const collection = tabListRef.current.children;
  //     for (let i = startIndex; i < collection.length; i += 1) {
  //       const e = collection[i];
  //       occupiedWidth += currentWidth;
  //       if (occupiedWidth < availableWidth) {
  //         endIndex += 1;
  //       } else {
  //         break;
  //       }
  //     }
  //     if (
  //       availableWidth - 10 <=
  //         currentWidth * Math.trunc(availableWidth / currentWidth) &&
  //       availableWidth > 500
  //     ) {
  //       setNumberOfItems(defaultTabs);
  //     } else if (showSideNav) {
  //       setNumberOfItems(Math.trunc(availableWidth / currentWidth) + 1);
  //     } else {
  //       setNumberOfItems(Math.trunc(availableWidth / currentWidth));
  //     }

  //     if (startIndex === endIndex) {
  //       widgetDetailSetter({ key: 'startIndex', value: 0 });
  //     }
  //     setTabRange([startIndex, endIndex]);
  //   }
  // }, [tabs, startIndex, width, tabListRef2?.current?.offsetWidth, showSideNav]);

  // const len = tabs.length;
  // const start = tabRange[0] ? tabRange[0] % len : 0;
  // const end = tabRange[1] ? tabRange[1] % len : 0;
  // const visibleMenu = useMemo(() => {
  //   if (tabs.length >= numberOfItems) {
  //     return [...getVisibleMenu(tabs, startIndex, numberOfItems)?.selected];
  //   }
  //   return tabs;
  // }, [tabRange]);

  // const overflownMenu = useMemo(() => {
  //   if (tabs.length >= numberOfItems) {
  //     return [...getVisibleMenu(tabs, startIndex, numberOfItems)?.leftOut];
  //   }
  //   return [];
  // }, [tabRange]);

  const [showExtraTabMenu, setShowExtraTabMenu] = useState(false);
  const clickTabRef = useRef();

  const handleClick = ({ id }) => {
    const index = tabs.findIndex((e) => e.id === id);
    clickTabRef.current = tabs.findIndex((tab) => tab?.id === activeTab?.id);
    // setStartIndex(index);
    widgetDetailSetter({ key: 'startIndex', value: index });
    tabHandler(tabs[index]);
    setShowExtraTabMenu(false);
  };

  useEffect(() => {
    const indexTab = tabs.findIndex((tab) => tab?.id === activeTab?.id);

    // ----------Code Written earlier with respect to scroll left -----------

    // if (clickTabRef.current > indexTab) {
    //   tabListRef2.current.scrollLeft = 100 * indexTab;
    // } else {
    //   tabListRef2.current.scrollLeft += 100 * indexTab;
    // }
    // --------------  ----------------------------------------------

    tabListRef2.current.scrollTo(indexTab * 100, 0);
  }, [showExtraTabMenu]);

  const handleScroll = (e) => {
    if (e?.target?.classList?.contains('on-scrollbar') === false) {
      e?.target?.classList?.add('on-scrollbar');
    }
    setTimeout(() => {
      if (e.target?.classList?.contains('on-scrollbar') === true) {
        e?.target?.classList?.remove('on-scrollbar');
      }
    }, 6000);
  };
  window.addEventListener('scroll', handleScroll, true);
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const handleFilter = () => {
    setBulkUpsertWidgetDetails({
      templateFormInput: TemplateParameters?.formInput,
      templateInstanceFormInput: WidgetComponentData,
      templateInstanceName: activeTab?.label,
      WTIId
    });
    setRedirect(true);
  };
  useEffect(() => {
    if (redirect) {
      history.push({
        pathname: '/home/template-instance',
        state: { isPreSelection: false, configureInstance: true }
      });
    }
  }, [redirect]);

  const refreshTab = () => {
    resetWidgetPanelPayload();
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    widgetDetailSetter({
      key: 'isTemplateComponentUpdateReq',
      value: true
    });
    widgetDetailSetter({ key: 'shouldRefreshPage', value: true });
  };
  const TabBox = ({
    activeTab,
    id,
    i,
    item,
    label,
    mouseEnter = () => { },
    mouseLeave = () => { }
  }) => {
    const isTabActive = activeTab?.id === id;
    return (
      <TabHeadingBox
        id={i}
        className='pointer'
        horPadding={tabHorizontalPadding}
        key={i}
        boxWidth={boxWidth}
        onClick={() => tabHandler(item)}
        isTabActive={isTabActive}
        isKababVisible={isTabActive}
      >
        <TabHeadingText className='flex' active={activeTab?.id === id}>
          <span
            style={{ maxWidth: '15rem', fontSize: '1.8rem' }}
            className='text-overflow'
            onMouseEnter={(e) => {
              mouseEnter(e, i);
            }}
            onMouseLeave={mouseLeave}
          >
            {`${label}`}
          </span>

          {isWidgetTab && (
            <>
              <IconContainer onClick={refreshTab}>
                {activeTab?.id === id ? (
                  <DynamicSVGIcon
                    rotate={45}
                    width='1.5rem'
                    iconUrl={refresh}
                  />
                ) : (
                  <></>
                )}
              </IconContainer>
              {(activeTab?.id === id && WTIId) || (activeTab?.id === id && TabType === 'Instance_Tab') ? (
                <FilterBtn
                  onClick={handleFilter}
                  isVisible={activeTab?.id === id}
                >
                  <DynamicSVGIcon width='1.5rem' iconUrl={filter} />
                </FilterBtn>
              ) : (
                <></>
              )}
              <TabDropdown item={item} isTab={true} activeTab={activeTab} />
            </>
          )}
          {activeTab?.id === id && <ActiveDivider width='105%' />}
        </TabHeadingText>
      </TabHeadingBox>
    );
  };
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [showTooltipHint, setShowTooltip] = useState(-1);
  const mouseEnter = (e, i) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      left: rect.left + rect.width / 2,
      top: rect.top + rect.height
    });
    setShowTooltip(i);
  };
  const mouseLeave = () => {
    setShowTooltip(-1);
  };
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  return (
    <>
      {/* container to calculate the width occupied */}
      <TabListContainer
        className='hide'
        ref={tabListRef}
        showBorder={showBorder}
        widgetDashboardType={widgetDashboardType === 'ALL'}
      >
        {tabs?.map((item, i) => {
          const { id, label } = item;
          return (
            <TabBox
              activeTab={activeTab}
              i={i}
              id={id}
              item={item}
              label={label}
            />
          );
        })}
      </TabListContainer>
      <TabListContainer ref={tabListRef2} showBorder={showBorder} widgetDashboardType={widgetDashboardType === 'ALL'}>
        {tabs?.map((item, i) => {
          const { id, label } = item;
          return (
            <TooltipBox onMouseLeave={mouseLeave}>
              {showTooltipHint === i && (
                <Tooltip
                  left={`${coords.left - 40}px`}
                  top={`${coords.top}px`}
                  name={label}
                />
              )}
              <TabBox
                mouseEnter={mouseEnter}
                mouseLeave={mouseLeave}
                activeTab={activeTab}
                i={i}
                id={id}
                item={item}
                label={label}
              />
            </TooltipBox>
          );
        })}
      </TabListContainer>
      {!isEmpty(tabs) && (
        <DropDownModal
          isActive={showExtraTabMenu}
          setModalIsVisibal={setShowExtraTabMenu}
          data={tabs}
          ContainerStyle={{
            left: 0,
            marginTop: '0.3rem',
            width: '15%',
            maxHeight: '500px',
            overflowY: 'auto'
          }}
          TextSpanStyle={{ fontSize: '1.8rem' }}
          handleClick={handleClick}
          noContainerPadding
          showToolTip='true'
        >
          <ListBtn
            type='button'
            onClick={() => setShowExtraTabMenu((prev) => !prev)}
          >
            &gt;&gt;
          </ListBtn>
        </DropDownModal>
      )}
      {/* <SettingBtn /> */}
    </>
  );
};

export default TabsList;

const TabHeadingBox = styled.div`
  box-sizing: content-box;
  margin: ${({ horPadding }) => `0rem ${horPadding || 10}px -0.4rem`};
  position: relative;
  display: flex;
  height: 7rem;
  justify-content: center;
  align-items: center;
  text-overflow: hidden;
  white-space: nowrap;
  text-decoration: ellipsis;
  &:hover {
    background-color: ${({ theme }) => theme.aux.darkSecondary};
    & .widget-tab-kabab {
      display: block;
    }
  }
  & .widget-tab-kabab {
    display: ${({ isKababVisible }) => (isKababVisible ? 'block' : 'none')};
  }
  @media ${device.tablet} {
    margin: 0 10px 0 10px;
  }
`;

export const TabHeadingText = styled.p`
  /* width: 10rem; */
  // TODO: test
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: ${fontSize.text};
  color: ${({ theme, active }) =>
    active ? theme.contrast.primary : theme.contrast.secondary};
  font-family: ${({ active }) =>
    active ? fontFamily.circularBold : fontFamily.circularBook};

  @media ${device.tablet} {
    font-size: 15px;
  }

  &.widgetHeading {
    color: ${({ theme }) => theme.contrast.primary};
  }
  &.flex {
    display: flex;
  }
`;

export const PanelHeadingText = styled.p`
  /* width: 10rem; */
  // TODO: test
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: ${fontSize.medium};
  padding-bottom: 1rem;
  color: ${({ theme, active }) =>
    active ? theme.contrast.primary : theme.contrast.secondary};
  font-family: ${({ active }) =>
    active ? fontFamily.circularBold : fontFamily.circularBook};

  @media ${device.tablet} {
    font-size: 15px;
  }

  &.widgetHeading {
    color: ${({ theme }) => theme.contrast.primary};
  }
  &.flex {
    display: flex;
  }
`;

export const TabListContainer = styled(FlexContainer)`
  display: flex;
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  min-height: 7rem;
  padding-bottom: 5px;
  border-bottom: 1px solid #70707080;
  border-bottom-width: ${({ showBorder }) => `${showBorder ? '1px' : '0px'}`};
  width: ${({ widgetDashboardType }) => `${widgetDashboardType ? '78%' : '90%'}`};
  /* width: 78%; */
  overflow: hidden;
  overflow-x: auto;
  &.hide {
    position: absolute;
    top: 0px;
    min-height: 0px;
    height: 0px;
    background: red;
    overflow-x: hidden;
    min-height: 0px;
    height: 0px;
    width: 0px;
  }
  @media print {
   display:none;
  }
`;

export const ListBtn = styled.button`
  border: none;
  background: #fff;
  padding: 1rem;
  border-radius: 1rem;
  transition: all 0.25s ease;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  :hover {
    box-shadow: none;
  }
  @media print {
    display: none;
  }
`;
