import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setActiveMenu: ['payload'],
  setShowSideNav: ['payload'],
  setError: ['payload'],

  getMenu: ['payload'],
  getMenuStart: ['payload'],
  getMenuSuccess: ['payload'],
  getMenuFailure: ['payload'],

  setSubMenuOptions: ['payload'],
  setActiveMultiLevelMenu: ['payload'],
  toggleHeaderOption: ['payload'],
  settingsActiveMenu: ['payload'],
  setNotificationTabs: ['payload'],

  fetchDetailsStart: ['payload'],
  fetchDetailsFailure: ['payload'],

  getNotificationsCount: ['payload'],
  getNotificationsCountSuccess: ['payload'],

  setDefaultUrl: ['payload'],
  setDefaultUrlStart: ['payload'],
  setDefaultUrlSuccess: ['payload'],
  setDefaultUrlFailure: ['payload'],

  getDefaultUrl: ['payload'],
  getDefaultUrlStart: ['payload'],
  getDefaultUrlSuccess: ['payload'],
  getDefaultUrlFailure: ['payload'],
});
