/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import Index from '..';
import { useActions } from '../../../store/hooks/useActions';
import { getWidgetPanelPayload } from '../utils/getWidgetPanelPayload';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { REPEATED_WIDGETS, SHARED_LAYOUT, colors } from '../../../constants';
import { getRepeatedWidgetsLayout } from '../utils/getLayoutData';
import { WidgetCreators } from '../../../views/Widgets/store';
import { FilterBtn } from '../Header/TabsList/TabsList.style';
import Filter from '../../../assets/icons/Filter/Filters.svg';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import ButtonComp from '../../Common/Button/Button.view';
import IconComponent from '../../Icon/Icons';
import { localString } from '../../../localization/localString';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ShowcaseLayoutPop = (props) => {
//   const layoutComponentData = useSelector((state) => state.widgets.layoutComponentData);
  // const repeatedWidgets = useSelector((state) => state.widgets.repeatedWidgets);
  //   const layout = useSelector((state) => state.widgets.layout);

  const [copied, setCopied] = useState(false);

  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const compactType = 'vertical';
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const userData = useSelector((state) => state.loginData.userData);
  const { updateWidgetsLayoutSuccess, updateWidgetsPanelPayloadSave } = useActions();
  const themeMode = useSelector((state) => state.configData.themeMode);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const isPreviewInstance = useSelector((state) => state.widgets.isPreviewInstance);

  const { isCustom } = props;
  const { layoutComponentData, layout, WidgetComponentData, repeatedWidgets, handleFilter } = props || {};
  const isResizable = isCustom ? true : (WTIId && !isPreviewInstance) ? true : !!isEditOn[activeTab.id];

  const language = useSelector((state) => state.configData.language);
  const string = localString[language];

  useEffect(() => {
    setTimeout(
      // eslint-disable-next-line no-undef
      () => { window.dispatchEvent(new Event('resize')); },
      1000
    );
  }, [showSideNav]);

  const onLayoutChange = useCallback((newLayout) => {
    // console.log('new layout', newLayout, {
    //   ...layout,
    //   [breakpoint]: newLayout
    // });
    // console.log('newLayout', layout, newLayout, !newLayout.every((e) => e.w === 1 && e.h === 1));
    if (
      !isEmpty(newLayout) &&
      !isEmpty(layout?.[breakpoint]) &&
      !isEmpty(layoutComponentData) &&
      !newLayout.every((e) => e.w === 1 && e.h === 1) // if all the cards are collapsed
    ) {
      // console.log('update in showcaselayout started');
      try {
        const widgetPayload = getWidgetPanelPayload({
          menuId: menuIdofWidget,
          userId: userData.userId,
          isShareable: activeTab?.isShareable,
          widgetPanelName: activeTab?.label,
          widgetPanelLayoutData: {
            [SHARED_LAYOUT]: newLayout,
            [REPEATED_WIDGETS]: getRepeatedWidgetsLayout(newLayout, repeatedWidgets)
          },
          widgetPanelComponentData: Object.values(layoutComponentData)
        });
        // updateWidgetsLayout({ widgetPayload, WPID: activeTab?.id });
        updateWidgetsLayoutSuccess({
          layout: newLayout,
          repeatedWidgets: getRepeatedWidgetsLayout(newLayout, repeatedWidgets)
        });
        updateWidgetsPanelPayloadSave({ widgetPayload });
      } catch (e) {
        console.log('error while updating layout', e);
      }
    }
  }, []);

  const handleBreakPointChange = (newBreakpoint) => {
    // const oldLayout = layout[breakpoint] || layout.lg || layout.md || layout.sm || layout.xs || layout.xxs || [];
    // console.log('new breakpoint', newBreakpoint);
    // updateLayoutOfBreakpoint({ layout: layout?.[breakpoint], breakpoint: newBreakpoint });
    // setBreakPoint(newBreakpoint);
    // updateLayoutOfBreakpoint({ ...layout, [newBreakpoint]: layout[newBreakpoint] || oldLayout });
  };

  return (
    <>
      <ResponsiveReactGridLayout
        {...props}
        layouts={layout} // ?.[breakpoint]
        onBreakpointChange={handleBreakPointChange}
        onLayoutChange={onLayoutChange}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={false}
        compactType={compactType}
        margin={[10, 10]}
        containerPadding={[10, 10]}
        style={{ backgroundColor: colors[themeMode].contrast.whiteNgray }}
        isResizable={true}
        isDraggable={!(window.innerWidth < 769)}
        className={isResizable}
        autoSize={false}

      // aditional
      // onCompactTypeChange
      >
        {layout?.[breakpoint]?.map((l, i) => {
          const widgetKey = l.i;
          const [WID, key] = widgetKey.split('_');
          // console.log('WID', l, widgetKey, WID, key);
          return (
            isHideBorder[WID + activeTab?.id] ? (
              <div
                // style={{
                //   boxShadow: colors[themeMode].shadowout,
                //   borderRadius: '8px',
                //   backgroundColor: colors[themeMode].aux.secondary
                // }}
                key={widgetKey}
                className={l.static ? 'static' : ''}
              >
                {l.static ? (
                  <span className='text' title='This item is static and cannot be removed or resized.'>
                    Static - {i}
                  </span>
                ) : (
                  <Index i={i} layoutPos={l} componentData={layoutComponentData?.[WID]} WidgetComponentData={WidgetComponentData} /> // l.WID || l.i
                  // <span className='text'>{i}</span>
                )}
              </div>
            ) : (
              <div
                style={{
                  boxShadow: colors[themeMode].shadowout,
                  borderRadius: '8px',
                  backgroundColor: colors[themeMode].aux.secondary
                }}
                key={widgetKey}
                className={l.static ? 'static' : ''}
              >
                {l.static ? (
                  <span className='text' title='This item is static and cannot be removed or resized.'>
                    Static - {i}
                  </span>
                ) : (
                  <Index i={i} layoutPos={l} componentData={layoutComponentData?.[WID]} WidgetComponentData={WidgetComponentData} /> // l.WID || l.i
                  // <span className='text'>{i}</span>
                )}
              </div>
            )
          );
        })}
      </ResponsiveReactGridLayout>
      <div style={{ backgroundColor: colors[themeMode].contrast.whiteNgray, display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
        <IconComponent name='vistrian' mr={1.5} />
        {'Vistrian FactoryLook' || string.brandingTitle}
      </div>
    </>
  );
};

ShowcaseLayoutPop.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowcaseLayoutPop.defaultProps = {
  className: 'layout',
  rowHeight: 30,
  onLayoutChange() { },
  breakpoints: { lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
};

export default memo(ShowcaseLayoutPop);
