/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { WIDGET_TYPES } from '../../../../constants';
// import { apiEndPoints } from '../../../../services/axios/endPoints';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { WebService } from '../../../../services/axios/webServices';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import GridMultipleTable from '../../../../views/Widgets/WidgetComponents/DetailedStatusValueWidget.jsx/GridMultipleTable';
import { Container } from '../../Common/WidgetCommons.style';
import Loader from '../../../Loader/Loader';
import { getFetchDetailsOfWidgetData } from '../../utils';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import { AddToHome, DeleteBlack, Export, ExportExcelIcon, ExportImageIcon, ExportPdfIcon, HideBorder, HideHeader, RemoveFromHome, RemoveHomeMenu, settingsBlack } from '../../../../assets/icons';
import { useActions } from '../../../../store/hooks/useActions';

const DetailedStatusValue = (props) => {
  const { componentData, id, isVisible, layoutPos, fullView, WIDFromProp, HDId } = props;
  const { WidgetName, infoText, formData, type, ...componentProps } = componentData || {};

  const WID = WIDFromProp || layoutPos.i?.split('_')?.[0];

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [widgetData, setWidgetData] = useState({});

  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);

  const { setExportData } = useActions();

  // Widget Options which is only for Designer Tab Widgets
  const widgetOptions = [
    { id: 1, label: 'configure', value: 'configure', iconUrl: settingsBlack },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExportWithoutTab',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },

  ];

  // Widget Options for the Instance Tab widgets and if HDId then for Home Dashboard widgets
  const widgetOptionsInstance = HDId ? [
    {
      id: 1,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExportWithoutTab',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },

  ] : [
    // { id: 1, label: 'configure', value: 'configure' },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExportWithoutTab',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },
  ];

  // Widget Options for the pop up view fron Configurable Dashboard widgets
  const widgetOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExportWithoutTab',
          iconUrl: ExportPdfIcon,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: ExportExcelIcon,
        }
      ]
    },

  ];

  const { id: WTIIdFromUrl } = useParams();

  useEffect(() => {
    if (isEmpty(widgetData)) {
      setLoading(true);
      const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
      const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
      WebService[method](fullURL, {
        widgetType: type, // WIDGET_TYPES.DETAILED_STATUS_VALUE,
        formInput: formData
      })
        .then((response) => {
          const { data, mata } = response?.data?.data;
          setWidgetData({
            equipments: data,
            meta: mata
          });
          setExportData({ TabId: WTIIdFromUrl || activeTab?.id, WID, widgetData: { data, mata, WidgetName, type } });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          const err = sagaCatchBlockHandling(e);
          setError(err.error);
          console.log('detailed status value err', e);
        });
    }
  }, []);

  if (error) return <span>{error}</span>;
  return (
    <Container>
      { (
        <WidgetHeaderView
          id={id}
          WID={WIDFromProp || layoutPos.i?.split('_')?.[0]} // WID
          WidgetName={WidgetName || 'Detailed Status Value'}
          widgetOptions={WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePop : widgetOptionsInstance : widgetOptions}
          fullView={fullView}
          hasZoomControl={true}
          setZoomLevel={setZoomLevel}
          componentData={componentData}
          zoomLevel={zoomLevel}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <GridMultipleTable fullView={fullView} zoomLevel={zoomLevel} {...(componentProps || {})} {...widgetData} />
      )}
    </Container>
  );
};

export default DetailedStatusValue;
