/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty } from 'lodash';
import { getUserDataFromLocal } from '../shared/utility/helper';
import Login from '../views/auth/login/Login.view';
import Iframe from '../component/ResultView/Iframe/Iframe';
import Layout from '../views/Layout/Layout';
import DirectIframe from '../views/DirectIframe/DirectIframe';

const ProtectedRoute = ({ Component, ...props }) => {
  const { authenticated } = useSelector((state) => state.loginData);
  const { logoutClicked } = useSelector((state) => state.loginData);
  const { defaultUrl } = useSelector((state) => state.home);
  const { loading } = useSelector((state) => state.home);
  const userData = getUserDataFromLocal();
  const currentLocation = window.location.pathname;
  const location = useLocation();
  const { referrer } = location.state || {};
  const { search } = location || {};

  const componentsURL = referrer?.split('/');
  componentsURL?.splice(0, 2);

  let relativePath;
  // eslint-disable-next-line prefer-const
  relativePath = componentsURL?.join('/');
  const modifiedDefaultUrl = defaultUrl.split('?')[0];

  if (Component === Login) {
    if (logoutClicked) {
      return (
        <Route
          {...props}
          render={(props) => (
            // eslint-disable-next-line no-nested-ternary
            // <>{userData?.token || authenticated ? referrer ? <Redirect to={{ pathname: `/${relativePath}` }} /> : <Redirect to='/home/dashboards/oee' /> : <Component {...props} />}</>
            <>{ (userData?.token || authenticated) && !loading ? <Redirect to={{ pathname: !isEmpty(defaultUrl) ? `${modifiedDefaultUrl}` : '/home/dashboards/oee', state: { referrer: currentLocation, fromLogin: true } }} /> : <Component {...props} />}</>

          )}
        />
      );
    }
    return (
      <Route
        {...props}
        render={(props) => (
          // eslint-disable-next-line no-nested-ternary
          // <>{userData?.token || authenticated ? referrer ? <Redirect to={{ pathname: `/${relativePath}` }} /> : <Redirect to='/home/dashboards/oee' /> : <Component {...props} />}</>
          <>{ (userData?.token || authenticated) && !loading ? <Redirect to={{ pathname: relativePath ? `/${relativePath}` : !isEmpty(defaultUrl) ? `${modifiedDefaultUrl}` : '/home/dashboards/oee', state: { referrer: currentLocation, fromLogin: true } }} /> : <Component {...props} />}</>

        )}
      />
    );
  }
  return (
    <Route
      {...props}
      render={(props) => (
        <>
          {userData?.token || authenticated ? <Component {...props} /> : logoutClicked ? (
            <Redirect to={{
              pathname: '/',
            }}
            />
          ) : (
            <Redirect to={{
              pathname: '/',
              state: { referrer: currentLocation },
              // search: `?${currentLocation}`,
            }}
            />
          )}
        </>
      )}
      // render={(props) => <>{userData?.token || authenticated ? <Component {...props} /> : <Redirect to='/' />}</>}
    />
  );
};
const Routes = () => (
  <Switch>
    <ProtectedRoute path='/home' exact={false} name='home' Component={Layout} />
    <ProtectedRoute path='/filter-iframe' exact={false} name='iframe' Component={Iframe} />
    <ProtectedRoute path='/direct-iframe' exact={false} name='direct-iframe' Component={DirectIframe} />
    <ProtectedRoute path='/' exact={false} name='login' Component={Login} />
  </Switch>
);

export default Routes;
