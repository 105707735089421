/* eslint-disable max-len */
import React, { useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterSearch } from './TreeSearchComponent.style';

import { Creators as ObjectTreeCreators } from '../Store';

import { loopIntoParent } from './SearchFunctionality';
import useDebounce from '../../../shared/hooks/useDebounce';

export const findTheValue = (objectTreeData, val, searchBy = 'name') => {
  let searchedObjectPath = [];
  let referencePath = [];
  let pathVal = [];
  let leafNodes = [];
  if (objectTreeData) {
    objectTreeData.forEach((item) => {
      const value = loopIntoParent(item, val, searchBy);

      if (value.selectedPath.length > 0) {
        pathVal = [...pathVal, ...value.selectedPath];
        searchedObjectPath = [...searchedObjectPath, ...value.selectedObj];
        referencePath = [...referencePath, ...value.refrenceObj];
        leafNodes = [...leafNodes, ...value.leafNodes];
      }
    });
  }
  return { searchedObjectPath, referencePath, pathVal, leafNodes };
};

function TreeSearchInput({ itemKey, searchValue, setSearchValue, isLabelChart, isDisabled, placeholder }) {
  const objectTreeData = useSelector((state) => state.objectTreeData.objectTreeData);
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const dispatch = useDispatch();

  const toggleDropDown = (value) => {
    dispatch(
      ObjectTreeCreators.universalObjectTreeSetter({
        key: 'isDropDownActive',
        value
      })
    );
  };

  const handleChange = (e) => {
    setSearchValue(e);
  };

  useEffect(() => {
    const objectTreeDataCurrent = objectTreeData.filter((item) => (item.key === itemKey));
    const value = findTheValue(objectTreeDataCurrent, searchValue);

    dispatch(
      ObjectTreeCreators.universalObjectTreeSetter({
        key: 'searchResult',
        value
      })
    );
  }, [searchValue]);

  const [localValue, setLocalValue] = useState(searchValue);
  useDebounce(localValue, handleChange, 1000);

  return (
    <FilterSearch
      isLabelChart={isLabelChart}
      placeholder={`Search For ${placeholder}` || localeStrings.searchFilters || 'Search For Filters'}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onFocus={() => toggleDropDown(true)}
      isDisabled={isDisabled}
      disabled={isDisabled}
    />
  );
}

export default TreeSearchInput;
