export const getWidgetDetailsPayload = (payload) => {
  const {
    WPID,
    siteId = 10, // TODO
    userId,
    widgetTypeId: typeId,
    widgetType, // type value
    widgetTypesMenu, // type menu
    widgetName: wName,
    widgetInfo = {},
    // widgetLayoutData,
    widgetComponentData,
    widgetMetaData,
    isShareable,
    ...rest
  } = payload;

  let widgetTypeId = typeId;
  let widgetName = wName;
  const wType = widgetTypesMenu?.[widgetType];
  if (!typeId) {
    widgetTypeId = wType?.id;
  }
  if (!widgetName) {
    widgetName = wType?.label;
  }
  return {
    SiteId: siteId,
    UserId: userId,
    WidgetName: widgetName,
    WidgetMetaData: widgetMetaData,
    WidgetInfo: widgetInfo,
    WidgetTypeId: widgetTypeId,
    WidgetLayoutData: {},
    WidgetPanelId: WPID,
    WidgetComponentData: widgetComponentData,
    IsShareable: isShareable,
    ...rest
  };
};
