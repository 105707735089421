import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../../constants';

export const BlankDiv = styled.div`
  width: 100%;
  background: #ffffff;
  &.multiple_status_widget {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    margin: 2rem 0px 1rem 0px;
    /* height: 5.5rem; */
    /* background: ${(props) => props.background}; */
    background: #000;
    color: ${(props) => props.color};
    font-size: ${fontSize.titleBig};
    font: ${fontFamily.circularBook};
  }
`;

export const GridContainer = styled.div`
  position: relative;
  display: flex;
  width: 98%;
  padding: 2.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  border-radius: 10px;
  background: #ffff 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #fffffff5;
  min-width: 40rem;
  gap: 1rem;
  height: calc(100% - 7rem);
  overflow-y: auto;
  &.multiple_status_widget {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 3rem;
  }
  &.zoom {
    /* padding: 1rem; */
    /* scale: ${({ zoomLevel }) => zoomLevel || 1}; */
    /* zoom:${({ zoomLevel }) => zoomLevel}; */
    transform: scale( ${({ zoomLevel }) => zoomLevel || 1});
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (0.9 / zoomLevel) * 100 || 100}%;
  }
  &.zoom_status_values {
    transform: scale( ${({ zoomLevel }) => zoomLevel || 1});
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) =>
    (zoomLevel > 0.99 ? (0.9 / zoomLevel) * 100 : 100 || 100)}%;
  }
`;

export const Label = styled.text`
  text-align: center;
  font: normal normal normal 1.8rem Circular Std Bold;
  letter-spacing: -0.18px;
  color: #4a4a4a;
  opacity: 1;
  font-size: ${fontSize.title};
  font: ${fontFamily.circularBold};
  margin-bottom: 1.5rem;
  &.multiple_status_widget {
    color: ${(props) => props.textColor};
    font-size: ${fontSize.titleBig};
  }
`;
export const SubLabel = styled.text`
  font: normal normal normal 2.5rem Circular Std Bold;
  letter-spacing: -0.36px;
  color: #ffffff;
`;
export const Span = styled.span`
  display: flex;
  font: normal normal normal 1.5rem Circular Std Bold;
  letter-spacing: -0.36px;
  color: #ffffff;
  align-items: center;
  margin-left: 0.5rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  font: ${fontFamily.circularBold};
  color: #4a4a4a;
  align-items: center;
  background: ${(props) => props.color};
  /* justify-content: space-between; */
  min-width: 12rem;
  padding: 1rem;
  margin: 0 0 1rem 0;
  min-width:${({ minWidth }) => minWidth || '12rem'};
  height:${({ height }) => height || '13rem'};
  &.multiple_status_widget {
    /* background: red; */
    /* background: #707070 0% 0% no-repeat padding-box; */
  background: ${(props) => props.background || '#707070 0% 0% no-repeat padding-box'};
    justify-content: center;
    height: 12rem;
    min-width: 8rem;
    flex: 1;
    padding:2rem;
  }
  &.equipment_status_value{
    min-width: 20rem;
    height: 18rem;
  }
  &.status_card_size{
    width:18rem;
  }

`;

export const ItemTitle = styled.text`
  display: flex;
  width: 100%;
  height: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: ${fontSize.ToastHeader};
  font: ${fontFamily.circularBold};
  letter-spacing: 0px;
  opacity: 1;
`;
export const ItemCategory = styled.text`
  font: normal normal normal 1.6rem Circular Std Medium;
  letter-spacing: -0.14px;
  color: #4a4a4a;
  opacity: 1;
  align-self: center;
  text-align: center;
`;
export const TableContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ParamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
