/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { getWidgetLayoutData, getWidgetLayoutDataForHome } from '../../../component/Widget/utils/getLayoutData';

const INITIAL_STATE = {
  widgetsInHome: [],

  instanceData: [],
  instanceMeta: {},
  instanceCount: 0,
  totalPages: 0,
  loading: false,

  HDId: '',
  loadingLayout: false,
  layoutError: '',
  layoutWarning: '',
  layout: {},
  breakpoint: 'lg', // setBreakPoint
  layoutComponentData: {},

  searchQueries: {},
};

const setWidgetsInHomeList = (state = INITIAL_STATE, action) =>
  ({
    ...state,
    widgetsInHome: action.payload
  });

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const getWidgetInstancesListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getWidgetInstancesListSuccess = (state = INITIAL_STATE, action) => {
  const { instanceData, meta } = action.payload;
  return {
    ...state,
    instanceData,
    instanceCount: meta?.Count,
    totalPages: meta?.TotalPages,
    instanceMeta: meta,
    loading: false
  };
};

const getWidgetInstancesListFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const addWidgetToHomeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const addWidgetToHomeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,

});

const addWidgetToHomeFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const getHomeWidgetsLayoutStart = (state = INITIAL_STATE) => ({
  ...state,
  loadingLayout: true
});

const getHomeWidgetsLayoutSuccess = (state = INITIAL_STATE, action) => {
  const {
    layout,
    layoutComponentData: componentData,
    repeatedWidgets,
    HDId
  } = action.payload;
  const { layoutComponentData, currentLayout, widgetTypesIncluded } = getWidgetLayoutDataForHome(
    componentData,
    layout,
    repeatedWidgets,
    false,
    state.layoutComponentData,
  );

  return {
    ...state,
    layout: {
      ...state.layout,
      [state.breakpoint]: currentLayout
    },
    HDId,
    repeatedWidgets,
    fullWidgetData: componentData,
    widgetTypesIncluded,
    layoutComponentData,
    loadingLayout: false,
  };
};

const getHomeWidgetsLayoutError = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingLayout: false,
  layoutError: action.payload?.error,
  layoutWarning: action.payload?.layoutWarning,
  layout: INITIAL_STATE.layout,
  layoutComponentData: INITIAL_STATE.layoutComponentData
});

const HomeLayoutUpdateStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const HomeLayoutUpdateSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
});

const HomeLayoutUpdateFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const addWidgetFromPreviewStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const addWidgetFromPreviewSuccess = (state = INITIAL_STATE, action) => ({
  ...state,

});

const addWidgetFromPreviewFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const deleteWidgetFromHomeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const deleteWidgetFromHomeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,

});

const deleteWidgetFromHomeFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const HomeAllWidgetsUpdateStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const HomeAllWidgetsUpdateSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
});

const HomeAllWidgetsUpdateFailure = (state = INITIAL_STATE, action) => {
  console.log('failure', action.payload);
  return {
    ...state,
    loading: false
  };
};

const resetInstanceData = (state = INITIAL_STATE, action) => ({
  ...state,
  instanceData: [],
  instanceMeta: {},
  instanceCount: 0,
  totalPages: 0,
});

const HANDLERS = {
  [Types.SET_WIDGETS_IN_HOME_LIST]: setWidgetsInHomeList,
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,

  [Types.GET_WIDGET_INSTANCES_LIST_START]: getWidgetInstancesListStart,
  [Types.GET_WIDGET_INSTANCES_LIST_SUCCESS]: getWidgetInstancesListSuccess,
  [Types.GET_WIDGET_INSTANCES_LIST_FAILURE]: getWidgetInstancesListFailure,

  [Types.ADD_WIDGET_TO_HOME_START]: addWidgetToHomeStart,
  [Types.ADD_WIDGET_TO_HOME_SUCCESS]: addWidgetToHomeSuccess,
  [Types.ADD_WIDGET_TO_HOME_FAILURE]: addWidgetToHomeFailure,

  [Types.GET_HOME_WIDGETS_LAYOUT_START]: getHomeWidgetsLayoutStart,
  [Types.GET_HOME_WIDGETS_LAYOUT_SUCCESS]: getHomeWidgetsLayoutSuccess,
  [Types.GET_HOME_WIDGETS_LAYOUT_ERROR]: getHomeWidgetsLayoutError,

  [Types.HOME_LAYOUT_UPDATE_START]: HomeLayoutUpdateStart,
  [Types.HOME_LAYOUT_UPDATE_SUCCESS]: HomeLayoutUpdateSuccess,
  [Types.HOME_LAYOUT_UPDATE_FAILURE]: HomeLayoutUpdateFailure,

  [Types.ADD_WIDGET_FROM_PREVIEW_START]: addWidgetFromPreviewStart,
  [Types.ADD_WIDGET_FROM_PREVIEW_SUCCESS]: addWidgetFromPreviewSuccess,
  [Types.ADD_WIDGET_FROM_PREVIEW_FAILURE]: addWidgetFromPreviewFailure,

  [Types.DELETE_WIDGET_FROM_HOME_START]: deleteWidgetFromHomeStart,
  [Types.DELETE_WIDGET_FROM_HOME_SUCCESS]: deleteWidgetFromHomeSuccess,
  [Types.DELETE_WIDGET_FROM_HOME_FAILURE]: deleteWidgetFromHomeFailure,

  [Types.HOME_ALL_WIDGETS_UPDATE_START]: HomeAllWidgetsUpdateStart,
  [Types.HOME_ALL_WIDGETS_UPDATE_SUCCESS]: HomeAllWidgetsUpdateSuccess,
  [Types.HOME_ALL_WIDGETS_UPDATE_FAILURE]: HomeAllWidgetsUpdateFailure,

  [Types.RESET_INSTANCE_DATA]: resetInstanceData,

};

export const HomeDashboardReducer = createReducer(INITIAL_STATE, HANDLERS);
