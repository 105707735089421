/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BackBlack } from '../../../assets/icons';
import {
  Button,
  Cell,
  Container,
  HeaderText,
  HeadingBox,
  LeftArrow,
  SaveICon,
  TableContainer,
  Wrapper
} from './KPVShortlist.style';
import DropArrow from '../../../assets/icons/layout/dropdownarrow.svg';
import { Creators as FilterResultCreators } from '../Store/index';
import { myAnalyticsCreators } from '../../../views/MyAnalytics/store';
import ColumnFilter from '../ColumnCustom/ColCustom.view';
import { ActionIcon } from '../KPVGroupComponent/KPVGroupComponent.style';
import SaveShortListModal from '../SaveShortlistModal/SaveShortlist.view';
import PlotOptionsModal from '../PlotOptionsModal/PlotOptionsModal.view';
import RemoveFromPane from '../../SvgIcons/KPVList/RemoveFromPane';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import ButtonComp from '../../Common/Button/Button.view';
import { Edit } from '../../../assets/icons/KPV';
import IconComp from '../../Common/IconContainer/Icon';
import { Row } from '../KPVListComponent/KPVListComponent.style';
import { PAGE_TYPES } from '../../../constants';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../Filter/utility/populateFilterDashboard';
import { formatedFilterData } from '../../Filter/utility/formatFilterData';
// import { generateSelectedFilter } from '../Iframe/generateSelectedFilter';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import Loader from '../../Common/Loader/Loader';
// import { saveAnalyticsPayload } from '../../MyAnalytics/utility/saveAnalyticsPayload';
import SaveAnalyticsChanges from '../../MyAnalytics/SaveAnalyticsModal/SaveAnalyticsChanges';
import SaveAnalytics from '../../MyAnalytics/SaveAnalyticsModal/SaveAnalytics';
import { fetchUserAndLocation } from '../../Sidenav/FetchStatics';
import { commonRoutes } from '../../../routes/route';

const KPVShortlistComp = () => {
  const activeMenuId = useSelector((state) => state.home.activeMenuId);
  const KPVShortlist =
    useSelector(
      (state) => state.filterResultData.KPVShortlist?.[activeMenuId]
    ) || [];
  const { metaDetailsInShortlist } = useSelector(
    (state) => state.filterResultData
  );
  // const [showSaveModal, setShowSaveModal] = useState(false);
  const [showPlotOptModal, setShowPlotOptModal] = useState(false);
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const { myAnalyticsItemName, isEdit, menuId, filterSelections } = useSelector(
    (state) => state.myAnalytics.analyticsData
  );
  const {
    menuIdofFilter,
    cumulativeTags,
    selectedParent,
    filterData,
    leafLevels
  } = useSelector((state) => state.filterData);

  const { userData } = useSelector((state) => state.loginData);
  const { analyticsData: form } = useSelector((state) => state.myAnalytics);
  const myAnalyticsPaths = useSelector(
    (state) => state.home?.[PAGE_TYPES.MY_ANAYLTICS]
  );
  const filterPaths = useSelector((state) => state.home?.[PAGE_TYPES.FILTER]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const panesShortHandInfo = useSelector(
    (state) => state.KPVCharts.panesShortHandInfo
  );

  useEffect(() => {
    const name = commonRoutes.find((ele) => ele.path == location.pathname);
    fetchUserAndLocation(location.state?.previousPath, userData, {
      pageType: name.name,
      tab: name.name
    });
  }, []);

  const handleShortList = (entry) => {
    dispatch(
      FilterResultCreators.setKpvShortlist({
        menuId: activeMenuId,
        payload: KPVShortlist.filter(
          (e) =>
            !(
              e.id === entry[metaDetailsInShortlist.UID] &&
              e.machineId === entry.MachineId
            )
        )
      })
    );
  };

  const handleBack = () => {
    history.goBack();
    dispatch(
      FilterCreators.universalFilterSetter({ key: 'showFilter', value: true })
    );
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'showFilterResult',
        value: true
      })
    );
  };

  const setEdit = () => {
    dispatch(myAnalyticsCreators.setAnalyticsItemEdit(true));
  };

  const handlePlot = () => {
    if (myAnalyticsItemName && !isEmpty(panesShortHandInfo)) {
      history.push({
        pathname: '/home/kpv-charting',
        state: { previousPath: location?.state?.previousPath }
      });
    } else {
      setShowPlotOptModal(true);
    }
  };

  const handleDiscard = () => {
    dispatch(myAnalyticsCreators.setAnalyticsItemEdit(false));
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleAddKpvs = async () => {
    // dispatch(FilterCreators.universalFilterSetter({ key: 'showFilter', value: true }));
    // dispatch(FilterCreators.universalFilterSetter({ key: 'showFilterResult', value: true }));
    // history.push(filterPaths[menuId]);
    setIsLoading(true);
    const filterURL = `${apiEndPoints.getFilterCategory}`;
    // needed for kpv list
    const filterDataOfMenuResp = await WebService.get(filterURL, {
      params: { menuId }
    });
    if (
      filterDataOfMenuResp.status >= 200 &&
      filterDataOfMenuResp.status < 300
    ) {
      const filterPayload = { menuid: menuId, data: filterSelections };

      try {
        // populate filter dashboard selections
        const { cumTags, cumSelectedObj } = populateFilterDashboard(
          filterPayload,
          filterDataOfMenuResp.data.data
        );
        const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
        dispatch(
          FilterCreators.populateFilterData({
            ...payload,
            menuIdofFilter: menuId
          })
        );
      } catch (e) {
        console.log('error in populating data', e);
      }

      // fetch filterCategory data
      history.push({
        pathname: filterPaths[menuId],
        goDirectToResult: true,
        resType: filterDataOfMenuResp.data.resultType
      });

      const { data: filtData, resultType } = filterDataOfMenuResp.data;
      const { formatedData, keyTitleMapping } = formatedFilterData(filtData);
      dispatch(
        FilterCreators.getFilterDataSuccess({
          data: formatedData,
          resultType,
          keyTitleMapping,
          menuId
        })
      );
    }
  };

  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showSavedashboard, setShowSavedashboard] = useState(false);

  const closeSaveConfirmationModal = () => setShowSaveConfirmation(false);

  const handleSave = async () => {
    setShowSaveConfirmation(true);
    // setIsLoading(true);
  };

  return (
    <Container>
      {isLoading && <Loader />}
      <HeadingBox>
        <Wrapper>
          <LeftArrow onClick={handleBack}>
            <DynamicSVGIcon iconUrl={DropArrow} width='2.4rem' />

            {/* <DynamicSVGIcon width='4.5rem' iconUrl={BackBlack} /> */}
          </LeftArrow>
          <HeaderText>
            {myAnalyticsItemName ||
              localeStrings.kpvShortlist ||
              'KPV shortlist'}
          </HeaderText>
        </Wrapper>
        <Wrapper>
          <ColumnFilter type='shortlist' />
          {isEdit ? (
            <>
              <ButtonComp
                style={{ marginRight: '2.5rem' }}
                onClick={handleAddKpvs}
              >
                + Add KPVs
              </ButtonComp>
              <ButtonComp
                style={{ marginLeft: '2.5rem', marginRight: '1.5rem' }}
                onClick={handleDiscard}
              >
                Discard
              </ButtonComp>
              <ButtonComp onClick={handleSave} dark={true}>
                save
              </ButtonComp>
            </>
          ) : (
            <>
              <Button type='button' onClick={handlePlot}>
                {localeStrings.plot || 'Plot'}
              </Button>
              {/* <SaveICon onClick={() => setShowSaveModal(true)}>Save</SaveICon> */}
              {myAnalyticsItemName && (
                <IconComp onClick={setEdit}>
                  <DynamicSVGIcon iconUrl={Edit} width='2.2rem' />
                </IconComp>
              )}
            </>
          )}
        </Wrapper>
      </HeadingBox>
      <TableContainer>
        <Row className='header'>
          {metaDetailsInShortlist?.fields?.map(
            (col, key) =>
              !metaDetailsInShortlist?.grouping?.includes(col.key) &&
              col?.visible && (
                <Cell
                  key={key}
                  width={col.width}
                  order={col.order}
                  border={col.name !== 'Action'}
                  className='colHeading'
                >
                  {localeStrings[col.title] || col.title}
                </Cell>
              )
          )}
          <Cell flex={0.5}>action</Cell>
        </Row>
        {KPVShortlist.length > 0 &&
          KPVShortlist.map((entry) => (
            <Row key={entry[metaDetailsInShortlist.UID]} className='active'>
              {metaDetailsInShortlist?.fields?.map(
                (col, key) =>
                  !metaDetailsInShortlist?.grouping?.includes(col.key) &&
                  col.visible && (
                    <Cell
                      key={key}
                      width={col.width}
                      order={col.order}
                      align={col.textAlign}
                      border={col.label !== 'Action'}
                      pointer={col.label === 'KPV'}
                    >
                      {/* {col.name !== 'Action' ? ( */}
                      {entry[col.key] || '-'}
                      {/* ) : (
                      <ActionIcon onClick={() => handleShortList(entry)}>
                        <RemoveFromPane />
                      </ActionIcon>
                    )} */}
                    </Cell>
                  )
              )}
              <Cell flex={0.5}>
                <ActionIcon onClick={() => handleShortList(entry)}>
                  <RemoveFromPane />
                </ActionIcon>
              </Cell>
            </Row>
          ))}
      </TableContainer>
      {/* {showSaveModal && <SaveShortListModal setShowSaveModal={setShowSaveModal} />} */}
      {showPlotOptModal && (
        <PlotOptionsModal setShowPlotOptModal={setShowPlotOptModal} />
      )}
      {/* create new myAnalytics */}
      {showSavedashboard && (
        <SaveAnalytics
          shouldSaveCharts={false}
          onCancel={() => setShowSavedashboard(false)}
        />
      )}
      {/* edit/new myAnalytics  */}
      {showSaveConfirmation && (
        <SaveAnalyticsChanges
          closeSaveConfirmationModal={closeSaveConfirmationModal}
          showSaveConfirmation={showSaveConfirmation}
          setShowSavedashboard={setShowSavedashboard}
          shouldSaveCharts={false}
        />
      )}
    </Container>
  );
};

export default KPVShortlistComp;
