import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 2rem;
  height: calc(100% - 9rem);
  overflow: auto;
  & .field-layout {
    display: flex;
    width: 300px;
  }
`;
