/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  PAGE_TYPES,
  REPEATED_WIDGETS,
  SHARED_LAYOUT,
  WIDGET_CATEGORIES,
  WIDGET_TYPES
} from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import ButtonComp from '../../Common/Button/Button.view';
import PageHeader from '../../Common/PageHeader/PageHeader';
import TabsList from '../../Common/TabsList/TabsList';
import TabsTable from '../../Common/Tabs_Table';
import Toast, { TOAST_TYPES } from '../../Common/Toast';
import Loader from '../../Loader/Loader';
import {
  defaultWidgetInstanceMetaDetails,
  widgetInstanceMetaDetails
} from '../../Widget/utils/contants';
import { useActions } from '../../../store/hooks/useActions';
import {
  getRepeatedWidgetsLayout,
  getWidgetLayoutObj
} from '../../Widget/utils/getLayoutData';
import { uid } from '../../../shared/utility';
import { getResolvedWidgetCategory } from '../../Widget/utils';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getWidgetPanelPayload } from '../../Widget/utils/getWidgetPanelPayload';
// import UpsertSaveHeader from '../UpsertSaveHeader';
// import AnalyticsInstances from './AnalyticsInstances/AnalyticsInstances';
// import DetaiStatusValueInstances from './DetaiStatusValueInstance/DetaiStatusValueInstances';
// import DonutInstances from './DonutInstances/DonutInstances';
// import StatusValueInstances from './StatusValueInstances/StatusValueInstances';
const defaultMeta = {
  columns: [
    {
      title: 'Instance Name',
      key: 'WidgetTemplateInstanceName',
      visible: true,
      options: []
    },
    { title: 'Created By', key: 'UserId', visible: true, options: [] },
    { title: 'Date of Creation', key: 'CreatedAt', visible: true, options: [] }
  ]
};
const WidgetTemplateInstances = (props) => {
  const rowOptionsInstance = [
    {
      id: 2,
      label: 'Preview',
      value: 'preview'
    },
    {
      id: 4,
      label: 'Global Share',
      value: 'globalShare'
    },

  ];

  const rowOptionsForGlobal = [
    {
      id: 2,
      label: 'Preview',
      value: 'preview'
    },
  ];
  const history = useHistory();
  const { location } = props;
  const [message, setMessage] = useState('');
  const [rowOptions, setRowOptions] = useState(rowOptionsInstance);
  //  history.push('/home/create-widget');
  const {
    widgetType,
    templateName,
    widgetCategory,

    widgetInstancesMeta,
    error: listError,
    loading: listLoading,
    WPId
  } = useSelector((state) => state.upsertWidgetData);
  const [activeRow, setActiveRow] = useState({});
  const [WidgetTemplateInstancesList, setWidgetTemplateInstancesList] =
    useState([]);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const layoutComponentData = useSelector(
    (state) => state.widgets.layoutComponentData
  );
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const templateLayoutData = useSelector((state) => state.upsertWidgetData.templateLayoutData);
  const templateLayoutMeta = useSelector((state) => state.upsertWidgetData.templateLayoutMeta);
  const layoutWPID = useSelector((state) => state.upsertWidgetData.layoutWPID);
  const userData = useSelector((state) => state.loginData.userData);
  const { WTId } = useSelector((state) => state.upsertWidgetData);
  const [enableInstance, setEnableInstance] = useState(false);
  const {
    // getWidgetInstances,
    // updateWidgetsLayout,
    addWidgetFailure,
    setBulkUpsertWidgetDetails,
    resetTemplateInstanceForm,
    setToastError,
    setTemplateLayoutData,
    resetWidgetPanelPayload,
    getPreviewTemplate,
    widgetDetailSetter,
    valueSetter,
  } = useActions();
  const onCreateNew = () => {
    widgetDetailSetter({
      key: 'WTIId',
      value: ''
    });
    resetTemplateInstanceForm();
    resetWidgetPanelPayload();
    history.push({
      pathname: '/home/template-instance',
      state: { isPreSelection: false }
    });
  };

  const tabs = [
    { label: 'My Instances', value: 'myInstances', id: 1 },
    // { label: 'Shared', value: 'Shared', id: 2 },
    { label: 'Global Instances', value: 'globalInstances', id: 3 }
  ];
  const [instanceType, setInstanceType] = useState(tabs[0]);
  useEffect(() => {
    // go to shared tab
    const { Shared } = location.state || {};
    if (Shared) {
      setInstanceType(tabs[1]);
    }
  }, [location]);
  const instanceTypeHandler = (item) => {
    setInstanceType(item);
    // setInstanceType(item);
  };

  const pageSize = 20;

  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const WidgetRoutes = widgetDashboardType === 'ALL' ? useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]) : useSelector((state) => state.home?.[PAGE_TYPES.WIDGET_INSTANCE]);
  // const WidgetRoutes = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const { repeatedWidgets, breakpoint, menuIdofWidget, layout } = useSelector(
    (state) => state.widgets
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onSuccessNavigation = () => {
    history.push(WidgetRoutes[menuIdofWidget]);
  };
  const handleBack = () => {
    onSuccessNavigation();
  };

  const handleAddToTab = async () => {
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    try {
      const newLayout = layout?.[breakpoint];
      const fullURL = `${apiEndPoints.getInstancePreview}/${activeRow?.WTIId}`;
      const response = await WebService.get(fullURL, {
        params: {
          UserId: userData.userId
        }
      });

      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        // console.log('layout', data[0]);
        if (data[0][0]) {
          const { WidgetPanelLayoutData } = data[0][0];
          // API not updating
          // const finalResponse = await WebService.post(`${apiEndPoints.updateWidgetPanel2}/${activeTab?.id}`, {
          //   InheritedWPID: WPId,
          //   WTIId: activeRow?.WTIId,
          //   WidgetPanelLayoutData
          // });
          const fullURL = apiEndPoints.updateWidgetPanel.replace(
            '{{WPID}}',
            activeTab?.id
          );
          const widgetPayload = getWidgetPanelPayload({
            menuId: menuIdofWidget,
            userId: userData.userId,
            isShareable: activeTab?.isShareable,
            widgetPanelName: activeTab?.label,
            widgetPanelLayoutData: WidgetPanelLayoutData,
            WTIId: activeRow?.WTIId,
            InheritedWPID: WPId
          });

          const finalResponse = await WebService.post(fullURL, widgetPayload);
          if (finalResponse.status >= 200 && finalResponse.status < 300) {
            valueSetter({ showTemplateListSidePanel: false });
            onSuccessNavigation();
          } else {
            throw finalResponse;
          }
        } else {
          throw response;
        }
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      setToastError(error);
    }
  };

  const getConfig = () => {
    if (instanceType.value === 'myInstances') {
      return {
        endpoint: `${apiEndPoints.getWidgetPanelTemplateInstances}/${WTId}`,
        config: {
          UserId: userData.userId
        }
      };
    }
    if (instanceType.value === 'Shared') {
      return {
        endpoint: apiEndPoints.GetGlobalOrSharedWidgetTemplateInstances,
        config: {
          UserId: userData.userId,
          IsGlobal: 0,
          WTId
        }
      };
    }
    return {
      endpoint: apiEndPoints.GetGlobalOrSharedWidgetTemplateInstances,
      config: {
        UserId: userData.userId,
        IsGlobal: 1,
        WTId
      }
    };
  };
  const handlePageChange = async (page) => {
    try {
      const { endpoint, config } = getConfig();
      setLoading(true);
      const resp = await WebService.get(endpoint, { params: config });
      if (resp.status >= 200 && resp.status < 300) {
        if (config?.IsGlobal) {
          setWidgetTemplateInstancesList(resp.data);
          setRowOptions(rowOptionsForGlobal);
        } else {
          setWidgetTemplateInstancesList(resp.data.data);
          setRowOptions(rowOptionsInstance);
        }
      } else {
        throw resp;
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('error', error);
      setError(error);
      setWidgetTemplateInstancesList([]);
    } finally {
      setLoading(false);
    }
  };

  const getLayoutConfig = async () => {
    if (WTId) {
      const endPoint = `${apiEndPoints.getWidgetPanelPreviewConfig}/${WTId}`;
      getPreviewTemplate({ WTId });
      try {
        const resp = await WebService.get(endPoint, {
          params: {
            UserId: userData.userId
          }
        });
        if (resp.status >= 200 && resp.status < 300) {
          const templateData = resp?.data?.data[0];
          if (templateData && !isEmpty(templateData.WidgetPanelLayoutData)) {
            setEnableInstance(true);
          }
          setTemplateLayoutData({
            WidgetPanelLayoutData: templateData.WidgetPanelLayoutData,
            WidgetPanelLayoutMetaData: templateData.WidgetPanelLayoutMetaData,
            WPID: templateData.WPID
          });
          // setTemplateLayoutData({})
        } else {
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('error', error);
        // console.log('Error caught');
      }
    }
  };

  useEffect(() => {
    getLayoutConfig();
  }, []);

  useEffect(() => {
    handlePageChange(1);
  }, [instanceType]);

  const populateDetails = (details) => {
    const { WidgetComponentData, WTIId, WidgetTemplateInstanceName } = details;
    const processedWidgetComponentData = typeof WidgetComponentData === 'string'
      ? JSON.parse(WidgetComponentData)
      : WidgetComponentData;
    setBulkUpsertWidgetDetails({
      templateInstanceFormInput: processedWidgetComponentData,
      instanceCreatorName: details?.UserId,
      WTIId,
      templateInstanceName: WidgetTemplateInstanceName
    });
  };
  const handleGlobalShare = async (details) => {
    try {
      const { WidgetComponentData, WTIId, WidgetTemplateInstanceName } =
        details;
      const res = await WebService.post(
        `${apiEndPoints.updateWidgetPanelTemplateInstance}/${WTIId}`,
        {
          WTId,
          WidgetTemplateInstanceName,
          UserId: userData.userId,
          WidgetComponentData,
          WPID: layoutWPID,
          IsGlobal: true
        }
      );
      if (res.status >= 200 && res.status < 300) {
        // route to main dashboard
        setMessage('Instance Shared Successfully');
      } else {
        throw res;
      }
    } catch (e) {
      const { error } = e;
      setToastError(error);
    }
  };

  const handleRowOptionsItemClick = async (opt, rowDetails, rowDetails2) => {
    // console.log('dp', opt, rowDetails);
    const { value } = opt;
    let { activeRowItem } = rowDetails;
    if (!activeRowItem) {
      activeRowItem = rowDetails2.activeRowItem;
    }
    populateDetails(activeRowItem);
    switch (value) {
      case 'preview':
        history.push({
          pathname: '/home/view-dashboard',
          state: { isTemplateInstance: true }
        });
        break;
      case 'globalShare':
        handleGlobalShare(activeRowItem);
        break;
      default:
        break;
    }
  };

  const clearError = () => {
    // console.log('clearing error');
    setError();
    addWidgetFailure('');
    setMessage('');
  };
  // console.log('templateName', templateName, WidgetTemplateInstancesList);

  useEffect(() => {
    if (!WTId) {
      handleBack();
    }
  }, [WTId]);
  return (
    <>
      <PageHeader handleBack={handleBack} title={templateName}>
        <ButtonComp
          onClick={onCreateNew}
          style={{ width: '22rem', marginRight: '1rem' }}
          // disabled={!enableInstance}
        >
          Add a new instance
        </ButtonComp>
        <ButtonComp disabled={!activeRow?.WTIId} dark onClick={handleAddToTab}>
          Add To Tab
        </ButtonComp>
      </PageHeader>
      <TabsList
        tabs={tabs}
        activeTab={instanceType}
        tabHandler={instanceTypeHandler}
        showBorder
        boxWidth='15rem'
      />
      {/* <div>{showInstances()}</div> */}
      {loading || listLoading ? (
        <Loader />
      ) : (
        <>
          <TabsTable
            metaDetails={{
              ...defaultMeta
              //   count: widgetInstancesMeta?.Count
            }}
            dataDetails={WidgetTemplateInstancesList}
            showPagiation={false}
            showShortlistSelection={false}
            rowOptions={rowOptions}
            // hasNestedRowOptions={true}
            handleRowOptionsItemClick={handleRowOptionsItemClick}
            handlePageChange={handlePageChange}
            id='WTIId'
            pageSize={pageSize}
            activeRow={activeRow}
            setActiveRow={setActiveRow}
          />
        </>
      )}
      {message && (
        <Toast
          header={TOAST_TYPES.SUCCESS}
          message={message}
          className='right'
          fn={clearError}
        />
      )}
      {(error || listError) && (
        <Toast
          message={error || listError}
          header='Error'
          className='right'
          fn={clearError}
        />
      )}
    </>
  );
};

export default WidgetTemplateInstances;
