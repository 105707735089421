/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { FlexContainer } from '../../../../globalStyles';
// import useClickOutside from '../../../../shared/hooks/useClickOutside';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import {
  Container
  // , DescriptionTextBox, FormContainer, HeaderText, LowOpacityText
} from './Annotations.styles';
import { Creators as kpvChartingCreators } from '../../Store/action';
import AnnotationsModal from './AnnotationModal';
import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getAnnotationPayload } from './utils';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import Loader from '../../../Common/Loader/Loader';

const AddAnnotationsForm = ({ index, pointClicked, setPointClicked, meta, chartType, chartTypeCategory }) => {
  // const [showBox, setShowBox] = useState(true);
  //   const [showModal, setShowModal] = useState(false);
  const [annotDesc, setAnnotDesc] = useState('');
  const { email, userId } = useSelector((state) => state.loginData.userData);
  const userid = email || userId;
  const annotationRef = useRef();
  const dispatch = useDispatch();

  const seriesKey = pointClicked?.seriesName?.split('-SensorData')?.[0];
  const label = meta?.[seriesKey]?.fields?.displayname;
  const closeModal = () => {
    setPointClicked({});
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAnnotDesc('');
  }, [pointClicked]);

  const handleSaveAnnotation = async () => {
    const annotationDetails = {
      kpvid: pointClicked?.kpvKey,
      seriesLabel: label || pointClicked.kpvLabel,
      argument: pointClicked?.argument,
      pointLabel: pointClicked?.pointLabel,
      text: annotDesc,
      seriesKey,
      userid,
      chartType,
      chartCategory: chartTypeCategory
    };
    setPointClicked({});
    try {
      setLoading(true);
      const response = await WebService.post(apiEndPoints.updateAnnotations, {
        ...getAnnotationPayload(annotationDetails),
        mode: 'insert'
      });
      if (response.status >= 200 && response.status < 300) {
        dispatch(
          kpvChartingCreators.setAnnotations({
            index,
            operation: 'add',
            value: annotationDetails
          })
        );
      } else {
        throw response;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('annotaton creation error', error);
      dispatch(kpvChartingCreators.getPlotDataFailure(error));
    }

    // console.log('response', response);
    setLoading(false);
    closeModal();
  };

  // useClickOutside(annotationRef, closeModal);

  if (isEmpty(pointClicked)) {
    return <></>;
  }

  return (
    <div>
      <Container left={pointClicked?.x} top={pointClicked?.y} ref={annotationRef} style={{ postiion: 'relative' }}>
        {loading && <Loader />}
        <AnnotationsModal
          argument={pointClicked?.argument}
          seriesLabel={label || `${seriesKey}=>${pointClicked.kpvLabel}`}
          closeHanlder={closeModal}
          annotDesc={annotDesc}
          setAnnotDesc={setAnnotDesc}
          handleSave={handleSaveAnnotation}
        />
      </Container>
    </div>
  );
};

export default AddAnnotationsForm;
