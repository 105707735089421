/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useMemo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { device, fontFamily, fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { ActiveDivider } from '../../../views/dashboard/FilterView/Filter.style';
import DropDownModal from '../DropDownModal/DropDownModal';
import { useWindowSize } from '../../../shared/hooks/useWindowResize';
import { isEmpty } from '../../../shared/utility/isEmpty';

const tabHorizontalPadding = 20;
const TabsList = ({ tabs, activeTab, tabHandler, showBorder = false, boxWidth, dropdown }) => {
  const tabListRef = useRef();
  const tabListRef2 = useRef();
  const [tabRange, setTabRange] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [width] = useWindowSize();
  useEffect(() => {
    if (tabListRef.current) {
      let endIndex = startIndex;
      // const tabsToShow = [];
      const availableWidth = tabListRef2.current.offsetWidth;
      let occupiedWidth = 0;
      const collection = tabListRef.current.children;
      for (let i = 0; i < collection.length; i += 1) {
        const e = collection[i];
        const currentWidth = e.offsetWidth + 2 * tabHorizontalPadding;
        occupiedWidth += currentWidth;
        if (occupiedWidth < availableWidth) {
          // tabsToShow.push(tabs[e.id]);
          endIndex += 1;
        } else {
          break;
        }
        // console.log('currentWidth', currentWidth, 'occupiedWidth', occupiedWidth, availableWidth, endIndex);
        // console.log(collection[i]);
      }
      setTabRange([startIndex, endIndex]);
    }
  }, [tabs, startIndex, width]);
  const len = tabs.length;
  const start = tabRange[0] ? tabRange[0] % len : 0;
  const end = tabRange[1] ? tabRange[1] % len : 0;

  const visibleMenu = useMemo(() => {
    if (start < end) {
      return [...tabs.slice(start, end)];
    }
    return [...tabs.slice(start), ...tabs.slice(0, end)];
  }, [tabRange]);

  const overflownMenu = useMemo(() => {
    if (start < end) {
      return [...tabs.slice(end), ...tabs.slice(0, start)];
    }
    return [...tabs.slice(end, start)];
  }, [tabRange]);

  const [showExtraTabMenu, setShowExtraTabMenu] = useState(false);

  const handleClick = ({ id }) => {
    const index = tabs.findIndex((e) => e.id === id);
    setStartIndex(index);
    tabHandler(tabs[index]);
    setShowExtraTabMenu(false);
  };

  return (
    <>
      {/* container to calculate the width occupied */}
      <TabListContainer className='hide' ref={tabListRef} showBorder={showBorder}>
        {tabs?.map((item, i) => {
          const { id, label } = item;
          return (
            <TabHeadingBox
              id={i}
              className='pointer'
              horPadding={tabHorizontalPadding}
              key={i}
              boxWidth={boxWidth}
              onClick={() => tabHandler(item)}
            >
              <TabHeadingText active={activeTab.id === id}>{label}</TabHeadingText>
              {activeTab.id === id && <ActiveDivider width='95%' />}
            </TabHeadingBox>
          );
        })}
      </TabListContainer>
      <TabListContainer ref={tabListRef2} showBorder={showBorder}>
        {visibleMenu?.map((item, i) => {
          const { id, label } = item;
          return (
            <TabHeadingBox
              id={i}
              className='pointer'
              horPadding={tabHorizontalPadding}
              key={i}
              boxWidth={boxWidth}
              onClick={() => tabHandler(item)}
            >
              <TabHeadingText active={activeTab.id === id}>{label}</TabHeadingText>
              {activeTab.id === id && dropdown}
              {activeTab.id === id && <ActiveDivider width='95%' />}
            </TabHeadingBox>
          );
        })}
      </TabListContainer>
      {!isEmpty(overflownMenu) && (
        <DropDownModal
          isActive={showExtraTabMenu}
          setModalIsVisibal={setShowExtraTabMenu}
          data={overflownMenu}
          ContainerStyle={{ left: 0, marginTop: '0.3rem', width: '10%' }}
          handleClick={handleClick}
          noContainerPadding
        >
          <ListBtn type='button' onClick={() => setShowExtraTabMenu((prev) => !prev)}>
            &gt;&gt;
          </ListBtn>
        </DropDownModal>
      )}
      {/* <SettingBtn /> */}
    </>
  );
};

export default TabsList;

const TabHeadingBox = styled.div`
  box-sizing: content-box;
  margin: ${({ horPadding }) => `0rem ${horPadding || 20}px -0.4rem`};
  position: relative;
  display: flex;
  min-width: 10rem;
  max-width: 20rem;
  height: 7rem;
  justify-content: center;
  align-items: center;
  text-overflow: hidden;
  white-space: nowrap;
  text-decoration: ellipsis;
  &:hover {
    background-color: ${({ theme }) => theme.aux.darkSecondary};
  }
  @media ${device.tablet} {
    margin: 0 10px 0 10px;
  }
`;

export const TabHeadingText = styled.p`
  /* width: 10rem; */
  // TODO: test
  min-width: max(100%, 10rem);
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: ${fontSize.headingText};
  color: ${({ theme, active }) => (active ? theme.contrast.primary : theme.contrast.secondary)};
  font-family: ${({ active }) => (active ? fontFamily.circularBold : fontFamily.circularBook)};
  @media ${device.tablet} {
    font-size: 15px;
  }

  &.widgetHeading {
    color: ${({ theme }) => theme.contrast.primary};
  }
`;

export const TabListContainer = styled(FlexContainer)`
  display: flex;
  overflow-x: auto;
  /* overflow-y: hidden; */
  min-height: 7rem;
  padding-bottom: 5px;
  border-bottom: 1px solid #70707080;
  border-bottom-width: ${({ showBorder }) => `${showBorder ? '1px' : '0px'}`};
  width: 100%;
  &.hide {
    position: absolute;
    top: 0px;
    min-height: 0px;
    height: 0px;
    background: red;
    overflow-x: hidden;
    min-height: 0px;
    height: 0px;
    width: 0px;
  }
`;

export const ListBtn = styled.button`
  border: none;
  background: #fff;
  padding: 1rem;
  border-radius: 1rem;
  transition: all 0.25s ease;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  :hover {
    box-shadow: none;
  }
`;
