import React from 'react';

function RemoveFromPane() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 48 48'>
      <g fill='red'>
        <path d='M24 45.063c-11.613 0-21.063-9.45-21.063-21.063S12.387 2.937 24 2.937 45.063 12.387 45.063 24 35.612 45.063 24 45.063zm0-38.297c-9.504 0-17.234 7.73-17.234 17.234 0 9.504 7.73 17.234 17.234 17.234 9.504 0 17.234-7.73 17.234-17.234 0-9.504-7.73-17.234-17.234-17.234zm0 0' />
        <path d='M31.66 25.914H16.34A1.912 1.912 0 0114.426 24c0-1.059.855-1.914 1.914-1.914h15.32c1.059 0 1.914.855 1.914 1.914a1.912 1.912 0 01-1.914 1.914zm0 0' />
      </g>
    </svg>
  );
}

export default RemoveFromPane;
