/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { getResolvedWidgetCategory } from '.';
import { uid } from '../../../shared/utility';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { setWidgetDimensions } from './setWigetDimensions';

export const getLayoutAsObj = (layout, key = 'WID', widgetOldKeyMap = {}) =>
  (layout || []).reduce((prev, curr) => {
    let entryKeyVal = curr[key];
    // console.log('Layout curr', curr);
    // if (key === 'WID') {
    //   const [WID] = curr.i.split('_');
    //   entryKeyVal = WID;
    // }
    if (key === 'WID') {
      const [WID] = curr.i.split('_');
      entryKeyVal = WID;
      if (widgetOldKeyMap[WID]) {
        entryKeyVal = widgetOldKeyMap[WID];
      }
    }
    if (prev[entryKeyVal]) {
      return prev; // stopping override
    }
    return {
      ...prev,
      [entryKeyVal]: curr
    };
  }, {});

export const getRepeatedWidgetsLayout = (layout, repeatedWidgets) => {
  const layoutObj = getLayoutAsObj(layout, 'i');
  // console.log('layoutObj', layoutObj);

  // updated layout positions data of the repeated widgets
  return repeatedWidgets?.map((rw) => {
    const { i } = rw;
    // console.log('RW', rw, i);
    return layoutObj[i];
  });
};

export const getWidgetLayoutObj = (WidgetComponentData, checkIndex, WID) => {
  const { chartTypeCategory = '', type, widgetCategory } = WidgetComponentData;
  // TODO_W_RR: change to widget category
  const { w, h } = setWidgetDimensions(getResolvedWidgetCategory(widgetCategory, type), {
    chartCategory: chartTypeCategory
  });
  // add it first
  return { w, h, i: `${WID}_${checkIndex}`, y: 1000000, x: 0 };
};

export const updateWidgetId = (obj, id, WID) => ({ ...obj, i: `${WID}_${id}` });

/* eslint-disable max-len */
export const getWidgetLayoutData = (componentData, layout, repeatedWidgets, isUpdated, existingLayoutData) => {
  let currentLayout = [];
  // if (!layout?.length) {
  //   currentLayout = [];
  // } else {
  //   currentLayout = [...layout];
  // }
  const widgetTypesIncluded = [];
  const widgetOldKeyMap = {};
  // converting componentdata from array to obj
  const formattedComponentData = isEmpty(componentData) ? [] : componentData;
  const layoutComponentData = (formattedComponentData || [])?.reduce((prev, e) => {
    const { WID, WidgetComponentData: WidgetComponentDataReceived, WidgetName, PreviousWID } = e;
    const WidgetComponentData = typeof (WidgetComponentDataReceived) === 'string' ? JSON.parse(WidgetComponentDataReceived) : WidgetComponentDataReceived;
    if (PreviousWID) {
      widgetOldKeyMap[PreviousWID] = WID;
    }
    widgetTypesIncluded.push(WidgetComponentData.type);
    return {
      ...prev,
      [WID]: {
        id: WID,
        WidgetName,
        PreviousWID,
        // ...WidgetComponentData
        // WidgetComponentData: {
        ...WidgetComponentData,
        formData: isUpdated ? existingLayoutData[WID]?.formData : WidgetComponentData?.formData
        // }
      }
    };
  }, {});
  const componentEntries = Object.entries(layoutComponentData);
  const componentsLen = componentEntries.length;
  // TODO: removing layout if no widgets
  if (componentsLen < 1 && currentLayout?.length) {
    currentLayout = [];
  }

  // arr to obj with id as key
  const checkLayoutObj = getLayoutAsObj(layout, 'WID', widgetOldKeyMap);
  // new approch
  let checkIndex = 0;
  while (checkIndex < componentsLen) {
    // replace the new addition made (if any)
    const [WID, WidgetComponentData] = componentEntries[checkIndex];
    // add if the layout for that widget is not created
    if (isEmpty(checkLayoutObj[WID])) {
      const newLayoutObj = getWidgetLayoutObj(WidgetComponentData, uid(), WID);
      currentLayout.push(newLayoutObj);
    } else {
      // currentLayout.push({...checkLayoutObj[WID], i: });

      // update i to handle duplicate wid's in layout data
      const newLayoutObj = updateWidgetId(checkLayoutObj[WID], uid(), WID);
      currentLayout.push(newLayoutObj);
    }
    checkIndex += 1;
  }
  // TODO: repeatedWidgets layout needs to be added into currentLayout here
  // old
  // test 2
  // const newRepeatedWidgets = [];
  // for (let k = 0; k < repeatedWidgets?.length; k += 1) {
  //   const [WID, t] = repeatedWidgets[k]?.i?.split('_') || [];
  //   console.log('repeatedWidgets[k]?.i', repeatedWidgets[k], repeatedWidgets[k]?.i, WID, t, 'din', checkIndex + k);
  //   newRepeatedWidgets.push({ ...repeatedWidgets[k], i: `${WID}_${uid()}` });
  // }
  // currentLayout = [...currentLayout, ...newRepeatedWidgets];
  // console.log('====currentLayout=========', currentLayout, 'checkLayoutObj', checkLayoutObj, newRepeatedWidgets);
  // return { layoutComponentData, currentLayout, newRepeatedWidgets };

  // new: appending repeatedWidgets
  currentLayout = [...currentLayout, ...(repeatedWidgets || [])];
  // console.log('====currentLayout=========', currentLayout, 'checkLayoutObj', checkLayoutObj, repeatedWidgets);
  return { layoutComponentData, currentLayout, widgetTypesIncluded: new Array(...new Set(widgetTypesIncluded)) };
};

/**
 * This function is used only in case of home dashboard where Uilayout will take preference over widgetcomponent data
 * @param {*} componentData
 * @param {*} layout
 * @param {*} repeatedWidgets
 * @param {*} isUpdated
 * @param {*} existingLayoutData
 * @returns
 */
export const getWidgetLayoutDataForHome = (componentData, layout, repeatedWidgets, isUpdated, existingLayoutData) => {
  let currentLayout = [];
  const widgetTypesIncluded = [];
  const widgetOldKeyMap = {};

  // converting componentdata from array to obj
  const formattedComponentData = isEmpty(componentData) ? [] : componentData;
  const layoutComponentData = (formattedComponentData || [])?.reduce((prev, e) => {
    const { WID, UILayoutData, WidgetComponentData: WidgetComponentDataReceived, WidgetName, PreviousWID, Url, WidgetTemplateInstanceName = '' } = e;
    const WidgetComponentData = !isEmpty(UILayoutData) ? typeof (UILayoutData) === 'string' ? JSON.parse(UILayoutData) : UILayoutData : typeof (WidgetComponentDataReceived) === 'string' ? JSON.parse(WidgetComponentDataReceived) : WidgetComponentDataReceived;
    if (PreviousWID) {
      widgetOldKeyMap[PreviousWID] = WID;
    }
    widgetTypesIncluded?.push(WidgetComponentData?.type);
    return {
      ...prev,
      [WID]: {
        id: WID,
        WidgetName,
        Url,
        PreviousWID,
        WidgetTemplateInstanceName,
        ...WidgetComponentData,
        formData: isUpdated ? existingLayoutData[WID]?.formData : WidgetComponentData?.formData
      }
    };
  }, {});
  const componentEntries = Object.entries(layoutComponentData);
  const componentsLen = componentEntries.length;

  // TODO: removing layout if no widgets
  if (componentsLen < 1 && currentLayout?.length) {
    currentLayout = [];
  }

  // arr to obj with id as key
  const checkLayoutObj = getLayoutAsObj(layout, 'WID', widgetOldKeyMap);
  // new approch
  let checkIndex = 0;
  while (checkIndex < componentsLen) {
    // replace the new addition made (if any)
    const [WID, WidgetComponentData] = componentEntries[checkIndex];
    // add if the layout for that widget is not created
    if (isEmpty(checkLayoutObj[WID])) {
      const newLayoutObj = getWidgetLayoutObj(WidgetComponentData, uid(), WID);
      currentLayout.push(newLayoutObj);
    } else {
      // update i to handle duplicate wid's in layout data
      const newLayoutObj = updateWidgetId(checkLayoutObj[WID], uid(), WID);
      currentLayout.push(newLayoutObj);
    }
    checkIndex += 1;
  }

  // new: appending repeatedWidgets
  currentLayout = [...currentLayout, ...(repeatedWidgets || [])];
  // console.log('====currentLayout=========', currentLayout, 'checkLayoutObj', checkLayoutObj, repeatedWidgets);
  return { layoutComponentData, currentLayout, widgetTypesIncluded: new Array(...new Set(widgetTypesIncluded)) };
};

/**
 * This is a wrapper function written For Home dashbaord
 * To convert the format on InstanceWidgets as of Widgets
 * @param {*} InstanceWidgets
 * @returns
 */
export const getInstanceWidgetsAsWidgets = (InstanceWidgets) => {
  const modifiedWidgetArray = [];
  InstanceWidgets?.forEach((widget) => {
    const modifiedIndividualWidget = {
      ...widget?.Widgets,
      UILayoutData: widget?.UILayoutData,
      Url: widget?.Url,
      WidgetTemplateInstanceName: widget?.WidgetTemplateInstanceName
    };
    modifiedWidgetArray.push(modifiedIndividualWidget);
  });
  return modifiedWidgetArray;
};
