export const graphPriorityOrder = { bar: 1, line: 2, scatter: 3 };

export const getSortedSeriesObjs = (seriesList, seriesTypesSelections) => {
  if (!seriesList) {
    return [];
  }
  // sorts seriesobjs
  const newSeriesObjs = [...seriesList];
  newSeriesObjs.sort((a, b) => {
    const aType = seriesTypesSelections[a]?.key;
    const bType = seriesTypesSelections[b]?.key;
    // sort condition
    return graphPriorityOrder?.[aType] - graphPriorityOrder?.[bType];
  });
  return newSeriesObjs;
};
