/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Div, FlexContainer, Image } from '../../globalStyles';
import Radiobutton from '../Common/RadioButton/RadioButton';
import { Table, Td, Tr, Th, Anchor } from './AnaltyticsTable.styles';
import kababSvg from '../../assets/icons/Filter/kabab.svg';
import { Creators as filterCreators } from '../../views/dashboard/FilterView/store';
import {
  Creators as FilterResultCreators,
  Creators as KPVCreators
} from '../ResultView/Store/index';
import { Creators as kpvCreators } from '../KPVCharts/Store/index';
import { myAnalyticsCreators } from '../../views/MyAnalytics/store';
import { Creators as LayoutCreators } from '../../views/Layout/store';
// import { myAnalytics } from '../../constants/sampleData';
import { populateFilter } from '../Filter/populateFilterItems';
import Loader from '../Common/Loader/Loader';
import { Tag, Thead } from '../../views/MyDashboards/MyDashboards.style';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import DropDownModal from '../Common/DropDownModal/DropDownModal_V1';
import { myAnalyticsData } from '../../constants/dashboardsData';
import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { PAGE_TYPES } from '../../constants';
import UpdateAnalytics from './SaveAnalyticsModal/EditAnalytics';
import Toast from '../Common/Toast';
import { isEmpty } from '../../shared/utility/isEmpty';
import { isCaseInsensitiveMatchFound } from '../../shared/utility/isItemMatched';
import ConfirmationModal from '../Common/ConfirmationBox/ConfirmationBox.view';
import SortIcon from '../Common/SortIcon';
import { compare } from '../../shared/utility/objComparision';
import { Creators as kpvChartingCreators } from '../KPVCharts/Store/action';
import { getPlotOptions } from '../KPVCharts/utils/getPlotOptions';

const AnalyticsTable = ({ searchValue, setSearchValue }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const filterPaths = useSelector((state) => state.home?.[PAGE_TYPES.FILTER]);
  const userData = useSelector((state) => state.loginData.userData);
  // const { filterData, menuIdofFilter } = useSelector((state) => state.filterData);
  const [isFilterDataUpdated, setIsFilterDataUpdated] = useState(false);
  const [machineIds, setMachineIds] = useState([]);
  const [kpvs, setKpvs] = useState([]);
  const {
    analyticsData,
    myAnalytics: orgData,
    loading,
    error
  } = useSelector((state) => state.myAnalytics);
  const { myAnalyticsItemId, menuId } = analyticsData;
  const [panesInfo, setPanesInfo] = useState([]);
  const [sortBy, setSortBy] = useState('');
  // can be either orgData or filteredData
  const [data, setData] = useState([...orgData]);
  // will be sorted through data only
  const [sortedData, setSortedData] = useState(data);
  // const [sortOrder, setSortOrder] = useState(0);

  useEffect(() => {
    setData(orgData);
  }, [orgData]);
  const sortData = () => {
    if (sortBy) {
      setSortedData([...data].sort((e1, e2) => compare(e1, e2, sortBy, 'inc')));
    }
  };

  useEffect(() => {
    sortData();
  }, [data, sortBy]);
  // const [showLoader, setShowLoader] = useState(false);
  const columnsDetails = [
    {
      label: 'Title',
      name: 'Name',
      width: '40%',
      align: 'left',
      isSortable: true
    },
    // {
    //   label: 'Category',
    //   name: 'Category',
    //   isSortable: true
    // },
    {
      label: 'SearchTags',
      name: 'SearchTags'
    },
    {
      label: 'KPV Shortlist',
      name: 'kpvShortlist'
    },
    {
      label: 'Chart',
      name: 'chart'
    },
    {
      label: 'Shared',
      name: 'Shared',
      isSortable: true
    }
  ];

  const getAnalytics = useCallback(() => {
    dispatch(
      myAnalyticsCreators.getAnalytics({
        UserId: userData.email || userData.mail
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // if (!myAnalytics?.length)
    // dispatch(myAnalyticsCreators.getAnalytics({ UserId: userData.email || userData.mail }));
    // dispatch(filterCreators.resetFilterState());
    getAnalytics();
    if (myAnalyticsItemId) {
      dispatch(myAnalyticsCreators.resetAnalyticsData());
      // dispatch(filterCreators.resetFilterState());
      dispatch(FilterResultCreators.resetKpvDetails());
    }
  }, []);

  const [activeAnalyticId, setActiveAnalyticId] = useState(-1);

  const toggleMenuDropdown = (dashbord) => {
    // convert the payload to make it common to create dashboard
    const { Id: id, Name, SearchTags, ...restDetails } = dashbord;
    const editDetailsPayload = {};
    if (id) {
      dispatch(
        myAnalyticsCreators.setAnalyticsDetails({
          form: { ...restDetails, Searchtags: SearchTags, Title: Name, Id: id },
          isInit: true
        })
      );
    }
    setActiveAnalyticId(id >= 0 ? id : -1);
  };
  const closeMenu = () => {
    setActiveAnalyticId(-1);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfModal, setShowDeleteConfModal] = useState(false);
  const dashBoardDeleteInit = {
    loading: false,
    successMsg: '',
    errMsg: '',
    id: -1
  };
  const [dashboardDelete, setDashboardDelete] = useState(dashBoardDeleteInit);
  const closeDeleteConfModal = () => {
    setShowDeleteConfModal(false);
  };
  const handleDelete = async () => {
    try {
      setDashboardDelete({ ...dashboardDelete, loading: true });
      const url = apiEndPoints.CallDeleteSavedAnalytics;
      const response = await WebService.post(url, {
        Id: dashboardDelete.id,
        // UserID: 'lino.thomas@vistrian.com'
        UserID: userData.email || userData.mail
      });
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        setDashboardDelete({
          ...dashboardDelete,
          loading: false,
          successMsg: data
        });
        closeDeleteConfModal();
        getAnalytics();
      } else {
        throw response;
      }
    } catch (error) {
      const payload = sagaCatchBlockHandling(error);
      setDashboardDelete({
        ...dashboardDelete,
        loading: false,
        errMsg: payload
      });
      console.log('err', payload);
    }
  };

  const handleClick = (item) => {
    switch (item.value) {
      case 'editAnalytics':
        setShowEditModal(true);
        break;
      case 'printAnalytics':
        break;
      case 'deleteAnalytics':
        setShowDeleteConfModal(true);
        // handleDelete(activeAnalyticId);
        setDashboardDelete({ ...dashboardDelete, id: activeAnalyticId });
        break;
      default:
        break;
    }
  };

  const getMachineId = (filterData) => {
    if (filterData?.values) {
      return getMachineId(filterData?.values[0]);
    }
    return filterData.id;
  };
  const fetchAnalyticsSummary = async (analytics) => {
    const { Id, Name, IsDeletable: isEditable } = analytics;
    try {
      const analyticsSummaryResp = await WebService.get(
        `${apiEndPoints.analyticsSummary}/${Id}`
      );
      if (
        analyticsSummaryResp.status >= 200 &&
        analyticsSummaryResp.status < 300
      ) {
        const {
          menuid,
          filterData: filterSelections,
          kpvDetails,
          chartDetails,
          tab
        } = analyticsSummaryResp.data.data;
        dispatch(
          myAnalyticsCreators.setAnalyticsDetails({
            form: {
              myAnalyticsItemId: Id,
              myAnalyticsItemName: Name,
              isEditable,
              selectedParent: tab,
              menuId: menuid,
              filterSelections,
              kpvDetails,
              chartDetails
            },
            isInit: false
          })
        );
      }
      return analyticsSummaryResp;
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      console.log('error', err);
      return err;
    }
  };

  const populateReducers = async (analyticsSummaryResp) => {
    const {
      kpvDetails,
      chartDetails,
      xMinDomain,
      xMaxDomain,
      xDomainDuration,
      menuid,
      latestAvailable
    } = analyticsSummaryResp.data.data;
    const { metaDetailsInShortlist, KPVShortlist } = kpvDetails;
    dispatch(filterCreators.resetFilterState());
    dispatch(LayoutCreators.setActiveMenu({ Id: menuid }));
    dispatch(
      FilterResultCreators.setKPVShortlistDetails({
        metaDetailsInShortlist,
        KPVShortlist,
        menuId: menuid
      })
    );
    if (isEmpty(chartDetails)) {
      dispatch(kpvCreators.resetPanesData());
      // eslint-disable-next-line no-throw-literal
      throw { customMsg: 'No Charts Saved' };
    }
    const chartDetailsObj = {
      panesShortHandInfo: chartDetails,
      xMinDomain,
      xMaxDomain,
      latestAvailable
    };
    if (metaDetailsInShortlist.enabledPanes) {
      chartDetailsObj.chartOptions = getPlotOptions(
        metaDetailsInShortlist.enabledPanes,
        kpvDetails.KPVShortlist
      );
    }
    if (xDomainDuration !== 'custom' && !isEmpty(xDomainDuration)) {
      const { shorthand, shorthandValue } = xDomainDuration;
      chartDetailsObj.xMaxDomain = new Date();
      chartDetailsObj.xMinDomain = new Date(
        moment().subtract(shorthandValue, shorthand)
      );
    }
    dispatch(kpvCreators.setChartDetails(chartDetailsObj));
  };

  const setIsLoading = () => {
    dispatch(myAnalyticsCreators.setIsLoading());
  };

  const setError = (msg) => {
    dispatch(myAnalyticsCreators.setError(msg));
  };

  const location = useLocation();
  const viewChart = async (analytic) => {
    setIsLoading();
    try {
      const { Id, Name } = analytic;
      // setShowLoader(true);
      const analyticsSummaryResp = await fetchAnalyticsSummary(analytic);
      if (
        analyticsSummaryResp.status >= 200 &&
        analyticsSummaryResp.status < 300
      ) {
        populateReducers(analyticsSummaryResp);
        // const { kpvDetails, chartDetails, xMinDomain, xMaxDomain } = analyticsSummaryResp.data.data;
        // const { metaDetailsInShortlist, KPVShortlist } = kpvDetails;
        // if (isEmpty(chartDetails)) {
        //   // eslint-disable-next-line no-throw-literal
        //   throw { customMsg: 'No Charts Saved' };
        // }
        // dispatch(FilterResultCreators.setKPVShortlistDetails({ metaDetailsInShortlist, KPVShortlist }));
        // dispatch(
        //   kpvCreators.setChartDetails({
        //     panesShortHandInfo: chartDetails,
        //     xMinDomain,
        //     xMaxDomain
        //   })
        // );
        history.push({
          pathname: `/home/kpv-charting/${Id}`,
          state: { previousPath: location.pathname }
        });
      } else {
        throw analyticsSummaryResp;
      }
    } catch (error) {
      const { customMsg } = error;
      let payload;
      if (!customMsg) {
        payload = sagaCatchBlockHandling(error);
        setError(payload.error);
      } else {
        setError(customMsg);
      }
      console.log('error', payload);
    }
  };

  const handleViewShortlist = async (analytic) => {
    setIsLoading();
    try {
      const analyticsSummaryResp = await fetchAnalyticsSummary(analytic);
      // console.log('analyticsSummaryResp', analyticsSummaryResp);
      // const filterURL = `${apiEndPoints.getFilterCategory}`;
      if (
        analyticsSummaryResp.status >= 200 &&
        analyticsSummaryResp.status < 300
      ) {
        // const { kpvDetails, chartDetails, xMinDomain, xMaxDomain } = analyticsSummaryResp.data.data;
        // if (isEmpty(kpvDetails)) {
        //   // eslint-disable-next-line no-throw-literal
        //   throw { customMsg: 'No KPVs shortlisted' };
        // }
        // const { metaDetailsInShortlist, KPVShortlist } = kpvDetails;
        // dispatch(FilterResultCreators.setKPVShortlistDetails({ metaDetailsInShortlist, KPVShortlist }));
        populateReducers(analyticsSummaryResp);
        history.push({
          pathname: '/home/kpv-shortlist',
          state: { previousPath: location.pathname }
        });
      } else {
        throw analyticsSummaryResp;
      }
    } catch (error) {
      const { customMsg } = error;
      let payload;
      if (!customMsg) {
        payload = sagaCatchBlockHandling(error);
        setError(payload.error);
      } else {
        setError(customMsg);
      }
    }

    // needed for kpv list
    // const filterDataOfMenuResp = await WebService.get(filterURL, { params: { menuId: menuid } });
    // if (filterDataOfMenuResp.status >= 200 && filterDataOfMenuResp.status < 300) {
    //   // console.log('2', filterDataOfMenuResp.data.data);
    //   const filterPayload = { menuid, data: filterSelections };

    //   // populate filter dashboard selections
    //   const { cumTags, cumSelectedObj } = populateFilterDashboard(filterPayload, filterDataOfMenuResp.data.data);
    //   const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
    //   dispatch(filterCreators.populateFilterData({ ...payload, menuIdofFilter: menuid }));

    //   // fetch filterCategory data
    //   history.push({
    //     pathname: filterPaths[menuid],
    //     goDirectToResult: true,
    //     resType: filterDataOfMenuResp.data.resultType
    //   });

    //   const { data: filtData, resultType } = filterDataOfMenuResp.data;
    //   const { formatedData, keyTitleMapping } = formatedFilterData(filtData);
    //   dispatch(
    //     filterCreators.getFilterDataSuccess({
    //       data: formatedData,
    //       resultType,
    //       keyTitleMapping,
    //       menuId
    //     })
    //   );
    // }
  };

  const handleTagClick = (tag) => {
    if (!searchValue.includes(tag)) {
      setSearchValue(`${searchValue} ${tag}`.trim());
    }
  };

  // const [data, setData] = useState([...myAnalytics]);
  // search results
  const isTitleMatched = (title) =>
    isCaseInsensitiveMatchFound(title, searchValue);
  const isCatgegoryMatched = (category) =>
    category && isCaseInsensitiveMatchFound(category, searchValue);
  const areTagsMatched = (SearchTags) => {
    let searchValueDup = searchValue.trim();
    if (searchValueDup.charAt(searchValueDup.length - 1) === ',') {
      searchValueDup = searchValueDup.slice(0, searchValueDup.length - 1);
    }
    return searchValueDup
      .replace(',', ' ')
      .split(' ')
      .every((e) => {
        if (!e) {
          return true;
        }
        return (
          e &&
          SearchTags.split(',').some((tag) =>
            isCaseInsensitiveMatchFound(tag, e)
          )
        );
      });
  };

  useEffect(() => {
    if (searchValue.trim()) {
      const filteredDataDup = orgData.filter((i) => {
        const { Name, SearchTags, Category } = i;
        return (
          isTitleMatched(Name) ||
          isCatgegoryMatched(Category) ||
          areTagsMatched(SearchTags || '')
        );
      });
      setData(filteredDataDup);
    } else {
      setData([...orgData]);
    }
  }, [searchValue]);
  return (
    <>
      <Div px='2' mt='2' style={{ width: '100%', position: 'relative' }}>
        <Table>
          <Thead>
            <tr>
              {columnsDetails.map((col) => (
                <Th
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (col.isSortable) {
                      setSortBy(sortBy === col.name ? '' : col.name);
                    }
                  }}
                  border
                >
                  <FlexContainer
                    className='jc_center ai_center'
                    style={{ fontSize: '1.6rem' }}
                  >
                    {col.label}
                    {col.isSortable && (
                      <SortIcon isActive={sortBy === col.name} />
                    )}
                  </FlexContainer>
                </Th>
              ))}
              <Th />
            </tr>
          </Thead>
          <tbody>
            {(sortBy ? sortedData : data).map((dashbord, index) => {
              const {
                Id: id,
                DashboardName: title,
                Category: category,
                SearchTags: searchTags,
                Owner,
                Shared: shared,
                IsDeletable,
                ids,
                menuid,
                tab,
                IsChartable
              } = dashbord;
              // const isChartable = 'false';

              return (
                <Tr key={index}>
                  {columnsDetails.map((col) => (
                    <Td width={col.width} align={col.align}>
                      {(() => {
                        switch (col.name) {
                          case 'Shared':
                            return (
                              <Radiobutton
                                margin='0 auto'
                                active={dashbord[col.name]}
                              />
                            );
                          case 'kpvShortlist':
                            return (
                              <Anchor
                                onClick={() => handleViewShortlist(dashbord)}
                              >
                                View
                              </Anchor>
                            );
                          case 'chart':
                            return (
                              <Anchor
                                className={
                                  IsChartable === false ||
                                  IsChartable === 'false'
                                    ? 'disable'
                                    : ''
                                }
                                onClick={() => viewChart(dashbord)}
                              >
                                View
                              </Anchor>
                            );
                          case 'SearchTags':
                            return (
                              <FlexContainer border className='tags'>
                                {searchTags?.split(',').map((tag) => (
                                  <Tag
                                    className={!tag ? 'noBg' : ''}
                                    key={tag}
                                    onClick={() => handleTagClick(tag)}
                                  >
                                    {tag || 'NA'}
                                  </Tag>
                                ))}
                              </FlexContainer>
                            );
                          default:
                            return dashbord[col.name];
                        }
                      })()}
                    </Td>
                  ))}

                  <Td>
                    <div
                      style={{ position: 'relative' }}
                      className={`pointer ${
                        IsDeletable === 'False' ? 'disable' : ''
                      }`}
                      onClick={() => toggleMenuDropdown(dashbord)}
                    >
                      <DynamicSVGIcon iconUrl={kababSvg} width='2.2rem' />
                      <DropDownModal
                        data={myAnalyticsData}
                        isActive={activeAnalyticId === id}
                        setModalIsVisibal={closeMenu}
                        handleClick={handleClick}
                        ContainerStyle={{ top: 20, right: 0, width: 'auto' }}
                      />
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
          {showEditModal && (
            <UpdateAnalytics
              showEditModal={showEditModal}
              onCancel={() => setShowEditModal(false)}
              getAnalytics={getAnalytics}
            />
          )}
          <ConfirmationModal
            showModal={showDeleteConfModal}
            loading={dashboardDelete.loading}
            handleDelete={handleDelete}
            closeModalHandler={closeDeleteConfModal}
            message='Do you really want to delete the Analytics?'
          />
          {/* {showEditModal && <SaveAnalytics onCancel={() => setShowEditModal(false)} />} */}
        </Table>
      </Div>
      {loading && <Loader position='fixed' />}
      {error && (
        <Toast
          style={{ bottom: 30, position: 'fixed' }}
          message={error}
          header='error'
          fn={() => setError('')}
        />
      )}
    </>
  );
};

export default AnalyticsTable;
