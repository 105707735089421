/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CHART_TYPES, INPUT_TYPES } from '../../constants';
import { useActions } from '../../store/hooks/useActions';
import DropDownModal from '../Common/DropDownModal/DropDownModal';
import InputDropdown from '../Common/DropDownModal/InputDropdown';
import CollapsableContainerView from './CollapsableContainer/CollapsableContainer.view';
import {
  CheckBoxContainer,
  KPVItemContainer,
  RadioContainer,
  RadioIcon,
  VerticalDiv
} from './CustomiseKpv/CustomiseKpv.style';
import { ReactComponent as CheckMark } from '../../assets/icons/KPV/checkmark.svg';
import { Creators as UpsertPaneCreators } from './Store';
import { Span } from '../../globalStyles';

const XTypeSelection = (props) => {
  const { isXSelection, opt } = props;
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const upsertPaneData = useSelector((state) => state.upsertPaneData);
  const { multiSelectKpv, chartType, chartTypeCategory, xType, yType } = upsertPaneData;
  const propertyKey = opt.type;
  const machinesShortlisted = useSelector((state) => state.KPVCharts.machinesShortlisted);
  const machinesSelected = upsertPaneData?.[propertyKey] || [];
  const xyMappingsObj = useSelector((state) => state.filterResultData.xyMappings);
  const { xyMappings, xSets, ySets } = xyMappingsObj?.[chartTypeCategory] || {};
  const isSingleSelect = isXSelection || !multiSelectKpv;
  const xOptions = useMemo(() => {
    let xOptions = Object.keys(xyMappings || {})?.reduce((acc, xSet) => {
      const items = xSets[xSet].filter((x) => {
        const res = machinesShortlisted.some((y) => y.kpvId === x.kpvId);
        return res;
      });
      return [...acc, ...(items || [])];
    }, []);
    // if empty xOptions
    // get xOptions from yOptions
    if (isEmpty(xOptions)) {
      // getting the y-sets from y selections
      const possibleYsets =
        !isEmpty(ySets) &&
        Object.values(ySets).filter((entries) =>
          entries?.some((e) => machinesShortlisted.some((x) => e.kpvId === x?.kpvId))
        );
      // getting x-sets from above
      const possibleXSets =
        (!isEmpty(xyMappings) &&
        Object.entries(xyMappings).filter((xyEntry) => {
          const [xSet, ySet] = xyEntry;
          return ySet?.some((e) => possibleYsets.some((x) => x.kpvId === e.kpvId));
        })) || [];
      xOptions = possibleXSets?.reduce((acc, xyMapping) => {
        const [xKey, yKeys] = xyMapping;
        return [...acc, ...(xSets[xKey] || [])];
      }, []);
    }
    return xOptions;
  }, [xyMappingsObj, machinesShortlisted]);

  // all the entries having the selected xType
  const belongingXSets = !isEmpty(xSets)
    ? Object.entries(xSets).filter((xEntry) => {
        const [key, entries] = xEntry;
        return entries?.some((e) => e.kpvId === xType[0]?.kpvId);
      })
    : [];
  let yOptions = belongingXSets?.reduce(
    (acc, e) => [...acc, ...(xyMappings[e[0]]?.reduce((acc, e) => [...acc, ...(ySets[e] || [])], []) || [])],
    []
  );
  if (!isEmpty(yOptions)) {
    // filter yOptions based on the selections
    const fiteredYoptions = yOptions.filter((e) => machinesShortlisted.some((x) => e.kpvId === x?.kpvId));
    if (!isEmpty(fiteredYoptions)) {
      yOptions = fiteredYoptions;
    }
  }
  // console.log('xOptions', xOptions, yOptions);
  const { setUpsertPaneData } = useActions();

  const setXType = (item) => {
    setUpsertPaneData({
      key: 'xType',
      value: item
    });
  };
  const handleXTypeSelection = (item) => {
    console.log('item', item);
    setXType(item);
  };
  const kpvs = isXSelection ? xOptions : yOptions;
  const dispatch = useDispatch();
  let property = 'kpvId';
  // setXType(item);
  switch (chartTypeCategory || chartType) {
    case CHART_TYPES.DATA_CHART:
      property = 'kpvId';
      break;
    case CHART_TYPES.TRANSITION_CHART:
      // property = 'macId';
      property = 'kpvId';
      break;
    default:
      break;
  }
  const handleSelectMachine = (ele) => {
    const isAlreadySelected = machinesSelected?.find((machines) => machines[property] === ele[property]);
    if (multiSelectKpv) {
      if (isAlreadySelected) {
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: propertyKey,
            value: machinesSelected?.filter(
              (machines) => machines[property] !== ele[property]
              // (machines) => machines.machineId !== ele.machineId && machines.kpvId !== ele.kpvId
            )
          })
        );
      } else {
        dispatch(
          UpsertPaneCreators.setUpsertPaneData({
            key: propertyKey,
            value: [...machinesSelected, ele]
          })
        );
      }
    } else if (isAlreadySelected) {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: propertyKey,
          value: []
        })
      );
    } else {
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: propertyKey,
          value: [ele]
        })
      );
    }
  };
  // set default option on mount
  if (!isXSelection && isEmpty(yOptions)) {
    return (
      <CollapsableContainerView
        header={localeStrings[opt.label] || opt.label}
        showKPVMultiSelectToggleIMP={!isXSelection}
      >
        <Span>Please Select X-Axis first</Span>
      </CollapsableContainerView>
    );
  }
  // console.log('machinesSelected', machinesSelected);
  return (
    <CollapsableContainerView
      header={localeStrings[opt.label] || opt.label}
      showKPVMultiSelectToggleIMP={!isXSelection}
    >
      {kpvs.map((ele, index) => (
        // console.log('e', ele, ele.kpvId, property, ele?.[property]);
        <KPVItemContainer key={index}>
          {isSingleSelect ? (
            <RadioContainer className='pointer' onClick={() => handleSelectMachine(ele)}>
              {(machinesSelected || [])?.find((machine) => machine?.[property] === ele?.[property]) && <RadioIcon />}
            </RadioContainer>
          ) : (
            <CheckBoxContainer
              onClick={() => handleSelectMachine(ele)}
              className={`${
                (machinesSelected || [])?.find((machine) => machine?.[property] === ele?.[property]) ? 'active' : ''
              } pointer`}
            >
              <CheckMark className='' />
            </CheckBoxContainer>
          )}
          <div>
            <div>
              <span>{ele.machineName}</span>
              <VerticalDiv>|</VerticalDiv>
              <span>{ele.kpvName}</span>
            </div>
          </div>
        </KPVItemContainer>
      ))}
    </CollapsableContainerView>
  );
};

export default XTypeSelection;
