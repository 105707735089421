/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FlexContainer } from '../../globalStyles';
import PopUpModal from '../../component/Common/PopUpModal/Modal.view';
import {
  BtnWrapper,
  Button,
  Heading
} from '../../component/Common/PopUpModal/Modal.style';
import { Footer } from '../../component/KPVCharts/PaneCard/AdditionalFilter/FilterPopUp.styles';
import { Input } from '../../component';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { WebService } from '../../services/axios/webServices';
import { useActions } from '../../store/hooks/useActions';
import { apiEndPoints } from '../../services/axios/endPoints';
import { REPEATED_WIDGETS, SHARED_LAYOUT } from '../../constants';
import { getWidgetLayoutData } from '../../component/Widget/utils/getLayoutData';
import { isEmpty } from '../../shared/utility/isEmpty';
import ConfirmationModal from '../../component/Common/ConfirmationBox/ConfirmationBox.view';
import { concatStringWithUnderscore } from './utils/functions';
import { Edit } from '../../assets/icons/KPV';
import { Clone, Share, View, settingsBlack } from '../../assets/icons';
import TabsTable from '../../component/Common/Tabs_Table';
import { Creators as myDashboardsCreators } from '../MyDashboards/store';
import { Container } from '../../component/HomeDashboard/WidgetInstancesList';

const DashboardTableForInstance = () => {
  const pageSize = 20;

  const rowOptionsWithCondition = {
    // Row options Available for User who Created the instance and already instance is a Global
    myDashboardMenu: [
      { label: 'Edit', value: 'edit', iconUrl: Edit },
      { label: 'View', value: 'pop', iconUrl: View },
      { label: 'Configure', value: 'configure', iconUrl: settingsBlack },
      { label: 'Clone', value: 'clone', iconUrl: Clone },
    ],

    // Row options Available for User who Created the instance and Instance is My Instance
    myDashboardMenuGlobal: [
      { label: 'Edit', value: 'edit', iconUrl: Edit },
      { label: 'View', value: 'pop', iconUrl: View },
      { label: 'Configure', value: 'configure', iconUrl: settingsBlack },
      { label: 'Clone', value: 'clone', iconUrl: Clone },
      { label: 'Global Share', value: 'global', iconUrl: Share }
    ],

    // Row options Available for User who can only view the instance
    globalDashboardMenu: [{ label: 'View', value: 'pop', iconUrl: View }, { label: 'Clone', value: 'clone', iconUrl: Clone }]
  };

  const userData = useSelector((state) => state.loginData.userData);
  const { instanceData, instanceMeta, loading } = useSelector((state) => state.myDashboards);
  const searchQueriesReducer = useSelector((state) => state.widgets.searchQueries);
  const WidgetComponentDataFromReducer = useSelector((state) => state.widgets.WidgetComponentData);

  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [editInstance, setEditInstance] = useState();
  const [instanceName, setInstanceName] = useState();
  const [activeInstance, setActiveInstance] = useState();
  const [instanceDescription, setInstanceDesc] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCloneConfirmationModal, setShowCloneConfirmationModal] = useState(false);
  const [showShareGlobalConfirmation, setShowShareGlobalConfirmation] = useState(false);

  const [localPaginatedData, setLocalPaginatedData] = useState([]);
  const [localFilteredData, setLocalFilteredData] = useState([]);
  const [totalFiltered, settotalFiltered] = useState([]);
  const [forcedPushtoFirstPage, setForcedPushToFirstPage] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { setToastError, setBulkUpsertWidgetDetails, widgetDetailSetter, setSearchQueriesWidgets } = useActions();

  useEffect(() => {
    setData([...instanceData]);
  }, [instanceData]);

  /**
   * Function to be called on forward and backward on pagination
   * @param {*} page
   * @param {*} searchQueries
   */
  const handlePageChange = (page, searchQueries, makeApiCall) => {
    setCurrPage(page);
    if (!(instanceMeta?.LocalPagination === 'true') || makeApiCall) {
      const endpoint = apiEndPoints.getWidgetInstancesList;
      dispatch(myDashboardsCreators.getInstanceDashboard({
        page,
        pageSize,
        endpoint,
        searchQueries,
        UserId: userData?.userId
      }));
    } else {
      const slicedData = instanceData?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      setLocalPaginatedData(slicedData);
    }
  };

  useEffect(() => {
    if (localFilteredData?.length > 0) {
      const slicedData = totalFiltered?.slice((currPage - 1) * pageSize, (currPage - 1) * pageSize + pageSize);
      setLocalFilteredData(slicedData);
    }
  }, [currPage, instanceData]);

  const handlePageChangeLocal = (localFilteredDataNew) => {
    const slicedData = localFilteredDataNew?.slice(0, pageSize);
    setLocalFilteredData(slicedData);
    settotalFiltered(localFilteredDataNew);
  };

  /**
   *Function to make an instance globally shared
   * @param {*} activeRow Receives instance item clicked
   */
  const handleGlobalShare = async (activeRow) => {
    setShowShareGlobalConfirmation(false);
    try {
      const res = await WebService.post(
        `${apiEndPoints.updateWidgetPanelTemplateInstance}/${activeRow?.WTIId}`,
        {
          IsGlobal: true
        }
      );
      if (res.status >= 200 && res.status < 300) {
        handlePageChange(1, searchQueriesReducer, true);
        setForcedPushToFirstPage(true);
      } else {
        throw res;
      }
    } catch (e) {
      const { error } = e;
      setToastError(error);
    }
  };

  /**
   * Function to call preview instance api and fill the required reducer data when going to configure page
   * @param {*} WTIId receives the instance id
   * Sets required dependent data to the reducer
   */
  const getPreviewDetails = async (activeRow) => {
    if (activeRow?.WTIId) {
      const res = await WebService.get(
        `${apiEndPoints.getInstancePreview}/${activeRow?.WTIId}`,
        {
          params: {
            UserId: userData?.userId
          }
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const { data } = res.data;
        if (data[0][0]) {
          const { WidgetPanelLayoutData, Widgets, WidgetComponentData, TemplateParameters, WTIId, TabType, InheritedWPID, WidgetTemplateInstanceName = '',
            WidgetTemplateInstanceDescription = '', description = '' } =
              data[0][0];

          const templateInstanceParams = {
            WidgetComponentData,
            WTIId,
            TabType,
            InheritedWPID
          };
          const { layoutComponentData: layCompData, currentLayout, widgetTypesIncluded } = getWidgetLayoutData(
            Widgets,
            WidgetPanelLayoutData[SHARED_LAYOUT],
            WidgetPanelLayoutData[REPEATED_WIDGETS],
            false,
            {}
          );
          setBulkUpsertWidgetDetails({
            templateFormInput: TemplateParameters?.formInput || {},
            templateInstanceFormInput: WidgetComponentData,
          });
          widgetDetailSetter({
            key: 'widgetTypesIncluded',
            value: TemplateParameters?.widgetTypesIncluded,
          });
          setBulkUpsertWidgetDetails({
            instanceCreatorName: activeRow?.UserId,
            WTIId: activeRow?.WTIId,
            templateInstanceName: activeRow?.WidgetTemplateInstanceName
          });

          setTimeout(() => {
            history.push({
              pathname: '/home/view-dashboard',
              state: { isTemplateInstance: true, isConfigurableDashboard: true }
            });
          }, 100);
        }
      }
      // setLoadingLayout(false);
    }
  };

  /**
   * Function to handle popup view of the instance
   * @param {any} activeRow receives the instance id which has t be sent as param to api call
   * @returns pops a window loading the instance dashboard
   */
  const handlePopUp = (activeRow) => {
    const currentLocation = window.location.pathname;
    const componentsURL = currentLocation?.split('/home');
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    window.open(
      `${componentsURL[0]}/home/view-dashboard/${activeRow?.WTIId}?instace_name=${concatStringWithUnderscore(activeRow?.WidgetTemplateInstanceName || ' ')}`,
      'popUpWindow',
      'height=800,width=1600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
    );
  };

  /**
   *Funtion to open configurable instance view
   * @param {*} activeRow receives entire instance item
   * pushes to view-dashboard page of widgets
   */
  const handleConfigurableView = (activeRow) => {
    getPreviewDetails(activeRow);
  };

  /**
   *Function to clone an instance directly from global dashboard
   * @param {*} activeRow  receives entire instance item
   * duplicates instance in dashboard list
   */
  const handleClone = async (activeRow) => {
    setShowCloneConfirmationModal(false);
    try {
      const fullUrl = `${apiEndPoints.cloneInstance}`;
      const res = await WebService.post(fullUrl, {}, {
        params: {
          WTIId: activeRow?.WTIId,
          UserId: userData.userId
        }
      });
      if (res.status >= 200 && res.status < 300) {
        setShowEditModal(false);
        handlePageChange(1, searchQueriesReducer, true);
        setForcedPushToFirstPage(true);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
  };

  /**
   *Function to update name and description of the instance from dashboard
   * @param {*} editInstance receives the instance item that has to be edited
   * updates the instance
   */
  const updateInstance = async (editInstance) => {
    try {
      const fullUrl = `${apiEndPoints.instanceTitleUpdate}/${editInstance?.WTIId}`;
      const res = await WebService.post(fullUrl, {
        WidgetTemplateInstanceName: instanceName,
        WidgetTemplateInstanceDescription: instanceDescription
      });
      if (res.status >= 200 && res.status < 300) {
        setShowEditModal(false);
        handlePageChange(currPage, searchQueriesReducer, true);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setToastError(error);
    }
  };

  /**
   * Reducer action to store the search queries
   * @param {*} payload
   */
  const saveSearchQueries = (payload) => {
    setSearchQueriesWidgets(payload || {});
  };

  useEffect(() => {
    handlePageChange(1, {}, true);
    return () => {
      saveSearchQueries();
    };
  }, []);

  useEffect(() => {
    if (instanceMeta?.LocalPagination === 'true') {
      handlePageChange(currPage);
    }
  }, [instanceMeta?.LocalPagination, instanceData]);

  /**
   * Function to handle click of dropdown menu
   * @param {*} item item that is clicked with value
   * @param {*} instance instance item that has been clicked.
   * calls the respective function of menu item click
   */
  const handleClick = (item, instanceItem) => {
    const instance = instanceItem?.activeRowItem;
    switch (item.value) {
      case 'edit':
        setShowEditModal(true);
        setEditInstance(instance);
        setInstanceName(instance?.WidgetTemplateInstanceName);
        setInstanceDesc(instance?.WidgetTemplateInstanceDescription);
        break;
      case 'pop':
        handlePopUp(instance);
        break;
      case 'configure':
        handleConfigurableView(instance);
        break;
      case 'clone':
        setShowCloneConfirmationModal(true);
        setActiveInstance(instance);
        break;
      case 'global':
        setShowShareGlobalConfirmation(true);
        setActiveInstance(instance);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {/* <Container> */}
      <div style={{ width: '100%', padding: '1rem' }}>
        <TabsTable
          metaDetails={instanceMeta}
          dataDetails={instanceMeta?.LocalPagination === 'true' ? localPaginatedData : instanceData}
          fullData={instanceData}
          handlePageChangeLocal={handlePageChangeLocal}
          setLocalFilteredData={setLocalFilteredData}
          localFilteredData={localFilteredData}
        // showShortlistSelection={true}
          hasObjectContent={true}
          assetListLoading={loading}
          onItemSelect={() => {}}
          showPagiation={true}
          rowOptionsWithCondition={rowOptionsWithCondition}
          handlePageChange={handlePageChange}
          styleType='strapedTable'
          noItemsFoundMessage='No Data Found'
          saveSearchQueries={saveSearchQueries}
          savedSearchQueries={searchQueriesReducer}
          resetSearchQueries={saveSearchQueries}
          id='WTIId'
          handleRowOptionsItemClick={handleClick}
          isActionReq={false}
          pageSize={pageSize}
          forcedPushtoFirstPage={forcedPushtoFirstPage}
          setForcedPushToFirstPage={setForcedPushToFirstPage}
        />
      </div>

      {showEditModal && (
        <PopUpModal
          width='50%'
          closeModalHandler={setShowEditModal}
          disableOutsideClick={true}
        >
          <Heading>{`Edit ${editInstance?.WidgetTemplateInstanceName}`}</Heading>
          <FlexContainer
            className='ai_center jc_spacebetween'
            style={{ marginBottom: '1rem' }}
          >
            <p style={{ fontSize: '1.6rem' }}>Instance Name</p>
            <Input
              placeholder='Instance Name'
              style={{
                backgroundColor: 'transparent',
                width: '25rem',
                minWidth: '25rem'
              }}
              ContainerStyle={{ marginBottom: '0' }}
              value={instanceName}
              handleInputChange={(e) => {
                setInstanceName(e.target.value);
              }}
              maxLength={200}
            />
          </FlexContainer>
          <FlexContainer
            className='ai_center jc_spacebetween'
            style={{ marginBottom: '1rem' }}
          >
            <p style={{ fontSize: '1.6rem' }}>Instance Description</p>
            <Input
              placeholder='Instance Description'
              style={{
                backgroundColor: 'transparent',
                width: '25rem',
                minWidth: '25rem'
              }}
              ContainerStyle={{ marginBottom: '0' }}
              value={instanceDescription}
              handleInputChange={(e) => {
                setInstanceDesc(e.target.value);
              }}
              maxLength={256}
            />
          </FlexContainer>
          <Footer style={{ marginTop: '3rem' }}>
            <BtnWrapper style={{ width: '40%', alignItems: 'flex-end' }}>
              <Button
                type='button'
                style={{
                  boxShadow:
                      '-5px -5px 19px rgba(255,255,255,0.1), 3px 3px 10px rgba(0,0,0,0.1)'
                }}
                onClick={() => {
                  setShowEditModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                className='dark'
                disabled={isEmpty(instanceName) || (
                  instanceName === editInstance?.WidgetTemplateInstanceName &&
                    instanceDescription ===
                      editInstance?.WidgetTemplateInstanceDescription)}
                onClick={() => {
                  updateInstance(editInstance);
                }}
              >
                Submit
              </Button>
            </BtnWrapper>
          </Footer>
        </PopUpModal>
      )}
      <ConfirmationModal
        showModal={showCloneConfirmationModal}
        action="Confirm"
        Header="Clone"
        handleDelete={() => { handleClone(activeInstance); }}
        closeModalHandler={() => {
          setActiveInstance({});
          setShowCloneConfirmationModal(false);
        }}
        message='Click Confirm to Clone the Instance?'
      />
      <ConfirmationModal
        showModal={showShareGlobalConfirmation}
        action="Confirm"
        Header="Global Share"
        handleDelete={() => { handleGlobalShare(activeInstance); }}
        closeModalHandler={() => {
          setActiveInstance({});
          setShowShareGlobalConfirmation(false);
        }}
        message='Click Confirm to Globally Share the Instance ?'
      />
      {/* </Container> */}
    </>
  );
};

export default DashboardTableForInstance;
