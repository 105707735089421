/* eslint-disable indent */
/* eslint-disable no-useless-concat */
/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckBox,
  ContentPadding,
  GridButton,
  GridOptContainer,
  Input,
  ItemContainer,
  Radio,
  RadioIcon,
  SidePanelContainer,
  SubItemContainer
} from './UpsertPane.style';
import { ReactComponent as CheckMark } from '../../assets/icons/KPV/checkmark.svg';
import CollapsableContainer from './CollapsableContainer/CollapsableContainer.view';
import { Creators as UpsertPaneCreators } from './Store';
import { Creators as kpvChartingCreators } from '../KPVCharts/Store/action';
import CustomiseKpv from './CustomiseKpv/CustomiseKpv.view';
import ScaleYAxis from './ScaleYAxis/ScaleYAxis.view';
// import { upsertOptions } from '../../constants/sampleData';
import TransitionChartType from './TransitionChartType/TransitionChartType.view';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../constants';
import Loader from '../Loader/Loader';
import useDebounce from '../../shared/hooks/useDebounce';
import { isEmpty } from '../../shared/utility/isEmpty';
import ShowOverlays from './ShowOverlays/ShowOverlays';
import ShowAlarmsView from './ShowAlarms/ShowAlarms.view';
import { getOverlayDataPoints } from '../KPVCharts/utils/getOverlayDataPoints';
import { Span } from '../../globalStyles';
import XTypeSelection from './XTypeSelection';
import SeriesTypes from './SeriesTypes';
import ChartTypeView from './ChartType/ChartTypeView';

const SidePanel = () => {
  const {
    paneTitle,
    showDetails,
    multiAxis,
    multiGrid,
    labelsData,
    chartType,
    chartTypeCategory,
    machinesSelected,
    showOverlays,
    showAlarms,
    panesData
  } = useSelector((state) => state.upsertPaneData);
  const meta = panesData[0]?.meta;
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const upsertPaneOptions = useSelector((state) => state.KPVCharts.upsertPaneOptions);
  const optionsError = useSelector((state) => state.KPVCharts.optionsError);
  const optionsLoading = useSelector((state) => state.KPVCharts.optionsLoading);

  const dispatch = useDispatch();

  const handleTitleInput = (value) => {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'paneTitle',
        value
      })
    );
  };

  const handleDetailsSelect = (value) => {
    const key = value === 'annotatedPts' ? 'annotations' : value;
    dispatch(
      UpsertPaneCreators.setShowDetails({
        key,
        value: !showDetails[key]
      })
    );
  };
  const handleOverlays = ({ key, value }) => {
    if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
      let data;
      if (showOverlays?.[key]) {
        data = {
          ...showOverlays
        };
        delete data[key];
      } else {
        data = {
          ...showOverlays,
          [key]: value
        };
      }
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'showOverlays',
          value: data
        })
      );
      // moved this to spcChart.view
      // const { data: overlayData } = getOverlayDataPoints(data, meta, false, {
      //   orgDataLength: panesData[0]?.data.length
      // });
      // dispatch(
      //   UpsertPaneCreators.setUpsertPaneData({
      //     key: 'overlaysData',
      //     value: overlayData
      //   })
      // );
      return;
    }
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'showOverlays',
        value: {
          ...showOverlays,
          [value]: showOverlays?.limits === false
        }
      })
    );
  };

  const handleAlarms = ({ key, value }) => {
    if (chartTypeCategory === CHART_TYPE_CATEGORY.SPC) {
      let data = {};
      if (showAlarms?.[key]) {
        data = {
          ...showAlarms
        };
        delete data[key];
      } else {
        data = {
          ...showAlarms,
          [key]: value
        };
      }
      dispatch(
        UpsertPaneCreators.setUpsertPaneData({
          key: 'showAlarms',
          value: data
        })
      );
      // moved to spcGraphWrapper
      // const { data: overlayData } = getOverlayDataPoints(data, meta, true, {
      //   orgDataLength: panesData[0]?.data.length
      // });
      // dispatch(
      //   UpsertPaneCreators.setUpsertPaneData({
      //     key: 'alarmsData',
      //     value: overlayData
      //   })
      // );
    }
  };

  const handleAxisNo = (value) => {
    // let check = true;
    // if (value && machinesSelected.length < 2) {
    //   check = false;
    // }
    // if (check) {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'multiAxis',
        value
      })
    );
    // }
  };
  useEffect(() => {
    if (!upsertPaneOptions[chartTypeCategory]) {
      dispatch(
        kpvChartingCreators.setPaneOptions({
          key: 'upsertPaneOptions',
          chartType: chartTypeCategory || chartType
        })
      );
    }
    // if (labelsData.length > 1) {
    //   dispatch(
    //     UpsertPaneCreators.setUpsertPaneData({
    //       key: 'multiGrid',
    //       value: true
    //     })
    //   );
    // } else {
    //   dispatch(
    //     UpsertPaneCreators.setUpsertPaneData({
    //       key: 'multiGrid',
    //       value: false
    //     })
    //   );
    // }
  }, []);

  const handleGrid = (value) => {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'multiGrid',
        value
      })
    );
  };

  const gridOptions = [
    { label: 'Single Grid', value: false },
    { label: 'Multi Grid', value: true }
  ];

  const [paneTitleLocal, setPaneTitleLocal] = useState(paneTitle);
  useDebounce(paneTitleLocal, handleTitleInput);

  const isLimitsEnabled = useMemo(() => {
    const KPVs = !isEmpty(meta) && Object.keys(meta);
    if (KPVs?.length > 0) {
      let isEnabled = meta[KPVs[0]]?.fields?.Limit?.length > 0;
      // eslint-disable-next-line no-empty
      if (KPVs.length === 1) {
      } else if (!(KPVs.length > 1 && multiAxis && multiGrid)) {
        isEnabled = false;
      }
      return isEnabled;
    }
    return false;
  }, [meta, multiAxis, multiGrid]);
  return (
    <SidePanelContainer>
      {optionsLoading && <Loader />}
      {!optionsLoading && <Span>{optionsError}</Span>}
      {!optionsLoading &&
        upsertPaneOptions?.[chartTypeCategory]?.map((opt) => {
          switch (opt.type) {
            case 'paneTitle':
              return (
                <CollapsableContainer
                  header={
                    (localeStrings.Pane || 'Pane') +
                    ' ' +
                    (localeStrings.Title || 'Title')
                  }
                >
                  <Input
                    type='text'
                    value={paneTitleLocal}
                    onChange={(e) => setPaneTitleLocal(e.target.value)}
                    placeholder='Enter pane title'
                  />
                </CollapsableContainer>
              );
            // case 'transitionCharts':
            //   return <TransitionChartType menu={opt} />;
            case 'xType':
              return <XTypeSelection opt={opt} isXSelection />;
            case 'yType':
              return <XTypeSelection opt={opt} isXSelection={false} />;
            case 'customiseKpvs':
              return (
                <CustomiseKpv
                  header={localeStrings[opt.label] || opt.label}
                  value={opt.value}
                />
              );
            case 'showDetails':
              return (
                <CollapsableContainer
                  header={localeStrings[opt.label] || opt.label}
                >
                  {opt.subMenu.map((ele, index) => {
                    const { label, value } = ele;
                    const key =
                      value === 'annotatedPts' ? 'annotations' : value;
                    return (
                      <ItemContainer
                        key={index}
                        onClick={() => handleDetailsSelect(key)}
                        className='pointer'
                      >
                        <CheckBox className={showDetails[key] ? 'active' : ''}>
                          <CheckMark />
                        </CheckBox>
                        {localeStrings[label] || label}
                      </ItemContainer>
                    );
                  })}
                </CollapsableContainer>
              );
            case 'showOverlays':
              return (
                <CollapsableContainer
                  header={localeStrings[opt.label] || opt.label}
                >
                  {/* <ItemContainer className={isLimitsEnabled ? '' : 'disable'} onClick={() => handleOverlays('limits')}>
                    <CheckBox className={showOverlays?.limits === false ? '' : 'active'}>
                      <CheckMark className='' />
                    </CheckBox>
                    Limits
                  </ItemContainer> */}
                  <ContentPadding>
                    <ShowOverlays
                      chartTypeCategory={chartTypeCategory}
                      meta={meta}
                      showOverlays={showOverlays}
                      handleSelect={handleOverlays}
                      isLimitsEnabled={false}
                    />
                  </ContentPadding>
                </CollapsableContainer>
              );
            case 'showAlarms':
              return (
                <CollapsableContainer
                  header={localeStrings[opt.label] || opt.label}
                >
                  {/* <ItemContainer className={isLimitsEnabled ? '' : 'disable'} onClick={() => handleOverlays('limits')}>
                      <CheckBox className={showOverlays?.limits === false ? '' : 'active'}>
                        <CheckMark className='' />
                      </CheckBox>
                      Limits
                    </ItemContainer> */}
                  <ContentPadding>
                    <ShowAlarmsView
                      chartTypeCategory={chartTypeCategory}
                      meta={meta}
                      showOverlays={showOverlays}
                      showAlarms={showAlarms}
                      handleSelect={handleAlarms}
                      isLimitsEnabled={false}
                    />
                  </ContentPadding>
                </CollapsableContainer>
              );
            case 'yAxis-settings':
              return (
                <CollapsableContainer
                  header={localeStrings[opt.label] || opt.label}
                >
                  {opt.subMenu.map((ele, index) => {
                    const { label, value } = ele;
                    return (
                      <React.Fragment key={index}>
                        <ItemContainer>
                          <Radio
                            className='pointer'
                            onClick={() => handleAxisNo(value)}
                          >
                            {multiAxis === value && <RadioIcon />}
                          </Radio>
                          {localeStrings[label] || label}
                        </ItemContainer>
                        <SubItemContainer>
                          {value && multiAxis && (
                            <GridOptContainer>
                              {gridOptions.map((opt, index) => (
                                <GridButton
                                  key={index}
                                  className={
                                    multiGrid === opt.value ? 'active' : ''
                                  }
                                  onClick={() => handleGrid(opt.value)}
                                >
                                  {localeStrings[opt.label] || opt.label}
                                </GridButton>
                              ))}
                            </GridOptContainer>
                          )}
                        </SubItemContainer>
                      </React.Fragment>
                    );
                  })}
                </CollapsableContainer>
              );
            case 'seriesTypes':
              return <SeriesTypes opt={opt} />;
            case 'scale-yAxis':
              return (
                <ScaleYAxis
                  header={localeStrings[opt.label] || opt.label}
                  scaleYaxisOpt={opt.subMenu}
                />
              );
            case 'chartType':
              return (
                <ChartTypeView
                  header={localeStrings[opt.label] || opt.label}
                  ChartTypeView={opt.subMenu}
                />
              );
            default:
              break;
          }
          return <></>;
        })}
    </SidePanelContainer>
  );
};

export default SidePanel;
