/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import { SelectedWidgetDiv } from '../../../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import { WIDGET_CATEGORIES } from '../../../../constants';
import Analytics from '../Analytics/Analytics';
import UpsertOEEDonutView from '../../../UpsertWidget/WidgetsComponents/OeeDonut/UpsertOEEDonut.view';
import UpsertStatusValueWidget from '../../../UpsertWidget/WidgetsComponents/StatusValue/UpsertStatusValueWidget.view';
import DetailedStatusValueWidget from '../../../UpsertWidget/WidgetsComponents/DetailedStatusValue/DetailedStatusValueWidget.view';
import FloorPlan from '../FloorPlan/FloorPlan';
import EquipmentPlan from '../EquipmentPlan/EquipmentPlan';
import FloorPlanWidget from '../../../UpsertWidget/WidgetsComponents/FloorPlan/FloorPlan.view';
import { getResolvedWidgetCategory } from '../../utils';
import Index from '../..';
import { CancelBlack, close } from '../../../../assets/icons';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';

export const widgetComponentMapping = {
  //  TODO_W_R: Change all these from WIDGET_TYPES to WIDGET_CATEGORIES
  [WIDGET_CATEGORIES.ANALYTICS]: Analytics,
  [WIDGET_CATEGORIES.OEE_DONUT]: UpsertOEEDonutView,
  [WIDGET_CATEGORIES.STATUS_VALUE]: UpsertStatusValueWidget,
  [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: DetailedStatusValueWidget,
  [WIDGET_CATEGORIES.FLOOR_PLAN]: FloorPlan,
  [WIDGET_CATEGORIES.EQUIPMENT_PLAN]: EquipmentPlan,
  [WIDGET_CATEGORIES.FLOOR_PLAN]: FloorPlanWidget,
};

const FullViewWidget = ({ WID, setShowFullView, fullView, componentData }) => {
  const layoutComponentData = useSelector(
    (state) => state.widgets.layoutComponentData
  );
  const currentCompData = layoutComponentData[WID];
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const loading = useSelector((state) => state.upsertWidgetData.loading);

  const layout = useSelector((state) => state.widgets.layout);

  let index;
  let layOutPos;
  layout?.[breakpoint]?.forEach((l, i) => {
    const layWid = l.i?.split('_')?.[0];
    if (layWid === WID) {
      index = i;
      layOutPos = l;
    }
  });

  const closeModuleHandler = () => {
    setShowFullView(false);
  };

  return (
    <PopUpModal style={{ maxHeight: '75%', minHeight: '40%', overflow: 'auto', zIndex: '20', backgroundClip: 'border-box' }} width="80%" height={`${((layOutPos?.h / 10) * 100) + 20}%`} closeModalHandler={closeModuleHandler}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ fontSize: '1.8rem',
          width: '45%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        >
          {currentCompData?.WidgetName}
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            closeModuleHandler(false);
          }}
        >
          <div>
            <DynamicSVGIcon iconUrl={CancelBlack} width='10px' />
          </div>

        </div>
      </div>

      {/* <RespectiveWidgetComponent isEdit={false} /> */}
      <div style={{ height: '96%' }}>
        <Index fullView={fullView} i={index} layoutPos={layOutPos} WIDFromProp={WID} componentData={layoutComponentData?.[WID] || componentData} />
      </div>
    </PopUpModal>
  );
};

export default FullViewWidget;
