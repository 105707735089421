/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActiveDivider, Container,
  HeaderText,
  HeadingBox,
  HeadingContainer,
  SearchContainer,
} from '../MyDashboards/MyDashboards.style';
import SearchInput from '../../component/Common/SearchBar/SearchBar';
import DashboardTableForInstance from './DashboardTableForInstance';
import Pagination from '../../component/Common/Pagination/Pagination.view';
import { Creators as myDashboardsCreators } from '../MyDashboards/store';
import { useActions } from '../../store/hooks/useActions';

const InstanceDashboardsView = () => {
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);

  const { valueSetter, widgetDetailSetter } = useActions();

  useEffect(() => {
    valueSetter({ showInstanceTablePopUp: false });
    valueSetter({ showTemplateListSidePanel: false });
    widgetDetailSetter({
      key: 'showSidePanel',
      value: false
    });
  }, []);

  return (
    <Container>
      <HeadingContainer>
        <HeadingBox>

          <>
            <HeaderText>{localeStrings['Instance Dashboards'] || 'Instance Dashboard'}</HeaderText>
            <ActiveDivider />
          </>

        </HeadingBox>
      </HeadingContainer>
      {/* <DashboardTable getDashboards={getDashboards} searchValue={searchValue} setSearchValue={setSearchValue} /> */}
      <DashboardTableForInstance />
    </Container>
  );
};

export default InstanceDashboardsView;
