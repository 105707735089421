/* eslint-disable max-len */
import React from 'react';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Search from '../../../assets/icons/Filter/Search white.svg';
import { Container, Icon, Input } from './SearchBar.styles';

function SearchBar(props) {
  const { searchValue, onChangeHandler, placeholder } = props;
  const handleChange = (e) => {
    onChangeHandler(e.target.value);
  };

  return (
    <Container {...props}>
      <Input
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChange}
        onFocus={() => null}
        onKeyUp={() => null}
      />
      <Icon>
        <DynamicSVGIcon iconUrl={Search} />
      </Icon>
    </Container>
  );
}

export default SearchBar;
