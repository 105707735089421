/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { Header, HeaderMenu, HeadingContent } from './HomeDashboard';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import { Kabab } from '../../assets/icons/KPV';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal';
import addIcon from '../../assets/icons/Filter/plus.svg';
import editIcon from '../../assets/icons/KPV/Chart menu_edit pane.svg';
import WidgetDashBoard from './HomeWidgetDashboard';
import { WidgetContainer } from '../Widgets/Widget.style';
import { useActions } from '../../store/hooks/useActions';
import ButtonComp from '../../component/Common/Button/Button.view';
import { MAX_WIDGETS_IN_HOME } from '../../constants';
import ConfirmationModal from '../../component/Common/ConfirmationBox/ConfirmationBox.view';

const HomeDashboarview = () => {
  const history = useHistory();

  const { setEditWidget, getHomeLayout, HomeLayoutUpdate, resetWidgetPanelPayload, widgetDetailSetter, HomeAllWidgetsUpdate, setDisableEditWidget } = useActions();

  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const userData = useSelector((state) => state.loginData.userData);
  const HDId = useSelector((state) => state.HomeDashboardReducer.HDId);
  const widgetPayload = useSelector((state) => state.widgets.widgetPayload);
  const widgetPanelPayload = useSelector((state) => state.widgets.widgetPanelPayload);
  const layoutComponentData = useSelector((state) => state.HomeDashboardReducer.layoutComponentData);

  const [showExtraTabMenu, setShowExtraTabMenu] = useState(false);
  const [showMaxError, setshowMaxError] = useState(false);
  const homeMenu = [{ label: 'Add Widget', value: 'addWidget', iconUrl: addIcon }, { label: 'Edit', value: 'edit', iconUrl: editIcon }];

  useEffect(() => {
    widgetDetailSetter({
      key: 'isInstancePop',
      value: false
    });
    widgetDetailSetter({
      key: 'WTIId',
      value: ''
    });
    return () => {
      setDisableEditWidget();
    };
  }, []);

  /**
   * Function to receive the menu clicked from Home Dashboard
   * @param {*} option Receives the clicked option
   */
  const handleClick = (option) => {
    switch (option.value) {
      case 'addWidget':
        setShowExtraTabMenu(false);
        if (Object.keys(layoutComponentData).length >= MAX_WIDGETS_IN_HOME) {
          setshowMaxError(true);
        } else {
          history.push({
            pathname: '/home/widget-instance',
            state: { isHome: true }
          });
        }

        break;
      case 'edit':
        setShowExtraTabMenu(false);
        setEditWidget({
          isEditOn: true,
          WPID: HDId,
        });
        break;
      default:
        break;
    }
  };

  /**
   * Function to Discard the layout or Individual widget changes made
   */
  const handleDiscard = () => {
    resetWidgetPanelPayload();
    setEditWidget({
      isEditOn: false,
      WPID: HDId,
    });
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    getHomeLayout({ UserId: userData.userId });
  };

  /**
   * Function called on success of update api
   */
  const onSuccessNavigation = () => {
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    getHomeLayout({ UserId: userData.userId });
    resetWidgetPanelPayload();
  };

  /**
   * Function to make api call to the Individual widget component Data Update
   */
  const handleAllWidgetDataUpdate = () => {
    const updateArray = [];
    Object.entries(widgetPayload).forEach((val) => {
      const updateEntry = {
        WID: val[0],
        UserId: userData?.userId,
        UILayoutData: val[1]?.WidgetComponentData
      };
      updateArray.push(updateEntry);
    });
    HomeAllWidgetsUpdate({ widgetPayload: updateArray, onSuccessNavigation });
  };

  /**
   * Function to make api call on Layout Update
   */
  const handleDashboardLayoutUpdate = () => {
    const widgetPayload = {
      UserId: userData?.userId,
      WidgetPanelLayoutData: widgetPanelPayload?.WidgetPanelLayoutData,
      WidgetPanelLayoutMetaData: widgetPanelPayload?.WidgetPanelLayoutMetaData
    };
    HomeLayoutUpdate({ widgetPayload });
    setEditWidget({
      isEditOn: false,
      WPID: HDId,
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <Header>
        <HeadingContent>Home</HeadingContent>
        <HeaderMenu>
          {isEditOn[HDId] ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ButtonComp style={{ marginRight: '2rem' }} onClick={handleDiscard}>
                Discard
              </ButtonComp>
              <ButtonComp
                dark
                onClick={() => {
                  handleDashboardLayoutUpdate();
                  handleAllWidgetDataUpdate();
                }}
              >
                Update
              </ButtonComp>
            </div>
          ) : (
            <DropDownModal
              isActive={showExtraTabMenu}
              setModalIsVisibal={setShowExtraTabMenu}
              data={homeMenu}
              ContainerStyle={{ width: 'auto' }}
              handleClick={handleClick}
              noContainerPadding
            >
              <div
                onClick={() => {
                  setShowExtraTabMenu(!showExtraTabMenu);
                }}
                style={{ cursor: 'pointer' }}
              >
                <DynamicSVGIcon
                  iconUrl={Kabab}
                  width='2.4rem'
                />
              </div>
            </DropDownModal>
          )}
        </HeaderMenu>
      </Header>
      <WidgetContainer style={{ height: !isEditOn[HDId] ? '91%' : '89%', margin: '2rem' }}>
        <WidgetDashBoard />
      </WidgetContainer>
      <ConfirmationModal
        showModal={showMaxError}
        action="Free Space"
        error={true}
        Header="Exceeding Limit!"
        handleDelete={() => {
          setshowMaxError(false);
          setEditWidget({
            isEditOn: true,
            WPID: HDId,
          });
        }}
        closeModalHandler={() => {
          setshowMaxError(false);
        }}
        message="Home reached maximum number of Widgets"
      />
    </div>
  );
};

export default HomeDashboarview;
