/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { localString } from '../../../localization/localString';

import { Input } from '../../../component/index';

import {
  LoginForm,
  Greeting,
  ForgotPassword,
  Btn,
  LogoContainer,
  LogoText
} from './Login.style';
import { vistrianLogo, lock, emailIcon } from '../../../assets/icons/index';
import { Creators as loginCreators } from '../store';
import Toast from '../../../component/Common/Toast';
import osramLogo from '../../../assets/Images/amsOSRAM_Color.png';
import Logo from '../../../component/Common/Logo';
import { AuthRoutes } from '../../../routes/route';
import { fetchUserAndLocation } from '../../../component/Sidenav/FetchStatics';
import ChangePwdModal from '../../../component/HeaderOptions/SettingsModal/Accounts/ChangePwdModal/ChangePwdModal.view';
import { useActions } from '../../../store/hooks/useActions';

const LoginFormView = (props) => {
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [emptyFeilds, setEmptyFeilds] = useState([]);

  const { language, logoUrl, favicon, loading } = useSelector(
    (state) => state.configData
  );
  const { localeStrings } = useSelector((state) => state.localeStrings);
  const { toggleHeaderOption } = useActions();
  const changePwd = useSelector((state) => state.home.changePwd);
  const [forceChangePwd, setForcedChangePwd] = useState({});
  const showChangePwdModal = (message, keyField, userData) => {
    setForcedChangePwd({
      message,
      keyField,
      userData
    });
    toggleHeaderOption('changePwd');
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const { error } = useSelector((state) => state.loginData);
  const handleInputChange = (e) => {
    // unset empty feilds on change
    if (emptyFeilds[e.target.name]) {
      const temp = { ...emptyFeilds };
      temp[e.target.name] = false;
      setEmptyFeilds(temp);
    }

    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    // check for empty feilds
    const feilds = Object.keys(loginDetails);
    let isEmpty = false;
    const emptyFeildsTemp = {};
    feilds.map((feild) => {
      if (loginDetails[feild] === '') {
        emptyFeildsTemp[feild] = true;
        isEmpty = true;
      }
      return emptyFeildsTemp;
    });
    setEmptyFeilds(emptyFeildsTemp);

    if (!isEmpty) {
      const versionDetail = `${process.env.REACT_APP_VERSION}`;
      dispatch(
        loginCreators.login({ loginDetails, showChangePwdModal, versionDetail })
      );
    }
  };

  const clearLoginToastMessage = () => {
    dispatch(loginCreators.resetAuthError());
  };
  useEffect(() => {
    const name = AuthRoutes.find((ele) => ele.path == location.pathname);
    fetchUserAndLocation(location.pathname, {}, { name: name.name });
  }, []);

  return (
    <LoginForm>
      {/* <LogoText>
        <img src={osramLogo} alt='osram' height='50' />
      </LogoText> */}
      {!loading ? <Logo isDark logourl={logoUrl} /> : null}
      <Greeting>{localeStrings?.welcome || 'Welcome'}</Greeting>
      <Input
        name='email'
        type='text'
        value={loginDetails.email}
        placeholder={localeStrings?.emailPlaceholder || 'Enter your email'}
        outline={emptyFeilds.email}
        errorMsg='Please provide Email'
        iconUrl={emailIcon}
        handleInputChange={handleInputChange}
        onSubmit={handleLogin}
      />
      <Input
        name='password'
        type='password'
        outline={emptyFeilds.password}
        errorMsg='Please provide Password'
        value={loginDetails.password}
        placeholder={
          localeStrings?.passwordPlaceholder || 'Enter your password'
        }
        iconUrl={lock}
        handleInputChange={handleInputChange}
        onSubmit={handleLogin}
      />
      <ForgotPassword
        // className='hide'
        onClick={() => {
          props.history.push('/forgot-password');
        }}
      >
        {localeStrings?.forgotPasswordQuery || 'Forgot Password?'}
      </ForgotPassword>
      <Btn onClick={handleLogin}>{localeStrings?.login || 'Login'}</Btn>
      {changePwd && <ChangePwdModal forceChangePwd={forceChangePwd} />}
      {error && (
        <Toast fn={clearLoginToastMessage} header='Error' message={error} />
      )}
    </LoginForm>
  );
};

export default LoginFormView;
