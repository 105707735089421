import styled from 'styled-components';
import { fontFamily } from '../../../constants';

export const Span = styled.div`
  position: absolute;
  font-size: 2rem;
  margin: auto;
  left: 5.8rem;
  bottom: 0.8rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin: 2rem;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 1.8rem;
`;

export const SidePanelContainer = styled.div`
  width: ${({ width }) => width || '20%'};
  margin-left: 1.5rem;
  min-width: 20.5%;
  margin-top: 1.5rem;
  max-height: calc(100vh - 7.5rem - 70px);
  height: calc(100vh - 7.5rem - 70px);
  overflow-y: scroll;
  background: ${({ theme }) => theme.aux.secondary};
  box-shadow: 0px 7px 10px ${({ theme }) => theme.shadow};
  border-radius: 10px 0px 0px 0px;
  padding: 2rem;
  z-index: 201;


  & > * {
    font-size: 1.6rem;
    font-family: ${fontFamily.circularMedium};
  }

  &.widgetSidePanel {
    position: fixed;
    top: 16rem;
    right: 1rem;
    background: white;
    overflow-y: auto;
    z-index: 201;
  }

  &.sideBarinWidg {
    min-height: 80%;
    width: 41.8rem;
  }
`;
