/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NormalPaneCards from './NormalPaneCards.view';
import FullScreenPane from './FullScreen.view';
import { Creators as KPVChartsCreators } from '../Store';
import useGetMachinesShortlisted from '../../../shared/hooks/useGetMachinesShortlisted';
import { getPlotDataPayload } from '../utils/getPlotDataPayload';

const PaneCards = () => {
  const { paneFullScreen, selectedPaneNo, panesShortHandInfo, panesData, xMinDomain, xMaxDomain, latestAvailable } =
    useSelector((state) => state.KPVCharts);
  const dispatch = useDispatch();
  useGetMachinesShortlisted();
  // getting the plotdata from panesShortHandInfo after refresh
  useEffect(() => {
    if (panesShortHandInfo?.length !== panesData.length) {
      const machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo, xMinDomain, xMaxDomain);
      if (!latestAvailable) {
        dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData));
      } else {
        dispatch(KPVChartsCreators.setDomainRange({ latestAvailable: 30 }));
        // isUpdate = true
        dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true, null, null, { latestAvailable: 30 }));
      }
    }
  }, []);

  return <>{paneFullScreen && selectedPaneNo > -1 ? <FullScreenPane /> : <NormalPaneCards />}</>;
};

export default memo(PaneCards);
