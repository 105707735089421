/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { WebService } from '../../services/axios/webServices';
import useDebounce from '../../shared/hooks/useDebounce';
import { useActions } from '../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { getReqPayloadForDependentField } from '../UpsertWidget/WidgetForm/Utility';
import { getFetchDetailsOfWidgetData } from '../Widget/utils';
import DropDownModal from './DropDownModal/DropDownModal';
import SearchBar from './SearchBar';

const SearchBarWithDropdown = (props) => {
  const {
    // required props
    itemLabel = 'scheduleName',
    onItemSelect: handleItemSelect = () => {},
    handlerFunc,
    dataSource = {
      dataKey: 'data',
      data: [
        { id: 1, scheduleName: 'item1' },
        { id: 2, scheduleName: 'item2' },
        { id: 3, scheduleName: 'item3' },
        { id: 4, scheduleName: 'item4' }
      ]
    },
    formStructure, // formstructure
    itemKey = '',
    placeholder = 'Search for schedules',
    // optional
    width = '30rem',
    valueKey, // selected item value key
    formInput, // for handling dependency
    tobeRefetched = false, // check for refetching the data
    resetFieldsTobeRefetched = () => {} // reset the FieldsTobeRefetched
  } = props;

  const [input, setInput] = useState('');
  const [loader, setLoader] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const { dataKey, responseKeys, data, source, dependencies } = dataSource;
  const initialDataList = data || [];
  const { labelKey } = responseKeys || {};
  const [initialData, setIntialData] = useState(initialDataList);
  const [filteredData, setFilteredData] = useState([]);

  const searchBarRef = useRef(null);
  const {
    //  setToastError,
    setFormStructureFailure
  } = useActions();

  const getDataFromSource = async (val, setter = () => {}, additionalPayload = {}) => {
    try {
      // console.log('fetching with controlledInputs', additionalPayload, dependencies);
      if (dataKey === 'source') {
        const fetchUrl = dataSource.source; // {method, UrlType, Url}
        const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
        let payload = {
          equipmentName: val,
          ...additionalPayload
        };
        if (method === 'get') {
          payload = { params: payload };
        }
        const resp = await WebService?.[method](fullURL, payload);
        if (resp.status >= 200 && resp.status < 300) {
          setter(resp.data?.data);
          setFilteredData(resp.data?.data);
        } else {
          throw resp;
        }
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('e', e, error.error);
      // setToastError(error.error);
      setFormStructureFailure(error.error);
    } finally {
      resetFieldsTobeRefetched();
    }
  };

  // TODO REMOVE
  useEffect(() => {
    setFilteredData(initialData);
  }, [initialData]);

  const handleSearch = (val) => {
    if (!isDropdownActive && document.activeElement === searchBarRef.current) {
      setIsDropdownActive(true);
    }
    // TODO ENABLE search from api
    // getDataFromSource(val);
    // TODO REMOVE
    const fData = initialData.filter((e) => e?.[labelKey || 'label']?.toLowerCase().includes(val.toLowerCase()));
    setFilteredData(fData);
  };
  useDebounce(input, handleSearch, 300);

  // const onItemSelect = (...args) => {
  //   const [item] = args;
  //   setInput(item[itemLabel]);
  //   handleItemSelect(...args);
  //   setInput('');
  // };

  useEffect(() => {
    getDataFromSource('', setIntialData);
  }, []);

  useEffect(() => {
    if (tobeRefetched) {
      // console.log('refetching....', itemKey);
      const newPayload = getReqPayloadForDependentField(formInput, dependencies, formStructure);
      getDataFromSource('', setIntialData, newPayload);
    }
  }, [tobeRefetched]);

  return (
    <>
      <DropDownModal
        setModalIsVisibal={setIsDropdownActive}
        isActive={isDropdownActive}
        handleClick={(item) => {
          setInput('');
          handlerFunc(item, { key: itemKey, itemValueKey: valueKey });
        }}
        label={itemLabel}
        data={filteredData}
        singleSelection
        width={width}
        loader={loader}
        noContainerPadding={true}
      >
        <SearchBar
          input={input}
          searchBarRef={searchBarRef}
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          width={width}
        />
      </DropDownModal>
    </>
  );
};

export default SearchBarWithDropdown;
