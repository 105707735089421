/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, CollapsableContainer, Row, ToogleGroupICon } from '../KPVListComponent/KPVListComponent.style';
import { ActionIcon } from './KPVGroupComponent.style';
import { Creators as FilterResultCreators } from '../Store/index';
import KPVDetailsModal from '../KPVDetailsModal/KPVDetailsModal.view';
import CollapseIcon from '../../SvgIcons/KPVList/CollapseIcon';
import AddToPane from '../../SvgIcons/KPVList/AddToPane';
import RemoveFromPane from '../../SvgIcons/KPVList/RemoveFromPane';
import { Tr, Td, Th } from '../../Common/Table/SpacedRows.styles';
import { FlexContainer } from '../../../globalStyles';
import { KPV_MAX_LIMIT } from '../config';

const KPVGroup = ({ groupTitle, groupItems, activeTab }) => {
  const dispatch = useDispatch();
  const activeMenuId = useSelector((state) => state.home.activeMenuId);
  const KPVShortlist = useSelector((state) => state.filterResultData.KPVShortlist?.[activeMenuId]) || [];
  const metaDetails = useSelector((state) => state.filterResultData.metaDetails);
  const [collapseGroup, setCollapseGroup] = useState(false);
  const [KpvDetailsModal, setKpvDetailsModal] = useState(false);
  const [selectedKPV, setSelectedKPV] = useState([]);

  const handleShortList = (entry, groupName, isChartable) => {
    if (isChartable) {
      const { MachineId, MachineName } = entry;
      const temp = {
        ...entry,
        machineId: MachineId,
        machineName: MachineName,
        groupName,
        id: entry[metaDetails.UID],
        macId: activeTab.id
      };

      const check = KPVShortlist.some((ele) => ele.id === entry[metaDetails.UID] && ele.machineId === MachineId);
      if (!check) {
        if (KPVShortlist?.length >= KPV_MAX_LIMIT) {
          dispatch(
            FilterResultCreators.genericSetter({
              key: 'infoMsg',
              value: `Maximum KPVs selection allowed is ${KPV_MAX_LIMIT}`
            })
          );
        } else {
          dispatch(FilterResultCreators.setKpvShortlist({ menuId: activeMenuId, payload: [...KPVShortlist, temp] }));
        }
      } else {
        dispatch(
          FilterResultCreators.setKpvShortlist({
            menuId: activeMenuId,
            payload: KPVShortlist.filter((e) => !(e.id === entry[metaDetails.UID] && e.machineId === MachineId))
          })
        );
      }
    }
  };

  const handleKpvDetails = (entry) => {
    setKpvDetailsModal(true);
    setSelectedKPV(entry);
  };

  const colorFields = Array.isArray(metaDetails.colorField) ? metaDetails.colorField : [metaDetails.colorField];
  // console.log('colorFields', colorFields);

  const getValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'True' : 'False';
    }
    return value || '-';
  };
  return (
    <>
      {groupItems.length > 0 && (
        <>
          <Tr>
            <Td noBorder={true} className='groupHeading align'>
              <FlexContainer className='ai_center'>
                <ToogleGroupICon onClick={() => setCollapseGroup(!collapseGroup)}>
                  <CollapseIcon />
                </ToogleGroupICon>
                <p>{groupTitle}</p>
              </FlexContainer>
            </Td>
            {metaDetails?.fields?.map(
              (col, key) =>
                // !metaDetails?.grouping?.includes(col.key) &&
                col.visible && <Td className='groupHeading' />
            )}
            {/* {dummyCols()} */}
          </Tr>
          {/* {!collapseGroup && groupItems.values.length > 0 && ( */}
          <>
            {!collapseGroup &&
              groupItems.map((entry, index) => {
                const isActive = KPVShortlist.some(
                  (ele) => ele.id === entry[metaDetails.UID] && ele.machineId === entry.MachineId
                );
                return (
                  <Tr
                    key={`${entry[metaDetails.UID]} ${entry.machineId} ${index}`}
                    className={isActive ? 'active' : 'fillColor'}
                  >
                    {metaDetails?.fields?.map(
                      (col, key) =>
                        // !metaDetails?.grouping?.includes(col.key) &&
                        col.visible && (
                          <Td
                            key={key}
                            border={col.key !== 'Action'}
                            // width={col.width}
                            // order={col.order}
                            // align={col.textAlign}
                            // className={isActive ? 'active' : ''}
                            // border={col.key !== 'Action'}
                            className={col.key === 'KPV' ? 'pointer' : ''}
                            onClick={col.key === 'KPV' ? () => handleKpvDetails(entry) : null}
                            color={
                              metaDetails.fieldsToColor.includes(col.key) && colorFields ? entry[colorFields[0]] : ''
                            }
                          >
                            {/* {col.key !== 'Action' ? ( */}
                            <p className='clip hideScrollBar'>{getValue(entry[col.key])}</p>
                            {/* ) : (
                            <ActionIcon onClick={() => handleShortList(entry, groupItems.groupTitle)}>
                              {KPVShortlist.some((ele) => ele.id === entry.id && ele.machineId === machineId) ? (
                                <RemoveFromPane />
                              ) : (
                                <AddToPane />
                              )}
                            </ActionIcon>
                          )} */}
                          </Td>
                        )
                    )}
                    <Td
                      // className={isActive ? 'active' : ''}
                      flex={0.5}
                    >
                      <FlexContainer className='jc_center ai_center'>
                        <ActionIcon
                          className={!entry[metaDetails.chartable] ? 'disable' : ''}
                          onClick={() => handleShortList(entry, groupTitle, entry[metaDetails.chartable])}
                        >
                          {/* show respective icon */}
                          {isActive ? <RemoveFromPane /> : <AddToPane />}
                        </ActionIcon>
                      </FlexContainer>
                    </Td>
                  </Tr>
                );
              })}
          </>
          {/* )} */}
        </>
      )}
      {/* api not available */}
      {KpvDetailsModal && (
        <KPVDetailsModal setKpvDetailsModal={setKpvDetailsModal} selectedKPV={selectedKPV} machineId={activeTab.id} />
      )}
    </>
  );
};

export default KPVGroup;
