/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import SearchBar from '../../Common/SearchBar/SearchBar';
import { SidePanelContainer } from '../WidgetCommonCreationForm/WidgetCommonCreationForm.style';
import ButtonComp from '../../Common/Button/Button.view';
import {
  BtnContainer,
  Details,
  IconDiv,
  MainContainer,
  MainDivSbar,
  Text,
  AddWidgetHeader
} from './WidgetTypeSelectionSideBar.style';
import { UpsertWidgetCreators } from '../Store';
// import { widgetMenu } from '../../Widget/utils/contants';
import { FlexContainer } from '../../../globalStyles';
import { useActions } from '../../../store/hooks/useActions';
import { WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../../constants';
import {
  ClockNWthr,
  MyDashboard,
  MyAnalytics,
  FloorPlan,
  EOEED,
  EquipmentPlan
} from '../../../assets/icons';
import { getWidgetCategory } from '../../Widget/utils';

const WidgetTypeSelectionSideBar = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const widgetType = useSelector((state) => state.upsertWidgetData.widgetType);
  const widgetLabel = useSelector((state) => state.upsertWidgetData.widgetLabel);
  const widgetCategory = useSelector((state) => state.upsertWidgetData.widgetCategory);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const { setWidgetType, widgetDetailSetter, valueSetter } = useActions();
  const widgetSVGs = {
    [WIDGET_CATEGORIES.ANALYTICS]: MyAnalytics,
    [WIDGET_CATEGORIES.OEE_DONUT]: EOEED,
    [WIDGET_CATEGORIES.STATUS_VALUE]: ClockNWthr,
    [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: MyDashboard,
    default: EOEED
  };

  useEffect(() => {
    dispatch(UpsertWidgetCreators.resetData());
  }, []);
  const handleSelect = (item) => {
    const { value, label, category } = item || {};
    setWidgetType({
      widgetType: value,
      widgetLabel: label,
      //  TODO_W_R: add widget category also
      [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: category
    });
  };

  const addingWidget = () => {
    history.push('/home/find-widget-instance');
    // dispatch(WidgetCreators.widgetDetailSetter({ key: 'showSidePanel', value: false }));
    valueSetter({ widgetDashboardTitle: activeTab?.label });
  };
  const handlePanelClose = () => {
    widgetDetailSetter({
      key: 'showSidePanel',
      value: false
    });
  };

  return (
    <SidePanelContainer className='widgetSidePanel' width='41.8rem'>
      <AddWidgetHeader>Add Widget</AddWidgetHeader>
      <div>
        <SearchBar
          searchValue={searchValue}
          placeholder='Search Widgets'
          width='36'
          onChangeHandler={setSearchValue}
        />
      </div>
      <MainContainer>
        {Object.values(widgetTypesMenu)
          .filter((val) => {
            const { label, value, category: definedCategory } = val;
            const category = definedCategory || getWidgetCategory(value);
            // eliminate test data
            if (!value && !category) {
              return false;
            }
            if (
              val.label
                ?.toLocaleLowerCase()
                ?.includes(searchValue.toLocaleLowerCase())
            ) {
              return true;
            }
            return false;
          })
          .map((e, i) => (
            <MainDivSbar
              key={e.id}
              onClick={() => handleSelect(e)}
              className={widgetType == e.value && widgetCategory && widgetLabel ? 'selected' : ''}
            >
              <IconDiv>
                <DynamicSVGIcon
                  iconUrl={
                    widgetSVGs?.[
                      e.category || getWidgetCategory(e.value) || e.value
                    ] || widgetSVGs.default
                  }
                  width='5rem'
                />
              </IconDiv>
              <Details>
                <Text>{e.label}</Text>
                <Text className='decription'>{e.detail}</Text>
              </Details>
            </MainDivSbar>
          ))}
      </MainContainer>
      <FlexContainer style={{ width: '100%' }} className='jc_center'>
        <BtnContainer>
          <ButtonComp
            style={{ marginRight: '2.5rem' }}
            onClick={handlePanelClose}
          >
            discard
          </ButtonComp>
          <ButtonComp dark onClick={addingWidget} disabled={!widgetType || !widgetCategory || !widgetLabel}>
            Add
          </ButtonComp>
        </BtnContainer>
      </FlexContainer>
    </SidePanelContainer>
  );
};

export default WidgetTypeSelectionSideBar;
