/* eslint-disable no-throw-literal */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { localString } from '../../../localization/localString';
import { Input, Button } from '../../../component/index';
import { LoginForm, LogoContainer, LogoText } from '../login/Login.style';
import { Return, Desc, Error, Heading, BackArrow } from './ForgotPassword.style';
import { vistrianLogo, emailIcon, inputFeildError } from '../../../assets/icons';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/login/back_arrow.svg';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import Toast from '../../../component/Common/Toast';
import osramLogo from '../../../assets/Images/amsOSRAM_Color.png';
import Logo from '../../../component/Common/Logo';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState('');

  const [emailVerifyError, setEmailVerifyError] = useState(false);
  const [emailVerifyErrorMsg, setEmailVerifyErrorMsg] = useState('');
  const [emptyFeild, setEmptyFeild] = useState(false);

  const language = useSelector((state) => state.configData.language);
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const string = localString[language];
  // input validation error
  // api validation error
  const history = useHistory();
  const handleVerification = async () => {
    try {
      if (email) {
        const endpoint = `${apiEndPoints.forgotPassword}?email=${email}`;
        const resp = await WebService.post(endpoint);
        // console.log('res', resp, resp.data, resp.data.data, 'sattus', resp.status);
        if (resp.status >= 200 && resp.status < 300) {
          // const { data, message } = JSON.parse(resp.data) || {};
          history.push({
            pathname: '/email-verify',
            state: {
              email
            }
          });
        } else {
          throw resp;
        }
      } else {
        setEmptyFeild(true);
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('error', error);
      setEmailVerifyErrorMsg(error);
    }
  };

  const backToLogin = () => {
    props.history.goBack();
  };

  const clearToastMessage = () => {
    setEmailVerifyErrorMsg('');
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    if (emptyFeild || emailVerifyErrorMsg || emailVerifyError) {
      setEmptyFeild(false);
      setEmailVerifyErrorMsg('');
      setEmailVerifyError(false);
    }
  };
  return (
    <LoginForm>
      {/* <LogoText>
        <img src={osramLogo} alt='osram' height='50' />
      </LogoText> */}
      <Logo />
      <Heading marginBottom='1.1rem'>{localeStrings?.forgotPassword || 'Forgot Password'}</Heading>
      {emailVerifyError ? (
        <Error>
          {localeStrings?.emailVerifyError ||
            'This email is not part of Active directory. Please contact your IT support.'}
        </Error>
      ) : (
        <Desc>{localeStrings?.verifyEmail || 'Verify your email'}</Desc>
      )}
      <Input
        name='email'
        value={email}
        type='text'
        placeholder={localeStrings?.emailPlaceholder || 'Enter your email'}
        iconUrl={emailVerifyError ? inputFeildError : emailIcon}
        outline={emailVerifyError || emptyFeild}
        errorMsg={emptyFeild && 'Please provide email'}
        handleInputChange={handleInputChange}
        onSubmit={handleVerification}
      />
      <Button marginTop='0.4rem' onClick={handleVerification}>
        {localeStrings?.verifyEmailBtnText || 'Verify email'}
      </Button>
      <Return onClick={backToLogin}>
        <BackArrowIcon className='fill' style={{ height: '1.5rem', width: '3rem' }} />
        {localeStrings?.backToLogin || 'Back to login'}
      </Return>
      {emailVerifyErrorMsg && !emailVerifyError && (
        <Toast header='Error' message={emailVerifyErrorMsg} fn={clearToastMessage} />
      )}
    </LoginForm>
  );
};
export default ForgotPasswordForm;
