/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Duration,
  TopFullScreen,
  ZoomIn,
  ZoomOut
} from '../../../assets/icons';
import Compress from '../../../assets/icons/KPV/CombineCharts.svg';
import {
  BackArrow,
  ForwardNavArrow,
  ForwardNavArrowBlack,
  Kabab,
  ToggleOff,
  ToggleOn
} from '../../../assets/icons/KPV';
import {
  Creators as LayoutCreator,
  Creators as LayoutCreators
} from '../../../views/Layout/store';
import DurationDropdownComp from '../DurationDropdown/DurationDropdown.view';
import FullScreenKpvCharting from '../FullScreenKpvCharting/FullScreenKpvCharting.view';
import PaneCards from '../PaneCard/PaneCards.view';
import { Creators as KPVChartsCreators } from '../Store';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
import {
  Header,
  AddPaneBtn,
  Container,
  NavigationDiv,
  ChartOptionsDiv,
  OptBtn,
  OptIcon,
  DurationContainer,
  UpperLayer,
  IconWrapper
} from './KPVCharting.style';
import { commonRoutes } from '../../../routes/route';
import ChartOptions from '../ChartOptions/ChartOptions.view';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { Creators as kpvChartingCreators } from '../Store/action';
import { getPlotDataPayload } from '../utils/getPlotDataPayload';
import {
  CHART_TYPE_CATEGORY,
  CHART_TYPE_CATEGORY_SUB,
  PAGE_TYPES,
  fontFamily
} from '../../../constants';
import DropDownModal from '../../Common/DropDownModal/DropDownModal_V1';
import {
  kpvChartingMenu,
  kpvChartingMenuWithoutComparison
} from '../Store/kpvGraphData';
import { FlexContainer } from '../../../globalStyles';
import KPVControllerIcon from './KPVControllerIcon';
import { graphFormat } from '../../../config/index';
import { updateAllArgDomains } from '../utils/updateAllArgDomains';
import Toast, { TOAST_TYPES } from '../../Common/Toast/index';
import SaveAnalytics from '../../MyAnalytics/SaveAnalyticsModal/SaveAnalytics';
import SaveAnalyticsChanges from '../../MyAnalytics/SaveAnalyticsModal/SaveAnalyticsChanges';
import { fetchUserAndLocation } from '../../Sidenav/FetchStatics';
import { theme } from '../../../config/theme';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { myAnalyticsCreators } from '../../../views/MyAnalytics/store';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { Creators as FilterResultCreators } from '../../ResultView/Store/index';
import { getPlotOptions } from '../utils/getPlotOptions';
import { useActions } from '../../../store/hooks/useActions';

const KPVCharting = () => {
  const dispatch = useDispatch();
  const [durationModal, setDurationModal] = useState(false);
  const history = useHistory();
  const menuIdofFilter = useSelector(
    (state) => state.filterData.menuIdofFilter
  );
  const {
    paneFullScreen,
    compressedView,
    panesShortHandInfo,
    xMinDomain,
    xMaxDomain,
    xMinDomainFullScreen,
    xMaxDomainFullScreen,
    comparisionMode,
    isAligned,
    error
  } = useSelector((state) => state.KPVCharts);
  const myAnalyticsItemName = useSelector(
    (state) => state.myAnalytics.analyticsData.myAnalyticsItemName
  );
  const [showSavedashboard, setShowSavedashboard] = useState(false);
  const userData = useSelector((state) => state.loginData.userData);
  const setIsAligned = () => {
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'isAligned',
        value: !isAligned
      })
    );
  };

  const [fullScreen, setFullScreen] = useState(false);
  const [showChartOpts, setShowChartOpts] = useState(false);
  const [showKPVMenu, setShowKPVMenu] = useState(false);
  const assetAdditionalInfo = useSelector(
    (state) => state.KPVCharts.assetAdditionalInfo
  );
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const filterPaths = useSelector((state) => state.home?.[PAGE_TYPES.FILTER]);
  const domainRangeAvailable = useRef();
  const location = useLocation();
  const { id: chartId } = useParams();

  useEffect(() => {
    const commonUrl = location.pathname.replace(chartId, ':id');
    const name = commonRoutes.find((ele) => ele.path == commonUrl);
    fetchUserAndLocation(location.state?.previousPath, userData, {
      pageType: name.name,
      tab: name.name
    });
    domainRangeAvailable.current = {
      xMinDomain,
      xMaxDomain
    };
    dispatch(LayoutCreator.setShowSideNav({ showSideNav: false }));
    return () => {
      dispatch(LayoutCreator.setShowSideNav({ showSideNav: true }));
    };
  }, []);

  const getPlotDataForUpdatedDomain = (xMin, xMax, isForward) => {
    let panesShortHandInfoTemp = panesShortHandInfo;
    let machinesWithNoPlotData;
    if (comparisionMode) {
      panesShortHandInfoTemp = updateAllArgDomains(
        panesShortHandInfo,
        xMinDomain,
        xMaxDomain,
        isForward
      );
      machinesWithNoPlotData = getPlotDataPayload(
        panesShortHandInfoTemp,
        null,
        null,
        true
      );
    } else {
      machinesWithNoPlotData = getPlotDataPayload(
        panesShortHandInfoTemp,
        xMin,
        xMax
      );
    }
    dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
  };

  const handleZoomIn = () => {
    if (paneFullScreen) {
      const updatedXMax = moment(xMaxDomainFullScreen)
        .subtract(5, 'm')
        .toISOString();
      dispatch(
        KPVChartsCreators.setDomainRange({ xMaxDomainFullScreen: updatedXMax })
      );
    } else {
      const updatedXMax = moment(xMaxDomain).subtract(5, 'm').toISOString();
      dispatch(KPVChartsCreators.setDomainRange({ xMaxDomain: updatedXMax }));
    }
  };
  const handleZoomOut = () => {
    if (paneFullScreen) {
      const updatedXMax = moment(xMaxDomainFullScreen)
        .add(5, 'm')
        .toISOString();
      dispatch(
        KPVChartsCreators.setDomainRange({ xMaxDomainFullScreen: updatedXMax })
      );
    } else {
      const updatedXMax = moment(xMaxDomain).add(5, 'm').toISOString();
      getPlotDataForUpdatedDomain(xMinDomain, updatedXMax);
      dispatch(KPVChartsCreators.setDomainRange({ xMaxDomain: updatedXMax }));
    }
  };
  const handleMoveForward = () => {
    if (paneFullScreen) {
      const diff =
        1.0 *
        moment(xMaxDomainFullScreen).diff(
          moment(xMinDomainFullScreen),
          'seconds'
        );
      const updatedXMax = moment(xMaxDomainFullScreen)
        .add(diff, 's')
        .toISOString();
      const updatedXMin = moment(xMinDomainFullScreen)
        .add(diff, 's')
        .toISOString();
      dispatch(
        KPVChartsCreators.setDomainRange({
          xMaxDomainFullScreen: updatedXMax,
          xMinDomainFullScreen: updatedXMin
        })
      );
    } else {
      const diff = 1.0 * moment(xMaxDomain).diff(moment(xMinDomain), 'seconds');
      const updatedXMin = moment(xMinDomain).add(diff, 's').toISOString();
      // const rawMax = moment(xMaxDomain).add(diff, 's').toISOString();
      // const updatedXMax = new Date().getTime() < new Date(rawMax).getTime() ? new Date().toISOString() : rawMax;
      const updatedXMax = moment(xMaxDomain).add(diff, 's').toISOString();
      getPlotDataForUpdatedDomain(updatedXMin, updatedXMax, true);
      dispatch(
        KPVChartsCreators.setDomainRange({
          xMinDomain: updatedXMin,
          xMaxDomain: updatedXMax
        })
      );
    }
  };

  const handleMoveBack = () => {
    if (paneFullScreen) {
      const diff =
        1.0 *
        moment(xMaxDomainFullScreen).diff(
          moment(xMinDomainFullScreen),
          'seconds'
        );
      const updatedXMax = moment(xMaxDomainFullScreen)
        .subtract(diff, 's')
        .toISOString();
      const updatedXMin = moment(xMinDomainFullScreen)
        .subtract(diff, 's')
        .toISOString();
      dispatch(
        KPVChartsCreators.setDomainRange({
          xMaxDomainFullScreen: updatedXMax,
          xMinDomainFullScreen: updatedXMin
        })
      );
    } else {
      const diff = 1.0 * moment(xMaxDomain).diff(moment(xMinDomain), 'seconds');
      const updatedXMin = moment(xMinDomain).subtract(diff, 's').toISOString();
      const updatedXMax = moment(xMaxDomain).subtract(diff, 's').toISOString();
      getPlotDataForUpdatedDomain(updatedXMin, updatedXMax, false);

      dispatch(
        KPVChartsCreators.setDomainRange({
          xMinDomain: updatedXMin,
          xMaxDomain: updatedXMax
        })
      );
    }
  };

  // always route to kpvlist on back(don't let go to upsert pane page)
  const handleBackNav = () => {
    if (myAnalyticsItemName) {
      // history.goBack();
      history.push('/home/analytics/myanalytics');
    } else if (!paneFullScreen) {
      if (filterPaths[menuIdofFilter]) {
        history.push(`${filterPaths[menuIdofFilter]}`);
      } else {
        history.goBack();
      }
      dispatch(
        FilterCreators.universalFilterSetter({ key: 'showFilter', value: true })
      );
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'showFilterResult',
          value: true
        })
      );
    } else {
      dispatch(KPVChartsCreators.exitPaneFullScreen());
    }
  };

  const handlePopOut = () => {
    setFullScreen(true);
  };
  const handleCompress = () => {
    dispatch(KPVChartsCreators.toggleCompressedView());
  };

  const handleAddPane = () => {
    if (!compressedView) {
      setShowChartOpts(true);
      // history.push('kpv-charting/add-pane');
    }
  };

  const handleKpvMenu = () => setShowKPVMenu(!showKPVMenu);

  const durationRef = useRef();
  useClickOutside(durationRef, () => setDurationModal(false));

  const headerIcons = [
    {
      role: 'ZoomOut',
      iconUrl: ZoomOut,
      onClick: handleZoomOut,
      label: 'Zoom Out'
    },
    {
      role: 'ZoomIn',
      iconUrl: ZoomIn,
      onClick: handleZoomIn,
      label: 'Zoom In'
    },
    {
      role: 'moveBack',
      iconUrl: BackArrow,
      onClick: handleMoveBack,
      label: 'Backward'
    },
    {
      role: 'moveForward',
      iconUrl: BackArrow,
      onClick: handleMoveForward,
      rotate: 180,
      label: 'Forward'
    },
    {
      role: 'compress',
      iconUrl: Compress,
      onClick: handleCompress,
      label: 'Compress'
    },
    {
      role: 'popOut',
      iconUrl: TopFullScreen,
      onClick: handlePopOut,
      label: 'Pop Out'
    }
    // { role: 'kpvMenu', iconUrl: Kabab, onClick: handleKpvMenu },
  ];
  const hideableIcons = {
    InFullScreen: ['compress', 'popOut']
  };

  // ========== Added Below Function Call as a part of feature Adding Id to saved analytics View============= //
  const fetchAnalyticsSummary = async (chartId) => {
    try {
      const analyticsSummaryResp = await WebService.get(
        `${apiEndPoints.analyticsSummary}/${chartId}`
      );
      if (
        analyticsSummaryResp.status >= 200 &&
        analyticsSummaryResp.status < 300
      ) {
        const {
          menuid,
          Title,
          isEdit,
          filterData: filterSelections,
          kpvDetails,
          chartDetails,
          tab
        } = analyticsSummaryResp.data.data;
        dispatch(
          myAnalyticsCreators.setAnalyticsDetails({
            form: {
              myAnalyticsItemId: chartId,
              myAnalyticsItemName: Title,
              isEditable: isEdit,
              selectedParent: tab,
              menuId: menuid,
              filterSelections,
              kpvDetails,
              chartDetails
            },
            isInit: false
          })
        );
      }
      return analyticsSummaryResp;
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      console.log('error', err);
      return err;
    }
  };

  const populateReducers = async (analyticsSummaryResp) => {
    const {
      kpvDetails,
      chartDetails,
      xMinDomain,
      xMaxDomain,
      xDomainDuration,
      menuid,
      latestAvailable
    } = analyticsSummaryResp.data.data;
    const { metaDetailsInShortlist, KPVShortlist } = kpvDetails;
    dispatch(FilterCreators.resetFilterState());
    dispatch(LayoutCreators.setActiveMenu({ Id: menuid }));
    dispatch(
      FilterResultCreators.setKPVShortlistDetails({
        metaDetailsInShortlist,
        KPVShortlist,
        menuId: menuid
      })
    );
    if (isEmpty(chartDetails)) {
      dispatch(KPVChartsCreators.resetPanesData());
      // eslint-disable-next-line no-throw-literal
      throw { customMsg: 'No Charts Saved' };
    }
    const chartDetailsObj = {
      panesShortHandInfo: chartDetails,
      xMinDomain,
      xMaxDomain,
      latestAvailable
    };
    if (metaDetailsInShortlist.enabledPanes) {
      chartDetailsObj.chartOptions = getPlotOptions(
        metaDetailsInShortlist.enabledPanes,
        kpvDetails.KPVShortlist
      );
    }
    if (xDomainDuration !== 'custom' && !isEmpty(xDomainDuration)) {
      const { shorthand, shorthandValue } = xDomainDuration;
      chartDetailsObj.xMaxDomain = new Date();
      chartDetailsObj.xMinDomain = new Date(
        moment().subtract(shorthandValue, shorthand)
      );
    }
    dispatch(KPVChartsCreators.setChartDetails(chartDetailsObj));

    const machinesWithNoPlotData = getPlotDataPayload(
      chartDetails,
      xMinDomain,
      xMaxDomain
    );
    dispatch(
      KPVChartsCreators.getPlotData(machinesWithNoPlotData, true, null, null, {
        latestAvailable: 30
      })
    );
  };

  const setIsLoading = () => {
    dispatch(myAnalyticsCreators.setIsLoading());
  };

  const setError = (msg) => {
    dispatch(myAnalyticsCreators.setError(msg));
  };

  const viewChart = async () => {
    setIsLoading();
    try {
      const analyticsSummaryResp = await fetchAnalyticsSummary(chartId);
      if (
        analyticsSummaryResp.status >= 200 &&
        analyticsSummaryResp.status < 300
      ) {
        populateReducers(analyticsSummaryResp);
      } else {
        throw analyticsSummaryResp;
      }
    } catch (error) {
      const { customMsg } = error;
      let payload;
      if (!customMsg) {
        payload = sagaCatchBlockHandling(error);
        setError(payload.error);
      } else {
        setError(customMsg);
      }
      console.log('error', payload);
    }
  };

  useEffect(() => {
    if (!myAnalyticsItemName && chartId) {
      viewChart();
    }
  }, []);
  // ========== Added Above Function Call as a part of feature Adding Id to saved analytics View============= //

  const iconsInit = [
    'moveBack',
    'moveForward',
    'ZoomOut',
    'ZoomIn',
    'compress',
    'popOut'
  ];
  const disableIcons = {
    InComparisionMode: iconsInit,
    InComparisionAndAlignMode: iconsInit.slice(2)
  };

  const isIconVisible = (role) => {
    if (paneFullScreen && hideableIcons.InFullScreen.includes(role)) {
      return false;
    }
    return true;
  };

  const isIconDisabled = (role) => {
    if (comparisionMode) {
      if (isAligned) {
        return disableIcons.InComparisionAndAlignMode.includes(role);
      }
      return disableIcons.InComparisionMode.includes(role);
    }
    return false;
  };
  // const setDuration = (domainObj) => dispatch(KPVChartsCreators.setDomainRange({ ...domainObj }));
  const { resetAssetAdditionalInfo } = useActions();

  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const handleMenuClick = (item) => {
    const { role } = item;
    switch (role) {
      case 'comparisionMode': {
        resetAssetAdditionalInfo();
        if (comparisionMode) {
          const machinesWithNoPlotData = getPlotDataPayload(
            panesShortHandInfo,
            xMinDomain,
            xMaxDomain,
            true,
            { latestAvailable: 30 }
          );
          // setDuration({ latestAvailable: 30 });
          dispatch(
            KPVChartsCreators.getPlotData(
              machinesWithNoPlotData,
              true,
              null,
              null,
              { latestAvailable: 30 }
            )
          );
          // const machinesWithNoPlotData = getPlotDataPayload(panesShortHandInfo, xMinDomain, xMaxDomain);
          // dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
          dispatch(KPVChartsCreators.setFilterFilesSelected([]));
        } else {
          const machinesWithNoPlotData = getPlotDataPayload(
            panesShortHandInfo,
            null,
            null,
            true
          );
          dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
          dispatch(KPVChartsCreators.setFilterFilesSelected([]));
        }
        dispatch(
          KPVChartsCreators.genericKPVPropertySetter({
            key: role,
            value: !comparisionMode
          })
        );
        break;
      }
      case 'saveAnalytics': {
        if (myAnalyticsItemName) {
          setShowSaveConfirmation(true);
        } else {
          setShowSavedashboard(true);
        }
        break;
      }
      case 'exportAnalytics': {
        break;
      }
      default:
        break;
    }
    setShowKPVMenu(false);
  };

  const handleAlignment = async () => {
    if (!isAligned) {
      const updatedPanesShortHandInfo = panesShortHandInfo.map((shInfo) => {
        const { compStartTime, StartTime, EndTime } = shInfo;
        const startTimeInit = moment(compStartTime);
        // After Bug https://vistrian.atlassian.net/browse/UX22-285 commented the below line
        // return {
        //   ...shInfo,
        //   StartTime: compStartTime
        //     ? startTimeInit
        //     : moment(StartTime || xMinDomain, graphFormat),
        //   EndTime: compStartTime
        //     ? startTimeInit.clone().add(30, 'minutes')
        //     : moment(EndTime || xMaxDomain, graphFormat)
        // };

        // After Bug https://vistrian.atlassian.net/browse/UX22-285 updated condition
        const formatedStartTime = moment(StartTime, graphFormat);
        const formatedEndTime = moment(EndTime, graphFormat);
        let differnt;
        const diff = 1.0 * formatedEndTime.diff(formatedStartTime, 'seconds');
        if (diff > 0) {
          differnt = diff;
        } else {
          const formatedxMinDomain = moment(xMinDomain, graphFormat);
          const formatedxMaxDomain = moment(xMaxDomain, graphFormat);
          differnt =
            1.0 * formatedxMaxDomain.diff(formatedxMinDomain, 'seconds');
        }
        // console.log(StartTimeInit, startTimeInit);
        if (compStartTime) {
          const startTimeGraph = moment(compStartTime);
          dispatch(
            KPVChartsCreators.setDomainRange({ xMinDomain: startTimeGraph })
          );
        }
        return {
          ...shInfo,
          StartTime: compStartTime
            ? startTimeInit
            : moment(StartTime || xMinDomain, graphFormat),
          EndTime: compStartTime
            ? startTimeInit.clone().add(differnt, 'seconds')
            : moment(EndTime || xMaxDomain, graphFormat)
        };
      });
      const machinesWithNoPlotData = getPlotDataPayload(
        updatedPanesShortHandInfo,
        null,
        null,
        true
      );
      await machinesWithNoPlotData?.forEach((machine, ind) => {
        const assetInfoMapped = Object.values(assetAdditionalInfo)?.filter(
          (addInfo) => addInfo.id === panesShortHandInfo[ind].id
        );
        if (assetInfoMapped) {
          machine.additionalFilter = assetInfoMapped[0]?.assetShortlist;
          machine.categoryOptions = assetInfoMapped[0]?.selectedCategory;
        }
      });
      dispatch(KPVChartsCreators.getPlotData(machinesWithNoPlotData, true));
    }

    setIsAligned(!isAligned);
  };

  const clearErrorHandler = () => {
    dispatch(
      kpvChartingCreators.genericKPVPropertySetter({
        key: 'error',
        value: null
      })
    );
  };

  const closeSaveConfirmationModal = () => setShowSaveConfirmation(false);

  const [showComparisonOpt, setShowComparisonOpt] = useState(true);

  useEffect(() => {
    const typeArray = [];
    const subTypeArray = [];
    panesShortHandInfo?.forEach((pane) => {
      typeArray.push(pane?.chartTypeCategory);
      if (pane?.chartTypeCategory === CHART_TYPE_CATEGORY?.SPC) {
        subTypeArray.push(pane?.chartTypeSub);
      }
    });
    if (typeArray?.includes(CHART_TYPE_CATEGORY?.LABEL)) {
      setShowComparisonOpt(false);
    } else if (typeArray?.includes(CHART_TYPE_CATEGORY?.SPC)) {
      if (subTypeArray?.includes(CHART_TYPE_CATEGORY_SUB.LABEL_SERIES)) {
        setShowComparisonOpt(false);
      } else {
        setShowComparisonOpt(true);
      }
    } else {
      setShowComparisonOpt(true);
    }
  }, [panesShortHandInfo]);

  return (
    <Container>
      <Header>
        <NavigationDiv>
          <OptIcon rotate={180} onClick={handleBackNav} className='pointer'>
            <DynamicSVGIcon iconUrl={ForwardNavArrow} width='2.5rem' />
          </OptIcon>
          <span>
            {myAnalyticsItemName || localeStrings.Charting || 'Charting'}
          </span>
        </NavigationDiv>

        {/* control panel buttons */}
        <ChartOptionsDiv pane={paneFullScreen}>
          {comparisionMode && (
            <FlexContainer
              className='icon jc_center ai_center pointer'
              onClick={handleAlignment}
            >
              <span>Align</span>
              <IconWrapper>
                <DynamicSVGIcon
                  iconUrl={isAligned ? ToggleOn : ToggleOff}
                  width='3.5rem'
                />
              </IconWrapper>
            </FlexContainer>
          )}
          {!paneFullScreen && (
            <OptBtn
              className={compressedView ? 'disable' : ''}
              onClick={handleAddPane}
            >
              {`+ ${localeStrings.Add || 'Add'} ${
                localeStrings.Pane || 'Pane'
              }`}
            </OptBtn>
          )}
          <DurationContainer ref={durationRef}>
            <OptBtn onClick={() => setDurationModal(!durationModal)}>
              <OptIcon>
                <DynamicSVGIcon iconUrl={Duration} width='2.5rem' />
              </OptIcon>
              <span>{localeStrings.Duration || 'Duration'}</span>
              <OptIcon rotate={90} width={1.5}>
                <DynamicSVGIcon iconUrl={ForwardNavArrowBlack} />
              </OptIcon>
            </OptBtn>
            {durationModal && (
              <DurationDropdownComp
                closeHandler={() => setDurationModal(false)}
              />
            )}
          </DurationContainer>
          {/* 'ZoomOut','ZoomIn','moveBack','moveForward','compress','popOut' */}
          {headerIcons.map((ele) => {
            const { role, iconUrl, onClick, rotate, label } = ele;
            return (
              <>
                {isIconVisible(role) && (
                  <KPVControllerIcon
                    classDefined={`
                    ${
                      role === 'moveForward' &&
                      new Date(moment(xMaxDomain).add(300, 's')).getTime() >=
                        new Date().getTime()
                        ? 'disable'
                        : ''
                    }
                    ${isIconDisabled(role) ? 'disable' : ''} ${
                      role === 'compress' && compressedView ? 'active' : ''
                    }`}
                    onClick={onClick}
                    rotate={rotate}
                    iconUrl={iconUrl}
                    name={label}
                  />
                )}
              </>
            );
          })}

          {!paneFullScreen && (
            <div style={{ position: 'relative' }}>
              <DropDownModal
                isActive={showKPVMenu}
                ContainerStyle={{ width: 'auto' }}
                TextStyle={{
                  fontSize: '2.1rem',
                  fontFamily: `${fontFamily.circularBook}`
                }}
                data={
                  showComparisonOpt
                    ? kpvChartingMenu.map((e) => ({
                        ...e,
                        label: e.label.replace(
                          '{{view}}',
                          comparisionMode ? ' normal ' : 'comparision'
                        )
                      }))
                    : kpvChartingMenuWithoutComparison
                }
                handleClick={handleMenuClick}
                // ContainerStyle={{ right: '0px' }}
                className='rightAlign'
                setModalIsVisibal={setShowKPVMenu}
              >
                <OptIcon className='pointer' onClick={handleKpvMenu}>
                  <DynamicSVGIcon iconUrl={Kabab} width='2.5rem' />
                </OptIcon>
              </DropDownModal>
            </div>
          )}
        </ChartOptionsDiv>
      </Header>

      {/* main content */}
      <PaneCards />

      {!paneFullScreen && !compressedView && (
        <AddPaneBtn onClick={handleAddPane}>
          {`+ ${localeStrings.Add || 'Add'} ${localeStrings.Pane || 'Pane'}`}
        </AddPaneBtn>
      )}
      {/* modals */}
      {showChartOpts && <ChartOptions setShowChartOpts={setShowChartOpts} />}
      {fullScreen && (
        <UpperLayer>
          <FullScreenKpvCharting setFullScreen={setFullScreen} />
        </UpperLayer>
      )}
      {error && (
        <Toast
          message={error}
          header={TOAST_TYPES.ERROR}
          fn={clearErrorHandler}
          style={{ position: 'fixed', bottom: 30, right: 0 }}
        />
      )}
      {showSavedashboard && (
        <SaveAnalytics onCancel={() => setShowSavedashboard(false)} />
      )}
      {showSaveConfirmation && (
        <SaveAnalyticsChanges
          closeSaveConfirmationModal={closeSaveConfirmationModal}
          showSaveConfirmation={showSaveConfirmation}
          setShowSavedashboard={setShowSavedashboard}
        />
      )}
    </Container>
  );
};

export default KPVCharting;
