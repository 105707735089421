/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Radiobutton from '../../component/Common/RadioButton/RadioButton';
import { Div, FlexContainer, Image } from '../../globalStyles';
import { Button, Table, Tag, Tbody, Td, Th, Thead, Tr } from './MyDashboards.style';
import kababSvg from '../../assets/icons/Filter/kabab.svg';
import { populateFilter } from '../../component/Filter/populateFilterItems';
import { Creators as filterCreators } from '../dashboard/FilterView/store';
import { Creators as myDashboardCreators } from './store/index';
import Loader from '../../component/Common/Loader/Loader';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal_V1';
import { filterDataOfMenu, myDashboardMenu } from '../../constants/dashboardsData';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import EditDashBoardModal from './EditDashboardModal';
import ConfirmationModal from '../../component/Common/ConfirmationBox/ConfirmationBox.view';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { apiEndPoints } from '../../services/axios/endPoints';
import { WebService } from '../../services/axios/webServices';
import { getFilterSelectionInp, populateFilterDashboard } from '../../component/Filter/utility/populateFilterDashboard';
import { formatedFilterData } from '../../component/Filter/utility/formatFilterData';
import { PAGE_TYPES } from '../../constants';
import { isCaseInsensitiveMatchFound } from '../../shared/utility/isItemMatched';
import { compare } from '../../shared/utility/objComparision';
import SortIcon from '../../component/Common/SortIcon';
import Toast from '../../component/Common/Toast';

const Dashboardtable = ({ getDashboards, searchValue, setSearchValue }) => {
  const { filterData, loading, menuIdofFilter } = useSelector((state) => state.filterData);
  const {
    myDashboards: orgData,
    loading: dashboardsLoading,
    myDashboardItemId,
    menuId: dashMenuId
  } = useSelector((state) => state.myDashboards);
  const filterPaths = useSelector((state) => state.home?.[PAGE_TYPES.FILTER]);
  const [sortBy, setSortBy] = useState('');
  // const dataInit = searchValue ? [...filteredDashboardData] : [...orgData];
  // can be either orgData or filteredData
  const [data, setData] = useState([...orgData]);
  // will be sorted through data only
  const [sortedData, setSortedData] = useState(data);
  // const [sortOrder, setSortOrder] = useState(0);

  useEffect(() => {
    setData(orgData);
  }, [orgData]);
  const sortData = () => {
    if (sortBy) {
      setSortedData([...data].sort((e1, e2) => compare(e1, e2, sortBy, 'inc')));
    }
  };

  useEffect(() => {
    sortData();
  }, [data, sortBy]);

  const [isFilterDataUpdated, setIsFilterDataUpdated] = useState(false);
  const [ids, setIds] = useState([]);
  const [menuId, setMenuId] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.loginData.userData);

  const [showLoader, setShowLoader] = useState(false);
  const addTabToObj = useCallback((payload) => dispatch(filterCreators.universalFilterSetter(payload)), [dispatch]);

  const populateData = (idsArg, menuIdArg) => {
    const { tempCummTags, tempSelObj } = populateFilter(filterData, idsArg || ids);
    dispatch(filterCreators.populateFilterTags(tempCummTags));
    dispatch(filterCreators.populateFilterSelectedObjs(tempSelObj));
    dispatch(filterCreators.setSelectedItem(filterData.map((group) => group.groupTitle)));
    setIsFilterDataUpdated(false);
    dispatch(filterCreators.universalFilterSetter({ key: 'showFilter', value: true }));
    dispatch(filterCreators.universalFilterSetter({ key: 'showFilterResult', value: true }));
    // history.push(filterPaths[menuIdArg || menuId]);
  };

  // const setDashboardDetails = (id, name, menuId) => {
  //   if (menuIdofFilter !== menuId) {
  //     dispatch(filterCreators.setMenuIdOfFilter(menuId));
  //   }
  //   if (myDashboardItemId !== id) {
  //     dispatch(myDashboardCreators.setDashboardDetails({ id, name, menuId }));
  //   }
  // };

  // const handleDashboardSelect = (dashId, dashName, idsArg, menuIdArg) => {
  //   setIds(idsArg);
  //   setMenuId(menuIdArg);
  //   setShowLoader(true);
  //   setDashboardDetails(dashId, dashName, menuIdArg);
  //   if (menuIdofFilter !== menuIdArg) {
  //     dispatch(filterCreators.getFilterData({ type: 'tab', tab: 'Summary', menuId: menuIdArg }));
  //   } else {
  //     populateData(idsArg, menuIdArg);
  //   }
  //   setIsFilterDataUpdated(true);
  // };

  useEffect(() => {
    if (!loading && isFilterDataUpdated) {
      populateData();
    }
  }, [loading]);

  // search results
  const isTitleMatched = (title) => isCaseInsensitiveMatchFound(title, searchValue);
  const isCatgegoryMatched = (category) => category && isCaseInsensitiveMatchFound(category, searchValue);
  const areTagsMatched = (SearchTags) => {
    let searchValueDup = searchValue.trim();
    if (searchValueDup.charAt(searchValueDup.length - 1) === ',') {
      searchValueDup = searchValueDup.slice(0, searchValueDup.length - 1);
    }
    return searchValueDup
      .replace(',', ' ')
      .split(' ')
      .every((e) => {
        if (!e) {
          return true;
        }
        return e && SearchTags.split(',').some((tag) => isCaseInsensitiveMatchFound(tag, e));
      });
  };

  useEffect(() => {
    if (searchValue.trim()) {
      const filteredDataDup = orgData.filter((i) => {
        const { DashboardName, SearchTags, Category } = i;
        return isTitleMatched(DashboardName) || isCatgegoryMatched(Category) || areTagsMatched(SearchTags || '');
      });
      setData(filteredDataDup);
    } else {
      setData([...orgData]);
    }
  }, [searchValue]);

  const [activeDashboardId, setActiveDashboardId] = useState(-1);
  const toggleMenuDropdown = (dashbord) => {
    // convert the payload to make it common to create dashboard
    const { DashboardId: id, DashboardName, Category, SearchTags, Shared } = dashbord;
    const editDetailsPayload = {};
    // console.log('id', id);
    if (id) {
      const { DashboardName, SearchTags, ...restDashboard } = dashbord;
      dispatch(
        myDashboardCreators.setDashboardDetails({
          form: { ...restDashboard, Searchtags: SearchTags, DashboardTitle: DashboardName },
          isInit: true
        })
      );
    }
    setActiveDashboardId(id >= 0 ? id : -1);
  };
  const closeMenu = () => {
    setActiveDashboardId(-1);
  };

  // console.log('activeDashboardId', activeDashboardId);
  // edit operation
  const [showEditModal, setShowEditModal] = useState(false);
  // const [form, setForm] = useState({});
  // useEffect(() => {
  //   if (showEditModal) {
  //     const dashBoardDetails = data.find((e) => e.DashboardId === activeDashboardId);
  //     // console.log('dashBoardDetails', dashBoardDetails);
  //     setForm(dashBoardDetails);
  //   } else {
  //     setForm({});
  //   }
  // }, [showEditModal]);

  // delete dashboard
  const [showDeleteConfModal, setShowDeleteConfModal] = useState(false);
  const dashBoardDeleteInit = {
    loading: false,
    successMsg: '',
    errMsg: '',
    id: -1
  };
  const [dashboardDelete, setDashboardDelete] = useState(dashBoardDeleteInit);
  const closeDeleteConfModal = () => {
    setShowDeleteConfModal(false);
  };
  const handleDelete = async () => {
    try {
      setDashboardDelete({ ...dashboardDelete, loading: true });
      const url = apiEndPoints.callDeleteSavedDashboards;
      const response = await WebService.post(url, {
        UserID: userData.email || userData.mail,
        DashboardId: dashboardDelete.id
      });
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        setDashboardDelete({ ...dashboardDelete, loading: false, successMsg: data });
        closeDeleteConfModal();
        getDashboards();
      } else {
        throw response;
      }
    } catch (error) {
      const payload = sagaCatchBlockHandling(error);
      setDashboardDelete({ ...dashboardDelete, loading: false, errMsg: payload });
    }
  };

  const handleClick = (item) => {
    switch (item.value) {
      case 'editDashboard':
        setShowEditModal(true);
        break;
      case 'printDashboard':
        break;
      case 'deleteDashboard':
        setShowDeleteConfModal(true);
        setDashboardDelete({ ...dashboardDelete, id: activeDashboardId });
        break;
      default:
        break;
    }
  };

  const filterPopInit = {
    fpLoading: false,
    fpError: ''
  };
  const [filterPop, setFilterPop] = useState(filterPopInit);
  const handleFilterPop = async (id) => {
    try {
      // console.log('id', id);
      setFilterPop({ fpLoading: true });
      const dashboardDetURL = `${apiEndPoints.getSavedDashboardSummary}`;
      const filterURL = `${apiEndPoints.getFilterCategory}`;

      const dashboardDetailsResp = await WebService.get(`${dashboardDetURL}${id}`);
      if (dashboardDetailsResp.status >= 200 && dashboardDetailsResp.status < 300) {
        const { menuid, tab, data, leafLevels } = dashboardDetailsResp.data.data;
        // console.log('1', menuid, tab, data, dashboardDetailsResp.data.data);
        const filterDataOfMenuResp = await WebService.get(filterURL, { params: { menuId: menuid, tab } });
        if (filterDataOfMenuResp.status >= 200 && filterDataOfMenuResp.status < 300) {
          // console.log('2', filterDataOfMenuResp.data.data);
          const filterPayload = { menuid, tab, data };

          // populate filter dashboard selections
          const { cumTags, cumSelectedObj } = populateFilterDashboard(filterPayload, filterDataOfMenuResp.data.data);
          const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
          dispatch(
            filterCreators.populateFilterData({ ...payload, menuIdofFilter: menuid, leafLevels: leafLevels || {} })
          );

          // fetch filterCategory data
          // history.push(filterPaths[menuid]);
          history.push({
            pathname: filterPaths[menuid],
            goDirectToResult: true,
            resType: filterDataOfMenuResp.data.resultType
          });
          const { data: filtData, resultType } = filterDataOfMenuResp.data;
          const { formatedData, keyTitleMapping } = formatedFilterData(filtData);
          dispatch(
            filterCreators.getFilterDataSuccess({
              data: formatedData,
              resultType,
              parent: tab,
              keyTitleMapping,
              menuId: menuid
            })
          );
          addTabToObj({ key: 'selectedParent', value: tab });
        } else {
          throw filterDataOfMenuResp;
        }
      } else {
        throw dashboardDetailsResp;
      }
    } catch (e) {
      const err = sagaCatchBlockHandling(e);
      setFilterPop({ ...filterPop, fpError: err.error, fpLoading: false });
    }
  };
  const clearError = () => {
    setFilterPop({ ...filterPop, fpLoading: false, fpError: '' });
  };
  const handleTagClick = (tag) => {
    if (!searchValue.includes(tag)) {
      setSearchValue(`${searchValue} ${tag}`.trim());
    }
  };

  return (
    <>
      <Div
        px='2'
        mt='2'
        style={{
          width: '100%',
          position: 'relative'
        }}
      >
        {(showLoader || filterPop.fpLoading) && <Loader position='fixed' />}
        <Table>
          <Thead>
            <tr>
              <Th
                style={{ cursor: 'pointer' }}
                onClick={() => setSortBy(sortBy === 'DashboardName' ? '' : 'DashboardName')}
                border
              >
                <FlexContainer className='jc_center ai_center' style={{ fontSize: '1.6rem' }}>
                  Title
                  <SortIcon isActive={sortBy === 'DashboardName'} />
                </FlexContainer>
              </Th>
              <Th
                style={{ cursor: 'pointer' }}
                onClick={() => setSortBy(sortBy === 'Category' ? '' : 'Category')}
                border
              >
                <FlexContainer className='jc_center ai_center' style={{ fontSize: '1.6rem' }}>
                  Category
                  <SortIcon isActive={sortBy === 'Category'} />
                  {/* {sortBy === 'Category' && DownArrow} */}
                </FlexContainer>
              </Th>
              <Th border>Search Tags</Th>
              <Th>Shared Dashboard</Th>
              <Th />
            </tr>
          </Thead>
          <Tbody>
            {dashboardsLoading ? (
              <Loader />
            ) : (
              // (searchValue ? filteredDashboardData : data).map((dashbord) => {
              (sortBy ? sortedData : data).map((dashbord) => {
                const {
                  DashboardId: id,
                  DashboardName: title,
                  Category: category,
                  SearchTags: searchTags,
                  Owner,
                  Shared: shared,
                  IsDeletable,
                  ids,
                  menuid,
                  tab
                } = dashbord;
                return (
                  <Tr
                    key={id}
                    // onClick={() => handleDashboardSelect(id, title, ids, menuId)}
                  >
                    <Td onClick={() => handleFilterPop(id)} border className='title'>
                      {title}
                    </Td>
                    <Td border>{category}</Td>
                    <Td border className='tags'>
                      {searchTags.split(',').map((tag) => (
                        <Tag className={!tag ? 'noBg' : ''} key={tag} onClick={() => handleTagClick(tag)}>
                          {tag || 'NA'}
                        </Tag>
                      ))}
                    </Td>
                    <Td className='shared'>
                      <Radiobutton margin='0 auto' active={shared} />
                    </Td>
                    <Td>
                      <div
                        style={{ position: 'relative' }}
                        className={`pointer ${IsDeletable === 'False' ? 'disable' : ''}`}
                        onClick={() => toggleMenuDropdown(dashbord)}
                      >
                        <DynamicSVGIcon iconUrl={kababSvg} width='2.2rem' />
                        <DropDownModal
                          data={myDashboardMenu}
                          isActive={activeDashboardId === id}
                          setModalIsVisibal={closeMenu}
                          handleClick={handleClick}
                          ContainerStyle={{ top: 20, right: 0 }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {/* modals */}
        <EditDashBoardModal
          showEditModal={showEditModal}
          // formInit={form}
          onCancel={() => setShowEditModal(false)}
          getDashboards={getDashboards}
        />
        <ConfirmationModal
          showModal={showDeleteConfModal}
          loading={dashboardDelete.loading}
          handleDelete={handleDelete}
          closeModalHandler={closeDeleteConfModal}
          message='Do you really want to delete the dashboard?'
        />
        {filterPop.fpError && (
          <Toast header='Error' className='fixed right' message={filterPop.fpError} fn={clearError} />
        )}
      </Div>
    </>
  );
};

export default Dashboardtable;
