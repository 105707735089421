/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiEndPoints } from '../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../store/utility/sagaErrorHandling';
import { generateSelectedFilter } from '../../component/ResultView/Iframe/generateSelectedFilter';
import { WebService } from '../../services/axios/webServices';
import Loader from '../../component/Common/Loader/Loader';
import DashboardForm from './components/DashboardForm';

const UpdateDashboard = ({ showEditModal, onCancel, getDashboards }) => {
  const { menuIdofFilter, cumulativeTags, selectedParent, filterData } = useSelector((state) => state.filterData);
  const userData = useSelector((state) => state.loginData.userData);
  const dashBoardUpdateInit = {
    loading: false,
    successMsg: '',
    errMsg: ''
  };

  const [dashboardPost, setDashboardPost] = useState(dashBoardUpdateInit);

  // init
  useEffect(() => {
    if (showEditModal) {
      setDashboardPost(dashBoardUpdateInit);
    }
  }, [showEditModal]);

  // console.log('showEditModal', showEditModal);
  const handleSave = async (form) => {
    try {
      setDashboardPost({ ...dashboardPost, loading: true });
      //   const filterPayload = generateSelectedFilter(cumulativeTags, filterData, true);
      const url = apiEndPoints.callUpdateDashboards;

      const response = await WebService.post(url, {
        ...form,
        UserID: userData.email || userData.mail
        // menuid: menuIdofFilter,
        // tab: selectedParent,
        // data: { ...filterPayload }
      });
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        setDashboardPost({ ...dashboardPost, loading: false, successMsg: data });
        onCancel();
        getDashboards();
      } else {
        throw response;
      }
    } catch (error) {
      const payload = sagaCatchBlockHandling(error);
      setDashboardPost({ ...dashboardPost, loading: false, errMsg: payload.error });
    }
  };

  if (dashBoardUpdateInit.loading) return <Loader />;
  if (!showEditModal) return <></>;
  return (
    <DashboardForm
      onCancel={onCancel}
      handleSave={handleSave}
      loading={dashboardPost.loading}
      error={dashboardPost.errMsg}
    />
  );
};

export default UpdateDashboard;
