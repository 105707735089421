/* eslint-disable max-len */
import React from 'react';
import { Div } from './KeyInput.style';
import { ContentText, Span, Tooltip } from './KeyValueLayout.style';

const KeyInputLayoutView = (props) => {
  const {
    isLabelActive,
    children,
    label,
    tooltip,
    className,
    fieldClassName, // field layout
    labelWidth, // width of the label
    width,
    isRequired, // is field required
    showTooltip = true
  } = props;
  return (
    <div className={`field-col ${fieldClassName || 'col-md'}`} style={{ display: 'flex' }}>
      <Div className={`${className}  field-layout`} width={width} alignItems="flex-start">
        <ContentText className='formLabel'>
          {/* radio button indication if any */}
          {isLabelActive}
          <div style={{ position: 'relative' }}>
            <Span
              labelWidth={labelWidth}
              className={`${className} ${isRequired ? 'required' : ''} text-label text-overflow`}
            >
              {label}
            </Span>
            {showTooltip && <Tooltip className='tooltip'>{tooltip || label}</Tooltip>}
          </div>
        </ContentText>

        <Span className='colonMargin field-colon'>{label ? ':' : null}</Span>
        {/* input of any type inputfield/dropdown/comment-editor */}
        {children}
      </Div>
    </div>
  );
};

export default KeyInputLayoutView;
