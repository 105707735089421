/* eslint-disable no-console */
/* eslint-disable max-len */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { filteredMenu } from '../../../constants/routeConfig';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
// import  { menuData } from '../../../constants/sampleData';

export function* getMenuSaga({ payload }) {
  const { company, userData } = payload;

  try {
    yield put(Creators.getMenuStart());

    const fullURL = yield `${apiEndPoints.getMenu}`;
    const response = yield WebService.get(fullURL, {
      params: {
        company,
        userid: userData?.userId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const menuData = response.data.data[0];

      // const menuData = data[0];

      // const { menu, subMenuOptions } = menuData;
      const menu = menuData.filter((menu) => menu.PId === 0);

      const { finalRoutes, componentPaths } = filteredMenu(menuData);
      // console.log('finalRoutes', finalRoutes, 'rest', { ...componentPaths });
      const subMenuOptions = finalRoutes
        .filter((item) => !!item.subMenuOptions)
        .map((item) => ({
          path: item.path,
          subMenuOptions: item.subMenuOptions.split(',')
        }));
      yield put(
        Creators.getMenuSuccess({ menu: menu || [], finalMenu: finalRoutes, subMenuOptions, ...componentPaths })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setError(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setError(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setError(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setError(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setError(payload));
    }
  }
}

export function* getNotificationsCountSaga({ payload }) {
  const { userId } = payload;
  try {
    // console.log('aaa');
    yield put(Creators.fetchDetailsStart({ key: 'notificationsCount' }));

    const fullURL = yield `${apiEndPoints.getNotifications}`;
    const response = yield WebService.get(fullURL, {
      params: {
        UserID: userId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const countData = response.data.data;
      yield put(Creators.getNotificationsCountSuccess({ data: countData }));
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    yield put(Creators.fetchDetailsFailure(error));
  }
}

export function* setDefaultUrlSaga({ payload }) {
  const { UserId, Url } = payload;
  try {
    yield put(Creators.setDefaultUrlStart());
    const fullURL = yield `${apiEndPoints.setDefaultURL}`;
    const response = yield WebService.post(fullURL, {
      UserId,
      Url
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(
        Creators.setDefaultUrlSuccess({ defaultUrl: response.data?.data[0][0]?.Url || '' })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    yield put(Creators.setDefaultUrlFailure(error));
  }
}

export function* getDefaultUrlSaga({ payload }) {
  const { userId } = payload;
  try {
    // console.log('aaa');
    yield put(Creators.getDefaultUrlStart({ key: 'notificationsCount' }));

    const fullURL = yield `${apiEndPoints.getDefaultUrl}`;
    const response = yield WebService.get(fullURL, {
      params: {
        UserID: userId
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const defaultUrl = response.data?.data[0]?.Url || '';
      yield put(Creators.getDefaultUrlSuccess({ defaultUrl }));
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    yield put(Creators.getDefaultUrlFailure(error));
  }
}
