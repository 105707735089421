/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import { useActions } from '../../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import PaneGraph from '../../../KPVCharts/PaneCard/PaneGraph';
import { getPlotData } from '../../../KPVCharts/utils/formatPlotData';
import { getKPVShortlisted } from '../../../KPVCharts/utils/getKPVShortlisted';
import { getPlotDataPayload } from '../../../KPVCharts/utils/getPlotDataPayload';
import Loader from '../../../Loader/Loader';
import { Container } from '../../Common/WidgetCommons.style';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import { HideHeader, HideBorder, settingsBlack, DeleteBlack, Export, FullScreen, AddToHome, RemoveFromHome, RemoveHomeMenu } from '../../../../assets/icons';

const Analytics = (props) => {
  const {
    componentData,
    id,
    isVisible,
    layoutPos,
    fullView,
    WIDFromProp,
    HDId
    // xMinDomainProp, xMaxDomainProp, layoutPos
  } = props;
  const { WidgetName, infoText } = componentData || {};
  const [rawPlotData, setRawPlotData] = useState({});
  const [err, setErr] = useState('');
  const { plotDataAvl, singlePane, spcDetails, ...rest } = componentData;
  const { chartType, chartTypeCategory, category } = rest;
  const { setCompleteUpsertPaneData, setMachinesShortlisted } = useActions();
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const history = useHistory();
  const WID = WIDFromProp || layoutPos.i?.split('_')?.[0];
  useEffect(() => {
    // is viewable and data not yet fetched
    if (isEmpty(rawPlotData)) {
      // console.log('fetching data for id=======', id, isVisible);
      const reqPayload = { ...rest, spc: spcDetails, RequestId: 'anything' };
      const { spcDetails: finalSpcDetails, ...restFormatedPayload } =
        getPlotDataPayload([reqPayload])?.[0];
      // renaming spcDetails to spc just for spc charts
      const formatedPayload = {
        ...restFormatedPayload,
        spc: finalSpcDetails
      };
      const fullURL = `${apiEndPoints.getPlotData}`;
      // WebService.post(fullURL, reqPayload)
      WebService.post(fullURL, formatedPayload)
        .then((res) => {
          if (!(res.status >= 200 && res.status < 300)) {
            throw res;
          }
          const payload = { ...res.data, reqPayload: componentData };
          const plotData = getPlotData(
            payload[category.toLowerCase()][0],
            chartType,
            chartTypeCategory
          );
          setRawPlotData(plotData);
        })
        .catch((e) => {
          const errPayload = sagaCatchBlockHandling(e);
          // console.log('err', errPayload.error);
          setErr(errPayload.error);
        });
    }
  }, []);
  const widgetName = WidgetName || 'Analytics';
  const handleConfiguration = () => {
    const {
      kpv,
      kpvDetails,
      stateDetails,
      spcDetails,
      machinesSelected: machinesSelectedProp
    } = componentData;
    const machinesSelected =
      kpv || kpvDetails || stateDetails || spcDetails || machinesSelectedProp;
    setCompleteUpsertPaneData({ ...componentData, machinesSelected });
    setMachinesShortlisted(getKPVShortlisted(machinesSelected));
    history.replace({
      pathname: '/home/kpv-charting/edit-pane',
      state: { isFromWidgets: true, WID, widgetName }
    });
  };
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const isPreviewInstance = useSelector((state) => state.widgets.isPreviewInstance);

  const widgetOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    {
      id: 1,
      label: 'configure',
      value: 'configure',
      iconUrl: settingsBlack,
      handler: handleConfiguration
    },
    // {
    //   id: 5,
    //   label: 'Export',
    //   value: 'export',
    //   // type: 'radio',
    //   iconUrl: Export,
    //   subMenu: [
    //     { id: 1, label: 'Image', value: 'pngExport' },
    //     {
    //       id: 2,
    //       label: 'PDF',
    //       value: 'pdfExport'
    //     }
    //   ]
    // },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    }, {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder,
    }
  ];
  const widgetOptionsInstance = HDId ? [
    {
      id: 1,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    }, {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder,
    }
  ] : [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    }, {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder,
    }
  ];

  const widgetOptionsInstancePOp = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    }, {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder,
    }
  ];
  const ContentContainer = () => {
    if (err) {
      return <p>{`Error: ${err}`}</p>;
    }
    return !isEmpty(rawPlotData) && !isEmpty(componentData?.showDetails) ? (
      <PaneGraph
        index={id}
        fillLayout
        chartTypeCategory={chartTypeCategory}
        paneData={{ rawPlotData, ...componentData }}
        position='right'
      />
    ) : (
      <Loader />
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
    >
      { (
        <WidgetHeaderView
          id={id}
          WID={WID} // WID
          WidgetName={widgetName}
          widgetOptions={WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePOp : widgetOptionsInstance : widgetOptions}
          fullView={fullView}
        />
      )}
      {/* <span>{`${WidgetName}`}</span> */}
      <Container>{ContentContainer()}</Container>
    </div>
  );
};
export default memo(Analytics);
