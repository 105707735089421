/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function DataInput(props) {
  const { handleChange } = props;

  return (
    <form className='form-inline'>
      <div className='form-group'>
        <label htmlFor='file'>Spreadsheet</label>
        <input type='file' className='form-control' id='file' accept='.xlsx,.xls' onChange={handleChange} />
      </div>
    </form>
  );
}

export default DataInput;
