/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import KeyInputLayoutView from '../../KeyValueLayout/KeyInputLayout.view';
import { ItemContainer } from '../../../KPVCharts/EditOptions/EditOptions.style';
import { Label } from '../../../UpsertWidget/WidgetForm/AddWidgetForm.style';
import { CheckBox } from '../../../UpsertPane/UpsertPane.style';
import { ReactComponent as CheckMark } from '../../../../assets/icons/KPV/checkmark.svg';
import { useActions } from '../../../../store/hooks/useActions';
import { getFetchDetailsOfWidgetData } from '../../../Widget/utils';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import { getReqPayloadForDependentField } from '../../../UpsertWidget/WidgetForm/Utility';
import { Span } from '../../KeyValueLayout/KeyValueLayout.style';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import ToggleableFieldValue from '../../GenericForm/ToggleableFieldValue';
import { INPUT_TYPES } from '../../../../constants';
import { generateSelectedObjectTree } from '../../../ResultView/Iframe/generateSelectedObjectTree';
import { generateSelectedFilter } from '../../../ResultView/Iframe/generateSelectedFilter';

const CheckboxTypeComponent = (props) => {
  const {
    item,
    value,
    handleChange,
    formStructure, // formstructure
    formInput, // for handling dependency
    tobeRefetched = false, // check for refetching the data
    resetFieldsTobeRefetched = () => {}, // reset the FieldsTobeRefetched
    isEditable,
    fieldClassName,
    isInstanceCreation = false
  } = props;
  const {
    // setToastError,
    setFormStructureFailure
  } = useActions();
  const filterState = useSelector((state) => state.objectTreeData);
  const filterData = useSelector((state) => state.filterData.filterData);
  const cumulativeTagsFilter = useSelector((state) => state.filterData.cumulativeTags);
  // const isEquipmentSelect = useSelector((state) => state.Widgets.isEquipmentSelect);
  const isEquipmentSelect = useSelector((state) => state.widgets.isEquipmentSelect);
  const {
    cumulativeTags,
    objectTreeData,

  } = filterState;
  const { label, key, dataSource, isRequired } = item;
  // TODO: handle api response
  const { data, dataKey, source, responseKeys, dependencies, selectAll, params } = dataSource;
  const { showOption, defaultValue } = selectAll || {};
  const [options, setOptions] = useState(data);
  const { labelKey, valueKey } = responseKeys || {};
  const labelKeyFinal = labelKey || 'label';
  const valueKeyFinal = valueKey || 'value';
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (items, selectAll) => {
    try {
      // parameters can be left undefined so or statements are used inside
      let payload = [];
      if (selectAll || (options || [])?.length !== (value || [])?.length) {
        payload = items || [...options];
      }
      // if (isEmpty(payload)) {
      //   payload = [{}];
      // }
      handleChange(
        payload,
        {
          key,
          itemValueKey: valueKey
        },
        { isDirectUpdate: true }
      );
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (defaultValue && isEmpty(value)) {
      // console.log('during fetch selectAll?', value, label, key, formInput, isEmpty(value));
      // enable select all only for fresh form
      handleSelectAll(data, true);
    }
  }, [data]);
  const fetchDetails = async (args) => {
    try {
      setLoading(true);
      const { additionalPayload } = args || {};
      if (dataKey === 'source' && source) {
        const fetchUrl = dataSource.source; // {method, UrlType, Url}
        const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl);
        // test
        // const method = 'post'

        let payload = {};
        dependencies?.forEach((dependent) => {
          // payload = { formInput: { ...payload, [dependent]: formInput[dependent] } };

          // if (isInstanceCreation) {
          //   const dependentTree = generateSelectedFilter(
          //     cumulativeTagsFilter,
          //     filterData,
          //     true,

          //   );

          //   payload = { formInput: { ...payload, ...dependentTree } };
          // } else {
          const dependentTree = generateSelectedObjectTree(
            cumulativeTags,
            objectTreeData,
            true,
            dependent
          );
          payload = { formInput: { ...payload, ...dependentTree } };
        });

        if (method === 'get') {
          payload = { params: { ...params, ...additionalPayload } };
        }

        // Commenting since we are changing dependency api calls to post method
        // console.log('fetching....', key, payload);
        // const resp = await WebService?.[method](fullURL, payload);

        const resp = await WebService?.[method](fullURL, payload);
        if (resp.status >= 200 && resp.status < 300) {
          setOptions(resp.data?.data);
          if (defaultValue && isEmpty(value)) {
            // console.log('during fetch selectAll?', value, label, key, formInput, isEmpty(value));
            // enable select all only for fresh form
            handleSelectAll(resp.data?.data, true);
          }
        } else {
          throw resp;
        }
        // }
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      setOptions([]);
      console.log('e', e, error.error);
      // setToastError(error.error);
      setFormStructureFailure(error.error);
    } finally {
      resetFieldsTobeRefetched();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tobeRefetched) {
      // console.log('refetching fetch2....', key);
      let newPayload = {};
      if (!isEmpty(formStructure)) {
        newPayload = getReqPayloadForDependentField(formInput, dependencies, formStructure);
      }
      if (!isEquipmentSelect) {
        fetchDetails({ additionalPayload: newPayload });
      }
    }
  }, [tobeRefetched, filterState, isEquipmentSelect]);
  // console.log('options', label, options, value);

  if (loading) {
    return (
      <KeyInputLayoutView fieldClassName={fieldClassName} label={label} className='show_stats'>
        {loading && <Span>Loading...</Span>}
      </KeyInputLayoutView>
    );
  }
  if (isEmpty(options) || !Array.isArray(options)) {
    return (
      <KeyInputLayoutView fieldClassName={fieldClassName} label={label} className='show_stats' isRequired={isRequired}>
        <Span>NA</Span>
      </KeyInputLayoutView>
    );
  }
  // console.log('valuee', valueKeyFinal, key, value);
  return (
    <div>
      <KeyInputLayoutView
        fieldClassName={fieldClassName}
        label={label}
        tooltip={item.tooltip}
        className='show_stats'
        isRequired={isRequired}
      >
        <ToggleableFieldValue type={INPUT_TYPES.CHECKBOX} isEditable={isEditable} value={formInput?.[key]}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              height: 'auto',
              maxHeight: '20rem',
              // flexWrap: 'wrap',
              padding: '1rem',
              overflowY: 'auto',
              minWidth: '35rem'
            }}
          >
            {showOption && (
              <ItemContainer className='checkbox-container'>
                <CheckBox
                  className={(options || [])?.length === (value || [])?.length ? 'active' : 'unactive'}
                  onClick={() => handleSelectAll()}
                >
                  <CheckMark />
                </CheckBox>
                <Label>Select All</Label>
                {/* not yet used till now */}
                {/* {item.hasLink && (
            <Label>
              <a style={{ fontSize: '1.5rem' }} href='http://mms.panorbitprojects.com/'>
                Add
              </a>
            </Label>
          )} */}
              </ItemContainer>
            )}
            {(options || [])?.map((item, index) => (
              <ItemContainer key={index.toString()} className='checkbox-container'>
                <CheckBox
                  className={
                    (Array.isArray(value) ? value : [])?.some((e) => e?.[valueKeyFinal] === item?.[valueKeyFinal])
                      ? 'active'
                      : 'unactive'
                  }
                  onClick={() => {
                    handleChange(item, { key, itemValueKey: valueKey });
                  }}
                >
                  <CheckMark />
                </CheckBox>
                <Label>{item?.[labelKeyFinal]}</Label>
                {/* not yet used till now */}
                {/* {item.hasLink && (
                <Label>
                  <a style={{ fontSize: '1.5rem' }} href='http://mms.panorbitprojects.com/'>
                    Add
                  </a>
                </Label>
              )} */}
              </ItemContainer>
            ))}
          </div>
        </ToggleableFieldValue>
      </KeyInputLayoutView>
    </div>
  );
};
export default CheckboxTypeComponent;
