/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { StateStoring } from 'devextreme-react/data-grid';
import { createReducer } from 'reduxsauce';
import { userData } from '../../../component/Users/data';
import { tableItemFlags } from '../../../constants';
import { Types } from './action';

const INITIAL_STATE = {
  usersData: [],
  usersMeta: {},
  usersUploadedData: [],
  searchQueries: {},
  valueForOutline: [],
  loading: false,
  error: null,

  bulkUpdateList: [],
  bulkUpdateListMeta: {},
  updatedBulkList: [],
  updatedBulkMeta: {},
  updationState: '',
};

const userReducerValueSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value
  };
};

const updateUsersData = (state = INITIAL_STATE, action) => {
  // this updates the list by adding newlyAdded flag into items
  const { freshRecords, resolvedRecords } = action.payload;
  const updatedUsers = state.usersData?.map((e) => {
    const { employeeId } = e;
    if (resolvedRecords?.[employeeId]) {
      return { ...resolvedRecords[employeeId], [tableItemFlags.NEWLY_ADDED]: true };
    }
    return e;
  });
  return {
    ...state,
    usersData: [
      ...((freshRecords || []).map((e) => ({ ...e, [tableItemFlags.NEWLY_ADDED]: true })) || []),
      ...updatedUsers
    ]
  };
};

const resetUserDataFlags = (state = INITIAL_STATE, action) => ({
  ...state,
  userData: state.usersData.map((e) => {
    delete e[tableItemFlags.NEWLY_ADDED];
    return e;
  })
});

const getUserTableData = (state = INITIAL_STATE, action) =>
  //   console.log(action.payload, 'getusertabledata');
  ({
    ...state,
    usersData: [...action.payload]
  });
const setUserTableData = (state = INITIAL_STATE, action) =>
  //   console.log(action.payload, 'getusertabledata');
  ({
    ...state,
    usersData: [action.payload.formDetails, ...state.usersData]
  });

const updateUserTableData = (state = INITIAL_STATE, action) => {
  console.log('action==>', action.payload);
  const { id, formDetails } = action.payload;
  const newData = state.usersData.map((ele) => {
    if (ele.employeeId === id) {
      return formDetails;
    }
    return ele;
  });
  return {
    ...state,
    usersData: newData
  };
};

const setSearchQueries = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const latestSavedValue = (state = INITIAL_STATE, action) =>
  // console.log(action.payload, 'latlatestSavedValue');
  ({
    ...state,
    valueForOutline: [action.payload]
  });
const getUsersListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const getUsersListSuccess = (state = INITIAL_STATE, action) => {
  const { data, meta } = action.payload;
  return {
    ...state,
    usersData: data,
    usersMeta: meta,
    loading: false
  };
};
const getUsersListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false
  };
};

const getBulkUsersUpdateListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const getBulkUsersUpdateListSuccess = (state = INITIAL_STATE, action) =>
  // const { data, meta } = action.payload;
  ({
    ...state,
    loading: false,
    bulkUpdateList: action.payload.data,
    bulkUpdateListMeta: action.payload.meta,
  });
const getBulkUsersUpdateListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false
  };
};

const updateBulkUsersStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});
const updateBulkUsersSuccess = (state = INITIAL_STATE, action) =>

  (
    {
      ...state,
      updatedBulkList: action.payload.data,
      updatedBulkMeta: action.payload.meta,
      loading: false,
      updationState: 'success'
    });
const updateBulkUsersFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    loading: false,
    updationState: 'failure'
  };
};

const resetUpdationStatus = (state = INITIAL_STATE) => ({
  ...state,
  updationState: '',

});
const resetUpdationData = (state = INITIAL_STATE) => ({
  ...state,
  updationState: '',
  bulkUpdateList: [],
  bulkUpdateListMeta: {},
  updatedBulkList: [],
  updatedBulkMeta: {},
});
const HANDLERS = {
  [Types.USER_REDUCER_VALUE_SETTER]: userReducerValueSetter,
  [Types.UPDATE_USERS_DATA]: updateUsersData,
  [Types.RESET_USER_DATA_FLAGS]: resetUserDataFlags,
  [Types.GET_USER_TABLE_DATA]: getUserTableData,
  [Types.SET_USER_TABLE_DATA]: setUserTableData,
  [Types.UPDATE_USER_TABLE_DATA]: updateUserTableData,
  [Types.SET_SEARCH_QUERIES]: setSearchQueries,
  [Types.LATEST_SAVED_VALUE]: latestSavedValue,
  [Types.GET_USERS_LIST_START]: getUsersListStart,
  [Types.GET_USERS_LIST_SUCCESS]: getUsersListSuccess,
  [Types.GET_USERS_LIST_FAILURE]: getUsersListFailure,
  [Types.GET_BULK_USERS_UPDATE_LIST_START]: getBulkUsersUpdateListStart,
  [Types.GET_BULK_USERS_UPDATE_LIST_SUCCESS]: getBulkUsersUpdateListSuccess,
  [Types.GET_BULK_USERS_UPDATE_LIST_FAILURE]: getBulkUsersUpdateListFailure,
  [Types.UPDATE_BULK_USERS_START]: updateBulkUsersStart,
  [Types.UPDATE_BULK_USERS_SUCCESS]: updateBulkUsersSuccess,
  [Types.UPDATE_BULK_USERS_FAILURE]: updateBulkUsersFailure,
  [Types.RESET_UPDATION_STATUS]: resetUpdationStatus,
  [Types.RESET_UPDATION_DATA]: resetUpdationData,
};

export const UsersReducer = createReducer(INITIAL_STATE, HANDLERS);
