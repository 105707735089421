import styled from 'styled-components';
import { FlexContainer } from '../../../globalStyles';

export const Container = styled.div`
  width: ${({ width }) => width || '98%'};
  height: ${({ height }) => height || '100%'};
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: ${({ position }) => position || 'absolute'};
  z-index: 10000;
  /* background-color: rgba(0, 0, 0, 0.4); */

  & path {
    stroke: ${({ theme }) => theme.specificDetails.primary};
  }
  & circle {
    fill: ${({ theme }) => theme.specificDetails.primary};
  }
`;

export const WrapperLoderContainer = styled(FlexContainer)`
  margin: auto; // align horizontal
  margin-top: -1rem;
  position: relative;
  width: 40%;
  height: 4rem;

  /* background: red; */
  & * {
    fill: ${({ theme }) => theme.body} !important;
  }
`;
