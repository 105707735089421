/* eslint-disable max-len */
import React from 'react';
import {
  GridContainer,
  ItemContainer,
  BlankDiv,
  Label,
  SubLabel,
  ParamContainer
} from '../StatusValueWidget.jsx/StatusValueWidget.style';
import { formatDetailedStatusData } from '../../../../shared/utility/formatDetailedStatusData';

const GridMultipleTable = (props) => {
  const { equipments: rawData, zoomLevel, fullView } = props;

  // The RawData received will be format as Required for OEE Equipment Donut.
  // To Reformat to the format which is required for Detailed Status value
  const equipments = formatDetailedStatusData(rawData);

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <GridContainer zoomLevel={fullView ? 1 : zoomLevel} className='multiple_status_widget zoom'>
        {equipments?.map((equipment, index) => {
          const Keys = Object.keys(equipment?.data);
          return (
            <div>
              <BlankDiv
                className='multiple_status_widget'
                background={equipment?.title?.bgcolor}
                color={equipment?.title?.textColor}
              >
                {equipment?.title?.label}
              </BlankDiv>

              <ParamContainer>
                {Keys.map((item, index) => (
                  <ItemContainer
                    key={index.toString()}
                    className='multiple_status_widget'
                    background={equipment?.data[item].bgcolor}
                    color={equipment?.data[item].textColor}
                  >
                    <div style={{ display: 'flex' }}>
                      <Label className='multiple_status_widget'>{equipment?.data[item].label}</Label>
                    </div>
                    <SubLabel>{equipment?.data[item].value}</SubLabel>
                    <SubLabel>{equipment?.data[item].target}</SubLabel>
                  </ItemContainer>
                ))}
              </ParamContainer>
            </div>
          );
        })}
      </GridContainer>
    </div>
  );
};

export default GridMultipleTable;
