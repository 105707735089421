/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-var */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DonutWidget from './DonutWidget';
import Loader from '../../../../component/Common/Loader/Loader';
import Toast from '../../../../component/Common/Toast';
import { CenterDiv } from '../../../../component/Card';
import { Text } from '../../../../component/Widget/WidgetComponents/GaugeChart/GaugeChart.style';

const OeeDonutWidget = (props) => {
  const [input, setInput] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const { donutData, donutMeta, loading, error, zoomLevel, WID } = props;

  const clearFilterToastMessqge = () => {
    dispatch.UpsertWidgetCreators.getWidgetDataFailure('');
  };

  return (
    <>
      {loading ? (
        <Loader position='relative' />
      ) : donutData ? (
        <DonutWidget
          className='zoom'
          tabs={donutData}
          donutData={donutData}
          donutMeta={donutMeta}
          zoomLevel={zoomLevel}
          WID={WID}
        />
      ) : (
        <>
          <CenterDiv className='jc_center ai_center'>
            <Text>No Data...</Text>
          </CenterDiv>
        </>
      )}
      {error && <Toast style={{ right: 20 }} fn={clearFilterToastMessqge} header='Error' message={error} />}
    </>
  );
};

export default OeeDonutWidget;
