/* eslint-disable max-len */
import React from 'react';
import { searchBlack } from '../../assets/icons';
import { FlexContainer } from '../../globalStyles';
import DynamicSVGIcon from './DynamicSVGIcon';
import { InputCotainer } from './Input/Input';

const SearchBar = (props) => {
  const { input, onChange, searchBarRef, width = '30rem', placeholder = 'Search for schedules' } = props;

  return (
    <FlexContainer className=' ai_center relative' style={{ width }}>
      <InputCotainer value={input} ref={searchBarRef} onChange={onChange} placeholder={placeholder} width={width} />
      <div className='searchIcon'>
        <DynamicSVGIcon width='1.8rem' iconUrl={searchBlack} />
      </div>
    </FlexContainer>
  );
};

export default SearchBar;
