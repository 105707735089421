/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { device, fontFamily, fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { ActiveDivider } from '../../../views/dashboard/FilterView/Filter.style';

const SubMenuOptionsView = ({ subMenus, activeSubMenu, tabHandler }) => (
  <FlexContainer>
    {subMenus?.map((item, i) => (
      <TabHeadingBox className='pointer' key={i} onClick={() => tabHandler(item)}>
        <TabHeadingText active={activeSubMenu === item}>{item}</TabHeadingText>
        {activeSubMenu === item && <ActiveDivider />}
      </TabHeadingBox>
    ))}
  </FlexContainer>
);

export default SubMenuOptionsView;

const TabHeadingBox = styled.div`
  margin: 0px 1.5rem 0px 1.5rem;
  position: relative;
  display: flex;
  height: 7rem;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    margin: 0 10px 0 10px;
  }
`;

export const TabHeadingText = styled.p`
  font-size: ${fontSize.headingText};
  color: ${({ theme, active }) => (active ? theme.contrast.primary : theme.contrast.secondary)};
  font-family: ${({ active }) => (active ? fontFamily.circularBold : fontFamily.circularBook)};
  @media ${device.tablet} {
    font-size: 15px;
  }
`;
