/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavItemsContent from '../../component/NavItemsContent/NavItemsContent.view';
import { commonRoutes } from '../../routes/route';
import { fetchUserAndLocation } from '../../component/Sidenav/FetchStatics';

const Search = () => {
  const location = useLocation();
  const searchResults = useSelector((state) => state.globalSearchData.searchResults);
  const { userData } = useSelector((state) => state.loginData);
  const [menu, setMenu] = useState([]);
  const parsePaths = (searchResults) => {
    const menuTemp = [];
    searchResults?.forEach((ele) => {
      const obj = {
        path: ele.link,
        category: ele.link.split('/')[2] || ele.link.split('/')[1],
        title: ele.title,
        description: ele.description
      };
      menuTemp.push(obj);
    });
    setMenu(menuTemp);
  };
  useEffect(() => {
    const name = commonRoutes.find((ele) => ele.path == location.pathname);
    fetchUserAndLocation(location.pathname, userData, { name: name.name });
  }, []);

  useEffect(() => {
    parsePaths(searchResults);
  }, [searchResults]);

  return <NavItemsContent menu={menu} />;
};
export default Search;
