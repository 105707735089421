/* eslint-disable max-len */
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PAGE_TYPES } from '../../../constants';
import { useActions } from '../../../store/hooks/useActions';
import { getFieldDependents } from './Utility';
import { isEmpty } from '../../../shared/utility/isEmpty';
import AddWidgetFormTree from './AddWidgetFormTree';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';

const FormPage = () => {
  const { formStructure, formInput, widgetType, widgetLabel, widgetCategory, loading, error } = useSelector(
    (state) => state.upsertWidgetData
  );

  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const templateInstanceFormInput = useSelector((state) => state.widgets.templateInstanceFormInput);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const isKpvConfigured = useSelector((state) => state.widgets.isKpvConfigured);
  const selectedKpvList = useSelector((state) => state.widgets.selectedKpvList);
  const WidgetTypeMenuRoutes = useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]);
  const { setFormInput, setFormStructure, getWidgetData, setToastError, getWidgetDataSuccess } = useActions();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { isEdit, WID, fromInstanceList = false } = state || {};
  const templateCommonParams = templateInstanceFormInput?.formInput?.common;
  const templateWidgetParams = templateInstanceFormInput?.formInput?.[widgetType];
  useEffect(() => {
    if (widgetType) {
      setFormStructure({ WTypeID: widgetTypesMenu?.[widgetType]?.id });
    }
  }, [widgetType]);

  const navigateToDashboard = () => {
    // handle based on menu
    // console.log(WidgetTypeMenuRoutes[menuIdofWidget], WidgetTypeMenuRoutes, menuIdofWidget);
    // history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    history.goBack();
    // old implementation
    // history.push('/home');
  };

  const handleBack = () => {
    navigateToDashboard();
  };

  const onSuccessNavigation = () => {
    // Use the Prop fromInstanceList to detect the individual widget instance update from widget Instance list page
    history.push({ pathname: '/home/create-widget', state: { isEdit, fromInstanceList, WID } });
  };

  const AddWidgetFormRef = useRef();

  const handleSave = async () => {
    const localFormInput = AddWidgetFormRef.current.getFormInput();
    setFormInput(localFormInput);
    // get widgetData
    if (widgetType === 'TEXT_HTML') {
      try {
        const fullURL = apiEndPoints.dummyEnd;
        const resp = await WebService.post(fullURL, {
          type: widgetType,
          widgetPayload: localFormInput,
          htmlContent: 'lorem <b>ipsum</b>',
          onSuccessNavigation,
          detailsMapping: {
            data: 'donutData',
            meta: 'donutMeta'
          },
          widgetCategory,
        });
        if (resp.status >= 200 && resp.status < 300) {
        // setLayoutComponentUpdated(false);
          getWidgetDataSuccess({ data: resp?.data?.data?.htmlContent,
            meta: resp?.data?.data?.widgetPayload?.dataSource });
          onSuccessNavigation();
        } else {
          history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
          setToastError('Failed to Update Template');
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('e', e, error);
      // setToastError(error);
      }
    } else if (widgetType === 'EQUIPMENT_KPV_STATUS_VALUE') {
      getWidgetData({
        type: widgetType,
        widgetPayload: localFormInput,
        allKPV: !isKpvConfigured,
        KPVs: selectedKpvList,
        onSuccessNavigation,
        detailsMapping: {
          data: 'donutData',
          meta: 'donutMeta'
        },
        widgetCategory,
        fetchUrl: widgetTypesMenu?.[widgetType]?.fetchUrl
      });
    } else {
      getWidgetData({
        type: widgetType,
        widgetPayload: localFormInput,
        onSuccessNavigation,
        detailsMapping: {
          data: 'donutData',
          meta: 'donutMeta'
        },
        widgetCategory,
        fetchUrl: widgetTypesMenu?.[widgetType]?.fetchUrl
      });
    }
  };
  return (

    <>
      <AddWidgetFormTree
        ref={AddWidgetFormRef}
        data={formStructure}
        disabledFields={[
          ...(!isEmpty(templateCommonParams) ? Object.keys(templateCommonParams) : []),
          ...(!isEmpty(templateWidgetParams) ? Object.keys(templateWidgetParams) : [])
        ]} // due to template
        formInput={formInput}
        setFormInput={setFormInput}
        showHeader
        header={widgetLabel ? `${isEdit ? 'Edit' : 'Add'} ${widgetLabel} Pane` : activeTab.label}
        handleBack={handleBack}
        handleSave={handleSave}
        formStructureFieldDepenents={getFieldDependents(formStructure)}
      />
    </>
  // ) : (
  //   <>
  //     <AddWidgetForm
  //       ref={AddWidgetFormRef}
  //       data={formStructure}
  //       disabledFields={[
  //         ...(!isEmpty(templateCommonParams) ? Object.keys(templateCommonParams) : []),
  //         ...(!isEmpty(templateWidgetParams) ? Object.keys(templateWidgetParams) : [])
  //       ]} // due to template
  //       formInput={formInput}
  //       setFormInput={setFormInput}
  //       showHeader
  //       header={widgetLabel ? `${isEdit ? 'Edit' : 'Add'} ${widgetLabel} Pane` : activeTab.label}
  //       handleBack={handleBack}
  //       handleSave={handleSave}
  //       formStructureFieldDepenents={getFieldDependents(formStructure)}
  //     />
  //   </>
  // )
  );
};
export default forwardRef(FormPage);
