/* eslint-disable react/prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { useActions } from '../../store/hooks/useActions';
import { getWidgetPanelPayload } from '../../component/Widget/utils/getWidgetPanelPayload';
import { REPEATED_WIDGETS, SHARED_LAYOUT, colors } from '../../constants';
import { getRepeatedWidgetsLayout } from '../../component/Widget/utils/getLayoutData';
import Index from '../../component/Widget';
import { UrlComponentDiv } from '../../component/UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ShowCaseLayoutHome = (props) => {
  const compactType = 'vertical';

  const isEditOn = useSelector((state) => state.widgets.isEditOn);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const userData = useSelector((state) => state.loginData.userData);
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const HDId = useSelector((state) => state.HomeDashboardReducer.HDId);
  const themeMode = useSelector((state) => state.configData.themeMode);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const layout = useSelector((state) => state.HomeDashboardReducer.layout);
  const breakpoint = useSelector((state) => state.HomeDashboardReducer.breakpoint);
  const menuIdofWidget = useSelector((state) => state.HomeDashboardReducer.menuIdofWidget);
  const repeatedWidgets = useSelector((state) => state.HomeDashboardReducer.repeatedWidgets);
  const layoutComponentData = useSelector((state) => state.HomeDashboardReducer.layoutComponentData);

  const { updateWidgetsLayoutSuccess, updateWidgetsPanelPayloadSave } = useActions();

  const isResizable = !!isEditOn[HDId];

  useEffect(() => {
    setTimeout(
      // eslint-disable-next-line no-undef
      () => { window.dispatchEvent(new Event('resize')); },
      1000
    );
  }, [showSideNav]);

  const onLayoutChange = useCallback((newLayout) => {
    if (
      !isEmpty(newLayout) &&
      !isEmpty(layout?.[breakpoint]) &&
      !isEmpty(layoutComponentData) &&
      !newLayout.every((e) => e.w === 1 && e.h === 1) // if all the cards are collapsed
    ) {
      try {
        const widgetPayload = getWidgetPanelPayload({
          menuId: menuIdofWidget,
          userId: userData.userId,
          isShareable: activeTab?.isShareable,
          widgetPanelName: activeTab?.label,
          widgetPanelLayoutData: {
            [SHARED_LAYOUT]: newLayout,
            [REPEATED_WIDGETS]: getRepeatedWidgetsLayout(newLayout, repeatedWidgets)
          },
          widgetPanelComponentData: Object.values(layoutComponentData)
        });
        // updateWidgetsLayout({ widgetPayload, WPID: activeTab?.id });
        updateWidgetsLayoutSuccess({
          layout: newLayout,
          repeatedWidgets: getRepeatedWidgetsLayout(newLayout, repeatedWidgets)
        });
        updateWidgetsPanelPayloadSave({ widgetPayload });
      } catch (e) {
        console.log('error while updating layout', e);
      }
    }
  }, []);

  const handleBreakPointChange = (newBreakpoint) => {
    // const oldLayout = layout[breakpoint] || layout.lg || layout.md || layout.sm || layout.xs || layout.xxs || [];
  };

  return (
    <>
      <ResponsiveReactGridLayout
        {...props}
        layouts={layout} // ?.[breakpoint]
        onBreakpointChange={handleBreakPointChange}
        onLayoutChange={onLayoutChange}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={false}
        compactType={compactType}
        margin={[10, 10]}
        containerPadding={[10, 10]}
        style={{ backgroundColor: colors[themeMode].contrast.whiteNgray }}
        isResizable={!!isEditOn[HDId]}
        isDraggable={window.innerWidth < 769 ? false : !!isEditOn[HDId]}
        className={!isResizable && 'not-editable'}
        autoSize={false}

      // aditional
      // onCompactTypeChange
      >
        {layout?.[breakpoint]?.map((l, i) => {
          const widgetKey = l.i;
          const [WID, key] = widgetKey.split('_');
          return (
            isHideBorder[WID + activeTab?.id] ? (
              <div
                // style={{
                //   boxShadow: colors[themeMode].shadowout,
                //   borderRadius: '8px',
                //   backgroundColor: colors[themeMode].aux.secondary
                // }}
                key={widgetKey}
                className={l.static ? 'static' : ''}
              >
                {l.static ? (
                  <span className='text' title='This item is static and cannot be removed or resized.'>
                    Static - {i}
                  </span>
                ) : (
                  <>
                    <Index i={i} layoutPos={l} componentData={layoutComponentData?.[WID]} HDId={HDId} />
                  </>
                )}
              </div>
            ) : (
              <div
                style={{
                  boxShadow: colors[themeMode].shadowout,
                  borderRadius: '8px',
                  backgroundColor: colors[themeMode].aux.secondary
                }}
                key={widgetKey}
                className={l.static ? 'static' : ''}
              >
                {l.static ? (
                  <span className='text' title='This item is static and cannot be removed or resized.'>
                    Static - {i}
                  </span>
                ) : (
                  <>
                    <Index i={i} layoutPos={l} componentData={layoutComponentData?.[WID]} HDId={HDId} />
                  </>

                )}
              </div>
            )
          );
        })}
      </ResponsiveReactGridLayout>
    </>
  );
};

ShowCaseLayoutHome.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowCaseLayoutHome.defaultProps = {
  className: 'layout',
  rowHeight: 30,
  onLayoutChange() { },
  breakpoints: { lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 },
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
};

export default memo(ShowCaseLayoutHome);
