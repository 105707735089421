/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Btn } from '../../../views/MyDashboards/SaveDashboardModal.style';
import { ButtonsContainer } from '../WidgetForm/AddWidgetForm.style';

const WidgetFooter = ({ formInput, hideModal, handleSave, enableSave }) => (
  // console.log(payload, 'payload');
  <>
    <ButtonsContainer>
      <Btn
        className='cancel_btn'
        onClick={() => {
          hideModal();
        }}
      >
        Cancel
      </Btn>
      <Btn className='save_btn' disabled={!enableSave} onClick={handleSave}>
        Save
      </Btn>
    </ButtonsContainer>
  </>
);
export default WidgetFooter;
