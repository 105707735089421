/* eslint-disable max-len */
import React, { Suspense, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './globalStyles/globalStyles';
import { colors } from './constants/colors';
import Routes from './routes/Routes';
import {
  getUserDataFromLocal,
  getVersionDataFromLocal,
  setUserDataToLocal,
  setVersionDataLocal
} from './shared/utility/helper';
import { Creators as loginCreators } from './views/auth/store';
import { Creators as localeStringsCreators } from './localeStrings/index';
import { Creators as configCreators } from './config/action';
import {
  setAccessTokenToHeader,
  setCustomHeader
} from './services/axios/helper';
import { persistor } from './store/store';

function App() {
  const themeMode = useSelector((state) => state.configData.themeMode);
  const language = useSelector((state) => state.configData.language);
  const specificDetails = useSelector(
    (state) => state.configData.specificDetails
  );
  // console.log('themeMode', specificDetails, themeMode, colors[themeMode]);
  const { token, userData, keyField, userMeta } = useSelector(
    (state) => state.loginData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      localeStringsCreators.getLocaleStrings({ pageId: '1023', lang: language })
    );
    const userData = getUserDataFromLocal();
    if (userData?.token) {
      dispatch(loginCreators.setUserDetails(userData));
    }
  }, []);
  useEffect(() => {
    dispatch(configCreators.setThemeColors({ themeMode }));
  }, [themeMode]);

  useEffect(() => {
    const versionDataFromCookie = getVersionDataFromLocal();
    if (
      versionDataFromCookie &&
      process.env.REACT_APP_VERSION !== versionDataFromCookie
    ) {
      setVersionDataLocal();
      localStorage.clear();
      persistor.flush().then(() => persistor.purge());
      window.location.reload();
      setUserDataToLocal();
    }
  }, []);

  // setToken to headers
  useEffect(() => {
    // setting the token as whatever is used for login(uName)
    if (token) {
      setAccessTokenToHeader(`Bearer ${token}`);
      setCustomHeader(userData[keyField]);
    }
  }, [token, userData]);

  return (
    <Suspense fallback={<div>Loading... </div>}>
      <ThemeProvider theme={{ ...colors[themeMode], specificDetails }}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
