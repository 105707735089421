export const getKPVShortlisted = (machinesArr) => {
  const machines = [];
  // console.log('machinesArr', machinesArr);
  machinesArr?.forEach((e) => {
    if (!machines.includes(e.machineName)) {
      const obj = {
        kpvId: e.id,
        machineId: e.machineId,
        machineName: e.machineName,
        groupName: e.groupName,
        kpvName: e.KPV,
        macId: e.macId,
        ...e
      };

      // TODO: skips the enabledPanes check
      // add details of charttypes
      //   enabledPanes.forEach((ele) => {
      //     const { key, type } = ele;
      //     obj[key] = e[key];
      //     obj[chartLableMapping[key]] = e[key];
      //     // console.log('key inside', key, chartLableMapping[key]);
      //     // obj[key] = 'true';
      //     // obj[chartLableMapping[key]] = 'true';
      //   });
      //   // console.log('obj', obj);
      machines.push(obj);
    }
  });
  return machines;
};
