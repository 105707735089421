/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import React from 'react';
import UpsertWidgetRoutes from './UpsertWidgetRoutes';

const UpsertWidget = () => (
  <>
    <UpsertWidgetRoutes />
  </>
);

export default UpsertWidget;
