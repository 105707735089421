/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../BarChart/BarChart.style';
import Ahu from '../../../SvgAnimationComponents/Ahu.View';

import {
  BodyContainer,
  ContainerBody
} from '../../../Widget/Common/WidgetCommons.style';
import { Container, ContainerInside } from './SVGWidget.style';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import {
  DeleteBlack,
  Export,
  HideBorder,
  HideHeader,
  settingsBlack,
  FullScreen,
  AddToHome,
  RemoveFromHome,
  RemoveHomeMenu } from '../../../../assets/icons';
import {} from '../../WidgetsCommon.style';
import Compressor from '../../../SvgAnimationComponents/Compressor.View';
import Chillar from '../../../SvgAnimationComponents/Chillar.View';
import Exhaust from '../../../SvgAnimationComponents/Exhaust.View';
import Fan from '../../../SvgAnimationComponents/Fan.View';
import Tank from '../../../SvgAnimationComponents/Tank.View';

import {
  ColorsAHU,
  ColorsCompressor,
  colorsChillar,
  colorsExhaust,
  colorsFan,
  colorsTank
} from './SvgWidgetColors';

const SVGWidget = (props) => {
  // eslint-disable-next-line max-len

  const { componentData, id, isVisible, layoutPos, fullView, HDId } = props;
  const WID = layoutPos?.i?.split('_')?.[0];
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const [machineState, setMachineState] = useState();
  const [dimension, setDimension] = useState({});
  const containerRef = useRef();
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const isPreviewInstance = useSelector(
    (state) => state.widgets.isPreviewInstance
  );

  useEffect(() => {
    if (containerRef) {
      const width = containerRef.current.offsetWidth - 50;
      const height = containerRef.current.offsetHeight - 50;
      setDimension({ width, height });
    }
  }, [containerRef?.current?.offsetHeight, containerRef?.current?.offsetWidth]);
  const { widgetLabel } = useSelector((state) => state.upsertWidgetData);
  const {
    WidgetName,
    infoText,
    formData,
    type,
    widgetEditOptions: initWidgetEditOptions
  } = componentData || {};
  const widgetOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    // {
    //   id: 1,
    //   label: 'Export',
    //   value: 'export',
    //   iconUrl: Export,
    //   subMenu: [
    //     { id: 1, label: 'Image', value: 'pngExport' },
    //     {
    //       id: 2,
    //       label: 'PDF',
    //       value: 'pdfExport'
    //     }
    //   ]
    // },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 1,
      label: 'Configure',
      value: 'configure',
      iconUrl: settingsBlack
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  const widgetOptionsInstance = HDId ? [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    {
      id: 1,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ] : [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  const widgetOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    }
  ];

  let index;
  /**
   * Function to return random element from an array
   * @param {any} array
   * @returns random element from array
   */
  function getRandomItem(arr) {
    // get random index value
    index = Math.floor(Math.random() * arr.length);
    // get random item
    const item = arr[index];
    return item;
  }

  useEffect(() => {
    const state = getRandomItem(['success', 'warning', 'Failure']);
    setMachineState(state);
  }, []);

  /**
   * Function to return Respective widget component based on WidgetName
   * @param {any} WidgetName
   * @returns component
   */
  // eslint-disable-next-line consistent-return
  const getRespectiveComponent = (WidgetName) => {
    switch (type) {
      case 'AHU':
        return (
          <Ahu
            componentData={componentData}
            dimension={dimension}
            colors={
              formData?.machineState?.value === 'running'
                ? ColorsAHU[0]
                : formData?.machineState?.value === 'alarm'
                  ? ColorsAHU[1]
                  : ColorsAHU[2]
            }
            speed={formData?.speed?.value}
            numSvg={formData?.number?.value}
          />
        );
      case 'COMPRESSOR':
        return (
          <Compressor
            componentData={componentData}
            dimension={dimension}
            Compressorcolors={
              formData?.machineState?.value === 'running'
                ? ColorsCompressor[0]
                : formData?.machineState?.value === 'alarm'
                  ? ColorsCompressor[1]
                  : ColorsCompressor[2]
            }
            speed={formData?.speed?.value}
            numSvg={formData?.number?.value}
          />
        );
      case 'CHILLER':
        return (
          <Chillar
            componentData={componentData}
            dimension={dimension}
            colors={
              formData?.machineState?.value === 'running'
                ? colorsChillar[0]
                : formData?.machineState?.value === 'alarm'
                  ? colorsChillar[1]
                  : colorsChillar[2]
            }
            speed={formData?.speed?.value}
            numSvg={formData?.number?.value}
          />
        );
      case 'EXHAUST':
        return (
          <Exhaust
            componentData={componentData}
            dimension={dimension}
            colors={
              formData?.machineState?.value === 'running'
                ? colorsExhaust[0]
                : formData?.machineState?.value === 'alarm'
                  ? colorsExhaust[1]
                  : colorsExhaust[2]
            }
            speed={formData?.speed?.value}
            numSvg={formData?.number?.value}
          />
        );
      case 'FAN':
        return (
          <Fan
            componentData={componentData}
            numSvg={formData?.number?.value}
            dimension={dimension}
            colors={
              formData?.machineState?.value === 'running'
                ? colorsFan[0]
                : formData?.machineState?.value === 'alarm'
                  ? colorsFan[1]
                  : colorsFan[2]
            }
            speed={formData?.speed?.value}
          />
        );
      case 'TANK':
        return (
          <Tank
            componentData={componentData}
            dimension={dimension}
            colors={
              formData?.machineState?.value === 'running'
                ? colorsTank[0]
                : formData?.machineState?.value === 'alarm'
                  ? colorsTank[1]
                  : colorsTank[2]
            }
            progress={formData?.tankLevel?.value}
            numSvg={formData?.number?.value}
          />
        );
      default:
        break;
    }
  };

  return (
    <Container headerOn={!isHideHeader[WID + activeTab?.id]}>
      { (
        <WidgetHeaderView
          id={id}
          WID={WID} // WID
          WidgetName={WidgetName || widgetLabel}
          widgetOptions={
          WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePop : widgetOptionsInstance : widgetOptions
        }
          componentData={componentData}
          fullView={fullView}
        />
      )}
      <BodyContainer headerOn={!isHideHeader[WID + activeTab?.id]}>
        <ContainerInside ref={containerRef}>
          {getRespectiveComponent(WidgetName || widgetLabel)}
        </ContainerInside>
      </BodyContainer>
    </Container>
  );
};

export default SVGWidget;
