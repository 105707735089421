/* eslint-disable max-len */
/* eslint-disable indent */
import styled from 'styled-components';
import { FlexContainer } from '../../../globalStyles';

export const LegendItemContainer = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 0px 10px 10px;

  .fullwidth: {
    width: 100%;
  }

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid ${theme.aux.darkSecondary}`};
    padding: 10px;
  }
`;

export const LegendContainer = styled.div`
  width: ${({ position }) => (position === 'right' ? ' 24%' : '')};
  height: 100%;
  margin: 0px auto;
  padding-top: 15px;
  overflow-x: auto;
  & {
    max-width: ${({ position }) => (position === 'right' ? '300px' : '')};
    margin: ${({ position }) => (position === 'right' ? '0px auto' : '0px 0px')};
    height: auto;
    & ${LegendItemContainer} {
      flex-direction: ${({ position }) => (position === 'right' ? 'column' : 'row')};
      flex-wrap: wrap;
      justify-content: ${({ position }) => (position === 'right' ? '' : 'center')};
      width: ${({ position }) => (position === 'right' ? '100%' : '')};
    }
  }
`;

export const LegendContainerBtm = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 10px;
`;

export const LegendItemContainerBtm = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0px;
  margin-left: 4rem;
  min-width: 30rem;
`;

export const ColorLabel = styled.div`
  width: 35px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ bgColor }) => bgColor};
  margin: 0px 10px 6px 0px;
`;

export const ColorLabelText = styled.div`
  color: ${({ theme }) => theme.contrast.primary};
  font-size: 1.5rem;
`;

export const OverlayLabelText = styled(FlexContainer)`
  background: ${({ bgColor }) => bgColor};
  color: ${({ bgColor, color }) =>
    // console.log('bgColor, color', bgColor, color);
    color}
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
`;
