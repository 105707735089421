import React from 'react';
import Ahu from './Ahu.View';
import Compressor from './Compressor.View';
import Exhaust from './Exhaust.View';
import Fan from './Fan.View';
import Chillar from './Chillar.View';
import Tank from './Tank.View';

const SvgDemo = () => {
  const colorsAhu = {
    class_0: '#3D3F50',
    class_1: '#1BAF0A',
    class_2: '#195C85',
    class_3: '#138F06',
    class_4: '#C1F6A2',
    class_5: '#35CC25',
    class_6: '#F4F4E6',
    class_7: '#14AB03',
    class_8: '#1AFF00',
    class_9: '#143441',
    class_10: '#2E81AA',
    class_11: '#CCF5B5',
    class_12: '#525360',
    class_13: '#7E7F82'
  };
  const colorsAhuFailure = {
    class_0: '#3D3F50',
    class_1: '#BF0000',
    class_2: '#195C85',
    class_3: '#F5B5B5',
    class_4: '#FF0000',
    class_5: '##FF0000',
    class_6: '#F4F4E6',
    class_7: '#BF0000',
    class_8: '#BF0000',
    class_9: '#143441',
    class_10: '#2E81AA',
    class_11: '#F5B5B5',
    class_12: '#525360',
    class_13: '#7E7F82'
  };
  const colorsAhuWarniing = {
    class_0: '#3D3F50',
    class_1: '#AC9B00',
    class_2: '#195C85',
    class_3: '#F7FF00',
    class_4: '#E1CB03',
    class_5: '##E1CB03',
    class_6: '#F4F4E6',
    class_7: '#AC9B00',
    class_8: '#AC9B00',
    class_9: '#143441',
    class_10: '#2E81AA',
    class_11: '#F7FF00',
    class_12: '#525360',
    class_13: '#7E7F82'
  };

  const colorsCompressor = {
    class_0: '#1baf0a',
    class_2: '#1aff00',
    class_3: '#c1f6a2',
    background: '#3d3f50'
  };

  const colorsCompressorWarning = {
    class_0: '#AC9B00',
    class_2: '#E1CB03',
    class_3: '#F7FF00',
    background: '#3d3f50'
  };

  const colorsCompressorFailure = {
    class_0: '#bf0000',
    class_2: '#ff3535',
    class_3: '#F5B5B5',
    background: '#3d3f50'
  };

  const colorsExhaust = {
    class_0: '#1BAF0A',
    class_1: '#138F06',
    class_2: '#C1F6A2',
    class_3: '#6FBC6F',
    class_4: '#F4F4E6',
    class_5: '#525360',
    class_6: '#3D3F50',
    class_7: '#7E7F82'
  };

  const colorsExhaustWarning = {
    class_0: '#E1CB03',
    class_1: '#AC9B00',
    class_2: '#F7FF00',
    class_3: '#E1CB03',
    class_4: '#F4F4E6',
    class_5: '#525360',
    class_6: '#3D3F50',
    class_7: '#7E7F82'
  };

  const colorsExhaustFailure = {
    class_0: '#ff3535',
    class_1: '#bf0000',
    class_2: '#F5B5B5',
    class_3: '#ff3535',
    class_4: '#F4F4E6',
    class_5: '#525360',
    class_6: '#3D3F50',
    class_7: '#7E7F82'
  };

  const colorsFan = {
    class_0: '#3D3F50',
    class_1: '#1BAF0A',
    class_2: '#1AFF00'
  };

  const colorsFanFailure = {
    class_0: '#3D3F50',
    class_1: '#bf0000',
    class_2: '#ff3535'
  };

  const colorsFanWarning = {
    class_0: '#3D3F50',
    class_1: '#AC9B00',
    class_2: '#E1CB03'
  };

  const colorsChillar = {
    class_1: '#143441',
    class_2: '#14ab03',
    class_3: '#3d3f50',
    class_4: '#1baf0a',
    class_5: '#2e81aa',
    class_6: '#c1f6a2'
  };

  const colorsChillarFailure = {
    class_1: '#143441',
    class_2: '#bf0000',
    class_3: '#3d3f50',
    class_4: '##ff3535',
    class_5: '#2e81aa',
    class_6: '##F5B5B5'
  };

  const colorsChillarWarning = {
    class_1: '#143441',
    class_2: '#AC9B00',
    class_3: '#3d3f50',
    class_4: '##E1CB03',
    class_5: '#2e81aa',
    class_6: '##F7FF00'
  };

  const colorsTankFailure = {
    class_0: '##bf0000',
    class_1: '#3d3f50',
    class_2: '#fff',
    class_3: '##F5B5B5',
    class_4: '##ff3535'
  };
  const colorsTankWarning = {
    class_0: '#AC9B00',
    class_1: '#3d3f50',
    class_2: '#fff',
    class_3: '#F7FF00',
    class_4: '#E1CB03'
  };
  const colorsTank = {
    class_0: '#138F06',
    class_1: '#3d3f50',
    class_2: '#fff',
    class_3: '#C1F6A2',
    class_4: '#1BAF0A'
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Compressor Compressorcolors={colorsCompressor} speed='2s' />
        <Ahu colors={colorsAhuWarniing} speed='2s' />
      </div>
      <div style={{ display: 'flex' }}>
        <Exhaust colors={colorsExhaust} speed='2s' />
        <Fan colors={colorsFan} speed='2s' />
      </div>
      <div style={{ display: 'flex' }}>
        <Chillar colors={colorsChillar} speed='2s' />
        <Tank colors={colorsTank} progress={10} />
      </div>
    </div>
  );
};

export default SvgDemo;
