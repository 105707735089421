/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Td, Tr } from '../../../Common/Table/SpacedRows.styles';
import { FlexContainer } from '../../../../globalStyles';
import { ToogleGroupICon } from '../../../ResultView/KPVListComponent/KPVListComponent.style';
import CollapseIcon from '../../../SvgIcons/KPVList/CollapseIcon';
import { ActionIcon } from '../../../ResultView/KPVGroupComponent/KPVGroupComponent.style';
import RemoveFromPane from '../../../SvgIcons/KPVList/RemoveFromPane';
import AddToPane from '../../../SvgIcons/KPVList/AddToPane';

const KpvSelectionGroup = ({ groupTitle, groupItems, kpvShortList, setKpvShortList }) => {
  const [collapseGroup, setCollapseGroup] = useState(false);

  const { kpvMeta: metaDetails } = useSelector((state) => state.widgets);
  const colorFields = Array.isArray(metaDetails.colorField) ? metaDetails.colorField : [metaDetails.colorField];

  /**
   *Handling Empty and Boolean Content
   * @param {*} value
   * @returns
   */
  const getValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'True' : 'False';
    }
    return value || '-';
  };

  /**
   * Function to select the values from Kpv list
   * @param {*} entry Receives the entry selected by User
   */
  const handleShortList = (entry) => {
    const found = kpvShortList.some((el) => el.title === entry.title);
    if (found) {
      const index = kpvShortList.findIndex((ele) => ele.title === entry.title);
      kpvShortList.splice(index, 1);
      setKpvShortList([...kpvShortList]);
    } else {
      setKpvShortList([...kpvShortList, entry]);
    }
  };

  return (
    <>
      {groupItems.length > 0 && (
        <>
          <Tr>
            <Td noBorder={true} className='groupHeading align'>
              <FlexContainer className='ai_center'>
                <ToogleGroupICon onClick={() => setCollapseGroup(!collapseGroup)}>
                  <CollapseIcon />
                </ToogleGroupICon>
                <p>{groupTitle}</p>
              </FlexContainer>
            </Td>
            {metaDetails?.fields?.map(
              (col, key) =>
                col.visible && <Td className='groupHeading' />
            )}
          </Tr>
          <>
            {!collapseGroup &&
              groupItems.map((entry, index) => {
                const isActive = kpvShortList.some((el) => el.KSId === entry.KSId || el.title === entry.title);
                return (
                  <Tr
                    key={`${entry[metaDetails.UID]} ${entry.machineId} ${index}`}
                    className={isActive ? 'active' : 'fillColor'}
                  >
                    {metaDetails?.fields?.map(
                      (col, key) =>
                        // !metaDetails?.grouping?.includes(col.key) &&
                        col.visible && (
                          <Td
                            key={key}
                            border={col.key !== 'Action'}
                            className={col.key === 'KPV' ? 'pointer' : ''}
                            color={
                              metaDetails.fieldsToColor.includes(col.key) && colorFields ? entry[colorFields[0]] : ''
                            }
                          >
                            {/* {col.key !== 'Action' ? ( */}
                            <p className='clip hideScrollBar'>{getValue(entry[col.key])}</p>
                          </Td>
                        )
                    )}
                    <Td
                      className={isActive ? 'active' : ''}
                      flex={0.5}
                    >
                      <FlexContainer className='jc_center ai_center'>
                        <ActionIcon
                          onClick={() => handleShortList(entry, groupTitle, entry[metaDetails.chartable])}
                        >
                          {/* show respective icon */}
                          {isActive ? <RemoveFromPane /> : <AddToPane />}
                        </ActionIcon>
                      </FlexContainer>
                    </Td>
                  </Tr>
                );
              })}
          </>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default KpvSelectionGroup;
