/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import { useActions } from '../../../store/hooks/useActions';
import { Footer } from '../../KPVCharts/PaneCard/AdditionalFilter/FilterPopUp.styles';
import { BtnWrapper, Button } from '../../Common/PopUpModal/Modal.style';
import Pagination from '../../Common/Pagination/Pagination.view';
import { SearchContainer } from '../../../views/MyDashboards/MyDashboards.style';
import { Creators as myDashboardsCreators } from '../../../views/MyDashboards/store';
import SearchBar from '../../Common/SearchBar/SearchBar';
import { HeaderText } from '../../Common/PageHeader/PageHeader.styles';
import TabsTable from '../../Common/Tabs_Table';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { getWidgetPanelPayload } from '../../Widget/utils/getWidgetPanelPayload';
import { PAGE_TYPES } from '../../../constants';
import { isEmpty } from '../../../shared/utility/isEmpty';
import Loader from '../../Loader/Loader';

const InstanceSelectionPop = () => {
  const { menuIdofWidget } = useSelector((state) => state.widgets);
  const userData = useSelector((state) => state.loginData.userData);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const searchQueriesReducer = useSelector((state) => state.widgets.searchQueries);
  const widgetDashboardType = useSelector((state) => state.widgets.widgetDashboardType);
  const WidgetRoutes = widgetDashboardType === 'ALL' ? useSelector((state) => state.home?.[PAGE_TYPES.WIDGET]) : useSelector((state) => state.home?.[PAGE_TYPES.WIDGET_INSTANCE]);
  const { totalInstances, instanceData, instanceMeta, loading } = useSelector((state) => state.myDashboards);

  const [currPage, setCurrPage] = useState(1);
  const [activeRow, setActiveRow] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const [localPaginatedData, setLocalPaginatedData] = useState([]);
  const [localFilteredData, setLocalFilteredData] = useState([]);
  const [totalFiltered, settotalFiltered] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { valueSetter, widgetDetailSetter, setToastError, setBulkUpsertWidgetDetails, setSearchQueriesWidgets } = useActions();

  const onSuccessNavigation = () => {
    history.push(WidgetRoutes[menuIdofWidget]);
  };

  const saveSearchQueries = (payload) => {
    setSearchQueriesWidgets(payload || {});
  };

  const pageSize = 10;
  const getDashboards = useCallback((page, searchQueries) => {
    const payload = {
      UserId: userData?.userId,
      page,
      pageSize,
      searchQueries
    };
    dispatch(myDashboardsCreators.getInstanceDashboard(payload));
  }, [dispatch]);

  const handlePageChange = (page, searchQueries) => {
    setCurrPage(page);
    if (!(instanceMeta?.LocalPagination === 'true')) {
      getDashboards(page, searchQueries);
    } else {
      const slicedData = instanceData?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      setLocalPaginatedData(slicedData);
    }
  };

  useEffect(() => {
    if (localFilteredData?.length > 0) {
      const slicedData = totalFiltered?.slice((currPage - 1) * pageSize, (currPage - 1) * pageSize + pageSize);
      setLocalFilteredData(slicedData);
    }
  }, [currPage]);

  const handlePageChangeLocal = async (localFilteredDataNew) => {
    const slicedData = localFilteredDataNew?.slice(0, pageSize);
    setLocalFilteredData(slicedData);
    settotalFiltered(localFilteredDataNew);
  };

  useEffect(() => {
    handlePageChange(1);
  }, [instanceMeta?.LocalPagination]);

  useEffect(() => {
    if (searchValue !== '') {
      const delayDebounceFn = setTimeout(() => {
        getDashboards(1, searchValue);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }

    getDashboards(1);
  }, [searchValue]);

  /**
   * Function to close the instace selection pop up
   */
  const closeModalHandler = () => {
    valueSetter({ showInstanceTablePopUp: false });
  };

  /**
   * Function to add selected instance into the created Instance Tab
   * @param {*} activeInstance Receives active instance item
   */
  const handleAddToTab = async (activeInstance) => {
    closeModalHandler();
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    try {
      const fullURL = `${apiEndPoints.getInstancePreview}/${activeInstance?.WTIId}`;
      const response = await WebService.get(fullURL, {
        params: {
          UserId: userData.userId
        }
      });

      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        if (data[0][0]) {
          const { WidgetPanelLayoutData, TemplateParameters, WidgetComponentData } = data[0][0];
          // setBulkUpsertWidgetDetails({
          //   templateFormInput: TemplateParameters?.formInput || {},
          //   templateInstanceFormInput: WidgetComponentData,
          // });
          // widgetDetailSetter({
          //   key: 'widgetTypesIncluded',
          //   value: TemplateParameters?.widgetTypesIncluded,
          // });
          // setBulkUpsertWidgetDetails({
          //   instanceCreatorName: activeRow?.UserId,
          //   WTIId: activeRow?.WTIId,
          //   templateInstanceName: activeRow?.WidgetTemplateInstanceName
          // });
          const fullURL = apiEndPoints.updateWidgetPanel.replace(
            '{{WPID}}',
            activeTab?.id
          );
          const widgetPayload = getWidgetPanelPayload({
            menuId: menuIdofWidget,
            userId: userData.userId,
            isShareable: activeTab?.isShareable,
            widgetPanelName: activeTab?.label,
            widgetPanelLayoutData: WidgetPanelLayoutData,
            WTIId: activeRow?.WTIId,
            InheritedWPID: activeInstance?.WPID
          });

          const finalResponse = await WebService.post(fullURL, widgetPayload);
          if (finalResponse.status >= 200 && finalResponse.status < 300) {
            onSuccessNavigation();
          } else {
            throw finalResponse;
          }
        } else {
          throw response;
        }
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('e', e, error);
      setToastError(error);
    }
  };

  /**
   * Functions to handle the Individual row click
   * @param {*} activeRow Receives active Instance item
   */
  const handleRowOptionsItemClick = (activeRow) => {
    setActiveRow(activeRow);
  };

  return (
    <PopUpModal
      width='60%'
      closeModalHandler={closeModalHandler}
      disableOutsideClick={true}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <HeaderText>Instance Dashboard</HeaderText>
        <div style={{ display: 'flex' }}>
          <Pagination
            ItemsPerPage={10}
            handlePageChange={handlePageChange}
            total={totalInstances}
          />
          <SearchContainer>
            <SearchBar placeholder='Search instances by name or description' searchValue={searchValue} onChangeHandler={setSearchValue} />
          </SearchContainer>
        </div>
      </div> */}

      <div style={{ maxHeight: '550px', overflow: 'auto' }}>

        <TabsTable
          metaDetails={instanceMeta}
          dataDetails={instanceMeta?.LocalPagination === 'true' ? localPaginatedData : instanceData}
          showPagiation={true}
          handlePageChange={handlePageChange}
          assetListLoading={loading}
          showShortlistSelection={false}
          noItemsFoundMessage='No Data Found'
          showRowOptionAtStart={false}
          handleRowOptionsItemClick={handleRowOptionsItemClick}
          saveSearchQueries={saveSearchQueries}
          savedSearchQueries={searchQueriesReducer}
          resetSearchQueries={saveSearchQueries}
          activeRow={activeRow}
          setActiveRow={setActiveRow}
          fullData={instanceData}
          handlePageChangeLocal={handlePageChangeLocal}
          setLocalFilteredData={setLocalFilteredData}
          localFilteredData={localFilteredData}
          id='WTIId'
          pageSize={pageSize}
        />

      </div>
      <Footer style={{ marginTop: '3rem' }}>
        <BtnWrapper style={{ width: '40%', alignItems: 'flex-end' }}>
          <Button
            type='button'
            style={{
              boxShadow:
                      '-5px -5px 19px rgba(255,255,255,0.1), 3px 3px 10px rgba(0,0,0,0.1)'
            }}
            onClick={() => {
              closeModalHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            className='dark'
            onClick={() => { handleAddToTab(activeRow); }}
            disabled={isEmpty(activeRow)}
          >
            Add To Tab
          </Button>
        </BtnWrapper>
      </Footer>
    </PopUpModal>
  );
};

export default InstanceSelectionPop;
