/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonComp from '../../component/Common/Button/Button.view';
import DynamicSVGIcon from '../../component/Common/DynamicSVGIcon';
import PageHeader from '../../component/Common/PageHeader/PageHeader';
import { ButtonContainer } from './Users.style';
import kababSvg from '../../assets/icons/Filter/kabab.svg';
import { Div } from '../../globalStyles';
import DropDownModal from '../../component/Common/DropDownModal/DropDownModal_V1';
import FileUpload from '../../component/Common/FIleUpload/FileUpload';
import { useActions } from '../../store/hooks/useActions';

const UsersHeader = (props) => {
  const {
    title,
    hasBack,
    isFormEditable,
    isCreate,
    handleUserEdit,
    handleDisableUser,
    handleUserCreate,
    handleADUserCreate,
    handleSave,
    handleCancel,
    handleBack,
    children,
    handleNext = () => {},
    handleUpdateBulkUser = () => {},
    handleAddBulkUser = () => {},
    isSaveEnabled,
    isADUser = false,
  } = props;
  // const location = useLocation();
  // const history = useHistory();
  const kababIconRef = useRef();
  const [modalIsVisibal, setModalIsVisibal] = useState(false);
  const [fileUploadBox, setFileUploadBox] = useState(false);
  const { userReducerValueSetter } = useActions();
  const defaultHanlder = () => {};
  const handleUsersUpload = () => {
    setFileUploadBox(true);
  };

  const menuOptions = [
    { label: 'Add Multiple Users', value: 'addMultipleUsers', handler: handleUsersUpload },
    { label: 'Export Users List', value: 'exportList', handler: defaultHanlder, disable: true }
  ];
  const chooseModalOption = (item, additionalDetails) => {
    const { handler } = item;
    handler(additionalDetails);
  };

  const handleSaveUploadedExcelData = (data) => {
    userReducerValueSetter({ key: 'usersUploadedData', value: data });
  };
  return (
    <>
      <PageHeader hasBack={hasBack} handleBack={handleBack} title={title}>
        {!isFormEditable ? (
          <ButtonContainer>
            {/* <ButtonComp className='disable' onClick={handleDisableUser}>
              Disable User
            </ButtonComp> */}
            {isCreate ? (
              <>
                <ButtonComp onClick={handleUserCreate}>Add User</ButtonComp>
                <ButtonComp onClick={handleADUserCreate} style={{ width: '20rem' }}>Add AD/LDAP User</ButtonComp>
                <ButtonComp style={{ width: '18rem' }} onClick={handleAddBulkUser}>Add Multiple User</ButtonComp>
                <ButtonComp onClick={handleUpdateBulkUser}>Update Users</ButtonComp>
              </>
            ) : (
              <ButtonComp onClick={handleUserEdit}>Edit</ButtonComp>
            )}
            {/* <Div ref={kababIconRef} onClick={() => setModalIsVisibal(!modalIsVisibal)} style={{ position: 'relative' }}>
              <DynamicSVGIcon iconUrl={kababSvg} width='2.4rem' />
              <DropDownModal
                kababIconRef={kababIconRef}
                isActive={modalIsVisibal}
                setModalIsVisibal={setModalIsVisibal}
                ContainerStyle={{ top: 20, right: 0 }}
                data={menuOptions}
                handleClick={chooseModalOption}
                noContainerPadding
              />
            </Div> */}
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <ButtonComp onClick={handleCancel}>Cancel</ButtonComp>
            <ButtonComp disabled={!isSaveEnabled} dark onClick={handleSave}>
              {isADUser ? 'Next' : 'Save'}
            </ButtonComp>
          </ButtonContainer>
        )}
        {children}
      </PageHeader>

      {fileUploadBox && (
        <FileUpload setFileUploadBox={setFileUploadBox} handleNext={handleNext} setData={handleSaveUploadedExcelData} />
      )}
    </>
  );
};

export default UsersHeader;
