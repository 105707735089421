/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ForwardNavArrow } from '../../assets/icons/KPV';
import { PAGE_TYPES, WIDGET_CATEGORIES, WIDGET_TYPES } from '../../constants';
import { FlexContainer } from '../../globalStyles';
import ButtonComp from '../Common/Button/Button.view';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import Loader from '../Common/Loader/Loader';
import { WrapperLoderContainer } from '../Common/Loader/Loader.style';
import PageHeader from '../Common/PageHeader/PageHeader';
import { HeaderContainer } from '../Common/PageHeader/PageHeader.styles';
import {
  NavigationDiv,
  OptIcon
} from '../KPVCharts/KPVCharting/KPVCharting.style';
import {
  PlotBtn,
  PlotContainer
} from '../ResultView/KPVCustomComponent/KPVCustom.style';
import { getResolvedWidgetCategory } from '../Widget/utils';
import { useActions } from '../../store/hooks/useActions';

const UpsertSaveHeader = (props) => {
  const { header, handleBack, handleSave, enableSave = true } = props;
  const widgetCategory = useSelector(
    (state) => state.upsertWidgetData.widgetCategory
  );
  const widgetType = useSelector((state) => state.upsertWidgetData.widgetType);
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const loading = useSelector((state) => state.upsertWidgetData.loading);
  const showSideNav = useSelector((state) => state.home.showSideNav);
  // console.log(widgetCategory, 'buttonClicked');
  const history = useHistory();
  const handleHome = () => {
    // history.push('/home');
    // handle based on menu
    history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
  };
  const location = useLocation();
  const { pathname, state } = location;
  let buttonLabel = 'Save';
  if (pathname !== '/home/create-widget') {
    // as it is not exact there will be one more step
    buttonLabel = state?.isEdit ? 'Save' : 'Add';
  }

  const { clearTreeData } = useActions();
  return (
    <HeaderContainer showSideNav={showSideNav} className='fixed'>
      <PageHeader title={header}>
        <div style={{ marginTop: '0px' }} className='buttonWrapper'>
          {/* TODO_W_RR: change to widget category */}
          {getResolvedWidgetCategory(widgetCategory, widgetType) ===
          WIDGET_CATEGORIES.ANALYTICS ? (
            <ButtonComp dark onClick={handleHome}>
              Go To Home
            </ButtonComp>
          ) : (
            <>
              <ButtonComp
                className='saveBtn'
                style={{ marginRight: '2rem' }}
                onClick={() => {
                  clearTreeData();
                  handleBack();
                }}
              >
                Discard
              </ButtonComp>
              <PlotContainer
                onClick={loading ? null : handleSave}
                style={{ width: '16rem', height: '4rem' }}
                className={`content ${!enableSave ? 'disable' : ''}`}
              >
                <PlotBtn className='saveWidgetBtn' width='wd'>
                  {loading ? (
                    <WrapperLoderContainer>
                      <Loader />
                    </WrapperLoderContainer>
                  ) : (
                    <Span>{buttonLabel}</Span>
                  )}
                </PlotBtn>
              </PlotContainer>
            </>
          )}
        </div>
      </PageHeader>
    </HeaderContainer>
  );
};

export const Span = styled.div`
  position: absolute;
  font-size: 2rem;
  margin: auto;
  left: 5.8rem;
  bottom: 0.8rem;
`;

export default UpsertSaveHeader;
