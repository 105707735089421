/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  WIDGET_CATEGORIES,
  WIDGET_TERMINOLOGY,
  WIDGET_TYPES,
  colors
} from '../../constants';
import useElementOnScreen from '../../shared/hooks/useElementOnScreen';
import useMeasure from '../../shared/hooks/useMeasure';
import { Text } from '../../views/Widgets/Widget.style';
import {
  SelectedWidgetDiv,
  UrlCompText,
  UrlComponentDiv
} from '../UpsertWidget/WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import { getResolvedWidgetCategory } from './utils';
import Analytics from './WidgetComponents/Analytics/Analytics';
import BarChart from './WidgetComponents/BarChart/BarChart.view';
import DetailedStatusValue from './WidgetComponents/DetailedStatusValue/DetailedStatusValue';
import EquipmentDonut from './WidgetComponents/EquipmentDonut/EquipmentDonut.view';
import EquipmentPlan from './WidgetComponents/EquipmentPlan/EquipmentPlan';
import FloorPlan from './WidgetComponents/FloorPlan/FloorPlan';
import StatusValue from './WidgetComponents/StatusValue/StatusValue';
import GaugeChart from './WidgetComponents/GaugeChart/GaugeChart.view';
import SVGWidget from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.view';
import TextHtml from '../UpsertWidget/WidgetsComponents/TextHTML/TextHtml.View';
import TableWidget from '../UpsertWidget/WidgetsComponents/TableWidget/TableWidget';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import { RedirectIcon } from '../../assets/icons';
import { concatStringWithUnderscore } from '../../views/InstanceDashboard/utils/functions';
import BarGaugeChart from './WidgetComponents/BarGauge/BarGaugeChart';

export const widgetComponentMapping = {
  // TODO_W_R: change to widget category
  [WIDGET_CATEGORIES.ANALYTICS]: Analytics,
  [WIDGET_CATEGORIES.OEE_DONUT]: EquipmentDonut,
  [WIDGET_CATEGORIES.STATUS_VALUE]: StatusValue,
  [WIDGET_CATEGORIES.DETAILED_STATUS_VALUE]: DetailedStatusValue,
  [WIDGET_CATEGORIES.FLOOR_PLAN]: FloorPlan,
  [WIDGET_CATEGORIES.EQUIPMENT_PLAN]: EquipmentPlan,
  [WIDGET_CATEGORIES.BAR_CHART]: BarChart,
  [WIDGET_CATEGORIES.GAUGE_CHART]: GaugeChart,
  [WIDGET_CATEGORIES.SVG]: SVGWidget,
  [WIDGET_CATEGORIES.TEXT_HTML]: TextHtml,
  [WIDGET_CATEGORIES.TABLE]: TableWidget,
  [WIDGET_CATEGORIES.PIVOT_TABLE]: TableWidget,
  [WIDGET_CATEGORIES.TEXT_HTML_WIDGET]: TextHtml,
  [WIDGET_CATEGORIES.GAUGE_CONCENTRIC_WIDGET]: BarGaugeChart
};

const Index = ({
  componentData,
  WIDFromProp,
  layoutPos,
  i,
  WidgetComponentData: WidgetComponentDataFromPOp,
  fullView = false,
  HDId
}) => {
  const { type, WidgetName, widgetCategory } = componentData || {};
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const themeMode = useSelector((state) => state.configData.themeMode);
  const activeTab = useSelector((state) => state.widgets.activeTab);

  const WidgetComponentData = useSelector(
    (state) => state.widgets.WidgetComponentData
  );
  if (!type) {
    return null;
  }
  // TODO_W_RR: change to widgetCategory
  const WidgetComponent =
    widgetComponentMapping[getResolvedWidgetCategory(widgetCategory, type)];
  const [ref, orgIsVisible] = useElementOnScreen();
  const isVisible = useRef(false);

  if (orgIsVisible) {
    isVisible.current = true;
  }
  const WID = WIDFromProp || layoutPos?.i?.split('_')?.[0];
  const currentLocation = window.location.pathname;
  const componentsURL = currentLocation?.split('/home');

  const redirectToPreview = () => {
    window.open(
      `${componentsURL[0]}${
        componentData?.Url
      }?instace_name=${concatStringWithUnderscore(
        componentData?.WidgetTemplateInstanceName
      )}`
    );
  };

  return isHideBorder[WID + activeTab?.id] ? (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: !fullView && colors[themeMode].contrast.whiteNgray,
        overflow: 'auto'
      }}
      id={`printableDiv${WID}`}
    >
      {/* ref */}
      <SelectedWidgetDiv HDId={HDId && componentData?.Url} ref={ref}>
        {WidgetComponent ? (
          <WidgetComponent
            isVisible={isVisible.current}
            layoutPos={layoutPos}
            WIDFromProp={WID}
            key={i}
            id={i}
            HDId={HDId}
            fullView={fullView}
            componentData={{
              ...componentData,
              formData: {
                ...componentData.formData,
                ...(WidgetComponentDataFromPOp?.formInput?.common ||
                  WidgetComponentData?.formInput?.common ||
                  {}),
                ...(WidgetComponentDataFromPOp?.formInput?.[type] ||
                  WidgetComponentData?.formInput?.[type] ||
                  {})
              } // combining the cofiguration defined in template
            }}
          />
        ) : (
          <Text>{WidgetName}</Text>
        )}
      </SelectedWidgetDiv>
      {HDId && componentData?.Url && (
        <UrlComponentDiv onClick={redirectToPreview}>
          <UrlCompText>{componentData?.WidgetTemplateInstanceName}</UrlCompText>
          <DynamicSVGIcon width='2rem' iconUrl={RedirectIcon} />
        </UrlComponentDiv>
      )}
      {/* <WidgetComponent /> */}
    </div>
  ) : (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '1rem',
        // overflowX: 'auto'
        overflowX: 'auto',
        overflowY: 'hidden'
      }}
      id={`printableDiv${WID}`}
    >
      {/* ref */}
      <SelectedWidgetDiv HDId={HDId && componentData?.Url} ref={ref}>
        {WidgetComponent ? (
          <WidgetComponent
            WID={WID}
            isVisible={isVisible.current}
            layoutPos={layoutPos}
            WIDFromProp={WID}
            HDId={HDId}
            key={i}
            id={i}
            fullView={fullView}
            componentData={{
              ...componentData,
              formData: {
                ...componentData.formData,
                ...(WidgetComponentDataFromPOp?.formInput?.common ||
                  WidgetComponentData?.formInput?.common ||
                  {}),
                ...(WidgetComponentDataFromPOp?.formInput?.[type] ||
                  WidgetComponentData?.formInput?.[type] ||
                  {})
              } // combining the cofiguration defined in template
            }}
          />
        ) : (
          <Text>{WidgetName}</Text>
        )}
      </SelectedWidgetDiv>
      {/* <WidgetComponent /> */}
      {HDId && componentData?.Url && (
        <UrlComponentDiv onClick={redirectToPreview}>
          <UrlCompText>{componentData?.WidgetTemplateInstanceName}</UrlCompText>
          <DynamicSVGIcon width='2rem' iconUrl={RedirectIcon} />
        </UrlComponentDiv>
      )}
    </div>
  );
};

export default Index;
