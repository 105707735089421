/* eslint-disable indent */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { WIDGET_CATEGORIES } from '../../../constants';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { getDataSource } from '../../../views/Widgets/WidgetComponents/BarChart/data';
import { getFetchDetailsOfWidgetData } from '../../Widget/utils';
import { UpsertWidgetCreators } from './index';
import { Creators as ConfigCreators } from '../../../config/action';
import { arrayToJson } from '../../../shared/utility';
import { responseData } from './WidgetFormStructure';

export function* addWidgetSaga({ payload }) {
  try {
    const { widgetPayload, history, onSuccessNavigation } = payload;
    yield put(UpsertWidgetCreators.addWidgetStart());
    const fullURL = yield `${apiEndPoints.addWidget}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      // const { data } = response.data;
      // console.log('add widget', response.data);

      yield put(UpsertWidgetCreators.addWidgetSuccess({}));
      // if defined
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
      // old
      // history.push('/home');
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(ConfigCreators.setToastError(error?.error));
    yield put(UpsertWidgetCreators.addWidgetFailure(error?.error));
  }
}

export function* getWidgetDataSaga(payloadObj) {
  try {
    const {
      type,
      widgetPayload,
      onSuccessNavigation,
      detailsMapping = {
        data: 'equipments',
        meta: 'meta'
      }, // default details used
      fetchUrl,
      widgetCategory,
      allKPV = false,
      KPVs = []
    } = payloadObj.payload;
    const payload = type === 'EQUIPMENT_KPV_STATUS_VALUE' ? { widgetType: type, formInput: widgetPayload, allKPV, KPVs } : { widgetType: type, formInput: widgetPayload };
    yield put(UpsertWidgetCreators.getWidgetDataStart());
    const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
    // console.log('fullURL', fullURL);
    let response;
    if (method === 'get') {
      response = yield WebService?.[method](fullURL);
    } else {
      response = yield WebService?.[method](fullURL, JSON.stringify(payload));
    }
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response?.data?.data || {};
      if (widgetCategory === WIDGET_CATEGORIES.BAR_CHART) {
        const barChartDetails = getDataSource(response?.data?.data, 0);
        yield put(
          UpsertWidgetCreators.getWidgetDataSuccess({ barChartDetails })
        );
      } else if (widgetCategory === WIDGET_CATEGORIES.TABLE) {
        const { data, meta } = response?.data;
        yield put(UpsertWidgetCreators.getWidgetDataSuccess({ data, meta }));
      } else if (widgetCategory === WIDGET_CATEGORIES.GAUGE_CHART) {
        // format data for gauge chart here
        const { data, meta } = response?.data;
        // console.log('response?.data?.data', response?.data, response?.data?.data);
        yield put(UpsertWidgetCreators.getWidgetDataSuccess({ data, meta }));
      } else if (widgetCategory === WIDGET_CATEGORIES.STATUS_VALUE) {
        yield put(
          UpsertWidgetCreators.getWidgetDataSuccess({ data, meta })
        );
      } else if (widgetCategory === WIDGET_CATEGORIES.DETAILED_STATUS_VALUE) {
        const { data, meta } = response?.data?.data;
        yield put(UpsertWidgetCreators.getWidgetDataSuccess({ equipments: data, meta }));
      } else if (widgetCategory === WIDGET_CATEGORIES.TEXT_HTML_WIDGET) {
        const { htmlContent } = response?.data?.data;
        yield put(
          UpsertWidgetCreators.getWidgetDataSuccess({ data: htmlContent, meta })
        );
      } else {
        yield put(
          UpsertWidgetCreators.getWidgetDataSuccess({
            data,
            meta,
            detailsMapping
          })
        );
      }
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('error', error);
    yield put(UpsertWidgetCreators.getWidgetDataFailure(error?.error));
  }
}

export function* getWidgetInstancesSaga({ payload }) {
  const { IsSharedWithMe, Others, UserId, WidgetTypeId, page, pageSize } =
    payload;
  try {
    yield put(UpsertWidgetCreators.getWidgetInstancesStart());

    const fullURL = apiEndPoints.getWidgetInstances;
    const endpoint = `${fullURL}?WidgetTypeId=${WidgetTypeId}&UserId=${UserId}&IsSharedWithMe=${IsSharedWithMe}&Others=${Others}&page=${page || 1
      }&pageSize=${pageSize || 10}`;
    const response = yield WebService.get(endpoint);
    if (response.status >= 200 && response.status < 300) {
      // console.log(response.data, '-=-=-=-=-=-=-=-=-=-=');
      const { meta, data } = response.data;
      // level down the details for meta mapping in list
      const formatedData = data.map((e) => {
        const { WidgetComponentData, ...rest } = e;
        return { ...rest, ...WidgetComponentData };
      });
      // console.log('formatedData', formatedData);
      yield put(
        UpsertWidgetCreators.getWidgetInstancesSuccess({
          widgetData: formatedData,
          widgetMeta: meta
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const error = sagaCatchBlockHandling(err);
    yield put(UpsertWidgetCreators.getWidgetInstancesFailure(error?.error));
  }
}

export function* setFormStructureSaga({ payload }) {
  try {
    const { WTypeID } = payload;
    // const URL = WTypeID === 1009 ? apiEndPoints.dummyEnd : apiEndPoints.getWigetFormStructure;
    const URL = apiEndPoints.getWigetFormStructure;
    yield put(UpsertWidgetCreators.setFormStructureStart());
    const resp = yield WebService.get(URL, {
      params: { WTypeID }
    });
    // let resp;
    // if (WTypeID === 1009) {
    //   resp = yield WebService.post(URL, responseData, {
    //     params: { WTypeID }
    //   });
    // } else {
    //   resp = yield WebService.get(URL, {
    //     params: { WTypeID }
    //   });
    // }
    if (resp.status >= 200 && resp.status < 300) {
      const data = resp.data.data[0];

      // let data;
      // if (WTypeID === 1009) {
      //   data = resp.data.data.data[0];
      // } else {
      //   data = resp.data.data[0];
      // }
      yield put(UpsertWidgetCreators.setFormStructureSuccess(data));
    } else {
      throw resp;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    // console.log('e', e, error.error);
    yield put(UpsertWidgetCreators.setFormStructureFailure(error?.error));
  }
}

export function* setTemplateFormStructureSaga({ payload }) {
  try {
    const { widgetTypesMenu, widgetTypesIncluded } = payload;
    const URL = apiEndPoints.getWigetFormStructure;
    yield put(UpsertWidgetCreators.setTemplateFormStructureStart());
    const responses = yield Promise.all(
      widgetTypesIncluded.map((type) => {
        const typeId = widgetTypesMenu?.[type]?.id;
        return WebService.get(URL, {
          params: { WTypeID: typeId }
        });
      })
    );
    const allResolved = responses.every(
      (resp) => resp.status >= 200 && resp.status < 300
    );

    // console.log('responses', responses);
    if (allResolved) {
      const data = responses.map((resp, index) => {
        const type = widgetTypesIncluded[index];
        return {
          type,
          WidgetFormDetails: {
            ...resp?.data?.data?.[0]?.WidgetFormDetails,
            data: arrayToJson(
              resp?.data?.data?.[0]?.WidgetFormDetails.data,
              'key'
            )
          }
        };
      });
      yield put(UpsertWidgetCreators.setTemplateFormStructureSuccess(data));
    } else {
      throw responses;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    // console.log('e', e, error.error);
    yield put(
      UpsertWidgetCreators.setTemplateFormStructureFailure(error?.error)
    );
  }
}
