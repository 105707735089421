/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WIDGET_TYPES } from '../../../../constants';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import GridMultipleTable from '../../../../views/Widgets/WidgetComponents/DetailedStatusValueWidget.jsx/GridMultipleTable';
import AddDetailedStatusValue from './AddDetailedStatusValue.view';

const DetailedStatusValueWidget = () => {
  // const { statusEquipmentList, statuscategory } = useSelector((state) => state.upsertWidgetData);
  // const statusdata = useSelector((state) => state.upsertWidgetData);
  // console.log(statusdata);

  const { equipments } = useSelector((state) => state.upsertWidgetData);

  return (
    <div style={{ width: '54%' }}>
      {/* input form as modal */}
      {/* <AddDetailedStatusValue /> */}
      {/* actual widget */}
      <GridMultipleTable equipments={equipments} />
    </div>
  );
};

export default DetailedStatusValueWidget;
