import React from 'react';
import { SVGList, StatsContainer, StatsText, SvgContainer } from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.style';
import SvgStatusCard from './SvgStatusCard.View';

const Chillar = ({ colors, speed, dimension, componentData, numSvg }) => {
  const { formData } = componentData || {};
  const svgList = [];
  const width = `${dimension.width}px`;
  const height = `${dimension.height}px`;
  for (let i = 0; i < numSvg; i += 1) {
    svgList.push(
      <SvgContainer>
        <StatsContainer>
          {formData && (
          <SvgStatusCard
            machineStatus={formData?.machineState?.label}
            speed={formData?.speed?.label}
          />
          )}
          {/* <StatsText>
            {' '}
            Chiller :
            {' '}
            {i + 1}

          </StatsText>
          <StatsText>
            {' '}
            Machine Status :
            {' '}
            {formData?.machineState?.label}

          </StatsText>
          <StatsText>
            {' '}
            Speed :
            {' '}
            {formData?.speed?.label}

          </StatsText> */}

        </StatsContainer>
        <svg
          width="80%"
          height="80%"
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 18.46 25.37'
        >
          <defs>
            <style>
              {` .cls-1 {
                        fill: ${colors.class_1}
                      }`}

              {` .cls-2 {
                        fill: ${colors.class_2}
                      }`}

              {`   .cls-3 {
                        fill:${colors.class_3}
                      }`}

              {`   .cls-4 {
                        fill:${colors.class_4}
                      }`}

              {`   .cls-5 {
                        fill: ${colors.class_5};
                      }`}

              {`   .cls-6 {
                        fill:${colors.class_6};
                      }`}
            </style>
          </defs>
          <circle
            id='Ellipse_956'
            data-name='Ellipse 956'
            className='cls-3'
            cx='5.06'
            cy='10.94'
            r='3.63'
          />
          <g>
            <g>
              <path
                id='Path_68126'
                data-name='Path 68126'
                className='cls-3'
                d='m17.85,9.39v12.31c0,1.48-1.2,2.68-2.68,2.68-1.48,0-2.68-1.2-2.68-2.68h0v-1.45h-2.96l-.02.29c-.14,2.46-2.24,4.34-4.69,4.2-1.1-.06-2.13-.52-2.91-1.3-.84-.82-1.31-1.95-1.31-3.13v-9.52c0-1.82,1.11-3.45,2.8-4.13l.18-.08v-2.12c0-2.13,1.73-3.85,3.86-3.86h4.92c.05,0,.1.04.1.09,0,0,0,0,0,0h.61v2.76h-.61c0,.05-.04.1-.09.1,0,0,0,0,0,0h-4.35c-.82,0-1.49.67-1.49,1.49v1.53l.2.08c.56.22,1.06.55,1.47.98.84.83,1.31,1.96,1.31,3.15v7.7h2.98v-9.11c0-1.48,1.2-2.67,2.68-2.67,1.48,0,2.67,1.2,2.67,2.67'
              />
              <path
                id='Path_68127'
                data-name='Path 68127'
                className='cls-3'
                d='m18.15,9.39v12.31c0,1.65-1.33,2.98-2.98,2.98s-2.98-1.33-2.98-2.98h0v-1.14h-2.37c-.15,2.62-2.39,4.63-5.01,4.49-1.17-.06-2.27-.56-3.1-1.38-.9-.88-1.41-2.09-1.41-3.35v-9.52c0-1.94,1.18-3.69,2.98-4.41v-1.92c0-2.3,1.87-4.16,4.17-4.17h4.92c.23,0,.41.18.41.41h-.31c0-.05-.04-.1-.09-.1,0,0,0,0,0,0h-4.92c-2.13,0-3.85,1.73-3.86,3.86v2.12l-.18.08c-1.69.68-2.79,2.31-2.8,4.13v9.52c0,1.18.47,2.3,1.31,3.12,1.74,1.74,4.55,1.74,6.3,0,.78-.78,1.24-1.81,1.3-2.91l.02-.29h2.96v1.45c0,1.48,1.2,2.68,2.68,2.68,1.48,0,2.68-1.2,2.68-2.68h0v-12.31c0-1.48-1.2-2.68-2.68-2.68-1.48,0-2.68,1.2-2.68,2.68v9.11h-2.98v-7.7c0-1.18-.47-2.31-1.31-3.15-.41-.43-.91-.76-1.47-.98l-.21-.08v-1.53c0-.82.67-1.49,1.49-1.49h4.35c.05,0,.1-.04.1-.09,0,0,0,0,0,0h.31c0,.23-.18.41-.41.41h-4.35c-.65,0-1.18.53-1.18,1.18v1.33c.6.23,1.14.59,1.59,1.04.89.89,1.39,2.11,1.39,3.37v7.39h2.37v-8.8c0-1.65,1.33-2.98,2.98-2.98s2.98,1.33,2.98,2.98'
              />
              <rect
                id='Rectangle_5472'
                data-name='Rectangle 5472'
                className='cls-6'
                x='.54'
                y='17.39'
                width='9.04'
                height='.65'
              />
              <path
                id='Path_68136'
                data-name='Path 68136'
                className='cls-2'
                d='m9.45,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68137'
                data-name='Path 68137'
                className='cls-2'
                d='m8.75,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68138'
                data-name='Path 68138'
                className='cls-2'
                d='m8.05,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68142'
                data-name='Path 68142'
                className='cls-2'
                d='m7.36,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68143'
                data-name='Path 68143'
                className='cls-2'
                d='m6.66,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68144'
                data-name='Path 68144'
                className='cls-2'
                d='m5.96,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68145'
                data-name='Path 68145'
                className='cls-2'
                d='m5.27,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68146'
                data-name='Path 68146'
                className='cls-2'
                d='m4.57,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68148'
                data-name='Path 68148'
                className='cls-2'
                d='m3.87,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68149'
                data-name='Path 68149'
                className='cls-2'
                d='m3.18,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68150'
                data-name='Path 68150'
                className='cls-2'
                d='m2.48,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68151'
                data-name='Path 68151'
                className='cls-2'
                d='m1.79,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <path
                id='Path_68152'
                data-name='Path 68152'
                className='cls-2'
                d='m1.09,17.65l-.36.26-.19-.14.36-.27.19.14Z'
              />
              <rect
                id='Rectangle_5471'
                data-name='Rectangle 5471'
                className='cls-6'
                x='12.5'
                y='13.28'
                width='5.42'
                height='.45'
              />
              <path
                id='Path_68128'
                data-name='Path 68128'
                className='cls-4'
                d='m16.87,12.68h1.06v.29h-1.07c-.12,0-.23.03-.32.1-.08.08-.13.2-.13.32v.04h-.29v-.05c0-.2.08-.39.22-.53.15-.13.34-.19.54-.18'
              />
              <path
                id='Path_68129'
                data-name='Path 68129'
                className='cls-1'
                d='m16.07,13.5c0-.1.08-.18.18-.18s.18.08.18.18c0,.1-.08.18-.18.18-.1,0-.18-.08-.18-.18'
              />
              <path
                id='Path_68130'
                data-name='Path 68130'
                className='cls-2'
                d='m16.17,13.5s.04-.08.08-.08c.05,0,.08.04.08.08,0,.05-.04.08-.08.08-.05,0-.08-.04-.08-.08'
              />
              <path
                id='Path_68131'
                data-name='Path 68131'
                className='cls-5'
                d='m16.21,13.62s.02,0,.04,0,.04,0,.04,0-.02,0-.04,0-.04,0-.04,0'
              />
              <path
                id='Path_68132'
                data-name='Path 68132'
                className='cls-4'
                d='m17.92,14.03v.29h-3.12c-.2.01-.39-.05-.54-.18-.16-.15-.24-.36-.22-.58h.29c-.04.22.11.43.33.46h0s.08,0,.12,0h3.14Z'
              />
              <path
                id='Path_68133'
                data-name='Path 68133'
                className='cls-1'
                d='m14,13.5c0-.1.08-.18.18-.18s.18.08.18.18c0,.1-.08.18-.18.18-.1,0-.18-.08-.18-.18,0,0,0,0,0,0'
              />
              <path
                id='Path_68134'
                data-name='Path 68134'
                className='cls-2'
                d='m14.1,13.5s.04-.08.08-.08c.05,0,.08.04.08.08,0,.05-.04.08-.08.08-.05,0-.08-.04-.08-.08'
              />
              <path
                id='Path_68135'
                data-name='Path 68135'
                className='cls-5'
                d='m14.15,13.38s.02,0,.04,0,.04,0,.04,0-.02,0-.04,0-.04,0-.04,0'
              />
              <path
                id='Path_68125'
                data-name='Path 68125'
                className='cls-4'
                d='m15.17,6.13c-1.81,0-3.28,1.46-3.29,3.27v8.49h-1.76v-7.09c0-1.35-.53-2.64-1.47-3.59-.44-.42-.95-.77-1.51-1.02v-1.12c0-.48.38-.87.86-.88,0,0,0,0,.01,0h4.35c.39,0,.71-.32.72-.71h-.61c0,.05-.04.1-.09.1,0,0,0,0,0,0h-4.35c-.82,0-1.49.67-1.49,1.49v1.53l.21.08c.56.21,1.06.55,1.47.98.84.83,1.31,1.96,1.31,3.15v7.7h2.98v-9.11c.05-1.48,1.28-2.64,2.76-2.59,1.41.04,2.55,1.18,2.59,2.59v12.31c.05,1.48-1.12,2.71-2.59,2.76-1.48.05-2.71-1.12-2.76-2.59,0-.05,0-.11,0-.16v-1.45h-2.96l-.02.29c-.14,2.46-2.24,4.34-4.69,4.2-1.1-.06-2.13-.52-2.91-1.3-.84-.82-1.31-1.95-1.31-3.13v-9.52c0-1.82,1.11-3.45,2.8-4.13l.18-.08v-2.12c0-2.13,1.73-3.85,3.86-3.86h4.92c.05,0,.1.04.1.09,0,0,0,0,0,0h.61c0-.39-.32-.71-.72-.71h-4.92C4.98,0,2.98,2,2.98,4.47c0,0,0,0,0,0v1.71C1.17,7.01,0,8.81,0,10.8v9.52c0,1.34.54,2.63,1.49,3.57,1.99,1.97,5.21,1.96,7.18-.03.8-.81,1.31-1.86,1.44-2.99h1.78v.84c-.02,1.82,1.44,3.3,3.26,3.32,1.82.02,3.3-1.44,3.32-3.26,0-.02,0-.04,0-.06v-12.31c0-1.81-1.48-3.27-3.29-3.27'
              />
            </g>
            <path
              id='Path_68140'
              data-name='Path 68140'
              className='cls-6'
              d='m5.06,14.57c-2,0-3.63-1.62-3.63-3.63,0-2,1.62-3.63,3.63-3.63,2,0,3.63,1.62,3.63,3.63h0c0,2-1.62,3.63-3.63,3.63m0-7.05c-1.89,0-3.42,1.53-3.42,3.42,0,1.89,1.53,3.42,3.42,3.42,1.89,0,3.42-1.53,3.42-3.42,0-1.89-1.53-3.42-3.42-3.42'
            />
          </g>
          <g>
            <path
              id='Path_68141'
              data-name='Path 68141'
              className='cls-6'
              d='m7.85,12.28c-.46.74-1.44.96-2.17.5-.38-.24-.64-.62-.71-1.06,0-.03-.03-.06-.06-.06-.11-.02-.2-.07-.29-.14-.03-.03-.08-.02-.11,0-.45.5-.57,1.21-.3,1.83.02.04,0,.09-.04.1,0,0,0,0,0,0h-.01c-.84.18-1.66-.36-1.84-1.19-.13-.64.15-1.3.71-1.64.42-.27.95-.32,1.42-.14.03.01.06,0,.09-.02.06-.06.13-.12.2-.16.03-.02.05-.05.04-.09-.17-.66-.72-1.16-1.39-1.26-.04,0-.07-.05-.07-.09,0,0,0,0,0,0h0c.3-.82,1.19-1.23,2-.93.61.23,1.02.82,1.01,1.47,0,.46-.2.9-.55,1.2-.03.02-.03.06-.02.09.03.08.05.17.05.25v.03s.02.07.06.08c.65.18,1.34-.03,1.77-.54.03-.03.08-.04.11,0h.01c.4.52.44,1.23.11,1.78Z'
            >
              <animateTransform
                attributeType='xml'
                attributeName='transform'
                type='rotate'
                from='360 5.06 10.94'
                to='0 5.06 10.94'
                dur={speed}
                additive='sum'
                repeatCount='indefinite'
              />
            </path>
            <circle
              id='Ellipse_957'
              data-name='Ellipse 957'
              className='cls-3'
              cx='5.06'
              cy='10.94'
              r='.55'
            />
          </g>
        </svg>
      </SvgContainer>
    );
  }
  return (<SVGList style={{ width, height }}>{svgList}</SVGList>);
};
export default Chillar;
