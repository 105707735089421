/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useActions } from '../../../store/hooks/useActions';
import { INPUT_TYPES, PAGE_TYPES } from '../../../constants';
import AddWidgetForm from '../../UpsertWidget/WidgetForm/AddWidgetForm';
import {
  getFieldDependents,
  getResetPayloadForDependentFields
} from '../../UpsertWidget/WidgetForm/Utility';
import TemplateFormComp from './TemplateFormComp';
import PageHeader from '../../Common/PageHeader/PageHeader';
import ButtonComp, { ButtonContainer } from '../../Common/Button/Button.view';
import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import EquipmentFilterSearchTypeTree from '../../Common/InputTypes/FilterSearchType/EquimentFilterSearchTypeTree';
import { getFetchDetailsOfWidgetData } from '../../Widget/utils';
import { isEmpty } from '../../../shared/utility/isEmpty';
import FilterSearchComponent from '../../Filter/FilterSearchComponent/FilterSearchComponent';
import FilterContainer from '../../Filter/FilterContainer/FilterContainer';
import Loader from '../../Loader/Loader';
import TreeSearchComponent from '../../WidgetForm/WidgetFormTreeSearch/TreeSearchComponent';
import TreeContainer from '../../WidgetForm/WidgetFormTreeContainer/TreeContainer';
import { generateSelectedFilter } from '../../ResultView/Iframe/generateSelectedFilter';
import {
  getFilterSelectionInp,
  populateFilterDashboard
} from '../../WidgetForm/utility/populateFilterDashboard';
import { Creators as ObjectTreeCreators } from '../../WidgetForm/Store';

const TemplateFormView = () => {
  const {
    formStructure,
    formInput,
    widgetType,
    widgetLabel,
    widgetCategory,
    loading,
    error,
    templateName,
    templateFormStructure,
    templateFormStructureFieldDepenents,
    templateFormInput,
    templateBasedData
  } = useSelector((state) => state.upsertWidgetData);

  const [showFilter, setShowFilter] = useState(false);
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [activeFilterCard, setActiveFilterCard] = useState(0);

  const isFormIntialized = useRef();
  const userData = useSelector((state) => state.loginData.userData);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const widgetTypesIncluded = useSelector(
    (state) => state.widgets.widgetTypesIncluded
  );
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const menuIdofWidget = useSelector((state) => state.widgets.menuIdofWidget);
  const WidgetTypeMenuRoutes = useSelector(
    (state) => state.home?.[PAGE_TYPES.WIDGET]
  );
  const objectTreeData = useSelector(
    (state) => state.objectTreeData.objectTreeData
  );

  const ObjectTreeDataPlant = useSelector((state) => state.objectTreeData);

  const cumulativeTags = useSelector(
    (state) => state.objectTreeData.cumulativeTags
  );
  const breakpoint = useSelector((state) => state.objectTreeData.breakpoint);

  const loadingTree = useSelector((state) => state.objectTreeData.loading);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const {
    setFormInput,
    setTemplateFormStructure,
    valueSetter,
    setToastError,
    getObjectTreeDataV2,
    clearTreeData,
    setTemplateBasedData
  } = useActions();
  const history = useHistory();
  const InheritedWPID = useSelector((state) => state.widgets.InheritedWPID);

  const location = useLocation();
  const { state } = location;
  const { WTId } = state || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (WTId) {
      if (!isEmpty(objectTreeData)) {
        const filterTree = objectTreeData.reduce((acc, cur) => {
          const { keyName } = cur;
          return {
            ...acc,
            [keyName]: [cur]
          };
        }, {});
        // console.log(filterTree, 'fil');
        const check = {
          data: {
            tree: [
              {
                ...templateBasedData?.formInput?.common?.attribute[0]?.value?.tree
              }
            ]
          }
        };
        delete check?.data?.tree[0]?.id;
        const { cumTags, cumSelectedObj } = populateFilterDashboard(
          check,
          filterTree
        );
        const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
        dispatch(ObjectTreeCreators.PopulateTreeStructure(payload));
        dispatch(
          ObjectTreeCreators.universalObjectTreeSetter({
            key: 'breakpoint',
            value: templateBasedData?.breakpoint
          })
        );
      }
    }
  }, [templateBasedData, objectTreeData, WTId]);
  useEffect(() => {
    if (widgetTypesIncluded) {
      setTemplateFormStructure({ widgetTypesMenu, widgetTypesIncluded });
    }
  }, [widgetTypesIncluded]);

  // console.log(interval, 'interval');

  const navigateToDashboard = () => {
    // handle based on menu
    history.push(WidgetTypeMenuRoutes[menuIdofWidget]);
    // old implementation
    // history.push('/home');
  };

  const handleBack = () => {
    navigateToDashboard();
  };

  // const onSuccessNavigation = () => {
  // history.push({ pathname: '/home/create-widget', state: { isEdit, WID } });
  // };

  const templateFormRef = useRef();

  useEffect(() => {
    if (templateFormInput.tree) {
      setShowFilter(true);
      setShowFilterSelection(true);
    } else {
      setShowFilter(false);
      setShowFilterSelection(false);
    }
  }, [templateFormInput]);

  const handleSave = async () => {
    let payloadTree;
    if (!isEmpty(objectTreeData)) {
      payloadTree = generateSelectedFilter(cumulativeTags, objectTreeData);
      // console.log(payload, 'pay');
    }
    const localFormInput = templateFormRef.current.getFormInput();
    // console.log('saving form input to reducer', localFormInput);
    try {
      if (!WTId) {
        // creation
        let res;
        //* * added isEmpty(payloadTree) condition when creating template if lock at first level without selection it wont allow tree during instance creation  */
        if (!isEmpty(breakpoint) && !isEmpty(payloadTree)) {
          res = await WebService.post(apiEndPoints.addWidgetPanelTemplate, {
            WidgetTemplateName: templateName,
            UserId: userData.userId,
            WPID: InheritedWPID || activeTab.id, // important, use inheritedWPId if present
            TemplateParameters: {
              WPID: InheritedWPID || activeTab.id,
              widgetTypesIncluded,
              formInput: localFormInput
            },
            TemplateBaseData: {
              breakpoint,
              formInput: {
                common: {
                  attribute: [
                    {
                      value: payloadTree
                    }
                  ]
                }
              }
            }
          });
        } else {
          res = await WebService.post(apiEndPoints.addWidgetPanelTemplate, {
            WidgetTemplateName: templateName,
            UserId: userData.userId,
            WPID: InheritedWPID || activeTab.id, // important, use inheritedWPId if present
            TemplateParameters: {
              WPID: InheritedWPID || activeTab.id,
              widgetTypesIncluded,
              formInput: localFormInput
            },
            TemplateBaseData: {}
          });
        }
        if (res.status >= 200 && res.status < 300) {
          valueSetter({ showTemplateListSidePanel: true });
          history.push({
            pathname: WidgetTypeMenuRoutes[menuIdofWidget],
            state: { showTemplateList: true }
          });
        } else {
          throw res;
        }
      } else {
        // updation
        const res = await WebService.post(
          `${apiEndPoints.updateWidgetPanelTemplate}/${WTId}`,
          {
            // TODO: need to add WPId also here
            WidgetTemplateName: templateName,
            UserId: userData.userId,
            WPID: InheritedWPID || activeTab.id, // use inherited WPID here if present
            TemplateParameters: {
              WPID: InheritedWPID || activeTab.id,
              widgetTypesIncluded,
              formInput: localFormInput
            },
            TemplateBaseData: {
              breakpoint,
              formInput: {
                common: {
                  attribute: [
                    {
                      value: payloadTree
                    }
                  ]
                }
              }
            }

          }
        );
        if (res.status >= 200 && res.status < 300) {
          valueSetter({ showTemplateListSidePanel: true });
          setTemplateBasedData(res.data?.data[0][0]?.TemplateBaseData);
          // history.replace({
          //   pathname: '/home/view-dashboard',
          //   state: { isTemplateInstance: false }
          // });
          history.goBack();
        } else {
          throw res;
        }
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('error in creating template', e, error);
      setToastError(error);
    }
  };

  useEffect(() => {
    if (showFilterSelection && !isEmpty(templateFormStructure)) {
      clearTreeData();
      const res = templateFormStructure?.common?.filter(
        (item) => item?.key === 'tree'
      );
      if (isEmpty(res)) {
        setShowFilterSelection(false);
      }
      const { params, source } = res[0]?.dataSource || {};
      const { fullURL, method } = getFetchDetailsOfWidgetData(source);
      getObjectTreeDataV2({ directParams: params, directURL: fullURL, method });
    }
  }, [showFilterSelection, templateFormStructure]);

  useEffect(() => {
    if (!templateName) {
      navigateToDashboard();
    }
  }, [templateName]);

  return (
    <>
      <PageHeader
        hasBack={true}
        handleBack={handleBack}
        title={`${WTId ? 'Edit' : 'Select'} Template Parameters`}
      >
        <ButtonContainer>
          <ButtonComp onClick={handleBack}>Discard</ButtonComp>
          <ButtonComp disabled={!isFormFilled || loadingTree} dark onClick={handleSave}>
            Save
          </ButtonComp>
        </ButtonContainer>
      </PageHeader>
      <TemplateFormComp
        ref={templateFormRef}
        data={templateFormStructure}
        formInput={templateFormInput}
        setFormInput={setFormInput}
        setIsFormFilled={setIsFormFilled}
        templateFormStructureFieldDepenents={
          templateFormStructureFieldDepenents
        }
        showHeader
        handleBack={handleBack}
        handleSave={handleSave}
        setShowFilterSelection={setShowFilterSelection}
        formStructureFieldDepenents={getFieldDependents(formStructure)}
      />

      {loadingTree ? (
        <div style={{ width: 'max(50%, 75rem)', margin: '2rem' }}>
          <Loader />
        </div>
      ) : showFilterSelection && objectTreeData && (
      <>
        <div style={{ width: 'max(50%, 75rem)', margin: '2rem' }}>
          <TreeSearchComponent
            placeholder={objectTreeData[0]?.placeholder || 'Filters'}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setActiveTab={setActiveFilterCard}
            isLabelChart={true}
          />
        </div>
        {showFilter && objectTreeData && (
        <>
          <div style={{ margin: '2rem' }}>
            <TreeContainer
              item={objectTreeData[0]}
              activeFilterCard={activeFilterCard}
              setActiveFilterCard={setActiveFilterCard}
              isPreSelection={true}
              hasPreSelection={true}
            />
          </div>
        </>
        )}
      </>
      )}
    </>
  );
};

export default TemplateFormView;
