import styled from 'styled-components';
import { fontFamily, fontSize, device } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';
import { SearchDropDown } from '../../GlobalSearch/GlobalSearchContainer.style';
import Radiobutton, { Radio } from '../RadioButton/RadioButton';

export const NestedModalContainer = styled.div`
  width: 100%;
  padding: 15px 20px 15px 20px;
`;

export const ModalContentText = styled(FlexContainer)`
  & * {
    font-size: 2rem;
  }
  font-size: 2rem;
  flex: 1;
  color: ${({ theme }) => theme.contrast.primary};
  font-family: ${fontFamily.circularBold};
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: capitalize;
  max-width: 100%;
  white-space: nowrap;
  align-items: center;
  padding: 0 10px;
  &.outside {
    color: ${({ theme }) => theme.contrast.darkPrimary};
  }
  &.settingBtn {
    display: flex;
  }
  &.settingMenu {
    display: flex;
  }
  &.disable {
    opacity: 0.2;
  }
  & .iconSvg {
    margin-right: 1rem;
    & * {
      /* fill: ${({ theme }) => theme.contrast.primary} !important; */
    }
  }
  & .selection_icon.ml {
    margin-right: 1rem;
  }
  & .next_icon {
    margin-right: 1rem;
  }

  &.uploader {
    font-size: ${fontSize.title};
    font-family: ${fontFamily.circularBold};
    color: ${({ theme }) => theme?.modal?.black || '#393939'};
  }
`;
export const ModalContainer = styled.div`
  min-height: 20px;
  /* position: fixed !important; */
  //Made this change Tabslist menu hidden when overflow hidden
  position: ${({ isTab }) => (isTab ? 'fixed' : 'absolute')} !important;
  background-color: ${({ theme }) => theme.specificDetails.primary};
  z-index: 21;
  width: ${(props) => props.width || '100%'};
  display: flex;
  border-radius: 6px;
  overflow: auto;
  /* min-width: max-content; */
  /* width: auto; */
  max-height: 50rem;
  // hanlde dropdown V1
  display: ${({ isActive }) => (isActive || isActive === undefined ? 'block' : 'none')};
  // TODO: removed
  padding: ${({ noContainerPadding }) => (noContainerPadding ? '0px' : '15px 20px 15px 20px')};
  &.light {
    background-color: ${({ theme }) => theme.core.pureSecondary};
    & ${ModalContentText} {
      color: ${({ theme }) => theme.contrast.primary};
    }
  }
  &.outside {
    background-color: ${({ theme }) => theme.core.pureSecondary};
    border: 1px solid ${({ theme }) => theme.core.secondary};

    & p {
      color: ${({ theme }) => theme.contrast.darkPrimary} !important;
    }
  }
  &.hasNested {
    /* background-color: transparent; */
    overflow: visible;

    & ${NestedModalContainer} {
      padding: 0px;
    }
  }

  @keyframes heightAnimation {
    from {
      opacity: 0;
      transform: translateY(10px);
      overflow: hidden;
    }
    to {
      opacity: 1;
      transform: translateY(0px);
      overflow: hidden;
    }
  }

  @media ${device.tablet} {
    display: none;
  }
`;
export const LevelContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.core.pureSecondary};
  width: ${(props) => props.width || '100%'};
  padding: 0px 0px 5px 10px;
  &.paddingTop {
    padding: 10px 0px 5px 10px;
  }
`;

export const ModalContentDiv = styled.div`
  /* height: 25px; */
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* &.hasRadio {
    display: flex;
    justify-content: space-between;
  } */
  &.radio ${Radio} {
    margin-right: 10px;
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.aux.darkSecondary};
  }
  
  &.icon {
    & p {
      margin-left: 10px;
    }
  }
`;

export const TextSpan = styled.span`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InputAndButtonContainer = styled.div`
  display: flex;
`;
export const DropdownLabelContainer = styled(FlexContainer)`
  width: 10rem;
  justify-content: space-between;
`;

export const DropBtn = styled(FlexContainer)`
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & > svg {
    height: 15px;
    width: 15px;
    transform: rotate(90deg);
  }

  &.nonActive {
    display: none;
  }
`;

export const DropdownCon = styled(SearchDropDown)`
  height: 165px;
  width: 53rem;
  z-index: 0;
  @keyframes heightAnimate {
    0% {
      height: 0vh;
    }
    100% {
      height: 165px;
    }
  }
`;

export const Image = styled.div`
  /* width: ${({ width }) => `${width}rem`};
  height: ${({ width }) => `${width}rem`}; */
  padding: 2rem;
  & * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;
