/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { FlexContainer } from '../../../../globalStyles';
// import useClickOutside from '../../../../shared/hooks/useClickOutside';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import {
  Container
  // , DescriptionTextBox, FormContainer, HeaderText, LowOpacityText
} from './Annotations.styles';
import { Creators as kpvChartingCreators } from '../../Store/action';
import AnnotationsModal from './AnnotationModal';
import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getAnnotationPayload } from './utils';
import Loader from '../../../Common/Loader/Loader';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';

const EditAnnotationsForm = ({
  index,
  pointClicked,
  setPointClicked,
  annotatedPoint,
  chartType,
  chartTypeCategory
}) => {
  if (isEmpty(pointClicked)) {
    return <></>;
  }

  // const [showBox, setShowBox] = useState(true);
  // const { point, activeAnnotationsDetails } = pointClicked;
  const { argument, seriesLabel, text, seriesKey } = annotatedPoint;
  const [annotDesc, setAnnotDesc] = useState(text);
  const { email, userId } = useSelector((state) => state.loginData.userData);
  const userid = email || userId;
  const annotationRef = useRef();
  const dispatch = useDispatch();
  const closeModal = () => {
    // console.log('closing modal');
    setPointClicked({});
  };
  const annotationDetails = {
    kpvid: pointClicked?.kpvKey,
    seriesLabel: seriesLabel || pointClicked.kpvLabel,
    argument,
    text: annotDesc,
    seriesKey,
    userid,
    chartType,
    chartCategory: chartTypeCategory,
    pointLabel: pointClicked?.pointLabel
  };
  const [loading, setLoading] = useState(false);

  const handleEditAnnotation = async () => {
    // console.log('saving...');

    try {
      setLoading(true);
      const response = await WebService.post(apiEndPoints.updateAnnotations, {
        ...getAnnotationPayload(annotationDetails),
        mode: 'update'
      });
      if (response.status >= 200 && response.status < 300) {
        dispatch(
          kpvChartingCreators.setAnnotations({
            index,
            operation: 'edit',
            value: {
              argument,
              text: annotDesc
            }
          })
        );
      } else {
        throw response;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('annotaton edit error', error);
      dispatch(kpvChartingCreators.getPlotDataFailure(error));
    }
    setLoading(false);
    // console.log('res', response);
    closeModal();
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await WebService.post(apiEndPoints.updateAnnotations, {
        ...getAnnotationPayload(annotationDetails),
        mode: 'delete'
      });
      if (response.status >= 200 && response.status < 300) {
        dispatch(
          kpvChartingCreators.setAnnotations({
            index,
            operation: 'delete',
            value: {
              argument
              //   text: annotDesc
            }
          })
        );
      } else {
        throw response;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      console.log('annotaton delete error', error);
      dispatch(kpvChartingCreators.getPlotDataFailure(error));
    }
    setLoading(false);
    // console.log('res', response);
    closeModal();
  };

  // useClickOutside(annotationRef, closeModal);

  return (
    <div>
      <Container left={pointClicked?.x} top={pointClicked?.y} ref={annotationRef} style={{ postiion: 'relative' }}>
        {loading && <Loader />}
        <AnnotationsModal
          argument={argument}
          closeHanlder={closeModal}
          annotDesc={annotDesc}
          setAnnotDesc={setAnnotDesc}
          handleSave={handleEditAnnotation}
          handleDelete={handleDelete}
          isEdit={true}
          seriesLabel={seriesLabel}
        />
      </Container>
    </div>
  );
};

export default EditAnnotationsForm;
