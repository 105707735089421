/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/named */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { AddWidgetBg } from '../../assets/icons';
import { isEmpty } from '../../shared/utility/isEmpty';
import { FlexContainer } from '../../globalStyles';
import Loader from '../../component/Loader/Loader';
import EmptyWidgetLanding from '../../component/Widget/Common/EmptyWidgetLanding';
import { useActions } from '../../store/hooks/useActions';
import ShowCaseLayoutHome from './ShowCaseLayoutHome';

const WidgetDashBoard = ({ isCustom = false }) => {
  const { getHomeLayout, setIsPreviewInstance, setConfigureTemplate, resetActiveTab } = useActions();

  const userData = useSelector((state) => state.loginData.userData);
  const breakpoint = useSelector((state) => state.widgets.breakpoint);
  const layout = useSelector((state) => state.HomeDashboardReducer.layout);
  const loadingLayout = useSelector((state) => state.HomeDashboardReducer.loadingLayout);

  useEffect(() => {
    resetActiveTab();
    getHomeLayout({ UserId: userData.userId });
  },
  []);

  useEffect(() => {
    setIsPreviewInstance(false);
    setConfigureTemplate(false);
  }, []);

  if (loadingLayout) {
    return (
      <FlexContainer style={{ marginTop: '30vh' }}>
        <Loader />
      </FlexContainer>
    );
  }

  return (
    <>
      {isEmpty(layout?.[breakpoint]) ? (
        <>
          <EmptyWidgetLanding
            label="Add Widgets"
            iconUrl={AddWidgetBg}
            description="No Widgets! Please add widgets to display"
          />
        </>
      ) : (
        <>
          <ShowCaseLayoutHome isCustom={isCustom} />
        </>
      )}

    </>
  );
};

export default WidgetDashBoard;
