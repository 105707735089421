/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import { createReducer } from 'reduxsauce';
import { getWidgetCategory } from '../../../component/Widget/utils';
import { getWidgetLayoutData } from '../../../component/Widget/utils/getLayoutData';
import { resolveTabsList } from '../../../component/Widget/utils/resolveTabsList';
import { setWidgetDimensions } from '../../../component/Widget/utils/setWigetDimensions';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { Types } from './action';

const INITIAL_STATE = {
  tabList: [
    // { id: 1, label: 'India' },
    // { id: 2, label: 'India1' },
    // { id: 3, label: 'India2' }
  ],
  activeTab: {},

  isEditOn: {},
  isHideHeader: {},
  isHideBorder: {},
  isEnablePivot: {},
  isTemplateConfigure: false,
  isEquipmentSelect: false,

  WidgetPanelName: '',
  WidgetPanelDesc: '',

  startIndex: 0,
  widgetTypesMenu: {},
  layout: {},
  breakpoint: 'lg', // setBreakPoint
  layoutComponentData: {},
  isLayoutUpdated: false,
  zoomLevel: {},
  fullWidgetData: {},
  newComponentData: {},
  widgetPanelPayload: {},
  widgetPayload: {},
  loadingLayout: false,
  layoutError: '',
  addedWidget: [],
  showSidePanel: false,
  error: '',
  layoutWarning: '',
  menuIdofWidget: '', // active widget type menuid
  repeatedWidgets: [], // duplicate instances
  // UI
  showWidgetSelectionPanel: false,
  widgetTypesIncluded: [], // list of widget types included in the given panel
  WidgetComponentData: {},
  TemplateBaseData: {},
  TemplateParameters: {},
  InheritedWPID: '',
  isTemplateComponentUpdateReq: true, // used to overide local templates details
  shouldRefreshPage: false,
  configureInstance: false,
  isPreviewInstance: false,
  TabType: '',

  widgetDashboardType: 'ALL',

  loadingKpv: false,
  kpvData: [],
  kpvMeta: {},
  selectedKpvs: [],
  selectedKpvList: [],
  isKpvConfigured: false,

  searchQueries: {},

};

const setMenuIdOfWidget = (state = INITIAL_STATE, action) => ({
  ...state,
  menuIdofWidget: parseInt(action.payload, 10)
});

const setWidgetPageType = (state = INITIAL_STATE, action) => ({
  ...state,
  widgetDashboardType: action.payload
});

/** Reducer to set true if we are editing the configurations of template instance in preview mode */
const setIsPreviewInstance = (state = INITIAL_STATE, action) => ({
  ...state,
  isPreviewInstance: action.payload
});

const setConfigureTemplate = (state = INITIAL_STATE, action) => ({
  ...state,
  isTemplateConfigure: action.payload
});

const setEquipmentSelect = (state = INITIAL_STATE, action) => ({
  ...state,
  isEquipmentSelect: action.payload
});

const setEditWidget = (state = INITIAL_STATE, action) => {
  const { isEditOn, WPID } = action.payload;

  return {
    ...state,
    isEditOn: { ...state.isEditOn, [WPID]: isEditOn }
  };
};
const setEditDiableWidget = (state = INITIAL_STATE, action) => {
  let userIDForHomeDashboard = null;
  const data = Object.entries(state?.isEditOn);
  if (data.length) {
    const [key, value] = data[0];
    userIDForHomeDashboard = key;
  }
  return {
    ...state,
    isEditOn: userIDForHomeDashboard ?? { ...state.isEditOn, [userIDForHomeDashboard]: false }
  };
};

const setHeaderHide = (state = INITIAL_STATE, action) => {
  const { WID, WPID, isHideHeader } = action.payload;

  return {
    ...state,
    isHideHeader: { ...state.isHideHeader, [WID + WPID]: isHideHeader }
  };
};
const setBorderHide = (state = INITIAL_STATE, action) => {
  const { WID, WPID, isHideBorder } = action.payload;

  return {
    ...state,
    isHideBorder: { ...state.isHideBorder, [WID + WPID]: isHideBorder }
  };
};

const setEnablePivot = (state = INITIAL_STATE, action) => {
  const { WID, WPID, isEnablePivot } = action.payload;

  return {
    ...state,
    isEnablePivot: { ...state.isEnablePivot, [WID + WPID]: isEnablePivot }
  };
};

// ===============GET WIDGET TABS ===============
const getWidgetTabsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const resetActiveTab = (state = INITIAL_STATE, action) => ({
  ...state,
  activeTab: {}
});

const getWidgetTabsSuccess = (state = INITIAL_STATE, action) => {
  let { activeTab } = state;
  const { makeFistTabActive, tabList, idParam } = action.payload;
  const tabItem = tabList.filter((tab) => tab.WPID === Number(idParam));

  // set starting active tab if active tab is empty
  if (makeFistTabActive || isEmpty(state.activeTab) || tabList.length !== state.tabList.length) {
    if (idParam === null || tabItem?.length <= 0) {
      activeTab = resolveTabsList(tabList?.[0]);
    } else {
      activeTab = resolveTabsList(tabItem?.[0]);
    }

    // console.log('settign first tab', activeTab);
  }

  return {
    ...state,
    loading: false,
    tabList: tabList.map((tab) => resolveTabsList(tab)),
    activeTab
  };
};

const getWidgetTabsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload
});

const addWidgetPanelSuccess = (state = INITIAL_STATE, action) => {
  const addedWidget = resolveTabsList(action.payload);
  return {
    ...state,
    tabList: [...state.tabList, addedWidget],
    activeTab: addedWidget
  };
};

// ======= GET WIDGET TYPES ==============
const getWidgetTypesStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true
});

const getWidgetTypesSuccess = (state = INITIAL_STATE, action) => {
  const widgetTypeMenu = action.payload.reduce((acc, item) => {
    const { WTypeID, WidgetTypeName, WidgetTypeMetaData: MetaData } = item;
    const WidgetTypeMetaData = MetaData;
    const { type, category, fetchUrl } = WidgetTypeMetaData || {};
    const newItem = {
      id: WTypeID,
      label: WidgetTypeName,
      value: type,
      category: category || getWidgetCategory(type) || type,
      fetchUrl
    };
    return { ...acc, [type]: newItem };
  }, {});
  return {
    ...state,
    loading: false,
    widgetTypesMenu: widgetTypeMenu
  };
};

const getWidgetTypesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.payload
});
// =======END==========

// TODO: remove not in use
// const setBreakPoint = (state = INITIAL_STATE, action) => ({
//   ...state,
//   breakpoint: action.payload
// });

const widgetDetailSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value
  };
};
const zoomLevelSetter = (state = INITIAL_STATE, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    zoomLevel: { ...state.zoomLevel, [key]: value }
  };
};

const setWidgetActiveTab = (state = INITIAL_STATE, action) => ({
  ...state,
  activeTab: action.payload
});

// const setNewTab = (state = INITIAL_STATE, action) => ({
//   ...state,
//   tabList: [...state.tabList, action.payload]
// });

const renameTab = (state = INITIAL_STATE, action) => {
  const { id: activeTabId, label } = action.payload;
  const revisedTabList = state.tabList.map((tab) => {
    const { id } = tab;
    if (id === activeTabId) {
      return action.payload;
    }
    return tab;
  });
  return {
    ...state,
    tabList: revisedTabList,
    activeTab: action.payload
  };
};

const setWidget = (state = INITIAL_STATE, action) => ({
  ...state,
  addedWidget: [action.payload]
});

const setLayoutComponent = (state = INITIAL_STATE, action) => ({
  ...state,
  layoutComponentData: action?.payload?.formP,
  isLayoutUpdated: true
});

const setLayoutComponentUpdated = (state = INITIAL_STATE, action) => ({
  ...state,
  isLayoutUpdated: action.payload
});

const deleteTab = (state = INITIAL_STATE, action) => {
  const { activeTab, error } = action.payload;
  const newList = state.tabList.filter((e) => e.id != activeTab.id);
  return {
    ...state,
    tabList: [...newList],
    activeTab: newList.length > 0 ? newList[0] : {},
    loadingLayout: false,
    layoutWarning: error
  };
};

const addNewComponentData = (state = INITIAL_STATE, action) => ({
  ...state,
  newComponentData: action.payload.newComponentData
});

// =============GET WIDGETS LAYOUT START=================
const getWidgetsLayoutStart = (state = INITIAL_STATE) => ({
  ...state,
  loadingLayout: true
});

const setSearchQueriesWidgets = (state = INITIAL_STATE, action) => ({
  ...state,
  searchQueries: { ...action.payload }
});

const getWidgetsLayoutSuccess = (state = INITIAL_STATE, action) => {
  const {
    layout,
    layoutComponentData: componentData,
    repeatedWidgets,
    WidgetComponentData,
    TemplateParameters,
    WTIId,
    TabType,
    InheritedWPID,
    TemplateBaseData,
    WidgetPanelName,
    WidgetPanelDesc,

  } = action.payload;
  const { layoutComponentData, currentLayout, widgetTypesIncluded } = getWidgetLayoutData(
    componentData,
    layout,
    repeatedWidgets,
    state.isLayoutUpdated,
    state.layoutComponentData,
  );
  // eslint-disable-next-line array-callback-return
  // layout?.map((lay, index) => {
  //   if (currentLayout?.length > 0) {
  //     console.log(lay.i, currentLayout, + 'hi');
  //     if (currentLayout[index]) {
  //     // eslint-disable-next-line no-param-reassign
  //       lay.i = currentLayout[index].i;
  //     }
  //   }
  // });
  if (state.isTemplateComponentUpdateReq) {
    return {
      ...state,
      layout: {
        ...state.layout,
        [state.breakpoint]: currentLayout
      },
      TemplateBaseData,
      repeatedWidgets,
      fullWidgetData: componentData,
      widgetTypesIncluded,
      layoutComponentData,
      loadingLayout: false,
      WidgetComponentData,
      TemplateParameters,
      WTIId,
      TabType,
      InheritedWPID,
      WidgetPanelName,
      WidgetPanelDesc,
    };
  }
  return {
    ...state,
    layout: {
      ...state.layout,
      [state.breakpoint]: currentLayout
    },
    fullWidgetData: componentData,
    repeatedWidgets,
    widgetTypesIncluded,
    TemplateBaseData,
    layoutComponentData,
    loadingLayout: false,
    TabType,
    WidgetPanelName,
    WidgetPanelDesc,
    isTemplateComponentUpdateReq: INITIAL_STATE.isTemplateComponentUpdateReq // reseting
  };
};

const getPreviewLayoutSuccess = (state = INITIAL_STATE, action) => {
  const {
    layout,
    layoutComponentData: componentData,
    repeatedWidgets,
    WidgetComponentData,
    TemplateParameters,
    WTIId,
    InheritedWPID,
    TemplateBaseData

  } = action.payload;
  const { layoutComponentData, currentLayout, widgetTypesIncluded } = getWidgetLayoutData(
    componentData,
    layout,
    repeatedWidgets,
    state.isLayoutUpdated,
    state.layoutComponentData
  );

  return {
    ...state,
    layout: {
      ...state.layout,
      [state.breakpoint]: currentLayout,
      WidgetComponentData
    },
  };
};

const getWidgetsLayoutError = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingLayout: false,
  layoutError: action.payload?.error,
  layoutWarning: action.payload?.layoutWarning,
  layout: INITIAL_STATE.layout,
  layoutComponentData: INITIAL_STATE.layoutComponentData
});

// =============UPDATE WIDGETS LAYOUT START=================
const updateWidgetsLayoutStart = (state = INITIAL_STATE) => ({
  ...state
  // loadingLayout: true
});

const updateWidgetsLayoutSuccess = (state = INITIAL_STATE, action) => {
  const { layout, repeatedWidgets } = action.payload;
  return {
    ...state,
    layout: {
      ...state.layout,
      [state.breakpoint]: layout
    },
    repeatedWidgets
    // loadingLayout: false
  };
};

const updateWidgetsPanelPayloadSave = (state = INITIAL_STATE, action) => {
  const { widgetPayload } = action.payload;
  return {
    ...state,
    widgetPanelPayload: widgetPayload
    // loadingLayout: false
  };
};

const updateWidgetPayload = (state = INITIAL_STATE, action) => {
  const { widgetPayload, WID } = action.payload;
  return {
    ...state,
    widgetPayload: { ...state.widgetPayload, [WID]: widgetPayload }
    // loadingLayout: false
  };
};

const updateTableConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  tableMetaPayload: action.payload
  // loadingLayout: false
});

const resetWidgetPanelPayload = (state = INITIAL_STATE, action) => ({
  ...state,
  widgetPayload: {},
  widgetPanelPayload: {},
});

const updateLayoutOfBreakpoint = (state = INITIAL_STATE, action) => {
  const { layout, breakpoint } = action.payload;
  // local not api connection
  return {
    ...state,
    layout: {
      ...state.layout,
      [breakpoint]: layout
    },
    breakpoint
    // loadingLayout: false
  };
};

const updateWidgetsLayoutError = (state = INITIAL_STATE, action) => ({
  ...state,
  // loadingLayout: false,
  layoutError: action.payload?.error
});

// clear widgets in a panel
const resetWidgetsLayout = (state = INITIAL_STATE, action) => ({
  ...state,
  layoutComponentData: INITIAL_STATE.layoutComponentData,
  layout: INITIAL_STATE.layout
});

// ====================DELETE WIDGETS LAYOUT START==================
const deleteWidgetsLayoutStart = (state = INITIAL_STATE) => ({
  ...state,
  loadingLayout: true
});

// TODO: remove not in use
const deleteWidgetsLayoutSuccess = (state = INITIAL_STATE, action) => {
  const { layout } = action.payload;
  return {
    ...state,
    loadingLayout: false
  };
};

const deleteWidgetsLayoutError = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingLayout: false,
  layoutError: action.payload?.error
});

const setBulkWidgetDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});

const getKpvListStart = (state = INITIAL_STATE) => ({
  ...state,
  loadingKpv: true
});

const getKpvListSuccess = (state = INITIAL_STATE, action) => {
  const { kpvData, kpvMeta } = action.payload;
  return ({
    ...state,
    loadingKpv: false,
    kpvData,
    kpvMeta,
  });
};
const getKpvListError = (state = INITIAL_STATE) => ({
  ...state,
  loadingKpv: false
});

const setSelectedKpvs = (state = INITIAL_STATE, action) => {
  const { selectedKpvs } = action.payload;
  const selectedData = [];
  selectedKpvs?.forEach((kpv) => {
    // console.log(kpv, kpv?.KPVId, kpv?.KSId, 'hello');
    selectedData.push(kpv?.KPVId || kpv?.KSId);
  });
  return ({
    ...state,
    selectedKpvs,
    selectedKpvList: selectedData
  }
  );
};

const setKpvConfigured = (state = INITIAL_STATE, action) => {
  const { isKpvConfigured } = action.payload;
  return ({
    ...state,
    isKpvConfigured
  }
  );
};

const HANDLERS = {
  [Types.SET_MENU_ID_OF_WIDGET]: setMenuIdOfWidget,
  [Types.SET_WIDGET_PAGE_TYPE]: setWidgetPageType,
  [Types.GET_WIDGET_TABS_START]: getWidgetTabsStart,
  [Types.GET_WIDGET_TABS_SUCCESS]: getWidgetTabsSuccess,
  [Types.GET_WIDGET_TABS_FAILURE]: getWidgetTabsFailure,
  [Types.ADD_WIDGET_PANEL_SUCCESS]: addWidgetPanelSuccess,
  [Types.SET_EQUIPMENT_SELECT]: setEquipmentSelect,
  //
  [Types.SET_DISABLE_EDIT_WIDGET]: setEditDiableWidget,

  // [Types.SET_BREAK_POINT]: setBreakPoint,
  [Types.WIDGET_DETAIL_SETTER]: widgetDetailSetter,
  [Types.SET_WIDGET_ACTIVE_TAB]: setWidgetActiveTab,
  // [Types.SET_NEW_TAB]: setNewTab,
  [Types.RENAME_TAB]: renameTab,
  [Types.SET_WIDGET]: setWidget,
  [Types.DELETE_TAB]: deleteTab,

  // [Types.ADD_NEW_WIDGET]: addNewWidget,
  [Types.ADD_NEW_COMPONENT_DATA]: addNewComponentData,

  [Types.GET_WIDGETS_LAYOUT_START]: getWidgetsLayoutStart,
  [Types.GET_WIDGETS_LAYOUT_SUCCESS]: getWidgetsLayoutSuccess,
  [Types.GET_PREVIEW_LAYOUT_SUCCESS]: getPreviewLayoutSuccess,
  [Types.GET_WIDGETS_LAYOUT_ERROR]: getWidgetsLayoutError,

  [Types.UPDATE_WIDGETS_LAYOUT_START]: updateWidgetsLayoutStart,
  [Types.UPDATE_WIDGETS_LAYOUT_SUCCESS]: updateWidgetsLayoutSuccess,
  [Types.UPDATE_LAYOUT_OF_BREAKPOINT]: updateLayoutOfBreakpoint,
  [Types.UPDATE_WIDGETS_LAYOUT_ERROR]: updateWidgetsLayoutError,
  [Types.RESET_WIDGETS_LAYOUT]: resetWidgetsLayout,

  [Types.DELETE_WIDGETS_LAYOUT_START]: deleteWidgetsLayoutStart,
  [Types.DELETE_WIDGETS_LAYOUT_SUCCESS]: deleteWidgetsLayoutSuccess,
  [Types.DELETE_WIDGETS_LAYOUT_ERROR]: deleteWidgetsLayoutError,

  [Types.GET_WIDGET_TYPES_START]: getWidgetTypesStart,
  [Types.GET_WIDGET_TYPES_SUCCESS]: getWidgetTypesSuccess,
  [Types.GET_WIDGET_TYPES_FAILURE]: getWidgetTypesFailure,
  [Types.SET_BULK_WIDGET_DETAILS]: setBulkWidgetDetails,
  [Types.ZOOM_LEVEL_SETTER]: zoomLevelSetter,

  [Types.SET_EDIT_WIDGET]: setEditWidget,
  [Types.SET_HEADER_HIDE]: setHeaderHide,
  [Types.SET_BORDER_HIDE]: setBorderHide,
  [Types.SET_ENABLE_PIVOT]: setEnablePivot,
  [Types.UPDATE_WIDGETS_PANEL_PAYLOAD_SAVE]: updateWidgetsPanelPayloadSave,
  [Types.UPDATE_WIDGET_PAYLOAD]: updateWidgetPayload,
  [Types.RESET_WIDGET_PANEL_PAYLOAD]: resetWidgetPanelPayload,
  [Types.SET_LAYOUT_COMPONENT]: setLayoutComponent,
  [Types.SET_LAYOUT_COMPONENT_UPDATED]: setLayoutComponentUpdated,
  [Types.SET_IS_PREVIEW_INSTANCE]: setIsPreviewInstance,
  [Types.SET_CONFIGURE_TEMPLATE]: setConfigureTemplate,
  [Types.RESET_ACTIVE_TAB]: resetActiveTab,
  [Types.SET_SEARCH_QUERIES_WIDGETS]: setSearchQueriesWidgets,
  [Types.UPDATE_TABLE_CONFIG]: updateTableConfig,

  [Types.GET_KPV_LIST_START]: getKpvListStart,
  [Types.GET_KPV_LIST_SUCCESS]: getKpvListSuccess,
  [Types.GET_KPV_LIST_ERROR]: getKpvListError,

  [Types.SET_SELECTED_KPVS]: setSelectedKpvs,
  [Types.SET_KPV_CONFIGURED]: setKpvConfigured,

};

export const WidgetReducer = createReducer(INITIAL_STATE, HANDLERS);
