import styled from 'styled-components';
import { FlexContainer } from '../../../../globalStyles';

export const ContainerInside = styled.div`
border-radius: 1rem;
background-color: ${({ theme }) => theme.contrast.tertiary};
width: 100%;
height:100%;
display: flex;
align-items: center;
justify-content: center;
overflow: auto;
padding: 1.5rem;

`;

export const ContainerInsideText = styled.div`
border-radius: 1rem;
background-color: ${({ theme }) => theme.contrast.tertiary};
width: 100%;
height:100%;
/* display: flex; */
overflow: auto;
padding: 1.5rem;
&.zoom {
    scale: ${({ zoomLevel }) => zoomLevel || 1};
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
  }
`;

export const TextBodyContainer = styled(FlexContainer)`
  margin-bottom: 1rem;
  /* margin-left: 2rem; */

  height: calc(100% - 4rem);
  /* background: red; */
  overflow: auto;
  &.zoom {
    /* padding: 1rem; */
    /* scale: ${({ zoomLevel }) => zoomLevel || 1}; */
    /* zoom:${({ zoomLevel }) => zoomLevel}; */
    transform: scale( ${({ zoomLevel }) => zoomLevel || 1});
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (0.9 / zoomLevel) * 100 || 100}%;
  }
  @media print{
    &.zoom {
    /* padding: 1rem; */
    scale: ${({ zoomLevel }) => zoomLevel || 1};
    /* zoom:${({ zoomLevel }) => zoomLevel}; */
    transform-origin: 0 0;  
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
  }
  }
`;

export const SvgContainer = styled.div`
display:flex;
align-items:center;
flex:0 1 50%
`;

export const SVGList = styled.div`
overflow: auto;
display:flex;
flex-wrap: wrap;
padding: 30px;
`;
export const StatsText = styled.div`
font-size: 1.2rem;

`;

export const StatsContainer = styled.div`
width: fit-content;
height: fit-content;
/* background-color: ${({ theme }) => theme.contrast.secondary}; */

`;

export const Container = styled.div`
/* height: calc(100% - 0rem); */
height: ${({ headerOn }) => (headerOn ? 'calc(100% - 2rem)' : '100%')};

`;

export const ColumnsContainer = styled.div`
margin-top: 2rem;
display: flex;
flex-wrap: wrap;

`;
