import { createPopper } from '@popperjs/core';
import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import DropDownModalV3 from './DropdownModal_V3';

const NestedDropdown = (props) => {
  const ref = useRef();
  const { children, extraMenu, ...otherProps } = props;
  const { field, isActive, setModalIsVisibal } = otherProps;
  // default reference if refernce prop is defined
  const [boxRef, setBoxRef] = useState(null);
  const [modalRef, setModalRef] = useState(null);

  createPopper(boxRef, modalRef, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-130, 0]
        }
      }
    ]
  });
  useClickOutside(ref, () => {
    if (!field) {
      return setModalIsVisibal(false);
    }
    return setModalIsVisibal(field);
  });

  return (
    <div ref={ref}>
      <div ref={setBoxRef}>{children}</div>
      {isActive && (
        <div
          style={{
            position: 'absolute'
          }}
          ref={setModalRef}
        >
          <DropDownModalV3 extraMenu={extraMenu} {...otherProps} />
        </div>
      )}
    </div>
  );
};

export default NestedDropdown;
