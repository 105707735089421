import dashbord from './Dashboard.svg';
import dashboardActive from './Dashboard active.svg';

import home from './home.svg';
import homeActive from './home active.svg';

import analytics from './analytics.svg';
import analyticsActive from './analytics active.svg';

import dataVerifier from './data verifier.svg';
import dataVerifierActive from './data verifier active.svg';

import review from './review.svg';
import reviewActive from './review active.svg';

import production from './Production Info.svg';
import productionActive from './Production Info active.svg';

import mypage from './My Pages.svg';
import mypageActive from './My Pages active.svg';

export const iconsMap = {
  Home: home,
  Dashboards: dashbord,
  'My Pages': mypage,
  'Production Info': production,
  Review: review,
  'Data Verifier': dataVerifier,
  Analytics: analytics,
  default: home
};

export const activeiconsMap = {
  Home: homeActive,
  Dashboards: dashboardActive,
  'My Pages': mypageActive,
  'Production Info': productionActive,
  Review: reviewActive,
  'Data Verifier': dataVerifierActive,
  Analytics: analyticsActive,
  default: homeActive
};
