/* eslint-disable no-throw-literal */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComp from '../../../../Common/Button/Button.view';
import Input from '../../../../Common/Input/Input';
import PopUpModal from '../../../../Common/PopUpModal/Modal.view';
import { ButtonContainer, Header, Instruction } from './ChangePwdModal.style';
import { Creators as LayoutCreators } from '../../../../../views/Layout/store';
import PwdChangeSuccessful from '../PwdChangeSuccessful/PwdChangeSuccessful.view';
import { apiEndPoints } from '../../../../../services/axios/endPoints';
import { WebService } from '../../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../../store/utility/sagaErrorHandling';
import Toast from '../../../../Common/Toast';
import { Creators as loginCreators } from '../../../../../views/auth/store/index';
import { encodeUserDetails } from '../../../../../shared/utility';

const ChangePwdModal = ({ forceChangePwd = {} }) => {
  const [currentPwd, setCurrentPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [reNewPwd, setReNewPwd] = useState('');
  const dispatch = useDispatch();
  const error = useSelector((state) => state.loginData.error);

  const handleCancel = () => {
    dispatch(LayoutCreators.settingsActiveMenu('Accounts'));
    dispatch(LayoutCreators.toggleHeaderOption('changePwd'));
  };
  const userData = useSelector((state) => state.loginData.userData);
  const [pwdChangeSuccessful, setPwdChangeSuccessful] = useState(false);

  const clearLoginToastMessage = (message = '') => {
    dispatch(loginCreators.onLoginFail({ error: message }));
  };

  const handleSave = async () => {
    if (newPwd && currentPwd && newPwd === reNewPwd) {
      try {
        const config = {
          headers: {
            ...WebService.defaults.headers,
            'Content-Type': 'text/plain'
          }
        };
        let resp;
        if (forceChangePwd?.keyField) {
          const keyData = forceChangePwd?.keyField;
          resp = await WebService.post(
            apiEndPoints.ChangePassword,
            encodeUserDetails({
              userID: forceChangePwd?.userData[keyData],
              oldPwd: currentPwd,
              newPwd
            }),
            config
          );
        } else {
          resp = await WebService.post(
            apiEndPoints.ChangePassword,
            encodeUserDetails({
              userID: userData?.userId,
              oldPwd: currentPwd,
              newPwd
            }),
            config
          );
        }
        const { status, data } = resp;
        if (status === 200) {
          const { data, message } = resp.data || {};
          if (data === 'Request Successful') {
            setPwdChangeSuccessful(true);
          } else {
            throw { error: message };
          }
          setPwdChangeSuccessful(true);
        } else {
          throw resp;
        }
      } catch (e) {
        const { error } = sagaCatchBlockHandling(e);
        console.log('error', error, e);
        clearLoginToastMessage(error);
      }
      // dispatch(LayoutCreators.settingsActiveMenu('Accounts'));
      // dispatch(LayoutCreators.toggleHeaderOption('changePwd'));
    } else {
      clearLoginToastMessage('Entered passwords are not matching');
    }
  };

  const closeModal = () => {
    dispatch(LayoutCreators.toggleHeaderOption('changePwd'));
  };
  return (
    <>
      {!pwdChangeSuccessful ? (
        <PopUpModal closeModalHandler={closeModal}>
          <Header>
            {forceChangePwd?.message ? 'Reset Password' : ' Change Password'}
          </Header>
          {forceChangePwd?.message && (
            <Instruction>{forceChangePwd?.message}</Instruction>
          )}
          <Input
            placeholder='Current Password'
            value={currentPwd}
            setValue={setCurrentPwd}
            type='password'
            style={{ marginBottom: '3rem' }}
            autocomplete='off'
          />
          <Input
            placeholder='New Password'
            value={newPwd}
            setValue={setNewPwd}
            type='password'
            style={{ marginBottom: '1rem' }}
            autocomplete='off'
          />
          <Input
            placeholder='Re-enter New Password'
            value={reNewPwd}
            setValue={setReNewPwd}
            type='password'
            style={{ marginBottom: '3rem' }}
            autocomplete='off'
          />
          <ButtonContainer>
            <ButtonComp style={{ marginRight: '2rem' }} onClick={handleCancel}>
              Cancel
            </ButtonComp>
            <ButtonComp dark={true} onClick={handleSave}>
              Save Changes
            </ButtonComp>
          </ButtonContainer>
          {error && (
            <Toast
              className='fixed right'
              fn={clearLoginToastMessage}
              header='Error'
              message={error}
            />
          )}
        </PopUpModal>
      ) : (
        <PwdChangeSuccessful />
      )}
    </>
  );
};

export default ChangePwdModal;
