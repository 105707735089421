/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React from 'react';
// import { isEmpty } from '../../../../shared/utility/isEmpty';
import '../Annotations/Annotations.css';
import { AlarmDetailsContainer } from './Alarms.style';

const AlarmDetails = ({ alarmDetails, left }) => {
  const { X, Y, name, description } = alarmDetails;
  return (
    <AlarmDetailsContainer left={left}>
      <div className='DetailsContainer'>
        <span className='HeaderText' style={{ display: 'block', fontSize: '1.5rem' }}>
          {name}
        </span>
        <span className='LowOpacityText' style={{ display: 'block' }}>
          {new Date(X).toLocaleString()}
        </span>
        <div style={{ width: '100%', borderBottom: '1px solid #cbcbcf' }} />
        <span className='text-overflow HeaderText' style={{ display: 'block' }}>
          {Y}
        </span>
        <span className='text-overflow HeaderText' style={{ display: 'block' }}>
          {description}
        </span>
        {/* <div className='flex'> */}
        {/* </div> */}
      </div>
    </AlarmDetailsContainer>
  );
};

export default AlarmDetails;
