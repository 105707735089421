/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ValueAxis,
  Size,
  Legend,
  ArgumentAxis,
  Label,
  Font,
  Point,
  Tooltip
} from 'devextreme-react/chart';
import { useSelector } from 'react-redux';
import LabelTemplate from './LabelTicks';
import horiTgtLine from '../../../../assets/icons/line.svg';
import verticleTgtline from '../../../../assets/icons/lineVertical.svg';
import useMeasure from '../../../../shared/hooks/useMeasure';
import { useActions } from '../../../../store/hooks/useActions';

// const { data, meta, colors, labelMapping, gridDetails } = getDataSource();
// console.log('data', data, colors, labelMapping);
const Container = styled.div`
  background: red;
  min-width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  background-color: ${({ theme }) => theme.contrast.tertiary};
  width: 100%;
  height: calc(100%);
  overflow: auto;

  & .fs {
    font-size: 1.6rem;
  }

  & foreignObject {
    transform: scale(
      ${({ containerHeight, isRotated }) => {
        if (isRotated) {
          return 1;
        }
        /* [250, 300] */
        if (containerHeight >= 250 && containerHeight <= 300) {
          return 0.9;
        }
        /* [200, 250) */
        if (containerHeight >= 200 && containerHeight < 250) {
          return 0.8;
        }
        return 1;
      }}
    );
  }
`;

function onTooltipShown(e, mousePosition) {
  // eslint-disable-next-line no-undef
const toolTipElement = document.getElementsByClassName('dxc-tooltip');
//   const element = document.getElementById(id);
// const position = element.getBoundingClientRect();
// const x = position.left;
// const y = position.top;
// const trialX = x + (0.6 * e?.target?.getCenterCoord().x);
// console.log(trialX, ((1 - zoomLevel) * 10), 'trial');
  setTimeout(() => {
    if (toolTipElement?.length) {
      toolTipElement[0].style.left = `${mousePosition.x - 38}px`;
      toolTipElement[0].style.top = `${mousePosition.y - 67}px`;
      // toolTipElement[0].style.top = `${e?.target?.getCenterCoord().y}px`;
    }
  }, 10);
}

const BarChartWidget = ({
  barChartDetails,
  activeTab,
  WID,
  isRotated,
  statsOf,
  heightOf,
  isLabelRotated,
  zoomLevel
}) => {
  // const isRotated = true;
  const { data, meta, colors, labelMapping, gridDetails, chartType } =
    barChartDetails || {};
  const pointDetails = meta?.charts?.find((e) => e.type === 'Point');
  const ref = useRef();
  const mousePositionRef = useRef({ x: 0, y: 0 });
  // console.log('barChartDetails', pointDetails);
  const customizeTooltip = (pointInfo) => ({
    text: `${pointInfo.value}`
  });
  // console.log('aaa', width, height, heightOf, data?.length * 60);
  const [containerRef, { width: containerWidth, height: containerHeight }] =
    useMeasure();
  const height = heightOf || !isRotated ? 240 : 350; // width: ;
  const width = Math.max(190, data?.length * 60); // height
  const { setBorderHide, zoomLevelSetter } = useActions();
  const layout = useSelector((state) => state.widgets.layout);

  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);

  useEffect(() => {
    setTimeout(() => {
      if (width && width <= 150) {
        if (zoomLevelFinal[WID + activeTab?.id] >= 0.8) {
          setBorderHide({ WID, WPID: activeTab?.id, isHideBorder: true });
          zoomLevelSetter({ key: WID + activeTab?.id, value: 0.8 });
        }
      }
    }, 1000);
  }, [layout]);

  useEffect(() => {
    document.onmousemove = function (e) {
      mousePositionRef.current = {
        x: e.clientX + document.body.scrollLeft,
        y: e.clientY + document.body.scrollLeft
      };
    };

    return () => {
      document.onmousemove = null;
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      isRotated={isRotated}
      height={isRotated ? width : height}
      // width={isRotated ? height : width}
      containerHeight={containerHeight}
    >
      <Chart
        rotated={isRotated}
        id='chart'
        dataSource={data}
        // resizePanesOnZoom={true}
        onTooltipShown={(e) => {
          onTooltipShown(e, mousePositionRef.current);
        }}

      >
        <Size height={containerHeight} width={containerWidth} />
        <CommonSeriesSettings argumentField='label' type={chartType} />
        <Legend visible={false} />
        {/* <Series type='scatter' valueField='target' name='target' /> */}

        {colors?.map((col) => (
          <Series
          // Removing bar padding and width in series to maintain the yellow line of bar chart constantly
            // barPadding={containerWidth <= 250 ? 0.5 : 0.2}
            color={col}
            valueField={'val-' + col}
            name={col}
          />
        ))}
        {/* <Series barWidth={30} barPadding={0.2} color='black' valueField='top' name='top' /> */}
        {pointDetails?.values?.map((e) => (
          <Series type='scatter' valueField={e} name={e}>
            <Point
              image={{
                url: isRotated ? verticleTgtline : horiTgtLine,
                // width: containerWidth <= 250 ? 20 : 20,
                height: 40
              }}
            />
          </Series>
        ))}

        <ValueAxis>
          <Label>
            <Font color='black' size={height <= 250 ? 10 : 12} />
          </Label>
        </ValueAxis>
        <ArgumentAxis>
          <Label
            ref={ref}
            wordWrap='none'
            textOverflow='ellipsis'
            render={(label) => (
              <LabelTemplate
                label={label}
                meta={meta}
                labelMapping={labelMapping}
                gridDetails={gridDetails}
                isRotated={isRotated}
                gridKeys={statsOf}
                isLabelRotated={isLabelRotated}
                chartType={chartType}
              />
            )}
            visible
          />
        </ArgumentAxis>
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      </Chart>
    </Container>
  );
};

export default BarChartWidget;
