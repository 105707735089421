/* eslint-disable max-len */
import styled from 'styled-components';
import { fontFamily, fontSize } from '../../../constants';
import { FlexContainer } from '../../../globalStyles';

export const FileUploadBox = styled(FlexContainer)`
  width: 94%;
  height: 100%;
  margin: 0 3% 0 3%;
  min-height: 16rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.core.secondary};
  box-shadow: ${({ theme }) => theme.shadowin};
  border-radius: 1rem;
  border: 0rem;
  color: ${({ theme }) => theme.contrast.secondary};
  font-size: 1.4rem;
`;

export const UploadingBox = styled.div`
  ${'' /* box-shadow: ${({ theme }) => theme.shadowin}; */}
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 1rem;
  padding: 1rem;
  min-height: 8rem;
  padding-bottom: 1rem;
  width: 94%;
  margin: 1rem 3% 3rem 3%;
`;

export const Span = styled.p`
  font-size: ${fontSize.descTextMobile};
  color: ${({ theme }) => theme.modal.gray};
  font-family: ${fontFamily.circularBold};
  margin: 5px 0 5px 0;
`;

export const HeadingDiv = styled(FlexContainer)`
  widht: 94%;
  margin: 0 3% 0 3%;
  position: relative;
  bottom: 1rem;
  height: 5rem;
`;

export const Dot = styled.span`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.modal.gray};
  margin: 0 3px;
`;
