import { addDays, addWeeks, addMonths, addYears, addQuarters } from 'date-fns';

export const getUnitDateAdder = (unit) => {
  let fn = addDays;
  if (unit === 'days') {
    fn = addDays;
  } else if (unit === 'weeks') {
    fn = addWeeks;
  } else if (unit === 'months') {
    fn = addMonths;
  } else if (unit === 'years') {
    fn = addYears;
  } else if (unit === 'quarters') {
    fn = addQuarters;
  }
  return fn;
};
