export const calanderInitContants = {
  weekStartsOn: 3,
  q1StartsOn: 3
};

export const calenderTypes = {
  DATE_RANGE: 'daterange',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter'
};
export const intervalType = {
  INTERVAL: 'interval'
};

export const groupType = {
  Plant: 'tree',
  Calendar: 'interval'
};
