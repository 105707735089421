/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  exportData: {}
};

const setExportData = (state = INITIAL_STATE, action) => {
  const { TabId, WID, widgetData } = action.payload;
  return {
    ...state,
    exportData: { ...state.exportData, [TabId]: { ...state.exportData[`${TabId}`], [WID]: widgetData } }
  };
};

const resetExportData = (state = INITIAL_STATE, action) => ({
  ...state,
  exportData: { }
});

const HANDLERS = {
  [Types.SET_EXPORT_DATA]: setExportData,
  [Types.RESET_EXPORT_DATA]: resetExportData,
};
export const ExportReducer = createReducer(INITIAL_STATE, HANDLERS);
