/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { FlexContainer } from '../../../../globalStyles';
import ChartGauge from '../../../../views/Widgets/WidgetComponents/GaugeChart/Gauge';
import {
  BodyContainer,
  Container,
  ContainerBody
} from '../../Common/WidgetCommons.style';
import { ChartContainer, ChartDiv, Text } from './GaugeChart.style';
import kababIcon from '../../../../assets/icons/Filter/kabab.svg';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import DropDownModal from '../../../Common/DropDownModal/DropDownModal';
import { TabUi } from '../../../Common/TabUi/TabUi';
import {
  TabBtnContainer,
  TabContainer
} from '../../../Common/TabUi/TabUi.style';
import WidgetHeaderView from '../../Common/WidgetHeader/WidgetHeader.view';
import GaugeChartWidget from '../../../../views/Widgets/WidgetComponents/GaugeChart/GaugeChart';
import { getFetchDetailsOfWidgetData } from '../../utils';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import Loader from '../../../Loader/Loader';
import {
  AddToHome,
  DeleteBlack,
  DeleteTab,
  Export,
  FullScreen,
  HideBorder,
  HideHeader,
  RemoveFromHome,
  RemoveHomeMenu,
  Settingslight,
  settingsBlack
} from '../../../../assets/icons';
import Exportexcel from '../../../../assets/icons/Export excel.svg';
import ExportPdf from '../../../../assets/icons/Export PDF.svg';
import ExportImg from '../../../../assets/icons/Export Image.svg';
import { CenterDiv } from '../../../Card';
import { useActions } from '../../../../store/hooks/useActions';

const GaugeChart = (props) => {
  const { componentData, id, isVisible, layoutPos, fullView, WIDFromProp, HDId } = props;
  const [loading, setLoading] = useState(false);
  const { WidgetName, infoText, formData, type, ...componentProps } =
    componentData || {};
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const [widgetData, setWidgetData] = useState({});
  const { data, meta } = widgetData;
  const [error, setError] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const WID = WIDFromProp || layoutPos.i?.split('_')?.[0];
  const isPreviewInstance = useSelector(
    (state) => state.widgets.isPreviewInstance
  );
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const { setExportData } = useActions();
  const { id: WTIIdFromUrl } = useParams();

  const widgetOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },

    { id: 1, label: 'configure', value: 'configure', iconUrl: settingsBlack },
    { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ]
    },
  ];
  const widgetOptionsInstance = HDId ? [
    {
      id: 1,
      label: 'Delete',
      value: 'deleteFromHome',
      iconUrl: DeleteBlack
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ]
    },
  ] : [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen
    // },
    // { id: 1, label: 'configure', value: 'configure', iconUrl: settingsBlack },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ]
    },
  ];
  const widgetOptionsInstancePop = [
    {
      id: 1,
      label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
      value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
      iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
    },
    {
      id: 3,
      label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
      value: 'toggleHeader',
      iconUrl: HideHeader
    },
    {
      id: 4,
      label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
      value: 'toggleBorder',
      iconUrl: HideBorder
    },
    {
      id: 5,
      label: 'Export',
      value: 'export',
      iconUrl: Export,
      subMenu: [
        { id: 1, label: 'Image', value: 'pngExport', iconUrl: ExportImg, },
        {
          id: 2,
          label: 'PDF',
          value: 'pdfExport',
          iconUrl: ExportPdf,
        },
        {
          id: 2,
          label: 'Excel',
          value: 'excelExport',
          iconUrl: Exportexcel,
        }
      ]
    },
  ];
  useEffect(() => {
    setLoading(true);
    const fetchUrl = widgetTypesMenu?.[type]?.fetchUrl;
    const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
    // TODO_W_R
    WebService[method](fullURL, {
      widgetType: type,
      formInput: formData
    })
      .then((response) => {
        const { data, meta } = response?.data;
        setWidgetData({ data, meta });
        setExportData({
          TabId: WTIIdFromUrl || activeTab?.id,
          WID,
          widgetData: { data, meta, WidgetName, type }
        });
        setLoading(false);
      })
      .catch((e) => {
        const err = sagaCatchBlockHandling(e);
        setError(err.error);
        setLoading(false);
        console.log('status value err', e);
      });
  }, []);

  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);

  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);

  useEffect(() => {
    if (fullView) {
      setZoomLevel(1);
    } else if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [fullView]);

  if (error) return <span>{error}</span>;
  return (
    <>
      <WidgetHeaderView
        id={id}
        WID={WIDFromProp || layoutPos.i?.split('_')?.[0]} // WID
        WidgetName={WidgetName || 'Equipment OEE Donut'}
        widgetOptions={WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePop : widgetOptionsInstance : widgetOptions}
        componentData={componentData}
        hasZoomControl
        setZoomLevel={setZoomLevel}
        zoomLevel={zoomLevel}
        fullView={fullView}
        bottomFullView={10}
        leftFullView={12}
      />
      <Container
        style={{
          // position: 'inherit',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
        zoomLevel={fullView ? 1 : zoomLevel}
      >
        <>
          {loading ? (
            <Loader />
          ) : (
            <BodyContainer
              headerOn={!isHideHeader[WID + activeTab?.id]}
              className='spred'
            >
              {data ? (
                <GaugeChartWidget WID={WID} data={data} meta={meta} />
              ) : (
                <>
                  <CenterDiv className='jc_center ai_center'>
                    <Text>No Data...</Text>
                  </CenterDiv>
                </>
              )}
            </BodyContainer>
          )}
        </>
      </Container>
    </>
  );
};

export default GaugeChart;
