/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import styled from 'styled-components';
import moment from 'moment';
import DateRangePickerComp from './components/DateRangePicker/index';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { createStaticRanges, defineds } from './defaultRanges';
import { thisQuarter, thisWeek } from './utils';
import { calDateFormat } from '../../../config/index';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getUnitDateAdder } from '../Common/Header/utlity';

const Wrapper = styled.div`
  & * {
    font-size: 1.2rem;
  }
  /* & .calender {
    background: red;
  } */
  /* & .rdrStartEdge {
    color: yellow !important;
  }
  & .rdrStartEdge + .rdrDayNumber {
    color: black;
  } */
`;
// const weekStartsOnInit = 3;
// const quarterStartsOn = 2;
// MaxAllowedRange obj {unit, value}, weekStartsOn, quarterStartsOn,
const DateRangePicker = ({
  initValue,
  handleSelection,
  MaxAllowedRange,
  isWeekSelection,
  weekStartsOn,
  quarterStartsOn = 0,
  dateOfWeekStart,
  clearSelection,
  setClearSelection,
  useGivenDateForRevenseCalculation = false
  // maxRangeAllowed
}) => {
  // console.log('week', weekStartsOn, quarterStartsOn);
  const initObj = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    isInit: true
  };
  const [state, setState] = useState([initObj]);

  const formatSelectionObj = (obj) => ({
    value: `${moment(obj.startDate).format(calDateFormat)} - ${moment(
      obj.endDate
    ).format(calDateFormat)}`,
    rawValue: obj,
    label: obj.label
  });

  useEffect(() => {
    if (clearSelection) {
      setState([initObj]);
      handleSelection(formatSelectionObj(initObj));
      setClearSelection(false);
    }
  }, [clearSelection]);

  useEffect(() => {
    if (!isEmpty(initValue)) {
      // console.log('initValue', initValue);
      setState([
        {
          startDate: new Date(initValue.startDate),
          endDate: new Date(initValue.endDate),
          key: 'selection'
        }
      ]);
    } else {
      handleSelection(formatSelectionObj(state[0]));
    }
  }, []);

  const handleWeekSelection = (item, { focusRange }) => {
    // console.log('sending selection', item);
    const { startDate, endDate } = item.selection;
    const [noOfSelections, isEndTurn] = focusRange;
    let setWeek = item.selection;
    // console.log('setWeek', setWeek, focusRange, weekStartsOn);
    const selDate = !isEndTurn ? endDate : startDate;
    // weekstartson calc condition blocks
    if (moment(selDate).day() >= weekStartsOn) {
      // console.log('week condition 1');
      // based on the turn we are init the start/end Date
      if (isEndTurn) {
        // console.log('rounding of start by adding weekstart', new Date(moment(startDate).startOf('week')));
        setWeek = {
          ...item.selection,
          startDate: new Date(
            moment(startDate).startOf('week').add(weekStartsOn, 'days')
          )
        };
        if (parseInt(MaxAllowedRange?.value, 10) === 0) {
          setWeek.endDate = new Date(
            moment(endDate).endOf('week').add(weekStartsOn, 'days')
          );
        }
      } else {
        // console.log('rounding of end by add', new Date(moment(endDate).endOf('week')));
        setWeek = {
          ...item.selection,
          endDate: new Date(
            moment(endDate).endOf('week').add(weekStartsOn, 'days')
          )
        };
      }
      // old
      // setWeek = {
      //   ...item.selection,
      //   startDate: new Date(moment(startDate).startOf('week').add(weekStartsOn, 'days'))
      //   // endDate: new Date(moment(startDate).endOf('week').add(weekStartsOn, 'days'))
      // };
    } else {
      // console.log('week condition 2');
      if (isEndTurn) {
        // console.log('rounding of start by subtract weekstart');
        setWeek = {
          ...item.selection,
          startDate: new Date(
            moment(startDate)
              .subtract(weekStartsOn, 'days')
              .startOf('week')
              .add(weekStartsOn, 'days')
          )
        };
        if (parseInt(MaxAllowedRange.value, 10) === 0) {
          setWeek.endDate = new Date(
            moment(endDate)
              .subtract(weekStartsOn, 'days')
              .endOf('week')
              .add(weekStartsOn, 'days')
          );
        }
      } else {
        // console.log('rounding of end by subtract');
        setWeek = {
          ...item.selection,
          endDate: new Date(
            moment(endDate)
              .subtract(weekStartsOn, 'days')
              .endOf('week')
              .add(weekStartsOn, 'days')
          )
        };
      }
      // old
      // setWeek = {
      //   ...item.selection,
      //   startDate: new Date(
      //     moment(startDate)
      //       .startOf('week')
      //       .subtract(weekStartsOn + 1, 'days')
      //   ),
      //   endDate: new Date(
      //     moment(startDate)
      //       .endOf('week')
      //       .subtract(weekStartsOn + 1, 'days')
      //   )
      // };
    }
    // setState([setWeek]);
    // // console.log('sending selection', item, startDate, formatSelectionObj(setWeek));
    // handleSelection(formatSelectionObj(setWeek));

    const { isInit, ...restItemSelection } = setWeek;
    const finalSetWeek = restItemSelection;
    setState([finalSetWeek]);
    // console.log('sending selection', item, startDate, formatSelectionObj(setWeek));
    handleSelection(formatSelectionObj(finalSetWeek));
  };

  const handleDateSelection = (item) => {
    const { isInit, ...restItem } = item.selection;
    setState([restItem]);
    handleSelection(formatSelectionObj(restItem));
  };

  const { unit, value: unitValue } = MaxAllowedRange || {};
  // console.log('unit || !unitValue', unit, unitValue);

  const restictedRange = () => {
    const date = new Date(state[0].startDate);
    if (!unit || !unitValue) {
      return { minDate: undefined, maxDate: undefined };
    }
    const obj = {};
    // set the function based on unit
    const fn = getUnitDateAdder(unit);
    // not allowing backward date selection
    obj.minDate = date;
    // fn(date, -unitValue);
    obj.maxDate = addDays(fn(date, unitValue), -1);
    // obj.maxDate = addWeeksCustom(date, unitValue);
    // console.log('obj', obj, unit, unitValue);
    return obj;
  };
  // console.log('state', state);

  const staticRangesArr = [
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },
    {
      label: 'This week',
      range: () => thisWeek(weekStartsOn)
    },
    {
      label: 'Last Week',
      range: () => {
        const { startDate, endDate } = thisWeek(weekStartsOn);
        return {
          startDate: addDays(startDate, -7),
          endDate: addDays(endDate, -7)
        };
      }
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth
      })
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: 'This Quarter',
      range: () => thisQuarter(quarterStartsOn, true)
    },
    {
      label: 'Last Quarter',
      range: () => thisQuarter(quarterStartsOn, false)
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear
      })
    }
  ];

  const getShortcuts = () => {
    if (unit === 'days') {
      if (unitValue >= 7) {
        return staticRangesArr.slice(0, 4);
      }
      return staticRangesArr.slice(0, 2);
    }
    if (unit === 'months') {
      if (unitValue >= 3) {
        return staticRangesArr.slice(0, 8);
      }
      return staticRangesArr.slice(0, 6);
    }
    return staticRangesArr;
  };

  // console.log('getShortcuts', getShortcuts(), initValue);
  return (
    <Wrapper>
      <DateRangePickerComp
        className='calender'
        onChange={(item) => handleDateSelection(item)}
        customChange={(item, rest) =>
          isWeekSelection
            ? handleWeekSelection(item, rest)
            : handleDateSelection(item, rest)
        }
        showSelectionPreview={true}
        // months={isWeekSelection ? 1 : 2}
        // show only one month if its a single selection
        months={parseInt(unitValue, 10) === 0 ? 1 : 2}
        ranges={state}
        minDate={!state[0].isInit ? restictedRange().minDate : undefined}
        maxDate={!state[0].isInit ? restictedRange().maxDate : undefined}
        direction='horizontal'
        weekStartsOn={weekStartsOn}
        isWeekSelection={isWeekSelection}
        staticRanges={createStaticRanges(getShortcuts())}
        dateOfWeekStart={dateOfWeekStart}
        clearSelection={clearSelection}
        useGivenDateForRevenseCalculation={useGivenDateForRevenseCalculation}
      />
    </Wrapper>
  );
};

export default DateRangePicker;
