/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Label, SidePanelContainer } from './WidgetCommonCreationForm.style';
import { InputCotainer } from '../../Common/Input/Input';
import Editor from '../../Common/Editor/Editor';
import { useActions } from '../../../store/hooks/useActions';
import CheckBox from '../../CheckBox/CheckBox';

const WidgetCommonCreationForm = ({ Header, placeholder }) => {
  const history = useHistory();
  const [fieldIsNotEmpty, setFielsisNotEmpty] = useState();
  const { widgetName, widgetInfo, shareWidget } = useSelector((state) => state.upsertWidgetData);
  const { valueSetter } = useActions();
  const handleChange = (e) => {
    const { name, value } = e.target;
    valueSetter({ [name]: value });
  };

  const shareHandler = () => {
    valueSetter({ shareWidget: !shareWidget });
  };

  const validating = () => {
    let fieldIsNotEmpty = true;
    if (widgetName === '' || widgetInfo === '') {
      fieldIsNotEmpty = false;
    }
    setFielsisNotEmpty(fieldIsNotEmpty);
  };

  useEffect(() => {
    validating();
  }, [widgetName, widgetInfo]);

  const handleBackOrDiscard = () => {
    // backk();
    history.goBack();
  };

  return (
    <>
      <SidePanelContainer className='widgetSidePanel sideBarinWidg' width='25%'>
        <Label>Widget Name</Label>
        <br />
        <InputCotainer
          placeholder={placeholder}
          value={widgetName}
          name='widgetName'
          className='widgetSideBar'
          onChange={(e) => {
            handleChange(e);
          }}
        />

        <Label>Info Text</Label>
        <br />
        <Editor
          value={widgetInfo}
          name='widgetInfo'
          onChange={(e) => {
            handleChange({ target: { name: 'widgetInfo', value: e } });
          }}
        />
        <CheckBox style={{ marginTop: '4rem' }} label='Share' isActive={shareWidget} handleChange={shareHandler} />
      </SidePanelContainer>
    </>
  );
};

export default WidgetCommonCreationForm;
