/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, ItemContainer, Label } from './Notifications.style';
import ToggleOn from '../../../../assets/icons/KPV/Toggle on.svg';
import ToggleOff from '../../../../assets/icons/KPV/toggle off.svg';
import { Creators as LayoutCreators } from '../../../../views/Layout/store';
import DynamicSVGIcon from '../../../Common/DynamicSVGIcon';
import DropdownBarComp from '../../../Common/DropdownBar/DropdownBar.view';
import config from '../../../../config.json';

const Notifications = () => {
  const dispatch = useDispatch();
  const showNotificationTabs = useSelector((state) => state.home.showNotificationTabs);

  // const tabsInit = [
  //   { label: 'Alarms', isPresent: false },
  //   { label: 'Approvals', isPresent: false },
  //   { label: 'PI', isPresent: false },
  //   { label: 'Schedule', isPresent: false },
  //   { label: 'CBM alerts', isPresent: false }
  // ];
  // const [tabs, setTabs] = useState(tabsInit);

  // useEffect(() => {
  //   setTabs(tabsInit.map((e) => ({ ...e, isPresent: showNotificationTabs.includes(e.label) })));
  // }, []);

  const handleTabToggle = (selectedTabId) => {
    // const selectedTabs = [];
    // const temp = tabs.map((ele) => {
    //   if (ele.label === tab.label) {
    //     if (ele.isPresent) {
    //       selectedTabs.filter((e) => e !== tab.label);
    //     } else {
    //       selectedTabs.push(tab.label);
    //     }
    //     return { label: tab.label, isPresent: !ele.isPresent };
    //   }
    //   if (ele.isPresent) selectedTabs.push(ele.label);
    //   return ele;
    // });
    // setTabs([...temp]);
    // dispatch(LayoutCreators.setNotificationTabs([...selectedTabs]));
    if (!config.hide_notifications) {
      const newShowNotificationTabs = showNotificationTabs.reduce((acc, tab) => {
        if (tab.NotificationID === selectedTabId) {
          const isHidden = tab.isVisible === false;
          return [...acc, { ...tab, isVisible: isHidden }];
        }
        return [...acc, tab];
      }, []);
      dispatch(LayoutCreators.getNotificationsCountSuccess({ data: newShowNotificationTabs }));
    }
  };

  return (
    <Container>
      <Header>Show Notifications of</Header>
      <DropdownBarComp>last one week</DropdownBarComp>
      <Header style={{ marginBottom: '0.5rem' }}>Show Notifications for</Header>
      {showNotificationTabs.map((tab, index) => (
        <ItemContainer key={index}>
          {tab.Name}
          <div onClick={() => handleTabToggle(tab.NotificationID)}>
            <DynamicSVGIcon
              iconUrl={config.hide_notifications || tab.isVisible === false ? ToggleOff : ToggleOn}
              width='4rem'
            />
          </div>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default Notifications;
