/* eslint-disable max-len */
import React, { useRef } from 'react';
import useClickOutside from '../../../shared/hooks/useClickOutside';

import { Modal, ModalContent } from './Modal.style';

const PopUpModal = (props) => {
  const { style, children, closeModalHandler, width, height, className, disableOutsideClick } = props;
  const modalRef = useRef();
  if (!disableOutsideClick) {
    useClickOutside(modalRef, () => closeModalHandler(false));
  }
  return (
    <Modal>
      <ModalContent style={style} width={width} ref={modalRef} height={height} className={className} onClick={(e) => { e.stopPropagation(); }}>
        {children}
      </ModalContent>
    </Modal>
  );
};

export default PopUpModal;
