/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ContentContainer, Image, Header, Message, ToastContainer } from './Toast.style';
import { errorToast, successToast } from '../../../assets/icons';
import { getUserDataFromLocal } from '../../../shared/utility/helper';
import { useActions } from '../../../store/hooks/useActions';

export const TOAST_TYPES = {
  ERROR: 'Error',
  SUCCESS: 'Success',
  INFO_MSG: 'Info Message'
};
export const TOAST_TYPES_ARR = [TOAST_TYPES.ERROR, TOAST_TYPES.SUCCESS, TOAST_TYPES.INFO_MSG];

const Toast = ({ message, header, fn = () => {}, style = { bottom: 30 }, shouldFade = true, showHeader = true, className }) => {
  const toastRef = useRef();
  const authenticated = useSelector((state) => state.loginData.authenticated);
  const { setToastError, onLoginFail } = useActions();

  useEffect(() => {
    toastRef.current.onanimationend = () => {
      fn();
    };

    return () => {
      if (message) {
        fn();
      }
    };
  }, []);

  // directing to login page if token is not available
  const userData = getUserDataFromLocal();
  if (authenticated && !userData?.token) {
    onLoginFail({ error: '' });
  }

  return (
    <ToastContainer style={style} ref={toastRef} className={`${className} show`}>
      {header === 'Error' ? (
        <Image src={errorToast} alt='error' />
      ) : (
        header === 'Success' && <Image src={successToast} alt='success' />
      )}
      <ContentContainer>
        {showHeader && <Header>{header}</Header>}
        <Message>{message}</Message>
      </ContentContainer>
    </ToastContainer>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  fn: PropTypes.func.isRequired
};

export default memo(Toast);
