import React from 'react';
import { SVGList, StatsContainer, StatsText, SvgContainer } from '../UpsertWidget/WidgetsComponents/SvgWidget/SVGWidget.style';
import SvgStatusCard from './SvgStatusCard.View';

const Ahu = ({ colors, speed, dimension, componentData, numSvg }) => {
  const { formData } = componentData || {};
  const svgList = [];
  const width = `${dimension.width}px`;
  const height = `${dimension.height}px`;
  for (let i = 0; i < numSvg; i += 1) {
    svgList.push(
      <SvgContainer>
        <StatsContainer>
          {formData && (
          <SvgStatusCard
            machineStatus={formData?.machineState?.label}
            speed={formData?.speed?.label}
          />
          )}
          {/* <StatsText>
            {' '}
            AHU :
            {' '}
            {i + 1}

          </StatsText>
          <StatsText>
            {' '}
            Machine Status :
            {' '}
            {formData?.machineState?.label}

          </StatsText>
          <StatsText>
            {' '}
            Speed :
            {' '}
            {formData?.speed?.label}

          </StatsText> */}

        </StatsContainer>
        <svg
          width="100%"
          height="100%"
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          x='0px'
          y='0px'
          viewBox='0 0 300 114.9'
        // style="enable-background:new 0 0 300 114.9;"
          style={{ enableBackground: 'new 0 0 300 114.9' }}
          xmlSpace='preserve'
        >
          <style type='text/css'>
            {`.st0 {
                    fill: ${colors.class_0};
          }`}

            {`.st1 {
      fill:${colors.class_1};
    }`}

            {`.st2 {
      fill:${colors.class_2};
    }`}

            {`.st3 {
      fill:${colors.class_3};
    }`}

            {`.st4 {
      fill:${colors.class_4};
    }`}

            {`.st5 {
      fill: ${colors.class_5};
    }`}

            {`.st6 {
      fill: ${colors.class_6};
    }`}

            {`.st7 {
      fill: ${colors.class_7};
    }`}

            {`.st8 {
      fill: ${colors.class_8};
    }`}

            {`.st9 {
      fill: ${colors.class_9};
    }`}

            {`.st10 {
      fill: ${colors.class_10};
    }`}

            {`.st11 {
      fill: ${colors.class_11};
    }`}
            {`
    .st12 {
      fill: ${colors.class_12};
    }`}

            {`.st13 {
      fill: ${colors.class_13};
    }`}
          </style>
          <g id='Group_16858' transform='translate(19314.373 1412.185)'>
            <g id='Group_16854' transform='translate(-89 -30)'>
              <path
                id='Path_67962'
                className='st0'
                d='M-19108.4-1331.8h162.9v56.5h-261c-1,0-1.8-0.7-1.9-1.7c0-0.1,0-0.1,0-0.2v-51.9
      c0-1,0.4-1.9,1.2-2.6l0,0c0.2-0.1,0.4-0.1,0.7-0.1h43.3c1,0,1.9-0.9,1.9-1.9c0,0,0,0,0,0v-40.1c0,0,0-0.1,0-0.1l0,0
      c0.6-1.1,1.7-1.8,2.9-1.8h46.2c1,0,1.9,0.9,1.9,1.9c0,0,0,0,0,0v40.1C-19110.3-1332.6-19109.5-1331.8-19108.4-1331.8z'
              />
              <path
                id='Path_67963'
                className='st0'
                d='M-18942.3-1329.1v51.9c0,0.1,0,0.1,0,0.2c-0.1,1-0.9,1.7-1.9,1.7h-41.2v-56.5h41.2
      c0.2,0,0.5,0,0.7,0.1C-18942.7-1331-18942.3-1330-18942.3-1329.1z'
              />
              <path
                id='Path_67964'
                className='st1'
                d='M-19158.4-1375.7c-1.2,0-2.4,0.7-2.9,1.8l0,0c-0.3,0.5-0.4,1-0.4,1.6v39.6
      c0,0.2-0.2,0.4-0.4,0.4h-42.9c-0.8,0-1.5,0.3-2.1,0.8l0,0c-0.8,0.6-1.2,1.6-1.2,2.6v1.1h2.9v-1.1c0-0.2,0.2-0.4,0.4-0.4h42.9
      c1.8,0,3.3-1.5,3.3-3.3v-39.6c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0h1v-2.9L-19158.4-1375.7z M-18945.2-1278.2v1.1
      c0,0.2-0.2,0.4-0.4,0.4l0,0h-39.7v2.9h39.7c1.8,0,3.2-1.4,3.3-3.2c0-0.1,0-0.1,0-0.2v-1.1H-18945.2z M-19205.5-1277.1v-1.1h-2.9
      v1.1c0,0.1,0,0.1,0,0.2c0.1,1.8,1.6,3.2,3.3,3.2h259.5v-2.9h-259.5C-19205.3-1276.7-19205.5-1276.9-19205.5-1277.1
      L-19205.5-1277.1z M-18943.5-1331.6c-0.6-0.5-1.4-0.8-2.1-0.8h-39.7v2.9h39.7c0.2,0,0.4,0.2,0.4,0.4v1.1h2.9v-1.1
      C-18942.3-1330-18942.7-1331-18943.5-1331.6L-18943.5-1331.6z M-19108.2-1332.8v-39.6c0-1.8-1.5-3.3-3.3-3.3h-0.9v2.9h0.9
      c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0v39.6c0,1.8,1.5,3.3,3.3,3.3h162.2v-2.9h-162.3C-19108.1-1332.4-19108.2-1332.6-19108.2-1332.8
      C-19108.2-1332.8-19108.2-1332.8-19108.2-1332.8L-19108.2-1332.8z'
              />
              <path
                id='Path_67965'
                className='st2'
                d='M-19100.6-1299.6c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.3,0.2-0.4,0.2
      c-0.3,0-0.6-0.2-0.6-0.5c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.2
      C-19100.9-1300.1-19100.6-1299.9-19100.6-1299.6z'
              />
              <path
                id='Path_67966'
                className='st1'
                d='M-19017.2-1307.7h-7.2c2.8,12.6-5.1,25.1-17.6,27.9c-12.6,2.8-25.1-5.1-27.9-17.6
      c-2.8-12.6,5.1-25.1,17.6-27.9c1.7-0.4,3.4-0.6,5.1-0.6h0.2l0,0h29.8L-19017.2-1307.7L-19017.2-1307.7z'
              />
              <path
                id='Path_67967'
                className='st3'
                d='M-19017.2-1324.3v2.5h-29v0h-0.2c-8.4,0-16.1,4.7-20,12.2c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.5-0.2-0.5-0.6c0,0,0,0,0,0v-0.1c0-0.1,0.1-0.2,0.1-0.3c3.5-8.5,11.7-14.1,20.9-14.2h0.2l0,0
      L-19017.2-1324.3L-19017.2-1324.3z'
              />
              <path
                id='Rectangle_5128'
                className='st4'
                d='M-19014.4-1328.8L-19014.4-1328.8c1.7,0,3,1.4,3,3v18.1c0,1.7-1.4,3-3,3l0,0
      c-1.7,0-3-1.4-3-3v-18.1C-19017.4-1327.5-19016.1-1328.8-19014.4-1328.8z'
              />

              <path
                id='Path_67968'
                className='st5'
                d='M-19014-1304.7c-0.1,0-0.3,0-0.4,0c-1.7,0-3-1.4-3-3c0,0,0,0,0,0v-18.1c0-1.7,1.4-3,3-3
      c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c-1.5,0.2-2.6,1.5-2.6,3v18.1C-19016.6-1306.2-19015.5-1304.9-19014-1304.7z'
              />
              <path
                id='Rectangle_5129'
                className='st6'
                d='M-19012-1317.8L-19012-1317.8c0.1,0,0.3,0.1,0.3,0.3v8.6c0,0.1-0.1,0.3-0.3,0.3l0,0
      c-0.1,0-0.3-0.1-0.3-0.3v-8.6C-19012.2-1317.7-19012.1-1317.8-19012-1317.8z'
              />
              <path
                id='Rectangle_5130'
                className='st6'
                d='M-19012-1319.6L-19012-1319.6c0.1,0,0.3,0.1,0.3,0.3v0.9c0,0.1-0.1,0.3-0.3,0.3l0,0
      c-0.1,0-0.3-0.1-0.3-0.3v-0.9C-19012.2-1319.5-19012.1-1319.6-19012-1319.6z'
              />
              <path
                id='Path_67971'
                className='st3'
                d='M-19030.4-1319.6c3,3.3,5.1,7.4,5.9,11.8c0,0.2-0.1,0.3-0.3,0.4c-0.2,0-0.3-0.1-0.4-0.2
      c-1.2-3.9-3.4-7.5-6.5-10.2c-0.1-0.1-0.1-0.3,0-0.4c0,0,0,0,0,0L-19030.4-1319.6L-19030.4-1319.6L-19030.4-1319.6z'
              />
              <g id='Group_15838' transform='translate(-19218.277 -1366.084)'>
                <rect
                  id='Rectangle_5131'
                  x='30.2'
                  y='36.6'
                  className='st4'
                  width='5.1'
                  height='52.7'
                />
                <g id='Group_15837' transform='translate(0.333 0.274)'>
                  <rect
                    id='Rectangle_5132'
                    x='31.6'
                    y='37.3'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -17.8938 34.2686)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5133'
                    x='31.6'
                    y='41.3'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -20.7677 35.4638)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5134'
                    x='31.6'
                    y='45.4'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -23.6373 36.6524)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5135'
                    x='31.6'
                    y='49.4'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -26.5118 37.8407)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5136'
                    x='31.6'
                    y='53.5'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -29.3874 39.0318)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5137'
                    x='31.6'
                    y='57.6'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -32.2619 40.2201)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5138'
                    x='31.6'
                    y='61.6'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -35.1323 41.4067)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5139'
                    x='31.6'
                    y='65.7'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -38.0062 42.6019)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5140'
                    x='31.6'
                    y='69.8'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -40.8799 43.7922)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5141'
                    x='31.6'
                    y='73.8'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -43.7564 44.9813)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5142'
                    x='31.6'
                    y='77.9'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -46.6259 46.1699)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5143'
                    x='31.6'
                    y='81.9'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -49.5004 47.3581)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />

                  <rect
                    id='Rectangle_5144'
                    x='31.6'
                    y='86'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 -52.3744 48.5533)'
                    className='st7'
                    width='1.6'
                    height='3'
                  />
                </g>
              </g>
              <g id='Group_15840' transform='translate(-19208.104 -1377.322)'>
                <rect
                  id='Rectangle_5145'
                  x='49.2'
                  y='15.6'
                  className='st4'
                  width='47.7'
                  height='5.1'
                />
                <g id='Group_15839' transform='translate(0.742 0.332)'>
                  <rect
                    id='Rectangle_5146'
                    x='91.4'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 14.6039 70.8847)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5147'
                    x='87.3'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 13.4156 68.0103)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5148'
                    x='83.3'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 12.2237 65.1326)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5149'
                    x='79.2'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 11.0362 62.2601)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5150'
                    x='75.1'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 9.848 59.3857)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5151'
                    x='71.1'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 8.6545 56.5158)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5152'
                    x='67'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 7.4642 53.6421)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5153'
                    x='62.9'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 6.2767 50.7697)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5154'
                    x='58.9'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 5.0873 47.8981)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5155'
                    x='54.8'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 3.8974 45.0196)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />

                  <rect
                    id='Rectangle_5156'
                    x='50.7'
                    y='17'
                    transform='matrix(0.7071 -0.7071 0.7071 0.7071 2.703 42.1477)'
                    className='st7'
                    width='3'
                    height='1.6'
                  />
                </g>
              </g>
              <rect
                id='Rectangle_5157'
                x='-19099.3'
                y='-1329.5'
                className='st8'
                width='4.4'
                height='52.8'
              />
              <rect
                id='Rectangle_5158'
                x='-18999.3'
                y='-1329.5'
                className='st4'
                width='4.4'
                height='52.8'
              />
              <rect
                id='Rectangle_5159'
                x='-18972.3'
                y='-1329.5'
                className='st4'
                width='4.4'
                height='52.8'
              />
              <path
                id='Rectangle_5160'
                className='st1'
                d='M-19083.5-1334.8h5.5c0.3,0,0.6,0.3,0.6,0.6v1.4c0,0.3-0.3,0.6-0.6,0.6h-5.5
      c-0.3,0-0.6-0.3-0.6-0.6v-1.4C-19084.1-1334.6-19083.8-1334.8-19083.5-1334.8z'
              />
              <g id='Group_15842' transform='translate(-19154.502 -1361.015)'>
                <path
                  id='Path_67973'
                  className='st8'
                  d='M152.5,84.3h-2.9V53.9c-0.1-1.9,0.5-3.8,1.7-5.3c1.5-1.5,3.5-2.3,5.7-2.2v2.9
        c-2.1-0.3-4.2,1.1-4.5,3.2c-0.1,0.4-0.1,0.8,0,1.1L152.5,84.3L152.5,84.3z'
                />
                <g id='Group_15841' transform='translate(2.172 0)'>
                  <ellipse
                    id='Ellipse_926'
                    className='st9'
                    cx='155.4'
                    cy='47.9'
                    rx='1.8'
                    ry='1.8'
                  />
                  <ellipse
                    id='Ellipse_927'
                    className='st7'
                    cx='155.4'
                    cy='47.9'
                    rx='0.8'
                    ry='0.8'
                  />
                  <ellipse
                    id='Ellipse_928'
                    className='st10'
                    cx='156.6'
                    cy='47.9'
                    rx='0.1'
                    ry='0.4'
                  />
                </g>
              </g>
              <g id='Group_15844' transform='translate(-19152.33 -1354.015)'>
                <path
                  id='Path_67974'
                  className='st8'
                  d='M163.5,67v10.3h-2.9V66.8c0.1-1.1-0.3-2.3-1-3.1c-0.8-0.8-1.9-1.2-3.1-1.2
        c-0.1,0-0.3,0-0.4,0v-2.9c0.1,0,0.3,0,0.5,0c2,0,3.8,0.8,5.2,2.2C162.9,63.2,163.6,65.1,163.5,67z'
                />
                <g id='Group_15843' transform='translate(0 0)'>
                  <ellipse
                    id='Ellipse_929'
                    className='st9'
                    cx='155.4'
                    cy='61'
                    rx='1.8'
                    ry='1.8'
                  />
                  <ellipse
                    id='Ellipse_930'
                    className='st7'
                    cx='155.4'
                    cy='61'
                    rx='0.8'
                    ry='0.8'
                  />
                  <ellipse
                    id='Ellipse_931'
                    className='st10'
                    cx='154.3'
                    cy='61'
                    rx='0.1'
                    ry='0.4'
                  />
                </g>
              </g>
              <g id='Group_15846' transform='translate(-19143.006 -1354.015)'>
                <path
                  id='Path_67975'
                  className='st8'
                  d='M180.9,67v10.3H178V66.8c0.1-1.1-0.3-2.3-1-3.1c-0.8-0.8-1.9-1.2-3.1-1.2
        c-0.1,0-0.3,0-0.4,0v-2.9c0.1,0,0.3,0,0.5,0c2,0,3.8,0.8,5.2,2.2C180.4,63.2,181,65.1,180.9,67z'
                />
                <g id='Group_15845' transform='translate(0 0)'>
                  <ellipse
                    id='Ellipse_932'
                    className='st9'
                    cx='172.9'
                    cy='61'
                    rx='1.8'
                    ry='1.8'
                  />
                  <ellipse
                    id='Ellipse_933'
                    className='st7'
                    cx='172.9'
                    cy='61'
                    rx='0.8'
                    ry='0.8'
                  />
                  <ellipse
                    id='Ellipse_934'
                    className='st10'
                    cx='171.7'
                    cy='61'
                    rx='0.1'
                    ry='0.4'
                  />
                </g>
              </g>
              <g id='Group_15848' transform='translate(-19145.178 -1361.015)'>
                <path
                  id='Path_67976'
                  className='st8'
                  d='M169.9,84.3H167V53.9c-0.1-1.9,0.5-3.8,1.7-5.3c1.5-1.5,3.5-2.3,5.7-2.2v2.9
        c-2.1-0.3-4.2,1.1-4.5,3.2c-0.1,0.4-0.1,0.8,0,1.1L169.9,84.3L169.9,84.3z'
                />
                <g id='Group_15847' transform='translate(2.172 0)'>
                  <ellipse
                    id='Ellipse_935'
                    className='st9'
                    cx='172.9'
                    cy='47.9'
                    rx='1.8'
                    ry='1.8'
                  />
                  <ellipse
                    id='Ellipse_936'
                    className='st7'
                    cx='172.9'
                    cy='47.9'
                    rx='0.8'
                    ry='0.8'
                  />
                  <ellipse
                    id='Ellipse_937'
                    className='st10'
                    cx='174'
                    cy='47.9'
                    rx='0.1'
                    ry='0.4'
                  />
                </g>
              </g>
              <rect
                id='Rectangle_5161'
                x='-19082.7'
                y='-1329.9'
                className='st1'
                width='3.9'
                height='10.6'
              />
              <rect
                id='Rectangle_5162'
                x='-19082.7'
                y='-1287.3'
                className='st1'
                width='3.9'
                height='10.6'
              />
              <rect
                id='Rectangle_5163'
                x='-19082.7'
                y='-1319.3'
                className='st11'
                width='3.9'
                height='10.6'
              />
              <rect
                id='Rectangle_5164'
                x='-19082.7'
                y='-1308.6'
                className='st1'
                width='3.9'
                height='10.6'
              />
              <rect
                id='Rectangle_5165'
                x='-19082.7'
                y='-1298'
                className='st11'
                width='3.9'
                height='10.6'
              />
            </g>
            <g id='Group_16853' transform='translate(-89 -30)'>
              <path
                id='Path_67969'
                className='st4'
                d='M-19031.2-1302.5c0,1-0.1,1.9-0.3,2.9c-3.2,1.6-5.2,4.9-5.2,8.4c0,0.2,0,0.5,0,0.7
      c-1.5,1.3-3.3,2.4-5.2,3c-3.2-2.2-7.4-2.2-10.6,0c-1.9-0.7-3.7-1.7-5.2-3c0-0.2,0-0.5,0-0.7c0-3.6-2-6.9-5.2-8.4l0,0
      c-0.2-1-0.3-1.9-0.3-2.9c0-1,0.1-2,0.3-3c3.3-1.6,5.3-4.9,5.3-8.5c0-0.2,0-0.4,0-0.6c1.5-1.3,3.2-2.3,5.1-2.9
      c3.2,2.2,7.4,2.2,10.6,0c1.9,0.7,3.6,1.6,5.1,2.9c0,0.2,0,0.4,0,0.6c0,3.6,2.1,6.9,5.3,8.5
      C-19031.3-1304.6-19031.2-1303.6-19031.2-1302.5L-19031.2-1302.5z'
              >
                <animateTransform
                  attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  from='0 -19047.2 -1302.5'
                  to='360 -19047.2 -1302.5'
                  dur={speed}
                  additive='sum'
                  repeatCount='indefinite'
                />
              </path>
              <g id='Group_16852'>
                <animateTransform
                  attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  from='0 -19047.2 -1302.5'
                  to='360 -19047.2 -1302.5'
                  dur={speed}
                  additive='sum'
                  repeatCount='indefinite'
                />
                <ellipse
                  id='Ellipse_923'
                  className='st12'
                  cx='-19047.2'
                  cy='-1302.5'
                  rx='8.9'
                  ry='8.9'
                />
                <path
                  id='Path_67970'
                  className='st0'
                  d='M-19039.7-1299.2c-0.9,2.1-2.7,3.7-4.9,4.4c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2
        c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0l0.1,0c4-1.9,5.7-6.8,3.8-10.8c-0.8-1.6-2.1-3-3.8-3.8l0,0c0,0,0,0-0.1,0
        c-0.1-0.1-0.1-0.2,0-0.3c0-0.1,0.1-0.1,0.2-0.1l0,0l0.1,0c4.3,1.4,6.6,6,5.2,10.3C-19039.5-1299.7-19039.6-1299.4-19039.7-1299.2
        z'
                />
                <ellipse
                  id='Ellipse_924'
                  className='st0'
                  cx='-19047.2'
                  cy='-1302.5'
                  rx='2.6'
                  ry='2.6'
                />
                <ellipse
                  id='Ellipse_925'
                  className='st13'
                  cx='-19045.6'
                  cy='-1302.5'
                  rx='0.4'
                  ry='1'
                />
                <path
                  id='Path_67972'
                  className='st0'
                  d='M-19045.5-1310.4c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0
        c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2h0l0.1,0C-19045.6-1310.6-19045.5-1310.5-19045.5-1310.4z'
                />
              </g>
            </g>
          </g>
        </svg>
      </SvgContainer>
    );
  }
  return (<SVGList style={{ width, height }}>{svgList}</SVGList>);
};
export default Ahu;
