/* eslint-disable max-len */
export const rootConfig = {
  apiRoot: 'http://fl2022.vistrian.com/webservices/demo/api',
  baseUrl: 'http://vistrian2.panorbitprojects.com/api',
  baseUrl2: 'https://vistrian-backend-2.panorbitprojects.com/api',
  // baseUrl3: 'http://vistrian.panorbitprojects.com/api',
  localUrl: 'http://localhost:8000/api'
};

export const primaryRootConfig = rootConfig.apiRoot; // primary root
const widgetsRootConfig = rootConfig.baseUrl2; // root for widgets
const sampleAPIsRootConfig = rootConfig.baseUrl; // root for dummy responses
const widgteTemplateRoot = rootConfig.baseUrl2;
export const apiEndPoints = {
  login: `${primaryRootConfig}/authenticate`,
  forgotPassword: `${primaryRootConfig}/ForgotPassword`,
  // ValidateCode: `${primaryRootConfig}/ForgotPassword/token`,
  ResetPassword: `${primaryRootConfig}/ResetPassword/`,
  ChangePassword: `${primaryRootConfig}/ChangePassword`,
  CarouselData: `${primaryRootConfig}/getCarouselData`,

  notifcationData: `${primaryRootConfig}/getBannerNotifications`,
  localStringsData: `${primaryRootConfig}/getLocaleString`,
  // updated theme
  getTheme: `${sampleAPIsRootConfig}/getTheme/`,
  // getTheme: `${primaryRootConfig}/getTheme/`,

  getMenu: `${primaryRootConfig}/getMenuHeading/`,
  // getMenu: 'https://fl2022.vistrian.com/webservices/demo/api/getMenuHeading/',

  // Getting the default URL for the user if any marked
  setDefaultURL: `${widgetsRootConfig}/UserDefaultUrl/AddOrUpdate`,
  getDefaultUrl: `${widgetsRootConfig}/UserDefaultUrl/Get`,

  getFilterCategory: `${primaryRootConfig}/getFilterCategory/`,
  getGraphURI: `${sampleAPIsRootConfig}/getGraphURI/`,
  getKpvData: `${primaryRootConfig}/getKpvData/`,
  getKpvDetails: `${sampleAPIsRootConfig}/getKpvDetails/`,

  getPlotData: `${primaryRootConfig}/getGraphData/`,
  getInsertPaneOptions: `${sampleAPIsRootConfig}/getInsertPaneOptions/`,
  getEditPaneMenu: `${sampleAPIsRootConfig}/getEditPaneMenu/`,

  // CRUD operations for dashboards
  callSaveDashboards: `${primaryRootConfig}/CallSaveDashboards/`,
  getSavedDashboardSummary: `${primaryRootConfig}/GetSavedDashboardSummary/`,
  callUpdateDashboards: `${primaryRootConfig}/CallUpdateDashboards/`,
  callDeleteSavedDashboards: `${primaryRootConfig}/CallDeleteSavedDashboards/`,

  // CRUD operations for anlytics
  myAnalytics: `${primaryRootConfig}/GetSavedAnalyticsSummary/`,
  analyticsSummary: `${primaryRootConfig}/GetSavedAnalyticsSummary`,
  CallSaveAnalytics: `${primaryRootConfig}/CallSaveAnalytics`,
  callUpdateAnalytics: `${primaryRootConfig}/CallUpdateAnalytics`,
  CallDeleteSavedAnalytics: `${primaryRootConfig}/CallDeleteSavedAnalytics`,

  // notifications
  getNotifications: `${primaryRootConfig}/getNotifications`,

  // annotations
  updateAnnotations: `${primaryRootConfig}/updateAnnotation`,
  updatePageUsageStatistics: `${primaryRootConfig}/updatePageUsageStatistics`,

  // widget panel
  getWidgetPanels: `${widgetsRootConfig}/WidgetPanel`, // ?SubMenuId=1
  addWidgetPanel: `${widgetsRootConfig}/WidgetPanel/Add`,
  getWidgetsByPanelId: `${widgetsRootConfig}/WidgetPanel`, // /1 panelId=1
  updateWidgetPanel: `${widgetsRootConfig}/WidgetPanel/{{WPID}}/update`,
  updateWidgetPanel2: `${widgetsRootConfig}/WidgetPanel/WTIId/Update`,
  deleteWidgetPanel: `${widgetsRootConfig}/WidgetPanel/{{WPID}}/Delete`,
  clearWidgetPanel: `${widgetsRootConfig}/Widget/Delete`,
  UpdateUILayout: `${widgetsRootConfig}/HomeDashboard/UpdateUILayout`,

  // widgets
  addWidget: `${widgetsRootConfig}/Widget/Add`,
  getWidget: `${widgetsRootConfig}/Widget`, // /wid
  deleteWidget: `${widgetsRootConfig}/Widget/{{WID}}/Delete`,
  updateWidget: `${widgetsRootConfig}/Widget/{{WID}}/Update`,
  getAllWidgets: `${widgetsRootConfig}/Widget/GetWidgetTemplateInstanceByPage`,

  // widget types
  addWidgetType: `${widgetsRootConfig}/WidgetType/Add`,
  getWidgetTypes: `${widgetsRootConfig}/WidgetType`,

  // widget_panel templates
  getAllWidgetPanelTemplates: `${widgteTemplateRoot}/WidgetPanelTemplate`,
  addWidgetPanelTemplate: `${widgteTemplateRoot}/WidgetPanelTemplate/Add`,
  GetWidgetPanelTemplateByWPID: `${widgteTemplateRoot}/WidgetPanelTemplateGetByWPID`,
  updateWidgetPanelTemplate: `${widgteTemplateRoot}/WidgetPanelTemplate/Update`,

  // template instances
  getWidgetPanelTemplateInstances: `${widgteTemplateRoot}/WidgetPanelTemplateInstanceGetByWTId`,
  getWidgetPanelPreviewConfig: `${widgteTemplateRoot}/WidgetPanelLayoutDataGetByWTId`,

  // Individual Instances
  getInstancePreview: `${widgteTemplateRoot}/Widget/WidgetPanelPreview`,
  GetGlobalOrSharedWidgetTemplateInstances: `${widgteTemplateRoot}/Widget/GetGlobalOrSharedWidgetTemplateInstances`,

  // localhost:7241/api/Widget/WidgetPanelPreview/2041?UserId=AJay

  // Adding and updating instances
  addWidgetPanelTemplateInstance: `${widgteTemplateRoot}/WidgetPanelTemplateInstanceNew/Add`,
  updateWidgetPanelTemplateInstance: `${widgteTemplateRoot}/WidgetPanelTemplateInstance/Update`,

  // Updating individual widgets of template
  templateWidgetUpdate: `${widgteTemplateRoot}/WidgetPanelTemplate/Update/Widgets`, // old not used api
  templateWidgetUpdateNew: `${widgteTemplateRoot}/TemplateUpdateFullWidgets`,

  // Updating individual widgets of Instance
  instanceWidgetUpdate: `${widgteTemplateRoot}/WidgetPanelTemplateInstance/Update/Widgets`, // old not used api
  instanceWidgetUpdateNew: `${widgteTemplateRoot}/InstanceUpdateFullWidgets`,

  // Instance update only name and description Global dashboard
  instanceTitleUpdate: `${widgteTemplateRoot}/InstanceDashboard/Update`,

  // Clone Instance in global Dashboard
  cloneInstance: `${widgteTemplateRoot}/InstanceDashboard/Clone`,

  // form structure
  getWigetFormStructure: `${widgetsRootConfig}/WidgetType/WidgetFormDetail`,
  // widget data
  getWidgetDataActual: `${primaryRootConfig}/getWidgetData`,
  getWidgetData: `${primaryRootConfig}/getWidgetData`,
  getKpvList: `${widgteTemplateRoot}/getKPVTableData`,
  // widget instances
  getWidgetInstances: `${widgetsRootConfig}/Widget/GetWidgetInstances`,
  addShareInstance: `${widgetsRootConfig}/Widget/{{WID}}/Share/Add`,
  removeShareInstance: `${widgetsRootConfig}/Widget/{{WID}}/Share/remove`,

  // Widget Instances list
  getWidgetInstancesList: `${widgetsRootConfig}/Widget/WidgetInstancesList`,
  updateOnlyNameWidgetInstance: `${widgetsRootConfig}/Widget/UpdateWidgetNameFromWidgetInstanceList`,
  addWidgetsFromList: `${widgetsRootConfig}/HomeDashboard/AddOrUpdateWidgets`,
  getHomeLayout: `${widgetsRootConfig}/HomeDashboard`,
  homeLayoutUpdate: `${widgetsRootConfig}/HomeDashboard/Update`,
  addToHomeFromPreview: `${widgetsRootConfig}/HomeDashboard/AddWidget`,
  cloneWidgetInstance: `${widgetsRootConfig}/WidgetInstanceDashboard/Clone`,
  deleteWidgetFromHome: `${widgetsRootConfig}/HomeDashboard/DeleteWidget`,

  // user management
  getUsersList: `${primaryRootConfig}/getUsersList`,
  // user forms
  getUserForm: `${primaryRootConfig}/getFormStructure/UserForm`,
  getADUserForm: `${primaryRootConfig}/getFormStructure/ActiveDirectoryUserForm`,
  getADUserSearchForm: `${primaryRootConfig}/getFormStructure/ActiveDirectorySearchForm`,
  getUserUpdateForm: `${primaryRootConfig}/getFormStructure/UpdateUserForm`,
  getChangePasswordForm: `${primaryRootConfig}/getFormStructure/ChangePasswordUserForm`,
  getProfileUserForm: `${primaryRootConfig}/getFormStructure/ProfileUserForm`,
  updateProfileDetails: `${primaryRootConfig}/updateUserDetails/profile`,

  // AD User Management
  getADUser: `${primaryRootConfig}/fetchActiveDirectoryUser`,

  // save user details
  createUserDetails: `${primaryRootConfig}/updateUserDetails/new`,
  createADUserDetails: `${primaryRootConfig}/updateUserDetails/newaduser`,
  updateUserDetails: `${primaryRootConfig}/updateUserDetails/update`,
  passwordUser: `${primaryRootConfig}/updateUserDetails/password`,

  getBulkUpdateExcel: `${rootConfig.apiRoot}/getAllUsersJsonToExcel`,
  getBulkAddExcel: `${rootConfig.apiRoot}/getEmptyUsersJsonToExcel`,
  updateBulkUsers: `${rootConfig.apiRoot}/updateBulkUsers/update`,
  addBulkUsers: `${rootConfig.apiRoot}/updateBulkUsers/new`,

  getAdditionalCategories: `${rootConfig.apiRoot}/GetFilterCategoriesForCharts`,
  getAdditionalFilterFiles: `${rootConfig.apiRoot}/GetFilterCategoriesDetailsList`,

  dummyEnd:
    'https://vistrian-backend-2.panorbitprojects.com/api/Widget/GenericApi'
};
