import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100%);
  &.flex {
    display: flex;
    flex-direction: column;
  }
  &.donut {
    width: 100%;
    /* height: calc(100% - 5rem); */
    height: ${({ headerOn }) => (headerOn ? 'calc(100% - 7rem)' : 'calc(100% - 2rem)')};

    /* width: ${({ zoomLevel }) => (zoomLevel || 1) * 100}%;
    height: ${({ zoomLevel }) => (zoomLevel || 1) * 100}%; */
  }
  & .zoom {
    /* padding: 1rem; */
    scale: ${({ zoomLevel }) => zoomLevel || 1};
    transform-origin: 0 0;
    width: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
    height: ${({ zoomLevel }) => (1 / zoomLevel) * 100 || 100}%;
  }
`;

export const ContainerBody = styled(Container)`
  width: 50%;
  height: 80%;
  background: #f4f4f8;
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  opacity: 1;
  margin-top: 3rem;
  padding: 2rem;
`;

export const BodyContainer = styled.div`
  /* height: calc(100% - 4rem); */
  height: ${({ headerOn }) => (headerOn ? 'calc(100% - 5rem)' : 'calc(100% - 0rem)')};
  padding-bottom: 1rem;
`;
