/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UpsertWidget from '../../component/UpsertWidget/UpsertWidget';
import WidgetInstances from '../../component/UpsertWidget/WidgetInstances/WidgetInstances';
import { FlexContainer } from '../../globalStyles';
import { useActions } from '../../store/hooks/useActions';
import WidgetLayout from './WidgetLandingPage';
import WidgetTemplateSelectionSideBar from '../../component/UpsertWidget/WidgetTemplateSelectionSideBar/WidgetTemplateSelectionSideBar';

const Index = (props) => {
  const { menuId } = props;
  const { setMenuIdOfWidget, setWidgetPageType } = useActions();
  useEffect(() => {
    setMenuIdOfWidget(menuId);
    setWidgetPageType('ALL');
  }, [menuId]);

  return (
    <FlexContainer className='col' style={{ height: '100%', padding: '0rem 1.5rem' }}>
      <WidgetLayout menuId={menuId} />
    </FlexContainer>
  );
};

export default Index;
