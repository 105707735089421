/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  ChartDiv,
  Text,
  ChartContainer
} from '../../../../component/Widget/WidgetComponents/GaugeChart/GaugeChart.style';
import { BodyContainer, TabBtnContainer, TabContainer } from '../../../../component/Common/TabUi/TabUi.style';
import { TabUi } from '../../../../component/Common/TabUi/TabUi';
import Gauge from './Gauge';
import { apiData } from './data';

const GaugeChart = ({ data, meta, WID }) => {
  // const { data, meta } = apiData;
  const [currentTab, setCurrentTab] = useState('1');
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };
  const { nodes } = meta || {};
  return (
    <>
      <TabContainer className='zoom chart padding gauge'>
        <TabBtnContainer id={`tab${WID}`}>
          <TabUi currentTab={currentTab} tabs={data} handleTabClick={handleTabClick} />
        </TabBtnContainer>
        <BodyContainer className='gauge'>
          <Gauge data={data?.[currentTab - 1]?.linearGauge || {}} metaNode={nodes?.type1} />
        </BodyContainer>
      </TabContainer>
    </>
  );
};

export default GaugeChart;
