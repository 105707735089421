/* eslint-disable max-len */

export function isEmpty(obj) {
  let isempty = false;
  const type = typeof obj;

  switch (type) {
    case 'object':
      isempty = obj === null || (Array.isArray(obj) && obj.length === 0) || (obj && Object.keys(obj).length === 0); // if object is empty
      break;
    case 'string':
      isempty = obj.trim() === '';
      break;
    default:
      isempty = isempty || !obj || type === 'undefined' || obj === null;
      break;
  }

  return isempty;
}
