/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as kpvChartingCreators } from '../../component/KPVCharts/Store/action';
import { chartLableMapping } from '../../constants';

const useGetMachinesShortlisted = (isCheckReq = true, skipComputation) => {
  if (skipComputation) {
    return;
  }
  const activeMenuId = useSelector((state) => state.home.activeMenuId);
  const KPVShortlist = useSelector((state) => state.filterResultData.KPVShortlist?.[activeMenuId]) || [];
  // const machinesShortlisted = useSelector((state) => state.KPVCharts.machinesShortlisted);
  const enabledPanes = useSelector((state) => state.filterResultData.enabledPanes);

  const dispatch = useDispatch();
  useEffect(() => {
    // if (KPVShortlist.length !== machinesShortlisted.length) {
    //   console.log('useGetMachinesShortlisted hook');
    const machines = [];
    KPVShortlist.forEach((e) => {
      if (!isCheckReq || !machines.includes(e.machineName)) {
        const obj = {
          kpvId: e.id,
          machineId: e.machineId,
          machineName: e.machineName,
          groupName: e.groupName,
          kpvName: e.KPV,
          macId: e.macId
        };
        // add details of charttypes
        (enabledPanes || []).forEach((ele) => {
          const { key, type } = ele;
          obj[key] = e[key];
          obj[chartLableMapping[key]] = e[key];
          // console.log('key inside', key, chartLableMapping[key]);
          // obj[key] = 'true';
          // obj[chartLableMapping[key]] = 'true';
        });
        // console.log('obj', obj);
        machines.push(obj);
      }
    });
    dispatch(kpvChartingCreators.setMachinesShortlisted(machines));
    // }
  }, []);
};

export default useGetMachinesShortlisted;
