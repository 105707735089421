/* eslint-disable max-len */
import React from 'react';
import { LogoText } from '../../views/auth/login/Login.style';
import config from '../../config.json';
import vistrianLogo from '../../assets/Logos/Vistrian_logomark.svg';
import osramLogo from '../../assets/Logos/amsOSRAM.png';
import IQMLogo from '../../assets/Logos/iqm.svg';
import IQMDarkLogo from '../../assets/Logos/iqm_black.svg';

export default function Logo({ height, isInLogin = true, isDark, logourl }) {
  // console.log('logo?.[config.company]', logo?.[config.company], config.company);

  const logo = {
    osram: osramLogo,
    IQM: isDark ? IQMDarkLogo : IQMLogo
  };

  return (
    <LogoText>
      {logo?.[config.company] || isInLogin ? (
        <img src={logourl || vistrianLogo || logo?.[config.company]} alt={config.company} height={height || '30'} />
      ) : (
        config.company_label || config.company
      )}
    </LogoText>
  );
}
