/* eslint-disable prefer-destructuring */
/* eslint-disable no-const-assign */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { kpvData } from '../../../constants/sampleData';
import { CancelBlack, searchBlack } from '../../../assets/icons';
import KPVGroup from '../KPVGroupComponent/KPVGroupComponent.view';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  MachineHeaderText,
  Row,
  TableContainer,
  Cell,
  CancelIcon,
  SearchIcon,
  SearchInput
} from './KPVListComponent.style';
import { Creators as FilterCreators } from '../../../views/dashboard/FilterView/store';
import { Creators as KPVCreators } from '../Store';
import Loader from '../../Loader/Loader';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import Groupby from '../../Common/GroupBy/GroupBy';
import Toast from '../../Common/Toast';
import { Div, FlexContainer } from '../../../globalStyles';
import { Table, Tr, Td, Th } from '../../Common/Table/SpacedRows.styles';
import { generateSelectedFilter, getBreadCrumbs } from '../Iframe/generateSelectedFilter';
import {
  FilterNoDataContainer,
  FilterSelectImage,
  FilterSelectText
} from '../../../views/dashboard/FilterView/Filter.style';
import FilterSelectPng from '../../../assets/Images/Filter/Use Filter @3x.png';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { Creators as kpvChartingCreators } from '../../KPVCharts/Store/action';
import { useActions } from '../../../store/hooks/useActions';
import { myAnalyticsCreators } from '../../../views/MyAnalytics/store';

const KPVList = () => {
  const state = useSelector((state) => state.filterData);
  const {
    tags,
    selectedItem,
    filterData,
    breadCrumbs,
    activeBreadCrumb,
    cumulativeTags,
    menuIdofFilter,
    selectedParent
  } = state;
  const {
    resultDetails: kpvData,
    metaDetails,
    loading,
    error,
    infoMsg,
    activeTab
  } = useSelector((state) => state.filterResultData);
  const localeStrings = useSelector((state) => state.localeStrings.localeStrings);
  const [selectedMachines, setSelectedMachines] = useState({});
  const { setActiveTab } = useActions();
  // const [activeTab, setActiveTab] = useState();
  const [showSearchInp, setShowSearchInp] = useState({});
  const [searchQuery, setSearchQuery] = useState({});
  const [filteredResultData, setFilteredResultData] = useState(null);
  const searchRef = useRef();
  const dispatch = useDispatch();
  const [kpvGroupDetails, setKpvGroupDetails] = useState([]);

  const [machines, setMachines] = useState([]);
  const [kpvGroups, setKpvGroups] = useState({});
  const [groupBy, setGroupBy] = useState();
  const [groupByList, setGroupByList] = useState([]);
  const addCummulativeTags = useCallback((payload) => dispatch(FilterCreators.setCummulativeTags(payload)), [dispatch]);
  const addTags = useCallback((payload) => dispatch(FilterCreators.setTags(payload)), [dispatch]);
  const addTabToObj = useCallback((payload) => dispatch(FilterCreators.universalFilterSetter(payload)), [dispatch]);

  useEffect(() => {
    dispatch(myAnalyticsCreators.resetAnalyticsData());
  }, []);

  // fetching kpv data on change of breadcrumb
  useEffect(() => {
    // console.log('change in breadcr', breadCrumbs);
    // console.log('breadCrumbs && activeTab', breadCrumbs.length > 0 && (activeBreadCrumb || activeBreadCrumb === 0));
    if (breadCrumbs.length > 0 && (activeBreadCrumb || activeBreadCrumb === 0)) {
      // console.log('activeBreadCrumb', activeBreadCrumb);
      let pointer = breadCrumbs[activeBreadCrumb]?.value?.tree;
      while (pointer?.values?.length) {
        // console.log('ins', pointer);
        pointer = pointer.values[0];
      }
      // console.log('pointer?.name', pointer);
      // not implemented
      if (pointer && Array.isArray(pointer)) {
        // console.log('isArray');
        setMachines(pointer.map((e) => e.name));
        setActiveTab({ id: pointer[0].id, name: pointer[0].name });
        if (pointer[0].name !== activeTab.name) {
          dispatch(
            KPVCreators.getKpvData({
              machineId: pointer[0].name,
              payload: breadCrumbs[activeBreadCrumb].value,
              menuId: menuIdofFilter,
              tab: selectedParent
            })
          );
        }
      } else if (pointer) {
        // console.log('is not Array');

        setActiveTab({ id: pointer.id, name: pointer.name });

        if (pointer.name !== activeTab) {
          dispatch(
            KPVCreators.getKpvData({
              machineId: pointer.name,
              payload: breadCrumbs[activeBreadCrumb].value,
              menuId: menuIdofFilter
            })
          );
        }
        const treeLevel = breadCrumbs.map((breadCrumb) => {
          pointer = breadCrumb?.value?.tree;
          while (pointer?.values?.length) {
            // console.log('ins', pointer);
            pointer = pointer.values[0];
          }
          // console.log('sas', pointer);
          return { id: pointer.id, name: pointer.name };
          // return breadCrumb.key[0].substring(breadCrumb.key[0]?.lastIndexOf('>') + 1).trim();
        });
        // setMachines(pointer.map((e) => ({ id: e.id, name: e.name })));
        setMachines(treeLevel);
      }
    }
    // dispatch(KPVCreators.getKpvData(breadCrumbs[activeBreadCrumb]));
  }, [activeBreadCrumb, breadCrumbs]);
  // make this fuctional for any number of search fields
  const _handleKeyPress = (e) => {
    e.preventDefault();
    // if (e.key === 'Enter') {
    //   setToggleSearchIcon(false);
    //   findSearchResults(searchQuery);
    // }
  };

  const handleSearchQuery = (field, e) => {
    const locSearchQuery = e?.target?.value || '';
    setSearchQuery({ ...searchQuery, [field]: locSearchQuery });
    const tempFilteredKpvGroupDet = { ...kpvGroupDetails };

    Object.keys(kpvGroupDetails).forEach((group) => {
      tempFilteredKpvGroupDet[group] = [
        ...kpvGroupDetails[group].filter((item) =>
          Object.keys(searchQuery).every((query) => {
            if (query === field) {
              return item[query]?.toString().toLowerCase().includes(locSearchQuery.toLowerCase());
            }
            // all other cumulative search queries are checked
            return item[query]?.toString().toLowerCase().includes(searchQuery[query].toLowerCase());
          })
        )
      ];
    });

    setFilteredResultData(tempFilteredKpvGroupDet);
    // if (!toggleSearchIcon) {
    //   setToggleSearchIcon(true);
    // }
  };

  const handleSearchVisiblity = (field) => {
    // before changing showSearchInp
    if (showSearchInp[field]) {
      setSearchQuery({ ...searchQuery, [field]: '' });
      handleSearchQuery(field, '');
    }

    setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
  };

  const computeBreadCrumbs = () => {
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'breadCrumbs',
        value: [...getBreadCrumbs(generateSelectedFilter(cumulativeTags, filterData, false))]
      })
    );
  };

  // make removeMachine functional
  const handleRemoveMachine = (machine, index) => {
    // console.log('machine, index', machine, index);
    // get the key of plant/tree which is privided at the start
    // const treeKey = Object.keys(cumulativeTags)[0];
    const treeKey = filterData[0].groupTitle;
    // get the pathNo
    const keyList = Object.keys(cumulativeTags[treeKey]);
    const pathNo = keyList[index];
    const lastIndex = keyList.length - 1;
    const isLastMachine = pathNo === keyList[lastIndex];

    // console.log(
    //   'pathNo',
    //   lastIndex,
    //   keyList[lastIndex],
    //   isLastMachine,
    //   index,
    //   pathNo,
    //   Object.keys(cumulativeTags[Object.keys(cumulativeTags)[0]])
    // );
    // dispatch(FilterCreators.setTags(tempTags));

    delete cumulativeTags[treeKey][pathNo];
    const cumulativeSelectedObj = state[`cumulative${treeKey}`];
    delete cumulativeSelectedObj[pathNo];
    computeBreadCrumbs();

    // no paths
    if (isEmpty(cumulativeTags[treeKey])) {
      addTags({ ...tags, [treeKey]: {} });
      addTabToObj({
        key: treeKey,
        value: {}
      });
    }

    // change the activeBreadCrumb to index before the machine for the last index
    //  for others it is not required as next machine would be taking that spot
    if (isLastMachine) {
      dispatch(
        FilterCreators.universalFilterSetter({
          key: 'activeBreadCrumb',
          value: index >= 1 ? index - 1 : 0
        })
      );
    }

    // old version code
    // const { group, id } = selectedMachineId;
    // const tempSelectedObj = { ...state[group] };
    // const tempTags = { ...tags };
    // const lastLevelIndex = Object.keys(tags[group]).length;
    // const lastLevelTags = tempTags[group][lastLevelIndex - 1];
    // // delete

    // if (lastLevelTags.length === 1) {
    //   const copiedSelectedArr = [...selectedItem];
    //   const newSelectedArr = copiedSelectedArr.filter((i) => i !== group);

    //   if (filterData.filter((item) => newSelectedArr.includes(item))) {
    //     dispatch(
    //       FilterCreators.universalFilterSetter({
    //         key: 'showFilterResult',
    //         value: false
    //       })
    //     );
    //   }
    //   dispatch(FilterCreators.setSelectedItem(newSelectedArr));
    // }

    // tempTags[group][lastLevelIndex - 1] = lastLevelTags.filter((tag) => tag.id !== id);
    // delete tempSelectedObj[id];

    // dispatch(FilterCreators.setTags(tempTags));
    // dispatch(
    //   FilterCreators.universalFilterSetter({
    //     key: group,
    //     value: tempSelectedObj
    //   })
    // );
  };

  const handleMachineSelect = (machineId, index) => {
    if (activeTab.name === machineId) return;
    dispatch(
      FilterCreators.universalFilterSetter({
        key: 'activeBreadCrumb',
        value: index
      })
    );
    // check whether the data is previously fetched
    // if (!kpvData[machineId]?.length) {
    dispatch(KPVCreators.getKpvData({ machineId, payload: breadCrumbs[index].value, menuId: menuIdofFilter }));

    setActiveTab(machineId);
  };

  // computing groupBy, searchableFields, searchInpFileds from dynamic meta content
  useEffect(() => {
    setGroupBy(metaDetails?.grouping && metaDetails?.grouping[0]);
    // searchable feilds computation
    const searchableFields = metaDetails?.fields?.filter((field) => field.searchable);
    const tempShowSearchInp = {};
    searchableFields?.forEach((e) => {
      tempShowSearchInp[e.key] = false;
    });
    setShowSearchInp({ ...tempShowSearchInp });
  }, [kpvData]);

  // computing the kpvGroups i.e, filtering them based on the group {group1: array, group2: array}
  useEffect(() => {
    if (kpvData[activeTab?.name]) {
      const data = kpvData[activeTab.name];
      if (metaDetails && data.length) {
        const { grouping } = metaDetails;
        setGroupByList(grouping);

        const tempGrps = {};
        grouping.forEach((group) => {
          data.forEach((e) => {
            if (!tempGrps[group]?.length) {
              tempGrps[group] = [];
            }
            tempGrps[group].push(e);
          });
        });
        setKpvGroups(tempGrps);
      }
    }
  }, [kpvData[activeTab?.name]]);

  // computing the actual rows that needs be rendered, groupBy key needs tobe changed to actual value
  useEffect(() => {
    // console.log(groupBy, 'kpvGroups[groupBy]', kpvGroups, kpvGroups[groupBy]);
    if (kpvGroups[groupBy]?.length) {
      const tempSubGroups = {};
      kpvGroups[groupBy].forEach((innerGroup) => {
        // data[group]:data.filter((e) => e.key === data[group])}
        if (!innerGroup) return;
        if (!tempSubGroups[innerGroup[groupBy]]?.length) {
          tempSubGroups[innerGroup[groupBy]] = [];
        }
        tempSubGroups[innerGroup[groupBy]].push(innerGroup);
      });
      // console.log('tempSubGroups', tempSubGroups);
      setKpvGroupDetails(tempSubGroups);
    }
  }, [kpvGroups, groupBy]);

  // console.log('kpvGroups', kpvGroups, 'kpvGroupDetails', kpvGroupDetails);
  const handleGroupBy = (data) => {
    setGroupBy(data);
  };

  const clearFilterToastMessage = () => {
    dispatch(KPVCreators.genericSetter({ key: 'error', value: null }));
  };
  const clearInfoToastMessage = () => {
    dispatch(KPVCreators.genericSetter({ key: 'infoMsg', value: null }));
  };
  // {
  // refresh for every 15 sec
  // useEffect(() => {
  //   const clearCount = setInterval(() => {
  //     dispatch(KPVCreators.getKpvData(activeTab));
  //   }, [15000]);
  //   return () => clearInterval(clearCount);
  // }, []);
  // old code to display machine tabs
  // {
  //   Object.keys(selectedMachines).map((machine) => (
  //     <MachineHeaderText
  //       onClick={() => handleMachineSelect(machine)}
  //       className={activeTab === machine ? 'active' : ''}
  //       key={machine}
  //     >
  //       {selectedMachines[machine].name}
  //       <CancelIcon onClick={() => handleRemoveMachine(selectedMachines[machine])}>
  //         <DynamicSVGIcon iconUrl={CancelBlack} width='1.5rem' />

  //         {/* <CancelBlack /> */}
  //       </CancelIcon>
  //     </MachineHeaderText>
  //   ));
  // }
  // useEffect(() => {
  //   dispatch(KPVCreators.setActiveTab(activeTab));
  // }, [kpvData[activeTab]]);
  // old version for search results
  // const findSearchResults = (field, searchQuery) => {
  //   const tempFilteredKpvGroupDet = { ...kpvGroupDetails };
  //   Object.keys(kpvGroupDetails).forEach((group) => {
  //     tempFilteredKpvGroupDet[group] = [
  //       ...kpvGroupDetails[group].filter((item) => item[field].toLowerCase().includes(searchQuery.toLowerCase()))
  //     ];
  //   });
  //   //   let tempGroupValues = [];
  //   //   const tempGroupObj = { ...group };
  //   //   if (group.values.length > 0) {
  //   //     tempGroupValues = [...group.values].filter((row) => row.KPV.toLowerCase().includes(searchQuery.toLowerCase()));
  //   //     tempGroupObj.values = [...tempGroupValues];
  //   //     tempKpvData[index] = tempGroupObj;
  //   //   }
  //   // });
  //   setFilteredResultData(tempFilteredKpvGroupDet);
  // };
  // setting selectedMachines from the filter payload
  // useEffect(() => {
  //   const tempSelectedMachines = {};
  //   selectedItem.forEach((item) => {
  //     if (filterData.find((i) => i.groupTitle === item)) {
  //       const finalLength = Object.keys(tags[item]).length;
  //       const lastItem = tags[item][finalLength - 1];
  //       if (Array.isArray(lastItem)) {
  //         lastItem.forEach((obj) => {
  //           tempSelectedMachines[obj.id] = { ...obj, group: item };
  //         });
  //       } else {
  //         tempSelectedMachines[lastItem.id] = { ...lastItem, group: item };
  //       }
  //     }
  //   });
  //   setActiveTab(Object.keys(tempSelectedMachines)[0]);
  //   dispatch(KPVCreators.getKpvData(Object.keys(tempSelectedMachines)[0]));
  //   setSelectedMachines(tempSelectedMachines);
  // }, [tags]);
  // useEffect(() => {
  // if (showSearchInp) searchRef.current.focus();
  // }, [showSearchInp]);
  // }

  let Content = null;
  if (loading) Content = <Loader />;
  else {
    Content = (
      <ContentContainer>
        {!isEmpty(metaDetails) ? (
          <Table className='attached'>
            {/* table columns */}
            <Tr className='header'>
              {metaDetails?.fields?.map(
                (col, key) =>
                  // !metaDetails?.grouping?.includes(col.key) &&
                  col?.visible && (
                    <Th
                      key={key}
                      width={col.width}
                      order={col.order}
                      border={col.name !== 'Action'}
                      // eslint-disable-next-line prefer-template
                      className={'colHeading ' + col.key}
                    >
                      <FlexContainer style={{ width: '100%' }} className='ai_center jc_center'>
                        <FlexContainer style={{ width: '100%' }} className='ai_center jc_center'>
                          {showSearchInp[col.key] && col.searchable ? (
                            <SearchInput
                              className='show'
                              ref={searchRef}
                              type='text'
                              placeholder={'Search ' + col.key + 's'}
                              onChange={(e) => handleSearchQuery(col.key, e)}
                              value={searchQuery[col.key]}
                              // onKeyUp={(e) => _handleKeyPress(e)}
                            />
                          ) : (
                            <p>{localeStrings[col.title] || col.title}</p>
                          )}
                          {col.searchable && (
                            <SearchIcon onClick={() => handleSearchVisiblity(col.key)}>
                              {!showSearchInp[col.key] ? (
                                <DynamicSVGIcon iconUrl={searchBlack} />
                              ) : (
                                <DynamicSVGIcon iconUrl={CancelBlack} />
                              )}
                            </SearchIcon>
                          )}
                        </FlexContainer>

                        {col.key === 'KPV' && (
                          <div style={{ marginLeft: 'auto' }}>
                            <Groupby selected={groupBy} onClick={handleGroupBy} list={groupByList} />
                          </div>
                        )}
                      </FlexContainer>
                    </Th>
                  )
              )}
              <Th>action</Th>
            </Tr>
            {/* table rows */}
            {Object.keys(showSearchInp).some((e) => showSearchInp[e]) && filteredResultData
              ? Object.keys(filteredResultData)?.map((groupName) => (
                  <KPVGroup
                    // key={groupName.id}
                    groupItems={filteredResultData[groupName]}
                    groupTitle={groupName}
                    // machineId={activeTab}
                    // machineName={selectedMachines[activeTab]?.name}
                    activeTab={activeTab}
                  />
                ))
              : kpvGroupDetails &&
                Object.keys(kpvGroupDetails)?.map((groupName) => (
                  <KPVGroup
                    // key={groupName.id}
                    groupItems={kpvGroupDetails[groupName]}
                    groupTitle={groupName}
                    // machineId={activeTab}
                    // machineName={selectedMachines[activeTab]?.name}
                    activeTab={activeTab}
                  />
                ))}
          </Table>
        ) : (
          <FlexContainer className='jc_center ai_center' style={{ fontSize: '2rem', height: '100%' }}>
            No data available
          </FlexContainer>
        )}
      </ContentContainer>
    );
  }
  // console.log('machines', machines, activeTab);
  return (
    <Container>
      {breadCrumbs.length > 0 && (activeBreadCrumb || activeBreadCrumb === 0) ? (
        <>
          <HeaderContainer className='hideScrollBar'>
            {machines?.map((machine, index) => (
              <MachineHeaderText className={activeTab.name === machine.name ? 'active' : ''} key={machine.id}>
                <span onClick={() => handleMachineSelect(machine.name, index)} style={{ flex: 1, marginRight: '2rem' }}>
                  {machine.name}
                </span>
                <CancelIcon className='relative pointer' onClick={() => handleRemoveMachine(machine.name, index)}>
                  <DynamicSVGIcon iconUrl={CancelBlack} width='1.5rem' />
                  {/* <CancelBlack /> */}
                </CancelIcon>
              </MachineHeaderText>
            ))}
          </HeaderContainer>
          {/* main content */}
          {Content}
        </>
      ) : (
        <FilterNoDataContainer>
          <FilterSelectImage src={FilterSelectPng} />
          <FilterSelectText>
            {localeStrings.chooseFromFilter || 'Choose From Filters To See Dashboard'}
          </FilterSelectText>
        </FilterNoDataContainer>
      )}
      {error && <Toast style={{ right: 20 }} fn={clearFilterToastMessage} header='Error' message={error} />}
      {infoMsg && <Toast style={{ right: 20 }} fn={clearInfoToastMessage} header='Info Message' message={infoMsg} />}
    </Container>
  );
};

export default KPVList;
