import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  aditionalFilterLoading: false,
  categoryData: null,
  error: null,

  loading: false,
  filterData: null,

  listLoading: false,
  listData: null,
  listMeta: null

};

const getCategoryListStart = (state = INITIAL_STATE) => ({
  ...state,
  aditionalFilterLoading: true
});

const getCategoryListSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    aditionalFilterLoading: false,
    categoryData: data
  };
};

const getCategoryListFailure = (state = INITIAL_STATE, action) => {
  console.log(state);
  return {
    ...INITIAL_STATE,
    aditionalFilterLoading: false,
    error: action.payload.error,

  };
};

const getFilterListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  listLoading: true
});
const getFilterListSuccess = (state = INITIAL_STATE, action) => {
  const { data, meta } = action.payload;
  return {
    ...state,
    listData: data,
    listMeta: meta,
    listLoading: false
  };
};
const getFilterListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error,
    listLoading: false
  };
};

const HANDLERS = {
  [Types.GET_CATEGORY_LIST_START]: getCategoryListStart,
  [Types.GET_CATEGORY_LIST_SUCCESS]: getCategoryListSuccess,
  [Types.GET_CATEGORY_LIST_FAILURE]: getCategoryListFailure,

  [Types.GET_FILTER_LIST_START]: getFilterListStart,
  [Types.GET_FILTER_LIST_SUCCESS]: getFilterListSuccess,
  [Types.GET_FILTER_LIST_FAILURE]: getFilterListFailure,
};

export const AdditionalFilterReducer = createReducer(INITIAL_STATE, HANDLERS);
