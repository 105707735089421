/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'prop-types';
import KeyInputLayoutView from '../../Common/KeyValueLayout/KeyInputLayout.view';
import RadioTypeComponent from '../../Common/InputTypes/RadioType/RadioType.view';
import CheckboxTypeComponent from '../../Common/InputTypes/CheckboxType/CheckboxType.view';
import TextTypeComponent from '../../Common/InputTypes/TextType/TextType.view';
import DropdownTypeComponent from '../../Common/InputTypes/DropdownType/DropdownType.view';
import { UpsertWidgetCreators } from '../Store';
import {
  ListContainer,
  SelectedList,
  CancelIcon,
  Item,
  Container
} from './AddWidgetForm.style';
import { INPUT_TYPES } from '../../../constants';
import EquipmentSearchView from '../../Common/InputTypes/EquipmentSearch/EquipmentSearch.view';
import Toast from '../../Common/Toast';
import { useActions } from '../../../store/hooks/useActions';
import Loader from '../../Common/Loader/Loader';
import { getResetPayloadForDependentFields } from './Utility';
import WidgetFooter from '../WidgetFooter/WidgetFooter.view';
import { genericFormValidation } from '../../Widget/utils/formValidation';
import UpsertSaveHeader from '../UpsertSaveHeader';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { formatedFilterData } from '../../WidgetForm/utility/formatFilterData';
import EquipmentFilterSearchTypeTree from '../../Common/InputTypes/FilterSearchType/EquimentFilterSearchTypeTree';
import { getFilterSelectionInp, populateFilterDashboard } from '../../WidgetForm/utility/populateFilterDashboard';
import { Creators as ObjectTreeCreators } from '../../WidgetForm/Store';

const AddWidgetFormTree = (props, ref) => {
  const {
    loading,
    formStructureError, // any error from the form
    error
  } = useSelector((state) => state.upsertWidgetData);
  const { setFormStructureFailure, getWidgetDataFailure } = useActions();
  const {
    data,
    disabledFields,
    showFooter, // boolean
    hideModal,
    handleSave,
    showHeader, // boolean
    header,
    handleBack,
    formInput: globalFormInput,
    formStructureFieldDepenents,
    allowHorizontal = true
    // setFormInput: setGlobalFormInput
  } = props;
  const [formInput, setFormInput] = useState([]);
  const [fieldsTobeRefetched, setFieldsTobeRefetched] = useState([]);
  const dispatch = useDispatch();
  const {
    objectTreeData
  } = useSelector((state) => state.objectTreeData);

  const isFormIntialized = useRef();
  useEffect(() => {
    // populate values in fields
    if (globalFormInput) {
      setFormInput(globalFormInput);
    }
  }, [globalFormInput]);

  useEffect(() => {
    if (!isEmpty(formInput)) {
      if (!isEmpty(objectTreeData) && !isEmpty(objectTreeData?.values)) {
        const filterTree = objectTreeData.reduce((acc, cur) => {
          const { keyName } = cur;
          return {
            ...acc,
            [keyName]: [cur]
          };
        }, {});
        const payloadTree = { data: formInput };
        const { cumTags, cumSelectedObj } = populateFilterDashboard(payloadTree, filterTree);
        const payload = getFilterSelectionInp(cumTags, cumSelectedObj);
        dispatch(ObjectTreeCreators.PopulateTreeStructure(payload));
      }
    }
  }, [objectTreeData]);

  useEffect(() => {
    // if globalforminput is filled then formInput must be filled
    if (!isEmpty(data)) {
      const isFormFilled = !isEmpty(globalFormInput)
        ? !isEmpty(formInput)
        : true;
      if (
        // !shouldForceRefetch.current &&
        !isFormIntialized.current &&
        isFormFilled
      ) {
        // formIsFilled but formIntialized = false, shouldForceRetch = false
        // shouldForceRefetch.current = true;
        isFormIntialized.current = true;
        const dependentFieldsList = Object.values(
          formStructureFieldDepenents
        ).reduce((e, final) => [...final, ...e], []);
        if (dependentFieldsList) {
          setFieldsTobeRefetched(dependentFieldsList);
        }
      }
    }
  }, [formInput, data]);

  useImperativeHandle(
    ref,
    () => ({
      getFormInput() {
        return formInput;
      }
    }),
    [formInput]
  );

  const { getObjectTreeDataSuccess, clearFilterData, clearTreeData } = useActions();

  useEffect(() => {
    // clearFilterData();
    clearTreeData();
  }, []);

  useEffect(() => {
    const dataObj = {};
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      dataObj[item?.key] = [{ ...item }];
    });
    const { formatedData, keyTitleMapping } = formatedFilterData(dataObj);
    const resultType = 'iframe';
    getObjectTreeDataSuccess({
      data: formatedData,
      resultType,
      keyTitleMapping
    });
  }, [data]);

  const resetFieldsTobeRefetched = () => {
    setFieldsTobeRefetched([]);
  };
  const resetDependents = (keyOfChangedField, additionalProps) => {
    const { shouldUpdateRefetch = true, localFormInput } =
      additionalProps || {};
    const latestFormInput = localFormInput || formInput;
    const dependents = formStructureFieldDepenents?.[keyOfChangedField];
    if (shouldUpdateRefetch) {
      setFieldsTobeRefetched(dependents);
    }
    const payloadTobeReseted = getResetPayloadForDependentFields(
      dependents,
      latestFormInput
    );
    return payloadTobeReseted;
  };

  const handleType = (ele, item) => {
    const { itemKey: key } = item;
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele
    }));
  };

  const clearError = () => {
    setFormStructureFailure('');
    getWidgetDataFailure('');
  };

  const multiValueSelectionHandler = (
    item,
    { key, itemValueKey = 'value' }, // field related properties
    additionalParams = {} // other props required
  ) => {
    try {
      // console.log('checkbox handler', isFormIntialized.current);
      if (isFormIntialized.current) {
        const { isDirectUpdate = false } = additionalParams;
        const payloadTobeReseted = resetDependents(key, additionalParams);
        // direct list as value for that key is sent
        if (isDirectUpdate) {
          // console.log('1============direcct', key, item, payloadTobeReseted);
          setFormInput((formInput) => ({
            ...formInput,
            ...payloadTobeReseted,
            [key]: item
          }));
        } else {
          // checking if already selected are not
          const selections = formInput?.[key] || [];
          const itemIndex = selections.findIndex(
            (prevItem) => prevItem?.[itemValueKey] === item?.[itemValueKey]
          );
          if (itemIndex > -1) {
            const newSelections = [
              ...selections.slice(0, itemIndex),
              ...selections.slice(itemIndex + 1)
            ];
            //  selections.splice(itemIndex, 1);
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: newSelections
            }));
          } else {
            setFormInput((formInput) => ({
              ...formInput,
              ...payloadTobeReseted,
              [key]: [...selections, item]
            }));
          }
        }
      }
    } catch (e) {
      //
    }
  };

  const formValueSetter = (item, { key }) => {
    const payloadTobeReseted = resetDependents(key);

    if (!isEmpty(item)) {
      setFormInput((formInput) => ({
        ...formInput,
        ...payloadTobeReseted,
        [key]: [item[key]]
      }));
    }
    // if (!isEmpty(item) && !isEmpty(item[0]?.key) && !isEmpty(item[0]?.value)) {
    //   setFormInput((formInput) => ({
    //     ...formInput,
    //     ...payloadTobeReseted,
    //     [key]: item
    //   }));
    // }
  };

  const textInputHandler = (ele, key) => {
    const payloadTobeReseted = resetDependents(key);
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: ele.target.value
    }));
  };
  const removeListItem = (item, key) => {
    const payloadTobeReseted = resetDependents(key);
    const equipmentArray = formInput?.[key]?.filter(
      (prevItem) => prevItem !== item
    );
    setFormInput((formInput) => ({
      ...formInput,
      ...payloadTobeReseted,
      [key]: equipmentArray
    }));
  };
  const isSaveEnabled = genericFormValidation(data, formInput);
  const Header = (
    <>
      {showHeader && (
        <UpsertSaveHeader
          header={header} // {widgetLabel ? `${isEdit ? 'Edit' : 'Add'} ${widgetLabel} Pane` : activeTab.label}
          handleBack={handleBack}
          handleSave={handleSave}
          enableSave={isSaveEnabled && header && true}
          submissionLoader={loading}
        />
      )}
    </>
  );
  // console.log('form==ss=2=', data, formInput);

  return (
    <>
      {Header}
      <div
        className='formContainer'
        style={{ position: 'relative', minHeight: '50rem' }}
      >
        {loading && <Loader width='75%' height='70vh' position='fixed' />}
        <Container
          width='92rem'
          height='48rem'
          className={`status_value_widget ${
            allowHorizontal ? 'allowHorizontal' : ''
          }`}
        >
          {objectTreeData &&
            objectTreeData?.map((item) => {
              // enable this check only if form is intialized
              let tobeRefetched;
              if (isFormIntialized.current) {
                if (
                  fieldsTobeRefetched &&
                  fieldsTobeRefetched?.includes(item.key)
                ) {
                  tobeRefetched = true;
                } else {
                  tobeRefetched = false;
                }
              } else {
                tobeRefetched = false;
              }
              const commonProps = {
                resetFieldsTobeRefetched,
                formInput,
                tobeRefetched,
                formStructure: data,
                isFormIntialized: isFormIntialized.current
              };
              const disabled = disabledFields?.some((e) => e === item.key);
              if (item.type === INPUT_TYPES.CHECKBOX) {
                return (
                  <div className={disabled ? 'disable' : ''}>
                    <CheckboxTypeComponent
                      item={item}
                      fieldClassName='none'
                      value={formInput?.[item.key]} // values tobe shown
                      handleChange={multiValueSelectionHandler}
                      {...commonProps}
                    />
                  </div>
                );
              }
              if (item.type === INPUT_TYPES.OBJECT) {
                return (
                  <div className={disabled ? 'disable' : ''}>
                    <EquipmentFilterSearchTypeTree
                      item={item}
                      fieldClassName='none'
                      value={formInput?.[item.key]}
                      formValueSetter={formValueSetter}
                      // multiValueSelectionHandler={multiValueSelectionHandler}
                      {...commonProps}
                    />
                    {/* <ShowFilterItems list={formInput?.equipments} removeListItem={removeListItem} itemKey={item.key} /> */}
                  </div>
                );
              }
              if (item.type === INPUT_TYPES.RADIO) {
                return (
                  <div className={disabled ? 'disable' : ''}>
                    <RadioTypeComponent
                      item={item}
                      fieldClassName='none'
                      value={formInput?.[item.key]} // values tobe shown
                      handleChange={formValueSetter}
                      {...commonProps}
                    />
                  </div>
                );
              }
              if (item.type === INPUT_TYPES.TEXT) {
                return (
                  <div className={disabled ? 'disable' : ''}>
                    <KeyInputLayoutView
                      fieldClassName='none'
                      label={item.label}
                      className='add_equipment'
                      width='50rem'
                    >
                      <TextTypeComponent
                        item={item}
                        value={item?.[item.key]} // form inp value
                        textInputHandler={textInputHandler}
                        {...commonProps}
                      />
                    </KeyInputLayoutView>
                  </div>
                );
              }
              if (item.type === INPUT_TYPES.DROPDOWN) {
                return (
                  <div className={disabled ? 'disable' : ''}>
                    <KeyInputLayoutView
                      fieldClassName='none'
                      label={item.label}
                      className='add_equipment'
                      width='50rem'
                      isRequired={item.isRequired}
                      showTooltip={false}
                    >
                      <DropdownTypeComponent
                        showSelection={true}
                        isActiveChecker={(activeItem, item) =>
                          activeItem === item.label}
                        item={item}
                        handleClick={handleType}
                        value={formInput?.[item.key]}
                        itemKey={item.key}
                        {...commonProps}
                      />
                    </KeyInputLayoutView>
                  </div>
                );
              }
              return null;
            })}
        </Container>
        {(formStructureError || error) && (
          <Toast
            header='Error'
            className='fixed right'
            message={formStructureError || error}
            fn={clearError}
          />
        )}
      </div>
      {showFooter && (
        <WidgetFooter
          hideModal={hideModal}
          handleSave={handleSave}
          enableSave={isSaveEnabled}
        />
      )}
    </>
  );
};
export default forwardRef(AddWidgetFormTree);
