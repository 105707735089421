/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  LinearGauge,
  Scale,
  RangeContainer,
  Range,
  ValueIndicator,
  Label,
  Width,
  Size,
  Tooltip
} from 'devextreme-react/linear-gauge';
import { FlexContainer } from '../../../../globalStyles';
import { RatingSpan, Span, TextDiv, TextBox } from './Gauge.style';
import useMeasure from '../../../../shared/hooks/useMeasure';
import './style.css';

const GaugeChart = ({ data, metaNode }) => {
  const { stats, labelMapping, colorMapping } = metaNode || {};
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  // console.log(data, 'containerWidth');
  return (
    <>
      {(stats || [])?.map((stat) => {
        const { label, values, total } = stat;
        const resValues = values
          .reduce((acc, value) => {
            const val = Number(data?.[value]?.replaceAll(',', ''));
            return [...acc, { key: value, value: Number.isNaN(val) ? '-' : val }];
          }, [])
          ?.sort((a, b) => b.value - a.value);
        const totalValue = Number(data?.[total]?.replaceAll(',', ''));
        return (
          <div
            style={{
              width: '96%'
            }}
            ref={containerRef}
          >
            <Span className='label'>{label}</Span>
            <div
              className='tooltip'
              style={{
                position: 'relative',
                height: '50px'
              }}
            >
              {resValues?.map((ele, index) => {
                const { key: val, value } = ele;
                const len = resValues.length;
                return (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0rem',
                      left: '1rem'
                    }}
                  >
                    <LinearGauge>
                      <Tooltip enabled={true} />
                      <Size height={50} width={containerWidth} />
                      <Scale startValue={0} endValue={totalValue}>
                        <Label visible={false} />
                      </Scale>
                      <RangeContainer>
                        <Width end={(len - index) * 10} start={(len - index) * 10} />
                        <Range startValue={0} endValue={value} color={data[colorMapping?.[val]]} />;
                      </RangeContainer>
                      <ValueIndicator visible={false} />
                    </LinearGauge>
                  </div>
                );
              })}
              {/* tooltip */}
              <span className='tooltiptext'>
                {resValues.reverse()?.map((ele) => {
                  const { key: item, value } = ele;
                  return (
                    <TextDiv
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '0.4rem 1rem'
                      }}
                    >
                      <div
                        style={{
                          background: data[colorMapping?.[item]],
                          width: '20px',
                          height: '10px'
                        }}
                      />
                      <Span> {labelMapping?.[item]} </Span>
                      <RatingSpan className='hit_rate'>{value}</RatingSpan>
                    </TextDiv>
                  );
                })}
              </span>
            </div>
            {/* legend */}
            <FlexContainer className='jc_spacearound' style={{ position: 'relative', top: '1rem' }}>
              {resValues.reverse()?.map((ele) => {
                const { key: item, value } = ele;
                return (
                  <TextDiv
                    style={{
                      color: data[colorMapping?.[item]]
                    }}
                  >
                    <Span> {labelMapping?.[item]} </Span>
                    <RatingSpan className='hit_rate'>{data?.[item]}</RatingSpan>
                  </TextDiv>
                );
              })}
            </FlexContainer>
          </div>
        );
      })}
    </>
  );
};

export default GaugeChart;
