import React from 'react';
import { Container } from '../../Common/WidgetCommons.style';

const FloorPlan = (props) => {
  const { componentData, id } = props;
  const { widgetName, infoText } = componentData || {};
  return (
    <Container>
      <span>{`${id}: ${widgetName}`}</span>
    </Container>
  );
};
export default FloorPlan;
