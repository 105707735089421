/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { WebService } from '../../../../services/axios/webServices';
import { useActions } from '../../../../store/hooks/useActions';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
// import { WidgetCreators } from '../../../../views/Widgets/store';
import ConfirmationModal from '../../../Common/ConfirmationBox/ConfirmationBox.view';

const ClearTabConfModal = (props) => {
  const { showDeleteConfModal, setShowDeleteConfModal } = props;
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const tabList = useSelector((state) => state.widgets.tabList);
  const { getWidgetsLayout, resetWidgetsLayout, resetWidgetPanelPayload, widgetDetailSetter, setSelectedKpvs, setKpvConfigured } = useActions();
  const closeDeleteConfModal = () => {
    setShowDeleteConfModal(false);
  };

  const refreshTab = () => {
    resetWidgetPanelPayload();
    widgetDetailSetter({
      key: 'isHideHeader',
      value: {}
    });
    widgetDetailSetter({
      key: 'isHideBorder',
      value: {}
    });
    widgetDetailSetter({
      key: 'zoomLevel',
      value: {}
    });
    widgetDetailSetter({
      key: 'isTemplateComponentUpdateReq',
      value: true
    });
    widgetDetailSetter({ key: 'shouldRefreshPage', value: true });
  };

  const setErr = (val) => {
    widgetDetailSetter({
      key: 'layoutError',
      value: val
    });
  };

  const handleDelete = async () => {
    // dispatch(WidgetCreators.setWidgetActiveTab({ ...activeTab }));
    // dispatch(WidgetCreators.deleteTab({ activeTab }));
    setButtonLoading(true);

    try {
      const fullUrl = apiEndPoints.clearWidgetPanel;

      const res = await WebService.post(
        fullUrl,
        {},
        {
          params: {
            WidgetPanelId: activeTab.id
          }
        }
      );
      if (res.status >= 200 && res.status < 300) {
        resetWidgetsLayout();
        refreshTab();
        setSelectedKpvs({ selectedKpvs: [] });
        setKpvConfigured({ isKpvConfigured: false });
        setButtonLoading(false);
      } else {
        throw res;
      }
    } catch (e) {
      const payload = sagaCatchBlockHandling(e);
      console.log('deletion error', payload);
      setErr(payload.error);
    }
    closeDeleteConfModal();
  };

  return (
    <ConfirmationModal
      Header='Clear Tab'
      action='Clear'
      showModal={showDeleteConfModal}
      handleDelete={handleDelete}
      closeModalHandler={closeDeleteConfModal}
      message='Do you really want to clear the tab?'
      buttonLoading={buttonLoading}
    />
  );
};

export default ClearTabConfModal;
