import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  genericKPVPropertySetter: ['payload'],
  setAnnotations: ['payload'],
  setMaxAxesWidth: ['payload'],
  setPaneType: ['payload'],
  operateDomainRange: ['payload'],
  setDomainRange: ['payload'],
  setDomainRangeInFullScreen: ['payload'],
  setPaneData: ['payload'],
  setPanesShortHandInfo: ['payload'],
  setMachinesShortlisted: ['payload'],
  editCompletePaneData: ['payload'],
  editPaneData: ['payload'],
  editSetOfPaneDataProps: ['payload'],
  editPropertyofPanes: ['payload'],
  setPointInfoforPanes: ['payload'],
  removePane: ['payload'],
  setPaneFullScreen: ['payload'],
  exitPaneFullScreen: ['payload'],
  setSelectedPaneNo: ['payload'],
  toggleCompressedView: ['payload'],
  resetPanesData: ['payload'],

  setAssetAdditionalInfo: ['payload'],
  resetAssetAdditionalInfo: ['payload'],

  getPlotData: ['payload', 'isUpdate', 'index', 'currentPaneInfoObj', 'additionalInfo'], // TODO: change to single paramater and make as object
  getPlotDataStart: ['payload'],
  getPlotDataSuccess: ['payload'],
  addPaneDetailsSuccess: ['payload'],
  setGraphFilterDetails: ['payload'],
  setFilterFilesSelected: ['payload'],
  getPlotDataFailure: ['payload'],
  setChartType: ['payload'],
  setPanePlotData: ['payload'],
  setColorCodes: ['payload'],
  setPaneOptions: ['payload'],
  setPaneOptionsStart: ['payload'],
  setPaneOptionsSuccess: ['payload'],
  setPaneOptionsFailure: ['payload'],
  setHoveredXValue: ['payload'],
  setChartDetails: ['payload'],
  setChartTypes: ['payload'],

  setMaxCharLength: ['payload']
});

export { Types, Creators };
