import { CHART_TYPE_CATEGORY, WIDGET_CATEGORIES, WIDGET_TYPES } from '../../../constants';

//  TODO_W_R: handle through widget category
export const setWidgetDimensions = (widgetCategory, additionalInfo = {}) => {
  // console.log('setWidgetDimensions widgetCategory', widgetCategory);
  if (widgetCategory === WIDGET_CATEGORIES.OEE_DONUT) {
    return { w: 5, h: 12 };
  }
  if (widgetCategory === WIDGET_CATEGORIES.ANALYTICS) {
    const { chartCategory } = additionalInfo;
    if (chartCategory === CHART_TYPE_CATEGORY.SPC) {
      return { w: 6, h: 10 };
    }
    if (chartCategory === CHART_TYPE_CATEGORY.TRANSITION_CHART) {
      return { w: 6, h: 6 };
    }
    return { w: 7, h: 6 };
  }
  if (widgetCategory === WIDGET_CATEGORIES.BAR_CHART) {
    return { w: 6, h: 13 };
  }
  return { w: 5, h: 10 };
};
