/* eslint-disable max-len */
import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { WebService } from '../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../store/utility/sagaErrorHandling';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { REPEATED_WIDGETS, SHARED_LAYOUT } from '../../../constants';
import { getInstanceWidgetsAsWidgets } from '../../../component/Widget/utils/getLayoutData';

/**
 *
 * @param {*} param0 Receives payload that has to be sent to api
 * Api returns list of Individual Widget Instances that are user created and Globally shared
 */
export function* getWidgetInstancesListSaga({ payload }) {
  const { page, pageSize, type, searchQueries, UserId, IsHome } = payload;
  try {
    yield put(Creators.getWidgetInstancesListStart());

    const endpoint = `${payload.endpoint}`;
    const payloadForApi = {
      Others: 1,
      PageSize: pageSize,
      Page: page,
      UserId,
      IsHome,
      searchQueries: searchQueries?.searchParams
    };
    const response = yield WebService.post(endpoint, { ...payloadForApi });
    if (response.status >= 200 && response.status < 300) {
      const { data, meta } = response.data;
      yield put(Creators.getWidgetInstancesListSuccess({ meta, instanceData: data || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };
          yield put(Creators.getWidgetInstancesListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getWidgetInstancesListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getWidgetInstancesListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getWidgetInstancesListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        // error: error.message
        error: 'No connection try again Later.'
      };

      yield put(Creators.getWidgetInstancesListFailure(payload));
    }
  }
}

/**
 *
 * @param {*} Receives payload that has to be sent to api
 * Api is used to add list of selected Widget Instances to Home Dashboard
 */
export function* addWidgetToHomeSaga({ payload }) {
  const { widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.addWidgetToHomeStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.addWidgetsFromList}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      onSuccessNavigation();
      yield put(
        Creators.addWidgetToHomeSuccess()
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.addWidgetToHomeFailure(error));
  }
}

/**
 * Receives userId as parameter to pass as payload to the api
 * Api is used to load the Home Dashboard layout
 */
export function* getHomeLayoutSaga({ payload }) {
  try {
    const { UserId } = payload;
    yield put(Creators.getHomeWidgetsLayoutStart());

    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = yield `${apiEndPoints.getHomeLayout}/${UserId}`;
    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      if (data[0]) {
        const { WidgetPanelLayoutData, InstanceWidgets, Widgets, WidgetComponentData, HDId } =
          data[0];
        const InstanceWidgetsModified = getInstanceWidgetsAsWidgets(InstanceWidgets);
        yield put(
          Creators.getHomeWidgetsLayoutSuccess({
            // shared layout
            layout: WidgetPanelLayoutData[SHARED_LAYOUT],
            layoutComponentData: [...Widgets, ...InstanceWidgetsModified],
            InstanceWidgets,
            repeatedWidgets: WidgetPanelLayoutData[REPEATED_WIDGETS],
            HDId
          })
        );
      } else {
        // deleted before

      }
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error, e);
    yield put(Creators.getHomeWidgetsLayoutError(error));
  }
}

export function* HomeLayoutUpdateSaga({ payload }) {
  const { widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.HomeLayoutUpdateStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.homeLayoutUpdate}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
      yield put(
        Creators.HomeLayoutUpdateSuccess()
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.HomeLayoutUpdateFailure(error));
  }
}

export function* addWidgetFromPreviewSaga({ payload }) {
  const { widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.addWidgetFromPreviewStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.addToHomeFromPreview}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
      yield put(
        Creators.addWidgetFromPreviewSuccess()
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.addWidgetFromPreviewFailure(error));
  }
}

export function* deleteWidgetFromHomeSaga({ payload }) {
  const { widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.deleteWidgetFromHomeStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.deleteWidgetFromHome}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      onSuccessNavigation();
      yield put(
        Creators.deleteWidgetFromHomeSuccess()
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.deleteWidgetFromHomeFailure(error));
  }
}

export function* HomeAllWidgetsUpdateSaga({ payload }) {
  const { widgetPayload, onSuccessNavigation } = payload;
  try {
    yield put(Creators.HomeAllWidgetsUpdateStart());
    // temporary until api is ready
    // change to widget layout api params: userId, tabId
    const fullURL = `${apiEndPoints.UpdateUILayout}`;
    const response = yield WebService.post(fullURL, widgetPayload);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      if (onSuccessNavigation) {
        onSuccessNavigation();
      }
      yield put(
        Creators.HomeAllWidgetsUpdateSuccess()
      );
    } else {
      throw response;
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    console.log('erro', error);
    yield put(Creators.HomeAllWidgetsUpdateFailure(error));
  }
}
