import styled from 'styled-components';
import { device, fontFamily } from '../../../constants';

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

export const HeaderLeft = styled.div`
color: ${({ theme }) => theme.contrast.primary};
font-size: 2.6rem;
font-family: ${fontFamily.circularBold};
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const HeaderRight = styled.div`
display: flex;
align-items: center;
gap:10px;
`;

export const UserInstruction = styled.div`
color: ${({ theme }) => theme.text.gray_2};
font-size: 1.6rem;
font-family: ${fontFamily.circularMedium};
`;
