/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CollapsableContainer from '../CollapsableContainer/CollapsableContainer.view';
import { ItemContainer, Radio, RadioIcon } from '../ScaleYAxis/ScaleYAxis.style';
import { Creators as UpsertPaneCreators } from '../Store';

const ChartTypeView = ({ header, ChartTypeView }) => {
  const { chartTypeSub } = useSelector((state) => state.upsertPaneData);
  const dispatch = useDispatch();

  const handleScaleType = (value) => {
    dispatch(
      UpsertPaneCreators.setUpsertPaneData({
        key: 'chartTypeSub',
        value
      })
    );
  };
  return (
    <CollapsableContainer header={header}>
      <>
        {ChartTypeView.map((ele, index) => {
          const { label, value } = ele;
          return (
            <ItemContainer key={index}>
              <Radio className='pointer' onClick={() => handleScaleType(value)}>
                {chartTypeSub === value && <RadioIcon />}
              </Radio>
              {label}
            </ItemContainer>
          );
        })}
      </>
    </CollapsableContainer>
  );
};

export default ChartTypeView;
