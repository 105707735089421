/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CHART_TYPES, CHART_TYPE_CATEGORY } from '../../../constants';
import Loader from '../../Common/Loader/Loader';
import EditOptionsContainer from '../EditOptions/EditOptions.view';
import { Creators as kpvChartingCreators } from '../Store/action';
import {
  getPaddingForAlignment,
  getSpaceOccupiedByMultipleVerticalAxes
} from '../utils/getPaddingForAlignment';
import {
  ComparisionOptionsContainer,
  Container,
  Header,
  HeaderTitle,
  FilterContainer,
  DropDownBox,
  DropDownButton,
  DropDownContainer,
  BtnText,
  FilterDropContainer,
  CheckBoxContainer,
  FileNameInfo,
  InfoHeader,
  FileNameDiv
} from './PaneCards.style';
import DataGraphComponent from '../Chart/DataChart/DataGraphWrapper';
import KPVControllerIcon from '../KPVCharting/KPVControllerIcon';
import { BackArrow } from '../../../assets/icons/KPV';
import { DateTimePicker } from '../../RangeSelectors/DateTimePicker';
import { getPlotDataPayload } from '../utils/getPlotDataPayload';
import { updateTime } from '../../../shared/utility/moment';
import { graphFormat } from '../../../config/index';
import TransitionGraphComponent from '../Chart/TransitionChart/TransitionChartWrapper';
import SPCGraphWrapper from '../Chart/SPCChart/SPCGraphWrapper';
import PaneGraph from './PaneGraph';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import filterIcon from '../../../assets/icons/Filter/Filters.svg';
import FilterPopUp from './AdditionalFilter/FilterPopUp.view';
import { FileInfoIcon, info } from '../../../assets/icons';
import {
  DropdownImage,
  ItemText
} from '../../ResultView/PlotOptionsModal/PlotOptionsModal.style';
import { isEmpty } from '../../../shared/utility/isEmpty';
import CheckBox from '../../CheckBox/CheckBox';
import useClickOutside from '../../../shared/hooks/useClickOutside';

const NormalPaneCards = () => {
  const {
    panesData: panesPlotData,
    panesShortHandInfo,
    compressedView,
    loading,
    maxAxesWidth,
    comparisionMode,
    isAligned,
    colorCodes
  } = useSelector((state) => state.KPVCharts);

  const ref = useRef();
  const filterRef = useRef();

  const [panesData, setPanesData] = useState([]);
  const [showAdditionalFilter, setShowAdditionalFilter] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [assetShortlist, setAssetShortlist] = useState([]);
  const localeStrings = useSelector(
    (state) => state.localeStrings.localeStrings
  );
  const showSideNav = useSelector((state) => state.home.showSideNav);
  const hoveredXValue = useSelector((state) => state.KPVCharts.hoveredXValue);
  const chartOptions = useSelector((state) => state.KPVCharts.chartOptions);
  const showFilter = useSelector((state) => state.KPVCharts.showAdditionalFilter);
  const assetAdditionalInfo = useSelector((state) => state.KPVCharts.assetAdditionalInfo);
  const [showFileName, setShowFileName] = useState({});
  const [fileInfoData, setFileInfoData] = useState({});
  // const globalHoveredState = useSelector((state) => state.KPVCharts.globalHoveredState);

  // combine panesPlotData and panesShortHandInfo

  const xMaxDomainForFiltered = useSelector((state) => state.KPVCharts.xMaxDomainForFiltered);
  const xMinDomainForFiltered = useSelector((state) => state.KPVCharts.xMinDomainForFiltered);

  useEffect(() => {
    setPanesData(
      panesShortHandInfo.map((e, index) => ({
        ...e,
        ...panesPlotData[index]
      }))
    );
  }, [panesPlotData, panesShortHandInfo]);

  const dispatch = useDispatch();

  useEffect(() => {
    let max = 0;
    panesData.forEach((paneData) => {
      const { multiAxis, multiGrid, rawPlotData } = paneData;
      const { meta } = rawPlotData || {};
      if (multiAxis && !multiGrid) {
        const axisWidth = meta && getSpaceOccupiedByMultipleVerticalAxes(meta);
        max = max >= axisWidth ? max : axisWidth;
      }
    });
    if (maxAxesWidth !== max) {
      dispatch(kpvChartingCreators.setMaxAxesWidth(max));
    }
  }, [panesData]);

  useClickOutside(ref, () => {
    setShowDrop(false);
  });

  const handleInp = (value, rest) => {
    // console.log('value', value, rest);
    const { label, index } = rest;
    dispatch(kpvChartingCreators.editPaneData({ index, key: label, value }));
  };

  const handleDomainChange = (index, startTime, endTime, operation) => {
    const formatedStartTime = moment(startTime, graphFormat);
    const formatedEndTime = moment(endTime, graphFormat);
    const diff = 1.0 * formatedEndTime.diff(formatedStartTime, 'seconds');
    const updatedStartTime = updateTime(
      formatedStartTime,
      operation,
      diff,
      'seconds'
    );
    const updatedEndTime = updateTime(
      formatedEndTime,
      operation,
      diff,
      'seconds'
    );
    const machinesWithNoPlotData = getPlotDataPayload(
      [panesShortHandInfo[index]],
      updatedStartTime,
      updatedEndTime
    );
    dispatch(
      kpvChartingCreators.getPlotData(
        machinesWithNoPlotData,
        true,
        index,
        panesData[index]
      )
    );
  };

  const ComparisionOptions = (
    lotNo,
    isAligned,
    index,
    startTime,
    endTime,
    compStartTime
  ) => (
    <ComparisionOptionsContainer>
      {/* className={isAligned ? "disable" : ""}  */}
      {/* <Input
        width="10%"
        index={index}
        label="lotNo"
        value={lotNo}
        setValue={handleInp}
        disabled={isAligned}
        style={{ marginRight: "10px" }}
        placeholder="Enter Lot No."
      /> */}
      <KPVControllerIcon
        style={{ width: '4.4rem', height: '4.4rem', background: '#EEEFF5 0% 0% no-repeat padding-box', boxShadow: '-5px -5px 15px #FFFFFFCB', border: '1px solid #B4B4B4' }}
        classDefined={isAligned ? 'disable' : ''}
        iconUrl={BackArrow}
        onClick={() => handleDomainChange(index, startTime, endTime, 'back')}
      />
      <KPVControllerIcon
        style={{ width: '4.4rem', height: '4.4rem', background: '#EEEFF5 0% 0% no-repeat padding-box', boxShadow: '-5px -5px 15px #FFFFFFCB', border: '1px solid #B4B4B4', marginRight: '0' }}
        classDefined={isAligned ? 'disable' : ''}
        iconUrl={BackArrow}
        onClick={() => handleDomainChange(index, startTime, endTime, 'forward')}
        rotate={180}
      />
      <DateTimePicker
        // more width for input when sideNav is acitve
        width={showSideNav ? '22%' : '20%'}
        value={compStartTime}
        setValue={handleInp}
        index={index}
        label='compStartTime'
        disabled={isAligned}
        placeholder='Enter Start time'
      />
      {showFilter[index] === 'ON' && comparisionMode && (
        <FilterContainer
          onClick={() => {
            setAssetShortlist([]);
            setShowAdditionalFilter(true);
            const chartId = panesData[index]?.id;
            setShowFileName({ ...showFileName, [chartId]: false });
            filterRef.current = index;
          }}
        >

          <DynamicSVGIcon iconUrl={filterIcon} width='2rem' />
        </FilterContainer>
      )}
      {showFilter[index] === 'ON' && comparisionMode && (
        <FilterContainer
          style={{ position: 'relative' }}
          className={isEmpty(Object.values(assetAdditionalInfo)?.filter((addInfo) => addInfo.id === panesData[index]?.id)[0]?.assetShortlist) ? 'disabled' : ''}
          onClick={() => {
            const chartId = panesData[index]?.id;
            const assetInfoMapped = Object.values(assetAdditionalInfo)?.filter((addInfo) => addInfo.id === chartId);
            setShowFileName({ ...showFileName, [chartId]: !showFileName[chartId] });
            setFileInfoData({ ...fileInfoData, [chartId]: assetInfoMapped[0] });
          }}
        >

          <DynamicSVGIcon iconUrl={info} width='2rem' />
        </FilterContainer>
      )}
    </ComparisionOptionsContainer>
  );

  const handleShortList = (e, isCheckRequired = true) => {
    const found = assetShortlist.some((el) => el.Key === e.Key);
    if (isCheckRequired) {
      if (found) {
        const index = assetShortlist.findIndex((ele) => ele.Key === e.Key);
        assetShortlist.splice(index, 1);
        setAssetShortlist([...assetShortlist]);
      } else {
        setAssetShortlist([...assetShortlist, e]);
      }
    } else {
      setAssetShortlist([...e]);
    }
  };

  const [isLabel, setIsLabel] = useState(false);

  useEffect(() => {
    panesData?.map((pane) => {
      const { rawPlotData } = pane || {};
      const { data } = rawPlotData || {};
      if (data?.every((d) => d?.SensorData === null)) {
        setIsLabel(true);
      }
    });
  }, [panesData]);

  return (
    <div>
      {loading && <Loader />}
      {panesData?.map((paneData, index) => {
        const {
          id,
          paneTitle,
          rawPlotData,
          multiAxis = false,
          multiGrid = false,
          lotNo,
          StartTime,
          EndTime,
          compStartTime,
          pointInfoObj,
          labelsData
        } = paneData;
        const { meta } = rawPlotData || {};

        // const padding = meta
        //   ? getPaddingForAlignment(meta, maxAxesWidth, multiAxis, multiGrid)
        //   : 0;

        // Changed above padding for the allignment of axis during label and no label scenario in comparison mode
        const padding = panesData?.length > 1 && isLabel ? 0 : meta
          ? getPaddingForAlignment(meta, maxAxesWidth, multiAxis, multiGrid)
          : 0;

        const chartTypeCategory =
          chartOptions?.[paneData.chartType]?.chartTypeCategory;
        return (
          <Container
            id={id}
            key={id || index}
            className={compressedView ? '' : 'normalView'}
          >
            {/* edit menu & comparision options */}
            {!compressedView && (
              <>
                <EditOptionsContainer
                  paneData={{ ...paneData, paneNo: index }}
                />

                {paneTitle ? (
                  <Header>{paneTitle}</Header>
                ) : (
                  // eslint-disable-next-line prefer-template
                  <Header>
                    <HeaderTitle>
                      {(localeStrings.Pane || 'Pane') +
                        (localeStrings.Title || 'Title')}
                    </HeaderTitle>
                  </Header>
                )}
                {/* panesPlotData has additionalFilter key */}

                {comparisionMode &&
                  ComparisionOptions(
                    lotNo,
                    isAligned,
                    index,
                    StartTime,
                    EndTime,
                    compStartTime
                  )}
              </>
            )}
            {/* compressedView mode common line */}
            {compressedView && (
              <div
                style={{
                  position: 'absolute',
                  left: hoveredXValue?.x + 10,
                  height: '100%',
                  width: '3px',
                  zIndex: '100',
                  background: 'black'
                }}
              />
            )}
            {/* comparsion mode lines when aligned */}
            {/* {comparisionMode && isAligned && (
              <div
                style={{
                  position: 'absolute',
                  left: globalHoveredState?.left + 10,
                  height: '70%',
                  width: '3px',
                  zIndex: '10',
                  background: '#cbcbcf'
                }}
              >
                <div style={{ position: 'absolute', bottom: -10, background: 'green' }}>checkx</div>
              </div>
            )} */}

            {/* display respective graph based on type */}
            <PaneGraph
              isLastPane={panesData.length - 1 === index}
              chartTypeCategory={chartTypeCategory}
              padding={padding}
              index={index}
              pointInfoObj={pointInfoObj}
              paneData={paneData}
              labelsData={labelsData}
              colorCodes={colorCodes}
              xMinDomainForFiltered={xMinDomainForFiltered[index]}
              xMaxDomainForFiltered={xMaxDomainForFiltered[index]}
            />
            {showFileName[panesData[index]?.id] &&
             (
             <FileNameInfo>
               <InfoHeader>{fileInfoData?.[panesData[index]?.id]?.selectedCategory?.label}</InfoHeader>
               {fileInfoData?.[panesData[index]?.id]?.assetShortlist?.map((file) => (
                 <div style={{ display: 'flex' }}>
                   <DynamicSVGIcon iconUrl={FileInfoIcon} width='1.5rem' />
                   <FileNameDiv>{file[fileInfoData?.[panesData[index]?.id]?.selectedCategory?.label]}</FileNameDiv>
                 </div>
               ))}
             </FileNameInfo>
             )}
          </Container>
        );
      })}
      {showAdditionalFilter && (
        <FilterPopUp
          assetShortlist={assetShortlist}
          setAssetShortlist={setAssetShortlist}
          setShowChartOpts={setShowAdditionalFilter}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          index={filterRef.current}
          chartId={panesData[filterRef.current]?.id}
        />
      )}
    </div>
  );
};

export default NormalPaneCards;
