/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import classnames from 'classnames';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  addDays,
  startOfQuarter,
  endOfQuarter
} from 'date-fns';
import moment from 'moment';

export function calcFocusDate(currentFocusedDate, props) {
  const { shownDate, date, months, ranges, focusedRange, displayMode } = props;
  // find primary date according the props
  let targetInterval;
  if (displayMode === 'dateRange') {
    const range = ranges[focusedRange[0]] || {};
    targetInterval = {
      start: range.startDate,
      end: range.endDate
    };
  } else {
    targetInterval = {
      start: date,
      end: date
    };
  }
  targetInterval.start = startOfMonth(targetInterval.start || new Date());
  targetInterval.end = endOfMonth(targetInterval.end || targetInterval.start);
  const targetDate =
    targetInterval.start || targetInterval.end || shownDate || new Date();

  // initial focus
  if (!currentFocusedDate) return shownDate || targetDate;

  // // just return targetDate for native scrolled calendars
  // if (props.scroll.enabled) return targetDate;
  if (
    differenceInCalendarMonths(targetInterval.start, targetInterval.end) >
    months
  ) {
    // don't change focused if new selection in view area
    return currentFocusedDate;
  }
  return targetDate;
}

export function findNextRangeIndex(ranges, currentRangeIndex = -1) {
  const nextIndex = ranges.findIndex(
    (range, i) =>
      i > currentRangeIndex && range.autoFocus !== false && !range.disabled
  );
  if (nextIndex !== -1) return nextIndex;
  return ranges.findIndex(
    (range) => range.autoFocus !== false && !range.disabled
  );
}

export function getMonthDisplayRange(date, dateOptions, fixedHeight) {
  const startDateOfMonth = startOfMonth(date, dateOptions);
  const endDateOfMonth = endOfMonth(date, dateOptions);
  const startDateOfCalendar = startOfWeek(startDateOfMonth, dateOptions);
  let endDateOfCalendar = endOfWeek(endDateOfMonth, dateOptions);
  if (
    fixedHeight &&
    differenceInCalendarDays(endDateOfCalendar, startDateOfCalendar) <= 34
  ) {
    endDateOfCalendar = addDays(endDateOfCalendar, 7);
  }
  return {
    start: startDateOfCalendar,
    end: endDateOfCalendar,
    startDateOfMonth,
    endDateOfMonth
  };
}

export function generateStyles(sources) {
  if (!sources.length) return {};
  const generatedStyles = sources
    .filter((source) => Boolean(source))
    .reduce((styles, styleSource) => {
      Object.keys(styleSource).forEach((key) => {
        styles[key] = classnames(styles[key], styleSource[key]);
      });
      return styles;
    }, {});
  return generatedStyles;
}

export function thisWeek(weekStartsOn) {
  if (moment().day() >= weekStartsOn) {
    return {
      startDate: new Date(moment().startOf('week').add(weekStartsOn, 'days')),
      endDate: new Date(moment().endOf('week').add(weekStartsOn, 'days'))
    };
  }
  return {
    startDate: new Date(
      moment()
        .subtract(weekStartsOn, 'days')
        .startOf('week')
        .add(weekStartsOn, 'days')
    ),
    endDate: new Date(
      moment()
        .subtract(weekStartsOn, 'days')
        .endOf('week')
        .add(weekStartsOn, 'days')
    )
  };
}

// this selects this quarter if thisQuarter = true
// else it will select the last quarter
export const thisQuarter = (quarterStartsOn, thisQuarter = true) => {
  const todaysYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  let start = quarterStartsOn;
  while (!(start <= currentMonth && currentMonth <= start + 2)) {
    start += 3;
    start %= 12;
  }
  start = thisQuarter ? start : start - 3;
  const end = start + 2;

  const obj = {
    startDate: new Date(
      moment(startOfQuarter(new Date()))
        .month(start % 12)
        .year(todaysYear + Math.floor(start / 12))
    ),
    endDate: new Date(
      moment(endOfQuarter(new Date()))
        .month(end % 12)
        .endOf('month')
        .year(todaysYear + Math.floor(end / 12))
    )
  };
  return obj;
};

export const getWeekNoInit = (dateOfWeekStart, dateOptions) => {
  if (dateOfWeekStart) {
    const dateObjOfWeekStart = moment(dateOfWeekStart, 'DD-MM');
    return moment(
      startOfWeek(new Date(dateObjOfWeekStart), dateOptions)
    ).week();
  }
  return 1;
};

// weekNoInit is revised week (which is used as week1)
export const getWeekno = (
  day,
  weekNoInit,
  dateOfWeekStart // Expected in "DD-MM" format
) => {
  let selectedYear = moment(day).year();
  let referenceDate = moment(
    `${dateOfWeekStart}-${selectedYear}`,
    'DD-MM-YYYY'
  );

  // Ensure referenceDate is set to the first occurrence of the day in the week for the given year
  if (referenceDate.day() !== moment(day).day()) {
    referenceDate.add(
      (7 - referenceDate.day() + moment(day).day()) % 7,
      'days'
    );
  }

  // Calculate the week number based on the reference date
  let weekNo = moment(day).diff(referenceDate, 'weeks') + 1;

  // Adjust for cases where the day is before the reference date
  if (weekNo < 1) {
    // Get the reference date for the previous year
    referenceDate = moment(
      `${dateOfWeekStart}-${selectedYear - 1}`,
      'DD-MM-YYYY'
    );
    if (referenceDate.day() !== moment(day).day()) {
      referenceDate.add(
        (7 - referenceDate.day() + moment(day).day()) % 7,
        'days'
      );
    }
    weekNo = moment(day).diff(referenceDate, 'weeks') + 1;
    selectedYear -= 1; // Adjust the year since we are counting from the previous year
  }

  return { weekNo, year: selectedYear };
};

// // weekNoInit is revised week (which is used as week1)
// export const getWeekno = (
//   day,
//   weekNoInit,
//   dateOfWeekStart,
//   currentMonthStartDate,
//   useGivenDateForRevenseCalculation = false
// ) => {
//   if (useGivenDateForRevenseCalculation && dateOfWeekStart) {
//     let currentYear = moment().format('YYYY');
//     console.log('currentYear', currentYear);
//     let weekNumberOfMonth = 0;
//     if (
//       moment().format('MM') <= moment(dateOfWeekStart, 'DD-MM').format('MM')
//     ) {
//       if (
//         moment().format('MM') === moment(dateOfWeekStart, 'DD-MM').format('MM')
//       ) {
//         if (
//           moment().format('DD') < moment(dateOfWeekStart, 'DD-MM').format('DD')
//         ) {
//           currentYear -= 1;
//         }
//       } else {
//         currentYear -= 1;
//       }
//     }
//     console.log('currentYear', currentYear);
//     const givenDateMomentObject = moment(
//       `${dateOfWeekStart}-${currentYear}`,
//       'DD-MM-YYYY'
//     );
//     const currentYearGivenDateDayOfCurrentWeek = givenDateMomentObject.week();
//     const dayOfWeekOfGivenDate = givenDateMomentObject.day();
//     const startWeekNumberForGivenDatesInMonth = moment(
//       `${dateOfWeekStart}-${currentYear}`,
//       'DD-MM-YYYY'
//     )
//       .set('date', 1)
//       .week();
//     console.log('day', day);
//     weekNumberOfMonth =
//       currentYearGivenDateDayOfCurrentWeek -
//       startWeekNumberForGivenDatesInMonth;
//     const selectedMonthMomentObject = moment(day);
//     console.log('selectedMonthMomentObject', selectedMonthMomentObject);
//     console.log('dayOfWeekOfGivenDate', dayOfWeekOfGivenDate);
//     console.log('weekNumberOfMonth', weekNumberOfMonth);
//     let selectedYear = selectedMonthMomentObject.year();
//     const formationOfSimilarDateInThatYear = moment(
//       `${givenDateMomentObject.month() + 1}-${selectedYear}`,
//       'M-YYYY'
//     );
//     formationOfSimilarDateInThatYear
//       .add('weeks', weekNumberOfMonth)
//       .day(dayOfWeekOfGivenDate);
//     let returnWeekNo = 1;
//     const currentDateMomentObject = moment(day);
//     // if(formationOfSimilarDateInThatYear.diff())
//     let differenceInDays = currentDateMomentObject.diff(
//       formationOfSimilarDateInThatYear,
//       'days'
//     );
//     console.log('currentDateMomentObject', currentDateMomentObject);
//     console.log(
//       'formationOfSimilarDateInThatYear',
//       formationOfSimilarDateInThatYear
//     );
//     console.log('differenceInDays', differenceInDays);
//     if (differenceInDays < 0) {
//       //
//       selectedYear -= 1;
//       const formationOfSimilarDateInThatAnotherYear = moment(
//         `${givenDateMomentObject.month() + 1}-${selectedYear}`,
//         'M-YYYY'
//       );
//       formationOfSimilarDateInThatAnotherYear
//         .add('weeks', weekNumberOfMonth)
//         .day(dayOfWeekOfGivenDate);
//       differenceInDays = currentDateMomentObject.diff(
//         formationOfSimilarDateInThatAnotherYear,
//         'days'
//       );
//     }
//     returnWeekNo = Math.floor(differenceInDays / 7) + 1;
//     return { weekNo: returnWeekNo, year: selectedYear };
//   }
//   return moment(day).week() - weekNoInit + 1;
// };

export const absoluteWeekNo = ({ weekNo, year }) => ({
  isNextYear: weekNo > 0,
  weekNo: weekNo <= 0 ? 52 + weekNo : weekNo,
  year
});
