/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import moment from 'moment';
import { createReducer } from 'reduxsauce';
// import { headingTitle } from '../Filter.view';
import { Types } from './action';

const INITIAL_STATE = {
  tags: {},
  deletingTag: null,
  cumulativeTags: {},
  error: null,
  loading: false,

  depedentLoading: false,
  iframeLoading: false,
  selectedTabs: {},
  selectedItem: [],
  selectedDate: moment(),
  isDropDownActive: false,
  searchResult: null,
  resultType: 'iframe',
  objectTreeData: null,
  objectTreeCategory: null,
  meta: {},
  showFilterResult: false,
  filterPayload: null,
  iframeRes: null,
  showFilter: false,
  // singleSelectionGroups: [],
  menuIdofFilter: '',
  leafLevels: {},
  selectedParent: null,
  breadCrumbs: [],
  activeBreadCrumb: 0,
  dependencies: [],
  dependents: {},
  keyTitleMapping: {},
  inputs: {},
  keysLatestUpdated: ''
};

const clearTreeData = () => INITIAL_STATE;

const setTagsObj = (state = INITIAL_STATE, action) => ({
  ...state,
  tags: action.payload,
  deletingTags: null
});

const populateObjectTreeTagsObj = (state = INITIAL_STATE, action) => {
  const cummTags = action.payload;
  const groups = Object.keys(cummTags);
  const referenceObj = {};
  groups.forEach((group) => {
    referenceObj[group] = cummTags[group][0];
  });
  return {
    ...state,
    tags: { ...referenceObj },
    cumulativeTags: cummTags
  };
};

const populateObjectTreeSelectedObjs = (state = INITIAL_STATE, action) => {
  const cummSelObjs = action.payload;
  const groups = Object.keys(cummSelObjs);
  const selectionObj = {};
  const cummlativeSelectionObj = {};
  groups.forEach((group) => {
    selectionObj[group] = cummSelObjs[group][0];
    cummlativeSelectionObj[`cumulative${group}`] = cummSelObjs[group];
  });
  return {
    ...state,
    ...selectionObj,
    ...cummlativeSelectionObj
  };
};

const setCummulativeTagsObj = (state = INITIAL_STATE, action) => ({
  ...state,
  cumulativeTags: action.payload
});

const setSingleTagObj = (state = INITIAL_STATE, action) => {
  const { groupTitle } = action.payload;
  return {
    ...state,
    tags: { ...state.tags, [groupTitle]: action.payload[groupTitle] },
    cumulativeTags: {
      ...state.cumulativeTags,
      [groupTitle]: {
        // ...state.cumulativeTags[groupTitle],
        // Object.keys(state.cumulativeTags).length - 1
        0: {
          ...action.payload[groupTitle]
        }
      }
    },
    [groupTitle]: { ...action.payload.selectedObj },
    [`cumulative${groupTitle}`]: { 0: { ...action.payload.selectedObj } }
  };
};

const setSelectedTabs = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedTabs: action.payload
});

const deletingTags = (state = INITIAL_STATE, action) => ({
  ...state,
  deletingTag: action.payload
});

const setSelectedItemObj = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedItem: action.payload
});

const universalObjectTreeSetter = (state = INITIAL_STATE, action) => ({
  ...state,
  [action.payload.key]: action.payload.value,
  keysLatestUpdated: action.payload.key
});

const getObjectTreeDataStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getObjectTreeDataSuccess = (state = INITIAL_STATE, action) => {
  const {
    data,
    meta,
    resultType,
    parent,
    dependencies,
    dependents,
    keyTitleMapping,
    menuId,
    CategoryOptions
  } = action.payload;
  return {
    ...state,
    loading: false,
    objectTreeData: data,
    objectTreeCategory: CategoryOptions,
    meta: meta || INITIAL_STATE.meta,
    resultType,
    selectedParent: parent,
    dependencies: dependencies || INITIAL_STATE.dependencies,
    dependents: dependents || INITIAL_STATE.dependents,
    keyTitleMapping,
    menuIdofFilter: menuId || state.menuIdofFilter
  };
};

const getObjectTreeDataV3Success = (state = INITIAL_STATE, action) => {
  const {
    data,
    meta,
    resultType,
    parent,
    dependencies,
    dependents,
    keyTitleMapping,
    menuId,
    CategoryOptions,
    placementKey
  } = action.payload;
  const objectTreeData = [...state.objectTreeData];
  objectTreeData.forEach((dataObj) => {
    if (dataObj.inputType === placementKey) {
      //
      // eslint-disable-next-line no-param-reassign
      dataObj.values = data[0].values;
    }
  });
  return {
    ...state,
    loading: false,
    objectTreeData,
    objectTreeCategory: CategoryOptions,
    meta: meta || INITIAL_STATE.meta,
    resultType,
    selectedParent: parent,
    dependencies: dependencies || INITIAL_STATE.dependencies,
    dependents: dependents || INITIAL_STATE.dependents,
    keyTitleMapping,
    menuIdofFilter: menuId || state.menuIdofFilter
  };
};

const getObjectTreeDataFail = (state = INITIAL_STATE, action) => ({
  ...INITIAL_STATE,
  loading: false,
  menuIdofFilter: state.menuIdofFilter,
  error: action.payload.error,
  selectedParent: action.payload.parent
});

const getObjectTreeDependencyDataStart = (state = INITIAL_STATE, action) => ({
  ...state,
  depedentLoading: true,
  dependentOn: action.payload.dependentOn
});

const getObjectTreeDependencyDataSuccess = (state = INITIAL_STATE, action) => {
  const { objectTreeData, resetDependents, meta } = action.payload;
  const nonDependentFilterGroups = state.objectTreeData.filter(
    (e) => objectTreeData.every((inner) => inner.id !== e.id)
    // eslint-disable-next-line function-paren-newline
  );
  return {
    ...state,
    depedentLoading: false,
    objectTreeData: [...nonDependentFilterGroups, ...objectTreeData],
    meta: { ...state.meta, ...meta },
    ...resetDependents,
    dependentOn: ''
  };
};

const getObjectTreeDependencyDataFail = (state = INITIAL_STATE, action) => {
  const dependents = action.payload;
  let dupCumulativeTags = { ...state.cumulativeTags };
  let dupTags = { ...state.tags };
  const objs = dependents
    .map((e) => state.keyTitleMapping[e])
    .reduce((final, curr) => {
      dupCumulativeTags = { ...dupCumulativeTags, [curr]: {} };
      dupTags = { ...dupTags, [curr]: {} };
      return {
        ...final,

        [curr]: {},
        [`cumulative${curr}`]: {}
      };
    }, {});
  return {
    ...state,
    depedentLoading: false,
    dependentOn: '',
    objectTreeData: state.objectTreeData.filter(
      (e) => !dependents.includes(e.keyName)
    ),
    ...objs,
    cumulativeTags: dupCumulativeTags,
    tags: dupTags
  };
};

const UniversalReducerSetterTree = (state = INITIAL_STATE, action) => {
  const {
    activeBreadCrumb,
    breadCrumbs,
    breakpoint,
    cumulativePlant,
    cumulativeTags,
    depedentLoading,
    dependencies,
    dependents,
    error,
    filterPayload,
    iframeLoading,
    iframeRes,
    inputs,
    isDropDownActive,
    keyTitleMapping,
    leafLevels,
    loading,
    menuIdofFilter,
    meta,
    objectTreeData,
    resultType,
    searchResult,
    selectedDate,
    selectedItem,
    selectedTabs,
    showFilter,
    showFilterResult,
    tags
  } = action.payload;
  const objectTreeDataMod = [...state.objectTreeData];
  objectTreeDataMod.forEach((dataObj) => {
    if (dataObj.key === objectTreeData[0].keyName) {
      // eslint-disable-next-line no-param-reassign
      dataObj.values = objectTreeData[0].values;
    }
  });
  return {
    ...state,
    activeBreadCrumb,
    breadCrumbs,
    breakpoint,
    cumulativePlant,
    cumulativeTags,
    depedentLoading,
    dependencies,
    dependents,
    objectTreeData: objectTreeDataMod,
    error,
    filterPayload,
    iframeLoading,
    iframeRes,
    inputs,
    isDropDownActive,
    keyTitleMapping,
    leafLevels,
    loading,
    menuIdofFilter,
    meta,
    resultType,
    searchResult,
    selectedDate,
    selectedItem,
    selectedTabs,
    showFilter,
    showFilterResult,
    tags
  };
};

const PopulateTreeStructure = (state = INITIAL_STATE, action) => {
  const {
    Calendar,
    Plant,
    cumulativeCalendar,
    cumulativePlant,
    cumulativeTags,
    tags,
  } = action.payload;
  return {
    ...state,
    Calendar,
    Plant,
    cumulativeCalendar,
    cumulativePlant,
    cumulativeTags,
    tags,

  };
};

const resetFilterState = () => INITIAL_STATE;

const clearSelectedFilter = (state = INITIAL_STATE) => ({
  // need to fetch again onclear because of dependencies
  ...INITIAL_STATE,
  // filterData: state.filterData,
  showFilter: true,
  selectedParent: state.selectedParent
  // menuIdofFilter: ''
});

const populateObjectTreeData = (state = INITIAL_STATE, action) => ({
  ...INITIAL_STATE,
  ...action.payload
});

const setBulkObjectTreeDetails = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.payload
});

const HANDLERS = {
  [Types.SET_TAGS_OBJ]: setTagsObj,
  [Types.POPULATE_OBJECT_TREE_TAGS_OBJ]: populateObjectTreeTagsObj,
  [Types.POPULATE_OBJECT_TREE_SELECTED_OBJS]: populateObjectTreeSelectedObjs,
  [Types.SET_CUMMULATIVE_TAGS_OBJ]: setCummulativeTagsObj,
  [Types.SET_SINGLE_TAG_OBJ]: setSingleTagObj,
  [Types.SET_SELECTED_TABS]: setSelectedTabs,
  [Types.DELETING_TAGS]: deletingTags,
  [Types.SET_SELECTED_ITEM_OBJ]: setSelectedItemObj,
  [Types.UNIVERSAL_OBJECT_TREE_SETTER]: universalObjectTreeSetter,
  [Types.GET_OBJECT_TREE_DATA_START]: getObjectTreeDataStart,
  [Types.GET_OBJECT_TREE_DATA_SUCCESS]: getObjectTreeDataSuccess,
  [Types.GET_OBJECT_TREE_DATA_V3_SUCCESS]: getObjectTreeDataV3Success,
  [Types.GET_OBJECT_TREE_DATA_FAIL]: getObjectTreeDataFail,
  [Types.GET_OBJECT_TREE_DEPENDENCY_DATA_START]:
    getObjectTreeDependencyDataStart,
  [Types.GET_OBJECT_TREE_DEPENDENCY_DATA_SUCCESS]:
    getObjectTreeDependencyDataSuccess,
  [Types.GET_OBJECT_TREE_DEPENDENCY_DATA_FAIL]: getObjectTreeDependencyDataFail,
  [Types.RESET_FILTER_STATE]: resetFilterState,
  [Types.CLEAR_SELECTED_FILTER]: clearSelectedFilter,
  [Types.POPULATE_OBJECT_TREE_DATA]: populateObjectTreeData,
  [Types.SET_BULK_OBJECT_TREE_DETAILS]: setBulkObjectTreeDetails,
  [Types.UNIVERSAL_REDUCER_SETTER_TREE]: UniversalReducerSetterTree,
  [Types.POPULATE_TREE_STRUCTURE]: PopulateTreeStructure,
  [Types.CLEAR_TREE_DATA]: clearTreeData
};

export const ObjectTreeReducer = createReducer(INITIAL_STATE, HANDLERS);
