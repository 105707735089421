/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { TabButton } from './TabUi.style';

export const TabUi = ({ tabs, currentTab, handleTabClick }) => (
  <>
    {!isEmpty(tabs) &&
      tabs.map((tab, i) => {
        const { id, tabTitle } = tab;
        return (
          <TabButton key={i} id={id} disabled={currentTab === `${id}`} onClick={handleTabClick}>
            {tabTitle}
          </TabButton>
        );
      })}
  </>
);
