/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
// import moment from 'moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../../globalStyles';
import { isEmpty } from '../../../shared/utility/isEmpty';
import { getUnitDateAdder } from '../Common/Header/utlity';
import { MONTHS } from '../MonthRangePicker/utility/constants';
import QuarterPicker from './QuarterPicker/QuarterPicker';

export default function QuarterRangePicker({
  initValue,
  handleSelection,
  quarterStartsOn,
  MaxAllowedRange,
  clearSelection,
  setClearSelection
}) {
  const [isAtStart, setIsAtStart] = useState(1);

  const [hoveredDate, setHoveredDate] = useState('');
  const todaysYear = new Date().getFullYear();
  const todaysMonth = new Date().getMonth();

  const startInit = quarterStartsOn;
  let start = startInit;
  let quarterInit = 1;
  // get the correct quarter month range of the currentMonth
  while (!((start < todaysMonth && todaysMonth < start + 2) || todaysMonth === start || todaysMonth === start + 2)) {
    start = (start + 3) % 12;
    quarterInit += 1;
  }
  const end = start + 2;
  // console.log('sinit', startInit, start, end);
  const startObj = {
    month: start % 12,
    year: todaysYear + Math.floor(start / 12)
  };
  const endObj = {
    month: end % 12,
    year: todaysYear + Math.floor(end / 12)
  };
  const currDate = { start: startObj, end: endObj, quarter: `q${quarterInit} ${todaysYear}` };
  const initObj = {
    start: undefined,
    end: undefined
  };
  const [selectedRange, setSelectedRange] = useState(initObj);
  const [year, setYear] = useState({ start: todaysYear - 1, end: todaysYear });

  const formatObj = (obj) => {
    const { start, end } = obj;
    return `${start?.correspondingYear} - ${end?.correspondingYear}`;
    // return `${MONTHS[obj.start.start.month]} ${obj.start.start.year.toString().substring(2)} - ${
    //   MONTHS[obj.end.end.month]
    // } ${obj.end.end.year.toString().substring(2)}`;
  };

  const formatSelectionObj = (objInit) => {
    let obj = objInit;
    if (!obj.start) {
      obj = { start: currDate, end: currDate };
    }
    return {
      value: formatObj(obj),
      rawValue: obj
    };
  };

  const { unit, value: unitValue } = MaxAllowedRange || {};

  const restictedRange = () => {
    if (!selectedRange.start) {
      return {
        minDate: undefined,
        maxDate: undefined
      };
    }
    const date = new Date(selectedRange.start.start.year, selectedRange.start.start.month, 1);
    if (!unit || !unitValue) {
      return { minDate: undefined, maxDate: undefined };
    }
    const obj = {};
    // set the function based on unit
    const fn = getUnitDateAdder(unit);
    // not allowing backward date selection
    obj.minDate = date;
    // fn(date, -unitValue);
    const rawMaxDate = new Date(moment(fn(date, unitValue)).date(1));
    const startMonth = rawMaxDate.getMonth();
    const startYear = rawMaxDate.getFullYear();
    const endMonth = (startMonth + 2) % 12;
    const endYear = startYear + Math.floor((startMonth + 2) / 12);
    obj.maxDate = {
      start: { month: startMonth, year: startYear },
      end: { month: endMonth, year: endYear }
    };

    return obj;
  };
  // console.log('selectedRange', selectedRange, restictedRange());

  useEffect(() => {
    if (!isEmpty(initValue)) {
      setSelectedRange(initValue);
    } else {
      // not setting to reducer if start is undefined
      if (selectedRange.start) {
        handleSelection(formatSelectionObj(selectedRange));
      }
    }
  }, []);

  useEffect(() => {
    if (clearSelection) {
      setSelectedRange(initObj);
      handleSelection(formatSelectionObj(initObj));
      setClearSelection(false);
      setIsAtStart(1);
    }
  }, [clearSelection]);

  return (
    <FlexContainer style={{ width: '100%' }}>
      {parseInt(unitValue, 10) > 0 && (
        <QuarterPicker
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          isAtStart={isAtStart}
          isSingleSelection={parseInt(unitValue, 10) === 0}
          setIsAtStart={setIsAtStart}
          title='start'
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          year={year}
          setYear={setYear}
          startInit={startInit}
          handleSelection={handleSelection}
          formatSelectionObj={formatSelectionObj}
          maxDate={restictedRange().maxDate}
        />
      )}

      <QuarterPicker
        isAtStart={isAtStart}
        title='end'
        setIsAtStart={setIsAtStart}
        isSingleSelection={parseInt(unitValue, 10) === 0}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        hoveredDate={hoveredDate}
        setHoveredDate={setHoveredDate}
        year={year}
        setYear={setYear}
        startInit={startInit}
        handleSelection={handleSelection}
        formatSelectionObj={formatSelectionObj}
        maxDate={restictedRange().maxDate}
      />
    </FlexContainer>
  );
}
