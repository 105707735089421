/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { utils, read, writeFile } from 'xlsx';
import DragDropFile from './DragDropFile';
import DataInput from './DataInput';
// import OutTable from './OutTable';

function SheetJSApp(props) {
  const { content, file, handleFile } = props;
  const [state, setState] = useState({
    data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
    cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */
  });

  // function exportFile() {
  //   /* convert state to workbook */
  //   const ws = utils.aoa_to_sheet(state.data);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, 'SheetJS');
  //   /* generate xlsx file and send to client */
  //   writeFile(wb, 'sheetjs.xlsx');
  // }

  return (
    <DragDropFile handleFile={handleFile}>
      <div>{content || <DataInput handleFile={handleFile} />}</div>
      {/* <div className='row'>
        <div className='col-xs-12'>
          <button disabled={!state.data.length} className='btn btn-success' onClick={exportFile}>
            Export
          </button>
        </div>
      </div> */}
      {/* <div className='row'>
          <div className='col-xs-12'>
            <OutTable data={this.state.data} cols={this.state.cols} />
          </div>
        </div> */}
    </DragDropFile>
  );
}

export default SheetJSApp;
