import { createActions } from 'reduxsauce';

export const { Creators, Types } = createActions({
  setWidgetsInHomeList: ['payload'],

  getWidgetInstancesList: ['payload'],
  getWidgetInstancesListStart: ['payload'],
  getWidgetInstancesListSuccess: ['payload'],
  getWidgetInstancesListFailure: ['payload'],

  addWidgetToHome: ['payload'],
  addWidgetToHomeStart: ['payload'],
  addWidgetToHomeSuccess: ['payload'],
  addWidgetToHomeFailure: ['payload'],

  getHomeLayout: ['payload'],
  getHomeWidgetsLayoutStart: ['payload'],
  getHomeWidgetsLayoutSuccess: ['payload'],
  getHomeWidgetsLayoutError: ['payload'],

  HomeLayoutUpdate: ['payload'],
  HomeLayoutUpdateStart: ['payload'],
  HomeLayoutUpdateSuccess: ['payload'],
  HomeLayoutUpdateFailure: ['payload'],

  addWidgetFromPreview: ['payload'],
  addWidgetFromPreviewStart: ['payload'],
  addWidgetFromPreviewSuccess: ['payload'],
  addWidgetFromPreviewFailure: ['payload'],

  deleteWidgetFromHome: ['payload'],
  deleteWidgetFromHomeStart: ['payload'],
  deleteWidgetFromHomeSuccess: ['payload'],
  deleteWidgetFromHomeFailure: ['payload'],

  HomeAllWidgetsUpdate: ['payload'],
  HomeAllWidgetsUpdateStart: ['payload'],
  HomeAllWidgetsUpdateSuccess: ['payload'],
  HomeAllWidgetsUpdateFailure: ['payload'],

  setSearchQueries: ['payload'],
  resetInstanceData: ['payload'],

});
