/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddFloorPlanWidget from './AddFloorPlan.view';

const FloorPlanWidget = () => {
  const { statusEquipmentList, statuscategory } = useSelector((state) => state.upsertWidgetData);
  return (
    <div>
      <AddFloorPlanWidget />
    </div>
  );
};

export default FloorPlanWidget;
