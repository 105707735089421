import styled from 'styled-components';
import { fontFamily, fontSize } from '../../constants';

export const Container = styled.div`
padding: 2rem;
`;

export const HeadingText = styled.div`
font-size: 2.4rem;
font-family: ${fontFamily.circularBook};
`;

export const HeaderRight = styled.div`
display: flex;
width:44%;
font-size: 1.6rem;
font-family: ${fontFamily.circularBook};
`;

export const ButtonContainer = styled.div`
display: flex;
gap: 2rem;
width: 40%;
`;

export const NumberOfWidgetsText = styled.div`
font-size: ${fontSize.text};
display: flex;
align-items: center;
padding-bottom: 2rem;
`;

export const Text = styled.div`
font-size: ${fontSize.text};
color:  ${({ theme, color }) => (color || theme.contrast.primary)};

`;

export const Dot = styled.div`
/* font-size: 5rem;

font-weight: 700; */
width: 1rem;
height: 1rem;
background-color:  ${({ theme, color }) => color || theme.contrast.primary};;
border-radius: 50%;
margin-right: 1rem;
`;

export const BodyContainer = styled.div`
font-family: ${fontFamily.circularBook};
color:  ${({ theme }) => theme.contrast.primary};
`;
