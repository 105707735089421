/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';

import {
  FieldChooser,
  PivotGrid,
  Scrolling
} from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { useActions } from '../../store/hooks/useActions';
import { ContainerDiv, SaveAnalyticsContainer } from './PivotTableComponent';
import DynamicSVGIcon from '../Common/DynamicSVGIcon';
import { Kabab } from '../../assets/icons/KPV';
import ButtonComp from '../Common/Button/Button.view';

const temp = [];
const initialDataSource = new PivotGridDataSource({
  fields: [],
  store: temp,
});
const PivotTableComponent = (props) => {
  const pivotGridRef = useRef();
  const { data, onSave, feild, fieldChoose = true, discardConfiguration, metaDetails } = props;
  const [dataSource, setDataSource] = useState(initialDataSource);

  /**
   * Function to Set the summaryType(Count, sum or Avg) on user changing the props
   * @param {*} args
   * @param {*} sourceField
   */
  const setSummaryType = (args, sourceField) => {
    dataSource.field(sourceField.index, {
      summaryType: args.itemData.value,
    });
    dataSource.load();
  };

  /**
   * Function to be passed as Prop to Pivot Grid to show the Ui For changing summary type
   * @param {*} e
   */
  const onContextMenuPreparing = (e) => {
    const sourceField = e.field;
    if (sourceField) {
      if (sourceField.dataType === 'number') {
        const menuItems = [];
        e.items.push({ text: 'Summary Type', items: menuItems });
        ['Sum', 'Avg', 'Count'].forEach((summaryType) => {
          const summaryTypeValue = summaryType.toLowerCase();
          menuItems.push({
            text: summaryType,
            value: summaryType.toLowerCase(),
            onItemClick(args) {
              setSummaryType(args, sourceField);
            },
            selected: e.field.summaryType === summaryTypeValue,
          });
        });
      }
    }
  };

  /**
 *Function which calls the constructor and updates the store
 * @param {*} newStoreData Receives the new Store Data
 */

  const updateStoreData = (feild = [], newStoreData) => {
    const newDataSource = new PivotGridDataSource({
      fields: feild,
      store: newStoreData,
    });
    setDataSource(newDataSource);
  };
  /**
   * UseEffect to Call the function that updates the store data for the Pivot Grid
   */
  useEffect(() => {
    updateStoreData(feild, data);
  }, [feild]);
  /**
 *Function to Update the user Pivot table configuration on Click of Save
 */
  // const discardConfiguration = () => {
  //   setShowPivotTable((prev) => !prev);
  // };
  const isTotalCell = (cell) => (
    cell.type === 'T'
  || cell.type === 'GT'
  || cell.rowType === 'T'
  || cell.rowType === 'GT'
  || cell.columnType === 'T'
  || cell.columnType === 'GT'
  );
  //
  const isDataCell = (cell) => cell.rowType === 'D' && cell.columnType === 'D';

  const isGrandTotalCell = (cell) => {
    if (cell.rowType === 'D' && cell.columnType === 'D' && cell.columnPath.length === 1) {
      return cell.rowType === 'D' && cell.columnType === 'D';
    }

    return (cell.rowType === 'D' && cell.columnType === 'T');
  };

  const getCssStyles = ({ fill, font, bold }) => ({
    'background-color': `#${fill}`,
    color: `#${font}`,
    'font-weight': bold ? 'bold' : undefined,
  });
  //

  // const meta = {
  //   columns: [],
  //   pivotTableEnabled: true,
  //   colorOnlyTotal: true,
  //   configurations: [
  //     { row: ['MachineName'],
  //       column: ['ActualHr', 'ReportLabel'],
  //       conditions: [
  //         { background: 'C6EFCE', limit: 10000 },
  //         { background: 'ffeb9c', limit: 600 },
  //         { background: 'FFC7CE', limit: 200 }
  //       ] }
  //   ]
  // };

  const getConditionalAppearance = (cell) => {
    const listOfColumns = dataSource?._lastLoadOptions?.columns?.map((col) => col.dataField);
    const listOfRows = dataSource?._lastLoadOptions?.rows?.map((row) => row.dataField);
    let appearance;
    metaDetails?.configurations?.forEach((config) => {
      if (JSON.stringify(listOfColumns?.sort()) === JSON.stringify(config.column?.sort()) && JSON.stringify(listOfRows?.sort()) === JSON.stringify(config.row?.sort())) {
        const { value } = cell;

        const conditionConfig = config?.conditions;
        conditionConfig?.map((condition, index) => {
          if (conditionConfig[index + 1]?.limit) {
            if (value <= condition?.limit && value > conditionConfig[index + 1]?.limit) {
              appearance = { fill: condition?.background || 'C6EFCE' };
            }
          } else if (value <= condition?.limit) {
            appearance = { fill: condition?.background || 'C6EFCE' };
          }
        });

        // if (metaDetails?.configurations?.failure?.limit ? value < metaDetails?.configurations?.failure?.limit : value < 200) {
        //   appearance = { font: '9C0006', fill: metaDetails?.configurations?.failure?.background || 'FFC7CE' };
        // } else if (metaDetails?.configurations?.failure?.limit ? (cell?.value >= (metaDetails?.configurations?.failure?.limit) && (cell?.value < metaDetails?.configurations?.success?.limit)) : (cell?.value >= 200 && cell?.value < 600)) {
        //   appearance = { fill: metaDetails?.configurations?.alarm?.background || 'ffeb9c', font: '3F3F3F', bold: true };
        // } else if (value >= 600) {
        //   appearance = { font: '006100', fill: metaDetails?.configurations?.success?.background || 'C6EFCE' };
        // } else {
        //   appearance = { font: 'F2F2F2', fill: 'FFEB9C' };
        // }
      }
    });
    return appearance || { fill: 'F2F2F2' };
  };
  const onCellPrepared = ({ cell, cellElement }) => {
    if (metaDetails?.colorOnlyTotal) {
      if ((isGrandTotalCell(cell)) || isTotalCell(cell)) {
        const appearance = getConditionalAppearance(cell);
        Object.assign(cellElement.style, getCssStyles(appearance));
      }
    } else if ((isDataCell(cell)) || isTotalCell(cell)) {
      const appearance = getConditionalAppearance(cell);
      Object.assign(cellElement.style, getCssStyles(appearance));
    }
  };

  return (
    <>
      <ContainerDiv>
        {!fieldChoose ? (<></>) : (
          <SaveAnalyticsContainer>
            <ButtonComp onClick={() => {
              discardConfiguration(dataSource?._fields);
            }}
            >
              Discard
            </ButtonComp>
            <ButtonComp
              dark
              onClick={() => {
                onSave(dataSource?._fields);
              }}
            >
              Save
            </ButtonComp>
          </SaveAnalyticsContainer>
        )}
        <PivotGrid
          id="pivotgrid"
          dataSource={dataSource}
          allowSortingBySummary={true}
          allowFiltering={false}
          showBorders={true}
          showColumnTotals={true}
          showColumnGrandTotals={true}
          showRowTotals={true}
          showRowGrandTotals={true}
          ref={pivotGridRef}
          sortBySummaryPath
          onContextMenuPreparing={onContextMenuPreparing}
          // width="100%"
          verticalScrollingMode="virtual"
          // Enable horizontal virtual scrolling
          horizontalScrollingMode="virtual"
          onCellPrepared={onCellPrepared}
        >
          <Scrolling mode="virtual" />
          <FieldChooser enabled={fieldChoose} allowSearch={true} />
        </PivotGrid>
      </ContainerDiv>
    </>
  );
};

export default PivotTableComponent;
