/* eslint-disable no-throw-literal */
import { INPUT_TYPES } from '../../../constants';
import { VALIDATION_TYPES, validate } from '../../../shared/utility/regex';

/* eslint-disable max-len */
export const convertFormInputObjToSimple = (formInput) =>
  Object.entries(formInput).reduce((acc, entry) => {
    const [key, value] = entry;
    let resolvedVal = value;
    if (typeof value === 'object') {
      resolvedVal = value?.value;
    }
    return {
      ...acc,
      [key]: resolvedVal
    };
  }, {});

export const validateFields = (userformStructure, formInput) =>
  userformStructure.every((field) => {
    const { inputType, key, validation, label, defaultValue } = field;
    if (validation) {
      const { format, type, value, key: comparisionKey } = validation;
      if (type === 'regex') {
        const res = new RegExp(value).test(formInput?.[key] || '');
        console.log('res', res, value, new RegExp(value), formInput?.[key] || '');
        if (res) {
          return true;
        }
        throw `Please enter ${label} in ${format} format`;
      }
      // if value is equals
      if (value.trim()?.toLowerCase() === 'equals') {
        if ((formInput[key] || defaultValue)?.localeCompare(formInput[comparisionKey] || defaultValue) === 0) {
          return true;
        }
        const toBeMatchedLabel = userformStructure.find((e) => e.key === comparisionKey)?.label;
        throw `${toBeMatchedLabel} and ${label} should match`;
      }
    }
    if (inputType === INPUT_TYPES.EMAIL) {
      const res = validate(formInput?.[key], VALIDATION_TYPES.EMAIL);
      if (!res) {
        throw 'Please Enter valid Email';
      }
      return res;
    }
    return true;
  });
