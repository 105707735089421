import moment from 'moment/moment';
/* eslint-disable max-len */
export const getSPCDataPoints = (nodeDetails, additionalDetails = {}, isAlarm) => {
  if (nodeDetails) {
    return nodeDetails.map((dataPoint) => {
      const { X, Y, xPointer } = dataPoint;
      const XLabel = moment(X).format('YYYY-MM-DDTHH:mm:ss');
      return {
        X,
        XLabel,
        xPointer,
        [isAlarm ? 'Y' : `${additionalDetails.node}-Overlay`]: Y,
        // [`${overlay}-Overlay`]: Y,
        ...additionalDetails
      };
    });
  }
  return [];
};
