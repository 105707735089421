/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import BarGaugeView from '../../../../views/Widgets/WidgetComponents/BarGaugeView/BarGaugeView';

const UpsertBarGaugeWidget = () => {
  const { data = [] } = useSelector((state) => state.upsertWidgetData);
  return (
    <div style={{ width: '51%' }}>
      <BarGaugeView data={data} initializing={true} />
    </div>
  );
};
export default UpsertBarGaugeWidget;
