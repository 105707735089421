/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-export-excel';
import * as XLSX from 'xlsx/xlsx.mjs';
import PopUpModal from '../../Common/PopUpModal/Modal.view';
import {
  BoldSpan,
  BulkUpdateHeader,
  CloseContainer,
  DownloadSpan,
  FileControl,
  Header,
  HighlightedText,
  Instructions,
  MaximumRestiriction,
  ProgressBarContainer,
  ProgressText,
  UpdateBody,
  UpdateInstruction,
  UploadButton,
  UploadingText
} from './BulkUpdateDownload.style';
import DynamicSVGIcon from '../../Common/DynamicSVGIcon';
import { CancelBlack } from '../../../assets/icons';
import ButtonComp from '../../Common/Button/Button.view';
import { useActions } from '../../../store/hooks/useActions';
import { colors } from '../../../constants';
import Progressbar from './ProgressBar.View';
import TabsTable from '../../Common/Tabs_Table';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const BulkUpdateDownload = (props) => {
  const { handleCloseUpdate, label, updateUsersList, setUpdateUsersList, modal } = props;
  const [uploadingStatus, setUpLoadingStatus] = useState('Uploading...');
  const [showProgress, setShowProgress] = useState(false);
  // const [remainingTime, setRemainingTime] = useState(0);
  // const [percentage, setPercentage] = useState();
  const [fileName, setFileName] = useState();

  const { bulkUpdateList, bulkUpdateListMeta } = useSelector((state) => state.usersDetails);

  const inputRef = useRef();
  const downloadRef = useRef();

  const { getBulkUsersUpdateList } = useActions();

  const startingTime = Date.now();

  /**
   * Function to show progress percentage on file upload
   * @param {any} e
   * @returns {any}
   */
  const ProgressHandler = (e) => {
    const totalBytes = e.total;
    const uploadedBytes = e.loaded;
    const percent = (e.loaded / e.total) * 100;
    // setPercentage(Math.round(percent));
    const currentTime = Date.now();
    const uploadSpeed = (uploadedBytes / (currentTime - startingTime)) * 1000;
    const timeRemaining = Math.round((totalBytes - uploadedBytes) / uploadSpeed);
    // setRemainingTime(timeRemaining);
  };

  /**
   * Function to change file upload status
   * @param {*} e
   */
  const SuccessHandler = (e) => {
    setUpLoadingStatus('Upload completed');
    setShowProgress(false);
    handleCloseUpdate();
  };

  /**
   * Function to change file upload status
   * @param {*} e
   */
  const ErrorHandler = (e) => {
    setUpLoadingStatus('upload failed!!');
  };

  /**
   * Function to change file upload status
   * @param {*} e
   */
  const AbortHandler = (e) => {
    setUpLoadingStatus('upload aborted!!');
  };

  /**
   * Description - Function which reads file and converts it into json  using xlxs
   * @param {any} event
   * @returns {any}
   */
  const fileSelectedHandler = (event) => {
    setFileName(event.target.files[0].name);
    setShowProgress(true);
    if (event.target.files) {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          cellDates: true,
          cellFormula: false,
          cellNF: false,
          cellHTML: false,
          cellText: false,
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 0, defval: null });
        // eslint-disable-next-line no-undef
        // const xhr = new XMLHttpRequest();
        // xhr.upload.addEventListener('progress', ProgressHandler, false);
        // xhr.addEventListener('load', SuccessHandler, false);
        // xhr.addEventListener('error', ErrorHandler, false);
        // xhr.addEventListener('abort', AbortHandler, false);
        // xhr.open('POST', 'https://v2.convertapi.com/upload');
        // xhr.send(data);
        const filtered = json.filter((row) => Object.values(row).some((v) => v !== ''));
        setUpdateUsersList(filtered.slice(1));
        setUpLoadingStatus('Upload completed');
        setShowProgress(false);
        handleCloseUpdate();
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const labelArray = [bulkUpdateList];

  useEffect(() => {
    getBulkUsersUpdateList(modal);
  }, []);

  return (
    <div>
      <PopUpModal
        width='45%'
        style={{ padding: '4rem' }}
        closeModalHandler={() => {}}
      >
        <Header>
          <BulkUpdateHeader>{label}</BulkUpdateHeader>
          <div style={{ cursor: 'pointer' }} onClick={handleCloseUpdate}>
            <DynamicSVGIcon iconUrl={CancelBlack} />
          </div>
        </Header>
        <UpdateBody>
          <UpdateInstruction>
            Please follow the following steps to update multiple users
          </UpdateInstruction>
          <Instructions>
            <div>
              1. Download the
              <BoldSpan
                onClick={() => {
                  // getBulkUsersUpdateList(modal);
                  if (bulkUpdateList) {
                    downloadRef?.current?.click();
                  }
                }}
              >
                {' '}
                Sample Import File
              </BoldSpan>
            </div>
            <div>
              2.
              <HighlightedText>
                {' '}
                Do not change Row 1 in the sample import file.
              </HighlightedText>
              These headers need to be same so that the data can be imported.
            </div>
            <div>
              3. Start adding data from row 2 ,dont forget to delete the sample
              rows provided.
            </div>
            <div>4. Save and upload file</div>
          </Instructions>
          <MaximumRestiriction>
            You can upload a maximum of 5000 users with a single spreadsheet.
          </MaximumRestiriction>
          <UploadButton>
            <input
              hidden
              type='file'
              accept=".xlsx"
              ref={inputRef}
              onChange={(e) => fileSelectedHandler(e)}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            {!showProgress && (
              <ButtonComp
                dark
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Upload
              </ButtonComp>
            )}
            {showProgress && (
              <ProgressBarContainer>
                <UploadingText>{uploadingStatus}</UploadingText>
                {uploadingStatus !== 'Upload completed' && (
                <FileControl>
                  <ProgressText>
                    {fileName}
                  </ProgressText>
                  <div>
                    <CloseContainer onClick={() => {
                      setShowProgress(false);
                      // setRemainingTime(0);
                      // setPercentage();
                      setUpLoadingStatus('Uploading...');
                    }}
                    >
                      <DynamicSVGIcon width='7px' iconUrl={CancelBlack} />
                    </CloseContainer>
                  </div>
                </FileControl>
                )}
                {/* <Progressbar progress={percentage} /> */}
              </ProgressBarContainer>
            )}
          </UploadButton>
        </UpdateBody>
        {bulkUpdateList.length > 0 && (
        <ExcelFile element={(

          <DownloadSpan ref={downloadRef}>Download</DownloadSpan>

        )}
        >
          <ExcelSheet data={bulkUpdateList} name='user'>
            {Object.keys(bulkUpdateList[0]).map((userData, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ExcelColumn label={userData} value={userData} key={i} />
            ))}
          </ExcelSheet>
        </ExcelFile>
        )}
        {modal === 'bulk add' && Object.keys(bulkUpdateList).length > 0 && (
        <ExcelFile element={(
          <DownloadSpan ref={downloadRef}>Download</DownloadSpan>
)}
        >
          <ExcelSheet data={labelArray} name='user'>
            {Object.keys(bulkUpdateList).map((userData, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ExcelColumn label={userData} value={userData} key={i} />
            ))}
          </ExcelSheet>
        </ExcelFile>
        )}
      </PopUpModal>
    </div>
  );
};

export default BulkUpdateDownload;
