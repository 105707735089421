/* eslint-disable import/extensions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ColumnsContainer, Container, TextBodyContainer } from '../SvgWidget/SVGWidget.style';
import { DeleteBlack, HideBorder, HideHeader, Export as ExportIcon, AddToHome, RemoveHomeMenu, settingsBlack, ExportImageIcon, ExportPdfIcon, ExportExcelIcon, } from '../../../../assets/icons';
import { getFetchDetailsOfWidgetData, getResolvedWidgetCategory } from '../../../Widget/utils';
import { WebService } from '../../../../services/axios/webServices';
import { sagaCatchBlockHandling } from '../../../../store/utility/sagaErrorHandling';
import TabsTable from '../../../Common/Tabs_Table';
import Loader from '../../../Loader/Loader';
import WidgetHeaderView from '../../../Widget/Common/WidgetHeader/WidgetHeader.view';
import { useActions } from '../../../../store/hooks/useActions';
import PopUpModal from '../../../Common/PopUpModal/Modal.view';
import { ReactComponent as CheckMark } from '../../../../assets/icons/KPV/checkmark.svg';
import { CheckBox } from '../../../UpsertPane/UpsertPane.style';
import { BtnWrapper, Button, Heading } from '../../../Common/PopUpModal/Modal.style';
import { Text } from '../../WidgetTypeSelectionSideBar/WidgetTypeSelectionSideBar.style';
import { FilterHeader, HeaderLeft, HeaderRight } from '../../../KPVCharts/PaneCard/AdditionalFilter/FilterPopUp.styles';
import { WIDGET_CATEGORIES, WIDGET_TERMINOLOGY } from '../../../../constants';
import { isEmpty } from '../../../../shared/utility/isEmpty';
import PivotTableComponent from '../../../PivotTable/PivotTableComponent.jsx';

// import { data } from './data';

const TableWidget = (props) => {
  const { componentData, id, layoutPos, fullView, HDId } = props;
  const WID = layoutPos?.i?.split('_')?.[0];

  const [zoomLevel, setZoomLevel] = useState(1);
  const [showColumnConfig, setshowConfig] = useState(false);
  const [showPivotTable, setShowPivotTable] = useState(false);

  const isHideHeader = useSelector((state) => state.widgets.isHideHeader);
  const isHideBorder = useSelector((state) => state.widgets.isHideBorder);
  const isEnablePivot = useSelector((state) => state.widgets.isEnablePivot);
  const isInstancePop = useSelector((state) => state.widgets.isInstancePop);
  const widgetPayloadFromReducer = useSelector((state) => state.widgets.tableMetaPayload);
  const widgetsInHome = useSelector((state) => state.HomeDashboardReducer.widgetsInHome);
  const activeTab = useSelector((state) => state.widgets.activeTab);
  const WTIId = useSelector((state) => state.widgets.WTIId);
  const WTid = useSelector((state) => state.upsertWidgetData.WTId);
  const widgetTypesMenu = useSelector((state) => state.widgets.widgetTypesMenu);
  const zoomLevelFinal = useSelector((state) => state.widgets.zoomLevel);
  const { widgetLabel } = useSelector((state) => state.upsertWidgetData);
  const { formInput, widgetType, } = useSelector((state) => state.upsertWidgetData);
  const [isLocalEnablePivot, setIsLocalEnablePivot] = useState(false);

  const { setExportData, updateWidgetPayload, updateTableConfig, setEnablePivot } = useActions();

  useEffect(() => {
    if (zoomLevelFinal[WID + activeTab?.id]) {
      setZoomLevel(zoomLevelFinal[WID + activeTab?.id]);
    }
  }, [zoomLevelFinal]);
  //
  useEffect(() => {
    if (isEnablePivot[WID + activeTab?.id]) {
      setIsLocalEnablePivot(isEnablePivot[WID + activeTab?.id]);
    }
  }, [isEnablePivot]);

  const {
    widgetEditOptions: initWidgetEditOptions,
    WidgetName,
  } = componentData || {};
  const [tableMeta, setTableMeta] = useState({});

  let widgetOptions = [];
  let widgetOptionsInstance = [];
  let widgetOptionsInstancePop = [];

  if (componentData?.type === WIDGET_CATEGORIES.PIVOT_TABLE && tableMeta?.pivotTableEnabled) {
    widgetOptions = [
    // {
    //   id: 9,
    //   label: 'Full View',
    //   value: 'fullView',
    //   iconUrl: FullScreen,
    // },
      {
        id: 1,
        label: 'configure',
        value: 'configure',
        iconUrl: settingsBlack,
      },
      { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 6,
        label: 'Configure Columns',
        value: 'configureColum',
        iconUrl: HideBorder
      },
      {
        id: 1,
        label: 'Pivot Table',
        iconUrl: settingsBlack,
        subMenu: [
          {
            id: 5,
            label: isEnablePivot[WID + activeTab?.id] ? 'Disable Pivot' : 'Enable Pivot',
            value: 'togglePivot',
            iconUrl: HideBorder
          },
          {
            id: 2,
            label: 'Configure Pivot Table',
            value: 'piviot',
            iconUrl: settingsBlack
          }
        ]
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ];
    widgetOptionsInstance = HDId ? [
      {
        id: 1,
        label: 'Delete',
        value: 'deleteFromHome',
        iconUrl: DeleteBlack
      },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ] : [
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ];

    widgetOptionsInstancePop = [
      {
        id: 1,
        label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
        value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
        iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
      },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      }
    ];
  } else {
    widgetOptions = [
      // {
      //   id: 9,
      //   label: 'Full View',
      //   value: 'fullView',
      //   iconUrl: FullScreen,
      // },
      {
        id: 1,
        label: 'configure',
        value: 'configure',
        iconUrl: settingsBlack,
      },
      { id: 2, label: 'delete', value: 'delete', iconUrl: DeleteBlack },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 6,
        label: 'Configure Columns',
        value: 'configureColum',
        iconUrl: HideBorder
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ];
    widgetOptionsInstance = HDId ? [
      {
        id: 1,
        label: 'Delete',
        value: 'deleteFromHome',
        iconUrl: DeleteBlack
      },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ] : [
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      },
      {
        id: 10,
        label: 'Export',
        value: 'export',
        iconUrl: ExportIcon,
        subMenu: [
          { id: 1, label: 'Image', value: 'PngTableExport', iconUrl: ExportImageIcon, },
          {
            id: 2,
            label: 'PDF',
            value: 'pdfExportWithoutTab',
            iconUrl: ExportPdfIcon,
          },
          {
            id: 2,
            label: 'Excel',
            value: 'excelExport',
            iconUrl: ExportExcelIcon,
          }
        ]
      },
    ];

    widgetOptionsInstancePop = [
      {
        id: 1,
        label: widgetsInHome.includes(WID) ? 'Remove From Home' : 'Add To Home',
        value: widgetsInHome.includes(WID) ? 'deleteFromHome' : 'addToHome',
        iconUrl: widgetsInHome.includes(WID) ? RemoveHomeMenu : AddToHome
      },
      {
        id: 3,
        label: isHideHeader[WID + activeTab?.id] ? 'Show Header' : 'Hide Header',
        value: 'toggleHeader',
        iconUrl: HideHeader
      },
      {
        id: 4,
        label: isHideBorder[WID + activeTab?.id] ? 'Show Border' : 'Hide Border',
        value: 'toggleBorder',
        iconUrl: HideBorder
      }
    ];
  }

  const [feilds, setFeilds] = useState([]);
  const [widgetData, setWidgetData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id: WTIIdFromUrl } = useParams();
  useEffect(() => {
    setLoading(true);
    const fetchUrl = widgetTypesMenu?.[componentData?.type]?.fetchUrl;
    const { fullURL, method } = getFetchDetailsOfWidgetData(fetchUrl, true);
    const type = componentData?.type;
    // TODO_W_R
    WebService[method](fullURL)
      .then((response) => {
        const { data, meta } = response?.data;
        setWidgetData({ data, meta });
        if (isEmpty(componentData?.tableMeta)) {
          setTableMeta(meta);
          const copiedObject = JSON.parse(JSON.stringify(meta));
          updateTableConfig(copiedObject);
        } else {
          setTableMeta(componentData?.tableMeta);
          const copiedObject = JSON.parse(JSON.stringify(componentData?.tableMeta));
          updateTableConfig(copiedObject);
        }
        // setTableMeta(meta);
        setExportData({ TabId: WTIIdFromUrl || activeTab?.id, WID, widgetData: { data, meta, WidgetName, type } });
        setLoading(false);
      })
      .catch((e) => {
        const err = sagaCatchBlockHandling(e);
        //   setError(err.error);
        setLoading(false);
        console.log('status value err', e);
      });
  }, []);

  const handleSave = async () => {
    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        tableMeta,
        feilds,
      },
      WidgetMetaData: widgetMetaData
    };
    updateWidgetPayload({ widgetPayload, WID });
    const copiedObject = JSON.parse(JSON.stringify(tableMeta));
    updateTableConfig(copiedObject);
  };
  const onSave = (feilds) => {
    setFeilds(feilds);
    setShowPivotTable(false);
    /**
     * Adding the below line of code to update the reducer
     * To set Pivot table view by default on configuring the pivot table
     */
    setEnablePivot({
      WID,
      WPID: activeTab?.id,
      isEnablePivot: true
    });

    const { type, WidgetName, widgetCategory, formData } = componentData;
    const { widgetComponentData, widgetMetaData } = {
      widgetComponentData: {
        ...componentData,
        formData,
        type,
        [WIDGET_TERMINOLOGY.WIDGET_CATEGORY]: getResolvedWidgetCategory(
          widgetCategory,
          type
        )
      },
      widgetMetaData: {}
    };
    const widgetPayload = {
      WidgetComponentData: {
        ...widgetComponentData,
        tableMeta,
        feilds,
      },
      WidgetMetaData: widgetMetaData
    };
    updateWidgetPayload({ widgetPayload, WID });
  };
  const discardConfiguration = (feildData) => {
    if (feilds.length === 0) {
      setFeilds(feildData?.length > 0 ? feildData : []);
    }
    setShowPivotTable(false);
  };
  return (
    <>
      <Container headerOn={!isHideHeader[WID + activeTab?.id]}>
        {!fullView && (
          <WidgetHeaderView
            id={id}
            WID={WID} // WID
            WidgetName={WidgetName || widgetLabel}
            hasZoomControl={true}
            setZoomLevel={setZoomLevel}
            zoomLevel={zoomLevel}
            setshowConfig={setshowConfig}
            setIsLocalEnablePivot={setIsLocalEnablePivot}
            setShowPivotTable={setShowPivotTable}
            widgetOptions={
              WTIId || WTid || HDId ? isInstancePop ? widgetOptionsInstancePop : widgetOptionsInstance : widgetOptions
            }
            componentData={componentData}
          />
        )}
        <TextBodyContainer className='zoom' zoomLevel={fullView ? 1 : zoomLevel} headerOn={!isHideHeader[WID + activeTab?.id]}>
          {loading ? <Loader /> : isLocalEnablePivot ? (
            <PivotTableComponent metaDetails={tableMeta} data={widgetData?.data} onSave={onSave} feild={feilds.length > 0 ? feilds : componentData?.feilds || []} fieldChoose={false} />
          ) : (
            <TabsTable
              metaDetails={tableMeta}
              dataDetails={widgetData?.data}
              showPagiation={false}
              showShortlistSelection={false}
              styleType='strapedTable'
              noItemsFoundMessage='No Users Found'
              showRowOptionAtStart={false}
              id='UserID'
            />
          )}
        </TextBodyContainer>
      </Container>
      {showColumnConfig && (
        <PopUpModal width='75%' closeModalHandler={setshowConfig}>
          <Heading>Column Configuration</Heading>
          <FilterHeader>
            <HeaderLeft>
              <Text>Select The Columns To be Shown</Text>
            </HeaderLeft>
            <HeaderRight>
              <BtnWrapper style={{ width: '100%', alignItems: 'flex-end' }}>
                <Button
                  type='button'
                  onClick={() => {
                    setshowConfig(false);
                    setTableMeta({ ...widgetPayloadFromReducer });
                  }}
                >
                  Back
                </Button>
                <Button
                  type='submit'
                  className='dark'
                  onClick={() => {
                    setshowConfig(false);
                    handleSave();
                  }}
                >
                  Apply
                </Button>
              </BtnWrapper>
            </HeaderRight>
          </FilterHeader>
          <ColumnsContainer>
            {tableMeta?.columns?.map((column, index) => (
              <div style={{ display: 'flex', alignItems: 'center', flex: '0 1 25%', padding: '1rem' }}>
                <Text style={{ flex: '0 1 70%', maxWidth: '184px', overflow: 'hidden', whiteSpace: 'noWrap', textOverflow: 'ellipsis' }}>{column?.title}</Text>
                <CheckBox
                  className={column?.visible ? 'active' : 'unactive'}
                  onClick={() => {
                    const columnsMeta = { ...tableMeta };
                    columnsMeta.columns[index] = {
                      ...column,
                      visible: !column?.visible,
                    };
                    setTableMeta({ ...columnsMeta });
                    // console.log(widgetPayloadFromReducer[WID], 'red onclick');
                  }}
                >
                  <CheckMark />
                </CheckBox>
              </div>
            ))}

          </ColumnsContainer>
        </PopUpModal>
      )}
      {showPivotTable && (
        <PopUpModal width="80%" height="80%" style={{ overflow: 'scroll' }} disableOutsideClick>
          <PivotTableComponent
            data={widgetData?.data}
            metaDetails={tableMeta}
            setShowPivotTable={setShowPivotTable}
            onSave={onSave}
            feild={feilds.length > 0 ? feilds : componentData?.feilds || []}
            discardConfiguration={discardConfiguration}
            fieldChoose={true}
          />
        </PopUpModal>
      )}

    </>
  );
};

export default TableWidget;
